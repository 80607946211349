import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ChartLegend.styles';

export const ChartLegend = () => {
  const { t } = useTranslation('earnings');

  return (
    <S.ChartLegendRoot>
      <S.LegendItem>
        <S.LegendIcon>
          <S.MiniBar />
        </S.LegendIcon>
        <S.LegendLabel>{t('earnings:chart.tradingRange')}</S.LegendLabel>
      </S.LegendItem>
      <S.LegendItem>
        <S.LegendIcon>
          <S.MiniCircle />
        </S.LegendIcon>
        <S.LegendLabel>{t('earnings:chart.median')}</S.LegendLabel>
      </S.LegendItem>
    </S.ChartLegendRoot>
  );
};
