import { Table, TableCell } from '@toggle/design-system';
import { NumericalCellValueStyle } from '@toggle/helpers';
import styled, { css } from 'styled-components';

export const PredictionSummaryTableRoot = styled.div`
  display: flex;
`;

export const TableStyled = styled(Table)`
  th {
    border-bottom: 1px solid var(--border-default);
    background-color: transparent;
    height: 40px;
  }

  td {
    border-bottom: 1px solid var(--border-soft);
    height: 44px;

    &:first-child {
      min-width: 94px;
      background-color: transparent;
    }
  }
`;

const backgroundColors = {
  [NumericalCellValueStyle.Positive]: 'var(--viz-bullish-soft)',
  [NumericalCellValueStyle.Negative]: 'var(--viz-bearish-soft)',
  [NumericalCellValueStyle.Neutral]: 'transparent',
};

export const NumericalTableCell = styled(TableCell)<{
  $numericalStyle: NumericalCellValueStyle;
}>`
  ${({ $numericalStyle }) =>
    css`
      background-color: ${backgroundColors[$numericalStyle]};
    `}
`;
