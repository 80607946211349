import React from 'react';

import { ColorScheme } from '~/design-tokens/themes/themes';

import * as S from './SidebarLogotype.styles';

export interface SidebarLogotypeProps {
  isExpanded?: boolean;
  colorScheme?: ColorScheme;
}

export const SidebarLogotype: React.FC<SidebarLogotypeProps> = ({
  isExpanded = false,
  colorScheme = ColorScheme.Dark,
  ...rest
}) => {
  const logoSrc = `/app/static/images/brand/logo-${colorScheme}.svg`;
  const logoMiniSrc = `/app/static/images/brand/logo-mini-${colorScheme}.svg`;

  return (
    <S.SidebarLogotypeRoot $isExpanded={isExpanded} {...rest}>
      <S.Logo src={logoSrc} />
      <S.LogoMini src={logoMiniSrc} />
    </S.SidebarLogotypeRoot>
  );
};
