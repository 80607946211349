import {
  Accordion,
  borderRadius,
  HighLowTripleLineChart,
  Icon,
  SkeletonLoader,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { PriceMovementLegend } from '~/components/price-movement/components/price-movement-legend/PriceMovementLegend';

export const StyledTextDefaultSpan = styled.span`
  color: var(--text-default);
  ${typographyV2.titleBase};
`;

export const StyledTextPrimarySpan = styled.span`
  color: var(--text-primary);
`;

export const IconStyledCustomAnimation = styled(Icon)<{
  $expanded: boolean;
}>`
  flex-shrink: 0;
  transition: transform 0.4s cubic-bezier(0.75, 0.02, 0.5, 1);
  display: block;
  max-width: 100%;
  ${({ $expanded }) => `transform: rotate(${$expanded ? 90 : 0}deg)`}
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '293px',
        borderRadius: '8px',
      },
    },
  ],
})``;

export const StyledErrorMessageWrapper = styled(ErrorMessageWrapper)`
  text-align: center;
`;

export const StyledAccordion = styled(Accordion)`
  border: 1px solid var(--border-soft);
  ${borderRadius.r2};
  ${Accordion.Styled.AccordionItem} {
    &.accordion-expanded {
      background-color: var(--background-soft);
    }
  }
  ${Accordion.Styled.TitleContainer} {
    padding: 16px;
  }

  ${HighLowTripleLineChart.Styled.HighLowTripleLineChartRoot} {
    min-height: 220px;
  }

  ${PriceMovementLegend.Styled.LegendLabel} {
    ${typographyV2.bodyXs};
  }
`;
