import { RangeHorizon, rangeToResampleMapping } from '@toggle/chart';
import { SmartTable, Tooltip } from '@toggle/design-system';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useMatch, useNavigate } from 'react-router';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { appPaths } from '~/routes/app-paths';
import {
  getInsightEpisodeDates,
  getInsightTotalTradingDays,
} from '~/services/insights/utils/insight-utils';
import { InsightPastEpisodesData } from '~/stores/use-chat/assistant-response.type';

import * as S from '../../PastEpisodesWidget.styles';
import { PastEpisodesChart } from '../past-episodes-chart/PastEpisodesChart';
import {
  getColumns,
  getHorizonsLabels,
  mapEpisodesToTableRow,
} from './utils/utils';

export const PAST_EPISODES_HORIZONS = [
  RangeHorizon.OneMonth,
  RangeHorizon.ThreeMonths,
  RangeHorizon.SixMonths,
  RangeHorizon.OneYear,
  RangeHorizon.ThreeYears,
  RangeHorizon.Max,
];

export type EpisodeTableRow = {
  key: string;
  episodeNo: number;
  date: string;
  tradingDays: number;
};

export const InsightPastEpisodes = ({
  insight,
  entity,
  hasHeader = true,
}: InsightPastEpisodesData) => {
  const { t } = useTranslation(['widget', 'chart']);

  const navigate = useNavigate();
  const match = useMatch(appPaths.chart);

  const episodes = insight.episodes;

  const totalTradingDays = getInsightTotalTradingDays(insight);

  const [activeHorizonIdx, setActiveHorizonIdx] = useState(5);
  const handleChangeHorizon = (index: number) => setActiveHorizonIdx(index);

  const columns = getColumns(totalTradingDays, t);

  const conditions = useMemo(() => {
    const { entity, snake } = insight.metadata;
    const uniqueConditions = uniqBy(insight.conditions, 'snake');

    const isInInsightsSnakes = uniqueConditions.some(
      condition =>
        condition.snake === snake ||
        (condition.snake.includes('price') &&
          condition.snake.replace(/\.ds$/, '') === snake)
    );

    if (isInInsightsSnakes) {
      return insight.conditions;
    }

    return [
      {
        entity,
        snake,
      },
      ...uniqueConditions,
    ];
  }, [insight.conditions, insight.metadata]);

  const handleFormatAssetsParams = () => {
    return conditions.map(
      (condition, index) => `${condition.entity};${condition.snake};${index}`
    );
  };

  const handleSamePathReload = () => {
    if (match?.pathname === appPaths.chart) {
      navigate(0);
    }
  };

  return (
    <>
      <S.Title data-testid="episode-description">
        <span>
          <span>{t('widget:pastEpisode:title.occurrences')}</span>
          <S.PastEpisodeKey />
          <TooltipContainer
            label={t('analyze:experiment.chart.tooltip')}
            showUnderline
          >
            <span>
              {t('widget:pastEpisode:title.episodes', {
                episodes: getInsightEpisodeDates(episodes).length,
              })}
            </span>
          </TooltipContainer>
          <span>
            {t('widget:pastEpisode:title.days', {
              totalTradingDays,
            })}
          </span>
        </span>
      </S.Title>

      <S.PastEpisodesButtons>
        <S.StyledButtonGroup
          labels={getHorizonsLabels(t, PAST_EPISODES_HORIZONS)}
          onClick={handleChangeHorizon}
          activeIndex={activeHorizonIdx}
          $activeIndex={activeHorizonIdx}
        />
        <Tooltip label={t('chart:fullscreen')}>
          <S.StyledButton
            iconName="Expand"
            variant="empty"
            size="small"
            onClick={() => {
              navigate({
                pathname: appPaths.chart,
                search: createSearchParams({
                  asset: handleFormatAssetsParams(),
                  horizon: PAST_EPISODES_HORIZONS[activeHorizonIdx],
                  primary: conditions[0].snake,
                  resample:
                    rangeToResampleMapping[
                      PAST_EPISODES_HORIZONS[activeHorizonIdx]
                    ],
                  'yAxisType.1': 'merged',
                }).toString(),
              });
              handleSamePathReload();
            }}
            data-testid="navigate-to-chart-page"
          />
        </Tooltip>
      </S.PastEpisodesButtons>
      <S.ChartWrapper>
        <PastEpisodesChart
          entityTag={entity.tag}
          snakes={conditions.map(condition => condition.snake)}
          episodes={episodes}
          horizon={PAST_EPISODES_HORIZONS[activeHorizonIdx]}
        />
      </S.ChartWrapper>

      {hasHeader && <S.Title>{t('widget:pastEpisode.pastEpisode')}</S.Title>}
      <S.TableRoot>
        <SmartTable
          columns={columns}
          data={mapEpisodesToTableRow(episodes)}
          withBodyRowHover={false}
        />
      </S.TableRoot>
    </>
  );
};
