import { Alert, AlertType } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MailToSupport } from '~/components/mail-to-support/MailToSupport';
import { useDismissContinuous } from '~/hooks/use-dismiss-continuous/useDismissContinuous';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';
import {
  formatTimeLeft,
  isSubscribedToPaidPlan,
  isTrialEnding,
} from '~/views/settings/subscription/utils/subscription-view-utils';

import { getTrialEndsMailLink } from '../../utils/notification-alert-utils';
import * as S from './AlertTrialEnds.styles';

export const DISMISSED_KEY = '__toggle_dismissed_trial_notification';
const SHOW_ALERT_BEFORE_IN_DAYS = 7;

export interface AlertTrialEndsProps {
  upgradeToPro: () => void;
}

export const AlertTrialEnds: FC<AlertTrialEndsProps> = ({ upgradeToPro }) => {
  const { t } = useTranslation('subscription');

  const userSubscription = useSubscription(state => state.userSubscription);
  const user = useUser(state => state.user);
  const isOnPaidPlan = isSubscribedToPaidPlan(userSubscription);
  const trialTo = userSubscription.trial_to;

  const { isVisible, handleDismiss } = useDismissContinuous({
    endDate: trialTo,
    cookieKey: DISMISSED_KEY,
    showBeforeInDays: SHOW_ALERT_BEFORE_IN_DAYS,
  });

  if (!isVisible || !(trialTo && !isOnPaidPlan) || !isTrialEnding(trialTo)) {
    return null;
  }

  const trialDaysLeft = formatTimeLeft(trialTo);
  const isProfessional = user?.is_professional;
  const mailToLink = getTrialEndsMailLink(t, isProfessional);

  const onPrimaryBtnClick = () => {
    if (isProfessional) {
      window.location.href = mailToLink;
    } else {
      upgradeToPro();
    }

    handleDismiss();
  };

  return (
    <S.AlertTrialEndsRoot>
      <Alert
        data-testid="alert-trial-ends"
        variant="toast"
        type={AlertType.Warning}
        title={t('subscription:alerts.trialEnds.title', {
          trialDaysLeft,
        })}
        message={<MailToSupport isProfessional={isProfessional} />}
        onClose={handleDismiss}
        primaryBtn={{
          label: isProfessional
            ? t('subscription:alerts.trialEnds.contactUs')
            : t('subscription:alerts.trialEnds.subscribeNow'),
          onClick: onPrimaryBtnClick,
        }}
      />
    </S.AlertTrialEndsRoot>
  );
};
