import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const MarkdownStyles = styled.div`
  ${typographyV2.bodyBase};
  margin-bottom: 16px;
  overflow: auto;

  h1 {
    ${typographyV2.titleLg}
  }

  h2 {
    ${typographyV2.titleMd}
  }

  h3 {
    ${typographyV2.titleBase}
  }

  h4 {
    ${typographyV2.titleBase};
  }

  h5 {
    ${typographyV2.titleSm};
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    list-style-type: disc;
    margin: 1em 0;
    padding: 0 0 0 2em;
  }
`;
