import { Portal, Toggle } from '@toggle/design-system';
import { useDisableScroll, useScrollListener } from '@toggle/helpers';
import { Citation } from '@toggle/toggle';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCitationGroup } from '~/hooks/use-citation-group/useCitationGroup';
import { useCitationGroupDocuments } from '~/hooks/use-citation-group-documents/useCitationGroupDocuments';
import { useApp } from '~/stores/use-app/useApp';
import { Message } from '~/stores/use-chat/use-chat.types';
import { useChat } from '~/stores/use-chat/useChat';
import { mapFilters } from '~/views/screener/use-screener-store/filter-utils';

import { DocumentEmpty } from '../document-viewer/document-empty/DocumentEmpty';
import { HighlightTextConfig } from '../document-viewer/document-highlight-utils/document-highlight-utils';
import { DocumentLoader } from '../document-viewer/document-loader/DocumentLoader';
import { DocumentViewer } from '../document-viewer/DocumentViewer';
import { DocumentFilters } from './document-filters/DocumentFilters';
import { DocumentSourcesHeader } from './document-sources-header/DocumentSourcesHeader';
import { DocumentSourcesTable } from './document-sources-table/DocumentSourcesTable';
import { useDocumentSourcesTableStore } from './document-sources-table/use-document-sources-table-store/useDocumentSourcesTableStore';
import * as S from './DocumentSourcesView.styles';

export const CITATION_GROUP_ID = 'CITATION_GROUP_ID';

interface DocumentSourcesViewProps {
  chatMessage: Message;
  messageIndex: number;
  onClose: () => void;
  citationId: string;
}

export const DocumentSourcesView: FC<DocumentSourcesViewProps> = ({
  chatMessage,
  messageIndex,
  citationId,
  onClose,
}) => {
  const { t } = useTranslation(['chat', 'common']);
  const [selectedCitation, setSelectedCitation] = useState<Citation>();
  const [isGroupedByDocument, setIsGroupedByDocument] = useState(false);
  const sorting = useDocumentSourcesTableStore(state => state.sorting);
  const resetColumnSorting = useDocumentSourcesTableStore(
    state => state.resetColumnSorting
  );
  const isMessagePending = useChat(state => state.isMessagePending);
  const activeDocumentFilterOptions = useDocumentSourcesTableStore(
    state => state.filterGroups.DOCUMENT_FILTERS.activeFilterOptions
  );
  const setCustomContextCounts = useDocumentSourcesTableStore(
    state => state.setCustomContextCounts
  );
  const filters = useMemo(
    () => mapFilters(activeDocumentFilterOptions),
    [activeDocumentFilterOptions]
  );

  const citationGroupQueryResult = useCitationGroup({
    groupId: citationId,
    filters,
  });
  const documentGroupQueryResult = useCitationGroupDocuments({
    groupId: citationId,
    sorting,
    filters,
  });

  const citations = citationGroupQueryResult.data?.pages.flatMap(
    p => p.citations
  );
  const documents = documentGroupQueryResult.data?.pages.flatMap(
    p => p.documents
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const isSidebarExpanded = useApp(state => state.isSidebarExpanded);

  const onClick = (citation?: Citation) => {
    setSelectedCitation(citation);
  };

  useEffect(() => {
    if (selectedCitation) {
      const selectedDocumentId = selectedCitation.document_id;

      if (isGroupedByDocument && !documentGroupQueryResult.isLoading) {
        const documentIds = documents?.map(doc => doc.id) ?? [];

        if (!documentIds.includes(selectedDocumentId)) {
          setSelectedCitation(undefined);
        }
      } else if (!isGroupedByDocument && !citationGroupQueryResult.isLoading) {
        const documentIds =
          citations?.map(citation => citation.document_id) ?? [];

        if (!documentIds.includes(selectedDocumentId)) {
          setSelectedCitation(undefined);
        }
      }
    }
  }, [
    selectedCitation,
    isGroupedByDocument,
    citations,
    documents,
    citationGroupQueryResult.isLoading,
    documentGroupQueryResult.isLoading,
  ]);

  useScrollListener(
    containerRef,
    () => {
      const queryResult = isGroupedByDocument
        ? documentGroupQueryResult
        : citationGroupQueryResult;

      if (queryResult?.hasNextPage) {
        queryResult.fetchNextPage();
      }

      return queryResult?.hasNextPage;
    },
    [citationGroupQueryResult, documentGroupQueryResult, isGroupedByDocument],
    { isGlobalScrollListened: false, offset: 200 }
  );

  useDisableScroll();

  useEffect(() => {
    return () => {
      resetColumnSorting();
    };
  }, []);

  const highlightTexts: HighlightTextConfig['highlightTexts'] = useMemo(() => {
    if (
      !selectedCitation ||
      selectedCitation.document_kind === 'presentation'
    ) {
      return [];
    }

    return [
      {
        text: selectedCitation.text.trim().replace(/\s\s*/g, ' '),
        pages: selectedCitation.pages,
        isPrimary: true,
      },
    ];
  }, [selectedCitation]);

  const totalCounts = citationGroupQueryResult.data?.pages[0].total_count;

  useEffect(() => {
    if (totalCounts && !isMessagePending) {
      setCustomContextCounts({
        documentsCount: totalCounts.docs,
        companiesCount: totalCounts.companies,
      });
    }
  }, [totalCounts, isMessagePending]);

  const resolvedDocumentCount = t('chat:documentCount', {
    documentCount: totalCounts?.docs ?? 0,
    count: totalCounts?.docs ?? 0,
  });
  const resolvedCompanyCount = t('chat:companyCount', {
    companyCount: totalCounts?.companies ?? 0,
    count: totalCounts?.companies ?? 0,
  });

  const resolvedPassages = t('chat:passageCount', {
    passageCount: totalCounts?.citations ?? 0,
    count: totalCounts?.citations ?? 0,
  });

  const sourcesListSubheader = isGroupedByDocument
    ? resolvedPassages
    : t('chat:sourcesListSubheader', {
        documentCount: resolvedDocumentCount,
        companyCount: resolvedCompanyCount,
      });

  const sourcesListHeader = isGroupedByDocument
    ? t('chat:sourcesListHeader', {
        documentCount: resolvedDocumentCount,
        companyCount: resolvedCompanyCount,
      })
    : resolvedPassages;

  const hasTableData = isGroupedByDocument
    ? !!documents?.length
    : !!citations?.length;
  const isTableLoading = isGroupedByDocument ? !documents : !citations;

  const tableData = isGroupedByDocument
    ? {
        data: documents ?? [],
        isLoading: documentGroupQueryResult.isLoading,
        isError: documentGroupQueryResult.isError,
      }
    : {
        data: citations ?? [],
        isLoading: citationGroupQueryResult.isLoading,
        isError: citationGroupQueryResult.isError,
      };
  const hasNextPage = isGroupedByDocument
    ? documentGroupQueryResult.hasNextPage
    : citationGroupQueryResult.hasNextPage;

  return (
    <Portal>
      <S.Container $isSidebarExpanded={isSidebarExpanded}>
        <DocumentSourcesHeader
          messageIndex={messageIndex}
          question={chatMessage.question}
          onClose={() => {
            if (!isMessagePending) {
              setCustomContextCounts();
            }

            onClose();
          }}
          groupId={citationId}
          filters={filters}
        />
        <DocumentFilters />
        <S.BodyContainer>
          <S.SourcesSection>
            <S.SourcesHeader>
              <S.SourcesListHeaderContainer>
                <S.HeaderAndToggleContainer>
                  <S.SourcesListHeader>{sourcesListHeader}</S.SourcesListHeader>
                  <S.GroupByDocumentContainer>
                    <S.GroupByDocumentLabel $hasData={hasTableData}>
                      {t('chat:groupByDocument')}
                    </S.GroupByDocumentLabel>
                    <Toggle
                      disabled={!hasTableData}
                      isChecked={isGroupedByDocument}
                      onToggle={() => {
                        setSelectedCitation(undefined);
                        setIsGroupedByDocument(!isGroupedByDocument);
                      }}
                    />
                  </S.GroupByDocumentContainer>
                </S.HeaderAndToggleContainer>
                <S.SourcesListSubheader>
                  {sourcesListSubheader}
                </S.SourcesListSubheader>
              </S.SourcesListHeaderContainer>
            </S.SourcesHeader>
            {isTableLoading ? (
              <DocumentLoader />
            ) : (
              <DocumentSourcesTable
                tableData={tableData}
                ref={containerRef}
                onClick={onClick}
                isGrouped={isGroupedByDocument}
                hasNextPage={hasNextPage}
              />
            )}
          </S.SourcesSection>
          {selectedCitation ? (
            <DocumentViewer
              selectedCitation={selectedCitation}
              highlightTexts={highlightTexts}
            />
          ) : (
            <DocumentEmpty hasTableData={hasTableData} />
          )}
        </S.BodyContainer>
      </S.Container>
    </Portal>
  );
};
