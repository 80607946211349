import styled, { CSSProperties, keyframes } from 'styled-components';

interface LineProps {
  $animationDelay: CSSProperties['animationDuration'];
  $animationDuration: CSSProperties['animationDuration'];
  $isFrozen: boolean;
}

const progress = keyframes`
    from {
      width: 0%;
    }
    to {
      width: var(--line-width, 100%);
    }
`;

export const Bar = styled.div`
  height: 4px;
  width: 100%;
  background: var(--surface-neutral);
`;

export const Line = styled.div<LineProps>`
  height: 100%;
  width: 0%;
  border-radius: 2px;
  animation-name: ${progress};
  animation-timing-function: var(--timing-function, linear);
  animation-fill-mode: forwards;
  animation-delay: ${({ $animationDelay }) => $animationDelay};
  animation-duration: ${({ $animationDuration }) => $animationDuration};
  background-color: var(--surface-primary);
  animation-play-state: ${({ $isFrozen }) => $isFrozen && 'paused'};
`;
