/**
 * @deprecated
 */
import { escapeBackslashQuery } from '@toggle/helpers/src/utils/strings/strings';
import {
  entities as entitiesEndpoints,
  Entity,
  entityArrSchema,
  EntitySearchType,
  SearchQueryEndpoints,
  UseTagSearchParams,
} from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export function postEntitiesByQuery(
  query: string,
  searchType: EntitySearchType,
  params: UseTagSearchParams = {},
  forceApiCall?: boolean
) {
  const endPoint = SearchQueryEndpoints[searchType];

  return apiFetch<Entity[]>(`${endPoint}`, {
    schema: entityArrSchema,
    method: 'post',
    body:
      query || forceApiCall
        ? { q: escapeBackslashQuery(query), ...params }
        : {},
  });
}

/**
 * NOTE: A guideline when using this endpoint is to provide only 1 entity tag per request
 */
export const postEntity = (entityTag: string, signal?: AbortSignal) => {
  return apiFetch<Entity[]>(entitiesEndpoints.entity.path, {
    schema: entityArrSchema,
    method: 'post',
    body: {
      filter: 2,
      args: [entityTag],
    },
    options: { signal },
  }).then(response => {
    if (response.error) {
      return {
        error: response.error,
        validationError: response.validationError,
      };
    }

    return {
      data: response.data?.[0],
      validationError: response.validationError,
    };
  });
};

export const postMultipleEntities = async (
  tags: string[],
  signal?: AbortSignal
): Promise<Entity[]> => {
  let entities: Entity[] = [];

  await Promise.all(
    tags.map(tag =>
      postEntity(tag, signal).then(r => {
        if (!r.error && r.data) {
          entities.push(r.data);
        }
      })
    )
  );

  return entities;
};
