import { Icon, TableCell } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const StyledDraggableRow = styled.tr<{
  $isTopOver: boolean;
  $isBottomOver: boolean;
}>`
  position: relative;

  &:hover {
    > button {
      visibility: visible;
    }
  }

  ${({ $isTopOver }) =>
    $isTopOver &&
    css`
      border-top: 2px solid var(--primary-color);
    `};
  ${({ $isBottomOver }) =>
    $isBottomOver &&
    css`
      border-bottom: 2px solid var(--primary-color);
    `};

  td:nth-child(2) {
    display: none;
  }
`;

export const CellContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  width: 54px;
`;

export const DragIcon = styled(Icon)`
  cursor: grab;
`;

export const StyledTableCell = styled(TableCell)`
  padding: 0px;
`;
