import { ScreenerFilter } from '@toggle/toggle';
import React from 'react';

import * as FilterStyles from '~/components/filter/Filter.styles';
import { FilterLabel } from '~/components/filter/FilterLabel';
import { FilterUseType } from '~/components/update-filter-options/updateFilterOptions';
import { isFilterWithChildOptionActive } from '~/views/screener/use-screener-store/filter-utils';

import { ActiveFilterOptions } from '../../Filters';
import * as S from './FilterTree.styles';

export interface FilterTreeProps {
  filter: ScreenerFilter;
  filterOptions: ActiveFilterOptions['options'];
  useType?: FilterUseType;
  showTriggerLabel?: boolean;
  multiselect?: boolean;
  id?: string;
  onClick: () => void;
  isModalOpen?: boolean;
}

export const FilterTree = ({
  filter,
  id = filter.key,
  filterOptions,
  useType,
  multiselect = useType === 'single-use' && filter.config.multiselect,
  showTriggerLabel,
  onClick,
  isModalOpen,
}: FilterTreeProps) => {
  const { name } = filter;
  const isParentOptionActive = isFilterWithChildOptionActive(filterOptions);
  const isFilterActive = useType === 'single-use' && isParentOptionActive;

  return (
    <div>
      <S.FilterTreeTrigger
        $active={isFilterActive}
        $showAsHovered={isModalOpen}
        data-filter={id}
        data-testid="tree-filter-trigger"
        role="button"
        onClick={onClick}
      >
        {(showTriggerLabel || !isFilterActive) && (
          <FilterStyles.TriggerLabel data-testid="filter-name">
            {name}
          </FilterStyles.TriggerLabel>
        )}
        {isFilterActive && (
          <FilterLabel options={filterOptions} multiselect={multiselect} />
        )}
        <FilterStyles.TriggerArrow iconName="ChevronLightDown" />
      </S.FilterTreeTrigger>
    </div>
  );
};
