import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

import * as S from './MultiLineChartAxes.styles';

export type MultiLineChartAxesProps = {
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  yAxisFormatter: (n: number) => string;
};

export const MultiLineChartAxes = ({
  xScale,
  yScale,
  yAxisFormatter,
}: MultiLineChartAxesProps) => {
  const xAxisRef = useRef<SVGGElement>(null);
  const yAxisRef = useRef<SVGGElement>(null);

  useEffect(() => {
    if (xAxisRef.current && xScale.range()[1] > 0) {
      const axisSelection = d3.select(xAxisRef.current);
      const formatter = d3.timeFormat('%b');

      const axis = d3
        .axisBottom<Date>(xScale)
        .ticks(d3.timeMonth.every(1))
        .tickSize(0)
        .tickPadding(16)
        .tickFormat(formatter);

      axisSelection.call(axis);
    }

    if (yAxisRef.current && yScale.range()[0] > 0) {
      const axisSelection = d3.select(yAxisRef.current);

      const axis = d3
        .axisRight<number>(yScale)
        .ticks(8)
        .tickSize(xScale.range()[1])
        .tickFormat(yAxisFormatter)
        .tickPadding(10);

      axisSelection.call(axis).call(g => g.select('.domain').remove());
    }
  }, [xScale, yScale]);

  return (
    <g>
      <S.ChartAxisG
        ref={xAxisRef}
        transform={`translate(0, ${yScale.range()[0]})`}
        data-testid="chart-x-axis-group"
      />
      <S.ChartAxisG ref={yAxisRef} />
    </g>
  );
};
