import { TableCell, TableHeader } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import {
  Article,
  InternalNews,
} from '~/views/intel/messages/web-message/web-message-link-markdown/markdown-components';

import * as S from './CustomComponents.styles';

type LinkTypes = 'article' | 'internal_news' | string;

export interface CustomLinkProps {
  href?: string;
  children?: ReactNode;
  title?: LinkTypes;
}

export const WebMessageLink = (props: CustomLinkProps) => {
  switch (props.title) {
    case 'internal_news':
      return <InternalNews {...props} />;
    case 'article':
    default:
      return <Article {...props} />;
  }
};

export const CustomLink = ({
  href,
  children,
  title,
}: {
  href?: string;
  children?: ReactNode;
  title?: string;
}) => (
  <S.MarkdownA
    href={href}
    title={title}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </S.MarkdownA>
);

export const CustomTableCell = ({ children }: { children?: ReactNode }) => (
  <TableCell>{children}</TableCell>
);

export const CustomTableHeader = ({ children }: { children?: ReactNode }) => (
  <TableHeader>{children}</TableHeader>
);

export const CustomTable = ({ children }: { children?: ReactNode }) => (
  <S.MarkdownTableContainer>
    <S.MarkdownTable>{children}</S.MarkdownTable>
  </S.MarkdownTableContainer>
);
