import { Button, ButtonProps } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import * as S from './ErrorMessage.styles';

export interface ErrorButton extends Partial<ButtonProps> {
  label: string;
  onClick?: () => void;
}

export interface ErrorMessageProps {
  className?: string;
  displayIcon?: 'error' | 'empty';
  title?: ReactNode;
  subtitle?: ReactNode;
  buttonProps?: ErrorButton;
}

export const ErrorMessage = ({
  className,
  displayIcon,
  title,
  subtitle,
  buttonProps,
}: ErrorMessageProps) => {
  return (
    <S.Wrapper className={className} data-testid="widget-error-state">
      {displayIcon === 'error' && (
        <S.ErrorIcon data-testid="error-icon" iconName="ExclamationTriangle" />
      )}

      {displayIcon === 'empty' && <S.EmptyIcon data-testid="empty-icon" />}

      {title && <S.Title>{title}</S.Title>}
      {subtitle && <S.SubTitle>{subtitle}</S.SubTitle>}
      <div>
        {buttonProps && (
          <Button
            onClick={buttonProps?.onClick}
            variant="primary"
            size="small"
            {...buttonProps}
          />
        )}
      </div>
    </S.Wrapper>
  );
};

ErrorMessage.Styled = S;
