import {
  ScreenerFilter,
  ScreenerFilterGroupKeys,
  ScreenerFilterOption,
} from '@toggle/toggle';
import { StoreApi, UseBoundStore } from 'zustand';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import * as FilterOptionUtils from '~/components/update-filter-options/updateFilterOptions';

import { addFiltersToActiveFilters } from './use-filter-actions-utils';

export interface FilterState {
  activeFilters: ScreenerFilter[];
  allFilters: ScreenerFilter[];
  activeFilterOptions: ActiveFilterOptions[];
}

export interface SharedFilterState<
  T extends FilterGroupKeys = ScreenerFilterGroupKeys
> {
  filterGroups: FilterGroups<T>;
}

export type ScenarioFilterGroupKeys =
  | 'SCENARIO_CONDITIONS'
  | 'COLUMN_FILTERS'
  | 'ASSET_FILTERS';
export type EarningFilterGroupKeys = 'EARNINGS_FILTERS';
export type NewsSearchFilterGroupKeys = 'NEWS_SEARCH_FILTERS';
export type ExploreFilterGroupKeys = 'ASSET_FILTERS' | 'INSIGHT_FILTERS';
export type DocumentSearchFilterGroupKeys = 'DOCUMENT_FILTERS';

export type FilterGroupKeys =
  | ScreenerFilterGroupKeys
  | ScenarioFilterGroupKeys
  | NewsSearchFilterGroupKeys
  | EarningFilterGroupKeys
  | ExploreFilterGroupKeys
  | DocumentSearchFilterGroupKeys;

export type FilterGroups<T extends string = FilterGroupKeys> = Record<
  T,
  FilterState
>;

export type AnyFilterStore<T extends FilterGroupKeys = FilterGroupKeys> =
  UseBoundStore<StoreApi<SharedFilterState<T>>>;

export type UseFilterActionsReturn<
  T extends FilterGroupKeys = FilterGroupKeys
> = ReturnType<typeof useFilterActions<T>>;

export interface UseFilterActionsProps<
  T extends FilterGroupKeys = FilterGroupKeys
> {
  store: AnyFilterStore<T>;
  group?: T;
  onFilterGroupsChange?: (filterGroups: FilterGroups<T>, group: T) => void;
}

export const useFilterActions = <T extends FilterGroupKeys>({
  store,
  group,
  onFilterGroupsChange,
}: {
  store: AnyFilterStore<T>;
  group?: T;
  onFilterGroupsChange?: (filterGroups: FilterGroups<T>, group: T) => void;
}) => {
  const defaultGroup = group ?? ('Market Filters' as T);

  const handleFilterGroupsChange = (
    filterGroups: FilterGroups<T>,
    group: T
  ) => {
    if (onFilterGroupsChange) {
      onFilterGroupsChange(filterGroups, group);
    } else {
      store.setState({ filterGroups });
    }
  };

  const addActiveFilters = (filter: ScreenerFilter, group = defaultGroup) => {
    const { filterGroups } = store.getState();
    const { activeFilters, allFilters } = filterGroups[group];

    const newActiveFilters = addFiltersToActiveFilters({
      activeFilters,
      allFilters,
      newFilterKeys: [filter.key],
    });

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilters: newActiveFilters,
        },
      }),
      group
    );
  };

  const removeActiveFilters = (filterKey: string, group = defaultGroup) => {
    const { filterGroups } = store.getState();
    const { activeFilterOptions, activeFilters } = filterGroups[group];

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilters: activeFilters.filter(f => f.key !== filterKey),
          activeFilterOptions: activeFilterOptions.filter(
            o => o.filter !== filterKey
          ),
        },
      }),
      group
    );
  };

  const addFilterOption = (
    props: FilterOptionUtils.ToggleFilterProps,
    group = defaultGroup
  ) => {
    const { filterGroups } = store.getState();
    const current = filterGroups[group] ?? [];

    const nextFilterOptions = FilterOptionUtils.addFilterOption(current, props);

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilterOptions: nextFilterOptions,
        },
      }),
      group
    );
  };

  const setFilterOptions = (
    props: { filterKey: string; options: ScreenerFilterOption[] },
    group = defaultGroup
  ) => {
    const { filterGroups } = store.getState();
    const current = filterGroups[group] ?? [];

    const nextFilterOptions = FilterOptionUtils.setFilterOptions(
      current,
      props
    );

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilterOptions: nextFilterOptions,
        },
      }),
      group
    );
  };

  const removeFilterOption = (
    props: FilterOptionUtils.ToggleFilterProps,
    group = defaultGroup
  ) => {
    const { filterGroups } = store.getState();
    const current = filterGroups[group];

    const nextFilterOptions = FilterOptionUtils.removeFilterOption(
      current,
      props
    );

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilterOptions: nextFilterOptions,
        },
      }),
      group
    );
  };

  const resetActiveFilterOptions = (group = defaultGroup) => {
    const { filterGroups } = store.getState();

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilterOptions: [],
        },
      }),
      group
    );
  };

  const resetFilterOptions = (filterKey: string, group = defaultGroup) => {
    const { filterGroups } = store.getState();
    const { activeFilterOptions } = filterGroups[group];

    const nextActiveFilterOptions = activeFilterOptions.filter(
      f => f.filter !== filterKey
    );

    handleFilterGroupsChange(
      FilterOptionUtils.updateGroup({
        filterGroups,
        group,
        state: {
          activeFilterOptions: nextActiveFilterOptions,
        },
      }),
      group
    );
  };

  const removeFilter = (
    props: FilterOptionUtils.ToggleFilterProps,
    group = defaultGroup
  ) => {
    if (props.filterId === props.filterKey) {
      removeActiveFilters(props.filterKey, group);
    } else {
      removeFilterOption(props, group);
    }
  };

  return {
    addActiveFilters,
    removeActiveFilters,
    addFilterOption,
    removeFilterOption,
    resetActiveFilterOptions,
    resetFilterOptions,
    removeFilter,
    setFilterOptions,
  };
};
