import { Alert, AlertType } from '@toggle/design-system';
import { getDaysInMonth } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDismissContinuous } from '~/hooks/use-dismiss-continuous/useDismissContinuous';

const DISMISSED_KEY = '__toggle_dismissed_card_is_expiring';

export interface AlertCardIsExpiringProps {
  expiryDate: Date;
  openEditCardModal: () => void;
}

export const AlertCardIsExpiring: FC<AlertCardIsExpiringProps> = ({
  expiryDate,
  openEditCardModal,
}) => {
  const { t } = useTranslation('subscription');
  const showBeforeInDays = getDaysInMonth(expiryDate);
  const { isVisible, handleDismiss } = useDismissContinuous({
    endDate: expiryDate,
    cookieKey: DISMISSED_KEY,
    showBeforeInDays,
  });

  const onClick = () => {
    handleDismiss();
    openEditCardModal();
  };

  return isVisible ? (
    <Alert
      data-testid="card-is-expiring-toast-alert"
      title={t('subscription:alerts.paymentMethodExpiring.title')}
      message={t(
        'subscription:alerts.paymentMethodExpiring.notificationMessage'
      )}
      type={AlertType.Warning}
      primaryBtn={{
        label: t('subscription:alerts.paymentMethodExpiring.primaryBtnLabel'),
        onClick,
      }}
      onClose={handleDismiss}
    />
  ) : null;
};
