import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { typographyV2 } from '@toggle/design-system';
import { FC } from 'react';
import styled from 'styled-components';

export const Item = styled<
  FC<RadixDropdownMenu.DropdownMenuItemProps & { $isChecked?: boolean }>
>(RadixDropdownMenu.Item)`
  all: unset;
  display: flex;
  margin-bottom: 4px;
  padding: 12px 8px;
  cursor: pointer;

  ${typographyV2.bodyBase}
`;
