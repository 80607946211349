import React from 'react';

import { CalendarEvents, isCompanyEvent, isEconomicEvent } from '../../utils';
import { CompanyEventDrawerContent } from '../company-event-drawer-content/CompanyEventDrawerContent';
import { EarningsEventDrawerContent } from '../earnings-event-drawer-content/EarningsEventDrawerContent';
import { EconomicsEventDrawerContent } from '../economics-event-drawer-content/EconomicsEventDrawerContent';

export const getDrawerContent = (data: CalendarEvents) => {
  if (isEconomicEvent(data)) {
    return <EconomicsEventDrawerContent data={data} />;
  } else if (isCompanyEvent(data)) {
    return <CompanyEventDrawerContent data={data} />;
  } else {
    return <EarningsEventDrawerContent data={data} />;
  }
};
