import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DropdownGroup = styled(RadixDropdown.Group)<{
  $hasSelectAll?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${typographyV2.bodyXs}
  ${({ $hasSelectAll }) =>
    $hasSelectAll &&
    css`
      padding-left: 16px;
    `}
`;

export const DropdownMenuCheckboxItem = styled(RadixDropdown.CheckboxItem)`
  all: unset;
  ${typographyV2.bodySm}
  padding: 8px 16px;
`;

export const Label = styled(RadixDropdown.Label)`
  color: var(--text-soft);
  padding: 2px 16px;
  text-transform: uppercase;
`;
