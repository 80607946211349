import { z } from 'zod';

const tagsSchema = z.object({
  tags: z.array(z.string()),
});

export const entities = {
  entity: { path: 'entity/v2/filtered' },
  searchEntities: {
    path: 'entity/v3/search/entities',
  },
  searchCurrencies: {
    path: 'entity/v3/search/currencies',
  },
  searchEconomies: {
    path: 'entity/v3/search/economies',
  },
  resolveFromCsv: {
    path: 'entity-resolver/v1/csv',
    schema: tagsSchema,
  },
  searchEntityAndEconomies: {
    path: 'entity/v3/search/entities-economy',
  },
  entityLookup: { path: `entity/v3/lookup` },
} as const;
