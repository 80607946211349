import { ScreenerFilter } from '@toggle/toggle';

export const addFiltersToActiveFilters = ({
  activeFilters,
  allFilters,
  newFilterKeys,
}: {
  activeFilters: ScreenerFilter[];
  allFilters: ScreenerFilter[];
  newFilterKeys: string[];
}) => {
  const initial = newFilterKeys.reduce<Record<string, true>>((res, key) => {
    res[key] = true;
    return res;
  }, {});
  const mapKeyToFilter = activeFilters.reduce((res, item) => {
    res[item.key] = true;
    return res;
  }, initial);

  return allFilters.reduce<ScreenerFilter[]>((res, item) => {
    if (mapKeyToFilter[item.key]) {
      res.push(item);
    }

    return res;
  }, []);
};
