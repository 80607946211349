import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledButton = styled(Button)<{
  $isActive?: boolean;
}>`
  color: ${props =>
    props.$isActive ? 'var(--text-success)' : 'var(--text-default)'};
  border-color: ${props =>
    props.$isActive ? 'var(--border-success)' : 'var(--border-default)'};
  svg {
    fill: ${props =>
      props.$isActive ? 'var(--icon-success)' : 'var(--icon-soft)'};
  }
  border-radius: 50%;
`;
