import { MappedEntity, PreliminaryPrice, SnakeMeta } from '@toggle/toggle';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';

import { LivePriceInfo } from '../../hooks/use-live-price';
import { globalPriceFormatter } from '../../utils/asset-price/assetPrice';
import { formatPriceDisplay } from './helpers/price-display-helpers';
import * as S from './PriceDisplayView.styles';

export interface PriceDisplayViewProps {
  entity: MappedEntity;
  suffix?: string;
  livePriceInfo?: LivePriceInfo;
  snakeMeta?: SnakeMeta;
  preliminaryPrice?: PreliminaryPrice;
  isChartHovered: boolean;
  t: TFunction;
  isCondensed?: boolean;
}

export const PriceDisplayView = ({
  entity,
  livePriceInfo,
  snakeMeta,
  suffix,
  preliminaryPrice,
  isChartHovered,
  t,
  isCondensed = false,
}: PriceDisplayViewProps) => {
  const priceFormatter = useCallback(
    (price: number) =>
      globalPriceFormatter({
        entity,
        price,
        priceSuffix: suffix ?? '',
        shouldShowCurrency: false,
      }),
    [suffix, entity]
  );

  if (
    (!livePriceInfo && snakeMeta?.last_value === undefined) ||
    !snakeMeta?.last_timestamp
  ) {
    return null;
  }

  const price = formatPriceDisplay({
    exchange: entity.exchange.code,
    livePriceInfo,
    preliminaryPrice,
    meta: snakeMeta,
    isHovered: isChartHovered,
    t,
  });

  const hasLivePrice = price.secondary?.price && price.secondary?.isLive;
  const isPrimaryPriceCondensed = isCondensed && hasLivePrice;

  return (
    <S.Container data-testid="price-display-view">
      {price.primary.price !== undefined && !isPrimaryPriceCondensed && (
        <S.StyledPriceDisplay
          price={priceFormatter(price.primary.price)}
          currency={entity.currency}
          priceChange={price.primary.priceChange}
          liveLabel={
            price.primary?.isLive ? t('analyze:analysis.livePrice') : undefined
          }
          timeNode={
            !price.primary.isLive && (
              <S.DateSpan>
                {price.primary.asOfDate
                  ? t('analyze:analysis.asOfDate', {
                      date: price.primary.asOfDate,
                    })
                  : t('analyze:analysis.asOfLastClose')}
              </S.DateSpan>
            )
          }
          showMarketIndicator={!isCondensed}
        />
      )}
      {!isChartHovered &&
        price.secondary &&
        price.secondary.price &&
        price.secondary.isLive && (
          <S.StyledPriceDisplay
            data-testid="secondary-price"
            price={priceFormatter(price.secondary.price)}
            currency={entity.currency}
            priceChange={price.secondary.priceChange}
            liveLabel={t('analyze:analysis.livePrice')}
            outsideHoursText={price.secondary.outsideHours}
            isPrimary={false}
            showMarketIndicator={!isCondensed}
          />
        )}
    </S.Container>
  );
};
