import { env } from '@toggle/helpers';

import { FeatureFlag } from '~/stores/use-flags/useFlags';

const hostUrl = new URL(document.URL).origin;

const apiBaseUrl = env(process.env.V2_API_BASE_URL, 'dev.rflx.co.uk/');

const supportRoot = 'https://support.reflexivity.com/';

export const config = {
  app: {
    version: env(process.env.V2_VERSION, ''),
    environment: env(process.env.V2_ENV_LEVEL, 'dev'),
    isLocalEnvironment: hostUrl === 'http://localhost:1234',
    featureFlags: env(process.env.V2_FEATURE_FLAGS, '')?.split(
      ','
    ) as FeatureFlag[],
  },
  release: {
    date: env(process.env.V2_RELEASE_DATE, new Date().toISOString()),
    number: env(process.env.V2_RELEASE_NUMBER, '1.1.0'),
  },
  api: {
    root: `https://api.${apiBaseUrl}`,
    wsRoot: `wss://ws.${apiBaseUrl}v2/prices`,
    wsDelayedRoot: `wss://ws.${apiBaseUrl}v1/delayed-prices`,
    wsNotifierRoot: `wss://ws.${apiBaseUrl}v2/notifier`,
    authRoot: `https://authn.${apiBaseUrl}`,
  },
  urls: {
    root: hostUrl,
    supportRoot,
    webinarLink: env(
      process.env.V2_WEBINAR_LINK,
      'https://us06web.zoom.us/webinar/register/WN_wXeIKzHpT1OvTSAHOziiHQ'
    ),
    socialMediaImageLink: `https://static.${apiBaseUrl}social-share-image.png`,
    supportNewTicket: `${supportRoot}hc/en-us/requests/new`,

    insightLink: 'https://www.youtube.com/watch?v=yKEaKMUFjHU',
    disclaimerGenAi:
      'https://reflexivity.com/resources/generative-ai-disclaimers-and-notices',
    helpImportWatchlist:
      'https://support.reflexivity.com/hc/en-us/articles/29753957516564-How-to-import-your-watchlist-from-Bloomberg',
  },
  ib: {
    website:
      'https://ndcdyn.interactivebrokers.com/sso/Login?action=TA_MARKET_DATA&loginType=1&serviceID1=1357&serviceID2=1064&serviceID3=1065',
    orders: env(
      process.env.V2_IB_ORDERS,
      'https://www.interactivebrokers.com/en/home.php'
    ),
    ibLogin: env(
      process.env.V2_IB_LOGIN,
      'https://www.interactivebrokers.co.uk/sso/Login?action=Settings&config=ResearchSubscriptions'
    ),
    linkDisclaimer: env(
      process.env.V2_IB_LINK_DISCLAIMER,
      'https://reflexivity.com/resources/direct-link-disclaimer-for-ib'
    ),
    manageSubscription: env(
      process.env.V2_IB_MANAGE_SUBSCRIPTION,
      'https://ndcdyn.interactivebrokers.com/sso/Login?RL=1&locale=en_US'
    ),
  },
  zendeskEmbedKey: env(
    process.env.V2_ZENDESK_EMBED_KEY,
    'bc557a7c-11a5-450a-a0d0-5a30d21e6288'
  ),
  zendeskSubdomain: env(
    process.env.V2_ZENDESK_SUBDOMAIN,
    'https://toggleglobal.zendesk.com/api/v2/requests'
  ),
  supportUrl: 'https://support.reflexivity.com/hc/en-us/requests/new',
  appStoreLink: env(
    process.env.V2_APP_STORE_LINK,
    'https://apps.apple.com/us/app/toggle-invest-with-confidence/id1537876211'
  ),
  stripePublicKey: env(
    process.env.V2_STRIPE_PUBLIC_KEY,
    'pk_test_51Mdw3pG1Cyr3tMwe60lnBGMRRRlj3ifDQ3wTTdVe2Lz7rIE8aQVg3ntM8wfhtGPyF431iUVl0Yo9k5pnUCdBUZWO00nRitX4J8'
  ),
  sentry: {
    dsn: 'https://8a5ac306d45e154ce0c548b57e788e81@o437249.ingest.us.sentry.io/4507052644368384',
  },
  appSessionCookieAuth: '__toggle_terminal_auth',
  supportEmail: 'support@reflexivity.com',
  partnershipsEmail: 'partnerships@reflexivity.com',
  companyDetails: {
    name: 'Knabble, Inc.',
    addressLine1: '460 Broadway',
    addressLine2: 'New York, NY 10036',
  },
  ga4Key: env(process.env.TERMINAL_GA4_KEY, 'G-G4NJPBEBK0'),
};
