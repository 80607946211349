import { formatPercentage } from '@toggle/helpers/src/utils/numbers/numbers';
import * as d3 from 'd3';
import React from 'react';

import { Bar } from '../bar/Bar';
import { BAR_HEIGHT, CHART_WIDTH, margin, ROW_HEIGHT } from '../constants';
import { HorizontalLabels } from '../horizontal-labels/HorizontalLabels';
import { MappedDominoV2 } from '../HorizontalBarChart';
import * as S from '../HorizontalBarChart.styles';

export interface ChartHeaderProps {
  headerData: MappedDominoV2[];
  headerTitle?: string;
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleBand<string>;
  prefix: string;
}

export const ChartHeader = ({
  headerData,
  headerTitle,
  xScale,
  yScale,
  prefix,
}: ChartHeaderProps) => {
  const HALF_BAR_HEIGHT = BAR_HEIGHT / 2;
  return (
    <g
      transform={`translate(0, ${margin.top})`}
      data-testid="header-chart-bars-root"
      width={CHART_WIDTH}
    >
      {headerTitle && (
        <S.LabelHeader x={margin.left} y={HALF_BAR_HEIGHT}>
          {headerTitle}
        </S.LabelHeader>
      )}

      {headerData.map((d, i) => {
        const extraSpace = i >= 3 ? ROW_HEIGHT : 0;
        const verticalPosition =
          (yScale(d.label) || i * ROW_HEIGHT) + extraSpace;
        return (
          <React.Fragment key={i}>
            <>
              {!!d.median && (
                <Bar
                  value={d.median}
                  xScale={xScale}
                  yPosition={verticalPosition}
                />
              )}
              {i === 1 && (
                <S.AxisLine
                  transform={`translate( 0, ${ROW_HEIGHT - BAR_HEIGHT})`}
                  x1={0}
                  x2={CHART_WIDTH}
                  strokeWidth={0.5}
                />
              )}
              <HorizontalLabels
                value={d.median}
                leftLabel={d.label}
                rightLabel={d.median ? formatPercentage(d.median) : ''}
                tooltipLabel={d.tooltipLabel}
                yPosition={verticalPosition}
                prefix={prefix}
              />
            </>
          </React.Fragment>
        );
      })}
    </g>
  );
};
