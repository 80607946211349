import {
  DropdownListItem,
  DropdownProps,
  Icon,
  Spinner,
} from '@toggle/design-system';
import React, { FC, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { useWatchlistActions } from '~/hooks/use-watchlist-actions/useWatchlistActions';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { Watchlist } from '~/stores/use-watchlist/watchlist-types';

import { CreateWatchlistModal } from '../create-watchlist-modal/CreateWatchlistModal';
import * as S from './WatchlistTrigger.styles';

interface WatchlistTriggerProps {
  tag: string;
  renderTrigger: (
    isInWatchlist: boolean,
    onCreateNewListClick?: () => void,
    hasNoWatchlist?: boolean
  ) => ReactElement;
  dropdownProps?: Partial<DropdownProps<Watchlist>>;
}

export function WatchlistTrigger({
  tag,
  renderTrigger,
  dropdownProps,
}: WatchlistTriggerProps) {
  const { t } = useTranslation('widget');
  const [displayCreateModal, setDisplayCreateModal] = useState(false);
  const [shouldCloseOnOutsideClick, setShouldCloseOnOutsideClick] =
    useState(true);

  const { createNewList, watchlistErrorText, clearError, createListLoading } =
    useWatchlistActions();
  const { lists, listIdsWithTag, addItemToList, removeItemFromList } =
    // passing equity fn has been deprecated
    // but it's required here to rerender this component only when
    // either list is created or tag is added/removed
    useStoreWithEqualityFn(
      useWatchlist,
      state => ({
        lists: state.lists,
        addItemToList: state.addItemToList,
        removeItemFromList: state.removeItemFromList,
        listIdsWithTag: state.listIdsByTag.get(tag),
      }),
      (current, prev) =>
        current.lists.length === prev.lists.length &&
        current.listIdsWithTag === prev.listIdsWithTag
    );

  const closeModal = () => {
    setShouldCloseOnOutsideClick(true);
    setDisplayCreateModal(false);
    clearError();
  };

  const onCreateNewListClick = () => {
    setShouldCloseOnOutsideClick(false);
    setDisplayCreateModal(true);
  };

  const submitNewList = async (name: string) => {
    await createNewList(name, [tag]);
    closeModal();
  };

  const toggleWatchlist = async (watchlistId: string, hasTag: boolean) => {
    if (hasTag) {
      await removeItemFromList(watchlistId, tag);
    } else {
      await addItemToList(watchlistId, tag);
    }
  };

  const includedInWatchlistIds = listIdsWithTag || [];
  const isInAnyWatchlist = !!includedInWatchlistIds.length;
  const hasNoWatchlist = useMemo(() => !lists.length, [lists.length]);
  return (
    <div>
      <CreateWatchlistModal
        isOpen={displayCreateModal}
        isLoading={createListLoading}
        onClose={closeModal}
        onSubmit={submitNewList}
        watchlistErrorText={watchlistErrorText}
        clearError={clearError}
      />
      <S.WatchlistDropdown<FC<DropdownProps<Watchlist>>>
        beforeInListNode={
          createListLoading ? (
            <S.LoadingWrapper>
              <Spinner size="small" />
            </S.LoadingWrapper>
          ) : (
            <>
              <DropdownListItem
                data-testid="create-new-list-btn"
                onClick={onCreateNewListClick}
                label={t('widget:watchlistTrigger.createListBtnLabel')}
                leadingIcon={<Icon iconName={'Add'} size={16} />}
                isActive={false}
                isFocused={false}
              />
              {lists.length > 0 && <S.DropdownDivider />}
            </>
          )
        }
        dropdownItems={lists}
        shouldCloseOnSelect={false}
        shouldCloseOnOutsideClick={shouldCloseOnOutsideClick}
        shouldShowItems={false}
        renderDropdownItem={item => {
          const hasTag = includedInWatchlistIds.includes(item.id);
          return (
            <S.ItemRow key={item.id} data-testid="dropdown-item">
              <S.DropdownCheckbox
                data-testid="column-checkbox"
                name={item.id}
                label={item.name}
                checked={hasTag}
                onChange={() => toggleWatchlist(item.id, hasTag)}
              />
            </S.ItemRow>
          );
        }}
        inPortal
        {...dropdownProps}
      >
        {renderTrigger?.(
          isInAnyWatchlist,
          onCreateNewListClick,
          hasNoWatchlist
        )}
      </S.WatchlistDropdown>
    </div>
  );
}
