import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Label = styled.p`
  color: var(--text-default);
`;
export const Price = styled.p<{ $isBullish: boolean }>`
  font-family: var(--font-roboto-mono);
  color: ${props =>
    props.$isBullish ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};
`;

export const OHLCPriceRoot = styled.div`
  margin-left: 8px;
  display: flex;
  gap: 8px;
  ${typographyV2.bodySm}
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 8px;
`;
