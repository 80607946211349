import React, { RefObject } from 'react';

import { Icon } from '../icon/Icon';
import { Tab } from '../tab-lines/TabLines';
import * as S from './TabPanel.styles';

export interface TabPanelProps<T> {
  tabs: Tab<T>[];
  activeTab: T;
  isDisabled?: boolean;
  onChange: (index: number, tabKey: T) => void;
  tabListRef?: RefObject<HTMLDivElement | null>;
}

export const TabPanel = <T extends string>({
  tabs,
  activeTab,
  onChange,
  tabListRef,
  isDisabled = false,
  ...rest
}: TabPanelProps<T>) => {
  const currentPanel = tabs.find(i => i.tabKey === activeTab)?.renderPanel;

  return (
    <S.TabContainer data-testid="tab-panel" {...rest}>
      <S.Tablist role="tablist" ref={tabListRef}>
        {tabs.map(({ tabKey, tabLabel, iconName, imgNode }, index) => (
          <S.Tab
            key={tabKey}
            data-testid={`tab-panel-${tabKey}`}
            $active={activeTab === tabKey}
            $isDisabled={isDisabled}
            role="tab"
            aria-selected={activeTab === tabKey}
            onClick={() => onChange(index, tabKey)}
          >
            {imgNode && <S.ImageWrapper>{imgNode}</S.ImageWrapper>}
            {iconName && <Icon size={20} iconName={iconName} />}
            <S.TabLabel>{tabLabel}</S.TabLabel>
          </S.Tab>
        ))}
      </S.Tablist>

      <S.TabPanel>{currentPanel}</S.TabPanel>
    </S.TabContainer>
  );
};

TabPanel.Styled = S;
