import React, { ReactNode } from 'react';

import * as S from './TextBlock.styles';

export interface TextBlockProps {
  title?: string;
  text?: ReactNode;
  helpText?: string;
  children?: ReactNode;
}

export const TextBlock = ({
  title,
  text,
  helpText,
  children,
  ...rest
}: TextBlockProps) => (
  <S.TextBlockRoot {...rest}>
    <S.Title>{title}</S.Title>
    {text && <S.Text>{text}</S.Text>}
    {helpText && <S.HelpText>{helpText}</S.HelpText>}
    {children}
  </S.TextBlockRoot>
);
