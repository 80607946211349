import styled, { css } from 'styled-components';

import { values } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const TabContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TabPanel = styled.div`
  width: 100%;
  height: 100%;
`;

export const Tablist = styled.div`
  width: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-top-left-radius: ${values.r2};
  border-top-right-radius: ${values.r2};
`;

export const Tab = styled.button<{
  $active: boolean;
  $isDisabled: boolean;
}>`
  ${typographyV2.bodySm};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  border: none;
  border-top-left-radius: ${values.r2};
  border-top-right-radius: ${values.r2};
  background-color: var(--background-soft);
  color: var(--text-default);
  cursor: pointer;

  svg {
    fill: var(--icon-default);
  }

  &:hover {
    background-color: var(--surface-hover);
  }

  &:focus-visible {
    background-color: var(--surface-hover);
    outline: 1px solid var(--border-focus);
  }

  &:not(:first-child) {
    overflow: hidden;
  }

  ${({ $active }) =>
    $active &&
    css`
      &,
      &:hover,
      &:focus-visible {
        background-color: var(--surface-default);
        color: var(--icon-primary);
        svg {
          fill: var(--icon-on-primary);
        }
      }
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background: var(--background-soft);
      color: var(--text-disabled);
      pointer-events: none;
    `};
`;

export const TabLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ImageWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;
