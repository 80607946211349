import React from 'react';
import { I18nextProvider } from 'react-i18next';

import { i18n } from '~/utils/i18n/i18n';

import { ChartArticleInsightCard } from '../chart-article-insight-card/ChartArticleInsightCard';
import { InsightDetails } from '../insights.types';

export interface ChartInsightCardProps {
  insightDetails: InsightDetails;
  onOpenDetails?: () => void;
}

export const ChartInsightCard = ({
  insightDetails,
  onOpenDetails,
}: ChartInsightCardProps) => {
  if (insightDetails.type === 'article') {
    return (
      <I18nextProvider i18n={i18n}>
        <ChartArticleInsightCard
          data={insightDetails.item}
          onOpenDetails={onOpenDetails}
        />
      </I18nextProvider>
    );
  }

  return null;
};
