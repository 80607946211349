import { NewsFilter, NewsOption } from '~/services/news/news.types';

import { WATCHLIST_FILTER_KEY } from '../news-filters-utils/news-filters-utils';

export const getUniqueEntities = (options: NewsOption[]): string[] => [
  ...new Set(
    options.reduce<string[]>((entities, option) => {
      if (option.isChecked && option.entities) {
        entities.push(...option.entities);
      }

      return entities;
    }, [])
  ),
];

export const createNewsApiPayload = (
  filters: NewsFilter[]
): Record<string, string[]> => {
  return filters.reduce((payload, filter) => {
    const checkedOptions = filter.options.filter(option => option.isChecked);

    if (checkedOptions.length > 0) {
      return {
        ...payload,
        ...(filter.key === WATCHLIST_FILTER_KEY
          ? { entities: getUniqueEntities(filter.options) }
          : { [filter.key]: checkedOptions.map(option => option.key) }),
      };
    }

    return payload;
  }, {});
};
