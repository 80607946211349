import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { earnings, EarningsForEntityResults } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const QUERY_KEY_EARNINGS = 'QUERY_KEY_EARNINGS';

export const useEarningsData = (entityTag: string) => {
  const { path } = earnings.getEarningsForEntity;
  return useQuery({
    queryKey: [QUERY_KEY_EARNINGS, entityTag],
    queryFn: ({ signal }) =>
      wretchRequest<EarningsForEntityResults>(path(entityTag), {
        signal,
      }),
    staleTime: getTimeTillEndOfDayMs(),
    select: data => data?.earnings ?? [],
  });
};
