import {
  DropdownEntry,
  DropdownMenu,
  DropdownRadioGroup,
  Icon,
} from '@toggle/design-system';
import { ChildrenFilter, ScreenerFilterOption } from '@toggle/toggle';
import React from 'react';

import * as S from './ChildFilterOptionDropdown.styles';

export const ChildFilterOptionDropdown = ({
  childFilter,
  selectedChildOption,
  setSelectedChildOption,
}: {
  childFilter: ChildrenFilter;
  selectedChildOption: ScreenerFilterOption;
  setSelectedChildOption: (option: ScreenerFilterOption) => void;
}) => {
  const dropdownItems: DropdownEntry[] = childFilter.options.map(opt => ({
    label: opt.description ?? opt.name,
    key: opt.key,
    onClick: () => setSelectedChildOption(opt),
  }));

  return (
    <DropdownMenu
      triggerNode={
        <S.ChildFilterDropdownTrigger>
          {`(${selectedChildOption.name})`}
          <Icon
            iconName="ChevronBoldDown"
            size={12}
            fillColor="var(--icon-primary)"
            data-testid="child-filter-dropdown"
          />
        </S.ChildFilterDropdownTrigger>
      }
    >
      <DropdownRadioGroup
        label={childFilter.name}
        items={dropdownItems}
        selectedKey={selectedChildOption.key}
      />
    </DropdownMenu>
  );
};
