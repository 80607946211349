import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const SearchSuggestionsRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const CategoryTitle = styled.h2`
  ${typographyV2.uiXs};
  color: var(--text-soft);
  text-transform: uppercase;
  flex-basis: 100%;
  margin-top: 32px;
`;

export const KeywordButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: calc(50% - 8px);
  padding: 12px 16px;
  border: none;
  border-radius: 10px;
  background: var(--surface-default);
  cursor: pointer;

  &:hover {
    background: var(--surface-hover);
  }

  &:focus-visible {
    outline: 1px solid var(--border-focus);
  }
`;

export const Label = styled.span`
  ${typographyV2.bodyBase};
  color: var(--text-default);
`;
