import { ModalProps, Toggle } from '@toggle/design-system';
import React, { FC } from 'react';

import * as S from './TogglesModal.styles';

export interface ToggleItem {
  label: string;
  id: string;
}

export interface TogglesModalProps extends Partial<ModalProps> {
  items: ToggleItem[];
  title: ModalProps['title'];
  checkedItems: ToggleItem['id'][];
  onClose: () => void;
  onChange: (item: ToggleItem, checked: boolean) => void;
}

export const TogglesModal: FC<TogglesModalProps> = ({
  title,
  items,
  checkedItems,
  onClose,
  onChange,
  ...rest
}) => {
  return (
    <S.StyledModal isOpen title={title} onClose={onClose} {...rest}>
      <S.Container>
        {items.map(item => (
          <S.Row key={item.id}>
            <span>{item.label}</span>
            <Toggle
              size="medium"
              isChecked={checkedItems.includes(item.id)}
              onToggle={checked => onChange(item, checked)}
            />
          </S.Row>
        ))}
      </S.Container>
    </S.StyledModal>
  );
};
