import { useWindowResize } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { useMemo, useRef, useState } from 'react';

import { StyledComponent } from '~/common/styled-component';

import { MultiLineChartAxes } from './components/multi-line-chart-axes/MultiLineChartAxes';
import { MultiLineChartLegend } from './components/multi-line-chart-legend/MultiLineChartLegend';
import { MultiLineChartLinePlot } from './components/multi-line-chart-line-plot/MultiLineChartLinePlot';
import { MultiLineChartOverlay } from './components/multi-line-chart-overlay/MultiLineChartOverlay';
import { MultiLineChartTooltip } from './components/multi-line-chart-tooltip/MultiLineChartTooltip';
import * as S from './MultiLineChart.styles';
import {
  MultiLineChartLine,
  TooltipData,
  TooltipSort,
  TsPoint,
} from './MultiLineChart.types';

const MultiLineChartFormatters = {
  percent2f: (v: number) => `${d3.format('.2f')(v)}%`,
  percent: (v: number) => `${d3.format('.0f')(v)}%`,
  default: (v: number) => v.toString(),
};

export type MultiLineChartProps = {
  lines: MultiLineChartLine[];
  hasTooltip?: boolean;
  yAxisFormatType?: keyof typeof MultiLineChartFormatters;
  tooltipFormatType?: keyof typeof MultiLineChartFormatters;
  tooltipSort?: TooltipSort;
};

export const MultiLineChart: StyledComponent<MultiLineChartProps, typeof S> = ({
  lines,
  hasTooltip = false,
  yAxisFormatType = 'default',
  tooltipFormatType = 'default',
  tooltipSort,
}) => {
  const containerRef = useRef<HTMLElement>(null);
  const { width: w, height: h } = useWindowResize({ targetRef: containerRef });
  const [tooltipData, setTooltipData] = useState<TooltipData>();

  const MARGIN = { top: 10, right: 40, bottom: 40, left: 0 };
  const chartWidth = w - MARGIN.right - MARGIN.left;
  const chartHeight = h - MARGIN.top - MARGIN.bottom;

  const mergedData = lines.reduce((acc, line) => {
    return acc.concat(line.data);
  }, [] as TsPoint[]);
  const uniqueDates = Array.from(new Set(mergedData.map(point => point.date)));

  const [minY = 0, maxY = 0] = useMemo(
    () => d3.extent(mergedData.map(p => p.value)),
    [mergedData]
  );
  const [minX = new Date(), maxX = new Date()] = useMemo(
    () => d3.extent(mergedData.map(p => p.date)),
    [mergedData]
  );

  const xScale = useMemo(() => {
    return d3.scaleTime().domain([minX, maxX]).range([0, chartWidth]);
  }, [minX, maxX, chartWidth]);

  // ensures data points are not at the very edges of the chart
  const yPadding = 0.05 * Math.max(Math.abs(maxY), Math.abs(minY));
  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([minY - yPadding, maxY + yPadding])
      .range([chartHeight, 0]);
  }, [minY, maxY, h]);

  return (
    <S.MultiLineChartRoot>
      {tooltipData && (
        <MultiLineChartTooltip
          lines={lines}
          tooltipData={tooltipData}
          formatter={MultiLineChartFormatters[tooltipFormatType]}
          sortBy={tooltipSort}
        />
      )}
      <S.MultiLineChartContainer
        ref={containerRef as React.RefObject<HTMLDivElement>}
      >
        <S.Plot data-testid="multi-line-chart">
          <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
            <MultiLineChartAxes
              xScale={xScale}
              yScale={yScale}
              yAxisFormatter={MultiLineChartFormatters[yAxisFormatType]}
            />
            {tooltipData && (
              <S.TooltipLine
                data-testid="multi-line-chart-tooltip-line"
                x1={tooltipData.xPos}
                x2={tooltipData.xPos}
                y1={0}
                y2={chartHeight}
              />
            )}
            {lines.map(line => (
              <MultiLineChartLinePlot
                key={line.label}
                xScale={xScale}
                yScale={yScale}
                data={line.data}
                color={line.color}
                tooltipDate={tooltipData?.date}
              />
            ))}
            {hasTooltip && (
              <MultiLineChartOverlay
                width={chartWidth}
                height={chartHeight}
                xScale={xScale}
                dates={uniqueDates}
                setTooltipData={setTooltipData}
              />
            )}
          </g>
        </S.Plot>
      </S.MultiLineChartContainer>
      {hasTooltip && <MultiLineChartLegend lines={lines} />}
    </S.MultiLineChartRoot>
  );
};

MultiLineChart.Styled = S;
