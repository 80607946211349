import { borderRadius, ErrorMessage } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const ErrorStateRoot = styled.div<{ $showBorder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ${props =>
    props.$showBorder &&
    css`
      border: 1px solid var(--border-soft);
      ${borderRadius.r2};
    `}
`;

export const EmailLink = styled.a`
  color: var(--text-link);
`;

export const ErrorSubtitle = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  width: auto;
`;
