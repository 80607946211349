import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const SCROLLING_PADDING = 3000;

export const NewsGroupRoot = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child hr {
    display: none;
  }

  &:last-child {
    padding-bottom: ${SCROLLING_PADDING}px;
  }
`;

export const PillWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: sticky;
  top: 0px;
  z-index: 2;
  margin-bottom: 20px;
  justify-content: space-between;
  background: linear-gradient(
    rgba(var(--background-default-rgb), 1) 0%,
    rgba(var(--background-default-rgb), 0) 100%
  );
`;

export const Timestamp = styled.span`
  ${typographyV2.bodySm};
  padding: 0 8px;
  width: max-content;
  color: var(--text-disabled);
`;

export const Divider = styled.hr<{ $isPillSticky: boolean }>`
  display: block;
  width: 100%;
  height: 1px;
  border: 0.5px solid var(--border-soft);

  ${({ $isPillSticky }) => {
    return (
      $isPillSticky &&
      css`
        display: none;
      `
    );
  }}
`;

export const ListsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  ${borderRadius.r2};
  width: 202px;
  height: 32px;
  background-color: #0d2b30;
`;

export const PoweredBy = styled.p`
  color: var(--base--0);
`;

export const StyledImg = styled.img`
  width: 75px;
  height: 9px;
`;
