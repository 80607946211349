import React from 'react';

import { NewsThumbnailSkeleton } from '~/components/news-thumbnail-skeleton/NewsThumbnailSkeleton';

export const NewsLoadingState = () => (
  <>
    <NewsThumbnailSkeleton />
    <NewsThumbnailSkeleton />
    <NewsThumbnailSkeleton />
  </>
);
