import { formatPriceWithCurrency } from '@toggle/helpers';
import { Earning, EarningsScenarioResponse } from '@toggle/toggle';

import { EarningState, Expectation } from './types';

export const getEarningState = (earning?: Earning) => {
  if (typeof earning?.surprise === 'undefined') {
    return undefined;
  }

  if (earning.surprise === 0) {
    return EarningState.Meet;
  } else if (earning.surprise > 0) {
    return EarningState.Beat;
  } else {
    return EarningState.Miss;
  }
};

export const formatEarning = (earning?: Earning, currency?: string) => {
  const value = earning?.surprise ?? 0;

  return formatPriceWithCurrency(value, currency);
};

export const getScenario = (
  earningsData: EarningsScenarioResponse,
  expectation: Expectation
) => {
  if (earningsData.reported) {
    return earningsData.scenarios.actual;
  }

  return earningsData.scenarios[expectation];
};
