import { RowSelectionState } from '@tanstack/react-table';
import { DocumentEntityItem } from '@toggle/toggle';
import { create } from '~/stores/create-store/createStore';

import { getSelectedRowKeys } from '../earnings/utils/utils';

type MappedDocumentEntityItem = DocumentEntityItem & { key: string };

interface DocumentWidgetStore {
  initStore: (data: MappedDocumentEntityItem[]) => void;
  documentsData: MappedDocumentEntityItem[];
  rowSelection: RowSelectionState;
  updateRowSelection: (rowId: string, selected: boolean) => void;
  getIsAllRowsSelected: () => boolean;
  getIsSomeRowsSelected: () => boolean;
  toggleAllRowsSelection: (selected: boolean) => void;
  getIsRowSelected: (rowId: string) => boolean;
}

const getSelectedRowFromDocumentsData = (data: MappedDocumentEntityItem[]) =>
  data.reduce(
    (
      selectedRows: Record<string, boolean>,
      earning: MappedDocumentEntityItem
    ) => {
      selectedRows[earning.id] = true;
      return selectedRows;
    },
    {}
  );

export const useDocumentWidgetStore = create<DocumentWidgetStore>(
  (set, get) => ({
    documentsData: [],
    rowSelection: {},
    initStore: data => {
      const selectedRows = getSelectedRowFromDocumentsData(data);
      set({
        documentsData: data,
        rowSelection: selectedRows,
      });
    },
    getIsRowSelected: rowId => get().rowSelection[rowId] ?? false,
    updateRowSelection: (rowId, selected) => {
      const { rowSelection } = get();
      const updatedRowSelection = { ...rowSelection };
      updatedRowSelection[rowId] = selected;
      set({ rowSelection: updatedRowSelection });
    },
    toggleAllRowsSelection: selected => {
      const { updateRowSelection, documentsData } = get();

      documentsData.forEach(doc => {
        updateRowSelection(doc.id, selected);
      });
    },
    getIsAllRowsSelected: () => {
      const { rowSelection, documentsData } = get();
      const selectedRowKeys = getSelectedRowKeys(rowSelection);
      return documentsData.length === selectedRowKeys.length;
    },
    getIsSomeRowsSelected: () => {
      const { rowSelection } = get();
      const selectedRowKeys = getSelectedRowKeys(rowSelection);
      return selectedRowKeys.length > 0;
    },
  })
);
