import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  financialEvents,
  KeyDevsObjectWithEntity,
  KeyDevsResponse,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

import { fetchEntities } from '../use-screener-events/utils';
import { groupEntriesByDate } from '../utils/grouping-utils';

export const KEY_DEV_TYPES_EXCEPT_EARNINGS = [
  'added_to_index',
  'analyst_investor_day',
  'announcement_operating_results',
  'annual_general_meeting',
  'business_expansions',
  'business_operations_contraction',
  'buyback_announcements',
  'buyback_cancellations',
  'client_announcements',
  'corporate_guidance_issuance',
  'corporate_guidance_lowered',
  'corporate_guidance_raised',
  'corporate_guidance_unusual_events',
  'debt_defaults',
  'delayed_earnings_announcements',
  'delayed_sec_filings',
  'dividend_cancellation',
  'dividend_decreases',
  'dividend_increases',
  'dividend_initiation',
  'dropped_from_index',
  'end_lock_up_period',
  'exchange_changes',
  'executive_changes_ceo',
  'executive_changes_cfo',
  'executive_changes_other',
  'investor_activism_activist_communication',
  'investor_activism_agreement_related',
  'investor_activism_nomination_related',
  'investor_activism_proposal_related',
  'investor_activism_proxy_voting_related',
  'labor_related_announcements',
  'lawsuits_legal_issues',
  'ma_announcement',
  'ma_calls',
  'ma_cancellation',
  'ma_rumors',
  'ma_transaction_closings',
  'product_related_announcements',
  'regulatory_agency_inquiries',
  'regulatory_authority_compliance',
  'regulatory_authority_enforcement_actions',
  'regulatory_authority_regulations',
  'restatements_of_operating_results',
  'secondary_equity_offerings',
  'seeking_acquisitions_investments',
  'seeking_financing_partners',
  'seeking_sell_divest',
  'shareholder_analyst_calls',
  'special_dividend_announced',
  'spin_off_split_off',
  'stock_splits_significant_stock_dividends',
  'strategic_alliances',
  'investor_activism_financing_option_from_activist',
  'ipos',
];

const QUERY_KEY_COMPANY_EVENTS = 'QUERY_KEY_COMPANY_EVENTS';
const PAGE_SIZE = 2000;

export interface UseCompanyEventsProps {
  filters: {
    types: string[];
    start_date: string | undefined;
    end_date: string | undefined;
  }[];
  sorting?: {
    desc: boolean;
    id: string;
  }[];
  watchlists?: {
    all: boolean;
  };
  entriesPerDateLimit?: number;
}

export const useCompanyEvents = ({
  filters,
  watchlists,
  sorting,
  entriesPerDateLimit,
}: UseCompanyEventsProps) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [QUERY_KEY_COMPANY_EVENTS, filters, sorting, watchlists],
    queryFn: async ({ signal }) => {
      const { path, schema } = financialEvents.postKeyDevs;
      const companyEventsResponse = await wretchRequest<KeyDevsResponse>(path, {
        method: 'post',
        schema,
        body: {
          filters,
          watchlists,
          page: {
            number: 1,
            size: PAGE_SIZE,
          },
          ...(sorting?.length && {
            sort_by: [
              {
                field: sorting[0].id,
                desc: sorting[0].desc,
              },
            ],
          }),
        },
        signal,
      });
      const { key_devs: events } = companyEventsResponse;

      const groupedCompanyEventsData =
        groupEntriesByDate<KeyDevsObjectWithEntity>(
          events,
          'effective_date',
          entriesPerDateLimit
        );

      const foundEntityTags = Object.values(groupedCompanyEventsData)
        .flat()
        .map(event => event.entity_tag);

      const entities = await fetchEntities(queryClient, foundEntityTags);
      const entitiesMap = new Map();
      const companyEvents = events.reduce((res, event) => {
        const entity =
          entitiesMap.get(event.entity_tag) ??
          entities.find(e => e.tag === event.entity_tag);

        if (entity) {
          entitiesMap.set(entity.tag, entity);
          res.push({
            ...event,
            entity,
          });
        } else {
          res.push(event);
        }

        return res;
      }, [] as KeyDevsObjectWithEntity[]);

      return groupEntriesByDate<KeyDevsObjectWithEntity>(
        companyEvents,
        'effective_date'
      );
    },
  });
};
