import { Entity, SnakeMeta } from '@toggle/toggle';
import { useEffect, useState } from 'react';

import { Theme } from '~/components/search-modal/utils';
import {
  AO_WIDGET_RECENTLY_OPENED_ENTITIES,
  CHART_WIDGET_RECENTLY_OPENED_ENTITIES,
  RECENTLY_OPENED_THEMES_OR_ENTITIES,
  useRemoteStorage,
} from '~/hooks/use-remote-storage';

type RecentChartItem = {
  entity: Entity;
  snakeMeta: SnakeMeta;
};

export const useRecentList = () => {
  const [recentChartList, setRecentChartList] = useState<RecentChartItem[]>([]);

  const {
    recentlyOpenedChartEntities,
    recentlyOpenedAOEntities,
    recentlyOpenedThemesOrEntities,
    storeItems,
  } = useRemoteStorage(({ items, storeItems }) => ({
    recentlyOpenedChartEntities:
      (items?.[CHART_WIDGET_RECENTLY_OPENED_ENTITIES] as RecentChartItem[]) ||
      [],
    recentlyOpenedAOEntities:
      (items?.[AO_WIDGET_RECENTLY_OPENED_ENTITIES] as Entity[]) || [],
    recentlyOpenedThemesOrEntities:
      (items?.[RECENTLY_OPENED_THEMES_OR_ENTITIES] as (Entity | Theme)[]) || [],
    storeItems,
  }));

  useEffect(() => {
    if (recentlyOpenedChartEntities) {
      //Remove legacy items
      const items = recentlyOpenedChartEntities.filter(
        r => r.entity && r.snakeMeta
      );
      setRecentChartList(items);
    }
  }, [recentlyOpenedChartEntities.length]);

  const isEntity = (item: Entity | Theme): item is Entity => 'tag' in item;

  const updateRecentlyOpenedThemesOrEntitiesList = (
    itemToAdd: Entity | Theme
  ) => {
    const isEntityItem = isEntity(itemToAdd);
    const filteredCombinedRecent = recentlyOpenedThemesOrEntities.filter(
      existingItem => {
        const existingItemIsEntity = isEntity(existingItem);

        // Both items are entities; filter out if default_snake matches
        if (isEntityItem && existingItemIsEntity) {
          return itemToAdd.default_snake !== existingItem.default_snake;
        }

        // Both items are themes; filter out if IDs match
        if (!isEntityItem && !existingItemIsEntity) {
          return itemToAdd.id !== existingItem.id;
        }

        // Different types; keep the item
        return true;
      }
    );
    return [itemToAdd, ...filteredCombinedRecent.slice(0, 9)];
  };

  const addAssetToRecent = async ({
    entity,
    snakeMeta,
    type,
  }: {
    entity: Entity;
    snakeMeta?: SnakeMeta;
    type: string;
  }) => {
    let nextRecentList;
    let nextCombinedRecentList;

    if (type === CHART_WIDGET_RECENTLY_OPENED_ENTITIES) {
      const filteredRecent = recentChartList.filter(
        item => item.entity?.default_snake !== entity.default_snake
      );
      nextRecentList = [
        { entity, snakeMeta },
        ...filteredRecent.slice(0, 4),
      ] as RecentChartItem[];

      setRecentChartList(nextRecentList);
    } else {
      const filteredRecent = recentlyOpenedAOEntities.filter(
        item => item && item?.default_snake !== entity.default_snake
      );
      nextRecentList = [entity, ...filteredRecent.slice(0, 4)];

      nextCombinedRecentList = updateRecentlyOpenedThemesOrEntitiesList(entity);
    }

    await storeItems({
      [type]: nextRecentList,
      [RECENTLY_OPENED_THEMES_OR_ENTITIES]: nextCombinedRecentList,
    });
  };

  const addThemeToRecent = async (theme: Theme) => {
    await storeItems({
      [RECENTLY_OPENED_THEMES_OR_ENTITIES]:
        updateRecentlyOpenedThemesOrEntitiesList(theme),
    });
  };

  return {
    addAssetToRecent,
    recentlyOpenedAOEntities,
    recentChartList,
    recentlyOpenedThemesOrEntities,
    addThemeToRecent,
  };
};
