import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import type { BadgeColor, BadgeSize, BadgeStyle } from './Badge';

export const BadgeRoot = styled.div<{ size: BadgeSize }>`
  display: inline-flex;
  align-items: center;
  box-sizing: content-box;
  ${props => (props.size === 'medium' ? typographyV2.uiSm : typographyV2.uiXs)};
`;

/* NUMERICAL BADGE */
export const BadgeNumerical = styled.p<{ size: BadgeSize }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-on-critical);
  background-color: var(--action-critical-default);
  border-radius: 20px;

  ${props =>
    props.size === 'small' &&
    css`
      height: 16px;
      min-width: 16px;
      padding: 0 5px;
    `};

  ${props =>
    props.size === 'medium' &&
    css`
      height: 20px;
      min-width: 20px;
      padding: 2px 7px;
    `};
`;

/* TEXTUAL BADGE */
export const BadgeTextual = styled.div<{
  size: BadgeSize;
  color: BadgeColor;
  $style: BadgeStyle;
}>`
  ${borderRadius.r2};
  display: inline-flex;
  padding: 4px 8px;
  gap: 4px;
  align-items: center;
  box-sizing: border-box;

  ${({ color, $style }) => setTextualStyles(color, $style)};

  ${props =>
    props.size === 'small' &&
    css`
      ${borderRadius.r1};
      ${typographyV2.uiXs};
      padding: 2px 4px;
    `};
`;

const setTextualStyles = (color: BadgeColor, style: BadgeStyle) => {
  const { iconColor, fontColor, bgColor, borderColor } = colors[color][style];

  switch (style) {
    case 'solid':
      return css`
        color: ${fontColor};
        background-color: ${bgColor};
        svg {
          fill: ${iconColor};
        }
      `;

    case 'outline':
      return css`
        color: ${fontColor};
        border: 1px solid ${borderColor};
        svg {
          fill: ${iconColor};
        }
      `;
    default:
      return '';
  }
};

type Colors = {
  [color in BadgeColor]: {
    [style in BadgeStyle]: {
      iconColor: string;
      fontColor: string;
      bgColor?: string;
      borderColor?: string;
    };
  };
};

const colors: Colors = {
  neutral: {
    solid: {
      iconColor: 'var(--icon-default)',
      fontColor: 'var(--text-default)',
      bgColor: 'var(--surface-neutral);',
    },

    outline: {
      iconColor: 'var(--icon-default)',
      fontColor: 'var(--text-default)',
      borderColor: 'var(--border-default)',
    },
  },

  primary: {
    solid: {
      iconColor: 'var(--icon-on-primary)',
      fontColor: 'var(--text-on-primary)',
      bgColor: 'var(--surface-primary)',
    },

    outline: {
      iconColor: 'var(--icon-primary)',
      fontColor: 'var(--text-primary)',
      borderColor: 'var(--border-primary)',
    },
  },

  success: {
    solid: {
      iconColor: 'var(--icon-success)',
      fontColor: 'var(--text-success)',
      bgColor: 'var(--surface-success)',
    },

    outline: {
      iconColor: 'var(--icon-success)',
      fontColor: 'var(--text-success)',
      borderColor: 'var(--border-success)',
    },
  },

  warning: {
    solid: {
      iconColor: 'var(--icon-warning)',
      fontColor: 'var(--text-warning)',
      bgColor: 'var(--surface-warning)',
    },

    outline: {
      iconColor: 'var(--icon-warning)',
      fontColor: 'var(--text-warning)',
      borderColor: 'var(--border-warning)',
    },
  },

  error: {
    solid: {
      iconColor: 'var(--icon-critical)',
      fontColor: 'var(--text-critical)',
      bgColor: 'var(--surface-critical)',
    },

    outline: {
      iconColor: 'var(--icon-critical)',
      fontColor: 'var(--text-critical)',
      borderColor: 'var(--border-critical)',
    },
  },
};
