import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ConditionSearch.styles';

export interface ConditionSearchProps {
  label?: string;
  children: ReactNode;
  dataTestId: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConditionSearch = ({
  label,
  children,
  dataTestId,
  onChange,
}: ConditionSearchProps) => {
  const { t } = useTranslation('scenario');
  return (
    <S.DropdownMenuRoot data-testid={dataTestId}>
      <S.Trigger $active={false}>
        <S.TriggerLabel>{label}</S.TriggerLabel>
        <S.TriggerArrow iconName="ChevronLightDown" size={12} />
      </S.Trigger>
      <RadixDropdownMenu.Portal>
        <S.DropdownMenuContent align="start" sideOffset={5} side="bottom">
          <S.StyledFilter
            placeholder={t('scenario:search')}
            onChange={onChange}
          />
          <S.ScrollableList>{children}</S.ScrollableList>
        </S.DropdownMenuContent>
      </RadixDropdownMenu.Portal>
    </S.DropdownMenuRoot>
  );
};
