import { ScreenerFilterOption } from '@toggle/toggle';

import {
  FilterGroups,
  FilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { createFilterId } from '~/views/screener/use-screener-store/filter-utils';

import { ActiveFilterOptions } from '../filters/Filters';

export interface FilterOption extends ScreenerFilterOption {
  childName?: string;
  parentName?: string;
}

interface UpdateFilterOptionProps extends ToggleFilterProps {
  existingFilters: ActiveFilterOptions[];
  defaultChildFilterOptions: FilterOption[];
}

export type FilterUseType = 'multi-use' | 'single-use';

//selected option should always be at 0 index, children are at 1+ index
export const updateFilterOptions = ({
  multiselect,
  existingFilters,
  filterKey,
  option,
  filterId = filterKey,
  defaultChildFilterOptions,
  dynamic,
  childrenOptions,
}: UpdateFilterOptionProps): ActiveFilterOptions[] => {
  const existingFilter = existingFilters?.find(f => f.id === filterId);

  const updateOptions = (existingOptions: FilterOption[]) => {
    if (multiselect) {
      return [...existingOptions, option];
    }

    if (option.childName) {
      return existingOptions.reduce((res, o) => {
        let newOption = o.childName === option.childName ? option : o;

        //if key is not included in "include" array fallback to default
        if (o.include && !o.include.some(key => res.some(o => o.key === key))) {
          const defaultOption = defaultChildFilterOptions.find(
            option => option.childName === o.childName
          ) as FilterOption;
          newOption = defaultOption;
        }

        res.push(newOption);
        return res;
      }, [] as FilterOption[]);
    }

    const children =
      childrenOptions ?? existingOptions.filter(o => o.childName);
    return [option, ...children];
  };

  if (existingFilter) {
    return existingFilters.reduce((allFilters, currentFilter) => {
      if (currentFilter.id === filterId) {
        const options = updateOptions(currentFilter.options);
        return [
          ...allFilters,
          {
            id: dynamic ? createFilterId(currentFilter.filter) : filterId,
            filter: currentFilter.filter,
            options,
          },
        ];
      }

      return [...allFilters, currentFilter];
    }, [] as ActiveFilterOptions[]);
  }

  const initialOptions = option.childName
    ? defaultChildFilterOptions.map(o =>
        o.childName === option.childName ? option : o
      )
    : [option, ...(childrenOptions ?? defaultChildFilterOptions)];
  const newFilterOption: ActiveFilterOptions = {
    id: dynamic ? createFilterId(filterKey) : filterKey,
    filter: filterKey,
    options: initialOptions,
  };
  return existingFilters
    ? [...existingFilters, newFilterOption]
    : [newFilterOption];
};

export type ToggleFilterProps = {
  multiselect?: boolean;
  filterKey: string;
  /**
   * have to be passed for dynamic filters, i.e theme_exposure/key_exposure
   */
  filterId?: string;
  dynamic?: boolean;
  option: FilterOption;
  /**
   * used in scenario condition builder to set/update option with children
   */
  childrenOptions?: FilterOption[];
};

export const getDefaultChildFilters = (
  filterKey: string,
  activeFilters?: FilterState['activeFilters']
): FilterOption[] => {
  const filter = activeFilters?.find(f => f.key === filterKey);

  if (filter?.config?.children) {
    return filter.config.children.map(child => {
      return {
        ...child.options.find(o => o.key === child.default),
        childName: child.name,
      } as FilterOption;
    });
  }

  return [];
};

export const addFilterOption = (
  { activeFilterOptions, activeFilters }: FilterState,
  toggleProps: ToggleFilterProps
) => {
  const { filterKey } = toggleProps;

  const defaultChildFilterOptions = getDefaultChildFilters(
    filterKey,
    activeFilters
  );

  const nextActiveFilterOptions = updateFilterOptions({
    existingFilters: activeFilterOptions,
    defaultChildFilterOptions,
    ...toggleProps,
  });

  return nextActiveFilterOptions;
};

export const setFilterOptions = (
  { activeFilterOptions: existingFilters }: FilterState,
  {
    filterKey,
    options,
    filterId = filterKey,
  }: { filterKey: string; options: ScreenerFilterOption[]; filterId?: string }
) => {
  const existingFilter = existingFilters?.find(f => f.id === filterId);

  if (existingFilter) {
    return existingFilters.reduce((allFilters, currentFilter) => {
      const filter =
        currentFilter.id === filterId
          ? {
              id: filterId,
              filter: currentFilter.filter,
              options,
            }
          : currentFilter;

      return [...allFilters, filter];
    }, [] as ActiveFilterOptions[]);
  }

  const newFilterOption: ActiveFilterOptions = {
    id: filterId,
    filter: filterKey,
    options,
  };

  return existingFilters
    ? [...existingFilters, newFilterOption]
    : [newFilterOption];
};

export const removeFilterOption = (
  { activeFilterOptions }: FilterState,
  { multiselect, filterKey, option, filterId = filterKey }: ToggleFilterProps
) => {
  const handleMulti = (): ActiveFilterOptions[] => {
    return activeFilterOptions.reduce((a, f) => {
      if (f.id === filterId) {
        const options = f.options.filter(o => o.key !== option.key);

        if (options.length) {
          a.push({ ...f, options });
        }
      } else {
        a.push(f);
      }

      return a;
    }, [] as ActiveFilterOptions[]);
  };

  const handleSingle = () => activeFilterOptions.filter(f => f.id !== filterId);

  return multiselect ? handleMulti() : handleSingle();
};

export const updateGroup = <T extends string>({
  filterGroups,
  group,
  state,
}: {
  filterGroups: FilterGroups<T>;
  group: T;
  state: Partial<FilterState>;
}) => ({
  ...filterGroups,
  [group]: {
    ...filterGroups[group],
    ...state,
  },
});
