import { Alert, AlertType } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { isPathMatches } from '~/components/sidebar/utils/navigation-utils';
import { appPaths } from '~/routes/app-paths';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { List, WatchlistError } from '~/stores/use-watchlist/watchlist-types';
import { widgetPaths } from '~/views/widgets/widget-utils';

const TOAST_ID = 'create-watchlist';

export const useWatchlistActions = () => {
  const { t } = useTranslation('widget');
  const [watchlistError, setWatchlistError] = useState<WatchlistError>();

  const navigate = useNavigate();

  const {
    createList,
    updateListName,
    hasListWithName,
    activeModal,
    setActiveModal,
    deleteList,
    setActiveListId,
    error,
    createListLoading,
  } = useWatchlist(state => ({
    createList: state.createList,
    updateListName: state.updateListName,
    hasListWithName: state.hasListWithName,
    activeModal: state.activeModal,
    setActiveModal: state.setActiveModal,
    deleteList: state.deleteList,
    setActiveListId: state.setActiveListId,
    error: state.error,
    createListLoading: state.createListLoading,
  }));
  const { showToast, removeToast } = useToasts(state => ({
    showToast: state.showToast,
    removeToast: state.removeToast,
  }));

  const clearError = () => {
    setWatchlistError(undefined);
  };

  const handleError = (error: WatchlistError) => {
    setWatchlistError(error);
    return Promise.reject(error);
  };

  const showWatchlistSavedToast = ({
    id,
    name,
    message,
    btnLabel,
  }: {
    id: string;
    name?: string;
    message?: string;
    btnLabel?: string;
  }) => {
    const onClose = () => removeToast(TOAST_ID);
    showToast({
      id: TOAST_ID,
      hideToast: true,
      position: ToastPosition.TopRight,
      content: (
        <Alert
          withIcon
          onClose={onClose}
          type={AlertType.Success}
          message={
            message ||
            t('widget:watchlistTrigger.watchlist-created', {
              watchlistName: name,
            })
          }
          primaryBtn={{
            label: btnLabel || t('widget:watchlistTrigger.viewOrEditWatchlist'),
            onClick: () => {
              navigateToWatchlist(id);
              onClose();
            },
          }}
        />
      ),
    });
  };

  const createNewList = async (
    name: string,
    tags: string[]
  ): Promise<string | null> => {
    clearError();

    if (hasListWithName(name)) {
      return handleError(WatchlistError.Duplicate);
    }

    const id = await createList(name, tags);
    const createListError = useWatchlist.getState().error;

    if (createListError?.status === 403) {
      return Promise.reject(WatchlistError.UsageLimitExceeded);
    }

    if (id === null) {
      return handleError(WatchlistError.ServerError);
    }

    const isDashboardOrWatchlist = isPathMatches(['dashboard', 'watchlist']);

    if (!isDashboardOrWatchlist) {
      showWatchlistSavedToast({ id, name });
    }

    return id;
  };

  const renameList = async (id: string, name: string) => {
    if (hasListWithName(name)) {
      return handleError(WatchlistError.Duplicate);
    }

    const response = await updateListName(id, name);

    if (response.error) {
      return handleError(WatchlistError.ServerError);
    }

    return undefined;
  };

  const navigateToWatchlist = (id?: string, skipFallback?: boolean) => {
    //grab the latest state of lists
    const newLists = useWatchlist.getState().lists as List[];
    const idToNavigateTo = id || (!skipFallback ? newLists[0]?.id : undefined);
    setActiveListId(idToNavigateTo);

    const newParam = `widget=${widgetPaths.watchlistManagerModal}`;
    const search = location.search
      ? `${location.search}&${newParam}`
      : `?${newParam}`;
    const to = idToNavigateTo
      ? `${appPaths.watchlist}/${idToNavigateTo}`
      : `${location.pathname}${search}`;
    setActiveModal({ activeModal: undefined });
    navigate(to);
  };

  const handleDelete = async (id: string) => {
    await deleteList(id);

    if (!error) {
      return navigateToWatchlist();
    } else {
      return handleError(WatchlistError.ServerError);
    }
  };

  const handleRename = async (id: string, name: string) => {
    try {
      await renameList(id, name);
      setActiveModal({ activeModal: undefined });
    } catch {
      return;
    }
  };

  const watchlistErrorText =
    watchlistError &&
    /* i18next-extract-mark-context-next-line ["duplicate", "serverError", "emptyName", "emptyList"] */
    t('widget:watchlist.watchlistError', {
      context: watchlistError,
    });

  return {
    createNewList,
    renameList,
    createListLoading,
    watchlistErrorText,
    clearError,
    activeModal,
    setActiveModal,
    handleDelete,
    handleRename,
    navigateToWatchlist,
    setWatchlistError,
    showWatchlistSavedToast,
  };
};
