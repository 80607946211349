import {
  ColorScheme,
  DEFAULT_THEME,
  ThemeName,
  ThemeVariant,
} from '@toggle/design-system';

import {
  THEME_NAME_STORAGE_KEY,
  useRemoteStorage,
} from '~/hooks/use-remote-storage';
import { create } from '~/stores/create-store/createStore';

import {
  createThemeName,
  getValidThemeName,
  splitThemeName,
} from '../utils/theme-utils/theme-utils';

interface ThemeStore {
  themeName: ThemeName;
  colorScheme: ColorScheme;
  initStore: () => void;
  changeTheme: (colorScheme: ColorScheme, themeVariant: ThemeVariant) => void;
}

export const useThemeStore = create<ThemeStore>(set => ({
  themeName: DEFAULT_THEME,
  colorScheme: splitThemeName(DEFAULT_THEME).colorScheme,
  initStore: () => {
    const { items } = useRemoteStorage.getState();
    const themeName = getValidThemeName(items?.[THEME_NAME_STORAGE_KEY]);
    const { colorScheme } = splitThemeName(themeName);

    set({
      themeName,
      colorScheme,
    });
  },
  changeTheme: (colorScheme, themeVariant) => {
    const { storeItems } = useRemoteStorage.getState();
    const themeName = createThemeName(colorScheme, themeVariant);

    set({
      themeName,
      colorScheme,
    });

    storeItems({
      [THEME_NAME_STORAGE_KEY]: themeName,
    });
  },
}));
