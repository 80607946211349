import { PriceStatus } from '@toggle/helpers';
import {
  AssetSubClassNames,
  Entity,
  mapAssetClass,
  MappedEntity,
  PrimaryMethod,
  SnakeMeta,
} from '@toggle/toggle';
import { StoreApi } from 'zustand';

import { PriceUpdate } from '~/services/price-ws/PriceWS';
import { priceDecimals } from '~/utils/currency/currency';
import {
  getPriceChange,
  getTickerForLivePrice,
} from '~/widgets/asset-overview-widget/utils/asset-box/asset-box-helpers';

export enum Identifier {
  Global = 'Global',
  Watchlist = 'Watchlist',
}

export interface LivePriceChange {
  status: PriceStatus;
  change: string;
  proportionChange: string;
}

export interface LivePriceInfo {
  price: number;
  priceChange: LivePriceChange;
  decimals: number;
  time: string;
  lastKnownPrice: number;
  isLive: boolean;
  priceBefore: number;
}

export interface LivePriceData {
  [key: string]: LivePriceInfo;
}

export type SubscribeConfig = {
  priceEntities: PriceEntity[];
  identifier?: Identifier;
  isPro: boolean;
};

export type SubscribeFn = (config: SubscribeConfig) => () => void;

export interface LivePriceState {
  livePriceData: LivePriceData;
  componentsWatchingData: { [key: string]: string[] };
  subscribe: SubscribeFn;
}

export interface PriceEntity {
  entity: {
    ticker: string;
    sub_class: MappedEntity['sub_class'];
    primary_method: PrimaryMethod | '';
    subscribable_ticker?: string;
  };
  lastKnownPrice: number;
  priceBefore: number;
  time?: string;
}

const anythingButLetter = /[^a-z]/g;

export const mapLivePriceData = ({
  entity,
  lastKnownPrice,
  priceBefore,
  time = '',
}: PriceEntity) => {
  const decimals = priceDecimals(entity.sub_class as AssetSubClassNames)(
    lastKnownPrice
  );

  return {
    price: lastKnownPrice,
    decimals,
    priceChange: getPriceChange({
      lastPrice: priceBefore,
      newPrice: lastKnownPrice,
      decimals,
      isPrice: entity.primary_method === PrimaryMethod.Price,
    }).priceChange,
    lastKnownPrice,
    time,
    isLive: false,
    priceBefore,
  };
};

export const mapPriceEntityToLivePriceData = (
  priceEntities: PriceEntity[],
  livePriceData: LivePriceData
) => {
  return priceEntities.reduce(
    ({ data, tickers }, priceEntity) => {
      const entity = priceEntity.entity;
      const ticker = entity.ticker;
      const tickerForLivePrice = getTickerForLivePrice(entity);

      if (!data[ticker] && tickerForLivePrice) {
        return {
          data: {
            ...data,
            [priceEntity.entity.ticker]: mapLivePriceData(priceEntity),
          },
          tickers: tickerForLivePrice
            ? [...tickers, tickerForLivePrice]
            : tickers,
        };
      }

      return { data, tickers };
    },
    { data: livePriceData, tickers: [] as string[] }
  );
};

export const mapNewLivePriceDataToWatchedData = (
  priceEntities: PriceEntity[],
  newLivePriceData: LivePriceData,
  identifier: string,
  componentsWatchingData: { [key: string]: string[] }
) => {
  const allEntityTickers = priceEntities.map(entity => entity.entity.ticker);
  return Object.keys(newLivePriceData).reduce((acc: any, key) => {
    if (!allEntityTickers.includes(key)) {
      return acc;
    }

    if (acc?.[key] && !acc[key].includes(identifier)) {
      acc[key].push(identifier);
    } else {
      acc[key] = [identifier];
    }

    return acc;
  }, componentsWatchingData);
};

export const priceUpdater =
  (set: StoreApi<LivePriceState>['setState']) =>
  ({ price, time, ticker }: PriceUpdate) => {
    set(state => {
      const stateTicker = ticker.toLowerCase().replace(anythingButLetter, '');
      const tickerData = state.livePriceData[stateTicker];

      if (!tickerData || tickerData.price === price) {
        return state;
      }

      const lastKnownPrice = tickerData.lastKnownPrice;

      const { priceChange } = getPriceChange({
        lastPrice: lastKnownPrice,
        newPrice: price,
        decimals: tickerData.decimals,
      });

      return {
        ...state,
        livePriceData: {
          ...state.livePriceData,
          [stateTicker]: {
            price,
            priceChange,
            lastKnownPrice,
            isLive: true,
            time,
            priceBefore: tickerData.priceBefore,
            decimals: tickerData.decimals,
          },
        },
      };
    });
  };

export const updateWatching = (
  set: StoreApi<LivePriceState>['setState'],
  get: StoreApi<LivePriceState>['getState'],
  ticker: string,
  identifier: string
) => {
  if (get().componentsWatchingData[ticker]?.length > 1) {
    const newWatching = get().componentsWatchingData[ticker];

    set({
      componentsWatchingData: {
        ...get().componentsWatchingData,
        [ticker]: newWatching.filter((id: string) => id !== identifier),
      },
    });
    return false;
  } else {
    const newWatching = get().componentsWatchingData;
    delete newWatching[ticker];
    set({
      componentsWatchingData: newWatching,
    });
    return true;
  }
};

export const mapEntityToPriceEntity = (
  entity?: Entity,
  snakeMeta?: SnakeMeta
): PriceEntity[] =>
  entity && snakeMeta?.last_value
    ? [
        {
          entity: {
            ticker: entity.ticker,
            sub_class: mapAssetClass(entity).sub_class,
            primary_method: entity.primary_method,
            subscribable_ticker: entity.subscribable_ticker,
          },
          lastKnownPrice: snakeMeta.last_value,
          priceBefore: snakeMeta.before_last_value,
          time: new Date(snakeMeta.last_timestamp / 100_0000).toISOString(),
        },
      ]
    : [];
