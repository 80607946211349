import React, {
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  RefObject,
  TextareaHTMLAttributes,
  useEffect,
} from 'react';

import { useRows } from '~/components/chat';
import { useChat } from '~/stores/use-chat/useChat';

import { ChatInputContextHeader } from '../chat-input-context-header/ChatInputContextHeader';
import * as S from './ChatDocumentContextInput.styles';

export interface ChatDocumentContextInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode;
  inputRef?: RefObject<HTMLTextAreaElement | null>;
  isMessagePending: boolean;
  contextCounts?: {
    documentsCount: number;
    companiesCount: number;
  };
}

export const ChatDocumentContextInput = forwardRef<
  HTMLDivElement,
  ChatDocumentContextInputProps
>(
  (
    {
      children,
      onChange,
      disabled = false,
      value,
      isMessagePending,
      inputRef,
      contextCounts,
      ...rest
    },
    ref
  ) => {
    const { rows, updateRows } = useRows({ value: value });

    const {
      setShouldResetStateOnSubmit,
      setShouldTargetDocumentAssistant,
      isSearchWithinContext,
      showContextHeader,
    } = useChat(state => ({
      setShouldResetStateOnSubmit: state.setShouldResetStateOnSubmit,
      setShouldTargetDocumentAssistant: state.setShouldTargetDocumentAssistant,
      isSearchWithinContext: state.isSearchWithinContext,
      showContextHeader: state.showContextHeader,
    }));

    useEffect(() => {
      const shouldResetValues = !showContextHeader;

      setShouldTargetDocumentAssistant(
        shouldResetValues ? false : isSearchWithinContext
      );
      setShouldResetStateOnSubmit(
        shouldResetValues ? false : !isSearchWithinContext
      );

      return () => {
        setShouldTargetDocumentAssistant(false);
        setShouldResetStateOnSubmit(false);
      };
    }, [showContextHeader, isSearchWithinContext]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      updateRows(event);
      onChange?.(event);
    };

    return (
      <S.ChatInputFieldWrapper ref={ref} $isMessagePending={isMessagePending}>
        {showContextHeader && (
          <ChatInputContextHeader contextCounts={contextCounts} />
        )}
        <S.ChatInputFieldContainer>
          <S.TextAreaWrapper>
            <S.TextareaElement
              id="dropdown-trigger"
              as="textarea"
              ref={inputRef}
              onChange={handleChange}
              rows={rows}
              disabled={disabled}
              value={value}
              {...rest}
              data-testid="chat-input"
            />
            {children}
          </S.TextAreaWrapper>
        </S.ChatInputFieldContainer>
      </S.ChatInputFieldWrapper>
    );
  }
) as ForwardRefExoticComponent<
  ChatDocumentContextInputProps & RefAttributes<HTMLTextAreaElement>
> & {
  Styled: typeof S;
};

ChatDocumentContextInput.Styled = S;
