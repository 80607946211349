import { SvgIconNames } from '@toggle/design-system';
import { PreliminaryPrice, SnakeMeta } from '@toggle/toggle';
import { format, isWithinInterval, parse } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { TFunction } from 'i18next';

import { LivePriceInfo } from '~/hooks/use-live-price';
import { EST_TIME_ZONE } from '~/widgets/asset-overview-widget/utils/asset-box/asset-box-helpers';

import { formatPrice } from './formatter/formatters';
import { PriceBase, PriceDisplayReturn } from './price-display.types';

interface MapPriceDisplayProps {
  exchange: string;
  livePriceInfo?: LivePriceInfo;
  preliminaryPrice?: PreliminaryPrice;
  meta?: SnakeMeta;
  isHovered: boolean;
  t: TFunction;
}

/**
 * Main formatting function that returns price details for v2 price display
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448
 * @returns primary price and secondary price details
 */
export const formatPriceDisplay = ({
  exchange,
  livePriceInfo,
  preliminaryPrice,
  meta,
  isHovered,
  t,
}: MapPriceDisplayProps): PriceDisplayReturn => {
  const isSupported = checkSupportedExchange(exchange);
  const marketHours = getMarketHours();

  const lastKnownPrice =
    livePriceInfo?.lastKnownPrice !== undefined
      ? livePriceInfo.lastKnownPrice
      : meta?.last_value;
  const price =
    livePriceInfo?.price !== undefined ? livePriceInfo.price : lastKnownPrice;
  const decimals = livePriceInfo?.decimals ?? 2;

  const base: PriceBase = {
    price,
    priceChange: livePriceInfo?.priceChange,
    isLive: livePriceInfo?.isLive,
    marketHours,
    isSupported,
    asOfDate:
      livePriceInfo?.time && isHovered
        ? format(Date.parse(livePriceInfo?.time), 'PPP')
        : undefined,
  };

  const { primaryPrice, secondaryPrice } = formatPrice({
    base,
    meta,
    preliminaryPrice,
    decimals,
    t,
  });

  return {
    primary: isSupported && !isHovered ? primaryPrice : base,
    secondary: isSupported ? secondaryPrice : undefined,
  };
};

/**
 * Function that returns an object of boolean values representing different states of market hours based on EST timezone
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?block=block-fb9fdbe5-3e88-4a90-8a9c-15927f7de1cf
 */
export const getMarketHours = () => {
  const utcZoned = toZonedTime(new Date(), EST_TIME_ZONE);

  const dayStart = parse('00:00:00', 'HH:mm:ss', utcZoned);
  const coreTradingStart = parse('9:30:00', 'HH:mm:ss', utcZoned);
  const coreTradingEnd = parse('16:00:00', 'HH:mm:ss', utcZoned);
  const dayEnd = parse('23:59:59', 'HH:mm:ss', utcZoned);

  const isPreMarket = isWithinInterval(utcZoned, {
    start: dayStart,
    end: coreTradingStart,
  });
  const isMarketHours = isWithinInterval(utcZoned, {
    start: coreTradingStart,
    end: coreTradingEnd,
  });
  const isAfterMarket = isWithinInterval(utcZoned, {
    start: coreTradingEnd,
    end: dayEnd,
  });

  return {
    isPreMarket,
    isAfterMarket,
    isMarketHours,
  };
};

/**
 * Returns a boolean value for if a specific exchange is supported to display v2 price display
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?block=block-9e8a31ee-2874-41c1-a8fe-158a9bd178e4
 */
export const checkSupportedExchange = (exchange: string) => {
  return ['nyse', 'nasd', 'arca', 'bats'].includes(exchange);
};

export interface GetPriceStampKeyProps {
  isLive: boolean;
  isEquity: boolean;
  marketHours: {
    isPreMarket: boolean;
    isAfterMarket: boolean;
    isMarketHours: boolean;
  };
}

type TooltipKey =
  | 'isPreMarket'
  | 'isAfterMarket'
  | 'isMarketHours'
  | 'livePrice'
  | 'asOfLastClose';

export type TypeStampPrice = {
  tooltipKey: TooltipKey;
  iconName: SvgIconNames;
  color: string;
  backgroundColor: string;
};

export const getPriceStamp = ({
  isLive,
  isEquity,
  marketHours,
}: GetPriceStampKeyProps): TypeStampPrice | undefined => {
  if (isLive === undefined) {
    return undefined;
  }

  if (isLive) {
    if (isEquity) {
      if (marketHours.isPreMarket) {
        return {
          tooltipKey: 'isPreMarket',
          iconName: 'SunRise',
          backgroundColor: 'var(--surface-warning)',
          color: 'var(--icon-primary)',
        };
      } else if (marketHours.isAfterMarket) {
        return {
          tooltipKey: 'isAfterMarket',
          iconName: 'Moon',
          backgroundColor: 'var(--surface-default)',
          color: 'var(--icon-soft)',
        };
      } else if (marketHours.isMarketHours) {
        return {
          tooltipKey: 'isMarketHours',
          iconName: 'Sun',
          backgroundColor: 'var(--surface-success)',
          color: 'var(--icon-success)',
        };
      }
    } else {
      return {
        tooltipKey: 'livePrice',
        iconName: 'WavePulse',
        backgroundColor: 'var(--surface-success)',
        color: 'var(--icon-success)',
      };
    }
  }

  return {
    tooltipKey: 'asOfLastClose',
    iconName: 'CalendarClock',
    backgroundColor: 'var(--surface-default)',
    color: 'var(--icon-soft)',
  };
};
