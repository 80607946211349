import styled from 'styled-components';

import { SharedAxisStyles } from '~/components/chart-x-axis/ChartXAxis.styles';

export const ChartAxisG = styled.g`
  ${SharedAxisStyles}

  .tick > line {
    stroke-width: 1;
  }
`;
