import { RowSelectionState } from '@tanstack/react-table';
import { useCallback, useState } from 'react';

export type UseRowsSelectionReturnType = ReturnType<typeof useRowsSelection>;

export const useRowsSelection = <T extends { tag: string }>(rows: T[]) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const updateRowSelection = useCallback(
    (rowId: string, selected: boolean) => {
      const updatedRowSelection = { ...rowSelection };

      if (updatedRowSelection[rowId]) {
        delete updatedRowSelection[rowId];
      } else {
        updatedRowSelection[rowId] = selected;
      }

      setRowSelection(updatedRowSelection);
    },
    [rowSelection]
  );

  const toggleAllRowsSelection = useCallback(
    (selected: boolean) => {
      if (selected) {
        const updatedRowSelection = rows.reduce(
          (acc: Record<string, boolean>, data) => {
            acc[data.tag] = selected;
            return acc;
          },
          {}
        );
        setRowSelection(updatedRowSelection);
      } else {
        setRowSelection({});
      }
    },
    [rows]
  );

  return {
    rowSelection,
    setRowSelection,
    updateRowSelection,
    toggleAllRowsSelection,
  };
};
