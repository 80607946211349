import { RangeHorizon } from '@toggle/chart';
import { SmartTableColumn } from '@toggle/design-system';
import { InsightsEpisode } from '@toggle/toggle';
import { format } from 'date-fns';
import React from 'react';

import { EpisodeTableRow } from '~/widgets/past-episodes-widget/components/insight-past-episodes/InsightPastEpisodes';
import * as S from '~/widgets/past-episodes-widget/PastEpisodesWidget.styles';

export const getColumns = (
  totalTradingDays: number,
  t: (key: string, options?: any) => string
): SmartTableColumn<EpisodeTableRow>[] => [
  {
    label: t('widget:pastEpisode.table.episodeNo'),
    key: 'episodeNo',
  },
  {
    label: t('widget:pastEpisode.table.episodeDate'),
    key: 'date',
    render: value => <S.NumericalValue>{value}</S.NumericalValue>,
  },
  {
    label: t('widget:pastEpisode.table.tradingDays', {
      totalTradingDays: totalTradingDays,
    }),
    key: 'tradingDays',
    alignment: 'right',
  },
];

export const mapEpisodesToTableRow = (
  episodes: InsightsEpisode[]
): EpisodeTableRow[] => {
  return episodes.map((episode, index) => ({
    key: `episode-${index}`,
    episodeNo: index + 1,
    date: format(new Date(episode.start_date), 'yyyy-MM-dd'),
    tradingDays: episode.events.length,
  }));
};

export const getHorizonsLabels = (
  t: (key: string, options?: any) => string,
  horizons: RangeHorizon[]
) => {
  return horizons.map(horizon =>
    /* i18next-extract-mark-context-next-line ["OneMonth", "ThreeMonths", "SixMonths", "OneYear", "ThreeYears", "Max"] */
    t('widget:insights.insightHorizon', { context: horizon })
  );
};
