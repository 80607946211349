import {
  borderRadius,
  CheckBox,
  customScrollMinimal,
  Icon,
  SkeletonLoader,
  TableHeader,
  Toggle,
  Tooltip,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { EARNINGS_COL_WIDTH } from './utils/utils';

export const Root = styled.div`
  ${customScrollMinimal};
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  gap: 20px;
  padding: 20px 20px 80px 0;
  margin: 0 auto;

  > table {
    ${CheckBox.Styled.Checkmark} {
      height: 20px;
      width: 20px;
      margin: 0;
    }
  }
`;

export const UpcomingItem = styled.p`
  flex: 1;
  text-align: center;
`;

export const UpcomingRoot = styled.section`
  border-radius: 8px;
  border: 1px solid var(--border-soft);
  background: var(--surface-default);
  display: flex;
  gap: 8px;
  padding: 12px;

  ${UpcomingItem} {
    border-right: 1px solid var(--border-soft);
    &:last-child {
      border-right: none;
    }
  }
`;

export const UpcomingTitle = styled.span`
  color: var(--text-soft);
  display: block;
  ${typographyV2.bodySm};
  line-height: 20px;
`;

export const UpcomingValue = styled.span`
  display: block;
  ${typographyV2.titleSm};
  line-height: 24px;
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '1280px',
        borderRadius: '8px',
        height: '360px',
        margin: '20px auto',
      },
    },
  ],
})``;

export const StyledTableHeader = styled(TableHeader)<{
  $highlight: boolean;
  $isSortable?: boolean;
  $isPinned?: boolean;
  $left?: number;
  $right?: number;
  $isLastPinnedCol?: boolean;
  $isLastHeader?: boolean;
}>`
  z-index: var(--z-index-header-cell);
  font-weight: 500;
  background-color: ${props => props.$highlight && 'var(--text-inverse)'};
  position: sticky;
  left: 0;
  top: 0;

  ${({ $isPinned, $left, $right }) =>
    $isPinned &&
    css`
      position: sticky;
      z-index: calc(var(--z-index-header-cell) + 1);
      ${$left !== undefined && `left: ${$left}px;`}
      ${$right !== undefined && `right: ${$right}px;`}
      top: 0;
    `}

  ${({ $isPinned, $isLastPinnedCol }) =>
    $isPinned &&
    $isLastPinnedCol &&
    css`
      border-right: 1px solid var(--border-default);
    `}


  ${({ $isSortable }) =>
    $isSortable &&
    css`
      &:hover {
        background-color: var(--surface-hover);
        cursor: pointer;
      }
    `};

  ${({ $isLastHeader }) =>
    $isLastHeader &&
    css`
      border-bottom: 1px solid var(--border-default);
    `};
`;

export const TableHeaderContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: left;
  gap: 24px;
`;

export const EarningsCell = styled.div`
  width: calc(${EARNINGS_COL_WIDTH}px - 24px);
  display: flex;
  justify-content: left;
`;

export const AlfredIcon = styled(Icon)`
  padding: 12px;
  background-color: var(--surface-default);
  ${borderRadius.rFull};
`;

export const FilterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledToggle = styled(Toggle)`
  background-color: var(--surface-default);
  padding: 8px;
  border-radius: 8px;
`;

export const WrapperCheckbox = styled.div`
  height: 20px;
  width: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledCheckBox = styled(CheckBox)<{
  $isManualUpdated: boolean;
  $isRowMatchingFilters: boolean;
}>`
  ${CheckBox.Styled.Checkmark} {
    margin-top: 2px;
  }
  ${props =>
    props.$isRowMatchingFilters &&
    css`
      && {
        ${CheckBox.Styled.Checkmark} {
          border-color: var(--border-primary);
          &:hover {
            border-color: var(--border-primary);
          }
        }
      }
    `}

  ${({ $isManualUpdated, $isRowMatchingFilters }) =>
    $isManualUpdated &&
    !$isRowMatchingFilters &&
    css`
      && {
        ${CheckBox.Styled.Checkmark} {
          background-color: var(--icon-soft);
          border-color: var(--border-default);

          &:hover {
            border-color: var(--border-default);
          }
        }
      }
    `}
`;

export const MatchingFilterLabel = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-default);
`;

export const StyledCheckboxTooltip = styled(Tooltip)`
  padding: 4px 8px;
`;
