import { RangeHorizon } from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import { appPaths } from '~/routes/app-paths';
import {
  getChartSubPageSearchParams,
  SnakeTypes,
} from '~/widgets/chart-peer-group/utils/chart-subpage-navigation/chart-subpage-navigation';
import { CompetitorsData } from '~/widgets/overview/utils';

import { MultiLineChart } from '../multi-line-chart/MultiLineChart';
import * as S from './OverviewFinancials.styles';

export interface OverviewFinancialsProps {
  entity: Entity;
  competitorsData: CompetitorsData;
}

const COMPETITORS_AMOUNT = 4;

export const OverviewFinancials = ({
  entity,
  competitorsData,
}: OverviewFinancialsProps) => {
  const { t } = useTranslation('asset-overview');
  const [searchParams] = useSearchParams();

  if (competitorsData.isLoading) {
    return <S.SkeletonChartLoader />;
  }

  const entities = [
    entity,
    ...competitorsData.competitors
      .slice(0, COMPETITORS_AMOUNT)
      .map(s => s.entity),
  ];

  const chartSubPageSearchParams = getChartSubPageSearchParams({
    entityTag: entity.tag,
    prevSearchParams: searchParams,
    horizon: RangeHorizon.OneYear,
    snakeType: SnakeTypes.Price,
  }).toString();

  const linkToChartSubPage = `${appPaths.assetOverview}?${chartSubPageSearchParams}`;

  return (
    <div>
      <SectionTitle
        title={t('asset-overview:performance')}
        linkTo={linkToChartSubPage}
      />

      <S.Title>
        {t('asset-overview:last12Months')}
        <span>%</span>
      </S.Title>

      <MultiLineChart
        entities={entities}
        chartConfig={{
          enableZoom: false,
        }}
        tooltipSortConfig={{ field: 'priceFormatted', desc: true }}
      />
    </div>
  );
};
