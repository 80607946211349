export const httpCode = {
  notFound: 404,
  serverError: 500,
  unauthorized: 401,
  badRequest: 400,
  upgradeRequired: 426,
  tooManyRequests: 429,
  precondition: 428,
  paymentRequired: 402,
  misdirectedRequest: 421,
  locked: 423,
};
