import {
  Alert,
  AlertType,
  SkeletonLoader,
  WithNAFallback,
} from '@toggle/design-system';
import { getPriceStatus } from '@toggle/helpers';
import { EarningsItemWithEntity } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEarningsScenario } from '~/hooks/use-earnings-scenario/useEarningsScenario';

import { SummaryTable } from '../summary-table/SummaryTable';
import { formatNumber } from '../utils/number-formatter';
import { ChartError } from './components/chart-error/ChartError';
import { EarningsWidget } from './components/earnings-widget/EarningsWidget';
import * as S from './EarningsEventDrawerContent.styles';

export type EarningsEventDrawerContentProps = {
  data: EarningsItemWithEntity;
};

export const EarningsEventDrawerContent = ({
  data,
}: EarningsEventDrawerContentProps) => {
  const { t } = useTranslation('calendar');

  const {
    entity,
    fiscal_period,
    fiscal_year,
    currency,
    eps,
    revenue,
    exchange,
    reporting_date,
  } = data;
  const {
    value: epsValue,
    estimate: epsEstimate,
    surprise: epsSurprise,
    surprise_percentage: epsSurprisePercentage,
  } = eps;
  const {
    value: revenueValue,
    estimate: revenueEstimate,
    surprise: revenueSurprise,
    surprise_percentage: revenueSurprisePercent,
  } = revenue;

  const {
    data: earningsScenarioData,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useEarningsScenario({
    ticker: entity?.ticker ?? '',
    exchange: exchange,
    year: fiscal_year,
    period: fiscal_period,
  });

  if (!entity) {
    return null;
  }

  const currentDate = new Date();
  const reportingDate = new Date(reporting_date);
  const shouldBeReported = currentDate >= reportingDate;
  const hasReportedValues = !!epsValue || !!revenueValue;

  const tableHeaders = [
    `(${currency})`,
    t('calendar:expected'),
    t('calendar:reported'),
    t('calendar:surpriseWithUnit', { unit: `(${currency})` }),
    t('calendar:surpriseWithUnit', { unit: '%' }),
  ];

  const renderValue = (value: number | undefined, isPercentage = false) => {
    return (
      <WithNAFallback
        value={formatNumber(isPercentage, value)}
        check={value !== undefined}
      />
    );
  };

  const tableRows = [
    {
      fieldName: t('calendar:eps'),
      expected: renderValue(epsEstimate),
      reported: renderValue(epsValue),
      surprise: (
        <S.SurpriseWrapper $status={getPriceStatus(epsSurprise)}>
          {renderValue(epsSurprise)}
        </S.SurpriseWrapper>
      ),
      surprisePercent: (
        <S.SurpriseWrapper $status={getPriceStatus(epsSurprisePercentage)}>
          {renderValue(epsSurprisePercentage, true)}
        </S.SurpriseWrapper>
      ),
    },
    {
      fieldName: t('calendar:revenue'),
      expected: renderValue(revenueEstimate),
      reported: renderValue(revenueValue),
      surprise: (
        <S.SurpriseWrapper $status={getPriceStatus(revenueSurprise)}>
          {renderValue(revenueSurprise)}
        </S.SurpriseWrapper>
      ),
      surprisePercent: (
        <S.SurpriseWrapper $status={getPriceStatus(revenueSurprisePercent)}>
          {renderValue(revenueSurprisePercent, true)}
        </S.SurpriseWrapper>
      ),
    },
  ];

  const getChartContent = () => {
    if (isLoading || isRefetching) {
      return (
        <SkeletonLoader
          areas={[
            {
              styles: {
                width: '100%',
                height: '240px',
                borderRadius: '8px',
              },
            },
          ]}
        />
      );
    }

    if (isError || !earningsScenarioData) {
      return <ChartError onRetry={() => refetch} />;
    }

    return (
      <EarningsWidget earningsData={earningsScenarioData} entity={entity} />
    );
  };

  return (
    <S.EarningsDrawerContentRoot>
      {shouldBeReported && !hasReportedValues && (
        <Alert
          variant="inline"
          type={AlertType.Info}
          title={t('calendar:earningsBeingAnalyzed', {
            period: fiscal_period,
            year: fiscal_year % 100,
          })}
        />
      )}
      <S.StyledSummaryTableWrapper>
        <SummaryTable
          title={
            <div>
              {t('calendar:earningsSummary', {
                period: fiscal_period,
                year: fiscal_year,
              })}
            </div>
          }
          tableHeaders={tableHeaders}
          tableRows={tableRows}
        />
      </S.StyledSummaryTableWrapper>
      {getChartContent()}
    </S.EarningsDrawerContentRoot>
  );
};
