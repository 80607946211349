import { Period } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './PriceMovementLegend.styles';

export interface PriceMovementLegendProps {
  bestHorizon?: Period;
  legendLabel?: string;
  isShowCumulativeReturn: boolean;
}

export const PriceMovementLegend = ({
  bestHorizon,
  legendLabel,
  isShowCumulativeReturn,
}: PriceMovementLegendProps) => {
  const { t } = useTranslation('widget');
  return (
    <S.Legend data-testid="price-movement-horizon">
      {bestHorizon && (
        <S.LegendItem data-testid="legend-best-horizon">
          <S.IconPrimary />
          <S.LegendLabel>
            {t('widget:priceMovement.chartLegend.bestHorizon')}
          </S.LegendLabel>
        </S.LegendItem>
      )}
      <S.LegendItem>
        <S.IconBar />
        <S.LegendLabel>
          {t('widget:priceMovement.chartLegend.tradingRange')}
        </S.LegendLabel>
      </S.LegendItem>
      <S.LegendItem>
        <S.IconMedian />
        <S.LegendLabel>
          {legendLabel ?? t('widget:priceMovement.chartLegend.median')}
        </S.LegendLabel>
      </S.LegendItem>
      {isShowCumulativeReturn && (
        <S.LegendItem>
          <S.IconCumulativeReturn />
          <S.LegendLabel>
            {t('widget:priceMovement.chartLegend.cumulativeReturn')}
          </S.LegendLabel>
        </S.LegendItem>
      )}
    </S.Legend>
  );
};

PriceMovementLegend.Styled = S;
