import { customScrollMinimal, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const AboutRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SubTitlesWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

export const Label = styled.span`
  color: var(--text-soft);
  ${typographyV2.titleSm};
`;

export const Value = styled.span`
  ${typographyV2.titleBase};
`;

export const Description = styled.p<{ $isModal: boolean }>`
  max-width: 580px;
  max-height: ${({ $isModal }) => ($isModal ? '600px' : '400px')};
  ${typographyV2.bodySm};
  overflow: scroll;
  ${customScrollMinimal}
`;
