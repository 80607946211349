import { z } from 'zod';

import { episodeSchema, periodSchema } from './compass-schema';
import { HighConvictionReportKeys, predictionsSchema } from './insights';

/**
 * Schemas for v1/compass endpoint
 */
export const dominoPredictionSchema = z.object({
  relative_idx: z.number(),
  low: z.number(),
  median: z.number(),
  high: z.number(),
});

export const dominoSchema = z.object({
  entity: z.string(),
  prediction: z.array(dominoPredictionSchema),
  episodes: z.array(episodeSchema),
});

const dominoResponseSchema = z.array(dominoSchema);
export type Domino = z.infer<typeof dominoSchema>;
export type DominoResponse = z.infer<typeof dominoResponseSchema>;

/**
 * Schemas for v1/scenarios endpoint
 */
// Todo review and type correctly
export const scenariosRequestConditionSchema = z.object({
  order: z.number().optional(),
  display: z.string().optional(),
  entity: z.string().optional(),
  snake: z.string(),
  condition: z.string().optional(),
  value: z.number().optional(),
  min_value: z.number().optional(),
  max_value: z.number().optional(),
  duration: z.number().optional(),
  duration_unit: z.string().optional(),
  level: z.number().optional(),
});

const scenariosRequestEntitySchema = z.object({
  tag: z.string(),
  snake: z.string().optional(),
});

const scenariosRequestEventSchema = z.object({
  date: z.string(),
  market_hours: z.string(),
});

const scenariosRequestSchema = z.object({
  entities: z.array(scenariosRequestEntitySchema),
  conditions: z.array(scenariosRequestConditionSchema).optional(),
  events: z.array(scenariosRequestEventSchema).optional(),
  horizons: z.array(z.string()).optional(),
  start_horizon: z.string().optional(),
  end_horizon: z.string().optional(),
  skip_report: z.boolean().optional(),
});
export type ScenariosRequest = z.infer<typeof scenariosRequestSchema>;
export type ScenariosCondition = z.infer<
  typeof scenariosRequestConditionSchema
>;

export const scenariosResponseEpisodeSchema = z.object({
  date: z.string(),
  events: z.array(
    z.object({
      date: z.string(),
    })
  ),
});

export const reportDetailSchema = z.object({
  kind: z.nativeEnum(HighConvictionReportKeys),
  triggered: z.boolean(),
  value: z.number(),
});

export const reportSchema = z.object({
  best_horizon: periodSchema,
  common: z.array(reportDetailSchema),
  horizons: z.record(z.array(reportDetailSchema)),
});

export const scenariosResponseSchema = z.object({
  entity: z.string(),
  episodes: z.array(scenariosResponseEpisodeSchema),
  predictions: predictionsSchema,
  delta_type: z.number(),
  last_value: z.number(),
  report: reportSchema,
  signal: z.object({
    top: z.number(),
    bottom: z.number(),
  }),
});

export type ReportDetail = z.infer<typeof reportDetailSchema>;
export type ScenariosResponse = z.infer<typeof scenariosResponseSchema>;

export const scenario = {
  domino: {
    path: `scenario-orchestrator/v1/compass`,
    schema: dominoResponseSchema,
  },
  scenarios: {
    path: `scenario-orchestrator/v1/scenarios`,
    schema: scenariosResponseSchema,
  },
} as const;
