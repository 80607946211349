import { Button, SkeletonLoader, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  justify-content: space-between;
  min-height: 100%;
  gap: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledErrorMessageWrapper = styled(ErrorMessageWrapper)`
  height: calc(100% - 40px);
  margin: 20px 20px 0px 20px;
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
      },
    },
  ],
})`
  height: 100%;
  padding: 20px;
`;

export const DescriptionText = styled.span`
  ${typographyV2.bodySm}
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: var(--border-soft);
`;

export const FullscreenButton = styled(Button)`
  max-width: 121px;
  margin-bottom: 24px;
`;
