import {
  Dropdown,
  DropdownProps,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import { JSX } from 'react';
import styled from 'styled-components';

export const StyledModalRoot = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: 95%;
    max-width: 555px;
    background: var(--background-soft);
  }
`;

export const CustomSelectorWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

export const CustomModalDropdown = styled(Dropdown)`` as <T>(
  props: DropdownProps<T>
) => JSX.Element;

export const ModalTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const CustomStyledInputWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 50%;
  align-items: center;

  span {
    color: var(--text-soft);
    ${typographyV2.bodySm}
  }
`;
