import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

import { HEADER_HEIGHT } from '../news-header/NewsHeader.styles';

const LAYOUT_WIDTH = '776px';

export const SearchResultsRoot = styled.div`
  ${customScrollMinimal};
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-gutter: stable;
`;

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    rgba(var(--background-default-rgb), 1) 80%,
    rgba(var(--background-default-rgb), 0) 100%
  );
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: ${LAYOUT_WIDTH};
  margin: 0 auto;
  padding: 16px 0;
  gap: 16px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  width: 100%;
  margin: 0 auto;
  max-width: ${LAYOUT_WIDTH};
  height: calc(100% - ${HEADER_HEIGHT});
`;
