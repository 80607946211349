import React, {
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
  RefObject,
  TextareaHTMLAttributes,
} from 'react';

import { useRows } from '~/components/chat';

import * as Shared from './ChatDocumentContextInput.styles';

export interface ChatInputFieldProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode;
  inputRef?: RefObject<HTMLTextAreaElement | null>;
  maxRows?: number;
}

export const ChatInputField = forwardRef<HTMLDivElement, ChatInputFieldProps>(
  ({ children, onChange, inputRef, value, maxRows, ...rest }, ref) => {
    const { rows, updateRows } = useRows({ value, maxRows: maxRows });

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      updateRows(event);
      onChange?.(event);
    };

    return (
      <Shared.ChatInputFieldWrapper ref={ref}>
        <Shared.ChatInputFieldContainer>
          <Shared.TextAreaWrapper>
            <Shared.TextareaElement
              id="dropdown-trigger"
              as="textarea"
              ref={inputRef}
              rows={rows}
              onChange={handleChange}
              {...rest}
              data-testid="chat-input"
            />
            {children}
          </Shared.TextAreaWrapper>
        </Shared.ChatInputFieldContainer>
      </Shared.ChatInputFieldWrapper>
    );
  }
) as ForwardRefExoticComponent<
  ChatInputFieldProps & RefAttributes<HTMLTextAreaElement>
>;
