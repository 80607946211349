import { Entity, isEtf } from '@toggle/toggle';
import React, { useEffect, useRef } from 'react';

import { useQueryParams } from '~/hooks/use-query-params/useQueryParams';
import { BrandAndProductModule } from '~/modules/brand-and-product/BrandAndProduct';
import { CompetitorVendor } from '~/modules/competitor-vendor/CompetitorVendor';
import { CountryExposure } from '~/modules/country-exposure/CountryExposure';
import { ThemeExposureModule } from '~/modules/theme-exposure-module/ThemeExposureModule';

import { queryKeys } from '../../routes/app-paths';
import * as S from './KnowledgeGraphWidget.styles';

export interface KnowledgeGraphProps {
  entity: Entity;
}

export enum KnowledgeGraphSectionId {
  themeExposure = 'themeExposureSection',
  peerGroup = 'peerGroupSection',
  countryExposure = 'countryExposureSection',
  brandAndProducts = 'brandAndProducts',
}

export const KnowledgeGraphWidget = ({ entity }: KnowledgeGraphProps) => {
  const themeExposureRef = useRef<HTMLDivElement>(null);
  const peersGroupRef = useRef<HTMLDivElement>(null);
  const countryExposureRef = useRef<HTMLDivElement>(null);
  const brandAndProductRef = useRef<HTMLDivElement>(null);

  const { params, deleteQueryParam } = useQueryParams();

  const selectedSectionId = params[queryKeys.assetOverviewSectionId];

  const sectionToRefMap: {
    [key: string]: React.RefObject<HTMLDivElement | null>;
  } = {
    [KnowledgeGraphSectionId.themeExposure]: themeExposureRef,
    [KnowledgeGraphSectionId.peerGroup]: peersGroupRef,
    [KnowledgeGraphSectionId.countryExposure]: countryExposureRef,
    [KnowledgeGraphSectionId.brandAndProducts]: brandAndProductRef,
  };

  useEffect(() => {
    if (!selectedSectionId) {
      return;
    }

    deleteQueryParam(queryKeys.assetOverviewSectionId, { replace: true });
    const refToScrollTo = sectionToRefMap[selectedSectionId];

    if (!refToScrollTo?.current) {
      return;
    }

    refToScrollTo.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [selectedSectionId]);

  return (
    <S.KnowledgeGraphWidgetRoot data-testid="knowledge-graph">
      <ThemeExposureModule
        entityTag={entity.tag}
        isEtf={isEtf(entity)}
        ref={themeExposureRef}
      />
      <BrandAndProductModule entityTag={entity.tag} ref={brandAndProductRef} />
      <CompetitorVendor entityTag={entity.tag} ref={peersGroupRef} />
      <CountryExposure entityTag={entity.tag} ref={countryExposureRef} />
    </S.KnowledgeGraphWidgetRoot>
  );
};
