/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react';
import { KeyboardKeys } from '@toggle/helpers';
import { useOutsideClick } from '@toggle/helpers/src/hooks/use-outside-click/useOutsideClick';
import React, {
  cloneElement,
  FocusEvent,
  HTMLAttributes,
  JSX,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { InputProps } from '~/components/input/Input';

import { Portal } from '../portal';
import * as S from './Dropdown.styles';
import { DropdownList, DropdownListProps } from './dropdown-list/DropdownList';
import { useDropdownScroll } from './hooks/useDropdownScroll';
import {
  DropdownArrowPressVariant,
  getArrowDownSuggestion,
  getArrowUpSuggestion,
} from './utils/dropdownUtils';

type OmittedInputProps = 'onKeyDown' | 'onClick' | 'onFocus';

export type SelectTrigger = 'click' | 'enter';

export interface DropdownProps<T> extends HTMLAttributes<HTMLDivElement> {
  inputProps?: Omit<InputProps, OmittedInputProps>;
  onFocus?: () => void;
  onClick?: () => void;
  onChildKeyDown?: (
    e: KeyboardEvent<HTMLElement>,
    item: T | null,
    itemIndex: number
  ) => void;
  onOutsideClick?: () => void;
  onItemsShowChange?: (show: boolean) => void;
  dropdownItems: T[];
  renderDropdownItem: (
    item: T,
    isActive: boolean,
    isFocused: boolean,
    isDisabled: boolean
  ) => ReactNode;
  focusedItemIdx?: number;
  activeItemIdx?: number;
  selectItem?: (item: T, trigger: SelectTrigger, index: number) => void;
  beforeResultsNode?: ReactNode;
  afterResultsNode?: ReactNode;
  beforeInListNode?: ReactNode;
  afterInListNode?: ReactNode;
  shouldCloseOnSelect?: boolean;
  shouldCloseOnTabClick?: boolean;
  shouldShowItems?: boolean;
  shouldCloseOnOutsideClick?: boolean;
  shouldResetScrollOnItemsChange?: boolean;
  shouldDisableItemMouseEvents?: boolean;
  shouldSetActiveOnSelect?: boolean;
  arrowKeyPressVariant?: DropdownArrowPressVariant;
  hasV2Styles?: boolean;
  enableFloating?: boolean;
  strategy?: 'absolute' | 'fixed';
  placement?: Placement;
  offsetValue?: {
    x?: number;
    y?: number;
  };
  isClickedOutside?: (
    ref: RefObject<HTMLDivElement | null>,
    e: MouseEvent
  ) => boolean;
  children: JSX.Element;
  autocompleteInputValue?: string;
  inPortal?: boolean;
  className?: string;
  dropdownApiRef?: RefObject<{ handleEnter: () => void }>;
}

export const TRIGGER_INPUT_ID = 'dropdown-trigger';

export const Dropdown = <T,>({
  onClick,
  onFocus,
  onChildKeyDown,
  onOutsideClick,
  onItemsShowChange,
  dropdownItems,
  renderDropdownItem,
  focusedItemIdx = 0,
  activeItemIdx,
  selectItem,
  beforeResultsNode,
  afterResultsNode,
  beforeInListNode,
  afterInListNode,
  shouldCloseOnSelect = true,
  shouldCloseOnTabClick = false,
  shouldShowItems = true,
  shouldCloseOnOutsideClick = true,
  shouldResetScrollOnItemsChange = false,
  shouldDisableItemMouseEvents = false,
  shouldSetActiveOnSelect = true,
  arrowKeyPressVariant = 'onlyLoopInList',
  hasV2Styles = true,
  children,
  strategy = 'absolute',
  placement = 'bottom',
  offsetValue = {
    x: 0,
    y: 5,
  },
  enableFloating = true,
  isClickedOutside,
  autocompleteInputValue,
  inPortal,
  className,
  dropdownApiRef,
  ...rest
}: DropdownProps<T>) => {
  const [showDropdownItems, setShowDropdownItems] = useState(shouldShowItems);
  const [focusedItemIndex, setFocusedItemIndex] = useState(focusedItemIdx);
  const [activeItemIndex, setActiveItemIndex] = useState(
    activeItemIdx !== undefined ? activeItemIdx : -1
  );
  const [currentAction, setCurrentAction] = useState<'hover' | 'focus'>(
    'focus'
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowDropdownItems(shouldShowItems);
  }, [shouldShowItems]);

  useEffect(() => {
    setFocusedItemIndex(focusedItemIdx);
  }, [autocompleteInputValue, focusedItemIdx]);

  useEffect(() => {
    onItemsShowChange?.(showDropdownItems);
  }, [showDropdownItems]);

  useEffect(() => {
    if (activeItemIdx !== undefined) {
      setCurrentAction('hover');
      setActiveItemIndex(activeItemIdx);
      setFocusedItemIndex(activeItemIdx);
    }
  }, [activeItemIdx]);

  useEffect(() => {
    setFocusedItemIndex(focusedItemIdx);
  }, [dropdownItems]);

  // see useSearchModalKeyboard for a reference
  useImperativeHandle(dropdownApiRef, () => ({
    handleEnter,
    focusedItemIndex,
    setFocusedItemIndex,
    setCurrentAction,
    getFocusedIndexForArrowKey,
  }));

  const { dropdownContainerRef, listItemRef, listRef } = useDropdownScroll({
    shouldResetScrollOnItemsChange,
    showDropdownItems: shouldShowItems && showDropdownItems,
    activeSuggestion: focusedItemIndex,
    dropdownItems,
    currentAction,
    activeItemIndex,
  });

  useOutsideClick({
    ref: inPortal ? dropdownContainerRef : containerRef,
    callback: () => {
      if (shouldCloseOnOutsideClick) {
        setShowDropdownItems(false);
      }

      onOutsideClick?.();
    },
    isClickedOutside,
  });

  const handleMouseLeave = () => {
    setFocusedItemIndex(-1);
  };

  const handleMouseEnter = (index: number) => {
    setFocusedItemIndex(index);
    setCurrentAction('hover');
  };

  const handleEnter = () => {
    const active = dropdownItems[focusedItemIndex];

    if (dropdownItems[focusedItemIndex]) {
      onItemSelect(active, focusedItemIndex, 'enter');
    }
  };

  const getFocusedIndexForArrowKey = (key: 'ArrowUp' | 'ArrowDown') => {
    const fn =
      key === 'ArrowDown' ? getArrowDownSuggestion : getArrowUpSuggestion;
    return fn(focusedItemIndex, dropdownItems, arrowKeyPressVariant);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    const key = e.key as KeyboardKeys;
    setCurrentAction('focus');
    let itemIndex = -1;

    switch (key) {
      case 'Enter':
        if (!e.shiftKey) {
          e.preventDefault();
          handleEnter();
        }

        break;

      case 'Escape':
        setShowDropdownItems(false);
        document.getElementById(TRIGGER_INPUT_ID)?.blur();
        break;

      case 'ArrowUp':
        e.preventDefault();
        itemIndex = getFocusedIndexForArrowKey('ArrowUp');
        setFocusedItemIndex(itemIndex);

        break;

      case 'ArrowDown':
        e.preventDefault();
        itemIndex = getFocusedIndexForArrowKey('ArrowDown');
        setFocusedItemIndex(itemIndex);
        break;

      case 'Tab':
        if (shouldCloseOnTabClick) {
          e.preventDefault();
          setShowDropdownItems(false);
          break;
        }
    }

    onChildKeyDown?.(
      e,
      itemIndex !== -1 ? dropdownItems[itemIndex] : null,
      itemIndex
    );
  };

  const onItemSelect = (item: T, idx: number, trigger: SelectTrigger) => {
    shouldSetActiveOnSelect && setActiveItemIndex(idx);

    if (shouldCloseOnSelect) {
      setShowDropdownItems(false);
      document.getElementById(TRIGGER_INPUT_ID)?.blur();
    }

    setFocusedItemIndex(-1);
    setCurrentAction(trigger === 'click' ? 'hover' : 'focus');
    selectItem?.(item, trigger, idx);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (e.currentTarget.id === TRIGGER_INPUT_ID && shouldShowItems) {
      setShowDropdownItems(true);
    }

    onFocus?.();
  };

  const handleClick = (e: MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.currentTarget.id !== TRIGGER_INPUT_ID) {
      setShowDropdownItems(s => !s);
    }

    onClick?.();
  };

  const showDropdownContainer =
    (dropdownItems.length > 0 ||
      Boolean(beforeResultsNode) ||
      Boolean(afterResultsNode) ||
      Boolean(beforeInListNode) ||
      Boolean(afterInListNode)) &&
    showDropdownItems;

  const {
    x,
    y,
    strategy: position,
    refs,
    elements,
  } = useFloating({
    placement,
    strategy,
    middleware: [
      offset({ alignmentAxis: offsetValue.x, mainAxis: offsetValue.y }),
      flip(),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const setListRef = (node: HTMLUListElement | null) => {
    listRef.current = node;
    refs.setFloating(node);
  };

  const setFloatingContainerRef = (node: HTMLDivElement | null) => {
    dropdownContainerRef.current = node;
    refs.setFloating(node);
  };

  const triggerComponent = cloneElement(children, {
    'data-testid': 'dropdown-trigger',
    onKeyDown: dropdownApiRef ? undefined : handleKeyDown,
    onFocus: handleFocus,
    onClick: handleClick,
    tabIndex: 0,
    'aria-expanded': showDropdownItems,
    'aria-autocomplete': 'list',
    'aria-controls': 'dropdown-list',
    ref: refs.setReference,
    ...children.props,
  });

  const floatingStyles = enableFloating
    ? {
        position,
        left: x || 0,
        top: y || 0,
        width: position === 'absolute' && !inPortal ? '100%' : undefined,
        minWidth:
          position === 'fixed'
            ? (elements.reference as HTMLElement | null)?.offsetWidth
            : undefined,
      }
    : undefined;

  const dropdownListProps: DropdownListProps<T> = {
    dropdownItems: dropdownItems,
    currentAction: currentAction,
    focusedItemIndex: focusedItemIndex,
    activeItemIndex: activeItemIndex,
    renderDropdownItem: renderDropdownItem,
    listItemRef: listItemRef,
    onItemMouseLeave: handleMouseLeave,
    onItemMouseEnter: handleMouseEnter,
    onItemSelect: onItemSelect,
    shouldDisableItemMouseEvents: shouldDisableItemMouseEvents,
    hasV2Styles: hasV2Styles,
    beforeNode: beforeInListNode,
    afterNode: afterInListNode,
  };

  return (
    <S.Container ref={containerRef} {...rest} className={className}>
      {triggerComponent}
      {showDropdownContainer &&
        (inPortal ? (
          <Portal>
            <S.DropdownListContainer
              ref={setFloatingContainerRef}
              style={floatingStyles}
              className={className}
            >
              {beforeResultsNode}
              <DropdownList {...dropdownListProps} />
              {afterResultsNode}
            </S.DropdownListContainer>
          </Portal>
        ) : (
          <S.DropdownListContainer ref={dropdownContainerRef}>
            {beforeResultsNode}
            <DropdownList
              {...dropdownListProps}
              setListRef={setListRef}
              style={floatingStyles}
            />
            {afterResultsNode}
          </S.DropdownListContainer>
        ))}
    </S.Container>
  );
};

Dropdown.Styled = S;
