export enum InsightsIconNames {
  ValuationDeteriorating = 'ValuationDeteriorating',
  ValuationImproving = 'ValuationImproving',
  ValuationLow = 'ValuationLow',
  ValuationHigh = 'ValuationHigh',

  AnalystEstimatesImproving = 'AnalystEstimatesImproving',
  AnalystEstimatesDeteriorating = 'AnalystEstimatesDeteriorating',

  AnalystRatingsOptimistic = 'AnalystRatingsOptimistic',
  AnalystRatingsPessimistic = 'AnalystRatingsPessimistic',
  AnalystRatingsImproving = 'AnalystRatingsImproving',
  AnalystRatingsDeteriorating = 'AnalystRatingsDeteriorating',
  AnalystRatingsConfident = 'AnalystRatingsConfident',
  AnalystRatingsCautious = 'AnalystRatingsCautious',

  DividendHigh = 'DividendHigh',
  DividendLow = 'DividendLow',
  DividendDecreasing = 'DividendDecreasing',
  DividendIncreasing = 'DividendIncreasing',
  EarningsBeats = 'EarningsBeats',
  EarningsMisses = 'EarningsMisses',
}

export const CATEGORY_DRIVER_MAPPING_TO_INSIGHTS_ICONS = {
  'valuation.deteriorating': InsightsIconNames.ValuationDeteriorating,
  'valuation.improving': InsightsIconNames.ValuationImproving,
  'valuation.low': InsightsIconNames.ValuationLow,
  'valuation.high': InsightsIconNames.ValuationHigh,
  'analystEstimates.improving': InsightsIconNames.AnalystEstimatesImproving,
  'analystEstimates.deteriorating':
    InsightsIconNames.AnalystEstimatesDeteriorating,
  'analystRating.optimistic': InsightsIconNames.AnalystRatingsOptimistic,
  'analystRating.pessimistic': InsightsIconNames.AnalystRatingsPessimistic,
  'analystRating.improving': InsightsIconNames.AnalystRatingsImproving,
  'analystRating.deteriorating': InsightsIconNames.AnalystRatingsDeteriorating,
  'analystRating.confident': InsightsIconNames.AnalystRatingsConfident,
  'analystRating.cautious': InsightsIconNames.AnalystRatingsCautious,
  'dividend.high': InsightsIconNames.DividendHigh,
  'dividend.low': InsightsIconNames.DividendLow,
  'dividend.decreasing': InsightsIconNames.DividendDecreasing,
  'dividend.increasing': InsightsIconNames.DividendIncreasing,
  'earnings.beats': InsightsIconNames.EarningsBeats,
  'earnings.misses': InsightsIconNames.EarningsMisses,
};
