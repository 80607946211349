import { z } from 'zod';

const oAuthIBPayloadSchema = z.object({
  request_token: z.string(),
  verifier_token: z.string(),
});

export const oAuthIBResponseSchema = z.object({
  result: z.object({
    login_url: z.string(),
  }),
});

export type OAuthIBPayload = z.infer<typeof oAuthIBPayloadSchema>;
export type OAuthIBResponse = z.infer<typeof oAuthIBResponseSchema>;

export const portfolio = {
  portfolio: { path: 'portfolio/v1/portfolio' },
  pendingPortfolio: { path: 'portfolio/v1/pending-portfolios' },
  verifyToken: { path: 'ib/v1/verifier-token', schema: oAuthIBPayloadSchema },
  ibLogin: { path: 'ib/v1/login-url', schema: oAuthIBResponseSchema },
  userPortfolioSettings: { path: 'portfolio/v3/settings/portfolio' },
} as const;
