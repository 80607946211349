import { MessageReply } from '../use-chat.types';

const getConfidence = (reply: MessageReply): number =>
  reply.data.confidence ?? 0;

export const sortReplies = (replies: MessageReply[]) => {
  const sortedReplies = [...replies].sort(
    (a, b) => getConfidence(b) - getConfidence(a)
  );
  const [fallbackAssistantResponse, documentAssistantResponse] = [
    'fallback-assistant',
    'document-assistant',
  ].map(source => sortedReplies.find(reply => reply.source === source));

  if (fallbackAssistantResponse && documentAssistantResponse) {
    const fallbackConfidence = getConfidence(fallbackAssistantResponse);
    const documentConfidence = getConfidence(documentAssistantResponse);

    if (fallbackConfidence >= documentConfidence) {
      const fallbackIndex = sortedReplies.indexOf(fallbackAssistantResponse);
      const documentIndex = sortedReplies.indexOf(documentAssistantResponse);

      sortedReplies.splice(documentIndex, 1);
      sortedReplies.splice(fallbackIndex, 0, documentAssistantResponse);
    }
  }

  return sortedReplies;
};
