import {
  chartAPI,
  ChartAPIReturn,
  ChartAssetData,
  CreateChartOptions,
  PaneData,
} from '@toggle/chart';
import { RefObject, useEffect } from 'react';

import { UseTurboChartReturn } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';

export interface UseChartApiProps {
  canvasRef: RefObject<HTMLCanvasElement | null>;
  chartApiRef: RefObject<null | ChartAPIReturn>;
  chartState: UseTurboChartReturn['chartState'];
  options: CreateChartOptions;
  primaryAsset: ChartAssetData;
  primaryPane: PaneData;
}

export const useChartApi = ({
  chartApiRef,
  canvasRef,
  chartState,
  options,
  primaryAsset,
  primaryPane,
}: UseChartApiProps) => {
  useEffect(() => {
    if (canvasRef.current) {
      chartApiRef.current = chartAPI({
        canvasElement: canvasRef.current,
        options,
      });

      chartApiRef.current?.init({
        horizon: chartState.horizon,
        resampleInterval: chartState.resample,
        domain: chartState.domain,
        panes: chartState.chartPanes,
        primaryAsset,
        primaryPaneId: primaryPane.id,
      });
    }

    return () => {
      chartApiRef.current?.removeListeners();
    };
  }, [options.width, options.height, chartState.chartPanes, primaryPane.id]);
};
