import React, { FC } from 'react';

import { Icon } from '~/components/icon/Icon';
import { ColorScheme } from '~/design-tokens/themes/themes';

import {
  Category,
  CategoryGroup,
} from './components/category-group/CategoryGroup';
import * as S from './SideNavigation.styles';

export const EXPANDED_WIDTH_PX = '200px';
export const COLLAPSED_WIDTH_PX = '68px';

export interface SideNavigationProps {
  activeKey?: string;
  activeSubKey?: string;
  categories: Category[];
  className?: string;
  colorScheme?: ColorScheme;
  disabledKeys?: string[];
  isExpanded?: boolean;
  onLogoClick: () => void;
  onSidebarToggle?: () => void;
}

export const SideNavigation: FC<SideNavigationProps> = ({
  activeKey,
  activeSubKey,
  categories,
  className,
  colorScheme,
  disabledKeys,
  isExpanded = true,
  onLogoClick,
  onSidebarToggle,
}) => {
  return (
    <S.AsideExpandable
      className={className}
      data-testid="side-navigation"
      $isExpanded={isExpanded}
    >
      <S.Header>
        <S.LogoButton onClick={onLogoClick}>
          <S.StyledLogotype isExpanded={isExpanded} colorScheme={colorScheme} />
        </S.LogoButton>

        <S.ToggleButtonWrapper>
          <S.ToggleButton $isExpanded={isExpanded} onClick={onSidebarToggle}>
            <Icon iconName="ChevronBoldRight" size={8} />
          </S.ToggleButton>
        </S.ToggleButtonWrapper>
      </S.Header>

      <S.Categories $isExpanded={isExpanded}>
        {categories.map(category => (
          <CategoryGroup
            key={category.id ?? category.title}
            category={category}
            activeKey={activeKey}
            activeSubKey={activeSubKey}
            disabledKeys={disabledKeys}
            sideBarExpanded={isExpanded}
          />
        ))}
      </S.Categories>
    </S.AsideExpandable>
  );
};
