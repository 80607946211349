import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledLogoImg = styled.img<{ $isCondensed: boolean }>`
  height: ${({ $isCondensed }) => ($isCondensed ? '20px' : '48px')};
  width: ${({ $isCondensed }) => ($isCondensed ? '20px' : '48px')};
  border-radius: 24px;
`;

export const LogoFallback = styled.div<{ $isCondensed: boolean }>`
  display: flex;
  height: ${({ $isCondensed }) => ($isCondensed ? '20px' : '48px')};
  width: ${({ $isCondensed }) => ($isCondensed ? '20px' : '48px')};
  border-radius: 24px;
  background-color: var(--dark-purple-12);
  color: var(--dark-purple-9);
  justify-content: center;
  align-items: center;
  text-transform: capitalize;

  ${({ $isCondensed }) =>
    $isCondensed ? typographyV2.titleXs : typographyV2.titleXL}
`;
