import {
  Dropdown,
  DropdownGenericItem,
  DropdownItem,
  DropdownListItem,
  DropdownProps,
} from '@toggle/design-system';
import React, { MouseEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { RESAMPLE_BUTTON_ID, ResampleIntervals } from '~/types/resample.types';

import * as S from './ResampleSelector.styles';

export interface ResampleSelectorProps {
  selectedResample: ResampleIntervals;
  enabledResamples: ResampleIntervals[];
  onChange: (selectedResample: ResampleIntervals) => void;
  isResampleDropdownOpen: boolean;
  disableMenuBar: boolean;
  onItemsShowChange: DropdownProps<ResampleOption>['onItemsShowChange'];
}

export interface ResampleOption extends DropdownGenericItem<DropdownItem> {
  type: ResampleIntervals;
}

export const ResampleSelector = ({
  selectedResample,
  onChange,
  enabledResamples,
  isResampleDropdownOpen = false,
  disableMenuBar,
  onItemsShowChange,
}: ResampleSelectorProps) => {
  const { t } = useTranslation('chart');

  const items: ResampleOption[] = Object.values(ResampleIntervals).map(
    resampleInterval => ({
      type: resampleInterval,
      label: t('chart:intervals.resample', {
        context: resampleInterval,
      }),
      disabled: !enabledResamples.includes(resampleInterval),
    })
  );

  const onSelectItem = (item: ResampleOption) => {
    onChange(item.type);
  };

  const isClickedOutside = (
    ref: RefObject<HTMLDivElement | null>,
    e: MouseEvent
  ) => {
    const target = e.target as HTMLElement;
    return (
      !!ref.current &&
      target.id !== RESAMPLE_BUTTON_ID &&
      !ref.current.contains(target)
    );
  };

  const selectedResampleIndex = items.findIndex(
    item => item.type === selectedResample
  );

  return (
    <S.ResampleSelectorRoot data-testid="interval-selector">
      <Dropdown
        dropdownItems={items}
        activeItemIdx={selectedResampleIndex}
        strategy="fixed"
        selectItem={onSelectItem}
        placement="bottom-start"
        shouldShowItems={isResampleDropdownOpen}
        shouldDisableItemMouseEvents={disableMenuBar}
        onItemsShowChange={onItemsShowChange}
        isClickedOutside={isClickedOutside}
        shouldSetActiveOnSelect={false}
        renderDropdownItem={(item, isActive, isFocused, isDisabled) => (
          <DropdownListItem
            label={item.label}
            supportiveText={item.supportiveText}
            isActive={isActive}
            isFocused={isFocused}
            isDisabled={isDisabled}
          />
        )}
      >
        <S.StyledChip
          type="choice"
          label={t('chart:intervals.resample', {
            context: selectedResample,
          })}
          disabled={disableMenuBar}
          rightIcon="ChevronLightDown"
        />
      </Dropdown>
    </S.ResampleSelectorRoot>
  );
};
