import { Icon, SvgIconNames, Tooltip } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PriceDisplayReturn } from '~/components/price-display-view/helpers/price-display.types';

import * as S from './LegendPriceIcons.styles';

export interface PriceIcon {
  iconName: SvgIconNames;
  tooltipLabel: string;
  color: string;
  shouldShow?: boolean;
}

export interface LegendPriceIconsProps {
  price: PriceDisplayReturn;
  iconInfo: PriceIcon;
}

export const LegendPriceIcons = ({
  price,
  iconInfo,
}: LegendPriceIconsProps) => {
  const { t } = useTranslation('chart');
  const iconSize = 14;

  return (
    <S.LegendPriceIconsRoot data-testid="legend-live-price-icons">
      <Tooltip
        label={
          price.primary.isLive
            ? /* i18next-extract-disable-next-line */
              t(iconInfo.tooltipLabel)
            : t('chart:lastCloseTooltip')
        }
        closeLabel={t('chart:tapToClose')}
        isTouchDevice={false}
        inPortal
      >
        <S.IconSpan data-testid="icon-wrapper">
          {price.primary.isLive && price.secondary && (
            <Icon
              data-testid="secondary-icon"
              iconName={iconInfo.iconName}
              size={iconSize}
              fillColor={iconInfo.color}
            />
          )}
          <Icon
            iconName={price.primary.isLive ? 'WavePulse' : 'CalendarClock'}
            size={iconSize}
            fillColor={
              price.primary.isLive ? 'var(--viz-bullish)' : 'var(--icon-soft)'
            }
          />
        </S.IconSpan>
      </Tooltip>
    </S.LegendPriceIconsRoot>
  );
};

LegendPriceIcons.Styled = S;
