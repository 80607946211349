import { fontWeight, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const TriggerLabel = styled.span<{ $hasNavigableOption?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${props =>
    props.$hasNavigableOption &&
    css`
      color: var(--text-primary);
      font-weight: ${fontWeight.medium};
      justify-content: center;
    `}
`;

export const DropdownLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownLabelSubtitle = styled.span`
  color: var(--text-soft);
  ${typographyV2.bodyXs}
`;
