import { Button, Input } from '@toggle/design-system';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { authPaths } from '~/routes/app-paths';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';
import * as S from './ForgotPasswordView.styles';
import { useForgotPasswordState } from './useForgotPasswordState';

export const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const {
    email,
    clearError,
    validateEmail,
    updateEmail,
    postReqResetPassword,
    requestState: { isPending, isSuccess },
    errorText,
  } = useForgotPasswordState();
  const [successTabView, setSuccessTabView] = useState(false);

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    updateEmail(value);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (errorText || isPending) {
      return;
    }

    //Field validation
    const isValid = validateEmail(email);

    if (!isValid) {
      return;
    }

    setSuccessTabView(true);
    postReqResetPassword(email);
  };

  const loginRedirect = () => {
    navigate(authPaths.login);
  };

  const isLinkSent = isSuccess && successTabView;

  return (
    <AuthSharedStyles.Layout>
      <S.Wrapper>
        <S.Container>
          {!isLinkSent && <AuthSharedStyles.Logo alt="logo" title="logo" />}
          <S.Form onSubmit={onSubmit} data-testid="forgot-password">
            {isLinkSent ? (
              <S.EmailSentWrapper>
                <S.LetterSvg />
                <S.SuccessTitle data-testid="success-title">
                  {t('auth:forgotResetPassword.checkInbox')}
                </S.SuccessTitle>
                <S.SuccessText data-testid="success-description">
                  {t('auth:forgotResetPassword.detailedNote')}
                </S.SuccessText>
                <S.SuccessEmailWrapper>{email}</S.SuccessEmailWrapper>
                <S.BackToLoginButton
                  data-testid="back-to-login"
                  label={t('auth:forgotResetPassword.backToLogin')}
                  onClick={loginRedirect}
                  variant="tertiary"
                  size="large"
                />
              </S.EmailSentWrapper>
            ) : (
              <S.ForgotPassWrapper>
                <AuthSharedStyles.Heading>
                  {t('auth:forgotResetPassword.heading')}
                </AuthSharedStyles.Heading>
                <S.Description>
                  {t('auth:forgotResetPassword.provideEmailNote')}
                </S.Description>
                <Input
                  id="email-input"
                  name="email-input"
                  type="text"
                  variant="outline"
                  onChange={onChangeEmail}
                  onFocus={clearError}
                  value={email}
                  hasError={!!errorText}
                  label={t('auth:forgotResetPassword.emailLabel')}
                  errorText={errorText}
                  data-testid="email-input"
                />
                <AuthSharedStyles.CenterContent>
                  <AuthSharedStyles.SendButtonWrapper>
                    <Button
                      type="submit"
                      fullWidth
                      label={t('auth:forgotResetPassword.sendResetLink')}
                      isLoading={isPending}
                      data-testid="submit-button"
                      size="large"
                    />
                  </AuthSharedStyles.SendButtonWrapper>
                  <AuthSharedStyles.CancelButton
                    to={authPaths.login}
                    data-testid="cancel-button"
                  >
                    {t('auth:forgotResetPassword.cancelButton')}
                  </AuthSharedStyles.CancelButton>
                </AuthSharedStyles.CenterContent>
              </S.ForgotPassWrapper>
            )}
          </S.Form>
        </S.Container>
      </S.Wrapper>
    </AuthSharedStyles.Layout>
  );
};
