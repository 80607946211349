import { ColumnDef, Table } from '@tanstack/react-table';
import { Entity, formatTicker, mapAssetClass } from '@toggle/toggle';
import { TFunction } from 'i18next';
import React from 'react';

import { RowSelectionCheckbox } from '~/components/row-selection-checkbox/RowSelectionCheckbox';
import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';

import * as S from './TableWatchlistManager.styles';

export const getColumns = (
  t: TFunction,
  toggleAllRowsSelection: (checked: boolean) => void
) => {
  const columns: ColumnDef<Entity>[] = [
    {
      id: 'rowSelected',
      header: ({ table }: { table: Table<Entity> }) => (
        <RowSelectionCheckbox
          name="watchlist-select"
          onChange={(_, e) => toggleAllRowsSelection(e.target.checked)}
          checked={table.getIsAllRowsSelected()}
        />
      ),
      cell: () => <span />,
    },
    {
      id: 'entity',
      header: t('watchlistManager:tickerColumnLabel'),
      cell: props => {
        const item = props.row.original;
        const ticker = formatTicker(item);
        return (
          <S.LogoAndTickerWrapper>
            <S.StyledAssetLogoContainer entity={item} size={20} />
            <TruncateTooltip
              atLength={9}
              text={ticker}
              truncatedLabelWrapper={<S.Text>{ticker}</S.Text>}
            />
          </S.LogoAndTickerWrapper>
        );
      },
    },
    {
      id: 'name',
      header: t('watchlistManager:nameColumnLabel'),
      cell: props => {
        const item = props.row.original;
        return (
          <TruncateTooltip
            atLength={15}
            text={item.name}
            truncatedLabelWrapper={<S.Text>{item.name}</S.Text>}
          />
        );
      },
    },
    {
      id: 'type',
      header: t('watchlistManager:typeColumnLabel'),
      cell: props => {
        const { sub_class } = mapAssetClass(props.row.original);
        return (
          sub_class && (
            /* i18next-extract-disable-next-line  */
            <S.Text>{t(`analyze:assetSubClass.${sub_class}`)}</S.Text>
          )
        );
      },
    },
  ];

  return columns;
};
