import { ScreenerFilter } from '@toggle/toggle';
import { TFunction } from 'i18next';

const columnsFilters = [
  { id: 'history', units: '' },
  { id: 'quality', units: '' },
  { id: 'min', units: '%' },
  { id: 'max', units: '%' },
  { id: 'mean', units: '%' },
  { id: 'median', units: '%' },
  { id: 'p20', units: '%' },
  { id: 'p80', units: '%' },
  { id: 'median_1m', units: '%' },
  { id: 'median_1w', units: '%' },
  { id: 'median_2w', units: '%' },
  { id: 'median_3m', units: '%' },
  { id: 'median_6m', units: '%' },
];

export const getColumnFilters = (t: TFunction): ScreenerFilter[] =>
  columnsFilters.map(({ id, units }) => {
    const filterName =
      t('screener:scenario') +
      ' ' +
      /*i18next-extract-mark-context-next-line ['history', 'quality', 'min', 'max', 'mean', 'median', 'p20', 'p80'] */
      t('scenario:table.headers.column', {
        context: id,
      });

    return {
      key: id,
      name: filterName,
      searchable: false,
      visible: false,
      locked: false,
      category: '',
      config: {
        name: filterName,
        multiselect: false,
        options: [],
        custom: {
          name: filterName,
          key: 'custom',
          custom: [
            {
              type: 'select',
              custom: [
                {
                  name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                  key: 'above-or-equal',
                  custom: [
                    {
                      type: 'text',
                      value_field: 'min',
                      value_type: 'float',
                      minimum: Number.NEGATIVE_INFINITY,
                      maximum: Number.POSITIVE_INFINITY,
                      units,
                    },
                  ],
                },
                {
                  name: t('widget:earnings.filters.input.belowOrEqualTo'),
                  key: 'below-or-equal',
                  custom: [
                    {
                      type: 'text',
                      value_field: 'max',
                      value_type: 'float',
                      minimum: Number.NEGATIVE_INFINITY,
                      maximum: Number.POSITIVE_INFINITY,
                      units,
                    },
                  ],
                },
                {
                  name: t('widget:earnings.filters.between'),
                  key: 'between',
                  custom: [
                    {
                      type: 'text',
                      value_field: 'min',
                      value_type: 'float',
                      minimum: Number.NEGATIVE_INFINITY,
                      maximum: Number.POSITIVE_INFINITY,
                      units,
                    },
                    {
                      type: 'label',
                      text: 'and',
                    },
                    {
                      type: 'text',
                      value_field: 'max',
                      value_type: 'float',
                      minimum: Number.NEGATIVE_INFINITY,
                      maximum: Number.POSITIVE_INFINITY,
                      units,
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    };
  });
