import { ButtonLink } from '@toggle/design-system';
import styled from 'styled-components';

export const ListContainer = styled.div`
  position: relative;
`;

export const StyledList = styled.ul<{ $height: number }>`
  height: ${({ $height }) => `${$height}px`};
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  height: 72px;
  width: 100%;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(var(--background-default-rgb), 0),
    rgba(var(--background-default-rgb), 0.6) 50%,
    rgba(var(--background-default-rgb), 1) 100%
  );
  z-index: 1;
`;

export const ExpandButtonContainer = styled.div`
  margin: 12px 0;
`;

export const StyledButtonLink = styled(ButtonLink)`
  &:focus {
    outline: none;
    text-decoration: none;
  }
`;
