import {
  flip,
  offset,
  shift,
  useClientPoint,
  useFloating,
} from '@floating-ui/react';
import {
  AxisUnit,
  ChartAPIReturn,
  ChartAssetData,
  CreateChartOptions,
  EventsConfig,
  PaneData,
} from '@toggle/chart';
import React, { RefObject, useMemo } from 'react';

import { useChartApi } from '~/hooks/use-chart-api/useChartApi';
import { useDomainChangeEventListener } from '~/hooks/use-domain-change-event-listener/useDomainChangeEventListener';
import { TooltipSortConfig } from '~/modules/multi-line-chart/MultiLineChart';
import { useGutterSize } from '~/modules/turbo-chart/hooks/use-gutter-size/useGutterSize';
import { Insights } from '~/views/turbo-chart/components/insights/Insights';
import { useChartInsights } from '~/views/turbo-chart/hooks/use-chart-insights/useChartInsights';
import { useLegendPriceHover } from '~/views/turbo-chart/hooks/use-legend-price-hover/useLegendPriceHover';
import { UseTurboChartReturn } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';

import { SimpleChartTooltip } from '../simple-chart-tooltip/SimpleChartTooltip';

export interface ChartAreaProps {
  canvasRef: RefObject<HTMLCanvasElement | null>;
  chartApiRef: RefObject<null | ChartAPIReturn>;
  enablePriceHover?: boolean;
  options: CreateChartOptions;
  chartState: UseTurboChartReturn['chartState'];
  primaryAsset: ChartAssetData;
  primaryPane: PaneData;
  onDomainChange?: (event: Event) => void;
  tooltipSortConfig?: TooltipSortConfig;
  axisUnit?: string;
  eventsConfig?: EventsConfig;
  isSimpleChart?: boolean;
}

export const ChartArea = ({
  canvasRef,
  chartApiRef,
  enablePriceHover = true,
  options,
  chartState,
  primaryAsset,
  primaryPane,
  onDomainChange,
  tooltipSortConfig,
  axisUnit,
  eventsConfig = {
    TMI: false,
  },
  isSimpleChart = false,
}: ChartAreaProps) => {
  useChartApi({
    chartApiRef,
    canvasRef,
    options,
    chartState,
    primaryAsset,
    primaryPane,
  });

  useDomainChangeEventListener({ canvasRef, onDomainChange });

  const { gutterSize } = useGutterSize({
    canvasRef,
  });

  const priceHover = useLegendPriceHover({
    elementRef: canvasRef,
    enabled: enablePriceHover,
  });

  const { refs, floatingStyles, context } = useFloating({
    open: !!priceHover,
    placement: 'top',
    strategy: 'fixed',
    middleware: [offset(15), flip(), shift({ padding: 5 })],
  });
  useClientPoint(context, {
    x: priceHover?.x,
    y: priceHover?.y,
  });

  const chartData = useMemo(
    () => chartState.chartPanes.flatMap(p => p.chartAssetData),
    [chartState.chartPanes]
  );
  const { insightsLoading, articleInsights } = useChartInsights({
    chartData,
    eventsConfig,
  });

  const refCallback = (node: HTMLCanvasElement) => {
    canvasRef.current = node;
    refs.setReference(node);
  };

  const panes = gutterSize.panes;
  return (
    <>
      {context.open && (
        <SimpleChartTooltip
          ref={refs.setFloating}
          styles={floatingStyles}
          priceHover={priceHover}
          sortConfig={tooltipSortConfig}
        />
      )}
      <canvas
        data-testid="chart-area-canvas"
        ref={refCallback}
        style={{ width: options.width, height: options.height }}
      />
      {!!panes.length && axisUnit && (
        <AxisUnit
          top={-10}
          label={axisUnit}
          isMixed={false}
          tooltipLabel=""
          offset={gutterSize.y - gutterSize.yAxisSizes[0].width}
          gutterWidth={gutterSize.yAxisSizes[0].width}
        />
      )}
      {!!articleInsights.length && !insightsLoading && (
        <Insights
          chartApiRef={chartApiRef}
          canvasRef={canvasRef}
          articleInsightsIds={articleInsights.map(i => i.id)}
          eventsConfig={eventsConfig}
          isSimpleChart={isSimpleChart}
        />
      )}
    </>
  );
};
