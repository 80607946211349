import { Entity } from '@toggle/toggle';
import React, { ReactElement, RefObject } from 'react';

import { ChatInputProps } from '~/components/chat';
import { NewsView } from '~/views/news/components/news-view/NewsView';
import { AssetFinancialsWidget } from '~/widgets/asset-financials-widget/AssetFinancialsWidget';
import { ChartPeerGroup } from '~/widgets/chart-peer-group/ChartPeerGroup';
import { Document } from '~/widgets/document-widget/DocumentWidget';
import { Earnings } from '~/widgets/earnings/Earnings';
import { KnowledgeGraphWidget } from '~/widgets/knowledge-graph-widget/KnowledgeGraphWidget';
import { Overview } from '~/widgets/overview/Overview';

import { TabsType } from './types';

export interface AssetOverviewTabsProps {
  entity: Entity;
  activeTab: TabsType;
  renderChatInput?: (props?: ChatInputProps) => ReactElement;
  overviewTabChartContainerRef?: RefObject<HTMLDivElement | null>;
}

export const TabContent = ({
  entity,
  activeTab,
  renderChatInput,
  overviewTabChartContainerRef,
}: AssetOverviewTabsProps) => {
  const renderElement = () => {
    switch (activeTab) {
      case TabsType.Financials: {
        return <AssetFinancialsWidget entity={entity} />;
      }

      case TabsType.Chart: {
        return <ChartPeerGroup entity={entity} />;
      }

      case TabsType.KnowledgeGraph: {
        return <KnowledgeGraphWidget entity={entity} />;
      }

      case TabsType.NewsInsights: {
        return <NewsView entity={entity} includedFilterKeys={['providers']} />;
      }

      case TabsType.Documents: {
        return (
          <Document entityTag={entity.tag} renderChatInput={renderChatInput} />
        );
      }

      case TabsType.Earnings: {
        return <Earnings entity={entity} renderChatInput={renderChatInput} />;
      }

      case TabsType.Overview:

      default: {
        return (
          <Overview
            entity={entity}
            key={entity.tag}
            overviewTabChartContainerRef={overviewTabChartContainerRef}
          />
        );
      }
    }
  };

  return <>{renderElement()}</>;
};
