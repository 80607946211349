import { Input } from '@toggle/design-system';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalLarge } from '~/components/modal';

export interface CreateWatchlistModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (listName: string) => Promise<void>;
  clearError: () => void;
  watchlistErrorText?: string;
}

export const CreateWatchlistModal: FC<CreateWatchlistModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  clearError,
  isLoading,
  watchlistErrorText,
}) => {
  const { t } = useTranslation('widget');
  const [newListName, setNewListName] = useState('');

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewListName(e.target.value);
    clearError();
  };

  const onPrimaryBtnClick = async () => {
    await onSubmit(newListName);
    setNewListName('');
  };

  const onBeforeClose = () => {
    setNewListName('');
    onClose();
  };

  const isPrimaryBtnDisabled = isLoading || !newListName.trim().length;

  return (
    <ModalLarge
      isOpen={isOpen}
      onClose={onBeforeClose}
      title={t('watchlistTrigger.modalTitle')}
      primaryBtnProps={{
        label: t('watchlistTrigger.saveBtnLabel'),
        disabled: isPrimaryBtnDisabled,
        onClick: () => {
          onPrimaryBtnClick();
        },
        'data-testid': 'submit-new-list-btn',
        isLoading,
      }}
      cancelBtnProps={{
        label: t('watchlistTrigger.cancelBtnLabel'),
        onClick: onBeforeClose,
      }}
      data-testid="create-new-list-modal"
    >
      <Input
        id="watchlist-name-input"
        name="watchlist-name-input"
        label={t('watchlistTrigger.inputLabel')}
        className="filterScreenModalInput"
        value={newListName}
        onChange={onNameInputChange}
        data-testid="watchlist-name-input"
        errorText={watchlistErrorText}
        hasError={!!watchlistErrorText}
      />
    </ModalLarge>
  );
};
