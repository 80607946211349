import { queryString } from '@toggle/helpers';
import {
  BankCard,
  bankCardResponseSchema,
  PriceWithTax,
  priceWithTaxResponseSchema,
  SubscriptionResponse,
  subscriptionSchema,
  subscriptionUserSchema,
  UserAddressPayload,
  UserSubscription,
} from '@toggle/toggle';

import { queryKeys } from '~/routes/app-paths';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const subscriptionEndpoints = {
  subscription: `subscriptions/v2`,
  pricePreview: `subscriptions/v2/price-preview`,
  userSubscription: `subscriptions/v2/user`,
  subscriptionPrice: (priceId: string, queryParams: string) =>
    `subscriptions/v1/price/${priceId}${queryParams}`,
  paymentCard: `payment/v1/card`,
  receipt: (transactionId: string) => `payment/v1/receipt/${transactionId}`,
};

export const getReceipt = async (transactionId: string) => {
  return apiFetch<Blob>(subscriptionEndpoints.receipt(transactionId), {
    method: 'get',
  });
};

export const getSubscription = async () => {
  return apiFetch<SubscriptionResponse>(subscriptionEndpoints.subscription, {
    schema: subscriptionSchema,
  });
};

export interface PostUserSubscriptionProps {
  productId: string;
  priceId?: string;
  promoCode?: string;
}

export const postUserSubscription = async ({
  productId,
  priceId,
  promoCode,
}: PostUserSubscriptionProps) => {
  return apiFetch<UserSubscription>(subscriptionEndpoints.userSubscription, {
    schema: subscriptionUserSchema,
    method: 'post',
    body: {
      subscription_id: productId,
      price_id: priceId,
      promo_code: promoCode,
    },
  });
};

export const getPriceWithTax = (priceId: string, promo?: string) => {
  const queryParams = queryString({
    [queryKeys.promoCode]: promo,
  });
  return apiFetch<PriceWithTax>(
    subscriptionEndpoints.subscriptionPrice(priceId, queryParams),
    {
      schema: priceWithTaxResponseSchema,
    }
  );
};

export interface PricePreviewRequest {
  address?: UserAddressPayload;
  priceId: string;
  signal?: AbortSignal;
  promoCode?: string;
}

export const postPricePreview = ({
  address,
  priceId,
  signal,
  promoCode,
}: PricePreviewRequest) => {
  return apiFetch<PriceWithTax>(subscriptionEndpoints.pricePreview, {
    schema: priceWithTaxResponseSchema,
    method: 'post',
    body: {
      price_id: priceId,
      address,
      promo_code: promoCode,
    },
    options: { signal },
  });
};

export const getBankCard = () => {
  return apiFetch<BankCard>(subscriptionEndpoints.paymentCard, {
    schema: bankCardResponseSchema,
  });
};

export const deleteBankCard = async () => {
  return apiFetch<void>(subscriptionEndpoints.paymentCard, {
    method: 'delete',
  });
};
