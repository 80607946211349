import { PaneData } from '@toggle/chart';
import { DropdownProps } from '@toggle/design-system';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { RangeHorizon, ResampleIntervals } from '~/types/resample.types';
import type { SeriesType } from '~/types/series.types';

import {
  ChartTypeSelector,
  ChartTypeSelectorProps,
} from '../chart-type-selector/ChartTypeSelector';
import { ChipHorizonSelector } from '../chip-horizon-selector/ChipHorizonSelector';
import {
  ResampleOption,
  ResampleSelector,
} from '../resample-selector/ResampleSelector';
import * as S from './ViewController.styles';

export interface ViewControllerProps {
  enabledResamples: ResampleIntervals[];
  horizonRanges: RangeHorizon[];
  selectedRange: RangeHorizon | null;
  selectedResample: ResampleIntervals;
  selectedSeriesType?: SeriesType;
  paneData?: PaneData[];
  onRangeChange: (range: RangeHorizon) => void;
  onResampleChange: (resample: ResampleIntervals) => void;
  onChartTypeChange?: ChartTypeSelectorProps['onChange'];
  onItemsShowChange: DropdownProps<ResampleOption>['onItemsShowChange'];
  isResampleDropdownOpen: boolean;
  disableMenuBar: boolean;
  disable1DHorizon: boolean;
  hasOHLC?: boolean;
  isSimpleChart?: boolean;
  renderFullScreenButton?: () => ReactNode;
  clearAllCharts?: () => void;
  maxHorizon?: RangeHorizon;
}

export const ViewController: FC<ViewControllerProps> = ({
  selectedRange,
  selectedResample,
  paneData,
  onRangeChange,
  onResampleChange,
  onChartTypeChange,
  horizonRanges,
  disable1DHorizon,
  isResampleDropdownOpen,
  disableMenuBar,
  hasOHLC,
  onItemsShowChange,
  enabledResamples,
  isSimpleChart = false,
  renderFullScreenButton,
  clearAllCharts,
  maxHorizon,
}) => {
  const { t } = useTranslation('chart');
  const disabledRange = disable1DHorizon ? [horizonRanges[0]] : [];
  const disabledHorizons = disableMenuBar ? horizonRanges : disabledRange;
  const hasMultipleAssets =
    paneData &&
    (paneData?.length > 1 || paneData?.[0].chartAssetData.length > 1);

  const maxHorizonIndex =
    isSimpleChart && maxHorizon
      ? horizonRanges.findIndex(h => h === maxHorizon)
      : horizonRanges.length - 1;
  const filteredHorizonsRanged = horizonRanges.slice(0, maxHorizonIndex + 1);
  const isEmptyChart = !paneData?.[0].chartAssetData.length;

  return (
    <S.ViewControllerRoot
      data-testid="view-controller"
      $isSimpleChart={isSimpleChart}
    >
      {horizonRanges && (
        <ChipHorizonSelector
          horizonRanges={filteredHorizonsRanged}
          selectedRange={selectedRange}
          onChange={onRangeChange}
          disabledHorizons={disabledHorizons}
        />
      )}

      {!isSimpleChart && (
        <ResampleSelector
          selectedResample={selectedResample}
          onChange={onResampleChange}
          enabledResamples={enabledResamples}
          isResampleDropdownOpen={isResampleDropdownOpen}
          disableMenuBar={disableMenuBar}
          onItemsShowChange={onItemsShowChange}
        />
      )}

      {onChartTypeChange && (
        <S.FlexWrapper>
          <ChartTypeSelector
            selectedSeriesType={paneData?.[0].seriesType}
            paneData={hasMultipleAssets ? paneData : undefined}
            onChange={onChartTypeChange}
            disableMenuBar={disableMenuBar}
            hasOHLC={hasOHLC}
          />
          {renderFullScreenButton?.()}
        </S.FlexWrapper>
      )}
      {!isSimpleChart && !isEmptyChart && clearAllCharts && (
        <S.StyledButton
          data-testid="clear-all-charts-button"
          label={t('chart:clearAllCharts')}
          onClick={clearAllCharts}
          variant="empty"
          size="small"
          iconName="Clear"
        />
      )}
    </S.ViewControllerRoot>
  );
};
