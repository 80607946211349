import {
  DropdownList,
  DropdownListItem,
  TooltipTrigger,
} from '@toggle/design-system';
import React, { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeYAxisSettingsProps } from '~/types/axis.types';
import { PaneData } from '~/types/create.types';
import { RIGHT_CLICK_EVENT, RightClickEvent } from '~/types/events.types';

import * as S from './OptionsMenu.styles';
import { OptionItem, OptionsMenuOptions } from './OptionsMenu.types';

export interface OptionsMenuProps<T> {
  canvasRef: RefObject<HTMLCanvasElement | null>;
  pane: T;
  changeYAxisSettings: (settings: ChangeYAxisSettingsProps) => void;
  resetChartState?: () => void;
}

export const OptionsMenu = <T extends PaneData>({
  canvasRef,
  changeYAxisSettings,
  pane,
  resetChartState,
}: OptionsMenuProps<T>) => {
  const { t } = useTranslation('chart');
  const [tooltipState, setTooltipState] = useState<RightClickEvent>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const canvasElement = canvasRef.current;

    if (!canvasElement) {
      return undefined;
    }

    const onRightClick = (event: Event) => {
      const rightClickEvent = event as CustomEvent<RightClickEvent>;
      setTooltipState(rightClickEvent.detail);
      setIsOpen(true);
    };

    canvasElement.addEventListener(RIGHT_CLICK_EVENT, onRightClick);

    return () => {
      canvasElement.removeEventListener(RIGHT_CLICK_EVENT, onRightClick);
    };
  }, []);

  if (!tooltipState) {
    return null;
  }

  const onItemSelect = (item: OptionItem) => {
    setIsOpen(false);

    if (item.key === OptionsMenuOptions.ChangeYAxisType) {
      changeYAxisSettings({
        type: item.value,
        paneId: pane.id,
      });
    }

    if (item.key === OptionsMenuOptions.ResetChart) {
      resetChartState?.();
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const isPriceDisplayed = pane.priceDisplay === 'price';
  const hasMultipleYAxis = pane.chartAssetData.length > 1;
  const isYAxisOptionItemShown =
    tooltipState.isWithinYAxis && hasMultipleYAxis && isPriceDisplayed;
  const isResetChartOptionItemShown = !!resetChartState;

  const dropdownItems: OptionItem[] = [];

  if (isYAxisOptionItemShown) {
    dropdownItems.push({
      /* i18next-extract-mark-context-next-line ["merged", "split"] */
      label: t('chart:optionsMenu.displayYAxis', {
        context: pane.yAxisType,
      }),
      key: OptionsMenuOptions.ChangeYAxisType,
      value: pane.yAxisType === 'merged' ? 'split' : 'merged',
    });
  }

  if (isResetChartOptionItemShown) {
    dropdownItems.push({
      label: t('chart:optionsMenu.resetChart'),
      key: OptionsMenuOptions.ResetChart,
    });
  }

  return (
    <S.StyledTooltip
      trigger={[TooltipTrigger.Click]}
      isOpen={isOpen}
      placement="top-start"
      closeLabel=""
      hasArrow={false}
      isTouchDevice={false}
      label={
        <DropdownList
          dropdownItems={dropdownItems}
          focusedItemIndex={-1}
          shouldDisableItemMouseEvents={false}
          onItemSelect={onItemSelect}
          currentAction="hover"
          hasV2Styles
          renderDropdownItem={(item, isActive, isFocused) => (
            <DropdownListItem
              data-testid={item.key}
              label={item.label}
              isActive={isActive}
              isFocused={isFocused}
            />
          )}
        />
      }
      onClose={onClose}
    >
      <div
        style={{
          position: 'fixed',
          transform: `translate(${tooltipState.mouseX}px,${tooltipState.mouseY}px)`,
        }}
      />
    </S.StyledTooltip>
  );
};
