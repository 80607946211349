import { useQuery } from '@tanstack/react-query';
import { SingleNews } from '@toggle/toggle';

import { fetchSingleNews } from '~/services/news/news-service';
import { REFETCH_INTERVAL_DASHBOARD } from '~/views/news/utils/utils';

export const QUERY_KEY_SINGLE_NEWS = 'QUERY_KEY_SINGLE_NEWS';

export const useSingleNews = (id: string, placeholderData?: SingleNews) => {
  return useQuery<SingleNews>({
    queryKey: [QUERY_KEY_SINGLE_NEWS, id],
    queryFn: ({ signal }) => fetchSingleNews(id, signal),
    staleTime: REFETCH_INTERVAL_DASHBOARD,
    placeholderData,
  });
};
