import {
  StaticMessage,
  staticMessageSchema,
  User,
  UserAddress,
  UserAddressPayload,
  userAddressSchema,
  userSchema,
  UserTimeZone,
} from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const userEndpoints = {
  user: `u/v1/user`,
  updateUser: (userId?: string) => `u/v1/user/${userId}`,
  userTimezone: `u/v1/tz`,
  userPortfolioSettings: `p/v3/settings/portfolio`,
  address: `u/v1/address`,
};

export const getUserData = () => {
  return apiFetch<User>(userEndpoints.user, {
    schema: userSchema,
  });
};

export const putUserData = (body: User) => {
  return apiFetch<User>(userEndpoints.updateUser(body.id), {
    schema: userSchema,

    method: 'put',
    body,
  });
};

export const putUserTimezone = (body: UserTimeZone) => {
  return apiFetch<StaticMessage>(userEndpoints.userTimezone, {
    schema: staticMessageSchema,

    method: 'put',
    body,
  });
};

export const putUserAddress = (body: UserAddressPayload) => {
  return apiFetch<UserAddress>(userEndpoints.address, {
    schema: userAddressSchema,
    method: 'put',
    body,
  });
};

export const getUserAddress = async () => {
  return apiFetch<UserAddress>(userEndpoints.address, {
    schema: userAddressSchema,
  });
};
