import { useThemePerformance } from '~/hooks/use-theme-overview/useThemeOverview';
import { formatNumber } from '~/views/dashboard/calendar-widget/calendar-event-drawer/drawer-content/utils/number-formatter';

type UseThemePerformanceReturnProps = {
  entityTags: string[];
  numDays?: number; // Defaults to 1 year
};

export const useThemePerformanceReturn = ({
  entityTags,
  numDays,
}: UseThemePerformanceReturnProps) => {
  const { data } = useThemePerformance(entityTags, numDays);
  const returnValue = data?.aggregate[data.aggregate.length - 1].value;
  return {
    value: formatNumber(true, returnValue),
    isNegative: !!returnValue && returnValue < 0,
  };
};
