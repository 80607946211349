import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  ${typographyV2.bodySm};
`;
export const Subtitle = styled.div`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;
