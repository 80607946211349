import { keyboard } from '@toggle/helpers';
import { formatPercentage } from '@toggle/helpers/src/utils/numbers/numbers';
import * as d3 from 'd3';
import React from 'react';

import { Bar } from '../bar/Bar';
import {
  MIDDLE_POSITION,
  PORTFOLIO_ITEM_LEFT_PADDING,
  ROW_HEIGHT,
} from '../constants';
import { HorizontalLabels } from '../horizontal-labels/HorizontalLabels';
import { MappedDominoV2 } from '../HorizontalBarChart';
import { Waves } from '../waves/Waves';
import * as S from './ChartItem.styles';

export interface ChartItemProps {
  data: MappedDominoV2;
  index: number;
  hasMaximum: boolean;
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleBand<string>;
  viewAllText: string;
  onViewAllHandle: () => void;
  onItemClick?: (mappedDomino: MappedDominoV2) => void;
  useOriginalValue: boolean;
  prefix: string;
}

export const ChartItem = ({
  data,
  index,
  hasMaximum,
  xScale,
  yScale,
  viewAllText,
  onViewAllHandle,
  onItemClick,
  useOriginalValue,
  prefix,
}: ChartItemProps) => {
  const extraSpace = index >= 3 && hasMaximum ? ROW_HEIGHT / 2 : 0;
  const verticalPosition =
    (yScale(data.label) || index * ROW_HEIGHT) + extraSpace;

  const chartItemClickHandler = () => {
    onItemClick?.(data);
  };

  const handleKeyDown = (event: React.KeyboardEvent<SVGGElement>) => {
    if (
      event.key === keyboard.enter.key ||
      event.code === keyboard.space.code
    ) {
      event.preventDefault();
      chartItemClickHandler();
    }
  };

  return (
    <React.Fragment key={index}>
      {hasMaximum && index === MIDDLE_POSITION ? (
        <Waves
          svgProps={{
            transform: `translate(0, ${index * ROW_HEIGHT + ROW_HEIGHT})`,
          }}
          viewAllText={viewAllText}
          onViewAllHandle={onViewAllHandle}
        />
      ) : (
        <S.Button
          $isInteractive={!!onItemClick}
          onClick={chartItemClickHandler}
          tabIndex={0}
          role="button"
          onKeyDown={handleKeyDown}
        >
          <S.BackgroundRect y={verticalPosition} />
          <Bar
            value={data.median}
            xScale={xScale}
            yPosition={verticalPosition}
          />
          <HorizontalLabels
            value={data.median}
            leftLabel={data.label}
            tooltipLabel={data.tooltipLabel}
            rightLabel={formatPercentage(data.median, { useOriginalValue })}
            paddingLeft={PORTFOLIO_ITEM_LEFT_PADDING}
            yPosition={verticalPosition}
            prefix={prefix}
          />
        </S.Button>
      )}
    </React.Fragment>
  );
};
