import { ButtonGroup, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const AssessmentContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
`;

export const AssessmentTitle = styled.h3`
  ${typographyV2.titleBase}
  white-space: nowrap;
`;

export const AssessmentTitleSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
`;

export const AssessmentLegendSelector = styled(ButtonGroup)`
  max-width: 274px;
`;

export const AssessmentBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AssessmentGaugeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
`;
