import React from 'react';

import { BarDataPoint } from '../vertical-bar/VerticalBar';
import * as S from './Legend.styles';

export interface LegendProps {
  title?: string;
  data: BarDataPoint[];
  formatValue: (value: number) => string;
  hoveredLabel: string;
}

export const Legend = ({
  title,
  data,
  formatValue,
  hoveredLabel,
}: LegendProps) => {
  const filteredData = data.filter(d => d.label);
  return (
    <S.LegendRoot data-testid="legend">
      {!!title && <S.Title>{title}</S.Title>}
      <S.List>
        {filteredData.map(item => (
          <S.Item key={item.label} $isHovered={item.label === hoveredLabel}>
            <S.Asset>
              <S.Dot $color={item.color} />
              <S.Label>{item.label}</S.Label>
            </S.Asset>
            <S.Value>
              {item.value !== undefined ? formatValue(item.value) : 'N/A'}
            </S.Value>
          </S.Item>
        ))}
      </S.List>
    </S.LegendRoot>
  );
};
