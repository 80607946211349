import { Column } from '@tanstack/react-table';

import { TableAction } from '~/views/screener/results/screener-table-utils';
import { EmptyTableData } from '~/widgets/scenario/utils/scenario-table-utils/scenario-table-utils';

export const useEmptyTableActions = () => {
  const getEnabledKeys = (column: Column<EmptyTableData>) => {
    if (column.id !== 'entity') {
      return [];
    }

    const enabledKeys: TableAction[] = ['ascending', 'descending'];

    if (column.getIsSorted()) {
      enabledKeys.push('clearSort');
    }

    return enabledKeys;
  };

  const handleDropdownAction =
    (column: Column<EmptyTableData>) => (action: TableAction) => {
      if (action === 'ascending') {
        return column.toggleSorting(false);
      }

      if (action === 'descending') {
        return column.toggleSorting(true);
      }

      if (action === 'clearSort') {
        column.clearSorting();
      }
    };

  return {
    getEnabledKeys,
    handleDropdownAction,
  };
};
