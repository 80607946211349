import { IndicatorListItem } from '@toggle/chart';
import { Entity, SnakeMeta } from '@toggle/toggle';
import React from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';

import {
  EntityWithIndicator,
  isEntityItem,
  isThemeItem,
  Item,
  TitleItem,
} from '../../utils';
import { ListEntityItem } from '../list-entity-item/ListEntityItem';
import { ListThemeItem } from '../list-theme-item/ListThemeItem';
import { ListTitleItem } from '../list-title-item/ListTitleItem';

export interface ListItemProps {
  item: Item;
  activeEntity?: Entity;
  isFocused: boolean;
  setFocusedText: React.Dispatch<React.SetStateAction<string | undefined>>;
  searchQuery: string;
  variant?: 'default' | 'secondary';
}

export const ListItem = ({
  item,
  activeEntity,
  isFocused,
  setFocusedText,
  searchQuery,
  variant = 'default',
}: ListItemProps) => {
  if (activeEntity) {
    const indicator = item as SnakeMeta;
    return (
      <IndicatorListItem
        onFocus={setFocusedText}
        isFocused={isFocused}
        item={indicator}
        matchedEntity={activeEntity}
        logoNode={<AssetLogoContainer entity={activeEntity} size={12} />}
      />
    );
  }

  const isTheme = isThemeItem(item);

  if (isTheme) {
    return (
      <ListThemeItem
        theme={item}
        onFocus={setFocusedText}
        highlighted={searchQuery}
        isFocused={isFocused}
      />
    );
  }

  const entityWithIndicator = item as EntityWithIndicator;
  const data = entityWithIndicator.entity ?? item;
  const isEntity = isEntityItem(data);

  if (isEntity) {
    return (
      <ListEntityItem
        entity={data}
        isFocused={isFocused}
        onFocus={setFocusedText}
        highlighted={searchQuery}
        snakeName={entityWithIndicator.snakeMeta?.name.english}
      />
    );
  }

  const titleItem = item as TitleItem;
  return <ListTitleItem item={titleItem} variant={variant} />;
};
