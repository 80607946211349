import { Entity } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '~/components/section-title/SectionTitle';

import * as S from './About.styles';

export interface AboutProps {
  entity: Entity;
  isModal?: boolean;
}

export const About = ({ entity, isModal = false }: AboutProps) => {
  const { t } = useTranslation('widget');

  /* i18next-extract-disable-next-line */
  const sectorName = t([
    `widget:assetOverview.companyProfile.sectorNames.${entity.gics.sector.code}`,
    'widget:assetOverview.companyProfile.sectorNames.notFound',
  ]);
  /* i18next-extract-disable-next-line */
  const industryName = t([
    `widget:assetOverview.companyProfile.sectorNames.${entity.gics.industry_group.code}`,
    'widget:assetOverview.companyProfile.sectorNames.notFound',
  ]);

  if (!entity.company_description) {
    return null;
  }

  return (
    <S.AboutRoot data-testid="about">
      <SectionTitle
        title={t('widget:assetOverview.companyProfile.about', {
          companyName: entity.name,
        })}
      />
      <S.SubTitlesWrapper>
        <S.Label> {t('widget:assetOverview.companyProfile.sector')}</S.Label>
        <S.Value>{sectorName}</S.Value>
      </S.SubTitlesWrapper>
      <S.SubTitlesWrapper>
        <S.Label>
          {t('widget:assetOverview.companyProfile.subIndustry')}
        </S.Label>
        <S.Value>{industryName}</S.Value>
      </S.SubTitlesWrapper>
      <S.Description $isModal={isModal}>
        {entity.company_description}
      </S.Description>
    </S.AboutRoot>
  );
};
