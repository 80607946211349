import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { SkeletonLoader, TableCell } from '@toggle/design-system';
import { isElementScrollbarVisible, useScrollListener } from '@toggle/helpers';
import React, {
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getEmptyColumns } from '~/widgets/scenario/utils/scenario-table-utils/scenario-table-utils';

import { EntityCell } from '../entity-cell/EntityCell';
import { SortableTableHeaderCell } from '../sortable-table-header-cell/SortableTableHeaderCell';
import * as S from './EmptyTable.styles';
import { useEmptyTableActions } from './hooks/useEmptyTableActions';

export interface EmptyTableProps {
  onEditAssets?: () => void;
  entitiesTags?: string[];
  children?: ReactNode;
}

const PAGE_SIZE = 20;

export const EmptyTable = ({
  entitiesTags,
  children,
  onEditAssets,
}: EmptyTableProps) => {
  const { t } = useTranslation('scenario');

  const data = useMemo(
    () => (entitiesTags ? entitiesTags.map(entityTag => ({ entityTag })) : []),
    [entitiesTags]
  );

  const [pagination, setPagination] = useState<PaginationState>(() => ({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  }));

  const containerRef = useRef<HTMLDivElement>(null);
  const columns = useMemo(() => getEmptyColumns(t), [t]);
  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useScrollListener(
    containerRef,
    () => {
      if (table.getCanNextPage()) {
        table.setPageSize(pagination.pageSize + PAGE_SIZE);
      }

      return false;
    },
    [table, pagination],
    { isGlobalScrollListened: false, offset: 45 }
  );

  useLayoutEffect(() => {
    const hasScroll = isElementScrollbarVisible(containerRef, 'vertical');

    if (hasScroll || !containerRef.current) {
      return;
    }

    if (table.getCanNextPage()) {
      table.setPageSize(pagination.pageSize + PAGE_SIZE);
    }
  }, [containerRef, pagination.pageSize]);

  const { getEnabledKeys, handleDropdownAction } = useEmptyTableActions();

  const { rows } = table.getRowModel();

  return (
    <S.EmptyTableRoot ref={containerRef} $isEmpty={rows.length === 0}>
      <S.EmptyTable data-testid="scenario-table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <SortableTableHeaderCell
                  key={header.id}
                  header={header}
                  handleAction={handleDropdownAction(header.column)}
                  enabledKeys={getEnabledKeys(header.column)}
                  enableDropdown={
                    !!data.length && !!getEnabledKeys(header.column).length
                  }
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.id}>
              {row
                .getVisibleCells()
                .filter(row => row.column.id === 'entity')
                .map(cell => (
                  <TableCell key={cell.id} isNumber={false}>
                    <EntityCell entityTag={row.original.entityTag} />
                  </TableCell>
                ))}

              {index === 0 && (
                <S.EmptyCell
                  data-testid="empty-cell"
                  colSpan={columns.length - 1}
                  rowSpan={rows.length}
                >
                  <S.StickyContent $isSticky={rows.length > PAGE_SIZE}>
                    {children}
                  </S.StickyContent>
                </S.EmptyCell>
              )}
            </tr>
          ))}

          {table.getCanNextPage() ? (
            <tr data-testid="loading-row">
              {rows[0].getVisibleCells().map(cell => (
                <TableCell key={cell.id} isNumber={false}>
                  <SkeletonLoader
                    areas={[
                      {
                        styles: {
                          width: '100%',
                          height: '20px',
                          borderRadius: '8px',
                        },
                      },
                    ]}
                  />
                </TableCell>
              ))}
            </tr>
          ) : (
            onEditAssets && (
              <tr>
                <td colSpan={1}>
                  <S.AddAssetButton
                    variant="tertiary"
                    size="small"
                    iconName="Add"
                    label={t('scenario:emptyState.addAsset')}
                    onClick={onEditAssets}
                  />
                </td>
              </tr>
            )
          )}
        </tbody>
      </S.EmptyTable>
    </S.EmptyTableRoot>
  );
};
