import { z } from 'zod';

export enum SortingColumn {
  Entity = 'entity',
  Fundamentals = 'fundamentals',
  Technicals = 'technicals',
  Macroeconomics = 'macroeconomics',
  Combination = 'combination',
}

export enum SortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface PostExploreRequestFilters {
  entity_tag: {
    values: { tags: string[] }[];
  };
  period: {
    values: [{ duration: number; unit: string }];
  };
  direction?: {
    values: { trend: 1 | 2 };
  };
  trading_horizon?: {
    values: { duration: string };
  };
  return_profile?: {
    values: { max: number; min: number };
  };
  insights_quality?: {
    values: { min: number };
  };
}

export interface SortByParams {
  field: SortingColumn;
  desc: boolean;
}

export interface PostExploreNoInsightsRequestFilters {
  entity_tag: {
    values: { tags: string[] }[];
  };
  period: {
    values: [
      {
        duration: number;
        unit: string;
      }
    ];
  };
}

export enum InsightGroup {
  Fundamentals = 'fundamentals',
  Technicals = 'technicals',
  Macroeconomics = 'macroeconomics',
}

export enum IconDisplayRule {
  Fundamentals = 'fundamentals',
  Technicals = 'technicals',
  Macroeconomics = 'macroeconomics',
  Combination = 'combination',
}

export enum ExploreInsightDirection {
  Bearish = 'Bearish',
  Bullish = 'Bullish',
}

export const exploreInsightConditionSchema = z.object({
  group: z.nativeEnum(InsightGroup),
  matched: z.boolean(),
  type: z.string(),
  category: z.string(),
  driver: z.string(),
  method: z.string(),
});

export const singleInsightSchema = z.object({
  id: z.string(),
  direction: z.nativeEnum(ExploreInsightDirection),
  created_at: z.coerce.date(),
  matched: z.boolean(),
  kind: z.nativeEnum(IconDisplayRule),
  conditions: z.array(exploreInsightConditionSchema),
  card: z.object({
    name: z.string(),
  }),
  best_horizon: z.string(),
  episode_count: z.number(),
  median: z.number(),
  stars: z.number(),
});

export const exploreInsightSchema = z.object({
  tag: z.string(),
  insights: z.array(singleInsightSchema),
  //TODO Remove optional when API change is deployed
  matched_count: z.number().optional(),
});
export const exploreResultsSchema = z.object({
  entities: z.array(exploreInsightSchema),
  page: z.object({
    current: z.number(),
    next: z.number().optional(),
    size: z.number(),
  }),
});
export const exploreNoInsightsResultsSchema = z.object({
  entities: z.array(z.string()),
});

const groupEnum = z.enum(['none', 'insights', 'Filter Insights by Driver']);

export const filterInsightOption = z.object({
  key: z.string(),
  name: z.string(),
  value: z.record(z.string(), z.unknown()),
  children: z.array(
    z.object({
      key: z.string(),
      name: z.string(),
      value: z.record(z.string(), z.unknown()),
    })
  ),
});
export const filterInsightSchema = z.object({
  key: z.string(),
  name: z.string(),
  config: z.object({
    multiselect: z.boolean(),
    options: z.array(filterInsightOption),
  }),
  tooltip: z.string().optional(),
  modal: z.boolean().optional(),
  visible: z.boolean().optional(),
  locked: z.boolean().optional(),
});
const filtersInsights = z.array(filterInsightSchema);

const groupCategoryEnum = z.enum(['Fundamental']);

export const filtersInsightsSchemas = z.object({
  groups: z.array(
    z.object({
      group: groupEnum,
      schemas: z.array(filterInsightSchema),
      children: z
        .array(
          z.object({
            group: groupCategoryEnum,
            schemas: filtersInsights,
          })
        )
        .optional(),
    })
  ),
});

export type FilterInsightsSchemas = z.infer<typeof filtersInsightsSchemas>;
export type ExploreResults = z.infer<typeof exploreResultsSchema>;
export type ExploreNoInsightsResults = z.infer<
  typeof exploreNoInsightsResultsSchema
>;
export type ExploreInsight = z.infer<typeof exploreInsightSchema>;
export type ExploreSingleInsight = z.infer<typeof singleInsightSchema>;
export type ExploreInsightCondition = z.infer<
  typeof exploreInsightConditionSchema
>;
export type ExploreFiltersInsights = z.infer<typeof filtersInsights>;

export const insight = {
  getFilters: {
    path: 'insights/v1/schemas',
    schema: filtersInsightsSchemas,
  },
  postExplore: {
    path: 'insights/v1/explore',
    schema: exploreResultsSchema,
  },
  postExploreNoMatched: {
    path: 'insights/v1/explore/no-matched',
    schema: exploreResultsSchema,
  },
  postExploreNoInsights: {
    path: 'insights/v1/explore/no-insights',
    schema: exploreNoInsightsResultsSchema,
  },
};
