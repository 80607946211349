import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: var(--surface-white);
  overflow-y: auto;
  overflow-x: hidden;
  ${customScrollMinimal};
  color: #000000;
  padding: 8px;
  z-index: 0;
`;
