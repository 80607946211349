import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';

import { WatchlistManagerName } from '../watchlist-manager-name/WatchlistManagerName';
import * as S from './WatchlistManagerHeader.styles';

export interface WatchlistManagerHeaderProps {
  listName: string;
  isRenameActive: boolean;
  onUpdateName: (name: string) => void;
  setIsRenameActive: Dispatch<SetStateAction<boolean>>;
  hasEntities: boolean;
  hasInputError: boolean;
  activeListName?: string;
}

export const WatchlistManagerHeader = (props: WatchlistManagerHeaderProps) => {
  const { t } = useTranslation(['watchlistManager']);

  const openNameInput = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.setIsRenameActive(true);
  };

  return (
    <S.Root>
      <WatchlistManagerName {...props} openNameInput={openNameInput} />
      <S.Description>{t('watchlistManager:description')}</S.Description>
      <S.StyledButtonLink
        label={
          <a
            href={config.urls.helpImportWatchlist}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t('watchlistManager:howToImport')}</span>
          </a>
        }
        iconName="QuestionMarkFill"
        iconSize={14}
      />
    </S.Root>
  );
};
