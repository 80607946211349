import { borderRadius, Card, Input, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { Layout } from '../auth-shared-styles/AuthSharedStyles.styles';

export const Wrapper = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 48px;
`;

export const StyledCard = styled(Card)`
  text-align: center;
  width: 512px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px 40px 40px;
  ${borderRadius.r2}
`;

export const Title = styled.h1`
  ${typographyV2.titleXL}
`;

export const Description = styled.p`
  ${typographyV2.bodyBase}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0;
`;

export const OpenedLetterIcon = styled.img`
  width: 141px;
  height: 137px;
  margin-top: -80px;
`;

export const Contact = styled.div`
  ${typographyV2.bodySm}
  margin-top: 20px;
  display: flex;
  justify-content: center;
  a {
    margin-left: 5px;
    color: var(--text-primary);
  }
`;

export const Form = styled.form`
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  flex: 1;
`;
