import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import React, { useId, useMemo, useRef } from 'react';

import { D3AreaCoordinate, TSPoint } from '../../HighLowTripleLineChart.types';
import { GradientDef, GradientDefProps } from './GradientDef';

export interface GradientAreaProps
  extends Omit<GradientDefProps, 'gradientId'> {
  ts: Array<TSPoint<number, number>>;
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleLinear<number, number>;
  className?: string;
  y0?: D3AreaCoordinate<TSPoint<number, number>>;
  y1?: D3AreaCoordinate<TSPoint<number, number>>;
  curve?: d3.CurveFactory;
}

interface Scale<T> {
  (value: T | number): number;
  invert(point: number): T;
  domain(): T[];
  domain(domain: Iterable<T>): this;
  copy(): this;
  range(): number[];
}

const chartGradientPath = <I, V>(
  xScale: Scale<I>,
  yScale: Scale<V>,
  y0: D3AreaCoordinate<TSPoint<I, V>>,
  y1: D3AreaCoordinate<TSPoint<I, V>>,
  curve: d3.CurveFactory
) => {
  const toAreaPoint =
    (line: D3AreaCoordinate<TSPoint<I, V>>) =>
    (p: TSPoint<I, V>, idx: number, arr: TSPoint<I, V>[]) => {
      const y = typeof line === 'number' ? line : line(p, idx, arr);
      return yScale(y);
    };

  return d3
    .area<TSPoint<I, V>>()
    .x(d => xScale(d.index))
    .y(toAreaPoint(y0))
    .curve(curve)
    .y1(toAreaPoint(y1));
};

export const GradientArea = ({
  ts,
  scaleX,
  scaleY,
  stops,
  className,
  y1 = scaleY.domain()[0],
  y0 = (p: TSPoint<number, number>) => p.value,
  curve = d3.curveLinear,
  gradientY1 = 0,
}: GradientAreaProps) => {
  const areaRef = useRef<SVGPathElement>(null);

  const gradientPath = useMemo(
    () => chartGradientPath<number, number>(scaleX, scaleY, y0, y1, curve)(ts),
    [scaleX, scaleY, y1]
  );

  const uniqueGradientID = useId();

  return (
    <>
      <GradientDef
        gradientY1={gradientY1}
        stops={stops}
        gradientId={uniqueGradientID}
      />
      <path
        ref={areaRef}
        d={gradientPath ?? ''}
        fill={`url(#${uniqueGradientID})`}
        data-testid="gradient-path"
        className={className}
      />
    </>
  );
};
