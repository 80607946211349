import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { QUERY_DEBOUNCE_OPTS, QUERY_DELAY } from '~/config/constants';
import { queryKeys } from '~/routes/app-paths';
import { useRecentSearches } from '~/views/news/hooks/use-recent-searches/useRecentSearches';
import { useNewsSearchStore } from '~/views/news/stores/use-news-search-store/useNewsSearchStore';

import { CloseButton } from '../close-button/CloseButton';
import { SearchInput } from '../search-input/SearchInput';
import * as S from './SearchBar.styles';

export const SearchBar = () => {
  const { t } = useTranslation('news');
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParam = searchParams.get(queryKeys.searchNews) ?? '';

  const setSearchParam = (value: string) => {
    setSearchParams({
      [queryKeys.searchNews]: value,
    });
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { clearSearch, setValue, value, isInputExpanded, setIsInputExpanded } =
    useNewsSearchStore(state => ({
      clearSearch: state.clearSearch,
      setValue: state.setInputValue,
      value: state.inputValue,
      isInputExpanded: state.isInputExpanded,
      setIsInputExpanded: state.setInputExpanded,
    }));

  const [isFocused, setIsFocused] = useState(false);

  const { addRecentSearch } = useRecentSearches();

  useEffect(() => {
    if (queryParam && !value) {
      searchInputRef.current?.focus();
    }
  }, [value]);

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    updateSearchParamsDebounced(event.target.value);
  };

  const updateSearchParamsDebounced = useCallback(
    debounce(
      (value: string) => {
        updateSearchParams(value);
      },
      QUERY_DELAY,
      QUERY_DEBOUNCE_OPTS
    ),
    []
  );

  const handleInputClear = () => {
    clearSearch();
    setSearchParams({});
  };

  const handleClose = () => {
    setIsInputExpanded(false);
    clearSearch();
    setSearchParams({});
    searchInputRef.current?.blur();
  };

  const handleFocus = () => {
    setIsInputExpanded(true);
    setIsFocused(true);
  };

  const updateSearchParams = (value: string) => {
    setSearchParam(value);
    addRecentSearch(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (value) {
      updateSearchParams(value);
      searchInputRef.current?.blur();
    }
  };

  return (
    <>
      <S.SearchBarRoot
        data-testid="search-bar"
        $isExpanded={isInputExpanded}
        $isFocused={isFocused}
      >
        <S.Form onSubmit={handleSubmit}>
          <SearchInput
            ref={searchInputRef}
            onChange={handleQueryChange}
            onClear={handleInputClear}
            onFocus={handleFocus}
            placeholder={
              isInputExpanded
                ? t('news:searchBar.expandedPlaceholder')
                : t('news:searchBar.collapsedPlaceholder')
            }
            value={value}
            showClearButton={isInputExpanded && !!value}
          />
        </S.Form>
      </S.SearchBarRoot>

      {isInputExpanded && <CloseButton onClick={handleClose} />}
    </>
  );
};
