import { borderRadius, customScrollMinimal } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const ChartInsightTooltipContentRoot = styled.div<{
  $isSimpleChart: boolean;
}>`
  display: flex;
  overflow-x: scroll;
  ${customScrollMinimal}
  ${({ $isSimpleChart }) =>
    $isSimpleChart &&
    css`
      transform: scale(0.8);
      margin-bottom: -35px;
    `}
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -100%);
`;

export const ArrowButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: none;
  background: var(--surface-default);
  cursor: pointer;
  ${borderRadius.rFull}

  &:hover {
    background: var(--surface-hover);
  }

  &:disabled {
    cursor: default;
    & svg {
      fill: var(--icon-disabled);
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  gap: 8px;
`;
