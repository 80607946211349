import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const RightHeaderWrapper = styled.div<{ $isNegative: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 16px;
  border-radius: 8px;
  background-color: ${({ $isNegative }) =>
    $isNegative ? 'var(--viz-bearish-soft)' : 'var(--viz-bullish-soft)'};
`;

export const RightHeaderReturnLabel = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const RightHeaderReturnValue = styled.span<{ $isNegative: boolean }>`
  ${typographyV2.titleBase};
  color: ${({ $isNegative }) =>
    $isNegative ? 'var(--viz-bearish)' : 'var(--viz-bullish)'};
  font-family: var(--font-roboto-mono);
`;
