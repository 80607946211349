import { StrictUnion } from '@toggle/helpers';
import { Entity, MappedEntity } from '@toggle/toggle';
import React from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';

import * as S from './CompanyListItem.styles';

const MAX_NAME_LENGTH = 30;

type CompanyListItemProps = {
  company: {
    entity: StrictUnion<Entity | MappedEntity>;
    label: string;
    tooltip?: string;
  };
  onPillClick: (entityTag: string) => void;
  iconSize?: number;
};

export const CompanyListItem = ({
  company,
  onPillClick,
  iconSize = 32,
}: CompanyListItemProps) => {
  return (
    <TooltipContainer label={company.tooltip} placement="top-start">
      <S.PillContainer>
        <S.StyledPillDropdown
          label={
            <S.StyledHeader>
              <AssetLogoContainer size={iconSize} entity={company.entity} />
              <S.AssetHeader>
                <TruncateTooltip
                  placement="top"
                  id={'company-label'}
                  text={company.label}
                  atLength={MAX_NAME_LENGTH}
                />
                <TruncateTooltip
                  placement="bottom"
                  id={'company-name'}
                  text={company.entity.name}
                  atLength={MAX_NAME_LENGTH}
                />
              </S.AssetHeader>
            </S.StyledHeader>
          }
          value={company.entity.tag}
          onClickPill={onPillClick}
        />
      </S.PillContainer>
    </TooltipContainer>
  );
};

CompanyListItem.Styled = S;
