import { ButtonLink } from '@toggle/design-system';
import styled from 'styled-components';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { FlagIcon } from '~/components/flag-icon/FlagIcon';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FlexContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledFlagIcon = styled(FlagIcon)`
  height: 16px;
  width: 16px;
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)`
  border-radius: 8px;
`;

export const StyledShowMorePill = styled(ButtonLink)`
  color: var(--text-default);
  border: 1px solid var(--border-soft);
  padding: 4px 8px;
  border-radius: 8px;

  &:hover {
    color: var(--text-primary);
  }
`;
