import { Button } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Shared from '../Shared.styles';
import * as S from './ScenarioNoAssets.styles';

export interface ScenarioNoAssetsProps {
  label?: string;
  onClick?: () => void;
  hasNoConditions: boolean;
}

export const ScenarioNoAssets = ({
  label,
  onClick,
  hasNoConditions,
}: ScenarioNoAssetsProps) => {
  const { t } = useTranslation('scenario');

  const titleText = hasNoConditions
    ? t('scenario:emptyState.selectAssetsAndConditions')
    : t('scenario:emptyState.selectAssets');

  const descriptionText = hasNoConditions
    ? t('scenario:emptyState.addAssetsAndConditions')
    : t('scenario:emptyState.addAssetsToInitiateAnalysis');

  return (
    <Shared.Container data-testid="scenario-no-assets">
      <Shared.Img src="/app/static/images/icons/no-assets-found.svg" alt="" />
      <S.Description>
        <Shared.Title>{titleText}</Shared.Title>
        <Shared.Message>{descriptionText}</Shared.Message>
      </S.Description>

      <Button
        data-testid="scenario-add-assets-button"
        variant={'secondary'}
        size="medium"
        label={label}
        onClick={onClick}
      />
    </Shared.Container>
  );
};
