import {
  RangeHorizon,
  ResampleIntervals,
  SeriesType,
  YAxisType,
} from '@toggle/chart';
import {
  Condition,
  DetailedInsight,
  Entity,
  Episode,
  HighConvictionReport,
  InsightsPrediction,
  Period,
  Prediction,
} from '@toggle/toggle';
import { ReactNode } from 'react';

type WithConfidenceScore<T> = T & {
  confidence: number;
  scores: Record<string, number>;
};

export enum Feedback {
  Neutral = 'Neutral',
  Bad = 'Bad',
  Good = 'Good',
}

export type ScenarioActions = ActionScenarioRun | ActionWatchlistCreate;

export interface ActionScenarioRun {
  display: string;
  action: 'scenario:run';
  data: {
    conditions: Condition[];
    confidence: number;
    entities: {
      snake: string;
      tag: string;
    }[];
  };
}

export interface ActionWatchlistCreate {
  display: string;
  action: 'watchlist:create';
  data: {
    confidence: number;
    entities: string[];
  };
}

export type Document = {
  title: string;
  url: string;
  pageNumber: number;
};

export type WatchlistData =
  | { action: 'create-list'; status: 'fail' }
  | {
      action: 'create-list';
      info: {
        id: string;
        name?: string;
        new_name?: string;
        entities: string[];
      };
      status: 'success';
    }
  | {
      action: 'create-list';
      issues: CreateListIssues[];
      info: {
        name: string;
        id?: string;
        entities: string[];
      };
      status: 'partial';
    }
  | {
      action: 'delete-list';
      status: 'success';
      info: {
        id: string;
        name?: string;
        new_name?: string;
        entities: string[];
      };
    }
  | { action: 'delete-list'; status: 'fail' }
  | {
      action: 'get-list';
      info: {
        entities?: string[];
        id: string;
        name: string;
        new_name?: string;
      };
      status: 'success';
    }
  | { action: 'get-list'; status: 'fail' }
  | {
      action: 'get-list';
      info: {
        entities?: string[];
        id: string;
        name: string;
      };
      issues: [{ code: 'list-not-found' }];
      status: 'partial';
    }
  | {
      action: 'rename-list';
      info: {
        id: string;
        name: string;
        new_name: string;
        entities?: string[];
      };
      status: 'success';
    }
  | {
      action: 'rename-list';
      info?: { id: string; name: string; new_name: string };
      status: 'fail';
    }
  | {
      action: 'rename-list';
      info: {
        id: string;
        name: string;
        new_name: string;
        entities: string[];
      };
      issues: RenameListIssues[];
      status: 'partial';
    }
  | {
      action: 'add-entities';
      status: 'success';
      info: {
        name: string;
        new_name?: string;
        id: string;
        entities: string[];
      };
    }
  | {
      action: 'add-entities';
      info: { entities: string[]; name: string; id: string };
      issues: [{ code: 'list-not-found'; items: [{ name: 'apple' }] }];
      status: 'partial';
    }
  | {
      action: 'add-entities';
      info?: { entities: string[]; name: string; id: string };
      status: 'fail';
    }
  | {
      action: 'remove-entities';
      info: { entities: string[]; name: string; new_name?: string; id: string };
      status: 'success';
    }
  | {
      action: 'remove-entities';
      info: { entities: string[]; name: string; id: string };
      issues: [
        { code: 'list-not-found' },
        { code: 'multiple-entity-matches'; items: [Record<string, string[]>] },
        { code: 'no-entity-matches'; items: [Record<string, string[]>] }
      ];
      status: 'partial';
    }
  | {
      action: 'remove-entities';
      info: { id: string; entities: string[]; name: string };
      status: 'fail';
    };

type CreateListIssues =
  | { code: 'no-list-name' }
  | { code: 'no-entities' }
  | { code: 'no-entity-matches'; items?: [{ queries: string[] }] }
  | {
      code: 'multiple-entity-matches';
      items: [Record<string, string[]>];
    }
  | { code: 'duplicate-list-name'; items?: [{ name: string }] };

type RenameListIssues =
  | { code: 'list-not-found' }
  | { code: 'duplicate-list-name' };

type ScreeningSnakes = {
  last_date: string;
  last_value: number;
  last_value_formatted: string;
};
export type ScreeningEntities = {
  entity: string;
  snakes: Record<string, ScreeningSnakes>;
};

export type InsightWidgetData = {
  insight: DetailedInsight;
  predictions: InsightsPrediction[];
  entity: Entity;
  snakeTs?: { index: number; value: number }[];
};

export type ChatInsightResponse = {
  source: 'insight-widget';
  data: WithConfidenceScore<InsightWidgetData>;
};

export interface ScenarioWidgetData {
  entityTag: string;
  conditions: Condition[];
  prediction: Prediction[];
  episodes: Episode[];
  period?: Period;
}

export type ChatScenarioResponse = {
  source: 'scenario-widget';
  data: WithConfidenceScore<ScenarioWidgetData>;
};

export type AssetOverviewAssistantResponse = {
  source: 'asset-overview-assistant';
  data: WithConfidenceScore<{ tag: string }>;
};

export type AssetOverviewResponse = {
  source: 'asset-overview-widget';
  data: WithConfidenceScore<{ entity: Entity }>;
};

export type ThemeOverviewResponse = {
  source: 'theme-overview-widget';
  data: WithConfidenceScore<{ selectedThemeId: string }>;
};

export type PastEpisodesData =
  | ScenarioPastEpisodesData
  | InsightPastEpisodesData;

export interface ScenarioPastEpisodesData {
  variant: 'scenario-past-episodes';
  episode: Episode;
}

export interface InsightPastEpisodesData {
  variant: 'insight-past-episodes';
  insight: DetailedInsight;
  entity: Entity;
  hasHeader?: boolean;
}

export type PastEpisodesResponse = {
  source: 'past-episodes-widget';
  data: WithConfidenceScore<PastEpisodesData>;
};

export interface ChatQualityScoreData {
  isFixedIncome?: boolean;
  gaugeValue: number;
  horizon: Period;
  median?: number;
  checklist: HighConvictionReport;
  titleElement?: ReactNode;
  hasHeader?: boolean;
}

export type QualityScoreResponse = {
  source: 'chat-quality-score';
  data: WithConfidenceScore<ChatQualityScoreData>;
};

export type DocumentOverviewData = {
  documents: number;
  companies: number;
  document_types: Record<
    string,
    {
      count: number;
      relevance: number;
    }
  >;
  industries: Record<
    string,
    {
      count: number;
      relevance: number;
    }
  >;
};
export type DocumentData = {
  message: string;
  citationsResult: {
    citations: Record<string, Document> | null;
  };
  citation_group_id: string;
  overview: DocumentOverviewData;
};

export type DocumentResponse = {
  source: 'document-assistant';
  data: WithConfidenceScore<DocumentData>;
  request_id: string;
  feedback?: Feedback;
};

export type WatchlistResponse = {
  source: 'watchlist-assistant';
  data: WithConfidenceScore<WatchlistData>;
  request_id: string;
  feedback?: Feedback;
};

export type ScenarioData = {
  conditions: Condition[];
  entities: { snake: string; tag: string }[];
  actions?: ScenarioActions[];
};
export type ScenarioResponse = {
  source: 'scenario-assistant';
  data: WithConfidenceScore<ScenarioData>;
  request_id: string;
  feedback?: Feedback;
};

export type ScreeningResponse = {
  source: 'screening-assistant';
  data: WithConfidenceScore<{
    entities: ScreeningEntities[];
  }>;
  request_id: string;
  feedback?: Feedback;
};

export type ChartData = {
  assets: { tag: string; snake: string; is_default: boolean }[];
  start?: string;
  end?: string;
  horizon?: RangeHorizon;
  resample?: ResampleIntervals;
  series_type?: SeriesType;
  y_axis_type?: YAxisType;
};
export type ChartResponse = {
  source: 'chart-assistant';
  data: WithConfidenceScore<ChartData>;
  request_id: string;
  feedback?: Feedback;
};

export type FallBackResponse = {
  source: 'fallback-assistant';
  data: WithConfidenceScore<{ answer: string }>;
  request_id: string;
  feedback?: Feedback;
};

export type WebSearchResponse = {
  source: 'websearch-assistant';
  data: WithConfidenceScore<{ answer: string }>;
  request_id: string;
  feedback?: Feedback;
};
