import { GutterSize } from '@toggle/chart';
import React from 'react';

import { useGutterSize } from '~/modules/turbo-chart/hooks/use-gutter-size/useGutterSize';
import { ChartAreaOverlayProps } from '~/views/turbo-chart/components/chart-area-overlay/ChartAreaOverlay';
import { Legend } from '~/views/turbo-chart/components/legend/Legend';
import { useLegendPriceHover } from '~/views/turbo-chart/hooks/use-legend-price-hover/useLegendPriceHover';

export const ChartLegend = ({
  canvasRef,
  chartState,
  panesRefLimitExceeded,
  options,
  chartPaneActions,
  changeChartLineColor,
  removeActiveAsset,
  onChangeTicker,
  hideActiveAsset,
  changeSeriesType,
  onChangeIndicator,
}: ChartAreaOverlayProps) => {
  const { gutterSize } = useGutterSize({
    canvasRef,
    onGutterSizeChange: (gutter: GutterSize) => {
      panesRefLimitExceeded.current = gutter.paneLimitExceeded;
    },
  });

  const panes = chartState.chartPanes;
  const priceHover = useLegendPriceHover({ elementRef: canvasRef });

  if (!panes?.length) {
    return null;
  }

  const selectedSeriesType = panes[0].seriesType;
  return (
    <>
      {gutterSize.panes.map(pane => (
        <Legend
          //handles case when pane is maximized  --> gutterSize.panes only have a single pane
          //while panes always represents all panes
          paneIndex={panes.findIndex(p => p.id === pane.id)}
          paneSize={gutterSize.panes.length}
          key={pane.id}
          pane={pane}
          removeAsset={removeActiveAsset}
          onChangeTicker={onChangeTicker}
          lineColorArray={options.colors?.getMulti()}
          changeChartLineColor={changeChartLineColor}
          hideActiveAsset={hideActiveAsset}
          selectedSeriesType={selectedSeriesType}
          changeSeriesType={changeSeriesType}
          onChangeIndicator={onChangeIndicator}
          chartPaneActions={chartPaneActions}
          priceHover={priceHover}
          legendLogoSize={16}
          hasHover={false}
        />
      ))}
    </>
  );
};
