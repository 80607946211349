import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  width: 100%;
`;

export const TitleDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TitleText = styled.span`
  ${typographyV2.titleBase};
`;

export const DescriptionText = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;
