/*
    1W: 5th element
    2W: 10th element
    1M: 21st element
    3M: 63rd element
    6M: 126th element

    because 1 week is 5 trading days and 1 month is 21
  */

export const getHorizonToIndexMap = (offset = 0) =>
  new Map<string, number>([
    ['1w', 4 + offset],
    ['2w', 9 + offset],
    ['1m', 20 + offset],
    ['2m', 41 + offset],
    ['3m', 62 + offset],
    ['4m', 83 + offset],
    ['5m', 104 + offset],
    ['6m', 125 + offset],
    ['1y', 251 + offset],
  ]);
