import { EconomicEventOutput } from '@toggle/toggle';
import { useTranslation } from 'react-i18next';

import { addAssetFilterOptions } from '~/views/dashboard/calendar-widget/calendar-event-drawer/drawer-content/run-scenario/utils/utils';
import { ConditionOptions } from '~/views/dashboard/calendar-widget/calendar-event-drawer/drawer-content/utils/getConditionOptions';
import { useScenarioAssistant } from '~/widgets/scenario/components/scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';
import { useScenarioEntityFilters } from '~/widgets/scenario/hooks/use-scenario-store/filter-utils';
import { useScenarioStore } from '~/widgets/scenario/hooks/use-scenario-store/useScenarioStore';

import { useFilterActions } from '../use-filter-actions/useFilterActions';
import { createCustomCondition } from './utils';

export type UseRunScenarioProps = {
  data: EconomicEventOutput;
  selectedAssets?: string;
  customConditionOptions?: ConditionOptions;
};

export const useRunScenario = ({
  data,
  selectedAssets,
  customConditionOptions,
}: UseRunScenarioProps) => {
  const { t } = useTranslation(['scenario']);
  const {
    addFilterOption: addAssetFilterOption,
    resetActiveFilterOptions: resetActiveAssetFilterOptions,
  } = useFilterActions({
    store: useScenarioStore,
    group: 'ASSET_FILTERS',
  });
  const { resetActiveFilterOptions: resetActiveConditionFilterOptions } =
    useFilterActions({
      store: useScenarioStore,
      group: 'SCENARIO_CONDITIONS',
    });
  const { manuallyAddCustomCondition, resetConditions } = useScenarioAssistant(
    state => ({
      manuallyAddCustomCondition: state.manuallyAddCustomCondition,
      resetConditions: state.resetConditions,
    })
  );

  const activeAssetFilters = useScenarioEntityFilters();

  const initializeAssetFilterOptions = () => {
    resetActiveAssetFilterOptions();
    addAssetFilterOptions({
      activeAssetFilters,
      selectedAssets,
      addAssetFilterOption,
    });
  };

  const initializeConditionFilterOptions = () => {
    if (!customConditionOptions) {
      return;
    }

    resetActiveConditionFilterOptions();
    resetConditions();
    const customCondition = createCustomCondition(
      customConditionOptions,
      data.country,
      data.actual_unit
    );
    manuallyAddCustomCondition(t, customCondition);
  };

  return {
    initializeAssetFilterOptions,
    initializeConditionFilterOptions,
  };
};
