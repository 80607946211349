import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  ${Button.Styled.Label} {
    ${typographyV2.titleSm};
    font-weight: normal;
    color: var(--text-primary);
  }
  & span > svg {
    fill: var(--text-primary);
  }
`;
