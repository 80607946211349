import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const NewsViewRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 240px;
  gap: 16px;
  padding: 0 20px;
  height: 100%;
  overflow: hidden;
`;

export const LeftContent = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 240px;
`;

export const ListTitle = styled.p`
  padding: 20px 0px 20px 20px;
  ${typographyV2.titleLg};
`;
