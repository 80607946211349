import {
  Country,
  ontology,
  Product,
  SnakeMetaResponseV2,
  Theme,
  TimeSeriesData,
} from '@toggle/toggle';

import { apiFetch, wretchRequest } from '~/utils/api-fetch/apiFetch';

export enum EntityAnalyticsReportType {
  VALUATION = 'valuation',
  ENTRY_POINT = 'entry_point',
  POSITIONING = 'positioning',
  MOMENTUM = 'momentum',
  EQUITY_YIELD = 'equity_yield',
  EQUITY_GROWTH_GAUGE = 'equity_growth_gauge',
  EQUITY_CONSENSUS_TREND = 'equity_consensus_trend',
  VOLUME_ANALYSIS = 'volume_analysis',
  ECONOMIC_GROWTH_GAUGE = 'economic_growth_gauge',
  ECONOMIC_INFLATION_GAUGE = 'economic_inflation_gauge',
  FINANCIAL_CONDITIONS = 'financial_conditions',
  VOLATILITY = 'volatility',
  MAIN_MARKET_PRICE = 'main_market_price',
  MAIN_YIELD = 'main_yield',
  TECHNICAL_ANALYSIS = 'technical_analysis',
  GEOPOLITICAL = 'geopolitical',
  SEASONALITY = 'seasonality',
  SENTIMENT = 'sentiment',
}

interface SummaryReportItemDrillDown {
  label: string;
  latest_value: number;
  percentile: number;
  snake: string;
  method: string;
}

interface SummaryReportItem {
  label: string;
  method_sub_cls: EntityAnalyticsReportType;
  percentile: number;
  pill: string;
  drill_down: SummaryReportItemDrillDown[];
  asset_cls: string;
}

export const aoUrls = {
  OW_ASSET_ITEMS_V3: `co/v3/w/f`,
  OW_SNAKE_BY_ENTITY: `ca/v2/w/p`,
  OW_SNAKE_BY_NAME: `ca/v2/q`,
  SNAKE_META_V3: `snake/v3`,
  CONNECTED_ENTITIES: `kg/v1/connected-entities/proximity`,
};

export const fetchAnalyseData = (entityName: string) =>
  apiFetch<SummaryReportItem[]>(aoUrls.OW_ASSET_ITEMS_V3, {
    method: 'post',
    body: { entity: entityName },
  });

export const fetchSnakeByEntity = (entityName: string, nDays = 0) => {
  const params = nDays !== undefined ? { n_days: nDays } : {};
  return apiFetch<TimeSeriesData>(aoUrls.OW_SNAKE_BY_ENTITY, {
    method: 'post',
    body: { entity: entityName, ...params },
  });
};

export const fetchSnakeByName = (
  snakeName: string,
  nDays?: number,
  signal?: AbortSignal
) => {
  const params = nDays !== undefined ? { date_since: nDays } : {};
  return apiFetch<TimeSeriesData>(aoUrls.OW_SNAKE_BY_NAME, {
    method: 'post',
    body: { snake_expression: snakeName, ...params, static: 1 },
    options: { signal },
  });
};

export const fetchSnakeMetaV2 = (snakesString: string, signal?: AbortSignal) =>
  apiFetch<SnakeMetaResponseV2>(`${aoUrls.SNAKE_META_V3}?q=${snakesString}`, {
    options: { signal },
  });

export const fetchSnakeMeta = (snake: string, signal?: AbortSignal) =>
  wretchRequest<SnakeMetaResponseV2>(`${aoUrls.SNAKE_META_V3}?q=${snake}`, {
    options: { signal },
  }).then(response => {
    // The snake passed from prop it could be aapl.price, but the returned object key can be aapl.price.ds, so it won't match
    const key = Object.keys(response)[0];
    return response[key];
  });

export const fetchConnectedEntities = (entity: string) =>
  apiFetch<string[]>(aoUrls.CONNECTED_ENTITIES, {
    method: 'post',
    body: { entity },
  });

export const fetchCountryExposure = (entityTag: string, signal?: AbortSignal) =>
  wretchRequest<Country[]>(
    ontology.getCountryExposure.path({ tag: entityTag }),
    {
      schema: ontology.getCountryExposure.schema,
      signal,
    }
  );

export const fetchThemeExposure = (
  { entityTag, isEtf }: { entityTag: string; isEtf: boolean },
  signal?: AbortSignal
) =>
  wretchRequest<Theme[]>(
    ontology.getThemeExposure.path({ tag: entityTag, isEtf }),
    {
      schema: ontology.getThemeExposure.schema,
      signal,
    }
  );

export const fetchBrandAndProducts = (entityTag: string) =>
  wretchRequest<Product[]>(
    ontology.getBrandsAndProducts.path({ tag: entityTag }),
    {
      schema: ontology.getBrandsAndProducts.schema,
    }
  );
