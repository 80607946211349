import {
  Company,
  CompanyExposureResponse,
  Entity,
  ontology,
  themeOverview,
} from '@toggle/toggle';

import { postEntity } from '~/services/entities/entity-service';
import { apiFetch, wretchRequest } from '~/utils/api-fetch/apiFetch';

export const COMPANY_FETCH_LIMIT = 10;

export interface CompanyWithEntity {
  entity: Entity;
  company: Company;
}

export interface RelatedCompaniesGrouped {
  competitors: CompanyWithEntity[];
  suppliers: CompanyWithEntity[];
}

export const getEntityForCompanies = async (
  companies: Company[]
): Promise<CompanyWithEntity[]> => {
  const responses = await Promise.all(
    companies.map(async company => {
      const response = await postEntity(company.tag);
      return { entity: response.data, company };
    })
  );

  return responses.filter(res => !!res.entity) as CompanyWithEntity[];
};

export const fetchCompetitorsAndSuppliers = async (entityTag: string) => {
  const [competitorsData, suppliersData] = await Promise.all([
    apiFetch<Company[]>(
      ontology.getCompetitors.path({
        tag: entityTag,
        limit: COMPANY_FETCH_LIMIT,
      }),
      {
        schema: ontology.getCompetitors.schema,
      }
    ),
    apiFetch<Company[]>(
      ontology.getSuppliers.path({
        tag: entityTag,
        limit: COMPANY_FETCH_LIMIT,
      }),
      {
        schema: ontology.getSuppliers.schema,
      }
    ),
  ]);

  return { competitorsData, suppliersData };
};

export const fetchRelatedCompanies = async (entityTag: string) => {
  const { competitorsData, suppliersData } = await fetchCompetitorsAndSuppliers(
    entityTag
  );

  const errors = [competitorsData.error, suppliersData.error].filter(Boolean);

  const [competitors, suppliers] = await Promise.all([
    competitorsData.error
      ? Promise.resolve([])
      : getEntityForCompanies(competitorsData.data),
    suppliersData.error
      ? Promise.resolve([])
      : getEntityForCompanies(suppliersData.data),
  ]);

  return {
    errors,
    competitors,
    suppliers,
  };
};

export const fetchThemeCompanies = async (themeId: string, limit?: number) => {
  const { path, schema } = themeOverview.getCompanyExposure;

  const themeCompanies = await wretchRequest<CompanyExposureResponse>(
    path(themeId, limit),
    {
      schema,
    }
  );

  return getEntityForCompanies(themeCompanies.result);
};
