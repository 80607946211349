import { Breadcrumbs } from '@toggle/design-system';
import styled from 'styled-components';

import { ChartBreadcrumbs } from './chart-breadcrumbs/ChartBreadcrumbs';

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 4px;
`;

export const StyledChartBreadcrumbs = styled(ChartBreadcrumbs)`
  margin-left: 8px;
`;
