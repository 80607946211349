import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';

import { getPreliminaryPrice } from '~/services/price/price-service';

const QUERY_KEY_PRELIMINARY_PRICE = 'QUERY_KEY_PRELIMINARY_PRICE';

export const usePreliminaryPrice = (ticker: string) =>
  useQuery({
    queryKey: [QUERY_KEY_PRELIMINARY_PRICE, ticker],
    queryFn: ({ signal }) => getPreliminaryPrice(ticker, signal),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: !!ticker,
  });
