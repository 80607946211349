import { formatTicker } from '@toggle/toggle';
import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewMoreList } from '~/components/view-more-list/ViewMoreList';
import { useRelatedCompanies } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { httpCode } from '~/utils/api-fetch/httpCode';

import { PeerSection } from '../../components/peer-section/PeerSection';
import * as S from './CompetitorVendor.styles';

export interface CompetitorVendorProps {
  entityTag: string;
}

export const CompetitorVendor = forwardRef(
  (
    { entityTag }: CompetitorVendorProps,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const { data: relatedCompaniesData, isLoading: relatedCompaniesLoading } =
      useRelatedCompanies(entityTag);
    const { t } = useTranslation('widget');
    const { navigateToPage } = useNavigateToAssetOverview();

    const openAssetOverview = (value: string) => {
      navigateToPage({ entityTag: value });
    };

    if (relatedCompaniesLoading) {
      return <S.LoadingSkeleton />;
    }

    const competitors = {
      data:
        relatedCompaniesData?.competitors.map(competitor => ({
          id: competitor.entity.id,
          entity: competitor.entity,
          label: formatTicker(competitor.entity),
          description: competitor.company.evidence,
        })) ?? [],
      title: 'competitors',
    };

    const variants = [competitors];

    if (
      relatedCompaniesData?.errors.some(e => e?.status !== httpCode.notFound)
    ) {
      return null;
    }

    return (
      <div>
        <ViewMoreList height={356}>
          <S.StyledWrapper ref={ref}>
            {variants.map(({ data, title }) => (
              <PeerSection
                key={title}
                data={data}
                /* i18next-extract-mark-context-next-line ["competitors"] */
                title={t('widget:assetOverview.relatedCompany', {
                  context: title,
                })}
                /* i18next-extract-mark-context-next-line ["competitors"] */
                tooltip={t('widget:assetOverview:knowledgeGraphTooltip', {
                  context: title,
                })}
                onPillClick={openAssetOverview}
              />
            ))}
          </S.StyledWrapper>
        </ViewMoreList>
      </div>
    );
  }
);
