import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
} from 'date-fns';

import { Domain, XAxisTick } from '~/types/axis.types';
import { ResampleIntervals } from '~/types/resample.types';
import { Timeseries } from '~/types/timeseries.types';

import {
  axisDateToTimezone,
  dateTransitionMapping,
  DateTransitionTier,
  formatHoursAndMins,
  formatMonth,
  formatYear,
  isDateTransitioning,
  isIntradayResample,
} from '../dates';

interface TicksRangeConfig {
  year?: boolean;
  month?: boolean;
  day?: boolean;
  hour?: boolean;
  condition: (date: Date, lastDate: Date) => number;
  format: (date: Date, isIntraday?: boolean) => string;
  diff: number;
}

type TicksConfig = {
  [x in Exclude<TicksRange, TicksRange.each2_3Mins>]: TicksRangeConfig;
};

export enum TicksRange {
  eachMin = 'eachMin',
  each2_3Mins = 'each2_3Mins',
  each5Mins = 'each5Mins',
  each10Mins = 'each10Mins',
  each15Mins = 'each15Mins',
  each30Mins = 'each30Mins',
  eachHour = 'eachHour',
  each2Hours = 'each2Hours',
  each3Hours = 'each3Hours',
  each6Hours = 'each6Hours',
  each8Hours = 'each8Hours',
  each12Hours = 'each12Hours',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  eachQuarter = 'eachQuarter',
  each4Month = 'each4Month',
  eachHalfYear = 'eachHalfYear',
  eachYear = 'eachYear',
  biYearly = 'biYearly',
  each3Years = 'each3Years',
  each4Years = 'each4Years',
  each5Years = 'each5Years',
  each10Years = 'each10Years',
}

const mapTicksRangeToConfig: TicksConfig = {
  [TicksRange.eachMin]: {
    year: true,
    month: true,
    day: true,
    hour: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 1,
  },
  [TicksRange.each5Mins]: {
    year: true,
    month: true,
    day: true,
    hour: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 5,
  },
  [TicksRange.each10Mins]: {
    year: true,
    month: true,
    day: true,
    hour: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 10,
  },
  [TicksRange.each15Mins]: {
    year: true,
    month: true,
    day: true,
    hour: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 15,
  },
  [TicksRange.each30Mins]: {
    year: true,
    month: true,
    day: true,
    hour: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 30,
  },
  [TicksRange.eachHour]: {
    year: true,
    month: true,
    day: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 60,
  },
  [TicksRange.each2Hours]: {
    year: true,
    month: true,
    day: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 120,
  },
  [TicksRange.each3Hours]: {
    year: true,
    month: true,
    day: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 180,
  },
  [TicksRange.each6Hours]: {
    year: true,
    month: true,
    day: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 360,
  },
  [TicksRange.each8Hours]: {
    year: true,
    month: true,
    day: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 480,
  },
  [TicksRange.each12Hours]: {
    year: true,
    month: true,
    day: true,
    condition: differenceInMinutes,
    format: formatHoursAndMins,
    diff: 720,
  },
  [TicksRange.daily]: {
    year: true,
    month: true,
    condition: differenceInCalendarDays,
    format: date => date.getDate().toString(),
    diff: 1,
  },
  [TicksRange.weekly]: {
    year: true,
    month: true,
    condition: differenceInCalendarWeeks,
    format: date => date.getDate().toString(),
    diff: 1,
  },
  [TicksRange.monthly]: {
    year: true,
    condition: differenceInCalendarMonths,
    format: formatMonth,
    diff: 1,
  },
  [TicksRange.eachQuarter]: {
    year: true,
    condition: (date1, date2) =>
      date1.getMonth() === date2.getMonth() ? 0 : date1.getMonth() + 3,
    format: formatMonth,
    diff: 3,
  },
  [TicksRange.each4Month]: {
    year: true,
    condition: (date1, date2) =>
      date1.getMonth() === date2.getMonth() ? 0 : date1.getMonth() + 4,
    format: formatMonth,
    diff: 4,
  },
  [TicksRange.eachHalfYear]: {
    year: true,
    condition: (date1, date2) =>
      date1.getMonth() === date2.getMonth() ? 0 : date1.getMonth() + 6,
    format: formatMonth,
    diff: 6,
  },
  [TicksRange.eachYear]: {
    condition: differenceInCalendarYears,
    format: formatYear,
    diff: 1,
  },
  [TicksRange.biYearly]: {
    condition: differenceInCalendarYears,
    format: formatYear,
    diff: 2,
  },
  [TicksRange.each3Years]: {
    condition: differenceInCalendarYears,
    format: formatYear,
    diff: 3,
  },
  [TicksRange.each4Years]: {
    condition: differenceInCalendarYears,
    format: formatYear,
    diff: 4,
  },
  [TicksRange.each5Years]: {
    condition: differenceInCalendarYears,
    format: formatYear,
    diff: 5,
  },
  [TicksRange.each10Years]: {
    condition: differenceInCalendarYears,
    format: formatYear,
    diff: 10,
  },
};

const mapResampleIntervalToTicksRange = {
  [ResampleIntervals.OneMinute]: TicksRange.eachMin,
  [ResampleIntervals.FiveMinutes]: TicksRange.each5Mins,
  [ResampleIntervals.FifteenMinutes]: TicksRange.each15Mins,
  [ResampleIntervals.ThirtyMinutes]: TicksRange.each30Mins,
  [ResampleIntervals.OneHour]: TicksRange.eachHour,
  [ResampleIntervals.OneDay]: TicksRange.daily,
  [ResampleIntervals.OneWeek]: TicksRange.weekly,
  [ResampleIntervals.OneMonth]: TicksRange.monthly,
};

const intradayTicksRange = [
  TicksRange.eachMin,
  TicksRange.each2_3Mins,
  TicksRange.each5Mins,
  TicksRange.each10Mins,
  TicksRange.each15Mins,
  TicksRange.each30Mins,
  TicksRange.eachHour,
  TicksRange.each2Hours,
  TicksRange.each3Hours,
  TicksRange.each6Hours,
  TicksRange.each8Hours,
  TicksRange.each12Hours,
];

//If resample interval is bigger than the suggested tick interval, display the tick based on the resample interval
const intradayResampleCheck = (
  resample: ResampleIntervals,
  ticksRange: TicksRange
) => {
  const resampleTicksRange = mapResampleIntervalToTicksRange[resample];
  const idx = intradayTicksRange.findIndex(item => item === resampleTicksRange);

  if (idx === -1) {
    return resampleTicksRange;
  }

  return intradayTicksRange.includes(ticksRange, idx)
    ? ticksRange
    : resampleTicksRange;
};

// eslint-disable-next-line complexity
export const getTicksRange = (
  data: Timeseries,
  domain: [number, number],
  resample: ResampleIntervals
) => {
  const isIntraday = isIntradayResample(resample);
  const start = axisDateToTimezone(new Date(data[domain[0]].time), isIntraday);
  const end = axisDateToTimezone(new Date(data[domain[1]].time), isIntraday);
  let diffInMins;
  let diffInHours;
  let diffInDays;
  let diffInYears;

  if ((diffInMins = differenceInMinutes(end, start)) <= 15) {
    return intradayResampleCheck(resample, TicksRange.eachMin);
  }

  if (diffInMins <= 30) {
    return intradayResampleCheck(resample, TicksRange.each2_3Mins);
  }

  if (diffInMins <= 90) {
    return intradayResampleCheck(resample, TicksRange.each5Mins);
  }

  if ((diffInHours = differenceInHours(end, start)) < 3) {
    return intradayResampleCheck(resample, TicksRange.each10Mins);
  }

  if (diffInHours < 6) {
    return intradayResampleCheck(resample, TicksRange.each15Mins);
  }

  if (diffInHours < 12) {
    return intradayResampleCheck(resample, TicksRange.each30Mins);
  }

  if (diffInHours < 24) {
    return intradayResampleCheck(resample, TicksRange.eachHour);
  }

  if ((diffInDays = differenceInCalendarDays(end, start)) < 2) {
    return intradayResampleCheck(resample, TicksRange.each2Hours);
  }

  if (diffInDays < 3) {
    return intradayResampleCheck(resample, TicksRange.each3Hours);
  }

  if (diffInDays < 4) {
    return intradayResampleCheck(resample, TicksRange.each6Hours);
  }

  if (diffInDays < 5) {
    return intradayResampleCheck(resample, TicksRange.each8Hours);
  }

  if (diffInDays < 8) {
    return intradayResampleCheck(resample, TicksRange.each12Hours);
  }

  if (diffInDays <= 21) {
    return TicksRange.daily;
  }

  if (differenceInWeeks(end, start) < 6) {
    return TicksRange.weekly;
  }

  if (differenceInCalendarMonths(end, start) <= 12) {
    return TicksRange.monthly;
  }

  if ((diffInYears = end.getFullYear() - start.getFullYear()) <= 3) {
    return TicksRange.eachQuarter;
  }

  if (diffInYears <= 5) {
    return TicksRange.each4Month;
  }

  if (diffInYears <= 10) {
    return TicksRange.eachHalfYear;
  }

  if (diffInYears <= 15) {
    return TicksRange.eachYear;
  }

  if (diffInYears <= 30) {
    return TicksRange.biYearly;
  }

  if (diffInYears <= 45) {
    return TicksRange.each3Years;
  }

  if (diffInYears <= 65) {
    return TicksRange.each4Years;
  }

  if (diffInYears <= 85) {
    return TicksRange.each5Years;
  }

  return TicksRange.each10Years;
};

export const createTicksFromRange = (
  ticksRange: TicksRange,
  timeseries: Timeseries,
  domain: [number, number],
  resample: ResampleIntervals
) => {
  const isIntraday = isIntradayResample(resample);
  const ticks = [];

  const tiers = Object.keys(dateTransitionMapping) as DateTransitionTier[];
  const date = intradayTicksRange.includes(ticksRange)
    ? new Date(timeseries[0].time)
    : new Date(timeseries[domain[0]].time);
  let prevDate = axisDateToTimezone(date, isIntraday);

  if (ticksRange === TicksRange.each2_3Mins) {
    for (let index = domain[0]; index <= domain[1]; index++) {
      const currentDate = axisDateToTimezone(
        new Date(timeseries[index].time),
        isIntraday
      );

      const diff = differenceInMinutes(currentDate, prevDate);

      //if diff % 5 === 2 --> correct 2 min interval (2,7,12...)
      //if diff % 5 === 0 --> correct 3 min interval (5,10,15...)
      if (diff % 5 !== 2 && diff % 5 !== 0) {
        continue;
      }

      const dateTransitionKey = tiers.find(tier =>
        isDateTransitioning({
          currentDate,
          prevDate,
          timeseries,
          index,
          tier,
        })
      );

      const tick = createTick({
        dateTransitionKey,
        currentDate,
        index,
        isIntraday,
      });
      ticks.push(tick);
    }

    return ticks;
  }

  const config = mapTicksRangeToConfig[ticksRange];
  let result;
  let prevResult;

  for (let index = domain[0]; index <= domain[1]; index++) {
    const currentDate = axisDateToTimezone(
      new Date(timeseries[index].time),
      isIntraday
    );

    result = config.condition(currentDate, prevDate);

    if (!prevResult) {
      prevResult = result;
    }

    const isMultipleOfTicksRange = result % config.diff === 0;
    const forceTick = result > prevResult + config.diff;

    if (!result || (!isMultipleOfTicksRange && !forceTick)) {
      continue;
    }

    const dateTransitionKey = tiers.find(
      tier =>
        config[tier] &&
        isDateTransitioning({
          currentDate,
          prevDate,
          timeseries,
          index,
          tier,
        })
    );

    let label,
      highlight = false;

    if (dateTransitionKey) {
      label = dateTransitionMapping[dateTransitionKey].format(currentDate);
      highlight = true;
    } else {
      label = config.format(currentDate, isIntraday);
    }

    prevResult = null;
    prevDate = currentDate;
    ticks.push({ index, label, highlight });
  }

  return ticks;
};

interface CalculateXAxisTicksProps {
  timeseries: Timeseries;
  domain: [number, number];
  resampleInterval: ResampleIntervals;
}

export const calculateXAxisTicks = ({
  timeseries,
  domain,
  resampleInterval,
}: CalculateXAxisTicksProps): XAxisTick[] => {
  const roundedDomain: Domain = [Math.floor(domain[0]), Math.floor(domain[1])];
  const ticksRange = getTicksRange(timeseries, roundedDomain, resampleInterval);
  const ticks = createTicksFromRange(
    ticksRange,
    timeseries,
    roundedDomain,
    resampleInterval
  );
  return ticks;
};

interface CreateTickProps {
  dateTransitionKey: DateTransitionTier | undefined;
  currentDate: Date;
  index: number;
  isIntraday: boolean;
}

export const createTick = ({
  dateTransitionKey,
  currentDate,
  index,
  isIntraday,
}: CreateTickProps): XAxisTick => {
  if (dateTransitionKey) {
    const label = dateTransitionMapping[dateTransitionKey].format(currentDate);
    return { index, label, highlight: true };
  }

  const label = formatHoursAndMins(currentDate, isIntraday);
  return { index, label, highlight: false };
};
