import React, { ReactNode, useEffect } from 'react';

import { StyledComponent } from '~/common/styled-component';
import { Portal } from '~/components/portal';

import { Button } from '../button/Button';
import * as S from './Drawer.styles';

export enum CloseTrigger {
  CloseButtonClick = 'CloseButtonClick',
  BackgroundClick = 'BackgroundClick',
  EscapeKey = 'EscapeKey',
}

export interface DrawerProps {
  headerTitle?: ReactNode;
  headerSubtitle?: ReactNode;
  headerButton?: ReactNode;
  isOpen: boolean;
  handleCloseClick: () => void;
  closeTriggers?: CloseTrigger | CloseTrigger[];
  closeIcon?: boolean;
  className?: string;
  children: ReactNode;
}

export const Drawer: StyledComponent<DrawerProps, typeof S> = ({
  className,
  headerTitle,
  headerSubtitle,
  headerButton,
  handleCloseClick,
  closeTriggers = [
    CloseTrigger.CloseButtonClick,
    CloseTrigger.BackgroundClick,
    CloseTrigger.EscapeKey,
  ],
  isOpen,
  children,
  closeIcon = true,
  ...rest
}) => {
  const closeTriggersArray = Array.isArray(closeTriggers)
    ? closeTriggers
    : [closeTriggers];

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleCloseClick();
      }
    };

    if (closeTriggersArray.includes(CloseTrigger.EscapeKey) && isOpen) {
      window.addEventListener('keyup', keyHandler);
    }

    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  }, [isOpen, handleCloseClick]);

  const onBackgroundOverlayClick = () => {
    if (closeTriggersArray.includes(CloseTrigger.BackgroundClick)) {
      handleCloseClick();
    }
  };

  const onCloseButtonClick = () => {
    if (closeTriggersArray.includes(CloseTrigger.CloseButtonClick)) {
      handleCloseClick();
    }
  };

  return (
    <Portal>
      <S.RootContainer className={className}>
        <S.BackgroundOverlay
          onClick={onBackgroundOverlayClick}
          $isOpen={isOpen}
          data-testid="background-overlay"
        />
        <S.Container data-testid="drawer-container" $isOpen={isOpen} {...rest}>
          <S.Header>
            {(headerTitle || headerSubtitle) && (
              <div>
                {headerTitle && <S.Title>{headerTitle}</S.Title>}
                {headerSubtitle && <S.Subtitle>{headerSubtitle}</S.Subtitle>}
              </div>
            )}

            <S.ButtonContainer>
              {headerButton}
              {closeIcon && (
                <Button
                  data-testid="drawer-header-close-btn"
                  onClick={onCloseButtonClick}
                  iconName="Close"
                  variant="empty"
                />
              )}
            </S.ButtonContainer>
          </S.Header>
          <S.Body>{children}</S.Body>
        </S.Container>
      </S.RootContainer>
    </Portal>
  );
};

Drawer.Styled = S;
