import { PillDropdown, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ThemeListItem = styled.li`
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 30% 70%;
  border-bottom: var(--border-soft) solid 1px;
  ${PillDropdown.Styled.PillDropdownRoot} {
    margin-left: 5px;
  }
`;

export const StyledPillDropdown = styled(PillDropdown)`
  ${typographyV2.bodySm}
  align-items: center;
  padding: 12px 4px;
`;

export const StyledListDescription = styled.p`
  ${typographyV2.bodySm}
  display: flex;
  color: var(--text-soft);
  align-items: center;
  padding: 12px 4px;
`;
