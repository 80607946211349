import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { appPaths, queryKeys } from '~/routes/app-paths';
import { ThemeHeader } from '~/views/theme-overview/components/theme-header/ThemeHeader';
import { CompanySection } from '~/views/theme-overview/theme-overview-drawer/components/company-section/CompanySection';
import { CountrySection } from '~/views/theme-overview/theme-overview-drawer/components/country-section/CountrySection';
import { ThemePerformanceSection } from '~/views/theme-overview/theme-overview-drawer/components/theme-performance-section/ThemePerformanceSection';
import { ThemeTabs } from '~/views/theme-overview/types';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

import * as S from './ThemeOverviewWidget.styles';

type ThemeOverviewWidgetProps = {
  selectedThemeId: string;
};

export const ThemeOverviewWidget = ({
  selectedThemeId,
}: ThemeOverviewWidgetProps) => {
  const navigate = useNavigate();

  const navigateToTheme = () => {
    const newSearchParams = new URLSearchParams();

    if (!location.pathname.includes(appPaths.themeOverview)) {
      newSearchParams.set(queryKeys.history, location.pathname);
    }

    navigate({
      pathname: `${appPaths.themeOverview}/${selectedThemeId}`,
      search: newSearchParams.toString(),
    });
  };

  const { t } = useTranslation('widget');
  const { viewedThemes, isLoading, getTheme, setInitialActiveTab } =
    useThemeOverviewStore(state => ({
      isLoading: state.isLoading,
      getTheme: state.getTheme,
      setInitialActiveTab: state.setInitialActiveTab,
      viewedThemes: state.viewedThemes,
    }));
  const theme = viewedThemes[selectedThemeId];

  if (isLoading) {
    return <S.LoadingSkeleton />;
  }

  if (!theme) {
    return (
      <S.StyledErrorMessageWrapper onClick={() => getTheme(selectedThemeId)} />
    );
  }

  return (
    <S.Container>
      <S.Content>
        <ThemeHeader
          themeId={selectedThemeId}
          themeName={theme.name}
          logo_url={theme.logo_url}
        />
        <S.DescriptionText>{theme.description}</S.DescriptionText>
        <ThemePerformanceSection />
        <S.HorizontalDivider />
        <CompanySection
          onFooterClick={() => {
            setInitialActiveTab(ThemeTabs.Companies);
            navigateToTheme();
          }}
        />
        <S.HorizontalDivider />
        <CountrySection onFooterClick={navigateToTheme} />
      </S.Content>
      <S.FullscreenButton
        variant="tertiary"
        iconName="Expand"
        onClick={navigateToTheme}
        label={t('fullScreen')}
      />
    </S.Container>
  );
};
