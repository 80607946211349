import { NewsFilter, NewsOption } from '~/services/news/news.types';

type ToggleFilterOptionProps = {
  filters: NewsFilter[];
  optionKey?: string;
  filterKey: string;
};

export type ToggleOptionProps = {
  option: NewsOption;
  optionKey?: string;
  someOptionsChecked: boolean;
};

export const toggleOption = ({
  optionKey,
  option,
  someOptionsChecked,
}: ToggleOptionProps) => {
  if (!optionKey) {
    return {
      ...option,
      isChecked: !someOptionsChecked,
    };
  }

  if (option.key === optionKey) {
    return {
      ...option,
      isChecked: !option.isChecked,
    };
  }

  return option;
};

export const toggleFilterOption = ({
  filters,
  optionKey,
  filterKey,
}: ToggleFilterOptionProps): NewsFilter[] => {
  return filters.map(filter => {
    if (filter.key === filterKey) {
      return {
        ...filter,
        options: filter.options.map(option =>
          toggleOption({
            optionKey,
            option,
            someOptionsChecked: filter.options.some(o => o.isChecked),
          })
        ),
      };
    }

    return filter;
  });
};
