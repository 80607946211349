import React, { useId, useState } from 'react';

import { checkHasProfit } from '~/charts/helpers/chart-values/chart-values';
import { TextWithBgAlign } from '~/charts/text-with-bg/text-with-bg-utils';

import * as Shared from '../../../SharedStyles.styles';
import { TextLabel } from '../text-label/TextLabel';
import * as S from './HighLowBar.styles';

export interface HighLowMedianDataPoint {
  high: number;
  low: number;
  median: number;
  label?: string;
}

export interface HighLowBarProps {
  dataPoint: HighLowMedianDataPoint;
  yHigh: number;
  yLow: number;
  yMedian: number;
  y0: number;
  showMedianLabel?: boolean;
  enableMouseInteraction?: boolean;
  numberFormatter: (n: number) => string;
  hasTextWithBg?: boolean;
}

const BAR_WIDTH_PX = 9;
const BAR_HALF_WIDTH_PX = BAR_WIDTH_PX / 2;
const HIGH_LOW_VALUE_PADDING = 4;

export const HighLowBar = ({
  dataPoint,
  yHigh,
  yLow,
  yMedian,
  y0,
  showMedianLabel = true,
  enableMouseInteraction = true,
  numberFormatter,
  hasTextWithBg = false,
}: HighLowBarProps) => {
  const [showHighLowValues, setShowHighLowValues] = useState(false);
  const clipPathId = useId();
  const hasMedianProfit = checkHasProfit(dataPoint.median);

  const highStart = yHigh;
  const highEnd = dataPoint.low > 0 ? yLow : y0;
  const highHeight = highEnd - highStart;

  const lowStart = dataPoint.high < 0 ? yHigh : y0;
  const lowEnd = yLow;
  const lowHeight = lowEnd - lowStart;

  const fullStart = yHigh;
  const fullHeight = lowEnd - highStart;

  const onMouseEnter = () => {
    setShowHighLowValues(true);
  };

  const onMouseLeave = () => {
    setShowHighLowValues(false);
  };

  return (
    <g
      data-testid="high-low-bar"
      onMouseEnter={enableMouseInteraction ? onMouseEnter : undefined}
      onMouseLeave={enableMouseInteraction ? onMouseLeave : undefined}
    >
      <clipPath id={clipPathId}>
        <rect
          width={BAR_WIDTH_PX}
          x={-BAR_HALF_WIDTH_PX}
          y={fullStart}
          height={fullHeight}
          rx={BAR_HALF_WIDTH_PX}
        />
      </clipPath>

      <g clipPath={`url(#${clipPathId})`}>
        {dataPoint.high > 0 && (
          <Shared.Rect
            data-testid="high-bar"
            width={BAR_WIDTH_PX}
            x={-BAR_HALF_WIDTH_PX}
            y={highStart}
            height={highHeight}
            $hasProfit={checkHasProfit(dataPoint.high)}
          />
        )}

        {dataPoint.low < 0 && (
          <Shared.Rect
            data-testid="low-bar"
            width={BAR_WIDTH_PX}
            x={-BAR_HALF_WIDTH_PX}
            y={lowStart}
            height={lowHeight}
            $hasProfit={checkHasProfit(dataPoint.low)}
          />
        )}
      </g>

      <g data-testid="median-label" transform={`translate(0, ${yMedian})`}>
        <Shared.AnimatedGroup>
          <Shared.Circle r={BAR_HALF_WIDTH_PX - 1} />
          {showMedianLabel && (
            <TextLabel
              hasProfit={hasMedianProfit}
              label={numberFormatter(dataPoint.median)}
              x={BAR_WIDTH_PX}
            />
          )}
        </Shared.AnimatedGroup>
      </g>

      {showHighLowValues &&
        (hasTextWithBg ? (
          <>
            <S.StyledTextWithBg
              $hasProfit={checkHasProfit(dataPoint.high)}
              position={TextWithBgAlign.Top}
              translateX={0}
              translateY={highStart - HIGH_LOW_VALUE_PADDING}
              data-testid="high-value-with-bg"
            >
              {numberFormatter(dataPoint.high)}
            </S.StyledTextWithBg>
            <S.StyledTextWithBg
              $hasProfit={checkHasProfit(dataPoint.low)}
              position={TextWithBgAlign.Bottom}
              translateX={0}
              translateY={lowEnd + HIGH_LOW_VALUE_PADDING}
              data-testid="low-value-with-bg"
            >
              {numberFormatter(dataPoint.low)}
            </S.StyledTextWithBg>
          </>
        ) : (
          <>
            <S.Value
              $hasProfit={checkHasProfit(dataPoint.high)}
              textAnchor="middle"
              dominantBaseline="after-edge"
              y={highStart - HIGH_LOW_VALUE_PADDING}
              data-testid="high-value"
            >
              {numberFormatter(dataPoint.high)}
            </S.Value>
            <S.Value
              $hasProfit={checkHasProfit(dataPoint.low)}
              alignmentBaseline="before-edge"
              textAnchor="middle"
              y={lowEnd + HIGH_LOW_VALUE_PADDING}
              data-testid="low-value"
            >
              {numberFormatter(dataPoint.low)}
            </S.Value>
          </>
        ))}
    </g>
  );
};
