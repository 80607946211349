import { createGlobalStyle, css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

const baseScroll = css`
  &::-webkit-scrollbar {
    width: 13px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--border-soft);
    border: 3px solid transparent;
    border-left: 4px solid transparent;
    ${borderRadius.r3}
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    border-top: 4px solid transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--border-soft);
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const customScrollMinimal = css`
  ${baseScroll}
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const customScrollBold = css`
  ${baseScroll}
  &::-webkit-scrollbar-track {
    background-color: var(--background-default);
  }
  &::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid var(--border-soft);
  }
  &::-webkit-scrollbar-track:vertical {
    border-left: 1px solid var(--border-soft);
  }
`;

export const BaseScrollGlobalStyle = createGlobalStyle`
  body {
    ${customScrollMinimal}
  }
`;
