import { InputProps } from '@toggle/design-system';
import React, { ChangeEventHandler, FC } from 'react';

import * as S from './BaseRenameInput.styles';

interface BaseRenameInputProps extends InputProps {
  className?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BaseRenameInput: FC<BaseRenameInputProps> = ({
  className,
  value,
  onChange,
  maxLength = 30,
  ...rest
}) => {
  return (
    <S.StyledInput
      className={className}
      data-testid="base-rename-input"
      value={value}
      onChange={onChange}
      rightIcon={`${value.length}/30`}
      maxLength={maxLength}
      {...rest}
    />
  );
};
