import { getCookie, setCookie } from '@toggle/helpers';
import { differenceInDays, isBefore, startOfTomorrow } from 'date-fns';
import { useEffect, useState } from 'react';

export interface UseDismissProps {
  endDate?: string | Date;
  cookieKey: string;
  showBeforeInDays?: number;
  overrideIsVisible?: boolean;
}

const SHOW_BEFORE_IN_DAYS = 7;

export const useDismissContinuous = ({
  endDate,
  cookieKey,
  showBeforeInDays = SHOW_BEFORE_IN_DAYS,
  overrideIsVisible,
}: UseDismissProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // always override if prop is passed in
    if (typeof overrideIsVisible === 'boolean') {
      setIsVisible(overrideIsVisible);
      return;
    }

    if (endDate) {
      const hasAlreadyBeenDismissed = getCookie(cookieKey);

      if (hasAlreadyBeenDismissed) {
        return;
      }

      const today = new Date();
      const until = new Date(endDate);
      const isValid = isBefore(today, until);
      const diff = differenceInDays(until, today);
      const shouldBeVisible = isValid && diff <= showBeforeInDays;
      setIsVisible(shouldBeVisible);
    }
  }, [endDate, cookieKey, showBeforeInDays, overrideIsVisible]);

  const handleDismiss = () => {
    setCookie(cookieKey, cookieKey, { expires: startOfTomorrow() });
    setIsVisible(false);
  };

  return {
    isVisible,
    handleDismiss,
  };
};
