import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const LegendPriceIconsRoot = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--border-soft);
  padding: 2px;
  gap: 2px;
  ${borderRadius.r1}
`;

export const IconSpan = styled.span`
  display: flex;
  gap: 2px;
`;
