import {
  ChartAssetData,
  LegendMoreMenu,
  PriceHoverData,
  SeriesType,
} from '@toggle/chart';
import { DropdownListItem, Icon, Tooltip } from '@toggle/design-system';
import { isSnakeWithThreshold, useOutsideClick } from '@toggle/helpers';
import { Entity, formatTicker, macroMethods, mapEntity } from '@toggle/toggle';
import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';

import { useChartTheme } from '../../hooks/use-chart-theme/useChartTheme';
import { UseTurboChartReturn } from '../../hooks/use-turbo-chart/useTurboChart';
import { IndicatorLegendDetails } from '../indicator-legend-details/IndicatorLegendDetails';
import { LegendPrice } from '../legend-price/LegendPrice';
import * as S from './LegendItem.styles';

export interface LegendItemProps {
  changeChartLineColor: (colorId: number) => void;
  changeSeriesType?: (
    chartType: SeriesType,
    { snake }: { snake?: string; paneIndex?: number }
  ) => void;
  chartAssetData: ChartAssetData;
  chartPaneActions: UseTurboChartReturn['chartPaneActions'];
  colorArray: string[];
  hasOHLC: boolean;
  lastPaneIndex: number;
  multiplePlotted: boolean;
  onChangeIndicator?: (entity: Entity) => void;
  onChangeTicker?: (entity: Entity, paneIndex: number) => void;
  renderLogo?: (entity: Entity) => ReactNode;
  onHideAsset: (entity: Entity, isHidden: boolean) => void;
  onRemoveItem: UseTurboChartReturn['removeActiveAsset'];
  paneIndex: number;
  selectedSeriesType?: SeriesType;
  hoverData?: PriceHoverData;
  legendLogoSize?: number;
  hasHover?: boolean;
}

export const LegendItem = ({
  changeChartLineColor,
  changeSeriesType,
  chartAssetData,
  chartPaneActions,
  colorArray,
  hasOHLC,
  lastPaneIndex,
  multiplePlotted,
  onChangeIndicator,
  onChangeTicker,
  onHideAsset,
  onRemoveItem,
  paneIndex,
  selectedSeriesType,
  hoverData,
  legendLogoSize = 20,
  hasHover = true,
  renderLogo = entity => (
    <AssetLogoContainer entity={entity} size={legendLogoSize} />
  ),
}: LegendItemProps) => {
  const { t } = useTranslation('chart');
  const { colors } = useChartTheme();
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const legendItemRef = useRef(null);
  const { moveBetweenPanes } = chartPaneActions;

  const { snakeMeta, ts, lineColorToken, isHidden } = chartAssetData;
  const legendColor = colors.getThemeColor(lineColorToken);

  useOutsideClick({
    ref: legendItemRef,
    callback: () => setIsHighlighted(false),
    eventType: 'click',
  });

  const entity = chartAssetData.entity;
  const entityName = entity?.name;
  const shouldShowEntityName =
    entity?.country?.long === 'kor' || entity?.country?.long === 'jpn';

  const handleChangeColour = (colorIndex: number) => {
    changeChartLineColor(colorIndex);
  };

  const isEconomicSnake =
    snakeMeta?.method_sub_class &&
    macroMethods.includes(snakeMeta.method_sub_class);
  const mappedEntityCountry = entity.country?.long;

  const handleHideAsset = () => {
    onHideAsset(entity, !isHidden);
  };

  const onPaneMove = (direction: 'up' | 'down') => {
    moveBetweenPanes({
      paneIndex,
      snake: snakeMeta?.snake ?? entity.default_snake,
      newPaneIndex: direction === 'up' ? paneIndex - 1 : paneIndex + 1,
    });
  };

  return (
    <S.LegendItemRoot
      data-testid="legend-item"
      ref={legendItemRef}
      $isHighlighted={isMoreMenuOpen || isHighlighted}
      $hasHover={hasHover}
    >
      <S.LegendItemDetails>
        <S.AssetContainer data-testid="asset-container">
          <S.Asset>
            <S.LegendButtonWrapper>
              {renderLogo(entity)}
              {shouldShowEntityName && entityName && (
                <TruncateTooltip
                  data-testid="legend-item-truncate-tooltip"
                  text={entityName}
                  atLength={13}
                  placement="top"
                  inPortal
                  fallbackLabel={
                    <S.EntityNameWrapper>{entityName}</S.EntityNameWrapper>
                  }
                  truncatedLabelWrapper={<S.EntityNameWrapper />}
                />
              )}
              <Tooltip
                label={t('chart:changeData')}
                closeLabel={t('chart:tapToClose')}
                isTouchDevice={false}
                inPortal
                disabled={!onChangeTicker}
              >
                <S.Button
                  data-testid="legend-ticker"
                  $isUpperCase
                  onClick={
                    onChangeTicker
                      ? () => onChangeTicker(entity, paneIndex)
                      : undefined
                  }
                >
                  {isEconomicSnake && mappedEntityCountry
                    ? mappedEntityCountry
                    : formatTicker(entity)}
                </S.Button>
              </Tooltip>
            </S.LegendButtonWrapper>
            {onChangeIndicator && (
              <>
                <S.SeperatorDot iconName="Dot" size={6} />
                <S.LegendButtonWrapper>
                  <Tooltip
                    label={t('chart:changeIndicator')}
                    closeLabel={t('chart:tapToClose')}
                    isTouchDevice={false}
                    inPortal
                  >
                    <S.Button
                      data-testid="legend-indicator"
                      onClick={() => {
                        onChangeIndicator(entity);
                      }}
                    >
                      {snakeMeta?.name.english}
                    </S.Button>
                  </Tooltip>
                </S.LegendButtonWrapper>
              </>
            )}
          </S.Asset>
        </S.AssetContainer>
        <S.MenuButtonContainer>
          <LegendMoreMenu
            chartColor={legendColor}
            colorArray={colorArray}
            onColorSelect={handleChangeColour}
            onMenuOpen={open => setIsMoreMenuOpen(open)}
            changeSeriesType={changeSeriesType}
            hasOHLC={hasOHLC}
            selectedSeriesType={selectedSeriesType}
            defaultSnake={snakeMeta?.snake}
            renderMovePane={() => (
              <>
                {(paneIndex !== 0 || multiplePlotted) && (
                  <DropdownListItem
                    label={t('chart:moveUp')}
                    leadingIcon={<Icon iconName="ArrowUp" size={16} />}
                    isActive={false}
                    isFocused={false}
                    onClick={() => onPaneMove('up')}
                  />
                )}
                {(paneIndex !== lastPaneIndex || multiplePlotted) && (
                  <DropdownListItem
                    label={t('chart:moveDown')}
                    leadingIcon={<Icon iconName="ArrowDown" size={16} />}
                    isActive={false}
                    isFocused={false}
                    onClick={() => onPaneMove('down')}
                  />
                )}
              </>
            )}
          />
          <Tooltip
            label={isHidden ? t('chart:show') : t('chart:hide')}
            closeLabel={t('chart:tapToClose')}
            isTouchDevice={false}
            inPortal
          >
            <S.MenuIconSpan>
              <Icon
                iconName={isHidden ? 'HidePassword' : 'ShowPassword'}
                size={12}
                onClick={handleHideAsset}
              />
            </S.MenuIconSpan>
          </Tooltip>
          <Tooltip
            label={t('chart:remove')}
            closeLabel={t('chart:tapToClose')}
            isTouchDevice={false}
            inPortal
          >
            <S.MenuIconSpan>
              <Icon
                iconName="Close"
                data-testid="Close-icon"
                size={12}
                onClick={() => onRemoveItem(entity.default_snake)}
              />
            </S.MenuIconSpan>
          </Tooltip>
        </S.MenuButtonContainer>
      </S.LegendItemDetails>
      {snakeMeta && (
        <S.PriceContainer>
          {isSnakeWithThreshold(snakeMeta) ? (
            <IndicatorLegendDetails
              chartAssetData={chartAssetData}
              hoverData={hoverData}
            />
          ) : (
            <LegendPrice
              entity={mapEntity(entity)}
              snakeMeta={snakeMeta}
              isChartHovered={false}
              isPrimary={hasOHLC}
              legendColor={legendColor}
              selectedSeriesType={selectedSeriesType}
              ts={ts}
              hoverData={hoverData}
            />
          )}
        </S.PriceContainer>
      )}
    </S.LegendItemRoot>
  );
};

LegendItem.Styled = S;
