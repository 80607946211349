export enum Expectation {
  Beat = 'beat',
  Miss = 'miss',
}

export enum EarningState {
  Beat = 'earning-beat',
  Miss = 'earning-miss',
  Meet = 'earning-meet',
}

export enum PrimaryStatus {
  OnlyEstimate = 'only_estimate',
  OnlyHistory = 'only_history',
  Both = 'both',
  None = 'none',
}

export enum SecondaryStatus {
  NotReported = 'not_reported',
  Analyzing = 'analyzing',
  Reported = 'reported',
}

export type Status = {
  primary: PrimaryStatus;
  secondary: SecondaryStatus;
  tertiary?: EarningState;
};
