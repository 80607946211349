import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ChartEmpty.styles';

export interface ChartEmptyProps {
  title: string;
  description?: string;
}

export const ChartEmpty = ({
  title,
  description,
  ...rest
}: ChartEmptyProps) => {
  const { t } = useTranslation('chart');
  return (
    <S.ChartEmptyRoot data-testid="chart-empty" {...rest}>
      <S.ImageWrapper>
        <img
          src="/app/static/images/illustrations/empty.svg"
          width={80}
          height={80}
          alt={t('chart:empty.imgAlt')}
        />
      </S.ImageWrapper>
      <S.Title>{title}</S.Title>
      {description && <S.Description>{description} </S.Description>}
    </S.ChartEmptyRoot>
  );
};
