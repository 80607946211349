import { format, getMonth } from 'date-fns';
import React, { ReactElement, useState } from 'react';
import { CalendarProps } from 'react-calendar';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { i18n } from '~/i18n';

import { Icon } from '../icon/Icon';
import { Tooltip, TooltipTrigger } from '../tooltip/Tooltip';
import * as S from './DatePicker.styles';

const CUSTOM_DISABLED_CLASS_NAME = 'react-calendar__tile--disabled';
const MONTH_ACTIVE_TILE = 'react-calendar__tile--hasActive';
const MONTH_FORMAT_PATTERN = 'MMM';

interface NavigationTooltipFactoryProp {
  label: string;
  children: ReactElement | string;
}

export type DateValue = Date | null;

enum CalendarView {
  Month = 'month',
  Year = 'year',
  Decade = 'decade',
  Century = 'century',
}

export type DatePickerValue = DateValue | [DateValue, DateValue];

export interface DatePickerProp {
  value: DatePickerValue;
  selectRange?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onDateChange: (newDate: DatePickerValue) => void;
}

export const DatePicker = ({
  value,
  selectRange = false,
  maxDate,
  minDate,
  onDateChange,
  ...rest
}: DatePickerProp) => {
  const { t } = useTranslation(['datePicker']);

  const [currentView, setCurrentView] = useState<CalendarProps['view']>(
    CalendarView.Month
  );

  const isInMonthView = currentView === CalendarView.Month;
  const isDecadeView = currentView === CalendarView.Decade;

  const navigationTooltipFactory = ({
    label,
    children,
  }: NavigationTooltipFactoryProp) => {
    return (
      <Tooltip
        isTouchDevice={false}
        label={label}
        strategy="fixed"
        trigger={TooltipTrigger.Hover}
        inPortal
      >
        <div>{children}</div>
      </Tooltip>
    );
  };

  return (
    <I18nextProvider i18n={i18n}>
      <S.DatePickerWrapper {...rest}>
        <S.CustomDatePicker
          $isShowNavLabel={
            currentView === CalendarView.Month ||
            currentView === CalendarView.Year
          }
          $isSelectRange={isInMonthView && selectRange}
          value={value}
          selectRange={isInMonthView && selectRange}
          maxDate={maxDate}
          minDate={minDate}
          showNeighboringDecade
          minDetail={CalendarView.Decade}
          tileClassName={({ date, view }) => {
            const classNames = [];
            const maxDateCondition =
              maxDate && new Date(date).getTime() > maxDate.getTime();
            const minDateCondition =
              minDate && new Date(date).getTime() < minDate.getTime();
            const shouldDisable = maxDateCondition || minDateCondition;

            if (shouldDisable) {
              classNames.push(CUSTOM_DISABLED_CLASS_NAME);
            }

            if (view === CalendarView.Year && value) {
              const monthOnCalendar = getMonth(date);
              const selectedMonth = getMonth(
                Array.isArray(value) ? (value[0] as Date) : value
              );

              if (monthOnCalendar === selectedMonth) {
                classNames.push(MONTH_ACTIVE_TILE);
              }
            }

            return classNames;
          }}
          formatMonth={(_, date) => format(date, MONTH_FORMAT_PATTERN)}
          navigationLabel={({ label, view }) => {
            return view === CalendarView.Decade
              ? undefined
              : navigationTooltipFactory({
                  /* i18next-extract-mark-context-next-line  ["month", "year"] */
                  label: t('datePicker:select', { context: currentView }),
                  children: label,
                });
          }}
          nextLabel={
            !isDecadeView
              ? navigationTooltipFactory({
                  /* i18next-extract-mark-context-next-line  ["month", "year", "decade"] */
                  label: t('datePicker:next', { context: currentView }),
                  children: <Icon iconName="ChevronLightRight" size={12} />,
                })
              : null
          }
          next2Label={
            isInMonthView
              ? navigationTooltipFactory({
                  label: t('datePicker:next_year'),
                  children: <Icon iconName="AngleLightRight" size={12} />,
                })
              : null
          }
          prevLabel={
            !isDecadeView
              ? navigationTooltipFactory({
                  /* i18next-extract-mark-context-next-line  ["month", "year", "decade"] */
                  label: t('datePicker:previous', { context: currentView }),
                  children: <Icon iconName="ChevronLightLeft" size={12} />,
                })
              : null
          }
          prev2Label={
            isInMonthView
              ? navigationTooltipFactory({
                  label: t('datePicker:previous_year'),
                  children: <Icon iconName="AngleLightLeft" size={12} />,
                })
              : null
          }
          onChange={onDateChange}
          onViewChange={({ view }) => {
            setCurrentView(view);
          }}
        />
      </S.DatePickerWrapper>
    </I18nextProvider>
  );
};

DatePicker.Styled = S;
