import {
  CreateScreenResponse,
  PostFilterBody,
  ScreenerFilter,
  ScreenerFilterGroupKeys,
  TableState,
} from '@toggle/toggle';
import { v4 } from 'uuid';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';
import { WATCHLIST_ID, WORKING_LIST_ID } from '~/config/constants';
import { addFiltersToActiveFilters } from '~/hooks/use-filter-actions/use-filter-actions-utils';
import { FilterGroups } from '~/hooks/use-filter-actions/useFilterActions';
import { useWorkingList } from '~/hooks/use-working-list/useWorkingList';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { getWatchlistEntitiesTags } from '~/stores/use-watchlist/useWatchlist.utils';
import { joinFilterNames } from '~/widgets/scenario/utils/scenario-conditions/scenario-conditions';

import { KNOWLEDGE_GRAPH_TAB } from '../results/screener-table-utils';

export const mapFilters = (
  activeFilters: ActiveFilterOptions[]
): PostFilterBody => {
  const requestFromFilters = activeFilters.reduce<PostFilterBody>(
    (res, filter) => {
      if (filter.options[0].childName && filter.options.length === 1) {
        return res;
      }

      if (!res[filter.filter]) {
        res[filter.filter] = { values: [], children: [] };
      }

      if (filter.filter === ASSET_FILTERS_KEY) {
        const isWatchlistFilter = WATCHLIST_ID in filter.options[0].value;
        const isWorkingList = WORKING_LIST_ID in filter.options[0].value;

        if (isWatchlistFilter) {
          const lists = useWatchlist.getState().lists;
          const watchlistTags = getWatchlistEntitiesTags(
            lists,
            filter.options[0].value.watchlistId
          );

          if (watchlistTags) {
            res.entity_tag.values = [{ tags: watchlistTags }];
          } else {
            delete res.entity_tag;
          }
        } else if (isWorkingList) {
          const workingList = useWorkingList.getState().workingList;
          res.entity_tag.values = [{ tags: workingList }];
        }
      } else {
        filter.options.forEach(o => {
          if (o.childName) {
            res[filter.filter].children?.push(o.value);
          } else {
            res[filter.filter].values.push(o.value);
          }
        });
      }

      return res;
    },
    {}
  );

  return requestFromFilters;
};

export const reduceSavedScreenFilterGroups = (
  filterGroups: FilterGroups<ScreenerFilterGroupKeys>,
  savedFilterOptionsPerGroup: Record<
    ScreenerFilterGroupKeys,
    ActiveFilterOptions[]
  >
) => {
  const keys = Object.keys(filterGroups) as ScreenerFilterGroupKeys[];
  return keys.reduce((res, key) => {
    const activeFilterOptions = savedFilterOptionsPerGroup[key] ?? [];
    const { activeFilters, allFilters } = filterGroups[key];

    const mapKeyToFilter = activeFilters.reduce((res, item) => {
      res[item.key] = item;
      return res;
    }, {} as Record<string, ScreenerFilter>);
    const notVisibleByDefault = activeFilterOptions.reduce<Set<string>>(
      (res, f) => {
        if (!mapKeyToFilter[f.filter]) {
          res.add(f.filter);
        }

        return res;
      },
      new Set()
    );

    res[key] = {
      allFilters,
      activeFilterOptions,
      activeFilters: notVisibleByDefault.size
        ? addFiltersToActiveFilters({
            activeFilters,
            allFilters,
            newFilterKeys: [...notVisibleByDefault],
          })
        : activeFilters,
    };

    return res;
  }, {} as FilterGroups<ScreenerFilterGroupKeys>);
};

export const getSavedScreenFilterGroups = (
  filterGroups: FilterGroups<ScreenerFilterGroupKeys>,
  {
    filterOptionGroups,
    version,
    activeFilterOptions = [],
  }: CreateScreenResponse['config']
) => {
  const finalFilterOptionGroups =
    version === 'v1'
      ? filterOptionGroups!
      : activeFilterOptions.reduce((res, next) => {
          const group: ScreenerFilterGroupKeys = [
            'theme_exposure',
            'country_exposure',
          ].includes(next.filter)
            ? 'Knowledge Graph Filters'
            : 'Market Filters';

          res[group] = res[group] ?? [];

          if (group === 'Knowledge Graph Filters') {
            next.options.forEach(item => {
              const options = [item];
              const id = createFilterId(next.filter);
              res[group].push({ filter: next.filter, id, options });
            });
          } else {
            res[group].push({ ...next, id: next.filter });
          }

          return res;
        }, {} as Record<ScreenerFilterGroupKeys, ActiveFilterOptions[]>);

  return reduceSavedScreenFilterGroups(filterGroups, finalFilterOptionGroups);
};

export const getFilterOptionsPerGroup = (
  filterGroups: FilterGroups<ScreenerFilterGroupKeys>
) => {
  const keys = Object.keys(filterGroups) as ScreenerFilterGroupKeys[];
  return keys.reduce((res, key) => {
    res[key] = filterGroups[key].activeFilterOptions;
    return res;
  }, {} as Record<ScreenerFilterGroupKeys, ActiveFilterOptions[]>);
};

export const createFilterId = (key: string) => `${key}_${v4()}`;

export const getFiltersNamesList = (
  activeFilters: ScreenerFilter[],
  activeFilterOptions: ActiveFilterOptions[]
): string[] => {
  return activeFilterOptions.map(activeFilterOption => {
    const activeFilter = activeFilters.find(
      activeFilter => activeFilter.key === activeFilterOption.filter
    );

    const filterName = activeFilter?.name;
    const filterValues = joinFilterNames(activeFilterOption);

    return `${filterName}: ${filterValues}`;
  });
};

export const ASSET_FILTERS_KEY = 'entity_tag';

export const assetFilter: ScreenerFilter = {
  key: ASSET_FILTERS_KEY,
  name: 'Assets',
  visible: true,
  searchable: false,
  category: 'Universe',
  locked: true,
  config: {
    multiselect: false,
    name: 'Assets',
    options: [],
  },
};

// resets table state analyzing changed table state and saved screen table state
// handles case when some of the columns in the changed table state doesn't exist in the saved screen table state
// and in that case uses saved screen table state
// Note: this is called when resetting filter options to saved screen ones,
// meaning some of the columns may not exist for the saved screen filter options
export const resetKGTableState = (
  tableState: TableState,
  savedScreenTableState: TableState
) => {
  const keys = Object.keys(tableState) as (keyof TableState)[];
  return keys.reduce((res, key) => {
    if (key === 'sorting') {
      res[key] = tableState[key].every(item =>
        savedScreenTableState[key].some(s => s.id === item.id)
      )
        ? tableState[key]
        : savedScreenTableState[key];
    } else {
      res[key] = tableState[key][KNOWLEDGE_GRAPH_TAB]?.every(id =>
        savedScreenTableState[key][KNOWLEDGE_GRAPH_TAB]?.includes(id)
      )
        ? tableState[key]
        : savedScreenTableState[key];
    }

    return res;
  }, {} as TableState);
};

export const clearActiveFilterOptions = (
  filterGroups: FilterGroups<ScreenerFilterGroupKeys>
) =>
  Object.keys(filterGroups).reduce((res, key) => {
    const group = key as ScreenerFilterGroupKeys;
    res[group] = {
      ...filterGroups[group],
      activeFilterOptions: [],
    };
    return res;
  }, {} as FilterGroups<ScreenerFilterGroupKeys>);

export const isFilterWithChildOptionActive = (filterOptions: FilterOption[]) =>
  filterOptions.some(o => !o.childName);

// The filter addition only occurs up to the count of available options
// unless filter is searchable or has custom option
export const showMultiUseFilter = (
  filter: ScreenerFilter,
  activeFilterOptions: ActiveFilterOptions[]
) => {
  if (filter.searchable || filter.config.custom) {
    return true;
  }

  const isAllSelected =
    !!filter.config.options.length &&
    activeFilterOptions.length === filter.config.options.length;
  return !isAllSelected;
};

export const getParentFilterOptions = ({
  filter,
  filterOptions,
  currentFilterOptions,
}: {
  filter: ScreenerFilter;
  currentFilterOptions: ActiveFilterOptions[];
  filterOptions: FilterOption[];
}) => {
  if (!filter.dynamic) {
    return filterOptions;
  }

  const currentOptions =
    filter.config.children &&
    currentFilterOptions.find(f => f.id === filter.key)?.options;
  return currentOptions || [];
};

// this is temporary logic
// see https://toggleglobal.slack.com/archives/C070NQ91AH1/p1726556414456699
export const getParentFilterDisabledOptions = (
  filter: ScreenerFilter,
  filterOptions: FilterOption[]
) => {
  return filter.dynamic && !filter.config.children ? filterOptions : [];
};
