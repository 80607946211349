import AssessmentDial from '@static/images/assessment/assessment-dial.svg';
import { typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

const positionReference = [
  {
    top: '71%',
    right: '50px',
    transform: '0',
    color: 'var(--text-critical-dark-red)',
  },
  {
    top: '54%',
    right: '47px',
    transform: '35deg',
    color: 'var(--text-critical-dark-red)',
  },
  {
    top: '35%',
    right: '60px',
    transform: '70deg',
    color: 'var(--text-critical)',
  },
  {
    top: '23%',
    right: '87px',
    transform: '96deg',
    color: 'var(--text-critical)',
  },
  {
    top: '22%',
    right: '116px',
    transform: '126deg',
    color: 'var(--text-decorative-lime)',
  },
  {
    top: '29%',
    right: '144px',
    transform: '154deg',
    color: 'var(--text-decorative-lime)',
  },
  {
    top: '47%',
    right: '163px',
    transform: '183deg',
    color: 'var(--text-success)',
  },
  {
    top: '64%',
    right: '167px',
    transform: '219deg',
    color: 'var(--text-success)',
  },
];

export const AssessmentHeader = styled.div`
  display: flex;
`;

export const QualityGaugeWrapper = styled.div`
  position: relative;
  height: 160px;
  width: 200px;
`;

export const QualityGaugeBackground = styled(AssessmentDial)`
  height: 160px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const GaugeVerdictWrapper = styled.span<{ $value: number }>`
  ${typographyV2.title3XL}
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ $value }) => positionReference[$value - 1].color};
`;

export const GaugeVerdict = styled.div`
  ${typographyV2.bodyXs}
  padding: 4px;
  border: 1px solid;
  border-radius: 4px;
`;

export const GaugeArrow = styled.span<{ $value: number }>`
  display: block;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 13px solid var(--icon-default);
  transform-origin: center;
  z-index: 1;
  ${({ $value }) => {
    const position = positionReference[$value - 1];
    return css`
      transform: rotate(calc(-200deg + ${position.transform})) translateX(50%)
        translateY(50%);
      top: ${position.top};
      right: calc(100% - ${position.right});
    `;
  }}
`;
