import { customScrollMinimal } from '@toggle/design-system';
import { EntityDocument } from '@toggle/toggle';
import styled from 'styled-components';

import { BaseErrorView } from '~/components/base-error-view/BaseErrorView';

export const Container = styled.section<{
  $type: EntityDocument['type'];
}>`
  padding: 12px;
  height: 100%;
  overflow: hidden;

  ${props =>
    props.$type === 'markdown' &&
    `
      overflow-y: auto;
      ${customScrollMinimal}
    `}

  & mark {
    background: rgba(255, 178, 36, 0.2);
    color: ${props => props.$type === 'markdown' && 'var(--text-default)'};
    font-size: ${props => props.$type === 'pdf' && 'calc(1em * 1.011)'};
  }

  & mark.primary {
    background: var(--surface-primary);
    color: var(--text-on-primary);
  }
`;

export const StyledBaseErrorView = styled(BaseErrorView)`
  flex-grow: 1;
`;
