import { startOfDayUTC } from '@toggle/helpers';
import { bisector } from 'd3-array';
import { isSameDay, startOfDay } from 'date-fns';

import { Domain } from '~/types/axis.types';
import { XAxis, YAxis } from '~/types/create.types';
import {
  ChartAssetData,
  ChartInsight,
  GroupedInsights,
  GroupInsightsDirection,
  InsightDirection,
  InsightsInDomain,
  TimeSeriesItem,
} from '~/types/timeseries.types';

type InsightsByType = Record<string, GroupedInsights>;
export type InsightsByDate = Record<string, InsightsByType>;

export const getGroupDirection = (
  mainDirection: GroupInsightsDirection,
  secondDirection: InsightDirection
): GroupInsightsDirection => {
  const formattedMainDirection = mainDirection.includes('-')
    ? mainDirection.split('-')[1]
    : mainDirection;

  return formattedMainDirection === secondDirection
    ? `multi-${secondDirection}`
    : `multi-mixed`;
};

export const groupSingleInsight = (
  insight: ChartInsight,
  insightsByDate: InsightsByDate,
  formatDate: (time: string) => Date
) => {
  const date = formatDate(insight.date.toISOString()).toISOString();

  if (!insightsByDate[date]) {
    insightsByDate[date] = {};
  }

  const type = insight.type;
  const group = insightsByDate[date][type];

  if (!group) {
    insightsByDate[date][type] = {
      date: insight.date,
      direction: insight.direction,
      insights: [insight],
      type,
    };
  } else {
    group.direction = getGroupDirection(group.direction, insight.direction);
    group.insights.push(insight);
  }

  return insightsByDate;
};

export const groupInsightsByDate = (
  insights: ChartInsight[],
  formatDate: (time: string) => Date
) =>
  insights.reduce((insightsByDate: InsightsByDate, insight: ChartInsight) => {
    groupSingleInsight(insight, insightsByDate, formatDate);
    return insightsByDate;
  }, {});

export interface InsightInDomainProps {
  primaryAsset: ChartAssetData;
  domain: Domain;
  insights?: ChartInsight[];
  y: YAxis[];
  x: XAxis;
  width: number;
  isIntraday: boolean;
}

// see x-scale logic and axisDateToTimezone
// TLDR; if intraday we display dates in local time, else -- UTC
export const formatInsightDate = (isIntraday: boolean) => (time: string) => {
  return isIntraday
    ? startOfDay(new Date(time))
    : startOfDayUTC(new Date(time));
};

// insights can be created on a date which doesn't exist in ts(data gap)
// or insight can be created let's say at 2024-09-09 3AM but we only have 2024-09-09 8AM in ts data --> insight will be display at 8AM
// so, it means we treat all insights as created at the very beginning of the day
// so that when we search for the next nearest ts point, it's guaranteed to be the same day
// if any exist on the same day as the insight
export const getInsightsInDomain = ({
  primaryAsset,
  domain,
  insights,
  y,
  x,
  width,
  isIntraday,
}: InsightInDomainProps) => {
  const insightsInDomain: InsightsInDomain[] = [];

  if (!insights?.length) {
    return insightsInDomain;
  }

  const formatDate = formatInsightDate(isIntraday);
  const data = primaryAsset.ts;
  const bis = bisector((d: TimeSeriesItem) => formatDate(d.time).getTime());
  const roundedDomain = [Math.ceil(domain[0]), Math.floor(domain[1])];
  const top = y[0].yScale.range()[0];

  const groupedInsightsByDate: InsightsByDate = groupInsightsByDate(
    insights,
    formatDate
  );

  Object.entries(groupedInsightsByDate).forEach(
    ([dateKey, groupedInsights]) => {
      const insightDate = formatDate(dateKey);
      const dateTime = insightDate.getTime();
      const index = bis.left(
        data,
        dateTime,
        roundedDomain[0],
        roundedDomain[1]
      );
      const foundDate = formatDate(data[index].time);
      const isPrevDateSame = isIntraday
        ? !!data[index - 1] &&
          isSameDay(formatDate(data[index - 1].time), foundDate)
        : false;

      //only if same day and previous is not the same date(meaning this is the 1st data point of that day)
      if (isSameDay(insightDate, foundDate) && !isPrevDateSame) {
        insightsInDomain.push({
          top,
          right: width - x.xScale(index),
          groupedInsights: Object.values(groupedInsights),
        });
      }
    }
  );

  //this is required for simplifying arrow navigation
  //closest to the left edge will have 0 index, closest to the right edge - last index
  return insightsInDomain.sort((a, b) => b.right - a.right);
};
