import { checkCellValueStyle } from '@toggle/helpers';
import React, { forwardRef } from 'react';

import {
  ChartTooltip,
  ChartTooltipProps,
} from '~/charts/chart-tooltip/ChartTooltip';

import { HighLowTripleLineChartProps } from '../../HighLowTripleLineChart';
import { HighLowDataPoint } from '../../HighLowTripleLineChart.types';
import * as S from './HighLowTripleLineChartTooltip.styles';
import { useTranslation } from 'react-i18next';

interface HighLowTripleLineChartTooltipProps
  extends Omit<ChartTooltipProps, 'children'> {
  tooltipLabels: HighLowTripleLineChartProps['tooltipLabels'];
  hoveredTs: HighLowDataPoint;
  hoveredSnakeTs?: number;
  numberFormatter: (value: number) => string;
}

export const HighLowTripleLineChartTooltip = forwardRef<
  HTMLDivElement,
  HighLowTripleLineChartTooltipProps
>(
  (
    {
      hoveredTs,
      hoveredSnakeTs,
      title,
      tooltipLabels,
      numberFormatter,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('chart');

    const keys: Array<keyof HighLowDataPoint> = ['high', 'median', 'low'];
    return (
      <S.StyledChartTooltip ref={ref} {...rest} title={title}>
        <S.ItemsContainer>
          {keys.map(key => (
            <ChartTooltip.Styled.Item key={key}>
              <ChartTooltip.Styled.Label>
                {tooltipLabels[key]}
              </ChartTooltip.Styled.Label>
              <S.ColoredValue
                $numericalStyle={checkCellValueStyle(hoveredTs[key])}
              >
                {numberFormatter(hoveredTs[key])}
              </S.ColoredValue>
            </ChartTooltip.Styled.Item>
          ))}
        </S.ItemsContainer>
        {hoveredSnakeTs && (
          <>
            <S.Separator />
            <S.ItemsContainer>
              <ChartTooltip.Styled.Item>
                <ChartTooltip.Styled.Label>
                  {t('chart:metrics.cumulativeReturn')}
                </ChartTooltip.Styled.Label>
                <S.ColoredValue
                  $numericalStyle={checkCellValueStyle(hoveredSnakeTs)}
                >
                  {numberFormatter(hoveredSnakeTs)}
                </S.ColoredValue>
              </ChartTooltip.Styled.Item>
            </S.ItemsContainer>
          </>
        )}
      </S.StyledChartTooltip>
    );
  }
);
