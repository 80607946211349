import { scaleLinear } from 'd3-scale';

import { PriceRange } from '~/types/axis.types';
import { ChartPane, CreateChartOptionsWithColors } from '~/types/create.types';
import { getYAxisTicks } from '~/utils/y-scale/y-scale';

interface GetYAxisDetailsProps {
  ticksCount: number;
  paneOptions: ChartPane['options'];
  dataRange: PriceRange;
  defaultSnake: string;
  minDomainValue: number;
}

export const getYAxisDetails = ({
  ticksCount,
  paneOptions,
  dataRange,
  defaultSnake,
  minDomainValue,
}: GetYAxisDetailsProps) => {
  const padding = (dataRange.max - dataRange.min) * 0.07;
  const min = Math.max(dataRange.min - padding, minDomainValue);
  const max = dataRange.max + padding;
  const yScale = scaleLinear()
    .domain([min, max])
    .range([
      paneOptions.height - paneOptions.gutters.x + paneOptions.gutters.top,
      paneOptions.gutters.top + paneOptions.panePaddingTop,
    ]);
  const axisTicks = getYAxisTicks(yScale, ticksCount);

  return {
    ticks: axisTicks.ticks,
    labels: axisTicks.labels,
    yScale,
    entityDefaultSnake: defaultSnake,
  };
};

const isWithinYAxisHeight = (
  event: MouseEvent,
  options: CreateChartOptionsWithColors
) => {
  const yAxisHeight = options.height - options.gutters.x;
  return event.offsetY >= 0 && event.offsetY <= yAxisHeight;
};

export const isWithinYAxis = (
  event: MouseEvent,
  options: CreateChartOptionsWithColors
): boolean => {
  const yAxisOffsetX = options.width - options.gutters.y;

  return event.offsetX >= yAxisOffsetX && isWithinYAxisHeight(event, options);
};
