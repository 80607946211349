import { create } from '~/stores/create-store/createStore';

export type ChatMode = 'intel' | 'reasoner';

export interface ChatModeStore {
  mode: ChatMode;
  setMode: (mode: ChatMode) => void;
}

export const useChatMode = create<ChatModeStore>(set => ({
  mode: 'intel',
  setMode: mode => set({ mode }),
}));
