import { TFunction } from 'i18next';

import { TabsType } from './types';

export const getTabs = (
  t: TFunction,
  options: { isStock: boolean; documentsCount: number }
) => [
  {
    tabKey: TabsType.Overview,
    tabLabel: t('widget:assetOverview.overview'),
  },
  {
    tabKey: TabsType.Financials,
    tabLabel: t('asset-overview:financials'),
  },
  {
    tabKey: TabsType.Chart,
    tabLabel: t('widget:assetOverview.chart'),
  },
  {
    tabKey: TabsType.KnowledgeGraph,
    tabLabel: t('widget:assetOverview.knowledgeGraph'),
    flag: !options.isStock,
  },
  {
    tabKey: TabsType.NewsInsights,
    tabLabel: t('widget:assetOverview.news'),
  },
  {
    tabKey: TabsType.Documents,
    tabLabel: t('widget:assetOverview.documents'),
    flag: options.isStock,
    isHidden: !options.isStock || !options.documentsCount,
  },
  {
    tabKey: TabsType.Earnings,
    tabLabel: t('widget:assetOverview.earnings'),
    isHidden: !options.isStock,
  },
];
