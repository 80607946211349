import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ManualEditOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  background-color: var(--background-soft);
  align-items: center;
  padding: 12px;
  max-width: 600px;
  border-radius: 4px;
`;

export const IconAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
`;

export const ClickableIconAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  cursor: pointer;
`;

export const ManualEditOptionsLabelText = styled.span`
  && {
    ${typographyV2.titleSm}
  }
`;
