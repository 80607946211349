import { Button, TableCell, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';

export const StyledErrorMessageWrapper = styled(ErrorMessageWrapper)`
  text-align: center;
  border: none;
  padding-bottom: 0px;
`;

export const StyledButton = styled(Button)`
  ${typographyV2.titleSm};
  color: var(--text-primary);
  & span > svg {
    fill: var(--text-primary);
  }
`;

export const ErrorMessageAndResetButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0;
`;

export const StyledTableCell = styled(TableCell)<{
  $isPinned: boolean;
  $highlight: boolean;
  $left?: number;
  $right?: number;
  $isLastPinnedCol?: boolean;
}>`
  font-family: var(--font-roboto-mono);
  background-color: ${props => props.$highlight && 'var(--text-inverse)'};

  ${props =>
    props.$isPinned &&
    css`
      position: sticky;
      z-index: var(--z-index-table-cell);

      ${props.$left !== undefined && `left: ${props.$left}px;`}
      ${props.$right !== undefined && `right: ${props.$right}px;`}
    `}

  ${props =>
    props.$isLastPinnedCol &&
    css`
      border-right: 1px solid var(--border-default);
    `}
`;

export const TableScrollContainer = styled.div`
  width: 100%;
`;
