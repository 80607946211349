import { Condition, formatTicker, SnakeMetaResponseV2 } from '@toggle/toggle';
import { v4 } from 'uuid';

import { postEntity } from '~/services/entities/entity-service';
import { fetchSnakeMetaV2 } from '~/services/overview-widget/overview-widget-service';

import { validateCondition } from '../validate-condition/validateCondition';

export interface MappedConditions extends Condition {
  id: string;
  active: boolean;
  confirmed: boolean;
  isValid: boolean;
  pendingRun: boolean;
  entityName: string;
  formattedTicker: string;
  snakeName: string;
  askedQuestion?: string;
  label?: string;
}

const getSnakeName = (data: SnakeMetaResponseV2, fallback: string) => {
  try {
    return Object.values(data)[0].name.english;
  } catch {
    return fallback;
  }
};

type EnrichedConditionsProps = {
  condition: Condition;
  askedQuestion?: string;
  isDefaultConfirmed?: boolean;
};

export const enrichedConditions = async ({
  condition,
  askedQuestion,
  isDefaultConfirmed,
}: EnrichedConditionsProps): Promise<MappedConditions> => {
  const entity = await postEntity(condition.entity);
  const snakes = await fetchSnakeMetaV2(condition.snake);

  return {
    ...condition,
    entityName: entity.data?.name ?? condition.entity,
    formattedTicker: entity.data ? formatTicker(entity.data) : condition.entity,
    snakeName: snakes.error
      ? condition.snake
      : getSnakeName(snakes.data, condition.snake),
    id: v4(),
    active: true,
    confirmed: !!isDefaultConfirmed,
    isValid: validateCondition(condition),
    pendingRun: true,
    askedQuestion,
  };
};
