import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { StyledComponent } from '~/common/styled-component';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Toggle.styles';

export type IconVisibility = 'only-on' | 'only-off' | 'both' | 'none';
export type HighlightMode = 'both' | 'only-on';

export interface ToggleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onToggle'> {
  id?: string;
  isChecked?: boolean;
  onToggle?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  leftLabel?: ReactNode;
  rightLabel?: ReactNode;
  icon?: SvgIconNames;
  iconVisibility?: IconVisibility;
  labelHighlight?: HighlightMode;
  backgroundHighlight?: HighlightMode;
  size?: 'medium' | 'small';
  disabled?: boolean;
}

export const Toggle: StyledComponent<ToggleProps, typeof S> = ({
  id,
  isChecked = false,
  onToggle,
  leftLabel,
  rightLabel,
  icon = 'Checkmark',
  iconVisibility = 'only-on',
  labelHighlight = 'both',
  backgroundHighlight = 'only-on',
  size = 'small',
  className,
  disabled = false,
  ...rest
}) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;

    if (onToggle) {
      onToggle(newValue, e);
    }
  };

  const shouldShowIcon =
    iconVisibility == 'both' ||
    (!checked && iconVisibility === 'only-off') ||
    (checked && iconVisibility === 'only-on');

  const isLeftSolid =
    (!checked && labelHighlight === 'only-on') || labelHighlight === 'both';
  const isRightSolid =
    (checked && labelHighlight === 'only-on') || labelHighlight === 'both';
  const isBackgroundActive =
    (checked && backgroundHighlight === 'only-on') ||
    backgroundHighlight === 'both';

  return (
    <S.ToggleRoot htmlFor={id} className={className} $disabled={disabled}>
      {leftLabel && (
        <S.LabelText data-testid="toggle-left-text" $isSolid={isLeftSolid}>
          {leftLabel}
        </S.LabelText>
      )}
      <S.ToggleInput
        id={id}
        type="checkbox"
        checked={checked}
        aria-checked={isChecked}
        onChange={handleOnChange}
        data-testid="toggle-ds"
        {...rest}
      />
      <S.ToggleContainer
        $isChecked={checked}
        $isBackgroundActive={isBackgroundActive}
        $size={size}
        data-testid="toggle-container"
        role="switch"
        aria-checked={checked}
      >
        <S.Circle $isChecked={checked} $size={size}>
          {icon && (
            <S.StyledIcon
              $isVisible={shouldShowIcon}
              $size={size}
              iconName={icon}
            />
          )}
        </S.Circle>
      </S.ToggleContainer>
      {rightLabel && (
        <S.LabelText $isSolid={isRightSolid}>{rightLabel}</S.LabelText>
      )}
    </S.ToggleRoot>
  );
};

Toggle.Styled = S;
