import { EarningsScenarioResponse, MappedEntity } from '@toggle/toggle';
import React, { useState } from 'react';

import { Expectation } from '../../types';
import { EarningsChart } from '../earnings-chart/EarningsChart';
import { ExpectationReported } from '../expectation-reported/ExpectationReported';
import { ExpectationsSwitcher } from '../expectations-switcher/ExpectationsSwitcher';
import * as S from './EarningsWidget.styles';

export interface EarningsWidgetProps {
  earningsData: EarningsScenarioResponse;
  entity: MappedEntity;
}

export const EarningsWidget = ({
  earningsData,
  entity,
}: EarningsWidgetProps) => {
  const [expectation, setExpectation] = useState<Expectation>(Expectation.Beat);
  const { reported, currency, eps } = earningsData;

  return (
    <S.EarningsWidgetRoot data-testid="earnings-widget">
      {reported && <ExpectationReported earning={eps} currency={currency} />}

      {!reported && (
        <ExpectationsSwitcher
          expectation={expectation}
          onChange={newExpectation => setExpectation(newExpectation)}
        />
      )}

      <EarningsChart
        expectation={expectation}
        earningsData={earningsData}
        entity={entity}
      />
    </S.EarningsWidgetRoot>
  );
};
