import * as Radix from '@radix-ui/react-dropdown-menu';
import type { ScreenerFilter, ScreenerFilters } from '@toggle/toggle';
import React, { ChangeEvent, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterSearch } from '~/views/screener/filter-search/FilterSearch';

import * as Shared from '../../filter/Filter.styles';
import { AddMoreFilterOption } from './AddMoreFilterOption';

type FilterProps = {
  filterGroups: [string, ScreenerFilters][];
  activeFilters: ScreenerFilter[];
  handleClick: (filterName: string, isChecked: boolean) => void;
  searchPlaceholder?: string;
  noResultsLabel: string;
};

export const AddMoreFilters = ({
  filterGroups,
  activeFilters,
  handleClick,
  searchPlaceholder,
  noResultsLabel,
}: FilterProps) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation(['screener']);

  const onClear = () => {
    setValue('');
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = (filterKey: string, isChecked: boolean) => {
    handleClick(filterKey, isChecked);
    onClear();
  };

  const mappedGroups = filterGroups
    .map(([category, options]) => {
      const filteredOptions = options.filter(o =>
        o.name.toLowerCase().includes(value.toLowerCase())
      );
      return (
        !!filteredOptions.length && (
          <Fragment key={category}>
            <Shared.MenuTitleText>{category}</Shared.MenuTitleText>
            {filteredOptions.map(option => {
              const isChecked = !!activeFilters.find(f => f.key === option.key);

              return (
                <AddMoreFilterOption
                  key={option.key}
                  filterKey={option.key}
                  name={option.name}
                  locked={option.locked}
                  isChecked={isChecked}
                  handleClick={filterKey => handleSelect(filterKey, isChecked)}
                />
              );
            })}
          </Fragment>
        )
      );
    })
    .filter(Boolean);
  const showEmpty = !mappedGroups.length;

  return (
    <Shared.DropdownMenuRoot>
      <Shared.DashedTrigger>
        <Shared.TriggerLabel>{t('screener:addMore')}</Shared.TriggerLabel>
        <Shared.TriggerAdd iconName="Add" />
      </Shared.DashedTrigger>
      <Radix.Portal>
        <Shared.DropdownMenuContent
          sideOffset={5}
          align="start"
          onInteractOutside={onClear}
        >
          <FilterSearch
            value={value}
            searchPlaceholder={searchPlaceholder}
            onChange={onSearch}
            onClear={onClear}
          />
          <Shared.Separator />
          <Shared.ScrollableList>
            {showEmpty ? (
              <Shared.NoResults>{noResultsLabel}</Shared.NoResults>
            ) : (
              mappedGroups
            )}
          </Shared.ScrollableList>
        </Shared.DropdownMenuContent>
      </Radix.Portal>
    </Shared.DropdownMenuRoot>
  );
};
