import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const DropdownMenuRoot = styled(RadixDropdown.Root)``;

export const Separator = styled(RadixDropdown.Separator)`
  height: 1px;
  background-color: var(--border-soft);
`;

export const DropdownContent = styled(RadixDropdown.Content)`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  max-width: 400px;
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  border-radius: 4px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  z-index: var(--z-index-modal);
  gap: 8px;
  padding: 8px 0;

  & > div:last-child {
    ${Separator} {
      display: none;
    }
  }
`;
