import { Icon, Tooltip, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChartBreadcrumbsRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

export const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
  fill: var(--icon-soft);
`;

export const Separator = styled.span`
  padding: 0 8px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 20px;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const StyledTooltip = styled(Tooltip)`
  background-color: var(--indigo--8);
  border-radius: 4px;
  width: 150px;
  padding: 8px;
  ${typographyV2.bodySm};
  color: var(--text-inverse);

  ${Tooltip.Styled.ArrowIcon} {
    background-color: var(--indigo--8);
  }
`;

export const Tutorial = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
