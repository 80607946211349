import * as React from 'react';
import { useSearchParams } from 'react-router';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import { appPaths } from '~/routes/app-paths';
import { KnowledgeGraphSectionId } from '~/widgets/knowledge-graph-widget/KnowledgeGraphWidget';

import { getKnowledgeGraphSearchParams } from '../utils/knowledge-graph-utils';
import * as S from './KnowledgeElement.styles';

export interface KnowledgeElementProps {
  title: string;
  isLoading: boolean;
  hasError: boolean;
  children: React.ReactNode;
  entityTag: string;
  sectionId?: KnowledgeGraphSectionId;
  tooltip?: string;
}

export const KnowledgeElement = ({
  title,
  tooltip,
  isLoading,
  hasError,
  children,
  entityTag,
  sectionId,
  ...rest
}: KnowledgeElementProps) => {
  const [searchParams] = useSearchParams();

  if (hasError) {
    return null;
  }

  if (isLoading) {
    return <S.LoadingSkeleton />;
  }

  const knowledgeGraphSearchParams = getKnowledgeGraphSearchParams({
    sectionId,
    entityTag,
    searchParams,
  }).toString();

  const linkToKnowledgeGraph = `${appPaths.assetOverview}?${knowledgeGraphSearchParams}`;

  return (
    <div {...rest}>
      <SectionTitle
        title={title}
        variant="small"
        linkTo={linkToKnowledgeGraph}
        tooltip={tooltip}
      />
      <S.ChipsContainer>{children}</S.ChipsContainer>
    </div>
  );
};
