import { Input, Modal } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModalRoot = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: 100%;
    min-width: 740px;
    max-width: 945px;
    background: var(--background-soft);
  }
`;

export const StyledInput = styled(Input)`
  ${Input.Styles.StyledLabel} {
    color: var(--text-soft);
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
