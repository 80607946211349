import styled, { css } from 'styled-components';

import { TableHeader } from '~/components/table/table-header/TableHeader';

import { PinConfig } from '../table-cell/TableCell';

export const StickyStyledTableHeader = styled(TableHeader)<{
  $pinConfig?: PinConfig;
  $size?: number;
}>`
  z-index: var(--z-index-header-cell);
  font-weight: 500;
  position: sticky;
  left: 0;
  top: 0;

  ${({ $size }) =>
    $size &&
    css`
      width: ${$size}px;
    `}

  ${({ $pinConfig }) =>
    $pinConfig &&
    css`
      position: sticky;
      z-index: calc(var(--z-index-header-cell) + 1);
      left: ${$pinConfig.left}px;
      right: ${$pinConfig.right}px;
      top: 0;
    `}

  ${({ $pinConfig }) =>
    $pinConfig?.isLastPinned &&
    css`
      border-right: 1px solid var(--border-default);
    `}

  ${props =>
    props.$pinConfig?.showShadow &&
    css`
      box-shadow: 15px 0 30px 15px rgba(var(--background-default-rgb), 0.6);
      clip-path: inset(0px -60px 0px 0px);
    `}
`;

export const StickyContentContainer = styled.div<{
  $size?: number;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ $size }) =>
    $size &&
    css`
      width: ${$size - 24}px;
    `}
`;
