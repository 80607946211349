import React, { ButtonHTMLAttributes, ReactNode } from 'react';

import { Icon } from '~/components/icon/Icon';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './ButtonLink.styles';

type IconPosition = 'left' | 'right';

export interface ButtonLinkProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: ReactNode;
  iconName?: SvgIconNames;
  iconPosition?: IconPosition;
  iconSize?: number;
}

export const ButtonLink = ({
  label,
  iconName,
  iconPosition = 'left',
  iconSize = 16,
  ...rest
}: ButtonLinkProps) => {
  return (
    <S.ButtonLinkRoot type="button" data-testid="button-link" {...rest}>
      <S.Label $isReversed={iconPosition === 'right'}>
        {iconName && <Icon iconName={iconName} size={iconSize} />}
        {label}
      </S.Label>
    </S.ButtonLinkRoot>
  );
};

ButtonLink.Styled = S;
