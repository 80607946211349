import { IconProps } from '@toggle/design-system';

import {
  CompanyWithEntity,
  RelatedCompaniesGrouped,
} from '~/services/related-companies/related-companies-service';

export type RelatedCompaniesIds = 'competitors' | 'suppliers';

export interface RelatedOptionItem {
  id: RelatedCompaniesIds;
  iconName: IconProps['iconName'];
  data?: CompanyWithEntity[];
}

export const relatedOptionsData = (
  relatedCompanies?: RelatedCompaniesGrouped
) => {
  const filterMap: RelatedOptionItem[] = [
    {
      id: 'competitors',
      iconName: 'Sword',
      data: relatedCompanies?.competitors?.sort(
        (a, b) => b.entity.search.rank - a.entity.search.rank
      ),
    },
    {
      id: 'suppliers',
      iconName: 'RightLeftLarge',
      data: relatedCompanies?.suppliers?.sort(
        (a, b) => b.entity.search.rank - a.entity.search.rank
      ),
    },
  ];

  return filterMap.filter(({ data }) => data && data.length > 0);
};
