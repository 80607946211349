import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Source } from '~/stores/use-chat/use-chat.types';
import { ChatMode } from '~/stores/use-chat-mode/useChatMode';

import { ChatDeepAnalysis } from './chat-deep-analysis/ChatDeepAnalysis';
import { ChatQuickAnalysis } from './chat-quick-analysis/ChatQuickAnalysis';
import { ChatInputConfig } from './types';

export interface ChatInputProps {
  submitCallback?: (message: string) => void;
  chatContext?: {
    target: Source;
    documents?: string[];
    documentTypes?: string[];
    entityTags?: string[];
  };
  config?: ChatInputConfig;
}

export const ChatInput = ({
  submitCallback,
  chatContext,
  config,
}: ChatInputProps) => {
  useTranslation(['chat']);

  const [mode, setMode] = useState<ChatMode>(config?.mode ?? 'intel');

  const componentMap = {
    intel: ChatQuickAnalysis,
    reasoner: ChatDeepAnalysis,
  };
  const Component = componentMap[mode];

  return (
    <Component
      config={config}
      submitCallback={submitCallback}
      chatContext={chatContext}
      mode={mode}
      setMode={setMode}
    />
  );
};
