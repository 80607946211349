import { DocumentOverviewData } from '~/stores/use-chat/assistant-response.type';
import { Message } from '~/stores/use-chat/use-chat.types';
import { CITATION_GROUP_ID } from '~/views/intel/document-sources-view/DocumentSourcesView';

export const getContextCounts = (documentOverview?: DocumentOverviewData) => ({
  documentsCount: documentOverview?.documents ?? 0,
  companiesCount: documentOverview?.companies ?? 0,
});

export const showContextHeader = ({
  messages,
  searchParams,
  disableContextHeader,
  documentsCount,
  companiesCount,
}: {
  messages: Message[];
  searchParams: URLSearchParams;
  disableContextHeader?: boolean;
  documentsCount: number;
  companiesCount: number;
}) => {
  const mostRecentMessage = [...messages]
    .reverse()
    .find(message => message.status !== 'abort');
  const mostRecentPrimaryReply = mostRecentMessage?.reply?.[0];

  return (
    !disableContextHeader &&
    (mostRecentPrimaryReply?.source === 'document-assistant' ||
      mostRecentMessage?.target === 'document-assistant' ||
      !!searchParams.get(CITATION_GROUP_ID)) &&
    !!documentsCount &&
    !!companiesCount
  );
};
