import { Modal } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: 95%;
    max-width: 600px;

    background: var(--background-soft);
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0 40px var(--background-soft) inset !important;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
