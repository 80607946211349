import { PriceHoverData, SeriesType, TimeSeriesItem } from '@toggle/chart';
import { isPriceSnake } from '@toggle/helpers';
import { MappedEntity, PreliminaryPrice, SnakeMeta } from '@toggle/toggle';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceDisplayReturn } from '~/components/price-display-view/helpers/price-display.types';
import { formatPriceDisplay } from '~/components/price-display-view/helpers/price-display-helpers';
import { useLivePrice } from '~/hooks/use-live-price';
import { globalPriceFormatter } from '~/utils/asset-price/assetPrice';
import { priceSuffix } from '~/utils/currency/currency';

import { LastClosePrice } from '../last-close-price/LastClosePrice';
import { LegendLivePrice } from '../legend-live-price/LegendLivePrice';
import { PriceIcon } from '../legend-price-icons/LegendPriceIcons';

export interface LegendPriceProps {
  entity: MappedEntity;
  snakeMeta: SnakeMeta;
  preliminaryPrice?: PreliminaryPrice;
  isChartHovered: boolean;
  legendColor: string;
  isPrimary: boolean;
  selectedSeriesType?: SeriesType;
  ts: TimeSeriesItem[];
  hoverData?: PriceHoverData;
}

export const legendIconMapping = (price: PriceDisplayReturn): PriceIcon[] => {
  return [
    {
      iconName: 'SunRise',
      tooltipLabel: 'chart:preMarketTooltip',
      color: 'var(--icon-primary)',
      shouldShow: price.primary.marketHours.isPreMarket,
    },
    {
      iconName: 'Sun',
      tooltipLabel: 'chart:marketOpenTooltip',
      color: 'var(--viz-bullish)',
      shouldShow: price.primary.marketHours.isMarketHours,
    },
    {
      iconName: 'Moon',
      tooltipLabel: 'chart:marketClosedTooltip',
      color: 'var(--icon-soft)',
      shouldShow: price.primary.marketHours.isAfterMarket,
    },
  ];
};

export const LegendPrice = ({
  entity,
  snakeMeta,
  preliminaryPrice,
  isChartHovered,
  legendColor,
  isPrimary,
  selectedSeriesType,
  ts,
  hoverData,
}: LegendPriceProps) => {
  const priceFormatter = useCallback(
    (price: number) =>
      globalPriceFormatter({
        entity,
        price,
        priceSuffix: snakeMeta
          ? priceSuffix(snakeMeta.display_format, entity.currency)
          : '',
        shouldShowCurrency: false,
      }),
    [entity]
  );

  const { t } = useTranslation('chart');
  const { ticker, sub_class, primary_method, subscribable_ticker } = entity;
  const priceEntities = [
    {
      entity: { ticker, sub_class, primary_method, subscribable_ticker },
      lastKnownPrice: snakeMeta.last_value,
      priceBefore: snakeMeta.before_last_value,
    },
  ];

  const livePriceInfo = useLivePrice(priceEntities)[entity.ticker];

  const price = formatPriceDisplay({
    exchange: entity.exchange.code,
    livePriceInfo,
    preliminaryPrice,
    meta: snakeMeta,
    isHovered: isChartHovered,
    t,
  });

  if (!snakeMeta || !ts[ts.length - 1]) {
    return null;
  }

  const isOHLCSeries =
    selectedSeriesType && ['bars', 'candlestick'].includes(selectedSeriesType);
  const isOHLCPrice = isOHLCSeries && isPrimary;

  //TODO will add back in full live price when we have figured out the OHLC live legend
  const shouldShowLivePrice =
    isPriceSnake(snakeMeta.snake) && !isOHLCPrice && !hoverData;

  if (shouldShowLivePrice) {
    return (
      <LegendLivePrice
        iconMapping={legendIconMapping(price)}
        price={price}
        isPrimary={isPrimary}
        priceFormatter={priceFormatter}
        legendColor={legendColor}
      />
    );
  }

  return (
    <LastClosePrice
      iconMapping={legendIconMapping(price)}
      price={price}
      entity={entity}
      snakeMeta={snakeMeta}
      legendColor={legendColor}
      isPrimary={isPrimary}
      priceFormatter={priceFormatter}
      seriesType={selectedSeriesType}
      ts={ts}
      hoverData={hoverData}
    />
  );
};
