import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './OfferingPriceTooltipLabel.styles';

export const OfferingPriceTooltipLabel = ({
  minPrice,
  maxPrice,
}: {
  minPrice: string;
  maxPrice: string;
}) => {
  const { t } = useTranslation('screener');
  const formattedMinPrice = parseFloat(minPrice).toFixed(2);
  const formattedMaxPrice = parseFloat(maxPrice).toFixed(2);
  return (
    <S.Root>
      <S.FlexColumnWrapper>
        <div data-testid="offering-price-minimum">
          {t('screener:screenerEvents.minimum')}
        </div>
        <div data-testid="offering-price-minimum-value">
          {formattedMinPrice}
        </div>
      </S.FlexColumnWrapper>
      <S.VerticalLineDivider />
      <S.FlexColumnWrapper>
        <div data-testid="offering-price-maximum">
          {t('screener:screenerEvents.maximum')}
        </div>
        <div data-testid="offering-price-maximum-value">
          {formattedMaxPrice}
        </div>
      </S.FlexColumnWrapper>
    </S.Root>
  );
};
