import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const KnowledgeGraphWidgetRoot = styled.div`
  ${customScrollMinimal};
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 1280px;
  padding: 20px;
  margin: 0 auto;
`;
