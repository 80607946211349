import styled from 'styled-components';

export const OverviewRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 1280px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const SplitSection = styled.div`
  flex: 1;
`;
