import { Icon } from '@toggle/design-system';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import * as S from './SearchInput.styles';

export interface SearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  onClear(): void;
  showClearButton?: boolean;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onClear, showClearButton = false, ...rest }, ref) => {
    return (
      <S.SearchInputRoot>
        <S.InputContainer>
          <S.SearchIcon>
            <Icon iconName="Search" size={24} />
          </S.SearchIcon>

          <S.Input
            ref={ref}
            data-testid="search-input"
            type="text"
            autoComplete="off"
            spellCheck={false}
            {...rest}
          />

          {showClearButton && (
            <S.ClearButton
              type="button"
              data-testid="clear-button"
              onClick={onClear}
            >
              <Icon iconName="CloseFillCircle" size={20} />
            </S.ClearButton>
          )}
        </S.InputContainer>
      </S.SearchInputRoot>
    );
  }
);
