import { WithNAFallback } from '@toggle/design-system';
import { getPriceStatus } from '@toggle/helpers';
import React from 'react';

import {
  formatNumberWithUnit,
  formatPercentageNumber,
} from '../../utils/utils';
import * as S from './NumberCell.styles';

interface NumberCellProps {
  value: number;
  percentage?: boolean;
}

export const NumberCell = ({ value, percentage }: NumberCellProps) => {
  const formattedValue = percentage
    ? formatPercentageNumber(value)
    : formatNumberWithUnit(value);

  return (
    <S.NumberTableValue $status={getPriceStatus(value)}>
      <WithNAFallback check={formattedValue !== '-'} value={formattedValue} />
    </S.NumberTableValue>
  );
};
