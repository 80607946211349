import React from 'react';
import { useTranslation } from 'react-i18next';

import { CountryListItem } from '~/components/country-section/country-list-item/CountryListItem';
import { useThemeCountryExposure } from '~/hooks/use-theme-overview/useThemeOverview';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

import { DrawerSection } from '../drawer-section/DrawerSection';

type CountrySectionProps = {
  onFooterClick: () => void;
};

export const CountrySection = ({ onFooterClick }: CountrySectionProps) => {
  const { t } = useTranslation('widget');

  const { themeId } = useThemeOverviewStore(state => ({
    themeId: state.themeId,
  }));
  const { data, isLoading, isError, isRefetching, refetch } =
    useThemeCountryExposure(themeId);

  const countries =
    data?.result.map((country, i) => ({
      ...country,
      tooltip: t('themeOverview.rank', {
        rank: i + 1,
      }),
    })) ?? [];

  const total = countries.length > 3 ? 3 : countries.length;
  const moreCountriesTotal = countries.length - total;

  return (
    <DrawerSection
      isLoading={isLoading || isRefetching}
      isEmpty={!countries.length}
      isError={isError}
      headerTitle={t('themeOverview.topCountries', { total })}
      headerDescription={t('themeOverview.rankedBySignificance')}
      footerLabel={t('themeOverview.viewMoreCountries', {
        context: moreCountriesTotal ? 'count' : 'none',
        total: moreCountriesTotal,
      })}
      footerOnClick={onFooterClick}
      refetch={refetch}
    >
      {countries.slice(0, 3).map(country => (
        <CountryListItem key={country.code} country={country} />
      ))}
    </DrawerSection>
  );
};
