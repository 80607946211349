import { DetailedInsight } from '@toggle/toggle';
import React from 'react';

import { InsightCardDetails } from '~/components/insight-card-details/InsightCardDetails';

import * as S from './InsightSummary.styles';

export interface InsightSummaryProps {
  insight: DetailedInsight;
  isFixedIncome: boolean;
}

export const InsightSummary = ({
  insight,
  isFixedIncome,
}: InsightSummaryProps) => (
  <S.InsightSummaryRoot data-testid="insight-summary">
    <S.InsightCardWrapper>
      <InsightCardDetails
        qualityStars={insight.metadata.stars}
        median={insight.metadata.median_return}
        horizon={insight.metadata.horizon}
        isFixedIncome={isFixedIncome}
      />
    </S.InsightCardWrapper>
  </S.InsightSummaryRoot>
);
