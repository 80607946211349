import { bisector } from 'd3-array';

import { getLineFormula } from '~/core/series/line/line';
import { Domain } from '~/types/axis.types';
import { BaseChartAPIProps, ChartPane, XAxis } from '~/types/create.types';
import {
  ChartThresholdInterval,
  ThresholdInterval,
  TimeSeriesItem,
} from '~/types/timeseries.types';

export const getThresholdIntervals = ({
  domain,
  chartPanes,
  x,
  primaryAsset,
}: {
  domain: Domain;
  chartPanes: ChartPane[];
  x: XAxis;
  primaryAsset: BaseChartAPIProps['primaryAsset'];
}) => {
  const chartAssetsWithThreshold = chartPanes
    .flatMap(p => p.chartAssetData)
    .filter(item => !item.isHidden && item.snakeMeta?.threshold);

  const pane = chartPanes.find(
    p => p.chartAssetData[0] === chartAssetsWithThreshold[0]
  );

  if (!pane || chartAssetsWithThreshold.length !== 1) {
    return undefined;
  }

  const chartAssetData = chartAssetsWithThreshold[0];
  const y = pane.y[0];

  const checker = (i: ThresholdInterval) =>
    //fully visible
    (i.start >= domain[0] && i.end <= domain[1]) ||
    //partially visible
    (i.end > domain[1] && i.start < domain[1]) ||
    (i.start < domain[0] && i.end > domain[0]);

  const bis = bisector((d: TimeSeriesItem) => d.time);

  return chartAssetData.thresholdIntervals?.reduce<ChartThresholdInterval[]>(
    (res, next) => {
      if (checker(next)) {
        const thresholdY = y.yScale(next.threshold);
        // x1 and x2 are the x coordinates that represents coordinates when threshold line crosses chart line within interval
        const [x1, x2] = [next.start, next.end].map(primaryIdx => {
          const idx = bis.left(
            chartAssetData.ts,
            primaryAsset.ts[primaryIdx].time
          );

          const prevClose = idx
            ? chartAssetData.ts[idx - 1].close
            : chartAssetData.ts[idx].close - 1;
          // find line equation to get x coordinate from y coordinate
          return primaryIdx > 0
            ? getLineFormula(
                x.xScale(primaryIdx - 1),
                y.yScale(prevClose),
                x.xScale(primaryIdx),
                y.yScale(chartAssetData.ts[idx].close)
              ).x(thresholdY)
            : 0;
        });

        res.push({ x1, x2, direction: next.direction });
      }

      return res;
    },
    []
  );
};
