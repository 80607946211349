import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WORKING_LIST_ID } from '~/config/constants';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { appPaths } from '~/routes/app-paths';
import { useExploreStore } from '~/views/insights/hooks/use-explore-store/useExploreStore';
import { insightsAssetFilter } from '~/views/insights/utils/filter-utils/filter-utils';
import { useScreenerActions } from '~/views/screener/use-screener-actions/useScreenerActions';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';
import { SCENARIO_ASSET_FILTER_KEY } from '~/widgets/scenario/hooks/use-scenario-store/filter-utils';
import { useScenarioStore } from '~/widgets/scenario/hooks/use-scenario-store/useScenarioStore';

import { Actions } from './components/Modals';

export const useLinkingToolsNavigation = () => {
  const { t } = useTranslation('common');
  const [activeModal, setActiveModal] = useState<Actions | null>(null);
  const navigate = useNavigate();

  const { createNewDraft } = useScreenerActions();
  const setHasCustomDefaultAssetFilter = useExploreStore(
    state => state.setHasCustomDefaultAssetFilter
  );
  const clearScenarioPageEntityTags = useScenarioStore(
    state => state.clearScenarioPageEntityTags
  );
  const setCustomDefaultTableTab = useScreenerStore(
    state => state.setCustomDefaultTableTab
  );

  const { addFilterOption: addScenarioFilterOption } = useFilterActions({
    store: useScenarioStore,
    group: 'ASSET_FILTERS',
  });
  const { addFilterOption: addInsightsFilterOption } = useFilterActions({
    store: useExploreStore,
    group: 'ASSET_FILTERS',
  });

  const workingListFilterOption = {
    key: WORKING_LIST_ID,
    name: t('common:filters.workingList'),
    value: {
      workingList: 'working-list',
    },
  };

  const navigateToWatchlist = () => {
    setActiveModal(Actions.CreateWatchlist);
  };

  const navigateToScreener = () => {
    createNewDraft({
      option: {
        filterKey: 'entity_tag',
        option: workingListFilterOption,
      },
      group: 'Market Filters',
    });
    setCustomDefaultTableTab('Performance');
    navigate(appPaths.screener);
  };

  const navigateToScenario = () => {
    clearScenarioPageEntityTags();
    addScenarioFilterOption({
      filterKey: SCENARIO_ASSET_FILTER_KEY,
      option: workingListFilterOption,
    });
    navigate(appPaths.scenario);
  };

  const navigateToInsights = () => {
    setHasCustomDefaultAssetFilter(true);
    addInsightsFilterOption({
      filterKey: insightsAssetFilter.key,
      option: workingListFilterOption,
    });
    navigate(appPaths.insights);
  };

  return {
    navigateToWatchlist,
    navigateToScreener,
    navigateToScenario,
    navigateToInsights,
    activeModal,
    setActiveModal,
  };
};
