import { RefObject, useLayoutEffect, useRef, useState } from 'react';

export const useScrollingIndicator = <
  T extends HTMLElement,
  R extends HTMLElement
>(
  additionalBottomOffset: number
): {
  containerRef: RefObject<T | null>;
  contentRef: RefObject<R | null>;
  showScrollingIndicator: boolean;
  scrollToBottom: () => void;
} => {
  const containerRef = useRef<T>(null);
  const contentRef = useRef<R>(null);
  const [showScrollingIndicator, setShowScrollingIndicator] = useState(false);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: contentRef.current?.getBoundingClientRect().bottom,
        behavior: 'smooth',
      });
      setShowScrollingIndicator(false);
    }
  };

  useLayoutEffect(() => {
    if (!containerRef.current || !contentRef.current) {
      return undefined;
    }

    const container = containerRef.current;

    const resizeObserver = new ResizeObserver(() => {
      const container = containerRef.current;

      if (!container || !contentRef.current) {
        return null;
      }

      const target = contentRef.current;
      const containerOffsetBottom =
        window.innerHeight - container.getBoundingClientRect().bottom;
      const requiredScrolledAmount =
        target.getBoundingClientRect().bottom -
        container.offsetHeight -
        containerOffsetBottom -
        additionalBottomOffset;

      const isScrolledToBottom = () =>
        container.scrollTop >= requiredScrolledAmount;

      setShowScrollingIndicator(!isScrolledToBottom());
      return null;
    });

    const handler = () => {
      setShowScrollingIndicator(false);
    };

    resizeObserver.observe(contentRef.current);
    container.addEventListener('scroll', handler);

    return () => {
      container.removeEventListener('scroll', handler);
      resizeObserver.disconnect();
    };
  }, [containerRef, contentRef, additionalBottomOffset]);

  return { containerRef, contentRef, showScrollingIndicator, scrollToBottom };
};
