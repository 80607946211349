import { DocumentItem } from '@toggle/toggle';
import { TFunction } from 'i18next';

export const copyRecord = (t: TFunction) => {
  return {
    description: {
      basic: t('auth:onHold.description.basic'),
      email: t('auth:onHold.description.email'),
      payment: t('auth:onHold.description.payment'),
      pro: t('auth:onHold.description.pro'),
    },
    title: {
      basic: t('auth:onHold.title.basic'),
      pro: t('auth:onHold.title.pro'),
      subscription: t('auth:onHold.title.subscription'),
      trial: t('auth:onHold.title.trial'),
    },
  };
};

export const getTitleType = ({
  reason,
  subscriptionType,
}: {
  reason: string | null;
  subscriptionType: PageVariants;
}) => {
  if (reason === PageVariants.Subscription || reason === PageVariants.Trial) {
    return reason;
  }

  return subscriptionType;
};

export const getDescriptionType = ({
  reason,
  subscriptionType,
}: {
  reason: string | null;
  subscriptionType: PageVariants;
}) => {
  if (reason === PageVariants.Email || reason === PageVariants.Payment) {
    return reason;
  }

  return subscriptionType;
};

export enum PageVariants {
  Basic = 'basic',
  Email = 'email',
  Payment = 'payment',
  Pro = 'pro',
  Subscription = 'subscription',
  Trial = 'trial',
}

export const getLatestDocument = (documents: DocumentItem[], name: string) => {
  const filteredDocs = documents.filter(doc => doc.name === name);

  if (filteredDocs.length === 0) {
    return null;
  }

  const latestDocument = filteredDocs.reduce((latest, current) => {
    if (compareVersions(current.version, latest.version) > 0) {
      return current;
    }

    return latest;
  }, filteredDocs[0]);

  return latestDocument;
};

export const compareVersions = (version1: string, version2: string): number => {
  const [major1, minor1 = 0, patch1 = 0] = version1.split('.').map(Number);
  const [major2, minor2 = 0, patch2 = 0] = version2.split('.').map(Number);

  if (major1 !== major2) {
    return major1 - major2;
  }

  if (minor1 !== minor2) {
    return minor1 - minor2;
  }

  return patch1 - patch2;
};
