import { DetailedInsight } from '@toggle/toggle';

import { Domain } from '~/types/axis.types';
import { CreateChartOptionsWithColors, XAxis } from '~/types/create.types';
import { ChartAssetData } from '~/types/timeseries.types';
import {
  EPISODES_BAND_HEX_ALPHA,
  MIN_THRESHOLD_BAND_WIDTH,
} from '~/utils/constants';
import { getTimefromSeries } from '~/utils/timeseries/time-series';

import { getClosestDateIndex } from './utils';

const Y_POSITION = 0;

type EpisodesXPositions = {
  x1: number;
  x2: number;
};

interface DrawEpisodesProps {
  x: XAxis;
  episodesData?: DetailedInsight['episodes'];
  options: CreateChartOptionsWithColors;
  context: CanvasRenderingContext2D;
  domain: Domain;
  tsByTime: ChartAssetData['tsByTime'];
  isPriceSnake: boolean;
}

export const drawEpisodes = ({
  x,
  episodesData,
  options,
  context,
  domain,
  tsByTime,
  isPriceSnake,
}: DrawEpisodesProps) => {
  if (!episodesData) {
    return;
  }

  const tsByTimeArray = Array.from(tsByTime);

  const horizonChecker = ({
    startDateIndex,
    endDateIndex,
  }: {
    startDateIndex: number;
    endDateIndex: number;
  }) =>
    //fully visible
    (startDateIndex >= domain[0] && endDateIndex <= domain[1]) ||
    //partially visible
    (endDateIndex > domain[1] && startDateIndex < domain[1]) ||
    (startDateIndex < domain[0] && endDateIndex > domain[0]);

  const episodesPositions = episodesData?.reduce<EpisodesXPositions[]>(
    (res, curr) => {
      const startTime = getTimefromSeries(isPriceSnake, curr.start_date);
      const endTime = getTimefromSeries(isPriceSnake, curr.end_date);
      const startDateIndex =
        tsByTime.get(startTime) ??
        getClosestDateIndex(startTime, tsByTimeArray);
      const endDateIndex =
        tsByTime.get(endTime) ?? getClosestDateIndex(endTime, tsByTimeArray);

      const isValidRange =
        startDateIndex !== undefined && endDateIndex !== undefined;

      if (isValidRange && horizonChecker({ startDateIndex, endDateIndex })) {
        res.push({ x1: x.xScale(startDateIndex), x2: x.xScale(endDateIndex) });
      }

      return res;
    },
    []
  );

  context.save();
  context.fillStyle = options.colors.primary.value + EPISODES_BAND_HEX_ALPHA;

  episodesPositions.forEach(i => {
    const x2 = Math.min(i.x2, options.width - options.gutters.y);

    context.fillRect(
      i.x1,
      Y_POSITION,
      Math.max(MIN_THRESHOLD_BAND_WIDTH, x2 - i.x1),
      options.height - options.gutters.x
    );
  });
  context.restore();
};
