import React from 'react';
import { useTranslation } from 'react-i18next';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';
import * as S from './MobileNotSupported.styles';

export const MobileNotSupported = () => {
  const { t } = useTranslation('common');

  return (
    <AuthSharedStyles.Layout>
      <S.Section data-testid="mobile-not-supported">
        <S.Wrapper>
          <S.Mobile
            src="/app/static/images/icons/mobile-with-sad-face.svg"
            alt=""
          />
          <S.Title>{t('common:smallScreensNotSupported')}</S.Title>
          <S.Description>{t('common:switchToDesktop')}</S.Description>
        </S.Wrapper>
      </S.Section>
    </AuthSharedStyles.Layout>
  );
};
