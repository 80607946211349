import React, { useId } from 'react';

import * as S from './VerticalBar.styles';

export interface BarDataPoint {
  value?: number;
  label: string;
  color?: string;
  borderColor?: string;
}

export interface VerticalBarProps {
  barRadius?: number;
  dataPoint: BarDataPoint;
  yValue: number;
  width: number;
  y0: number;
  showBackground?: boolean;
}

const BG_WIDTH_RATIO = 1.681;
const BAR_RADIUS_PX = 4;

export const VerticalBar = ({
  barRadius = BAR_RADIUS_PX,
  dataPoint,
  width,
  y0,
  yValue,
  showBackground,
}: VerticalBarProps) => {
  const value = dataPoint.value ?? 0;
  const clipPathId = useId();
  const height = Math.abs(y0 - yValue);
  const yOffset = value > 0 ? y0 - height : y0;
  const bgWidth = BG_WIDTH_RATIO * width;

  const borderRadiusProps =
    value > 0
      ? {
          height: height + barRadius,
          y: yOffset,
          rx: barRadius,
        }
      : {
          height,
          y: yOffset - barRadius,
          rx: barRadius,
        };

  return (
    <S.VerticalBarRoot>
      {showBackground && (
        <S.RectBackground width={bgWidth} x={-bgWidth / 2} height="100%" />
      )}

      <clipPath id={clipPathId}>
        <rect width={width} x={-width / 2} {...borderRadiusProps} />
      </clipPath>

      <S.Rect
        clipPath={`url(#${clipPathId})`}
        $color={dataPoint.color}
        $borderColor={dataPoint.borderColor}
        width={width}
        y={yOffset}
        x={-width / 2}
        height={height}
      />
    </S.VerticalBarRoot>
  );
};
