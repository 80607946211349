import {
  Alignment,
  arrow,
  autoUpdate,
  flip,
  hide,
  Middleware,
  offset,
  Padding,
  Placement,
  safePolygon,
  shift,
  Side,
  Strategy,
  useClick,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React, {
  cloneElement,
  forwardRef,
  ForwardRefExoticComponent,
  ReactElement,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Portal } from '~/components/portal';

import * as S from './Tooltip.styles';

export enum TooltipTrigger {
  Click = 'click',
  Focus = 'focus',
  Hover = 'hover',
}

export interface TooltipProps {
  label: React.ReactNode;
  placement?: Placement;
  children: ReactElement<any>;
  isOpen?: boolean;
  disabled?: boolean;
  inPortal?: boolean;
  dismissible?: boolean;
  hasDelay?: boolean;
  strategy?: Strategy;
  offsetX?: number;
  offsetY?: number;
  onOpen?: () => void;
  onClose?: () => void;
  hasArrow?: boolean;
  trigger?: TooltipTrigger | TooltipTrigger[];
  closeLabel?: string;
  isTouchDevice?: boolean;
  className?: string;
  hidePadding?: Padding;
  withSafePolygon?: boolean;
}

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  (
    {
      isOpen,
      children,
      label,
      placement = 'top',
      disabled = false,
      inPortal = false,
      strategy = 'absolute',
      dismissible = true,
      hasDelay = true,
      offsetX = 0,
      offsetY = 0,
      onOpen,
      onClose,
      hasArrow = true,
      withSafePolygon,
      trigger = [
        TooltipTrigger.Click,
        TooltipTrigger.Focus,
        TooltipTrigger.Hover,
      ],
      closeLabel,
      isTouchDevice = false,
      className,
      hidePadding = 0,
    },
    ref
  ) => {
    const arrowRef = useRef(null);
    const [open, setOpen] = useState(isOpen);

    const handlers = Array.isArray(trigger) ? trigger : [trigger];

    const setOpenTooltip = useCallback(
      (nextValue: boolean) => {
        if (!disabled) {
          setOpen(nextValue);

          if (nextValue) {
            onOpen?.();
          } else {
            onClose?.();
          }
        } else {
          setOpen(false);
        }
      },
      [disabled, onOpen]
    );

    const { x, y, middlewareData, context, refs } = useFloating({
      placement,
      open,
      onOpenChange: setOpenTooltip,
      strategy,
      middleware: [
        offset(5),
        flip(),
        shift({ padding: 5 }),
        hasArrow ? arrow({ element: arrowRef }) : undefined,
        hide({
          padding: hidePadding,
        }),
      ].filter(Boolean) as Middleware[],
      whileElementsMounted: autoUpdate,
    });

    const optionalUseHoverParams = withSafePolygon
      ? { handleClose: safePolygon() }
      : {};

    const { delay } = useDelayGroup(context, { enabled: hasDelay });
    const { getReferenceProps, getFloatingProps } = useInteractions([
      useHover(context, {
        enabled: handlers.includes(TooltipTrigger.Hover),
        delay,
        ...optionalUseHoverParams,
      }),
      useFocus(context, {
        enabled: handlers.includes(TooltipTrigger.Focus),
      }),
      useClick(context, {
        enabled: handlers.includes(TooltipTrigger.Click),
        ignoreMouse: handlers.includes(TooltipTrigger.Hover),
      }),
      useRole(context, { role: 'tooltip' }),
      useDismiss(context, { enabled: dismissible }),
    ]);

    const refCallback = (node: HTMLElement | null) => {
      refs.setReference(node);

      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    };

    const coords = middlewareData.arrow;
    const referenceHidden = middlewareData?.hide?.referenceHidden;

    const arrowPosition = context.placement || placement;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[arrowPosition.split('-')[0]] as Side;

    const alignment = arrowPosition.split('-')[1] as Alignment;

    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

    const tooltip = open && (
      <S.TooltipBubble
        ref={refs.setFloating}
        onClick={() => isTouchDevice && setOpen(false)}
        className={className}
        {...getFloatingProps({
          style: {
            visibility: referenceHidden ? 'hidden' : 'visible',
            position: strategy,
            top: (y ?? 0) + offsetY,
            left: (x ?? 0) + offsetX,
            zIndex: 'var(--z-tooltip)',
          },
        })}
        data-testid="tooltip-content"
      >
        {label}
        {isTouchDevice && (
          <S.MobileTapClose data-testid="close-label">
            {closeLabel}
          </S.MobileTapClose>
        )}
        {hasArrow && (
          <S.ArrowIcon
            data-testid="tooltip-arrow"
            ref={arrowRef}
            x={coords?.x}
            y={coords?.y}
            $placement={staticSide}
            $alignment={alignment}
          />
        )}
      </S.TooltipBubble>
    );

    return (
      <>
        {cloneElement(children, {
          ref: refCallback,
          ...getReferenceProps(children.props),
        })}
        {inPortal ? <Portal>{tooltip}</Portal> : tooltip}
      </>
    );
  }
) as ForwardRefExoticComponent<TooltipProps & RefAttributes<HTMLElement>> & {
  Styled: typeof S;
};

Tooltip.Styled = S;
