import { AssetLogo, AssetLogoProps } from '@toggle/design-system';
import React from 'react';

import { useThemeStore } from '../theme-controller/stores/useThemeStore';
import { isDarkTheme } from '../theme-controller/utils/theme-utils/theme-utils';

export type AssetLogoContainerProps = Omit<AssetLogoProps, 'isDarkTheme'>;

export const AssetLogoContainer = (props: AssetLogoContainerProps) => {
  const themeName = useThemeStore(state => state.themeName);
  const isDark = isDarkTheme(themeName);

  return <AssetLogo {...props} isDarkTheme={isDark} />;
};
