import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ViewMoreList.styles';

export type ViewMoreListProps = {
  children: ReactNode;
  height: number;
};

export const ViewMoreList = ({ height, children }: ViewMoreListProps) => {
  const { t } = useTranslation('widget');
  const [expanded, setExpanded] = useState(false);
  const [childrenHeight, setChildrenHeight] = useState<number>();

  if (childrenHeight !== undefined && height > childrenHeight) {
    return <>{children}</>;
  }

  return (
    <>
      <S.ListContainer>
        {!expanded && <S.Overlay data-testid="view-more-overlay" />}
        <S.StyledList
          data-testid="list-container"
          $height={expanded && childrenHeight ? childrenHeight : height}
        >
          <div
            ref={node => {
              setChildrenHeight(node?.clientHeight ?? 0);
            }}
          >
            {children}
          </div>
        </S.StyledList>
      </S.ListContainer>
      <S.ExpandButtonContainer>
        <S.StyledButtonLink
          label={
            expanded
              ? t('widget:assetOverview.viewLess')
              : t('widget:assetOverview.viewMore')
          }
          iconName={expanded ? 'ChevronBoldUp' : 'ChevronBoldDown'}
          iconPosition="right"
          onClick={() => setExpanded(!expanded)}
          data-testid="expand-button"
        />
      </S.ExpandButtonContainer>
    </>
  );
};
