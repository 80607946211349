import {
  checkCellValueStyle,
  compareObjectKeys,
  dateToUTC,
  NumericalCellValueStyle,
} from '@toggle/helpers';
import { bisector } from 'd3-array';

import { ChartColors } from '~/core/colors';
import { CustomMixDomain, Domain } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  CreateChartOptionsWithColors,
  PaneData,
} from '~/types/create.types';
import { RangeHorizon } from '~/types/resample.types';
import {
  ChartAssetData,
  Timeseries,
  TimeSeriesItem,
} from '~/types/timeseries.types';

import { getPercentage } from '../../utils/y-scale/y-scale';
import { getStartOfRangeHorizon } from '../dates';

export interface IndexedChartBar extends TimeSeriesItem {
  index: number;
}

export const getBarColor = (
  isBullish: boolean,
  options: CreateChartOptionsWithColors
) =>
  isBullish ? options.colors.bars.up.value : options.colors.bars.down.value;

export const isBarBullish = (bar: TimeSeriesItem) => bar.open <= bar.close;

export const MIN_DAYS_OFFSET = 15;
export const MAX_RANGE = 60;
export const DATES_PADDING_RIGHT = 0.03;
export const MIN_IDX_RANGE = 4;

export const getDomainDataPointsToAdd = (diff: number) =>
  Math.max(
    MIN_DAYS_OFFSET,
    Math.min(Math.round(DATES_PADDING_RIGHT * diff), MAX_RANGE)
  );

export const getDomainFromHorizon = ({
  timeseries,
  horizon,
  lastIdx,
}: {
  timeseries: Timeseries;
  horizon: RangeHorizon;
  lastIdx: number;
}): Domain => {
  const endDate = new Date(timeseries[lastIdx].time);
  //start represents date in timezone's local time of the UTC 00:00
  //example: for America/New_York start will be 19:00 while UTC is 00:00
  const start = getStartOfRangeHorizon(horizon, endDate);

  if (!start) {
    return [0, lastIdx];
  }

  if (start === endDate.getTime()) {
    return [lastIdx - 1, lastIdx];
  }

  const bis = bisector((d: TimeSeriesItem) => new Date(d.time));
  const startIdx = bis.left(
    timeseries,
    //we want to find the date with 00:00 local time
    dateToUTC(new Date(start)),
    0,
    lastIdx - 1 //handle case when closest date for specific timezones is the last one(1D horizon)
  );

  return [startIdx, lastIdx];
};

export interface HorizonWithPriceRange {
  horizon: RangeHorizon;
  firstPrice: number;
  lastPrice: number;
  priceChangePercentage: number;
  lineColorToken: string;
}

export const getPricesForAllHorizons = ({
  horizonRanges,
  timeseries,
  lastIdx,
  colors,
}: {
  horizonRanges: RangeHorizon[];
  timeseries: Timeseries;
  lastIdx: number;
  colors: Pick<ChartColors, 'bars' | 'line'>;
}): HorizonWithPriceRange[] => {
  const lineColorTokenMapping = {
    [NumericalCellValueStyle.Positive]: colors.bars.up.token,
    [NumericalCellValueStyle.Negative]: colors.bars.down.token,
    [NumericalCellValueStyle.Neutral]: colors.line.default.token,
  };

  return horizonRanges.reduce((result, horizon) => {
    const domain = getDomainFromHorizon({ timeseries, horizon, lastIdx });
    const firstPrice = timeseries[domain[0]]?.close;
    const lastPrice = timeseries[domain[1]]?.close;
    const priceChangePercentage = getPercentage(lastPrice, firstPrice);
    const numericalStyle = checkCellValueStyle(priceChangePercentage);
    const lineColorToken = lineColorTokenMapping[numericalStyle];

    result.push({
      horizon,
      firstPrice,
      lastPrice,
      priceChangePercentage,
      lineColorToken,
    });

    return result;
  }, [] as HorizonWithPriceRange[]);
};

// When the resample option changes in any given horizon, the horizon will be defined by the number of data points, which doesn't change.
// For instance, the current chart contains 48 data points and user changes the resample from 1 minute to 5 minutes
// the new chart will still contains 48 data points but 5 minutes intervals.
export const getDomainForResample = (
  lastDataIdx: number,
  prevBase: BaseChartAPIProps
) => {
  const prevDomain = prevBase.domain;
  const indexesDiff = Math.abs(
    prevBase.primaryAsset.ts.length - 1 - prevBase.domain[1]
  );
  const dataPointsCount = Math.min(lastDataIdx, prevDomain[1] - prevDomain[0]);
  return [
    Math.max(0, lastDataIdx - indexesDiff - dataPointsCount),
    Math.min(Math.abs(lastDataIdx - indexesDiff), lastDataIdx),
  ] as Domain;
};

interface GetChartDomainProps {
  prevBase?: BaseChartAPIProps;
  timeseries: Timeseries;
  horizon: RangeHorizon | null;
  lastDataIdx: BaseChartAPIProps['lastDataIdx'];
  resampleInterval: BaseChartAPIProps['resampleInterval'];
  customDomain: CustomMixDomain | null;
}

export const getChartDomain = ({
  prevBase,
  timeseries,
  horizon,
  lastDataIdx,
  resampleInterval,
  customDomain,
}: GetChartDomainProps): Domain => {
  const onlyResampleChanged = isOnlyResampleChanged({
    prevBase,
    horizon,
    resampleInterval,
    customDomain,
  });

  if (onlyResampleChanged) {
    return getDomainForResample(lastDataIdx, prevBase as BaseChartAPIProps);
  }

  if (horizon) {
    return getDomainFromHorizon({ timeseries, horizon, lastIdx: lastDataIdx });
  }

  if (customDomain) {
    return getDomainFromCustom(customDomain, timeseries, lastDataIdx);
  }

  return prevBase
    ? [prevBase.domain[0], Math.min(lastDataIdx, prevBase.domain[1])]
    : [0, lastDataIdx];
};

export const getDomainTimeSeries = (
  chartAssetData: ChartAssetData[],
  primaryAssetData: ChartAssetData,
  startIndex: number,
  endIndex: number
) => {
  const bis = bisector((d: TimeSeriesItem) => d.time);

  return chartAssetData.map(asset => {
    const start =
      primaryAssetData.ts[startIndex] &&
      bis.left(asset.ts, primaryAssetData.ts[startIndex].time);
    const end =
      primaryAssetData.ts[endIndex] &&
      bis.left(asset.ts, primaryAssetData.ts[endIndex].time);

    return asset.ts[start] || asset.ts[end]
      ? {
          startIndex: start,
          endIndex: end,
          start: asset.ts[start],
          end: asset.ts[end],
        }
      : null;
  });
};

export const getDomainFromCustom = (
  customDomain: CustomMixDomain,
  timeseries: TimeSeriesItem[],
  lastDataIdx: number
): Domain => {
  const bis = bisector((d: TimeSeriesItem) => new Date(d.time));
  let start = 0;
  let end = lastDataIdx;

  if (typeof customDomain[0] === 'string') {
    start = bis.left(timeseries, new Date(customDomain[0]));
  } else {
    start = customDomain[0];
  }

  if (typeof customDomain[1] === 'string') {
    end = bis.left(timeseries, new Date(customDomain[1]));
  } else {
    end = customDomain[1];
  }

  return [
    Math.max(0, Math.min(start, lastDataIdx - MIN_IDX_RANGE)),
    Math.min(end, lastDataIdx),
  ];
};

interface OnlyResampleChangedProps
  extends Pick<
    GetChartDomainProps,
    'prevBase' | 'horizon' | 'customDomain' | 'resampleInterval'
  > {}

export const isOnlyResampleChanged = ({
  prevBase,
  horizon,
  resampleInterval,
  customDomain,
}: OnlyResampleChangedProps) => {
  if (!prevBase) {
    return false;
  }

  const horizonChanged = !!horizon && prevBase.horizon !== horizon;
  const resampleChanged = prevBase.resampleInterval !== resampleInterval;
  const domainChanged =
    customDomain &&
    !compareObjectKeys(prevBase.domain, customDomain, ['0', '1']);
  return resampleChanged && !horizonChanged && !domainChanged;
};

export const getPrimaryDetails = (chartPanes: PaneData[]) => {
  let asset: ChartAssetData | undefined;
  let primaryPane: PaneData | undefined;

  //asset with longest data history
  chartPanes.forEach(pane => {
    pane.chartAssetData.forEach(data => {
      if (!asset || data.ts.length > asset.ts.length) {
        asset = data;
        primaryPane = pane;
      }
    });
  });

  return {
    primaryAsset: asset as ChartAssetData,
    primaryPane: primaryPane as PaneData,
  };
};

export const createFakeTS = ({
  ts,
  dataPointsToAdd,
  index1,
  index2,
  lastKnownDate,
}: {
  ts: TimeSeriesItem[];
  index1: number;
  index2: number;
  dataPointsToAdd: number;
  lastKnownDate: Date;
}) => {
  const diff =
    new Date(ts[index1].time).getTime() - new Date(ts[index2].time).getTime();

  const weekDays = [0, 6];
  const fake: TimeSeriesItem[] = [];

  while (fake.length !== dataPointsToAdd) {
    lastKnownDate.setTime(lastKnownDate.getTime() + diff);

    if (weekDays.includes(lastKnownDate.getUTCDay())) {
      lastKnownDate.setUTCDate(lastKnownDate.getUTCDate() + 1);
    }

    const element = {
      time: lastKnownDate.toISOString(),
    } as TimeSeriesItem;
    fake.push(element);
  }

  return fake;
};

// Ignore time of the day for non price assets
export const getTimefromSeries = (isPriceSnake: boolean, time: string) => {
  return isPriceSnake ? time : `${time.slice(0, 10)}T00:00:00Z`;
};

export const hasOHLC = (chartAssetData?: ChartAssetData) => {
  const fieldsOHLC: (keyof TimeSeriesItem)[] = ['high', 'low'];
  return (
    !!chartAssetData &&
    fieldsOHLC.some(
      field => chartAssetData.ts[chartAssetData.ts.length - 1][field] !== 0
    )
  );
};
