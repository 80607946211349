import { z } from 'zod';

import { mappedEntitySchema } from '../entity-schema';

export const keyDevsRequestSchema = z.object({
  entities: z.array(z.string()).optional(),
  filters: z.array(
    z.object({
      types: z.array(z.string()),
      start_date: z.string(),
      end_date: z.string().optional(),
    })
  ),
  watchlists: z
    .object({
      all: z.boolean(),
      identities: z.array(z.string()),
    })
    .optional(),
  page: z
    .object({
      number: z.number(),
      size: z.number(),
    })
    .optional(),
  sort_by: z
    .array(
      z.object({
        field: z.string(),
        desc: z.boolean(),
      })
    )
    .optional(),
});

export type KeyDevsRequest = z.infer<typeof keyDevsRequestSchema>;

export const keyDevsObjectSchema = z.object({
  entity_tag: z.string(),
  event_type: z.string(),
  effective_date: z.string(),
  headline: z.string(),
  situation: z.string().optional(),
  reported: z.boolean(),
});

export const keyDevsObjectWithEntitySchema = keyDevsObjectSchema.extend({
  entity: mappedEntitySchema.optional(),
});

export type KeyDevsObject = z.infer<typeof keyDevsObjectSchema>;
export type KeyDevsObjectWithEntity = z.infer<
  typeof keyDevsObjectWithEntitySchema
>;

export const keyDevsResponseSchema = z.object({
  key_devs: z.array(keyDevsObjectSchema),
  page: z.object({
    current: z.number(),
    next: z.number(),
    size: z.number(),
  }),
  total_count: z.number(),
});

export type KeyDevsResponse = z.infer<typeof keyDevsResponseSchema>;
