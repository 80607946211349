import { Alert, AlertProps, AlertType } from '@toggle/design-system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { sendTicket } from '~/services/zendesk/zendeskService';
import { useToasts } from '~/stores/use-toasts/useToasts';
import { useUser } from '~/stores/use-user/useUser';

export interface PortfolioToast {
  id: number;
  type: PortfolioErrors | PortfolioSuccess | undefined;
  customToast: AlertProps | undefined;
}

export enum PortfolioErrors {
  AlreadyConnected = 'ALREADY_CONNECTED',
  Unknown = 'UNKNOWN',
  UnknownGetHelp = 'UNKNOWN_GET_HELP',
}

export enum PortfolioSuccess {
  PortfolioRemoved = 'PORTFOLIO_REMOVED',
  SupportContacted = 'SUPPORT_CONTACTED',
}

type PortfolioErrorBanners = {
  [key in PortfolioErrors]: AlertProps;
};

type PortfolioSuccessBanners = {
  [key in PortfolioSuccess]: AlertProps;
};

export const usePortfolioToasts = (
  toast: PortfolioToast,
  clearCurrentToast: () => void
) => {
  const { t } = useTranslation('settings');
  const { user } = useUser(state => ({ user: state.user }));
  const { email = '', full_name = '' } = user ?? {};

  const { showToast, removeToast, clearScopedToasts } = useToasts(
    ({ showToast, removeToast, clearScopedToasts }) => ({
      showToast,
      removeToast,
      clearScopedToasts,
    })
  );

  const onContactUsClick = () => {
    sendTicket({
      subject: 'Failed portfolio connection',
      commentBody:
        'It looks like this IB portfolio is already connected on another account',
      requester: {
        name: full_name,
        email,
      },
    });
    const toastId = new Date().getTime();
    showPortfolioToast(toastId, PortfolioSuccess.SupportContacted);
  };

  const portfolioErrorBanners: PortfolioErrorBanners = {
    [PortfolioErrors.AlreadyConnected]: {
      type: AlertType.Error,
      title: t('settings:portfolio.error.alreadyConnected.title'),
      message: t('settings:portfolio.error.alreadyConnected.message'),
    },
    [PortfolioErrors.Unknown]: {
      type: AlertType.Error,
      title: t('settings:portfolio.error.unknownTryAgain.title'),
    },
    [PortfolioErrors.UnknownGetHelp]: {
      type: AlertType.Error,
      title: t('settings:portfolio.error.unknown.title'),
      primaryBtn: {
        label: t('settings:portfolio.contactSupport'),
        onClick: onContactUsClick,
      },
    },
  };
  const portfolioSuccessBanners: PortfolioSuccessBanners = {
    [PortfolioSuccess.PortfolioRemoved]: {
      type: AlertType.Success,
      title: t('settings:portfolio.success.portfolioRemoved.title'),
    },
    [PortfolioSuccess.SupportContacted]: {
      type: AlertType.Success,
      title: t('settings:portfolio.success.supportContacted.title'),
    },
  };

  const showPortfolioToast = (
    toastId: number,
    type: PortfolioErrors | PortfolioSuccess
  ) => {
    const bannerProps = Object.values(PortfolioErrors).includes(
      type as PortfolioErrors
    )
      ? portfolioErrorBanners[type as PortfolioErrors]
      : portfolioSuccessBanners[type as PortfolioSuccess];

    showToast({
      id: toastId,
      scope: 'portfolio',
      content: (
        <Alert
          {...bannerProps}
          primaryBtn={
            bannerProps.primaryBtn && {
              label: bannerProps.primaryBtn.label,
              onClick: () => {
                bannerProps.primaryBtn?.onClick();
                removeToast(toast.id);
                clearCurrentToast();
              },
            }
          }
          onClose={() => {
            removeToast(toastId);
            clearCurrentToast();
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (toast.customToast) {
      showToast({
        id: toast.id,
        scope: 'portfolio',
        content: (
          <Alert
            {...toast.customToast}
            primaryBtn={
              toast.customToast.primaryBtn && {
                label: toast.customToast.primaryBtn.label,
                onClick: () => {
                  toast.customToast?.primaryBtn?.onClick();
                  removeToast(toast.id);
                  clearCurrentToast();
                },
              }
            }
            onClose={() => {
              removeToast(toast.id);
              clearCurrentToast();
            }}
          />
        ),
      });
    } else if (toast.type) {
      showPortfolioToast(toast.id, toast.type);
    }
  }, [toast.id]);

  useEffect(() => {
    return () => {
      clearCurrentToast();
      clearScopedToasts('portfolio');
    };
  }, []);
};
