import {
  ChartAssetData,
  CreateChartOptionsWithConfig,
  i18n,
  PaneData,
  PriceHoverData,
  RangeHorizon,
  ResampleIntervals,
} from '@toggle/chart';
import { Entity, formatTicker } from '@toggle/toggle';
import React, { useRef } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { ChartAreaWrapperProps } from '~/components/chart-area-wrapper/ChartAreaWrapper';
import { ChartArea } from '~/modules/simple-chart/components/chart-area/ChartArea';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';
import { useTurboChart } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';
import { ValidChartSettings } from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import { MiniLegend } from './components/mini-legend/MiniLegend';
import * as S from './MultiLineChart.styles';

export interface TooltipSortConfig {
  field: keyof PriceHoverData;
  desc: boolean;
}
export interface MultiLineChartProps {
  entities: Pick<Entity, 'tag' | 'default_snake' | 'ticker' | 'exchange'>[];
  chartConfig?: ChartAreaWrapperProps['chartConfig'];
  tooltipSortConfig?: TooltipSortConfig;
}

const DEFAULT_SIMPLE_CHART_CONFIG: Partial<
  CreateChartOptionsWithConfig['config']
> = {
  enableZoom: true,
  enableHover: true,
  hoverVariant: 'simple',
};

export const MultiLineChart = ({
  entities,
  chartConfig,
  tooltipSortConfig,
}: MultiLineChartProps) => {
  const initialChartSettings: ValidChartSettings = {
    assets: entities.map(entity => ({
      tag: entity.tag,
      snake: entity.default_snake,
      paneIndex: 0,
    })),
    resample: ResampleIntervals.OneDay,
    horizon: RangeHorizon.OneYear,
    domain: null,
    hidden: undefined,
    paneSettings: {
      0: {
        yAxisType: 'merged',
        priceDisplay: 'percentage',
      },
    },
  };

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { colors } = useChartTheme(['soft', 'multiline']);

  const {
    assetWithError,
    chartData,
    chartApiRef,
    hasError,
    hasEmpty,
    dailyTimeseries,
    chartState,
    retryInitialChartLoad,
    primaryAsset,
    primaryPane,
  } = useTurboChart({
    initialChartSettings,
    shouldUseSearchParams: !initialChartSettings,
  });

  useTranslation(['chart', 'analyze', 'countries']);

  const isLoading = !hasError && !hasEmpty && !dailyTimeseries && !chartData[0];

  if (isLoading) {
    return (
      <S.SkeletonWrapper data-testid="chart-loading">
        <S.SkeletonChartLoader />
      </S.SkeletonWrapper>
    );
  }

  const finalChartConfig = {
    ...DEFAULT_SIMPLE_CHART_CONFIG,
    ...chartConfig,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <S.Container data-testid="multi-line-chart">
        <S.Body>
          <S.StyledChartAreaWrapper
            assetWithError={assetWithError}
            hasError={hasError}
            hasEmpty={hasEmpty}
            colors={colors}
            chartData={chartData}
            chartConfig={finalChartConfig}
            onRetry={retryInitialChartLoad}
            renderChartArea={options => (
              <ChartArea
                canvasRef={canvasRef}
                chartApiRef={chartApiRef}
                options={options}
                chartState={chartState}
                primaryAsset={primaryAsset as ChartAssetData}
                primaryPane={primaryPane as PaneData}
                tooltipSortConfig={tooltipSortConfig}
              />
            )}
          />
          <MiniLegend
            labels={chartData.map(c => formatTicker(c.entity))}
            colors={colors.getMulti()}
          />
        </S.Body>
      </S.Container>
    </I18nextProvider>
  );
};
