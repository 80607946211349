import { formatTicker } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyListItem } from '~/components/peer-section/company-list-item/CompanyListItem';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { useThemeCompanyEntities } from '~/hooks/use-theme-overview/useThemeOverview';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

import { DrawerSection } from '../drawer-section/DrawerSection';
import * as S from './CompanySection.styles';

type CompanySectionProps = {
  onFooterClick: () => void;
};

export const CompanySection = ({ onFooterClick }: CompanySectionProps) => {
  const { t } = useTranslation('widget');
  const themeId = useThemeOverviewStore(state => state.themeId);
  const { data, isLoading, isError, isRefetching, refetch } =
    useThemeCompanyEntities({ themeId });
  const { navigateToPage } = useNavigateToAssetOverview();

  const openAssetOverview = (value: string) => {
    navigateToPage({ entityTag: value });
  };

  const companies =
    data?.companiesData.map((company, i) => ({
      id: company.tag,
      entity: company.mappedEntity,
      label: formatTicker(company.mappedEntity),
      tooltip: t('widget:themeOverview.rank', {
        rank: i + 1,
      }),
    })) ?? [];

  const total = companies.length > 10 ? 10 : companies.length;
  const moreCompaniesTotal = companies.length - total;

  return (
    <S.Root>
      <DrawerSection
        isLoading={isLoading || isRefetching}
        isError={isError}
        isEmpty={!data?.companiesData.length}
        headerDescription={t('themeOverview.rankedBySignificance')}
        headerTitle={t('themeOverview.topCompaniesTitle', { total })}
        footerLabel={t('themeOverview.viewMoreCompanies', {
          context: moreCompaniesTotal ? 'count' : 'none',
          total: moreCompaniesTotal,
        })}
        footerOnClick={onFooterClick}
        refetch={refetch}
      >
        <S.GridContainer>
          {companies.slice(0, 10).map(company => (
            <S.CompanyListItemWrapper key={company.id}>
              <CompanyListItem
                company={company}
                onPillClick={() => openAssetOverview(company.entity.tag)}
                iconSize={20}
              />
            </S.CompanyListItemWrapper>
          ))}
        </S.GridContainer>
      </DrawerSection>
    </S.Root>
  );
};
