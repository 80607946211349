import { Alert, AlertType, Button, ButtonProps } from '@toggle/design-system';
import { useCountdown } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthStore } from '~/stores/use-auth/store/authStore';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';
import { postSendVerifyEmail } from '~/views/auth/services/auth-services';

const VERIFY_EMAIL_COUNTDOWN_TIMER_KEY = 'verify-email-countdown-timer';
const COUNTDOWN_TIME_SECONDS = 120;
export const TOAST_SUCCESS_ID = 'verify-email-success-toast';
export const TOAST_FAILED_ID = 'verify-email-failed-toast';

export const VerifyEmailButton = (props: ButtonProps) => {
  const { t } = useTranslation('auth');
  const { showToast, removeToast } = useToasts();

  const email = useAuthStore(state => state.email);
  const { timeLeft, newCountdown, stopCountdown } = useCountdown({
    key: VERIFY_EMAIL_COUNTDOWN_TIMER_KEY,
    time: COUNTDOWN_TIME_SECONDS,
  });

  const sendVerifyEmail = async () => {
    if (!email) {
      return;
    }

    newCountdown();
    const response = await postSendVerifyEmail(email);

    if (response.error) {
      stopCountdown();
    }

    const toastId = response.error ? TOAST_FAILED_ID : TOAST_SUCCESS_ID;
    const alertProps = response.error
      ? {
          type: AlertType.Error,
          title: t('auth:onHold.emailSentError'),
          message: t('auth:onHold.emailSentDescriptionError'),
        }
      : {
          type: AlertType.Success,
          title: t('auth:onHold.emailSent'),
          message: t('auth:onHold.emailSentDescription', {
            email,
          }),
        };

    showToast({
      id: toastId,
      hideToast: true,
      position: ToastPosition.TopRight,
      content: (
        <Alert withIcon onClose={() => removeToast(toastId)} {...alertProps} />
      ),
    });
  };

  return (
    <Button
      data-testid="verify-email-button"
      type="button"
      label={
        timeLeft
          ? t('auth:onHold.verifyEmailTimer', { timeLeft: timeLeft })
          : t('auth:onHold.verifyEmail')
      }
      disabled={Boolean(timeLeft)}
      onClick={sendVerifyEmail}
      {...props}
    />
  );
};
