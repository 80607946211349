import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const KeyValueElementRoot = styled.div`
  display: flex;
  padding-left: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-left: 2px solid var(--border-soft);
  height: 70px;
  justify-content: space-between;
`;

export const Title = styled.div`
  ${typographyV2.titleSm};
  color: var(--text-soft);
`;

export const Details = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
`;

export const Value = styled.span`
  ${typographyV2.bodyMd};
  font-family: var(--font-roboto-mono);
`;
export const Unit = styled.span`
  ${typographyV2.bodyXs};
`;
