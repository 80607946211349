import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFilterModal } from '~/components/custom-filter-modal/CustomFilterModal';
import { Filter } from '~/components/filter/Filter';
import { ToggleFilterProps } from '~/components/update-filter-options/updateFilterOptions';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { FiltersPanel } from '~/views/screener/filters-panel/FiltersPanel';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';

import { useScenarioStore } from '../../hooks/use-scenario-store/useScenarioStore';

export const ColumnFilters = () => {
  const { t } = useTranslation('widget');

  const { removeFilterOption, addFilterOption, addActiveFilters } =
    useFilterActions({ store: useScenarioStore, group: 'COLUMN_FILTERS' });

  const {
    allFilters = [],
    activeFilterOptions = [],
    activeFilters = [],
    activeModalFilter,
    addColumnFilter,
    clearActiveModalFilter,
    clearColumnFilter,
    clearAllColumnsFilters,
    setActiveModalFilter,
  } = useScenarioStore(state => ({
    ...state.filterGroups['COLUMN_FILTERS'],
    activeModalFilter: state.activeModalFilter,
    addColumnFilter: state.addColumnFilter,
    clearActiveModalFilter: state.clearActiveModalFilter,
    clearAllColumnsFilters: state.clearAllColumnsFilters,
    clearColumnFilter: state.clearColumnFilter,
    setActiveModalFilter: state.setActiveModalFilter,
  }));
  const { filterGroups } = useScreenerStore(state => ({
    filterGroups: state.filterGroups,
  }));

  useEffect(() => {
    clearAllColumnsFilters();
  }, [filterGroups]);

  const labels = {
    searchPlaceholder: t('screener:search'),
    resetLabel: t('screener:reset'),
    noResultsLabel: t('screener:noResults'),
    selectedLabel: t('screener:selected'),
    disabledTooltipLabel: t('screener:disabledTooltip'),
    multiselectFeatured: t('screener:multiselectFeatured'),
    remove: t('scenario:conditionDropdown.remove'),
    setCustomRange: t('screener:setCustomRange'),
    edit: t('screener:edit'),
  };

  const onAddFilterOption = (props: ToggleFilterProps) => {
    const activeFilter = allFilters.find(f => f.key === props.filterKey);

    if (activeFilter) {
      addActiveFilters(activeFilter);
      addFilterOption(props);
      addColumnFilter(props);
    }
  };

  return (
    <>
      {activeFilters.length > 0 && (
        <FiltersPanel title={t('scenario:scenarioColumnFilters')}>
          {activeFilters.map(f => (
            <Filter
              filter={f}
              key={f.key}
              onCustomRangeClick={() => setActiveModalFilter(f)}
              labels={labels}
              filterOptions={
                activeFilterOptions.find(filter => filter.filter === f.key)
                  ?.options ?? []
              }
              resetFilterOptions={clearColumnFilter}
              removeFilterOption={removeFilterOption}
              addFilterOption={addFilterOption}
            />
          ))}
        </FiltersPanel>
      )}
      {activeModalFilter && (
        <CustomFilterModal
          onClose={() => clearActiveModalFilter()}
          activeFilter={activeModalFilter}
          addFilterOption={onAddFilterOption}
        />
      )}
    </>
  );
};
