import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChartEmptyRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
  margin: 0 auto;
  padding: 12px;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.div`
  ${typographyV2.titleBase}
  color: var(--text-default);
`;

export const Description = styled.div`
  ${typographyV2.bodySm}
  color: var(--text-soft);
  margin-bottom: 16px;
`;
