import ToggleLogo from '@static/images/brand/logo-dark.svg';
import { deviceConstraints, typographyV2 } from '@toggle/design-system';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';

export const wrapperCss = css`
  background: var(--surface-default);
  padding: 40px;
`;

export const Main = styled.main`
  max-width: 480px;
  margin: 6% auto;
`;

export const Logo = styled(ToggleLogo)`
  width: 114px;
  padding: 32px;
  box-sizing: content-box;

  [class*='logoColor'] {
    fill: var(--copilot--default);
  }

  @media ${deviceConstraints.tablet} {
    width: 121px;
  }
`;

export const WrapperWithBg = styled.div`
  ${wrapperCss}
`;

export const Heading = styled.h1`
  margin-bottom: 32px;
  ${typographyV2.titleXL};
`;

export const SendButtonWrapper = styled.div`
  margin: 40px 0 16px 0;
`;

export const CenterContent = styled.div`
  text-align: center;
`;

export const CancelButton = styled(Link)`
  ${typographyV2.uiBase};

  &:hover {
    text-decoration: underline;
  }
`;

export const Layout = styled.div`
  min-height: 100vh;
  width: 100%;
  background: var(--background-default);
  background-image: url('/app/static/images/auth/sun.png'),
    url('/app/static/images/auth/stars.png');
  background-position: center bottom, center top;
  background-repeat: no-repeat, repeat;
`;

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background: var(--background-default);
  background-image: url('/app/static/images/auth/sun.png'),
    url('/app/static/images/auth/stars.png');
  background-position: center bottom, center top;
  background-repeat: no-repeat, repeat;
`;
