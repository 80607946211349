import { keyboard } from '@toggle/helpers/src/constants';
import { useDisableScroll } from '@toggle/helpers/src/hooks/use-disable-scroll/useDisableScroll';
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  Fragment,
  ReactNode,
  RefAttributes,
  useEffect,
  useRef,
} from 'react';

import { Button, ButtonProps } from '../button/Button';
import { Portal } from '../portal';
import * as S from './Modal.styles';

export const CLOSE_MODAL_ID = 'modal-close-button';
export const MODAL_ID = 'modal';

export interface ModalProps {
  isOpen: boolean;
  withBackdrop?: boolean;
  withClose?: boolean;
  outsideClickClose?: boolean;
  description?: ReactNode;
  title?: ReactNode;
  onClose?: () => void;
  children?: ReactNode;
  imageNode?: ReactNode;
  cancelBtnProps?: ButtonProps;
  secondaryBtnProps?: ButtonProps;
  primaryBtnProps?: ButtonProps;
  className?: string;
  'data-testid'?: string;
  inPortal?: boolean;
}

export const Modal = forwardRef<HTMLButtonElement, ModalProps>(
  // eslint-disable-next-line complexity
  (
    {
      withBackdrop = true,
      withClose = true,
      outsideClickClose = true,
      isOpen,
      title,
      onClose,
      description,
      children,
      cancelBtnProps,
      secondaryBtnProps,
      primaryBtnProps,
      imageNode,
      className,
      inPortal = true,
      ...rest
    }: ModalProps,
    ref
  ) => {
    const backdrop = useRef<HTMLDivElement>(null);

    useDisableScroll(isOpen);

    useEffect(() => {
      const { current } = backdrop;

      const keyHandler = (e: KeyboardEvent) => {
        if (e.key === keyboard.esc.key) {
          onClose?.();
        } else if (
          e.key === keyboard.enter.key &&
          primaryBtnProps?.onClick &&
          !primaryBtnProps.disabled
        ) {
          e.preventDefault();
          //@ts-ignore
          primaryBtnProps.onClick();
        }
      };

      const clickHandler = (e: MouseEvent) => {
        outsideClickClose && e.target === current && onClose?.();
      };

      if (current) {
        current.addEventListener('click', clickHandler);
        window.addEventListener('keyup', keyHandler);
      }

      if (isOpen && inPortal) {
        document.querySelector('#app')?.setAttribute('inert', 'true');
      }

      return () => {
        if (current) {
          current.removeEventListener('click', clickHandler);
        }

        document.querySelector('#app')?.removeAttribute('inert');
        window.removeEventListener('keyup', keyHandler);
      };
    }, [isOpen, onClose, primaryBtnProps]);

    const content = isOpen ? (
      <S.ModalContainer
        role="dialog"
        data-testid={rest['data-testid'] ?? 'modal-container'}
        $fillContainer={!inPortal}
        id={MODAL_ID}
      >
        <S.Header>
          {(title || description || imageNode) && (
            <S.TextContainer>
              {imageNode && <S.ImageContainer>{imageNode}</S.ImageContainer>}
              {title && <S.Title data-testid="modal-title">{title}</S.Title>}
              {description && <S.Description>{description}</S.Description>}
            </S.TextContainer>
          )}
          {withClose && (
            <S.CloseButton
              data-testid="modal-close-button"
              id={CLOSE_MODAL_ID}
              onClick={onClose}
              iconName="Close"
              variant="empty"
              size="small"
              ref={ref}
            />
          )}
        </S.Header>
        {children}
        {[cancelBtnProps, secondaryBtnProps, primaryBtnProps].some(Boolean) && (
          <S.ButtonsContainer>
            {cancelBtnProps && (
              <Button
                data-testid="modal-cancel-button"
                variant="tertiary"
                size="large"
                {...cancelBtnProps}
              />
            )}
            {secondaryBtnProps && (
              <Button
                data-testid="modal-secondary-button"
                variant="secondary"
                size="large"
                {...secondaryBtnProps}
              />
            )}
            {primaryBtnProps && (
              <Button
                data-testid="modal-primary-button"
                variant="primary"
                size="large"
                {...primaryBtnProps}
              />
            )}
          </S.ButtonsContainer>
        )}
      </S.ModalContainer>
    ) : null;

    const modalContent = (
      <S.Container className={className}>
        {withBackdrop && (
          <S.Backdrop
            data-testid="modal-backdrop"
            ref={backdrop}
            className={isOpen ? 'active' : undefined}
          />
        )}
        {content}
      </S.Container>
    );

    return (
      <Fragment>
        {isOpen && (inPortal ? <Portal>{modalContent}</Portal> : modalContent)}
      </Fragment>
    );
  }
) as ForwardRefExoticComponent<
  ModalProps & RefAttributes<HTMLButtonElement>
> & {
  Styled: typeof S;
};

Modal.Styled = S;
