import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ClearFiltersButton.styles';

export interface ClearFiltersButtonProps {
  label?: string;
  onClick: () => void;
}

export const ClearFiltersButton = ({
  label,
  onClick,
  ...rest
}: ClearFiltersButtonProps) => {
  const { t } = useTranslation('news');

  return (
    <S.StyledButton
      data-testid="clear-filters-button"
      label={label ?? t('news:resetAllFilters')}
      iconName="Reset"
      iconPosition="left"
      onClick={onClick}
      variant="empty"
      size="small"
      {...rest}
    />
  );
};
