import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 525px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  ${typographyV2.titleBase};
`;

export const StyledButton = styled(Button)`
  margin-top: 6px;
  color: var(--text-primary);
`;

export const StyledTableRow = styled.tr`
  &:hover {
    background: unset !important;
    cursor: default;
  }
`;

export const StyledTableTd = styled.td`
  &:hover {
    background: unset !important;
    cursor: default;
  }
`;
