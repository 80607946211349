import React from 'react';

import { TitleItem } from '../../utils';
import * as S from './ListTitleItem.styles';

export interface ListTitleItemProps {
  item: TitleItem;
  variant: 'default' | 'secondary';
}

export const ListTitleItem = ({ item, variant }: ListTitleItemProps) => {
  if (!item.title) {
    return null;
  }

  return (
    <S.TitleAndDividerWrapper data-testid="list-title-item-root">
      <S.HideOrShowButtonWrapper>
        <S.ListTitle $variant={variant} data-testid={item.title}>
          {item.title}
        </S.ListTitle>
      </S.HideOrShowButtonWrapper>

      {variant === 'secondary' && <S.HorizontalDivider />}
    </S.TitleAndDividerWrapper>
  );
};
