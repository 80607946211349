/* eslint-disable max-lines-per-function */
import { ColumnDef, Row, RowSelectionState } from '@tanstack/react-table';
import { CheckBox, TooltipTrigger } from '@toggle/design-system';
import {
  EarningsPeriod,
  EarningsPeriods,
  MovePeriod,
  PriceMove,
} from '@toggle/toggle';
import { t } from 'i18next';
import React from 'react';

import { DateCell } from '../components/date-cell/DateCell';
import { NumberCell } from '../components/number-cell/NumberCell';
import { PriceCell } from '../components/price-cell/PriceCell';
import * as S from '../Earnings.styles';
import {
  EARNINGS_COL_WIDTH,
  EARNINGS_COLUMN_ID,
  RELEASED_ON_COLUMN_ID,
  SELECTED_COLUMN_ID,
} from './utils';

const SORT_UNDEFINED = 'last';

export const handlePriceData = (price: PriceMove, period: MovePeriod) =>
  price?.find(p => p.period === period)?.change;

const getNumberOfSelectedRowsHeaderText = (
  earningsData: EarningsPeriods,
  rowSelection: RowSelectionState
): string => {
  const totalRows = earningsData.length;
  const numberOfSelectedRows =
    Object.values(rowSelection).filter(Boolean).length;

  if (numberOfSelectedRows === totalRows) {
    return t('widget:earnings.earningsScenario.selectedTotalOf', {
      total: totalRows,
    });
  }

  return t('widget:earnings.earningsScenario.numberOfEarningsSelected', {
    numberOfSelected: numberOfSelectedRows,
    total: totalRows,
  });
};

type GetColumnsProps = {
  getIsAllRowsSelected: () => boolean;
  getIsSomeRowsSelected: () => boolean;
  toggleAllRowsSelection: (selected: boolean) => void;
  updateRowSelection: (key: string, selected: boolean) => void;
  rowSelection: RowSelectionState;
  getIsRowManualChanged: (key: string) => boolean;
  getIsRowMatchingFilters: (key: string) => boolean;
  earningsData: EarningsPeriods;
};

export const getColumns = ({
  getIsAllRowsSelected,
  getIsSomeRowsSelected,
  toggleAllRowsSelection,
  updateRowSelection,
  rowSelection,
  getIsRowManualChanged,
  getIsRowMatchingFilters,
  earningsData,
}: GetColumnsProps): ColumnDef<EarningsPeriod>[] => [
  {
    header: getNumberOfSelectedRowsHeaderText(earningsData, rowSelection),
    columns: [
      {
        id: SELECTED_COLUMN_ID,
        header: () => (
          <S.TableHeaderContent>
            <CheckBox
              name="all-earnings"
              label=""
              onChange={(_, e) => toggleAllRowsSelection(e.target.checked)}
              checked={getIsAllRowsSelected() || getIsSomeRowsSelected()}
              partial={getIsSomeRowsSelected() && !getIsAllRowsSelected()}
            />
          </S.TableHeaderContent>
        ),
        cell: props => {
          const { row } = props;
          const isRowMatchingFilters = getIsRowMatchingFilters(row.id);
          const isManualUpdated = getIsRowManualChanged(row.id);

          return (
            <S.StyledCheckboxTooltip
              isTouchDevice={false}
              label={
                <S.MatchingFilterLabel>
                  {isRowMatchingFilters
                    ? t('widget:earnings.matchesFilter')
                    : t('widget:earnings.outsideFilter')}
                </S.MatchingFilterLabel>
              }
              trigger={[TooltipTrigger.Hover]}
              inPortal
            >
              <S.WrapperCheckbox>
                <S.StyledCheckBox
                  name={`earning-${row.id}`}
                  label=""
                  checked={row.getIsSelected()}
                  onChange={() =>
                    updateRowSelection(row.id, !row.getIsSelected())
                  }
                  $isManualUpdated={isManualUpdated}
                  $isRowMatchingFilters={isRowMatchingFilters}
                />
              </S.WrapperCheckbox>
            </S.StyledCheckboxTooltip>
          );
        },
        size: 50,
      },
      {
        id: EARNINGS_COLUMN_ID,
        header: t('widget:earnings.earnings'),
        cell: props => {
          return <S.EarningsCell>{props.getValue()}</S.EarningsCell>;
        },
        accessorFn: row => `${row.fiscal_period} ${row.fiscal_year}`,
        sortUndefined: SORT_UNDEFINED,
        sortingFn: (rowA: Row<EarningsPeriod>, rowB: Row<EarningsPeriod>) => {
          const dateA = new Date(rowA.original.reporting_date);
          const dateB = new Date(rowB.original.reporting_date);

          if (dateA > dateB) {
            return 1;
          } else if (dateA < dateB) {
            return -1;
          } else {
            return 0;
          }
        },
        size: EARNINGS_COL_WIDTH,
      },
      {
        id: RELEASED_ON_COLUMN_ID,
        header: () => (
          <S.TableHeaderContent>
            {t('widget:earnings.releasedOn')}
          </S.TableHeaderContent>
        ),
        cell: props => <DateCell date={props.getValue()} />,
        accessorFn: row => new Date(row.reporting_date),
        sortUndefined: SORT_UNDEFINED,
      },
    ],
  },
  {
    header: t('widget:earnings.eps'),
    columns: [
      {
        id: 'eps-reported',
        header: t('widget:earnings.reported'),
        cell: props => <PriceCell value={props.getValue()} />,
        accessorFn: row => row.eps.value,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'eps-surprise-dollar',
        header: t('widget:earnings.surprise', { suffix: '$' }),
        cell: props => <NumberCell value={props.getValue()} />,
        accessorFn: row => row.eps.surprise,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'eps-surprise-percentage',
        header: t('widget:earnings.surprise', { suffix: '%' }),
        cell: props => {
          return (
            <span>
              <NumberCell value={props.getValue()} percentage />
            </span>
          );
        },
        accessorFn: row => row.eps.surprise_percentage,
        sortUndefined: SORT_UNDEFINED,
      },
    ],
  },
  {
    header: t('widget:earnings.revenue'),
    columns: [
      {
        id: 'revenue-reported',
        header: t('widget:earnings.reported'),
        cell: props => <PriceCell value={props.getValue()} />,
        accessorFn: row => row.revenue.value,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'revenue-surprise-dollar',
        header: t('widget:earnings.surprise', { suffix: '$' }),
        cell: props => <NumberCell value={props.getValue()} />,
        accessorFn: row => row.revenue.surprise,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'revenue-surprise-percentage',
        header: t('widget:earnings.surprise', { suffix: '%' }),
        cell: props => <NumberCell value={props.getValue()} percentage />,
        accessorFn: row => row.revenue.surprise_percentage,
        sortUndefined: SORT_UNDEFINED,
      },
    ],
  },
  {
    header: t('widget:earnings.percentMove'),
    columns: [
      {
        id: 'return-one-day',
        header: t('widget:earnings.1d'),
        cell: props => <NumberCell value={props.getValue()} percentage />,
        accessorFn: row => handlePriceData(row.price, 'd1'),
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'return-two-days',
        header: t('widget:earnings.2d'),
        cell: props => <NumberCell value={props.getValue()} percentage />,
        accessorFn: row => handlePriceData(row.price, 'd2'),
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'return-one-week',
        header: t('widget:earnings.1w'),
        cell: props => <NumberCell value={props.getValue()} percentage />,
        accessorFn: row => handlePriceData(row.price, 'w1'),
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'return-two-weeks',
        header: t('widget:earnings.2w'),
        cell: props => <NumberCell value={props.getValue()} percentage />,
        accessorFn: row => handlePriceData(row.price, 'w2'),
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'return-one-month',
        header: t('widget:earnings.1m'),
        cell: props => <NumberCell value={props.getValue()} percentage />,
        accessorFn: row => handlePriceData(row.price, 'm1'),
        sortUndefined: SORT_UNDEFINED,
      },
    ],
  },
];
