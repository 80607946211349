import { BillingCycle } from '@toggle/toggle';
import { createSearchParams, NavigateOptions, useNavigate } from 'react-router';

import { accountRoutes, appPaths } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import {
  ChangingPlanTier,
  PlanCardType,
} from '../../plan-matrix/utils/plan-matrix.types';

export const useNavigateToCheckout = () => {
  const navigate = useNavigate();

  const { card } = useSubscription(state => ({
    card: state.card,
  }));

  const upgradeTo = (
    {
      type = PlanCardType.Pro,
      tier = card ? ChangingPlanTier.Upgrading : ChangingPlanTier.Initial,
      billingCycle = BillingCycle.year,
    } = {},
    navigateOptions?: NavigateOptions
  ) => {
    navigate(
      {
        pathname: `${appPaths.settings.subscription}/${accountRoutes.checkout}`,
        search: `?${createSearchParams({
          billingCycle,
          tier,
          type,
        })}`,
      },
      {
        ...navigateOptions,
      }
    );
  };

  return upgradeTo;
};
