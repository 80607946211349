import { NewsFilters, ScreenerFilter } from '@toggle/toggle';

import { NewsFilter, NewsOption } from '~/services/news/news.types';
import { Watchlist } from '~/stores/use-watchlist/watchlist-types';

import { datePickerFilter } from '../get-search-filters/getSearchFilters';

export const WATCHLIST_FILTER_KEY = 'watchlist';

export const mapNewsFiltersToScreenerFilter = (
  newsFilters: NewsFilters
): ScreenerFilter[] =>
  newsFilters.map(filter => ({
    key: filter.key,
    name: filter.name,
    visible: true,
    searchable: false,
    category: 'Universe',
    locked: true,
    config: {
      name: filter.name,
      multiselect: true,
      options: filter.config.options.map(option => ({
        key: option.key,
        name: option.name,
        value: {
          [filter.key]: option.key,
        },
      })),
    },
  }));

export const mapWatchlistToNewsFilter = (lists: Watchlist[]): NewsFilter => ({
  name: 'Watchlist',
  key: WATCHLIST_FILTER_KEY,
  options: lists.map(l => ({
    isChecked: false,
    name: l.name,
    key: l.id,
    entities: l.entities.map(e => e.tag),
    visible: true,
  })),
});

export const mapNewsFilters = (data: NewsFilters): NewsFilter[] =>
  data.map(f => ({
    name: f.name,
    key: f.key,
    options: (f.config?.options || []).reduce<NewsOption[]>(
      (options, option) => {
        if (option.visible) {
          options.push({ ...option, isChecked: false });
        }

        return options;
      },
      []
    ),
  }));

interface CountFilterKeyCheckedProps {
  filters: NewsFilter[];
  filterKey: string;
}

export const countFilterKeyChecked = ({
  filters,
  filterKey,
}: CountFilterKeyCheckedProps) => {
  const filter = filters.find(f => f.key === filterKey);
  let some = false;
  let all = false;
  let count = 0;

  if (filter) {
    count = filter.options.filter(o => o.isChecked).length;
    some = count > 0;

    all = count === filter.options.length;
  }

  return { some, all, count };
};

export const createAccordionFilters = (
  filters: NewsFilters,
  watchlist: Watchlist[]
) => {
  const mappedFilters = mapNewsFilters(filters);
  const watchlistFilter = mapWatchlistToNewsFilter(watchlist);

  return [watchlistFilter, ...mappedFilters];
};

export const createScreenerFilters = (
  newsFilters: NewsFilters
): ScreenerFilter[] => {
  if (!newsFilters.length) {
    return [];
  }

  const [providersFilter] = mapNewsFiltersToScreenerFilter(newsFilters);

  return [providersFilter, datePickerFilter];
};

export const updateWatchlistOptions = (
  filter: NewsFilter,
  lists: Watchlist[]
): NewsOption[] => {
  const optionMap = new Map(filter.options.map(option => [option.key, option]));

  return lists.map(list => {
    const { isChecked = false, visible = true } = optionMap.get(list.id) ?? {};

    return {
      key: list.id,
      name: list.name,
      entities: list.entities.map(entity => entity.tag),
      isChecked,
      visible,
    };
  });
};

export const updateWatchlistFilter = (
  filters: NewsFilter[],
  lists: Watchlist[]
): NewsFilter[] =>
  filters.map(filter => {
    if (filter.key === WATCHLIST_FILTER_KEY) {
      const updatedOptions = updateWatchlistOptions(filter, lists);
      return { ...filter, options: updatedOptions };
    }

    return filter;
  });
