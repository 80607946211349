import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './PanesLimitModal.styles';

interface PanesLimitModalProps {
  onClose: () => void;
}

export const PanesLimitModal: FC<PanesLimitModalProps> = ({ onClose }) => {
  const { t } = useTranslation(['chart', 'common']);

  return (
    <S.StyledModal
      data-testid="panes-limit-modal"
      isOpen
      title={t('chart:widget.paneLimitTitle')}
      onClose={onClose}
      cancelBtnProps={{
        label: t('common:close'),
        onClick: onClose,
      }}
    >
      <S.Text>{t('chart:widget.paneLimitMsg')}</S.Text>
    </S.StyledModal>
  );
};
