import {
  DropdownProps,
  Tooltip,
  TRIGGER_INPUT_ID,
} from '@toggle/design-system';
import { keyboard } from '@toggle/helpers';
import { Entity, EntitySearchType } from '@toggle/toggle';
import React, { FC, MouseEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatInputField } from '~/components/chat/chat-input/chat-input-fields/ChatInputField';
import { SearchAssetItem } from '~/components/search-asset-item/SearchAssetItem';
import { useTagsSearch } from '~/hooks/use-tags-search/useTagsSearch';
import { CHAT_INPUT_ENTITIES_REGEX } from '~/stores/use-watchlist/entityResolver';

import * as S from './ChatWatchlistManagerWidget.styles';

export interface ChatWatchlistManagerProps {
  isPending: boolean;
  value: string;
  onChange: (value: string, isAssetMessage?: boolean) => void;
  onSelectItem: (item: Entity, isInWatchlist: boolean) => void;
  onChatSubmit: (value: string) => void;
  onItemsShowChange: (show: boolean) => void;
  getIsInWatchlist: (item: Entity) => boolean;
  activeListEntityTags: string[];
}

export const ChatWatchlistManager = ({
  isPending,
  value,
  onChange,
  onSelectItem,
  onChatSubmit,
  getIsInWatchlist,
  onItemsShowChange,
  activeListEntityTags,
}: ChatWatchlistManagerProps) => {
  const { t } = useTranslation(['widget', 'analyze', 'countries']);

  const isAssetNameMessage = (value: string) =>
    !CHAT_INPUT_ENTITIES_REGEX.test(value);

  const {
    tags: tagList,
    searchTags,
    clearTags,
    loading: tagsSearchPending,
  } = useTagsSearch({
    searchType: EntitySearchType.Entity,
  });

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const isAsset = isAssetNameMessage(value);
    onChange(value, isAsset);

    if (isAsset) {
      searchTags(value);
    } else {
      clearTags();
    }
  };

  const onClear = () => {
    onChange('');
    clearTags();
  };

  const selectItem = (item: Entity) => {
    if (activeListEntityTags.includes(item.tag)) {
      return;
    }

    onSelectItem(item, getIsInWatchlist(item));
  };

  const onChildKeyDown: DropdownProps<Entity>['onChildKeyDown'] = e => {
    if (value && !items.length && e.key === keyboard.enter.key && !e.shiftKey) {
      handleChatSubmit();
    }
  };

  const handleChatSubmit = () => {
    const question = value.trim();

    if (isPending || !question) {
      return;
    }

    onChatSubmit(question);
    onChange('');
  };

  const handelIsClickedOutside = (
    ref: RefObject<HTMLDivElement | null>,
    e: MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    const target = e.target as HTMLElement;
    return target.id !== TRIGGER_INPUT_ID && !ref.current?.contains(target);
  };

  const items = tagList.slice(0, 5);
  // if there are no items either because value has comma or line break OR search results are empty
  // --> display chat submit
  const showChatInput =
    !tagsSearchPending && !items.length && value.trim().length > 1;

  return (
    <S.StyledContainer>
      <S.StyledDropdown<FC<DropdownProps<Entity>>>
        dropdownItems={items}
        renderDropdownItem={(item, _, isFocused) => {
          const activeListHasEntity = activeListEntityTags.includes(item.tag);
          return (
            <Tooltip
              label={t(
                'widget:watchlistManager.editYourWatchlistToRemoveAssets'
              )}
              disabled={!activeListHasEntity}
            >
              <span>
                <SearchAssetItem
                  key={item.id}
                  isAdded={getIsInWatchlist(item) || activeListHasEntity}
                  isDisabled={activeListHasEntity}
                  entity={item}
                  onClick={onSelectItem}
                  isFocused={isFocused}
                  // @ts-ignore
                  // https://styled-components.com/docs/api#as-polymorphic-prop
                  as="div"
                />
              </span>
            </Tooltip>
          );
        }}
        shouldCloseOnSelect={false}
        strategy="fixed"
        // WATCH-115, Safari issue
        inPortal
        focusedItemIdx={-1}
        onChildKeyDown={onChildKeyDown}
        selectItem={selectItem}
        onItemsShowChange={onItemsShowChange}
        isClickedOutside={handelIsClickedOutside}
      >
        <ChatInputField
          placeholder={t('widget:watchlistManager.chatPlaceholder')}
          onChange={onInputChange}
          value={value}
          maxRows={20}
        >
          {!!items.length && (
            <S.StyledIcon iconName="CloseCircle" size={16} onClick={onClear} />
          )}
        </ChatInputField>
      </S.StyledDropdown>
      {showChatInput && (
        <S.SubmitButton
          variant="primary"
          iconName="ArrowDownLeft"
          size="large"
          iconPosition="right"
          label={t('widget:watchlistManager.submit')}
          data-testid="chat-watchlist-submit-btn"
          onClick={handleChatSubmit}
          isLoading={isPending}
        />
      )}
    </S.StyledContainer>
  );
};
