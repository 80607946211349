import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import React from 'react';

import * as S from './DropdownMenu.styles';

export interface DropdownMenuProps {
  triggerNode: React.ReactNode;
  children?: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  triggerProps?: Partial<RadixDropdown.DropdownMenuTriggerProps>;
  contentProps?: Partial<RadixDropdown.DropdownMenuContentProps>;
  align?: 'start' | 'center' | 'end';
  className?: string;
}

export const DropdownMenu = ({
  triggerNode,
  children,
  onOpenChange,
  open,
  contentProps,
  triggerProps,
  align = 'start',
  className,
}: DropdownMenuProps) => {
  return (
    <S.DropdownMenuRoot
      onOpenChange={onOpenChange}
      {...(open !== undefined ? { open: open } : {})}
    >
      <RadixDropdown.Trigger asChild {...triggerProps}>
        {triggerNode}
      </RadixDropdown.Trigger>
      <RadixDropdown.Portal>
        <S.DropdownContent
          className={className}
          align={align}
          sideOffset={5}
          {...contentProps}
        >
          {children}
        </S.DropdownContent>
      </RadixDropdown.Portal>
    </S.DropdownMenuRoot>
  );
};

DropdownMenu.Styled = S;
