import { Button } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useScenarioAssistant } from '../use-scenario-assistant/useScenarioAssistant';
import * as S from './QuestionView.styles';
import { QuestionViewTextArea } from './QuestionViewTextArea';

type QuestionViewProps = {
  question: string;
  handleSetQuestion: (question: string) => void;
  handleClose: () => void;
};

export const QuestionView = ({
  question,
  handleSetQuestion,
  handleClose,
}: QuestionViewProps) => {
  const { t } = useTranslation(['screener', 'common']);
  const { error, loading, sendMessage } = useScenarioAssistant();

  const [emptyError, setEmptyError] = useState(false);

  const handleSubmitQuestion = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (question) {
      sendMessage(question);
    } else {
      setEmptyError(true);
    }
  };

  return (
    <S.Container data-testid="scenario-assistant-question-view">
      <form onSubmit={handleSubmitQuestion}>
        <S.InputContainer>
          <S.InputLabel>{t('screener:modal.whatHappens')}</S.InputLabel>
          <QuestionViewTextArea
            placeholder={t('screener:modal.placeholderText')}
            value={question}
            onChange={e => {
              handleSetQuestion(e.target.value);
              setEmptyError(false);
            }}
            errorText={t('screener:error:emptyQuestion')}
            hasError={emptyError}
          />
        </S.InputContainer>

        <S.ButtonContainer>
          <Button
            variant="tertiary"
            size="large"
            type="button"
            label={t('common:cancel')}
            onClick={handleClose}
          />
          <Button
            variant="primary"
            size="large"
            label={t('screener:next')}
            disabled={loading || error}
          />
        </S.ButtonContainer>
      </form>
    </S.Container>
  );
};
