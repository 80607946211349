import { mergeDeep } from '@toggle/helpers';
import * as Types from 'echarts/types/dist/echarts';

import { getThemeColor } from '~/design-tokens/themes/theme-utils';
import { DesignToken, ThemeName } from '~/design-tokens/themes/themes';

export const createApacheConfig = (
  theme: ThemeName,
  options: Types.EChartsOption
): Types.EChartsOption => {
  const defaultAxisConfig = {
    axisLabel: {
      fontSize: 12,
      fontFamily: 'Roboto Mono',
    },
    axisTick: {
      lineStyle: {
        color: getThemeColor('--border-default', theme),
      },
    },
  };

  const defaultXAxisConfig = mergeDeep(defaultAxisConfig, {
    alignTicks: true,
    position: 'bottom',
    axisTick: {
      alignWithLabel: true,
    },
    axisLabel: {
      align: 'center',
    },
    splitLine: {
      show: false,
    },
  });

  const defaultYAxisConfig = mergeDeep(defaultAxisConfig, {
    boundaryGap: ['5%', '5%'],
    axisTick: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getThemeColor('--border-default', theme),
        width: 0.5,
      },
    },
  });

  const defaultTooltipConfig = {
    backgroundColor: getThemeColor('--surface-default', theme),
    borderColor: getThemeColor('--background-soft', theme),
    textStyle: {
      color: getThemeColor('--text-default', theme),
      fontSize: 12,
    },
    triggerOn: 'mousemove',
    padding: 12,
    extraCssText:
      'box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);',
  };

  const xAxis = options.xAxis && mergeDeep(defaultXAxisConfig, options.xAxis);
  const yAxis = options.yAxis && mergeDeep(defaultYAxisConfig, options.yAxis);
  const axis = xAxis && yAxis ? { xAxis, yAxis } : {};
  const tooltip = mergeDeep(defaultTooltipConfig, options.tooltip ?? {});
  const hasTitle = Array.isArray(options?.title)
    ? options.title.length > 0
    : !!options?.title;

  return {
    ...options,
    backgroundColor: getThemeColor('--background-default', theme),
    textStyle: {
      fontFamily: 'Nort',
    },
    ...axis,
    tooltip,
    color: Array.from({ length: 12 }, (_, i) =>
      getThemeColor(`--chart-multi-lines-hex-${i + 1}` as DesignToken, theme)
    ),
    legend: {
      top: hasTitle ? '6.5%' : '1%',
      left: 4,
      itemGap: 20,
      textStyle: {
        color: getThemeColor('--text-default', theme),
        fontSize: 14,
      },
    },
    grid: {
      show: false,
    },
  };
};
