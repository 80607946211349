import '@static/index.css';
import './utils/i18n/i18n';

import { FloatingDelayGroup } from '@floating-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { getApplicationContainer } from '@toggle/helpers';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';

import { Routes } from '~/routes';
import { queryClient } from '~/services/query-client/queryClient';
import { Tracking } from '~/services/tracking';

import { config } from './config';
import { useFlags } from './stores/use-flags/useFlags';

ReactGA.initialize(config.ga4Key);

Tracking.initialise();

useFlags.getState().initialise();

const root = createRoot(getApplicationContainer());

root.render(
  <QueryClientProvider client={queryClient}>
    <FloatingDelayGroup delay={{ open: 300, close: 0 }} timeoutMs={300}>
      <Routes />
    </FloatingDelayGroup>
  </QueryClientProvider>
);
