import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${typographyV2.titleLg}
`;

export const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 20px;
  margin-bottom: 32px;
`;

export const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
