import { persist } from 'zustand/middleware';

import { create } from '~/stores/create-store/createStore';

const WORKING_LIST_KEY = 'workingListKey';

type WorkingListStore = {
  workingList: string[];
  saveWorkingList: (entityTags: string[]) => void;
};

export const useWorkingList = create<WorkingListStore>()(
  persist(
    set => ({
      workingList: [],
      saveWorkingList: (entityTags: string[]) =>
        set({ workingList: entityTags }),
    }),
    {
      name: WORKING_LIST_KEY,
    }
  )
);
