import { NumericalCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import { Property } from 'csstype';
import React, { forwardRef, TableHTMLAttributes } from 'react';

import { WithNAFallback } from '~/components/table';

import * as S from './TableCell.styles';

export type PinConfig = {
  left: number;
  right: number;
  isLastPinned: boolean;
  showShadow?: boolean;
};

export interface TableCellProps
  extends TableHTMLAttributes<HTMLTableCellElement> {
  alignment?: Property.TextAlign;
  condensed?: boolean;
  isNumber?: boolean;
  isUpperCase?: boolean;
  colspan?: number;
  fixed?: boolean;
  numericalValueStyle?: NumericalCellValueStyle;
  pinConfig?: PinConfig;
}

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  (
    {
      alignment = 'end',
      condensed,
      isNumber = true,
      isUpperCase = false,
      colspan,
      fixed,
      numericalValueStyle = NumericalCellValueStyle.Neutral,
      children,
      pinConfig,
      ...rest
    },
    ref
  ) => {
    return (
      <S.TableCell
        data-testid="table-cell"
        $alignment={alignment}
        $condensed={condensed}
        $numericalStyle={numericalValueStyle}
        $pinConfig={pinConfig}
        $isNumber={isNumber}
        $isUpperCase={isUpperCase}
        $fixed={fixed}
        colSpan={colspan}
        {...rest}
        ref={ref}
      >
        <WithNAFallback value={children} check={!!children} />
      </S.TableCell>
    );
  }
);
