import {
  Alert,
  borderRadius,
  customScrollMinimal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const MainContent = styled.main<{ $hasMessage: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: ${props => (props.$hasMessage ? '32px' : '66px')};
  padding-bottom: ${props => props.$hasMessage && '32px'};
`;

export const ChatView = styled.div`
  ${customScrollMinimal};
  position: relative;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  overflow-y: auto;
  padding: 20px 20px var(--chat-footer-height) 0px;
  scroll-behavior: smooth;
  margin-bottom: 20px;
`;

export const ChatWrapper = styled.div<{ $hasMessage: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  margin-bottom: 50px;
  transition: bottom 0.3s ease-in-out;
  width: calc(100% - 300px);
`;

export const ChatInputComposerWrapper = styled.div<{
  $isHistoryExpanded?: boolean;
}>`
  padding-right: 30px;
  width: 100%;

  & > form {
    width: ${({ $isHistoryExpanded }) =>
      $isHistoryExpanded ? '100%' : 'var(--chat-message-width)'};
  }
`;

export const HistoryWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
`;

export const EmptyContainer = styled.section`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  bottom: 16px;
  z-index: 1;
`;

export const HistoryButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  position: fixed;
  top: 20px;
  right: 20px;
`;

export const ConversationName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    ${typographyV2.titleLg}
  }
`;

export const ChatTitle = styled.h3`
  ${typographyV2.titleLg};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 50vh;
`;

export const StyledAlert = styled(Alert)`
  ${borderRadius.r2};
`;

export const Content = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
