import {
  customScrollMinimal,
  TableCell,
  TableHeader,
  TanStackTableRoot,
  typographyV2,
} from '@toggle/design-system';
import styled, { css, keyframes } from 'styled-components';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';

export const StyledTanStackTableRoot = styled(TanStackTableRoot)`
  border-left: 1px solid var(--border-soft);
  border-collapse: collapse;

  th:first-child {
    width: 24px;
  }

  th:second-child {
    width: 132px;
  }

  tbody tr td:first-child {
    padding-left: 12px;
  }
`;

export const StyledTableHeader = styled(TableHeader)`
  border-bottom: 1px solid var(--border-default);
  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--z-index-header-cell);
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)`
  border-radius: 50%;
`;

export const LogoAndTickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

export const Text = styled.span`
  ${typographyV2.bodySm};
`;

const slideDown = keyframes`
  0% {
    background-color: var(--surface-hover);
  }
  100% {
    background-color: inherit;
  }
`;

export const StyledTableCell = styled(TableCell)<{ $isNew?: boolean }>`
  text-align: initial;
  ${({ $isNew }) =>
    $isNew &&
    css`
      animation: ${slideDown} 1s ease-out;
    `}
`;

export const ShadowContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
  ${customScrollMinimal};
  overflow-y: auto;

  &[data-shadow]::after {
    content: '';
    position: sticky;
    display: flex;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: linear-gradient(
      90deg,
      rgba(16, 16, 16, 0.6) 0%,
      rgba(16, 16, 16, 0) 100%
    );
  }

  &[data-overlay]::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    opacity: 50%;
    background-color: var(--background-default);
    z-index: 2;
  }
`;
