import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const MiniLegendRoot = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Dot = styled.div<{ $color?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.$color};
`;

export const Label = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-default);
  text-transform: uppercase;
`;
