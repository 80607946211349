import { Spinner } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionsView } from './conditions-view/ConditionsView';
import { ErrorView } from './error-view/ErrorView';
import { QuestionView } from './question-view/QuestionView';
import * as S from './ScenarioAssistantModal.styles';
import { useScenarioAssistant } from './use-scenario-assistant/useScenarioAssistant';

export interface ScenarioAssistantModalProps {
  onClose: () => void;
  isOpen: boolean;
  editFilterKey?: string;
  resetEditFilterKey: () => void;
}

export const ScenarioAssistantModal = ({
  onClose,
  isOpen,
  editFilterKey,
  resetEditFilterKey,
}: ScenarioAssistantModalProps) => {
  const { t } = useTranslation(['screener', 'scenarioCommander']);
  const {
    askedQuestion,
    error,
    loading,
    conditions,
    editingConditionId,
    editQuestion,
    resetAskedQuestions,
  } = useScenarioAssistant(state => ({
    askedQuestion: state.askedQuestion,
    error: state.error,
    loading: state.loading,
    conditions: state.conditions,
    editingConditionId: state.editingConditionId,
    editQuestion: state.editQuestion,
    resetAskedQuestions: state.resetAskedQuestions,
  }));
  const [question, setQuestion] = useState(askedQuestion);

  const handleSetQuestion = (question: string) => {
    setQuestion(question);
  };

  const handleEdit = () => {
    if (editFilterKey || !!conditions.length) {
      editQuestion(editFilterKey || conditions[conditions.length - 1].id);
    }

    resetAskedQuestions();
    handleSetQuestion(askedQuestion);
    resetEditFilterKey();
  };

  const handleClose = () => {
    if (isOpen) {
      setQuestion('');
    }

    onClose();
    editQuestion(undefined);
  };

  // show when editing current condition (clicked on 'screener:editTextEntry')
  // or when initially opened
  const showQuestionView =
    editingConditionId || (!editFilterKey && !askedQuestion);

  return (
    <S.StyledModalRoot
      data-testid="scenario-assistant-modal"
      isOpen={isOpen}
      title={t('screener:modal.scenarioAssistant')}
      description={t('screener:modal.scenarioDescription')}
      onClose={handleClose}
      outsideClickClose={!conditions.length}
    >
      {loading && (
        <S.SpinnerWrapper data-testid="scenario-question-loading">
          <Spinner size="large" />
        </S.SpinnerWrapper>
      )}
      {error && <ErrorView handleClose={handleClose} handleEdit={handleEdit} />}

      {!error && !loading && (
        <>
          {showQuestionView ? (
            <QuestionView
              question={question}
              handleSetQuestion={handleSetQuestion}
              handleClose={handleClose}
            />
          ) : (
            <ConditionsView
              handleEdit={handleEdit}
              handleClose={onClose}
              editFilterKey={editFilterKey}
              conditions={conditions}
            />
          )}
        </>
      )}
    </S.StyledModalRoot>
  );
};
