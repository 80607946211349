import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Breadcrumb = styled.span<{ $disabled?: boolean }>`
  display: flex;
  ${typographyV2.bodySm};
  color: var(--text-soft);

  a {
    text-decoration: none;
    outline: none;
    color: inherit;
  }

  ${({ $disabled = false }) =>
    $disabled
      ? css`
          pointer-events: none;
        `
      : css`
          a:hover,
          a:focus-visible,
          button:hover,
          button:focus-visible {
            outline: none;
            color: var(--text-primary);
            cursor: pointer;
          }
        `}
`;
