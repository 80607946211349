import { z } from 'zod';

import { entitySchema } from './entity-schema';

export const option = z.object({
  key: z.string(),
  description: z.string().optional(),
  name: z.string(),
  value: z.record(z.string(), z.unknown()),
  include: z.array(z.string()).optional(),
  children: z
    .array(
      z.object({
        key: z.string(),
        name: z.string(),
        value: z.record(z.string(), z.unknown()),
      })
    )
    .optional(),
  customHeaderTitle: z.string().optional(),
  hasBottomBorder: z.boolean().optional(),
  entity: entitySchema.optional(),
});

export const children = z.object({
  name: z.string(),
  multiselect: z.boolean(),
  default: option.shape.key,
  options: z.array(option),
});

const subFilter = z.array(children).optional();

export const customOptionMeta = z.object({
  type: z.string(),
  text: z.string().optional(),
  value_field: z.string().optional(),
  value_type: z.string().optional(),
  minimum: z.number().optional(),
  maximum: z.number().optional(),
  units: z.string().optional(),
  scale: z.number().optional(),
});

const customComponentOption = z.object({
  name: z.string(),
  key: z.string(),
  custom: z.array(customOptionMeta),
});

const customFilterComponent = z.object({
  type: z.string(),
  custom: z.array(customComponentOption),
});

const customFilter = z.object({
  key: z.string(),
  name: z.string(),
  custom: z.array(customFilterComponent),
});

export const filterSchema = z.object({
  key: z.string(),
  name: z.string(),
  searchable: z.boolean(),
  search: z
    .object({
      placeholder: z.string(),
    })
    .optional(),
  category: z.string(),
  locked: z.boolean(),
  visible: z.boolean(),
  dynamic: z.boolean().optional(),
  config: z.object({
    default: z.string().optional(),
    name: z.string(),
    min_select: z.number().optional(),
    multiselect: z.boolean(),
    options: z.array(option),
    children: subFilter,
    custom: customFilter.optional(),
  }),
  tooltip: z.string().optional(),
  modal: z.boolean().optional(),
});
const filters = z.array(filterSchema);
const filterResultValue = z.object({
  value: z.number().optional(),
  units: z.string().optional(),
  tooltip: z.string().optional(),
});

export const groupEnum = z.enum(['Market Filters', 'Knowledge Graph Filters']);
const metaValueTypeEnum = z.enum(['integer', 'float', 'scaled_number']);
export const filtersSchemas = z.object({
  groups: z.array(
    z.object({
      group: groupEnum,
      schemas: filters,
    })
  ),
});
export const columnMeta = z.object({
  field: z.string(),
  filter: z.string(),
  name: z.string(),
  directional: z.boolean().optional(),
  parent_id: z.string().optional(),
  parent_name: z.string().optional(),
  tooltip: z.string().optional(),
  value_type: metaValueTypeEnum.optional(),
  filter_children: z.array(z.string()).optional(),
});

const searchFilters = z.array(option);

export const filterResultsSchema = z.object({
  entities: z.array(z.string()),
  data: z.array(
    z.object({
      category: z.string(),
      meta: z.array(columnMeta),
      data: z.array(z.record(z.string(), filterResultValue)),
    })
  ),
  page: z.object({
    current: z.number(),
    next: z.number().optional(),
    size: z.number(),
  }),
  total_count: z.number(),
});

export type FilterSchemas = z.infer<typeof filtersSchemas>;
export type ScreenerFilters = z.infer<typeof filters>;
export type ScreenerFilter = z.infer<typeof filterSchema>;
export type ScreenerFilterOption = z.infer<typeof option>;
export type SubFilters = z.infer<typeof subFilter>;
export type ChildrenFilter = z.infer<typeof children>;
export type FilterResults = z.infer<typeof filterResultsSchema>;
export type FilterResultsCategoryData = FilterResults['data']['0'];
export type CustomFilter = z.infer<typeof customFilter>;
export type CustomComponentOption = z.infer<typeof customComponentOption>;
export type FilterResultMethod = z.infer<typeof filterResultValue>;
export type ColumnMeta = z.infer<typeof columnMeta>;
export type FilterCustomOptionMeta = z.infer<typeof customOptionMeta>;
export type ScreenerFilterGroupKeys = z.infer<typeof groupEnum>;
export type MetaValueType = z.infer<typeof metaValueTypeEnum>;

const activeFilterOptions = z.array(
  z.object({
    filter: z.string(),
    options: z.array(
      z.object({ isChild: z.boolean().optional(), ...option.shape })
    ),
  })
);

const filterOptionGroups = z.record(
  z.array(
    z.object({
      id: z.string(),
      filter: z.string(),
      options: z.array(z.any()),
    })
  )
);

const tableState = z.object({
  sorting: z.array(
    z.object({
      desc: z.boolean(),
      id: z.string(),
    })
  ),
  pinnedColumns: z.record(z.string(), z.array(z.string())),
  columnOrdering: z.record(z.string(), z.array(z.string())),
});

const screenSchema = z.object({
  name: z.string(),
  favorite: z.boolean(),
  config: z.object({
    activeFilterOptions: activeFilterOptions.optional(),
    filterOptionGroups: filterOptionGroups.optional(),
    isDraft: z.boolean(),
    tableState,
    version: z.string().optional(),
  }),
});
const postFilterBodySchema = z.record(
  z.string(),
  z.object({
    values: z.array(z.record(z.string(), z.unknown())),
    children: z.array(z.record(z.string(), z.unknown())).optional(),
  })
);

export type PostFilterBody = z.infer<typeof postFilterBodySchema>;

export const screenSchemaWithId = screenSchema.extend({ id: z.string() });

export type CreateScreenPayload = z.infer<typeof screenSchema>;
export type CreateScreenResponse = z.infer<typeof screenSchemaWithId>;
export const createScreenPayloadSchema = screenSchema;
export const createScreenResponseSchema = screenSchemaWithId;

/**
 * Read
 */
export type FetchScreenResponse = z.infer<typeof fetchScreenResponseSchema>;
export const fetchScreenResponseSchema = z.object({
  screens: z.array(screenSchemaWithId),
});

/**
 * Update
 */
export type UpdateScreenPayload = z.infer<typeof updateScreenPayloadSchema>;
export type UpdateScreenResponse = z.infer<typeof updateScreenResponseSchema>;
export const updateScreenPayloadSchema = screenSchema;
export const updateScreenResponseSchema = screenSchemaWithId;
export type TableState = z.infer<typeof tableState>;

export const screener = {
  getFilters: {
    path: 'entity-screener/v2/schemas',
    schema: filtersSchemas,
  },
  getFilterOptions: {
    path: (key: string, searchQuery: string) =>
      `entity-screener/v1/schemas/${key}/search?q=${searchQuery}`,
    schema: searchFilters,
  },
  postFilter: {
    path: 'entity-screener/v2/entities',
    schema: filterResultsSchema,
  },
  saveNewFilter: {
    path: 'entity-screener/v1/screens',
    schema: createScreenResponseSchema,
  },
  getSavedScreens: {
    path: 'entity-screener/v1/screens',
    schema: fetchScreenResponseSchema,
  },
  updateSavedFilter: {
    path: (screenId: string) => `entity-screener/v1/screens/${screenId}`,
    schema: updateScreenResponseSchema,
  },
  deleteSavedFilter: {
    path: (screenId: string) => `entity-screener/v1/screens/${screenId}`,
  },
};
