import { borderRadius, Input, Modal } from '@toggle/design-system';
import styled from 'styled-components';

export const ChatInputWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: var(--z-index-chat-input);
  display: flex;
  align-items: center;
`;

export const AlfredIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--border-soft);
  background: var(--background-default);
  height: 54px;
  width: 54px;
`;

export const StyledInput = styled(Input)`
  width: 1000px;

  ${Input.Styles.InputWrapper} {
    padding: 0px 12px 0px 0px;
    ${borderRadius.r2};

    ::placeholder {
      color: var(--text-default);
    }
  }
`;

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    padding-bottom: 0;
    border: none;
  }

  ${Modal.Styled.Header} {
    padding: 0;
  }

  ${Modal.Styled.TextContainer} {
    margin: 0;
  }

  button {
    display: none;
  }

  form {
    padding: 0;
  }
`;
