import { SharedInputStyles, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  flex-grow: 1;
`;

export const TextAreaElement = styled.textarea`
  ${SharedInputStyles}
  padding: 0;
  resize: none;
  ${typographyV2.bodyBase}
  color: var(--text-default)
`;
