import { Icon, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChartErrorRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  min-height: 300px;
  margin: 0 auto;
`;

export const ErrorIcon = styled(Icon).attrs({
  viewBox: '0 0 60 60',
  fillColor: 'none',
})`
  fill: var(--surface-neutral);
  opacity: 0.4;
  width: 70px;
  height: 70px;
`;

export const Title = styled.div`
  ${typographyV2.titleBase}
  color: var(--text-default);
`;

export const Description = styled.div`
  ${typographyV2.bodySm}
  color: var(--text-soft);
  margin-bottom: 16px;
`;

export const Link = styled.a`
  color: var(--text-link);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
