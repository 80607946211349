import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Label = styled.p`
  ${typographyV2.bodyXs}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--surface-default);
  ${borderRadius.r1}
  padding: 2px 6px 2px 0px;
  cursor: default;
`;

export const LabelRoot = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
