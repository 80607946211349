import { ModalProps } from '@toggle/design-system';
import { ReactNode, useEffect, useState } from 'react';
import { WretchError } from 'wretch/resolver';

import { ApiFetchResponse } from '~/utils/api-fetch/apiFetch';

interface UseActionsModalProps<A extends string, S, R> {
  defaultInputValue: string | undefined;
  entryId: string | undefined;
  saveNewApi?: (name: string) => Promise<ApiFetchResponse<S>>;
  renameApi: (id: string, name: string) => Promise<ApiFetchResponse<R>>;
  deleteApi: (id: string) => Promise<ApiFetchResponse<unknown>>;
  setActiveModal: (props: {
    activeModal?: A;
    nextActions?: Function[];
  }) => void;
  labels: {
    empty: string;
    existing?: string;
    error: string;
  };
}

export interface ModalDetails extends Omit<ModalProps, 'isOpen'> {
  title: string;
  content: ReactNode;
}

export const useActionsModal = <A extends string, S, R>({
  defaultInputValue = '',
  entryId,
  saveNewApi,
  renameApi,
  deleteApi,
  setActiveModal,
  labels,
}: UseActionsModalProps<A, S, R>) => {
  const [error, setError] = useState<{
    message: string;
    type: 'unknown' | 'validation';
  }>();
  const [name, setName] = useState(defaultInputValue);

  useEffect(() => {
    setName(defaultInputValue);
  }, [entryId]);

  const handleAPIError = (e: WretchError) => {
    if (e.status === 409) {
      setError({
        type: 'validation',
        message: labels.existing || labels.error,
      });
    } else {
      setError({
        type: 'unknown',
        message: labels.error,
      });
    }
  };

  const saveAction = async (name: string) => {
    if (!saveNewApi) {
      return undefined;
    }

    const res = await saveNewApi(name);

    if (res.error) {
      handleAPIError(res.error);
    } else {
      setActiveModal({ activeModal: undefined, nextActions: [] });
    }

    return res;
  };

  const renameAction = async (name: string, id: string) => {
    const res = await renameApi(id, name);

    if (res.error) {
      handleAPIError(res.error);
    } else {
      setActiveModal({ activeModal: undefined });
    }

    return res;
  };

  const deleteAction = async (id: string) => {
    const res = await deleteApi(id);

    if (res.error) {
      handleAPIError(res.error);
    } else {
      setActiveModal({ activeModal: undefined });
    }

    return res;
  };

  const updateNameAction = async (name: string, id?: string) => {
    if (!name.trim()) {
      setError({
        type: 'validation',
        message: labels.empty,
      });
      return undefined;
    } else {
      const res = await (id ? renameAction(name, id) : saveAction(name));

      if (res && !res.error) {
        setName('');
      }

      return res;
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  return {
    saveAction,
    renameAction,
    deleteAction,
    updateNameAction,
    error,
    handleAPIError,
    clearError,
    name,
    setName,
  };
};
