import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, CheckBox, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const DropdownMenuContent = styled(RadixDropdownMenu.Content)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 8px;
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  border-radius: 8px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  z-index: var(--z-index-modal);
  max-width: 208px;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
`;

export const StyledRadixItem = styled(RadixDropdownMenu.Item)`
  &:hover {
    outline: none;
  }
`;

export const Item = styled.div<{ $hasInvalidSubmit: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 12px;
  }

  ${CheckBox.Styled.Checkmark} {
    margin-top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: ${({ $hasInvalidSubmit }) =>
      $hasInvalidSubmit
        ? '1px solid var(--border-critical)'
        : '1px solid inherit'};
  }

  ${CheckBox.Styled.Checkbox} {
    align-items: center;
  }

  ${CheckBox.Styled.CheckboxLabel} {
    align-items: center;
    ${typographyV2.bodySm};
  }
`;

export const ActionItem = styled.div`
  padding: 12px 16px;
  display: flex;
  border-top: 1px solid var(--border-soft);
`;

export const ApplyButton = styled(Button)`
  height: 28px;
  width: 100%;
`;

export const CheckboxDescription = styled.div`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  margin-left: 28px;
`;
