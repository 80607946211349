import { Chip, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const ChipWrapper = styled.div`
  ${Chip.Styled.Container} {
    width: max-content;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  border: 0.5px solid var(--border-soft);
`;

export const CustomDate = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  gap: 4px;
  ${typographyV2.titleBase};
  cursor: pointer;
`;

export const SpecificDate = styled.span`
  ${typographyV2.uiSm}
`;

export const MenuWrapper = styled.div<{ $isCalendarShow: boolean }>`
  padding: 8px 0;
  width: 212px;

  ${({ $isCalendarShow }) =>
    $isCalendarShow &&
    css`
      padding: 0;
    `}
`;
