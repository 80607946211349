import { SkeletonLoader, TableCell } from '@toggle/design-system';
import React from 'react';

interface EventTableSkeletonLoaderProps {
  columnLength: number;
}

export const EventTableBodySkeletonLoader = ({
  columnLength,
}: EventTableSkeletonLoaderProps) => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <tr key={`tr-${index}`}>
            {Array.from({ length: columnLength }).map((_, index) => {
              return (
                <TableCell key={index}>
                  <SkeletonLoader
                    areas={[
                      {
                        styles: {
                          width: '100%',
                          height: '20px',
                          borderRadius: '8px',
                        },
                      },
                    ]}
                  />
                </TableCell>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
