import { PriceStatus } from '@toggle/helpers';
import styled from 'styled-components';

import { priceChangeColors } from '~/utils/common/utils';

export const NumberTableValue = styled.span<{ $status: PriceStatus }>`
  padding-left: 8px;
  font-family: var(--font-roboto-mono);
  color: ${({ $status }) => priceChangeColors[$status]};
  text-align: right;
`;
