import { Elements } from '@stripe/react-stripe-js';
import { Alert, AlertType, Spinner } from '@toggle/design-system';
import { deleteCookie, setCookie } from '@toggle/helpers';
import { startOfTomorrow } from 'date-fns';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';

import { StripeErrorTypeEnum } from '../hooks/use-stripe-form/types';
import {
  stripePromise,
  useStripeForm,
} from '../hooks/use-stripe-form/useStripeForm';
import * as Shared from '../utils/PlanCardSharedStyles.styles';
import * as S from './EditPaymentMethodModal.styles';
import { StripeForm } from './StripeForm';

export interface EditPaymentMethodModalProps {
  isInGracePeriod?: boolean;
  onClose: () => void;
}

export const PAYMENT_METHOD_UPDATED_KEY = '__toggle_payment_method_updated';

export const EditPaymentMethodModal: FC<EditPaymentMethodModalProps> = ({
  isInGracePeriod = false,
  onClose,
}) => {
  const { t } = useTranslation('subscription');
  const { showToast, removeToast } = useToasts();

  const onSuccess = () => {
    onClose();
    showToast({
      id: 'payment-method-success',
      position: ToastPosition.TopRight,
      timeout: 3000,
      content: (
        <Alert
          title={t('subscription:alerts.editPaymentMethodSuccessTitle')}
          type={AlertType.Success}
          onClose={() => removeToast('payment-method-success')}
        />
      ),
    });
  };

  const {
    handleFormChange,
    onReady,
    stripeError,
    inputError,
    ready,
    clientSecret,
    formComplete,
    stripeFormRef,
    handleSubmit,
    isSubmittingCard,
    stripeErrorType,
  } = useStripeForm({ onSuccess });

  const onUpdate = () => {
    handleSubmit();

    if (isInGracePeriod) {
      setCookie(PAYMENT_METHOD_UPDATED_KEY, PAYMENT_METHOD_UPDATED_KEY, {
        expires: startOfTomorrow(),
      });
    }
  };

  useEffect(() => {
    if (stripeError && isInGracePeriod) {
      deleteCookie(PAYMENT_METHOD_UPDATED_KEY);
    }
  }, [stripeError, isInGracePeriod]);

  return (
    <Shared.StyledModal
      isOpen
      title={t('subscription:changePaymentMethod')}
      cancelBtnProps={{
        label: t('subscription:payments.form.cancel'),
        onClick: onClose,
      }}
      primaryBtnProps={{
        label: t('subscription:modals.editPaymentMethod.update'),
        onClick: onUpdate,
        disabled: !ready || !formComplete || isSubmittingCard,
        isLoading: isSubmittingCard,
      }}
      onClose={onClose}
      data-testid="edit-payment-method-modal"
    >
      <div>
        {stripeError && (
          <S.StyledAlert
            data-testid="stripe-error-alert"
            title={
              stripeErrorType === StripeErrorTypeEnum.verificationFailed
                ? t('subscription:alerts.verificationFailedTitle')
                : t('subscription:alerts.3dSecureFailedTitle')
            }
            message={t('subscription:alerts.paymentFailedMessage')}
            variant="inline"
          />
        )}
        <S.Label>{t('subscription:paymentDetails')}</S.Label>
        <S.Container>
          {clientSecret ? (
            <Elements stripe={stripePromise}>
              <StripeForm
                ref={stripeFormRef}
                clientSecret={clientSecret}
                ready={ready}
                onReady={onReady}
                handleFormChange={handleFormChange}
                inputError={inputError}
              />
            </Elements>
          ) : (
            <Spinner size="small" />
          )}
        </S.Container>
        <S.HelperText>
          {t('subscription:modals.editPaymentMethod.cardValidation')}
        </S.HelperText>
      </div>
    </Shared.StyledModal>
  );
};
