import { Chip } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ChatInput } from '~/components/chat';
import { appPaths } from '~/routes/app-paths';
import { useChatMode } from '~/stores/use-chat-mode/useChatMode';

import * as S from './DocSearchInput.styles';

enum DocType {
  Filing = 'filing',
  Transcript = 'transcript',
  Presentation = 'presentation',
}

export interface DocsSearchInputProps {
  entityTags: string[];
}

export const DocsSearchInput = ({ entityTags }: DocsSearchInputProps) => {
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();
  const setMode = useChatMode(state => state.setMode);

  const [selectedChips, setSelectedChips] = useState<DocType[]>([
    DocType.Filing,
    DocType.Transcript,
    DocType.Presentation,
  ]);

  const toggleSelectedChip = (id: DocType) => {
    setSelectedChips(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(chipId => chipId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const chips = [
    {
      id: DocType.Filing,
      /** i18next-extract-mark-context-next-line ["filing", "transcript", "presentation"] */
      label: t('common:linkingDocSearch.docType', { context: DocType.Filing }),
    },
    {
      id: DocType.Transcript,
      /** i18next-extract-mark-context-next-line ["filing", "transcript", "presentation"] */
      label: t('common:linkingDocSearch.docType', {
        context: DocType.Transcript,
      }),
    },
    {
      id: DocType.Presentation,
      /** i18next-extract-mark-context-next-line ["filing", "transcript", "presentation"] */
      label: t('common:linkingDocSearch.docType', {
        context: DocType.Presentation,
      }),
    },
  ];

  return (
    <div>
      <S.TitleWrapper>
        <S.Description>
          {t('common:linkingDocSearch.modalDescription')}
        </S.Description>
        <S.ChipsWrapper>
          {chips.map(chip => {
            const isActive = selectedChips.includes(chip.id);

            return (
              <Chip
                key={chip.id}
                label={chip.label}
                variation="outline"
                type="choice"
                leftIcon={isActive ? 'Checkmark' : 'Add'}
                isActive={isActive}
                onClick={() => toggleSelectedChip(chip.id)}
                data-testid={`chip-${chip.id}`}
              />
            );
          })}
        </S.ChipsWrapper>
        {!selectedChips.length && (
          <S.WarningText data-testid="warning-text">
            {t('common:linkingDocSearch.warning')}
          </S.WarningText>
        )}
      </S.TitleWrapper>
      <S.InputWrapper>
        <S.ChatInputComposerWrapper>
          <ChatInput
            config={{
              placeholder: t('common:linkingDocSearch.placeholder'),
              allowedModes: [],
              isCustomizedDisabled: !selectedChips.length,
              disableContextHeader: true,
              clearDocumentContextOnSubmit: true,
            }}
            chatContext={{
              target: 'document-assistant',
              documentTypes: selectedChips,
              entityTags,
            }}
            submitCallback={() => {
              setMode('intel');
              navigate(appPaths.alfred);
            }}
          />
        </S.ChatInputComposerWrapper>
      </S.InputWrapper>
    </div>
  );
};
