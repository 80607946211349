import {
  amberDark,
  blue,
  crimson,
  cyan,
  gold,
  grass,
  grassDark,
  gray,
  indigo,
  lime,
  limeDark,
  olive,
  orange,
  plum,
  purple,
  red,
  teal,
  tomato,
  violet,
  yellow,
} from '@radix-ui/colors';

import { hslToHex } from '../../utils/color-conversion/color-conversion';

const background = {
  '--background-default': 'rgb(252, 252, 252, 1)',
  '--background-soft': 'rgba(249, 249, 249, 1)',
  '--background-backdrop': 'rgba(0, 0, 0, 0.7)',
};

const surface = {
  '--surface-default': gray.gray3,
  '--surface-hover': gray.gray4,
  '--surface-pressed': gray.gray5,
  '--surface-active': gray.gray5,
  '--surface-inverse': '#000000',

  '--surface-primary': blue.blue9,
  '--surface-success': grass.grass3,
  '--surface-warning': yellow.yellow3,
  '--surface-critical': tomato.tomato3,
  '--surface-neutral': gray.gray5,
  '--surface-white': '#ffffff',

  '--surface-decorative-indigo-default': indigo.indigo3,
  '--surface-decorative-indigo-hover': indigo.indigo4,
  '--surface-decorative-indigo-active': indigo.indigo5,

  '--surface-decorative-teal-default': teal.teal3,
  '--surface-decorative-teal-hover': teal.teal4,
  '--surface-decorative-teal-active': teal.teal5,

  '--surface-decorative-cyan-default': cyan.cyan3,
  '--surface-decorative-cyan-hover': cyan.cyan4,
  '--surface-decorative-cyan-active': cyan.cyan5,

  '--surface-decorative-purple-default': purple.purple3,
  '--surface-decorative-purple-hover': purple.purple4,
  '--surface-decorative-purple-active': purple.purple5,

  '--surface-decorative-lime-default': lime.lime3,
  '--surface-decorative-lime-hover': lime.lime4,
  '--surface-decorative-lime-active': lime.lime5,
};

const text = {
  '--text-default': gray.gray12,
  '--text-soft': gray.gray11,
  '--text-disabled': gray.gray9,
  '--text-inverse': gray.gray1,
  '--text-primary': blue.blue9,
  '--text-success': grass.grass11,
  '--text-warning': yellow.yellow11,
  '--text-critical': tomato.tomato11,
  '--text-critical-dark-red': red.red11,
  '--text-on-primary': '#ffffff',
  '--text-on-critical': '#ffffff',
  '--text-link': blue.blue11,
  '--text-decorative-indigo': indigo.indigo11,
  '--text-decorative-teal': teal.teal11,
  '--text-decorative-cyan': cyan.cyan11,
  '--text-decorative-purple': purple.purple11,
  '--text-decorative-lime': lime.lime11,
};

const icon = {
  '--icon-default': gray.gray12,
  '--icon-soft': gray.gray11,
  '--icon-disabled': gray.gray9,
  '--icon-inverse': gray.gray1,
  '--icon-primary': blue.blue9,
  '--icon-success': grass.grass11,
  '--icon-success-grass': grassDark.grass9,
  '--icon-warning': yellow.yellow11,
  '--icon-critical': tomato.tomato11,
  '--icon-critical-red': red.red9,
  '--icon-on-primary': '#ffffff',
  '--icon-on-critical': '#ffffff',
  '--icon-decorative-indigo': indigo.indigo11,
  '--icon-decorative-teal': teal.teal11,
  '--icon-decorative-cyan': cyan.cyan11,
  '--icon-decorative-purple': purple.purple11,
  '--icon-decorative-lime': lime.lime10,
  '--icon-success-lime': limeDark.lime9,
};

const border = {
  '--border-default': gray.gray9,
  '--border-soft': gray.gray6,
  '--border-hover': gray.gray11,
  '--border-disabled': gray.gray6,
  '--border-primary': blue.blue9,
  '--border-success': grass.grass11,
  '--border-warning': yellow.yellow11,
  '--border-critical': tomato.tomato11,
  '--border-focus': blue.blue4,
  '--border-decorative-indigo': indigo.indigo9,
  '--border-decorative-teal': teal.teal9,
  '--border-decorative-cyan': cyan.cyan9,
  '--border-decorative-purple': purple.purple9,
  '--border-decorative-lime': lime.lime9,
};

const action = {
  '--action-primary-default': blue.blue9,
  '--action-primary-hover': blue.blue10,
  '--action-primary-disabled': gray.gray4,
  '--action-primary-soft-hover': blue.blue4,
  '--action-critical-default': tomato.tomato9,
  '--action-critical-hover': tomato.tomato10,
  '--action-critical-disabled': gray.gray4,
};

const grass11Rgb = '42, 126, 59';
const tomato11Rgb = '209, 52, 21';
const blue11Rgb = '0, 106, 220';

const visual = {
  '--viz-bullish': grass.grass11,
  '--viz-bullish-rgb': grass11Rgb,
  '--viz-bullish-soft': grass.grass4,
  '--viz-bearish': tomato.tomato11,
  '--viz-bearish-rgb': tomato11Rgb,
  '--viz-bearish-soft': tomato.tomato4,
  '--viz-neutral-soft': blue.blue4,
};

const elevation = {
  '--elevation-subtle':
    '0px 4px 16px -4px rgba(0, 0, 0, 0.12), 0px 0px 6px -3px rgba(0, 0, 0, 0.08)',
  '--elevation-moderate':
    '0px 8px 20px -4px rgba(0, 0, 0, 0.12), 0px 3px 6px -3px rgba(0, 0, 0, 0.08)',
  '--elevation-strong':
    '0px 8px 20px 1px rgba(0, 0, 0, 0.12), 0px 3px 6px 1px rgba(0, 0, 0, 0.08)',
};

const custom = {
  '--background-default-rgb': '252, 252, 252',
  '--background-soft-rgb': '249, 249, 249',
  '--background-table-zebra': gray.gray2,
  '--chart-pane-gradient': gray.gray3,
  '--chart-multi-lines-hex-1': hslToHex(amberDark.amber9),
  '--chart-multi-lines-hex-2': hslToHex(violet.violet10),
  '--chart-multi-lines-hex-3': hslToHex(teal.teal10),
  '--chart-multi-lines-hex-4': hslToHex(gold.gold10),
  '--chart-multi-lines-hex-5': hslToHex(crimson.crimson10),
  '--chart-multi-lines-hex-6': hslToHex(orange.orange10),
  '--chart-multi-lines-hex-7': hslToHex(tomato.tomato10),
  '--chart-multi-lines-hex-8': hslToHex(olive.olive10),
  '--chart-multi-lines-hex-9': hslToHex(grass.grass10),
  '--chart-multi-lines-hex-10': hslToHex(yellow.yellow10),
  '--chart-multi-lines-hex-11': hslToHex(blue.blue10),
  '--chart-multi-lines-hex-12': hslToHex(indigo.indigo10),
  '--chart-multi-lines-hex-13': hslToHex(plum.plum10),
};

const light_default = {
  ...background,
  ...surface,
  ...border,
  ...text,
  ...icon,
  ...action,
  ...visual,
  ...elevation,
  ...custom,
};

const light_inverted = {
  ...light_default,
  '--viz-bullish': tomato.tomato11,
  '--viz-bullish-rgb': tomato11Rgb,
  '--viz-bullish-soft': tomato.tomato4,
  '--viz-bearish': grass.grass11,
  '--viz-bearish-rgb': grass11Rgb,
  '--viz-bearish-soft': grass.grass4,
};
const light_asian = {
  ...light_default,
  '--viz-bullish': tomato.tomato11,
  '--viz-bullish-rgb': tomato11Rgb,
  '--viz-bullish-soft': tomato.tomato4,
  '--viz-bearish': blue.blue11,
  '--viz-bearish-rgb': blue11Rgb,
  '--viz-bearish-soft': blue.blue4,
};

const light_protanopia = {
  ...light_default,
  '--viz-bullish': grass.grass11,
  '--viz-bullish-rgb': grass11Rgb,
  '--viz-bullish-soft': grass.grass4,
  '--viz-bearish': blue.blue11,
  '--viz-bearish-rgb': blue11Rgb,
  '--viz-bearish-soft': blue.blue4,
};

const light_deuteranopia = {
  ...light_default,
  '--viz-bullish': blue.blue11,
  '--viz-bullish-rgb': blue11Rgb,
  '--viz-bullish-soft': blue.blue4,
  '--viz-bearish': tomato.tomato11,
  '--viz-bearish-rgb': tomato11Rgb,
  '--viz-bearish-soft': tomato.tomato4,
};

export const lightTheme = {
  light_default,
  light_inverted,
  light_asian,
  light_protanopia,
  light_deuteranopia,
};
