import { QueryClient } from '@tanstack/react-query';
import {
  GovernmentTradesInputResponse,
  govTradeResponseSchema,
  InsiderTradesInputResponse,
  insiderTradesResponseSchema,
  IposInputResponse,
  iposResponseSchema,
  mapEntity,
  ScreenerEventsInputResponse,
} from '@toggle/toggle';

import { ScreenerEvents } from '~/views/screener/results/screener-events-table/utils/types';

import { createEntityQueryOptions } from '../use-entities';

export const fetchEntities = async (
  queryClient: QueryClient,
  tags: string[]
) => {
  const entityPromises = tags.map(tag =>
    queryClient.fetchQuery(createEntityQueryOptions(tag))
  );
  const entities = await Promise.all(entityPromises);
  const mappedEntities = entities
    .filter(e => e.data)
    .map(e => mapEntity(e.data!));

  return mappedEntities;
};

export const isGovernmentTradesResponse = (
  data: ScreenerEventsInputResponse
): data is GovernmentTradesInputResponse => {
  return 'gov_trades' in data;
};

export const isInsiderTradesResponse = (
  data: ScreenerEventsInputResponse
): data is InsiderTradesInputResponse => {
  return 'insider_trades' in data;
};

export const isIposResponse = (
  data: ScreenerEventsInputResponse
): data is IposInputResponse => {
  return 'ipos' in data;
};

export const safeParseScreenerEvents = ({
  eventType,
  data,
}: {
  eventType: ScreenerEvents;
  data: ScreenerEventsInputResponse;
}) => {
  switch (eventType) {
    case ScreenerEvents.GovTrades: {
      const govTradesWithDefaults = govTradeResponseSchema.safeParse(data);

      if (govTradesWithDefaults.success) {
        return govTradesWithDefaults.data.gov_trades;
      }

      break;
    }

    case ScreenerEvents.InsiderTrades: {
      const insiderTradesWithDefaults =
        insiderTradesResponseSchema.safeParse(data);

      if (insiderTradesWithDefaults.success) {
        return insiderTradesWithDefaults.data.insider_trades;
      }

      break;
    }

    case ScreenerEvents.Ipos: {
      const iposWithDefaults = iposResponseSchema.safeParse(data);

      if (iposWithDefaults.success) {
        return iposWithDefaults.data.ipos;
      }

      break;
    }
  }

  return [];
};
