import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const LegendPriceRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const PriceContainer = styled.div<{
  $color: string;
}>`
  display: flex;
  align-items: center;
  font-family: var(--font-roboto-mono);
  color: ${props => props.$color};
  gap: 8px;
  ${typographyV2.bodySm}
`;
