import mapValues from 'lodash/mapValues';
import { StoreApi } from 'zustand';

import { create } from '~/stores/create-store/createStore';
import { useFlags } from '~/stores/use-flags/useFlags';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

import { STORAGE_ITEMS, STORAGE_KEYS } from './storage-keys';
import { mapItemsToFeatureFlags } from './utils/remote-storage-utils';

type Actions = {
  storeItems: (items: STORAGE_ITEMS) => Promise<void>;
  deleteItem: (item: STORAGE_KEYS) => Promise<void>;
  initialize: () => Promise<void>;
};

type State = {
  items?: STORAGE_ITEMS;
  error?: Error;
  loading: boolean;
};

type UseRemoteStorageValue = Actions & State;
export const REMOTE_STORAGE_URL = `flags/v1`;

const parseItem = (item: string) => {
  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};

const stringifyObject = (item: object | string | boolean) => {
  if (typeof item === 'object' && item !== null) {
    return JSON.stringify(item);
  }

  return item;
};

const initialize = async (set: StoreApi<UseRemoteStorageValue>['setState']) => {
  const items = await apiFetch<STORAGE_ITEMS>(REMOTE_STORAGE_URL);

  if (items.error) {
    set({ error: items.error, loading: false });
  } else {
    const parsedItems = mapValues(items.data, parseItem);

    const featureFlags = mapItemsToFeatureFlags(parsedItems);

    if (featureFlags.length) {
      useFlags.getState().addFlags(featureFlags);
    }

    set({ items: parsedItems, loading: false });
  }
};

const storeItems = async (
  items: STORAGE_ITEMS,
  set: StoreApi<UseRemoteStorageValue>['setState']
) => {
  set({ loading: true });
  const stringifyItem = mapValues(items, stringifyObject);

  const store = await apiFetch<STORAGE_ITEMS>(REMOTE_STORAGE_URL, {
    method: 'put',
    body: stringifyItem,
  });

  if (store.error) {
    set({ error: store.error, loading: false });
  } else {
    set(state => ({ items: { ...state.items, ...items }, loading: false }));
  }
};

const deleteItem = async (
  key: STORAGE_KEYS,
  set: StoreApi<UseRemoteStorageValue>['setState']
) => {
  set({ loading: true });

  const deleteItem = await apiFetch<STORAGE_ITEMS>(
    `${REMOTE_STORAGE_URL}/${key}`,
    {
      method: 'delete',
    }
  );

  if (deleteItem.error) {
    set({ error: deleteItem.error as Error, loading: false });
  } else {
    const filterItem = (storedItems: STORAGE_ITEMS | undefined) => {
      if (storedItems) {
        const { [key]: _, ...rest } = storedItems;
        return rest;
      }

      return undefined;
    };

    set(state => ({ items: filterItem(state.items), loading: false }));
  }
};

const initialState: State = {
  items: undefined,
  error: undefined,
  loading: true,
};

export const useRemoteStorage = create<UseRemoteStorageValue>(set => ({
  ...initialState,
  storeItems: async items => storeItems(items, set),
  deleteItem: async item => deleteItem(item, set),
  initialize: async () => initialize(set),
}));
