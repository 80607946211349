import { useInfiniteQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { CitationFilters } from '@toggle/toggle';

import { getCitations } from '~/views/intel/citation-service/citation-service';

const QUERY_KEY = 'DOCUMENT_SOURCES';
const PAGE_SIZE = 25;

type useCitationGroupProps = {
  groupId: string;
  filters?: CitationFilters;
};

export const useCitationGroup = ({ groupId, filters }: useCitationGroupProps) =>
  useInfiniteQuery({
    queryKey: [QUERY_KEY, groupId, JSON.stringify(filters)],
    queryFn: async ({ pageParam, signal }) =>
      getCitations(
        groupId,
        {
          filters,
          page: {
            number: pageParam,
            size: PAGE_SIZE,
          },
        },
        signal
      ),
    initialPageParam: 1,
    staleTime: getTimeTillEndOfDayMs(),
    getNextPageParam: lastData => lastData.page.next,
  });
