import { useQueryClient } from '@tanstack/react-query';
import {
  mapEntity,
  MappedEntity,
  scenario,
  ScenariosRequest,
  ScenariosResponse,
} from '@toggle/toggle';
import { useRef, useState } from 'react';

import { config } from '~/config';
import {
  apiStream,
  ApiStreamProps,
  ApiStreamStatus,
} from '~/utils/api-stream/apiStream';
import { useScenarioStore } from '~/widgets/scenario/hooks/use-scenario-store/useScenarioStore';

import { createEntityQueryOptions } from '../use-entities';

export interface ScenariosData extends Omit<ScenariosResponse, 'entity'> {
  entity: MappedEntity;
}

export const useScenarios = () => {
  const [data, setData] = useState<ScenariosData[]>([]);
  const [lastBody, setLastBody] = useState<ScenariosRequest>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const controllerRef = useRef<AbortController | null>(null);
  const queryClient = useQueryClient();
  const setLastScenariosData = useScenarioStore(
    state => state.setLastScenariosData
  );
  const setHasExceededUsageLimit = useScenarioStore(
    state => state.setHasExceededUsageLimit
  );

  const { path } = scenario.scenarios;

  const handleStreamData = async (chunks: ScenariosResponse[]) => {
    const tags = chunks.map(c => c.entity);
    const entityPromises = tags.map(tag =>
      queryClient.fetchQuery(createEntityQueryOptions(tag))
    );
    const entities = await Promise.all(entityPromises);
    const data = entities.reduce((res, next, idx) => {
      if (next.data) {
        res.push({ ...chunks[idx], entity: mapEntity(next.data) });
      }

      return res;
    }, [] as ScenariosData[]);

    setData(data);
    setLastScenariosData(data);
  };

  const onComplete = (status: ApiStreamStatus) => {
    switch (status) {
      case ApiStreamStatus.Success:
        setIsLoading(false);
        setError(false);
        break;
      case ApiStreamStatus.Error:
      case ApiStreamStatus.Abort:
        setIsLoading(false);
        setError(true);
        break;
    }
  };

  const sendMessage = async (body: ScenariosRequest) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const abortController = new AbortController();
    controllerRef.current = abortController;

    // Reset state variables
    setData([]);
    setIsLoading(true);
    setError(false);
    setLastBody(body);

    const apiStreamProps: ApiStreamProps<ScenariosResponse> = {
      body,
      onReadChunk: handleStreamData,
      onComplete: () => onComplete(ApiStreamStatus.Success),
      onError: error => {
        const isUsageLimitExceeded = error?.status === 429;

        if (isUsageLimitExceeded) {
          setHasExceededUsageLimit(true);
        }

        onComplete(ApiStreamStatus.Error);
      },
      onAbort: () => onComplete(ApiStreamStatus.Abort),
      controller: abortController,
      endpoint: `${config.api.root}${path}`,
    };

    await apiStream<ScenariosResponse>(apiStreamProps);
  };

  const refetch = () => {
    if (lastBody) {
      sendMessage(lastBody);
    }
  };

  const clear = () => {
    setData([]);
  };

  const abort = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    error,
    sendMessage,
    refetch,
    abort,
    clear,
    data,
  };
};
