import Add from './icons/add.svg';
import AddBoldCircle from './icons/add-bold-circle.svg';
import AddLightCircle from './icons/add-light-circle.svg';
import AddRemove from './icons/add-remove.svg';
import Ai from './icons/ai.svg';
import AlarmClock from './icons/alarm-clock.svg';
import AlfredNav from './icons/alfred-nav.svg';
import AmericanExpress from './icons/american-express.svg';
import AngleLightDown from './icons/angle-light-down.svg';
import AngleLightLeft from './icons/angle-light-left.svg';
import AngleLightRight from './icons/angle-light-right.svg';
import AngleLightUp from './icons/angle-light-up.svg';
import ArrowCircleDown from './icons/arrow-circle-down.svg';
import ArrowCircleLeft from './icons/arrow-circle-left.svg';
import ArrowCircleRight from './icons/arrow-circle-right.svg';
import ArrowCircleUp from './icons/arrow-circle-up.svg';
import ArrowDown from './icons/arrow-down.svg';
import ArrowDownLeft from './icons/arrow-down-left.svg';
import ArrowFilter from './icons/arrow-filter.svg';
import ArrowLeft from './icons/arrow-left.svg';
import ArrowLeftLong from './icons/arrow-left-long.svg';
import ArrowLeftLongLine from './icons/arrow-left-long-to-line.svg';
import ArrowRight from './icons/arrow-right.svg';
import ArrowRightLong from './icons/arrow-right-long.svg';
import ArrowRightLongLine from './icons/arrow-right-long-to-line.svg';
import ArrowRotateRight from './icons/arrow-rotate-right.svg';
import ArrowUp from './icons/arrow-up.svg';
import ArrowUpRight from './icons/arrow-up-right.svg';
import Astronaut from './icons/astronaut.svg';
import BadgePercentage from './icons/badge-percentage.svg';
import Bars from './icons/bars.svg';
import BarsSortDown from './icons/bars-sort-down.svg';
import BarsSortUp from './icons/bars-sort-up.svg';
import BearishBear from './icons/bearish-bear.svg';
import Binary from './icons/binary.svg';
import Binoculars from './icons/binoculars.svg';
import Bolt from './icons/bolt.svg';
import Book from './icons/book.svg';
import BookIdea from './icons/book-idea.svg';
import BookOpenReader from './icons/book-open-reader.svg';
import BookmarkFill from './icons/bookmark-fill.svg';
import BookmarkOutline from './icons/bookmark-outline.svg';
import Breaking from './icons/breaking.svg';
import Briefcase from './icons/briefcase.svg';
import BriefcaseLight from './icons/briefcase-light.svg';
import BullishBull from './icons/bullish-bull.svg';
import Calendar from './icons/calendar.svg';
import CalendarClock from './icons/calendar-clock.svg';
import Candlestick from './icons/candlestick.svg';
import CaretDown from './icons/caret-down.svg';
import CaretRight from './icons/caret-right.svg';
import Chain from './icons/chain.svg';
import ChartArrow from './icons/chart-arrow.svg';
import ChartBar from './icons/chart-bar.svg';
import ChartCandlestick from './icons/chart-candlestick.svg';
import ChartLine from './icons/chart-line.svg';
import ChartMixed from './icons/chart-mixed.svg';
import ChartNetwork from './icons/chart-network.svg';
import ChartPie from './icons/chart-pie.svg';
import ChartPieSimple from './icons/chart-pie-simple.svg';
import Chat from './icons/chat.svg';
import ChatNew from './icons/chat-new.svg';
import Checkmark from './icons/checkmark.svg';
import CheckmarkBoldCircle from './icons/checkmark-bold-circle.svg';
import CheckmarkCircle from './icons/checkmark-circle.svg';
import CheckmarkFill from './icons/checkmark-fill.svg';
import CheckmarkLightCircle from './icons/checkmark-light-circle.svg';
import ChevronBoldDown from './icons/chevron-bold-down.svg';
import ChevronBoldLeft from './icons/chevron-bold-left.svg';
import ChevronBoldRight from './icons/chevron-bold-right.svg';
import ChevronBoldUp from './icons/chevron-bold-up.svg';
import ChevronFillDown from './icons/chevron-fill-down.svg';
import ChevronFillLeft from './icons/chevron-fill-left.svg';
import ChevronFillRight from './icons/chevron-fill-right.svg';
import ChevronFillUp from './icons/chevron-fill-up.svg';
import ChevronLightDown from './icons/chevron-light-down.svg';
import ChevronLightLeft from './icons/chevron-light-left.svg';
import ChevronLightRight from './icons/chevron-light-right.svg';
import ChevronLightUp from './icons/chevron-light-up.svg';
import Clear from './icons/clear.svg';
import ClearSort from './icons/clear-sort.svg';
import Clock from './icons/clock.svg';
import ClockFill from './icons/clock-fill.svg';
import ClockRotateLeft from './icons/clock-rotate-left.svg';
import Close from './icons/close.svg';
import CloseBoldCircle from './icons/close-bold-circle.svg';
import CloseCircle from './icons/close-circle.svg';
import CloseFillCircle from './icons/close-fill-circle.svg';
import CloseLightCircle from './icons/close-light-circle.svg';
import CloudArrowUp from './icons/cloud-arrow-up.svg';
import Coin from './icons/coin.svg';
import Comment from './icons/comment.svg';
import CommentSmile from './icons/comment-smile.svg';
import Compass from './icons/compass.svg';
import Compress from './icons/compress.svg';
import ComputerClassic from './icons/computer-classic.svg';
import Construction from './icons/construction.svg';
import Copy from './icons/copy.svg';
import Corn from './icons/corn.svg';
import Cow from './icons/cow.svg';
import CreditCard from './icons/credit-card.svg';
import CreditCardLight from './icons/credit-card-light.svg';
import Crop from './icons/crop.svg';
import Crosshair from './icons/crosshair.svg';
import DashboardNew from './icons/dashboard-new.svg';
import DashedLine from './icons/dashed-line.svg';
import Desktop from './icons/desktop.svg';
import DinersClub from './icons/diners-club.svg';
import Discover from './icons/discover.svg';
import Dollar from './icons/dollar.svg';
import Dot from './icons/dot.svg';
import Download from './icons/download.svg';
import Edit from './icons/edit.svg';
import Ellipsis from './icons/ellipsis.svg';
import EnvelopeSolid from './icons/envelope-solid.svg';
import Euro from './icons/euro.svg';
import Exclamation from './icons/exclamation.svg';
import ExclamationBoldCircle from './icons/exclamation-bold-circle.svg';
import ExclamationCircle from './icons/exclamation-circle.svg';
import ExclamationFill from './icons/exclamation-fill.svg';
import ExclamationLightCircle from './icons/exclamation-light-circle.svg';
import ExclamationTriangle from './icons/exclamation-triangle.svg';
import Expand from './icons/expand.svg';
import Explore from './icons/explore.svg';
import ExploreLine from './icons/explore-line.svg';
import ExploreNav from './icons/explore-nav.svg';
import Extension from './icons/extension.svg';
import ExternalLink from './icons/external-link.svg';
import Facebook from './icons/facebook.svg';
import FaucetDrip from './icons/faucet-drip.svg';
import Feedback from './icons/feedback.svg';
import File from './icons/file.svg';
import FileChart from './icons/file-chart.svg';
import Filing from './icons/filing.svg';
import Filter from './icons/filter.svg';
import FilterClear from './icons/filter-clear.svg';
import FilterInline from './icons/filter-inline.svg';
import FilterSolid from './icons/filter-solid.svg';
import Fire from './icons/fire.svg';
import GeneralKnowledge from './icons/general-knowledge.svg';
import Gift from './icons/gift.svg';
import Globe from './icons/globe.svg';
import Grid from './icons/grid.svg';
import GripsDots from './icons/grips-dots.svg';
import HamburgerMenu from './icons/hamburger-menu.svg';
import HandBack from './icons/hand-back.svg';
import Handshake from './icons/handshake.svg';
import HeartEmpty from './icons/heart-empty.svg';
import HeartFill from './icons/heart-fill.svg';
import Ellipse from './icons/ellipse.svg';
import HidePassword from './icons/hide-password.svg';
import Home from './icons/home.svg';
import HospitalAlt from './icons/hospital-alt.svg';
import HtmlLinkIcon from './icons/html-link-icon.svg';
import IdeaBook from './icons/idea-book.svg';
import Indicators from './icons/indicators.svg';
import Indicator from './icons/indicator.svg';
import InformationBoldCircle from './icons/information-bold-circle.svg';
import InformationCircle from './icons/info-circle.svg';
import InformationLightCircle from './icons/info-light-circle.svg';
import Insights from './icons/insights.svg';
import AnalystEstimatesDeteriorating from './icons/insights/analyst-estimates-deteriorating.svg';
import AnalystEstimatesImproving from './icons/insights/analyst-estimates-improving.svg';
import AnalystEstimatesSlow from './icons/insights/analyst-estimates-slow.svg';
import AnalystEstimatesStrong from './icons/insights/analyst-estimates-strong.svg';
import AnalystRatingsCautious from './icons/insights/analyst-ratings-cautious.svg';
import AnalystRatingsConfident from './icons/insights/analyst-ratings-confident.svg';
import AnalystRatingsDeteriorating from './icons/insights/analyst-ratings-deteriorating.svg';
import AnalystRatingsImproving from './icons/insights/analyst-ratings-improving.svg';
import AnalystRatingsOptimistic from './icons/insights/analyst-ratings-optimistic.svg';
import AnalystRatingsPessimistic from './icons/insights/analyst-ratings-pessimistic.svg';
import ComboInsightsHorizontalBorderNegative from './icons/insights/combo-insights-horizontal-border-negative.svg';
import ComboInsightsHorizontalBorderPositive from './icons/insights/combo-insights-horizontal-border-positive.svg';
import ComboInsightsVerticalBorderNegative from './icons/insights/combo-insights-vertical-border-negative.svg';
import ComboInsightsVerticalBorderPositive from './icons/insights/combo-insights-vertical-border-positive.svg';
import TradingVolumeDecreasing from './icons/insights/decreasing-volume.svg';
import DividendDecreasing from './icons/insights/dividend-decreasing.svg';
import DividendHigh from './icons/insights/dividend-high.svg';
import DividendIncreasing from './icons/insights/dividend-increasing.svg';
import DividendLow from './icons/insights/dividend-low.svg';
import EarningsBeats from './icons/insights/earnings-beats.svg';
import EarningsMisses from './icons/insights/earnings-misses.svg';
import EconomicGrowthDeteriorating from './icons/insights/economic-growth-deteriorating.svg';
import EconomicGrowthImproving from './icons/insights/economic-growth-improving.svg';
import EconomicGrowthSlow from './icons/insights/economic-growth-slow.svg';
import EconomicGrowthStrong from './icons/insights/economic-growth-strong.svg';
import EventsFOMCMeeting from './icons/insights/events-fomc-meeting.svg';
import EventsOPECMeeting from './icons/insights/events-opec-meeting.svg';
import GeopoliticsStable from './icons/insights/geopolitics-stable.svg';
import GeopoliticsTense from './icons/insights/geopolitics-tense.svg';
import GovernmentShutdown from './icons/insights/government-shutdown.svg';
import TradingVolumeHigh from './icons/insights/high-volume.svg';
import TradingVolumeIncreasing from './icons/insights/increasing-volume.svg';
import InflationDeflationWatch from './icons/insights/inflation-deflation-watch.svg';
import InflationDeflationary from './icons/insights/inflation-deflationary.svg';
import InflationInflationWatch from './icons/insights/inflation-inflation-watch.svg';
import InflationInflationary from './icons/insights/inflation-inflationary.svg';
import KeyPriceLevelNearResistance from './icons/insights/key-price-level-near-resistance.svg';
import KeyPriceLevelNearSupport from './icons/insights/key-price-level-near-support.svg';
import TradingVolumeLow from './icons/insights/low-volume.svg';
import MarketExtremesBigMoveDown from './icons/insights/market-extreme-big-move-down.svg';
import MarketExtremesBigMoveUp from './icons/insights/market-extreme-big-move-up.svg';
import MarketSentimentDeteriorating from './icons/insights/market-sentiment-deteriorating.svg';
import MarketSentimentImproving from './icons/insights/market-sentiment-improving.svg';
import MarketSentimentOptimistic from './icons/insights/market-sentiment-optimistic.svg';
import MarketSentimentPessimistic from './icons/insights/market-sentiment-pessimistic.svg';
import MomentumReversalsBottoming from './icons/insights/momentum-reversals-bottoming.svg';
import MomentumReversalsPeaking from './icons/insights/momentum-reversals-peaking.svg';
import MomentumSpikesDrop from './icons/insights/momentum-spikes-drop.svg';
import MomentumSpikesSurge from './icons/insights/momentum-spikes-surge.svg';
import MomentumStrongNegative from './icons/insights/momentum-strong-negative.svg';
import MomentumStrongPositive from './icons/insights/momentum-strong-positive.svg';
import MonetaryConditionEasy from './icons/insights/monetary-condition-easy.svg';
import MonetaryConditionTight from './icons/insights/monetary-condition-tight.svg';
import PositioningCrowdedLong from './icons/insights/positioning-crowded-long.svg';
import PositioningCrowdedShort from './icons/insights/positioning-crowded-short.svg';
import PositioningIncreasedLong from './icons/insights/positioning-increased-long.svg';
import PositioningIncreasedShort from './icons/insights/positioning-increased-short.svg';
import SingleInsightBorderNegative from './icons/insights/single-insight-border-negative.svg';
import SingleInsightBorderPositive from './icons/insights/single-insight-border-positive.svg';
import TrendAnalysisStrongDowntrend from './icons/insights/trend-analysis-strong-downtrend.svg';
import TrendAnalysisStrongUptrend from './icons/insights/trend-analysis-strong-uptrend.svg';
import USPresidentialElection from './icons/insights/us-presidential-election.svg';
import USRecession from './icons/insights/us-recession.svg';
import ValuationDeteriorating from './icons/insights/valuation-deteriorating.svg';
import ValuationHigh from './icons/insights/valuation-high.svg';
import ValuationImproving from './icons/insights/valuation-improving.svg';
import ValuationLow from './icons/insights/valuation-low.svg';
import VolatilityDecreasing from './icons/insights/volatility-decreasing.svg';
import VolatilityHigh from './icons/insights/volatility-high.svg';
import VolatilityIncreasing from './icons/insights/volatility-increasing.svg';
import VolatilityLow from './icons/insights/volatility-low.svg';
import Instagram from './icons/instagram.svg';
import Jcb from './icons/jcb.svg';
import Lightbulb from './icons/lightbulb.svg';
import Line from './icons/line.svg';
import Linkedin from './icons/linkedin.svg';
import Lock from './icons/lock.svg';
import LockLight from './icons/lock-light.svg';
import Mail from './icons/mail.svg';
import MarkAsRead from './icons/mark-as-read.svg';
import Mastercard from './icons/mastercard.svg';
import Megaphone from './icons/megaphone.svg';
import Minus from './icons/minus.svg';
import MinusFillCircle from './icons/minus-fill-circle.svg';
import MoneyCheckEdit from './icons/money-check-edit.svg';
import MoneyWave from './icons/money-wave.svg';
import Moon from './icons/moon.svg';
import MugHot from './icons/mug-hot.svg';
import Newspaper from './icons/newspaper.svg';
import Notification from './icons/notification.svg';
import NotificationLine from './icons/notification-line.svg';
import OilCan from './icons/oil-can.svg';
import Pencil from './icons/pencil.svg';
import PencilRuler from './icons/pencil-ruler.svg';
import Pin from './icons/pin.svg';
import PinSolid from './icons/pin-solid.svg';
import Play from './icons/play.svg';
import Plug from './icons/plug.svg';
import Presentation from './icons/presentation.svg';
import QrCode from './icons/qr-code.svg';
import Question from './icons/question.svg';
import QuestionMarkFill from './icons/questionmark-fill.svg';
import QuestionMarkCircle from './icons/questionmark-circle.svg';
import Rating from './icons/rating.svg';
import Reddit from './icons/reddit.svg';
import Relevance from './icons/relevance.svg';
import Reset from './icons/reset.svg';
import RightLeftLarge from './icons/right-left-large.svg';
import RightPanelExpand from './icons/right-panel-expand.svg';
import RightPanelCollapse from './icons/right-panel-collapse.svg';
import Rocket from './icons/rocket.svg';
import SatelliteDish from './icons/satellite-dish.svg';
import Save from './icons/save.svg';
import Scenario from './icons/scenario.svg';
import Screener from './icons/screener.svg';
import Search from './icons/search.svg';
import SendMessage from './icons/send-message.svg';
import Settings from './icons/settings.svg';
import Share from './icons/share.svg';
import Shield from './icons/shield.svg';
import ShoePrints from './icons/shoe-prints.svg';
import ShowPassword from './icons/show-password.svg';
import Sign from './icons/sign.svg';
import SignOut from './icons/sign-out.svg';
import SignPost from './icons/sign-post.svg';
import Sort from './icons/sort.svg';
import SortAscLeft from './icons/sort-asc-left.svg';
import SortAscRight from './icons/sort-asc-right.svg';
import SortDescLeft from './icons/sort-desc-left.svg';
import SortDescRight from './icons/sort-desc-right.svg';
import SortDown from './icons/sort-down.svg';
import SortUp from './icons/sort-up.svg';
import StarEmpty from './icons/star-empty.svg';
import StarFill from './icons/star-fill.svg';
import Stop from './icons/stop.svg';
import Summarise from './icons/summarise.svg';
import Sun from './icons/sun.svg';
import SunRise from './icons/sunrise.svg';
import Support from './icons/support.svg';
import Swap from './icons/swap.svg';
import Sword from './icons/sword.svg';
import Sync from './icons/sync.svg';
import Telescope from './icons/telescope.svg';
import ThumbsDown from './icons/thumbs-down.svg';
import ThumbsUp from './icons/thumbs-up.svg';
import ToggleOff from './icons/toggle-off.svg';
import Tools from './icons/tools.svg';
import Transcript from './icons/transcript.svg';
import Trash from './icons/trash.svg';
import Trending from './icons/trending.svg';
import TShirt from './icons/tshirt.svg';
import Twitter from './icons/twitter.svg';
import TwitterColor from './icons/twitter-color.svg';
import Unpin from './icons/unpin.svg';
import User from './icons/user.svg';
import UserLine from './icons/user-line.svg';
import Unstar from './icons/unstar.svg';
import Vial from './icons/vial.svg';
import Vials from './icons/vials.svg';
import Visa from './icons/visa.svg';
import Washer from './icons/washer.svg';
import Watchlist from './icons/watchlist.svg';
import WavePulse from './icons/wave-pulse.svg';
import WebSearch from './icons/web-search.svg';
import Youtube from './icons/youtube.svg';

export const SvgIconsMap = {
  AlfredNav,
  ArrowLeftLong,
  ArrowRightLong,
  ArrowLeftLongLine,
  ArrowRightLongLine,
  ArrowRotateRight,
  Add,
  AddBoldCircle,
  Ai,
  AddLightCircle,
  AlarmClock,
  AmericanExpress,
  AngleLightDown,
  AngleLightLeft,
  AngleLightRight,
  AngleLightUp,
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowCircleUp,
  ArrowDown,
  ArrowFilter,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
  Astronaut,
  BadgePercentage,
  Bars,
  BarsSortDown,
  BarsSortUp,
  BearishBear,
  Binary,
  Binoculars,
  Bolt,
  Book,
  BookOpenReader,
  BookmarkFill,
  BookmarkOutline,
  BookIdea,
  Breaking,
  Briefcase,
  BriefcaseLight,
  BullishBull,
  Calendar,
  CalendarClock,
  Candlestick,
  CaretDown,
  CaretRight,
  Chain,
  ChartArrow,
  ChartNetwork,
  ChartLine,
  ChartBar,
  ChartCandlestick,
  ChartMixed,
  Chat,
  ChatNew,
  Checkmark,
  CheckmarkBoldCircle,
  CheckmarkCircle,
  CheckmarkFill,
  CheckmarkLightCircle,
  ChevronFillDown,
  ChevronFillLeft,
  ChevronFillRight,
  ChevronFillUp,
  ChevronLightDown,
  ChevronLightLeft,
  ChevronLightRight,
  ChevronLightUp,
  ChevronBoldDown,
  ChevronBoldLeft,
  ChevronBoldRight,
  ChevronBoldUp,
  ClearSort,
  Clock,
  ClockFill,
  ClockRotateLeft,
  Close,
  CloseBoldCircle,
  CloseCircle,
  CloseFillCircle,
  CloseLightCircle,
  CloudArrowUp,
  Clear,
  Coin,
  Comment,
  CommentSmile,
  Compass,
  Compress,
  ComputerClassic,
  Construction,
  Copy,
  Corn,
  Cow,
  CreditCard,
  CreditCardLight,
  Crop,
  Crosshair,
  DashedLine,
  DashboardNew,
  Desktop,
  DinersClub,
  Discover,
  Dollar,
  Dot,
  Download,
  Edit,
  Ellipsis,
  ArrowDownLeft,
  EnvelopeSolid,
  Euro,
  ExclamationBoldCircle,
  ExclamationFill,
  ExclamationCircle,
  ExclamationLightCircle,
  ExclamationTriangle,
  Exclamation,
  Ellipse,
  Expand,
  Explore,
  ExploreNav,
  ExploreLine,
  Extension,
  ExternalLink,
  Facebook,
  FaucetDrip,
  FileChart,
  File,
  Filing,
  Filter,
  FilterClear,
  FilterInline,
  FilterSolid,
  Fire,
  GeneralKnowledge,
  Gift,
  Globe,
  Grid,
  GripsDots,
  HamburgerMenu,
  HandBack,
  Handshake,
  HeartEmpty,
  HeartFill,
  HidePassword,
  Home,
  HospitalAlt,
  IdeaBook,
  Indicator,
  Indicators,
  InformationBoldCircle,
  InformationCircle,
  InformationLightCircle,
  Insights,
  Instagram,
  Jcb,
  Lightbulb,
  Line,
  Linkedin,
  Lock,
  LockLight,
  Mail,
  MarkAsRead,
  Mastercard,
  Megaphone,
  Minus,
  MinusFillCircle,
  MoneyCheckEdit,
  MoneyWave,
  Moon,
  MugHot,
  Newspaper,
  Notification,
  NotificationLine,
  OilCan,
  Pencil,
  PencilRuler,
  ChartPieSimple,
  ChartPie,
  Pin,
  PinSolid,
  Play,
  Plug,
  QrCode,
  Question,
  QuestionMarkCircle,
  QuestionMarkFill,
  Rating,
  Reddit,
  Relevance,
  Reset,
  RightPanelExpand,
  RightPanelCollapse,
  RightLeftLarge,
  Rocket,
  Presentation,
  SatelliteDish,
  Save,
  SendMessage,
  Search,
  Screener,
  Scenario,
  Settings,
  Share,
  Shield,
  ShoePrints,
  ShowPassword,
  Sign,
  SignOut,
  SignPost,
  Sort,
  SortUp,
  SortDown,
  SortAscLeft,
  SortAscRight,
  SortDescLeft,
  SortDescRight,
  StarFill,
  StarEmpty,
  Stop,
  Sun,
  SunRise,
  Summarise,
  AddRemove,
  Swap,
  Sync,
  Support,
  Sword,
  Telescope,
  ThumbsUp,
  ThumbsDown,
  ToggleOff,
  Tools,
  TShirt,
  Transcript,
  Trash,
  Trending,
  Twitter,
  TwitterColor,
  Unpin,
  UserLine,
  Unstar,
  User,
  Vial,
  Vials,
  Visa,
  Washer,
  Watchlist,
  WavePulse,
  WebSearch,
  Youtube,
  HtmlLinkIcon,
  Feedback,
  ValuationHigh,
  ValuationLow,
  ValuationImproving,
  ValuationDeteriorating,
  AnalystEstimatesStrong,
  AnalystEstimatesSlow,
  AnalystEstimatesImproving,
  AnalystEstimatesDeteriorating,
  MarketExtremesBigMoveUp,
  MarketExtremesBigMoveDown,
  VolatilityHigh,
  VolatilityLow,
  VolatilityIncreasing,
  VolatilityDecreasing,
  EconomicGrowthStrong,
  EconomicGrowthSlow,
  EconomicGrowthImproving,
  EconomicGrowthDeteriorating,
  USPresidentialElection,
  GovernmentShutdown,
  USRecession,
  KeyPriceLevelNearResistance,
  KeyPriceLevelNearSupport,
  AnalystRatingsCautious,
  AnalystRatingsConfident,
  AnalystRatingsDeteriorating,
  AnalystRatingsImproving,
  AnalystRatingsPessimistic,
  AnalystRatingsOptimistic,
  EarningsBeats,
  EarningsMisses,
  DividendDecreasing,
  DividendIncreasing,
  DividendHigh,
  DividendLow,
  SingleInsightBorderPositive,
  SingleInsightBorderNegative,
  ComboInsightsVerticalBorderPositive,
  ComboInsightsVerticalBorderNegative,
  ComboInsightsHorizontalBorderNegative,
  ComboInsightsHorizontalBorderPositive,
  EventsFOMCMeeting,
  EventsOPECMeeting,
  MomentumSpikesSurge,
  MomentumSpikesDrop,
  TrendAnalysisStrongUptrend,
  TrendAnalysisStrongDowntrend,
  MomentumStrongPositive,
  MomentumStrongNegative,
  MomentumReversalsBottoming,
  MomentumReversalsPeaking,
  PositioningCrowdedLong,
  PositioningCrowdedShort,
  PositioningIncreasedLong,
  PositioningIncreasedShort,
  MarketSentimentOptimistic,
  MarketSentimentPessimistic,
  MarketSentimentImproving,
  MarketSentimentDeteriorating,
  InflationInflationary,
  InflationDeflationary,
  InflationInflationWatch,
  InflationDeflationWatch,
  GeopoliticsTense,
  GeopoliticsStable,
  MonetaryConditionEasy,
  MonetaryConditionTight,
  TradingVolumeIncreasing,
  TradingVolumeDecreasing,
  TradingVolumeLow,
  TradingVolumeHigh,
} as const;

export type SvgIconNames = keyof typeof SvgIconsMap;
