import { Icon } from '@toggle/design-system';
import React from 'react';

import { ChartInsightCard } from '../chart-insight-card/ChartInsightCard';
import { ActiveInsightsGroup } from '../insights.types';
import * as S from './ChartInsightTooltipContent.styles';

export interface ChartInsightTooltipContentProps {
  activeInsightsGroup: ActiveInsightsGroup;
  groupedInsightsIndex: number;
  isNextBtnEnabled: boolean;
  isPrevBtnEnabled: boolean;
  insightsInDomainIndex: number;
  isSimpleChart: boolean;
  onArrowClick: (
    direction: 'next' | 'prev',
    currentInsightsByDateIndex: number,
    currentInsightsByTypeIndex: number
  ) => void;
}

export const ChartInsightTooltipContent = ({
  activeInsightsGroup,
  groupedInsightsIndex,
  insightsInDomainIndex,
  isPrevBtnEnabled,
  isNextBtnEnabled,
  isSimpleChart,
  onArrowClick,
}: ChartInsightTooltipContentProps) => {
  return (
    <S.ChartInsightTooltipContentRoot
      data-testid="chart-insight-tooltip-content"
      $isSimpleChart={isSimpleChart}
    >
      <S.ButtonsContainer>
        <S.ArrowButton
          id="arrow-button"
          onClick={() =>
            onArrowClick('prev', insightsInDomainIndex, groupedInsightsIndex)
          }
          disabled={!isPrevBtnEnabled}
        >
          <Icon iconName="ArrowLeft" size={16} />
        </S.ArrowButton>
        <S.ArrowButton
          id="arrow-button"
          onClick={() =>
            onArrowClick('next', insightsInDomainIndex, groupedInsightsIndex)
          }
          disabled={!isNextBtnEnabled}
        >
          <Icon iconName="ArrowRight" size={16} />
        </S.ArrowButton>
      </S.ButtonsContainer>
      <S.Cards>
        {activeInsightsGroup?.insightsDetails.map(insightDetails => (
          <ChartInsightCard
            key={insightDetails.item.id}
            insightDetails={insightDetails}
          />
        ))}
      </S.Cards>
    </S.ChartInsightTooltipContentRoot>
  );
};
