import { SingleNews } from '@toggle/toggle';
import { isSameDay } from 'date-fns';
import { RefObject } from 'react';

import { TopNewsReducerAction } from '../../News';
import { formatPillDate } from '../../utils/utils';

interface UseListScrollProps {
  newsListRef: RefObject<HTMLDivElement | null>;
  activeNewsList: SingleNews[];
  handleSelectSpecificDate: (fromTime: string) => void;
}

export const useListScroll = ({
  newsListRef,
  activeNewsList,
  handleSelectSpecificDate,
}: UseListScrollProps) => {
  const scrollToPosition = (top = 0, behavior: ScrollBehavior = 'smooth') => {
    newsListRef.current?.scrollTo({
      top: top - newsListRef.current.offsetTop - 10,
      behavior,
    });
  };

  const handleDatePillChange = (action: TopNewsReducerAction) => {
    const { date } = action;
    const actionDate = new Date(date);

    const formattedPillDate = formatPillDate(actionDate);
    const hasBeenFetched = activeNewsList.find(singleNews =>
      isSameDay(actionDate, new Date(singleNews.published))
    );

    const pillDateDom = document.getElementById(
      `news-group-${formattedPillDate}`
    );

    if (!hasBeenFetched) {
      handleSelectSpecificDate(date);
    } else if (hasBeenFetched && pillDateDom) {
      scrollToPosition(pillDateDom.offsetTop);
    }
  };

  return { scrollToPosition, handleDatePillChange };
};
