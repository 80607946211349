import { formatTicker } from '@toggle/toggle';
import React, { useMemo } from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { useEntity } from '~/hooks/use-entities';

import * as S from './EntityDetailsCell.styles';

export type EntityDetailsProps = {
  entityTag: string;
  iconSize?: number;
};

export const EntityDetailsCell = ({
  entityTag,
  iconSize = 12,
}: EntityDetailsProps) => {
  const { data } = useEntity(entityTag);
  const entity = data?.data;

  const entityDetailsContent = useMemo(
    () =>
      entity && (
        <S.EntityDetails data-testid="entity-details">
          <AssetLogoContainer size={iconSize} entity={entity} />
          <span>{formatTicker(entity)}</span>
        </S.EntityDetails>
      ),
    [entity, iconSize]
  );

  return entityDetailsContent;
};

EntityDetailsCell.Styled = S;
