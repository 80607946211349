import styled, { css, RuleSet } from 'styled-components';
import { LinePlot } from '../line-plot/LinePlot';

const Positive = css`
  color: var(--viz-bullish);
`;

const Negative = css`
  color: var(--viz-bearish);
`;

const Median = css`
  color: var(--border-default);
`;

const statusColors: Record<string, RuleSet<object>> = {
  positive: Positive,
  negative: Negative,
  median: Median,
};

export const StyledLinePlot = styled(LinePlot)<{
  $type: string;
}>`
  ${({ $type }) => statusColors[$type]};

  ${LinePlot.Styled.LinePlotPath} {
    stroke-width: 2;
  }
`;
