import * as Radix from '@radix-ui/react-dropdown-menu';
import {
  CheckBox,
  customScrollMinimal,
  fontWeight,
  Icon,
  typographyV2,
} from '@toggle/design-system';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { OptionProps } from './Option';

export const DropdownMenuRoot = styled(Radix.Root)``;

export const DropdownContent = css`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  max-width: 400px;
  max-height: min(55vh, 550px);
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  border-radius: 4px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  z-index: var(--z-index-modal);
`;

export const DropdownMenuContent = styled(Radix.Content)`
  ${DropdownContent}
`;

export const SubMenuContent = styled(Radix.SubContent)`
  ${DropdownContent}
`;

export const MenuItemLeftIndicator = styled.span`
  width: 4px;
  display: inline-block;
  height: auto;
  color: transparent;
  border-left: solid 3px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
`;

export const ItemText = styled.div<{ $direction?: OptionProps['direction'] }>`
  display: inline-flex;
  flex-direction: ${props => props.$direction};
  align-items: ${props =>
    props.$direction === 'column' ? 'flex-start' : 'center'};
  gap: ${props => (props.$direction === 'column' ? '2px' : '8px')};
  margin: 0px 4px;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

export const DisabledItemText = styled(ItemText)`
  padding: 5px;
`;
export const ResetButton = styled.p`
  display: flex;
  align-items: center;
  margin: 0px 8px;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

export const ResetIconWrapper = styled.span`
  display: inline-flex;
  margin-top: auto;
`;
export const ResetIcon = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-primary);
  margin-right: 8px;
`;

export const ResetButtonLabel = styled.span`
  color: var(--text-primary);
  ${typographyV2.bodySm};
`;

export const ResetItem = styled<
  FC<Radix.DropdownMenuItemProps & { isChecked?: boolean }>
>(Radix.Item)`
  all: unset;
  display: flex;
  padding: 8px;

  &[data-highlighted] {
    ${ResetButton} {
      background-color: var(--surface-hover);
    }
  }
`;

export const LockedIcon = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-disabled);
`;

export const IconWrapper = styled.span`
  padding-right: 8px;
`;

export const ItemLabel = styled.span<{
  $locked?: boolean;
}>`
  color: ${({ $locked }) =>
    $locked ? 'var(--text-disabled)' : 'var(--text-default)'};
  ${typographyV2.bodySm};
`;

export const CustomLabel = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;

  ${typographyV2.bodySm};
`;

export const RemoveLabel = styled(CustomLabel)`
  color: var(--text-critical);

  & svg {
    fill: currentColor;
  }
`;

export const ItemDescription = styled.span`
  display: block;
  color: var(--text-soft);
  ${typographyV2.bodyXs};
`;

export const CountBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: var(--surface-primary);
  color: var(--text-on-primary);
`;

export const CheckboxItem = styled<
  FC<Radix.DropdownMenuItemProps & { $isChecked?: boolean }>
>(Radix.CheckboxItem)`
  all: unset;
  display: flex;
  margin-bottom: 4px;

  ${CheckBox.Styled.Checkmark} {
    margin-top: 0;
  }

  ${CheckBox.Styled.Checkbox} {
    align-items: center;
  }

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      ${ItemText} {
        background-color: var(--surface-active);
      }
    `}

  &[data-highlighted] {
    ${ItemText} {
      background-color: var(--surface-hover);
    }
  }

  &[data-disabled] {
    filter: contrast(40%);
  }
`;

export const Item = styled<
  FC<Radix.DropdownMenuItemProps & { $isChecked?: boolean }>
>(Radix.Item)`
  all: unset;
  display: flex;
  margin-bottom: 4px;

  &[data-highlighted] {
    ${ItemText} {
      background-color: var(--surface-hover);
    }
  }

  &[data-disabled] {
    & ${ItemText} {
      cursor: default;
    }
  }

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      ${MenuItemLeftIndicator} {
        color: var(--surface-primary);
        border-color: var(--surface-primary);
      }
      ${ItemText} {
        background-color: var(--surface-active);
      }
    `}
`;

export const DropdownMenuTitle = styled.div`
  display: flex;
  padding: 0px 16px;
`;

export const DropdownMenuTitleText = styled.p`
  color: #9ba1a6;
  ${typographyV2.bodyXs};
  flex: 1;
  padding: 8px 0px;
  align-items: flex-start;
  text-transform: uppercase;
`;

export const MenuTitleText = styled(DropdownMenuTitleText)`
  padding: 8px 16px;
`;
export const TriggerLabel = styled.span`
  ${typographyV2.bodySm};
`;

export const Separator = styled(Radix.Separator)`
  height: 1px;
  background-color: var(--border-soft);
  margin-bottom: 8px;
`;

export const Trigger = styled<
  FC<Radix.DropdownMenuTriggerProps & { $active?: boolean }>
>(Radix.Trigger)`
  display: inline-flex;
  background-color: ${props =>
    props.$active ? 'var(--surface-active)' : 'var(--surface-soft)'};
  height: 32px;
  padding: 6px 8px 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.$active ? 'var(--surface-active)' : 'var(--border-default)'};
  cursor: pointer;

  ${TriggerLabel} {
    color: ${props =>
      props.$active ? 'var(--text-default)' : 'var(--text-soft)'};
  }

  &:hover {
    background: var(--surface-hover);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }
  &[data-state='open'] {
    background-color: var(--surface-active);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }
  &[data-disabled] {
    background-color: var(--surface-soft);
    cursor: default;
    ${TriggerLabel} {
      color: var(--text-disabled);
    }
  }
`;

export const DashedTrigger = styled(Trigger)`
  border: 1px dashed var(--border-hover);
`;

export const SelectedValue = styled.span`
  display: inline-flex;
  gap: 4px;
  color: var(--text-primary);
  align-items: center;
  ${typographyV2.bodySm};
`;

export const TriggerArrow = styled(Icon).attrs({
  size: 14,
})`
  fill: var(--icon-soft);
`;
export const SubTriggerArrow = styled(Icon).attrs({
  size: 12,
})`
  fill: var(--icon-primary);
`;

export const TriggerAdd = styled(Icon).attrs({
  size: 14,
})`
  fill: var(--icon-soft);
`;

export const SubTriggerLabel = styled.span`
  font-weight: ${fontWeight.medium};
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const SubTrigger = styled<
  FC<Radix.DropdownMenuSubTriggerProps & { active?: boolean }>
>(Radix.SubTrigger)`
  display: inline-flex;
  background-color: ${props =>
    props.active ? 'var(--surface-active)' : 'var(--surface-soft)'};
  height: 32px;
  padding: 6px 8px 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  color: var(--text-primary);
  outline: none;
  ${typographyV2.bodySm};

  &:hover {
    background: var(--surface-hover);
  }
  &[data-state='open'] {
    background-color: var(--surface-soft);
  }
  &[data-disabled] {
    background-color: var(--surface-soft);
  }

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const ScrollableList = styled.div`
  ${customScrollMinimal};
  overflow-y: auto;
`;

export const NoResults = styled.p`
  padding: 8px;
  margin: 8px;
  ${typographyV2.bodySm}
`;

export const CustomHeaderLabel = styled.div`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  padding: 10px 16px 2px 16px;
`;

export const CustomBottomBorder = styled(Radix.Separator)`
  height: 1px;
  background-color: var(--border-soft);
  margin-top: 8px;
`;
