import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { isValidEmail } from '~/hooks/use-email-validation/useEmailValidation';
import { queryKeys } from '~/routes/app-paths';
import { postReqResetPassword } from '~/views/auth/services/auth-services';

interface RequestState {
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface useForgotPasswordStateResult {
  email: string;
  clearError: () => void;
  validateEmail: (email: string) => boolean;
  updateEmail: (email: string) => void;
  postReqResetPassword: (email: string) => Promise<void>;
  requestState: RequestState;
  errorText: string;
}

const initialRequestState = {
  isPending: false,
  isSuccess: false,
  isError: false,
};

export function useForgotPasswordState() {
  const { t } = useTranslation('auth');
  const searchParams = useLocation().search;
  const emailToReset = useMemo(() => {
    const params = new URLSearchParams(searchParams);
    return params.get(queryKeys.emailReset);
  }, [searchParams]);

  const [email, setEmail] = useState(emailToReset || '');
  const [errorText, setErrorText] = useState('');
  const [requestState, setRequestState] = useState(initialRequestState);

  const updateEmail = (value: string) => {
    setEmail(value.trim());
  };

  const validateEmail = (value: string) => {
    if (!value) {
      setErrorText(t('auth:genericEmailEmptyError'));
      return false;
    }

    if (!isValidEmail(value)) {
      setErrorText(t('auth:genericEmailError'));
      return false;
    }

    clearError();
    return true;
  };

  const clearError = () => {
    setErrorText('');
  };

  async function request(email: string) {
    setRequestState({ ...requestState, isPending: true });
    const result = await postReqResetPassword({ email });

    if (result.error) {
      setRequestState({
        ...initialRequestState,
        isError: true,
      });
      setErrorText(t('auth:forgotResetPassword.errorLabel'));
    } else if (result.data.message === 'ok') {
      setRequestState({ ...initialRequestState, isSuccess: true });
    }
  }

  return {
    clearError,
    email,
    validateEmail,
    updateEmail,
    postReqResetPassword: request,
    requestState,
    errorText,
  };
}
