import * as Radix from '@radix-ui/react-dropdown-menu';
import { Badge, borderRadius, EntityDetails } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledEntityDetails = styled(EntityDetails)`
  margin: 0;
`;

export const Content = styled(Radix.Content)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 500px;
  padding: 20px;
  ${borderRadius.r2};
  background: var(--background-soft);
  z-index: var(--z-index-modal);
`;

export const ChartWrapper = styled.div`
  position: relative;
`;

export const HorizonLabel = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;

  ${Badge.Styled.BadgeTextual} {
    background: var(--background-soft);
  }
`;
