import {
  customScrollMinimal,
  Input,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ChatInputFieldWrapper = styled.div<{
  $isMessagePending?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  padding: 0;
  border: 1px solid var(--border-soft);
  &:focus-within {
    border: ${({ $isMessagePending }) =>
      !$isMessagePending && '1px solid var(--border-primary)'};
  }
  background-color: var(--surface-default);
  box-shadow: var(--elevation-moderate);
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  gap: 8px;
  background-color: transparent;
  align-items: center;
  border: none;
  padding: 16px;
  padding-right: 0px;
  width: 100%;

  &:hover {
    border-color: var(--border-soft);
  }
`;

export const EnterIcon = styled.div`
  display: flex;
  position: absolute;
  border: 1px solid var(--icon-disabled);
  padding: 4px 8px 4px 8px;
  width: 67px;
  height: 24px;
  ${typographyV2.uiXs}
  color: var(--icon-disabled);
  align-items: center;
  border-radius: 4px;
  svg {
    align-items: center;
    margin-left: 4px;
    path {
      fill: var(--icon-disabled);
    }
  }
`;

export const TextareaElement = styled(Input.Styles.InputElement)`
  ${customScrollMinimal};
  ${typographyV2.bodyBase};
  display: inline-block;
  width: fit-content;
  padding: 0;
  resize: none;
  margin-right: 60px;
  ${typographyV2.bodyBase};
  &::placeholder {
    color: var(--text-disabled);
  }
  max-height: 240px;
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--surface-neutral);
  }
`;

export const ChatInputFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
