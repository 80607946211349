import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const Menu = styled.div`
  z-index: var(--z-index-app-header);
  box-shadow: var(--elevation-moderate);
  ${borderRadius.r1}
  outline: 1px solid var(--border-soft);
  background-color: var(--surface-default);
`;

export const MenuItem = styled.div`
  z-index: var(--z-index-app-header);
  outline: 0;
`;
