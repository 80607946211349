import { StyledComponent, WithNAFallback } from '@toggle/design-system';
import { KeyValue } from '@toggle/toggle';
import React from 'react';

import * as S from './KeyValueElement.styles';

export interface KeyValueElementProps {
  keyValue: KeyValue;
}

export const KeyValueElement: StyledComponent<
  KeyValueElementProps,
  typeof S
> = ({ keyValue }: KeyValueElementProps) => {
  return (
    <S.KeyValueElementRoot data-testid="components">
      <S.Title>{keyValue.display}</S.Title>
      <S.Details>
        <S.Value>
          <WithNAFallback
            check={keyValue.value_fmt !== undefined}
            value={keyValue.value_fmt}
          />
        </S.Value>
        {keyValue.value_fmt && <S.Unit>{keyValue.unit}</S.Unit>}
      </S.Details>
    </S.KeyValueElementRoot>
  );
};

KeyValueElement.Styled = S;
