import { Alert, AlertType } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDismissContinuous } from '~/hooks/use-dismiss-continuous/useDismissContinuous';

const DISMISSED_KEY = '__toggle_dismissed_card_error';

export interface AlertCardErrorProps {
  openEditCardModal: () => void;
  hasError: boolean;
}

export const AlertCardError: FC<AlertCardErrorProps> = ({
  openEditCardModal,
  hasError,
}) => {
  const { t } = useTranslation('subscription');

  const { isVisible, handleDismiss } = useDismissContinuous({
    cookieKey: DISMISSED_KEY,
    overrideIsVisible: hasError,
  });

  const onClick = () => {
    handleDismiss();
    openEditCardModal();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Alert
      data-testid="card-error-toast-alert"
      title={t('subscription:alerts.paymentMethodExpiring.titleExpired')}
      message={t(
        'subscription:alerts.paymentMethodExpiring.notificationMessage'
      )}
      type={AlertType.Error}
      primaryBtn={{
        label: t('subscription:alerts.paymentMethodError.primaryBtnLabel'),
        onClick,
      }}
      onClose={handleDismiss}
    />
  );
};
