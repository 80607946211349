import { Button } from '@toggle/design-system';
import { BillingCycle } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { VerifyEmailButton } from '~/components/verify-email-button/VerifyEmailButton';
import { useNavigateToCheckout } from '~/views/settings/subscription/hooks/use-navigate-to-checkout/useNavigateToCheckout';
import {
  ChangingPlanTier,
  PlanCardType,
} from '~/views/settings/subscription/plan-matrix/utils/plan-matrix.types';

import { PageVariants } from '../utils';

export const OnHoldActionButton = () => {
  const { t } = useTranslation('auth');
  const [searchParams] = useSearchParams();
  const upgradeToPro = useNavigateToCheckout();
  const reason = searchParams.get('reason');

  if (reason === PageVariants.Email) {
    return <VerifyEmailButton />;
  }

  if (reason === PageVariants.Payment) {
    return (
      <Button
        data-testid="verify-email-button"
        type="button"
        label={t('auth:onHold.enterPaymentMethod')}
        onClick={() =>
          upgradeToPro(
            {
              billingCycle: BillingCycle.year,
              tier: ChangingPlanTier.Initial,
              type: PlanCardType.Pro,
            },
            { replace: true }
          )
        }
      />
    );
  }

  return (
    <Button
      data-testid="resume-subscription-btn"
      label={t('auth:onHold.subscribe')}
      size="small"
      onClick={() => upgradeToPro({ tier: ChangingPlanTier.Upgrading })}
    />
  );
};
