import {
  CLOSE_MODAL_ID,
  MODAL_ID,
  TRIGGER_INPUT_ID,
} from '@toggle/design-system';
import { KeyboardKeys } from '@toggle/helpers';
import { useEffect, useRef } from 'react';

import { CHIPS_WRAPPER_ID } from '~/components/search-modal/components/chips/Chips';
import { ENTITY_CHIP_ID } from '~/components/search-modal/utils';

export interface TabbedElementConfig {
  selector: string;
  key: string;
  checker?: (element: Element | null) => boolean;
  shouldSetFocusIndex?: boolean;
}

const config: TabbedElementConfig[] = [
  {
    selector: `#${MODAL_ID} #${CLOSE_MODAL_ID}`,
    key: 'close',
  },
  {
    selector: `#${MODAL_ID} #${ENTITY_CHIP_ID}`,
    key: 'entity-chip',
  },
  {
    selector: `#${MODAL_ID} #${TRIGGER_INPUT_ID}`,
    key: 'input',
  },
  {
    selector: `#${MODAL_ID} #${CHIPS_WRAPPER_ID} button[aria-selected=true]`,
    key: 'chip',
  },
  {
    selector: `#${MODAL_ID} li > button`,
    key: 'option',
    checker: () => true,
    shouldSetFocusIndex: true,
  },
];

export const useSearchModalKeyboard = (tabbedElementsConfig = config) => {
  const dropdownApiRef = useRef<any>(undefined);

  const getElementToFocusWithTab = (
    tabbedElementsConfig: TabbedElementConfig[],
    shiftKey: KeyboardEvent['shiftKey']
  ) => {
    const getNextIdx = shiftKey
      ? (idx: number) => (idx === 0 ? tabbedElementsConfig.length - 1 : idx - 1)
      : (idx: number) =>
          idx === tabbedElementsConfig.length - 1 ? 0 : idx + 1;

    const mapped = tabbedElementsConfig.map(item => ({
      ...item,
      element: document.querySelector<HTMLElement>(item.selector),
    }));
    const foundIdx = mapped.findIndex(item =>
      item.checker
        ? item.checker(document.activeElement)
        : document.activeElement === item.element
    );

    if (foundIdx !== -1) {
      const idx = getNextIdx(foundIdx);
      const searchArray = shiftKey
        ? mapped.slice(0, idx + 1).reverse()
        : mapped.slice(idx);
      return {
        focusItemConfig: searchArray.find(item => item.element),
        idx,
      };
    }

    return null;
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const key = e.key as KeyboardKeys;

    if (dropdownApiRef.current) {
      dropdownApiRef.current.setCurrentAction('focus');
    }

    switch (key) {
      case 'Enter':
        if (!e.shiftKey && dropdownApiRef.current.focusedItemIndex !== -1) {
          e.preventDefault();
          dropdownApiRef.current.handleEnter();
        }

        break;

      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault();

        {
          const idx = dropdownApiRef.current.getFocusedIndexForArrowKey(key);
          dropdownApiRef.current.setFocusedItemIndex(idx);
          const item = config.find(c => c.key === 'option');

          if (item) {
            document.querySelectorAll<HTMLElement>(item.selector)[idx]?.focus();
          }
        }

        break;

      case 'Tab':
        {
          const data = getElementToFocusWithTab(
            tabbedElementsConfig,
            e.shiftKey
          );

          if (data?.focusItemConfig?.element) {
            e.preventDefault();
            data.focusItemConfig.element.focus();
            dropdownApiRef.current.setFocusedItemIndex(
              data.focusItemConfig.shouldSetFocusIndex ? 0 : -1
            );
          } else {
            dropdownApiRef.current.setFocusedItemIndex(-1);
          }
        }

        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return dropdownApiRef;
};
