import { SkeletonLoader } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';

import { DrawerSectionFooter } from '../drawer-section-footer/DrawerSectionFooter';
import { DrawerSectionHeader } from '../drawer-section-header/DrawerSectionHeader';
import * as S from './DrawerSection.styles';

interface DrawerSectionProps {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  children: ReactNode;
  headerTitle: ReactNode;
  headerDescription: ReactNode;
  rightHeaderNode?: ReactNode;
  footerLabel?: string;
  footerOnClick?: () => void;
  refetch: () => void;
}

export const DrawerSection = ({
  isLoading,
  isEmpty,
  isError,
  children,
  headerTitle,
  headerDescription,
  rightHeaderNode,
  footerLabel,
  footerOnClick,
  refetch,
}: DrawerSectionProps) => {
  if (isLoading) {
    return (
      <SkeletonLoader
        areas={[
          {
            styles: {
              width: '100%',
              height: '248px',
              borderRadius: '8px',
            },
          },
        ]}
      />
    );
  }

  if (isError) {
    return <ErrorMessageWrapper displayIcon onClick={refetch} />;
  }

  if (isEmpty) {
    return null;
  }

  return (
    <S.Root>
      <DrawerSectionHeader
        title={headerTitle}
        description={headerDescription}
        rightNode={rightHeaderNode}
      />
      {children}
      {!!footerLabel && !!footerOnClick && (
        <DrawerSectionFooter label={footerLabel} onClick={footerOnClick} />
      )}
    </S.Root>
  );
};
