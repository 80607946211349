import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const CardContainer = styled.div<{ $isActive: boolean }>`
  max-width: calc((100% / 7) - 20px);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid
    ${({ $isActive }) =>
      $isActive ? 'var(--border-primary)' : 'var(--border-soft)'};

  &:hover {
    border: 1px solid
      ${({ $isActive }) =>
        $isActive ? 'var(--border-primary)' : 'var(--border-hover)'};
    cursor: pointer;
  }
`;

export const CardDateWrapper = styled.p`
  display: flex;
  gap: 4px;
  ${typographyV2.bodySm}
`;

export const DayOfWeek = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Row = styled.div<{ $bgColor?: string }>`
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 8px;
  background-color: ${({ $bgColor }) => $bgColor ?? 'inherit'};
  border-radius: 4px;
  border: 1px solid transparent;
`;
