import styled, { css } from 'styled-components';

import { customScrollMinimal } from '~/components/custom-scroll/CustomScroll.styles';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

export const DropdownListStyles = css`
  ${customScrollMinimal}
  max-height: min(30vh, 320px);
  box-shadow: var(--elevation-moderate);
  ${borderRadius.r1}
  border: 1px solid var(--border-soft);
  background-color: var(--surface-default);
  overflow-y: auto;
  && {
    padding: 1px;
  }
`;

export const DropdownListRoot = styled.ul<{ $hasV2Styles?: boolean }>`
  ${({ $hasV2Styles }) => $hasV2Styles && DropdownListStyles}
`;
