import { Icon } from '@toggle/design-system';
import React from 'react';

import * as S from './ProviderCell.styles';
import { isValidIcon } from './utils';

export type ProviderCellProps = {
  documentKind: string;
  date?: string;
  iconSize?: number;
  customText?: string;
};

export const ProviderCell = ({
  documentKind,
  date,
  iconSize = 12,
  customText,
}: ProviderCellProps) => {
  const formattedProvider = documentKind
    ? `${documentKind[0].toUpperCase()}${documentKind.slice(1)}`
    : '';
  const labelText = customText ?? `${formattedProvider} | ${date}`;
  return (
    <S.Root data-testid="provider-cell">
      {isValidIcon(formattedProvider) && (
        <Icon iconName={formattedProvider} size={iconSize} />
      )}
      <S.StyledLabel data-testid="provider-string">{labelText}</S.StyledLabel>
    </S.Root>
  );
};
