import { Insight, InsightDirection } from '@toggle/toggle';
import { isToday } from 'date-fns';
import React from 'react';

import * as S from './InsightIcon.styles';

export interface InsightIconProps {
  insight: Insight;
  options: {
    iconSize: number;
    isDisabled?: boolean;
  };
}

export const InsightIcon = ({ insight, options }: InsightIconProps) => {
  const { iconSize, isDisabled = false } = options;
  const isBullish = insight.metadata.direction === InsightDirection.Bullish;
  const showSparkles = isToday(new Date(insight.created_at));
  return (
    <S.Container>
      <S.StyledIcon
        key={insight.id}
        iconName={isBullish ? 'BullishBull' : 'BearishBear'}
        size={iconSize}
        $disabled={isDisabled}
      />
      {showSparkles && (
        <S.Sparkles
          width="8"
          height="8"
          viewBox="0 0 8 8"
          $isBullish={isBullish}
          $isDisabled={isDisabled}
          data-testid="sparkles-icon"
        >
          <path d="M4.07403 0.188482L5.05671 2.33717L7.21104 3.31728C7.32442 3.37382 7.40001 3.48691 7.40001 3.6C7.40001 3.71309 7.32442 3.82618 7.21104 3.86387L5.05671 4.86283L4.07403 7.01152C4.01733 7.12461 3.90395 7.2 3.79056 7.2C3.67718 7.2 3.56379 7.12461 3.526 7.01152L2.52442 4.86283L0.370091 3.88272C0.256705 3.82618 0.200012 3.71309 0.200012 3.6C0.200012 3.48691 0.256705 3.37382 0.370091 3.31728L2.52442 2.33717L3.526 0.188482C3.56379 0.0753928 3.67718 0 3.79056 0C3.90395 0 4.01733 0.0753928 4.07403 0.188482Z" />
        </S.Sparkles>
      )}
    </S.Container>
  );
};
