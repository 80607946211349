import { Condition } from '@toggle/toggle';
import { z } from 'zod';

const conditionSchema = z.discriminatedUnion('condition', [
  z.object({
    condition: z.literal('crosses_above'),
    value: z.number(),
  }),
  z.object({
    condition: z.literal('crosses_below'),
    value: z.number(),
  }),
  z.object({
    condition: z.literal('above'),
    value: z.number(),
  }),
  z.object({
    condition: z.literal('below'),
    value: z.number(),
  }),
  z.object({
    condition: z.literal('between'),
    max_value: z.number(),
    min_value: z.number(),
  }),
  z.object({
    condition: z.literal('rises_by'),
    value: z.number(),
    duration: z.number(),
    duration_unit: z.enum(['m', 'd', 'w']),
  }),
  z.object({
    condition: z.literal('falls_by'),
    value: z.number(),
    duration: z.number(),
    duration_unit: z.enum(['m', 'd', 'w']),
  }),
  z.object({
    condition: z.literal('rises_by_level'),
    value: z.number(),
    duration: z.number(),
    duration_unit: z.enum(['m', 'd', 'w']),
  }),
  z.object({
    condition: z.literal('falls_by_level'),
    value: z.number(),
    duration: z.number(),
    duration_unit: z.enum(['m', 'd', 'w']),
  }),
]);

export const validateCondition = <T extends Condition>(
  condition: Partial<T>,
  fullCondition?: boolean
) => {
  const withEntityAndSnake = fullCondition
    ? !!(condition.entity && condition.snake)
    : true;
  return withEntityAndSnake && conditionSchema.safeParse(condition).success;
};

export const validateConditions = <T extends Condition>(
  conditions: Partial<T>[]
) => {
  return conditions.every(c => validateCondition(c, true));
};
