import { Accordion, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const AssessmentResultWrapper = styled.div`
  margin-top: 32px;

  ${Accordion.Styled.AccordionItem} {
    background-color: transparent;
  }
`;

export const ResultTitle = styled.h2`
  ${typographyV2.titleMd}
  text-align: left;
  margin-bottom: 12px;
`;

export const StyledExplicitValue = styled.p<{ $isBullish?: boolean }>`
  color: var(--text-critical);
  ${({ $isBullish = false }) =>
    $isBullish &&
    css`
      color: var(--text-success);
    `}
`;
