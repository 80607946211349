import { formatTicker } from '@toggle/toggle';
import React from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { Filter, FilterProps } from '~/components/filter/Filter';
import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';
import { FilterGroupKeys } from '~/hooks/use-filter-actions/useFilterActions';

import * as S from './FilterCompany.styles';

export const FilterCompany = <T extends FilterGroupKeys>({
  labels,
  ...rest
}: FilterProps<T>) => {
  const renderCustomFilterOptionLabel = (filterOption: FilterOption) => {
    if (filterOption?.entity) {
      const entity = filterOption.entity;
      const tag = formatTicker(entity);
      return (
        <S.FlexRow>
          <AssetLogoContainer entity={entity} size={20} />
          <S.FlexColumn>
            <S.Title>{tag}</S.Title>
            <S.Subtitle>{entity.name}</S.Subtitle>
          </S.FlexColumn>
        </S.FlexRow>
      );
    }

    return null;
  };

  return (
    <Filter labels={{ ...labels, renderCustomFilterOptionLabel }} {...rest} />
  );
};
