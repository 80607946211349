import { CheckBox } from '@toggle/design-system';
import { Condition } from '@toggle/toggle';
import React from 'react';

import { ConditionBuilder } from '../../custom-scenario-modal/ConditionBuilder';
import { MappedConditions } from '../use-scenario-assistant/enrichedConditions';
import { useScenarioAssistant } from '../use-scenario-assistant/useScenarioAssistant';
import * as S from './ScenarioConditionBuilder.styles';

export interface ScenarioConditionBuilderProps {
  editFilterKey?: string;
  conditions: MappedConditions[];
}

export const ScenarioConditionBuilder = ({
  editFilterKey,
  conditions,
}: ScenarioConditionBuilderProps) => {
  const { updateCustomCondition, toggleConditionState } =
    useScenarioAssistant();

  const onConditionChange = (
    editedCondition: Partial<Condition>,
    condition: MappedConditions
  ) => {
    updateCustomCondition({
      ...condition,
      ...editedCondition,
    });
  };

  const filteredConditions = editFilterKey
    ? conditions.filter(c => c.id === editFilterKey)
    : conditions;

  return (
    <S.Conditions data-testid="scenario-condition-builder">
      {filteredConditions.map(condition => (
        <S.ConditionItem
          data-testid="scenario-condition-item"
          key={condition.id}
        >
          {!editFilterKey && (
            <CheckBox
              name=""
              label=""
              data-testid="scenario-condition-check"
              checked={condition.active}
              onChange={() => {
                toggleConditionState?.(condition.id);
              }}
            />
          )}
          <ConditionBuilder
            snake={condition.snake}
            onConditionChange={change => onConditionChange(change, condition)}
            defaultCondition={condition}
          />
        </S.ConditionItem>
      ))}
    </S.Conditions>
  );
};
