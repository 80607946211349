import { formatTicker } from '@toggle/toggle';
import React from 'react';
import { useSearchParams } from 'react-router';

import { OverlayModal } from '~/components/overlay-modal/OverlayModal';
import { CompareEntitiesOnChart } from '~/components/prompts/components/compare-entities-on-chart/CompareEntitiesOnChart';
import { CreateWatchlistFromRelatedCompanies } from '~/components/prompts/components/create-watchlist-from-related-companies/CreateWatchlistFromRelatedCompanies';
import { useEntity } from '~/hooks/use-entities';
import { useOntology } from '~/hooks/use-ontology/useOntology';
import { ChartSearchParams } from '~/utils/chart-search-utils/chart-search-utils';

import { AssetOverviewWidget } from './AssetOverviewWidget';

export interface AssetOverviewModalProps {
  handleClose: () => void;
}

export const AssetOverviewModal = ({
  handleClose,
}: AssetOverviewModalProps) => {
  const [searchParams] = useSearchParams();
  const assets = searchParams.getAll(ChartSearchParams.Asset);
  const tag = assets.map(string => string.split(';')[0])[0];

  const { relatedCompanies } = useOntology({ entityTag: tag });
  const { data } = useEntity(tag);

  const entity = data?.data;

  if (!entity) {
    return null;
  }

  const subheading = formatTicker(entity);

  const hasAnyRelatedCompany =
    !!relatedCompanies &&
    (relatedCompanies.competitors.length > 0 ||
      relatedCompanies.suppliers.length > 0);

  return (
    <OverlayModal
      data-testid="asset-overview-modal"
      key={entity.tag}
      title={`${entity.name} (${subheading})`}
      onClose={handleClose}
      chatPrompts={
        hasAnyRelatedCompany
          ? [
              <CompareEntitiesOnChart
                key="first"
                data-testid="prompt-compare-asset"
                entity={entity}
                relatedCompanies={relatedCompanies}
              />,

              <CreateWatchlistFromRelatedCompanies
                key="second"
                data-testid="prompt-create-watchlist"
                relatedCompanies={relatedCompanies}
              />,
            ]
          : undefined
      }
    >
      <AssetOverviewWidget entity={entity} />
    </OverlayModal>
  );
};
