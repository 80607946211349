import { useUser } from '~/stores/use-user/useUser';

import {
  PromoMetadata,
  verifyPromoCode,
} from '../../services/promo-code/promo-code-service';
import { create } from '../create-store/createStore';

type State = {
  promoMetadata: PromoMetadata;
  promoCode: string;
};

type Actions = {
  initialize: () => Promise<void>;
  setPromoData: (state: Partial<State>) => void;
};

type PromoCodeStore = State & Actions;

const initialState = {
  promoMetadata: {},
  promoCode: '',
};

export const usePromoCodeStore = create<PromoCodeStore>(set => ({
  ...initialState,
  initialize: async () => {
    const userPromoCode = useUser.getState().user?.promo_code;

    if (userPromoCode) {
      const response = await verifyPromoCode(userPromoCode);

      if (response.error) {
        set({ promoMetadata: {} });
      } else {
        set({
          promoMetadata: response.data.promo.metadata,
          promoCode: userPromoCode,
        });
      }
    }
  },
  setPromoData: (promoData: Partial<State>) =>
    set(s => ({ ...s, ...promoData })),
}));
