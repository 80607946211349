import { EarningsScenario, EarningsScenarioResponse } from '@toggle/toggle';
import { isPast } from 'date-fns/isPast';

import { PrimaryStatus, SecondaryStatus, Status } from '../../types';
import { getEarningState } from '../../utils';

// For reference this file is based on requirements in the "WhatIF Earnings - Status Scenarios and Use Cases.pdf" document seen here:
// https://toggleai.atlassian.net/wiki/spaces/TW/pages/308772875/WhatIf+Earnings+PDFs

const mapStatus = (
  primaryStatus: PrimaryStatus,
  assetDetails?: EarningsScenarioResponse
): Status => {
  const isNotReported = !assetDetails?.reported;
  const isAnalyzing =
    !!assetDetails &&
    isPast(new Date(assetDetails.reporting_date)) &&
    assetDetails.eps.value === undefined;

  const isReported =
    assetDetails?.reported && assetDetails?.eps.value !== undefined;

  if (isAnalyzing) {
    return {
      primary: primaryStatus,
      secondary: SecondaryStatus.Analyzing,
    };
  }

  if (isNotReported) {
    return {
      primary: primaryStatus,
      secondary: SecondaryStatus.NotReported,
    };
  }

  if (isReported) {
    return {
      primary: primaryStatus,
      secondary: SecondaryStatus.Reported,
      tertiary: getEarningState(assetDetails.eps),
    };
  }

  return {
    primary: PrimaryStatus.None,
    secondary: SecondaryStatus.NotReported,
  };
};

export const getStatus = (
  assetDetails?: EarningsScenarioResponse,
  scenario?: EarningsScenario | null
): Status => {
  const hasEstimate = assetDetails?.eps.estimate !== undefined;
  const hasHistory = !!scenario?.history?.meta.count;

  // 1 history & estimate
  if (hasHistory && hasEstimate) {
    return mapStatus(PrimaryStatus.Both, assetDetails);
  }

  // 2 no history & estimate
  if (!hasHistory && hasEstimate) {
    return mapStatus(PrimaryStatus.OnlyEstimate, assetDetails);
  }

  // 3 history & no estimate
  if (hasHistory && !hasEstimate) {
    return mapStatus(PrimaryStatus.OnlyHistory, assetDetails);
  }

  // 4 no history no estimate
  if (!hasHistory && !hasEstimate) {
    return mapStatus(PrimaryStatus.None, assetDetails);
  }

  return {
    primary: PrimaryStatus.None,
    secondary: SecondaryStatus.NotReported,
  };
};
