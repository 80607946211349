import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  text-align: right;
`;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VerticalLineDivider = styled.div`
  height: 32px;
  width: 1px;
  background-color: var(--border-default);
`;
