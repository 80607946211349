import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatMode } from '~/stores/use-chat-mode/useChatMode';
import { useFlags } from '~/stores/use-flags/useFlags';

import * as S from './ChatModesButtonGroup.styles';

interface ChatModesButtonGroupProps {
  mode: ChatMode;
  onClick: (mode: ChatMode) => void;
}

export const ChatModesButtonGroup: FC<ChatModesButtonGroupProps> = ({
  mode,
  onClick,
}) => {
  const { t } = useTranslation(['chat']);
  const enableReasonerOption = useFlags(state =>
    state.getFeatureFlagState('enable_reasoner')
  );

  const labels = [
    t('chat:analysis', { context: 'intel' }),
    ...(enableReasonerOption
      ? [t('chat:analysis', { context: 'reasoner' })]
      : []),
  ];

  return (
    <S.StyledButtonGroup
      activeIndex={mode === 'intel' ? 0 : 1}
      labels={labels}
      onClick={index => onClick(index === 0 ? 'intel' : 'reasoner')}
    />
  );
};
