import {
  BankCard,
  BillingCycle,
  Subscription,
  SubscriptionName,
  UserAddress,
  UserRole,
  UserSubscription,
  UserSubscriptionWithUpcoming,
} from '@toggle/toggle';
import {
  differenceInDays,
  endOfMonth,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfMonth,
  subMonths,
} from 'date-fns';

import { PlanCardType } from '../plan-matrix/utils/plan-matrix.types';

export const formatTimeLeft = (date?: string) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return undefined;
  }

  return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
};

export const mapPlanCardToUserRole: Partial<Record<PlanCardType, UserRole>> = {
  [PlanCardType.Basic]: UserRole.Basic,
  [PlanCardType.Pro]: UserRole.Pro,
};

export const getProPrice = (
  subscriptions: Subscription[] | null,
  defaultPrice = 100
) => {
  const proSubscription = subscriptions?.find(s => s.role === UserRole.Pro);
  const yearlyPricePerMonth = getYearlyPricePerMonth(proSubscription);
  return yearlyPricePerMonth ? yearlyPricePerMonth : defaultPrice;
};

export const getYearlyPricePerMonth = (subscription?: Subscription) => {
  const yearlyPrice = subscription?.prices.find(
    p => p.billing_cycle === BillingCycle.year
  )?.price;

  return yearlyPrice ? yearlyPrice / 12 : 0;
};

interface FormatNextBillingDateProps {
  userSubscription: UserSubscription;
  startingOn?: string;
  fallback?: string;
  dateFormat?: string;
}

export const formatNextBillingDate = ({
  userSubscription,
  startingOn,
  fallback = 'N/A',
  dateFormat = 'd LLL y',
}: FormatNextBillingDateProps) => {
  if (startingOn) {
    return format(new Date(startingOn), dateFormat);
  }

  const nextPayment =
    userSubscription.next_payment ??
    userSubscription.upcoming_subscription?.next_payment;
  return nextPayment ? format(new Date(nextPayment), dateFormat) : fallback;
};

export const isSubscriptionCancelled = (
  { role, upcoming_subscription }: UserSubscription,
  card: BankCard | null,
  address?: UserAddress
) =>
  (!!card || !!address) &&
  role !== UserRole.Basic &&
  !!upcoming_subscription &&
  upcoming_subscription.name === SubscriptionName.Basic;

export const isSubscribedToPaidPlan = ({
  role,
  upcoming_subscription,
  trial,
}: UserSubscription) =>
  (role !== UserRole.Basic && !trial && !upcoming_subscription) ||
  (upcoming_subscription &&
    upcoming_subscription.name !== SubscriptionName.Basic);

const TRIAL_ENDS_BEFORE_IN_DAYS = 5;

export const isTrialEnding = (trialTo?: string) => {
  if (!trialTo) {
    return false;
  }

  const today = new Date();
  const until = new Date(trialTo);
  const isValid = isBefore(today, until);
  const diff = differenceInDays(until, today);
  return isValid && diff <= TRIAL_ENDS_BEFORE_IN_DAYS;
};

export const isSubscribedWhileOnTrial = (
  userSubscription: UserSubscription | UserSubscriptionWithUpcoming
): userSubscription is UserSubscriptionWithUpcoming =>
  userSubscription.trial &&
  !!userSubscription.upcoming_subscription &&
  [SubscriptionName.Pro].includes(userSubscription.upcoming_subscription.name);

export const isBillingFrequencyChanged = (
  userSubscription: UserSubscription | UserSubscriptionWithUpcoming
): userSubscription is UserSubscriptionWithUpcoming =>
  !userSubscription.trial &&
  !!userSubscription.upcoming_subscription &&
  userSubscription.upcoming_subscription.name === userSubscription.name &&
  userSubscription.upcoming_subscription.billing_cycle !==
    userSubscription.billing_cycle;

const ABOUT_TO_EXPIRE_ADDITIONAL_MONTHS = 1;

export const checkCardExpiry = ({
  expiry_year: expiryYear,
  expiry_month: expiryMonth,
}: BankCard) => {
  const today = new Date();
  const expiryMonthIndex = expiryMonth - 1;
  const expiryDate = new Date(expiryYear, expiryMonthIndex);

  const expiryPeriodStarts = subMonths(
    startOfMonth(expiryDate),
    ABOUT_TO_EXPIRE_ADDITIONAL_MONTHS
  );
  const expiryPeriodEnds = endOfMonth(expiryDate);

  const isAboutToExpire = isWithinInterval(today, {
    start: expiryPeriodStarts,
    end: expiryPeriodEnds,
  });

  const isCardExpired = isAfter(today, expiryPeriodEnds);

  return {
    expiryDate: expiryPeriodEnds,
    isAboutToExpire,
    isCardExpired,
  };
};

export const getSelectedPriceForLocation = (
  userSubscription: UserSubscription,
  subscriptions?: Subscription[] | null
) => {
  const subscriptionName =
    userSubscription.upcoming_subscription?.name ?? userSubscription.name;
  const billingCycle =
    userSubscription.upcoming_subscription?.billing_cycle ??
    userSubscription.billing_cycle;
  const subscription = subscriptions?.find(s => s.name === subscriptionName);

  return !subscription || subscriptionName === SubscriptionName.Basic
    ? undefined
    : subscription.prices.find(p => p.billing_cycle === billingCycle);
};
