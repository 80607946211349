import React, { CSSProperties, ReactNode, RefObject } from 'react';

import { StyledComponent } from '~/common/styled-component';

import type { DropdownProps, SelectTrigger } from '../Dropdown';
import { DropdownListOption } from '../dropdown-list-option/DropdownListOption';
import * as S from './DropdownList.styles';

export interface DropdownListProps<T> {
  focusedItemIndex: number;
  activeItemIndex?: number;
  dropdownItems: T[];
  listItemRef?: RefObject<HTMLLIElement | null>;
  focusedItemClassName?: string;
  shouldDisableItemMouseEvents: boolean;
  setListRef?: (node: HTMLUListElement | null) => void;
  currentAction: 'hover' | 'focus';
  onItemSelect: (item: T, idx: number, trigger: SelectTrigger) => void;
  renderDropdownItem: DropdownProps<T>['renderDropdownItem'];
  onItemMouseLeave?: () => void;
  onItemMouseEnter?: (idx: number) => void;
  hasV2Styles?: boolean;
  beforeNode?: ReactNode;
  afterNode?: ReactNode;
  style?: CSSProperties;
}

export type DropdownGenericItem<T> = {
  disabled?: boolean;
  separated?: boolean;
} & T;

export const DropdownList: StyledComponent<DropdownListProps<any>, typeof S> = <
  T extends DropdownGenericItem<{}>
>({
  dropdownItems,
  focusedItemIndex,
  activeItemIndex,
  currentAction,
  renderDropdownItem,
  onItemSelect,
  onItemMouseLeave,
  onItemMouseEnter,
  listItemRef,
  focusedItemClassName,
  shouldDisableItemMouseEvents,
  setListRef,
  hasV2Styles,
  beforeNode,
  afterNode,
  style,
}: DropdownListProps<T>) => {
  if (!dropdownItems?.length && !afterNode) {
    return null;
  }

  return (
    <S.DropdownListRoot
      ref={setListRef}
      id="dropdown-list"
      role="listbox"
      data-testid="dropdown-list"
      $hasV2Styles={hasV2Styles}
      style={style}
    >
      {beforeNode}
      {dropdownItems.map((item, idx) => (
        <DropdownListOption
          key={idx}
          isDisabled={!!item.disabled}
          isFocused={
            currentAction === 'focus' &&
            idx === focusedItemIndex &&
            !item.disabled
          }
          isActive={activeItemIndex !== undefined && idx === activeItemIndex}
          item={item}
          onClick={() => onItemSelect(item, idx, 'click')}
          listItemRef={listItemRef}
          onMouseLeave={
            shouldDisableItemMouseEvents ? undefined : onItemMouseLeave
          }
          onMouseEnter={
            shouldDisableItemMouseEvents
              ? undefined
              : () => onItemMouseEnter?.(idx)
          }
          renderDropdownItem={renderDropdownItem}
          focusedItemClassName={focusedItemClassName}
          hasV2Styles={hasV2Styles}
        />
      ))}
      {afterNode}
    </S.DropdownListRoot>
  );
};

DropdownList.Styled = S;
