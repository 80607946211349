import { SkeletonLoader } from '@toggle/design-system';
import React from 'react';

import * as S from './NewsThumbnailSkeleton.styles';

export const NewsThumbnailSkeleton = () => (
  <S.NewsThumbnailSkeletonRoot data-testid="news-thumbnail-skeleton">
    <SkeletonLoader
      areas={[
        {
          styles: {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
          },
        },
        {
          styles: {
            width: '114px',
            height: '16px',
            borderRadius: '8px',
          },
        },
      ]}
    />
    <SkeletonLoader
      areas={[
        {
          styles: {
            width: '100%',
            height: '24px',
            borderRadius: '8px',
          },
        },
      ]}
    />
  </S.NewsThumbnailSkeletonRoot>
);
