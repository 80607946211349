import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetSearchModal } from '~/components/asset-search-modal/AssetSearchModal';
import { useAssetsFilterEntityTags } from '~/hooks/use-assets-filter-entity-tags/useAssetsFilterEntityTags';
import { ScenarioAssistantModal } from '~/widgets/scenario/components/scenario-assistant-modal/ScenarioAssistantModal';
import { useScenarioAssistant } from '~/widgets/scenario/components/scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';
import { ScenarioConditions } from '~/widgets/scenario/components/scenario-conditions/ScenarioConditions';
import {
  ScenarioPageModalType,
  useScenarioStore,
} from '~/widgets/scenario/hooks/use-scenario-store/useScenarioStore';

import { ClearAllButton } from './header/clear-all-button/ClearAllButton';
import * as S from './Scenario.styles';

export const Scenario = () => {
  const { t } = useTranslation([
    'widget',
    'chart',
    'scenario',
    'screener',
    'scenarioCommander',
    'countries',
    'analyze',
  ]);
  const [scenarioAssistantConditionId, setScenarioAssistantConditionId] =
    useState<string | undefined>();

  const {
    activeScenarioPageModal,
    filterGroups,
    scenarioPageEntityTags,
    scenarioPageSearchResultsLimit,
    setActiveScenarioPageModal,
    setScenarioPageEntityTags,
  } = useScenarioStore(state => ({
    activeScenarioPageModal: state.activeScenarioPageModal,
    filterGroups: state.filterGroups,
    scenarioPageEntityTags: state.scenarioPageEntityTags,
    scenarioPageSearchResultsLimit: state.scenarioPageSearchResultsLimit,
    setActiveScenarioPageModal: state.setActiveScenarioPageModal,
    setScenarioPageEntityTags: state.setScenarioPageEntityTags,
  }));

  const { data: scenarioEntityTags } = useAssetsFilterEntityTags(filterGroups);

  const memoScenarioEntityTags = useMemo(
    () => scenarioEntityTags,
    [JSON.stringify(scenarioEntityTags)]
  );
  useEffect(() => {
    const uniqueTags = memoScenarioEntityTags.filter(
      tag => !scenarioPageEntityTags.tagsAddedViaSearch.includes(tag)
    );
    setScenarioPageEntityTags({
      tagsAddedViaFilters: uniqueTags,
    });
  }, [
    memoScenarioEntityTags,
    JSON.stringify(scenarioPageEntityTags.tagsAddedViaSearch),
  ]);

  const changeEditFilter = (conditionId: string) => {
    if (conditionId) {
      setScenarioAssistantConditionId(conditionId);
      setActiveScenarioPageModal(ScenarioPageModalType.addCondition);
    }
  };

  const resetAskedQuestions = useScenarioAssistant(
    state => state.resetAskedQuestions
  );

  const displayedEntitiesTags = [
    ...(scenarioPageEntityTags.tagsAddedViaFilters ?? []),
    ...(scenarioPageEntityTags.tagsAddedViaSearch ?? []),
  ].sort((a, b) => a.localeCompare(b));

  return (
    <S.Root>
      <S.HeaderWrapper>
        <S.StyledTitle>{t('scenario:scenario')}</S.StyledTitle>
        <ClearAllButton />
      </S.HeaderWrapper>
      <ScenarioConditions
        entitiesTags={displayedEntitiesTags}
        noAssetsButtonLabel={t('scenario:emptyState.addAssets')}
        onEditAssets={() =>
          setActiveScenarioPageModal(ScenarioPageModalType.addAssets)
        }
        onAddConditions={() => {
          resetAskedQuestions();
          setActiveScenarioPageModal(ScenarioPageModalType.addCondition);
        }}
        onEditAssistantCondition={changeEditFilter}
      />
      <ScenarioAssistantModal
        editFilterKey={scenarioAssistantConditionId}
        isOpen={activeScenarioPageModal === ScenarioPageModalType.addCondition}
        onClose={() => {
          setScenarioAssistantConditionId(undefined);
          setActiveScenarioPageModal();
        }}
        resetEditFilterKey={() => {
          setScenarioAssistantConditionId(undefined);
        }}
      />
      {activeScenarioPageModal === ScenarioPageModalType.addAssets && (
        <AssetSearchModal
          isOpen
          tagsAddedViaFilters={scenarioPageEntityTags.tagsAddedViaFilters}
          tagsAddedViaSearch={scenarioPageEntityTags.tagsAddedViaSearch}
          pageSearchResultsLimit={scenarioPageSearchResultsLimit}
          onClose={() => setActiveScenarioPageModal()}
          setPageEntityTags={setScenarioPageEntityTags}
        />
      )}
    </S.Root>
  );
};
