import {
  ChartColor,
  ChartPane,
  hasOHLC,
  PriceHover,
  SeriesType,
} from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import React from 'react';

import { LegendPosition } from '~/modules/turbo-chart/components/legend-position/LegendPosition';

import { ChartAreaOverlayProps } from '../chart-area-overlay/ChartAreaOverlay';
import { LegendItem } from '../legend-item/LegendItem';

export interface LegendProps
  extends Pick<
    ChartAreaOverlayProps,
    | 'chartPaneActions'
    | 'hideActiveAsset'
    | 'changeChartLineColor'
    | 'changeSeriesType'
  > {
  pane: ChartPane;
  lineColorArray?: ChartColor[];
  removeAsset: (defaultSnake: string, paneIndex: number) => void;
  onChangeTicker?: (entity: Entity, paneIndex: number) => void;
  onChangeIndicator?: (entity: Entity) => void;
  selectedSeriesType: SeriesType;
  paneSize: number;
  paneIndex: number;
  priceHover?: PriceHover;
  legendLogoSize?: number;
  hasHover?: boolean;
}

export const Legend = ({
  pane,
  lineColorArray = [],
  removeAsset,
  onChangeTicker,
  hideActiveAsset,
  changeChartLineColor,
  changeSeriesType,
  selectedSeriesType,
  paneIndex,
  paneSize,
  onChangeIndicator,
  chartPaneActions,
  priceHover,
  legendLogoSize = 20,
  hasHover = true,
}: LegendProps) => {
  const chartAssetData = pane.chartAssetData;
  const priceHoverData = pane.collapsed
    ? undefined
    : priceHover?.panes.find(p => p.id === pane.id)?.data;
  const colorArray = lineColorArray.map(c => c.value);

  return (
    <LegendPosition pane={pane} paneIndex={paneIndex}>
      {chartAssetData.map((data, i) => (
        <LegendItem
          key={`${data.entity.default_snake}-${i}`}
          changeChartLineColor={id =>
            changeChartLineColor(id, {
              snake: data.entity.default_snake,
              paneIndex,
            })
          }
          changeSeriesType={changeSeriesType}
          chartAssetData={data}
          chartPaneActions={chartPaneActions}
          colorArray={colorArray}
          hasOHLC={hasOHLC(data)}
          lastPaneIndex={paneSize - 1}
          multiplePlotted={chartAssetData.length > 1}
          onChangeIndicator={onChangeIndicator}
          onChangeTicker={onChangeTicker}
          onHideAsset={(entity, isHidden) =>
            hideActiveAsset(entity.default_snake, { isHidden, paneIndex })
          }
          onRemoveItem={snake => removeAsset(snake, paneIndex)}
          paneIndex={paneIndex}
          selectedSeriesType={data.seriesType ?? selectedSeriesType}
          hoverData={priceHoverData?.[i]}
          legendLogoSize={legendLogoSize}
          hasHover={hasHover}
        />
      ))}
    </LegendPosition>
  );
};
