import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const ButtonLinkRoot = styled.button`
  ${borderRadius.r1};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  color: var(--text-primary);
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid var(--border-focus);
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
    cursor: default;
    color: var(--text-disabled);

    svg {
      fill: var(--icon-disabled);
    }
  }

  svg {
    fill: var(--icon-primary);
  }
`;

export const Label = styled.span<{ $isReversed: boolean }>`
  ${typographyV2.uiSm};
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: ${({ $isReversed }) => ($isReversed ? 'row-reverse' : 'row')};
`;
