import { deleteCookie, queryString, setCookie } from '@toggle/helpers';
import { AuthDataPayload } from '@toggle/toggle';
import { differenceInHours } from 'date-fns';
import { useLocation, useNavigate } from 'react-router';

import { useThemeStore } from '~/components/theme-controller/stores/useThemeStore';
import { config } from '~/config';
import { useLegalDocuments } from '~/hooks/use-legal-documents/useLegalDocuments';
import { useRemoteStorage } from '~/hooks/use-remote-storage';
import { appPaths, authPaths, queryKeys } from '~/routes/app-paths';
import { usePromoCodeStore } from '~/stores/use-promo-code/usePromoCodeStore';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useUser } from '~/stores/use-user/useUser';
import { ApiFetchResponse } from '~/utils/api-fetch/apiFetch';
import { httpCode } from '~/utils/api-fetch/httpCode';
import {
  getAuthToken,
  getRefreshToken,
  postLogin,
  postLogout,
  postRegister,
} from '~/views/auth/services/auth-services';
import { useNewsFiltersStore } from '~/views/news/stores/use-news-filters-store/useNewsFiltersStore';
import { useNewsSearchStore } from '~/views/news/stores/use-news-search-store/useNewsSearchStore';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';

import { resetStores } from '../create-store/createStore';
import { useApp } from '../use-app/useApp';
import { useWatchlist } from '../use-watchlist/useWatchlist';
import { useAuthStore } from './store/authStore';

const { appSessionCookieAuth } = config;

export interface SignUpProps {
  email: string;
  password: string;
  isProfessional: boolean;
  referralCode?: string;
  promoCode?: string;
  termsAccepted?: string[];
}

interface UseAuth {
  email?: string;
  initStores: (session: AuthDataPayload) => Promise<void>;
  signUp: (user: SignUpProps) => Promise<ApiFetchResponse<AuthDataPayload>>;
  logout: (noQuery?: boolean) => Promise<void>;
  authProcessing: boolean;
  setAuthProcessing: (authProcessing: boolean) => void;
  unLockAccount: () => void;
  isLocked: boolean;
  isAuthenticated: boolean;
  isInitError: boolean;
  isInitSignUp: boolean;
}

const dueToExpire = (timestamp: Date): boolean => {
  const HOURS_BEFORE_RENEW = 48;
  return differenceInHours(timestamp, new Date()) < HOURS_BEFORE_RENEW;
};

export const getSession = async (): Promise<AuthDataPayload | undefined> => {
  const session = await getAuthToken();

  if (session.error) {
    return;
  }

  if (dueToExpire(new Date(session.data.expiry))) {
    const newSession = await getRefreshToken(session.data.token);

    if (!newSession.error) {
      return newSession.data;
    }
  }

  return session.data;
};

export const useAuth = (): UseAuth => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    email,
    resetAuthStore,
    updateAuthStore,
    authProcessing,
    setAuthProcessing,
    unLockAccount,
    isLocked,
    isAuthenticated,
    isInitError,
    isInitSignUp,
    setIsInitError,
  } = useAuthStore(state => ({
    email: state.email,
    unLockAccount: state.unlock,
    resetAuthStore: state.destroy,
    updateAuthStore: state.update,
    isLocked: state.isLocked,
    authProcessing: state.authProcessing,
    setAuthProcessing: state.setAuthProcessing,
    isAuthenticated: !!state.token,
    isInitError: state.isInitError,
    isInitSignUp: state.isInitSignUp,
    setIsInitError: state.setIsInitError,
  }));

  const initializeUser = useUser(state => state.initialize);
  const initializeSubscription = useSubscription(state => state.initialize);
  const initializeRemoteStorage = useRemoteStorage(state => state.initialize);

  const initializePromoCodeStore = usePromoCodeStore(state => state.initialize);
  const initializeAppStore = useApp(state => state.initialize);
  const initWatchlist = useWatchlist(state => state.getLists);
  const initScreenerFiltersStore = useScreenerStore(state => state.initStore);
  const initNewsFiltersStore = useNewsFiltersStore(state => state.initStore);
  const initNewsSearchStore = useNewsSearchStore(state => state.initStore);
  const initThemeStore = useThemeStore(state => state.initStore);

  const getDocsPendingConsent = useLegalDocuments(
    state => state.getDocsPendingConsent
  );

  const initStores = async (
    session: AuthDataPayload,
    isInitSignUp?: boolean
  ) => {
    setAuthProcessing(true);
    updateAuthStore(session, !!isInitSignUp);
    const userResponse = await initializeUser();
    setAppSessionCookie();
    const responses = await Promise.all([
      initializeRemoteStorage().then(() => initializeAppStore()),
      initializeSubscription(),
      initializePromoCodeStore(),
      getDocsPendingConsent(),
    ]);
    await initWatchlist();
    await initScreenerFiltersStore();
    await initNewsFiltersStore();
    await initNewsSearchStore();
    initThemeStore();

    const truthyResponses = [userResponse, ...responses].filter(Boolean);

    const unHandledError = (r: typeof truthyResponses[0]) =>
      r?.error && r.error.status !== httpCode.locked;
    const isResponsesError = truthyResponses.some(unHandledError);

    if (isResponsesError) {
      setIsInitError(true);
      navigate(appPaths.error);
    }

    setAuthProcessing(false);
  };

  const setAppSessionCookie = () => {
    setCookie(appSessionCookieAuth, undefined, { expires: 365 });
  };

  const signUp = async (userDetails: SignUpProps) => {
    const registered = await postRegister(userDetails);

    if (registered.error) {
      return registered;
    }

    const session = await postLogin({
      username: userDetails.email,
      password: userDetails.password,
    });

    if (session.error) {
      return session;
    }

    await initStores(session.data, true);
    return session;
  };

  const logout = async (noQuery = false) => {
    deleteCookie(appSessionCookieAuth);
    resetStores();
    resetAuthStore();

    const clearRedirects = [appPaths.alfred].some(
      path => path === location.pathname
    );

    const queryStr =
      noQuery || clearRedirects
        ? ''
        : queryString({
            [queryKeys.redirect]: `${location.pathname}${location.search}`,
          });

    navigate(authPaths.login + queryStr);
    await postLogout();
  };

  return {
    email,
    isInitError,
    isInitSignUp,
    isAuthenticated,
    isLocked,
    unLockAccount,
    signUp,
    logout,
    initStores,
    authProcessing,
    setAuthProcessing,
  };
};
