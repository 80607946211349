import { Modal, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Label = styled.p`
  ${typographyV2.bodyBase}
`;

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    max-width: 80%;
    width: 540px;
  }
`;
