import { v4 } from 'uuid';

import { Message, MessageReply, Source } from '../use-chat.types';

interface UpdateMessageProps {
  messages: Message[];
  message: Partial<Message>;
}

export const createNewMessage = (
  message: Partial<Message>,
  target?: Source | 'all',
  documents?: string[]
): Message => {
  const {
    id = v4(),
    question = '',
    status = 'pending',
    reply = [],
    hasSeen = false,
    type = 'alfred',
  } = message;

  // WORKAROUND FOR DOCUMENT ASSISTANT API, TO KEEP UNTIL API IS FIXED
  // REMOVED APPENDED "[[DOCUMENT_NAME]]" TO QUESTION
  const parsedQuestion =
    target === 'document-assistant' ? question.split('[[')[0] : question;

  return {
    id,
    question: parsedQuestion,
    status,
    reply,
    hasSeen,
    type,
    target,
    documents,
  };
};

export const updateMessage = ({
  messages,
  message,
}: UpdateMessageProps): Message[] => {
  const existingMessageIndex = messages.findIndex(m => m.id === message.id);
  const updatedMessages = [...messages];
  updatedMessages[existingMessageIndex] = {
    ...updatedMessages[existingMessageIndex],
    ...message,
  };
  return updatedMessages;
};

export const appendReply = (
  messages: Message[],
  { id, reply }: { id: string; reply: MessageReply[] }
): Message[] => {
  return messages?.map(message => {
    if (message.id === id) {
      return { ...message, reply: [...message.reply, ...reply] };
    }

    return message;
  });
};
