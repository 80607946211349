import {
  ChartAPIReturn,
  EventsConfig,
  INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
  InsightsInDomain,
  InsightsInDomainDetail,
  InsightsInDomainEvent,
} from '@toggle/chart';
import { Insight } from '@toggle/toggle';
import { RefObject, useEffect, useState } from 'react';

import { ActiveInsightsGroup } from '../../components/insights/insights.types';
import { mapArticleInsight } from '../../components/insights/utils/inisights-utils/insights-utils';

interface UseInsightsInDomainProps {
  canvasRef: RefObject<HTMLCanvasElement | null>;
  eventsConfig: EventsConfig;
  chartApiRef: RefObject<ChartAPIReturn | null>;
  articleInsights: Insight[];
}

export const useInsightsInDomain = ({
  canvasRef,
  eventsConfig,
  chartApiRef,
  articleInsights,
}: UseInsightsInDomainProps) => {
  const [insightsInDomain, setInsightsInDomain] = useState<InsightsInDomain[]>(
    []
  );
  const [activeInsightsGroup, setActiveInsightsGroup] =
    useState<ActiveInsightsGroup | null>(null);
  const [zoomIntoViewText, setZoomIntoViewText] =
    useState<InsightsInDomainDetail['helperText']>(null);

  const handleSetActiveInsightsGroup = (
    insightGroup: ActiveInsightsGroup | null
  ) => {
    setActiveInsightsGroup(insightGroup);
  };

  useEffect(() => {
    const canvasElement = canvasRef.current;

    const insightsInDomainListener = (event: Event) => {
      const detail = (event as InsightsInDomainEvent).detail;

      if (detail.insightsInDomain) {
        setInsightsInDomain(detail.insightsInDomain);
      }

      setActiveInsightsGroup(null);
      setZoomIntoViewText(detail.helperText);
    };

    if (canvasElement) {
      canvasElement.addEventListener(
        INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
        insightsInDomainListener
      );
    }

    return () => {
      canvasElement?.removeEventListener(
        INSIGHTS_IN_DOMAIN_CHANGE_CUSTOM_EVENT,
        insightsInDomainListener
      );
    };
  }, [eventsConfig]);

  useEffect(() => {
    if (chartApiRef.current) {
      const mappedArticleInsights = eventsConfig.TMI
        ? articleInsights.map(mapArticleInsight)
        : [];

      chartApiRef.current.displayInsights(mappedArticleInsights);
    }
  }, [eventsConfig, articleInsights]);

  return {
    insightsInDomain,
    activeInsightsGroup,
    zoomIntoViewText,
    handleSetActiveInsightsGroup,
  };
};
