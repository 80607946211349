import { ScreenerFilter } from '@toggle/toggle';

export const datePickerFilter: ScreenerFilter = {
  key: 'date-picker',
  name: 'Date range',
  visible: true,
  searchable: false,
  category: '',
  locked: true,
  dynamic: false,
  config: {
    multiselect: false,
    name: 'Date range',
    options: [
      {
        name: 'Today',
        key: 'today',
        value: {
          from: '',
          to: '',
        },
      },
      {
        name: 'Yesterday',
        key: 'yesterday',
        value: {
          from: '',
          to: '',
        },
      },
    ],
    custom: {
      key: 'date-range',
      name: 'Datepicker',
      custom: [],
    },
  },
};
