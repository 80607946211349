import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Text = styled.text<{ $hasProfit?: boolean }>`
  ${typographyV2.bodySm};
  fill: ${props =>
    props.$hasProfit ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};
`;

export const Rect = styled.rect<{ $hasProfit?: boolean }>`
  fill: ${props =>
    props.$hasProfit ? 'var(--viz-bullish-soft)' : 'var(--viz-bearish-soft)'};
`;
