import { Alert, AlertType } from '@toggle/design-system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  createKGFilterOptions,
  KNOWLEDGE_GRAPH_SUCCESS_TOAST,
} from '~/modules/overview-knowledge-graph/utils/knowledge-graph-utils';
import { appPaths } from '~/routes/app-paths';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';
import { useScreenerActions } from '~/views/screener/use-screener-actions/useScreenerActions';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';

import { useFilterActions } from '../use-filter-actions/useFilterActions';

export const useScreenerDropdownItems = () => {
  const { t } = useTranslation();
  const { createNewDraft, navigateToScreener } = useScreenerActions();
  const { showToast, removeToast } = useToasts();
  const { activeSavedScreen } = useScreenerStore();
  const { addFilterOption } = useFilterActions({ store: useScreenerStore });

  const navigate = useNavigate();

  const addToCurrentScreenItem = ({
    name,
    id,
    key,
  }: {
    name: string;
    id: string;
    key: string;
  }) => {
    return {
      label: t('widget:assetOverview.addToCurrentScreen'),
      onClick: () => {
        addFilterOption(
          createKGFilterOptions({ id, name, key }),
          'Knowledge Graph Filters'
        );
        showToast({
          id: KNOWLEDGE_GRAPH_SUCCESS_TOAST,
          hideToast: true,
          position: ToastPosition.TopRight,
          content: (
            <Alert
              withIcon
              onClose={() => removeToast(KNOWLEDGE_GRAPH_SUCCESS_TOAST)}
              type={AlertType.Success}
              title={t('widget:assetOverview.knowledgeGraphToast.title', {
                screenerName:
                  activeSavedScreen?.name ?? t('screener:newScreen'),
              })}
              message={
                <Trans
                  t={t}
                  i18nKey="widget:assetOverview.knowledgeGraphToast.message"
                  components={[
                    <span
                      style={{ textTransform: 'capitalize' }}
                      key="key-0"
                    />,
                  ]}
                  values={{
                    name,
                  }}
                />
              }
              primaryBtn={{
                label: t('widget:assetOverview.knowledgeGraphToast.button'),
                onClick: () => {
                  if (activeSavedScreen?.id) {
                    navigateToScreener(
                      activeSavedScreen.id,
                      activeSavedScreen.name
                    );
                  } else {
                    navigate(appPaths.screener);
                  }
                },
              }}
            />
          ),
        });
      },
    };
  };

  const createNewScreenItem = ({
    name,
    id,
    key,
  }: {
    name: string;
    id: string;
    key: string;
  }) => {
    return {
      label: t('widget:assetOverview.createNewScreen'),
      onClick: () => {
        createNewDraft({
          option: createKGFilterOptions({ id, name, key }),
        });
      },
    };
  };

  return {
    addToCurrentScreenItem,
    createNewScreenItem,
  };
};
