import { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';
import { RangeHorizon, rangeToResampleMapping } from '@toggle/chart';
import { Badge } from '@toggle/design-system';
import {
  Entity,
  formatTicker,
  isMappedEntity,
  MappedEntity,
  SnakeMeta,
} from '@toggle/toggle';
import React, { forwardRef, MouseEvent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';
import { useChartNavigate } from '~/hooks/use-chart-navigate/useChartNavigate';
import { ChartPrice } from '~/modules/simple-chart/components/chart-price/ChartPrice';
import { SimpleChart } from '~/modules/simple-chart/SimpleChart';
import { ChartSearchParamsAssetValue } from '~/utils/chart-search-utils/chart-search-utils';
import { ChartSettings } from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import * as S from './ChartPreview.styles';

export interface ChartPreviewProps extends DropdownMenuContentProps {
  entity: Entity | MappedEntity;
  horizon: RangeHorizon;
  onMouseLeave: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  enableHover?: boolean;
  snakeMeta?: SnakeMeta;
}

export const ChartPreview = forwardRef<HTMLDivElement, ChartPreviewProps>(
  (
    {
      entity,
      horizon,
      onMouseLeave,
      onMouseEnter,
      enableHover = true,
      snakeMeta,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation(['chart', 'analyze']);
    const chartNavigate = useChartNavigate();
    const formattedTicker = formatTicker(entity);
    const entityName = entity.name_short || entity.name_full;
    const showLivePrice = !!snakeMeta && !isMappedEntity(entity);

    const onClick = (e: MouseEvent) => {
      e.stopPropagation();

      if ((e.target as HTMLElement).tagName !== 'CANVAS') {
        return;
      }

      const entityValue: ChartSearchParamsAssetValue = [
        entity.tag,
        entity.default_snake,
        0,
      ];
      chartNavigate([entityValue], { horizon });
    };

    const initialChartSettings: ChartSettings = {
      assets: [
        {
          tag: entity.tag,
          snake: entity.default_snake,
          paneIndex: 0,
        },
      ],
      resample: rangeToResampleMapping[horizon],
      horizon,
      domain: null,
      hidden: undefined,
      paneSettings: {},
    };

    return (
      <S.Content
        onClick={onClick}
        side="right"
        align="start"
        sideOffset={-10}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        data-testid="chart-preview"
        ref={ref}
        {...rest}
      >
        <S.StyledEntityDetails
          entityNameComponent={
            <TruncateTooltip
              placement="top"
              atLength={20}
              text={entityName}
              data-testid="analyze-header-title"
            />
          }
          formattedTicker={formattedTicker}
          leftImgNode={<AssetLogoContainer entity={entity} />}
        />
        {showLivePrice && (
          <ChartPrice
            entity={entity}
            t={t}
            snakeMeta={snakeMeta}
            enablePriceHover={enableHover}
          />
        )}
        <S.ChartWrapper>
          <S.HorizonLabel>
            <Badge
              color="neutral"
              size="small"
              badgeStyle="outline"
              label={
                /** i18next-extract-mark-context-next-line ["OneMonth"] */
                t('chart:intervals.horizon', { context: horizon })
              }
            />
          </S.HorizonLabel>
          <SimpleChart
            initialChartSettings={initialChartSettings}
            shouldShowChartHeader={false}
            chartConfig={{ enableHover }}
          />
        </S.ChartWrapper>
      </S.Content>
    );
  }
);
