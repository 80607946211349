import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { httpCode } from '~/utils/api-fetch/httpCode';
import { postEmailValidation } from '~/views/auth/services/auth-services';

export const isValidEmail = (email: string) =>
  z.string().email().safeParse(email).success;

type ErrorCallback = (message?: string) => void;

export function useEmailValidation(errorCallback: ErrorCallback) {
  const { t } = useTranslation('auth');
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    errorCallback(validationError);
  }, [validationError]);

  const validate = async (query: string, skipAPIcall = false) => {
    if (!query) {
      setValidationError(t('auth:genericEmailEmptyError'));
      return false;
    }

    if (!isValidEmail(query)) {
      setValidationError(t('auth:genericEmailError'));
      return false;
    }

    if (!skipAPIcall) {
      const res = await postEmailValidation(query);

      if (res.error && res.error.status === httpCode.badRequest) {
        setValidationError(t('auth:notUniqueError'));
        return false;
      }
    }

    setValidationError('');
    return true;
  };

  const clearValidationError = () => setValidationError('');

  return {
    emailErrorText: validationError,
    clearEmailError: clearValidationError,
    validateEmail: validate,
  };
}
