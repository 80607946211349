import {
  ButtonGroup,
  SkeletonLoader,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

import { SectionTitle } from '~/components/section-title/SectionTitle';

export const SectionTitleWrapper = styled.div`
  ${SectionTitle.Styled.TitleWrapper} {
    margin-bottom: 0;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1;
  min-width: 134px;
  max-width: fit-content;
`;

export const SkeletonChartLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '350px',
        borderRadius: '8px',
      },
    },
  ],
})``;

export const Title = styled.p`
  display: flex;
  justify-content: space-between;
  ${typographyV2.titleBase};
  text-underline-offset: 4px;

  & span {
    font-family: var(--font-roboto-mono);
    text-align: right;
    color: var(--text-soft);
    ${typographyV2.bodySm}
    display: inline-block;
    padding-right: 50px;
  }
`;
