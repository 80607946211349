import { useNavigate } from 'react-router';

import { appPaths } from '~/routes/app-paths';
import {
  appendChartAssetParam,
  ChartSearchParamsAssetValue,
} from '~/utils/chart-search-utils/chart-search-utils';
import { ChartState } from '~/views/turbo-chart/hooks/use-chart-state/useChartState';
import {
  COMMANDER_OPEN_TURBO_CUSTOM_EVENT,
  CommanderOpenTurboEvent,
  PaneSettings,
} from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

export type ChartNavigateConfigParams = Partial<
  Omit<ChartState, 'chartPanes'> & PaneSettings
>;

export const useChartNavigate = () => {
  const navigate = useNavigate();

  const navigateToChart = (
    assets: ChartSearchParamsAssetValue[],
    configParams?: ChartNavigateConfigParams,
    isOnChartPage = false
  ) => {
    const searchParams = new URLSearchParams();

    assets.forEach(asset => {
      appendChartAssetParam(searchParams, asset);
    });
    configParams &&
      Object.keys(configParams).forEach(key => {
        const value = configParams[key as keyof ChartNavigateConfigParams];

        if (value) {
          searchParams.append(key, value.toString());
        }
      });
    navigate({
      pathname: appPaths.chart,
      search: searchParams.toString(),
    });

    if (isOnChartPage) {
      document.dispatchEvent(
        new CustomEvent(COMMANDER_OPEN_TURBO_CUSTOM_EVENT, {
          detail: {
            searchParams,
          },
        }) as CommanderOpenTurboEvent
      );
    }
  };

  return navigateToChart;
};
