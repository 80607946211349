import { StripeElementStyle } from '@stripe/stripe-js';
import { DesignToken, getThemeColorInHex } from '@toggle/design-system';

import { useThemeStore } from '~/components/theme-controller/stores/useThemeStore';

export const useStripeTheme = () => {
  const themeName = useThemeStore(state => state.themeName);

  const getThemeColor = (designToken: DesignToken) =>
    getThemeColorInHex(themeName, designToken);

  const stripeElementStyle: StripeElementStyle = {
    base: {
      color: getThemeColor('--text-default'),
      fontFamily: 'nort, sans-serif, Arial, sans-serif',
      fontSize: '16px',
      iconColor: getThemeColor('--icon-default'),
      backgroundColor: 'transparent',
      '::placeholder': {
        color: getThemeColor('--text-disabled'),
      },
      ':-webkit-autofill': {
        color: getThemeColor('--text-disabled'),
      },
    },
    invalid: {
      color: getThemeColor('--border-critical'),
      iconColor: getThemeColor('--icon-critical'),
    },
  };

  return stripeElementStyle;
};
