import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { Icon } from '@toggle/design-system';
import { ScreenerFilter, WatchList } from '@toggle/toggle';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import * as StylesFilter from '~/components/filter/Filter.styles';
import { FilterLabel } from '~/components/filter/FilterLabel';
import { Option } from '~/components/filter/Option';
import {
  FilterOption,
  ToggleFilterProps,
} from '~/components/update-filter-options/updateFilterOptions';
import {
  INDEX_ID,
  SCREENER_ID,
  WATCHLIST_ID,
  WORKING_LIST_ID,
} from '~/config/constants';
import { useQueryParams } from '~/hooks/use-query-params/useQueryParams';
import { useWorkingList } from '~/hooks/use-working-list/useWorkingList';
import { appPaths } from '~/routes/app-paths';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { isFilterWithChildOptionActive } from '~/views/screener/use-screener-store/filter-utils';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';
import { widgetPaths } from '~/views/widgets/widget-utils';

import {
  AssetsFilterOption,
  mapActiveFilterOptions,
  mapToFilterOptions,
} from '../utils';

interface FilterAssetsProps {
  filter: ScreenerFilter;
  filterOptions: FilterOption[];
  publicLists: WatchList[];
  withScreeners?: boolean;
  addFilterOption: (props: ToggleFilterProps) => void;
}

export const FilterAssets = ({
  filter,
  filterOptions,
  publicLists,
  withScreeners = true,
  addFilterOption,
}: FilterAssetsProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const watchLists = useWatchlist(store => store.lists);
  const savedScreens = useScreenerStore(state => state.savedScreens);
  const hasWorkingList = useWorkingList(store => !!store.workingList.length);
  const { setQueryParams } = useQueryParams();

  const [open, setOpen] = useState(false);

  const isChecked = (optKey: string) =>
    mappedActiveOptions.some(f => f.key === optKey);

  const onFilterSelect = (props: ToggleFilterProps, active: boolean) => {
    if (!active) {
      addFilterOption(props);
    }
  };

  const workingListOption = hasWorkingList
    ? [
        {
          name: t('common:filters.workingList'),
          key: WORKING_LIST_ID,
          value: { workingList: 'working-list' },
          hasBottomBorder: true,
          customHeaderTitle: undefined,
        } as AssetsFilterOption,
      ]
    : [];

  const watchlistOptions = useMemo(
    () =>
      mapToFilterOptions({
        items: watchLists,
        valueKey: WATCHLIST_ID,
        customHeaderTitle: t('common:filters.watchlist'),
        hasBottomBorder: true,
        withDefaultOption: true,
        customOptionLabel: t('common:filters.createWatchlist'),
        customOptionCallback: () =>
          setQueryParams({ widget: widgetPaths.watchlistManagerModal }),
      }),
    [watchLists]
  );

  const screenOptions = useMemo(
    () =>
      withScreeners
        ? mapToFilterOptions({
            items: savedScreens,
            valueKey: SCREENER_ID,
            customHeaderTitle: t('common:filters.savedScreen'),
            hasBottomBorder: true,
            withDefaultOption: true,
            customOptionLabel: t('common:filters.createScreen'),
            customOptionCallback: () => navigate(appPaths.screener),
          })
        : [],
    [savedScreens, withScreeners]
  );
  const options = [
    ...workingListOption,
    ...watchlistOptions,
    ...screenOptions,
    ...mapToFilterOptions({
      items: publicLists,
      valueKey: INDEX_ID,
      customHeaderTitle: t('common:filters.index'),
      hasBottomBorder: true,
    }),
  ];

  const activeWorkingListOption = filterOptions.find(
    f => f.key === WORKING_LIST_ID
  );
  const mappedActiveOptions = [
    ...(activeWorkingListOption ? [activeWorkingListOption] : []),
    ...mapActiveFilterOptions(watchLists, WATCHLIST_ID, filterOptions),
    ...mapActiveFilterOptions(savedScreens, SCREENER_ID, filterOptions),
    ...mapActiveFilterOptions(publicLists, INDEX_ID, filterOptions),
  ];
  const isParentOptionActive = isFilterWithChildOptionActive(filterOptions);

  return (
    <RadixDropdownMenu.Root open={open} onOpenChange={setOpen}>
      <StylesFilter.Trigger
        data-filter={filter.key}
        data-testid="assets-filter"
        onClick={() => setOpen(true)}
        $active={isParentOptionActive}
      >
        <StylesFilter.TriggerLabel>{filter.name}</StylesFilter.TriggerLabel>
        <FilterLabel options={mappedActiveOptions} multiselect={false} />
        <StylesFilter.TriggerArrow iconName="ChevronLightDown" />
      </StylesFilter.Trigger>
      <RadixDropdownMenu.Portal>
        <StylesFilter.DropdownMenuContent
          align="start"
          sideOffset={5}
          collisionPadding={5}
        >
          <StylesFilter.ScrollableList>
            <StylesFilter.Separator />
            {options.map((option, idx) => {
              const customHeaderTitle = option.customHeaderTitle;
              const hasBottomBorder = option.hasBottomBorder;
              const checked = isChecked(option.key);

              return (
                <div key={option.key || `cta-option-${idx}`}>
                  {customHeaderTitle && (
                    <StylesFilter.CustomHeaderLabel>
                      {customHeaderTitle}
                    </StylesFilter.CustomHeaderLabel>
                  )}
                  {option.key ? (
                    <Option
                      key={option.key}
                      option={option}
                      isChecked={checked}
                      handleClick={() =>
                        onFilterSelect(
                          {
                            option,
                            filterKey: filter.key,
                            filterId: filter.key,
                          },
                          checked
                        )
                      }
                      multiselect={false}
                    />
                  ) : (
                    <StylesFilter.Item
                      data-testid="custom-option-item"
                      onClick={option.customOptionCallback}
                    >
                      <StylesFilter.MenuItemLeftIndicator />
                      <StylesFilter.ItemText>
                        <StylesFilter.CustomLabel>
                          <Icon iconName="Add" size={14} />
                          <span>{option.customOptionLabel}</span>
                        </StylesFilter.CustomLabel>
                      </StylesFilter.ItemText>
                    </StylesFilter.Item>
                  )}
                  {hasBottomBorder && <StylesFilter.CustomBottomBorder />}
                </div>
              );
            })}
          </StylesFilter.ScrollableList>
        </StylesFilter.DropdownMenuContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
