import {
  DisplayFormat,
  entities,
  entityReport,
  listSchema,
  MappedEntity,
  watchlist,
} from '@toggle/toggle';
import { WretchError } from 'wretch/resolver';
import { z } from 'zod';

import { ActionModal } from '~/components/actions-modal/ActionsModal';
import { ApiFetchResponse } from '~/utils/api-fetch/apiFetch';

export type LatestValues = z.infer<
  typeof entityReport.getEntityLatestValues.schema
>;
export type Watchlists = z.infer<typeof watchlist.getLists.schema>;

export type ReturnValue = {
  value: number;
  formattedValue: string;
};

export type EntityReturns = {
  rsi?: ReturnValue;
  return1D?: ReturnValue;
  return1W?: ReturnValue;
  return1M?: ReturnValue;
  return3M?: ReturnValue;
  return6M?: ReturnValue;
  return1Y?: ReturnValue;
  peTrailing?: ReturnValue;
  peForward?: ReturnValue;
};

export type WatchlistEntities = {
  tag: string;
  asset?: MappedEntity;
  returns?: EntityReturns;
  prices?: {
    last_value: number;
    before_last_value: number;
  };
  displayFormat?: DisplayFormat;
  isLoading?: boolean;
};

export interface List extends z.infer<typeof listSchema> {}

export interface Watchlist extends z.infer<typeof watchlist.postList.schema> {
  isFavorite: boolean;
  entities: WatchlistEntities[];
  last_update: string;
}

export type WatchlistEntity = z.infer<typeof watchlist.postListEntities.schema>;

export interface WatchlistStore {
  activeListId: string | undefined;
  lists: Watchlist[];
  error: WretchError | undefined;
  listIdsByTag: Map<string, string[]>;
  toggleFavoriteWatchlist: (id: string) => Promise<void>;
  getLists: () => Promise<void>;
  getList: (id: string) => Promise<void>;
  createList: (name: string, tags: string[]) => Promise<string | null>;
  createListLoading: boolean;
  updateList: ({
    id,
    name,
    tags,
  }: {
    id: string;
    name: string;
    tags: string[];
  }) => Promise<ApiFetchResponse<List>>;
  updateListName: (id: string, name: string) => Promise<ApiFetchResponse<List>>;
  deleteList: (id: string) => Promise<void>;
  addItemToList: (id: string, tag: string) => Promise<void>;
  removeItemFromList: (id: string, tag: string) => Promise<void>;
  isInAnyWatchlists: (tag: string) => Promise<Watchlists>;
  isInWatchlist: (tag: string, watchlistId: string) => Promise<boolean>;
  createListFromCvs: (
    tags: string,
    newListName: string
  ) => Promise<[string | null, string[]]>;
  editListFromCvs: (
    id: string,
    tagsCvs: string
  ) => Promise<[[] | string[], string[]]>;
  hasListWithName: (name: string) => boolean;
  createListByAsset: (
    assetTag: string,
    newListName: string
  ) => Promise<string | null>;
  editListByAsset: (
    id: string,
    assetTag: string
  ) => Promise<[[] | string[], string[]]>;
  setActiveListId: (id?: string) => void;
  activeModal?: Extract<ActionModal, 'rename' | 'delete'>;
  setActiveModal: (props: {
    activeModal?: Extract<ActionModal, 'rename' | 'delete'>;
  }) => void;
}

export type EntityTags = z.infer<typeof entities.resolveFromCsv.schema>;

export enum WatchlistError {
  Duplicate = 'duplicate',
  EmptyName = 'emptyName',
  EmptyList = 'emptyList',
  ServerError = 'serverError',
  UsageLimitExceeded = 'usageLimitExceeded',
}
