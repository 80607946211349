import { customScrollMinimal } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const ScenarioResultsRoot = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const TableWrapper = styled.div`
  ${customScrollMinimal};
  overflow: auto;
  flex: 1 1 0;
`;
