import { ScreenerFilter } from '@toggle/toggle';

export const SCENARIO_ASSET_FILTER_KEY = 'scenario_assets';
const SCENARIO_ASSET_FILTER_NAME = 'Assets';

export const useScenarioEntityFilters = (): ScreenerFilter[] => {
  const assetFilter: ScreenerFilter = {
    key: SCENARIO_ASSET_FILTER_KEY,
    name: SCENARIO_ASSET_FILTER_NAME,
    visible: true,
    searchable: true,
    category: 'Universe',
    locked: true,
    config: {
      multiselect: false,
      name: SCENARIO_ASSET_FILTER_NAME,
      options: [],
    },
  };

  return [assetFilter];
};
