import { Entity } from '@toggle/toggle';

export const PEER_GROUP_DISPLAYED = 4;
export const THEMES_DISPLAYED = 7;
export const COUNTRIES_DISPLAYED = 4;
export const PRODUCTS_DISPLAYED = 6;

export interface CompetitorsData {
  competitors: {
    entity: Entity;
  }[];
  isLoading: boolean;
}
