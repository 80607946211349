import React from 'react';
import { useTranslation } from 'react-i18next';

import { useThemePerformanceReturn } from '../hooks/use-theme-performance-return';
import * as S from './OneDayReturnPill.styles';

export const OneDayReturnPill = ({ entityTags }: { entityTags: string[] }) => {
  const { t } = useTranslation('widget');

  const { value, isNegative } = useThemePerformanceReturn({
    entityTags,
    numDays: 2,
  });

  return (
    <S.RightHeaderWrapper $isNegative={isNegative}>
      <S.RightHeaderReturnValue $isNegative={isNegative}>
        {value}
      </S.RightHeaderReturnValue>
      <S.RightHeaderReturnLabel>
        {t('watchlist.return1D')}
      </S.RightHeaderReturnLabel>
    </S.RightHeaderWrapper>
  );
};
