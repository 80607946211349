import styled from 'styled-components';

export const HEADER_HEIGHT = '80px';

export const NewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${HEADER_HEIGHT};
  width: 100%;
  padding: 0 20px;
  background-color: var(--background-default);
`;
