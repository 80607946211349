import { PillDropdown } from '@toggle/design-system';
import { Country } from '@toggle/toggle';
import React from 'react';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { useScreenerDropdownItems } from '~/hooks/use-screener-dropdown-items/useScreenerDropdownItems';

import * as S from './CountryListItem.styles';

type CountryListItemProps = {
  country: Country & { tooltip?: string };
};

export const CountryListItem = ({ country }: CountryListItemProps) => {
  const { createNewScreenItem, addToCurrentScreenItem } =
    useScreenerDropdownItems();

  const dropdownItems = ({ name, id }: { name: string; id: string }) => {
    return [
      addToCurrentScreenItem({ name, id, key: 'country_exposure' }),
      createNewScreenItem({ name, id, key: 'country_exposure' }),
    ];
  };

  return (
    <S.CountryListItem key={country.code} data-testid="country-exposure-item">
      <S.PillDropdownWrapper $hasTooltip={!!country.tooltip}>
        <TooltipContainer label={country.tooltip} placement="top-start">
          <span>
            <PillDropdown
              label={country.name}
              renderIcon={() => (
                <S.StyledFlagIcon countryCode={country.code} circular />
              )}
              dropdownItems={dropdownItems({
                name: country.name,
                id: country.code.toUpperCase(),
              })}
            />
          </span>
        </TooltipContainer>
      </S.PillDropdownWrapper>

      <S.StyledListDescription>{country.evidence}</S.StyledListDescription>
    </S.CountryListItem>
  );
};
