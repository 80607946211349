import { CheckBox } from '@toggle/design-system';
import React, { MouseEvent } from 'react';

import * as Shared from '../../filter/Filter.styles';

type AddMoreFilterOptionProps = {
  name: string;
  filterKey: string;
  locked: boolean;
  isChecked?: boolean;
  handleClick: (option: string) => void;
};

export const AddMoreFilterOption = ({
  filterKey,
  name,
  locked,
  isChecked,
  handleClick,
}: AddMoreFilterOptionProps) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleClick(filterKey);
  };

  if (locked) {
    return (
      <Shared.Item disabled>
        <Shared.MenuItemLeftIndicator />
        <Shared.ItemText>
          <Shared.LockedIcon iconName="Lock" />
          <Shared.ItemLabel $locked>{name}</Shared.ItemLabel>
        </Shared.ItemText>
      </Shared.Item>
    );
  }

  return (
    <Shared.CheckboxItem onClick={onClick}>
      <Shared.ItemText>
        <CheckBox
          label={<Shared.ItemLabel>{name}</Shared.ItemLabel>}
          name={filterKey}
          checked={isChecked}
        />
      </Shared.ItemText>
    </Shared.CheckboxItem>
  );
};
