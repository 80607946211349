import React, {
  ChangeEvent,
  cloneElement,
  ReactElement,
  ReactNode,
} from 'react';

import { StyledComponent } from '~/common/styled-component';

import { TooltipProps } from '../tooltip/Tooltip';
import * as S from './Checkbox.styles';

export type CheckboxVariant = 'primary' | 'critical' | 'disabled';

export interface CheckBoxProps {
  className?: string;
  id?: string;
  tooltip?: ReactElement<Omit<TooltipProps, 'children'>>;
  testId?: string;
  hasError?: boolean;
  errorText?: ReactNode;
  name: string;
  label: React.ReactNode;
  onChange?: (name: string, event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  locked?: boolean;
  partial?: boolean;
  variant?: CheckboxVariant;
  size?: 'medium' | 'small';
}

export const CheckBox: StyledComponent<CheckBoxProps, typeof S> = ({
  className,
  onChange,
  label,
  name,
  id,
  tooltip,
  checked = false,
  locked = false,
  partial = false,
  variant = 'primary',
  size = 'medium',
  testId = 'custom-checkbox',
  hasError,
  errorText,
  ...rest
}) => {
  const Label = tooltip ? (
    cloneElement(
      tooltip,
      {
        placement: tooltip.props.placement ?? 'top-start',
      },
      <S.CheckboxLabel $withTooltip>{label}</S.CheckboxLabel>
    )
  ) : (
    <S.CheckboxLabel $withTooltip={false}>{label}</S.CheckboxLabel>
  );

  const iconName = partial ? 'Minus' : 'Checkmark';

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange?.(name, event);

  return (
    <S.CheckboxWrapper data-testid={testId} className={className}>
      <S.Checkbox
        htmlFor={id}
        $size={size}
        $isChecked={checked}
        $variant={variant}
        $locked={locked}
      >
        <S.CheckboxElement
          id={id}
          data-testid={id}
          type="checkbox"
          checked={checked}
          aria-checked={checked}
          name={name}
          onChange={onInputChange}
          disabled={variant === 'disabled' || locked}
          readOnly
          {...rest}
        />
        {locked ? (
          <S.LockIcon iconName="LockLight" size={20} />
        ) : (
          <S.Checkmark>
            <S.CheckIcon iconName={iconName} size={16} data-testid="check" />
          </S.Checkmark>
        )}
        {Label}
      </S.Checkbox>
      {hasError && errorText && (
        <S.ErrorText data-testid="error-text">{errorText}</S.ErrorText>
      )}
    </S.CheckboxWrapper>
  );
};

CheckBox.Styled = S;
