import { numberRoundedToPrecision } from '@toggle/helpers';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { D3ZoomEvent, zoom, zoomIdentity } from 'd3-zoom';

import { Domain } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  CreateChartOptionsWithColors,
} from '~/types/create.types';
import { isWithinYAxis } from '~/utils/axis/axis-utils';
import { getPaneDividerIndex } from '~/utils/pane/pane-utils';
import { numZoom } from '~/utils/zoom/zoom-utils';

import { ChartStoreReturn } from '../create/chart-store/chartStore';
import { reDraw } from '../create/redraw/redraw';
import {
  dispatchDomainChangeEvent,
  dispatchInsightsInDomainEvent,
} from '../events/events';

// see defaultConstraint in zoom.js of d3
// dx1 = transform.invertX(extent[1][0]) - translateExtent[1][0]; when dx1 is 0 the panning stops
// (w- Tx)/k - (w - X) = 0(this is dx1);
// w --> width of the viewport, Tx --> transform.x, k --> zoom coefficient
const getTranslateExtentDiffForZeroDx = (Tx: number, w: number, k: number) =>
  w - (w - Tx) / k;

const getTranslateExtent = (
  base: BaseChartAPIProps,
  length: number
): [[number, number], [number, number]] => {
  const lastDataIdx = base.primaryAsset.ts.length - 1;
  // we want to stop pinning at 50% of the visible chart area
  const scale = scaleLinear()
    .domain([lastDataIdx - length / 2, lastDataIdx + length / 2])
    .range(base.fullXScale.range());
  const { zoom, offset } = numZoom(base.fullXScale, scale);

  // zoom * offset is a CONST value
  // the idea is to calculate what zoom*offset value WILL BE when we will display 50% of visible chart and 50% of the padding
  const diff = getTranslateExtentDiffForZeroDx(
    zoom * offset,
    base.fullXScale.range()[1],
    zoom
  );
  return [
    [0, 0],
    [base.fullXScale.range()[1] - diff, base.options.height],
  ];
};

const isRightClick = (sourceEvent: MouseEvent) =>
  sourceEvent.button === 2 || sourceEvent.buttons === 2;

const shouldIgnoreEvent = (
  sourceEvent: MouseEvent,
  options: CreateChartOptionsWithColors
) => {
  if (!sourceEvent) {
    return false;
  }

  return isRightClick(sourceEvent) || isWithinYAxis(sourceEvent, options);
};

const resetDomainChanged = (chartStore: ChartStoreReturn) => {
  const zoom = chartStore.getState().zoom!;
  chartStore.setState({
    zoom: { ...zoom, hasDomainChanged: false },
  });
};

export const onZoom =
  (chartStore: ChartStoreReturn) =>
  (e: D3ZoomEvent<SVGSVGElement, unknown>) => {
    const previousBase = chartStore.getState().base as BaseChartAPIProps;
    const zoom = chartStore.getState().zoom!;
    let newBase = previousBase;

    if (shouldIgnoreEvent(e.sourceEvent, previousBase.options)) {
      resetDomainChanged(chartStore);
      return;
    }

    if (e.sourceEvent?.type === 'mousemove') {
      previousBase.canvasElement.style.cursor = 'grabbing';
    }

    const lastDataIdx = previousBase.primaryAsset.ts.length - 1;
    const newDomain = e.transform
      .rescaleX(previousBase.fullXScale)
      .domain() as Domain;
    newDomain[0] = numberRoundedToPrecision(newDomain[0]);
    newDomain[1] = numberRoundedToPrecision(newDomain[1]);

    if (newDomain[1] > lastDataIdx + lastDataIdx / 2) {
      resetDomainChanged(chartStore);
      return;
    }

    const length = newDomain[1] - newDomain[0];
    zoom.zoomBehavior.translateExtent(getTranslateExtent(previousBase, length));

    if (
      e.sourceEvent &&
      (newDomain[0] !== previousBase.domain[0] ||
        newDomain[1] !== previousBase.domain[1])
    ) {
      resetDomainChanged(chartStore);
      newBase = chartStore.getState().updateDomain(newDomain);
      reDraw({ chartStore });
    }

    dispatchInsightsInDomainEvent(newBase);
  };

export const onZoomEnd =
  (chartStore: ChartStoreReturn) =>
  (e: D3ZoomEvent<SVGSVGElement, unknown>) => {
    const base = chartStore.getState().base as BaseChartAPIProps;
    const zoom = chartStore.getState().zoom!;

    if (
      shouldIgnoreEvent(e.sourceEvent, base.options) ||
      (e.sourceEvent && zoom.hasDomainChanged)
    ) {
      return;
    }

    chartStore.setState({
      zoom: { ...zoom, hasDomainChanged: false },
    });
    dispatchDomainChangeEvent(base, !e.sourceEvent);
  };

export const initZoom = (
  canvasElement: HTMLCanvasElement,
  chartStore: ChartStoreReturn
) => {
  const zoomBehavior = zoom<HTMLCanvasElement, unknown>().filter(
    e => !e.shiftKey
  );
  zoomBehavior.on('zoom', onZoom(chartStore));
  zoomBehavior.on('end', onZoomEnd(chartStore));
  zoomBehavior.filter(
    e =>
      getPaneDividerIndex(
        e,
        chartStore.getState().base as BaseChartAPIProps
      ) === undefined
  );
  chartStore.setState({
    zoom: {
      zoomBehavior,
      hasDomainChanged: false,
    },
  });
  updateZoom(canvasElement, chartStore);
  select(canvasElement).call(zoomBehavior);
  select(canvasElement).on('dblclick.zoom', null);
};

export const removeZoom = (chartStore: ChartStoreReturn) => {
  chartStore.getState().zoom?.zoomBehavior.on('zoom', null);
  chartStore.setState({ zoom: undefined });
};

const updateZoom = (
  canvasElement: HTMLCanvasElement,
  chartStore: ChartStoreReturn
) => {
  const zoom = chartStore.getState().zoom;

  if (!zoom) {
    return;
  }

  const { options, fullXScale, maxChartZoom, x } = chartStore.getState()
    .base as BaseChartAPIProps;
  const viewportWidth = options.width - options.gutters.y;
  const viewportHeight = options.height - options.gutters.x;
  const { zoom: zoomNumber, offset } = numZoom(fullXScale, x.xScale);

  const zoomTransform = zoomIdentity.scale(zoomNumber).translate(offset, 0);

  zoom.zoomBehavior
    .translateExtent([
      [0, 0],
      [viewportWidth, viewportHeight],
    ])
    .extent([
      [0, 0],
      [viewportWidth, viewportHeight],
    ])
    .scaleExtent([zoomIdentity.k, maxChartZoom]);

  select(canvasElement).call(zoom.zoomBehavior.transform, zoomTransform);
};
