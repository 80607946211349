import { Alignment, Side } from '@floating-ui/react';
import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import {
  fontWeight,
  typographyV2,
} from '~/design-tokens/typography/Typography.styles';

interface ArrowProps {
  x?: number;
  y?: number;
  $placement: Side;
  $alignment?: Alignment;
  offset?: number;
}

const position = ({ x, y, $placement, $alignment }: ArrowProps) => {
  const getPositionFromOffset = (side: Side, n?: number) => {
    if ($alignment === 'start') {
      return `${side}: 8px`;
    }

    if ($alignment === 'end') {
      const oppositeSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[side];

      return `${oppositeSide}: 8px`;
    }

    return `${side}: ${n}px`;
  };

  switch ($placement) {
    case 'left':
      return css`
        left: -4px;
        ${getPositionFromOffset('top', y)};
      `;
    case 'right':
      return css`
        right: -4px;
        ${getPositionFromOffset('top', y)};
      `;
    case 'bottom':
      return css`
        bottom: -4px;
        ${getPositionFromOffset('left', x)};
      `;
    case 'top':
    default:
      return css`
        top: -4px;
        ${getPositionFromOffset('left', x)};
      `;
  }
};

export const ArrowIcon = styled.div<ArrowProps>`
  position: absolute;
  background-color: var(--surface-default);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  z-index: 1;
  ${({ $placement, x, y, $alignment }) =>
    position({ x, y, $placement, $alignment })}
`;

export const TooltipBubble = styled.div`
  font-family: var(--font-nort);
  background-color: var(--surface-default);
  padding: 8px 12px;
  ${borderRadius.r1}
  ${typographyV2.bodySm}
  box-shadow: var(--elevation-strong);
  color: var(--text-default);
  max-width: 340px;
  overflow-wrap: break-word;
  white-space: normal;
  z-index: 1;
  text-align: left;
`;

export const MobileTapClose = styled.span`
  display: block;
  ${typographyV2.uiXs}
  margin-top: 4px;
  font-weight: ${fontWeight.medium};
  color: var(--text-disabled);
  text-transform: uppercase;
`;
