import { isElementScrollbarVisible } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import { RefObject, useLayoutEffect } from 'react';

const SCROLL_OFFSET = 45;
const TABLE_HEADER_HEIGHT = 40;

export interface UseTableShadowProps {
  data: Entity[];
  shadowContainerRef: RefObject<HTMLDivElement | null>;
}

export const useTableShadow = ({
  data,
  shadowContainerRef,
}: UseTableShadowProps) => {
  useLayoutEffect(() => {
    const isScrollable = isElementScrollbarVisible(
      shadowContainerRef,
      'vertical'
    );

    if (!shadowContainerRef.current || !isScrollable) {
      return undefined;
    }

    const shadowDiv = shadowContainerRef.current;

    const handler = (target: HTMLDivElement) => {
      const scrollDistance =
        target.scrollHeight - (target.offsetHeight - TABLE_HEADER_HEIGHT);
      const showShadow = scrollDistance - target.scrollTop > SCROLL_OFFSET;
      shadowDiv.toggleAttribute('data-shadow', showShadow);
    };

    const scrollListener = (e: Event) => {
      handler(e.target as HTMLDivElement);
    };

    handler(shadowDiv);
    shadowDiv.addEventListener('scroll', scrollListener);

    return () => {
      shadowDiv.removeEventListener('scroll', scrollListener);
    };
  }, [data]);
};
