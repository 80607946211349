import { Tooltip } from '@toggle/design-system';
import { formatTicker, SingleNews } from '@toggle/toggle';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NewsThumbnailSkeleton } from '~/components/news-thumbnail-skeleton/NewsThumbnailSkeleton';
import { useEntities } from '~/hooks/use-entities';
import { ArticleDate } from '~/widgets/news-widget/components/article-date/ArticleDate';

import { NewsBadges } from '../news-badges/NewsBadges';
import * as S from './NewsThumbnail.styles';

export interface NewsThumbnailProp {
  newsData: SingleNews;
  highlighted?: string;
  isGrid?: boolean;
  showFullTimestamp?: boolean;
  onClick?: (singleNews: SingleNews) => void;
}

export const NewsThumbnail = ({
  newsData,
  highlighted,
  isGrid = false,
  showFullTimestamp,
  onClick,
}: NewsThumbnailProp) => {
  const { t } = useTranslation([
    'news',
    'chart',
    'analyze',
    'countries',
    'watchlistManager',
  ]);
  const titleRef = useRef<HTMLDivElement>(null);
  const { id, title, published, body, entities, highlights, source } = newsData;

  const { data: entitiesData, isLoading } = useEntities(entities);
  const [hasEllipsis, setHasEllipsis] = useState(false);

  useEffect(() => {
    if (titleRef.current) {
      setHasEllipsis(
        titleRef.current.scrollWidth > titleRef.current.clientWidth
      );
    }
  }, [isLoading, title, titleRef.current, isGrid]);

  if (isLoading) {
    return <NewsThumbnailSkeleton />;
  }

  const handleThumbnailClick = () => onClick?.(newsData);

  const genHighlightedText = (text: string, highlighted: string) => {
    const highlightedArr = highlighted?.toLowerCase().split(' ');
    const textArr = text.split(' ');

    return textArr.map((word, index) => {
      if (highlightedArr?.includes(word.toLowerCase())) {
        return (
          <mark key={`${word}-${index}`} data-testid="highlighted">
            {word}{' '}
          </mark>
        );
      } else {
        return <span key={`${word}-${index}`}>{word} </span>;
      }
    });
  };

  return (
    <S.ThumbnailButton
      data-testid={`news-thumbnail-${id}`}
      onClick={handleThumbnailClick}
    >
      <S.Header>
        <S.Tags>
          {!!entitiesData.length && (
            <S.StyledTooltip
              label={entitiesData.map(entity => {
                return (
                  <S.TooltipWrapper key={entity.id}>
                    <S.EntityTicker>{formatTicker(entity)}</S.EntityTicker>
                    <S.EntityShortName data-testid="entity-name">
                      {entity.name_short || entity.name_full || entity.name}
                    </S.EntityShortName>
                  </S.TooltipWrapper>
                );
              })}
            >
              <S.LogosWrapper data-testid="logos-wrapper">
                {entitiesData
                  .slice(0, 5)
                  .reverse()
                  .map(entity => {
                    return (
                      <S.StyledAssetLogoContainer
                        key={entity.name_short}
                        entity={entity}
                        withFallback={false}
                        size={20}
                      />
                    );
                  })}
              </S.LogosWrapper>
            </S.StyledTooltip>
          )}

          <NewsBadges highlights={highlights} entityTags={entities} />
        </S.Tags>
        <S.MetadataWrapper>
          <S.MetaItem>
            {/* i18next-extract-mark-context-next-line ["benzinga", "mtn", "dowjones"] */}
            {t('news:source', {
              context: source,
            })}
          </S.MetaItem>
          <S.MetaItem>
            <ArticleDate
              date={published}
              applicableRelativeTime
              showFullTimestamp={showFullTimestamp}
              timezoneType="abbreviation"
              data-testid="publishing-time"
            />
          </S.MetaItem>
        </S.MetadataWrapper>
      </S.Header>

      <S.Content>
        {title && (
          <S.Headline ref={titleRef}>
            <Tooltip label={title} disabled={!hasEllipsis}>
              <span data-testid="headline">
                {highlighted ? genHighlightedText(title, highlighted) : title}
              </span>
            </Tooltip>
          </S.Headline>
        )}

        {body && (
          <S.Body data-testid="news-body">
            {highlighted ? genHighlightedText(body, highlighted) : body}
          </S.Body>
        )}
      </S.Content>
    </S.ThumbnailButton>
  );
};

NewsThumbnail.Styled = S;
