import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortDirection,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  DropdownEntry,
  DropdownGroup,
  DropdownMenu,
  TableHeaderIcons,
  TanStackTableRoot,
} from '@toggle/design-system';
import { EarningsItemWithEntity } from '@toggle/toggle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { TabsType as AOTabsType } from '~/modules/tab-content/types';
import { getHeaderProps } from '~/views/dashboard/calendar-widget/calendar-event-drawer/calendar-event-drawer-header/utils';
import { CalendarEventDrawer } from '~/views/dashboard/calendar-widget/calendar-event-drawer/CalendarEventDrawer';
import { getDrawerContent } from '~/views/dashboard/calendar-widget/calendar-event-drawer/drawer-content/utils/utils';
import {
  CalendarEvents,
  isEarningsEvent,
} from '~/views/dashboard/calendar-widget/calendar-event-drawer/utils';

import { EmptyState } from '../empty-state/EmptyState';
import { ErrorState } from '../error-state/ErrorState';
import { useEventsColumns } from '../useEventsColumns';
import { formatEventDate, TabsType } from '../utils';
import * as S from './EventsTable.styles';
import { EventTableBodySkeletonLoader } from './EventTableBodySkeletonLoader';

export interface TableDataSet {
  data: Record<string, CalendarEvents[]>;
  isLoading: boolean;
  isError: boolean;
  handleRefetch: () => void;
}

export interface EventsTableProps {
  activeTab: TabsType;
  activeDate: Date;
  tableDataSet: TableDataSet;
  forceIsTableLoading: boolean;
  setForceIsTableLoading: (isLoading: boolean) => void;
}

export const EventsTable = ({
  activeTab,
  activeDate,
  tableDataSet,
  forceIsTableLoading,
  setForceIsTableLoading,
}: EventsTableProps) => {
  const { t } = useTranslation('common');
  const { navigateToPage } = useNavigateToAssetOverview();

  const [drawerData, setDrawerData] = useState<CalendarEvents | undefined>();
  const [activeSortingState, setActiveSortingState] = useState<SortingState>(
    []
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setForceIsTableLoading(false);
    }, 20);
    return () => clearTimeout(handler);
  }, [forceIsTableLoading]);

  const onEarningsDrawerFullScreenClick = (
    drawerData: EarningsItemWithEntity
  ) =>
    navigateToPage({
      entityTag: drawerData.entity_tag,
      tab: AOTabsType.Earnings,
    });

  const { data, isError, isLoading, handleRefetch } = tableDataSet;
  const activeDateData = data[formatEventDate(activeDate)] ?? [];
  const columns = useEventsColumns({
    activeTab,
  });

  const getAlignment = (index: number) => {
    switch (activeTab) {
      case TabsType.Earnings:
        return index > 2 ? 'end' : 'start';
      case TabsType.Economics:
        return index > 3 ? 'end' : 'start';
      case TabsType.Company:
      default:
        return 'start';
    }
  };

  const getDropdownItems = (
    columndId: string,
    sortOrder: false | SortDirection
  ) => {
    const enabledKeys = sortOrder
      ? ['sortUp', 'sortDown', 'clearSort']
      : ['sortUp', 'sortDown'];

    const items = [
      {
        label: t('common:table.dropdown_ascending'),
        iconName: 'SortUp',
        key: 'sortUp',
        onClick: () =>
          setActiveSortingState([
            {
              id: columndId,
              desc: false,
            },
          ]),
      },
      {
        label: t('common:table.dropdown_descending'),
        iconName: 'SortDown',
        key: 'sortDown',
        onClick: () =>
          setActiveSortingState([
            {
              id: columndId,
              desc: true,
            },
          ]),
      },
      {
        label: t('common:table.dropdown_clearSort'),
        iconName: 'ClearSort',
        key: 'clearSort',
        onClick: () => setActiveSortingState([]),
      },
    ];

    return items.filter(i => enabledKeys.includes(i.key)) as DropdownEntry[];
  };

  const table = useReactTable({
    data: activeDateData as CalendarEvents[],
    columns: columns as ColumnDef<CalendarEvents>[],
    state: {
      sorting: activeSortingState,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isError) {
    return <ErrorState handleReload={handleRefetch} />;
  }

  if (!activeDateData?.length && !isLoading) {
    return <EmptyState activeTab={activeTab} />;
  }

  return (
    <>
      <S.TableWrapper data-testid="events-table">
        <TanStackTableRoot>
          <thead>
            {table.getHeaderGroups().map(headerGroup => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => {
                    const sortOrder = header.column.getIsSorted();
                    const alignment = getAlignment(index);
                    const dropdownItems = getDropdownItems(
                      header.column.id,
                      sortOrder
                    );
                    return (
                      <DropdownMenu
                        key={header.id}
                        triggerNode={
                          <S.StyledTableHeader
                            key={header.column.id}
                            isHoverEnabled={false}
                            alignment={alignment}
                          >
                            <TableHeaderIcons
                              isFiltered={header.column.getIsFiltered()}
                              sortDirection={sortOrder}
                              alignment={alignment}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </TableHeaderIcons>
                          </S.StyledTableHeader>
                        }
                      >
                        <DropdownGroup items={dropdownItems} />
                      </DropdownMenu>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {isLoading || forceIsTableLoading ? (
              <EventTableBodySkeletonLoader
                columnLength={table.getHeaderGroups()[0].headers.length}
              />
            ) : (
              table.getRowModel().rows.map(row => {
                return (
                  <tr key={row.id} onClick={() => setDrawerData(row.original)}>
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <S.StyledTableCell
                          key={cell.id}
                          alignment={getAlignment(index)}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </S.StyledTableCell>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </TanStackTableRoot>
      </S.TableWrapper>
      {drawerData && (
        <div
          onClick={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
          role="button"
        >
          <CalendarEventDrawer
            closeDrawer={() => {
              setDrawerData(undefined);
            }}
            isOpen={!!drawerData}
            headerContent={getHeaderProps(drawerData)}
            drawerContent={getDrawerContent(drawerData)}
            onFullScreenClick={
              isEarningsEvent(drawerData)
                ? () => onEarningsDrawerFullScreenClick(drawerData)
                : undefined
            }
          />
        </div>
      )}
    </>
  );
};
