import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const SearchSourcesRoot = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid var(--border-soft);
  justify-content: space-between;
`;

export const Title = styled.h2`
  ${typographyV2.uiXs};
  color: var(--text-soft);
  text-transform: uppercase;
`;

export const Logotypes = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const Img = styled.img`
  max-height: 21px;
`;
