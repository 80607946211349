import { Icon } from '@toggle/design-system';
import React, { forwardRef, ReactNode } from 'react';

import * as S from './DocumentViewerContainer.styles';

interface DocumentViewerContainerProps {
  name: string;
  iconNode?: ReactNode;
  children: ReactNode;
  onClick: () => void;
}

export const DocumentViewerContainer = forwardRef<
  HTMLDivElement,
  DocumentViewerContainerProps
>(({ children, name, iconNode, onClick }, ref) => {
  return (
    <S.Container ref={ref}>
      <S.Header>
        <S.LeftContainer>
          <div onClick={onClick}>
            {iconNode}
            {name}
            <Icon iconName="ExternalLink" size={20} aria-hidden="true" />
          </div>
        </S.LeftContainer>
      </S.Header>
      {children}
    </S.Container>
  );
});
