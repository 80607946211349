import Calendar from 'react-calendar';
import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const DatePickerWrapper = styled.div`
  width: fit-content;
`;

export const CustomDatePicker = styled(Calendar)<{
  $isShowNavLabel: boolean;
  $isSelectRange: boolean;
}>`
  border: 1px solid var(--border-soft);
  padding: 20px 12px;
  width: 306px;
  background-color: var(--background-soft);

  button {
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  /* For navigation */
  .react-calendar__navigation {
    display: flex;
    margin-bottom: 16px;
    font-size: 14px;
  }

  .react-calendar__navigation__arrow {
    padding: 4px;

    &:hover {
      ${borderRadius.r1};
      background-color: var(--surface-hover);
    }

    &:disabled svg {
      fill: var(--icon-disabled);
    }
  }

  .react-calendar__navigation__label {
    margin: 0px 32px;
    visibility: hidden;

    ${({ $isShowNavLabel }) =>
      $isShowNavLabel &&
      css`
        visibility: visible;

        &:hover {
          ${borderRadius.r1};
          background-color: var(--surface-hover);
        }
      `};
  }

  .react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none;
  }

  /* For weekdays */
  .react-calendar__month-view__weekdays {
    margin-bottom: 4px;
    text-align: center;
    color: var(--text-disabled);
    text-transform: uppercase;
  }

  .react-calendar__month-view__days {
    ${typographyV2.bodyBase};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--text-soft);
  }

  .react-calendar__month-view__days__day {
    &:hover:not(.react-calendar__tile--disabled, .react-calendar__tile--active, .react-calendar__tile--hoverStart, .react-calendar__tile--hoverEnd) {
      ${borderRadius.rFull};
      background-color: var(--surface-hover);
    }
  }

  .react-calendar__tile {
    width: 32px;
    height: 37px;
    cursor: pointer;
  }

  .react-calendar__tile--now {
    color: var(--text-primary);
  }

  .react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--active {
    ${borderRadius.rFull};
    background-color: var(--surface-primary);
    color: var(--text-on-primary);

    ${({ $isSelectRange }) =>
      $isSelectRange &&
      css`
        &.react-calendar__tile--hoverStart {
          border-radius: 100px 0 0 100px;
        }
        &.react-calendar__tile--hoverEnd {
          border-radius: 0 100px 100px 0;
        }
        &.react-calendar__tile--hoverStart.react-calendar__tile--hoverEnd {
          ${borderRadius.rFull};
        }
      `};
  }

  .react-calendar__month-view__days__day.react-calendar__tile--range.react-calendar__tile--rangeStart {
    border-radius: 100px 0 0 100px;
    background-color: var(--surface-primary);
    color: var(--text-on-primary);
  }

  .react-calendar__month-view__days__day.react-calendar__tile--range.react-calendar__tile--rangeEnd {
    border-radius: 0 100px 100px 0;
    background-color: var(--surface-primary);
    color: var(--text-on-primary);
  }

  .react-calendar__tile--disabled {
    color: var(--text-disabled);
    cursor: unset;
  }

  .react-calendar__tile--hover {
    background-color: var(--surface-hover);

    ${({ $isSelectRange }) =>
      $isSelectRange &&
      css`
        &.react-calendar__tile--hoverStart {
          border-radius: 100px 0 0 100px;
        }
        &.react-calendar__tile--hoverEnd {
          border-radius: 0 100px 100px 0;
        }
      `};
  }

  .react-calendar__tile--disabled.react-calendar__tile--hover {
    background-color: unset;
  }

  .react-calendar__tile--range {
    background-color: var(--surface-hover);
    border-radius: unset;
  }

  /* For months */
  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year {
    width: 80px;
    height: 55px;
    ${typographyV2.bodyBase};

    &:hover:not(.react-calendar__tile--disabled, .react-calendar__tile--hasActive) {
      ${borderRadius.r2};
      background-color: var(--surface-hover);
    }
  }

  .react-calendar__tile--hasActive {
    ${borderRadius.r2};
    background-color: var(--surface-primary);
    color: var(--text-on-primary);
  }
`;
