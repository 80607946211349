import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './SingleDocLoader.styles';

export const SingleDocLoader = () => {
  const { t } = useTranslation(['chat']);

  return (
    <S.SingleDocLoaderRoot data-testid="single-doc-loader">
      <S.StyledProgressBar animationDuration={'2000ms'} />
      <S.Label>{t('chat:docIsLoading')}</S.Label>
    </S.SingleDocLoaderRoot>
  );
};
