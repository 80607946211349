import { XAxisTick } from '~/types/axis.types';
import { CreateChartOptionsWithColors, XAxis } from '~/types/create.types';

interface DrawXAxisProps {
  context: CanvasRenderingContext2D;
  options: CreateChartOptionsWithColors;
  x: XAxis;
}

export const drawXAxis = ({ context, options, x }: DrawXAxisProps) => {
  // Draw border line
  const xAxisStart = options.height - options.gutters.x;
  context.fillStyle = options.colors.border.default.value;
  context.fillRect(0, xAxisStart, options.width - options.gutters.y, 1);

  // Draw ticks
  const prevState = {
    labelEndPosX: 0,
  };

  for (const tick of x.axis) {
    drawTick({ context, options, tick, xScale: x.xScale, prevState });
  }
};

interface DrawTickProps {
  context: CanvasRenderingContext2D;
  options: CreateChartOptionsWithColors;
  tick: XAxisTick;
  xScale: XAxis['xScale'];
  prevState: { labelEndPosX: number };
}

const drawTick = ({
  context,
  options,
  tick,
  xScale,
  prevState,
}: DrawTickProps) => {
  context.font = options.font.default;
  const textToBorderGap = 8;
  const coordinate = xScale(tick.index);
  const xAxisStart = options.height - options.gutters.x;
  const size = context.measureText(tick.label);
  const y = xAxisStart + size.actualBoundingBoxAscent + textToBorderGap;
  context.fillStyle = tick.highlight
    ? options.colors.typography.highlight.value
    : options.colors.typography.soft.value;

  const labelStartPosX = coordinate - size.width / 2;
  const labelEndPosX = labelStartPosX + size.width;

  if (labelStartPosX > prevState.labelEndPosX) {
    // Draw Tick label
    context.fillText(tick.label, labelStartPosX, y);
    prevState.labelEndPosX = labelEndPosX;

    // Draw grid line
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = options.colors.border.soft.value;
    context.fillRect(coordinate, 0, 1, options.height - options.gutters.x);
    context.globalCompositeOperation = 'source-over';
  }
};

interface DrawXAxisMarkProps {
  context: CanvasRenderingContext2D;
  x: number;
  y: number;
  label: string;
  options: CreateChartOptionsWithColors;
}

export const drawXAxisMark = ({
  context,
  label,
  x,
  y,
  options,
}: DrawXAxisMarkProps) => {
  context.font = options.font.default;
  const rectPadding = 8;
  const textSize = context.measureText(label);
  const textHeight =
    textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent;
  const rectHeight = textHeight + rectPadding * 2;
  const textWidth = textSize.width;
  const rectWidth = textWidth + rectPadding * 2;
  const rectX = Math.max(0, x - rectWidth / 2);
  const textX = Math.max(x - rectWidth / 2 + rectPadding, 0 + rectPadding);

  context.fillStyle = options.colors.surface.default.value;
  context.fillRect(rectX, y, rectWidth, rectHeight);

  context.fillStyle = options.colors.typography.highlight.value;
  context.textBaseline = 'middle';

  context.fillText(label, textX, y + rectPadding + textHeight / 2);
};
