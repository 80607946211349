import { bisector, pointer, ScaleTime } from 'd3';
import React, { MouseEvent } from 'react';

import { TooltipData } from '../../MultiLineChart.types';

export interface MultiLineChartOverlayProps {
  width: number;
  height: number;
  xScale: ScaleTime<number, number>;
  dates: Date[];
  setTooltipData: React.Dispatch<React.SetStateAction<TooltipData | undefined>>;
}

export const MultiLineChartOverlay = ({
  width,
  height,
  xScale,
  dates,
  setTooltipData,
}: MultiLineChartOverlayProps) => {
  const onMouseMove = (event: MouseEvent) => {
    // Returns corresponding value from the domain given mouse xPos
    const correspondingDate = xScale.invert(pointer(event)[0]);

    // Finds nearest data point to the corresponding date
    const bisectDate = bisector((d: Date) => d).left;
    const i = bisectDate(dates, correspondingDate, 1);
    const d0 = dates[i - 1];
    const d1 = dates[i];
    const tooltipDate =
      correspondingDate.valueOf() - d0.valueOf() >
      d1.valueOf() - correspondingDate.valueOf()
        ? d1
        : d0;
    setTooltipData({
      xPos: xScale(tooltipDate),
      date: tooltipDate,
    });
  };

  return (
    <rect
      data-testid="multi-line-chart-overlay"
      width={Math.max(0, width)}
      height={Math.max(0, height)}
      fill="none"
      onMouseMove={onMouseMove}
      onMouseLeave={() => {
        setTooltipData(undefined);
      }}
      pointerEvents={'all'}
    />
  );
};
