import { ScaleLinear } from 'd3';
import React from 'react';

import * as S from './VerticalGridLines.styles';
import { LineProp } from './VerticalGridLines.types';

interface VerticalLinesProps {
  lines: LineProp[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  className?: string;
  orientation?: 'top' | 'bottom';
}

const LABEL_Y1_OFFSET = 25;
const LABEL_Y2_OFFSET = 5;

export const VerticalLines = ({
  lines,
  xScale,
  yScale,
  className = '',
  orientation = 'top',
}: VerticalLinesProps) => {
  return (
    <g className={className}>
      {lines.map(({ x, label, variant, ...rest }) => {
        const posX = xScale(x);
        const posY1 = yScale(yScale.domain()[0]);
        const posY2 = yScale(yScale.domain()[1]);
        const labelPosition =
          orientation === 'top'
            ? posY2 - LABEL_Y2_OFFSET
            : posY1 + LABEL_Y1_OFFSET;

        return (
          <g key={`${x}-${label}`}>
            <S.VerticalLine
              x1={posX}
              y1={posY1}
              x2={posX}
              y2={posY2}
              $variant={variant}
              {...rest}
            />

            <S.Label x={posX} y={labelPosition} textAnchor="middle">
              {label}
            </S.Label>
          </g>
        );
      })}
    </g>
  );
};
