import { formatPercentage } from '@toggle/helpers';
import { extent, scaleLinear } from 'd3';
import React, { useId, useState } from 'react';

import {
  ErrorButton,
  ErrorMessage,
} from '~/components/error-message/ErrorMessage';

import { getHumanFriendlyTicks } from '../helpers/chart-ticks/chart-ticks';
import { assureZeroInDomain } from '../v2/horizontal-bar-chart/utils';
import * as S from './BarChart.styles';
import { Legend } from './components/legend/Legend';
import {
  BarDataPoint,
  VerticalBar,
} from './components/vertical-bar/VerticalBar';
import { useFloatingLegend } from './hooks/use-floating-legend/useFloatingLegend';

const defaultMargin = {
  top: 8,
  right: 45,
  bottom: 8,
  left: 0,
};

const defaultPadding = {
  yAxisLabel: 8,
  xAxisLabel: 16,
};

export interface BarChartProps {
  barRadius?: number;
  data: BarDataPoint[];
  decimalsAxis?: number;
  decimalsLegend?: number;
  errorButtonProps?: ErrorButton;
  height: number;
  isError?: boolean;
  legendTitle?: string;
  margin?: Partial<typeof defaultMargin>;
  padding?: Partial<typeof defaultPadding>;
  postfix?: string;
  showAxisLines?: boolean;
  showLegend?: boolean;
  showXAxisLabels?: boolean;
  showYAxisLabels?: boolean;
  width: number;
  yAxisLabel?: string;
  yAxisLabelFormatter?: (value: number) => string;
  yAxisTicksRange?: [number, number];
  useOriginalValue?: boolean;
}

export const BarChart = ({
  barRadius,
  data,
  decimalsAxis = 0,
  decimalsLegend = 2,
  errorButtonProps,
  height,
  isError = false,
  legendTitle,
  postfix = 'x',
  showAxisLines = true,
  showLegend = true,
  showXAxisLabels = true,
  showYAxisLabels = true,
  width,
  useOriginalValue = false,
  yAxisLabel,
  yAxisLabelFormatter = tickValue =>
    formatPercentage(tickValue, {
      suffix: postfix,
      decimals: decimalsAxis,
      useOriginalValue,
    }),
  yAxisTicksRange = [0, 1],
  ...rest
}: BarChartProps) => {
  const padding = { ...defaultPadding, ...rest.padding };
  const margin = { ...defaultMargin, ...rest.margin };
  const chartId = useId();
  const [hoveredLabel, setHoveredLabel] = useState('');
  const { isOpen, refs, floatingStyles, getReferenceProps, getFloatingProps } =
    useFloatingLegend();

  const [xMin, xMax] = [0, data.length - 1];
  const [yMinInit, yMaxInit] = yAxisTicksRange;
  const [yMin = yMinInit, yMax = yMaxInit] = extent(data, item => item.value);

  const [adjustedMin, adjustedMax] = assureZeroInDomain([yMin, yMax]);
  const { chartTicks, maxRounded, minRounded } = getHumanFriendlyTicks({
    min: adjustedMin,
    max: adjustedMax,
  });

  const chartWidth = showYAxisLabels ? width - margin.right : width;
  const chartHeight = showXAxisLabels ? height - margin.bottom : height;
  const barWidth = Math.floor(chartWidth / data.length / 2);

  const xScale = scaleLinear()
    .domain([xMin, xMax])
    .range([barWidth, chartWidth - padding.yAxisLabel - barWidth]);

  const yScale = scaleLinear()
    .domain([minRounded, maxRounded])
    .range([chartHeight - padding.xAxisLabel, margin.top]);

  const formatValue = (value: number) =>
    formatPercentage(value, {
      suffix: postfix,
      decimals: decimalsLegend,
      useOriginalValue,
    });

  return (
    <S.BarChartRoot
      data-testid="bar-chart"
      style={{
        width,
      }}
    >
      {isError && (
        <S.ErrorMessageWrapper>
          <ErrorMessage
            displayIcon="error"
            buttonProps={{
              size: 'xsmall',
              label: 'Reload',
              ...errorButtonProps,
            }}
          />
        </S.ErrorMessageWrapper>
      )}

      {showLegend && isOpen && (
        <S.LegendWrapper>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            data-testid="legend-tooltip"
          >
            <Legend
              title={legendTitle}
              data={data}
              hoveredLabel={hoveredLabel}
              formatValue={formatValue}
            />
          </div>
        </S.LegendWrapper>
      )}

      {yAxisLabel && <S.TitleAxisY>{yAxisLabel}</S.TitleAxisY>}
      <S.Svg
        data-testid="bar-chart-svg"
        width={width}
        height={height}
        $isBlurred={isError}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {chartTicks.map(tickValue => (
          <g key={tickValue} transform={`translate(0, ${yScale(tickValue)})`}>
            {showAxisLines && (
              <S.AxisLine
                data-testid="axis-lines"
                x1={margin.left}
                x2={chartWidth - padding.yAxisLabel}
                $highlight={tickValue === 0}
              />
            )}
            {showYAxisLabels && (
              <S.LabelAxisY
                data-testid="ticker-value"
                textAnchor="start"
                x={chartWidth}
                alignmentBaseline="middle"
                $highlight={tickValue === 0}
              >
                {yAxisLabelFormatter(tickValue)}
              </S.LabelAxisY>
            )}
          </g>
        ))}

        {data.map((dataPoint, index) => {
          const value = dataPoint.value ?? 0;
          const yValue = yScale(value);
          const y0 = yScale(0);
          return (
            <g
              data-testid="bar"
              key={`${chartId}-${index}`}
              transform={`translate(${xScale(index)}, 0)`}
              onMouseEnter={() => setHoveredLabel(dataPoint.label)}
              onMouseLeave={() => setHoveredLabel('')}
            >
              <VerticalBar
                barRadius={barRadius}
                yValue={yValue}
                y0={y0}
                width={barWidth}
                dataPoint={dataPoint}
                showBackground={showLegend}
              />
              {showXAxisLabels && (
                <S.LabelAxisX textAnchor="middle" y="100%">
                  {dataPoint.label}
                </S.LabelAxisX>
              )}
            </g>
          );
        })}
      </S.Svg>
    </S.BarChartRoot>
  );
};
