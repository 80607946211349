import { useEffect, useState } from 'react';

export const ROWS_MIN = 1;
export const ROWS_MAX = 6;
export const TEXTAREA_LINE_HEIGHT = 24;

export interface UseRowsProps {
  value: string | number | readonly string[] | undefined;
  textAreaLineHeight?: number;
  defaultRows?: number;
  maxRows?: number;
}

export const useRows = ({
  value,
  textAreaLineHeight = TEXTAREA_LINE_HEIGHT,
  defaultRows,
  maxRows = ROWS_MAX,
}: UseRowsProps) => {
  const [rows, setRows] = useState(defaultRows ?? ROWS_MIN);
  useEffect(() => {
    if (!value) {
      setRows(ROWS_MIN);
    }
  }, [value]);

  const updateRows = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const previousRows = event.target.rows;
    event.target.rows = ROWS_MIN;

    const currentRows = Math.ceil(
      event.target.scrollHeight / textAreaLineHeight
    );

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  return { rows, updateRows };
};
