import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const DetailsContainer = styled.div`
  display: flex;
  color: var(--text-soft);
  ${typographyV2.bodyXs};
  gap: 4px;
  & svg {
    fill: var(--icon-soft);
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${typographyV2.bodyXs};
`;
