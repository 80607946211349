import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 8px;
`;

export const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const CurrencyLabel = styled.div`
  display: flex;
  flex-direction: row;
  font-size: xx-small;
  position: relative;
  top: 2px;
`;
