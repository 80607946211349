import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Shared from '../Shared.styles';

export const ScenarioUsageExceeded = ({
  assetsCount,
  conditionsCount,
}: {
  assetsCount: number;
  conditionsCount: number;
}) => {
  const { t } = useTranslation('scenario');
  const scenarioPageTitle = `${t('scenario:emptyState.assetsAnd', {
    count: assetsCount,
  })} ${t('scenario:emptyState.conditionsSelected', {
    count: conditionsCount,
  })}`;

  return (
    <Shared.Container>
      <Shared.Img src="/app/static/images/icons/no-assets-found.svg" alt="" />
      <Shared.Description>
        <Shared.Title>{scenarioPageTitle}</Shared.Title>
        <Shared.StyledButton
          variant="primary"
          label={t('scenario:buttonLabels.runScenario')}
          disabled
        />
      </Shared.Description>
    </Shared.Container>
  );
};
