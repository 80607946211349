import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { StyledLogoImg } from '~/views/theme-overview/components/theme-logo-icon/ThemeLogoIcon.styles';

export const ListThemeItemRoot = styled.button<{ $isFocused?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: none;
  ${borderRadius.r1};

  &:hover {
    background-color: var(--surface-hover);
  }

  &,
  &:focus-visible {
    outline: ${props =>
      props.$isFocused ? '2px solid var(--border-focus)' : 'none'};
  }

  ${StyledLogoImg} {
    height: 32px;
    width: 32px;
    border-radius: 40px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  mark {
    color: var(--text-primary);
    background-color: transparent;
  }
`;

export const Title = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-default);
`;

export const Subtitle = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3e63dd;
  height: 32px;
  width: 32px;
  border-radius: 40px;
  color: var(--text-default);
  ${typographyV2.titleSm}
`;
