import { Input } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  ${Input.Styles.InputWrapper} {
    padding: 0;
  }

  ${Input.Styles.InputElement} {
    height: 40px;
    padding: 0 12px;

    &::-ms-reveal {
      display: none;
    }
  }
`;

export const ButtonWrapper = styled.span`
  display: flex;
  padding-right: 4px;
`;
