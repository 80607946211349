import { entityReport, KeyValue } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

export function fetchKeyValues(entityTag: string, signal?: AbortSignal) {
  return wretchRequest<KeyValue[]>(
    entityReport.getEntityKeyValues.path(entityTag),
    {
      schema: entityReport.getEntityKeyValues.schema,
      signal,
    }
  );
}
