import { Button } from '@toggle/design-system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { useAuth } from '~/stores/use-auth/useAuth';
import { useUser } from '~/stores/use-user/useUser';

import { OnHoldActionButton } from '../on-hold-action-button/onHoldActionButton';
import {
  copyRecord,
  getDescriptionType,
  getTitleType,
  PageVariants,
} from '../utils';
import * as S from './OnHold.styles';

const contactFormLink = 'https://support.reflexivity.com/hc/en-us/requests/new';

export const OnHold = () => {
  const { t } = useTranslation('auth');
  const { logout, unLockAccount } = useAuth();
  const [searchParams] = useSearchParams();
  const isProfessional = useUser(state => state.user?.is_professional);

  const copyReference = copyRecord(t);

  const reason = searchParams.get('reason');

  const handleSignOut = () => {
    unLockAccount();
    logout(true);
  };

  const subscriptionType = isProfessional
    ? PageVariants.Pro
    : PageVariants.Basic;

  const titleType = getTitleType({
    reason,
    subscriptionType,
  }) as keyof typeof copyReference.title;

  const descriptionType = getDescriptionType({
    reason,
    subscriptionType,
  }) as keyof typeof copyReference.description;

  return (
    <S.Wrapper>
      <div>
        <S.StyledCard>
          <S.LockIcon src="/app/static/images/auth/lock.svg" />
          <S.ContentWrapper>
            <S.Title>{copyReference.title[titleType]}</S.Title>
            <S.Description>
              {copyReference.description[descriptionType]}
            </S.Description>
          </S.ContentWrapper>

          <S.ButtonWrapper>
            <OnHoldActionButton />
          </S.ButtonWrapper>
          <Button
            data-testid="sign-out-btn"
            label={t('auth:onHold.signOut')}
            onClick={handleSignOut}
            variant="empty"
            size="small"
          />
        </S.StyledCard>
        <S.Contact>
          <Trans
            t={t}
            i18nKey="auth:onHold.stillHavingTrouble"
            components={[<a key="key-0" href={contactFormLink} />]}
          />
        </S.Contact>
      </div>
    </S.Wrapper>
  );
};
