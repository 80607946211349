import React, { ReactNode } from 'react';

import {
  FilterOption,
  ToggleFilterProps,
} from '~/components/update-filter-options/updateFilterOptions';

import * as S from './Filter.styles';
import { Option } from './Option';

export type MultiSelectedOptionsProps = {
  filterKey: string;
  options: FilterOption[];
  disabledTooltipLabel: string;
  defaultOptionKey?: string;
  onFilterSelect: (props: ToggleFilterProps, active: boolean) => void;
  title: string;
  renderCustomFilterOptionLabel?: (filterOption: FilterOption) => ReactNode;
};

export const MultiSelectedOptions = ({
  filterKey,
  options,
  disabledTooltipLabel,
  defaultOptionKey,
  onFilterSelect,
  title,
  renderCustomFilterOptionLabel,
}: MultiSelectedOptionsProps) => {
  const isDisabled = (optKey: string) => {
    if (options.length === 1) {
      return defaultOptionKey === optKey ? disabledTooltipLabel : undefined;
    }

    return undefined;
  };

  if (options.length) {
    return (
      <>
        <S.Separator />
        <S.DropdownMenuTitle>
          <S.DropdownMenuTitleText>{title}</S.DropdownMenuTitleText>
        </S.DropdownMenuTitle>
        {options.map(option => (
          <Option
            key={option.key}
            option={option}
            isChecked
            handleClick={() =>
              onFilterSelect({ filterKey, multiselect: true, option }, true)
            }
            multiselect
            disabledTooltipLabel={isDisabled(option.key)}
            renderCustomFilterOptionLabel={renderCustomFilterOptionLabel}
          />
        ))}
      </>
    );
  }

  return null;
};
