import * as Radix from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '~/components/filter/Option';
import { useFinancialEventsCountries } from '~/hooks/use-financial-events-countries/useFinancialEventsCountries';
import { FilterSearch } from '~/views/screener/filter-search/FilterSearch';

import { CountryFilter } from '../utils';
import * as S from './CountrySelector.styles';

export interface CountrySelectorProps {
  activeCountries: CountryFilter[];
  setActiveCountries: (value: React.SetStateAction<CountryFilter[]>) => void;
}

export const CountrySelector = ({
  activeCountries,
  setActiveCountries,
}: CountrySelectorProps) => {
  const { t } = useTranslation(['calendars']);
  const { data } = useFinancialEventsCountries();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const isActive = !!activeCountries.length;
  const showResetButton = !searchValue && isActive;
  const optionCount = activeCountries.length;
  const countryOptions =
    data
      ?.filter(d => {
        return searchValue
          ? true
          : !activeCountries
              .map(country => country.value.value)
              .includes(d.code);
      })
      .filter(d => {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        return searchValue
          ? d.name.toLowerCase().includes(lowerCaseSearchValue) ||
              d.code.toLowerCase().includes(lowerCaseSearchValue)
          : true;
      }) ?? [];
  const showEmpty = !countryOptions.length && searchValue;

  const renderOptionsContent = () => {
    return (
      <>
        {!searchValue && !!activeCountries.length && (
          <>
            <S.Separator />
            <S.DropdownMenuTitle>
              <S.DropdownMenuTitleText>
                {t('calendar:countrySelector.selected')}
              </S.DropdownMenuTitleText>
            </S.DropdownMenuTitle>
            {activeCountries?.map(c => (
              <Option
                key={c.key}
                isChecked
                option={c}
                handleClick={() => onSelectOption(c)}
                multiselect
              />
            ))}
          </>
        )}
        <S.DropdownMenuTitle>
          <S.DropdownMenuTitleText>
            {t('calendar:countrySelector.countries')}
          </S.DropdownMenuTitleText>
        </S.DropdownMenuTitle>

        {countryOptions.map(({ name, code }) => {
          const option = {
            key: code,
            name: name,
            value: { value: code },
          };

          return (
            <Option
              key={code}
              isChecked={!!activeCountries.find(c => c.value.value === code)}
              option={option}
              handleClick={() => onSelectOption(option)}
              multiselect
            />
          );
        })}
      </>
    );
  };

  const onClearSearchInput = () => {
    setSearchValue('');
  };

  const handleOutsideClick = () => {
    onClearSearchInput();
    setOpen(false);
  };

  const onSelectOption = (countryCode: CountryFilter) => {
    const isNewCountryCode = !activeCountries.find(
      a => a.value.value === countryCode.value.value
    );

    if (isNewCountryCode) {
      setActiveCountries(prev => [...prev, countryCode]);
    } else {
      setActiveCountries(
        activeCountries.filter(a => a.value.value !== countryCode.value.value)
      );
    }
  };

  return (
    <div data-testid="events-country-selector">
      <Radix.Root open={open} onOpenChange={setOpen}>
        <S.Trigger $active={isActive} onClick={() => setOpen(true)}>
          <S.TriggerLabel>
            {t('calendar:countrySelector.country')}
          </S.TriggerLabel>
          {isActive && (
            <S.SelectedValue>
              {optionCount > 1 ? (
                <S.CountBadge>{optionCount}</S.CountBadge>
              ) : (
                activeCountries[0].name
              )}
            </S.SelectedValue>
          )}
          <S.TriggerArrow iconName="ChevronLightDown" />
        </S.Trigger>
        <Radix.Portal>
          <S.DropdownMenuContent
            align="start"
            sideOffset={5}
            collisionPadding={5}
            onInteractOutside={handleOutsideClick}
          >
            <>
              <>
                <FilterSearch
                  value={searchValue}
                  searchPlaceholder={t('calendar:countrySelector.search')}
                  onChange={e => setSearchValue(e.target.value)}
                  onClear={onClearSearchInput}
                />
                {showResetButton && (
                  <div>
                    <S.Separator />
                  </div>
                )}
              </>

              {showResetButton && (
                <S.ResetItem onSelect={() => setActiveCountries([])}>
                  <S.ResetButton data-testid="reset-label">
                    <S.ResetIconWrapper>
                      <S.ResetIcon iconName="ArrowRotateRight" />
                    </S.ResetIconWrapper>
                    <S.ResetButtonLabel>
                      {t('calendar:countrySelector.reset')}
                    </S.ResetButtonLabel>
                  </S.ResetButton>
                </S.ResetItem>
              )}
              <S.ScrollableList>
                {showEmpty ? (
                  <S.NoResults>
                    {t('calendar:countrySelector.noResults')}
                  </S.NoResults>
                ) : (
                  renderOptionsContent()
                )}
              </S.ScrollableList>
            </>
          </S.DropdownMenuContent>
        </Radix.Portal>
      </Radix.Root>
    </div>
  );
};
