import React, { useRef } from 'react';

import {
  ChatInputComposer,
  ChatInputComposerProps,
  ChatInputConfig,
} from '~/components/chat';
import { isPathMatches } from '~/components/sidebar/utils/navigation-utils';
import { useNavigateAlfred } from '~/hooks/use-navigate-alfred/useNavigateAlfred';
import { useChat } from '~/views/reasoner/use-chat/useChat';
import { isAnyMessagePending } from '~/views/reasoner/use-chat/utils/use-chat-utils';

export interface ChatInputProps
  extends Pick<ChatInputComposerProps, 'mode' | 'setMode'> {
  config?: ChatInputConfig;
}

export const ChatDeepAnalysis = ({ config, ...rest }: ChatInputProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const navigateToAlfred = useNavigateAlfred();

  const { postQuestion, abortRequest, activeConversation } = useChat(state => ({
    postQuestion: state.postQuestion,
    abortRequest: state.abortRequest,
    activeConversation: state.conversationId
      ? state.conversations.find(c => c.id === state.conversationId)
      : undefined,
  }));

  const isAlfredPage = isPathMatches(['alfred']);
  const isLoading = isAlfredPage && isAnyMessagePending(activeConversation);

  const submitHandler = async (
    inputValue: string,
    onSubmitCallbackHandler: () => void
  ) => {
    const question = inputValue.trim();

    if (isLoading || !question) {
      return;
    }

    postQuestion(question, isAlfredPage ? activeConversation?.id : undefined);
    onSubmitCallbackHandler();

    if (!isAlfredPage) {
      navigateToAlfred('reasoner');
    }
  };

  const onSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    inputText: string,
    onSubmitCallbackHandler: () => void
  ) => {
    event.preventDefault();
    submitHandler(inputText, onSubmitCallbackHandler);
  };

  const onKeyDown = (
    _: React.KeyboardEvent<HTMLElement>,
    inputText: string,
    onSubmitCallbackHandler: () => void
  ) => {
    submitHandler(inputText, onSubmitCallbackHandler);
  };

  return (
    <ChatInputComposer
      ref={inputRef}
      handlers={{
        onSubmit,
        onKeyDown,
        onSelect: () => {},
        onAbort: abortRequest,
      }}
      config={{
        ...config,
        isLoading,
      }}
      {...rest}
    />
  );
};
