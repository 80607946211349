import { WithNAFallback } from '@toggle/design-system';
import { getPriceStatus } from '@toggle/helpers';
import { EconomicEventOutput } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RunScenario } from '../run-scenario/RunScenario';
import { SummaryTable } from '../summary-table/SummaryTable';
import { getConditionOptions } from '../utils/getConditionOptions';
import { formatNumber } from '../utils/number-formatter';
import * as S from './EconomicsEventDrawerContent.styles';

export interface EconomicsEventDrawerContentProps {
  data: EconomicEventOutput;
}

export const EconomicsEventDrawerContent = ({
  data,
}: EconomicsEventDrawerContentProps) => {
  const { t } = useTranslation('calendar');
  const {
    category,
    period,
    period_year,
    description,
    consensus,
    consensus_unit,
    actual,
    actual_unit: actualUnit,
    surprise,
    surprise_unit,
    name,
    snakes,
  } = data;

  const isBooleanEvent = !!snakes?.event;
  const actualUnitHeader = actualUnit ? [`(${actualUnit})`] : [''];

  const tableHeaders = [
    ...actualUnitHeader,
    t('calendar:expected'),
    t('calendar:reported'),
    t('calendar:surprise'),
  ];

  const tableRows = [
    {
      fieldName: name,
      consensus: (
        <WithNAFallback
          value={
            consensus !== undefined &&
            consensus !== null &&
            formatNumber(consensus_unit === '%', consensus)
          }
        />
      ),
      reported: (
        <WithNAFallback
          value={
            actual !== undefined &&
            actual !== null &&
            formatNumber(actualUnit === '%', actual)
          }
        />
      ),
      surprise: (
        <S.SurpriseWrapper $status={getPriceStatus(surprise ?? undefined)}>
          <WithNAFallback
            value={
              surprise !== undefined &&
              surprise !== null &&
              formatNumber((surprise_unit ?? actualUnit) === '%', surprise)
            }
          />
        </S.SurpriseWrapper>
      ),
    },
  ];

  const formattedPeriodLabel = period
    ? `${period}, ${period_year}`
    : period_year;
  const dateTitleLabel = `${category} ${formattedPeriodLabel}`;

  const conditionOptions = getConditionOptions({ t, data });

  return (
    <S.Container>
      <S.TitleWrapper data-testid="title-label">
        {dateTitleLabel}
      </S.TitleWrapper>
      {description && (
        <S.DescriptionWrapper data-testid="description-label">
          {description}
        </S.DescriptionWrapper>
      )}
      <SummaryTable
        title={t('calendar:summary')}
        tableHeaders={tableHeaders}
        tableRows={tableRows}
      />
      {!isBooleanEvent && !!conditionOptions.length && (
        <RunScenario data={data} conditionOptions={conditionOptions} />
      )}
    </S.Container>
  );
};
