import styled, { css } from 'styled-components';

import { Icon } from '../icon/Icon';

export const StyledIcon = styled(Icon)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      path:not(:first-child) {
        fill: var(--icon-disabled);
      }

      path:first-child {
        stroke: var(--icon-disabled);
      }
    `}
`;

export const Container = styled.span`
  position: relative;
  display: inline-block;
`;

export const Sparkles = styled.svg<{
  $isBullish: boolean;
  $isDisabled: boolean;
}>`
  position: absolute;
  right: ${({ $isBullish }) => ($isBullish ? '-1px' : '-2px')};
  top: ${({ $isBullish }) => ($isBullish ? '0' : '-4px')};
  fill: ${({ $isDisabled }) =>
    $isDisabled ? 'var(--icon-disabled)' : 'var(--icon-primary)'}; ;
`;
