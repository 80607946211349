import { ButtonGroup } from '@toggle/design-system';
import styled from 'styled-components';

export const HorizonSelectorRoot = styled.div`
  display: flex;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  background-color: var(--surface-default);

  ${ButtonGroup.Styles.ButtonGroupItem} {
    &:not(:first-child) {
      text-transform: uppercase;
      min-width: 37px;
    }
  }
`;
