import { checkCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import React from 'react';

import { BAR_HEIGHT, LABEL_WIDTH } from '../constants';
import * as S from './Bar.styles';

interface BarProps {
  value: number;
  xScale: d3.ScaleLinear<number, number, never>;
  yPosition: number;
}

export const Bar = ({ value, xScale, yPosition }: BarProps) => {
  const zeroXScale = xScale(0) > 0 ? xScale(0) : 0;
  const xPosition = value < 0 ? xScale(value) : zeroXScale;

  return (
    <S.Rect
      data-testid="bar"
      width={Math.abs(xScale(value) - zeroXScale)}
      x={xPosition + LABEL_WIDTH}
      y={yPosition}
      height={BAR_HEIGHT}
      $numericalStyle={checkCellValueStyle(value)}
    />
  );
};
