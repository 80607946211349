import React, {
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  ReactNode,
  RefAttributes,
  useState,
} from 'react';

import * as S from './Input.styles';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'size'> {
  size?: 'medium' | 'large';
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  hasError?: boolean;
  errorText?: ReactNode;
  helperText?: ReactNode;
  rightAlignText?: boolean;
  disabled?: boolean;
  prefix?: ReactNode;
  label?: ReactNode;
  'data-testid'?: string;
  variant?: 'fill' | 'outline';
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = 'large',
      leftIcon,
      rightIcon,
      hasError,
      errorText,
      helperText,
      rightAlignText,
      label,
      id,
      className,
      prefix,
      disabled = false,
      variant = 'fill',
      ...rest
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const onFocus = () => {
      setFocused(true);
    };

    const onBlur = () => {
      setFocused(false);
    };

    return (
      <S.Container
        ref={ref}
        className={className}
        data-testid="input-container"
      >
        {label && <S.StyledLabel htmlFor={id}>{label}</S.StyledLabel>}
        <S.InputWrapper
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid="input-wrapper"
          $error={hasError}
          $focused={focused}
          $disabled={disabled}
          $variant={variant}
        >
          {leftIcon && <S.LeftIcon>{leftIcon}</S.LeftIcon>}
          {prefix && <S.PrefixContainer>{prefix}</S.PrefixContainer>}
          <S.InputElement
            $size={size}
            $rightAlignText={rightAlignText || !!prefix}
            id={id}
            disabled={disabled}
            {...rest}
          />
          {rightIcon && <S.RightIcon>{rightIcon}</S.RightIcon>}
        </S.InputWrapper>
        {hasError && errorText && (
          <S.ErrorLabel data-testid="error-text">{errorText}</S.ErrorLabel>
        )}
        {!hasError && helperText && (
          <S.HelperLabel data-testid="helper-text">{helperText}</S.HelperLabel>
        )}
      </S.Container>
    );
  }
) as ForwardRefExoticComponent<InputProps & RefAttributes<HTMLInputElement>> & {
  Styles: typeof S;
};

Input.Styles = S;
