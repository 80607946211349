import { z } from 'zod';

export enum ConditionType {
  CrossesAbove = 'crosses_above',
  CrossesBelow = 'crosses_below',
  Above = 'above',
  Below = 'below',
  Between = 'between',
  RisesBy = 'rises_by',
  FallsBy = 'falls_by',
  RisesByLevel = 'rises_by_level',
  FallsByLevel = 'falls_by_level',
}

export const conditionSchema = z.object({
  entity: z.string(),
  entityName: z.string().optional(),
  formattedTicker: z.string().optional(),
  snake: z.string(),
  snakeName: z.string().optional(),
  condition: z.nativeEnum(ConditionType),
  order: z.number().optional(),
  value: z.number().optional(),
  duration: z.number().optional(),
  duration_unit: z.string().optional(),
  min_value: z.number().optional(),
  max_value: z.number().optional(),
  display: z.string().optional(),
  level: z.number().optional(),
  actualUnit: z.string().optional(),
});

export const periodSchema = z.enum(['1w', '2w', '1m', '3m', '6m']);
export const periods: Period[] = periodSchema._def.values;

export const predictionSchema = z.object({
  low: z.number(),
  median: z.number(),
  high: z.number(),
  relative_idx: z.number(),
});

const pastEpisodeSchema = z.object({
  data: z.array(z.number()).nullable(),
  date: z.string(),
});

export const episodeSchema = z.object({
  period: periodSchema,
  dates: z.array(pastEpisodeSchema),
  is_best: z.boolean().optional(),
  best: z.number(),
  worst: z.number(),
  median: z.number(),
  upside: z.number(),
  downside: z.number(),
});

export type Period = z.infer<typeof periodSchema>;
export type Condition = z.infer<typeof conditionSchema>;
export type Episode = z.infer<typeof episodeSchema>;
export type PastEpisode = z.infer<typeof pastEpisodeSchema>;
export type Prediction = z.infer<typeof predictionSchema>;
