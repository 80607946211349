import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EmptyStateRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
  gap: 16px;
`;

export const Description = styled.p`
  ${typographyV2.bodyBase};
  color: var(--text-default);
`;
