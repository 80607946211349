import { borderRadius } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Link = styled.a`
  color: var(--text-link);
  &:hover {
    color: var(--text-link);
  }
`;

export const StyledErrorMessageRoot = styled.div<{ $showBorder: boolean }>`
  height: 100%;
  padding: 38px 0px;
  ${borderRadius.r2}
  ${props =>
    props.$showBorder &&
    css`
      border: 1px solid var(--border-soft);
    `}
`;
