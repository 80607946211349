import { getOffset } from '@toggle/helpers';
import { User, UserAddress, UserAddressPayload } from '@toggle/toggle';
import ReactGA from 'react-ga4';

import { ApiFetchResponse } from '~/utils/api-fetch/apiFetch';

import { create } from '../create-store/createStore';
import {
  getUserAddress,
  getUserData,
  putUserAddress,
  putUserData,
  putUserTimezone,
} from './service/user-service';

interface UserStore {
  user?: User;
  address?: UserAddress;
  loading: boolean;
  error: boolean;
  initialize: () => Promise<ApiFetchResponse<User>>;
  updateUser: (user: Partial<User>) => Promise<void>;
  refreshUser: () => Promise<void>;
  updateUserAddress: (address: UserAddressPayload) => Promise<void>;
}

const syncTimeZone = async (tzName: string, tzOffset: number) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = getOffset();

  if (tzName !== timezone || tzOffset !== offset) {
    await putUserTimezone({
      name: timezone,
      offset,
    });
  }
};

export const useUser = create<UserStore>((set, get) => ({
  user: undefined,
  address: undefined,
  loading: false,
  error: false,
  initialize: async () => {
    set({ loading: true });
    const userData = await getUserData();
    const addressReq = await getUserAddress();
    const address = addressReq.error ? undefined : addressReq.data;

    if (userData.error) {
      set({ error: true, loading: false });
    } else {
      syncTimeZone(userData.data.tz_name, userData.data.tz_offset);
      set({
        address,
        user: userData.data,
        loading: false,
      });
      ReactGA.set({ userId: userData.data.id });
    }

    return userData;
  },
  refreshUser: async () => {
    const userData = await getUserData();

    if (userData.error) {
      set({ error: true, loading: false });
    } else {
      set({
        user: {
          ...(get().user as User),
          ...userData.data,
        },
        loading: false,
      });
    }
  },
  updateUser: async (dataToUpdate: Partial<User>) => {
    const { ...userData } = get().user as User;
    const updated = await putUserData({
      ...userData,
      ...dataToUpdate,
    });

    if (!updated.error) {
      set(state => ({
        user: {
          ...(state.user as User),
          ...updated.data,
        },
      }));
    }
  },

  updateUserAddress: async (address: UserAddressPayload) => {
    const response = await putUserAddress(address);

    if (!response.error) {
      set({ address: response.data });
    }
  },
}));
