import { Button, TabLines } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledTabLines = styled(TabLines)`
  padding: 0px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-default);
`;

export const TabsContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 20px;
  width: 100%;
  max-height: 60vh;
  overflow: hidden;
`;

export const ToggleWrapper = styled.div`
  display: inline-flex;
  gap: 8px;
  padding: 0px 8px;
`;

export const ItemText = styled.div`
  margin: 0px 4px;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

export const BottomBar = styled.div`
  position: sticky;
  bottom: 0;
  padding: 8px 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  background-color: var(--background-default);
`;
