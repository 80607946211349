import { BarChart } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '~/components/section-title/SectionTitle';

export interface ValuationMetricsProps {
  snakesData: {
    data: {
      value: number;
      index: string;
    }[];
    snake: string;
  }[];
  companies: {
    tag: string;
    formattedTicker: string;
    color: string;
  }[];
  snakeName: string;
  chartWidth: number;
  chartHeight: number;
}

export const ValuationMetrics = ({
  snakesData,
  companies,
  snakeName,
  chartWidth,
  chartHeight,
}: ValuationMetricsProps) => {
  const { t } = useTranslation('widget');

  const chartData = snakesData.map((d, index) => ({
    value: d.data?.[0]?.value ? d.data?.[0].value / 100 : undefined,
    label: companies[index].formattedTicker,
    color: companies[index].color,
  }));

  // Fill missing data with empty values
  while (chartData.length < companies.length) {
    chartData.push({
      value: undefined,
      label: companies[chartData.length].formattedTicker,
      color: companies[chartData.length].color,
    });
  }

  return (
    <div data-testid="valuation-metric">
      {/* i18next-extract-disable-next-line */}
      <SectionTitle
        title={t(`widget:assetOverview.snakeNames.${snakeName}`)}
        variant="small"
      />
      <BarChart
        data={chartData}
        height={chartHeight}
        width={chartWidth}
        showXAxisLabels={false}
      />
    </div>
  );
};
