import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './SearchSources.styles';

export const SearchSources = () => {
  const { t } = useTranslation('news');

  return (
    <S.SearchSourcesRoot data-testid="search-sources">
      <S.Title>{t('news:searchSources')}</S.Title>

      <S.Logotypes>
        <S.Img src="/app/static/images/logotypes/wsj.svg" alt="" />
        <S.Img src="/app/static/images/logotypes/marketwatch.svg" alt="" />
        <S.Img src="/app/static/images/logotypes/barrons.svg" alt="" />
        <S.Img src="/app/static/images/logotypes/mtn.png" alt="" />
      </S.Logotypes>
    </S.SearchSourcesRoot>
  );
};
