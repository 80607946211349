import { queryString } from '@toggle/helpers';
import { useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router';

import { appPaths, authPaths, queryKeys } from '~/routes/app-paths';
import { useAuth } from '~/stores/use-auth/useAuth';
import {
  postCheckResetPasswordToken,
  postResetPassword,
  ResetPasswordData,
} from '~/views/auth/services/auth-services';

interface AsyncActionState {
  isPending: boolean;
  isSuccess: boolean;
  error: string;
}

interface ResetPasswordHookResult {
  resetState: AsyncActionState;
  checkTokenState: AsyncActionState;
  checkToken: () => Promise<void>;
  resetPassword: (resetData: Omit<ResetPasswordData, 'token'>) => Promise<void>;
}

const initialAsyncState: AsyncActionState = {
  isPending: false,
  isSuccess: false,
  error: '',
};

export function useResetPasswordState(): ResetPasswordHookResult {
  const [resetState, setResetState] = useState(initialAsyncState);
  const [checkTokenState, setCheckTokenState] = useState(initialAsyncState);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const resetPassword = async (
    passwordData: Omit<ResetPasswordData, 'token'>
  ) => {
    setResetState({ ...initialAsyncState, isPending: true });
    const token = getTokenFromUrl(location);
    const result = await postResetPassword({
      password: passwordData.password,
      confirmedPassword: passwordData.confirmedPassword,
      token: token,
    });

    if (result.error) {
      setResetState({ ...initialAsyncState, error: 'internal' });
    } else {
      setResetState({ ...initialAsyncState, isSuccess: true });
      logout();
      navigate(
        authPaths.login +
          queryString({
            [queryKeys.redirect]: appPaths.settings.account,
          })
      );
    }
  };

  const checkToken = async () => {
    setCheckTokenState({ ...initialAsyncState, isPending: true });
    const token = getTokenFromUrl(location);

    if (!token) {
      setCheckTokenState({ ...initialAsyncState, error: 'notFound' });
      return;
    }

    const result = await postCheckResetPasswordToken(token);

    try {
      if (result.error) {
        throw result.error;
      }

      if (result.data.is_expired || result.data.is_used) {
        throw new Error('expired-used');
      }

      if (result.data.not_found) {
        throw new Error('notFound');
      }

      setCheckTokenState({ ...initialAsyncState, isSuccess: true });
    } catch (e) {
      setCheckTokenState({ ...initialAsyncState, error: (e as Error).message });
    }
  };

  return {
    resetPassword,
    resetState,
    checkToken,
    checkTokenState,
  };
}

function getTokenFromUrl(location: Location): string {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get('token') ?? '';
}
