import {
  loadStripe,
  StripeCardElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { useEffect, useRef, useState } from 'react';

import { config } from '~/config';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import { usePayments } from '../use-payments/usePayments';
import { StripeErrorTypeEnum } from './types';

export interface UseStripeFormProps {
  onSuccess?: () => void;
  hasError?: boolean;
  shouldUseStripeFormSubmit?: boolean;
}

export const stripePromise = loadStripe(config.stripePublicKey);

export const useStripeForm = ({
  onSuccess,
  hasError = false,
  shouldUseStripeFormSubmit = true,
}: UseStripeFormProps = {}) => {
  const [ready, setReady] = useState(false);
  const [stripeError, setStripeError] = useState(hasError);
  const [stripeErrorType, setStripeErrorType] =
    useState<StripeErrorTypeEnum | null>(null);
  const [inputError, setInputError] = useState<string>();
  const [formComplete, setFormComplete] = useState(false);
  const [isSubmittingCard, setIsSubmittingCard] = useState(false);
  const stripeFormRef = useRef<{
    onSubmit: () => Promise<null | string | StripeError>;
  }>(null);

  const { clientSecret, getClientSecret, confirmPayment, clearClientSecret } =
    usePayments();
  const getCard = useSubscription(state => state.getCard);

  useEffect(() => {
    getClientSecret();

    return () => {
      clearClientSecret();
    };
  }, []);

  const handleFormChange = (event: StripeCardElementChangeEvent) => {
    setFormComplete(event.complete);
    setStripeError(false);
    setStripeErrorType(null);
    setInputError(event.error?.message);
  };

  const onReady = () => {
    setReady(true);
  };

  const handleSubmit = async () => {
    if (!shouldUseStripeFormSubmit) {
      return true;
    }

    let isSubmitted = false;

    if (!stripeFormRef.current?.onSubmit) {
      return isSubmitted;
    }

    setIsSubmittingCard(true);
    const result = await stripeFormRef.current.onSubmit();

    if (typeof result === 'string') {
      await confirmPayment(result);
      await getCard();
      onSuccess?.();
      isSubmitted = true;
    } else if (result) {
      setStripeError(true);
      const errorType = result.type;

      if (errorType === 'invalid_request_error') {
        // 3D Secure failed
        setStripeErrorType(StripeErrorTypeEnum.threeDSecureFailed);
      } else {
        // Card declined
        setStripeErrorType(StripeErrorTypeEnum.verificationFailed);
      }
    }

    setIsSubmittingCard(false);
    return isSubmitted;
  };

  return {
    handleFormChange,
    clientSecret,
    ready,
    stripeError,
    inputError,
    formComplete,
    onReady,
    handleSubmit,
    stripeFormRef,
    isSubmittingCard,
    stripeErrorType,
  };
};
