import { SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '360px',
        borderRadius: '8px',
      },
    },
  ],
})``;
