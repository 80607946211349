import { isPriceSnake } from '@toggle/helpers';

import { isMergedAxis } from '~/core/axis/axis';
import { drawXAxis } from '~/core/axis/x-axis/drawXAxis';
import { drawChartUnit, drawYAxis } from '~/core/axis/y-axis/drawYAxis';
import { drawEpisodes } from '~/core/episodes-drawer/episodesDrawer';
import { drawBars } from '~/core/series/bars/bars';
import { drawCandles } from '~/core/series/candles/candles';
import { drawLine } from '~/core/series/line/line';
import {
  DataWithThreshold,
  drawThreshold,
  drawThresholdBands,
} from '~/core/threshold-drawer/thresholdDrawer';
import { BaseChartAPIProps, ChartPane } from '~/types/create.types';
import { SeriesType } from '~/types/series.types';
import { clearChart } from '~/utils/clearChart';
import { PANES_DIVIDER_HEIGHT } from '~/utils/constants';
import { drawPaneGradient } from '~/utils/gradient/gradient-utils';
import { getActivePanes } from '~/utils/pane/pane-utils';
import { getCurrentYIndex, getYAxisCoordinate } from '~/utils/y-scale/y-scale';

import { ChartStoreReturn } from './../chart-store/chartStore';

const mapSeriesTypeToDrawFn = {
  line: drawLine,
  bars: drawBars,
  candlestick: drawCandles,
};

// the order of drawing things matters if there is an indicator with thresholds in secondary panes
// context.globalCompositeOperation is used to paint elements on a different layer
// so that firstly we paint the line with it's color and then painting threshold with globalCompositeOperation
// then painting axis
export const reDraw = ({
  chartStore,
  hoveredPaneDivider,
}: {
  chartStore: ChartStoreReturn;
  hoveredPaneDivider?: number;
}) => {
  const base = chartStore.getState().base as BaseChartAPIProps;
  const { colors } = base.options;
  const chartPanes = base.panes;
  const primaryPane = chartPanes.find(p => p.id === base.primaryPaneId);

  const panesWithEpisodes = chartPanes
    .flatMap(p => p.chartAssetData)
    .filter(item => !item.isHidden && !!item.episodesData);

  clearChart(base.context, base.options);

  getActivePanes(chartPanes).forEach(pane => {
    const isAxisMerged = isMergedAxis(pane.yAxisType);
    let shouldDrawThresholdLines = false;

    for (let index = 0; index < pane.chartAssetData.length; index++) {
      const asset = pane.chartAssetData[index];
      const axisIndex = getCurrentYIndex({ isAxisMerged, pane, asset });
      const currentAssetY = pane.y[axisIndex];
      const domainTimeSeries = pane.domainTimeSeries[index];
      let seriesType: SeriesType = 'line';

      if (asset.isHidden || !currentAssetY || !domainTimeSeries) {
        continue;
      }

      const getYCoordinate = getYAxisCoordinate(
        pane.priceDisplay,
        currentAssetY,
        domainTimeSeries.start.close
      );

      seriesType = asset?.seriesType ?? pane.seriesType;

      shouldDrawThresholdLines =
        !!asset.snakeMeta?.threshold && currentAssetY.ticks.length > 1;
      base.context.save();

      const isAssetPriceSnake = isPriceSnake(asset.entity.default_snake);
      mapSeriesTypeToDrawFn[seriesType]({
        ...base,
        y: currentAssetY,
        getYCoordinate,
        timeseries: asset.ts,
        tsByTime: asset.tsByTime,
        primaryAsset: base.primaryAsset,
        lineColorOverride: colors.getThemeColor(asset.lineColorToken),
        domainTimeSeries,
        isPriceSnake: isAssetPriceSnake,
      });

      if (shouldDrawThresholdLines) {
        drawThreshold(base, asset as DataWithThreshold, pane);
      }

      if (!isAxisMerged) {
        const colorOverride =
          pane.chartAssetData.length === 1
            ? undefined
            : colors.getThemeColor(asset.lineColorToken);
        drawYAxis({
          ...base,
          y: currentAssetY,
          isPrimaryAsset: index === 0,
          colorOverride,
          priceDisplay: pane.priceDisplay,
          yAxisSize: base.yAxisSizes[axisIndex],
          asset,
        });
      }

      base.context.restore();
    }

    if (isAxisMerged) {
      drawYAxis({
        ...base,
        asset: pane.chartAssetData[0],
        yAxisSize: base.yAxisSizes[0],
        y: pane.y[0],
        priceDisplay: pane.priceDisplay,
        isPrimaryAsset: true,
      });
    }

    if (!shouldDrawThresholdLines && base.thresholdIntervals) {
      drawThresholdBands({
        base,
        thresholdIntervals: base.thresholdIntervals,
        pane,
      });
    }
  });

  if (panesWithEpisodes.length) {
    drawEpisodes({
      ...base,
      episodesData: panesWithEpisodes[0].episodesData,
      tsByTime: base.primaryAsset.tsByTime,
      isPriceSnake: isPriceSnake(base.primaryAsset.entity.default_snake),
    });
  }

  chartPanes.forEach((pane, index) => {
    if (index > 0) {
      drawPaneDivider({ base, pane, isHovered: hoveredPaneDivider === index });
    }

    if (pane !== primaryPane) {
      if (base.options.config.paneGradient) {
        drawPaneGradient({
          pane,
          context: base.context,
          options: base.options,
          gradientStops: base.options.config.paneGradient,
        });
      }
    }

    if (panesWithEpisodes.length) {
      chartPanes
        .filter(pane => pane.chartAssetData.length)
        .forEach(pane => {
          drawChartUnit({
            context: base.context,
            options: base.options,
            pane: pane,
            y: pane.y[0],
            displayFormat: pane.chartAssetData[0].snakeMeta?.display_format,
            currency: pane.chartAssetData[0].entity.currency,
            yAxisSize: base.yAxisSizes[0],
          });
        });
    }
  });

  drawXAxis(base);
};

export const drawPaneDivider = ({
  pane,
  base: { context, options },
  isHovered,
}: {
  base: BaseChartAPIProps;
  pane: ChartPane;
  isHovered: boolean;
}) => {
  context.save();

  if (!isHovered) {
    context.globalCompositeOperation = 'destination-over';
  }

  context.fillStyle = isHovered
    ? options.colors.border.hover.value
    : options.colors.border.soft.value;
  const yCoord = pane.options.gutters.top - PANES_DIVIDER_HEIGHT;
  context.fillRect(0, yCoord, options.width, PANES_DIVIDER_HEIGHT);
  context.restore();
};
