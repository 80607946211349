import { alfred } from '@toggle/toggle';
import { v4 } from 'uuid';

import { config } from '~/config/app.config';
import { create } from '~/stores/create-store/createStore';
import { useActions } from '~/stores/use-actions/useActions';
import { hasActions } from '~/stores/use-actions/utils/hasActions';
import { apiFetch } from '~/utils/api-fetch/apiFetch';
import { apiStream } from '~/utils/api-stream/apiStream';

import { ChatStore, MessageReply, MessageStatus } from './use-chat.types';
import { sortReplies } from './utils/sortReplies';
import {
  appendReply,
  createNewMessage,
  updateMessage,
} from './utils/updateMessage';

export const useChat = create<ChatStore>((set, get) => ({
  sessionId: v4(),
  controller: new AbortController(),
  messages: [],
  documentContextMessages: [],
  addDocumentContextMessage: message => {
    set(state => ({
      documentContextMessages: [...state.documentContextMessages, message],
    }));
  },
  clearDocumentContextMessages: () => {
    set({ documentContextMessages: [] });
  },
  isMessagePending: false,
  abortRequest: () => {
    get().controller.abort();
    set({ isMessagePending: false });
  },
  addFeedback: ({ sessionId, requestId, feedback }) => {
    apiFetch(alfred.feedback.path, {
      method: 'post',
      body: {
        session_id: sessionId,
        request_id: requestId,
        feedback,
      },
    });
    set(state => ({
      ...state,
      messages: state.messages.map(m => {
        return {
          ...m,
          reply: m.reply.map(reply =>
            'request_id' in reply && reply.request_id === requestId
              ? { ...reply, feedback }
              : reply
          ),
        };
      }),
    }));
  },
  resetSession: hideResetCompletedMessage => {
    const sessionId = v4();
    set(state => ({
      sessionId,
      messages: [
        ...state.messages,
        ...(hideResetCompletedMessage
          ? []
          : [createNewMessage({ status: 'complete', type: 'reset' })]),
      ],
    }));
  },
  append: message => {
    set(state => ({
      messages: [...state.messages, message],
    }));
  },
  setActionsFromReply: (primaryReply: MessageReply) => {
    if (hasActions(primaryReply?.data)) {
      useActions.getState().setActions(primaryReply.data.actions);
    }
  },
  removeReply: (messageId, source) => {
    set(({ messages }) => ({
      messages: messages.map(m => {
        if (m.id === messageId) {
          return { ...m, reply: m.reply.filter(r => r.source !== source) };
        }

        return m;
      }),
    }));
  },
  update: message => {
    set(state => ({
      messages: updateMessage({ messages: state.messages, message }),
    }));
  },
  sendMessage: async ({ question, target, ancillary }) => {
    useActions.getState().removeAllActions();
    const id = v4();
    const parsedTarget = target ?? 'all';
    const documents = ancillary?.documents;

    set(state => ({
      ...state,
      controller: new AbortController(),
      mostRecentTargetedAssistant: parsedTarget,
      messages: [
        ...state.messages,
        createNewMessage(
          {
            id,
            question,
          },
          parsedTarget,
          documents
        ),
      ],
      isMessagePending: true,
    }));

    const onComplete = (
      status: MessageStatus,
      hasExceededUsageLimit?: boolean
    ) => {
      const existingMessage = get().messages.find(m => m.id === id);

      if (existingMessage) {
        const sortedReplies = sortReplies(existingMessage.reply);
        const shouldAddDocumentContextMessage =
          existingMessage.target === 'document-assistant' ||
          sortedReplies.some(reply => reply.source === 'document-assistant');

        get().update({
          ...existingMessage,
          reply: sortedReplies,
          status,
          hasExceededUsageLimit,
        });

        const [primaryReply] = sortedReplies;

        if (status === 'complete') {
          get().setActionsFromReply(primaryReply || {});
        }

        if (shouldAddDocumentContextMessage) {
          get().addDocumentContextMessage({
            ...existingMessage,
            reply: existingMessage.reply.filter(
              r => r.source === 'document-assistant'
            ),
          });
        }
      }

      set(() => ({
        isMessagePending: false,
      }));
    };

    const onReadChunk = (reply: MessageReply[]) => {
      set(({ messages }) => ({
        messages: appendReply(messages, { id, reply }),
      }));
    };

    apiStream<MessageReply>({
      body: {
        question,
        session_id: get().sessionId,
        target,
        ancillary,
      },
      onReadChunk,
      onComplete: () => onComplete('complete'),
      onError: error => {
        const isUsageLimitExceeded = error?.status === 429;
        onComplete('error', isUsageLimitExceeded);
      },
      onAbort: () => onComplete('abort'),
      controller: get().controller,
      endpoint: `${config.api.root}${alfred.streaming.path}`,
    });
  },
  resetStore: () => {
    useActions.getState().removeAllActions();
    get().controller.abort();

    set(() => ({
      sessionId: v4(),
      messages: [],
      isMessagePending: false,
      documentContextMessages: [],
      priorDocumentContextReplyMap: {},
    }));
  },
  setMostRecentTargetedAssistant: source => {
    set({ mostRecentTargetedAssistant: source });
  },
  shouldResetStateOnSubmit: false,
  setShouldResetStateOnSubmit: value => {
    set({ shouldResetStateOnSubmit: value });
  },
  shouldTargetDocumentAssistant: false,
  setShouldTargetDocumentAssistant: value => {
    set({ shouldTargetDocumentAssistant: value });
  },
  isSearchWithinContext: true,
  setIsSearchWithinContext: value => {
    set({ isSearchWithinContext: value });
  },
  resetIsSearchWithinContext: () => {
    set({ isSearchWithinContext: true });
  },
  showContextHeader: false,
  setShowContextHeader: value => {
    set({ showContextHeader: value });
  },
  priorDocumentContextReplyMap: {},
  appendDocumentContextReply: (id, reply) => {
    set({
      priorDocumentContextReplyMap: {
        ...get().priorDocumentContextReplyMap,
        [id]: reply,
      },
    });
  },
}));
