import { Tooltip, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { AssetLogoContainer } from '../asset-logo-container/AssetLogoContainer';

export const ThumbnailButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  width: 100%;
  border: none;
  color: inherit;
  background-color: transparent;
  text-align: left;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: var(--surface-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--border-focus);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-soft);
  }

  mark {
    color: var(--text-primary);
    background-color: transparent;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)`
  margin-left: -4px;
  border-radius: 50%;
  border: 1px solid var(--background-default);

  &:first-child {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 0px;
  }
`;

export const Headline = styled.div`
  ${typographyV2.bodyBase};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Body = styled.div`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTooltip = styled(Tooltip)`
  padding: 4px 8px;
`;

export const TooltipWrapper = styled.div`
  display: table-row;
`;

export const EntityTicker = styled.span`
  ${typographyV2.uiXs}
  display: table-cell;
`;

export const EntityShortName = styled.span`
  ${typographyV2.bodyXs}
  color: var(--text-soft);
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: table-cell;
  padding-left: 8px;
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
`;

export const MetadataWrapper = styled.p`
  ${typographyV2.uiXs}
  display: flex;
  align-self: flex-end;
  font-weight: 400;
  color: var(--text-soft);
`;

export const MetaItem = styled.span`
  &:not(:last-child)::after {
    content: '·';
    margin: 0 0.5em;
  }
`;
