import { FilterCustomOptionMeta } from '@toggle/toggle';
import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';
import { z } from 'zod';

import * as S from './CustomFilterModalInput.styles';

const MINIMUM = -999999999999999;
const MAXIMUM = 999999999999999;

export interface InputState {
  units: string;
  scale: number;
  values: Record<string, { number: number; string: string }>;
}

export type CustomFilterModalInputProps = {
  input?: InputState;
  handleChange: (inputState: InputState) => void;
  meta: FilterCustomOptionMeta & {
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
  };
};

const numFormatter = new Intl.NumberFormat(window.navigator.language, {
  notation: 'compact',
});

export const CustomFilterModalInput = ({
  input,
  handleChange,
  meta,
}: CustomFilterModalInputProps) => {
  const { value_field, units, leftIcon, rightIcon } = meta;

  const onInputChange =
    (key: string, meta: FilterCustomOptionMeta) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { units = '', scale = 1 } = meta;
      const string = e.target.value.trim();
      const number = +string;

      const schemaMap: Record<string, z.ZodNumber> = {
        integer: z.number().int().min(MINIMUM).max(MAXIMUM),
        float: z.number().min(MINIMUM).max(MAXIMUM).multipleOf(0.01),
      };

      const schema = meta.value_type ? schemaMap[meta.value_type] : z.number();

      const valid =
        schema.safeParse(number).success ||
        !string || // to allow backspace to empty input
        string == '-'; //to allow negative numbers

      if (valid) {
        handleChange({
          units,
          scale,
          values: {
            ...input?.values,
            [key]: { number, string },
          },
        });
      }
    };

  const onFocusToggle =
    (format: (v: number) => string) => (e: FocusEvent<HTMLInputElement>) => {
      const number = input?.values[e.target.name]?.number;

      if (number) {
        handleChange(
          input && {
            ...input,
            values: {
              ...input.values,
              [e.target.name]: {
                string: format(number),
                number,
              },
            },
          }
        );
      }
    };

  if (!value_field) {
    return <span>{meta.text}</span>;
  }

  const inputOptionsMap: Record<string, Object> = {
    integer: {
      onBlur: onFocusToggle(numFormatter.format),
      onFocus: onFocusToggle(v => v.toString()),
      'data-testid': `${value_field}-custom-int-input`,
    },
    float: {
      'data-testid': `${value_field}-custom-float-input`,
    },
  };
  const inputOptions = meta.value_type ? inputOptionsMap[meta.value_type] : {};

  return (
    <S.StyledInput
      type="text"
      value={input?.values[value_field]?.string ?? ''}
      onChange={onInputChange(value_field, meta)}
      $textAlign={leftIcon && rightIcon ? 'right' : 'left'}
      leftIcon={meta.leftIcon}
      rightIcon={meta.rightIcon ?? units}
      {...inputOptions}
    />
  );
};
