import React, { ReactElement, ReactNode } from 'react';

import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import { BadgeColor } from '../badge/Badge';
import { Tooltip, TooltipProps, TooltipTrigger } from '../tooltip/Tooltip';
import * as S from './TabLines.styles';

export interface Tab<T> {
  tabKey: T;
  tabLabel?: ReactNode;
  badgeLabel?: string;
  badgeColor?: BadgeColor;
  imgNode?: ReactNode;
  iconName?: SvgIconNames;
  customBottomElement?: ReactElement;
  tooltip?: Omit<TooltipProps, 'children'>;
  renderPanel?: ReactNode;
  disabled?: boolean;
}

export interface TabLinesProps<T> {
  tabs: Tab<T>[];
  activeTab: T;
  fullWidth?: boolean;
  onChange: (index: number, tabKey: T) => void;
}

export const TabLines = <T extends string>({
  tabs,
  activeTab,
  onChange,
  fullWidth,
  ...rest
}: TabLinesProps<T>) => {
  const currentPanel = tabs.find(i => i.tabKey === activeTab)?.renderPanel;

  return (
    <S.TabContainer data-testid="tab-lines" {...rest}>
      <S.Header>
        {tabs.map(
          (
            {
              tabKey,
              tabLabel,
              badgeLabel,
              badgeColor,
              tooltip,
              iconName,
              customBottomElement,
              disabled = false,
            },
            index
          ) => (
            <Tooltip
              key={tabKey}
              disabled={disabled || !tooltip?.label}
              label={tooltip?.label}
              closeLabel={tooltip?.closeLabel ? tooltip.closeLabel : ''}
              isTouchDevice={
                tooltip?.isTouchDevice ? tooltip.isTouchDevice : false
              }
              strategy="fixed"
              placement="top"
              trigger={[TooltipTrigger.Hover]}
              inPortal
            >
              <S.Tab
                $fullWidth={fullWidth}
                data-testid={`tab-container-${tabKey}`}
              >
                <S.TabButton
                  onClick={() => {
                    onChange(index, tabKey);
                  }}
                  data-testid={`tab-${tabKey}`}
                  $active={activeTab === tabKey}
                  $fullWidth={fullWidth}
                  disabled={disabled}
                  role="tab"
                >
                  {iconName && (
                    <S.IconWrapper>
                      <S.TabIcon
                        $active={activeTab === tabKey}
                        iconName={iconName}
                        size={20}
                        fillColor="var(--text-on-primary)"
                      />
                    </S.IconWrapper>
                  )}
                  {tabLabel}
                  {customBottomElement}
                </S.TabButton>
                {badgeLabel && (
                  <div data-testid="tab-badge">
                    <S.StyledBadge
                      label={badgeLabel}
                      variant="numerical"
                      color={badgeColor}
                    />
                  </div>
                )}
              </S.Tab>
            </Tooltip>
          )
        )}
      </S.Header>
      <S.TabPanel>{currentPanel}</S.TabPanel>
    </S.TabContainer>
  );
};

TabLines.Styled = S;
