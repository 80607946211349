import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';

export const themes = {
  ...darkTheme,
  ...lightTheme,
};

export enum ColorScheme {
  Dark = 'dark',
  Light = 'light',
}

export enum ThemeVariant {
  Default = 'default',
  Inverted = 'inverted',
  AsianStandard = 'asian',
  Deuteranopia = 'deuteranopia',
  Protanopia = 'protanopia',
}

export type ThemeName = keyof typeof themes;
export type DesignToken = keyof typeof darkTheme.dark_default;

export const DEFAULT_THEME: ThemeName = 'dark_default';

export const accessibilityVariants = [
  ThemeVariant.Deuteranopia,
  ThemeVariant.Protanopia,
];

export const themeVariants = Object.values(ThemeVariant);
export const colorSchemes = Object.values(ColorScheme);
