import {
  EarningsItemWithEntity,
  earningsItemWithEntitySchema,
  EconomicEventOutput,
  economicEventOutputSchema,
  KeyDevsObjectWithEntity,
  keyDevsObjectWithEntitySchema,
} from '@toggle/toggle';

export type CalendarEvents =
  | EarningsItemWithEntity
  | EconomicEventOutput
  | KeyDevsObjectWithEntity;

export const isEconomicEvent = (
  event: CalendarEvents
): event is EconomicEventOutput => {
  const { success } = economicEventOutputSchema.safeParse(event);
  return success;
};

export const isCompanyEvent = (
  event: CalendarEvents
): event is KeyDevsObjectWithEntity => {
  const { success } = keyDevsObjectWithEntitySchema.safeParse(event);
  return success;
};

export const isEarningsEvent = (
  event: CalendarEvents
): event is EarningsItemWithEntity => {
  const { success } = earningsItemWithEntitySchema.safeParse(event);
  return success;
};
