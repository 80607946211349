import { ScreenerFilterOption } from '@toggle/toggle';

export const mapOptions = (options?: ScreenerFilterOption[]) => {
  if (!options) {
    return [];
  }

  return options.flatMap(o => {
    let keys = [] as ScreenerFilterOption[];

    if (o.children) {
      keys = mapOptions(o.children);
    }

    return [o, ...keys];
  });
};

export const getIsOptionSelected = (
  option: ScreenerFilterOption,
  selectedOptions: ScreenerFilterOption[]
) => {
  const childlessOptions = mapOptions(option.children).filter(
    o => !o?.children?.length
  );
  const allSelectedChildren =
    !!childlessOptions.length &&
    childlessOptions.every(c =>
      selectedOptions.some(s => s.value.code === c.value.code)
    );

  return (
    selectedOptions.some(s => s.value.code === option.value.code) ||
    allSelectedChildren
  );
};

export const getIsPartiallyOrAllSelected = (
  option: ScreenerFilterOption,
  selectedOptions: ScreenerFilterOption[]
) => {
  const childrenOptions = mapOptions(option.children).filter(
    o => !o?.children?.length
  );
  const selectedChildren = childrenOptions.filter(c =>
    selectedOptions.some(s => s.value.code === c.value.code)
  );

  const hasChildren = !!childrenOptions.length;
  const isOptionSelected = selectedOptions.some(
    s => s.value.code === option.value.code
  );

  const isAllSelected = hasChildren
    ? selectedChildren.length > 0 &&
      selectedChildren.length === childrenOptions.length
    : isOptionSelected;

  return {
    isPartial:
      selectedChildren.length > 0 &&
      selectedChildren.length < childrenOptions.length,
    isAll: isAllSelected,
  };
};

export const getHighlightedOptionValues = ({
  options,
  searchQuery,
}: {
  options?: ScreenerFilterOption[];
  searchQuery: string;
}) => {
  if (!options || !searchQuery) {
    return [];
  }

  const results: string[] = options.flatMap(o => {
    let items = [] as string[];

    if (o.name.toUpperCase().includes(searchQuery.toLocaleUpperCase())) {
      const value = o.value.code as string;
      items.push(value);

      if (o.children) {
        const test = getHighlightedOptionValues({
          options: o.children,
          searchQuery,
        });
        items.push(...test);
      }
    } else {
      return getHighlightedOptionValues({
        options: o.children,
        searchQuery,
      });
    }

    return items;
  });

  return results;
};

export const isChildrenExpanded = (
  option: ScreenerFilterOption,
  expandedItems: string[]
) => {
  const childrenOptions = mapOptions(option.children);
  const expandedChildren = childrenOptions.some(c => {
    const value = c.value.code as string;
    return value ? expandedItems.includes(value) : false;
  });

  return expandedChildren;
};

export const isOptionExpanded = ({
  expandedItems,
  option,
}: {
  expandedItems: string[];
  option: ScreenerFilterOption;
}) => {
  const filteredExpandedItems = expandedItems.filter(Boolean);
  const optionValue = option.value.code as string;

  return (
    (optionValue && expandedItems.includes(optionValue)) ||
    isChildrenExpanded(option, filteredExpandedItems)
  );
};

export const getSectorActiveOptions = ({
  options,
  activeOptions,
}: {
  options: ScreenerFilterOption[];
  activeOptions: ScreenerFilterOption[];
}) => {
  const activeTopLevelSectors = options
    .map(option => {
      const { isPartial, isAll } = getIsPartiallyOrAllSelected(
        option,
        activeOptions
      );

      if (isPartial || isAll) {
        return option.value.code;
      }

      return;
    })
    .filter(x => !!x);

  return options.filter(o => activeTopLevelSectors.includes(o.value.code));
};

export const filterOptionsByHighlightedValues = (
  options: ScreenerFilterOption[],
  selectedIds: string[]
) => {
  const filterNode = (
    node: ScreenerFilterOption
  ): ScreenerFilterOption | undefined => {
    if (selectedIds.includes(node.key)) {
      return { ...node };
    }

    if (node.children) {
      const filteredChildren = node.children.map(filterNode).filter(x => !!x);

      if (filteredChildren.length > 0) {
        return { ...node, children: filteredChildren };
      }
    }

    return undefined;
  };

  return options.map(filterNode).filter(x => !!x);
};
