import {
  getBarColor,
  getTimefromSeries,
  isBarBullish,
} from '~/utils/timeseries/time-series';

import { getCandleWidth } from '../candles/candles';
import { DrawLineProps } from '../line/line';

export const drawBars = ({
  x,
  options,
  context,
  timeseries,
  domain,
  getYCoordinate,
  tsByTime,
  primaryAsset,
  isPriceSnake,
}: DrawLineProps) => {
  const barLineWidth = 1;
  const sideWidth = getCandleWidth(x) / 2;
  const drawOpenClose = sideWidth >= 0.5;
  const xMax = x.xScale.range()[1];

  for (let i = Math.floor(domain[0]); i <= domain[1]; i++) {
    const time = getTimefromSeries(isPriceSnake, primaryAsset.ts[i].time);
    const idx = tsByTime.get(time);

    if (idx === undefined) {
      continue;
    }

    const bar = timeseries[idx];
    const isBullish = isBarBullish(bar);
    const color = getBarColor(isBullish, options);
    context.fillStyle = color;

    const xCoord = x.xScale(i);
    const high = getYCoordinate(bar.high);
    const low = getYCoordinate(bar.low);
    const barHigh = Math.min(high, low);
    const barLow = Math.max(high, low);

    const bodyCenter = Math.round(xCoord);
    const bodyTop = Math.round(barHigh);
    const bodyBottom = Math.round(barLow);
    const bodyHeight = Math.max(bodyBottom - bodyTop, barLineWidth);
    const bodyLeft = bodyCenter;
    const bodyRight = bodyCenter + barLineWidth - 1;

    context.fillRect(bodyCenter, bodyTop, barLineWidth, bodyHeight);

    if (drawOpenClose) {
      const openLeft = bodyCenter - sideWidth;
      const barOpen = getYCoordinate(bar.open);
      let openTop = Math.max(bodyTop, Math.round(barOpen));
      let openBottom = openTop + barLineWidth - 1;

      if (openBottom > bodyTop + bodyHeight - 1) {
        openBottom = bodyTop + bodyHeight - 1;
        openTop = openBottom - barLineWidth + 1;
      }

      context.fillRect(
        openLeft,
        openTop,
        bodyLeft - openLeft,
        openBottom - openTop + 1
      );

      const closeRight = bodyCenter + sideWidth;
      const barClose = getYCoordinate(bar.close);
      let closeTop = Math.max(bodyTop, Math.round(barClose));
      let closeBottom = closeTop + barLineWidth - 1;

      if (closeBottom > bodyTop + bodyHeight - 1) {
        closeBottom = bodyTop + bodyHeight - 1;
        closeTop = closeBottom - barLineWidth + 1;
      }

      context.fillRect(
        bodyRight + 1,
        closeTop,
        Math.min(xMax - bodyRight + 1, closeRight - bodyRight),
        closeBottom - closeTop + 1
      );
    }
  }
};
