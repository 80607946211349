import {
  Chip,
  COLLAPSED_WIDTH_PX,
  customScrollMinimal,
  EXPANDED_WIDTH_PX,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div<{ $isSidebarExpanded: boolean }>`
  position: fixed;
  inset: 0;
  left: ${({ $isSidebarExpanded }) =>
    $isSidebarExpanded ? EXPANDED_WIDTH_PX : COLLAPSED_WIDTH_PX};
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  background: var(--background-default);
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  & > section {
    flex-grow: 1;
  }

  & > section:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    min-width: 400px;
    border-right: 1px solid var(--border-soft);
  }
`;

export const StyledChip = styled(Chip)`
  margin-top: 12px;
`;

export const List = styled.ul`
  width: 100%;
  list-style-type: none;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--background-soft);
  ${customScrollMinimal};
`;

export const SourcesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SourcesHeader = styled.div`
  padding: 16px;
  background: var(--background-soft);
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  min-width: var(--radix-popper-anchor-width);
  z-index: var(--z-tooltip);
`;

export const DocumentLoaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SourcesListHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SourcesListHeader = styled.h3`
  color: var(--text-primary);
  ${typographyV2.titleBase};
`;

export const SourcesListSubheader = styled.div`
  ${typographyV2.bodySm};
  color: var(--text-primary);
`;

export const HeaderAndToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GroupByDocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 8px;
`;

export const GroupByDocumentLabel = styled.span<{ $hasData: boolean }>`
  ${typographyV2.bodySm};
  color: ${({ $hasData }) =>
    $hasData ? 'var(--text-default)' : 'var(--text-disabled)'};
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: var(--background-soft);
  width: 100%;
  border-bottom: 1px solid var(--border-default);

  span {
    ${typographyV2.bodyXs};
    color: var(--text-soft);
  }
`;
