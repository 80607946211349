import { formatPercentage } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { FC, useId } from 'react';

import { getHumanFriendlyTicks } from '../helpers/chart-ticks/chart-ticks';
import * as Shared from '../SharedStyles.styles';
import {
  HighLowBar,
  HighLowMedianDataPoint,
} from './components/high-low-bar/HighLowBar';
import * as S from './HighLowChart.styles';

export interface HighLowChartProps {
  data: HighLowMedianDataPoint[];
  width: number;
  height: number;
  padding?: {
    left: number;
    right: number;
  };
  alt?: string;
}

const margin = {
  top: 20,
  right: 50,
  bottom: 30,
  left: 0,
};

const defaultPadding = {
  left: 85,
  right: 150,
};

export const HighLowChart: FC<HighLowChartProps> = ({
  data,
  width,
  height,
  padding = defaultPadding,
  alt,
}) => {
  const chartId = useId();
  const [xMin, xMax] = [0, data.length - 1];
  const yMax = d3.max(data, d => d.high) ?? 0;
  const yMin = d3.min(data, d => d.low) ?? 0;

  const { chartTicks, maxRounded, minRounded } = getHumanFriendlyTicks({
    min: yMin,
    max: yMax,
  });

  const xScale = d3
    .scaleLinear()
    .domain([xMin, xMax])
    .range([margin.left + padding.left, width - margin.left - padding.right]);

  const yScale = d3
    .scaleLinear()
    .domain([minRounded, maxRounded])
    .range([height - margin.bottom, margin.top]);

  return (
    <S.HighLowChartRoot>
      <Shared.RootSVG
        viewBox={`0 0 ${width} ${height}`}
        data-testid="high-low-chart"
      >
        {alt && <title>{alt}</title>}
        <g>
          {chartTicks.map(tickValue => (
            <g key={tickValue} transform={`translate(0, ${yScale(tickValue)})`}>
              <Shared.AxisLine
                x1={margin.left}
                x2={width - margin.right}
                strokeWidth={tickValue === 0 ? 2 : 1}
              />
              <Shared.AxisText
                textAnchor="end"
                x={width}
                alignmentBaseline="middle"
              >
                {formatPercentage(tickValue, { suffix: '%' })}
              </Shared.AxisText>
            </g>
          ))}
        </g>

        <g transform={`translate(0, ${height})`}>
          {data.map((item, index) => (
            <Shared.AxisText
              key={`${chartId}-${index}`}
              x={xScale(index)}
              textAnchor="middle"
            >
              {item.label}
            </Shared.AxisText>
          ))}
        </g>

        {data.map((dataPoint, index) => {
          const yHigh = yScale(dataPoint.high);
          const yLow = yScale(dataPoint.low);
          const yMedian = yScale(dataPoint.median);
          const y0 = yScale(0);

          return (
            <g
              key={`${chartId}-${index}`}
              transform={`translate(${xScale(index)}, 0)`}
            >
              <HighLowBar
                yMedian={yMedian}
                yLow={yLow}
                yHigh={yHigh}
                y0={y0}
                dataPoint={dataPoint}
                numberFormatter={value =>
                  formatPercentage(value, { suffix: '%' })
                }
              />
            </g>
          );
        })}
      </Shared.RootSVG>
    </S.HighLowChartRoot>
  );
};
