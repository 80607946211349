import { TFunction } from 'i18next';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';
import { appPaths, authPaths } from '~/routes/app-paths';

const {
  app: { version },
  urls: { socialMediaImageLink },
} = config;

interface AppMetadataProps {
  path: string;
}

export const getTitleFromPath = (path: string, t: TFunction) => {
  if (path.includes(appPaths.settings.subscription)) {
    return t('seo:settingsSubscription.title');
  }

  if (path.includes(appPaths.screener)) {
    return t('seo:screener.title');
  }

  if (path.includes(appPaths.themeOverview)) {
    return t('seo:themeOverview.title');
  }

  if (path.includes(appPaths.watchlist)) {
    return t('seo:watchlist.title');
  }

  const titles = {
    [appPaths.base]: t('seo:base.title'),
    [appPaths.alfred]: t('seo:alfred.title'),
    [appPaths.chart]: t('seo:chart.title'),
    [authPaths.login]: t('seo:login.title'),
    [authPaths.signUp]: t('seo:signUp.title'),
    [authPaths.forgotPassword]: t('seo:forgotPassword.title'),
    [appPaths.settings.appearance]: t('seo:settingsAppearance.title'),
    [appPaths.settings.account]: t('seo:settingsAccount.title'),
    [appPaths.assetOverview]: t('seo:assetOverview.title'),
    [appPaths.events]: t('seo:events.title'),
    [appPaths.scenario]: t('seo:scenario.title'),
    [appPaths.insights]: t('seo:insights.title'),
    [appPaths.news]: t('seo:news.title'),
    [appPaths.watchlistManager]: t('seo:watchlistManager.title'),
    [appPaths.verifyEmail]: t('seo:verifyEmail.title'),
    [appPaths.accountOnHold]: t('seo:accountOnHold.title'),
    [appPaths.error]: t('seo:error.title'),
  };
  return titles[path] || t('seo:default.title');
};

export const AppMetadata = ({ path }: AppMetadataProps) => {
  const { t } = useTranslation('seo');
  const title = getTitleFromPath(path, t);

  const description = t('seo:default.description');

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="tog-version" content={version} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://reflexivity.com" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={socialMediaImageLink} />
      <meta property="og:image:secure_url" content={socialMediaImageLink} />
      <meta
        property="og:image:alt"
        content="TOGGLE. Invest rationally, not rashly"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
    </Helmet>
  );
};
