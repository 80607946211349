import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FiltersPanel } from '~/views/screener/filters-panel/FiltersPanel';

import {
  ScenarioPageModalType,
  useScenarioStore,
} from '../../hooks/use-scenario-store/useScenarioStore';
import { AssistantConditions } from '../assistant-conditions/AssistantConditions';
import { ConditionsFilters } from '../conditions-filters/ConditionsFilters';
import * as S from './ScenarioFilters.styles';

export interface ScenarioFiltersProps {
  filtersChildren?: ReactNode;
  onEditAssistantCondition?: (filterKey: string) => void;
  resetAskedQuestions(): void;
}

export const ScenarioFilters = ({
  filtersChildren,
  onEditAssistantCondition,
  resetAskedQuestions,
}: ScenarioFiltersProps) => {
  const { t } = useTranslation('scenario');

  const { setActiveScenarioPageModal } = useScenarioStore(state => ({
    setActiveScenarioPageModal: state.setActiveScenarioPageModal,
  }));

  const onAddAnotherCondition = () => {
    setActiveScenarioPageModal(ScenarioPageModalType.addCondition);
    resetAskedQuestions();
  };

  return (
    <FiltersPanel
      title={t('scenario:scenarioConditions')}
      data-testid="scenario-filters"
    >
      <S.HeaderWrapper>
        <S.FiltersWrapper>
          <ConditionsFilters />
          <AssistantConditions
            onEditAssistantCondition={onEditAssistantCondition}
          />

          <S.DashedButton
            data-testid="add-another-condition-btn"
            size="small"
            variant="secondary"
            label={t('scenario:filters.addAnotherCondition')}
            iconPosition="right"
            iconName="AlfredNav"
            onClick={onAddAnotherCondition}
          />
        </S.FiltersWrapper>

        {filtersChildren}
      </S.HeaderWrapper>
    </FiltersPanel>
  );
};
