import { formatTicker } from '@toggle/toggle';

import { postEntity } from '~/services/entities/entity-service';
import { fetchRelatedCompanies } from '~/services/related-companies/related-companies-service';
import { useActions } from '~/stores/use-actions/useActions';
import { useChat } from '~/stores/use-chat/useChat';
import { createNewMessage } from '~/stores/use-chat/utils/updateMessage';

interface CreateAssetOverviewMessageProps {
  entityTag: string;
}

export const createAssetOverviewMessage = async ({
  entityTag,
}: CreateAssetOverviewMessageProps) => {
  const { append, update } = useChat.getState();
  const { removeAllActions, setActions } = useActions.getState();

  const message = createNewMessage({ status: 'pending', question: entityTag });
  append(message);
  removeAllActions();

  try {
    const [entityResponse, relatedCompanies] = await Promise.all([
      postEntity(entityTag),
      fetchRelatedCompanies(entityTag),
    ]);

    if (entityResponse.error || !entityResponse.data) {
      throw entityResponse.error;
    }

    const entity = entityResponse.data;
    const entityName = entity.name_short || entity.name_full || entity.name;
    const formattedTicker = formatTicker(entity);
    const assetName = `${entityName} (${formattedTicker})`;

    update({
      ...message,
      status: 'complete',
      question: assetName,
      reply: [
        {
          source: 'asset-overview-widget',
          data: {
            confidence: 1,
            scores: {},
            entity,
          },
        },
      ],
    });

    const hasAnyRelatedCompany =
      !!relatedCompanies &&
      (relatedCompanies.competitors.length > 0 ||
        relatedCompanies.suppliers.length > 0);

    if (hasAnyRelatedCompany) {
      setActions([
        {
          action: 'chart-comparison:compare-entities',
          display: assetName,
          data: {
            entity,
            relatedCompanies,
          },
        },
        {
          action: 'watchlist:create-related-companies',
          display: 'watchlist:create-related-companies',
          data: {
            relatedCompanies,
          },
        },
      ]);
    }
  } catch (error) {
    update({
      ...message,
      status: 'error',
    });
  }
};
