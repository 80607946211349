import React, { FC, ReactNode, RefObject } from 'react';

import { DocumentMessageLoader } from '~/views/intel/messages/document-message/components/DocumentMessageLoader';

import * as S from './ChatQuestionResponse.styles';

export interface ChatQuestionResponseProps {
  title: ReactNode;
  children: ReactNode;
  chatPrompts?: ReactNode[];
  contentRef?: RefObject<HTMLDivElement | null>;
  isQuestionPending?: boolean;
  isDocumentMessage?: boolean;
  loaderNode?: ReactNode;
  rightContent?: ReactNode;
  hasExceededUsageLimit?: boolean;
}

export const ChatQuestionResponse: FC<ChatQuestionResponseProps> = ({
  title,
  children,
  chatPrompts,
  contentRef,
  isQuestionPending = false,
  isDocumentMessage = false,
  loaderNode = <S.Loader />,
  rightContent,
  hasExceededUsageLimit,
}) => {
  const getLoader = () =>
    isDocumentMessage ? <DocumentMessageLoader /> : loaderNode;

  return (
    <S.Root>
      <S.Container>
        <S.Body
          data-testid="question-answer-body"
          $withBorder={!hasExceededUsageLimit}
        >
          <S.Title
            data-testid="question-answer-title"
            $useRoundedBottomBorders={!!hasExceededUsageLimit}
          >
            {title}
          </S.Title>
          {isQuestionPending ? (
            <S.LoaderWrapper>{getLoader()}</S.LoaderWrapper>
          ) : (
            <S.Content
              data-testid="question-answer-content"
              ref={contentRef}
              $usePadding={!hasExceededUsageLimit}
            >
              {children}
            </S.Content>
          )}
        </S.Body>
        {chatPrompts && (
          <S.ChatPromptsContainer>{chatPrompts}</S.ChatPromptsContainer>
        )}
      </S.Container>
      {rightContent}
    </S.Root>
  );
};
