import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { ChatInputProps } from '~/components/chat';
import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { useDocumentsEntityCount } from '~/hooks/use-documents-entity/useDocumentsEntity';
import { useEntity } from '~/hooks/use-entities';
import { AO_WIDGET_RECENTLY_OPENED_ENTITIES } from '~/hooks/use-remote-storage';
import { useSnakeMeta } from '~/hooks/use-snake/useSnake';
import { AssetOverviewHeader } from '~/modules/asset-overview-header/AssetOverviewHeader';
import { TabContent } from '~/modules/tab-content/TabContent';
import { TabsType } from '~/modules/tab-content/types';
import { appPaths, queryKeys } from '~/routes/app-paths';
import { useApp } from '~/stores/use-app/useApp';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { ChartSearchParams } from '~/utils/chart-search-utils/chart-search-utils';
import { useEarningsStore } from '~/widgets/earnings/useEarningsStore/useEarningsStore';

import { useRecentList } from '../turbo-chart/hooks/use-recent-list/useRecentList';
import * as S from './AssetOverview.styles';

const STICKY_HEIGHT_MAP = {
  [TabsType.Financials]: 57,
  [TabsType.Documents]: 41,
  [TabsType.Earnings]: 87,
};

interface AssetOverviewProps {
  renderChatInput?: (props?: ChatInputProps) => ReactElement;
}

export const AssetOverview = ({ renderChatInput }: AssetOverviewProps) => {
  const navigate = useNavigate();
  const isBasicUser = useSubscription(state => state.isBasicUser());
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(TabsType.Overview);
  const [showShadow, setShowShadow] = useState(true);
  const { addAssetToRecent } = useRecentList();
  const { setSidebarExpanded } = useApp(state => ({
    isSidebarExpanded: state.isSidebarExpanded,
    setSidebarExpanded: state.setSidebarExpanded,
  }));
  const assetParam =
    searchParams.get(ChartSearchParams.Asset)?.split(',') ?? [];
  const assetData = assetParam[assetParam.length - 1];

  const { resetStore: resetEarningsStore } = useEarningsStore();
  const { data, refetch, isLoading, isRefetching } = useEntity(assetData);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const overviewTabChartContainerRef = useRef<HTMLDivElement>(null);

  const entity = data?.data;
  const isGatedEntity = entity?.gated;
  const { data: documentsCount } = useDocumentsEntityCount(entity?.tag ?? '');

  useEffect(() => {
    return () => resetEarningsStore();
  }, []);

  useEffect(() => {
    if (!assetData) {
      navigate(appPaths.screener, { replace: true });
    }
  }, [assetData]);

  useEffect(() => {
    if (entity) {
      addAssetToRecent({
        entity,
        type: AO_WIDGET_RECENTLY_OPENED_ENTITIES,
      });
    }
  }, [entity?.default_snake]);

  useEffect(() => {
    const tab =
      searchParams.get(queryKeys.assetOverviewTab) ?? TabsType.Overview;
    setActiveTab(tab as TabsType);
  }, [searchParams]);

  const { data: snakeMeta } = useSnakeMeta(entity?.default_snake);

  const onTabChange = (_: number, tabKey: TabsType) => {
    setSearchParams(params => {
      params.set(queryKeys.assetOverviewTab, tabKey);
      return params;
    });
    scrollContainerRef.current?.scroll(0, 0);
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollContainerRef.current?.scrollTop ?? 0;
      const tHeader = document.querySelector('thead');
      const tHeaderTop = tHeader?.getBoundingClientRect().top ?? 0;

      if (tHeader) {
        switch (activeTab) {
          case TabsType.Financials:
          case TabsType.Documents:
          case TabsType.Earnings:
            const stickyHeight = tHeaderTop || STICKY_HEIGHT_MAP[activeTab];
            return setShowShadow(scrollTop <= stickyHeight);
          default:
            return setShowShadow(true);
        }
      }
    };

    const tabContent = scrollContainerRef.current;
    tabContent?.addEventListener('scroll', handleScroll);

    return () => {
      tabContent?.removeEventListener('scroll', handleScroll);
    };
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === TabsType.Overview) {
      setSidebarExpanded(true);
    } else {
      setSidebarExpanded(false);
    }
  }, [activeTab]);

  if (isLoading || isRefetching || (isBasicUser && isGatedEntity)) {
    return null;
  }

  return (
    <S.AssetOverviewRoot data-testid="asset-overview">
      {entity ? (
        <>
          <AssetOverviewHeader
            snakeMeta={snakeMeta}
            entity={entity}
            changeTab={onTabChange}
            activeTab={activeTab}
            scrollContainerRef={scrollContainerRef}
            overviewTabChartContainerRef={overviewTabChartContainerRef}
            showShadow={showShadow}
            documentsCount={documentsCount}
          />
          <S.ScrollContainer ref={scrollContainerRef}>
            <TabContent
              entity={entity}
              activeTab={activeTab}
              renderChatInput={renderChatInput}
              overviewTabChartContainerRef={overviewTabChartContainerRef}
            />
          </S.ScrollContainer>
        </>
      ) : (
        <ErrorMessageWrapper displayIcon onClick={refetch} />
      )}
    </S.AssetOverviewRoot>
  );
};
