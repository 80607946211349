import {
  borderRadius,
  Button,
  Icon,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Condition = styled(Button)`
  ${borderRadius.r2};
  background: var(--surface-active);
  color: var(--text-primary);
  fill: var(--text-soft);
  ${typographyV2.bodySm};
  height: 32px;
`;

export const RemoveText = styled.span`
  color: var(--text-critical);
  display: flex;
`;

export const TrashIcon = styled(Icon).attrs({ size: 16 })`
  margin-right: 8px;
  fill: var(--icon-critical);
`;
