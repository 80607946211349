import styled from 'styled-components';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

const colors: Record<string, string> = {
  bullish: 'var(--viz-bullish)',
  bearish: 'var(--viz-bearish)',
  neutral: 'var(--icon-default)',
};

export const StyledForeign = styled.foreignObject`
  text-align: center;
  line-height: 16px;
  vertical-align: middle;
`;

export const CircleIcon = styled.circle<{ direction: string }>`
  fill: ${({ direction }) => colors[direction]};
  r: 2px;
`;

export const CircleRingIcon = styled.circle<{ direction: string }>`
  fill: none;
  r: 4px;
  stroke-width: 1;
  stroke: ${({ direction }) => colors[direction]};
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Date = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const Value = styled.span`
  ${typographyV2.bodySm};
`;
