import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './DocumentEmpty.styles';

type DocumentEmptyProps = {
  hasTableData: boolean;
};

export const DocumentEmpty = ({ hasTableData }: DocumentEmptyProps) => {
  const { t } = useTranslation(['chat']);

  return (
    <S.DocumentEmptyRoot>
      <S.StyledImage
        $hasData={hasTableData}
        src="/app/static/images/empty-state/empty-doc.svg"
        alt=""
      />
      <S.Description $hasData={hasTableData}>
        {t('chat:documentSearchEmpty.description')}
      </S.Description>
    </S.DocumentEmptyRoot>
  );
};
