import { Drawer, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  ${Drawer.Styled.Body} {
    height: 100%;
  }

  ${Drawer.Styled.Title} {
    ${typographyV2.titleBase};
    margin-bottom: 4px;
  }

  ${Drawer.Styled.Container} {
    width: 600px;
  }
`;

export const ScenarioDrawerWrapper = styled.div`
  margin: 0 16px 16px 16px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: var(--border-soft);
  margin: 40px 0;
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  margin: 40px 0;
`;
