const PASSWORD_LENGTH_MIN = 8;

export const isPasswordTooShort = (password: string) =>
  password.length < PASSWORD_LENGTH_MIN;

export const arePasswordsEqual = (password1: string, password2: string) =>
  password1 === password2;

export const validatePassword = (password: string) => {
  if (!password) {
    return 'auth:signUp.errors.emptyPassword';
  } else if (isPasswordTooShort(password)) {
    return 'auth:signUp.errors.passwordMinLength';
  }

  return '';
};

export const validateNewPassword = (newPassword: string, oldPassword: string) =>
  validatePassword(newPassword) ||
  (arePasswordsEqual(newPassword, oldPassword)
    ? 'auth:resetPassword.passwordsShouldNotMatch'
    : '');

export const validateConfirmPassword = (
  confirmPassword: string,
  newPassword: string
) => {
  if (!newPassword) {
    return '';
  }

  return !arePasswordsEqual(confirmPassword, newPassword)
    ? 'auth:resetPassword.passwordsShouldMatch'
    : '';
};
