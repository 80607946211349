import { ReactNode } from 'react';
import styled from 'styled-components';

import { CheckBox } from '~/components/checkbox/CheckBox';
import { Dropdown, DropdownProps } from '~/components/dropdown/Dropdown';
import { DropdownList } from '~/components/dropdown/dropdown-list/DropdownList';
import { Input } from '~/components/input/Input';
import { TableHeader } from '~/components/table/table-header/TableHeader';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

export const ItemRow = styled.div`
  padding: 8px;
`;

export const AddColumnTableHeader = styled(TableHeader)`
  padding: 0;
  position: sticky;
  right: 0;
  z-index: calc(var(--z-index-smarttable-sticky-header-cell) + 2);

  > span {
    justify-content: flex-end;
    margin-right: 2px;
  }
`;

export const SearchInput = styled(Input)`
  padding: 8px 8px 8px 8px;
  border-bottom: 1px solid var(--border-soft);
  margin-bottom: 4px;

  ${Input.Styles.InputWrapper} {
    padding: 8px;
  }
`;

export const DropdownCheckbox = styled(CheckBox)`
  label {
    width: 100%;
    align-items: center;

    ${CheckBox.Styled.Checkmark} {
      margin-top: 0px;
    }
  }
`;

export const ColumnsDropdown = styled(Dropdown)`
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  ${borderRadius.r1}
  box-shadow: var(--elevation-moderate);
  ${DropdownList.Styled.DropdownListRoot} {
    border: none;
  }
` as <T>(props: DropdownProps<T>) => ReactNode;
