import { borderRadius, Button, Dropdown, Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledIcon = styled(Icon)`
  fill: var(--icon-soft);
  cursor: pointer;
  margin-right: 16px;
`;

export const SubmitButton = styled(Button)`
  align-self: end;
`;

export const StyledDropdown = styled(Dropdown)`
  z-index: var(--z-index-modal);

  ul {
    padding: 1px;
    background: var(--background-soft);
    ${borderRadius.r2}
  }
`;
