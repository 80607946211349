import React, { HTMLAttributes } from 'react';

import { ChoiceChips, ChoiceItem } from '~/components/choice-chips/ChoiceChips';

export interface ChipsSelectorProps<T>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  items: ChoiceItem<T>[];
  selectedId: ChoiceItem<T>['id'];
  onChange: (value: ChoiceItem<T>['id']) => void;
}

export const ChipsSelector = <T extends string>({
  items,
  selectedId,
  onChange,
  ...rest
}: ChipsSelectorProps<T>) => {
  const selectedItem = items.find(item => item.id === selectedId) ?? items[0];

  return (
    <ChoiceChips
      items={items}
      selectedItem={selectedItem}
      onChange={({ id }) => onChange(id)}
      {...rest}
    />
  );
};
