import { ChartInsight } from '@toggle/chart';
import {
  Insight,
  InsightDirection,
  InsightsPrediction,
  Period,
} from '@toggle/toggle';

import { PredictionRow } from '~/widgets/scenario/components/prediction-summary-table/PredictionSummaryTable';

import { InsightDetails } from '../../insights.types';

export const mapArticleInsight = (a: Insight): ChartInsight => ({
  id: a.id,
  type: 'article',
  direction:
    a.metadata.direction === InsightDirection.Bullish ? 'bullish' : 'bearish',
  date: new Date(a.metadata.last_value_date ?? a.created_at),
  entity: a.metadata.entity,
});

export const mapInsightsToDetails = (
  insights: ChartInsight[],
  articleInsights: Insight[]
): InsightDetails[] =>
  insights
    .map(insight => {
      let item;

      if (insight.type === 'article') {
        item = articleInsights.find(article => article.id === insight.id);
      }

      return (
        item && {
          type: insight.type,
          item,
        }
      );
    })
    .filter((element): element is InsightDetails => !!element);

export const getPredictionsTableData = (
  horizons: string[],
  predictions: InsightsPrediction[]
) => {
  return horizons.reduce((result, horizon) => {
    const prediction = predictions.find(
      prediction => prediction.horizon === horizon
    );

    if (prediction) {
      result[horizon as Period] = {
        max: prediction.max,
        p80: prediction.percentiles['80'],
        median: prediction.median,
        mean: prediction.mean,
        p20: prediction.percentiles['20'],
        min: prediction.min,
      };
    }

    return result;
  }, {} as Record<Period, PredictionRow>);
};
