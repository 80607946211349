import { Entity, formatTicker } from '@toggle/toggle';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';
import { WatchlistTrigger } from '~/components/watchlist/watchlist-trigger/WatchlistTrigger';

import * as S from './EntityHeader.styles';

export interface EntityHeaderProps {
  className?: string;
  entity: Entity;
  shouldShowLogo?: boolean;
  priceDisplayNode?: ReactNode;
  isCondensed?: boolean;
  truncateLongTitle?: boolean;
  headerButton?: ReactNode;
  isDrawer?: boolean;
}

const DEFAULT_ICON_SIZE = 48;
const SMALL_ICON_SIZE = 20;

export const EntityHeader = ({
  className,
  entity,
  shouldShowLogo = true,
  priceDisplayNode,
  isCondensed = false,
  truncateLongTitle = false,
  headerButton,
  isDrawer = false,
}: EntityHeaderProps) => {
  const { t } = useTranslation('common');
  const ticker = formatTicker(entity);

  const entityName = isCondensed
    ? ticker
    : entity.name_short || entity.name_full;

  const getWatchlistLabel = (isInWatchlist: boolean) => {
    if (headerButton) {
      return undefined;
    }

    return isInWatchlist
      ? t('common:components.watchlistBtn.watching')
      : t('common:components.watchlistBtn.addToWatchlistLabel');
  };

  const renderWatchlistTrigger = (
    isInWatchlist: boolean,
    onCreateNewListClick?: () => void,
    hasNoWatchlist?: boolean
  ) => {
    const buttonProps = hasNoWatchlist ? { onClick: onCreateNewListClick } : {};

    return (
      <S.WatchlistButton
        data-testid="watchlist-button"
        label={getWatchlistLabel(isInWatchlist)}
        size="small"
        variant="tertiary"
        iconName={isInWatchlist ? 'Checkmark' : 'Add'}
        $isActive={isInWatchlist}
        $hasHeaderButton={!!headerButton}
        {...buttonProps}
      />
    );
  };

  return (
    <S.StyledEntityDetails
      className={className}
      isCondensed={isCondensed}
      entityNameComponent={
        truncateLongTitle ? (
          <TruncateTooltip
            placement="top"
            atLength={10}
            text={entityName}
            data-testid="analyze-header-title"
          />
        ) : (
          <span data-testid="text">{entityName}</span>
        )
      }
      priceDisplayNode={priceDisplayNode}
      formattedTicker={isCondensed ? '' : ticker}
      leftImgNode={
        shouldShowLogo && (
          <S.StyledAssetLogoContainer
            size={isCondensed ? SMALL_ICON_SIZE : DEFAULT_ICON_SIZE}
            entity={entity}
            $isCondensed={isCondensed}
            $isDrawer={isDrawer}
          />
        )
      }
      buttonNode={
        <S.ButtonContainer>
          {headerButton}
          <WatchlistTrigger
            tag={entity.tag}
            dropdownProps={{
              strategy: 'fixed',
              placement: 'top-start',
              inPortal: false,
            }}
            renderTrigger={renderWatchlistTrigger}
          />
        </S.ButtonContainer>
      }
    />
  );
};

EntityHeader.Styled = S;
