import { useQuery } from '@tanstack/react-query';
import {
  EconomicEventOutput,
  EconomicsResponseInput,
  economicsResponseInputSchema,
  financialEvents,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

import { groupEntriesByDate } from '../utils/grouping-utils';

export const US_COUNTRY_CODE = 'US';

const QUERY_KEY_ECONOMICS_EVENTS = 'QUERY_KEY_ECONOMICS_EVENTS';
export interface UseEconomicsEventsProps {
  from: string;
  to: string;
  countries?: string[];
}

export const useEconomicsEvents = ({
  from,
  to,
  countries,
}: UseEconomicsEventsProps) =>
  useQuery({
    queryKey: [QUERY_KEY_ECONOMICS_EVENTS, to, from, countries],
    queryFn: ({ signal }) => {
      const { path, schema } = financialEvents.getEconomics;
      return wretchRequest<EconomicsResponseInput>(
        path({ from, to, countries }),
        {
          schema,
          signal,
        }
      );
    },
    select: data => {
      const parsedData = economicsResponseInputSchema.parse(data);
      const sortedEconomics = parsedData.economics.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      return groupEntriesByDate<EconomicEventOutput>(sortedEconomics, 'date');
    },
  });
