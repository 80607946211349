export interface HighLowDataPoint {
  high: number;
  median: number;
  low: number;
}

export interface HighLowDataPointWithIndex extends HighLowDataPoint {
  relative_idx: number;
}

export type HorizonToIndex = Map<string, number | undefined>;

export interface TSPoint<I, V> {
  index: I;
  value: V;
}

export enum PlotTypes {
  Positive = 'positive',
  Negative = 'negative',
  Median = 'median',
}

export type D3AreaCoordinate<T> =
  | number
  | ((d: T, index: number, data: T[]) => number);

export interface StopGradient {
  offset: number;
  stopColor: string;
  stopOpacity?: number;
}

export interface PlotLine {
  ts: Array<TSPoint<number, number>>;
  type: PlotTypes;
  gradients?: {
    y0: D3AreaCoordinate<TSPoint<number, number>>;
    y1: D3AreaCoordinate<TSPoint<number, number>>;
    gradientStops?: StopGradient[];
  }[];
}
