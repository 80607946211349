import {
  BankCard,
  DowngradeReason,
  Subscription,
  SubscriptionName,
  SubscriptionResponse,
  UserRole,
  UserSubscription,
} from '@toggle/toggle';
import { format } from 'date-fns';
import find from 'lodash/find';
import { StoreApi } from 'zustand';

import {
  deleteBankCard,
  getBankCard,
  getSubscription,
  postUserSubscription,
  PostUserSubscriptionProps,
} from '~/services/subscription/subscription-service';
import { ApiFetchResponse } from '~/utils/api-fetch/apiFetch';
import { httpCode } from '~/utils/api-fetch/httpCode';

import { create } from '../create-store/createStore';

export const isExpired = (year: number, month: number): boolean => {
  const today = new Date();
  const someday = new Date();
  someday.setFullYear(year, month, 1);

  return someday < today;
};

export interface SubscriptionStore {
  isError: boolean;
  isFetching: boolean;
  userSubscription: UserSubscription;
  subscriptions: Subscription[] | null;
  cardError: boolean;
  nextPaymentDateFormatted?: string;
  subscriptionChanged: boolean;
  hasActivePromo: boolean;
  setError: (isError: boolean) => void;
  card: BankCard | null;
  initialize: () => Promise<ApiFetchResponse<SubscriptionResponse>>;
  subscribe: ({
    productId,
    priceId,
  }: PostUserSubscriptionProps) => Promise<void>;
  getCard: () => Promise<void>;
  getDowngradeReason: () => DowngradeReason;
  getSubscriptionByRole: (role: UserRole) => Subscription | undefined;
  showSubscriptionPanel: () => boolean;
  isSubscriptionOnRoll: () => boolean;
  isTerminal: () => boolean;
  isFromBO: () => boolean;
  isTrialUser: () => boolean;
  isBasicUser: () => boolean;
  downgradeTo: (role: UserRole) => Promise<void>;
  removeCard: () => Promise<void>;
}

const getUserCard = async (set: StoreApi<SubscriptionStore>['setState']) => {
  const req = await getBankCard();
  set({
    card: req.data,
    cardError: req.error && req.error.status !== httpCode.notFound,
  });
};

function isUserHasActivePromo(userSubscription: UserSubscription) {
  return (
    !!userSubscription.promo || !!userSubscription.upcoming_subscription?.promo
  );
}

export const findSubscriptionByName = (
  subscriptions: Subscription[],
  subscriptionName?: string
) => {
  return find(subscriptions, {
    name: subscriptionName,
  });
};

export const getNextPaymentDateFormatted = (
  userSubscription: UserSubscription
) => {
  const { starting_on } = userSubscription?.upcoming_subscription ?? {};

  return starting_on && format(new Date(starting_on), 'PPP');
};

const getUserSubscriptions = async (
  set: StoreApi<SubscriptionStore>['setState']
) => {
  const res = await getSubscription();

  if (!res.error) {
    set({
      nextPaymentDateFormatted: getNextPaymentDateFormatted(res.data.user),
      subscriptions: res.data.available,
      userSubscription: res.data.user,
      hasActivePromo: !isUserHasActivePromo(res.data.user),
    });
  }

  return res;
};

const initialize = async (set: StoreApi<SubscriptionStore>['setState']) => {
  await getUserCard(set);
  return await getUserSubscriptions(set);
};

const subscribe = async (
  { productId, priceId, promoCode }: PostUserSubscriptionProps,
  set: StoreApi<SubscriptionStore>['setState']
) => {
  set({
    isFetching: true,
    isError: false,
  });

  const userSubscription = await postUserSubscription({
    productId,
    priceId,
    promoCode,
  });

  if (userSubscription.error) {
    throw userSubscription.error;
  } else {
    set(state => ({
      nextPaymentDateFormatted: getNextPaymentDateFormatted(
        userSubscription.data
      ),
      userSubscription: userSubscription.data,
      subscriptionChanged:
        state.userSubscription?.name !== userSubscription.data.name,
      hasActivePromo: !isUserHasActivePromo(userSubscription.data),
      isFetching: false,
    }));
  }
};

export const useSubscription = create<SubscriptionStore>((set, get) => ({
  isError: false,
  isFetching: false,
  userSubscription: {} as UserSubscription,
  subscriptions: null,
  card: null,
  plan: null,
  subscriptionChanged: false,
  hasActivePromo: false,
  cardError: false,
  setError: isError =>
    set({
      isError,
    }),
  subscribe: props => subscribe(props, set),
  initialize: async () => initialize(set),
  getCard: async () => getUserCard(set),
  getDowngradeReason: () => get().userSubscription.downgrade_reason,
  getSubscriptionByRole: (role: UserRole) =>
    find(get().subscriptions, {
      role,
    }),
  showSubscriptionPanel: () => {
    const subscriptionName = get().userSubscription?.name;
    return !!subscriptionName;
  },
  isSubscriptionOnRoll: () => get().userSubscription.role !== UserRole.Basic,
  isTerminal: () => get().userSubscription.name === SubscriptionName.Terminal,
  isFromBO: () =>
    get().userSubscription.name === SubscriptionName.OrganizationTerminal,
  isTrialUser: () => get().userSubscription.trial,
  isBasicUser: () =>
    get().userSubscription.name === SubscriptionName.ReflexivityBasic,
  downgradeTo: async (role: UserRole) => {
    const { getSubscriptionByRole, subscribe, setError } = get();
    const planFromRole = getSubscriptionByRole(role);

    if (!planFromRole) {
      return;
    }

    try {
      const priceId = planFromRole.prices[0].id;
      await subscribe({ productId: planFromRole.id, priceId });
    } catch (error) {
      setError(true);
    }
  },
  removeCard: async () => {
    const res = await deleteBankCard();

    if (res.error) {
      set({ cardError: true });
    } else {
      set({ card: null, cardError: false });
    }
  },
}));
