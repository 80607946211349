import {
  ChartAssetData,
  CustomMixDomain,
  getPrimaryDetails,
  PaneData,
  RangeHorizon,
  ResampleIntervals,
} from '@toggle/chart';
import { useState } from 'react';
import { useSearchParams } from 'react-router';

import { buildChartSearchParams } from '../../utils/chart-settings-utils/chart-settings-utils';
import { emptyChartState } from '../../utils/chart-utils/chart-utils';

export interface ChartState {
  chartPanes: PaneData[];
  resample: ResampleIntervals;
  horizon: RangeHorizon | null;
  domain: CustomMixDomain | null;
  hidden?: string[];
}

export type UseChartStateReturn = ReturnType<typeof useChartState>;

export const useChartState = (shouldUseSearchParams: boolean) => {
  const [initialChartState, setInitialChartState] = useState<ChartState>();

  const [chartState, setChartState] = useState<ChartState>(
    () => emptyChartState
  );
  const [primaryAsset, setPrimaryAsset] = useState<ChartAssetData>();
  const [primaryPane, setPrimaryPane] = useState<PaneData>();
  const [, setSearchParams] = useSearchParams();

  const updateChartState = (partial: Partial<ChartState>) => {
    const nextChartState = { ...chartState, ...partial };
    setChartState(nextChartState);

    if (!initialChartState) {
      setInitialChartState({
        ...nextChartState,
        // Required to trigger the useEffect in the useChartApi() hook
        chartPanes: [...nextChartState.chartPanes],
      });
    }

    if (partial.chartPanes) {
      const { primaryAsset, primaryPane } = getPrimaryDetails(
        partial.chartPanes
      );
      setPrimaryAsset(primaryAsset);
      setPrimaryPane(primaryPane);
    }

    if (shouldUseSearchParams) {
      const urlSearchParams = buildChartSearchParams({
        searchParams: new URLSearchParams(location.search),
        primaryAsset,
        ...partial,
      });
      setSearchParams(urlSearchParams, { replace: true });
    }
  };

  const resetChartState = () => {
    if (initialChartState) {
      setChartState(initialChartState);
    }
  };

  return {
    updateChartState,
    resetChartState,
    chartState,
    primaryAsset,
    primaryPane,
    setChartState,
  };
};
