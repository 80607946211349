import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { CheckBox } from '@toggle/design-system';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { useWorkingList } from '~/hooks/use-working-list/useWorkingList';
import { appPaths } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import { Actions, Modals } from './components/Modals';
import * as S from './SendAllAssetsTo.styles';
import { useLinkingToolsNavigation } from './useLinkingToolsNavigation';

export interface SendAllAssetsToProps {
  entityTags: string[];
}

export type AssetDestination =
  | 'watchlist'
  | 'screener'
  | 'scenario'
  | 'insights'
  | 'document-search';

export const SendAllAssetsTo = ({ entityTags }: SendAllAssetsToProps) => {
  const location = useLocation();
  const isBasicUser = useSubscription(state => state.isBasicUser());
  const { t } = useTranslation(['widget', 'screener', 'common']);
  const [selectedItem, setSelectedItem] = useState<AssetDestination | null>(
    null
  );
  const [hasInvalidSubmit, setHasInvalidSubmit] = useState(false);
  const [open, setOpen] = useState(false);

  const { saveWorkingList } = useWorkingList();
  const {
    navigateToWatchlist,
    navigateToScreener,
    navigateToScenario,
    navigateToInsights,
    activeModal,
    setActiveModal,
  } = useLinkingToolsNavigation();

  const items = useMemo(
    () => [
      {
        id: 'watchlist',
        label: t('widget:sendAllAssetsTo.watchlist.label'),
        description: t('widget:sendAllAssetsTo.watchlist.description'),
        enabled: !matchPath(
          { path: appPaths.watchlist, end: false },
          location.pathname
        ),
      },
      {
        id: 'screener',
        label: t('widget:sendAllAssetsTo.screener.label'),
        description: t('widget:sendAllAssetsTo.screener.description'),
        enabled: !matchPath(
          { path: appPaths.screener, end: false },
          location.pathname
        ),
      },
      {
        id: 'scenario',
        label: t('widget:sendAllAssetsTo.scenario.label'),
        description: t('widget:sendAllAssetsTo.scenario.description'),
        enabled:
          !matchPath(
            { path: appPaths.scenario, end: false },
            location.pathname
          ) && !isBasicUser,
      },
      {
        id: 'insights',
        label: t('widget:sendAllAssetsTo.insights.label'),
        description: t('widget:sendAllAssetsTo.insights.description'),
        enabled: !matchPath(
          { path: appPaths.insights, end: false },
          location.pathname
        ),
      },
      {
        id: 'document-search',
        label: t('widget:sendAllAssetsTo.docSearch.label'),
        description: t('widget:sendAllAssetsTo.docSearch.description'),
        enabled: !isBasicUser,
      },
    ],
    [location.pathname, t]
  );

  const onSelect = (e: Event, itemId: AssetDestination) => {
    e.preventDefault();
    setSelectedItem(itemId);
    setHasInvalidSubmit(false);
  };

  const onSubmit = () => {
    if (selectedItem) {
      saveWorkingList(entityTags);
      setOpen(false);
      setSelectedItem(null);

      switch (selectedItem) {
        case 'watchlist':
          navigateToWatchlist();
          break;
        case 'screener':
          navigateToScreener();
          break;
        case 'scenario':
          navigateToScenario();
          break;
        case 'insights':
          navigateToInsights();
          break;
        case 'document-search':
          setActiveModal(Actions.DocSearch);
          break;
      }
    } else {
      setHasInvalidSubmit(true);
    }
  };

  return (
    <>
      <RadixDropdownMenu.Root open={open} onOpenChange={setOpen}>
        <RadixDropdownMenu.Trigger asChild>
          <S.StyledButton
            variant="secondary"
            iconName="ArrowUpRight"
            size="medium"
            label={t('widget:sendAllAssetsTo.buttonLabel')}
            data-testid="trigger-button-send-all-assets"
            onClick={() => setOpen(true)}
          />
        </RadixDropdownMenu.Trigger>
        <RadixDropdownMenu.Portal>
          <S.DropdownMenuContent
            align="end"
            sideOffset={5}
            collisionPadding={5}
          >
            <RadixDropdownMenu.Group>
              {items
                .filter(i => i.enabled)
                .map(item => (
                  <S.StyledRadixItem
                    key={item.id}
                    asChild
                    onSelect={e => onSelect(e, item.id as AssetDestination)}
                  >
                    <S.Item $hasInvalidSubmit={hasInvalidSubmit}>
                      <CheckBox
                        name={item.id}
                        label={item.label}
                        checked={item.id === selectedItem}
                      />
                      <S.CheckboxDescription>
                        {item.description}
                      </S.CheckboxDescription>
                    </S.Item>
                  </S.StyledRadixItem>
                ))}
            </RadixDropdownMenu.Group>
            <S.ActionItem>
              <S.ApplyButton
                variant="primary"
                size="small"
                label={t('widget:sendAllAssetsTo.apply')}
                onClick={onSubmit}
              />
            </S.ActionItem>
          </S.DropdownMenuContent>
        </RadixDropdownMenu.Portal>
      </RadixDropdownMenu.Root>
      {!!activeModal && (
        <Modals
          entityTags={entityTags}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
        />
      )}
    </>
  );
};
