import { ScaleLinear } from 'd3';
import React from 'react';

export const RIGHT_Y_AXIS_WIDTH = 70;

interface ClipAreasProps {
  uniqueId: string;
  width: number;
  height: number;
  yScale: ScaleLinear<number, number>;
}

export const ClipAreas = ({
  uniqueId,
  width,
  height,
  yScale,
}: ClipAreasProps) => {
  return (
    <>
      <clipPath id={`hl-clip-upper-${uniqueId}`}>
        <rect
          width={width}
          height={yScale(0) + height * 0.1}
          x="0"
          y={-height * 0.1}
        />
      </clipPath>
      <clipPath id={`hl-clip-bottom-${uniqueId}`}>
        <rect
          width={width}
          height={height - yScale(0)}
          x="0"
          y={yScale(0) + 1}
        />
      </clipPath>
    </>
  );
};
