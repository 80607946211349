import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ToggleItem,
  TogglesModal,
  TogglesModalProps,
} from '../toggles-modal/TogglesModal';

export type ChartEvents = 'TMI';

export type EventsConfig = {
  [K in ChartEvents]: boolean;
};

export interface EventsModalProps {
  onClose: () => void;
  eventsConfig: EventsConfig;
  onChange: TogglesModalProps['onChange'];
}

export const EventsModal: FC<EventsModalProps> = ({
  onClose,
  eventsConfig,
  onChange,
}) => {
  const { t } = useTranslation('chart');

  const items: ToggleItem[] = Object.keys(eventsConfig).map(id => ({
    id,
    label: t('chart:widget.event', { context: id }),
  }));

  const checkedItems = Object.keys(eventsConfig).reduce((res, key) => {
    if (eventsConfig[key as ChartEvents]) {
      res.push(key);
    }

    return res;
  }, [] as string[]);

  return (
    <TogglesModal
      data-testid="events-modal"
      title={t('chart:widget.events')}
      items={items}
      checkedItems={checkedItems}
      onClose={onClose}
      onChange={onChange}
    />
  );
};
