import {
  BaseChartAPIProps,
  ChartPane,
  GradientStop,
} from '~/types/create.types';

interface GradientProps extends Pick<BaseChartAPIProps, 'context'> {
  color: string;
  y1: number;
  y2: number;
  gradientStops: GradientStop[];
}

interface GradientAreaProps extends Omit<GradientProps, 'y1' | 'y2'> {
  options: BaseChartAPIProps['options'];
}

const createGradient = ({
  context,
  y1,
  y2,
  color,
  gradientStops,
}: GradientProps) => {
  const gradient = context.createLinearGradient(0, y1, 0, y2);

  gradientStops.forEach(item => {
    const opacity = Math.round(item.opacity * 255).toString(16);
    const gradientColor = item.color ?? color;
    const opacityString = opacity.length === 1 ? `0${opacity}` : opacity;
    gradient.addColorStop(item.stop, `${gradientColor}${opacityString}`);
  });

  return gradient;
};

export const drawGradientArea = ({
  context,
  options,
  color,
  gradientStops,
}: GradientAreaProps) => {
  const gradient = createGradient({
    context,
    y1: 0,
    y2: options.height - options.gutters.y,
    color,
    gradientStops,
  });
  context.fillStyle = gradient;
  context.lineTo(
    options.width - options.gutters.y,
    options.height - options.gutters.x
  );
  context.lineTo(0, options.height - options.gutters.x);
  context.fill();
};

interface DrawPaneGradient
  extends Pick<BaseChartAPIProps, 'context' | 'options'> {
  pane: ChartPane;
  gradientStops: GradientStop[];
}

export const drawPaneGradient = ({
  pane,
  context,
  options,
  gradientStops,
}: DrawPaneGradient) => {
  const gradient = createGradient({
    context,
    y1: pane.options.gutters.top,
    y2: pane.options.gutters.top + pane.options.height,
    gradientStops,
    color: options.colors.paneGradient.value,
  });

  context.save();
  context.globalCompositeOperation = 'destination-over';
  context.fillStyle = gradient;
  context.fillRect(
    0,
    pane.options.gutters.top,
    options.width - options.gutters.y,
    pane.options.height
  );
  context.restore();
};
