import {
  AllPaymentsPayload,
  allPaymentsSchema,
  ClientSecretPayload,
  clientSecretPayload,
} from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

const paymentsEndpoint = {
  secret: `payment/v1/secret`,
  paymentsList: `payment/v1/payments`,
};

export const getClientSecret = () => {
  return apiFetch<ClientSecretPayload>(paymentsEndpoint.secret, {
    schema: clientSecretPayload,
  });
};

export const postClientSecret = (clientSecret: string) => {
  return apiFetch(paymentsEndpoint.secret, {
    method: 'post',
    body: {
      client_secret: clientSecret,
    },
  });
};

export const deleteClientSecret = (clientSecret: string) => {
  return apiFetch(paymentsEndpoint.secret, {
    method: 'delete',
    body: {
      client_secret: clientSecret,
    },
  });
};

export const getAllPayments = async (signal?: AbortSignal) => {
  return apiFetch<AllPaymentsPayload>(paymentsEndpoint.paymentsList, {
    schema: allPaymentsSchema,
    options: {
      signal,
    },
  });
};
