import styled, { css } from 'styled-components';

export const RectBackground = styled.rect`
  fill: var(--surface-hover);
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const VerticalBarRoot = styled.g`
  &:hover ${RectBackground} {
    opacity: 0.6;
    transition: none;
  }
`;

export const Rect = styled.rect<{ $color?: string; $borderColor?: string }>`
  fill: ${props => (props.$color ? props.$color : 'white')};
  ${props =>
    props.$borderColor &&
    css`
      stroke-width: 1;
      stroke: ${props.$borderColor};
    `};
`;
