import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { InsightCard } from '~/components/insight-card/InsightCard';
import { useNavigateToInsight } from '~/hooks/use-navigate-to-insight/useNavigateToInsight';
import { getFilteredInsights } from '~/services/insights/insights-service';

import * as S from './AssetInsightsList.styles';

export interface AssetInsightsListProps {
  entityTag: string;
  title?: React.ReactNode;
  onClickInsight?: (insightId: string) => void;
  setHasInsights?: (hasInsights: boolean) => void;
}

export const AssetInsightsList = ({
  entityTag,
  title,
  onClickInsight,
  setHasInsights,
}: AssetInsightsListProps) => {
  const { t } = useTranslation('widget');
  const navigateToInsight = useNavigateToInsight();

  const { data, isLoading, isError, refetch, isRefetching } = useQuery({
    queryKey: [entityTag],
    queryFn: () =>
      getFilteredInsights({
        entity: [entityTag],
      }),
  });

  const insightsData = data?.data?.result;

  if (insightsData && !insightsData.length) {
    setHasInsights?.(false);
    return null;
  }

  const getInsightsContent = () => {
    if (isLoading || isRefetching) {
      return <S.LoadingSkeleton />;
    }

    if (isError) {
      return <ErrorMessageWrapper variant="error" onClick={refetch} />;
    }

    if (!insightsData) {
      setHasInsights?.(false);
      return null;
    }

    return insightsData.map(i => {
      const metadata = i.metadata;
      return (
        <InsightCard
          key={i.id}
          title={i.card.name}
          qualityStars={metadata.stars}
          horizon={metadata.horizon}
          median={metadata.median_return}
          imageSrc={
            i.charts.find(chart => chart.type == 'scenario-thumbnail')?.url
          }
          onClick={() =>
            onClickInsight
              ? onClickInsight(i.id)
              : navigateToInsight({
                  insightId: i.id,
                  name: i.card.name,
                })
          }
        />
      );
    });
  };

  return (
    <S.AssetInsightsListRoot data-testid="asset-insights">
      {title ?? (
        <S.HeadingContainer>
          <S.Heading>{t('widget:assetOverview.insights')}</S.Heading>
        </S.HeadingContainer>
      )}
      {getInsightsContent()}
    </S.AssetInsightsListRoot>
  );
};
