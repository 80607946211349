import {
  CheckBox,
  SkeletonLoader,
  SmartTable,
  SmartTableCellProps,
  SmartTableProps,
} from '@toggle/design-system';
import { FC } from 'react';
import styled from 'styled-components';

import { DocumentTableItem } from './utils';

export const PdfWrapper = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const PdfLink = styled.a`
  &:hover {
    text-decoration-line: underline;
  }
`;

export const PdfIcon = styled.img`
  height: 22px;
  width: 18px;
`;

export const StyledSmartTable = styled<FC<SmartTableProps<DocumentTableItem>>>(
  SmartTable
)`
  ${SmartTable.Styled.StickyTableHeader} {
    border-bottom: 1px solid var(--border-default);
  }

  ${SmartTable.Styled.TableRow} {
    &:hover {
      cursor: default;
    }
  }

  ${SmartTable.Styled.ActionTableCell} {
    width: 100%;
    padding-left: 30px;
  }
`;

export const Cell = styled<FC<SmartTableCellProps<DocumentTableItem>>>(
  SmartTable.TableCell
)<{
  $withBorder: boolean;
}>`
  border-bottom-color: ${({ $withBorder }) =>
    $withBorder ? 'var(--border-soft)' : 'transparent'};
`;

export const StyledSkeletonLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        maxWidth: '1280px',
        borderRadius: '8px',
        height: '360px',
        margin: '20px auto',
      },
    },
  ],
})``;

export const Root = styled.div`
  max-width: 1280px;
  padding: 20px 20px 80px 0;
  margin: 0 auto;
`;

export const NumericalValue = styled.span`
  font-family: var(--font-roboto-mono);
`;

export const StyledCheckBox = styled(CheckBox)`
  ${CheckBox.Styled.Checkmark} {
    margin-top: 2px;
  }

  && {
    ${CheckBox.Styled.Checkmark} {
      border-color: var(--border-primary);
      &:hover {
        border-color: var(--border-primary);
      }
    }
  }
`;
