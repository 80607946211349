import React, { RefObject } from 'react';

export const renderBounds = ({
  ref,
  value,
  fill,
  xPosition,
}: {
  ref: RefObject<SVGTextElement | null>;
  value: number;
  fill: string;
  xPosition: number;
}) => {
  let width = 0;
  try {
    if (ref.current) {
      width = ref.current.getBBox().width;
    }
  } catch (error) {
    width = String(value).length * 6;
  }
  const x = xPosition - width / 2;

  return (
    <text x={x} y={10} fill={fill} ref={ref}>
      {value}
    </text>
  );
};
