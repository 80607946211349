import { Icon } from '@toggle/design-system';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Suggestion } from '../../hooks/use-recent-searches/useRecentSearches';
import * as S from './SearchSuggestions.styles';

export interface SearchSuggestionsProps {
  list: Suggestion[];
  handleSelect: (item: Suggestion) => void;
}

export const SearchSuggestions = ({
  list,
  handleSelect,
}: SearchSuggestionsProps) => {
  const { t } = useTranslation('news');

  return (
    <S.SearchSuggestionsRoot data-testid="search-suggestions">
      {list.map((suggestion, index, array) => {
        const prevSuggestion = array[index - 1];
        const showGroupLabel =
          !prevSuggestion || prevSuggestion.type !== suggestion.type;

        return (
          <Fragment key={`${suggestion.type}-${suggestion.label}`}>
            {showGroupLabel && (
              <S.CategoryTitle>
                {
                  /* i18next-extract-mark-context-next-line ["recent_searches", "topics", "industry", "asset_class"] */
                  t('news:suggestion.type', { context: suggestion.type })
                }
              </S.CategoryTitle>
            )}
            <S.KeywordButton
              type="button"
              onClick={() => {
                handleSelect(suggestion);
              }}
            >
              <S.Label>{suggestion.label}</S.Label>
              <Icon iconName="Search" size={16} />
            </S.KeywordButton>
          </Fragment>
        );
      })}
    </S.SearchSuggestionsRoot>
  );
};
