import { Button, SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--background-soft);
  border-bottom: 1px solid var(--border-soft);
  padding: 12px;
  gap: 8px;
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  color: var(--text-primary);

  ${Button.Styled.Label} {
    gap: 4px;
  }
`;

export const SkeletonChartLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '32px',
        borderRadius: '8px',
      },
    },
  ],
})``;
