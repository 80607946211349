import styled from 'styled-components';

export const ActionLink = styled.a`
  color: var(--text-primary);

  &:hover {
    text-decoration: underline;
    color: var(--text-primary);
  }
`;
