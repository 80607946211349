import { EarningsScenario, EarningsScenarioResponse } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EarningState,
  Expectation,
  PrimaryStatus,
  SecondaryStatus,
} from '../../../../types';
import { getStatus } from '../../../../utils/status/status';
import { ChartEmpty } from '../chart-empty/ChartEmpty';
import * as S from './ChartEmptyStates.styles';

export interface ChartEmptyStatesProps {
  earningsData: EarningsScenarioResponse;
  expectation: Expectation;
  scenario?: EarningsScenario | null;
}

export const ChartEmptyStates = ({
  earningsData,
  expectation,
  scenario,
}: ChartEmptyStatesProps) => {
  const { t } = useTranslation('earnings');

  const { ticker, exchange, company_name: companyName } = earningsData;

  const hasNoEstimate = [PrimaryStatus.None, PrimaryStatus.OnlyHistory];
  const { primary, secondary, tertiary } = getStatus(earningsData, scenario);

  if (secondary === SecondaryStatus.Reported) {
    if (hasNoEstimate.includes(primary)) {
      return null;
    }

    if (tertiary === EarningState.Meet) {
      return (
        <S.ChartRoot>
          <S.Title />
          <ChartEmpty
            data-testid="chart-empty-no-history-reported-meet"
            title={t('earnings:empty.noHistoryReportedTitleMeet', {
              companyName,
              exchange,
              ticker,
            })}
            description={t('earnings:empty.description')}
          />
        </S.ChartRoot>
      );
    }

    if (tertiary === EarningState.Beat) {
      return (
        <S.ChartRoot>
          <S.Title />
          <ChartEmpty
            data-testid="chart-empty-no-history-reported-beat"
            title={t('earnings:empty.noHistoryReportedTitleBeat', {
              companyName,
              exchange,
              ticker,
            })}
            description={t('earnings:empty.description')}
          />
        </S.ChartRoot>
      );
    }

    if (tertiary === EarningState.Miss) {
      return (
        <S.ChartRoot>
          <S.Title />
          <ChartEmpty
            data-testid="chart-empty-no-history-reported-miss"
            title={t('earnings:empty.noHistoryReportedTitleMiss', {
              companyName,
              exchange,
              ticker,
            })}
            description={t('earnings:empty.description')}
          />
        </S.ChartRoot>
      );
    }
  } else {
    if (expectation === Expectation.Beat) {
      return (
        <S.ChartRoot data-testid="earnings-chart-empty">
          <S.Title />
          <ChartEmpty
            data-testid="chart-empty-no-history-scheduled-beat"
            title={t('earnings:empty.noHistoryScheduledTitleBeat', {
              companyName,
              exchange,
              ticker,
            })}
            description={t('earnings:empty.description')}
          />
        </S.ChartRoot>
      );
    }

    if (expectation === Expectation.Miss) {
      return (
        <S.ChartRoot>
          <S.Title />
          <ChartEmpty
            data-testid="chart-empty-no-history-scheduled-miss"
            title={t('earnings:empty.noHistoryScheduledTitleMiss', {
              companyName,
              exchange,
              ticker,
            })}
            description={t('earnings:empty.description')}
          />
        </S.ChartRoot>
      );
    }
  }

  return null;
};
