import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const ListEntityItemRoot = styled.button<{
  $isFocused?: boolean;
  $isDisabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};
  border: none;
  background: none;
  ${borderRadius.r1};

  &:hover {
    background-color: var(--surface-hover);
  }

  &,
  &:focus-visible {
    outline: ${props =>
      props.$isFocused ? '2px solid var(--border-focus)' : 'none'};
  }
`;

export const Details = styled.div`
  display: flex;
  gap: 16px;
`;

export const Close = styled.div`
  ${borderRadius.r1};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: var(--surface-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--border-focus);
  }
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
