import { format, formatDistanceStrict } from 'date-fns';
import React from 'react';

const MILLISECOND_A_DAY = 86400000;
export interface ArticleDateProps {
  date: string;
  applicableRelativeTime?: boolean;
  timezoneType?: 'nearestCity' | 'abbreviation';
  showFullTimestamp?: boolean;
}

const getTimeZoneAbbr = () => {
  const date = new Date();
  return date
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
};

export const ArticleDate = ({
  date,
  applicableRelativeTime = false,
  timezoneType = 'nearestCity',
  showFullTimestamp = true,
  ...rest
}: ArticleDateProps) => {
  const publishedDate = new Date(date);
  const now = new Date();

  const userTimeZone =
    timezoneType === 'nearestCity'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : getTimeZoneAbbr();

  const isRecent = now.getTime() - publishedDate.getTime() < MILLISECOND_A_DAY;
  const shouldShowRelative = applicableRelativeTime && isRecent;

  if (shouldShowRelative) {
    const relativeTime = formatDistanceStrict(publishedDate, now, {
      addSuffix: true,
    });
    return (
      <span data-testid="article-date" {...rest}>
        {relativeTime}
      </span>
    );
  }

  const dateFormat = showFullTimestamp ? 'MMM d, yyyy h:mm a' : 'MMM d';
  const formattedDate = format(publishedDate, dateFormat);

  return (
    <span data-testid="article-date" {...rest}>
      {showFullTimestamp ? `${formattedDate} ${userTimeZone}` : formattedDate}
    </span>
  );
};
