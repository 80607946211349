import {
  deviceConstraints,
  PriceDisplay,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  @media ${deviceConstraints.desktopSmall} {
    flex-direction: row;
    gap: 40px;
  }
`;

export const DateSpan = styled.span`
  ${typographyV2.bodyXs}
  color:var(--text-soft);
  margin-right: 12px;
`;

export const StyledPriceDisplay = styled(PriceDisplay)`
  ${PriceDisplay.Styled.Price} {
    ${typographyV2.titleBase}
  }
`;
