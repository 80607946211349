import React, { useRef } from 'react';
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { TableHeader } from './table-header/TableHeader';
import { StickyTableHeader } from './sticky-table-header/StickyTableHeader';
import { PinConfig, TableCell } from './table-cell/TableCell';
import { Tooltip } from '../tooltip/Tooltip';
import {
  getPriceStatus,
  isElementScrollbarVisible,
  PriceStatus,
} from '@toggle/helpers';
import { WithNAFallback } from './table-cell/WithNAFallback';
import * as S from './TableV2.styles';

type TableData = { value: string; tooltip?: string; logo?: string };
type TableRow<T extends string> = Record<T, TableData>;
type ColumnsType = 'text' | 'directional' | 'number';
type TableColumns<T extends string> = {
  type: ColumnsType;
  sticky?: boolean;
  tooltip?: string;
  id: T;
  title: string;
};
export type TableV2Payload<T extends string> = {
  data: TableRow<T>[];
  stickyHeader?: boolean;
  columnsMeta: TableColumns<T>[];
  pagination?: {
    itemsPerPage?: number;
    strategy?: 'scroll' | 'buttons';
  };
};

export type TableV2Props<T extends string> = TableV2Payload<T> & {
  className?: string;
  onRowClick?: (row: Row<TableRow<T>>) => void;
};

export const TableV2 = <T extends string>({
  className,
  columnsMeta,
  data,
  stickyHeader = false,
  onRowClick,
}: TableV2Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const getCellAlignment = (type: ColumnsType) =>
    type === 'text' ? 'left' : 'right';

  const columns: ColumnDef<TableRow<T>>[] = columnsMeta.map(item => ({
    id: item.id,
    header: item.title,
    accessorFn: row => row[item.id],
    cell: ({ getValue }) => {
      const value = getValue() as TableData;
      const status =
        item.type === 'directional'
          ? getPriceStatus(+value.value)
          : PriceStatus.Default;
      return (
        <Tooltip disabled={!value.tooltip} inPortal label={value.tooltip}>
          <S.CellInner aria-haspopup={!!value.tooltip}>
            {value.logo && <img src={value.logo} alt="logo" />}
            <WithNAFallback
              value={
                value.value !== undefined && (
                  <S.PriceWrapper $status={status}>
                    {value.value}
                  </S.PriceWrapper>
                )
              }
            />
          </S.CellInner>
        </Tooltip>
      );
    },
    meta: item,
  }));

  const hasHorizontalScroll = isElementScrollbarVisible(containerRef);
  const pinnedColumns = columnsMeta.filter(p => p.sticky).map(p => p.id);

  const getIsPinned = (column: Column<TableRow<T>>) =>
    hasHorizontalScroll && !!column.getIsPinned();

  const getPinConfig = (column: Column<TableRow<T>>): PinConfig | undefined =>
    getIsPinned(column)
      ? {
          left: column.getStart('left'),
          right: column.getStart('right'),
          isLastPinned: column.getIsLastColumn('left'),
        }
      : undefined;

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: pinnedColumns,
      },
    },
  });

  const { rows } = table.getRowModel();

  return (
    <S.Container ref={containerRef} className={className}>
      <S.StyledTanStackTableRoot>
        <thead>
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const content = flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );
                  const meta = header.column.columnDef.meta as
                    | TableColumns<T>
                    | undefined;
                  const pinConfig = getPinConfig(header.column);

                  const children =
                    stickyHeader || pinConfig ? (
                      <StickyTableHeader
                        key={header.column.id}
                        alignment={meta?.type && getCellAlignment(meta.type)}
                        pinConfig={pinConfig}
                      >
                        {content}
                      </StickyTableHeader>
                    ) : (
                      <TableHeader
                        alignment={meta?.type && getCellAlignment(meta.type)}
                        key={header.column.id}
                      >
                        {content}
                      </TableHeader>
                    );

                  return meta?.tooltip ? (
                    <Tooltip inPortal label={meta?.tooltip}>
                      {children}
                    </Tooltip>
                  ) : (
                    children
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.id} onClick={onRowClick && (() => onRowClick(row))}>
              {row.getVisibleCells().map(cell => {
                const meta = cell.column.columnDef.meta as
                  | TableColumns<T>
                  | undefined;
                return (
                  <TableCell
                    key={cell.id}
                    alignment={meta?.type && getCellAlignment(meta.type)}
                    isNumber={meta?.type !== 'text'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </S.StyledTanStackTableRoot>
    </S.Container>
  );
};

TableV2.Styled = S;
