import { customScrollMinimal, SkeletonLoader } from '@toggle/design-system';
import { PriceStatus, Status } from '@toggle/helpers';
import styled from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { priceChangeColors } from '~/utils/common/utils';

export const TabContent = styled.div`
  padding: 12px;
  background-color: var(--surface-default);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  ${customScrollMinimal};
  position: relative;
  overflow: auto;
  flex: 1 1 0;
  padding table {
    th:first-child {
      width: 20%;
    }
    tbody {
      tr > td:nth-child(n + 2) {
        font-family: var(--font-roboto-mono);
      }
    }
  }
  &::-webkit-scrollbar-track {
    margin-left: 207px;
  }
`;

export const TableLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
      },
    },
  ],
})`
  height: 100%;
`;

export const TickerCell = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledErrorMessageWrapper = styled(ErrorMessageWrapper)`
  border: none;
`;

export const PriceWrapper = styled.div<{
  $status: PriceStatus | Status;
  $alignment?: string;
}>`
  color: ${({ $status }) => priceChangeColors[$status]};
`;

export const Unit = styled.span`
  font-size: 8px;
  line-height: 12px;
  margin-top: 6px;
  margin-left: 5px;
`;

export const StyledTooltip = styled(TooltipContainer)`
  font-family: var(--font-nort);
`;
