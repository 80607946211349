import wretch from 'wretch';

import { config } from '~/config';

export interface ZendeskTicket {
  subject: string;
  commentBody: string;
  requester: {
    email: string;
    name: string;
  };
}

export const sendTicket = ({
  subject,
  commentBody,
  requester,
}: ZendeskTicket) => {
  return wretch(config.zendeskSubdomain)
    .headers({ 'Content-Type': 'application/json' })
    .post({
      request: {
        subject,
        comment: {
          body: commentBody,
        },
        requester,
      },
    })
    .json(r => r);
};
