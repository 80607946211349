import { deviceConstraints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 36px 12px 0;
  @media ${deviceConstraints.desktopSmall} {
    margin: 36px 20px 0;
  }

  button + button {
    margin-left: 8px;
  }
`;

interface InputWrapperProps {
  $focused?: boolean;
  $error?: boolean;
}

export const InputContainer = styled.div<InputWrapperProps>`
  border: 1px solid var(--text-disabled);
  background-color: transparent;
  border-radius: 4px;
  padding: 10px 12px;
  height: 40px;

  ${({ $error }) =>
    $error &&
    `
      border-color: var(--border-critical);
    `}

  ${({ $focused }) =>
    $focused
      ? `
          border-color: var(--border-primary);
        `
      : `
          &:hover {
            border-color: var(--border-hover);
          }
        `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ErrorLabel = styled.p`
  color: var(--text-critical);
  ${typographyV2.bodySm}
`;
