import { Period } from '@toggle/toggle';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceMovementChart } from '~/components/price-movement/components/price-movement-chart/PriceMovementChart';
import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';
import { getPredictionsTableData } from '~/views/turbo-chart/components/insights/utils/inisights-utils/insights-utils';
import { PredictionSummaryTable } from '~/widgets/scenario/components/prediction-summary-table/PredictionSummaryTable';

import * as S from './ScenarioPriceMovement.styles';

export interface ScenarioPriceMovementProps {
  scenario: ScenariosData;
  bestHorizon: string;
  horizons: string[];
  isFixedIncome: boolean;
}

const SCENARIO_DRAWER_CHART_HEIGHT = 168;

export const ScenarioPriceMovement = ({
  scenario,
  bestHorizon,
  horizons,
  isFixedIncome,
}: ScenarioPriceMovementProps) => {
  const { t } = useTranslation('scenario');

  const [activeLabelIndex, setActiveLabelIndex] = useState(0);
  const labels = [t('drawer.buttonGroup.median'), t('drawer.buttonGroup.mean')];
  const legendLabel = labels[activeLabelIndex];
  const isMedian = activeLabelIndex === 0;
  const predictionsMapped = scenario.predictions.map(p => {
    return {
      high: p.high,
      median: isMedian ? p.median : p.mean,
      low: p.low,
      relative_idx: p.relative_idx,
    };
  });

  const predictionByHorizon = useMemo(() => {
    return getPredictionsTableData(horizons, scenario.predictions);
  }, [scenario.predictions, horizons]);

  return (
    <S.ScenarioPriceMovementRoot data-testid="scenario-price-movement">
      <S.StyledButtonGroup
        labels={labels}
        onClick={setActiveLabelIndex}
        activeIndex={activeLabelIndex}
      />

      <PriceMovementChart
        prediction={predictionsMapped}
        bestHorizon={bestHorizon as Period}
        isFixedIncome={isFixedIncome}
        horizonToIndexOffset={1}
        maxHeight={SCENARIO_DRAWER_CHART_HEIGHT}
        legendLabel={legendLabel}
        isMedian={isMedian}
        isShowCumulativeReturn={false}
        useDynamicDaysLabel
      />
      <PredictionSummaryTable
        data={predictionByHorizon}
        horizons={horizons}
        isMedian={isMedian}
        isFixedIncome={isFixedIncome}
      />
    </S.ScenarioPriceMovementRoot>
  );
};
