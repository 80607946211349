import { Property } from 'csstype';
import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { customScrollMinimal } from '../custom-scroll/CustomScroll.styles';

interface DrawerStyledProps {
  $isOpen: boolean;
  $width?: Property.Width;
}

export const RootContainer = styled.div``;

export const BackgroundOverlay = styled.div<DrawerStyledProps>`
  content: '';
  z-index: var(--z-index-modal);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-backdrop);
  transition: opacity 0.3s ease-out;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          opacity: 0.7;
          top: 0;
        `
      : css`
          opacity: 0;
        `}
`;

export const Container = styled.section<DrawerStyledProps>`
  width: 400px;
  height: 100%;
  max-height: 100vh;
  display: flex;
  position: fixed;
  right: 0;
  flex-direction: column;
  top: 0;
  z-index: var(--z-index-modal);
  background-color: var(--background-soft);
  transition: transform 0.3s ease-out;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          transform: translateX(0%);
        `
      : css`
          transform: translateX(100%);
        `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 6px 16px 20px;
  gap: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.h2`
  ${typographyV2.titleLg}
  color: var(--text-default);
  margin-top: 18px;
`;

export const Subtitle = styled.div`
  ${typographyV2.bodySm}
  color: var(--text-soft);
`;

export const Body = styled.div`
  ${customScrollMinimal};
  flex: 1;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-behavior: smooth;
`;
