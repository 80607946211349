import { AssetClassMap, AssetSubClassMap } from '../api/entity-constants';
import { Entity, MappedEntity } from '../api/entity-schema';

export const mapAssetClass = (
  entity: Entity
): Pick<MappedEntity, 'asset_class' | 'sub_class'> => ({
  asset_class:
    entity?.asset_class !== 0 ? AssetClassMap[entity?.asset_class] : '',
  sub_class: entity?.sub_class !== 0 ? AssetSubClassMap[entity?.sub_class] : '',
});
