import { keyboard } from '@toggle/helpers';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipFilteredData } from '../../utils';
import * as S from './Chips.styles';

export const CHIPS_WRAPPER_ID = 'chips-wrapper';

type ChipEnd = 'right' | 'left';

export interface ChipsProps {
  chipData: ChipFilteredData[];
  activeChip?: string;
  handleChangeChip: (selectedChip: ChipFilteredData['value']) => void;
}

export const Chips = ({
  chipData,
  activeChip,
  handleChangeChip,
}: ChipsProps) => {
  const { t } = useTranslation('chart');

  const [isOverflow, setIsOverflow] = useState(true);

  const chipsContainerRef = useRef<HTMLDivElement>(null);
  const chipsRef = useRef<HTMLButtonElement[] | null[]>([]);

  const [showPrevBtn, setShowPrevBtn] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(isOverflow);

  useEffect(() => {
    const target = chipsContainerRef.current;

    if (!target) {
      return undefined;
    }

    const handleResize = () => {
      setIsOverflow(target.scrollWidth > target.clientWidth);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(target);

    return () => {
      resizeObserver.disconnect();
    };
  }, [chipData, chipsContainerRef.current]);

  const getHiddenChip = (end: ChipEnd) => {
    const chipsContainerDom = chipsContainerRef.current;

    if (chipsContainerDom) {
      return chipsRef.current.find(chip => {
        const containerRectRight =
          chipsContainerDom.getBoundingClientRect().right;
        const chipRectRight = chip?.getBoundingClientRect().right;

        if (end === 'right') {
          return (
            chipRectRight && Math.round(chipRectRight) >= containerRectRight
          );
        } else {
          return (
            chipRectRight && Math.round(chipRectRight) <= containerRectRight
          );
        }
      });
    }

    return null;
  };

  const handleGetNext = () => {
    const chipsContainerDom = chipsContainerRef.current;

    if (chipsContainerDom) {
      const firstRightHiddenChip = getHiddenChip('right');

      if (firstRightHiddenChip) {
        chipsContainerDom.scrollBy({
          left: firstRightHiddenChip.getBoundingClientRect().right,
          behavior: 'smooth',
        });

        setShowPrevBtn(
          chipsContainerDom.scrollWidth > chipsContainerDom.clientWidth
        );
        setShowNextBtn(
          chipsContainerDom.scrollWidth < chipsContainerDom.clientWidth
        );
      }
    }
  };

  const handleGetPrev = () => {
    const chipsContainerDom = chipsContainerRef.current;

    if (chipsContainerDom) {
      const firstLeftHiddenChip = getHiddenChip('right');

      if (firstLeftHiddenChip) {
        chipsContainerDom.scrollBy({
          left: -firstLeftHiddenChip.getBoundingClientRect().right,
          behavior: 'smooth',
        });
        const chipsContainer = chipsContainerDom;

        setShowPrevBtn(chipsContainer.clientLeft > 0);
        setShowNextBtn(chipsContainer.clientLeft === 0);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const chipsElements = chipsRef.current.filter(Boolean);
    let chipElementIndex = -1;

    switch (e.key) {
      case keyboard.left.key:
        chipElementIndex = chipsElements.findIndex(c => c === target);

        if (chipElementIndex !== -1) {
          const isFirstElement = chipElementIndex === 0;
          const goToIndex =
            (isFirstElement ? chipsElements.length : chipElementIndex) - 1;
          const chipElement = chipsElements[goToIndex];
          chipElement?.focus();
        }

        break;

      case keyboard.right.key:
        chipElementIndex = chipsElements.findIndex(c => c === target);

        if (chipElementIndex !== -1) {
          const isLastElement = chipElementIndex === chipsElements.length - 1;
          const goToIndex = isLastElement ? 0 : chipElementIndex + 1;
          const chipElement = chipsElements[goToIndex];
          chipElement?.focus();
        }

        break;
    }
  };

  return (
    <S.ChipsContainer
      ref={chipsContainerRef}
      data-testid="chips-wrapper"
      id={CHIPS_WRAPPER_ID}
      onKeyDown={handleKeyDown}
    >
      {chipData.map((chip, i) => (
        <S.StyledChip
          ref={element => {
            if (chipsRef.current) {
              chipsRef.current[i] = element;
            }
          }}
          key={chip.name}
          isActive={chip.value === activeChip}
          /* i18next-extract-disable-next-line */
          label={t(chip.name)}
          onClick={() => handleChangeChip(chip.value)}
          variation="outline"
          data-testid={`chip-${chip.value}`}
        />
      ))}
      {isOverflow && (
        <>
          {showPrevBtn && (
            <S.StyledButton
              $direction="prev"
              iconName="ChevronLightLeft"
              size="small"
              variant="empty"
              onClick={handleGetPrev}
              data-testid="previous-btn"
            />
          )}
          {showNextBtn && (
            <S.StyledButton
              $direction="next"
              iconName="ChevronLightRight"
              size="small"
              variant="empty"
              onClick={handleGetNext}
              data-testid="next-btn"
            />
          )}
        </>
      )}
    </S.ChipsContainer>
  );
};
