import { Button } from '@toggle/design-system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { config } from '~/config';

import * as S from './ChartError.styles';

export interface ChartErrorProps {
  onRetry?: () => void;
}

export const ChartError = ({ onRetry }: ChartErrorProps) => {
  const { t } = useTranslation(['earnings', 'common']);

  return (
    <S.ChartErrorRoot data-testid="chart-error">
      <S.ErrorIcon iconName="ExclamationTriangle" />
      <S.Title> {t('earnings:error.title')}</S.Title>
      <S.Description>
        <Trans
          i18nKey={'earnings:error.description'}
          components={[
            <S.Link
              key="key-0"
              title={t('earnings:error.linkAlt')}
              href={`mailto:${config.supportEmail}`}
            />,
          ]}
          values={{ email: config.supportEmail }}
        />
      </S.Description>
      <Button
        data-testid="chart-error-button"
        label={t('common:tryAgain')}
        onClick={onRetry}
      />
    </S.ChartErrorRoot>
  );
};
