import { BarChart } from '@toggle/design-system';
import React from 'react';
import styled from 'styled-components';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { formatNumberWithUnit } from '~/widgets/earnings/utils/utils';

const Tooltip = styled(TooltipContainer)`
  max-width: 450px;
`;

export type MiniChartData = {
  label: string;
  value: number;
  year: number;
};

export type MiniBarChartProps = {
  data: MiniChartData[];
  yAxisLabel?: string;
};

export const formatTableData = (data: MiniChartData[]) => {
  return data
    .filter(v => !['LTM', 'NTM'].includes(v.label))
    .map(bar => {
      const currentYear = new Date().getFullYear();
      const isExpectedData = bar.year > currentYear || bar.label.includes('E');

      return {
        value: bar.value,
        ...(isExpectedData && {
          borderColor:
            bar.value < 0 ? 'var(--viz-bearish)' : 'var(--viz-bullish)',
        }),
        color:
          bar.value < 0
            ? `var(--viz-bearish${isExpectedData ? '-soft' : ''})`
            : `var(--viz-bullish${isExpectedData ? '-soft' : ''})`,
        label: `${bar.label} ${bar.year.toFixed().slice(-2)}`,
      };
    });
};

export const MiniBarChart = ({ data, yAxisLabel }: MiniBarChartProps) => {
  const bars = formatTableData(data);

  return (
    <Tooltip
      label={
        <BarChart
          data={bars}
          height={220}
          width={352}
          showAxisLines
          yAxisLabelFormatter={formatNumberWithUnit}
          yAxisLabel={yAxisLabel}
          barRadius={0}
        />
      }
      placement="bottom-start"
      inPortal
      hasArrow={false}
    >
      <div>
        <BarChart
          barRadius={0}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          padding={{ yAxisLabel: 0, xAxisLabel: 0 }}
          data={bars}
          height={20}
          width={40}
          showLegend={false}
          showXAxisLabels={false}
          showYAxisLabels={false}
          showAxisLines={false}
        />
      </div>
    </Tooltip>
  );
};
