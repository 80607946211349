import { useQuery } from '@tanstack/react-query';
import { financialEvents } from '@toggle/toggle';
import { CountriesResponse } from '@toggle/toggle/src/api/financial-events/countries';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const QUERY_KEY_FINANCIAL_EVENTS_COUNTRIES =
  'QUERY_KEY_FINANCIAL_EVENTS_COUNTRIES';

export const useFinancialEventsCountries = () =>
  useQuery({
    queryKey: [QUERY_KEY_FINANCIAL_EVENTS_COUNTRIES],
    queryFn: ({ signal }) => {
      const { path, schema } = financialEvents.getCountries;
      return wretchRequest<CountriesResponse>(path, {
        schema,
        signal,
      });
    },
    select: data =>
      data.countries
        .filter(c => !!c.code)
        .sort((a, b) => a.name.localeCompare(b.name)),
  });
