import * as Radix from '@radix-ui/react-dropdown-menu';
import type { ChildrenFilter } from '@toggle/toggle';
import React, { useState } from 'react';

import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';

import * as S from './Filter.styles';
import { Option } from './Option';

export type ChildFilterProps = {
  filter: ChildrenFilter;
  activeOptions: FilterOption[];
  onClick: (option: FilterOption) => void;
};

export const ChildFilter = ({
  filter,
  activeOptions,
  onClick,
}: ChildFilterProps) => {
  const [open, setOpen] = useState(false);
  const activeOption =
    activeOptions.find(o => o.childName === filter.name) ||
    filter.options.find(f => f.key === filter.default) ||
    filter.options[0];

  const isChecked = (optKey?: string) => activeOption.key === optKey;

  const isFilterOptionActive = (option: FilterOption) => {
    return (
      !option.include ||
      !activeOptions.length ||
      option.include.some(key => activeOptions.some(o => o.key === key))
    );
  };

  return (
    <Radix.Sub open={open} onOpenChange={o => setOpen(o)}>
      <S.SubTrigger>
        <S.SubTriggerLabel>{activeOption.name}</S.SubTriggerLabel>
        <S.SubTriggerArrow iconName="ChevronLightRight" />
      </S.SubTrigger>
      <Radix.Portal>
        <S.SubMenuContent>
          <S.MenuTitleText>{filter.name}</S.MenuTitleText>
          <Radix.Group />
          {filter.options.filter(isFilterOptionActive).map(o => {
            const option = { ...o, childName: filter.name };

            return (
              <Option
                data-testid={option.key}
                key={option.name}
                option={option}
                isChecked={isChecked(option.key)}
                handleClick={() => {
                  onClick(option);
                  setOpen(o => !o);
                }}
              />
            );
          })}
        </S.SubMenuContent>
      </Radix.Portal>
    </Radix.Sub>
  );
};
