import { borderRadius, typographyV2, values } from '@toggle/design-system';
import styled from 'styled-components';

export const HighlightsFiltersRoot = styled.div`
  ${borderRadius.r2};
  border: 1px solid var(--border-soft);
`;

export const Header = styled.div`
  padding: 6px 12px;
  border-top-left-radius: ${values.r2};
  border-top-right-radius: ${values.r2};
  ${typographyV2.uiXs};
  color: var(--text-soft);
  background-color: var(--surface-default);
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-soft);
`;

export const Toggle = styled.label`
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-soft);
  }
`;

export const Label = styled.div`
  ${typographyV2.bodySm};
`;
