import React from 'react';

import { PlotTypes } from '../../HighLowTripleLineChart.types';
import { MemoPlot, PlotProps } from '../plot/Plot';

interface MainAreasProps extends PlotProps {
  uniqueId: string;
}

export const TripleLines = ({
  uniqueId,
  plots,
  yScale,
  xScale,
}: MainAreasProps) => {
  return (
    <>
      <g clipPath={`url(#hl-clip-upper-${uniqueId})`}>
        <MemoPlot
          plots={plots}
          xScale={xScale}
          yScale={yScale}
          linePlotType={PlotTypes.Positive}
        />
      </g>
      <g clipPath={`url(#hl-clip-bottom-${uniqueId})`}>
        <MemoPlot
          plots={plots}
          xScale={xScale}
          yScale={yScale}
          linePlotType={PlotTypes.Negative}
        />
      </g>
    </>
  );
};
