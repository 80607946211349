import { Card } from '@toggle/design-system';
import styled from 'styled-components';

export const ExpectationReportedRoot = styled.div`
  margin-bottom: 12px;
`;

export const SuccessCard = styled(Card)`
  background-color: var(--viz-bullish-soft);
  border: 1px solid var(--viz-bullish);
  color: var(--viz-bullish);
`;

export const CriticalCard = styled(Card)`
  background-color: var(--viz-bearish-soft);
  border: 1px solid var(--viz-bearish);
  color: var(--viz-bearish);
`;
