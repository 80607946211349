import {
  CheckBox,
  customScrollMinimal,
  SkeletonLoader,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ChartPeerGroupRoot = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Body = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 20px;
`;

export const SkeletonChartLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        borderRadius: '8px',
      },
    },
  ],
})`
  flex-grow: 1;
  display: flex;
`;

const toolbarHeight = '57px';

export const ChartContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - ${toolbarHeight} - 64px);
  padding: 0px 20px 20px;
  overflow-y: auto;
  ${customScrollMinimal};
`;

export const Toolbar = styled.div`
  display: flex;
  border-bottom: 1px solid var(--border-default);
  padding: 8px;
  height: ${toolbarHeight};
  width: 100%;
  justify-content: space-between;
`;

export const GroupSection = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px;
`;

export const StyledCheckBox = styled(CheckBox)<{ $color: string }>`
  --surface-primary: ${props => props.$color};
  --border-primary: ${props => props.$color};
`;
