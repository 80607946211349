import { getContextCounts } from '~/components/chat';
import { DocumentResponse } from '~/stores/use-chat/assistant-response.type';
import { Message, MessageReply } from '~/stores/use-chat/use-chat.types';
import { useDocumentSourcesTableStore } from '~/views/intel/document-sources-view/document-sources-table/use-document-sources-table-store/useDocumentSourcesTableStore';
import { CITATION_GROUP_ID } from '~/views/intel/document-sources-view/DocumentSourcesView';

const isDocumentResponse = (
  reply?: MessageReply
): reply is DocumentResponse => {
  const data = reply?.data as any;
  return Boolean(
    data && data.citation_group_id !== undefined && data.overview !== undefined
  );
};

export const useGetDocumentContextCounts = ({
  params,
  messages,
}: {
  params: URLSearchParams;
  messages: Message[];
}) => {
  const customContextCounts = useDocumentSourcesTableStore(
    state => state.customContextCounts
  );

  if (customContextCounts) {
    return {
      documentsCount: customContextCounts.documentsCount,
      companiesCount: customContextCounts.companiesCount,
    };
  }

  const citationGroupId = params.get(CITATION_GROUP_ID);
  const message = [...messages].reverse().find(message => {
    if (citationGroupId) {
      const documentReplies = message.reply.filter(isDocumentResponse);
      const primaryReplyData = documentReplies?.[0]?.data;
      const overview = primaryReplyData?.overview;
      return (
        primaryReplyData?.citation_group_id === citationGroupId &&
        getContextCounts(overview).documentsCount > 0
      );
    } else {
      return message.status === 'complete';
    }
  });

  const documentReplies = message?.reply as DocumentResponse[] | undefined;
  const docsOverview = documentReplies?.[0]?.data.overview;
  const { documentsCount, companiesCount } = getContextCounts(docsOverview);
  return {
    documentsCount,
    companiesCount,
  };
};
