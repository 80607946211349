import { InfiniteData } from '@tanstack/react-query';
import {
  Cell,
  Column,
  ColumnDef,
  Header,
  Row,
  Table,
} from '@tanstack/react-table';
import { getStartOfRangeHorizon, RangeHorizon } from '@toggle/chart';
import { Tooltip, WithNAFallback } from '@toggle/design-system';
import { getPriceStatus, PriceStatus, Status } from '@toggle/helpers';
import {
  ColumnMeta,
  FilterResultMethod,
  FilterResults,
  FilterResultsCategoryData,
  MappedEntity,
  MetaValueType,
  TSDatum,
} from '@toggle/toggle';
import { Property } from 'csstype';
import { differenceInBusinessDays } from 'date-fns';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { ActiveFilterOptions } from '~/components/filters/Filters';
import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';
import { FilterGroups } from '~/hooks/use-filter-actions/useFilterActions';
import { MAX_TICKER_DISPLAY_LENGTH } from '~/stores/use-watchlist/constants';

import * as Shared from '../../dashboard/my-assets-widget/assets-table/AssetsTable.styles';
import { checkIfHorizonsMatch } from '../screener-table/screener-table-header-cell/utils';
import { ScreenerTableChart } from '../screener-table-chart/ScreenerTableChart';
import * as S from './Results.styles';
import { SCREENER_TICKER_COLUMN_ID } from './screener-events-table/utils/column-utils';
import {
  eventToDisplayName,
  ScreenerEvents,
} from './screener-events-table/utils/types';
import { KeyDevelopments } from './screener-key-developments-table/utils/types';
import { getColumnFilterKeyDetails } from './use-table-actions/utils';
import { ScreenerTableData } from './useScreenerTable';

export interface SavedCategorySetting extends Record<string, string[]> {}

export interface PinnedData {
  categoryData: FilterResultsCategoryData;
  fields: string[];
}

export interface ColumnOrder {
  category: string;
  fields: string[];
}

export interface ColumnMetaWithCategory extends ColumnMeta {
  category: FilterResultsCategoryData['category'];
}

export const DEFAULT_TAB = 'Overview';
export const KNOWLEDGE_GRAPH_TAB = 'Knowledge Graph';
export const KEY_DEV_TAB = 'Key Developments';

const numFormatter = new Intl.NumberFormat(navigator.language, {
  notation: 'compact',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const priceFormatter = new Intl.NumberFormat(navigator.language, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const mapValueTypeToFormatter: Record<
  MetaValueType,
  (value: number) => string
> = {
  float: priceFormatter.format,
  scaled_number: numFormatter.format,
  integer: v => v.toString(),
};

export const sortingFn = <T,>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
  const a = rowA.getValue<FilterResultMethod>(columnId).value;
  const b = rowB.getValue<FilterResultMethod>(columnId).value;

  // similar to SortingColumnDef.sortingUndefined:"last"
  if (a === undefined) {
    return 1;
  }

  if (b === undefined) {
    return -1;
  }

  return a > b ? 1 : a < b ? -1 : 0;
};

export const mapColumnMeta = <T extends Record<string, FilterResultMethod>>(
  c: ColumnMetaWithCategory | ColumnMeta,
  isDisabled?: boolean
): ColumnDef<T> => ({
  id: c.field,
  accessorFn: row => row[c.field],
  header: c.name,
  cell: ({ getValue }) => {
    const value = getValue() as FilterResultMethod;
    const unit = value.units ?? '';
    const formatter = mapValueTypeToFormatter[c.value_type || 'integer'];
    const status = c.directional
      ? getPriceStatus(value.value)
      : PriceStatus.Default;

    return (
      <S.StyledTooltip inPortal label={value.tooltip} placement="top-end">
        <span aria-haspopup={!!value.tooltip}>
          <WithNAFallback
            value={
              value.value !== undefined && (
                <S.PriceWrapper $status={isDisabled ? Status.Disabled : status}>
                  {formatter(value.value)}
                  {unit && <S.Unit>{unit}</S.Unit>}
                </S.PriceWrapper>
              )
            }
          />
        </span>
      </S.StyledTooltip>
    );
  },
  meta: c,
  sortingFn,
});

export const getColumns = ({
  t,
  columnsMeta,
  horizon,
}: {
  t: TFunction;
  columnsMeta: ColumnMetaWithCategory[];
  horizon: RangeHorizon;
}) => {
  const entityColumnDef: ColumnDef<ScreenerTableData> = {
    id: SCREENER_TICKER_COLUMN_ID,
    header: t('widget:watchlist.ticker'),
    meta: {
      alignment: 'start',
    },
    cell: props => {
      const item = props.row.original;
      return (
        item.entity && (
          <S.TickerCell>
            <Shared.TickerCell>
              {<AssetLogoContainer entity={item.entity} size={24} />}
              <Shared.Ticker>
                <TruncateTooltip
                  text={item.entity.formattedTicker}
                  tooltipText={`${item.entity.formattedTicker}, ${item.entity.name_full}`}
                  atLength={MAX_TICKER_DISPLAY_LENGTH}
                  fallbackLabel={
                    <Tooltip label={item.entity.name_full} inPortal>
                      <span>{item.entity.formattedTicker}</span>
                    </Tooltip>
                  }
                />
              </Shared.Ticker>
            </Shared.TickerCell>
            {!!item.ts?.length && (
              <ScreenerTableChart
                width={40}
                height={24}
                data={item.ts}
                entity={item.entity}
                horizon={horizon}
              />
            )}
          </S.TickerCell>
        )
      );
    },
  };
  const hasParent = columnsMeta.some(c => c.parent_id);

  if (hasParent) {
    const mapIdToColumnsMeta = columnsMeta.reduce((res, next) => {
      const parentId = next.parent_id ?? next.field;
      const name = next.parent_name ?? next.name;

      if (res[parentId]) {
        res[parentId].columnsMeta.push(next);
      } else {
        res[parentId] = {
          id: parentId,
          name,
          columnsMeta: [next],
        };
      }

      return res;
    }, {} as Record<string, { id: string; name: string; columnsMeta: ColumnMetaWithCategory[] }>);

    const parentColumnDefs = Object.values(mapIdToColumnsMeta).map(item => ({
      id: item.id,
      header: item.name,
      columns: item.columnsMeta.map<ColumnDef<ScreenerTableData>>(cm =>
        mapColumnMeta(cm)
      ),
    }));

    return [
      {
        id: entityColumnDef.id as string,
        header: entityColumnDef.header,
        columns: [entityColumnDef],
      },
      ...parentColumnDefs,
    ];
  }

  const columns: ColumnDef<ScreenerTableData>[] = [
    entityColumnDef,
    ...columnsMeta.map<ColumnDef<ScreenerTableData>>(cm => mapColumnMeta(cm)),
  ];

  return columns;
};

export interface FilterQueryResultData {
  filterResults: FilterResults;
  tickerColumnData: {
    mappedEntities: MappedEntity[];
    ts: Array<TSDatum[] | undefined>;
  };
}

export interface TableData {
  [category: string]: {
    lastPage: number;
    data: ScreenerTableData[];
  };
}

const mapFilterQueryResult = ({
  pageData,
  categoryData,
  pinnedColumnsMeta,
}: {
  pageData: FilterQueryResultData;
  categoryData: FilterResultsCategoryData;
  pinnedColumnsMeta: ColumnMetaWithCategory[];
}) => {
  const { tickerColumnData, filterResults } = pageData;

  const pinnedCategoriesData = pinnedColumnsMeta.map(p => {
    const categoryData = pageData.filterResults.data.find(
      item => item.category === p.category
    ) as FilterResultsCategoryData;

    return { categoryData, field: p.field };
  });

  return (
    categoryData.data?.reduce<ScreenerTableData[]>((res, item, idx) => {
      const entityTag = filterResults.entities[idx];
      const orderIndex = tickerColumnData.mappedEntities.findIndex(
        ({ tag }) => tag === entityTag
      );
      const entity = tickerColumnData.mappedEntities[orderIndex];

      if (entity) {
        const ts = tickerColumnData.ts[orderIndex];
        const tableRow = {
          entity,
          ts,
          ...item,
        } as ScreenerTableData;

        pinnedCategoriesData.forEach(item => {
          tableRow[item.field] = item.categoryData.data[idx][item.field];
        });

        res.push(tableRow);
      }

      return res;
    }, []) || []
  );
};

export const tableDataQueryUpdater =
  ({
    data,
    tab,
    pinnedColumnsMeta,
  }: {
    data: InfiniteData<FilterQueryResultData>;
    tab: string;
    pinnedColumnsMeta: ColumnMetaWithCategory[];
  }) =>
  (prevData: TableData = {}) => {
    //TO-DO figure out a way not to map every row on each tab switch
    // prev logic doesn't work when pinning columns
    //consider using virtualized list
    // const prevItems = prevData[tab];
    // const hasAllData = !!prevItems && prevItems.lastPage === data.pages.length;
    // if (hasAllData) {
    //   return prevData;
    // }

    const newData = data.pages.flatMap(pageData => {
      const categoryData = pageData.filterResults.data.find(
        d => d.category === tab
      ) as FilterResultsCategoryData;
      return mapFilterQueryResult({
        pageData,
        categoryData,
        pinnedColumnsMeta,
      });
    });

    return {
      ...prevData,
      [tab]: {
        data: newData,
        lastPage: data.pages.length,
      },
    };
  };

export const getNumDaysForHorizon = (
  horizon: RangeHorizon,
  start = new Date()
) => {
  const horizonStartDate = getStartOfRangeHorizon(horizon, start);
  return horizonStartDate
    ? differenceInBusinessDays(start, horizonStartDate)
    : 21;
};

export const getPinnedColumnsMeta = ({
  filterResults,
  pinnedColumns,
  categoryData,
}: {
  filterResults: FilterResults;
  pinnedColumns: SavedCategorySetting;
  categoryData?: FilterResultsCategoryData;
  resetToDefault?: boolean;
}) => {
  // we only add pinned columns to the overview tab
  // if resetting we want to remove all pinned columns from overview
  if (categoryData?.category !== DEFAULT_TAB) {
    return [];
  }

  const pinnedColumnsMeta = Object.keys(pinnedColumns).reduce(
    (res, category) => {
      const fields = pinnedColumns[category];
      const categoryData = filterResults.data.find(
        item => item.category === category
      ) as FilterResultsCategoryData;

      if (categoryData) {
        fields.forEach(field => {
          const meta = categoryData.meta.find(m => m.field === field);

          if (meta) {
            res.push({ ...meta, category });
          }
        });
      }

      return res;
    },
    [] as ColumnMetaWithCategory[]
  );

  return pinnedColumnsMeta;
};

const isParentHighlighted = (
  table: Table<ScreenerTableData>,
  parent: Column<ScreenerTableData, unknown>
) => {
  const headerGroup = table.getHeaderGroups()[0];
  return (
    headerGroup.headers.findIndex(c => c.column.id === parent.id) % 2 !== 0
  );
};

export const getIsCellHighlighted = (
  cell: Cell<ScreenerTableData, unknown>
) => {
  const parent = cell.column.parent;

  if (parent) {
    return isParentHighlighted(cell.getContext().table, parent);
  }

  return cell.column.getIndex() % 2 !== 0;
};

export const getIsHeaderHighlighted = (
  header: Header<ScreenerTableData, unknown>
) => {
  const parent = header.column.parent;

  if (parent) {
    return isParentHighlighted(header.getContext().table, parent);
  }

  return header.index % 2 !== 0;
};

export const menuItems = [
  {
    key: 'themeOverview',
    iconName: 'ChevronLightRight',
    group: 'themeOverview',
  } as const,
  {
    key: 'ascending',
    iconName: 'SortUp',
    group: 'sort',
  } as const,
  {
    key: 'descending',
    iconName: 'SortDown',
    group: 'sort',
  } as const,
  {
    key: 'clearSort',
    iconName: 'ClearSort',
    group: 'sort',
  } as const,
  { key: 'ascendingDocumentType', iconName: 'SortUp', group: 'sort' } as const,
  {
    key: 'descendingDocumentType',
    iconName: 'SortDown',
    group: 'sort',
  } as const,
  {
    key: 'filter',
    iconName: 'FilterInline',
    group: 'filter',
  } as const,
  {
    key: 'editFilter',
    iconName: 'FilterInline',
    group: 'filter',
  } as const,
  {
    key: 'clearFilter',
    iconName: 'FilterClear',
    group: 'filter',
  } as const,
  {
    key: 'pin',
    iconName: 'Pin',
    group: 'pin',
  } as const,
  {
    key: 'unpin',
    iconName: 'Unpin',
    group: 'pin',
  } as const,
  {
    key: 'left',
    iconName: 'ArrowLeftLong',
    group: 'move',
  } as const,
  {
    key: 'right',
    iconName: 'ArrowRightLong',
    group: 'move',
  } as const,
  {
    key: 'start',
    iconName: 'ArrowLeftLongLine',
    group: 'move',
  } as const,
  {
    key: 'end',
    iconName: 'ArrowRightLongLine',
    group: 'move',
  } as const,
  {
    key: 'reset',
    iconName: 'ArrowRotateRight',
    group: 'reset',
  } as const,
];

export const defaultDropdownKeys = menuItems.map(item => item.key);
export const navigableDropdownOptions = ['themeOverview'];

export type TableAction = typeof menuItems[number]['key'];

// eslint-disable-next-line complexity
export const getDisabledKeys = ({
  pinnedFields,
  header,
  lastColumnIndex,
  overviewColumns,
  activeFilterOptions,
}: {
  pinnedFields: string[];
  lastColumnIndex: number;
  header: Header<ScreenerTableData, unknown>;
  overviewColumns: string[];
  activeFilterOptions: ActiveFilterOptions[];
}): TableAction[] => {
  const column = header.column;

  if (column.parent && column.parent.id !== column.id) {
    return defaultDropdownKeys.filter(key =>
      key === 'ascending' || key === 'descending' ? false : true
    );
  }

  let defaultKeys: TableAction[] = [
    'clearSort',
    'ascendingDocumentType',
    'descendingDocumentType',
  ];

  if (column.id === SCREENER_TICKER_COLUMN_ID) {
    const enableKeys = ['ascending', 'descending'];

    defaultKeys = defaultDropdownKeys.filter(key => !enableKeys.includes(key));
  }

  const groupColumns = column.columns.length ? column.columns : [column];
  const childColumnDefMeta = column.columns?.[0]?.columnDef?.meta as
    | undefined
    | ColumnMetaWithCategory;
  const columnDefMeta = column.columnDef.meta as
    | undefined
    | ColumnMetaWithCategory;
  const { headerFilter, isMultiColumnFilter } = getHeaderFilter(
    header,
    activeFilterOptions
  );
  const horizonsMatch = checkIfHorizonsMatch(columnDefMeta, headerFilter);
  const disableApplyFilterKey = !isMultiColumnFilter
    ? !!headerFilter
    : horizonsMatch;

  //handles same columns as on the overview tab
  if (
    columnDefMeta?.category === DEFAULT_TAB ||
    overviewColumns.includes(column.id)
  ) {
    defaultKeys.push('pin', 'unpin');
  } else {
    defaultKeys.push(
      pinnedFields.some(id => groupColumns.some(c => c.id === id))
        ? 'pin'
        : 'unpin'
    );
  }

  if (childColumnDefMeta?.filter?.split('.')?.[0] !== 'theme_exposure') {
    defaultKeys.push('themeOverview');
  }

  if (disableApplyFilterKey) {
    defaultKeys.push('filter');
  } else {
    defaultKeys.push('editFilter');
    defaultKeys.push('clearFilter');
  }

  //parent column with a different column than parent.column
  if (
    header.subHeaders.length &&
    header.subHeaders[0].column.id !== column.id
  ) {
    defaultKeys.push(
      ...defaultDropdownKeys.filter(key =>
        ['ascending', 'descending', 'filter'].includes(key)
      )
    );
  }

  const idx = header.subHeaders.length ? header.index : column.getIndex();

  if (!!column.getIsSorted()) {
    defaultKeys = defaultKeys.filter(key => key !== 'clearSort');
  }

  //single parent column displayed
  if (idx === 1 && idx === lastColumnIndex) {
    return ['left', 'start', 'right', 'end', ...defaultKeys];
  }

  if (idx === 1) {
    return ['left', 'start', ...defaultKeys];
  }

  if (idx === lastColumnIndex) {
    return ['right', 'end', ...defaultKeys];
  }

  return defaultKeys;
};

export const isHeaderPinned = (
  header: Header<ScreenerTableData, unknown>,
  pinnedFields: string[]
) => {
  if (header.column.parent) {
    return false;
  }

  return header.column.columns.length
    ? pinnedFields.some(id => header.column.columns.some(c => c.id === id))
    : pinnedFields.includes(header.column.id);
};

export const getHeaderFilter = (
  header: Header<ScreenerTableData, unknown>,
  activeFilterOptions: ActiveFilterOptions[]
) => {
  const meta = header.column.columnDef.meta as ColumnMeta;
  const { filterKey, isMultiColumnFilter } = getColumnFilterKeyDetails(meta);
  const filterOptions = activeFilterOptions.find(f => f.filter === filterKey);

  if (!filterOptions) {
    return {
      isMultiColumnFilter,
    };
  }

  const hasValidChildFilters = filterOptions.options.every(
    opt => !opt.childName || meta?.filter_children?.includes(opt.key)
  );
  const shouldReturnFilterOptions = hasValidChildFilters || isMultiColumnFilter;
  const headerFilter = shouldReturnFilterOptions ? filterOptions : undefined;

  return {
    headerFilter,
    isMultiColumnFilter,
  };
};

export const getCategoryData = (
  data: InfiniteData<FilterQueryResultData> | undefined,
  tab: string
) => {
  const latestData = data?.pages[data?.pages.length - 1];
  return latestData?.filterResults.data.find(d => d.category === tab);
};

type TabRedirectConfig = {
  group: 'Knowledge Graph Filters' | 'Market Filters';
  ids?: string[];
  tabFn: (id: string, categoryData?: FilterResultsCategoryData) => string;
};
const tabRedirectMapping: Record<string, TabRedirectConfig> = {
  knowledgeGraph: {
    group: 'Knowledge Graph Filters',
    tabFn: (_: string, categoryData?: FilterResultsCategoryData) => {
      return categoryData ? KNOWLEDGE_GRAPH_TAB : DEFAULT_TAB;
    },
  },
  events: {
    group: 'Market Filters',
    ids: Object.values(ScreenerEvents),
    tabFn: (id: string) => eventToDisplayName[id as ScreenerEvents],
  },
  keyDev: {
    group: 'Market Filters',
    ids: Object.values(KeyDevelopments),
    tabFn: (_: string) => KEY_DEV_TAB,
  },
};

export const getEventsOrKeyDevsTab = ({
  filterGroups,
}: {
  filterGroups: FilterGroups<'Knowledge Graph Filters' | 'Market Filters'>;
}) => {
  const marketFilters = filterGroups['Market Filters'];

  const keyDevelopmentTypes = Object.values(KeyDevelopments) as string[];
  const keyDevsFilter = marketFilters.activeFilterOptions.find(filter =>
    keyDevelopmentTypes.includes(filter.id)
  );

  const eventsFilterTypes = Object.values(ScreenerEvents) as string[];
  const eventsFilter = marketFilters.activeFilterOptions.find(filter =>
    eventsFilterTypes.includes(filter.id)
  )?.id as ScreenerEvents;

  if (keyDevsFilter) {
    return KEY_DEV_TAB;
  }

  if (eventsFilter) {
    return eventToDisplayName[eventsFilter];
  }

  return DEFAULT_TAB;
};

export const getNewTableTab = ({
  prevFilterGroups,
  filterGroups,
  categoryData,
}: {
  categoryData?: FilterResultsCategoryData;
  prevFilterGroups?: FilterGroups<'Knowledge Graph Filters' | 'Market Filters'>;
  filterGroups: FilterGroups<'Knowledge Graph Filters' | 'Market Filters'>;
}) => {
  for (const config of Object.values(tabRedirectMapping)) {
    const { group, ids, tabFn } = config;
    let prevFilters = prevFilterGroups?.[group].activeFilterOptions ?? [];
    let filters = filterGroups[group].activeFilterOptions;

    if (ids) {
      prevFilters = prevFilters.filter(filter => ids.includes(filter.id));
      filters = filters.filter(filter => ids.includes(filter.id));
    }

    const prevFilterMapping = prevFilters.reduce((mapping, prevFilter) => {
      mapping[prevFilter.id] = prevFilter;
      return mapping;
    }, {} as Record<string, ActiveFilterOptions>);

    const diffFilter = filters.find(filter => {
      const prevFilter = prevFilterMapping[filter.id];
      return prevFilter ? !isEqual(filter, prevFilter) : true;
    });

    if (diffFilter) {
      return tabFn(diffFilter.id, categoryData);
    }
  }

  return categoryData
    ? categoryData.category
    : getEventsOrKeyDevsTab({ filterGroups });
};

export const getCellAlignment = (
  columnId: string,
  parentColLength: number = 0
): Property.TextAlign => {
  const isRankColumns = ['theme.rank', 'company.rank'].some(partialId =>
    columnId.includes(partialId)
  );

  if (isRankColumns) {
    return parentColLength > 1 ? 'end' : 'center';
  }

  return 'end';
};

interface Props {
  meta: ColumnMetaWithCategory[];
  resetToDefault?: boolean;
  defaultColumnOrder?: ColumnMetaWithCategory['field'][];
  columnOrder?: ColumnMetaWithCategory['field'][];
}

export const getOrderedMetaFields = ({
  meta,
  resetToDefault,
  defaultColumnOrder,
  columnOrder,
}: Props) => {
  const metaFields = [
    SCREENER_TICKER_COLUMN_ID,
    ...meta.map(item => item.field),
  ];

  if (resetToDefault) {
    return defaultColumnOrder || metaFields;
  }

  if (!columnOrder) {
    return metaFields;
  }

  return metaFields.sort((a, b) => {
    const idx1 = columnOrder.indexOf(a);
    const idx2 = columnOrder.indexOf(b);

    if (idx1 === -1 || idx2 === -1) {
      return 1;
    }

    return Math.sign(idx1 - idx2);
  });
};
