import { useLocation } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';
import { createThemeOverviewMessage } from '~/services/create-theme-overview-message/createThemeOverviewMessage';

import { useNavigateAlfred } from '../use-navigate-alfred/useNavigateAlfred';

export const useNavigateToThemeOverview = () => {
  const location = useLocation();
  const navigateToAlfred = useNavigateAlfred();

  const navigateToChatMessage = (themeId: string, themeName: string) => {
    createThemeOverviewMessage({ themeId, themeName });

    if (location.pathname !== appPaths.alfred) {
      navigateToAlfred('intel');
    }
  };

  return {
    navigateToChatMessage,
  };
};
