import {
  ChartColors,
  getPricesForAllHorizons,
  TimeSeriesItem,
} from '@toggle/chart';

import { UseTurboChartReturn } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';

export const getPriceHorizonSettings = ({
  horizonRanges,
  horizon,
  dailyTimeseries,
  colors,
}: {
  horizonRanges: UseTurboChartReturn['horizonRanges'];
  dailyTimeseries: TimeSeriesItem[];
  horizon: UseTurboChartReturn['chartState']['horizon'];
  colors: Pick<ChartColors, 'bars' | 'line'>;
}) => {
  const rangesWithPrice = getPricesForAllHorizons({
    horizonRanges,
    timeseries: dailyTimeseries,
    lastIdx: dailyTimeseries.length - 1,
    colors,
  });
  const foundRange = rangesWithPrice.find(r => r.horizon === horizon);

  return { rangesWithPrice, lineColorToken: foundRange?.lineColorToken };
};
