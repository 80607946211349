import styled from 'styled-components';

export const SvgChart = styled.svg`
  width: 100%;
  height: 56px;
  border-radius: 3px;
`;

export const SvgBounds = styled.svg`
  width: 100%;
  height: 16px;
`

export const OverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
`;

export const RectBackground = styled.rect`
  fill: var(--surface-default);
  width: inherit;
  height: 100%;
`;

export const PathPlot = styled.path`
  stroke-width: 1;
  fill: none;
  stroke: var(--surface-inverse);
`;

export const LineXAxis = styled.line`
  stroke: var(--icon-disabled);
  stroke-width: 1;
`;

export const ZeroLine = styled.line`
  stroke: var(--icon-disabled);
  stroke-width: 1;
  stroke-dasharray: 1 1;
`;
