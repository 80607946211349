import { NumericalCellValueStyle } from '@toggle/helpers';
import styled from 'styled-components';
import { ChartTooltip } from '~/charts/chart-tooltip/ChartTooltip';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Container = styled.div`
  min-width: 180px;
  padding: 12px;
  background: var(--surface-default);
  ${borderRadius.r1}
  box-shadow: var(--elevation-strong);
  pointer-events: none;
`;

export const DayLabel = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  margin-bottom: 8px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.span`
  ${typographyV2.bodyXs};
`;

export const ColoredValue = styled(Label)<{
  $numericalStyle: NumericalCellValueStyle;
}>`
  color: ${({ $numericalStyle }) =>
    ({
      [NumericalCellValueStyle.Positive]: 'var(--viz-bullish)',
      [NumericalCellValueStyle.Negative]: 'var(--viz-bearish)',
      [NumericalCellValueStyle.Neutral]: 'var(--text-default)',
    }[$numericalStyle])};
`;

export const StyledChartTooltip = styled(ChartTooltip)`
  padding: 12px 0;

  ${ChartTooltip.Styled.Title} {
    padding: 0 12px;
  }
`;

export const Separator = styled.div`
  margin: 5px 0;
  border-bottom: 1px dashed var(--border-default);
`;
