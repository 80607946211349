import { ChartAssetData } from '@toggle/chart';
import { precisionFixed } from 'd3-format';
import { ScaleLinear } from 'd3-scale';

import { Domain, PriceDisplay, PriceRange } from '~/types/axis.types';
import { ChartPane, YAxis } from '~/types/create.types';
import { TimeSeriesItem } from '~/types/timeseries.types';
import { extent } from '~/utils/extent/extent';

interface MinMax {
  min: keyof TimeSeriesItem;
  max: keyof TimeSeriesItem;
}

// TODO we could improve these typings with generics
export const calculateDataRange = (data: any[], minMax: MinMax): PriceRange => {
  const [min, max] = extent(data, minMax);

  return { min, max, length: max - min };
};

export const Y_AXIS_MIN_WIDTH = 67.5;
export const Y_AXIS_PADDING = 5;
export const FONT_IDX_WIDTH = 8;

export const calculateYAxisWidth = (labels: string[]) => {
  const lastLabel = labels[labels.length - 1];

  if (!lastLabel) {
    return Y_AXIS_MIN_WIDTH;
  }

  const priceLabel =
    lastLabel.length > labels[0].length ? lastLabel : labels[0];
  const priceLabelWidth = priceLabel.length * FONT_IDX_WIDTH + Y_AXIS_PADDING;
  return Math.max(priceLabelWidth, Y_AXIS_MIN_WIDTH);
};

export const getYAxisTicks = (
  yScale: ScaleLinear<number, number>,
  ticksCount: number
) => {
  const domain = yScale.domain();
  const axisHeight = Math.abs(domain[1] - domain[0]);
  const offsetTop = axisHeight * 0.05;
  const offsetBottom = axisHeight * 0.05;
  const ticks = yScale
    .copy()
    .ticks(ticksCount)
    .filter(t => t < domain[1] - offsetTop && t > domain[0] + offsetBottom);

  const decimals = precisionFixed(ticks[1] - ticks[0]) || 0;

  const format = (n: number) =>
    n.toLocaleString(window.navigator.language, {
      minimumFractionDigits: Math.max(2, decimals),
    });

  return domain[0] === domain[1]
    ? { ticks: [domain[0]], labels: [format(domain[0])] }
    : {
        ticks,
        labels: ticks.map(t => format(t)),
      };
};

export const getYAxisDomain = (domain: Domain): Domain => [
  Math.floor(domain[0]),
  Math.floor(domain[1]),
];

export const getPercentage = (value: number, baseValue: number) =>
  ((value - baseValue) / baseValue) * 100;

export const getYAxisCoordinate =
  (priceDisplay: PriceDisplay, y: YAxis, baseValue: number) =>
  (value: number) =>
    priceDisplay === 'price'
      ? y.yScale(value)
      : y.yScale(getPercentage(value, baseValue));

export const getCurrentYIndex = ({
  isAxisMerged,
  pane,
  asset,
}: {
  isAxisMerged: boolean;
  pane: ChartPane;
  asset: ChartAssetData;
}) =>
  isAxisMerged
    ? 0
    : pane.y.findIndex(
        e => e.entityDefaultSnake === asset.entity.default_snake
      );
