import { BaseScrollGlobalStyle } from '@toggle/design-system';
import React, { FC, Suspense, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet, useLocation } from 'react-router';

import { Loader } from '~/components/loader/Loader';
import { ToastsLayout } from '~/components/toasts-layout/ToastsLayout';
import { appPaths } from '~/routes/app-paths';
import { livePriceWebSocket } from '~/stores/live-price-websocket/livePriceWebSocket';
import { getSession, useAuth } from '~/stores/use-auth/useAuth';
import { useFlags } from '~/stores/use-flags/useFlags';

import { AppMetadata } from './components';
import { AppHistory } from './components/app-history/AppHistory';
import { Maintenance } from './components/maintenance/Maintenance';
import { MaintenanceBanner } from './components/maintenance-banner/MaintenanceBanner';
import { NotificationAlerts } from './components/notification-alerts/NotificationAlerts';
import { ThemeController } from './components/theme-controller/ThemeController';
import {
  useMediaQuery,
  useMediaQueryV2,
  useTouchDeviceV2,
} from './hooks/MediaQueryHook';
import { useNotificationsListener } from './hooks/use-notifications-listener/useNotificationsListener';
import { useNavigator } from './stores/use-navigator/useNavigator';
import { setCurrentLanguage } from './utils/i18n/setCurrentLanguage';
import { MobileNotSupported } from './views/auth/mobile-not-supported/MobileNotSupported';

export const Root: FC = () => {
  const { authProcessing, initStores, setAuthProcessing } = useAuth();
  const { listenToNavigator, language: navigatorLanguage } = useNavigator();

  const location = useLocation();
  const isToucheDevice = useTouchDeviceV2();
  const isIbRoute = location.pathname.includes(appPaths.ib);
  const { languages, checkIsInMaintenance } = useFlags(state => ({
    languages: state.systemFlags.languages,
    checkIsInMaintenance: state.checkIsInMaintenance,
  }));
  useNotificationsListener();

  const initializeMediaQuery = useMediaQuery(s => s.initialize);
  const initializeMediaQueryV2 = useMediaQueryV2(s => s.initialize);

  const initSession = async () => {
    const session = await getSession();

    if (session) {
      await initStores(session);
    }

    setAuthProcessing(false);
  };

  useEffect(() => {
    setCurrentLanguage(languages);
  }, [languages, navigatorLanguage]);

  useEffect(() => {
    initSession();
    initializeMediaQuery();
    initializeMediaQueryV2();

    return () => {
      livePriceWebSocket.getState().closeConnection();
    };
  }, []);

  useEffect(() => {
    listenToNavigator();
  }, []);

  if (checkIsInMaintenance()) {
    return (
      <>
        <ThemeController />
        <Suspense fallback={<Loader />}>
          <Maintenance />
        </Suspense>
      </>
    );
  }

  return (
    <>
      <ThemeController />
      <AppMetadata path={location.pathname} />
      <Suspense fallback={<Loader />}>
        {authProcessing ? (
          <Loader />
        ) : (
          <>
            <BaseScrollGlobalStyle />
            <MaintenanceBanner />
            <NotificationAlerts />
            <DndProvider backend={HTML5Backend}>
              <AppHistory>
                {isToucheDevice && !isIbRoute ? (
                  <MobileNotSupported />
                ) : (
                  <Outlet />
                )}
              </AppHistory>
            </DndProvider>
          </>
        )}
      </Suspense>
      <ToastsLayout />
    </>
  );
};
