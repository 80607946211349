import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EarningsChartRoot = styled.div`
  ${borderRadius.r2};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--border-soft);
  margin-bottom: 32px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  border-bottom: 1px solid var(--border-soft);
  min-height: 1em;
  box-sizing: content-box;
  ${typographyV2.bodyBase};
`;

export const Content = styled.div`
  padding: 12px 20px;
`;

export const ChartWrapper = styled.div`
  margin-bottom: 20px;
`;
