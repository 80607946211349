import { Input, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const InputContainer = styled.div`
  display: flex;
`;

export const InputLabel = styled.label`
  padding-right: 12px;
  color: var(--text-default);
  ${typographyV2.bodyMd};
  display: flex;
  flex-direction: row;
  padding-top: 8px;
`;

export const StyledInput = styled(Input)`
  ${Input.Styles.StyledLabel} {
    color: var(--text-soft);
  }
  flex: 1;
`;

export const ButtonContainer = styled.footer`
  display: flex;
  padding-top: 36px;
  justify-content: flex-end;
  gap: 8px;
`;
