import { DisplayFormat } from '@toggle/toggle';

import { PriceDisplay } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  ChartPane,
  CreateChartOptionsWithColors,
  YAxis,
} from '~/types/create.types';
import { ChartAssetData, TsDataPoint } from '~/types/timeseries.types';
import { formatPriceLabel } from '~/utils/format';

export interface DrawYAxisProps
  extends Pick<BaseChartAPIProps, 'options' | 'context'> {
  y: YAxis;
  isPrimaryAsset: boolean;
  colorOverride?: string;
  priceDisplay: ChartPane['priceDisplay'];
  yAxisSize: BaseChartAPIProps['yAxisSizes'][0];
  asset: ChartAssetData;
}

export const drawYAxis = ({
  options,
  context,
  y,
  isPrimaryAsset,
  priceDisplay,
  colorOverride,
  asset,
  yAxisSize,
}: DrawYAxisProps) => {
  context.font = options.font.mono;

  drawAxisTicks({
    context,
    y,
    options,
    isPrimaryAsset,
    colorOverride,
    asset,
    yAxisSize,
  });
  drawAxisBorder({ yAxisSize, context, y, options });

  if (priceDisplay === 'percentage') {
    drawZeroLine({
      context,
      options,
      y,
    });
  }
};

export const defaultAxisValues = ({
  yAxisSize,
  options,
}: {
  options: CreateChartOptionsWithColors;
  y: YAxis;
  yAxisSize: DrawYAxisProps['yAxisSize'];
}) => {
  const textToBorderGap = 8;
  const tickX = options.width - options.gutters.y + yAxisSize.xStart;
  const textX = tickX + textToBorderGap;

  return {
    textToBorderGap,
    tickX,
    textX,
  };
};

interface DrawAxisTicksProps extends Pick<DrawYAxisProps, 'yAxisSize'> {
  context: CanvasRenderingContext2D;
  y: YAxis;
  options: CreateChartOptionsWithColors;
  isPrimaryAsset: boolean;
  colorOverride?: string;
  asset: ChartAssetData;
}

const drawAxisTicks = ({
  context,
  y,
  options,
  isPrimaryAsset,
  colorOverride,
  asset,
  yAxisSize,
}: DrawAxisTicksProps) => {
  const defaultValues = defaultAxisValues({ options, y, yAxisSize });

  for (let index = 0; index < y.ticks.length; index++) {
    const mark = y.ticks[index];
    const label = y.labels[index];

    const yCoord = y.yScale(mark);
    const isAnyThresholdEqualToTick = asset.snakeMeta?.threshold
      ? Object.values(asset.snakeMeta.threshold).some(value => mark === value)
      : false;
    const shouldDrawGrid = isAnyThresholdEqualToTick ? false : isPrimaryAsset;

    if (shouldDrawGrid) {
      drawGridLine(context, options, yCoord);
    }

    context.fillStyle = colorOverride ?? options.colors.typography.soft.value;

    drawText({
      context,
      label,
      yCoord,
      textX: defaultValues.textX,
    });
  }
};

export interface DrawAxisMarksProps extends Pick<DrawYAxisProps, 'yAxisSize'> {
  context: CanvasRenderingContext2D;
  value: TsDataPoint['value'];
  options: CreateChartOptionsWithColors;
  y: YAxis;
  priceDisplay: PriceDisplay;
  circleColor: string;
  yCoord: number;
}

export const drawYAxisMark = ({
  context,
  value,
  options,
  yCoord,
  priceDisplay,
  circleColor,
  y,
  yAxisSize,
}: DrawAxisMarksProps) => {
  context.font = options.font.mono;
  const defaultValues = defaultAxisValues({ options, y, yAxisSize });
  const label = formatPriceLabel(value, priceDisplay);
  const textSize = context.measureText(label);
  const rectHeight =
    textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent + 4;

  const centeredRect = yCoord - rectHeight / 2;

  context.fillStyle = options.colors.surface.default.value;
  context.fillRect(
    defaultValues.tickX,
    centeredRect,
    yAxisSize.width,
    rectHeight
  );

  context.fillStyle = options.colors.typography.highlight.value;

  drawText({
    context,
    label,
    yCoord,
    textX: defaultValues.textX,
    fillStyle: circleColor,
  });
};

interface DrawTextProps {
  context: CanvasRenderingContext2D;
  label: string;
  yCoord: number;
  textX: number;
  fillStyle?: string;
}

const drawText = ({
  context,
  label,
  yCoord,
  textX,
  fillStyle = '',
}: DrawTextProps) => {
  context.textBaseline = 'middle';
  context.textAlign = 'left';
  context.fillStyle = fillStyle;
  context.fillText(label, textX, yCoord);
};

const drawAxisBorder = ({
  context,
  y,
  options,
  yAxisSize,
}: {
  context: CanvasRenderingContext2D;
  y: YAxis;
  options: CreateChartOptionsWithColors;
  yAxisSize: DrawYAxisProps['yAxisSize'];
}) => {
  const defaultValues = defaultAxisValues({ options, y, yAxisSize });
  context.fillStyle = options.colors.border.default.value;
  context.fillRect(
    defaultValues.tickX,
    0,
    1,
    options.height - options.gutters.x + 1
  );
};

const drawGridLine = (
  context: CanvasRenderingContext2D,
  options: CreateChartOptionsWithColors,
  yCoord: number
) => {
  context.save();
  context.globalCompositeOperation = 'destination-over';
  context.fillStyle = options.colors.border.soft.value;
  context.fillRect(0, yCoord, options.width - options.gutters.y, 1);
  context.restore();
};

export const drawZeroLine = ({
  context,
  y,
  options,
}: {
  context: CanvasRenderingContext2D;
  options: CreateChartOptionsWithColors;
  y: YAxis;
}) => {
  context.fillStyle = options.colors.border.default.value;
  context.fillRect(0, y.yScale(0), options.width - options.gutters.y, 1);
};

interface DrawChartUnitProps {
  context: CanvasRenderingContext2D;
  options: CreateChartOptionsWithColors;
  y: YAxis;
  pane: ChartPane;
  displayFormat?: string;
  currency: string;
  yAxisSize: BaseChartAPIProps['yAxisSizes'][0];
}

export const drawChartUnit = ({
  context,
  options,
  pane,
  y,
  displayFormat,
  currency,
  yAxisSize,
}: DrawChartUnitProps) => {
  let label = '';

  switch (displayFormat) {
    case DisplayFormat.CURRENCY:
      label = currency.toUpperCase();
      break;
    case DisplayFormat.PERCENT:
      label = '%';
      break;
    default:
      break;
  }

  if (label) {
    const defaultValues = defaultAxisValues({ options, y, yAxisSize });

    const BORDER_PADDING = 10;
    const FONT_SIZE = 12;
    const BORDER_RADIUS = 2;
    const BORDER_LINE_HEIGHT = FONT_SIZE * 1.5;
    const textWidth = context.measureText(label).width;

    context.fillStyle = options.colors.default.value;

    const labelX = defaultValues.textX + yAxisSize.width / 2;
    const labelTop = pane.options.gutters.top + pane.options.panePaddingTop / 2;

    context.fillText(label, labelX, labelTop);

    context.strokeStyle = options.colors.border.soft.value;
    context.beginPath();
    context.roundRect(
      labelX - BORDER_PADDING / 2,
      labelTop - BORDER_LINE_HEIGHT / 2,
      textWidth + BORDER_PADDING,
      BORDER_LINE_HEIGHT,
      BORDER_RADIUS
    );
    context.stroke();
  }
};
