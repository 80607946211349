import React, { CSSProperties, FC } from 'react';

import * as S from './ProgressBar.styles';

interface StyledComponent<P, S> extends FC<P> {
  Styled: S;
}

export interface ProgressBarProps
  extends Pick<CSSProperties, 'animationDuration' | 'animationDelay'> {
  isFrozen?: boolean;
  className?: string;
}

export const ProgressBar: StyledComponent<ProgressBarProps, typeof S> = ({
  isFrozen = false,
  animationDuration,
  animationDelay,
  className,
}) => (
  <S.Bar className={className}>
    <S.Line
      data-testid="progress-line"
      $animationDelay={animationDelay}
      $animationDuration={animationDuration}
      $isFrozen={isFrozen}
    />
  </S.Bar>
);

ProgressBar.Styled = S;
