import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';

import { fetchKeyValues } from '../../services/entity-report-service';

export const QUERY_KEY_VALUES = 'QUERY_KEY_VALUES';
export const useKeyValues = (entityTag: string) =>
  useQuery({
    queryKey: [QUERY_KEY_VALUES, entityTag],
    queryFn: ({ signal }) => fetchKeyValues(entityTag, signal),
    staleTime: getTimeTillEndOfDayMs(),
  });
