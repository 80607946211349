import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const TextBlockRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.p`
  ${typographyV2.titleSm};
  color: var(--text-soft);
`;

export const Text = styled.div`
  ${typographyV2.titleBase};
  color: var(--text-default);
  white-space: pre-line;
`;

export const HelpText = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
`;
