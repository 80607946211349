import { CitationFilters } from '@toggle/toggle';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { EllipsisDetector } from '~/components/ellipsis-detector/EllipsisDetector';
import { useCitationGroupDocuments } from '~/hooks/use-citation-group-documents/useCitationGroupDocuments';
import { useChat } from '~/stores/use-chat/useChat';

import { useDocumentSourcesTableStore } from '../document-sources-table/use-document-sources-table-store/useDocumentSourcesTableStore';
import { CITATION_GROUP_ID } from '../DocumentSourcesView';
import * as S from './DocumentSourcesHeader.styles';

export interface DocumentSourcesHeaderProps {
  onClose: () => void;
  question: string;
  messageIndex: number;
  groupId: string;
  filters?: CitationFilters;
}

const PAGE_SIZE = 1000;

export const DocumentSourcesHeader: FC<DocumentSourcesHeaderProps> = ({
  onClose,
  question,
  messageIndex,
  groupId,
  filters,
}) => {
  const { t } = useTranslation(['chat', 'common']);
  const [params, setParams] = useSearchParams();
  const {
    resetSession,
    sendMessage,
    clearDocumentContextMessages,
    setIsSearchWithinContext,
    priorDocumentContextReplyMap,
    messages,
  } = useChat(state => ({
    resetSession: state.resetSession,
    sendMessage: state.sendMessage,
    clearDocumentContextMessages: state.clearDocumentContextMessages,
    setIsSearchWithinContext: state.setIsSearchWithinContext,
    priorDocumentContextReplyMap: state.priorDocumentContextReplyMap,
    messages: state.messages,
  }));
  const activeFilterOptions = useDocumentSourcesTableStore(
    state => state.filterGroups.DOCUMENT_FILTERS.activeFilterOptions
  );
  const setActiveDocumentIds = useDocumentSourcesTableStore(
    state => state.setActiveDocumentIds
  );
  const documentGroupQueryResult = useCitationGroupDocuments({
    groupId,
    docIdsOnly: true,
    pageSize: PAGE_SIZE,
    filters,
  });

  const priorDocumentContextReply =
    priorDocumentContextReplyMap[messages[messageIndex]?.id];
  const priorContextOverviewData = priorDocumentContextReply?.data.overview;

  const documentIds = (documentGroupQueryResult.data?.pages ?? []).flatMap(
    page => page.doc_ids ?? []
  );

  const sendNewMessageHandler = (includeDocIds: boolean) => {
    resetSession(true);
    clearDocumentContextMessages();

    sendMessage({
      question,
      target: 'document-assistant',
      ancillary: {
        documents: includeDocIds ? documentIds : undefined,
      },
    });

    const newParams = new URLSearchParams(params);
    newParams.delete(CITATION_GROUP_ID);
    setParams(newParams);
  };

  useEffect(() => {
    if (!documentGroupQueryResult.isLoading) {
      setActiveDocumentIds(documentIds);
    }

    return () => {
      setActiveDocumentIds([]);
    };
  }, [activeFilterOptions.length, documentGroupQueryResult.isLoading]);

  return (
    <S.Root>
      <S.FlexRow>
        <S.LeftContainer>
          <EllipsisDetector label={question}>
            <p>{question}</p>
          </EllipsisDetector>
        </S.LeftContainer>
        <S.ButtonsContainer>
          {!!documentIds.length && activeFilterOptions.length > 0 && (
            <S.StyledButton
              label={t('chat:summarize')}
              aria-label={t('chat:summarize')}
              variant="secondary"
              size="small"
              iconName="AlfredNav"
              data-testid="summarize-button"
              onClick={() => sendNewMessageHandler(true)}
            />
          )}
          <S.StyledButton
            label={t('common:close')}
            aria-label={t('common:close')}
            variant="empty"
            size="small"
            iconName="Close"
            iconPosition="right"
            data-testid="close-button"
            onClick={onClose}
          />
        </S.ButtonsContainer>
      </S.FlexRow>
      {!!priorContextOverviewData?.documents &&
        !!priorContextOverviewData?.companies && (
          <S.SubheaderWrapper data-testid="continue-context">
            <S.StyledSubheaderLabel>
              {`${t(
                'chat:documentSourcesTable.searchedWithinPriorContext'
              )} ${t('chat:documentSourcesTable.document', {
                count: priorContextOverviewData.documents,
              })} ${t('chat:documentSourcesTable.from')} ${t(
                'chat:documentSourcesTable.company',
                {
                  count: priorContextOverviewData.companies,
                }
              )}`}
            </S.StyledSubheaderLabel>
            <S.StyledButtonLink
              label={t('chat:documentSourcesTable.clearContextAndSearchAll')}
              onClick={() => {
                setIsSearchWithinContext(false);
                sendNewMessageHandler(false);
              }}
            />
          </S.SubheaderWrapper>
        )}
    </S.Root>
  );
};
