import { v4 } from 'uuid';

import {
  NotificationResponseTypes,
  NotificationSocketResponses,
  NotificationWs,
} from '~/services/notification-ws/NotificationWs';

import { create } from '../create-store/createStore';

interface UseNotificationsStore {
  ws: NotificationWs | null;
  messages: {
    id: string;
    type: NotificationResponseTypes;
    response: NotificationSocketResponses;
  }[];
  subscribeToMessageOfType: NotificationWs['subscribe'];
  initialize: () => NotificationWs;
  listenMessages: () => void;
  unsubscribe: () => void;
}

// Possible usage #1
// const _useGlobalHook = () => {
//   const { subscribeToMessageOfType } = useNotifications();

//   useEffect(() => {
//     return subscribeToMessageOfType('alfred', message => {
//       //fetch alfred messages
//       // ...
//       // check if NOT on alfred page --> show toast and update counter in sidebar?
//     });
//   }, []);
// };

// Possible usage #2
// const _useGlobalHook = () => {
//   const listenMessages = useNotifications(state => state.listenMessages);

//   useEffect(() => {
//     listenMessages();
//     return useNotifications.subscribe((state, prevState) => {
//       if (state.messages !== prevState.messages) {
//         // grab last message and do smth?
//       }
//     });
//   }, []);
// };

export const useNotifications = create<UseNotificationsStore>((set, get) => ({
  ws: null,
  messages: [],
  initialize: () => {
    const { ws } = get();

    if (ws && !ws.closed) {
      return ws;
    }

    const instance = new NotificationWs();
    set({ ws: instance });
    return instance;
  },
  subscribeToMessageOfType: (type, callback) => {
    const { initialize } = get();
    const wsInstance = initialize();
    return wsInstance.subscribe(type, callback);
  },
  listenMessages: () => {
    const { initialize } = get();
    const wsInstance = initialize();

    wsInstance.onmessage = (message: NotificationSocketResponses) => {
      const newMessage = {
        id: v4(),
        type: message.event.type,
        response: message,
      };

      set(prev => ({ messages: [...prev.messages, newMessage] }));
    };
  },
  unsubscribe: () => {
    const { ws } = get();
    ws?.unsubscribe();
    set({ ws: undefined });
  },
}));
