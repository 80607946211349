import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  InsightCardDetails,
  InsightCardDetailsProps,
} from '../insight-card-details/InsightCardDetails';
import * as S from './InsightCard.styles';

export interface InsightCardProps extends InsightCardDetailsProps {
  title: string;
  imageSrc?: string;
  onClick: () => void;
}

export const InsightCard = ({
  title,
  imageSrc,
  onClick,
  ...rest
}: InsightCardProps) => {
  const { t } = useTranslation('common');
  return (
    <S.Root onClick={onClick}>
      <S.Container>
        <S.Title>{title}</S.Title>
        <InsightCardDetails {...rest} />
      </S.Container>
      {imageSrc && (
        <S.Image
          src={imageSrc}
          alt={t('common:altImage', { component: 'Insight' })}
        />
      )}
    </S.Root>
  );
};
