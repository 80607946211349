import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './CloseButton.styles';

export interface CloseButtonProps {
  label?: string;
  onClick: () => void;
}

export const CloseButton = ({ label, onClick }: CloseButtonProps) => {
  const { t } = useTranslation('news');

  return (
    <S.StyledButton
      data-testid="close-button"
      label={label ?? t('news:searchBar.closeButtonLabel')}
      onClick={onClick}
      variant="empty"
      size="small"
    />
  );
};
