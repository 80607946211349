import {
  GUTTER_SIZE_CUSTOM_EVENT,
  GutterSize,
  GutterSizeEvent,
} from '@toggle/chart';
import { RefObject, useEffect, useState } from 'react';

interface UseGutterSizeProps {
  canvasRef: RefObject<HTMLCanvasElement | null>;
  onGutterSizeChange?: (gutter: GutterSize) => void;
}

export const useGutterSize = ({
  canvasRef,
  onGutterSizeChange,
}: UseGutterSizeProps) => {
  const [gutterSize, setGutterSize] = useState<GutterSize>(() => ({
    x: 40,
    y: 40,
    paneLimitExceeded: false,
    panes: [],
    yAxisSizes: [],
  }));

  useEffect(() => {
    const canvasElement = canvasRef.current;

    if (!canvasElement) {
      return undefined;
    }

    const handleGutterSizeChange = (event: Event) => {
      const gutter = (event as GutterSizeEvent).detail;
      onGutterSizeChange?.(gutter);
      setGutterSize(gutter);
    };

    canvasElement.addEventListener(
      GUTTER_SIZE_CUSTOM_EVENT,
      handleGutterSizeChange
    );

    return () => {
      canvasElement.removeEventListener(
        GUTTER_SIZE_CUSTOM_EVENT,
        handleGutterSizeChange
      );
    };
  }, [gutterSize]);

  return { gutterSize };
};
