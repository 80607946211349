import { Entity } from '@toggle/toggle';

import { useChartNavigate } from '~/hooks/use-chart-navigate/useChartNavigate';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { ChartSearchParamsAssetValue } from '~/utils/chart-search-utils/chart-search-utils';
import { ChartState } from '~/views/turbo-chart/hooks/use-chart-state/useChartState';

export const useAssetOverviewNavigation = (entity: Entity) => {
  const chartNavigate = useChartNavigate();
  const { navigateToPage } = useNavigateToAssetOverview();

  const navigateToAssetOverview = () => {
    navigateToPage({ entityTag: entity.tag });
  };

  const navigateToChart = (chartState: ChartState) => {
    const entityValue: ChartSearchParamsAssetValue = [
      entity.tag,
      entity.default_snake,
      0,
    ];

    chartNavigate([entityValue], {
      resample: chartState.resample,
      horizon: chartState.horizon,
      seriesType: chartState.chartPanes[0].seriesType,
    });
  };

  return {
    navigateToAssetOverview,
    navigateToChart,
  };
};
