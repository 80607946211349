import { PriceHoverData, TimeSeriesItem } from '@toggle/chart';
import { PriceStatus } from '@toggle/helpers';
import { AssetSubClassName, Entity, MappedEntity } from '@toggle/toggle';

import {
  LivePriceData,
  LivePriceInfo,
  mapLivePriceData,
  PriceEntity,
} from '~/hooks/use-live-price';

export const EST_TIME_ZONE = 'America/New_York';

interface PriceChangeParams {
  lastPrice: number;
  newPrice: number;
  decimals?: number;
  isPrice?: boolean;
}

function getCommonChangeParams(
  lastPrice: number,
  newPrice: number,
  decimals: number
) {
  let change = (0).toFixed(decimals);
  let status = PriceStatus.Default;
  const absoluteChange = newPrice - lastPrice;

  if (!lastPrice) {
    return { sign: '', change, status, isChanged: false };
  }

  const sign = Math.sign(absoluteChange) >= 0 ? '+' : '-';
  const isChanged = absoluteChange !== 0;

  if (isChanged) {
    change = `${sign}${Math.abs(absoluteChange).toFixed(decimals)}`;
    status = sign === '+' ? PriceStatus.Positive : PriceStatus.Negative;
  }

  return { sign, change, status, isChanged };
}

export function getPriceChange({
  lastPrice,
  newPrice,
  decimals = 2,
  isPrice = true,
}: PriceChangeParams) {
  const suffix = isPrice ? '%' : 'bps';
  let proportionChange = `0.00${suffix}`;
  const { sign, status, change, isChanged } = getCommonChangeParams(
    lastPrice,
    newPrice,
    decimals
  );

  if (isChanged) {
    const percentageValue = isPrice
      ? (newPrice / lastPrice - 1) * 100
      : (newPrice - lastPrice) * 100;

    proportionChange = `${sign}${Math.abs(percentageValue).toFixed(
      2
    )}${suffix}`;
  }

  return {
    priceChange: {
      status,
      change,
      proportionChange,
    },
  };
}

export function getTickerForLivePrice(
  entity: Pick<Entity, 'subscribable_ticker'>
) {
  return entity.subscribable_ticker;
}

export const isPriceSnake = (entity: MappedEntity, snakeName = '') =>
  snakeName === entity.default_snake;

export const isFX = (subClass: string) =>
  subClass === AssetSubClassName.MajorCross ||
  subClass === AssetSubClassName.GenericCross;

export const getPriceData = ({
  hoverData,
  livePriceData,
  entity,
  ts,
  priceEntity,
}: {
  hoverData?: PriceHoverData;
  livePriceData: LivePriceData;
  entity: MappedEntity;
  ts: TimeSeriesItem[];
  priceEntity?: PriceEntity;
}): LivePriceInfo => {
  if (hoverData?.currentPrice && hoverData?.previousPrice) {
    return mapLivePriceData({
      entity,
      lastKnownPrice: hoverData.currentPrice.close,
      priceBefore:
        hoverData.previousPrice.close || hoverData.currentPrice.close,
      time: hoverData.currentPrice.time,
    });
  }

  if (!livePriceData[entity.ticker] && ts.length) {
    return mapLivePriceData({
      entity,
      lastKnownPrice: ts[ts.length - 1].close,
      priceBefore: ts[ts.length - 2].close || ts[ts.length - 1].close,
      time: ts[ts.length - 1].time,
    });
  }

  if (!livePriceData[entity.ticker] && priceEntity) {
    return mapLivePriceData({
      entity,
      lastKnownPrice: priceEntity.lastKnownPrice,
      priceBefore: priceEntity.priceBefore,
      time: priceEntity.time,
    });
  }

  return livePriceData[entity.ticker];
};
