import { Badge, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { FlagIcon } from '../../../flag-icon/FlagIcon';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 12px;
  border-bottom: 1px solid var(--border-soft);
  background: var(--surface-hover);

  mark {
    background-color: transparent;
    color: var(--text-primary);
  }
`;

const sharedText = css`
  height: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftText = styled.span`
  ${sharedText}
  ${typographyV2.bodyBase}
  flex: 1;
`;

export const RightText = styled.span`
  ${sharedText}
  ${typographyV2.bodyBase}
  flex: 3;
`;

export const StyledBadge = styled(Badge)`
  ${Badge.Styled.BadgeTextual} {
    gap: 0;
  }
`;

export const StyledFlagIcon = styled(FlagIcon)`
  height: 16px;
  width: 16px;
`;

export const BadgeLabel = styled.span`
  margin-left: 4px;
  &:hover {
    color: var(--text-primary);
  }
`;
