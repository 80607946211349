export const isArrayOrObject = (item: any): item is Record<string, any> =>
  item && typeof item === 'object';

export const mergeDeep = (
  target: Record<string, any>,
  partial: Record<string, any>
) => {
  const result: Record<string, any> = Array.isArray(target)
    ? [...target]
    : { ...target };

  if (isArrayOrObject(target) && isArrayOrObject(partial)) {
    Object.keys(partial).forEach(key => {
      if (target[key]) {
        if (isArrayOrObject(target[key])) {
          result[key] = mergeDeep(target[key], partial[key]);
        } else {
          result[key] = partial[key];
        }
      } else {
        result[key] = partial[key];
      }
    });
  }

  return result;
};
