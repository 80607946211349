import { Column, ColumnDef, Row, Table } from '@tanstack/react-table';
import { Icon } from '@toggle/design-system';
import {
  Citation,
  DocumentGroup,
  DocumentSourcesTableData,
} from '@toggle/toggle';
import { TFunction } from 'i18next';
import React from 'react';

import { TableAction } from '~/views/screener/results/screener-table-utils';

import { EntityDetailsCell } from '../document-sources-table-cells/entity-details-cell/EntityDetailsCell';
import { ProviderCell } from '../document-sources-table-cells/provider-cell/ProviderCell';
import { UngroupedPassageCell } from '../document-sources-table-cells/ungrouped-passage-cell/UngroupedPassageCell';
import * as S from './DocumentSourcesTable.styles';

const GROUPED_DOCUMENTS_COMPANY_COLUMN_ID = 'entity';
const GROUPED_DOCUMENTS_DOCUMENT_COLUMN_ID = 'kind';
const GROUPED_DOCUMENTS_PASSAGE_COLUMN_ID = 'citations_count';
const GROUPED_DOCUMENTS_RELEVANCE_COLUMN_ID = 'rank';

export const getIsSubRow = (row: Row<DocumentSourcesTableData>) =>
  row.depth > 0;

const getCitationRow = (row: Row<DocumentSourcesTableData>) =>
  (row as Row<Citation>).original;

const getDocumentGroupRow = (row: Row<DocumentSourcesTableData>) =>
  (row as Row<DocumentGroup>).original;

export const getClickedCitation = (
  row: Row<DocumentSourcesTableData>,
  isGrouped: boolean
) => {
  if (isGrouped) {
    if (getIsSubRow(row)) {
      return getCitationRow(row);
    }

    const documentRow = getDocumentGroupRow(row);
    const firstSubRow = documentRow.subRows?.[0];
    return firstSubRow ? { ...firstSubRow, pages: [] } : undefined;
  }

  return getCitationRow(row);
};

export const getAlignment = (index: number, isGrouped: boolean) => {
  const shouldAlignRight = isGrouped ? index > 1 : index >= 1;
  return shouldAlignRight ? 'end' : 'start';
};

export const getIsAnyRowExpanded = (table: Table<DocumentSourcesTableData>) =>
  table.getRowModel().rows.some(row => row.depth > 0);

export const getPercentage = (score: number) => `${Math.round(score * 100)}%`;

export const getEnabledKeys = (
  column: Column<DocumentSourcesTableData>
): TableAction[] => {
  if (column.id === GROUPED_DOCUMENTS_DOCUMENT_COLUMN_ID) {
    return ['ascendingDocumentType', 'descendingDocumentType'];
  }

  return ['ascending', 'descending'];
};

export const getColumns = ({
  t,
  isGrouped,
}: {
  t: TFunction;
  isGrouped: boolean;
}) => {
  const groupedColumns: ColumnDef<DocumentSourcesTableData>[] = [
    {
      id: GROUPED_DOCUMENTS_COMPANY_COLUMN_ID,
      header: t('chat:documentSourcesTable.company'),
      cell: ({ row }) =>
        getIsSubRow(row) ? (
          <S.SubRowLabelWrapper data-testid="company-subrow">
            <S.SubRowLabel>
              {getCitationRow(row).text ||
                t('chat:documentSourcesTable.placeholderPassage')}
            </S.SubRowLabel>
          </S.SubRowLabelWrapper>
        ) : (
          <S.EntityDetailsWrapper data-testid="company-row">
            <EntityDetailsCell entityTag={row.original.entity} iconSize={20} />
          </S.EntityDetailsWrapper>
        ),
    },
    {
      id: GROUPED_DOCUMENTS_DOCUMENT_COLUMN_ID,
      header: ({ table }) =>
        getIsAnyRowExpanded(table)
          ? t('chat:documentSourcesTable.documentName')
          : t('chat:documentSourcesTable.document'),
      cell: ({ row }) =>
        getIsSubRow(row) ? null : (
          <ProviderCell
            documentKind={getDocumentGroupRow(row).kind}
            date={`${row.original.year} ${row.original.period.toUpperCase()}`}
            customText={
              row.getIsExpanded() && row.subRows?.length > 0
                ? getCitationRow(row.subRows[0])?.document_name
                : undefined
            }
            iconSize={20}
          />
        ),
    },
    {
      id: GROUPED_DOCUMENTS_PASSAGE_COLUMN_ID,
      header: ({ table }) =>
        getIsAnyRowExpanded(table)
          ? t('chat:documentSourcesTable.count')
          : t('chat:documentSourcesTable.passage'),
      cell: ({ row }) =>
        getIsSubRow(row) ? null : (
          <S.NumberContainer data-testid="passage-row">
            {getDocumentGroupRow(row).citations_count}
          </S.NumberContainer>
        ),
    },
    {
      id: GROUPED_DOCUMENTS_RELEVANCE_COLUMN_ID,
      header: t('chat:documentSourcesTable.relevance'),
      cell: ({ row }) => {
        const isRowExpanded = row.getIsExpanded();
        const isSubRow = getIsSubRow(row);
        return (
          <S.RelevanceCellWrapper>
            {!isRowExpanded && (
              <S.NumberContainer data-testid="relevance-score">
                {getPercentage(
                  isSubRow
                    ? getCitationRow(row).score
                    : getDocumentGroupRow(row).relevance
                )}
              </S.NumberContainer>
            )}
            {!isSubRow && (
              <Icon
                data-testid="expand-icon"
                size={12}
                iconName={isRowExpanded ? 'ChevronLightUp' : 'ChevronLightDown'}
              />
            )}
          </S.RelevanceCellWrapper>
        );
      },
    },
  ];

  const ungroupedColumns: ColumnDef<Citation>[] = [
    {
      id: 'passage',
      header: t('chat:documentSourcesTable.passage'),
      cell: ({ row }) => {
        const { text, published_date, entity, document_kind } = row.original;
        return (
          <UngroupedPassageCell
            text={text}
            published_date={published_date}
            entity={entity}
            document_kind={document_kind}
          />
        );
      },
    },
    {
      id: 'relevance',
      header: () => (
        <S.RelevanceHeaderWrapper data-testid="relevance-header">
          <Icon
            size={12}
            iconName="SortDescRight"
            fillColor="var(--icon-soft)"
          />
          {t('chat:documentSourcesTable.relevance')}
        </S.RelevanceHeaderWrapper>
      ),
      cell: ({ row }) => (
        <S.NumberContainer data-testid="relevance-score">
          {getPercentage(row.original.score)}
        </S.NumberContainer>
      ),
    },
  ];

  return isGrouped ? groupedColumns : ungroupedColumns;
};
