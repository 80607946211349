import {
  PillDropdown,
  SkeletonLoader,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

const ColumnFlex = css`
  display: flex;
  flex-direction: column;
`;

export const StyledThemeExposureModuleRoot = styled.div`
  ${ColumnFlex}
`;

export const ThemeListItem = styled.li`
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 30% 70%;
  border-bottom: var(--border-soft) solid 1px;
  ${PillDropdown.Styled.PillDropdownRoot} {
    text-transform: capitalize;
    margin-left: 5px;
  }
`;

export const StyledPillDropdown = styled(PillDropdown)`
  ${typographyV2.bodySm}
  align-items: center;
  text-transform: capitalize;
  padding: 12px 4px;
`;

export const StyledListDescription = styled.p`
  ${typographyV2.bodySm}
  display: flex;
  color: var(--text-soft);
  align-items: center;
  padding: 12px 4px;
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '360px',
        borderRadius: '8px',
      },
    },
  ],
})``;

export const CapitalizedName = styled.span`
  text-transform: capitalize;
`;
