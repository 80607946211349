import { Category } from '@toggle/design-system';

import { appPaths, authPaths } from '~/routes/app-paths';
import { widgetPaths } from '~/views/widgets/widget-utils';

export const sidebarKeys = {
  dashboard: 'dashboard',
  alfred: 'alfred',
  chart: 'chart',
  events: 'events',
  news: 'news',
  help: 'help',
  settings: 'settings',
  signOut: 'signOut',
  screener: 'screener',
  scenario: 'scenario',
  search: 'searchAsset',
  feedback: 'feedback',
  watchlist: 'watchlist',
  insights: 'insights',
  reasoner: 'reasoner',
};

export const mapSidebarKeyToPathname = {
  [sidebarKeys.settings]: appPaths.settings.account,
  [sidebarKeys.alfred]: appPaths.alfred,
  [sidebarKeys.reasoner]: appPaths.reasoner,
  [sidebarKeys.screener]: appPaths.screener,
  [sidebarKeys.insights]: appPaths.insights,
  [sidebarKeys.events]: appPaths.events,
  [sidebarKeys.chart]: appPaths.chart,
  [sidebarKeys.news]: appPaths.news,
  [sidebarKeys.signOut]: authPaths.login,
  [sidebarKeys.watchlist]: appPaths.watchlist,
  [sidebarKeys.scenario]: appPaths.scenario,
  [sidebarKeys.search]: `${appPaths.base}?widget=${widgetPaths.searchAsset}`,
  [sidebarKeys.dashboard]: appPaths.base,
};

export const mapSidebarKeyToRegex = {
  [sidebarKeys.alfred]: new RegExp(appPaths.alfred),
  [sidebarKeys.reasoner]: new RegExp(appPaths.reasoner),
  [sidebarKeys.screener]: new RegExp(appPaths.screener),
  [sidebarKeys.chart]: new RegExp(appPaths.chart),
  [sidebarKeys.events]: new RegExp(appPaths.events),
  [sidebarKeys.news]: new RegExp(appPaths.news),
  [sidebarKeys.scenario]: new RegExp(appPaths.scenario),
  [sidebarKeys.insights]: new RegExp(appPaths.insights),
  [sidebarKeys.dashboard]: new RegExp(`${appPaths.base}\/?$`),
  [sidebarKeys.signOut]: new RegExp(authPaths.login),
  [sidebarKeys.watchlist]: new RegExp(appPaths.watchlist),
  [sidebarKeys.settings]: new RegExp(appPaths.settings.base),
};

const pathMatcher = (pathname: string, regexp?: RegExp) =>
  regexp?.test(pathname);

// gets the active key from search or from location.pathname,
// /app/asset-overview?asset=aapl_nasd&tab=overview&history=searchAsset --> sidebarKeys.search
// /app/screener/:id --> sidebarKeys.screener
export const getActiveNavigationKey = (categories: Category[]) => {
  const items = categories.flatMap(c => c.items);
  const search = decodeURIComponent(location.search);

  if (search) {
    const mapping = {
      [sidebarKeys.alfred]: mapSidebarKeyToRegex[sidebarKeys.alfred],
      [sidebarKeys.search]: new RegExp(widgetPaths.searchAsset),
      [sidebarKeys.screener]: mapSidebarKeyToRegex[sidebarKeys.screener],
      [sidebarKeys.watchlist]: mapSidebarKeyToRegex[sidebarKeys.watchlist],
      [sidebarKeys.events]: mapSidebarKeyToRegex[sidebarKeys.events],
    };

    const category = items.find(item => pathMatcher(search, mapping[item.key]));

    if (category) {
      return category.key;
    }
  }

  const foundItem = items.find(item =>
    pathMatcher(location.pathname, mapSidebarKeyToRegex[item.key])
  )?.key;

  return foundItem ?? sidebarKeys.dashboard;
};

// gets active key from location.pathname using CategorySubitem.key
// which can be either a pathname or some string(key)
export const getActiveNavigationSubKey = (categories: Category[]) => {
  const items = categories.flatMap(c => c.items);
  const subItems = items.flatMap(item => item.subitemGroups?.flat() ?? []);

  return subItems.find(item => {
    const isPath = item.key.startsWith('/');
    return isPath && pathMatcher(location.pathname, new RegExp(item.key));
  })?.key;
};

type SidebarKeys = keyof typeof sidebarKeys;

export const isPathMatches = (
  keys: SidebarKeys[],
  pathname = location.pathname
) => keys.some(key => pathMatcher(pathname, mapSidebarKeyToRegex[key]));
