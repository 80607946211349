import { highlight } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React, { ReactNode } from 'react';

import * as S from './AssetCard.styles';

export interface AssetCardProps {
  logoNode: ReactNode;
  entity: Entity;
  highlighted?: string;
  snakeName?: string;
}

export const AssetCard = ({
  logoNode,
  entity,
  highlighted,
  snakeName,
}: AssetCardProps) => {
  const { ticker, name } = entity;

  const title = `${ticker} ${snakeName ?? ''}`;
  return (
    <S.AssetCardRoot data-testid={`asset-card-${title}`}>
      {logoNode}
      <S.Details>
        <S.TitleWrapper>
          <S.Title>
            {highlighted ? highlight(ticker, highlighted) : ticker}
          </S.Title>
          {snakeName && <S.Snake>&nbsp;{snakeName}</S.Snake>}
        </S.TitleWrapper>

        <S.Subtitle>
          {highlighted ? highlight(name, highlighted) : name}
        </S.Subtitle>
      </S.Details>
    </S.AssetCardRoot>
  );
};
