import { z } from 'zod';

export const countriesResponseSchema = z.object({
  countries: z.array(
    z.object({
      code: z.string(),
      name: z.string(),
    })
  ),
});

export type CountriesResponse = z.infer<typeof countriesResponseSchema>;
