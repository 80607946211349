import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchNewsArticles } from '~/services/news/news-service';

import {
  QUERY_KEY_MOST_READ_NEWS,
  REFETCH_INTERVAL_NEWS_PAGE,
} from '../../utils/utils';

export enum NewsHighlights {
  Breaking = 'breaking',
  MostRead = 'most_read',
}
export interface UseFetchInfiniteNewsListProps {
  dateRangeEnd?: string;
  highlights?: string[];
  newsFilters?: Record<string, string[]>;
}

export const useFetchInfiniteNewsList = ({
  dateRangeEnd,
  highlights,
  newsFilters,
}: UseFetchInfiniteNewsListProps) => {
  const {
    data,
    isRefetching,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY_MOST_READ_NEWS, dateRangeEnd, highlights, newsFilters],
    queryFn: async ({ pageParam }) => {
      const response = await fetchNewsArticles({
        page: { number: pageParam },
        ...(dateRangeEnd && { date_range: { to: dateRangeEnd } }),
        highlights,
        newsFilters,
      });

      return response.news;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }

      return lastPageParam + 1;
    },
    refetchInterval: REFETCH_INTERVAL_NEWS_PAGE,
    refetchIntervalInBackground: true,
  });

  return {
    newsData: data,
    isRefetching: isRefetching || isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
  };
};
