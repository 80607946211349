import styled from 'styled-components';

import { ColorScheme } from '~/design-tokens/themes/themes';

import { Variant } from './InvertedThresholdArea';

const colors: Record<Variant, string> = {
  bullish: 'var(--viz-bullish)',
  bearish: 'var(--viz-bearish)',
};

export const softColors: Record<Variant, string> = {
  bullish: 'var(--viz-bullish-soft)',
  bearish: 'var(--viz-bearish-soft)',
};

export const LineColorBlendMask = styled.rect<{
  $variant: Variant;
  $colorScheme?: ColorScheme;
}>`
  fill: ${({ $variant }) => colors[$variant]};
  mix-blend-mode: ${({ $colorScheme }) =>
    $colorScheme === ColorScheme.Light ? 'lighten' : 'darken'};
`;

export const AreaRectBackground = styled.rect<{
  $variant: Variant;
}>`
  fill: ${({ $variant }) => softColors[$variant]};
  mix-blend-mode: color;
`;

export const EdgeLine = styled.line<{ $variant: Variant }>`
  stroke: ${({ $variant }) => colors[$variant]};
  stroke-width: 1;
  stroke-dasharray: 3 2;
`;

export const LineAxis = styled.line<{ $variant: Variant }>`
  stroke: ${({ $variant }) => colors[$variant]};
  stroke-width: 1;
`;
