import { Placement } from '@floating-ui/react';
import { keyboard } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItem } from '~/components/search-modal/components/list-item/ListItem';
import {
  addTitleAndSearchType,
  EntityOrThemeWithListTypes,
  isThemeItem,
  ListItemData,
} from '~/components/search-modal/utils';
import { useChat } from '~/stores/use-chat/useChat';

import { ActionHandlers } from '../types';
import * as S from './ChatInputDropdown.styles';

type ChatInputDropdownProps = {
  children: React.ReactElement;
  config: {
    placement?: Placement;
    isLoading: boolean;
  };
  handlers: ActionHandlers & {
    onSubmitCallbackHandler: () => void;
  };
  inputValue: string;
  tagList: Entity[];
  themesList: {
    name: string;
    id: string;
    isTheme: boolean;
  }[];
  clearDropdown: () => void;
  isAssetMessage: React.RefObject<boolean>;
  setInputValue: (value: React.SetStateAction<string>) => void;
  showItems: boolean;
  offsetY?: number;
};

export const ChatInputDropdown = ({
  children,
  config,
  handlers,
  inputValue,
  tagList,
  themesList,
  clearDropdown,
  isAssetMessage,
  setInputValue,
  showItems,
  offsetY = 0,
}: ChatInputDropdownProps) => {
  const { t } = useTranslation('widget');
  const { isSearchWithinContext, showContextHeader } = useChat(state => ({
    isSearchWithinContext: state.isSearchWithinContext,
    showContextHeader: state.showContextHeader,
  }));

  const showEntityResults = !!tagList.length;
  const showThemeResults = !!themesList.length;
  const entitySearchResultsIsVisible = showEntityResults && !!inputValue;
  const themeSearchResultsIsVisible = showThemeResults && !!inputValue;
  const entitiesTitle = entitySearchResultsIsVisible
    ? t('widget:search.assets')
    : '';
  const themesTitle = themeSearchResultsIsVisible
    ? t('widget:search.investmentThemes')
    : '';

  const searchListData: ListItemData[] = useMemo(() => {
    return [
      {
        title: entitiesTitle,
        data: tagList,
        searchListType: 'result',
        isVisible: entitySearchResultsIsVisible,
      },
      {
        title: themesTitle,
        data: themesList,
        searchListType: 'result',
        isVisible: themeSearchResultsIsVisible,
      },
    ];
  }, [showEntityResults, inputValue, tagList, showThemeResults, themesList]);

  const filteredData = useMemo(
    () =>
      searchListData
        .filter(e => e.isVisible)
        .flatMap(addTitleAndSearchType) as EntityOrThemeWithListTypes[],
    [searchListData]
  );

  const renderDropdownItems = (
    item: EntityOrThemeWithListTypes,
    _: boolean,
    isFocused: boolean
  ) => (
    <ListItem
      item={item}
      isFocused={isFocused}
      setFocusedText={() => {}}
      searchQuery={inputValue}
      variant="secondary"
    />
  );

  const onSelectItem = (item: EntityOrThemeWithListTypes) => {
    handlers.onSelect(item, clearDropdown);
  };

  const handleDropdownKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    item: EntityOrThemeWithListTypes | null,
    _: number
  ) => {
    if (isAssetMessage.current && tagList.length > 0) {
      return;
    }

    if (item && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
      const isTheme = isThemeItem(item);
      setInputValue(isTheme ? item.name : item.tag);
    }

    if (
      e.key === keyboard.enter.key &&
      !e.shiftKey &&
      !e.nativeEvent.isComposing // fix: [861n3tz5g] Japanese IME: not finished question is sent via 'enter'
    ) {
      // Let user skip line when message is pending
      if (!config.isLoading) {
        e.preventDefault();
      }

      handlers.onKeyDown?.(e, inputValue, handlers.onSubmitCallbackHandler);
    }
  };

  const disableDropdown = showContextHeader && isSearchWithinContext;

  return (
    <S.StyledDropdown
      enableFloating
      dropdownItems={filteredData}
      shouldCloseOnOutsideClick
      renderDropdownItem={renderDropdownItems}
      selectItem={onSelectItem}
      shouldShowItems={!disableDropdown && showItems}
      autocompleteInputValue={inputValue}
      placement={config.placement ?? 'bottom-start'}
      offsetValue={{ y: offsetY }}
      shouldSetActiveOnSelect={false}
      onChildKeyDown={handleDropdownKeyDown}
    >
      {children}
    </S.StyledDropdown>
  );
};
