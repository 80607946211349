import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TanStackTableRoot } from '@toggle/design-system';
import { isElementScrollbarVisible } from '@toggle/helpers';
import { EarningsPeriod } from '@toggle/toggle';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEarningsStore } from '../../useEarningsStore/useEarningsStore';
import {
  EARNINGS_COLUMN_ID,
  getCellHighlighted,
  QNA_COLUMN_ID,
  RELEASED_ON_COLUMN_ID,
  SELECTED_COLUMN_ID,
} from '../../utils/utils';
import { EarningsTableHeaderCell } from '../EarningsTableHeaderCell';
import * as S from './EarningsTable.styles';

interface EarningsTableProps {
  columns: ColumnDef<EarningsPeriod>[];
}

export const EarningsTable = ({ columns }: EarningsTableProps) => {
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    activeSortingState,
    rowSelection,
    isViewSelectedOnly,
    earningsData,
    resetAllFilterOptions,
  } = useEarningsStore();
  const { t } = useTranslation();

  const table = useReactTable({
    data: earningsData,
    columns,
    enableMultiSort: false,
    enableRowSelection: true,
    state: {
      sorting: activeSortingState,
      rowSelection,
      columnPinning: {
        left: [SELECTED_COLUMN_ID, EARNINGS_COLUMN_ID, QNA_COLUMN_ID],
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: data => data.reporting_date,
  });

  const tableRows = table.getRowModel().rows;
  const tableRowsToDisplay = isViewSelectedOnly
    ? tableRows.filter(row => row.getIsSelected())
    : tableRows;

  const isCellNumber = (columnId: string) => {
    return (
      columnId !== EARNINGS_COLUMN_ID && columnId !== RELEASED_ON_COLUMN_ID
    );
  };

  const getIsPinnedVertically = (column: Column<EarningsPeriod>) =>
    hasHorizontalScroll && !!column.getIsPinned();

  useEffect(
    () => setHasHorizontalScroll(isElementScrollbarVisible(containerRef)),
    [containerRef]
  );

  if (!tableRowsToDisplay.length) {
    return (
      <S.ErrorMessageAndResetButtonWrapper>
        <S.StyledErrorMessageWrapper
          variant="empty"
          displayIcon
          title={t('widget:earnings.empty.title')}
          subTitle={t('widget:earnings.empty.emptyFilteredResultsSubTitle')}
        />
        <S.StyledButton
          data-testid="earnings-reset-all-filters-button"
          label={t('widget:earnings.resetAll')}
          onClick={resetAllFilterOptions}
          variant="empty"
          size="small"
          iconName="Reset"
        />
      </S.ErrorMessageAndResetButtonWrapper>
    );
  }

  return (
    <S.TableScrollContainer ref={containerRef}>
      <TanStackTableRoot data-testid="earnings-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup, index, arr) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isLastHeaderGroup = index === arr.length - 1;
                return (
                  <EarningsTableHeaderCell
                    key={header.id}
                    header={header}
                    isLastHeaderGroup={isLastHeaderGroup}
                    isPinnedVertically={getIsPinnedVertically(header.column)}
                    isHighlighted={getCellHighlighted(header.column.id)}
                  />
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableRowsToDisplay.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                  <S.StyledTableCell
                    key={cell.id}
                    isNumber={isCellNumber(cell.column.id)}
                    $isPinned={getIsPinnedVertically(cell.column)}
                    $left={cell.column.getStart('left')}
                    $right={cell.column.getAfter('right')}
                    $isLastPinnedCol={
                      getIsPinnedVertically(cell.column) &&
                      cell.column.getIsLastColumn('left')
                    }
                    $highlight={getCellHighlighted(cell.column.id)}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </S.StyledTableCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </TanStackTableRoot>
    </S.TableScrollContainer>
  );
};
