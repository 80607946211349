import { StyledComponent } from '@toggle/design-system';
import { formatPercentage } from '@toggle/helpers/src/utils/numbers/numbers';
import { Period } from '@toggle/toggle';
import * as d3 from 'd3';
import React, {
  MouseEvent,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useFloatingLegend } from '../bar-chart/hooks/use-floating-legend/useFloatingLegend';
import { HighLowBar } from '../high-low-chart/components/high-low-bar/HighLowBar';
import {
  ClipAreas,
  RIGHT_Y_AXIS_WIDTH,
} from './components/clip-areas/ClipAreas';
import { HorizontalGridLines } from './components/horizontal-grid-lines/HorizontalGridLines';
import { HighLowTripleLineChartTooltip } from './components/tooltip/HighLowTripleLineChartTooltip';
import { TripleLines } from './components/triple-lines/TripleLines';
import { VerticalLines } from './components/vertical-grid-lines/VerticalGridLines';
import { LineProp } from './components/vertical-grid-lines/VerticalGridLines.types';
import * as S from './HighLowTripleLineChart.styles';
import {
  HighLowDataPoint,
  HighLowDataPointWithIndex,
  HorizonToIndex,
} from './HighLowTripleLineChart.types';
import { getPlots } from './utils/gradient-utils/gradient-utils';
import { CumulativeReturn } from './components/cumulative-return/CumulativeReturn';

export interface HighLowTripleLineChartProps {
  ts: HighLowDataPointWithIndex[];
  bestHorizon?: Period;
  horizonToIndex?: HorizonToIndex;
  highlightedHorizons?: Array<Period | string>;
  isFixedIncome?: boolean;
  isBordered?: boolean;
  maxHeight?: number;
  yAxisLabel?: string;
  tooltipLabels: Record<keyof HighLowDataPoint, string>;
  daysLabel: string;
  useDynamicDaysLabel?: boolean;
  snakeTs: { index: number; value: number }[];
}

const RIGHT_Y_AXIS_TEXT_WIDTH = 65;
const SIMPLE_RIGHT_Y_AXIS_TEXT_WIDTH = 45;
const SIMPLE_RIGHT_Y_AXIS_WIDTH = 45;
const ADDITIONAL_HEIGHT_BUFFER = 45;

export const HighLowTripleLineChart: StyledComponent<
  HighLowTripleLineChartProps,
  typeof S
> = ({
  ts,
  bestHorizon,
  horizonToIndex,
  highlightedHorizons,
  isFixedIncome,
  maxHeight,
  yAxisLabel,
  tooltipLabels,
  daysLabel,
  isBordered = false,
  snakeTs,
}: HighLowTripleLineChartProps) => {
  const rightAxisWidth = isBordered
    ? SIMPLE_RIGHT_Y_AXIS_TEXT_WIDTH
    : RIGHT_Y_AXIS_TEXT_WIDTH;
  const rightYAxisWidth = isBordered
    ? SIMPLE_RIGHT_Y_AXIS_WIDTH
    : RIGHT_Y_AXIS_WIDTH;

  const [{ width, height }, setChartSize] = useState({
    width: 0,
    height: 0,
  });
  const [mouseX, setMouseX] = useState<number>();
  const svgRef = useRef<SVGSVGElement>(null);
  const { t } = useTranslation('chart');

  const uniqueId = useId();
  const { isOpen, refs, floatingStyles, getReferenceProps, getFloatingProps } =
    useFloatingLegend({ offsetOptions: 5 });

  useEffect(() => {
    const { width: svgWidth, height: svgHeight } = (
      svgRef.current as SVGSVGElement
    ).getBoundingClientRect();

    if (svgWidth !== width || svgHeight !== height) {
      setChartSize({
        width: svgWidth,
        height: maxHeight ? maxHeight + ADDITIONAL_HEIGHT_BUFFER : svgHeight,
      });
    }
  }, []);

  const domain = [0, ts.length - 1];
  const mainHeight = height * 0.8;
  const xScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain(domain)
      .range([0, Math.max(width - rightYAxisWidth, 0)]);
  }, [width, domain]);

  const yScale = useMemo(() => {
    const snakeValues = snakeTs.map(d => d.value);
    const tsData = ts.slice(domain[0], domain[1]).flatMap(i => [i.low, i.high]);
    const allData = snakeValues.concat(tsData);

    const yDomain = d3.extent(allData) as [number, number];
    return d3.scaleLinear().domain(yDomain).range([mainHeight, 0]).clamp(true);
  }, [height, ts, snakeTs, domain]);

  const plots = useMemo(() => getPlots(ts), [ts, yScale]);

  const verticalLines = useMemo(() => {
    const results: LineProp[] = [];

    horizonToIndex?.forEach((tsIndex, horizon) => {
      const isHighlightedHorizon = highlightedHorizons?.includes(horizon);
      const isMuted = !isHighlightedHorizon;
      const isSelected = horizon === bestHorizon;
      const variant = isSelected ? 'selected' : isMuted ? 'muted' : undefined;

      results.push({
        x: tsIndex ?? 0,
        label: isHighlightedHorizon ? horizon : '',
        variant,
      });
    });

    // add vertical lines for max of y axis
    isBordered &&
      results.push({
        x: domain[1],
        label: '',
        variant: 'muted',
      });

    return results;
  }, [yScale, bestHorizon]);

  const onMouseMove = (e: MouseEvent<SVGGElement>) => {
    setMouseX(e.nativeEvent.offsetX);
  };

  const numberFormatter = (value: number) =>
    formatPercentage(value, { suffix });

  const y0 = yScale(0);
  const y1 = height * 0.1;
  const suffix = !!yAxisLabel ? '' : isFixedIncome ? 'bps' : '%';
  const hoveredIndex = mouseX && Math.round(xScale.invert(mouseX));
  const hoveredTs = hoveredIndex && ts[hoveredIndex];
  const hoveredSnakeTs = hoveredIndex && snakeTs[hoveredIndex]?.value;
  const showTooltip = isOpen && !!hoveredTs;
  const snakeDataInDomain = snakeTs.slice(0, domain[1] - domain[0] + 1);

  const getDaysLabel = () => {
    if (!hoveredTs || !hoveredIndex) {
      return daysLabel;
    }

    return t('chart:days', {
      count: hoveredTs.relative_idx,
    });
  };

  const daysLabelToDisplay = getDaysLabel();

  return (
    <S.HighLowTripleLineChartRoot
      $maxHeight={maxHeight}
      data-testid="high-low-triple-line-chart"
    >
      {showTooltip && (
        <HighLowTripleLineChartTooltip
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          data-testid="high-low-triple-line-chart-tooltip"
          title={daysLabelToDisplay}
          hoveredTs={hoveredTs}
          hoveredSnakeTs={hoveredSnakeTs}
          tooltipLabels={tooltipLabels}
          numberFormatter={numberFormatter}
        />
      )}
      <S.Chart ref={svgRef}>
        <ClipAreas
          uniqueId={uniqueId}
          width={width}
          height={height}
          yScale={yScale}
        />
        <g transform={`translate(0, ${y1})`}>
          <g transform={`translate(${width - rightAxisWidth}, 0)`}>
            <HorizontalGridLines
              yScale={yScale}
              tickSize={width - rightAxisWidth}
              format={value => formatPercentage(value, { suffix, decimals: 0 })}
              isBordered={isBordered}
              yAxisLabel={yAxisLabel}
              rightAxisWidth={rightAxisWidth}
            />
          </g>
          <VerticalLines
            lines={verticalLines}
            xScale={xScale}
            yScale={yScale}
            orientation={'bottom'}
          />
          <TripleLines
            uniqueId={uniqueId}
            plots={plots}
            xScale={xScale}
            yScale={yScale}
          />
          <CumulativeReturn
            ts={snakeDataInDomain}
            xScale={xScale}
            yScale={yScale}
            id={uniqueId}
          />
          <S.BaseLine x1={0} x2={width - rightAxisWidth} y1={y0} y2={y0} />

          {highlightedHorizons?.map(horizon => {
            const tsIndex = horizonToIndex?.get(horizon);
            const dataPoint = tsIndex && ts[tsIndex];

            if (!dataPoint) {
              return null;
            }

            const yHigh = yScale(dataPoint.high);
            const yLow = yScale(dataPoint.low);
            const yMedian = yScale(dataPoint.median);

            return (
              <g
                key={`${uniqueId}-${tsIndex}`}
                transform={`translate(${xScale(tsIndex)}, 0)`}
              >
                <HighLowBar
                  yMedian={yMedian}
                  yLow={yLow}
                  yHigh={yHigh}
                  y0={y0}
                  dataPoint={dataPoint}
                  showMedianLabel={false}
                  enableMouseInteraction={false}
                  numberFormatter={numberFormatter}
                  hasTextWithBg
                />
              </g>
            );
          })}
        </g>
        {showTooltip && (
          <S.DashedLine x1={mouseX} x2={mouseX} y1={y1} y2={y1 + mainHeight} />
        )}
        <S.HoverAreaReact
          ref={refs.setReference}
          width={width - rightAxisWidth}
          y={y1}
          height={mainHeight}
          data-testid="high-low-triple-line-chart-hover-rect"
          {...getReferenceProps({ onMouseMove })}
        />
      </S.Chart>
    </S.HighLowTripleLineChartRoot>
  );
};

HighLowTripleLineChart.Styled = S;
