import { Alert, AlertType } from '@toggle/design-system';
import {
  format,
  formatDistanceStrict,
  formatDistanceToNowStrict,
} from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDismissContinuous } from '~/hooks/use-dismiss-continuous/useDismissContinuous';
import { useFlags } from '~/stores/use-flags/useFlags';

import * as S from './MaintenanceBanner.styles';

export const DISMISSED_KEY = '__toggle_dismissed_maintenance_banner';
const SHOW_BANNER_BEFORE_IN_DAYS = 7;

export const MaintenanceBanner = () => {
  const { t } = useTranslation('common');
  const { maintenance } = useFlags(state => state.systemFlags);

  const { isVisible, handleDismiss } = useDismissContinuous({
    endDate: maintenance?.from,
    cookieKey: DISMISSED_KEY,
    showBeforeInDays: SHOW_BANNER_BEFORE_IN_DAYS,
  });

  if (!isVisible || !maintenance) {
    return null;
  }

  const fromDate = new Date(maintenance.from);
  const toDate = new Date(maintenance.to);

  const duration = formatDistanceStrict(fromDate, toDate);
  const timeLeft = formatDistanceToNowStrict(fromDate);
  const fromDateFormatted = format(fromDate, 'PPPp');
  const toDateFormatted = format(toDate, 'PPPp');

  return (
    <S.MaintenanceBannerRoot data-testid="maintenance-banner">
      <Alert
        type={AlertType.Warning}
        variant="inline"
        title={t('common:maintenanceBanner.title', {
          timeLeft,
        })}
        message={t('common:maintenanceBanner.message', {
          duration,
          fromDate: fromDateFormatted,
          toDate: toDateFormatted,
        })}
        onClose={handleDismiss}
      />
    </S.MaintenanceBannerRoot>
  );
};
