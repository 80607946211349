import { TooltipTrigger } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';

import * as S from './ChatSubmitButton.styles';

export interface ChatSubmitButtonProps {
  isMessagePending: boolean;
  inputValue: string;
  abortRequest?: () => void;
}

export const ChatSubmitButton = ({
  isMessagePending,
  inputValue,
  abortRequest,
}: ChatSubmitButtonProps) => {
  const { t } = useTranslation('chat');

  return (
    <TooltipContainer
      label={t('chat:stopResponse')}
      inPortal
      trigger={[TooltipTrigger.Hover]}
      disabled={!isMessagePending}
    >
      {isMessagePending ? (
        <S.SubmitButton
          data-testid="abort-button"
          iconName="Stop"
          onClick={abortRequest}
        />
      ) : (
        <S.SubmitButton
          data-testid="submit-button"
          iconName="ArrowUp"
          disabled={!inputValue.trim()}
        />
      )}
    </TooltipContainer>
  );
};
