import { Button, ButtonLink, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border-soft);
  background: var(--background-soft);
  padding: 14px 16px;
`;

export const FlexRow = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    flex-shrink: 0;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 12px;

  & > span {
    flex-shrink: 0;
    ${typographyV2.titleMd}
  }

  & > svg {
    fill: var(--icon-primary);
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${typographyV2.titleMd}
    cursor: default;
  }
`;

export const Divider = styled.span`
  color: var(--border-default);
`;

export const ContextContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  & p {
    ${typographyV2.titleBase}
  }
`;

export const StyledButton = styled(Button)`
  color: var(--icon-primary);
  border-radius: 8px;

  svg {
    fill: var(--icon-primary);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
`;

export const StyledSubheaderLabel = styled.div`
  ${typographyV2.bodySm};
  color: var(--text-soft);
`;

export const SubheaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const StyledButtonLink = styled(ButtonLink)`
  ${ButtonLink.Styled.Label} {
    ${typographyV2.bodySm};
  }
`;
