import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Legend = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  width: fit-content;
  border: 1px solid var(--border-soft);
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LegendLabel = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-default);
`;

export const IconPrimary = styled.div`
  width: 2px;
  height: 16px;
  background-color: var(--border-primary);
`;

export const IconBar = styled.div`
  width: 1px;
  height: 16px;
  border-radius: 2px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    height: 50%;
    background-color: var(--viz-bullish);
  }

  &::after {
    content: '';
    display: block;
    height: 50%;
    background-color: var(--viz-bearish);
  }
`;

export const IconMedian = styled.div`
  height: 0;
  width: 12px;
  border-top: 1px dashed var(--icon-on-critical);
`;

export const IconCumulativeReturn = styled.div`
  height: 0;
  width: 12px;
  border-top: 1px solid var(--text-default);
`;
