import React, { ReactNode, useEffect, useState } from 'react';

import { Dropdown } from '~/components/dropdown/Dropdown';

import * as ItemStyles from './BreadcrumbItem.styles';
import * as S from './Breadcrumbs.styles';

export type Path = {
  pathname: string;
  search: string;
};

export type Breadcrumb = {
  id: string;
  to?: string | Partial<Path>;
  label: string;
  themeId?: string;
  assetTag?: string;
  navigateFn?: () => void;
};

export interface BreadcrumbsProps {
  className?: string;
  data: Breadcrumb[];
  maxVisibleItems?: number;
  renderNode: (item: Breadcrumb, disabled?: boolean) => ReactNode;
  renderDropdownListItem: (
    item: Breadcrumb,
    isActive: boolean,
    isFocused: boolean
  ) => ReactNode;
  onSelectDropdownItem: (item: Breadcrumb) => void;
}

export const Breadcrumbs = ({
  className,
  data,
  maxVisibleItems = 8,
  renderNode,
  renderDropdownListItem,
  onSelectDropdownItem,
}: BreadcrumbsProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(
    data.length >= maxVisibleItems
  );

  useEffect(() => {
    setIsCollapsed(data.length >= maxVisibleItems);
  }, [data, maxVisibleItems]);

  const lastIndex = data.length - 1;

  if (data.length < 2) {
    return null;
  }

  if (isCollapsed) {
    const firstBreadcrumbs = data.slice(0, 2);
    const middleBreadcrumbs = data.slice(2, data.length - 2).reverse();
    const lastBreadcrumbs = data.slice(data.length - 2);
    const lastBreadcrumbsFirstIndex = data.findIndex(
      d => d.id === lastBreadcrumbs[0]?.id
    );

    return (
      <S.BreadcrumbsRoot className={className}>
        {firstBreadcrumbs.map((item, index) => (
          <ItemStyles.Breadcrumb key={item.id} $disabled={lastIndex === index}>
            {renderNode(item)}
            {lastIndex !== index && <S.Separator>/</S.Separator>}
          </ItemStyles.Breadcrumb>
        ))}
        <ItemStyles.Breadcrumb>
          <Dropdown
            dropdownItems={middleBreadcrumbs}
            selectItem={onSelectDropdownItem}
            shouldShowItems={isDropdownOpen}
            renderDropdownItem={(item, isActive, isFocused) =>
              renderDropdownListItem(item, isActive, isFocused)
            }
            onItemsShowChange={setIsDropdownOpen}
            placement="bottom-start"
            inPortal
          >
            <S.Button>
              <div>...</div>
            </S.Button>
          </Dropdown>
          <S.Separator>/</S.Separator>
        </ItemStyles.Breadcrumb>
        {lastBreadcrumbs.map((item, index) => {
          const currentIndex = index + lastBreadcrumbsFirstIndex;
          return (
            <ItemStyles.Breadcrumb
              key={item.id}
              $disabled={lastIndex === currentIndex}
            >
              {renderNode(item)}
              {lastIndex !== currentIndex && <S.Separator>/</S.Separator>}
            </ItemStyles.Breadcrumb>
          );
        })}
      </S.BreadcrumbsRoot>
    );
  }

  return (
    <S.BreadcrumbsRoot className={className}>
      {data.map((item, index) => (
        <ItemStyles.Breadcrumb key={item.id} $disabled={lastIndex === index}>
          {renderNode(item)}
          {lastIndex !== index && <S.Separator>/</S.Separator>}
        </ItemStyles.Breadcrumb>
      ))}
    </S.BreadcrumbsRoot>
  );
};

Breadcrumbs.ItemStyles = ItemStyles;
