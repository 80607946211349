import { DropdownMenu, Icon } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NewsFilter } from '~/services/news/news.types';
import { SideAccordionFilters } from '~/views/news/components/side-accordion-filters/SideAccordionFilters';
import { NewsFiltersStore } from '~/views/news/stores/use-news-filters-store/useNewsFiltersStore';

import * as S from './MoreFilters.styles';

export interface MoreFiltersProps {
  moreFiltersSelectedCount: number;
  filters: NewsFilter[];
  toggleFilterOption: NewsFiltersStore['toggleFilterOption'];
}

export const MoreFilters = ({
  moreFiltersSelectedCount,
  filters,
  toggleFilterOption,
}: MoreFiltersProps) => {
  const { t } = useTranslation('news');

  const [isMoreFilterOpen, setIsMoreFilterOpen] = useState(false);

  return (
    <DropdownMenu
      open={isMoreFilterOpen}
      onOpenChange={() => setIsMoreFilterOpen(prev => !prev)}
      triggerNode={
        <S.MoreFilterPill
          variant="tertiary"
          size="small"
          label={t('news:filters.moreFilters')}
        >
          <S.BottomSection>
            {!!moreFiltersSelectedCount && (
              <S.StyledBadge
                variant="numerical"
                label={moreFiltersSelectedCount}
                size="small"
                data-testid={`count-badge-${moreFiltersSelectedCount}`}
              />
            )}
            <Icon
              size={14}
              iconName="ChevronLightDown"
              fillColor="var(--text-soft)"
            />
          </S.BottomSection>
        </S.MoreFilterPill>
      }
      contentProps={{
        asChild: true,
      }}
    >
      <S.MenuWrapper>
        <SideAccordionFilters
          filters={filters}
          toggleFilterOption={toggleFilterOption}
        />
      </S.MenuWrapper>
    </DropdownMenu>
  );
};
