import { z } from 'zod';

const themeOverviewResponseSchema = z.object({
  name: z.string(),
  description: z.string(),
  logo_url: z.string().optional(),
});
export type ThemeOverviewResponse = z.infer<typeof themeOverviewResponseSchema>;

export const themePerformanceSchema = z.object({
  index: z.string().datetime(),
  value: z.number(),
});

const entitiesSchema = z.record(z.string(), z.array(themePerformanceSchema));

export const calculatorResponseSchema = z.object({
  entities: entitiesSchema.optional(),
  aggregate: z.array(themePerformanceSchema),
});

export type CalculatorResponse = z.infer<typeof calculatorResponseSchema>;

const companySchema = z.object({
  tag: z.string(),
  name: z.string(),
  ticker: z.string(),
  exchange: z.string(),
  evidence: z.string(),
});

export const companyExposureSchema = z.object({
  result: z.array(companySchema),
});

export type CompanyExposureResponse = z.infer<typeof companyExposureSchema>;

const countrySchema = z.object({
  code: z.string(),
  name: z.string(),
  evidence: z.string(),
});

export const countryExposureSchema = z.object({
  result: z.array(countrySchema),
});

export type CountryExposureResponse = z.infer<typeof countryExposureSchema>;

export const themeOverview = {
  getTheme: {
    path: (themeId: string) => `theme/v1/${themeId}`,
    schema: themeOverviewResponseSchema,
  },
  themePerformance: {
    path: 'calculator/v1/performance',
    schema: calculatorResponseSchema,
  },
  getCompanyExposure: {
    path: (themeId: string, limit: number = 10) =>
      `ontology/v1/theme/${themeId}/company-exposure?limit=${limit}`,
    schema: companyExposureSchema,
  },
  getCountryExposure: {
    path: (themeId: string, limit: number = 10) =>
      `ontology/v1/theme/${themeId}/country-exposure?limit=${limit}`,
    schema: countryExposureSchema,
  },
};
