import {
  FilterResultMethod,
  FilterResults,
  FilterResultsCategoryData,
} from '@toggle/toggle';

export const getFieldValue = (
  data?: Record<string, FilterResultMethod>,
  fieldName?: string
) => (fieldName ? data?.[fieldName]?.value : undefined);

export const getFieldUnits = (
  data?: Record<string, FilterResultMethod>,
  fieldName?: string
) => (fieldName ? data?.[fieldName]?.units : undefined);

export const parseRelevantSections = (filterResults: FilterResults) => {
  const knowledgeGraphData = filterResults.data.find(
    item => item.category === 'Knowledge Graph'
  );
  const overviewData = filterResults.data.find(
    item => item.category === 'Overview'
  );
  const performanceData = filterResults.data.find(
    item => item.category === 'Performance'
  );

  return { knowledgeGraphData, overviewData, performanceData };
};

export const parseRelevantFieldNames = ({
  knowledgeGraphData,
  overviewData,
  performanceData,
  themeId,
}: {
  knowledgeGraphData?: FilterResultsCategoryData;
  overviewData?: FilterResultsCategoryData;
  performanceData?: FilterResultsCategoryData;
  themeId: string;
}) => {
  const companyRankFieldName = knowledgeGraphData?.meta.find(
    item =>
      item.filter === `theme_exposure.${themeId}` &&
      item.name.includes('Company')
  )?.field;
  const themeRankFieldName = knowledgeGraphData?.meta.find(
    item =>
      item.filter === `theme_exposure.${themeId}` && item.name.includes('Theme')
  )?.field;
  const currentPriceFieldName = overviewData?.meta.find(
    item => item.filter === 'price'
  )?.field;
  const marketCapFieldName = overviewData?.meta.find(
    item => item.filter === 'market_cap'
  )?.field;
  const return1DFieldName = performanceData?.meta.find(
    item => item.field === 'mom(horiz=1d).value' && item.name.includes('1D')
  )?.field;

  return {
    companyRankFieldName,
    themeRankFieldName,
    currentPriceFieldName,
    marketCapFieldName,
    return1DFieldName,
  };
};
