import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { queryKeys } from '~/routes/app-paths';

import { NewsHeader } from './components/news-header/NewsHeader.styles';
import { NewsView } from './components/news-view/NewsView';
import { SearchBar } from './components/search-bar/SearchBar';
import { SearchView } from './components/search-view/SearchView';
import * as S from './News.styles';
import { useNewsSearchStore } from './stores/use-news-search-store/useNewsSearchStore';

export interface TopNewsTimeState {
  date: string;
}
export interface TopNewsReducerAction {
  date: string;
  userScrolling?: boolean;
}

export const News = () => {
  const { t } = useTranslation([
    'analyze',
    'chart',
    'countries',
    'datePicker',
    'news',
    'scenario',
    'screener',
    'widget',
  ]);

  const {
    isInputExpanded,
    setInputValue,
    setInputExpanded,
    clearSearch,
    clearActiveFilters,
  } = useNewsSearchStore(state => ({
    inputValue: state.inputValue,
    setInputValue: state.setInputValue,
    isInputExpanded: state.isInputExpanded,
    setInputExpanded: state.setInputExpanded,
    clearSearch: state.clearSearch,
    clearActiveFilters: state.clearActiveFilters,
  }));

  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get(queryKeys.searchNews) ?? '';

  useEffect(() => {
    if (searchQuery) {
      setInputValue(searchQuery);
    }
  }, []);

  useEffect(() => {
    return () => {
      setInputExpanded(false);
      clearSearch();
      clearActiveFilters();
    };
  }, [clearActiveFilters, clearSearch, setInputExpanded]);

  return (
    <S.NewsRoot data-testid="news">
      <NewsHeader>
        <S.NewsTitle>{t('news:title')}</S.NewsTitle>
        <SearchBar />
      </NewsHeader>

      {isInputExpanded ? (
        <SearchView searchQuery={searchQuery} />
      ) : (
        <NewsView />
      )}
    </S.NewsRoot>
  );
};
