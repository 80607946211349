import { Alert, AlertType, Button } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Feedback } from '~/stores/use-chat/assistant-response.type';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';

import * as S from './ChatFeedbackButtons.styles';

const TOAST_ID = 'chat-feed-back';
export const CHAT_FEEDBACK_ID = 'chat-feedback';

export type ChatFeedbackButtonsProps<F extends string> = {
  addFeedback: (feedback: F) => void;
  onBadFeedback?: () => void;
  feedback?: F;
};

export const ChatFeedbackButtons = <T extends string>({
  addFeedback,
  onBadFeedback,
  feedback,
}: ChatFeedbackButtonsProps<T>) => {
  const { t } = useTranslation('chat');
  const { showToast } = useToasts();

  const onFeedbackButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const feedback = e.currentTarget.name as T;
    addFeedback(feedback);

    if (feedback === Feedback.Bad && onBadFeedback) {
      onBadFeedback();
    }

    if (feedback === Feedback.Good) {
      showToast({
        id: TOAST_ID,
        hideToast: true,
        position: ToastPosition.TopRight,
        content: (
          <Alert
            type={AlertType.Success}
            message={t('chat:feedback')}
            withIcon
          />
        ),
      });
    }
  };

  return (
    <S.ButtonsWrapper id={CHAT_FEEDBACK_ID} data-testid={CHAT_FEEDBACK_ID}>
      {(feedback === Feedback.Good || !feedback) && (
        <Button
          data-testid="button-positive-feedback"
          variant="empty"
          size="xsmall"
          iconName="ThumbsUp"
          disabled={feedback === Feedback.Good}
          onClick={onFeedbackButtonClick}
          name={Feedback.Good}
        />
      )}
      {!feedback && <S.Divider />}
      {(feedback === Feedback.Bad || !feedback) && (
        <Button
          data-testid="button-negative-feedback"
          variant="empty"
          size="xsmall"
          label={t('message.irrelevant')}
          iconName="ThumbsDown"
          onClick={onFeedbackButtonClick}
          name={Feedback.Bad}
          disabled={feedback === Feedback.Bad}
        />
      )}
    </S.ButtonsWrapper>
  );
};
