import * as Radix from '@radix-ui/react-dropdown-menu';
import {
  Alert,
  Badge,
  borderRadius,
  Button,
  customScrollMinimal,
  Icon,
  RadioGroup,
  typographyV2,
} from '@toggle/design-system';
import { FC } from 'react';
import styled, { css } from 'styled-components';

export const ScenarioRoot = styled.div`
  margin-top: 20px;
`;

export const ScenarioTitle = styled.h2`
  margin-bottom: 8px;
  ${typographyV2.titleMd};
`;

export const FilterGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  ${borderRadius.r2};
  background-color: var(--surface-default);
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const FilterLabel = styled.label`
  align-self: center;
  ${typographyV2.bodyBase}
`;

export const TriggerLabel = styled.span`
  color: var(--text-soft);
  ${typographyV2.bodySm};
`;

export const Trigger = styled<
  FC<Radix.DropdownMenuTriggerProps & { $active?: boolean }>
>(Radix.Trigger)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  background-color: var(--surface-soft);
  height: 32px;
  padding: 6px 8px 6px 12px;
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.$active ? 'var(--surface-active)' : 'var(--border-default)'};
  cursor: pointer;
  outline: none;
  cursor: pointer;

  ${TriggerLabel} {
    color: ${props =>
      props.$active ? 'var(--text-default)' : 'var(--text-soft)'};
  }

  &:hover {
    background: var(--surface-hover);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }

  &[data-state='open'] {
    background-color: var(--surface-active);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }
`;

export const ConditionTrigger = styled(Trigger)`
  ${TriggerLabel} {
    color: ${props =>
      props.$active ? 'var(--text-primary)' : 'var(--text-soft)'};
  }
`;

export const TriggerArrow = styled(Icon).attrs({
  size: 14,
})`
  fill: var(--icon-soft);
`;

export const DropdownMenuContent = styled(Radix.Content)`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 250px;
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  border-radius: 4px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  z-index: var(--z-index-modal);
  ${customScrollMinimal};
  overflow-y: auto;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin: 0 8px;
`;

export const StyledBadge = styled(Badge)`
  p {
    background-color: var(--surface-primary);
    color: var(--text-on-primary);
  }
`;

export const WatchlistWrapper = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Separator = styled(Radix.Separator)`
  margin: 8px 0px;
  height: 1px;
  background-color: var(--border-soft);
`;

export const ConditionTitle = styled.span`
  flex: 1;
  padding: 8px;
  align-items: flex-start;
  color: #9ba1a6;
  ${typographyV2.bodyXs};
  text-transform: uppercase;
`;

export const ItemText = styled.div`
  margin: 0px 4px;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  ${typographyV2.bodySm};
  cursor: pointer;
`;

export const Item = styled<
  FC<Radix.DropdownMenuItemProps & { $isChecked?: boolean }>
>(Radix.Item)`
  all: unset;
  display: flex;
  margin-bottom: 4px;
  margin-left: 4px;

  &[data-highlighted] {
    ${ItemText} {
      background-color: var(--surface-hover);
    }
  }

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      ${MenuItemLeftIndicator} {
        color: var(--surface-primary);
        border-color: var(--surface-primary);
      }
      ${ItemText} {
        background-color: var(--surface-active);
      }
    `}
`;

export const MenuItemLeftIndicator = styled.span`
  margin-right: 4px;
  width: 4px;
  display: inline-block;
  height: auto;
  color: transparent;
  border-left: solid 3px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
`;

export const StyledButton = styled(Button)`
  margin-top: 8px;
  width: 111px;
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 12px;
`;

export const WatchlistNameLabel = styled.div`
  color: var(--text-primary);
  ${typographyV2.bodySm};
`;

export const WatchlistGroup = styled.div`
  padding: 2px 14px;
  color: var(--text-soft);
  ${typographyV2.titleSm};
`;
