import { Button, customScrollMinimal } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const EmptyTableRoot = styled.div<{ $isEmpty: boolean }>`
  ${customScrollMinimal};
  overflow: auto;
  position: relative;

  ${({ $isEmpty }) =>
    !$isEmpty &&
    css`
      height: 100%;
    `}
`;

export const EmptyTable = styled.table`
  border-spacing: 0;
  width: 100%;

  --z-index-header-cell: 1;
  --z-index-table-cell: 1;
`;

export const EmptyCell = styled.td`
  /* vertical-align: middle; */

  vertical-align: top;
`;

export const StickyContent = styled.div<{ $isSticky: boolean }>`
  display: flex;
  padding: 32px 0;

  ${({ $isSticky }) =>
    $isSticky
      ? css`
          top: 50%;
          transform: translateY(-50%);
          position: sticky;
        `
      : css`
          position: absolute;
          top: 50%;
          left: calc(50% + 135px);
          transform: translate(-50%, -50%);
        `}
`;

export const AddAssetButton = styled(Button)`
  margin-top: 8px;
`;
