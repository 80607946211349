import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Shared.styles';

export const ScenarioNoResults = () => {
  const { t } = useTranslation('scenario');

  return (
    <S.Container data-testid="scenario-no-results">
      <S.Img src="/app/static/images/icons/no-results.svg" alt={''} />
      <S.Description>
        <S.Title>{t('scenario:emptyState:noResultsTitle')}</S.Title>
        <S.Message>{t('scenario:emptyState:noResultsMessage')}</S.Message>
      </S.Description>
    </S.Container>
  );
};
