import React, { FC } from 'react';

import { ColorScheme } from '~/design-tokens/themes/themes';

import * as S from './InvertedThresholdArea.styles';

export type Variant = 'bullish' | 'bearish';

interface InvertedThresholdAreaProps {
  variant?: Variant;
  offset?: number;
  width?: string | number;
  edgeOffset?: number;
  colorScheme?: ColorScheme;
}

export const InvertedThresholdArea: FC<InvertedThresholdAreaProps> = ({
  variant = 'bullish',
  width,
  offset,
  edgeOffset = offset,
  colorScheme,
}) => {
  return (
    <g>
      <S.AreaRectBackground
        data-testid="color-area-rectangle"
        width={width}
        x={offset}
        height="100%"
        $variant={variant}
      />
      {edgeOffset &&
        (variant === 'bearish' ? (
          <S.EdgeLine
            data-testid="bearish-edge-line"
            y1="0"
            x1={edgeOffset}
            y2="100%"
            x2={edgeOffset}
            $variant={variant}
          />
        ) : (
          <S.LineAxis
            data-testid="bullish-edge-line"
            y1="0"
            x1={edgeOffset}
            y2="100%"
            x2={edgeOffset}
            $variant={variant}
          />
        ))}
      <S.LineColorBlendMask
        width={width}
        x={offset}
        height="100%"
        $variant={variant}
        $colorScheme={colorScheme}
      />
    </g>
  );
};
