import { useQuery } from '@tanstack/react-query';
import {
  CalendarRequest,
  CalendarResponse,
  financialEvents,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const QUERY_KEY_FINANCIAL_EVENTS_CALENDAR =
  'QUERY_KEY_FINANCIAL_EVENTS_CALENDAR';

export const useFinancialEventsCalendar = (body: CalendarRequest) =>
  useQuery({
    queryKey: [QUERY_KEY_FINANCIAL_EVENTS_CALENDAR, body],
    queryFn: ({ signal }) => {
      const { path, schema } = financialEvents.postCalendars;
      return wretchRequest<CalendarResponse>(path, {
        schema,
        body,
        method: 'post',
        signal,
      });
    },
  });
