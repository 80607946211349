import { ReportDetail } from '@toggle/toggle';

import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';

import { ScenarioTableData } from '../scenario-table-utils/scenario-table-utils';

interface ParseScenarioDataProps {
  data: ScenariosData[];
  horizons: string[];
  activeHorizon?: string;
}

export const getQualityReports = (
  report: ScenariosData['report'],
  activeHorizon?: string
) => {
  const commonReports = report.common;
  const activeHorizonReports = activeHorizon
    ? report.horizons[activeHorizon]
    : [];

  return [commonReports, activeHorizonReports].flat().filter(Boolean);
};

export const countReportTriggers = (reportDetails: ReportDetail[]) =>
  reportDetails.reduce(
    (acc, reportDetail) => acc + (reportDetail.triggered ? 1 : 0),
    0
  );

export const parseScenarioData = ({
  data,
  horizons,
  activeHorizon,
}: ParseScenarioDataProps): ScenarioTableData[] =>
  data
    .filter(item =>
      item.predictions.some(prediction => horizons.includes(prediction.horizon))
    )
    .map(item => {
      const activePrediction = item.predictions.find(
        prediction => prediction.horizon === activeHorizon
      );

      const summary = horizons.reduce((acc, horizon) => {
        const prediction = item.predictions.find(
          prediction => prediction.horizon === horizon
        );

        if (prediction) {
          acc[horizon] = prediction.median;
        }

        return acc;
      }, {} as ScenarioTableData['summary']);

      const qualityReports = getQualityReports(item.report, activeHorizon);
      const quality = countReportTriggers(qualityReports);

      return {
        entity: item.entity,
        prediction: activePrediction,
        quality,
        bestHorizon: item.report.best_horizon,
        episodesCount: item.episodes.length,
        summary,
      };
    });
