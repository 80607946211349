import styled, { css } from 'styled-components';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';

export const StyledTooltipContainer = styled(TooltipContainer)`
  border: none;
  background: none;
  box-shadow: none;
  position: relative;
  max-width: 100%;
  padding: 0px 12px;
`;

export const IconContainer = styled.div<{ $direction: string }>`
  position: absolute;
  cursor: pointer;
  svg {
    ${({ $direction }) => {
      if ($direction === 'bullish') {
        return css`
          path:nth-child(2) {
            fill: var(--viz-bullish);
          }
          path:nth-child(3) {
            fill: var(--viz-bullish-soft);
          }
          path:last-child {
            fill: var(--viz-bullish);
          }
        `;
      }

      if ($direction === 'multi-bullish') {
        return css`
          path:first-child {
            fill: var(--viz-bullish);
          }
          path:nth-child(2) {
            fill: var(--viz-bullish);
          }
          path:nth-child(3) {
            fill: var(--viz-bullish);
          }
          path:nth-child(4) {
            fill: var(--viz-bullish-soft);
          }
          path:last-child {
            fill: var(--viz-bullish);
          }
        `;
      }

      if ($direction === 'bearish') {
        return css`
          path:nth-child(2) {
            fill: var(--viz-bearish);
          }
          path:nth-child(3) {
            fill: var(--viz-bearish-soft);
          }
          path:last-child {
            fill: var(--viz-bearish);
          }
        `;
      }

      if ($direction === 'multi-bearish') {
        return css`
          path:first-child {
            fill: var(--viz-bearish);
          }
          path:nth-child(2) {
            fill: var(--viz-bearish);
          }
          path:nth-child(3) {
            fill: var(--viz-bearish);
          }
          path:nth-child(4) {
            fill: var(--viz-bearish-soft);
          }
          path:last-child {
            fill: var(--viz-bearish);
          }
        `;
      }

      if ($direction === 'multi-mixed') {
        return css`
          path:nth-child(1) {
            fill: var(--viz-bullish-soft);
          }
          path:nth-child(2) {
            fill: var(--viz-bullish);
          }
          path:nth-child(3) {
            fill: var(--viz-bearish-soft);
          }
          path:nth-child(4) {
            fill: var(--viz-bearish);
          }
          path:nth-last-child(2) {
            fill: var(--border-primary);
          }
          path:last-child {
            fill: var(--border-primary);
          }
        `;
      }

      return '';
    }}
  }
`;
