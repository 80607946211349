import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';

export interface AssetsFilterOption extends FilterOption {
  customOptionLabel?: string;
  customOptionCallback?: () => void;
}

interface MapToFilterOptionsArgs<T> {
  items: T[];
  valueKey: string;
  customHeaderTitle?: string;
  hasBottomBorder?: boolean;
  withDefaultOption?: boolean;
  customOptionLabel?: string;
  customOptionCallback?: () => void;
}

export const mapToFilterOptions = <T extends { id: string; name: string }>({
  items,
  valueKey,
  customHeaderTitle,
  hasBottomBorder = false,
  withDefaultOption = false,
  ...rest
}: MapToFilterOptionsArgs<T>): AssetsFilterOption[] => {
  if (withDefaultOption && items.length === 0) {
    return [
      {
        name: '',
        key: '',
        value: {},
        customHeaderTitle,
        hasBottomBorder,
        ...rest,
      },
    ];
  }

  const results = items.map((item, idx) => ({
    name: item.name,
    key: item.id,
    value: { [valueKey]: item.id },
    customHeaderTitle: idx === 0 ? customHeaderTitle : undefined,
    hasBottomBorder: hasBottomBorder && idx === items.length - 1,
  }));

  return results;
};

export const mapActiveFilterOptions = <T extends { id: string; name: string }>(
  items: T[],
  valueKey: string,
  filterOptions: FilterOption[]
): FilterOption[] => {
  const itemMap = new Map<unknown, T>(items.map(item => [item.id, item]));

  return filterOptions.reduce<FilterOption[]>((options, option) => {
    const matchedItem = itemMap.get(option.value[valueKey]);

    if (matchedItem) {
      options.push({ ...option, name: matchedItem.name });
    }

    return options;
  }, []);
};
