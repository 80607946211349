import { ScreenerFilterGroupKeys } from '@toggle/toggle';
import { useTranslation } from 'react-i18next';
import { matchPath, useNavigate } from 'react-router';

import { ActionModal } from '~/components/actions-modal/ActionsModal';
import { ToggleFilterProps } from '~/components/update-filter-options/updateFilterOptions';
import { useActionsModal } from '~/hooks/use-actions-modal/useActionsModal';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { appPaths } from '~/routes/app-paths';
import { create } from '~/stores/create-store/createStore';

import { useScreenerStore } from '../use-screener-store/useScreenerStore';

type ScreenerActions = {
  activeModal?: ActionModal;
  nextActions: Function[];
  onCloseActions: Function[];
  onDontSaveActions: Function[];
  setActiveModal: (props: {
    activeModal?: ActionModal;
    nextActions?: Function[];
    onCloseActions?: Function[];
    onDontSaveActions?: Function[];
  }) => void;
};

export const useScreenerActionsStore = create<ScreenerActions>(set => ({
  activeModal: undefined,
  queuedActions: [],
  nextActions: [],
  onCloseActions: [],
  onDontSaveActions: [],
  setActiveModal: ({
    activeModal,
    nextActions = [],
    onCloseActions = [],
    onDontSaveActions = [],
  }) => set({ activeModal, nextActions, onCloseActions, onDontSaveActions }),
}));

export const useScreenerActions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['screener']);
  const {
    setActiveModal,
    activeModal,
    nextActions,
    onCloseActions,
    onDontSaveActions,
  } = useScreenerActionsStore();
  const {
    deleteSavedFilter,
    saveNewFilter,
    updateSavedFilter,
    unsavedChanges,
    unsavedDraftChanges,
    setActiveSavedScreen,
    activeSavedScreen,
    createDraftScreen,
  } = useScreenerStore();

  const getDefaultInput = () => {
    if (activeSavedScreen) {
      return activeModal === ActionModal.SaveAs
        ? t('screener:copyOf', { name: activeSavedScreen?.name })
        : activeSavedScreen?.name;
    }

    return '';
  };

  const {
    updateNameAction,
    deleteAction,
    clearError,
    handleAPIError,
    name,
    setName,
    error,
  } = useActionsModal({
    entryId: activeSavedScreen?.id,
    defaultInputValue: getDefaultInput(),
    saveNewApi: saveNewFilter,
    renameApi: updateSavedFilter,
    deleteApi: deleteSavedFilter,
    setActiveModal,
    labels: {
      existing: t('screener:error.existingScreener'),
      empty: t('screener:error.emptyName'),
      error: t('common:somethingWentWrongTryAgain'),
    },
  });

  const isScreenerPage = !!matchPath(
    { path: appPaths.screener, end: false, caseSensitive: false },
    location.pathname
  );

  const { addFilterOption } = useFilterActions({ store: useScreenerStore });

  const openScreen = (id: string | null, name: string | null) => {
    setActiveSavedScreen(id);
    const isDraft = !id || !name;
    const pathname = isDraft ? appPaths.screener : `${appPaths.screener}/${id}`;
    navigate(pathname);
  };

  const navigateToScreener = (screenId: string | null, name: string | null) => {
    if (!unsavedChanges() || !activeSavedScreen) {
      openScreen(screenId, name);
      return;
    }

    if (!isScreenerPage) {
      openScreen(activeSavedScreen?.id, name);
    }

    setActiveModal({
      activeModal: ActionModal.UnsavedChanges,
      nextActions: [() => openScreen(screenId, name)],
    });
  };

  const createNewDraft = (props?: {
    option?: ToggleFilterProps;
    group?: ScreenerFilterGroupKeys;
    skipUnsavedCheck?: boolean;
  }) => {
    const {
      option,
      group = 'Knowledge Graph Filters',
      skipUnsavedCheck = false,
    } = props ?? {};

    const actions = () => {
      navigate(appPaths.screener);
      createDraftScreen();

      if (option) {
        addFilterOption(option, group);
      }
    };

    if (skipUnsavedCheck) {
      return actions();
    }

    const hasScreenUnsaved = activeSavedScreen && unsavedChanges();
    const hasDraftUnsaved = unsavedDraftChanges();

    const draftAction = () => {
      setActiveSavedScreen(null);
      setActiveModal({
        activeModal: ActionModal.UnsavedDraftChanges,
        nextActions: [actions],
      });
    };

    if (!hasScreenUnsaved && !hasDraftUnsaved) {
      return actions();
    }

    if (!isScreenerPage) {
      navigate(appPaths.screener);
    }

    if (!hasScreenUnsaved && hasDraftUnsaved) {
      draftAction();
    } else {
      //display UnsavedDraftChanges modal after UnsavedChanges if there are both unsaved screen and draft changes
      setActiveModal({
        activeModal: hasScreenUnsaved
          ? ActionModal.UnsavedChanges
          : ActionModal.UnsavedDraftChanges,
        nextActions: hasDraftUnsaved ? [draftAction] : [actions],
      });
    }
  };

  const handleDontSave = () => {
    setActiveModal({ activeModal: undefined, nextActions: [] });
    nextActions.forEach(action => action());

    if (onDontSaveActions?.length) {
      onDontSaveActions.forEach(action => action());
    }
  };

  const handleUpdate = async (name: string, id?: string) => {
    const res = await updateNameAction(name, id);

    if (!id && res && !res.error) {
      // new screen saved
      if (nextActions.length) {
        nextActions.forEach(action => action());
      } else {
        openScreen(res.data.id, name);
      }
    }
  };

  const handleDelete = async () => {
    if (activeSavedScreen?.id) {
      const res = await deleteAction(activeSavedScreen.id);

      if (!res.error) {
        createDraftScreen({ forceNew: false });
      }
    }
  };

  const handleUnsavedChanges = async () => {
    if (activeSavedScreen) {
      const res = await updateSavedFilter(activeSavedScreen.id);

      if (res.error) {
        handleAPIError(res.error);
      } else {
        setActiveModal({ activeModal: undefined, nextActions: [] });
        nextActions.forEach(action => action());
      }
    } else {
      setActiveModal({
        activeModal: ActionModal.SaveAs,
        nextActions: !!nextActions.length
          ? nextActions
          : [() => createNewDraft({ skipUnsavedCheck: true })],
      });
    }
  };

  const handleCloseModal = () => {
    if (onCloseActions.length) {
      onCloseActions.forEach(action => action());
    }

    setActiveModal({ activeModal: undefined });
  };

  return {
    activeModal,
    nextActions,
    error,
    navigateToScreener,
    setActiveModal,
    createNewDraft,
    handleDontSave,
    handleUnsavedChanges,
    handleDelete,
    handleUpdate,
    handleCloseModal,
    clearError,
    name,
    setName,
  };
};
