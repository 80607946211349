import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Entity } from '@toggle/toggle';
import React, { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RowSelectionCheckbox } from '~/components/row-selection-checkbox/RowSelectionCheckbox';
import { UseRowsSelectionReturnType } from '~/hooks/use-rows-selection/useRowsSelection';

import { DraggableWatchlistRow } from '../draggable-watchlist-row/DraggableWatchlistRow';
import { useTableShadow } from './hooks/useTableShadow';
import * as S from './TableWatchlistManager.styles';
import { getColumns } from './utils';

export interface TableWatchlistManagerProps
  extends Omit<UseRowsSelectionReturnType, 'setRowSelection'> {
  data: Entity[];
  countEntitiesAdded: number;
  shadowContainerRef: RefObject<HTMLDivElement | null>;
}

export const TableWatchlistManager = ({
  data,
  countEntitiesAdded,
  toggleAllRowsSelection,
  rowSelection,
  updateRowSelection,
  shadowContainerRef,
}: TableWatchlistManagerProps) => {
  const { t } = useTranslation(['watchlistManager']);

  const columns = useMemo(
    () => getColumns(t, toggleAllRowsSelection),
    [toggleAllRowsSelection]
  );

  useTableShadow({ data, shadowContainerRef });
  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId: data => data.tag,
  });
  const tableRows = table.getRowModel().rows;

  return (
    <S.ShadowContainer ref={shadowContainerRef}>
      <S.StyledTanStackTableRoot>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <S.StyledTableHeader alignment="left" key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </S.StyledTableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!!data.length ? (
            tableRows.map((row, index) => {
              const isNew = index < countEntitiesAdded;
              return (
                <DraggableWatchlistRow
                  key={row.id}
                  row={row}
                  afterNode={
                    <RowSelectionCheckbox
                      name={`watchlist-${row.id}`}
                      checked={row.getIsSelected()}
                      onChange={() =>
                        updateRowSelection(row.id, !row.getIsSelected())
                      }
                    />
                  }
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <S.StyledTableCell
                        key={cell.id}
                        $isNew={isNew}
                        isNumber={false}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </S.StyledTableCell>
                    );
                  })}
                </DraggableWatchlistRow>
              );
            })
          ) : (
            <tr>
              <td />
            </tr>
          )}
        </tbody>
      </S.StyledTanStackTableRoot>
    </S.ShadowContainer>
  );
};

TableWatchlistManager.Styled = S;
