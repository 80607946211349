import { Icon, Tooltip } from '@toggle/design-system';
import React from 'react';
import { To } from 'react-router';

import * as S from './SectionTitle.styles';

export interface SectionTitleProps {
  title: string;
  linkTo?: To;
  tooltip?: string;
  variant?: 'default' | 'small';
}

export const SectionTitle = ({
  title,
  linkTo,
  tooltip,
  variant = 'default',
  ...rest
}: SectionTitleProps) => {
  const titleElement = (
    <Tooltip
      isTouchDevice={false}
      label={tooltip}
      disabled={!tooltip}
      strategy="fixed"
      {...rest}
    >
      <S.TitleWrapper>
        <S.Title $variant={variant} $hasTooltip={!!tooltip}>
          {title}
        </S.Title>
        {linkTo && <Icon iconName="ChevronBoldRight" size={12} />}
      </S.TitleWrapper>
    </Tooltip>
  );

  return linkTo ? (
    <S.StyledLink to={linkTo}>{titleElement}</S.StyledLink>
  ) : (
    titleElement
  );
};

SectionTitle.Styled = S;
