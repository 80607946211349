import {
  Column,
  ColumnDef,
  ExpandedState,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { SkeletonLoader, TanStackTableRoot } from '@toggle/design-system';
import { Citation, DocumentSourcesTableData } from '@toggle/toggle';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableAction } from '~/views/screener/results/screener-table-utils';
import { SortableTableHeaderCell } from '~/widgets/scenario/components/sortable-table-header-cell/SortableTableHeaderCell';

import { DocumentSourcesTableNoResultsCell } from './document-sources-table-no-results-cell/DocumentSourcesTableNoResultsCell';
import * as S from './DocumentSourcesTable.styles';
import { useDocumentSourcesTableStore } from './use-document-sources-table-store/useDocumentSourcesTableStore';
import {
  getAlignment,
  getClickedCitation,
  getColumns,
  getEnabledKeys,
  getIsSubRow,
} from './utils';

interface TableDataSet {
  data: DocumentSourcesTableData[];
  isLoading: boolean;
  isError: boolean;
}
export interface DocumentSourcesTableProps {
  tableData: TableDataSet;
  onClick: (citation?: Citation) => void;
  isGrouped: boolean;
  hasNextPage: boolean;
}
export const DocumentSourcesTable = forwardRef<
  HTMLDivElement,
  DocumentSourcesTableProps
>(({ tableData, onClick, isGrouped, hasNextPage }, ref) => {
  const { t } = useTranslation('chat');
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [selectedRowId, setSelectedRowId] = useState<string>();
  const sorting = useDocumentSourcesTableStore(state => state.sorting);
  const handleColumnSorting = useDocumentSourcesTableStore(
    state => state.handleColumnSorting
  );
  const { data } = tableData;
  const hasData = data.length > 0;

  const columns = getColumns({ t, isGrouped });
  const table = useReactTable(
    useMemo(
      () => ({
        data,
        columns: columns as ColumnDef<DocumentSourcesTableData>[],
        state: {
          sorting,
          expanded,
        },
        getSubRows: (row: DocumentSourcesTableData) =>
          'subRows' in row ? row.subRows : undefined,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        enableMultiSort: false,
        manualSorting: true,
        onSortingChange: updaterFunction => {
          const newValue = functionalUpdate(updaterFunction, sorting);
          handleColumnSorting(newValue);
        },
      }),
      [data, columns, expanded, sorting]
    )
  );
  const tableRows = table.getRowModel().rows;

  useEffect(() => {
    setExpanded({});
    setSelectedRowId(undefined);
  }, [isGrouped]);

  const resetSelection = () => {
    setExpanded({});
    onClick();
    setSelectedRowId(undefined);
  };

  const handleSelection = (row: Row<DocumentSourcesTableData>) => {
    onClick(getClickedCitation(row, isGrouped));
    setSelectedRowId(row.id);

    if (!getIsSubRow(row)) {
      setExpanded({});
    }

    if (row.getCanExpand()) {
      row.toggleExpanded();
    }
  };

  const onRowClick = (row: Row<DocumentSourcesTableData>) => {
    row.getIsExpanded() ? resetSelection() : handleSelection(row);
  };

  const handleDropdownAction =
    (column: Column<DocumentSourcesTableData>) => (action: TableAction) => {
      resetSelection();

      if (action === 'ascending' || action === 'ascendingDocumentType') {
        column.toggleSorting(false);
      }

      if (action === 'descending' || action === 'descendingDocumentType') {
        column.toggleSorting(true);
      }
    };

  return (
    <S.TableWrapper ref={ref}>
      <TanStackTableRoot>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <S.StyledTableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const alignment = getAlignment(index, isGrouped);
                const enabledKeys = getEnabledKeys(header.column);
                return (
                  <SortableTableHeaderCell
                    key={header.id}
                    header={header}
                    alignment={alignment}
                    enabledKeys={enabledKeys}
                    handleAction={handleDropdownAction(header.column)}
                    enableDropdown={isGrouped}
                  />
                );
              })}
            </S.StyledTableHeaderRow>
          ))}
        </thead>
        {hasData ? (
          <tbody>
            {tableRows.map(row => (
              <tr key={row.id} onClick={() => onRowClick(row)}>
                {row.getVisibleCells().map((cell, index) => {
                  const alignment = getAlignment(index, isGrouped);
                  return (
                    <S.StyledTableCell
                      $isActive={
                        row.getIsExpanded() || row.id === selectedRowId
                      }
                      $isSubRow={getIsSubRow(row)}
                      key={cell.id}
                      alignment={alignment}
                      isNumber={false}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </S.StyledTableCell>
                  );
                })}
              </tr>
            ))}
            {hasNextPage && tableRows.length > 0 && (
              <tr key="loading-row">
                {tableRows[0].getVisibleCells().map(cell => (
                  <S.StyledTableCell key={cell.id} isNumber={false}>
                    <SkeletonLoader
                      areas={[
                        {
                          styles: {
                            width: '100%',
                            height: '20px',
                            borderRadius: '8px',
                          },
                        },
                      ]}
                    />
                  </S.StyledTableCell>
                ))}
              </tr>
            )}
            <tr>
              <S.SpacerTableCell>
                <S.SpacerCellContent />
              </S.SpacerTableCell>
            </tr>
          </tbody>
        ) : (
          <DocumentSourcesTableNoResultsCell
            colSpan={table.getHeaderGroups()[0].headers.length}
          />
        )}
      </TanStackTableRoot>
    </S.TableWrapper>
  );
});
