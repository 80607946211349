import { ColumnMeta } from '@toggle/toggle';

import { ActiveFilterOptions } from '~/components/filters/Filters';

const COLUMN_META_HORIZON_FIELD_VALUE = /horiz=(\w+)/;
const getHorizonFromColumnMetaField = (field?: string) =>
  field?.match(COLUMN_META_HORIZON_FIELD_VALUE)?.[1];

const getHorizonFromHeaderFilterOptions = (
  headerFilter?: ActiveFilterOptions
) => headerFilter?.options.find(o => o.childName === 'Timeframe')?.key;

export const checkIfHorizonsMatch = (
  columnMeta?: ColumnMeta,
  headerFilter?: ActiveFilterOptions
) => {
  const columnMetaHorizon = getHorizonFromColumnMetaField(columnMeta?.field);
  const headerFilterHorizon = getHorizonFromHeaderFilterOptions(headerFilter);
  return (
    !!columnMetaHorizon &&
    !!headerFilterHorizon &&
    columnMetaHorizon === headerFilterHorizon
  );
};
