import { Input } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  display: flex;
  padding: 4px 10px 4px 10px;
  width: 100%;

  ${Input.Styles.InputWrapper} {
    width: 100%;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    input {
      width: 100%;
    }
  }

  ${Input.Styles.RightIcon} {
    cursor: pointer;
    svg:hover {
      fill: var(--icon-soft);
    }
  }
`;
