import styled, { css, keyframes } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const EntityDetailsRoot = styled.div`
  margin: 16px;
`;

export const TitleContainer = styled.div<{ $isCondensed: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  ${({ $isCondensed }) =>
    $isCondensed
      ? css`
          ${typographyV2.bodySm};
        `
      : css`
          ${typographyV2.titleLg};
          padding-right: 20px;
        `}
`;

export const Tag = styled.p`
  ${typographyV2.bodySm}
  color: var(--text-soft);
  text-transform: uppercase;
  align-items: center;
`;

export const Ticker = styled.span`
  display: flex;
  flex-direction: row;
`;

export const CondensedContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const CondensedTicker = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
`;

export const ContentWrapper = styled.div<{ $isCondensed: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isCondensed }) =>
    $isCondensed ? '10% auto 10%' : 'auto auto'};
  grid-template-rows: auto auto;
  grid-template-areas: ${({ $isCondensed }) =>
    $isCondensed
      ? `'ticker price watchlist'
         'empty empty empty'`
      : `'ticker watchlist'
         'price empty'`};

  align-items: center;

  ${({ $isCondensed }) =>
    $isCondensed &&
    css`
      animation: ${fadeIn} 0.2s ease-out;
    `}
`;

export const TickerWrapper = styled.div`
  grid-area: ticker;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const WatchlistWrapper = styled.div`
  grid-area: watchlist;
  justify-self: end;
`;

export const PriceWrapper = styled.div`
  grid-area: price;
`;

export const LeftImageContainer = styled.div<{ $isCondensed: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;
