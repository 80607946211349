import { breakpoints } from '@toggle/design-system';
import isFunction from 'lodash/isFunction';
import { useEffect, useState } from 'react';
import { StoreApi } from 'zustand';

import {
  MediaQueries,
  MediaQueriesColorSchemes,
  MediaQueriesOrientation,
} from '~/hooks/MediaQueryList';
import { create } from '~/stores/create-store/createStore';

export interface MediaQueryStoreValue<T> {
  currentMedia: T;
  initialize: () => void;
}

const MediaQueriesValues = Object.values(MediaQueries);
const designSystemValues = Object.values(breakpoints);
const mediaQueryLists = MediaQueriesValues.map(q => window.matchMedia(q));
const designSystemQueryLists = designSystemValues.map(q =>
  window.matchMedia(q)
);

function getMediaQueryValue<T extends string>(
  list: MediaQueryList[],
  values: T[]
) {
  const index = list.findIndex(mql => mql.matches);
  return values[index];
}

const listenMatchMedia = (
  m: MediaQueryList,
  listener: (this: MediaQueryList, ev: MediaQueryListEvent) => void
) => {
  if (isFunction(m.addEventListener)) {
    m.addEventListener('change', listener);
    return () => m.removeEventListener('change', listener);
  } else {
    m.addListener(listener);
    return () => m.removeListener(listener);
  }
};

const initialize = <T extends string>(
  set: StoreApi<MediaQueryStoreValue<T>>['setState'],
  list: MediaQueryList[],
  values: T[]
) => {
  const mediaQueryListener = () => {
    const newMedia = getMediaQueryValue(list, values);
    set(prevState => ({ currentMedia: newMedia || prevState.currentMedia }));
  };

  list.forEach(mql => listenMatchMedia(mql, mediaQueryListener));
};

export const useTouchDeviceV2 = () => {
  const currentMedia = useMediaQueryV2(s => s.currentMedia);
  return [breakpoints.xs, breakpoints.sm, breakpoints.md].includes(
    currentMedia
  );
};

export const useTouchDevice = () => {
  const currentMedia = useMediaQuery(s => s.currentMedia);
  return [
    MediaQueries.SMALL_MOBILE,
    MediaQueries.MOBILE,
    MediaQueries.TABLET,
  ].includes(currentMedia);
};

export const useMediaQuery = create<MediaQueryStoreValue<MediaQueries>>(
  set => ({
    currentMedia: getMediaQueryValue(mediaQueryLists, MediaQueriesValues),
    initialize: () => initialize(set, mediaQueryLists, MediaQueriesValues),
  })
);

export const useMediaQueryV2 = create<MediaQueryStoreValue<string>>(set => ({
  currentMedia: getMediaQueryValue(designSystemQueryLists, designSystemValues),
  initialize: () => initialize(set, designSystemQueryLists, designSystemValues),
}));

export function useMediaQueryColorScheme() {
  const darkModeMatchMedia = window.matchMedia?.(
    MediaQueriesColorSchemes.DARK_MODE
  );

  const [isDarkMode, setIsDarkMode] = useState(darkModeMatchMedia?.matches);

  const darkModeListener = ({ matches }: MediaQueryListEvent) => {
    setIsDarkMode(matches);
  };

  useEffect(() => {
    if (!darkModeMatchMedia) {
      return undefined;
    }

    return listenMatchMedia(darkModeMatchMedia, darkModeListener);
  }, []);

  return { isDarkMode };
}

const OrientationMatchMedias = Object.values(MediaQueriesOrientation).map(
  v => ({
    value: v,
    matchMedia: window.matchMedia(v),
  })
);

const getCurrentOrientation = () => {
  const orientationMatcher = OrientationMatchMedias.find(({ matchMedia }) => {
    return matchMedia.matches;
  });
  return orientationMatcher?.value;
};

export function useMediaQueryOrientation() {
  const [orientation, setOrientation] = useState(getCurrentOrientation);

  const updateOrientation = () => setOrientation(getCurrentOrientation);

  useEffect(() => {
    const unlisten = OrientationMatchMedias.map(({ matchMedia }) =>
      listenMatchMedia(matchMedia, updateOrientation)
    );

    return () => {
      unlisten.forEach(l => l());
    };
  }, []);

  return orientation;
}
