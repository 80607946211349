import {
  deleteClientSecret,
  getClientSecret,
  postClientSecret,
} from '~/services/payments/payments-service';
import { create } from '~/stores/create-store/createStore';

interface PaymentsStoreState {
  error: boolean;
  clientSecret?: string;
}
interface PaymentsStoreActions {
  getClientSecret: () => Promise<void>;
  confirmPayment: (token: string) => Promise<void>;
  deletePayment: () => Promise<void>;
  clearClientSecret: () => void;
}

const initialPaymentState: PaymentsStoreState = {
  error: false,
  clientSecret: undefined,
};

export const usePayments = create<PaymentsStoreState & PaymentsStoreActions>(
  (set, get) => ({
    ...initialPaymentState,
    getClientSecret: async () => {
      const req = await getClientSecret();

      if (req.error) {
        set({ error: true });
      } else {
        set({ clientSecret: req.data.client_secret });
      }
    },
    confirmPayment: async stripeClientSecret => {
      const result = await postClientSecret(stripeClientSecret);

      if (result.error) {
        set({ error: true, clientSecret: undefined });
      }
    },
    deletePayment: async () => {
      set({ error: false });
      const { clientSecret } = get();
      const result = await deleteClientSecret(clientSecret as string);

      if (result.error) {
        set({ error: true });
      }

      set({ clientSecret: undefined });
    },
    clearClientSecret: () => set({ clientSecret: undefined }),
  })
);
