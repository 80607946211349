import { RowSelectionState } from '@tanstack/react-table';
import { MappedEntity, themeOverview, TSDatum } from '@toggle/toggle';
import isEqual from 'lodash/isEqual';

import { create } from '~/stores/create-store/createStore';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

import { ThemeTabs } from '../types';

export type ThemeOverviewResponse = {
  name: string;
  description: string;
  logo_url?: string;
};

export type ThemeCompaniesData = {
  tag: string;
  ticker: string;
  name: string;
  company_rank?: number;
  theme_rank?: number;
  current_price?: number;
  currency?: string;
  return_1d?: number;
  market_cap?: number;
  mappedEntity: MappedEntity;
  ts: TSDatum[];
};

interface ThemeOverviewStore {
  isLoading: boolean;
  theme?: ThemeOverviewResponse;
  isError: boolean;
  errorStatus?: number;
  themeId: string;
  getTheme: (themeId: string) => Promise<void>;
  viewedThemes: { [id: string]: ThemeOverviewResponse };
  rowSelection: RowSelectionState;
  companiesData: ThemeCompaniesData[];
  setCompaniesData: (value?: any) => void;
  toggleAllRowsSelection: (selected: boolean) => void;
  updateRowSelection: (key: string, selected: boolean) => void;
  getIsAllRowsSelected: () => boolean;
  getIsSomeRowsSelected: () => boolean;
  initialActiveTab?: ThemeTabs;
  setInitialActiveTab: (tab?: ThemeTabs) => void;
}

const getSelectedRowKeys = (rowSelection: Record<string, boolean>): string[] =>
  Object.entries(rowSelection).reduce<string[]>(
    (selectedKeys, [key, value]) => {
      if (value) {
        selectedKeys.push(key);
      }

      return selectedKeys;
    },
    []
  );

export const useThemeOverviewStore = create<ThemeOverviewStore>((set, get) => ({
  isLoading: true,
  theme: undefined,
  isError: false,
  themeId: '',
  errorStatus: undefined,
  viewedThemes: {},
  rowSelection: {},
  companiesData: [],
  getTheme: async (themeId: string) => {
    set({
      isLoading: true,
      isError: false,
      theme: undefined,
      themeId,
    });

    const { path, schema } = themeOverview.getTheme;
    const res = await apiFetch<ThemeOverviewResponse>(path(themeId), {
      method: 'get',
      schema,
    });

    if (res.error) {
      set({
        isLoading: false,
        isError: true,
        theme: undefined,
        themeId,
        errorStatus: res.error.status,
      });
    } else {
      set(previous => ({
        isLoading: false,
        isError: false,
        errorStatus: undefined,
        theme: res.data,
        themeId,
        viewedThemes: {
          ...previous.viewedThemes,
          [themeId]: res.data,
        },
      }));
    }
  },
  getIsAllRowsSelected: () => {
    const { rowSelection, companiesData } = get();
    const selectedRowKeys = getSelectedRowKeys(rowSelection);
    return companiesData.length === selectedRowKeys.length;
  },
  getIsSomeRowsSelected: () => {
    const { rowSelection } = get();
    const selectedRowKeys = getSelectedRowKeys(rowSelection);
    return selectedRowKeys.length > 0;
  },
  updateRowSelection: (rowId: string, selected: boolean) => {
    set(previous => ({
      rowSelection: { ...previous.rowSelection, [rowId]: selected },
    }));
  },
  toggleAllRowsSelection: (selected: boolean) => {
    const { updateRowSelection, companiesData } = get();

    for (const company of companiesData) {
      updateRowSelection(company.tag, selected);
    }
  },
  setCompaniesData: (data: ThemeCompaniesData[]) => {
    const { rowSelection: previousRowSelection } = get();

    set({
      companiesData: data,
    });

    // initialize the first 10 to be selected
    const rowSelection: { [key: string]: boolean } = {};

    for (let i = 0; i < Math.min(data.length, 10); i++) {
      const { tag } = data[i];
      rowSelection[tag] = true;
    }

    if (!isEqual(rowSelection, previousRowSelection)) {
      set({ rowSelection });
    }
  },
  setInitialActiveTab: tab => {
    set({ initialActiveTab: tab });
  },
}));
