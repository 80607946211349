import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChartFooterRoot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterItem = styled.div`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;
