import React, { ReactNode } from 'react';

import * as S from './SummaryTable.styles';

export interface SummaryTableProps {
  title: ReactNode;
  tableHeaders: string[];
  tableRows: {
    fieldName: string;
    consensus?: ReactNode;
    expected?: ReactNode;
    reported: ReactNode;
    surprise: ReactNode;
    surprisePercent?: ReactNode;
  }[];
}

export const SummaryTable = ({
  title,
  tableHeaders,
  tableRows,
}: SummaryTableProps) => {
  const renderCell = (key: string, value?: ReactNode) => {
    if (key === 'fieldName') {
      return (
        <S.StyledTableHeader
          key={key}
          isHoverEnabled={false}
          alignment={'left'}
        >
          <S.StyledRowHeaderLabel>{value}</S.StyledRowHeaderLabel>
        </S.StyledTableHeader>
      );
    }

    return (
      <S.StyledTableCell key={key} isNumber data-testid={`${key}-cell`}>
        {value}
      </S.StyledTableCell>
    );
  };

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.StyledTable
        withBodyRowHover={false}
        TableHeaderContent={
          <tr>
            {tableHeaders.map((name, idx) => (
              <S.StyledTableHeader
                key={name}
                isHoverEnabled={false}
                alignment={idx === 0 ? 'left' : 'right'}
              >
                <span>{name}</span>
              </S.StyledTableHeader>
            ))}
          </tr>
        }
        TableBodyContent={tableRows.map(data => (
          <tr key={data.fieldName}>
            {Object.entries(data).map(([key, value]) => {
              return renderCell(key, value);
            })}
          </tr>
        ))}
      />
    </S.Container>
  );
};

SummaryTable.Styled = S;
