import { MIDDLE_POSITION } from './constants';
import { MappedDominoV2 } from './HorizontalBarChart';

interface FormatPortfolioDataProps {
  data: MappedDominoV2[];
  hasMaximum: boolean;
  expanded: boolean;
}

export const formatPortfolioData = ({
  data,
  hasMaximum,
  expanded,
}: FormatPortfolioDataProps): MappedDominoV2[] => {
  const sorted = data.sort((a, b) => b.median - a.median);

  if (hasMaximum && !expanded) {
    const IN_PLACE_OF_WAVE = {
      label: '',
      median: 0,
      tag: '',
      tooltipLabel: '',
    };

    return [
      ...sorted.slice(0, MIDDLE_POSITION),
      IN_PLACE_OF_WAVE,
      ...sorted.slice(-MIDDLE_POSITION),
    ];
  }

  return sorted;
};

export const assureZeroInDomain = (
  domain: [number, number]
): [number, number] => {
  const [min, max] = domain;

  if ((min >= 0 && max >= 0) || (min < 0 && max < 0)) {
    return [Math.min(min, 0), Math.max(max, 0)];
  } else {
    return [min, max];
  }
};
