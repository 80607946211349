import { useElemSize } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { FC, JSX, useEffect, useMemo, useRef, useState } from 'react';

import { ChartXAxis } from '../../components/chart-x-axis/ChartXAxis';
import { HorizontalGridLines } from '../high-low-triple-line-chart/components/horizontal-grid-lines/HorizontalGridLines';
import * as S from './BandChart.styles';
import { Scale, SpxTsData } from './BandChart.types';
import { SectionArea } from './section-area/SectionArea';
import { drawThresholdFill } from './section-utils/section-utils';

//prevent the yScale from calculations when the data changes unless the difference is greater than this threshold
const YSCALE_THRESHOLD = 0.0005;

export interface BandChartProps {
  tsData: SpxTsData[];
  renderAfterPlotNode?: (
    xScale: Scale<number>,
    yScale: Scale<number>
  ) => JSX.Element;
  showLine?: boolean;
  domainLimited?: boolean;
  includeXScalePadding?: boolean;
  isBordered?: boolean;
  fillColors?: {
    positive: string;
    negative: string;
  };
  threshold?: number;
  isPercent?: boolean;
}

export interface BandChartComponent extends FC<BandChartProps> {
  Styled: typeof S;
}

export const BandChart: BandChartComponent = ({
  tsData,
  renderAfterPlotNode,
  showLine,
  domainLimited = true,
  includeXScalePadding = true,
  isBordered,
  fillColors,
  threshold = 0,
  isPercent,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { width, height } = useElemSize(svgRef);
  const [translateY, setTranslateY] = useState(0);
  const [highestY, setHighestY] = useState(0);

  const RIGHT_PADDING = 65;
  const MARGIN_BOTTOM = 50;
  const AXIS_PADDING = 5;

  const adjustedWidth = width - RIGHT_PADDING;
  const heightOffset = height * 0.05 + Math.abs(highestY);

  const [minY = 0, maxY = 0] = useMemo(
    () => d3.extent(tsData.map(p => p.value)),
    [tsData]
  );

  const xScale = useMemo(() => {
    const domainEnd = includeXScalePadding
      ? tsData.length + 1
      : tsData.length - 1;
    return d3
      .scaleLinear()
      .domain([0, domainEnd])
      .range([0, adjustedWidth || 0]);
  }, [tsData, width]);

  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([minY, maxY])
      .range([height - MARGIN_BOTTOM - 1.5 * heightOffset, 0]);
  }, [tsData, height, heightOffset, minY, maxY]);

  useEffect(() => {
    if (!canvasRef.current || !tsData.length || !fillColors) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    drawThresholdFill(
      ctx,
      tsData,
      xScale,
      yScale,
      adjustedWidth,
      height,
      threshold,
      fillColors,
      heightOffset
    );
  }, [
    tsData,
    xScale,
    yScale,
    adjustedWidth,
    height,
    canvasRef.current,
    threshold,
    fillColors,
    heightOffset,
  ]);

  if (!tsData.length) {
    return null;
  }

  const translatedPadding = translateY - yScale(minY);

  return (
    <S.BandChartRoot>
      <S.Plot data-testid="band-chart" ref={svgRef}>
        <foreignObject width={width} height={height} x="0" y="0">
          <canvas ref={canvasRef} width={width} height={height} />
        </foreignObject>
        <g transform={`translate(0, ${heightOffset})`}>
          <SectionArea
            ts={tsData}
            scaleX={xScale}
            height={translateY}
            y={yScale(minY) + heightOffset}
          />
          <g transform={`translate(${adjustedWidth}, 0)`}>
            <HorizontalGridLines
              tickVariant="nice"
              yScale={yScale}
              tickSize={adjustedWidth}
              tickCount={7}
              format={val => {
                return isPercent ? `${val.toFixed(1)}%` : val.toFixed(2);
              }}
              stateCallback={ticks => {
                const lowestTick = yScale(ticks[0]);
                const lowestLinearPoint = yScale(minY);
                const newTranslateY =
                  lowestTick > lowestLinearPoint
                    ? lowestTick
                    : lowestLinearPoint;
                const highestTick = yScale(ticks[ticks.length - 1]);

                setTranslateY(prev =>
                  Math.abs(prev - newTranslateY) > YSCALE_THRESHOLD
                    ? newTranslateY
                    : prev
                );
                setHighestY(prev =>
                  Math.abs(prev - highestTick) > YSCALE_THRESHOLD
                    ? highestTick
                    : prev
                );
              }}
              rightAxisWidth={RIGHT_PADDING}
            />
          </g>

          <ChartXAxis
            tsData={tsData}
            xScale={xScale}
            yScale={yScale}
            domainLimited={domainLimited}
            showLine={showLine}
            padding={AXIS_PADDING + translatedPadding}
            y1={-maxY}
            adjustedWidth={adjustedWidth}
            isBordered={isBordered}
          />
          <S.LinePlot ts={tsData} scaleX={xScale} scaleY={yScale} />
          {!!renderAfterPlotNode && renderAfterPlotNode(xScale, yScale)}
        </g>
      </S.Plot>
    </S.BandChartRoot>
  );
};

BandChart.Styled = S;
