import { typographyV2 } from '@toggle/design-system';
import { PriceStatus } from '@toggle/helpers';
import styled from 'styled-components';

import { priceChangeColors } from '~/utils/common/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  ${typographyV2.titleMd}
  padding-bottom: 16px;
`;

export const DescriptionWrapper = styled.div`
  ${typographyV2.bodyBase}
  color: var(--text-soft);
  padding-bottom: 32px;
`;

export const SurpriseWrapper = styled.div<{ $status: PriceStatus }>`
  color: ${({ $status }) => priceChangeColors[$status]};
`;
