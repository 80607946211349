import { Input } from '@toggle/design-system';
import styled from 'styled-components';

export const Search = styled(Input)`
  display: flex;
  padding: 8px 0px 8px 16px;
  width: 100%;

  ${Input.Styles.InputWrapper} {
    border: none;
    padding-left: 0px;
    width: 100%;
  }

  ${Input.Styles.RightIcon} {
    cursor: pointer;
    svg {
      fill: var(--icon-soft);
    }
  }
`;
