import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: 1px solid var(--border-soft);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px;
  gap: 4px;
`;
