import { createGlobalStyle } from 'styled-components';

export const fontFamily = {
  nort: "'nort', sans-serif, Helvetica, Arial, Verdana, Tahoma",
  robotoMono: "'Roboto Mono', monospace, Helvetica, Arial, Verdana, Tahoma",
};

export const Fonts = {
  '--font-nort': fontFamily.nort,
  '--font-roboto-mono': fontFamily.robotoMono,
};

export const GlobalFonts = createGlobalStyle`
  /* nort */
  @import url('https://use.typekit.net/gep3ucs.css');
  /* roboto mono */
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');

  :root {
    ${Fonts}
  }
  
  @font-face {
    font-family: 'nort';
    src: url('https://use.typekit.net/af/12e55b/00000000000000007735bb1d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
        format('woff'),
      url('https://use.typekit.net/af/12e55b/00000000000000007735bb1d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'nort';
    src: url('https://use.typekit.net/af/42230d/00000000000000007735bb23/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/42230d/00000000000000007735bb23/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_ROW4.woff2')
      format('woff2');
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7Pq_ROW4.woff2')
      format('woff2');
  }

  body {
    font-family: var(--font-nort);
    color: var(--text-default);
  }
`;
