import styled, { css, RuleSet } from 'styled-components';

import { PlotTypes } from '../../HighLowTripleLineChart.types';
import { LinePlot } from '../line-plot/LinePlot';

export const Positive = css`
  color: var(--viz-bullish);

  circle {
    color: var(--viz-bullish);
  }
`;

export const Negative = css`
  color: var(--viz-bearish);

  circle {
    color: var(--viz-bearish);
  }
`;

export const Median = css`
  color: var(--border-default);

  path {
    stroke-dasharray: 2;
  }
`;

const statusColors: Record<PlotTypes, RuleSet<object>> = {
  positive: Positive,
  negative: Negative,
  median: Median,
};

export const LinePlotStyled = styled(LinePlot)<{
  $type?: PlotTypes;
  $dashed: boolean;
}>`
  ${({ $type = PlotTypes.Positive }) => statusColors[$type]};

  ${({ $dashed }) =>
    $dashed &&
    css`
      path {
        stroke-dasharray: 2;
      }
    `}
`;
