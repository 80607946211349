import styled from 'styled-components';

export const MultiLineChartRoot = styled.div`
  position: relative;
  overflow: visible;
`;

export const MultiLineChartContainer = styled.div`
  overflow: visible;
`;

export const Plot = styled.svg`
  width: 100%;
  height: 340px;
  overflow: visible;
`;

export const TooltipLine = styled.line`
  stroke: var(--border-default);
  stroke-dasharray: 4 4;
`;
