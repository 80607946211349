import styled from 'styled-components';

export const TanStackTableRoot = styled.table`
  border-spacing: 0;
  width: 100%;

  --z-index-header-cell: 2;
  --z-index-table-cell: 1;

  tbody tr {
    cursor: pointer;
  }
  tbody tr:hover {
    background-color: var(--surface-hover);
    td {
      background-color: var(--surface-hover);
      [aria-haspopup='true'] {
        border-bottom: 1px dashed var(--border-default);
      }
    }
  }
`;
