import { Alert, AlertType, Button } from '@toggle/design-system';
import { mailTo, useCountdown } from '@toggle/helpers';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router';

import { Loader } from '~/components/loader/Loader';
import { config } from '~/config';
import { appPaths, authPaths } from '~/routes/app-paths';
import { useAuth } from '~/stores/use-auth/useAuth';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';
import { useUser } from '~/stores/use-user/useUser';
import {
  getRefreshToken,
  postSendVerifyEmail,
  postVerifyEmail,
} from '~/views/auth/services/auth-services';

import * as S from './Verify.styles';

const contactFormLink = 'https://support.reflexivity.com/hc/en-us/requests/new';
const COUNTDOWN_TIME_SECONDS = 120;
const VERIFY_EMAIL_COUNTDOWN_TIMER_KEY = 'verify-email-countdown-timer';
const TOAST_VERIFY_ID = 'send-verification-email';
const TOAST_EMAIL_ID = 'send-verification-email';

export const Verify: FC = () => {
  const { t } = useTranslation('auth');
  const { showToast, removeToast } = useToasts();
  const location = useLocation();
  const { unLockAccount, isAuthenticated } = useAuth();
  const { user } = useUser(({ user }) => ({ user }));
  const { timeLeft, newCountdown, stopCountdown } = useCountdown({
    key: VERIFY_EMAIL_COUNTDOWN_TIMER_KEY,
    time: COUNTDOWN_TIME_SECONDS,
  });
  const [hasExpired, setHasExpired] = useState(false);
  const [emailValue, setEmailValue] = useState('');

  useEffect(() => {
    verifyToken();
  }, []);

  const supportEmail = mailTo(config.supportEmail);

  const getTokenFromUrl = (location: Location) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('token') ?? '';
  };

  const getAlertProps = (isError: boolean) => {
    if (isError) {
      return {
        type: AlertType.Error,
        title: t('auth:verify.errorTitle'),
        message: (
          <Trans
            t={t}
            i18nKey="auth:verify.errorMessage"
            components={[<a key="key-0" href={supportEmail} />]}
            values={{
              email: config.supportEmail,
            }}
          />
        ),
      };
    }

    return {
      type: AlertType.Success,
      message: t('auth:verify.success'),
    };
  };

  const redirectPage = isAuthenticated
    ? appPaths.settings.account
    : authPaths.login;

  const verifyToken = async () => {
    const response = await postVerifyEmail(getTokenFromUrl(location));

    if (!response.error) {
      const alertProps = getAlertProps(!!response.error);
      showToast({
        id: TOAST_VERIFY_ID,
        hideToast: true,
        position: ToastPosition.TopRight,
        content: (
          <Alert
            withIcon
            onClose={() => removeToast(TOAST_VERIFY_ID)}
            {...alertProps}
          />
        ),
      });
      unLockAccount();

      if (isAuthenticated) {
        await getRefreshToken();
      }

      window.location.assign(redirectPage);
      return;
    }

    setHasExpired(true);
  };

  const sendVerifyEmail = async () => {
    if (!emailValue) {
      return;
    }

    newCountdown();
    const response = await postSendVerifyEmail(user?.email ?? emailValue);

    if (response.error) {
      stopCountdown();
    }

    const toastId = TOAST_EMAIL_ID;
    const alertProps = response.error
      ? {
          type: AlertType.Error,
          title: t('auth:onHold.emailSentError'),
          message: t('auth:onHold.emailSentDescriptionError'),
        }
      : {
          type: AlertType.Success,
          title: t('auth:onHold.emailSent'),
          message: t('auth:onHold.emailSentDescription', {
            email: emailValue,
          }),
        };

    showToast({
      id: toastId,
      hideToast: true,
      position: ToastPosition.TopRight,
      content: (
        <Alert withIcon onClose={() => removeToast(toastId)} {...alertProps} />
      ),
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await sendVerifyEmail();
  };

  if (!hasExpired) {
    return (
      <S.Wrapper>
        <Loader />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <div>
        <S.StyledCard>
          <S.OpenedLetterIcon src="/app/static/images/auth/opened-letter.svg" />
          <S.ContentWrapper>
            <S.Title>{t('auth:verify.title')}</S.Title>
            <S.Description>{t('auth:verify.description')}</S.Description>
          </S.ContentWrapper>
          <S.Form id="sendVerify" onSubmit={handleSubmit}>
            {!user?.email && (
              <S.StyledInput
                id="sendVerify"
                variant="outline"
                size="medium"
                placeholder={t('auth:verify.inputPlaceholder')}
                data-testid="email-input"
                onChange={e => setEmailValue(e.target.value)}
              />
            )}
            <Button
              variant="primary"
              size="medium"
              label={t('auth:verify.request', { timeLeft: timeLeft })}
              type="submit"
              form="sendVerify"
              data-testid="send-verify-button"
              disabled={Boolean(timeLeft)}
            />
          </S.Form>
        </S.StyledCard>
        <S.Contact>
          <Trans
            t={t}
            i18nKey="auth:onHold.stillHavingTrouble"
            components={[<a key="key-0" href={contactFormLink} />]}
          />
        </S.Contact>
      </div>
    </S.Wrapper>
  );
};
