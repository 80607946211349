import { ChartPane, TURBO_CHART_INNER_PADDING } from '@toggle/chart';
import React, { ReactNode } from 'react';

import * as S from './LegendPosition.styles';

export interface LegendPositionProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  pane: ChartPane;
  paneIndex: number;
}

const LEGEND_ITEM_HEIGHT = 36;

export const LegendPosition = ({
  children,
  className,
  pane,
  paneIndex,
}: LegendPositionProps) => {
  const chartAssetData = pane.chartAssetData;
  const legendItemHeights = LEGEND_ITEM_HEIGHT * chartAssetData.length;
  const height =
    legendItemHeights >= pane.options.height
      ? pane.options.height - TURBO_CHART_INNER_PADDING
      : undefined;

  const top =
    paneIndex === 0
      ? TURBO_CHART_INNER_PADDING
      : TURBO_CHART_INNER_PADDING + pane.options.gutters.top;

  return (
    <S.LegendPositionRoot
      data-testid="legend-position"
      style={{ top, height }}
      className={className}
    >
      {children}
    </S.LegendPositionRoot>
  );
};
