import {
  customScrollMinimal,
  Drawer,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';

const footerHeight = '56px';

export const StyledDrawer = styled(Drawer)`
  ${Drawer.Styled.Header} {
    padding: 16px 8px 16px 16px;
  }

  ${Drawer.Styled.Body} {
    height: 100%;
  }

  ${Drawer.Styled.ButtonContainer} {
    width: 100%;
    justify-content: space-between;
  }

  ${Drawer.Styled.Container} {
    width: 500px;
  }
`;

export const Content = styled.div<{ $hasFooter: boolean }>`
  ${customScrollMinimal};
  overflow-y: auto;
  height: calc(100% - ${footerHeight});
  padding: 0 16px 80px 16px;

  &:after {
    display: block;
    content: ' ';
    position: fixed;
    left: 0;
    bottom: ${footerHeight};
    height: 130px;
    width: 100%;
    background: linear-gradient(
      rgba(var(--background-soft-rgb), 0) 0%,
      rgba(var(--background-soft-rgb), 1) 100%
    );
    pointer-events: none;
  }

  ${({ $hasFooter }) =>
    !$hasFooter &&
    css`
      height: 100%;
      &:after {
        bottom: 0;
      }
    `}
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${footerHeight};
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-soft);

  svg {
    fill: var(--icon-primary);
  }
`;

export const StyledErrorMessage = styled(ErrorMessageWrapper)`
  border: none;
`;

export const DowJonesDisclaimer = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;
