import {
  ChartAssetData,
  ChartColors,
  ChartEmpty,
  ChartError,
  CreateChartOptions,
  CreateOptionsConfig,
} from '@toggle/chart';
import { useWindowResize } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React, { ReactNode, RefObject, useRef } from 'react';

import { config } from '~/config';

import * as S from './ChartAreaWrapper.styles';

export interface ChartAreaWrapperProps {
  className?: string;
  assetWithError?: Entity;
  hasError: boolean;
  hasEmpty: boolean;
  colors: ChartColors;
  chartData: ChartAssetData[];
  chartConfig?: Partial<CreateOptionsConfig>;
  onSearchAgain?: () => void;
  onRetry: () => void;
  renderChartArea: (
    options: CreateChartOptions,
    containerRef: RefObject<HTMLDivElement | null>
  ) => ReactNode;
}

export const ChartAreaWrapper = ({
  className,
  assetWithError,
  hasError,
  hasEmpty,
  colors,
  chartData,
  chartConfig,
  renderChartArea,
  onRetry,
  onSearchAgain,
}: ChartAreaWrapperProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowResize({ targetRef });

  const options: CreateChartOptions = {
    width,
    height,
    panePaddingTop: chartConfig?.panePaddingTop ?? 0,
    gutters: {
      x: 40,
      y: 40,
    },
    // TODO when this is packaged make sure this parent component adds robot mono and nort
    font: {
      default: '12px Nort',
      mono: '12px Roboto Mono',
    },
    config: chartConfig,
    colors,
  };

  const hasSize = width && height;
  return (
    <S.Container className={className} ref={targetRef}>
      {hasError && (
        <ChartError supportEmail={config.supportEmail} onRetry={onRetry} />
      )}
      {!hasError && hasEmpty && !chartData.length && assetWithError && (
        <ChartEmpty
          assetName={assetWithError.name}
          onSearchAgain={onSearchAgain}
        />
      )}
      {!!chartData.length && hasSize && renderChartArea(options, targetRef)}
    </S.Container>
  );
};
