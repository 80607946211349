import { Entity } from '@toggle/toggle';
import { scaleLinear } from 'd3-scale';

import {
  BaseChartAPIProps,
  ChartPane,
  CreateChartOptionsWithColors,
  PaneData,
  YAxis,
} from '~/types/create.types';
import { RangeHorizon, ResampleIntervals } from '~/types/resample.types';
import {
  ChartAssetData,
  ChartInsight,
  TimeSeriesItem,
} from '~/types/timeseries.types';

import { ChartColors, XAxisTick } from '../';

export const mockTSData: TimeSeriesItem[] = [
  { time: '2018-12-26', open: 28.17, close: 25.17, high: 30.35, low: 23.45 },
  { time: '2018-12-27', open: 23.45, close: 19.89, high: 25, low: 19.4 },
  { time: '2018-12-28', open: 25.46, close: 27.45, high: 29, low: 24.3 },
  { time: '2018-12-29', open: 22.68, close: 26.68, high: 28.4, low: 21.3 },
  { time: '2018-12-30', open: 10, close: 23.67, high: 10, low: 10 },
  { time: '2018-12-31', open: 10, close: 22.67, high: 10, low: 10 },
];

export const mockChartInsights: ChartInsight[] = [
  {
    id: 'mock-article-1',
    type: 'article',
    direction: 'bullish',
    date: new Date('2018-12-26'),
    entity: 'tsla_nasd',
  },
  {
    id: 'mock-article-2',
    type: 'article',
    direction: 'bullish',
    date: new Date('2018-12-27'),
    entity: 'aapl_nasd',
  },
];

const mockXAxisTicks: XAxisTick[] = [
  { index: 10, label: '26', highlight: false },
  { index: 50, label: '27', highlight: false },
];

export const mockYAxis: YAxis[] = [
  {
    entityDefaultSnake: 'mock-snake.price.ds',
    yScale: scaleLinear().domain([2, 4]).range([0, 100]),
    labels: ['1', '2'],
    ticks: [1, 2],
  },
  {
    entityDefaultSnake: 'mock-snake-two.price.ds',
    yScale: scaleLinear().domain([2, 4]).range([0, 100]),
    labels: ['1'],
    ticks: [1],
  },
];

export const mockChartAssetData: ChartAssetData[] = [
  {
    ts: mockTSData,
    lineColorToken: 'mock',
    isHidden: false,
    entity: {
      tag: 'mock_nasd',
      default_snake: 'mock-snake.price.ds',
      exchange: {
        code: 'NASD',
        name: 'Nasdaq',
      },
      gics: {
        sector: {
          code: 'sector_communication_services',
        },
      },
      primary_method: 'price',
      subscribable_ticker: 'NASD',
    } as Entity,
    tsByTime: new Map([
      ['2018-12-26', 0],
      ['2018-12-27', 1],
      ['2018-12-28', 2],
      ['2018-12-29', 3],
      ['2018-12-30', 4],
      ['2018-12-31', 5],
    ]),
    originalTs: mockTSData,
  },
  {
    ts: mockTSData,
    lineColorToken: 'mock',
    isHidden: false,
    entity: {
      tag: 'mock-two_nasd',
      default_snake: 'mock-snake-two.price.ds',
      primary_method: 'price',
    } as Entity,
    tsByTime: new Map([
      ['2018-12-26', 0],
      ['2018-12-27', 1],
      ['2018-12-28', 2],
      ['2018-12-29', 3],
      ['2018-12-30', 4],
      ['2018-12-31', 5],
    ]),
    originalTs: mockTSData,
  },
];

export const mockChartPane: ChartPane = {
  id: 'mock-pane-id',
  seriesType: 'line',
  chartAssetData: mockChartAssetData,
  actualHeight: 120,
  options: {
    width: 120,
    height: 120,
    panePaddingTop: 0,
    gutters: {
      x: 20,
      top: 32,
    },
  },
  y: mockYAxis,
  yAxisType: 'merged',
  priceDisplay: 'price',
  domainTimeSeries: [
    { startIndex: 0, endIndex: 4, start: mockTSData[0], end: mockTSData[5] },
    { startIndex: 1, endIndex: 4, start: mockTSData[1], end: mockTSData[5] },
  ],
};

export const mockChartPaneData: PaneData = {
  id: 'mock-pane-id',
  chartAssetData: mockChartAssetData,
  seriesType: 'line',
  yAxisType: 'merged',
  priceDisplay: 'price',
};

export const mockCreateChartOptions: CreateChartOptionsWithColors = {
  width: 120,
  height: 120,
  panePaddingTop: 0,
  gutters: {
    x: 20,
    y: 20,
  },
  colors: {
    bars: {
      down: {
        value: 'bar-down-value',
        token: 'token',
      },
      up: {
        value: 'bar-up-value',
        token: 'token',
      },
    },
    default: {
      value: 'surface-inverse',
      token: 'token',
    },
    surface: {
      default: {
        value: 'surface-default-value',
        token: 'token',
      },
    },
    typography: {
      highlight: {
        value: 'typography-highlight-value',
        token: 'token',
      },
      soft: {
        value: 'typography-soft-value',
        token: 'token',
      },
    },
    paneGradient: {
      value: 'pane-gradient-value',
      token: 'token',
    },
    primary: {
      value: 'primary-value',
      token: 'token',
    },
    border: {
      hover: {
        value: 'hover-value',
        token: 'token',
      },
      default: {
        value: 'border-value',
        token: 'token',
      },
      soft: {
        value: 'soft-value',
        token: 'token',
      },
    },
    line: {
      default: {
        value: 'line-value',
        token: 'token',
      },
    },
    threshold: {
      bullish: {
        value: 'bullish-value',
        token: 'token',
      },
      bearish: {
        value: 'bearish-value',
        token: 'token',
      },
    },
    getThemeColor: () => 'color',
  } as unknown as ChartColors,
  font: {
    default: 'default',
    mono: 'mono',
  },
  config: {
    paneMaxCount: 5,
    enableZoom: true,
    enableHover: true,
    collapsedPaneHeight: 44,
    hoverVariant: 'default',
    panePaddingTop: 0,
  },
};

export const mockBase: BaseChartAPIProps = {
  primaryAsset: mockChartAssetData[0],
  context: {} as CanvasRenderingContext2D,
  options: mockCreateChartOptions,
  x: {
    axis: mockXAxisTicks,
    xScale: scaleLinear().domain([0, 100]).range([0, 100]),
  },
  fullXScale: scaleLinear().domain([0, 100]).range([0, 100]),
  maxChartZoom: 4,
  domain: [0, 4],
  canvasElement: document.createElement('canvas'),
  lastDataIdx: 10,
  resampleInterval: ResampleIntervals.OneDay,
  panes: [mockChartPane],
  horizon: RangeHorizon.ThreeMonths,
  yAxisSizes: [
    {
      width: 67.5,
      xStart: 0,
    },
    {
      width: 67.5,
      xStart: 67.5,
    },
  ],
  primaryPaneId: mockChartPane.id,
  timeseries: mockChartAssetData[0].ts,
};
