import React, { FC } from 'react';

import { EntityDetailsCell } from '../entity-details-cell/EntityDetailsCell';
import { ProviderCell } from '../provider-cell/ProviderCell';
import * as S from './UngroupedPassageCell.styles';
import { formatPublishedDate } from './utils';

export interface UngroupedPassageCellProps {
  text: string;
  published_date: string;
  entity: string;
  document_kind: string;
}

export const UngroupedPassageCell: FC<UngroupedPassageCellProps> = ({
  text,
  published_date,
  entity,
  document_kind,
}) => {
  const formattedDate = formatPublishedDate(published_date);

  return (
    <S.Column data-testid="ungrouped-passage-cell">
      {text && <S.StyledText data-testid="citation-text">{text}</S.StyledText>}
      <S.DetailsContainer>
        <EntityDetailsCell entityTag={entity} />
        <span>{'·'}</span>
        <ProviderCell documentKind={document_kind} date={formattedDate} />
      </S.DetailsContainer>
    </S.Column>
  );
};
