import React, { ReactNode } from 'react';

import * as S from './HorizonSelector.styles';

export interface HorizonSelectorProps {
  horizonLabels: ReactNode[];
  activeHorizonIndex: number;
  onClick: (index: number) => void;
}

export const HorizonSelector = ({
  horizonLabels,
  activeHorizonIndex,
  onClick,
}: HorizonSelectorProps) => {
  return (
    <S.HorizonSelectorRoot data-testid="horizon-selector">
      <S.StyledButtonGroup
        labels={horizonLabels}
        onClick={onClick}
        activeIndex={activeHorizonIndex}
      />
    </S.HorizonSelectorRoot>
  );
};
