import { OAuthIBPayload, OAuthIBResponse, portfolio } from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const IB_OAUTH_CODE_EVENT = 'IB_OAUTH_CODE_EVENT';

export const getIBLoginLink = () =>
  apiFetch<OAuthIBResponse>(portfolio.ibLogin.path, {
    schema: portfolio.ibLogin.schema,
  });

export const postOAuthCodeIB = (data: OAuthIBPayload) =>
  apiFetch<OAuthIBPayload>(portfolio.verifyToken.path, {
    method: 'post',
    body: data,
  });
