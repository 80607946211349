import React, { FC } from 'react';

import * as S from './GradientDef.styles';

export interface StopGradient {
  offset: number;
  stopColor: string;
  stopOpacity?: number;
}

export interface GradientDefProps {
  gradientId: string;
  gradientY1?: number | string;
  stops?: StopGradient[];
}

export const GradientDef: FC<GradientDefProps> = ({
  gradientId,
  gradientY1,
  stops,
}) => {
  return (
    <defs>
      <linearGradient id={gradientId} x1="0" x2="0" y1={gradientY1} y2="1">
        {(stops ?? []).map(({ offset, stopColor, stopOpacity }) => (
          <S.Stop
            key={`gradient-${offset}`}
            offset={`${offset}%`}
            $stopColor={stopColor}
            $stopOpacity={stopOpacity}
          />
        ))}
      </linearGradient>
    </defs>
  );
};
