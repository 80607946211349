import { Icon } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEarningsStore } from '../../useEarningsStore/useEarningsStore';
import * as S from './EarningsManualEditOptions.styles';

export const EarningsManualEditOptions = () => {
  const { t } = useTranslation();
  const { resetSelectionByManualEdit } = useEarningsStore();
  return (
    <S.ManualEditOptionsWrapper>
      <S.IconAndLabelWrapper>
        <Icon iconName="InformationLightCircle" size={20} />
        <S.ManualEditOptionsLabelText>
          {t('widget:earnings.earningsScenario.manualChangesMade')}
        </S.ManualEditOptionsLabelText>
      </S.IconAndLabelWrapper>

      <S.ClickableIconAndLabelWrapper onClick={resetSelectionByManualEdit}>
        <Icon iconName="Reset" size={20} />
        <S.ManualEditOptionsLabelText>
          {t('widget:earnings.earningsScenario.resetManualEdits')}
        </S.ManualEditOptionsLabelText>
      </S.ClickableIconAndLabelWrapper>
    </S.ManualEditOptionsWrapper>
  );
};
