import { Button, Icon } from '@toggle/design-system';
import { formatPercentage, formatPriceWithCurrency } from '@toggle/helpers';
import { formatTicker, Insight, InsightDirection } from '@toggle/toggle';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { useEntity } from '~/hooks/use-entities';
import { formatUtcDate } from '~/services/insights/utils/insight-utils';
import { InsightsDrawer } from '~/widgets/insight-widget/components/insights-drawer/InsightsDrawer';

import * as S from './ChartArticleInsightCard.styles';

export interface ChartArticleInsightCardProps {
  data: Insight;
  onOpenDetails?: () => void;
}

const MAX_STARS = 8;

export const ChartArticleInsightCard: FC<ChartArticleInsightCardProps> = ({
  data,
  onOpenDetails,
}) => {
  const { t } = useTranslation('widget');
  const [isInsightsDrawerOpen, setIsInsightsDrawerOpen] = useState(false);
  const metadata = data.metadata;

  const analysisType = data.metadata.type;
  const isBullish = metadata.direction === InsightDirection.Bullish;

  const date = formatUtcDate(
    metadata.last_value_date ?? data.created_at,
    'PPP'
  );

  const median = formatPercentage(metadata.median_return, {
    suffix: '%',
    decimals: 2,
  });

  const rows = [
    {
      title: t('widget:insights:analysisType'),
      label: <S.RowLabel>{analysisType}</S.RowLabel>,
    },
    {
      title: t('widget:insights:attractiveHorizon'),
      label: (
        <S.RowLabel>
          {t('widget:insights:attractiveHorizon', {
            context: metadata.horizon,
          })}
        </S.RowLabel>
      ),
    },
    {
      title: t('widget:insights:medianReturn'),
      label: (
        <S.MedianReturn data-testid="median-return-label">
          {isBullish ? `+ ${median}` : `${median}`}
        </S.MedianReturn>
      ),
    },
    {
      title: t('widget:insights:stars', { context: metadata.stars }),
      label: (
        <S.StarsLabel>
          <Icon iconName="Rating" size={20} />
          <S.RowLabel>
            {metadata.stars}/{MAX_STARS}
          </S.RowLabel>
        </S.StarsLabel>
      ),
    },
  ];

  const handleOpenArticleClick = () => {
    if (data.id) {
      if (onOpenDetails) {
        onOpenDetails();
      } else {
        setIsInsightsDrawerOpen(true);
      }
    }
  };

  const { data: entityData } = useEntity(data.metadata.entity);

  return (
    <>
      {isInsightsDrawerOpen && (
        <InsightsDrawer
          isOpen
          closeDrawer={() => setIsInsightsDrawerOpen(false)}
          insightId={data.id}
          zIndex={'calc(var(--z-tooltip) + 1)'}
        />
      )}
      <S.Container
        $isBullish={isBullish}
        data-testid="chart-article-insight-card"
      >
        {entityData?.data && (
          <S.EntityWrapper data-testid="entity-wrapper">
            <AssetLogoContainer entity={entityData.data} size={24} />
            {formatTicker(entityData.data)}
          </S.EntityWrapper>
        )}
        <S.TypeTitle data-testid="type-title">
          {
            /* i18next-extract-mark-context-next-line ["1", "2"] */
            t('widget:insights:articleInsight', { context: metadata.direction })
          }
          <mark>&#8480;</mark>
        </S.TypeTitle>
        <S.Title data-testid="card-name">{data.card.name}</S.Title>
        <S.RowsContainer data-testid="rows-container">
          {rows.map((row, idx) => (
            <S.Row key={idx}>
              <S.RowTitle>{row.title}</S.RowTitle>
              {row.label}
            </S.Row>
          ))}
        </S.RowsContainer>
        <S.HelperText data-testid="helper-text">
          {t('widget:insights:analysisBased', {
            price: formatPriceWithCurrency(metadata.last_value),
            date: date,
          })}
        </S.HelperText>
        <Button
          data-testid="full-details-button"
          label={t('widget:insights:fullDetails')}
          size="small"
          fullWidth
          onClick={handleOpenArticleClick}
        />
      </S.Container>
    </>
  );
};
