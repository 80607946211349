import { Watchlist } from '~/stores/use-watchlist/watchlist-types';

export const getWatchlistNames = (
  entityTags: string[],
  lists: Watchlist[]
): string[] => {
  const watchlistNames = new Set<string>();

  lists.forEach(watchlist => {
    const hasMatchingTag = watchlist.entities.some(entity =>
      entityTags.includes(entity.tag)
    );

    if (hasMatchingTag) {
      watchlistNames.add(watchlist.name);
    }
  });

  return Array.from(watchlistNames);
};
