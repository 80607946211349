import React, { HTMLAttributes, ReactNode } from 'react';

import * as S from './EntityDetails.styles';

export interface EntityDetailsProps extends HTMLAttributes<HTMLDivElement> {
  entityNameComponent: ReactNode;
  formattedTicker: string;
  leftImgNode: ReactNode;
  buttonNode?: ReactNode;
  priceDisplayNode?: ReactNode;
  isCondensed?: boolean;
  isButtonActive?: boolean;
}

export const EntityDetails = ({
  entityNameComponent,
  formattedTicker,
  leftImgNode,
  buttonNode,
  priceDisplayNode,
  isCondensed = false,
  ...rest
}: EntityDetailsProps) => {
  return (
    <S.EntityDetailsRoot data-testid="module-entity-details" {...rest}>
      <S.ContentWrapper $isCondensed={isCondensed}>
        <S.TickerWrapper>
          <S.Ticker>
            {leftImgNode && (
              <S.LeftImageContainer $isCondensed={isCondensed}>
                {leftImgNode}
              </S.LeftImageContainer>
            )}
            <S.TitleContainer $isCondensed={isCondensed}>
              {entityNameComponent}
              <S.Tag>{formattedTicker}</S.Tag>
            </S.TitleContainer>
          </S.Ticker>
        </S.TickerWrapper>
        <S.WatchlistWrapper>{buttonNode}</S.WatchlistWrapper>
        <S.PriceWrapper>{priceDisplayNode}</S.PriceWrapper>
      </S.ContentWrapper>
    </S.EntityDetailsRoot>
  );
};

EntityDetails.Styles = S;
