import { Insight, InsightFilterResponse, insights } from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';
import { getArticleDisplayDate } from '~/widgets/asset-overview-widget/utils/insights-helpers';

const PAGE_LIMIT = 20;

interface FetchFeedProps {
  entityTag: string;
  startDate: Date;
  endDate: Date;
  abort?: AbortSignal;
  pageLimit?: number;
}

const fetchSinglePage = async ({
  entityTag,
  fromDate,
  abort,
  endDate,
}: {
  entityTag: string;
  fromDate: Date;
  endDate: Date;
  abort?: AbortSignal;
}) => {
  const endPoint = insights.insightFilter.path;
  const insightResp = await apiFetch<InsightFilterResponse>(endPoint, {
    method: 'post',
    options: {
      signal: abort,
    },
    body: {
      entity: [entityTag],
      from_date: fromDate.toISOString(),
      to_date: endDate.toISOString(),
    },
  });

  if (!insightResp.error) {
    const insightData = insightResp.data.result;
    return {
      error: false,
      minDate: insightData.length
        ? getArticleDisplayDate({
            created_at: insightData[insightData.length - 1].created_at,
            last_value_reference_date:
              insightData[insightData.length - 1].metadata.last_value_date,
          })
        : null,
      articles: insightResp.data,
    };
  }

  return insightResp;
};

export const fetchFeed = async ({
  entityTag,
  startDate,
  endDate,
  abort,
  pageLimit = PAGE_LIMIT,
}: FetchFeedProps): Promise<
  { error: true } | { error: false; result: Insight[] }
> => {
  const result: Insight[] = [];

  let date = endDate,
    prevDate;

  while (date > startDate && !abort?.aborted) {
    const res = await fetchSinglePage({
      entityTag,
      fromDate: startDate,
      endDate: date,
      abort,
    });

    if (res.error) {
      return { error: true };
    }

    const { minDate, articles } = res;
    const isSameData =
      !!minDate && !!prevDate && prevDate.getTime() === minDate.getTime();

    if (!isSameData) {
      result.push(...articles.result);
    }

    if (articles.result.length < pageLimit || !minDate || isSameData) {
      break;
    }

    prevDate = date = minDate;
  }

  return {
    error: false,
    result,
  };
};
