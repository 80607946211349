import { useEffect } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { useUser } from '~/stores/use-user/useUser';

import { useLivePriceStore } from './use-live-price-store/useLivePriceStore';
import {
  Identifier,
  LivePriceData,
  PriceEntity,
  SubscribeFn,
} from './use-live-price-store/useLivePriceStore.utils';

interface StateSlice {
  subscribe: SubscribeFn;
  livePriceData: LivePriceData;
}

const bindEquityFnWithEntities =
  (priceEntities: PriceEntity[]) =>
  (prevState: StateSlice, currentState: StateSlice) =>
    priceEntities.every(e => {
      const ticker = e.entity.ticker;
      return (
        prevState.livePriceData[ticker] === currentState.livePriceData[ticker]
      );
    });

export const useLivePrice = (
  priceEntities: PriceEntity[],
  identifier?: Identifier
) => {
  const user = useUser(state => state.user);
  const { subscribe, livePriceData } = useStoreWithEqualityFn(
    useLivePriceStore,
    s => ({
      subscribe: s.subscribe,
      livePriceData: s.livePriceData,
    }),
    bindEquityFnWithEntities(priceEntities)
  );

  useEffect(() => {
    if (!priceEntities?.length) {
      return undefined;
    }

    return subscribe({
      priceEntities,
      identifier,
      isPro: user?.is_professional ?? false,
    });
  }, [priceEntities.map(e => e.entity.ticker).join(' ')]);

  return livePriceData;
};
