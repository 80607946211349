import { Entity } from '@toggle/toggle';
import React, { ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { ChatInputProps } from '~/components/chat';
import { useDocumentsEntity } from '~/hooks/use-documents-entity/useDocumentsEntity';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { useEarningsData } from '~/hooks/useEarningsData/useEarningsData';

import { EarningsChatInput } from './components/earnings-chat-input/EarningsChatInput';
import { EarningsFilters } from './components/earnings-filters/EarningsFilters';
import { EarningsManualEditOptions } from './components/earnings-manual-edit-options/EarningsManualEditOptions';
import { EarningsPriceMovementsChartAccordion } from './components/earnings-price-movements-chart-accordion/EarningsPriceMovementsChartAccordion';
import { EarningsTableWrapper } from './components/earnings-table-wrapper/EarningsTableWrapper';
import * as S from './Earnings.styles';
import { UnreportedEarnings } from './UnreportedEarnings';
import { useEarningsStore } from './useEarningsStore/useEarningsStore';
import { getEarningsFilterPayload, getEarningsFilters } from './utils/utils';

export type EarningsProps = {
  entity: Entity;
  renderChatInput?: (props?: ChatInputProps) => ReactElement;
};

export const Earnings = ({ entity, renderChatInput }: EarningsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useEarningsData(entity.tag);
  const { data: documents } = useDocumentsEntity(entity.tag);
  const { t } = useTranslation([
    'widget',
    'asset-overview',
    'chat',
    'common',
    'screener',
    'scenario',
  ]);
  const {
    initStore,
    isViewSelectedOnly,
    rowSelection,
    rowSelectionByManualEdit,
    setIsViewSelectedOnly,
    earningsData,
  } = useEarningsStore();

  const [searchParams, setSearchParams] = useSearchParams();
  const { filterGroups, onFilterGroupsChange } = useEarningsStore();
  const { addFilterOption } = useFilterActions({
    store: useEarningsStore,
    group: 'EARNINGS_FILTERS',
    onFilterGroupsChange,
  });

  const hasManualChanges = !!Object.keys(rowSelectionByManualEdit).length;

  useEffect(() => {
    if (data && data !== earningsData) {
      initStore({
        data,
        filters: getEarningsFilters(t),
      });
    }
  }, [data, earningsData]);

  useEffect(() => {
    const epsFilter = searchParams.get('epsFilter');

    if (filterGroups.EARNINGS_FILTERS && epsFilter) {
      const epsValue = searchParams.get('epsValue');
      const filterPayload = getEarningsFilterPayload(
        t,
        epsFilter,
        epsValue ? Number(epsValue) : 0
      );

      if (filterPayload) {
        addFilterOption(filterPayload);
        setIsViewSelectedOnly(false);

        searchParams.delete('epsFilter');
        searchParams.delete('epsValue');
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, filterGroups]);

  if (isLoading || isRefetching) {
    return <S.LoadingSkeleton data-testid="earnings-loading" />;
  }

  const latest = data?.[0];

  const documentIds = documents?.map(doc => doc.id);

  return (
    <S.Root ref={containerRef}>
      <UnreportedEarnings latest={latest} />
      <S.FilterOptionsWrapper>
        <S.StyledToggle
          data-testid="view-selected-only-toggle"
          icon="Checkmark"
          isChecked={isViewSelectedOnly}
          onToggle={() => setIsViewSelectedOnly(!isViewSelectedOnly)}
          size="small"
          leftLabel={t('widget:earnings.viewSelectedOnly')}
        />
        <EarningsFilters />
      </S.FilterOptionsWrapper>
      {hasManualChanges && <EarningsManualEditOptions />}
      {!!Object.values(rowSelection).length && (
        <EarningsPriceMovementsChartAccordion
          rowSelection={rowSelection}
          entity={entity}
        />
      )}
      <EarningsTableWrapper
        isError={isError}
        isRefetchError={isRefetchError}
        refetch={refetch}
        assetName={entity.tag}
      />
      <EarningsChatInput
        renderChatInput={renderChatInput}
        documentIds={documentIds}
      />
    </S.Root>
  );
};
