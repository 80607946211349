import { PillDropdown, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { FlagIcon } from '~/components/flag-icon/FlagIcon';

export const CountryListItem = styled.li`
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 30% 70%;
  border-bottom: var(--border-soft) solid 1px;
  ${PillDropdown.Styled.Label} {
    display: flex;
    gap: 8px;
  }
  ${PillDropdown.Styled.PillDropdownRoot} {
    text-transform: capitalize;
    margin: 0 5px;
    justify-content: left;
  }
`;

export const StyledFlagIcon = styled(FlagIcon)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const PillDropdownWrapper = styled.div<{ $hasTooltip?: boolean }>`
  display: flex;
  align-items: center;

  ${PillDropdown.Styled.PillDropdownRoot} {
    ${({ $hasTooltip }) =>
      $hasTooltip &&
      css`
        &:hover,
        &:focus-visible {
          background-color: var(--surface-hover);
        }
      `}
  }
`;

export const StyledListDescription = styled.p`
  ${typographyV2.bodySm}
  display: flex;
  color: var(--text-soft);
  align-items: center;
  padding: 12px 4px;
`;
