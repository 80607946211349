import { useQuery } from '@tanstack/react-query';
import {
  earnings,
  EarningsFilterStatsRequest,
  EarningsFilterStatsResponse,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const QUERY_KEY_EARNINGS_FILTER_STATS = 'QUERY_KEY_EARNINGS_FILTER_STATS';

export const useEarningsFilterStats = (body: EarningsFilterStatsRequest) =>
  useQuery({
    queryKey: [QUERY_KEY_EARNINGS_FILTER_STATS, body],
    queryFn: ({ signal }) => {
      const { path, schema } = earnings.postEarningsFilterStats;
      return wretchRequest<EarningsFilterStatsResponse>(path, {
        method: 'post',
        body,
        schema,
        signal,
      });
    },
  });
