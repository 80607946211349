import { PriceDisplay, YAxisType } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  CreateChartOptions,
  CreateChartOptionsWithColors,
  CreateChartOptionsWithConfig,
} from '~/types/create.types';
import { SeriesType } from '~/types/series.types';
import { ChartAssetData, ChartInsight } from '~/types/timeseries.types';
import { COLLAPSED_PANE_HEIGHT, PANE_MAX_COUNT } from '~/utils/constants';
import { createCanvasContext } from '~/utils/createCanvasContext';

import { dispatchInsightsInDomainEvent } from '../events/events';
import { InitProps, setup } from './setup/setup';

interface ChartAPIProps {
  canvasElement: HTMLCanvasElement;
  options: CreateChartOptions;
}

export interface AddPaneProps {
  data: ChartAssetData[];
  priceDisplay: PriceDisplay;
  yAxisType: YAxisType;
  seriesType: SeriesType;
}

export interface ChartAPIReturn {
  init: (props: InitProps) => void;
  displayInsights: (insights: ChartInsight[]) => void;
  getBaseData: () => BaseChartAPIProps | undefined;
  removeListeners: () => void;
  reset: () => void;
}

const DEFAULT_CONFIG: CreateChartOptionsWithConfig['config'] = {
  enableZoom: true,
  enableHover: true,
  paneMaxCount: PANE_MAX_COUNT,
  collapsedPaneHeight: COLLAPSED_PANE_HEIGHT,
  hoverVariant: 'default',
  panePaddingTop: 0,
};

export const chartAPI = ({
  canvasElement,
  options,
}: ChartAPIProps): null | ChartAPIReturn => {
  const context = createCanvasContext(canvasElement, options);

  if (!context) {
    return null;
  }

  const optionsWithConfig: CreateChartOptionsWithColors = {
    ...options,
    config: {
      ...DEFAULT_CONFIG,
      ...options.config,
    },
    colors: options.colors,
  };
  const chartSetup = setup(canvasElement, optionsWithConfig, context);

  return {
    init: props => {
      chartSetup.initialiseData(props);
      chartSetup.setListeners(true);
    },
    displayInsights: insights => {
      const newBase = chartSetup.chartStore.getState().setInsights(insights);
      dispatchInsightsInDomainEvent(newBase);
    },
    getBaseData: () => {
      return chartSetup.chartStore.getState().base;
    },
    removeListeners: () => {
      chartSetup.setListeners(false);
    },
    reset: () => {
      chartSetup.setListeners(false);
      chartSetup.chartStore.setState({ base: undefined });
    },
  };
};
