import styled from 'styled-components';

export const Logo = styled.img`
  width: 103px;
`;

export const LogoMini = styled.img`
  height: 20px;
  width: 30px;
  position: absolute;
  left: 39px;
  top: 0;
  opacity: 0;
`;

export const SidebarLogotypeRoot = styled.div<{ $isExpanded: boolean }>`
  position: relative;
  min-width: 103px;
  height: 20px;
  pointer-events: none;

  ${Logo} {
    opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  }

  ${LogoMini} {
    opacity: ${({ $isExpanded }) => ($isExpanded ? 0 : 1)};
  }
`;
