import { Condition, ConditionType, Entity, formatTicker } from '@toggle/toggle';
import { t, TFunction } from 'i18next';
import { times } from 'number-precision';

import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';

export const DECIMAL_TO_PERCENTAGE_FACTOR = 100;
export const CUSTOM = 'custom';

export interface CheckIsDecimalValueProps {
  value?: number | string;
  conditionType?: ConditionType;
}

//TODO: Refactor function to improve handling of formating condition types
export const checkIsDecimalValue = ({
  conditionType,
  value,
}: CheckIsDecimalValueProps) => {
  const isValidConditionType =
    conditionType === ConditionType.RisesBy ||
    conditionType === ConditionType.FallsBy;
  const isValidValue = typeof value === 'number' && !isNaN(value);

  return isValidConditionType && isValidValue;
};

export const formatConditionValue = ({
  conditionType,
  value,
}: CheckIsDecimalValueProps) => {
  const isDecimalValue = checkIsDecimalValue({
    conditionType: conditionType,
    value,
  });

  return isDecimalValue && value !== undefined
    ? times(value, DECIMAL_TO_PERCENTAGE_FACTOR)
    : value;
};

export const generateConditionString = <T extends Condition>({
  t,
  conditions,
}: {
  t: TFunction;
  conditions?: T[];
}): string => {
  if (!conditions) {
    return '';
  }

  const formatCondition = ({
    condition,
    duration,
    duration_unit,
    value,
    max_value,
    min_value,
    entityName,
    formattedTicker,
    snakeName,
  }: Condition): string => {
    /* i18next-extract-mark-context-next-line  ["d", "w", "m", "y"] */
    const period = t(`scenarioCommander:domino.period`, {
      context: duration_unit,
      count: duration,
    });

    const formattedValue = formatConditionValue({
      conditionType: condition,
      value,
    });

    /* i18next-extract-mark-context-next-line  ["above", "below", "between", "crosses_above", "crosses_below", "falls_by", "falls_by_level", "rises_by", "rises_by_level"] */
    return t(`scenarioCommander:domino.conditions`, {
      context: condition,
      entityName,
      formattedTicker: `(${formattedTicker})`,
      snakeName,
      duration,
      period,
      value: formattedValue,
      max_value,
      min_value,
    });
  };

  const andLabel = t('scenarioCommander:domino.and');
  return conditions.map(formatCondition).join(`${andLabel}\n`);
};

export const updateConditionWithEntityDetails = (
  conditions: Condition[],
  entities?: Entity[]
): Condition[] =>
  conditions.map(condition => {
    const matchingEntity = entities?.find(
      entity => entity.tag === condition.entity
    );

    if (!matchingEntity) {
      return condition;
    }

    return {
      ...condition,
      entityName: matchingEntity.name,
      formattedTicker: formatTicker(matchingEntity),
    };
  });

const formatInterpolations = (selectedOption?: FilterOption) => {
  if (!selectedOption) {
    return '';
  }

  const { value: conditionObj } = selectedOption;
  const {
    condition,
    value,
    duration_unit: durationUnit,
    max_value: maxValue,
    min_value: minValue,
    duration,
  } = conditionObj as Condition;

  return {
    value: formatConditionValue({
      conditionType: condition,
      value,
    }),
    max_value: formatConditionValue({
      conditionType: condition,
      value: maxValue,
    }),
    min_value: formatConditionValue({
      conditionType: condition,
      value: minValue,
    }),
    period:
      durationUnit && duration
        ? /* i18next-extract-mark-context-next-line  ["d", "w", "m", "y"] */
          t(`scenarioCommander:domino.period`, {
            context: durationUnit,
            count: duration,
          })
        : undefined,
  };
};

export const generateConditionLabel = (condition?: FilterOption) => {
  if (!condition) {
    return null;
  }

  if (condition.name !== CUSTOM) {
    return condition.name;
  } else {
    /* i18next-extract-mark-context-next-line  ["above", "below", "between", "crosses_above", "crosses_below", "falls_by", "falls_by_level", "rises_by", "rises_by_level"] */
    return t(`scenarioCommander:domino.conditions_short`, {
      context: condition?.value.condition,
      ...formatInterpolations(condition),
    });
  }
};
