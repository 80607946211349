import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateConditionString } from '~/utils/condition-utils/condition-utils';

import { ConditionDropdown } from '../condition-dropdown/ConditionDropdown';
import { MappedConditions } from '../scenario-assistant-modal/use-scenario-assistant/enrichedConditions';
import { useScenarioAssistant } from '../scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';

export interface AssistantConditionsProps {
  onEditAssistantCondition?: (filterKey: string) => void;
}

export const AssistantConditions = ({
  onEditAssistantCondition,
}: AssistantConditionsProps) => {
  const { t } = useTranslation('scenario');
  const { conditions, deleteCustomCondition } = useScenarioAssistant(s => ({
    conditions: s.conditions.filter(c => c.confirmed && c.isValid && c.active),
    deleteCustomCondition: s.deleteCustomCondition,
  }));

  const genConditionLabel = (condition: MappedConditions) => {
    const { label, entityName, formattedTicker, snakeName } = condition;

    if (condition.label) {
      return t(`scenarioCommander:domino.conditionsWithCustomLabel`, {
        entityName,
        formattedTicker: `(${formattedTicker})`,
        snakeName,
        customLabel: label,
      });
    }

    return generateConditionString({
      t,
      conditions: [condition],
    });
  };

  return (
    <>
      {conditions.map(condition => (
        <ConditionDropdown
          key={condition.id}
          conditionLabel={
            genConditionLabel(condition) ?? t('scenario:customCondition')
          }
          onDelete={() => {
            deleteCustomCondition(condition.id);
          }}
          onEdit={() => {
            onEditAssistantCondition?.(condition.id);
          }}
        />
      ))}
    </>
  );
};
