import { flexRender, Header } from '@tanstack/react-table';
import {
  DropdownEntry,
  DropdownGroup,
  DropdownMenu,
  TableHeaderIcons,
} from '@toggle/design-system';
import { EarningsPeriod } from '@toggle/toggle';
import { Property } from 'csstype';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Earnings.styles';
import { useEarningsStore } from '../useEarningsStore/useEarningsStore';
import { EARNINGS_COLUMN_ID } from '../utils/utils';

export const EarningsTableHeaderCell = ({
  header,
  isLastHeaderGroup,
  isPinnedVertically,
  isHighlighted,
}: {
  header: Header<EarningsPeriod, unknown>;
  isLastHeaderGroup: boolean;
  isPinnedVertically: boolean;
  isHighlighted: boolean;
}) => {
  const { t } = useTranslation('widget');
  const { filterGroups, setActiveSortingState, setActiveModalFilter } =
    useEarningsStore();
  const columnId = header.column.id;
  const filter = filterGroups.EARNINGS_FILTERS.allFilters.find(
    filter => filter.key === columnId
  );

  const isFirstTableHeader = header.index === 0;
  const sortOptions: DropdownEntry[] = [
    {
      label: t('widget:earnings:table_ascending'),
      iconName: 'SortUp',
      key: 'sortUp',
      onClick: () =>
        setActiveSortingState([
          {
            id: header.column.id,
            desc: false,
          },
        ]),
    },
    {
      label: t('widget:earnings:table_descending'),
      iconName: 'SortDown',
      key: 'sortDown',
      onClick: () =>
        setActiveSortingState([
          {
            id: header.column.id,
            desc: true,
          },
        ]),
    },
  ];

  const filterOption: DropdownEntry[] = [
    {
      label: t('widget:earnings:filter'),
      iconName: 'Filter',
      key: 'filter',
      onClick: () => {
        if (filter) {
          setActiveModalFilter(filter);
        }
      },
    },
  ];

  const sortOrder = header.column.getIsSorted();

  const commonTableHeaderAttrs = {
    key: header.id,
    colSpan: header.colSpan,
    $highlight: isHighlighted,
  };

  const headerGroupAttrs = {
    alignment: (isFirstTableHeader ? 'left' : 'center') as Property.TextAlign,
  };

  const lastHeaderGroupAttrs = {
    alignment: (header.column.id === EARNINGS_COLUMN_ID
      ? 'left'
      : 'right') as Property.TextAlign,
    $left: header.column.getStart('left'),
    $right: header.column.getAfter('right'),
    $isLastPinnedCol: header.column.getIsLastColumn('left'),
    $isPinned: isPinnedVertically,
    $isSortable: header.column.getCanSort(),
    $isLastHeader: true,
  };

  const tableHeader = (
    <S.StyledTableHeader
      {...(isLastHeaderGroup ? lastHeaderGroupAttrs : headerGroupAttrs)}
      {...commonTableHeaderAttrs}
    >
      <TableHeaderIcons
        isFiltered={header.column.getIsFiltered()}
        sortDirection={sortOrder}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </TableHeaderIcons>
    </S.StyledTableHeader>
  );

  if (header.column.getCanSort()) {
    return (
      <DropdownMenu key={header.id} triggerNode={tableHeader}>
        <DropdownGroup items={sortOptions} />
        {filter && <DropdownGroup items={filterOption} />}
      </DropdownMenu>
    );
  }

  return tableHeader;
};
