export const getClosestDateIndex = (
  date: string,
  timestampArray: [string, number][]
) => {
  if (!timestampArray.length) {
    return undefined;
  }

  let startIdx = 0;
  let endIdx = timestampArray.length - 1;

  while (startIdx <= endIdx) {
    const midIdx = Math.floor((startIdx + endIdx) / 2);

    if (timestampArray[midIdx][0] === date) {
      return midIdx;
    } else if (timestampArray[midIdx][0] < date) {
      startIdx = midIdx + 1;
    } else {
      endIdx = midIdx - 1;
    }
  }

  return endIdx === -1 ? 0 : endIdx;
};
