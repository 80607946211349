import { StyledComponent } from '@toggle/design-system';
import { PriceStatus } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LivePriceInfo } from '../../hooks/use-live-price';
import * as S from './LivePriceView.styles';

export interface LivePriceViewProps {
  livePriceInfo?: LivePriceInfo;
  lastKnownPrice?: number;
  datePresentation?: string;
  className?: string;
  formatPrice?: (price: number, decimals?: number) => string;
  showMarketIndicator?: boolean;
  showPriceChange?: boolean;
  lastTimestamp?: number;
  keepLayout?: boolean;
}

export const LivePriceView: StyledComponent<LivePriceViewProps, typeof S> = ({
  livePriceInfo,
  lastKnownPrice,
  className,
  formatPrice,
  showMarketIndicator = true,
  showPriceChange = true,
  lastTimestamp,
  keepLayout = false,
}) => {
  const { t } = useTranslation('analyze');

  if ((!livePriceInfo && lastKnownPrice === undefined) || lastTimestamp === 0) {
    return null;
  }

  const livePrice =
    livePriceInfo?.lastKnownPrice !== undefined
      ? livePriceInfo.lastKnownPrice
      : lastKnownPrice;
  const price =
    livePriceInfo?.price !== undefined ? livePriceInfo.price : livePrice;

  const validPrice = price !== undefined;
  const validChange =
    livePriceInfo?.priceChange.status &&
    livePriceInfo?.priceChange.proportionChange;
  return (
    <S.LivePriceBox className={className} data-testid="live-price-view">
      {formatPrice && (
        <S.PriceContainer data-testid="price-container">
          {validPrice && (
            <S.LivePrice data-testid="price">{formatPrice(price)}</S.LivePrice>
          )}
        </S.PriceContainer>
      )}
      {validChange && (
        <S.PriceChange
          data-testid="price-change"
          $status={livePriceInfo.priceChange.status}
        >
          {showPriceChange && (
            <>
              <span data-testid="price-change-value">
                {livePriceInfo.priceChange.change}
              </span>
              <S.Divider $status={livePriceInfo.priceChange.status}>
                /
              </S.Divider>
            </>
          )}
          <span data-testid="price-change-proportion">
            {livePriceInfo.priceChange.proportionChange}
          </span>
        </S.PriceChange>
      )}
      {!validChange && keepLayout && (
        <S.PriceChange
          $status={PriceStatus.Default}
          data-testid="price-change-placeholder"
        />
      )}
      {showMarketIndicator && (
        <S.MarketIndicatorStyled
          isMarketOpen={!!livePriceInfo?.isLive}
          closeText={t('analyze:analysis.lastClose')}
          liveText={t('analyze:analysis.livePrice')}
        />
      )}
    </S.LivePriceBox>
  );
};

LivePriceView.Styled = S;
