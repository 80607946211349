import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const ContentBodyWrapper = styled.div`
  ${customScrollMinimal};
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex: 1 0 0;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const NoNewsFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
`;
