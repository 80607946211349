import { AssetClassMap, AssetClassName } from '@toggle/toggle';

import { useActions } from '~/stores/use-actions/useActions';
import { useChat } from '~/stores/use-chat/useChat';
import { createNewMessage } from '~/stores/use-chat/utils/updateMessage';

import { getInsightWidgetData } from '../insights/insights-service';
import { filterPredictions } from '../insights/utils/insight-utils';

const IDX_MAX = 126;

export interface CreateInsightMessageProps {
  insightId: string;
  title?: string;
  name?: string;
}

export const createInsightMessage = async ({
  insightId,
  title = '',
  name = '',
}: CreateInsightMessageProps) => {
  const { append, update } = useChat.getState();
  const { removeAllActions, setActions } = useActions.getState();

  const question = title || name;
  const message = createNewMessage({ status: 'pending', question });
  append(message);
  removeAllActions();

  const res = await getInsightWidgetData(insightId);

  if (res.error) {
    update({
      ...message,
      status: 'error',
    });

    return;
  }

  const { insight, entity } = res.data;

  const fullQuestion = name ? `${name}\n${insight.title}` : insight.title;
  update({
    ...message,
    question: fullQuestion,
    status: 'complete',
    reply: [
      {
        source: 'insight-widget',
        data: filterPredictions(res.data, IDX_MAX),
      },
    ],
  });

  setActions([
    {
      display: 'widget:chatPrompts.message_past-episodes-widget',
      action: 'past-episodes-widget:create-message',
      data: {
        variant: 'insight-past-episodes',
        insight: insight,
        entity: entity,
      },
    },
    {
      display: 'widget:chatPrompts.message_chat-quality-score',
      action: 'chat-quality-score:create-message',
      data: {
        isFixedIncome:
          AssetClassMap[insight.metadata.asset_cls] === AssetClassName.Fi,
        gaugeValue: insight.metadata.stars,
        horizon: insight.metadata.horizon,
        median: insight.metadata.median_return,
        checklist: insight.metadata.high_conviction_report,
      },
    },
  ]);
};
