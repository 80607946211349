import { alfred, Condition } from '@toggle/toggle';
import { TFunction } from 'i18next';
import { v4 } from 'uuid';

import { config } from '~/config';
import { create } from '~/stores/create-store/createStore';
import { apiStream, ApiStreamStatus } from '~/utils/api-stream/apiStream';
import { generateConditionString } from '~/utils/condition-utils/condition-utils';

import { validateCondition } from '../validate-condition/validateCondition';
import { enrichedConditions, MappedConditions } from './enrichedConditions';

export interface ConditionWithLabel extends Condition {
  label: string;
}

type ScenarioAssistantStore = {
  askedQuestion: string;
  editingConditionId?: string;
  sendMessage: (question: string) => Promise<void>;
  manuallyAddCustomCondition: (
    t: TFunction,
    condition: ConditionWithLabel
  ) => Promise<void>;
  resetConditions: () => void;
  deleteCustomCondition: (id: string) => void;
  updateCustomCondition: (condition: MappedConditions) => void;
  confirmConditions: () => void;
  runConfirmedConditions: () => void;
  toggleConditionState: (id: string) => void;
  resetAskedQuestions: () => void;
  editQuestion: (id?: MappedConditions['id']) => void;
  conditions: MappedConditions[];
  loading: boolean;
  error: boolean;
};

export const useScenarioAssistant = create<ScenarioAssistantStore>(set => ({
  askedQuestion: '',
  editingConditionId: undefined,
  conditions: [],
  loading: false,
  error: false,
  updateCustomCondition: condition => {
    set(state => ({
      conditions: state.conditions.map(c =>
        c.id === condition.id
          ? { ...condition, isValid: validateCondition(condition) }
          : c
      ),
      askedQuestion: condition.askedQuestion || state.askedQuestion,
    }));
  },
  confirmConditions: () => {
    set(state => ({
      conditions: state.conditions
        .filter(c => c.active)
        .map(c => ({
          ...c,
          confirmed: true,
          pendingRun: true,
        })),
    }));
  },
  runConfirmedConditions: () => {
    set(state => ({
      conditions: state.conditions.map(c => ({ ...c, pendingRun: false })),
    }));
  },
  toggleConditionState: id => {
    set(state => ({
      conditions: state.conditions.map(c =>
        c.id === id ? { ...c, active: !c.active } : c
      ),
    }));
  },

  deleteCustomCondition: id => {
    set(state => ({ conditions: state.conditions.filter(c => c.id !== id) }));
  },

  sendMessage: async (askedQuestion: string) => {
    const controller = new AbortController();
    const id = v4();

    set({ loading: true, askedQuestion });

    const onComplete = (status: ApiStreamStatus) => {
      if (
        status === ApiStreamStatus.Success ||
        status === ApiStreamStatus.Abort
      ) {
        set(state => ({
          loading: false,
          error: !state.conditions.length,
          editingConditionId: undefined,
        }));
      }

      if (status === ApiStreamStatus.Error) {
        set({ loading: false, error: true });
      }
    };

    const onReadChunk = async (
      reply: { data: { conditions: Condition[] } }[]
    ) => {
      if (reply.length) {
        const conditions = await Promise.all(
          reply[0].data.conditions.map(c =>
            enrichedConditions({ condition: c, askedQuestion })
          )
        );

        set(state => ({
          ...state,
          conditions: state.editingConditionId
            ? state.conditions.reduce<MappedConditions[]>((res, c) => {
                if (c.id === state.editingConditionId) {
                  res.push(...conditions);
                } else {
                  res.push(c);
                }

                return res;
              }, [])
            : [...state.conditions, ...conditions],
        }));
      }
    };

    apiStream({
      body: {
        question: askedQuestion,
        session_id: id,
        target: 'scenario-assistant.conditions',
      },
      onReadChunk,
      onComplete: () => onComplete(ApiStreamStatus.Success),
      onError: () => onComplete(ApiStreamStatus.Error),
      onAbort: () => onComplete(ApiStreamStatus.Abort),
      controller,
      endpoint: `${config.api.root}${alfred.streaming.path}`,
    });
  },
  manuallyAddCustomCondition: async (t, condition) => {
    const mappedCondition = await enrichedConditions({
      condition,
      isDefaultConfirmed: true,
    });
    const conditionString = generateConditionString({
      t,
      conditions: [mappedCondition],
    });
    set(state => ({
      conditions: [...state.conditions, mappedCondition],
      askedQuestion: conditionString,
    }));
  },
  resetConditions: () => {
    set({
      error: false,
      conditions: [],
    });
  },
  resetAskedQuestions: () => {
    set({ askedQuestion: '', error: false });
  },
  editQuestion: id => {
    set({ editingConditionId: id });
  },
}));
