import { AlertType, Button } from '@toggle/design-system';
import { mailTo } from '@toggle/helpers';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ActionLink } from '~/components/link/link.styles';
import { config } from '~/config';

import * as S from './ErrorView.styles';

type ErrorViewProps = {
  handleClose: () => void;
  handleEdit: () => void;
};

export const ErrorView = ({ handleClose, handleEdit }: ErrorViewProps) => {
  const { t } = useTranslation(['screener', 'common']);
  const supportEmail = mailTo(config.supportEmail);

  return (
    <S.Container data-testid="scenario-assistant-error">
      <S.ScenarioFilterError
        type={AlertType.Error}
        variant="inline"
        title={t('screener:error.alfredErrorTitle')}
        message={
          <Trans
            t={t}
            i18nKey={'screener:error.alfredErrorDescription'}
            components={[<ActionLink key="key-0" href={supportEmail} />]}
            values={{
              email: config.supportEmail,
            }}
          />
        }
      />

      <S.ButtonContainer>
        <Button
          variant="secondary"
          size="large"
          label={t('screener:editTextEntry')}
          onClick={handleEdit}
        />
        <Button
          variant="tertiary"
          size="large"
          label={t('common:cancel')}
          onClick={handleClose}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};
