import {
  CheckBox,
  Dropdown,
  DropdownList,
  Input,
  TableHeader,
} from '@toggle/design-system';
import styled from 'styled-components';

export const WatchlistDropdown = styled(Dropdown)`
  ${DropdownList.Styled.DropdownListRoot} {
    width: 288px;
    overflow: auto;
    max-height: 264px;
  }
`;

export const DropdownDivider = styled.hr`
  border: 1px solid var(--border-soft);
  margin: 4px 0;
`;

export const ItemRow = styled.div`
  padding: 8px;
`;

export const AddColumnTableHeader = styled(TableHeader)`
  padding: 0;
  position: sticky;
  right: 0;

  > span {
    justify-content: flex-end;
    margin-right: 2px;
  }
`;

export const SearchInput = styled(Input)`
  padding: 8px;
`;

export const DropdownCheckbox = styled(CheckBox)`
  label {
    width: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
`;
