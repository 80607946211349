import { StrictUnion } from '@toggle/helpers';
import {
  Class,
  Entity,
  isMappedEntity,
  mapEntity,
  MappedEntity,
} from '@toggle/toggle';
import React, { FC, useState } from 'react';

import { LogoImage } from './components/logo-image/LogoImage';
import { LogoPlaceholder } from './components/logo-placeholder/LogoPlaceholder';

export interface AssetLogoProps {
  entity: StrictUnion<Entity | MappedEntity>;
  isDarkTheme: boolean;
  className?: string;
  size?: number;
  withFallback?: boolean;
}

export const AssetLogo: FC<AssetLogoProps> = ({
  entity,
  size = 32,
  className,
  isDarkTheme,
  withFallback = true,
}) => {
  const [hasError, setHasError] = useState(false);
  const entityMapped = isMappedEntity(entity) ? entity : mapEntity(entity);
  const logoUrl = isDarkTheme
    ? entityMapped?.logo_urls.light_png
    : entityMapped?.logo_urls.dark_png;
  const isNotStockEntity =
    entityMapped.class !== Class.ClassStock &&
    entityMapped.class !== Class.ClassUnspecified;
  const showFallbackLogo = !logoUrl || hasError || isNotStockEntity;

  if (showFallbackLogo) {
    return withFallback ? (
      <LogoPlaceholder
        entity={entityMapped}
        className={className}
        size={size}
      />
    ) : null;
  }

  return (
    <LogoImage
      entity={entityMapped}
      className={className}
      logoUrl={logoUrl}
      onError={() => setHasError(true)}
      size={size}
    />
  );
};
