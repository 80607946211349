import {
  ColorScheme,
  DEFAULT_THEME,
  ThemeName,
  themes,
  ThemeVariant,
} from '@toggle/design-system';

export const isValidThemeName = (value: any): value is ThemeName => {
  return value in themes;
};

export const splitThemeName = (themeName: ThemeName) => {
  const parts = themeName.split('_');
  const colorScheme = parts[0] as ColorScheme;
  const themeVariant = parts[1] as ThemeVariant;

  return { colorScheme, themeVariant };
};

export const createThemeName = (
  colorScheme: ColorScheme,
  themeVariant: ThemeVariant
): ThemeName => `${colorScheme}_${themeVariant}`;

export const getValidThemeName = (themeName: unknown) => {
  if (isValidThemeName(themeName)) {
    return themeName;
  }

  return DEFAULT_THEME;
};

export const isDarkTheme = (themeName: ThemeName) => {
  const { colorScheme } = splitThemeName(themeName);

  return colorScheme === ColorScheme.Dark;
};
