import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const HorizonTitle = styled.span`
  ${typographyV2.uiXs};
  color: var(--text-default);
`;

export const HorizonValue = styled.span<{ $isNegative: boolean }>`
  ${typographyV2.uiSm};
  font-family: var(--font-roboto-mono);
  color: ${props =>
    props.$isNegative ? 'var(--viz-bearish)' : 'var(--viz-bullish)'};
`;

export const HorizonWrapper = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 20%;
  text-align: center;
  padding: 4px 0;
  border-radius: 4px;

  &:hover {
    background-color: ${props =>
      props.$isSelected ? 'var(--surface-default)' : 'var(--surface-hover)'};
    cursor: ${props => (props.$isSelected ? 'default' : 'pointer')};
  }

  ${props =>
    props.$isSelected &&
    `
        background-color: var(--surface-default);
    `}
`;
