import { FilterResults, PostFilterBody, screener } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

export const fetchFilteredEntitiesTagsOnly = (
  filters: PostFilterBody,
  signal?: AbortSignal
) =>
  wretchRequest<FilterResults>(screener.postFilter.path, {
    method: 'post',
    body: {
      filters,
      tags_only: true,
    },
    signal,
  });
