import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

type QueryParams<T> = {
  [K in keyof T]: string | null;
};

type SetQueryParams<T> = {
  [K in keyof T]?: string;
};

export const useQueryParams = <T extends Record<string, string>>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParams = useCallback((): QueryParams<T> => {
    const params: Partial<QueryParams<T>> = {};
    searchParams.forEach((value, key) => {
      params[key as keyof T] = value;
    });
    return params as QueryParams<T>;
  }, [searchParams]);

  const setQueryParams = useCallback(
    (params: SetQueryParams<T>, options?: { replace?: boolean }) => {
      const newParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });
      setSearchParams(newParams, options);
    },
    [searchParams, setSearchParams]
  );

  const deleteQueryParam = useCallback(
    (key: string | string[], options?: { replace?: boolean }) => {
      const keysToDelete = Array.isArray(key) ? key : [key];
      const newParams = new URLSearchParams(searchParams);
      keysToDelete.forEach(k => newParams.delete(k));
      setSearchParams(newParams, options);
    },
    [searchParams, setSearchParams]
  );

  return {
    params: getQueryParams(),
    setQueryParams,
    deleteQueryParam,
  };
};
