export enum KeyDevelopments {
  BuybackAndDividends = 'buybacks_and_dividends',
  CorporateGuidance = 'corporate_guidance',
  Earnings = 'earnings',
  ShareholderMeetingsAndCalls = 'shareholder_meetings_calls',
  LegalAndRegulatory = 'legal_and_regulatory',
  MergersAndAcquisitions = 'mergers_and_acquisitions',
  ManagementChanges = 'management_changes',
  CompanyAnnouncements = 'company_announcements',
  ListingChanges = 'listing_changes',
  InvestorActivism = 'investor_activism',
  CapitalMarketsActivity = 'capital_markets_activity',
}
