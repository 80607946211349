import { Entity } from '@toggle/toggle';

export enum ChartSearchType {
  Primary,
  Change,
  Compare,
}

export enum ChartSearchVariantType {
  Asset,
  Indicator,
}

export type SearchAssetPayload =
  | AssetPrimaryPayload
  | AssetComparePayload
  | AssetChangePayload;

interface AssetPrimaryPayload {
  type: ChartSearchType.Primary;
  asset: Entity;
}

interface AssetChangePayload {
  type: ChartSearchType.Change;
  asset: Entity;
  assetToChange: Entity;
}

/**
 * @deprecated used only on legacy webapp
 */
interface AssetComparePayload {
  type: ChartSearchType.Compare;
  asset: Entity;
}
