import { SkeletonLoader } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import { ViewMoreList } from '~/components/view-more-list/ViewMoreList';

import * as S from './KnowledgeGraphModule.styles';

export type KnowledgeGraphModuleProps = {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  title: string;
  tooltip: string;
  children: ReactNode;
  collapsedHeight: number;
};

export const KnowledgeGraphModule = ({
  isLoading,
  isError,
  isEmpty,
  title,
  tooltip,
  children,
  collapsedHeight,
}: KnowledgeGraphModuleProps) => {
  if (isLoading) {
    return (
      <SkeletonLoader
        areas={[
          {
            styles: {
              width: '100%',
              height: `${collapsedHeight}px`,
              borderRadius: '8px',
            },
          },
        ]}
      />
    );
  }

  if (isError || isEmpty) {
    return null;
  }

  return (
    <S.StyledKnowledgeGraphModuleRoot data-testid="knowledge-graph-module">
      <SectionTitle title={title} tooltip={tooltip} />
      <ViewMoreList height={collapsedHeight}>{children}</ViewMoreList>
    </S.StyledKnowledgeGraphModuleRoot>
  );
};
