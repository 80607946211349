import { ChartPane, HoverEvent } from '@toggle/chart';
import { Button, ButtonProps, Tooltip } from '@toggle/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './PaneActions.styles';

enum PaneActionType {
  MoveUp = 'up',
  MoveDown = 'down',
  Collapse = 'collapse',
  Maximize = 'maximize',
  Remove = 'remove',
}

interface PaneActionConfig extends ButtonProps {
  visible: boolean;
  actionType: PaneActionType;
}

export interface PaneActionsProps {
  chartPanes: ChartPane[];
  paneDetails?: HoverEvent['paneDetails'];
  onPaneMove: (direction: 'up' | 'down', index: number) => void;
  onPaneRemove: (id: string) => void;
  onPaneCollapse: (id: string) => void;
  onPaneMaximize: (id: string) => void;
}

export const PaneActions: FC<PaneActionsProps> = ({
  chartPanes,
  onPaneMove,
  onPaneCollapse,
  onPaneMaximize,
  onPaneRemove,
  paneDetails,
}) => {
  const { t } = useTranslation('chart');
  const maximizedPaneId = chartPanes.find(p => p.maximized)?.id;
  const paneId = maximizedPaneId ?? paneDetails?.pane.id;

  if (!paneDetails && !maximizedPaneId) {
    return null;
  }

  const nonCollapsedPanes = chartPanes.filter(p => !p.collapsed);

  const maximizeButton: PaneActionConfig[] = [
    {
      actionType: PaneActionType.Maximize,
      onClick: () => paneId && onPaneMaximize(paneId),
      variant: maximizedPaneId ? 'primary' : 'empty',
      iconName: 'Expand',
      visible: !!paneId,
    },
  ];

  const actions: PaneActionConfig[] =
    paneDetails && !maximizedPaneId
      ? [
          {
            actionType: PaneActionType.MoveUp,
            onClick: () => onPaneMove('up', paneDetails.index),
            iconName: 'ArrowUp',
            variant: 'empty',
            visible: paneDetails.index > 0,
          },
          {
            actionType: PaneActionType.MoveDown,
            onClick: () => onPaneMove('down', paneDetails.index),
            iconName: 'ArrowDown',
            variant: 'empty',
            visible: paneDetails.index < chartPanes.length - 1,
          },
          {
            actionType: PaneActionType.Collapse,
            onClick: () => onPaneCollapse(paneDetails.pane.id),
            iconName: 'Compress',
            variant: paneDetails.pane.collapsed ? 'primary' : 'empty',
            visible:
              nonCollapsedPanes.length > 1 ||
              nonCollapsedPanes[0].id !== paneDetails.pane.id,
          },
          ...maximizeButton,
          {
            actionType: PaneActionType.Remove,
            onClick: () => onPaneRemove(paneDetails.pane.id),
            variant: 'empty',
            iconName: 'Close',
            visible: !paneDetails.isPrimary,
          },
        ]
      : maximizeButton;

  return (
    <S.Container data-testid="pane-actions">
      {actions.map(action => {
        /* i18next-extract-mark-context-next-line  ["up", "down", "collapse", "maximize", "remove"] */
        const label = t('chart:action', {
          context: action.actionType,
        });
        return (
          action.visible && (
            <Tooltip label={label} inPortal key={action.actionType}>
              <Button
                onClick={action.onClick}
                aria-label={label}
                variant={action.variant}
                iconName={action.iconName}
                data-testid={`pane-${action.actionType}`}
              />
            </Tooltip>
          )
        );
      })}
    </S.Container>
  );
};
