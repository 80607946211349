import React, { useEffect, useRef } from 'react';

import * as S from './TextLabel.styles';

interface TextLabelProps {
  label?: string;
  x: number;
  y?: number;
  hasProfit?: boolean;
}

const BG_HEIGHT_PX = 24;
const BG_HEIGHT_HALF_PX = BG_HEIGHT_PX / 2;
const BG_PADDING_PX = 8;
const BG_BORDER_RADIUS_PX = 8;

export const TextLabel = ({ label, x, y, hasProfit }: TextLabelProps) => {
  const textRef = useRef<SVGTextElement>(null);
  const backgroundRef = useRef<SVGRectElement>(null);

  useEffect(() => {
    if (textRef.current && backgroundRef.current) {
      const textBoundingClientRect = textRef.current.getBoundingClientRect();
      const backgroundWidth = textBoundingClientRect.width + BG_PADDING_PX * 2;
      backgroundRef.current.setAttribute('width', backgroundWidth.toFixed());
    }
  }, [textRef.current, backgroundRef.current]);

  return (
    <g data-testid="svg-tooltip-label">
      <S.Rect
        ref={backgroundRef}
        $hasProfit={hasProfit}
        height={BG_HEIGHT_PX}
        y={y ?? -BG_HEIGHT_HALF_PX}
        x={x}
        rx={BG_BORDER_RADIUS_PX}
      />

      <S.Text
        ref={textRef}
        $hasProfit={hasProfit}
        alignmentBaseline="central"
        x={x + BG_PADDING_PX}
        y={y ? y + BG_HEIGHT_HALF_PX : undefined}
      >
        {label}
      </S.Text>
    </g>
  );
};
