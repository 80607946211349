import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: -24px;
  right: 16px;
  max-width: 215px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid var(--border-soft);
  background-color: var(--background-soft);
`;

export const Divider = styled.span`
  border: 1px solid var(--border-soft);
  height: 20px;
  width: 1px;
`;
