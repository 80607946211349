import React from 'react';

import { PriceDisplayReturn } from '~/components/price-display-view/helpers/price-display.types';

import {
  LegendPriceIcons,
  PriceIcon,
} from '../legend-price-icons/LegendPriceIcons';
import * as S from './LegendLivePrice.styles';

export interface LegendLivePriceProps {
  iconMapping: PriceIcon[];
  price: PriceDisplayReturn;
  legendColor: string;
  isPrimary: boolean;
  priceFormatter: (price: number) => string;
}

export const LegendLivePrice = ({
  iconMapping,
  price,
  legendColor,
  isPrimary,
  priceFormatter,
}: LegendLivePriceProps) => {
  return (
    <S.LegendPriceRoot data-testid="legend-live-price">
      {isPrimary &&
        iconMapping.map((icon, i) =>
          icon.shouldShow ? (
            <LegendPriceIcons key={i} iconInfo={icon} price={price} />
          ) : null
        )}

      {price.primary.price !== undefined && (
        <S.PriceContainer $color={legendColor}>
          {priceFormatter(price.primary.price)}
          {price.primary.priceChange && (
            <>
              <span>{price.primary.priceChange.change}</span>
              <span>({price.primary.priceChange.proportionChange})</span>
            </>
          )}
        </S.PriceContainer>
      )}
    </S.LegendPriceRoot>
  );
};

LegendLivePrice.styled = S;
