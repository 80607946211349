import { useQuery } from '@tanstack/react-query';
import { SkeletonLoader } from '@toggle/design-system';
import React from 'react';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { getInsightWidgetData } from '~/services/insights/insights-service';

import { InsightsDrawerContent } from './components/insights-drawer-content/InsightsDrawerContent';
import * as S from './InsightsDrawer.styles';

export type InsightsDrawerProps = {
  insightId: string;
  isOpen: boolean;
  closeDrawer: () => void;
  zIndex?: string;
};

export const InsightsDrawer = ({
  insightId,
  isOpen,
  closeDrawer,
  zIndex,
}: InsightsDrawerProps) => {
  // TODO: Investigate why cache is not working on this query
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [insightId],
    queryFn: () => getInsightWidgetData(insightId),
    enabled: !!insightId,
  });

  const getDrawerContent = () => {
    if (isLoading || isRefetching) {
      return <SkeletonLoader defaultView />;
    }

    if (data?.error || !data?.data) {
      return <ErrorMessageWrapper variant="empty" />;
    }

    return <InsightsDrawerContent insightsData={data.data} />;
  };

  return (
    <S.StyledDrawer
      data-testid="insights-drawer"
      isOpen={isOpen}
      handleCloseClick={closeDrawer}
      $zIndex={zIndex}
    >
      {getDrawerContent()}
    </S.StyledDrawer>
  );
};
