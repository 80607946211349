import { Button, Tooltip } from '@toggle/design-system';
import { useDisableScroll } from '@toggle/helpers';
import { DOW_JONES, SingleNews } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NewsBadges } from '~/components/news-badges/NewsBadges';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';

import * as S from './ArticleDrawer.styles';
import { SingleArticle } from './components/single-article/SingleArticle';
import * as Shared from './components/single-article/SingleArticle.styles';
import { useSingleNews } from './hooks/use-single-news/useSingleNews';

export interface ArticleDrawerProps {
  list?: SingleNews[];
  newsId: string;
  onClick?: (singleNews: SingleNews) => void;
  onClose: () => void;
}

export const ArticleDrawer = ({
  list = [],
  newsId,
  onClick,
  onClose,
}: ArticleDrawerProps) => {
  const { t } = useTranslation(['news', 'chart', 'analyze', 'countries']);
  const { navigateToPage } = useNavigateToAssetOverview();
  useDisableScroll();

  const navigateToAssetOverview = (entityTag: string) => {
    navigateToPage({ entityTag });
    onClose();
  };

  const initialNews = list.find(item => item.id === newsId);
  const { data, isError, isFetching, refetch, isLoading } = useSingleNews(
    newsId,
    initialNews
  );

  const newsIndex = list.findIndex(item => item.id === newsId);
  const nextArticle = list[newsIndex + 1];
  const prevArticle = list[newsIndex - 1];
  const hasFooter = !!nextArticle || !!prevArticle;

  const renderContent = () => {
    if (isLoading) {
      return <Shared.ContentSkeleton data-testid="loading-state" />;
    }

    if (isError && !isFetching) {
      return (
        <S.StyledErrorMessage
          data-testid="error-state"
          displayIcon
          variant="error"
          onClick={refetch}
        />
      );
    }

    if (!!data) {
      return (
        <SingleArticle
          article={data}
          isLoading={isFetching}
          onEntityClick={navigateToAssetOverview}
        />
      );
    }

    return null;
  };

  return (
    <S.StyledDrawer
      isOpen
      data-testid="article-drawer"
      handleCloseClick={onClose}
      headerButton={
        data && (
          <NewsBadges highlights={data.highlights} entityTags={data.entities} />
        )
      }
    >
      <S.Content $hasFooter={hasFooter}>
        {renderContent()}
        {data?.source === DOW_JONES && (
          <S.DowJonesDisclaimer>
            {t('news:dowJonesDisclaimer.dowJonesCopyright', {
              year: new Date().getFullYear(),
            })}
          </S.DowJonesDisclaimer>
        )}
      </S.Content>

      {hasFooter && (
        <S.Footer data-testid="article-drawer-footer">
          <div>
            {!!prevArticle && onClick && (
              <Tooltip label={prevArticle.title}>
                <Button
                  data-testid="prev-article"
                  label={t('news:drawer.prevArticle')}
                  variant="empty"
                  size="xsmall"
                  iconName="ArrowUp"
                  iconPosition="right"
                  onClick={() => onClick(prevArticle)}
                />
              </Tooltip>
            )}
          </div>

          <div>
            {!!nextArticle && onClick && (
              <Tooltip label={nextArticle.title}>
                <Button
                  data-testid="next-article"
                  label={t('news:drawer.nextArticle')}
                  variant="empty"
                  size="xsmall"
                  iconName="ArrowDown"
                  iconPosition="right"
                  onClick={() => onClick(nextArticle)}
                />
              </Tooltip>
            )}
          </div>
        </S.Footer>
      )}
    </S.StyledDrawer>
  );
};
