import { CustomFilter } from '@toggle/toggle';
import { TFunction } from 'i18next';

export const getCustomOil = (t: TFunction): CustomFilter => ({
  name: t('scenario:filters.conditions', {
    context: 'oil-prices',
  }),
  key: 'oil-prices',
  custom: [
    {
      type: 'oil',
      custom: [
        {
          name: 'CO:ICE',
          key: 'co_ice_fut_energy.price',
          custom: [],
        },
        {
          name: 'CL:NYMEX',
          key: 'cl_nymex_fut_energy.price',
          custom: [],
        },
        {
          name: 'USO:ARCA',
          key: 'uso_arca.price',
          custom: [],
        },
      ],
    },
  ],
});

export const getCustomInterest = (t: TFunction): CustomFilter => ({
  name: t('scenario:filters.conditions', {
    context: 'interest-rate',
  }),
  key: 'interest-rate',
  custom: [
    {
      type: 'currency',
      custom: [
        {
          name: 'US',
          key: 'sov_us_usd',
          custom: [],
        },
        {
          name: 'UK',
          key: 'sov_uk_gbp',
          custom: [],
        },
        {
          name: 'Japan',
          key: 'sov_jpn_jpy',
          custom: [],
        },
        {
          name: 'Germany',
          key: 'sov_deu_eur',
          custom: [],
        },
      ],
    },
    {
      type: 'horizon',
      custom: [
        {
          name: '2Y',
          key: '2y.yield',
          custom: [],
        },
        {
          name: '3Y',
          key: '3y.yield',
          custom: [],
        },
        {
          name: '5Y',
          key: '5y.yield',
          custom: [],
        },
        {
          name: '7Y',
          key: '7y.yield',
          custom: [],
        },
        {
          name: '10Y',
          key: '10y.yield',
          custom: [],
        },
        {
          name: '30Y',
          key: '30y.yield',
          custom: [],
        },
      ],
    },
  ],
});

export const headlineCPI = {
  name: 'Headline CPI',
  key: 'cpi_level.change_pct(horiz=1y)',
  custom: [],
};

export const coreCPI = {
  name: 'Core CPI',
  key: 'cpi_core_level.change_pct(horiz=1y)',
  custom: [],
};
export const inflationSnake = {
  type: 'snake',
  custom: [
    headlineCPI,
    coreCPI,
    {
      name: 'Headline PCE',
      key: 'pce_level.change_pct(horiz=1y)',
      custom: [],
    },
    {
      name: 'Core PCE',
      key: 'pce_core_level.change_pct(horiz=1y)',
      custom: [],
    },
  ],
};

export const countryUs = {
  name: 'US',
  key: 'us',
  custom: [],
};
export const countryUk = {
  name: 'UK',
  key: 'uk',
  custom: [],
};
export const countryJpn = {
  name: 'Japan',
  key: 'jpn',
  custom: [],
};
export const countryDeu = {
  name: 'Germany',
  key: 'deu',
  custom: [],
};

export const getCustomInflation = (t: TFunction): CustomFilter => ({
  name: t('scenario:filters.conditions', {
    context: 'inflation',
  }),
  key: 'inflation',
  custom: [
    {
      type: 'country',
      custom: [countryUs, countryUk, countryJpn, countryDeu],
    },
    inflationSnake,
  ],
});

export const getCustomIndexMoves = (t: TFunction): CustomFilter => ({
  name: t('scenario:filters.conditions', {
    context: 'index',
  }),
  key: 'index',
  custom: [
    {
      type: 'select',
      custom: [
        {
          name: 'S&P 500',
          key: 'spx.price',
          custom: [],
        },
        {
          name: 'FTSE 100',
          key: 'ukx.price',
          custom: [],
        },
        {
          name: 'Nikkei 225',
          key: 'nky.price',
          custom: [],
        },
        {
          name: 'DAX',
          key: 'dax.price',
          custom: [],
        },
      ],
    },
  ],
});
