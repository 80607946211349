import { RangeHorizon } from '@toggle/chart';
import { getPriceStatus } from '@toggle/helpers';
import { MappedEntity, TSDatum } from '@toggle/toggle';
import * as d3 from 'd3';
import React, { FC, useMemo } from 'react';

import { ChartPreviewDropdown } from '~/modules/chart-preview-dropdown/ChartPreviewDropdown';

import * as S from './ScreenerTableChart.styles';

export interface ScreenerTableChartProps {
  entity: MappedEntity;
  data: TSDatum[];
  width: number;
  height: number;
  horizon: RangeHorizon;
}

const CHART_PADDING_PX = 2;

export const ScreenerTableChart: FC<ScreenerTableChartProps> = ({
  entity,
  data,
  width,
  height,
  horizon,
}) => {
  const [plotLine, priceStatus] = useMemo(() => {
    const [yMin = 0, yMax = 1] = d3.extent(data, data => data.value);

    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([CHART_PADDING_PX, width - CHART_PADDING_PX]);

    const yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([height - CHART_PADDING_PX, CHART_PADDING_PX]);

    const plotLine = d3
      .line()
      .x(p => xScale(p[0]))
      .y(p => yScale(p[1]))
      .curve(d3.curveCardinal)(data.map((item, index) => [index, item.value]));
    const priceStatus = getPriceStatus(
      data[data.length - 1].value - data[0].value
    );

    return [plotLine, priceStatus];
  }, [data, width, height]);

  return (
    <ChartPreviewDropdown entity={entity} horizon={horizon}>
      <svg data-testid="screener-table-chart" width={width} height={height}>
        <S.PlotPath
          data-testid="chart-path"
          $status={priceStatus}
          d={plotLine ?? ''}
          strokeLinecap="round"
        />
      </svg>
    </ChartPreviewDropdown>
  );
};
