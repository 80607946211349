import { BandChart } from '@toggle/design-system';
import { CalculatorResponse } from '@toggle/toggle';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getHorizonToIndexMap } from '~/components/price-movement/components/price-movement-chart/utils/price-movement-chart-utils';
import {
  useThemeCompanyExposure,
  useThemePerformance,
} from '~/hooks/use-theme-overview/useThemeOverview';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';

import { DrawerSection } from '../drawer-section/DrawerSection';
import { HorizonSelector } from './horizon-selector/HorizonSelector';
import { OneDayReturnPill } from './one-day-return-pill/OneDayReturnPill';
import * as S from './ThemePerformanceSection.styles';

export type Horizon = '1W' | '1M' | '3M' | '6M' | '1Y';

type ThemePerformanceSectionProps = {
  customHeaderTitle?: ReactNode;
  customHeaderDescription?: ReactNode;
};

export const ThemePerformanceSection = ({
  customHeaderDescription,
  customHeaderTitle,
}: ThemePerformanceSectionProps) => {
  const { t } = useTranslation('widget');
  const { colors } = useChartTheme(['soft']);
  const themeId = useThemeOverviewStore(state => state.themeId);
  const {
    data: companiesData,
    isLoading: isCompaniesLoading,
    isError: isCompaniesError,
    isRefetching: isCompaniesRefetching,
    refetch: companiesRefetch,
  } = useThemeCompanyExposure(themeId);
  const [selectedHorizon, setSelectedHorizon] = useState<Horizon>('1Y');
  const horizonToIndexMap = getHorizonToIndexMap(1);

  const companies = companiesData ?? [];
  const companyTags = companies.map(company => company.entity.tag);
  const total = companies.length > 10 ? 10 : companies.length;

  const {
    data: themePerformanceData,
    isLoading: isThemePerformanceLoading,
    isError: isThemePerformanceError,
    refetch: themePerformanceRefetch,
    isRefetching: isThemePerformanceRefetching,
  } = useThemePerformance(
    companyTags,
    horizonToIndexMap.get(selectedHorizon.toLowerCase())
  );

  const refetch = () => {
    if (isCompaniesError) {
      companiesRefetch();
    }

    if (isThemePerformanceError) {
      themePerformanceRefetch();
    }
  };

  const formatData = (data?: CalculatorResponse) =>
    data?.aggregate.map((d, idx) => ({
      index: idx,
      value: d.value,
      date: new Date(d.index),
    })) ?? [];

  const handleHorizonChange = (horizon: Horizon) => {
    setSelectedHorizon(horizon);
  };

  return (
    <DrawerSection
      isLoading={
        isCompaniesLoading ||
        isCompaniesRefetching ||
        isThemePerformanceLoading ||
        isThemePerformanceRefetching
      }
      isError={isCompaniesError || isThemePerformanceError}
      isEmpty={!themePerformanceData?.aggregate.length}
      headerTitle={
        customHeaderTitle ?? t('themeOverview.themePerformanceTitle')
      }
      headerDescription={
        customHeaderDescription ??
        `${t('themeOverview.equalWeightedBasket')} ${t(
          'themeOverview.topCompanies',
          { total }
        )}`
      }
      refetch={refetch}
      rightHeaderNode={<OneDayReturnPill entityTags={companyTags} />}
    >
      <S.ChartAndHorizonsWrapper>
        <S.BandChartWrapper $color={colors.default.value}>
          <BandChart
            tsData={formatData(themePerformanceData)}
            showLine
            domainLimited={false}
            includeXScalePadding={false}
            isBordered
            fillColors={{
              positive: colors.threshold.bullish.value,
              negative: colors.threshold.bearish.value,
            }}
            isPercent
          />
        </S.BandChartWrapper>
        <HorizonSelector
          entityTags={companyTags}
          selectedHorizon={selectedHorizon}
          horizons={['1W', '1M', '3M', '6M', '1Y']}
          handleHorizonChange={handleHorizonChange}
          horizonToIndexMap={horizonToIndexMap}
        />
      </S.ChartAndHorizonsWrapper>
    </DrawerSection>
  );
};
