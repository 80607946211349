import { RangeHorizon } from '@toggle/chart';

import { TabsType } from '~/modules/tab-content/types';
import { queryKeys } from '~/routes/app-paths';

interface GetChartSubPageSearchParams {
  entityTag: string;
  prevSearchParams: URLSearchParams;
  horizon?: RangeHorizon;
  snakeType?: SnakeTypes;
}

export enum SnakeTypes {
  Price = 'price',
  ValuationMetrics = 'valuation-metrics',
}

export const getChartSubPageSearchParams = ({
  entityTag,
  prevSearchParams,
  horizon = RangeHorizon.OneYear,
  snakeType = SnakeTypes.Price,
}: GetChartSubPageSearchParams) => {
  const prevAsset =
    prevSearchParams.get(queryKeys.assetOverviewAsset) ?? entityTag;

  const searchParams = new URLSearchParams();
  searchParams.set(queryKeys.assetOverviewAsset, prevAsset);
  searchParams.set(queryKeys.assetOverviewTab, TabsType.Chart);
  searchParams.set('horizon', horizon);
  searchParams.set('snakeType', snakeType);

  return searchParams;
};
