import {
  ButtonGroup,
  SmartTable,
  SmartTableColumn,
  WithNAFallback,
} from '@toggle/design-system';
import { FinancialStatementSchema, Statement } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from '~/stores/use-app/useApp';

import { MiniBarChart, MiniChartData } from '../mini-bar-chart/MiniBarChart';
import * as S from './FinancialTable.styles';

export type FinancialTableProps = {
  data: FinancialStatementSchema;
  activeStatementButtonGroupIndex: number;
  handleStatementButtonGroupSelection: (i: number) => void;
  activeTimeframeButtonGroupIndex: number;
  handleTimeframeButtonGroupSelection: (i: number) => void;
};

interface FinancialTableRow {
  key: string;
  metric: string;
  [timePeriod: `period-${number}`]: string;
  values: MiniChartData[];
  indentation?: number;
  yAxisLabel?: string;
}

export const FinancialTable = ({
  data,
  activeStatementButtonGroupIndex,
  handleStatementButtonGroupSelection,
  activeTimeframeButtonGroupIndex,
  handleTimeframeButtonGroupSelection,
}: FinancialTableProps) => {
  const { t } = useTranslation('widget');
  const isSidebarExpanded = useApp(state => state.isSidebarExpanded);

  const columns: SmartTableColumn<FinancialTableRow>[] = [
    {
      label: t('widget:financials.dataProviderDetails'),
      key: 'metric',
      sticky: true,
      disableHiding: true,
      renderHeader: () => (
        <S.MetricColumnTitleLabelWrapper>
          <S.ColumnTitleLabel>
            {t('widget:financials.dataProviderDetails', {
              currency: data.metadata.currency,
            })}
          </S.ColumnTitleLabel>
        </S.MetricColumnTitleLabelWrapper>
      ),
      render(value, i) {
        return (
          <S.RowLabelWrapper>
            <S.RowLabel $indentation={i.indentation}>{value}</S.RowLabel>
            <S.BarChartWrapper>
              <MiniBarChart data={i.values} yAxisLabel={i.yAxisLabel} />
            </S.BarChartWrapper>
          </S.RowLabelWrapper>
        );
      },
    },
    ...data.columns.map((col, index) => {
      return {
        label: `${col.year} ${col.period}`,
        key: `period-${index}`,
        renderHeader: () =>
          !['LTM', 'NTM'].includes(col.period) ? (
            <S.MultilineColumnTitleWrapper>
              <S.ColumnTitleLabel>{col.year}</S.ColumnTitleLabel>
              <S.ColumnSubtitleLabel>{col.period}</S.ColumnSubtitleLabel>
            </S.MultilineColumnTitleWrapper>
          ) : (
            <S.ColumnTitleLabel>{col.period}</S.ColumnTitleLabel>
          ),
        render: (value: string) => {
          return (
            <WithNAFallback
              check={!!value}
              value={<S.NumericalValue>{value}</S.NumericalValue>}
            />
          );
        },
      } as SmartTableColumn<FinancialTableRow>;
    }),
  ];

  const handleRowData = (
    row: Statement,
    indentation?: number
  ): FinancialTableRow => {
    return {
      key: row.display,
      metric: row.display,
      ...row.values_fmt.reduce((a, c, i) => ({ ...a, [`period-${i}`]: c }), {}),
      indentation,
      yAxisLabel: row.value_type === 'percent' ? '%' : undefined,
      values: row.values.reduce((a, value, index) => {
        const current = data.columns[index];
        a.push({
          label: current.period,
          value: value || 0,
          year: current.year,
        });

        return a;
      }, [] as MiniChartData[]),
    };
  };

  const mapRows = (rows: Statement[], level = 0): FinancialTableRow[] => {
    const result: FinancialTableRow[] = [];
    rows.forEach(row => {
      result.push(handleRowData(row, level));

      if (row.subsections && row.subsections.length > 0) {
        row.subsections.forEach(subsection => {
          result.push(...mapRows([subsection], level + 1));
        });
      }
    });
    return result;
  };

  const shadedColumnIndices = data.columns
    .map((column, index) => (column.type === 'expected' ? index + 2 : null))
    .filter(index => index !== null);

  const rowData: FinancialTableRow[] = mapRows(data.rows);

  return (
    <S.AssetFinancialsRoot data-testid="financial-table">
      <S.ButtonGroupsWrapper>
        <div>
          <S.FinancialStatementStyledButtonGroup
            labels={[
              t('widget:financials.statementTypes.incomeStatement'),
              t('widget:financials.statementTypes.balanceSheet'),
              t('widget:financials.statementTypes.cashFlow'),
            ]}
            onClick={handleStatementButtonGroupSelection}
            activeIndex={activeStatementButtonGroupIndex}
            customActiveBackgroundColor={'var(--surface-hover)'}
          />
        </div>
        <div>
          <ButtonGroup
            labels={[
              t('widget:financials.timeFrames.annual'),
              t('widget:financials.timeFrames.quarterly'),
            ]}
            onClick={handleTimeframeButtonGroupSelection}
            activeIndex={activeTimeframeButtonGroupIndex}
          />
        </div>
      </S.ButtonGroupsWrapper>
      <S.FinancialsTableWrapper
        $isSidebarExpanded={isSidebarExpanded}
        $estimatedColumnIndices={shadedColumnIndices}
      >
        <SmartTable
          columns={columns}
          data={rowData}
          isHeaderSticky
          alignment="right"
        />
      </S.FinancialsTableWrapper>
    </S.AssetFinancialsRoot>
  );
};
