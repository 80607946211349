import React, { ReactNode, useState } from 'react';

import * as S from './ThemeLogoIcon.styles';

export const ThemeLogoIcon = ({
  name,
  logo_url,
  isCondensed = false,
  fallbackIcon,
}: {
  name: string;
  logo_url?: string;
  isCondensed: boolean;
  fallbackIcon?: ReactNode;
}) => {
  const [imageError, setImageError] = useState(false);

  return logo_url && !imageError ? (
    <S.StyledLogoImg
      src={logo_url}
      onError={() => setImageError(true)}
      $isCondensed={isCondensed}
    />
  ) : (
    <>
      {fallbackIcon ?? (
        <S.LogoFallback $isCondensed={isCondensed}>
          {name.charAt(0)}
        </S.LogoFallback>
      )}
    </>
  );
};
