import { RangeHorizon } from '@toggle/chart';
import { Icon, Tooltip } from '@toggle/design-system';
import { formatTicker } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LivePriceView } from '~/components/live-price-view/LivePriceView';
import { WatchlistTrigger } from '~/components/watchlist/watchlist-trigger/WatchlistTrigger';
import { useEntity } from '~/hooks/use-entities';
import { useLivePrice } from '~/hooks/use-live-price';
import { mapEntityToPriceEntity } from '~/hooks/use-live-price/use-live-price-store/useLivePriceStore.utils';
import { useSnakeMeta } from '~/hooks/use-snake/useSnake';
import { ChartPreviewDropdown } from '~/modules/chart-preview-dropdown/ChartPreviewDropdown';

import * as S from './AssetChip.styles';

export interface AssetChipProps {
  entityTag: string;
  onEntityClick(entityTag: string): void;
}

export const AssetChip = ({ entityTag, onEntityClick }: AssetChipProps) => {
  const { t } = useTranslation('news');
  const { data } = useEntity(entityTag);
  const entity = data?.data;

  const { data: snakeMeta } = useSnakeMeta(entity?.default_snake);

  const priceEntities = mapEntityToPriceEntity(entity, snakeMeta);
  const livePriceState = useLivePrice(priceEntities);

  if (!entity) {
    return null;
  }

  return (
    <S.AssetChipRoot data-testid="asset-chip">
      <ChartPreviewDropdown
        horizon={RangeHorizon.OneMonth}
        enableHover={false}
        entity={entity}
        side="bottom"
        align="end"
        snakeMeta={snakeMeta}
      >
        <S.EntityButton
          data-testid="entity-button"
          onClick={() => onEntityClick(entityTag)}
        >
          <S.Entity>
            <S.StyledAssetLogoContainer entity={entity} size={16} />
            <S.Ticker data-testid="asset-ticker">
              {formatTicker(entity)}
            </S.Ticker>
          </S.Entity>

          <LivePriceView
            livePriceInfo={livePriceState[entity.ticker]}
            lastTimestamp={snakeMeta?.last_timestamp}
            showMarketIndicator={false}
            showPriceChange={false}
            keepLayout
          />
        </S.EntityButton>
      </ChartPreviewDropdown>

      <WatchlistTrigger
        tag={entityTag}
        dropdownProps={{
          strategy: 'fixed',
          placement: 'top-start',
          inPortal: false,
        }}
        renderTrigger={isInWatchlist => (
          <span>
            <Tooltip
              label={t('news:tooltips.watchlistButton')}
              inPortal
              offsetX={-3}
            >
              <S.WatchlistButton
                data-testid="watchlist-button"
                $isActive={isInWatchlist}
              >
                <S.IconWrapper>
                  <Icon
                    iconName={isInWatchlist ? 'Checkmark' : 'Add'}
                    size={12}
                  />
                </S.IconWrapper>
              </S.WatchlistButton>
            </Tooltip>
          </span>
        )}
      />
    </S.AssetChipRoot>
  );
};
