import { z } from 'zod';

export const MOST_READ = 'most_read';
export const BREAKING = 'breaking';

export const BENZINGA = 'benzinga';
export const MTN = 'mtn';
export const DOW_JONES = 'dowjones';

const highlightsSchema = z.union([z.literal(MOST_READ), z.literal(BREAKING)]);

export const newsSchema = z.object({
  id: z.string(),
  title: z.string(),
  body: z.string().optional(),
  entities: z.array(z.string()),
  highlights: z.array(highlightsSchema).optional(),
  source: z.union([z.literal(BENZINGA), z.literal(MTN), z.literal(DOW_JONES)]),
  published: z.string(),
});
export const newsList = z.array(newsSchema);
export const newsListSchema = z.object({
  news: z.array(newsSchema),
});

const dateRangeSchema = z.object({
  from: z.string().optional(),
  to: z.string().optional(),
});

const searchFiltersSchema = z
  .object({
    highlights: z.array(highlightsSchema).optional(),
    date_range: dateRangeSchema.optional(),
  })
  .catchall(z.array(z.string()));

const pageSchema = z.object({
  number: z.number().optional(),
  size: z.number().optional(),
});

const sortSchema = z.object({
  field: z.union([z.literal('score'), z.literal('published')]).optional(),
  desc: z.boolean().optional(),
});

const searchRequestSchema = z.object({
  query: z.string(),
  page: pageSchema.optional(),
  sort_by: sortSchema.optional(),
  filters: searchFiltersSchema.optional(),
});

const marketsFilter = z.object({
  name: z.string(),
  key: z.string(),
  featured: z.boolean(),
});
const sourceFilter = z.object({
  name: z.string(),
  key: z.string(),
});

const newsFilterOption = z.object({
  name: z.string(),
  key: z.string(),
  visible: z.boolean(),
});

const newsFilterSchema = z.object({
  key: z.string(),
  name: z.string(),
  config: z.object({
    options: z.array(newsFilterOption),
  }),
});

export const newsFiltersSchema = z.array(newsFilterSchema);

export type NewsFilterOption = z.infer<typeof newsFilterOption>;

export type SingleNews = z.infer<typeof newsSchema>;
export type NewsList = z.infer<typeof newsList>;
export type NewsListResponse = z.infer<typeof newsListSchema>;
export type SearchRequest = z.infer<typeof searchRequestSchema>;
export type SortOption = z.infer<typeof sortSchema>;
export type SearchFilters = z.infer<typeof searchFiltersSchema>;
export type MarketsFilter = z.infer<typeof marketsFilter>;
export type SourceFilter = z.infer<typeof sourceFilter>;
export type NewsFilters = z.infer<typeof newsFiltersSchema>;
export type Highlights = z.infer<typeof highlightsSchema>;

export const news = {
  newsSearch: {
    path: 'news/v4/search',
    schema: newsListSchema,
  },
  singleNews: {
    path: (id: string) => `news/v3/${id}`,
    schema: newsSchema,
  },

  filtersV4: {
    path: 'news/v4/filters',
    schema: newsFiltersSchema,
  },
} as const;
