import React, { createRef, RefObject, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as styles from '~/components/toasts-layout/ToastsLayout.styled';
import { Toast, useToasts } from '~/stores/use-toasts/useToasts';
import { CONVERSATION_TOAST } from '~/views/reasoner/use-notification-toast/useNotificationToast';

const SCOPES_TO_CLEAR = [CONVERSATION_TOAST];

const { offsetBetweenToasts, toastTransitionDuration, transitionClass, ...S } =
  styles;

export const ToastsLayout = () => {
  const { toasts, clearScopedToasts } = useToasts(state => ({
    toasts: state.toasts,
    clearScopedToasts: state.clearScopedToasts,
  }));
  const refs = useRef<Record<string, RefObject<HTMLDivElement | null>>>({});

  toasts.forEach(t => {
    refs.current[t.id] = refs.current[t.id] || createRef();
  });

  const yOffsets = toasts.map((toast: Toast, toastIndex: number) =>
    toasts.slice(0, toastIndex).reduce((offsetY, t, nodeIndex) => {
      const ref = refs.current[t.id];

      if (ref.current && toast.position === toasts[nodeIndex].position) {
        return offsetY + ref.current.clientHeight + offsetBetweenToasts;
      }

      return offsetY;
    }, 0)
  );

  const onExited = (id: Toast['id']) => () => {
    delete refs.current[id];
  };

  const filteredScopes = SCOPES_TO_CLEAR.filter(scope =>
    toasts.some(toast => toast.scope === scope)
  );

  const handleScopedToastsClose = () => {
    filteredScopes.forEach(scope => clearScopedToasts(scope));
  };

  return (
    <S.ToastsWrapper
      data-testid="toasts-wrapper"
      onClick={
        filteredScopes.length ? () => handleScopedToastsClose() : undefined
      }
    >
      <TransitionGroup component={null}>
        {toasts.map((toast, toastIndex) => (
          <CSSTransition
            key={toast.id}
            id={toast.id}
            timeout={toastTransitionDuration}
            classNames={transitionClass}
            nodeRef={refs.current[toast.id]}
            onExited={onExited(toast.id)}
          >
            <S.Toast
              key={toast.id}
              ref={refs.current[toast.id]}
              $offsetY={yOffsets[toastIndex]}
              $position={toast.position}
              data-testid="global-toast"
            >
              {toast.content}
            </S.Toast>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </S.ToastsWrapper>
  );
};
