import { Icon, SvgIconNames, Toggle } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './HighlightsFilters.styles';

export interface HighlightsFiltersProps {
  isFetchMostRead: boolean;
  toggleIsFetchMostRead: (nextValue: boolean) => void;
}

export const HighlightsFilters = ({
  isFetchMostRead,
  toggleIsFetchMostRead,
}: HighlightsFiltersProps) => {
  const { t } = useTranslation(['news']);

  const toggleFilters = [
    {
      icon: 'Trending' as SvgIconNames,
      label: t('news:highlightsFilters.mostRead'),
      value: isFetchMostRead,
      toggleFn: toggleIsFetchMostRead,
      testId: 'toggle-btn-most-read',
    },
  ];

  return (
    <S.HighlightsFiltersRoot>
      <S.Header>{t('news:highlights')}</S.Header>
      <div>
        {toggleFilters.map(({ icon, label, value, toggleFn, testId }) => {
          return (
            <S.Toggle key={label}>
              <S.LabelWrapper>
                <Icon size={14} iconName={icon} fillColor="var(--text-soft)" />
                <S.Label>{label}</S.Label>
              </S.LabelWrapper>
              <Toggle
                iconVisibility="none"
                isChecked={value}
                onToggle={toggleFn}
                data-testid={testId}
              />
            </S.Toggle>
          );
        })}
      </div>
    </S.HighlightsFiltersRoot>
  );
};
