import styled, { css } from 'styled-components';

export const underlineTextStyles = css`
  text-decoration: underline dashed;
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
  text-decoration-color: var(--border-default);
`;

export const UnderlineText = styled.div`
  display: inline-block;
  ${underlineTextStyles};
`;
