import * as d3 from 'd3';
import React, { FC, useEffect, useRef } from 'react';

import { humanFriendlyTicks } from '~/charts/helpers/human-friendly-ticks/human-friendly-ticks';

import * as S from './HorizontalGridLines.styles';

interface HorizontalGridLinesProps {
  yScale: d3.AxisScale<number>;
  tickSize: number;
  tickCount?: number;
  format?: (n: number, fractionDigit?: number) => string;
  stateCallback?: (ticks: number[]) => void;
  tickVariant?: 'domain' | 'nice';
  isBordered?: boolean;
  yAxisLabel?: string;
  rightAxisWidth: number;
}

export const HorizontalGridLines: FC<HorizontalGridLinesProps> = ({
  yScale,
  tickSize,
  tickCount = 5,
  format = d3.format('.0%'),
  stateCallback,
  tickVariant = 'domain',
  isBordered = false,
  yAxisLabel,
  rightAxisWidth,
}) => {
  const gRef = useRef<SVGGElement>(null);
  const [start, stop] = yScale.domain();

  useEffect(() => {
    if (!gRef.current) {
      return;
    }

    const axisGroup = d3.select(gRef.current);
    const tickScale =
      tickVariant === 'domain'
        ? [start, stop]
        : d3.nice(start, stop, tickCount);

    let { ticks, step } = humanFriendlyTicks(tickScale, tickCount);

    if (isBordered) {
      ticks = ticks.concat([start, stop]);
    }

    let fractionDigits = 0;

    if (step * 100 < 0.1) {
      fractionDigits = 2;
    } else if (step * 100 < 1) {
      fractionDigits = 1;
    }

    stateCallback?.(ticks);
    const preciseFormat = (val: number) => format(val, fractionDigits);
    axisGroup.call(
      d3
        .axisRight(yScale)
        .tickValues(ticks)
        .tickSize(-tickSize)
        .tickFormat(preciseFormat)
        .tickPadding(10)
    );
    axisGroup.selectAll('.domain').remove();
  }, [tickSize, yScale]);

  return (
    <g>
      <S.GridLinesGroup ref={gRef} />
      <g transform={`translate(${rightAxisWidth}, ${yScale.range()[0] / 2})`}>
        <S.YAxisLabel dy={'-1.5em'}>{yAxisLabel}</S.YAxisLabel>
      </g>
    </g>
  );
};
