import {
  Table,
  TableCell,
  TableHeader,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
`;

export const Title = styled.h2`
  ${typographyV2.titleMd}
  padding: 8px 0px;
  border-bottom: 1px solid var(--border-soft);
`;

export const StyledTable = styled(Table)`
  padding: 0;

  > td,
  th {
    padding: 12px;
  }
`;

export const StyledTableHeader = styled(TableHeader)`
  white-space: normal;
  background-color: var(--background-soft);
`;

export const StyledTableCell = styled(TableCell)`
  background-color: var(--background-soft);
`;

export const StyledRowHeaderLabel = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
