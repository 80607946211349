import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 8px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const Img = styled.img`
  margin-bottom: 4px;
`;

export const Title = styled.p`
  ${typographyV2.titleBase};
  text-align: center;
`;

export const Message = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  max-width: 420px;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  max-width: 113px;
  padding: 6px 12px 6px 12px;
`;
