import { FC } from 'react';
import styled, { css } from 'styled-components';

import { Icon, IconProps } from '~/components/icon/Icon';

export const FilterIcon = styled(Icon).attrs({ size: 10 })`
  fill: var(--icon-disabled);
`;

export const PinIcon = styled(Icon).attrs({ size: 10 })`
  fill: var(--icon-disabled);
`;

export const SortIcon = styled<FC<IconProps & { $isSortAsc: boolean }>>(
  Icon
).attrs(props => ({
  iconName: props.$isSortAsc ? 'SortAscLeft' : 'SortDescLeft',
  size: 12,
}))`
  fill: var(--icon-default);
`;

export const TableHeaderIconsRoot = styled.span<{
  $isReversed: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: row;

  ${({ $isReversed }) =>
    $isReversed &&
    css`
      flex-direction: row-reverse;

      ${SortIcon} {
        transform: scaleX(-1);
      }
    `}
`;
