import styled, { css } from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const MainLabel = styled.p<{ $isActive: boolean }>`
  ${typographyV2.bodySm};
  color: var(--text-default);
  flex: 1;
  margin: ${({ $isActive }) => ($isActive ? '0 8px' : '0 8px 0 0')};
`;

export const Item = styled.div<{
  $isFocused?: boolean;
  $isCondensed?: boolean;
  $isDisabled?: boolean;
  $isActive?: boolean;
}>`
  position: relative;
  ${borderRadius.r1};
  display: flex;
  align-items: center;
  padding: ${({ $isCondensed }) => ($isCondensed ? '6px 8px' : '8px')};
  margin: ${({ $isActive }) => ($isActive ? '8px 0 0 0' : '8px 0px 0px 8px')};
  cursor: ${({ $isDisabled }) => !$isDisabled && 'pointer'};

  &:last-child {
    margin-bottom: 8px;
  }

  &:hover,
  &:focus-visible {
    background-color: var(--surface-hover);
  }

  ${({ $isFocused }) =>
    $isFocused &&
    `
      background-color: var(--surface-hover);
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: var(--surface-pressed);

      &::before {
        content: '';
        position: absolute;
        left: 0px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        height: 36px;
        width: 4px;
        background-color: var(--border-primary);
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
      & svg {
        fill: var(--icon-disabled);
      }

      & ${MainLabel} {
        color: var(--text-disabled);
      }
    `}
`;

export const LeadingIconContainer = styled.div<{ $isActive: boolean }>`
  width: 20px;
  height: 20px;
  margin: ${({ $isActive }) => ($isActive ? '0 0 0 8px' : '0 8px 0 0')};
  display: flex;
  align-items: center;
`;

export const TrailingIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
`;

export const SupportiveText = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  text-align: right;
  margin-left: 8px;
`;

export const LoadingContainer = styled.li`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: var(--border-soft);
  border: none;
`;
