import { onMouseLeave } from '~/core/mouse-events/onMouseLeave';
import {
  onMouseDown,
  onMouseMove,
  onMouseUp,
} from '~/core/mouse-events/onMouseMove';
import { onMouseRightClick } from '~/core/mouse-events/onMouseRightClick';
import { initZoom, removeZoom } from '~/core/zoom/zoom';
import { CreateChartOptionsWithConfig } from '~/types/create.types';

import { ChartStoreReturn } from '../chart-store/chartStore';

export const listenerModel = ({
  chartStore,
  canvasElement,
  options,
}: {
  chartStore: ChartStoreReturn;
  canvasElement: HTMLCanvasElement;
  options: CreateChartOptionsWithConfig;
}) => {
  const mouseMove = (event: MouseEvent) => onMouseMove(event, chartStore);
  const mouseLeave = () => onMouseLeave(chartStore);
  const mouseDown = (event: MouseEvent) => onMouseDown(event);
  const mouseUp = onMouseUp;

  const mouseRightClick = (event: MouseEvent) =>
    onMouseRightClick(event, chartStore);

  const setListeners = (isSet: boolean) => {
    if (isSet) {
      if (options.config.enableZoom) {
        initZoom(canvasElement, chartStore);
      }

      if (options.config.enableHover) {
        canvasElement.addEventListener('mousemove', mouseMove);
        canvasElement.addEventListener('mouseleave', mouseLeave);
        canvasElement.addEventListener('mousedown', mouseDown);
        canvasElement.addEventListener('mouseup', mouseUp);
      }

      canvasElement.addEventListener('contextmenu', mouseRightClick);
      return;
    }

    if (options.config.enableHover) {
      canvasElement.removeEventListener('mousemove', mouseMove);
      canvasElement.removeEventListener('mouseleave', mouseLeave);
      canvasElement.removeEventListener('mousedown', mouseDown);
      canvasElement.removeEventListener('mouseup', mouseUp);
    }

    canvasElement.removeEventListener('contextmenu', mouseRightClick);

    if (options.config.enableZoom) {
      removeZoom(chartStore);
    }
  };

  return { setListeners };
};
