import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Container = styled.div`
  min-width: 180px;
  padding: 12px;
  background: var(--surface-default);
  ${borderRadius.r1}
  box-shadow: var(--elevation-strong);
  pointer-events: none;
`;

export const Title = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  margin-bottom: 8px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  ${typographyV2.bodyXs};
`;
