import {
  Accordion,
  AccordionItem,
  AccordionProps,
} from '@toggle/design-system';
import type { HighConvictionReport } from '@toggle/toggle';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './AssessmentAccordion.style';

export interface AssessmentAccordionProps
  extends Omit<AccordionProps, 'items'> {
  title: string;
  items: Partial<HighConvictionReport>;
  showHasValue?: boolean;
}

export const AssessmentAccordion: FC<AssessmentAccordionProps> = ({
  items,
  title,
  showHasValue = false,
}) => {
  const { t } = useTranslation('common');

  const AssessmentItems = useMemo((): AccordionItem[] => {
    return Object.entries(items).map(([qualityKey, value]) => ({
      /* i18next-extract-disable */
      title: t(`common:qualityChecklist.labels.${qualityKey}`),
      content: t(`common:qualityChecklist.tooltips.${qualityKey}`),
      titleIcon: value ? 'StarFill' : 'StarEmpty',
      value: value ? (
        <S.StyledExplicitValue $isBullish>
          {t(`common:qualityChecklist.labels.yes`)}
        </S.StyledExplicitValue>
      ) : (
        <S.StyledExplicitValue>
          {t(`common:qualityChecklist.labels.no`)}
        </S.StyledExplicitValue>
      ),
      showHasValue,
    }));
  }, [items, t]);

  return (
    <S.AssessmentResultWrapper>
      <S.ResultTitle data-testid="assessment-accordion-title">
        {title}
      </S.ResultTitle>
      <Accordion items={AssessmentItems} variant="secondary" />
    </S.AssessmentResultWrapper>
  );
};
