import { Button, EntityDetails } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';

export const StyledEntityDetails = styled(EntityDetails)<{
  isCondensed: boolean;
}>`
  margin: 0px 0px 16px 0px;
  ${EntityDetails.Styles.ContentWrapper} {
    flex-direction: column;

    ${({ isCondensed }) =>
      isCondensed &&
      css`
        margin: 8px 0;
      `}
  }
`;

export const WatchlistButton = styled(Button)<{
  $isActive?: boolean;
  $hasHeaderButton?: boolean;
}>`
  justify-self: flex-end;
  color: ${props =>
    props.$isActive ? 'var(--text-success)' : 'var(--text-default)'};
  border-color: ${props =>
    props.$isActive ? 'var(--border-success)' : 'var(--border-default)'};
  svg {
    fill: ${props =>
      props.$isActive ? 'var(--icon-success)' : 'var(--icon-default)'};
  }
  ${({ $hasHeaderButton }) =>
    $hasHeaderButton &&
    css`
      border-radius: 100%;
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)<{
  $isCondensed: boolean;
  $isDrawer?: boolean;
}>`
  border-radius: ${({ $isCondensed, $isDrawer }) =>
    $isCondensed ? '9999px' : $isDrawer ? '50%' : '0px'};
`;
