import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const LegendRoot = styled.div`
  box-shadow: var(--elevation-strong);
  background: var(--surface-default);
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  border-radius: 4px;
  min-width: 240px;
`;

export const Title = styled.p`
  ${typographyV2.titleSm};
  margin-bottom: 8px;
  padding: 0 4px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div<{ $isHovered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  ${props =>
    props.$isHovered &&
    css`
      background-color: var(--surface-active);
      border-radius: 4px;
    `};
`;

export const Asset = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const Dot = styled.div<{ $color?: string }>`
  width: 8px;
  height: 8px;
  background-color: ${props => (props.$color ? props.$color : 'white')};
  border-radius: 50%;
`;

export const Label = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-default);
`;

export const Value = styled.span`
  font-family: var(--font-roboto-mono);
  ${typographyV2.bodyXs};
  color: var(--text-default);
`;
