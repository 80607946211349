export const webappPaths = {
  login: '/login',
};

export const authPaths = {
  login: '/login',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

export const onHoldReason = {
  email: 'email',
  subscription: 'subscription',
  payment: 'payment',
  trial: 'trial',
};

export const appPaths = {
  base: '/app',
  watchlistManager: '/app/watchlist-manager',
  chart: '/app/chart',
  alfred: '/app/alfred',
  screener: '/app/screener',
  scenario: '/app/scenario',
  insights: '/app/insights',
  reasoner: '/app/2-alfred',
  news: '/app/news',
  settings: {
    base: '/app/settings',
    subscription: '/app/settings/subscription',
    account: '/app/settings/account',
    appearance: '/app/settings/appearance',
    portfolio: '/app/settings/portfolio',
  },
  verifyEmail: '/app/verify-email',
  accountOnHold: '/app/account-on-hold',
  error: '/app/error',
  notFound: '/app/*',
  website: '/',
  assetOverview: '/app/asset-overview',
  themeOverview: '/app/theme',
  watchlist: '/app/watchlist',
  events: '/app/events',
  ib: '/app/ib',
};

export const accountRoutes = {
  checkout: 'checkout',
  billingInformation: 'billing-information',
};

export const queryKeys = {
  activeCalendarTab: 'activeTab',
  activeCalendarDate: 'activeDate',
  assetOverviewAsset: 'asset',
  assetOverviewTab: 'tab',
  assetOverviewSectionId: 'sectionId',
  emailReset: 'emailToReset',
  history: 'history',
  insightId: 'insightId',
  insightTitle: 'insightTitle',
  insightName: 'insightName',
  limited: 'limited',
  mode: 'mode',
  promoCode: 'promo_code',
  redirect: 'redirectTo',
  searchNews: 'search',
  view: 'view',
  widget: 'widget',
  entityTag: 'entityTag',
  activeInsightId: 'activeInsightId',
  activeInsightHorizon: 'activeInsightHorizon',
  conversationId: 'conversationId',
} as const;
