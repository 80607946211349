import React from 'react';

import { Filters } from '~/components/filters/Filters';
import {
  EarningFilterGroupKeys,
  FilterState,
} from '~/hooks/use-filter-actions/useFilterActions';

import { useEarningsStore } from '../../useEarningsStore/useEarningsStore';
import { ResetAllButton } from '../filters/reset-all-button/ResetAllButton';

export const EarningsFilters = () => {
  const { resetAllFilterOptions, filterGroups, onFilterGroupsChange } =
    useEarningsStore();

  const filterGroupsEntries = Object.entries<FilterState>(filterGroups);
  const showResetAll =
    !!filterGroups.EARNINGS_FILTERS?.activeFilterOptions.length;
  return (
    <>
      {filterGroupsEntries.map(([group, filterState]) => (
        <Filters
          key={group}
          filterState={filterState}
          store={useEarningsStore}
          group={group as EarningFilterGroupKeys}
          onFilterGroupsChange={onFilterGroupsChange}
        />
      ))}
      {showResetAll && (
        <ResetAllButton resetAllFilterOptions={resetAllFilterOptions} />
      )}
    </>
  );
};
