import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChartLegendRoot = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const LegendIcon = styled.div`
  margin-right: 6px;
`;

export const LegendLabel = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-default);
`;

export const MiniBar = styled.div`
  width: 2px;
  height: 16px;
  border-radius: 2px;
  background-color: var(--border-success);
  border-bottom: 8px solid var(--border-critical);
  box-sizing: border-box;
`;

export const MiniCircle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  background-color: var(--background-default);
`;
