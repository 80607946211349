import { EntitySearchType } from '@toggle/toggle';
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionHandlers,
  ChatDocumentContextInput,
  ChatInputConfig,
} from '~/components/chat';
import { ChatModesButtonGroup } from '~/components/chat-landing/ChatModesButtonGroup';
import { useTagsSearch } from '~/hooks/use-tags-search/useTagsSearch';
import { ChatMode } from '~/stores/use-chat-mode/useChatMode';
import { useThemeSearch } from '~/widgets/search-asset-widget/hooks/use-theme-search/useThemeSearch';

import { ChatSubmitButton } from '../../components/chat-submit-button/ChatSubmitButton';
import { ChatInputDropdown } from '../chat-input-dropdown/ChatInputDropdown';
import * as S from './ChatInputComposer.styles';
import { isAssetNameMessage } from './utils';

export interface ChatInputComposerProps {
  className?: string;
  handlers: ActionHandlers;
  config: ChatInputConfig & {
    isLoading: boolean;
  };
  contextCounts?: {
    documentsCount: number;
    companiesCount: number;
  };
  mode?: ChatMode;
  setMode?: (mode: ChatMode) => void;
}

export const ChatInputComposer = forwardRef<
  HTMLTextAreaElement,
  ChatInputComposerProps
>(
  (
    {
      handlers,
      config,
      contextCounts,
      className,
      mode = 'intel',
      setMode,
    }: ChatInputComposerProps,
    ref
  ) => {
    const { t } = useTranslation(['chat']);
    const isAssetMessage = useRef(false);
    const [inputValue, setInputValue] = useState('');
    const [showItems, setShowItems] = useState(true);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    // intel -- enables asset search + intel streaming API
    // intel + reasoner -- enables everything, only on dashboard
    // empty -- only question, intel streaming API
    const allowedModes = config.allowedModes ?? ['intel'];
    const enableAssetSearch =
      mode === 'intel' && allowedModes.includes('intel');
    const enableThemeSearch =
      enableAssetSearch && !!(config.enableThemeSearch ?? true);

    const {
      tags: tagList,
      searchTags,
      clearTags,
    } = useTagsSearch({
      searchType: EntitySearchType.Entity,
      additionalConditionChecker: isAssetNameMessage,
    });
    const { themeSearchResults, handleTextChange } = useThemeSearch(
      false,
      enableThemeSearch
    );

    useImperativeHandle(ref, () => inputRef.current as HTMLTextAreaElement, [
      inputRef,
    ]);

    const clearInput = () => {
      setInputValue('');
    };

    const clearDropdown = () => {
      clearTags();
      setShowItems(false);

      if (!config?.persistInputTextOnSubmit) {
        setInputValue('');
      }
    };

    const onSubmitCallbackHandler = () => {
      if (!config?.persistInputTextOnSubmit) {
        clearInput();
      }

      clearDropdown();

      if (inputRef.current) {
        inputRef.current.rows = 1;
      }
    };

    const onChatSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      handlers.onSubmit(event, inputValue, onSubmitCallbackHandler);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      isAssetMessage.current = isAssetNameMessage(value);
      setInputValue(value);
      setShowItems(true);
      enableAssetSearch && searchTags(value);
      enableThemeSearch && handleTextChange(value);
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      } else {
        clearInput();
      }
    }, [inputRef]);

    const placeholderText = allowedModes.length
      ? t('chat:placeholder', { context: mode })
      : undefined;
    const showModesSwitcher = allowedModes.length > 1 && !!setMode;

    return (
      <S.Form onSubmit={onChatSubmit} className={className}>
        <S.LoaderWrapper
          $showModesSwitcher={showModesSwitcher}
          $isMessagePending={config.isLoading}
        >
          {showModesSwitcher && (
            <S.ModesSwitcher>
              <ChatModesButtonGroup mode={mode} onClick={setMode} />
            </S.ModesSwitcher>
          )}
          <ChatInputDropdown
            config={config}
            handlers={{ ...handlers, onSubmitCallbackHandler }}
            inputValue={inputValue}
            tagList={tagList}
            themesList={themeSearchResults}
            clearDropdown={clearDropdown}
            isAssetMessage={isAssetMessage}
            showItems={showItems}
            setInputValue={setInputValue}
            offsetY={showModesSwitcher ? 50 : 0}
          >
            <ChatDocumentContextInput
              inputRef={inputRef}
              data-testid="chat-input"
              autoFocus
              placeholder={config?.placeholder ?? placeholderText}
              value={inputValue}
              onChange={onInputChange}
              isMessagePending={config.isLoading}
              disabled={config.isLoading || config.isCustomizedDisabled}
              contextCounts={contextCounts}
            >
              <ChatSubmitButton
                inputValue={inputValue}
                isMessagePending={config.isLoading}
                abortRequest={handlers.onAbort}
              />
            </ChatDocumentContextInput>
          </ChatInputDropdown>
        </S.LoaderWrapper>
      </S.Form>
    );
  }
) as ForwardRefExoticComponent<
  ChatInputComposerProps & RefAttributes<HTMLTextAreaElement>
> & {
  Styled: typeof S;
};

ChatInputComposer.Styled = S;
