import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const CalendarButton = styled(Button)`
  border: 1px solid var(--border-default);
  margin-right: 12px;
`;

export const DateLabel = styled.span`
  ${typographyV2.titleBase};
`;

export const EventCardsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const MenuWrapper = styled.div`
  padding: 0;
`;
