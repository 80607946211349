export interface HighlightTextConfig {
  highlightTexts: Array<{
    text: string;
    isPrimary?: boolean;
    pages?: number[];
  }>;
  scrollToPrimaryText: (fallbackElement?: HTMLElement) => void;
}

export interface DesiredItem {
  startIdx: number;
  endIndex: number;
  node: Text;
  nodeValue: string;
}

export const highlightText = (desired: Array<DesiredItem>) => {
  desired.forEach(item => {
    if (item.node.parentNode) {
      const nodeContent = item.nodeValue;
      const before = nodeContent.slice(0, item.startIdx);
      const match = nodeContent.slice(item.startIdx, item.endIndex);
      const after = nodeContent.slice(item.endIndex);

      const mark = document.createElement('mark');
      mark.classList.add('primary');
      mark.textContent = match;

      const fragment = document.createDocumentFragment();

      if (before) {
        fragment.appendChild(document.createTextNode(before));
      }

      fragment.appendChild(mark);

      if (after) {
        fragment.appendChild(document.createTextNode(after));
      }

      item.node.parentNode.replaceChild(fragment, item.node);
    }
  });
};

export const highlightElement = ({
  part,
  container,
  shouldAddSpace,
  replaceNodeValue,
  filter,
}: {
  part: string;
  container: Node;
  shouldAddSpace: (
    content: string,
    currentNode: Node,
    prevNode?: Node
  ) => boolean;
  replaceNodeValue: (value: string) => string;
  filter?: NodeFilter;
}) => {
  const walker = document.createTreeWalker(
    container,
    NodeFilter.SHOW_TEXT,
    filter
  );

  let content = '';
  let done = false;
  const nodes: Text[] = [];
  const desired: Array<DesiredItem> = [];

  while (walker.nextNode() && !done) {
    const node = walker.currentNode as Text;
    nodes.push(node);

    if (shouldAddSpace(content, node, nodes[nodes.length - 2])) {
      content += ' ';
    }

    let replaced = replaceNodeValue(node.nodeValue!);
    content +=
      content.endsWith(' ') && replaced.startsWith(' ')
        ? replaced.trimStart()
        : replaced;

    if (content.indexOf(part) !== -1) {
      // this is needed to find the index in the replaced string
      // example: part is "this is a random letter from anything else" and replaced is "this is a random letter"
      while (part.indexOf(replaced) === -1) {
        replaced = replaced.slice(0, -1);
      }

      let startIdx = 0;
      let i = 1;
      let remPart = part;

      desired.unshift({
        node,
        startIdx: 0,
        endIndex: replaced.length,
        nodeValue: replaced,
      });
      content = content.slice(0, -replaced.length);
      remPart = remPart.slice(0, -replaced.length);
      let diff = remPart.length;

      while (content.indexOf(remPart) !== -1 && remPart.length) {
        const node = nodes[nodes.length - 1 - i];
        // handle many nbsp at the end
        let repl = replaceNodeValue(node.nodeValue!);
        const minusSpace = Number(shouldAddSpace(repl, node, desired[0].node));

        startIdx =
          repl.length <= remPart.length
            ? 0
            : repl.length - remPart.length + minusSpace;
        const sliceIdx = -Math.min(repl.length, diff) - minusSpace;
        content = content.slice(0, sliceIdx);
        remPart = remPart.slice(0, sliceIdx);
        desired.unshift({
          node,
          startIdx,
          endIndex: repl.length,
          nodeValue: repl,
        });
        diff += -repl.length - minusSpace;
        i++;
      }

      highlightText(desired);
      done = true;
    }
  }
};
