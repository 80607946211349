import { Button, CheckBox } from '@toggle/design-system';
import { highlight } from '@toggle/helpers';
import { ScreenerFilterOption } from '@toggle/toggle';
import React, { ChangeEvent } from 'react';

import {
  getIsOptionSelected,
  getIsPartiallyOrAllSelected,
  isOptionExpanded,
  mapOptions,
} from '../../utils';
import * as S from './Node.styles';

export interface NodeProps {
  option: ScreenerFilterOption;
  displayCaret: boolean;
  level: number;
  selectedOptions: ScreenerFilterOption[];
  searchQuery: string;
  expandedItems: string[];
  onClickCaret: (values: string[], isExpanded: boolean) => void;
  onChangeCheckBox: (options: ScreenerFilterOption[], checked: boolean) => void;
}

export const Node = ({
  option,
  displayCaret,
  level,
  selectedOptions,
  searchQuery,
  expandedItems,
  onClickCaret,
  onChangeCheckBox,
}: NodeProps) => {
  const optionValue = option.value.code as string;
  const mappedOptions = mapOptions([option]);

  const handleChange = (_: string, e: ChangeEvent<HTMLInputElement>) => {
    onChangeCheckBox(mappedOptions, e.target.checked);
  };

  const handleClick = () => {
    const items = isExpanded
      ? mappedOptions.map(mo => mo.value.code)
      : [option.value.code];
    onClickCaret(items as string[], isExpanded);
  };

  const { isPartial } = getIsPartiallyOrAllSelected(option, selectedOptions);
  const isExpanded = isOptionExpanded({ expandedItems, option });
  return (
    <S.NodeWrapper $level={level}>
      {displayCaret && (
        <Button
          variant="empty"
          size="xsmall"
          iconName={isExpanded ? 'CaretDown' : 'CaretRight'}
          onClick={handleClick}
        />
      )}

      <CheckBox
        label={
          !!searchQuery.length
            ? highlight(option.name, searchQuery)
            : option.name
        }
        name={optionValue}
        checked={getIsOptionSelected(option, selectedOptions) || isPartial}
        partial={isPartial}
        onChange={handleChange}
      />
    </S.NodeWrapper>
  );
};
