import { Entity, groupedSubClassMap } from '@toggle/toggle';
import { useEffect, useState } from 'react';

const defaultCriteria = {};

export interface UseTagsSearchCriteriaProps {
  activeSubclassChip: string;
  activeEntity?: Entity;
}

interface Criteria {
  asset_class: number[];
  class: number[];
  sub_class: number[];
}

export function useTagsSearchCriteria({
  activeSubclassChip,
  activeEntity,
}: UseTagsSearchCriteriaProps) {
  const [criteria, setCriteria] = useState<Criteria | {}>(defaultCriteria);

  useEffect(() => {
    if (activeSubclassChip && !activeEntity) {
      setCriteria({
        criteria: {
          asset_class: [],
          class: [],
          sub_class:
            groupedSubClassMap[
              activeSubclassChip as keyof typeof groupedSubClassMap
            ],
        },
      });
    } else {
      setCriteria(defaultCriteria);
    }
  }, [activeSubclassChip, activeEntity]);

  return { criteria };
}
