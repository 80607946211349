import * as Radix from '@radix-ui/react-dropdown-menu';
import { customScrollMinimal, Icon, typographyV2 } from '@toggle/design-system';
import { FC } from 'react';
import styled from 'styled-components';

export const TriggerArrow = styled(Icon).attrs({
  size: 14,
})`
  fill: var(--icon-soft);
`;

export const TriggerLabel = styled.span`
  color: var(--text-default);
  ${typographyV2.bodySm};
`;

export const Trigger = styled<
  FC<Radix.DropdownMenuTriggerProps & { $active?: boolean }>
>(Radix.Trigger)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  background-color: var(--surface-soft);
  height: 32px;
  padding: 6px 8px 6px 12px;
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.$active ? 'var(--surface-active)' : 'var(--border-default)'};
  cursor: pointer;
  outline: none;
  cursor: pointer;

  ${TriggerLabel} {
    color: ${props =>
      props.$active ? 'var(--text-default)' : 'var(--text-soft)'};
  }

  &:hover {
    background: var(--surface-hover);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }
  &[data-state='open'] {
    background-color: var(--surface-active);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }
`;

export const DropdownMenuContent = styled(Radix.Content)`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  max-width: 400px;
  max-height: 400px;
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  border-radius: 4px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  z-index: var(--z-index-modal);
`;

export const ScrollableList = styled.div`
  ${customScrollMinimal};
  overflow-y: auto;
`;

export const Separator = styled(Radix.Separator)`
  height: 1px;
  background-color: var(--border-soft);
  margin-bottom: 8px;
`;

export const CountBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: var(--surface-primary);
  color: var(--text-on-primary);
`;

export const ResetButton = styled.p`
  display: flex;
  align-items: center;
  margin: 0px 8px;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

export const SelectedValue = styled.span`
  display: inline-flex;
  gap: 4px;
  color: var(--text-primary);
  align-items: center;
  ${typographyV2.bodySm};
`;

export const ResetItem = styled<
  FC<Radix.DropdownMenuItemProps & { isChecked?: boolean }>
>(Radix.Item)`
  all: unset;
  display: flex;
  padding: 8px;

  &[data-highlighted] {
    ${ResetButton} {
      background-color: var(--surface-hover);
    }
  }
`;

export const ResetIconWrapper = styled.span`
  display: inline-flex;
`;

export const ResetIcon = styled(Icon).attrs({
  size: 14,
})`
  fill: var(--icon-primary);
  margin-right: 8px;
`;

export const ResetButtonLabel = styled.span`
  color: var(--text-primary);
  ${typographyV2.titleSm};
`;

export const DropdownMenuTitle = styled.div`
  display: flex;
  padding: 0px 16px;
`;

export const DropdownMenuTitleText = styled.p`
  color: #9ba1a6;
  ${typographyV2.bodyXs};
  flex: 1;
  padding: 8px 0px;
  align-items: flex-start;
  text-transform: uppercase;
`;

export const NoResults = styled.p`
  padding: 8px;
  margin: 8px;
  ${typographyV2.bodySm}
`;
