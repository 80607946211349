import {
  flip,
  offset,
  OffsetOptions,
  shift,
  useClientPoint,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useState } from 'react';

interface UseFloatingLegendProps {
  offsetOptions?: OffsetOptions;
}

export const useFloatingLegend = (props?: Partial<UseFloatingLegendProps>) => {
  const { offsetOptions = 15 } = props ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right-start',
    middleware: [offset(offsetOptions), flip(), shift({ padding: 5 })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClientPoint(context),
    useHover(context),
    useRole(context, { role: 'tooltip' }),
  ]);

  return {
    isOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
  };
};
