import React, { HTMLAttributes, ReactNode } from 'react';

import { StyledComponent } from '~/common/styled-component';

import * as S from './DropdownListItem.styles';

export interface DropdownItem {
  label: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  badge?: ReactNode;
  supportiveText?: string;
  separated?: boolean;
}

export interface DropdownListItemProps
  extends DropdownItem,
    HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
  isFocused: boolean;
  isDisabled?: boolean;
}

export const DropdownListItem: StyledComponent<
  DropdownListItemProps,
  typeof S
> = ({
  label,
  leadingIcon,
  trailingIcon,
  badge,
  supportiveText,
  isActive,
  isFocused,
  separated = false,
  isDisabled = false,
  ...rest
}) => {
  return (
    <>
      <S.Item
        data-testid="dropdown-list-item"
        role="option"
        aria-selected={!!isActive}
        aria-disabled={!!isDisabled}
        $isActive={isActive}
        $isFocused={isFocused}
        $isCondensed={!!badge}
        $isDisabled={isDisabled}
        {...rest}
      >
        {leadingIcon && (
          <S.LeadingIconContainer $isActive={isActive}>
            {leadingIcon}
          </S.LeadingIconContainer>
        )}
        <S.MainLabel $isActive={isActive}>{label}</S.MainLabel>
        {badge}
        {supportiveText && (
          <S.SupportiveText>{supportiveText}</S.SupportiveText>
        )}
        {trailingIcon && (
          <S.TrailingIconContainer>{trailingIcon}</S.TrailingIconContainer>
        )}
      </S.Item>
      {separated && <S.Separator data-testid="dropdown-list-separator" />}
    </>
  );
};

DropdownListItem.Styled = S;
