import { ConditionOptions } from '~/views/dashboard/calendar-widget/calendar-event-drawer/drawer-content/utils/getConditionOptions';
import { ConditionWithLabel } from '~/widgets/scenario/components/scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';

export const createCustomCondition = (
  conditionOptions: ConditionOptions,
  entity: string,
  actualUnit?: string
): ConditionWithLabel => {
  const {
    value,
    config: { snake, condition, value: configValue },
  } = conditionOptions;

  return {
    entity: entity.toLowerCase(),
    snake,
    condition,
    value: configValue,
    label: value,
    actualUnit: actualUnit ?? '',
  };
};
