import { AlertProps, AlertType } from '@toggle/design-system';
import { portfolio } from '@toggle/toggle';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { PortfolioNotificationPayload } from '~/services/notification-ws/NotificationWs';
import { create } from '~/stores/create-store/createStore';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { apiFetch } from '~/utils/api-fetch/apiFetch';
import {
  PortfolioErrors,
  PortfolioSuccess,
  PortfolioToast,
} from '~/views/settings/portfolio-view/hooks/portfolio-toasts/usePortfolioToasts';
import { createPortfolioGroups } from '~/views/settings/portfolio-view/utils/createPortfolioGroups';

import {
  ENABLED_TRADING_PROVIDERS,
  isTradingEnabledForPortfolio,
  TradingProvider,
} from './utils/use-portfolio-utils';

export interface PortfolioGroup {
  id: string;
  lastStatementDate: string;
  createdAt: string;
  provider: PortfolioProvider;
  portfolios: Omit<Portfolio, 'provider'>[];
}

export interface PendingPortfolio {
  id: string;
  user_id: string;
  provider: PortfolioProvider;
  login_id: string;
}

export interface PortfolioProvider {
  id: number | string;
  name: string;
  active: boolean;
  logo: string;
  external_id: string;
}

export interface Portfolio {
  id: string;
  external_id: string;
  login_id: string;
  has_mapping_issues: boolean;
  active: boolean;
  last_statement_date: string;
  created_at: string;
  provider: PortfolioProvider;
  alias?: string;
  name: string;
  position_count: number | null;
}

export type PortfolioStore = {
  portfolios: Portfolio[];
  portfolioGroups: PortfolioGroup[];
  pendingPortfolios: PendingPortfolio[];
  error: boolean;
  loading: boolean;
  toast: PortfolioToast;
  initialize: () => Promise<void>;
  getPortfolioById: (portfolioId: string) => Portfolio | undefined;
  getPortfoliosByProvider: (providerId: string) => Portfolio[];
  getConnectedTradingProviders: () => TradingProvider[];
  handlePortfolioWebsocketResponse: (
    response?: PortfolioNotificationPayload
  ) => Promise<void>;
  disconnectSinglePortfolio: (loginId: string) => Promise<void>;
  saveAlias: (
    providerId: string | number,
    portfolioId: string | number,
    name: string
  ) => Promise<void>;
  showPortfolioToast: (
    type?: PortfolioErrors | PortfolioSuccess,
    customToast?: AlertProps
  ) => void;
  clearCurrentToast: () => void;
  hasPortfolioWithTradingEnabled: () => boolean;
};

const getPendingPortfolios = async () => {
  const pendingPortfolios = await apiFetch<{ result: PendingPortfolio[] }>(
    portfolio.pendingPortfolio.path
  );

  if (pendingPortfolios.error) {
    return {
      pendingPortfolios: [],
    };
  }

  return {
    pendingPortfolios: pendingPortfolios.data.result,
  };
};

const getPortfolios = async () => {
  const portfolios = await apiFetch<{ result: Portfolio[] }>(
    portfolio.portfolio.path
  );

  if (portfolios.error) {
    return {
      portfolios: [],
      portfolioGroups: [],
    };
  }

  const orderedPortfolios = sortBy(portfolios.data.result, 'created_at');

  return {
    portfolios: orderedPortfolios,
    portfolioGroups: createPortfolioGroups(orderedPortfolios),
  };
};

export const usePortfolio = create<PortfolioStore>((set, get) => ({
  portfolios: [],
  portfolioGroups: [],
  pendingPortfolios: [],
  error: false,
  loading: false,
  toast: {
    id: 0,
    type: undefined,
    customToast: undefined,
  },
  initialize: async () => {
    set({ loading: true, error: false });

    const portfolios = await getPortfolios();
    const pendingPortfolios = await getPendingPortfolios();

    set({ ...portfolios, ...pendingPortfolios, loading: false });
  },
  getPortfolioById: portfolioId =>
    find(get().portfolios, portfolio => portfolio.id === portfolioId),
  getPortfoliosByProvider: (providerId: string) =>
    get().portfolios.filter(
      portfolio => portfolio.provider.external_id === providerId
    ),
  getConnectedTradingProviders: () =>
    get().portfolios.reduce((res, portfolio) => {
      const tradingProvider = (
        ENABLED_TRADING_PROVIDERS as TradingProvider[]
      ).find(provider => portfolio.provider.external_id === provider);

      if (tradingProvider && !res.includes(tradingProvider)) {
        res.push(tradingProvider);
      }

      return res;
    }, [] as TradingProvider[]),
  handlePortfolioWebsocketResponse: async (
    response?: PortfolioNotificationPayload
  ) => {
    if (response?.event.action === 'connection.success') {
      get().initialize();
      get().showPortfolioToast(undefined, {
        withIcon: true,
        type: AlertType.Success,
        title: response.title,
        message: response.body,
      });
      useWatchlist.getState().getLists();
    }

    if (response?.event.action === 'connection.error') {
      get().showPortfolioToast(undefined, {
        withIcon: true,
        type: AlertType.Error,
        title: response.title,
        message: response.body,
      });
    }
  },
  disconnectSinglePortfolio: async (loginId: string) => {
    const res = await apiFetch(
      `${portfolio.portfolio.path}?login_id=${loginId}`,
      {
        method: 'delete',
      }
    );

    if (res.error) {
      get().showPortfolioToast(PortfolioErrors.Unknown);
      return;
    }

    const removedPortfolioGroup = get().portfolioGroups.filter(
      p => p.id !== loginId
    );
    const removedPortfolios = get().portfolios.filter(
      p => p.login_id !== loginId
    );
    get().showPortfolioToast(PortfolioSuccess.PortfolioRemoved);

    set({
      portfolioGroups: removedPortfolioGroup,
      portfolios: removedPortfolios,
    });
  },

  saveAlias: async (
    providerId: string | number,
    portfolioId: string | number,
    name: string
  ) => {
    const res = await apiFetch(`${portfolio.portfolio.path}/${portfolioId}`, {
      method: 'put',
      body: {
        name,
      },
    });

    if (res.error) {
      get().showPortfolioToast(PortfolioErrors.Unknown);
      return;
    }

    const updatedPortfolioGroups = get().portfolioGroups.map(p => {
      if (p.provider.id === providerId) {
        return {
          ...p,
          portfolios: p.portfolios.map(obj =>
            obj.id === portfolioId ? { ...obj, alias: name } : obj
          ),
        };
      }

      return p;
    });
    const updatedPortfolios = get().portfolios.map(portfolio =>
      portfolio.id === portfolioId ? { ...portfolio, alias: name } : portfolio
    );

    set({
      portfolioGroups: updatedPortfolioGroups,
      portfolios: updatedPortfolios,
    });
  },
  showPortfolioToast: (type, customToast) =>
    set({
      toast: {
        id: get().toast.id + 1,
        type: type,
        customToast: customToast,
      },
    }),
  clearCurrentToast: () =>
    set({ toast: { id: 0, type: undefined, customToast: undefined } }),
  hasPortfolioWithTradingEnabled: () =>
    get().portfolios.some(isTradingEnabledForPortfolio),
}));
