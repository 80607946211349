import { Icon } from '@toggle/design-system';
import React, { ChangeEvent } from 'react';

import * as S from './Search.styles';

interface SearchProps {
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Search = ({ onChange, placeholder }: SearchProps) => {
  return (
    <S.StyledInput
      data-testid="scenario-input-search"
      placeholder={placeholder}
      onChange={onChange}
      leftIcon={<Icon iconName="Search" size={20} />}
    />
  );
};
