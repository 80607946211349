import React, { ReactNode } from 'react';

import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import { Separator } from '../DropdownMenu.styles';
import * as S from './DropdownGroup.styles';

export type DropdownEntry = {
  label: ReactNode;
  key: string;
  iconName?: SvgIconNames;
  disabled?: boolean;
  onClick?: () => void;
};

export type DropdownGroupProps = {
  items: DropdownEntry[];
  className?: string;
};

export const DropdownGroup = ({ items, className }: DropdownGroupProps) => {
  return (
    <S.DropdownWrapper data-testid="dropdown-wrapper" className={className}>
      <S.DropdownGroup>
        {items.map(item => (
          <S.DropdownMenuItem
            key={item.key}
            onSelect={item?.onClick}
            disabled={item.disabled}
            data-testid={`dropdown-group-item-${item.key}`}
            data-key={item.key}
          >
            <S.DropdownItemButton $disabled={item.disabled}>
              {item.iconName && (
                <S.DropdownIcon iconName={item.iconName} size={16} />
              )}
              <S.DropdownItemLabel>{item.label}</S.DropdownItemLabel>
            </S.DropdownItemButton>
          </S.DropdownMenuItem>
        ))}
      </S.DropdownGroup>
      <Separator />
    </S.DropdownWrapper>
  );
};

DropdownGroup.Styled = S;
