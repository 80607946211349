import { RefObject } from 'react';

type OptionalN<T> = T | null;

export function getElementHeight(
  elementRef: OptionalN<RefObject<HTMLElement>>
): number {
  if (!elementRef?.current) {
    return 0;
  }

  const currentElement = elementRef.current;
  const elementRect = currentElement.getBoundingClientRect();
  return elementRect.height;
}

export function getApplicationContainer(): HTMLElement {
  return document.getElementById('app') as HTMLElement;
}

export const isElementScrollbarVisible = (
  elementRef: RefObject<HTMLElement | null>,
  direction: 'horizontal' | 'vertical' = 'horizontal'
): boolean => {
  if (!elementRef.current) {
    return false;
  }

  if (direction === 'horizontal') {
    return elementRef.current.scrollWidth > elementRef.current.offsetWidth;
  }

  return elementRef.current.scrollHeight > elementRef.current.offsetHeight;
};
