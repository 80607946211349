import { RowSelectionState } from '@tanstack/react-table';
import { RangeHorizon } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceMovementChart } from '~/components/price-movement/components/price-movement-chart/PriceMovementChart';
import { useScenarios } from '~/hooks/use-scenarios/useScenarios';

import { getMarketHoursLabel } from '../../utils/utils';
import * as S from './EarningsPriceMovementsChartAccordion.styles';

const HORIZONS = [
  RangeHorizon.OneDay,
  RangeHorizon.TwoDays,
  RangeHorizon.OneWeek,
  RangeHorizon.TwoWeeks,
  RangeHorizon.OneMonth,
];

export interface EarningsPriceMovementsChartAccordionProps {
  rowSelection: RowSelectionState;
  entity: Entity;
}

export const EarningsPriceMovementsChartAccordion = ({
  rowSelection,
  entity,
}: EarningsPriceMovementsChartAccordionProps) => {
  const { t } = useTranslation('widget');
  const selectedRowsLength = Object.values(rowSelection).filter(Boolean).length;

  useEffect(() => {
    if (selectedRowsLength) {
      const reportingDates = Object.keys(rowSelection).filter(
        key => rowSelection[key]
      );
      const body = {
        entities: [
          {
            tag: entity.tag,
            snake: entity.default_snake,
          },
        ],
        events: reportingDates.map(reportingDate => ({
          date: reportingDate,
          market_hours: getMarketHoursLabel(new Date(reportingDate)),
        })),
        horizons: HORIZONS,
        start_horizon: '0d',
        end_horizon: '1m',
        skip_report: true,
      };
      sendMessage(body);
    }
  }, [selectedRowsLength, entity]);

  const { error, isLoading, data, refetch, sendMessage } = useScenarios();
  const scenario = data[0];

  const renderAccordionContent = () => {
    if (isLoading) {
      return (
        <S.LoadingSkeleton data-testid="earnings-price-movements-chart-loading-skeleton" />
      );
    }

    if (error) {
      return <S.StyledErrorMessageWrapper displayIcon onClick={refetch} />;
    }

    if (!scenario || !selectedRowsLength) {
      return null;
    }

    const prediction = scenario.predictions.map(p => ({
      high: p.high,
      median: p.median,
      low: p.low,
      relative_idx: p.relative_idx,
    }));

    return (
      <PriceMovementChart
        prediction={prediction}
        defaultHorizonToIndex={
          new Map<string, number>([
            ['1d', 1],
            ['2d', 2],
            ['1w', 4],
            ['2w', 9],
            ['1m', 20],
          ])
        }
        defaultHorizons={HORIZONS}
        yAxisLabel={t('widget:earnings.return%')}
        useDynamicDaysLabel
      />
    );
  };

  return (
    <S.StyledAccordion
      data-testid="earnings-price-movements-chart-accordion"
      items={[
        {
          id: 'scenario',
          title: (
            <S.StyledTextDefaultSpan>
              {t('widget:earnings.earningsScenario.priceMovementsFollowing')}
              <S.StyledTextPrimarySpan>
                {` ${selectedRowsLength} `}
              </S.StyledTextPrimarySpan>
              <S.StyledTextDefaultSpan>
                {t(
                  'widget:earnings.earningsScenario.selectedEarningsReleases',
                  {
                    count: selectedRowsLength,
                  }
                )}
              </S.StyledTextDefaultSpan>
            </S.StyledTextDefaultSpan>
          ),
          content: renderAccordionContent(),
          renderIcon: (expanded: boolean) => (
            <S.IconStyledCustomAnimation
              iconName="ChevronLightRight"
              fillColor="var(--icon-default)"
              size={20}
              $expanded={expanded}
            />
          ),
        },
      ]}
    />
  );
};
