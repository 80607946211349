import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventTypeWrapper = styled.div`
  ${typographyV2.titleMd}
  padding-bottom: 16px;
`;

export const HeadlineWrapper = styled.div`
  ${typographyV2.bodyBase}
  padding-bottom: 8px;
`;

export const SituationWrapper = styled.div`
  ${typographyV2.bodyBase}
  color: var(--text-soft);
`;
