import { Card } from '@toggle/design-system';
import { Earning } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EarningState } from '../../types';
import { formatEarning, getEarningState } from '../../utils';
import * as S from './ExpectationReported.styles';

export interface ExpectationReportedProps {
  earning: Earning;
  currency: string;
}

export const ExpectationReported = ({
  earning,
  currency,
}: ExpectationReportedProps) => {
  const { t } = useTranslation('earnings');

  const valueFormatted = formatEarning(earning, currency);
  const earningState = getEarningState(earning);

  return (
    <S.ExpectationReportedRoot data-testid="expectation-reported">
      {earningState === EarningState.Beat && (
        <S.SuccessCard data-testid="expectation-reported-beat">
          {t('earnings:expectationReportedBeat', {
            value: valueFormatted,
          })}
        </S.SuccessCard>
      )}

      {earningState === EarningState.Miss && (
        <S.CriticalCard data-testid="expectation-reported-miss">
          {t('earnings:expectationReportedMiss', {
            value: valueFormatted,
          })}
        </S.CriticalCard>
      )}

      {earningState === EarningState.Meet && (
        <Card data-testid="expectation-reported-meet">
          {t('earnings:expectationReportedMeet')}
        </Card>
      )}
    </S.ExpectationReportedRoot>
  );
};
