import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';

import { postEntity } from '~/services/entities/entity-service';

import { createEntityQueryOptions, QUERY_KEY_ENTITY } from './utils';

export const QUERY_KEY_ENTITIES = 'QUERY_KEY_ENTITIES';

export const useEntity = (entityTag: string) =>
  useQuery(createEntityQueryOptions(entityTag));

export const useEntities = (entityTags: string[], enabled = true) => {
  const queries = entityTags.map(entityTag => ({
    queryKey: [QUERY_KEY_ENTITY, entityTag],
    queryFn: () => postEntity(entityTag),
    enabled,
  }));
  return useQueries({
    queries,
    combine: results => {
      return {
        data: results
          .map(result => result.data?.data)
          .filter(Boolean) as Entity[],
        isLoading: results.some(
          result => result.isLoading || result.isRefetching
        ),
        isError: !!results.length && results.every(result => result.error),
        isFetched: results.every(result => result.isFetched),
        refetch: () => {
          results.forEach(result => result.refetch());
        },
        enabled: false,
      };
    },
  });
};

export const useEntitiesTableData = (entities: string[], pageSize = 20) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery({
    queryKey: [
      QUERY_KEY_ENTITIES,
      ...[...entities].sort((a, b) => a.localeCompare(b)),
    ],
    queryFn: async ({ pageParam }) => {
      const start = pageParam * pageSize;
      const slice = entities.slice(start, start + pageSize);
      const results = await Promise.all(
        slice.map(tag =>
          queryClient
            .ensureQueryData(createEntityQueryOptions(tag))
            .then(res => res.data)
        )
      );
      return results;
    },
    initialPageParam: 0,
    enabled: !!entities.length,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      const lastEntity = lastPage[lastPage.length - 1]?.tag;

      if (!lastEntity) {
        return lastPage.length === entities.length ? null : lastPageParam + 1;
      }

      return lastEntity === entities[entities.length - 1]
        ? null
        : lastPageParam + 1;
    },
    staleTime: getTimeTillEndOfDayMs(),
  });
};
