import { ErrorLabel, InputWrapper } from '@toggle/design-system';
import React, { ReactNode, TextareaHTMLAttributes, useState } from 'react';

import { useRows } from '~/components/chat';

import * as S from './QuestionViewTextArea.styles';

export interface QuestionViewTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
  errorText?: ReactNode;
}

export const QuestionViewTextArea = ({
  hasError,
  errorText,
  ...rest
}: QuestionViewTextAreaProps) => {
  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const { value, onChange } = rest;
  const { rows, updateRows } = useRows({ value });

  return (
    <S.Container>
      <InputWrapper
        data-testid="input-wrapper"
        $error={hasError}
        $focused={focused}
        $variant="fill"
      >
        <S.TextAreaElement
          onFocus={onFocus}
          onBlur={onBlur}
          rows={rows}
          data-testid="textarea-input"
          {...rest}
          onChange={e => {
            updateRows(e);
            onChange?.(e);
          }}
          value={value}
        />
      </InputWrapper>
      {hasError && errorText && (
        <ErrorLabel data-testid="error-text">{errorText}</ErrorLabel>
      )}
    </S.Container>
  );
};
