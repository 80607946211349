import {
  Badge,
  borderRadius,
  Button,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledBadge = styled(Badge)`
  ${Badge.Styled.BadgeNumerical} {
    ${typographyV2.bodyXs};
    background-color: var(--surface-primary);
    color: var(--text-on-primary);
  }
`;

export const MoreFilterPill = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  ${borderRadius.r2}
  color: var(--text-soft);
  ${typographyV2.bodySm};

  &:hover {
    color: var(--text-default);
  }
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-left: 4px;

  & > svg {
    fill: var(--text-soft);
  }
`;

export const MenuWrapper = styled.div`
  padding: 0px;
  border: unset;
  ${borderRadius.r2}
  width: 240px;
  background-color: var(--background-default);
`;
