export enum NumericalCellValueStyle {
  Positive,
  Negative,
  Neutral,
}

export const checkCellValueStyle = (value: number): NumericalCellValueStyle => {
  if (!value) {
    return NumericalCellValueStyle.Neutral;
  }

  if (value > 0) {
    return NumericalCellValueStyle.Positive;
  } else {
    return NumericalCellValueStyle.Negative;
  }
};
