import { Icon } from '@toggle/design-system';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';

import * as S from './FilterSearch.styles';

interface FilterSearchProps {
  searchPlaceholder?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

export const FilterSearch: FC<FilterSearchProps> = ({
  searchPlaceholder,
  value,
  onChange,
  onClear,
}) => {
  const onKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <S.Search
      data-testid="filter-search"
      placeholder={searchPlaceholder}
      onKeyDown={onKeyDown}
      onChange={onChange}
      leftIcon={<Icon iconName="Search" size={20} />}
      rightIcon={
        !!value.length && (
          <Icon iconName="CloseCircle" size={16} onClick={onClear} />
        )
      }
      value={value}
    />
  );
};
