import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const GridLinesGroup = styled.g`
  text {
    ${typographyV2.bodyXs};
    fill: var(--text-soft);
    font-family: var(--font-roboto-mono);
  }

  line {
    stroke: var(--border-soft);
  }
`;

export const YAxisLabel = styled.text`
  fill: var(--text-soft);
  font-family: var(--font-nort);
  ${typographyV2.bodyXs};
  rotate: -90deg;
  text-anchor: middle;
`;
