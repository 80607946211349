import { Button } from '@toggle/design-system';
import React from 'react';

import { collapseRange } from './collapseRange';
import * as S from './Pagination.styles';

export interface PaginationProps {
  pages: string[];
  activeIndex: number;
  onClick: (index: number) => void;
  maxItems?: number;
  className?: string;
}

export const Pagination = ({
  pages,
  activeIndex,
  className,
  maxItems = 7,
  onClick,
}: PaginationProps) => {
  const onPrevious = () => {
    if (activeIndex !== 0) {
      onClick(activeIndex - 1);
    }
  };

  const onNext = () => {
    if (activeIndex !== pages.length - 1) {
      onClick(activeIndex + 1);
    }
  };

  return (
    <S.Root data-testid="pagination" className={className}>
      <S.Group>
        <Button
          iconName="ChevronBoldLeft"
          size="small"
          variant="empty"
          onClick={onPrevious}
          data-testid="button-previous-pagination"
        />
        {collapseRange({
          pages,
          current: activeIndex,
          max: maxItems,
          ellipsis: () => <span>...</span>,
          transform: (page: string, currPageIndex: number) => (
            <S.StyledButton
              size="small"
              variant="empty"
              label={page}
              onClick={() => onClick(currPageIndex)}
              $isActive={currPageIndex === activeIndex}
              data-testid="button-pagination"
            />
          ),
        })}
        <Button
          iconName="ChevronBoldRight"
          size="small"
          variant="empty"
          onClick={onNext}
          data-testid="button-next-pagination"
        />
      </S.Group>
    </S.Root>
  );
};
