import { Modal } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    display: flex;
    flex-direction: column;
    width: max(35%, 680px);
    padding-bottom: 24px;
    max-height: calc(100% - 40px);
  }

  ${Modal.Styled.Header} {
    padding: 8px 8px 0px;
  }
`;
