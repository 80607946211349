import React from 'react';

import * as S from './FilterList.styles';

export interface FilterListProps {
  className?: string;
  list?: string[];
  title?: string;
}

export const FilterList = ({
  className,
  list = [],
  title,
}: FilterListProps) => {
  if (!list.length) {
    return null;
  }

  return (
    <S.FilterListRoot data-testid="filter-list" className={className}>
      {title && <p>{title}</p>}
      <ul>
        {list.map(name => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </S.FilterListRoot>
  );
};
