import { TimeSeriesItem } from '@toggle/chart';
import { Entity, mapEntity, SnakeMeta } from '@toggle/toggle';
import { TFunction } from 'i18next';
import React from 'react';

import { PriceDisplayView } from '~/components/price-display-view/PriceDisplayView';
import { useLivePrice } from '~/hooks/use-live-price';
import { mapEntityToPriceEntity } from '~/hooks/use-live-price/use-live-price-store/useLivePriceStore.utils';
import { usePreliminaryPrice } from '~/hooks/use-preliminary-price/usePreliminaryPrice';
import { useLegendPriceHover } from '~/views/turbo-chart/hooks/use-legend-price-hover/useLegendPriceHover';
import { getPriceData } from '~/widgets/asset-overview-widget/utils/asset-box/asset-box-helpers';

import * as S from './ChartPrice.styles';

export interface ChartPriceProps {
  entity: Entity;
  snakeMeta?: SnakeMeta;
  ts?: TimeSeriesItem[];
  t: TFunction;
  className?: string;
  isCondensed?: boolean;
  enablePriceHover?: boolean;
}

export const ChartPrice = ({
  entity,
  snakeMeta,
  ts = [],
  t,
  className,
  isCondensed = false,
  enablePriceHover = true,
}: ChartPriceProps) => {
  const mappedEntity = mapEntity(entity);
  const priceHover = useLegendPriceHover({
    elementRef: {
      current: document,
    },
    enabled: enablePriceHover,
  });

  const priceEntities = mapEntityToPriceEntity(entity, snakeMeta);

  const livePriceData = useLivePrice(priceEntities);
  const hoverData = priceHover?.panes[0].data[0];

  const priceData = getPriceData({
    hoverData,
    livePriceData,
    entity: mappedEntity,
    ts,
    priceEntity: priceEntities[0],
  });

  const { data: preliminaryPriceResult } = usePreliminaryPrice(
    entity.subscribable_ticker ?? ''
  );

  return (
    <S.PriceWrapper className={className}>
      {priceData && (
        <PriceDisplayView
          entity={mappedEntity}
          livePriceInfo={priceData}
          snakeMeta={snakeMeta}
          preliminaryPrice={preliminaryPriceResult?.data}
          isChartHovered={!!hoverData?.currentPrice}
          t={t}
          isCondensed={isCondensed}
        />
      )}
    </S.PriceWrapper>
  );
};
