import {
  ChartAssetData,
  ChartColors,
  getTimefromSeries,
  PaneData,
  RANGE_HORIZON_DEFAULT,
  RangeHorizon,
  ResampleIntervals,
  ThresholdInterval,
  Timeseries,
  TimeSeriesItem,
} from '@toggle/chart';
import { isSnakeWithThreshold, Variant } from '@toggle/helpers';
import {
  DetailedInsight,
  Entity,
  SnakeMeta,
  SnakeMetaResponseV2,
  SnakeMetaThreshold,
} from '@toggle/toggle';
import { v4 } from 'uuid';

import { fetchSnakeMeta } from '~/services/overview-widget/overview-widget-service';

import { ChartState } from '../../hooks/use-chart-state/useChartState';
import { ChartSearchType, SearchAssetPayload } from '../../types/search.types';

export const getLineColorToken = ({
  chartData,
  colors,
}: {
  chartData: ChartAssetData[];
  colors: ChartColors;
}) => {
  const multiColors = colors.getMulti();
  const fallback = multiColors[0].token;

  if (!chartData.length) {
    return fallback;
  }

  const foundColor = multiColors.find(color =>
    chartData.every(item => item.lineColorToken !== color.token)
  );

  return foundColor?.token ?? fallback;
};

interface CreateChartDataItemProps {
  entity: Entity;
  ts: Timeseries;
  lineColorToken: string;
  primaryAsset?: ChartAssetData;
  isHidden?: ChartAssetData['isHidden'];
  snakeMeta?: SnakeMeta;
  thresholdIntervals?: ChartAssetData['thresholdIntervals'];
  episodes?: DetailedInsight['episodes'];
}

export const createChartDataItem = ({
  entity,
  ts,
  lineColorToken,
  primaryAsset,
  isHidden = false,
  snakeMeta,
  episodes,
}: CreateChartDataItemProps): ChartAssetData => {
  let filteredTs =
    !primaryAsset || primaryAsset.entity.default_snake === entity.default_snake
      ? ts
      : ts.filter(t => primaryAsset.tsByTime.get(t.time) !== undefined);

  if (filteredTs.length === 0) {
    filteredTs = ts;
  }

  const tsByTime = new Map();
  filteredTs.forEach((item, idx) => tsByTime.set(item.time, idx));

  const thresholdIntervals = createThresholdIntervals({
    primaryAsset,
    ts: filteredTs,
    snakeMeta,
  });

  return {
    entity,
    ts: filteredTs,
    originalTs: ts,
    lineColorToken,
    tsByTime,
    isHidden,
    snakeMeta,
    thresholdIntervals,
    episodesData: episodes,
  };
};

export const createChartDataFromPrimaryAsset = ({
  chartData,
  asset,
  ts,
  snakeMeta,
  lineColorToken,
}: {
  chartData: ChartAssetData[];
  asset: Entity;
  ts: TimeSeriesItem[];
  snakeMeta?: SnakeMeta;
  lineColorToken: string;
}) => {
  const primaryAsset = createChartDataItem({
    entity: asset,
    ts,
    lineColorToken,
    snakeMeta,
  });
  return [
    primaryAsset,
    ...chartData.map(item =>
      createChartDataItem({
        entity: item.entity,
        ts: item.originalTs,
        lineColorToken: item.lineColorToken,
        primaryAsset,
        isHidden: item.isHidden,
        snakeMeta: item.snakeMeta,
      })
    ),
  ];
};

interface TsItem {
  value?: number;
  index?: string;
  time?: string;
  close?: number;
}

export const createTsDataFromSnake = (ts: TsItem[], isPriceSnake: boolean) => {
  const result: TimeSeriesItem[] = [];

  ts.forEach(item => {
    if (item.value !== null) {
      const time = getTimefromSeries(
        isPriceSnake,
        (item.index ?? item.time) as string
      );
      result.push({
        time,
        open: 0,
        high: 0,
        low: 0,
        close: (item.value ?? item.close) as number,
      });
    }
  });

  return result;
};

export const getHorizons = () => {
  const horizons = Object.values(RangeHorizon);
  const defaultHorizon = horizons.find(
    horizon => horizon === RANGE_HORIZON_DEFAULT
  );

  return {
    activeHorizon:
      defaultHorizon ??
      (horizons[horizons.length - 2] || horizons[horizons.length - 1]),
    horizons,
  };
};

export const getChartDataSliced = (
  chartData: ChartAssetData[],
  payload: SearchAssetPayload
) => {
  if (payload.type === ChartSearchType.Change) {
    return chartData.filter(
      item => item.entity.default_snake !== payload.assetToChange.default_snake
    );
  }

  return chartData;
};

export const getSnakeMeta = async (
  defaultSnake: string
): Promise<SnakeMeta | undefined> => {
  try {
    return await fetchSnakeMeta(defaultSnake);
  } catch (e) {
    return undefined;
  }
};

export const updateChartPane = (
  chartPanes: PaneData[],
  chartAssetData: ChartAssetData[],
  paneIndex = 0
): PaneData[] => [
  ...chartPanes.slice(0, paneIndex),
  { ...chartPanes[paneIndex], chartAssetData },
  ...chartPanes.slice(paneIndex + 1),
];

export const updatePane = (
  chartPanes: PaneData[],
  idx: number,
  paneData: Partial<PaneData>
): PaneData[] => {
  if (paneData.chartAssetData?.length) {
    return [
      ...chartPanes.slice(0, idx),
      { ...chartPanes[idx], ...paneData },
      ...chartPanes.slice(idx + 1),
    ];
  }

  if (chartPanes.length === 1) {
    return [
      {
        ...chartPanes[0],
        ...paneData,
      },
    ];
  }

  return [...chartPanes.slice(0, idx), ...chartPanes.slice(idx + 1)];
};

export const createThresholdIntervals = <
  T extends Array<{ close: number; time: string }>
>({
  ts,
  primaryAsset,
  snakeMeta,
}: {
  ts: T;
  primaryAsset?: ChartAssetData;
  snakeMeta?: SnakeMeta;
}) => {
  if (!primaryAsset || !snakeMeta?.threshold) {
    return undefined;
  }

  const intervals: ThresholdInterval[] = [];
  const threshold = snakeMeta.threshold as Required<SnakeMetaThreshold>;
  const tsByTime = primaryAsset.tsByTime;
  const bullishCheck =
    threshold.good > threshold.bad
      ? (item: T[0]) => item.close >= threshold.good
      : (item: T[0]) => item.close <= threshold.good;
  const bearishCheck =
    threshold.good < threshold.bad
      ? (item: T[0]) => item.close >= threshold.bad
      : (item: T[0]) => item.close <= threshold.bad;
  const mapping = {
    bullish: threshold.good,
    bearish: threshold.bad,
  };
  let start: number | undefined = undefined;
  let direction: Variant | undefined = undefined;

  const addInterval = (item: T[0], variant: Variant) => {
    const startIdx = start as number;
    intervals.push({
      direction: variant,
      start: startIdx,
      end: tsByTime.get(item.time) as number,
      threshold: mapping[variant],
    });
    direction = undefined;
    start = undefined;
  };

  ts.forEach(item => {
    if (bullishCheck(item)) {
      if (direction === 'bearish') {
        addInterval(item, direction);
      }

      direction = 'bullish';
      start = start ?? tsByTime.get(item.time);
    } else if (bearishCheck(item)) {
      if (direction === 'bullish') {
        addInterval(item, direction);
      }

      direction = 'bearish';
      start = start ?? tsByTime.get(item.time);
    } else if (direction) {
      addInterval(item, direction);
    }
  });

  if (direction && start !== undefined) {
    intervals.push({
      direction,
      start: start,
      end: tsByTime.get(ts[ts.length - 1].time) ?? primaryAsset.ts.length - 1,
      threshold: mapping[direction],
    });
  }

  return intervals;
};

export const getSimilarPane = ({
  chartPanes,
  indicator,
  assetToChange,
}: {
  chartPanes: PaneData[];
  indicator: SnakeMeta;
  assetToChange?: Entity;
}) => {
  const checker = assetToChange
    ? (chartData: ChartAssetData) =>
        chartData.entity.default_snake === assetToChange.default_snake
    : (chartData: ChartAssetData) =>
        chartData.snakeMeta?.name.english === indicator.name.english;

  return chartPanes.findIndex(pane => pane.chartAssetData.some(checker));
};

export const getActiveToggleIndicators = (
  paneData: PaneData[],
  indicators?: SnakeMetaResponseV2
) => {
  if (!indicators) {
    return [];
  }

  const snakes = Object.keys(indicators);
  return paneData.reduce((res, next) => {
    const snake = next.chartAssetData[0]?.snakeMeta?.snake;

    if (snake && snakes.includes(snake)) {
      res.push(snake);
    }

    return res;
  }, [] as string[]);
};

export const paneContainsAsset = (
  pane: PaneData,
  asset: Pick<ChartAssetData, 'entity'>
) => {
  return pane.chartAssetData.some(
    c => c.entity.default_snake === asset.entity.default_snake
  );
};

export const updateAllPanes = (
  data: ChartAssetData[],
  chartPanes: PaneData[]
): PaneData[] => {
  let index = 0;
  const newChartPanes = [...chartPanes];
  newChartPanes.forEach(pane => {
    pane.chartAssetData = data.slice(index, index + pane.chartAssetData.length);
    index += pane.chartAssetData.length;
  });

  return newChartPanes;
};

export const createChartDataWithChangedAsset = ({
  primaryAsset,
  chartData,
  asset,
  snakeMeta,
  prices,
  assetToChange,
  colors,
  newLineColorToken = colors.line.default.token,
}: {
  chartData: ChartAssetData[];
  primaryAsset?: ChartAssetData;
  asset: Entity;
  snakeMeta?: SnakeMeta;
  prices: TimeSeriesItem[];
  assetToChange?: Entity;
  colors: ChartColors;
  newLineColorToken?: string;
}) => {
  if (assetToChange) {
    return chartData.map(c =>
      c.entity.default_snake === assetToChange.default_snake
        ? createChartDataItem({
            entity: asset,
            ts: prices,
            primaryAsset,
            lineColorToken: c.lineColorToken,
            snakeMeta,
          })
        : c
    );
  }

  const newChartDataItem = createChartDataItem({
    entity: asset,
    ts: prices,
    primaryAsset,
    lineColorToken: newLineColorToken,
    snakeMeta,
  });

  return [...chartData, newChartDataItem];
};

export const createAllPanesChartAssetData = ({
  assetEntities,
  currentResamplePrices,
  existingSnakeMetas,
  primaryEntity,
  primaryChartData,
  colors,
  hidden,
  episodes,
}: {
  assetEntities: Entity[];
  currentResamplePrices: Array<TimeSeriesItem[] | undefined>;
  existingSnakeMetas: SnakeMeta[];
  primaryEntity: Entity;
  primaryChartData: ChartAssetData;
  colors: ChartColors;
  hidden?: string[];
  episodes?: DetailedInsight['episodes'];
}) => {
  const multiColors = colors.getMulti(assetEntities.length);
  return assetEntities.reduce((res, entity, idx) => {
    const ts = currentResamplePrices[idx];

    if (ts?.length) {
      const colorInMulti =
        multiColors[idx]?.token ??
        multiColors[assetEntities.length - 1 - idx].token;

      const chartDataItem =
        entity === primaryEntity
          ? primaryChartData
          : createChartDataItem({
              entity,
              ts,
              lineColorToken: isSnakeWithThreshold(existingSnakeMetas[idx])
                ? colors.default.token
                : colorInMulti,
              primaryAsset: primaryChartData,
              isHidden: hidden?.includes(entity.default_snake),
              snakeMeta: existingSnakeMetas[idx],
              episodes,
            });

      res.push(chartDataItem);
    }

    return res;
  }, [] as ChartAssetData[]);
};

export const emptyChartState: ChartState = {
  chartPanes: [
    {
      id: v4(),
      chartAssetData: [],
      seriesType: 'line',
      yAxisType: 'split',
      priceDisplay: 'price',
    },
  ],
  resample: ResampleIntervals.OneDay,
  horizon: null,
  domain: null,
};

export const getNewLineColorToken = ({
  colors,
  indicator,
  chartData,
}: {
  colors: ChartColors;
  indicator: SnakeMeta;
  chartData: ChartAssetData[];
}) =>
  isSnakeWithThreshold(indicator)
    ? colors.default.token
    : getLineColorToken({ chartData, colors });

export const getTargetPaneIndex = ({
  chartData,
  chartPanes,
  isSinglePrice,
  assetToChange,
  indicator,
  primaryPaneId,
}: {
  chartPanes: PaneData[];
  indicator: SnakeMeta;
  chartData: ChartAssetData[];
  isSinglePrice: boolean;
  assetToChange?: Entity;
  primaryPaneId: string;
}) => {
  if (!chartData.length) {
    return 0;
  }

  const similarPaneIndex = getSimilarPane({
    chartPanes,
    assetToChange,
    indicator,
  });

  const fallbackIndex = isSinglePrice
    ? chartPanes.length
    : chartPanes.findIndex(p => p.id === primaryPaneId);

  return similarPaneIndex === -1 ? fallbackIndex : similarPaneIndex;
};

export const getUpdatedPaneData = ({
  targetPane,
  chartAssetData,
  isSinglePrice,
}: {
  targetPane: PaneData | undefined;
  chartAssetData: ChartAssetData[];
  isSinglePrice: boolean;
}): PaneData => {
  if (targetPane) {
    const seriesType = isSinglePrice ? 'line' : targetPane.seriesType;
    return {
      ...targetPane,
      chartAssetData,
      seriesType,
    };
  }

  return {
    id: v4(),
    chartAssetData,
    seriesType: 'line',
    priceDisplay: 'price',
    yAxisType: 'merged',
  };
};
