import { Country } from '@toggle/toggle';
import React from 'react';

import { KnowledgeGraphModule } from '~/modules/knowledge-graph-module/KnowledgeGraphModule';

import { CountryListItem } from './country-list-item/CountryListItem';

type CountrySectionProps = {
  countries: (Country & { tooltip?: string })[];
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  title: string;
  tooltip: string;
  collapsedHeight: number;
};

export const CountrySection = ({
  countries,
  title,
  tooltip,
  collapsedHeight = 350,
  isLoading,
  isEmpty,
  isError,
}: CountrySectionProps) => {
  return (
    <KnowledgeGraphModule
      title={title}
      tooltip={tooltip}
      collapsedHeight={collapsedHeight}
      isLoading={isLoading}
      isEmpty={isEmpty}
      isError={isError}
    >
      {countries.map(country => (
        <CountryListItem key={country.code} country={country} />
      ))}
    </KnowledgeGraphModule>
  );
};
