import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const ChoiceChipsRoot = styled.div`
  ${borderRadius.r2};
  display: flex;
  padding: 4px;
  background-color: var(--surface-default);
  gap: 4px;
`;
