import React, { FC } from 'react';

import { Markdown } from '~/components/markdown/Markdown';

import { MarkdownViewer } from '../DocumentViewer';

export const DocumentMarkdownViewer: FC<MarkdownViewer> = ({
  data,
  highlightTexts,
}) => {
  const regexp = new RegExp(highlightTexts.map(t => t.text).join('|'), 'g');
  const withHighlightedParts = data.replace(regexp, '`$&`');
  const primaryText = highlightTexts.find(t => t.isPrimary)?.text;

  return (
    <Markdown
      message={withHighlightedParts}
      components={{
        code: t => {
          const isPrimary = t.children === primaryText;
          return (
            <mark className={isPrimary ? 'primary' : undefined}>
              {t.children}
            </mark>
          );
        },
      }}
    />
  );
};
