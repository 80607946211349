import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ActionLink } from '~/components/link/link.styles';
import { getTrialEndsMailLink } from '~/components/notification-alerts/utils/notification-alert-utils';
import { config } from '~/config';

export interface MailToSupportProps {
  isProfessional?: boolean;
}

export const MailToSupport = ({ isProfessional }: MailToSupportProps) => {
  const { t } = useTranslation('subscription');

  const mailToLink = getTrialEndsMailLink(t, isProfessional);

  if (isProfessional) {
    return (
      <Trans
        t={t}
        i18nKey={'subscription:alerts.trialEnds.partnershipsMessage'}
        components={[<ActionLink key="key-0" href={mailToLink} />]}
        values={{
          email: config.partnershipsEmail,
        }}
      />
    );
  }

  return (
    <Trans
      t={t}
      i18nKey={'subscription:alerts.trialEnds.supportMessage'}
      components={[<ActionLink key="key-0" href={mailToLink} />]}
      values={{
        email: config.supportEmail,
      }}
    />
  );
};
