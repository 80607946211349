import { Entity, EntitySearchType } from '@toggle/toggle';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchAssetItem } from '~/components/search-asset-item/SearchAssetItem';
import { SearchModalBase } from '~/components/search-modal-base/SearchModalBase';
import { useEntities } from '~/hooks/use-entities';
import { useTagsSearch } from '~/hooks/use-tags-search/useTagsSearch';

import * as S from './AssetSearchModal.styles';

export interface AssetSearchModalProps {
  isOpen: boolean;
  tagsAddedViaFilters?: string[];
  tagsAddedViaSearch?: string[];
  pageSearchResultsLimit?: number;
  onClose: () => void;
  setPageEntityTags: ({
    tagsAddedViaSearch,
    tagsAddedViaFilters,
  }: {
    tagsAddedViaSearch?: string[];
    tagsAddedViaFilters?: string[];
  }) => void;
}

export const AssetSearchModal: FC<AssetSearchModalProps> = ({
  isOpen,
  tagsAddedViaFilters = [],
  tagsAddedViaSearch = [],
  pageSearchResultsLimit = 30,
  setPageEntityTags,
  onClose,
}) => {
  const { t } = useTranslation('widget');
  const [searchQuery, setSearchQuery] = useState('');

  const allAddedTags = tagsAddedViaFilters.concat(tagsAddedViaSearch);
  const [displayedTags] = useState(allAddedTags.slice(0, 30));

  const { data: activeEntitiesData, isLoading: activeEntitiesIsLoading } =
    useEntities(displayedTags);

  const {
    tags: tagList,
    loading,
    searchTags,
  } = useTagsSearch({
    searchType: EntitySearchType.Entity,
  });

  const handleSearch = (value: string) => {
    searchTags(value);
    setSearchQuery(value);
  };

  const handleSelectItem = (entity: Entity) => {
    const isAlreadyInList = allAddedTags.includes(entity.tag);

    if (isAlreadyInList) {
      if (tagsAddedViaFilters.includes(entity.tag)) {
        setPageEntityTags({
          tagsAddedViaFilters: tagsAddedViaFilters.filter(
            tag => tag !== entity.tag
          ),
        });
      }

      if (tagsAddedViaSearch.includes(entity.tag)) {
        setPageEntityTags({
          tagsAddedViaSearch: tagsAddedViaSearch.filter(
            tag => tag !== entity.tag
          ),
        });
      }
    } else {
      setPageEntityTags({
        tagsAddedViaSearch: [...tagsAddedViaSearch, entity.tag],
      });
    }
  };

  const dropdownItems = !searchQuery ? activeEntitiesData : tagList;

  const andMoreAssetsCount =
    tagsAddedViaFilters.length +
    tagsAddedViaSearch.length -
    pageSearchResultsLimit;

  return (
    <SearchModalBase<Entity>
      data-testid="scenario-add-asset-search"
      isOpen={isOpen}
      isLoading={loading || activeEntitiesIsLoading}
      shouldCloseOnSelect={false}
      onClose={() => {
        onClose();
        setSearchQuery('');
      }}
      title={t('widget:search.addAsset')}
      dropdownItems={dropdownItems}
      selectItem={handleSelectItem}
      searchValue={searchQuery}
      value={searchQuery}
      handleSearch={handleSearch}
      placeholder={
        allAddedTags.length ? '' : t('widget:search.placeholderAssetOnly')
      }
      renderDropdownItem={(entity, _, isFocused) => (
        <SearchAssetItem
          entity={entity}
          isFocused={isFocused}
          highlighted={searchQuery}
          isAdded={allAddedTags.includes(entity.tag)}
        />
      )}
      noSearchResultsLabel={t('widget:search.noSearchResultsAssetOnly')}
      afterInListNode={
        andMoreAssetsCount > 0 && (
          <S.AndMoreAssetLabel>
            {t('widget:search.andMoreAssets', { count: andMoreAssetsCount })}
          </S.AndMoreAssetLabel>
        )
      }
    />
  );
};
