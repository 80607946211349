import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  color: var(--text-primary);

  ${Button.Styled.Label} {
    gap: 4px;
  }
`;
