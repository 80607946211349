import { ThemeGlobalStyles } from '@toggle/design-system';
import React from 'react';

import { useThemeStore } from './stores/useThemeStore';

export const ThemeController = () => {
  const themeName = useThemeStore(state => state.themeName);

  return <ThemeGlobalStyles $themeName={themeName} />;
};
