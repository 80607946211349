import {
  borderRadius,
  Button,
  ButtonGroup,
  SmartTable,
  typographyV2,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Title = styled.h2`
  ${typographyV2.titleBase};
  color: var(--text-default);
  margin-bottom: 16px;
`;

export const ChartImage = styled.img`
  margin-bottom: 8px;
`;

export const NumericalValue = styled.span`
  font-family: var(--font-roboto-mono);
`;

export const Source = styled.p`
  ${typographyV2.bodySm};
  text-align: right;
  margin-bottom: 32px;
  color: var(--text-soft);
`;

export const TableRoot = styled.div`
  ${SmartTable.Styled.TableRow} {
    cursor: default;
  }
`;

export const PastEpisodesButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButtonGroup = styled(ButtonGroup)<{
  $activeIndex: number;
}>`
  width: 271px;
  ${borderRadius.r3};
  border-width: 0px;
  background-color: var(--surface-default);

  ${ButtonGroup.Styles.ButtonGroupItem} {
    ${({ $activeIndex }) =>
      css`
        &:nth-child(${$activeIndex + 1}) {
          ${borderRadius.r2};
          border: 1px solid var(--text-primary);
          background-color: unset;
        }
      `}
  }
`;

export const StyledButton = styled(Button)`
  background-color: var(--surface-default);
`;

export const ChartWrapper = styled.div`
  margin-top: 32px;
`;

export const PastEpisodeKey = styled.span`
  ${borderRadius.r05};
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 6px;
  width: 14px;
  height: 14px;
  background: var(--surface-primary);
`;
