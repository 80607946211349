import React from 'react';
import { useTranslation } from 'react-i18next';

import { useScreenerDropdownItems } from '~/hooks/use-screener-dropdown-items/useScreenerDropdownItems';

import { ThemeLogoIcon } from '../theme-logo-icon/ThemeLogoIcon';
import * as S from './ThemeHeader.styles';

interface ThemeHeaderProps {
  themeName: string;
  themeId: string;
  logo_url?: string;
  isCondensed?: boolean;
}

export const ThemeHeader = ({
  themeName,
  themeId,
  logo_url,
  isCondensed = false,
}: ThemeHeaderProps) => {
  const { t } = useTranslation('widget');

  const { addToCurrentScreenItem, createNewScreenItem } =
    useScreenerDropdownItems();
  const dropdownItems = [
    addToCurrentScreenItem({
      name: themeName,
      id: themeId,
      key: 'theme_exposure',
    }),
    createNewScreenItem({
      name: themeName,
      id: themeId,
      key: 'theme_exposure',
    }),
  ];

  return (
    <S.FlexRowWrapper>
      <S.LogoAndTitleWrapper>
        <ThemeLogoIcon
          logo_url={logo_url}
          name={themeName}
          key={themeName}
          isCondensed={isCondensed}
        />
        <S.FlexColumnWrapper>
          <S.TitleText $isCondensed={isCondensed}>{themeName}</S.TitleText>
          {!isCondensed && (
            <S.SubtitleText>
              {t('themeOverview.investmentTheme')}
            </S.SubtitleText>
          )}
        </S.FlexColumnWrapper>
      </S.LogoAndTitleWrapper>
      <S.StyledPillDropdown
        label={t('themeOverview.addToScreener')}
        variant="buttonPrimary"
        shouldShowMoreButton={false}
        dropdownItems={dropdownItems}
        renderIcon={() => <S.StyledIcon iconName="Screener" size={16} />}
      />
    </S.FlexRowWrapper>
  );
};
