import { deviceConstraints, typographyV2 } from '@toggle/design-system';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';

import { PasswordInput } from '~/components/forms/password-input/PasswordInput';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';

const wrapperCss = css`
  padding: 40px;
  @media ${deviceConstraints.smartphone} {
    background: transparent;
    padding: 14px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  align-items: center;

  @media ${deviceConstraints.tablet} {
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;

  @media ${deviceConstraints.tablet} {
    min-height: 620px;
  }
`;

export const ErrorContainer = styled.div`
  width: 100%;
  border-radius: 4px;
`;

export const ErrorView = styled.section`
  display: flex;
  flex-direction: column;
`;

export const BackToLoginLink = styled(Link)`
  margin-top: 24px;
  align-self: center;
`;

export const NoLongerValidWrapper = styled(AuthSharedStyles.WrapperWithBg)`
  ${wrapperCss}
`;

export const Form = styled.form`
  ${AuthSharedStyles.wrapperCss};
  ${wrapperCss};
  border-radius: 12px;
`;

export const ErrorTitle = styled.h3`
  ${typographyV2.bodyBase}
  margin-bottom: 40px;
`;

export const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: 20px;
`;
