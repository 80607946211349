/* eslint-disable max-lines-per-function */
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { RangeHorizon } from '@toggle/chart';
import { TabLinesProps } from '@toggle/design-system';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import {
  FilterResultMethod,
  FilterResults,
  FilterResultsCategoryData,
  mapEntity,
  MappedEntity,
  screener,
  TSDatum,
} from '@toggle/toggle';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { createEntityQueryOptions } from '~/hooks/use-entities';
import { FilterGroups } from '~/hooks/use-filter-actions/useFilterActions';
import { QUERY_KEY_SNAKES_BY_NAMES } from '~/hooks/use-snake/useSnake';
import { fetchSnakeByName } from '~/services/overview-widget/overview-widget-service';
import { wretchRequest } from '~/utils/api-fetch/apiFetch';

import { mapFilters } from '../use-screener-store/filter-utils';
import { useScreenerStore } from '../use-screener-store/useScreenerStore';
import { SCREENER_TICKER_COLUMN_ID } from './screener-events-table/utils/column-utils';
import { eventToDisplayName } from './screener-events-table/utils/types';
import {
  ColumnMetaWithCategory,
  DEFAULT_TAB,
  FilterQueryResultData,
  getCategoryData,
  getNewTableTab,
  getNumDaysForHorizon,
  getOrderedMetaFields,
  getPinnedColumnsMeta,
  KEY_DEV_TAB,
  TableData,
  tableDataQueryUpdater,
} from './screener-table-utils';

export type ScreenerTableData = {
  entity: MappedEntity;
  ts: TSDatum[] | undefined;
} & Record<string, FilterResultMethod>;

export type UseScreenerTableReturn = ReturnType<typeof useScreenerTable>;

const QUERY_KEY_SCREENER_RESULTS = 'QUERY_KEY_SCREENER_RESULTS';
const QUERY_KEY_TABLE_DATA = 'QUERY_KEY_TABLE_DATA';
const PAGE_SIZE = 15;

export const useScreenerTable = ({
  filters,
  horizon,
}: {
  filters: ActiveFilterOptions[];
  horizon: RangeHorizon;
}) => {
  const {
    filterGroups,
    handlePinnedColumns,
    handleColumnOrdering,
    handleColumnSorting,
    tableState: { sorting, columnOrdering, pinnedColumns },
    resetTableState,
    hasTableChanges,
    activeSavedScreen,
    customDefaultTableTab,
    setCustomDefaultTableTab,
  } = useScreenerStore();
  const [tab, setTab] = useState(customDefaultTableTab ?? DEFAULT_TAB);
  const kgOptions = filterGroups['Knowledge Graph Filters'].activeFilterOptions;

  const prevFilterGroups =
    useRef<FilterGroups<'Knowledge Graph Filters' | 'Market Filters'>>(
      undefined
    );

  const queryClient = useQueryClient();
  const staleTime = getTimeTillEndOfDayMs();
  const nDays = getNumDaysForHorizon(horizon);

  const requestFilters = useMemo(() => mapFilters(filters), [filters]);

  const filterResultQueryResult = useInfiniteQuery<FilterQueryResultData>({
    queryKey: [QUERY_KEY_SCREENER_RESULTS, requestFilters, sorting],
    queryFn: async ({ signal, pageParam }) => {
      const filterResults = await wretchRequest<FilterResults>(
        screener.postFilter.path,
        {
          method: 'post',
          body: {
            filters: requestFilters,
            page: {
              number: pageParam,
              size: PAGE_SIZE,
            },
            ...(sorting.length && {
              sort_by: {
                field: sorting[0].id,
                desc: sorting[0].desc,
              },
            }),
          },
          signal,
        }
      );

      const tickerColumnData = await fetchTickerCellData({
        tags: filterResults.entities,
        staleTime,
        nDays,
        queryClient,
      });
      return {
        filterResults,
        tickerColumnData,
      };
    },
    initialPageParam: 1,
    staleTime,
    enabled: !!Object.keys(requestFilters).length,
    getNextPageParam: lastData => lastData.filterResults.page.next,
  });

  const categoryData = getCategoryData(filterResultQueryResult.data, tab);
  const defaultTabOrdering = categoryData
    ? [SCREENER_TICKER_COLUMN_ID, ...categoryData.meta.map(item => item.field)]
    : undefined;

  const isTableChanged = hasTableChanges(tab, defaultTabOrdering);

  const updateColumnsState = ({
    data,
    tab,
    resetToDefault,
  }: {
    data: InfiniteData<FilterQueryResultData> | undefined;
    tab: string;
    resetToDefault?: boolean;
  }) => {
    // Do not update column state if it is a screener event
    if (
      Object.values(eventToDisplayName).includes(tab) ||
      tab === KEY_DEV_TAB
    ) {
      return;
    }

    const latestData = data?.pages[data.pages.length - 1];

    if (!latestData || !latestData.filterResults.entities.length) {
      return;
    }

    const categoryData = latestData.filterResults.data.find(
      d => d.category === tab
    ) as FilterResultsCategoryData;
    const defaultPinnedCols =
      activeSavedScreen?.config.tableState.pinnedColumns ?? {};
    const pinnedColumnsMeta = getPinnedColumnsMeta({
      filterResults: data.pages[0]?.filterResults,
      pinnedColumns: resetToDefault ? defaultPinnedCols : pinnedColumns,
      categoryData,
    });
    const queryKey = [QUERY_KEY_TABLE_DATA, requestFilters, sorting];
    queryClient.setQueryDefaults(queryKey, {
      gcTime: Infinity,
    });
    queryClient.setQueryData<TableData>(
      queryKey,
      tableDataQueryUpdater({ data, tab, pinnedColumnsMeta })
    );

    const meta: ColumnMetaWithCategory[] = [
      ...categoryData.meta.map(item => ({ ...item, category: tab })),
      ...pinnedColumnsMeta,
    ];

    const defaultColumnOrder =
      activeSavedScreen?.config.tableState.columnOrdering[tab];
    const columnOrder = columnOrdering[tab];
    const newOrder = getOrderedMetaFields({
      columnOrder,
      meta,
      resetToDefault,
      defaultColumnOrder,
    });
    const shouldUpdateOrder =
      resetToDefault ||
      !columnOrder?.length ||
      newOrder.some(id => !columnOrder.includes(id));

    if (shouldUpdateOrder) {
      handleColumnOrdering({
        tab,
        fields: newOrder,
      });
    }
  };

  useLayoutEffect(() => {
    prevFilterGroups.current = undefined;

    return () => {
      setCustomDefaultTableTab();
      setTab(DEFAULT_TAB);
    };
  }, [activeSavedScreen]);

  useLayoutEffect(() => {
    const haveKGFiltersChanged = !!kgOptions.length;

    if (haveKGFiltersChanged) {
      const newSort = sorting.filter(
        sort =>
          !(
            sort.id.includes('theme_exposure') ||
            sort.id.includes('country_exposure')
          )
      );
      handleColumnSorting(newSort);
    }
  }, [kgOptions]);

  //important, make all state updates with the queryClient.setQueryData at once
  useLayoutEffect(() => {
    if (!filterResultQueryResult.data) {
      return;
    }

    const categoryData = getCategoryData(filterResultQueryResult.data, tab);

    const newTab = getNewTableTab({
      categoryData,
      prevFilterGroups: prevFilterGroups.current,
      filterGroups,
    });
    prevFilterGroups.current = filterGroups;

    setTab(newTab);
    updateColumnsState({
      data: filterResultQueryResult.data,
      tab: newTab,
    });
  }, [filterResultQueryResult.data]);

  const onTabChange: TabLinesProps<string>['onChange'] = (_, tab) => {
    setTab(tab);
    updateColumnsState({ data: filterResultQueryResult.data, tab });
  };

  const onPinChange = (columnsMeta: ColumnMetaWithCategory[], pin: boolean) => {
    handlePinnedColumns({
      columnsMeta,
      pin,
    });
  };

  const onColumnOrderChange = (fields: string[]) => {
    handleColumnOrdering({ tab, fields });
  };

  const tableData = queryClient.getQueryData<TableData | undefined>([
    QUERY_KEY_TABLE_DATA,
    requestFilters,
    sorting,
  ]);

  const resetTable = () => {
    resetTableState(tab);
    const shouldResetToDefault =
      !activeSavedScreen?.config.tableState.columnOrdering[tab] ||
      tab == DEFAULT_TAB;
    updateColumnsState({
      data: filterResultQueryResult.data,
      tab,
      resetToDefault: shouldResetToDefault,
    });
  };

  const filterResults = filterResultQueryResult.data?.pages[0]?.filterResults;
  const pinnedColumnsMeta =
    filterResults &&
    getPinnedColumnsMeta({
      filterResults,
      pinnedColumns,
      categoryData,
    });
  const columnsMeta: ColumnMetaWithCategory[] = categoryData
    ? [
        ...categoryData.meta.map(item => ({ ...item, category: tab })),
        ...(pinnedColumnsMeta || []),
      ]
    : [];

  return {
    filterResultQueryResult,
    tableData,
    onTabChange,
    activeTab: tab,
    sorting,
    handleColumnSorting,
    columnsMeta,
    columnOrdering,
    pinnedColumns,
    onColumnOrderChange,
    onPinChange,
    resetTable,
    isTableChanged,
  };
};

type FetchTickerCellDataProps = {
  tags: string[];
  staleTime: number;
  nDays: number;
  queryClient: QueryClient;
};

export const fetchTickerCellData = async ({
  tags,
  staleTime,
  nDays,
  queryClient,
}: FetchTickerCellDataProps) => {
  const entityPromises = tags.map(tag =>
    queryClient.fetchQuery(createEntityQueryOptions(tag))
  );
  const entities = await Promise.all(entityPromises);
  const mappedEntities = entities
    .filter(e => e.data)
    .map(e => mapEntity(e.data!));

  const tsPromises = mappedEntities.map(e =>
    queryClient.fetchQuery({
      queryKey: [
        QUERY_KEY_SNAKES_BY_NAMES,
        { snakeName: e.default_snake, nDays },
      ],
      queryFn: async () => {
        const tsResponse = await fetchSnakeByName(e.default_snake, nDays);
        return tsResponse.data?.result.data ?? [];
      },
      staleTime,
    })
  );
  const ts = await Promise.all(tsPromises);

  return {
    mappedEntities,
    ts,
  };
};
