import { borderRadius } from '@toggle/design-system';
import styled from 'styled-components';

export const InsightSummaryRoot = styled.div`
  display: flex;
`;

export const InsightCardWrapper = styled.div`
  ${borderRadius.r2};
  padding: 12px 16px;
  border: 1px solid var(--border-soft);
  width: 100%;
`;
