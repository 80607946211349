import { Entity } from '@toggle/toggle';
import React from 'react';

import { SectionTitle } from '~/components/section-title/SectionTitle';

import { CompanyListItem } from './company-list-item/CompanyListItem';
import * as S from './PeerSection.styles';

type PeerSectionProps = {
  data: {
    id: string;
    entity: Entity;
    label: string;
    description: string;
    tooltip?: string;
  }[];
  title: string;
  tooltip: string;
  onPillClick: (entityTag: string) => void;
};

export const PeerSection = ({
  data,
  title,
  tooltip,
  onPillClick,
}: PeerSectionProps) => {
  if (!data.length) {
    return null;
  }

  return (
    <S.StyledCompetitorVendorRoot>
      <SectionTitle title={title} tooltip={tooltip} />
      {data.map(company => (
        <S.CompetitorVendorListItem key={company.id} data-testid="peer-section">
          <CompanyListItem
            key={company.id}
            company={company}
            onPillClick={onPillClick}
          />
          <S.StyledListDescription>
            {company.description}
          </S.StyledListDescription>
        </S.CompetitorVendorListItem>
      ))}
    </S.StyledCompetitorVendorRoot>
  );
};
