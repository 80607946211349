import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  earnings,
  EarningsFilterRequest,
  EarningsFilterResponse,
  EarningsItemWithEntity,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

import { fetchEntities } from '../use-screener-events/utils';
import { groupEntriesByDate } from '../utils/grouping-utils';

const QUERY_KEY_EARNINGS_FILTER = 'QUERY_KEY_EARNINGS_FILTER';

export const useEarningsFilter = (
  body: EarningsFilterRequest,
  entriesPerDateLimit?: number
) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [QUERY_KEY_EARNINGS_FILTER, body],
    queryFn: async ({ signal }) => {
      const { path, schema } = earnings.postEarningsFilter;
      const earningsFilterResponse =
        await wretchRequest<EarningsFilterResponse>(path, {
          method: 'post',
          body,
          schema,
          signal,
        });
      const { earnings: earningsData } = earningsFilterResponse;

      const groupedEarningsData = groupEntriesByDate<EarningsItemWithEntity>(
        earningsData,
        'reporting_date',
        entriesPerDateLimit
      );

      const entityTags = Object.values(groupedEarningsData)
        .flat()
        .map(earning => earning.entity_tag);

      const entities = await fetchEntities(queryClient, entityTags);
      const entitiesMap = new Map(entities.map(entity => [entity.tag, entity]));

      const earningsWithEntities = earningsData.map(earning => {
        const entity = entitiesMap.get(earning.entity_tag);
        return entity ? { ...earning, entity } : earning;
      });

      return groupEntriesByDate<EarningsItemWithEntity>(
        earningsWithEntities,
        'reporting_date'
      );
    },
  });
};
