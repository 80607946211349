import { ScreenerFilterOption } from '@toggle/toggle';
import React from 'react';

import { isOptionExpanded } from '../../utils';
import { ChildNode } from '../ChildNode/ChildNode';
import { Node } from '../Node/Node';
import * as S from './TreeNode.styles';

interface TreeNodeProps {
  option: ScreenerFilterOption;
  level?: number;
  selectedOptions: ScreenerFilterOption[];
  searchQuery: string;
  expandedItems: string[];
  onClickCaret: (values: string[], isExpanded: boolean) => void;
  onChangeCheckBox: (options: ScreenerFilterOption[], checked: boolean) => void;
}

export const TreeNode = ({
  option,
  level = 0,
  selectedOptions,
  searchQuery,
  expandedItems,
  onClickCaret,
  onChangeCheckBox,
}: TreeNodeProps) => {
  const hasChildren = !!option.children;
  const nextLevel = hasChildren ? level + 1 : level + 2;

  return (
    <S.TreeNodeContainer data-testid="tree-node">
      <Node
        option={option}
        displayCaret={hasChildren}
        level={nextLevel}
        selectedOptions={selectedOptions}
        searchQuery={searchQuery}
        expandedItems={expandedItems}
        onClickCaret={onClickCaret}
        onChangeCheckBox={onChangeCheckBox}
      />
      {!!option.children && isOptionExpanded({ option, expandedItems }) && (
        <ChildNode
          level={nextLevel}
          selectedOptions={selectedOptions}
          searchQuery={searchQuery}
          expandedItems={expandedItems}
          onClickCaret={onClickCaret}
          onChangeCheckBox={onChangeCheckBox}
        >
          {option.children}
        </ChildNode>
      )}
    </S.TreeNodeContainer>
  );
};
