import {
  ChartAssetData,
  CreateChartOptionsWithConfig,
  EventsConfig,
  hasOHLC,
  HorizonWithPriceRange,
  i18n,
  PaneData,
  RangeHorizon,
  ViewController,
} from '@toggle/chart';
import { Button } from '@toggle/design-system';
import { Entity } from '@toggle/toggle';
import React, { useEffect, useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ChartAreaWrapperProps } from '~/components/chart-area-wrapper/ChartAreaWrapper';
import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { appPaths } from '~/routes/app-paths';
import { ChartState } from '~/views/turbo-chart/hooks/use-chart-state/useChartState';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';
import { useTurboChart } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';
import {
  ChartFullScreenSettings,
  ChartSettings,
} from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import { ChartArea } from './components/chart-area/ChartArea';
import { ChartHeader } from './components/chart-header/ChartHeader';
import { ChartLoading } from './components/chart-loading/ChartLoading';
import * as S from './SimpleChart.styles';
import { getPriceHorizonSettings } from './utils/simple-chart-utils';

export interface SimpleChartProps {
  entity?: Entity;
  initialChartSettings?: ChartSettings;
  isChatMessage?: boolean;
  shouldShowChartHeader?: boolean;
  hasLineColourBasedOnPrice?: boolean;
  isWidget?: boolean;
  fullScreenSetting?: ChartFullScreenSettings;
  chartConfig?: ChartAreaWrapperProps['chartConfig'];
  isLegacyVariant?: boolean;
  onClickFullscreen?: (chartState: ChartState) => void;
  maxHorizon?: RangeHorizon;
  eventsConfig?: EventsConfig;
}

const DEFAULT_SIMPLE_CHART_CONFIG: Partial<
  CreateChartOptionsWithConfig['config']
> = {
  enableZoom: false,
  enableHover: true,
  lineGradient: [
    { stop: 0, opacity: 0.5 },
    { stop: 1, opacity: 0.1 },
  ],
  hoverVariant: 'simple',
};
const EXCLUDED_HORIZONS = [RangeHorizon.TwoWeeks, RangeHorizon.TwoMonths];

export const SimpleChart = ({
  entity,
  initialChartSettings,
  isChatMessage = false,
  hasLineColourBasedOnPrice = true,
  chartConfig,
  isLegacyVariant = true,
  shouldShowChartHeader = true,
  onClickFullscreen,
  maxHorizon,
  eventsConfig = {
    TMI: false,
  },
}: SimpleChartProps) => {
  const [horizonWithPriceRanges, setHorizonWithPriceRanges] = useState<
    HorizonWithPriceRange[]
  >([]);
  const [isResampleDropdownOpen, setIsResampleDropdownOpen] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const location = useLocation();
  const { colors } = useChartTheme();
  const {
    assetWithError,
    chartData,
    enabledResamples,
    changeRange,
    changeSeriesType,
    chartApiRef,
    horizonRanges,
    hasError,
    hasEmpty,
    disable1DHorizon,
    dailyTimeseries,
    chartState,
    retryInitialChartLoad,
    primaryAsset,
    primaryPane,
    changeResample,
    onDomainChange,
  } = useTurboChart({
    initialChartSettings,
    shouldUseSearchParams: !initialChartSettings,
  });

  const { t } = useTranslation(['chart', 'analyze', 'countries', 'common']);

  const { horizon, resample, chartPanes } = chartState;
  const isAssetOverviewPage = location.pathname === appPaths.assetOverview;

  useEffect(() => {
    if (dailyTimeseries && chartData[0]) {
      const { rangesWithPrice, lineColorToken } = getPriceHorizonSettings({
        horizonRanges,
        dailyTimeseries,
        horizon,
        colors,
      });

      if (hasLineColourBasedOnPrice && lineColorToken) {
        chartData[0].lineColorToken = lineColorToken;
      }

      setHorizonWithPriceRanges(rangesWithPrice);
    }
  }, [dailyTimeseries]);

  useEffect(() => {
    if (horizonWithPriceRanges.length) {
      const defaultSeriesType = chartState.chartPanes[0].seriesType;
      changeSeriesType(
        horizon === RangeHorizon.OneDay ? 'candlestick' : defaultSeriesType,
        {}
      );
    }
  }, [horizonWithPriceRanges, horizon]);

  const isLoading = !hasError && !hasEmpty && !dailyTimeseries && !chartData[0];

  if (isLoading) {
    return (
      <ChartLoading
        isLegacyVariant={isLegacyVariant}
        isChatMessage={isChatMessage}
      />
    );
  }

  const onRangeChange = (horizon: RangeHorizon) => {
    if (hasLineColourBasedOnPrice) {
      const found = horizonWithPriceRanges.find(
        h => h.horizon === horizon
      ) as HorizonWithPriceRange;
      chartData[0].lineColorToken = found.lineColorToken;
    }

    changeRange(horizon);
  };

  const renderFullScreenButton = () => {
    if (!onClickFullscreen || !chartState.chartPanes.length) {
      return false;
    }

    const label = isAssetOverviewPage
      ? undefined
      : t('chart:fullScreenTooltip');
    return (
      <TooltipContainer
        placement="top-end"
        inPortal
        label={label}
        data-testid="tooltip-container"
      >
        <S.FullscreenButtonContainer>
          <Button
            variant="empty"
            iconName="Expand"
            size="small"
            onClick={() => onClickFullscreen(chartState)}
            data-testid="open-full-chart"
          />
        </S.FullscreenButtonContainer>
      </TooltipContainer>
    );
  };

  const finalChartConfig = {
    ...DEFAULT_SIMPLE_CHART_CONFIG,
    ...chartConfig,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <S.Container data-testid="simple-chart" $isChatMessage={isChatMessage}>
        {shouldShowChartHeader && (
          <ChartHeader
            isLegacyVariant={isLegacyVariant}
            isChatMessage={isChatMessage}
            chartData={chartData}
            entity={entity}
            chartConfig={{
              ...chartState,
              ...primaryPane,
            }}
            horizonWithPriceRanges={horizonWithPriceRanges}
            changeSeriesType={changeSeriesType}
            seriesType={chartPanes[0].seriesType}
            renderFullScreenButton={renderFullScreenButton}
            t={t}
          />
        )}
        <S.Body>
          {!isLegacyVariant && (
            <ViewController
              enabledResamples={enabledResamples}
              horizonRanges={horizonRanges.filter(
                range => !EXCLUDED_HORIZONS.includes(range)
              )}
              selectedRange={horizon}
              selectedResample={resample}
              paneData={chartPanes}
              onRangeChange={onRangeChange}
              onResampleChange={changeResample}
              onChartTypeChange={changeSeriesType}
              isResampleDropdownOpen={isResampleDropdownOpen}
              disableMenuBar={!chartData.length}
              onItemsShowChange={setIsResampleDropdownOpen}
              hasOHLC={hasOHLC(primaryAsset)}
              disable1DHorizon={disable1DHorizon}
              isSimpleChart
              renderFullScreenButton={renderFullScreenButton}
              maxHorizon={maxHorizon}
            />
          )}
          <S.StyledChartAreaWrapper
            assetWithError={assetWithError}
            hasError={hasError}
            hasEmpty={hasEmpty}
            colors={colors}
            chartData={chartData}
            chartConfig={finalChartConfig}
            onRetry={retryInitialChartLoad}
            renderChartArea={options =>
              horizonWithPriceRanges.length ? (
                <ChartArea
                  canvasRef={canvasRef}
                  chartApiRef={chartApiRef}
                  options={options}
                  chartState={chartState}
                  primaryAsset={primaryAsset as ChartAssetData}
                  primaryPane={primaryPane as PaneData}
                  onDomainChange={onDomainChange}
                  enablePriceHover={finalChartConfig.enableHover}
                  eventsConfig={eventsConfig}
                  isSimpleChart
                />
              ) : null
            }
          />
        </S.Body>
      </S.Container>
    </I18nextProvider>
  );
};
