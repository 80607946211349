import React from 'react';

import * as S from './DrawerSectionFooter.styles';

type DrawerSectionFooterProps = {
  label: string;
  onClick: () => void;
};

export const DrawerSectionFooter = ({
  label,
  onClick,
}: DrawerSectionFooterProps) => (
  <S.StyledButtonLink
    label={label}
    iconName={'ChevronLightRight'}
    iconPosition="right"
    onClick={onClick}
  />
);
