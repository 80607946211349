import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { documentEntity } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const QUERY_KEY_DOCUMENT_CONTENT = 'QUERY_KEY_DOCUMENT_CONTENT';

export const useDocumentContent = (documentId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_DOCUMENT_CONTENT, documentId],
    queryFn: ({ signal }) =>
      wretchRequest<Blob | string>(
        documentEntity.getDocumentContent.path(documentId),
        {
          signal,
        }
      ),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: !!documentId,
    refetchOnWindowFocus: false,
  });
