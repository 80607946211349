import { t } from 'i18next';
import React from 'react';

import { useEarningsStore } from '../../useEarningsStore/useEarningsStore';
import { getColumns } from '../../utils/tableUtils';
import { EarningsTable } from '../earnings-table/EarningsTable';
import * as S from './EarningsTableWrapper.styles';

interface EarningsTableWrapperProps {
  isError: boolean;
  isRefetchError: boolean;
  refetch: () => {};
  assetName: string;
}

export const EarningsTableWrapper = ({
  isError,
  isRefetchError,
  refetch,
  assetName,
}: EarningsTableWrapperProps) => {
  const {
    rowSelection,
    updateRowSelection,
    getIsAllRowsSelected,
    getIsSomeRowsSelected,
    toggleAllRowsSelection,
    getIsRowManualChanged,
    getIsRowMatchingFilters,
    earningsData,
  } = useEarningsStore();

  const columns = getColumns({
    getIsAllRowsSelected,
    getIsSomeRowsSelected,
    toggleAllRowsSelection,
    updateRowSelection,
    rowSelection,
    getIsRowManualChanged,
    getIsRowMatchingFilters,
    earningsData,
  });

  if (isError || isRefetchError) {
    return <S.StyledErrorMessageWrapper displayIcon onClick={refetch} />;
  }

  if (!earningsData?.length) {
    return (
      <S.StyledErrorMessageWrapper
        variant="empty"
        displayIcon
        title={t('widget:earnings.empty.title')}
        subTitle={t('widget:earnings.empty.subTitle', { asset: assetName })}
      />
    );
  }

  return <EarningsTable columns={columns} />;
};
