import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';

export const ScenarioTableRoot = styled.div`
  ${customScrollMinimal};
  overflow: auto;
  flex: 1 1 0;
  z-index: 1;
`;

export const Footer = styled.div`
  padding: 8px 0;
`;

export const StyledErrorMessageWrapper = styled(ErrorMessageWrapper)`
  text-align: center;
  border: none;
`;
