import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';

import { WatchlistManagerModal } from '~/modules/watchlist-manager-modal/WatchlistManagerModal';
import { SearchAssetWidget } from '~/widgets/search-asset-widget/SearchAssetWidget';

import { AssetOverviewModal } from '../../widgets/asset-overview-widget/AssetOverviewModal';
import { WidgetPathNames, widgetPaths } from './widget-utils';

export const Widgets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeWidget, setActiveWidget] = useState<WidgetPathNames | null>();

  useEffect(() => {
    validateWidgetParams();
  }, [searchParams]);

  const validateWidgetParams = () => {
    const widgetParam = searchParams.get('widget') as WidgetPathNames | null;
    setActiveWidget(widgetParam);
  };

  const handleClose = () => {
    const nextSearchParams = new URLSearchParams({});
    setSearchParams(nextSearchParams);
    setActiveWidget(null);
  };

  const widgetViews = {
    [widgetPaths.assetOverview]: {
      component: <AssetOverviewModal handleClose={handleClose} />,
    },
    [widgetPaths.searchAsset]: {
      component: <SearchAssetWidget />,
    },
    [widgetPaths.watchlistManagerModal]: {
      component: <WatchlistManagerModal />,
    },
  };

  if (!activeWidget || !widgetViews[activeWidget]?.component) {
    return null;
  }

  return <div>{widgetViews[activeWidget].component}</div>;
};
