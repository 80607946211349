import styled from 'styled-components';

export const SearchFiltersRoot = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--border-soft);
  margin: 0 8px;
`;
