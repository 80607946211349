import { Icon, WithNAFallback } from '@toggle/design-system';
import React from 'react';

import { formatNumberWithUnit } from '~/widgets/earnings/utils/utils';

import * as S from './MonetaryValueCell.styles';

export const MonetaryValueCell = ({
  value,
  currency = 'USD',
  includeIcon,
  shouldTruncate = false,
}: {
  value?: number;
  currency?: string;
  includeIcon?: boolean;
  shouldTruncate?: boolean;
}) => {
  const priceFormatter = new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <S.Root>
      {includeIcon && (
        <Icon fillColor="var(--icon-success)" iconName="Sun" size={12} />
      )}
      <WithNAFallback
        value={
          value && (
            <S.FlexRowWrapper>
              {shouldTruncate
                ? formatNumberWithUnit(value)
                : priceFormatter.format(value)}{' '}
              <S.CurrencyLabel>{currency}</S.CurrencyLabel>
            </S.FlexRowWrapper>
          )
        }
      />
    </S.Root>
  );
};
