import LetterIcon from '@static/images/icons/letter.svg';
import { Button, deviceConstraints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${deviceConstraints.tablet} {
    min-height: 620px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  align-items: center;

  @media ${deviceConstraints.tablet} {
    justify-content: center;
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const EmailSentWrapper = styled(AuthSharedStyles.WrapperWithBg)`
  @media ${deviceConstraints.smartphone} {
    padding: 38px 12px;
  }
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 100px;
  border-radius: 12px;
`;

export const ForgotPassWrapper = styled(AuthSharedStyles.WrapperWithBg)`
  border-radius: 12px;
  @media ${deviceConstraints.smartphone} {
    background: transparent;
    padding: 0;
  }
`;

export const Description = styled.p`
  ${typographyV2.bodyBase};
  margin-bottom: 32px;
`;

export const SuccessTitle = styled.h1`
  margin-bottom: 12px;
  ${typographyV2.titleXL};
`;

export const SuccessText = styled.p`
  margin-bottom: 12px;
  ${typographyV2.bodyBase};
`;

export const SuccessEmailWrapper = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--border-soft);
  background: var(--surface-default);
  ${typographyV2.bodyBase};
`;

export const BackToLoginButton = styled(Button)`
  align-self: center;
`;

export const LetterSvg = styled(LetterIcon)`
  position: absolute;
  top: -60px;
  left: 33%;
`;
