import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 18px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Title = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  text-transform: uppercase;
`;

export const FiltersContainer = styled.div<{ $expanded: boolean }>`
  display: ${props => (props.$expanded ? 'flex' : 'none')};
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 10px 0px 16px;
  border-bottom: 1px solid var(--border-soft);
`;

export const StyledButton = styled(Button)`
  & svg {
    fill: var(--icon-soft);
  }
`;
