import { Citation } from '@toggle/toggle';
import React, { FC, lazy, Suspense, useCallback, useRef } from 'react';

import { useDocumentContent } from '~/hooks/use-document-content/useDocumentContent';

import { HighlightTextConfig } from './document-highlight-utils/document-highlight-utils';
import { DocumentHtmlViewer } from './document-html-viewer/DocumentHtmlViewer';
import { DocumentLoader } from './document-loader/DocumentLoader';
import { DocumentMarkdownViewer } from './document-markdown-viewer/DocumentMarkdownViewer';
import * as S from './DocumentViewer.styles';
import { SingleDocLoader } from './single-doc-loader/SingleDocLoader';

export interface MarkdownViewer extends HighlightTextConfig {
  data: string;
}

export interface PdfViewer extends HighlightTextConfig {
  blob: Blob;
  selectedCitation: Citation;
}

export interface HtmlViewer extends HighlightTextConfig {
  data: string;
  name: string;
}

export interface DocumentViewerProps
  extends Omit<HighlightTextConfig, 'scrollToPrimaryText'> {
  selectedCitation: Citation;
}

const LazyDocumentPdfViewer = lazy(
  () => import('./document-pdf-viewer/DocumentPdfViewer')
);

export const DocumentViewer: FC<DocumentViewerProps> = ({
  highlightTexts,
  selectedCitation,
}) => {
  const ref = useRef<HTMLElement>(null);

  const { data, isError, isFetching } = useDocumentContent(
    selectedCitation.document_id
  );
  const scrollToPrimaryText = useCallback((fallback?: HTMLElement) => {
    const primaryMark = ref.current?.querySelector('mark.primary');
    const el = primaryMark || fallback;
    el?.scrollIntoView({ block: primaryMark ? 'center' : 'start' });
  }, []);

  const renderViewer = (data: Blob | string) => {
    if (data instanceof Blob) {
      return (
        <Suspense fallback={<DocumentLoader />}>
          <LazyDocumentPdfViewer
            highlightTexts={highlightTexts}
            blob={data}
            selectedCitation={selectedCitation}
            scrollToPrimaryText={scrollToPrimaryText}
          />
        </Suspense>
      );
    }

    if (selectedCitation.document_type === 'markdown') {
      return (
        <DocumentMarkdownViewer
          scrollToPrimaryText={scrollToPrimaryText}
          data={data}
          highlightTexts={highlightTexts}
        />
      );
    }

    return (
      <DocumentHtmlViewer
        scrollToPrimaryText={scrollToPrimaryText}
        data={data}
        name={selectedCitation.document_name}
        highlightTexts={highlightTexts}
      />
    );
  };

  if (isError) {
    return <S.StyledBaseErrorView />;
  }

  if (isFetching) {
    return <SingleDocLoader />;
  }

  return (
    <S.Container
      ref={ref}
      $type={selectedCitation.document_type}
      data-testid={`document-${selectedCitation.document_type}-viewer`}
    >
      {data && renderViewer(data)}
    </S.Container>
  );
};
