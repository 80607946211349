import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabsType } from '../utils';
import * as S from './EmptyState.styles';

export interface EmptyStateProps {
  activeTab: TabsType;
}

export const EmptyState = ({ activeTab }: EmptyStateProps) => {
  const { t } = useTranslation(['calendar']);

  return (
    <S.EmptyStateRoot data-testid="empty-state">
      <img src="/app/static/images/calendar/no-events.svg" alt="" />
      <S.Description data-testid="state-description">
        {/* i18next-extract-mark-context-next-line ["earnings", "economics", "company"]  */}
        {t('calendar:emptyState', {
          context: activeTab,
        })}
      </S.Description>
    </S.EmptyStateRoot>
  );
};
