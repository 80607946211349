import { RangeHorizon, ResampleIntervals } from '@toggle/chart';
import { Button } from '@toggle/design-system';
import { hasKeyValues } from '@toggle/helpers';
import { AssetSubClassCode, Entity, isEtf } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import { useRelatedCompanies } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { useSnakeMeta } from '~/hooks/use-snake/useSnake';
import { About } from '~/modules/about/About';
import { AssetInsightsList } from '~/modules/asset-insights-list/AssetInsightsList';
import { OverviewFinancials } from '~/modules/overview-financials/OverviewFinancials';
import { OverviewKeyValues } from '~/modules/overview-key-values/OverviewKeyValues';
import { OverviewKnowledgeGraph } from '~/modules/overview-knowledge-graph/OverviewKnowledgeGraph';
import { OverviewValuation } from '~/modules/overview-valuation-metrics/OverviewValuationMetrics';
import { ChartPrice } from '~/modules/simple-chart/components/chart-price/ChartPrice';
import { SimpleChart } from '~/modules/simple-chart/SimpleChart';
import { sortElementByEdgeWeight } from '~/utils/common/utils';
import { ChartSettings } from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import * as S from './AssetOverviewWidget.styles';
import { useAssetOverviewNavigation } from './hooks/use-asset-overview-navigation/useAssetOverviewNavigation';

export interface AssetOverviewWidgetProps {
  entity: Entity;
  isDrawer?: boolean;
  onClickInsight?: (insightId: string) => void;
}

export const AssetOverviewWidget = ({
  entity,
  isDrawer = false,
  onClickInsight,
}: AssetOverviewWidgetProps) => {
  const {
    data: relatedCompaniesData,
    isLoading,
    isError,
  } = useRelatedCompanies(entity.tag);

  const { data: snakeMeta } = useSnakeMeta(entity?.default_snake);
  const { t } = useTranslation(['widget', 'common']);
  const { navigateToAssetOverview, navigateToChart } =
    useAssetOverviewNavigation(entity);

  const sortedCompanies = sortElementByEdgeWeight(
    relatedCompaniesData?.competitors ?? []
  );
  const sortedSuppliers = sortElementByEdgeWeight(
    relatedCompaniesData?.suppliers ?? []
  );

  const chartSettings: ChartSettings = {
    assets: [
      {
        tag: entity.tag,
        snake: entity.default_snake,
        paneIndex: 0,
      },
    ],
    resample: ResampleIntervals.OneDay,
    horizon: RangeHorizon.ThreeMonths,
    domain: null,
    hidden: undefined,
    paneSettings: {},
  };

  const isStock = entity.sub_class === AssetSubClassCode.EquityStock;
  const entityIsEtf = isEtf(entity);
  const displayAbout = isStock || entityIsEtf;

  return (
    <S.Container data-testid="asset-overview-widget">
      <S.StyledEntityHeader
        entity={entity}
        truncateLongTitle={isDrawer}
        $isDrawer={isDrawer}
        isDrawer={isDrawer}
        headerButton={
          <Button
            iconName="Expand"
            label={t('widget:assetOverview.assetOverview')}
            onClick={navigateToAssetOverview}
            variant="tertiary"
            size="small"
          />
        }
      />
      <S.ContentContainer $isDrawer={isDrawer}>
        <S.ChartChipSelectorWrapper>
          <ChartPrice entity={entity} t={t} snakeMeta={snakeMeta} />
          <SimpleChart
            entity={entity}
            initialChartSettings={chartSettings}
            isLegacyVariant={false}
            onClickFullscreen={navigateToChart}
            maxHorizon={RangeHorizon.ThreeYears}
            eventsConfig={{
              TMI: true,
            }}
          />
        </S.ChartChipSelectorWrapper>
        {hasKeyValues(entity.tag) && (
          <S.StyledOverviewKeyValuesWrapper>
            <OverviewKeyValues entityTag={entity.tag} />
          </S.StyledOverviewKeyValuesWrapper>
        )}

        {isDrawer ? (
          <>
            {displayAbout && <About entity={entity} isModal />}
            {isStock && (
              <div>
                <OverviewKnowledgeGraph
                  entityTag={entity.tag}
                  isEtf={isEtf(entity)}
                  companiesData={{
                    competitors: relatedCompaniesData?.competitors ?? [],
                    suppliers: sortedSuppliers,
                    isLoading,
                    isError,
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <S.SplitContainer data-testid="split-container">
            <S.SplitLeftContainer>
              {displayAbout && <About entity={entity} isModal />}
            </S.SplitLeftContainer>
            {isStock && (
              <S.SplitRightContainer>
                <OverviewKnowledgeGraph
                  entityTag={entity.tag}
                  isEtf={isEtf(entity)}
                  companiesData={{
                    competitors: relatedCompaniesData?.competitors ?? [],
                    suppliers: sortedSuppliers,
                    isLoading,
                    isError,
                  }}
                />
              </S.SplitRightContainer>
            )}
          </S.SplitContainer>
        )}

        {isStock && (
          <>
            <OverviewFinancials
              entity={entity}
              competitorsData={{
                competitors: sortedCompanies,
                isLoading,
              }}
            />
            <OverviewValuation
              entity={entity}
              competitorsData={{
                competitors: sortedCompanies,
                isLoading,
              }}
            />
            <AssetInsightsList
              title={
                <SectionTitle
                  title={t('widget:assetOverview.insightSectionTitle')}
                />
              }
              entityTag={entity.tag}
              onClickInsight={onClickInsight}
            />
          </>
        )}
      </S.ContentContainer>
      <S.ButtonContainer>
        <Button
          iconName="Expand"
          iconPosition="left"
          label={t('widget:assetOverview.assetOverview')}
          size="small"
          variant="tertiary"
          fullWidth={false}
          onClick={navigateToAssetOverview}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};
