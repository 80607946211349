import { HighConvictionReportKeys, Period } from '@toggle/toggle';
import React, { useState } from 'react';

import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';
import { AssessmentWidget } from '~/widgets/assessment-widget/AssessmentWidget';
import { HorizonSelector } from '~/widgets/scenario/components/horizon-selector/HorizonSelector';
import {
  countReportTriggers,
  getQualityReports,
} from '~/widgets/scenario/utils/scenario-table-data/scenario-table-data';

import * as S from './ScenarioQuality.styles';

export interface ScenarioQualityProps {
  scenario: ScenariosData;
  bestHorizon: Period;
  horizons: string[];
  initHorizon: string;
  isFixedIncome: boolean;
}

export const ScenarioQuality = ({
  scenario,
  bestHorizon,
  horizons,
  initHorizon,
  isFixedIncome,
}: ScenarioQualityProps) => {
  const [activeHorizon, setActiveHorizon] = useState(initHorizon);

  const qualityReports = getQualityReports(scenario.report, activeHorizon);
  const gaugeValue = countReportTriggers(qualityReports);
  const activeHorizonIndex = horizons.indexOf(activeHorizon);

  const setActiveHorizonIndex = (index: number) =>
    setActiveHorizon(horizons[index]);

  const checklist = qualityReports.reduce((acc, report) => {
    acc[report.kind] = report.triggered;
    return acc;
  }, {} as Record<HighConvictionReportKeys, boolean>);

  const horizonLabels = horizons.map(horizon => (
    <S.SelectorWrapper key={horizon}>
      {horizon === bestHorizon && (
        <S.StyledIcon size={12} iconName="StarFill" />
      )}
      {horizon}
    </S.SelectorWrapper>
  ));

  return (
    <AssessmentWidget
      isFixedIncome={isFixedIncome}
      gaugeValue={gaugeValue}
      horizon={bestHorizon}
      checklist={checklist}
      titleElement={
        <HorizonSelector
          horizonLabels={horizonLabels}
          onClick={setActiveHorizonIndex}
          activeHorizonIndex={activeHorizonIndex}
        />
      }
    />
  );
};
