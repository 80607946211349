import { Tooltip, WithNAFallback } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './UnderwritersCell.styles';

export const UnderwritersCell = ({
  leadUnderwriters,
  otherUnderwriters,
}: {
  leadUnderwriters: string[];
  otherUnderwriters: string[];
}) => {
  const { t } = useTranslation('screener');

  const formattedLeadUnderwriters = (
    <WithNAFallback
      check={leadUnderwriters.length > 0}
      value={leadUnderwriters.join(', ')}
    />
  );

  const formattedOtherUnderwriters = otherUnderwriters.length
    ? otherUnderwriters.join(', ')
    : undefined;

  return (
    <S.Root>
      {formattedLeadUnderwriters}
      {formattedOtherUnderwriters && (
        <Tooltip
          label={formattedOtherUnderwriters}
          placement="bottom-start"
          hasArrow={false}
        >
          <S.StyledSpan>
            {t('screener:screenerEvents.moreUnderwriters', {
              count: otherUnderwriters.length,
            })}
          </S.StyledSpan>
        </Tooltip>
      )}
    </S.Root>
  );
};
