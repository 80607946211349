import { DependencyList, useLayoutEffect } from 'react';

export interface UseCondensedHeaderProps {
  getRootElement: () => HTMLElement | null;
  getTargetElement: () => HTMLElement | null;
  toggleCondensed: (next: boolean) => void;
  heightThreshold?: number;
  deps?: DependencyList;
}

const CHANGE_HEADER_HEIGHT = 10;

export const useCondensedHeader = ({
  getRootElement,
  getTargetElement,
  toggleCondensed,
  heightThreshold = CHANGE_HEADER_HEIGHT,
  deps = [],
}: UseCondensedHeaderProps) => {
  useLayoutEffect(() => {
    const rootElement = getRootElement();
    const targetElement = getTargetElement();

    if (!rootElement || !targetElement) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        const intersectionRatio = entry.intersectionRatio;
        toggleCondensed(entry.isIntersecting ? intersectionRatio < 1 : true);
      },
      {
        root: rootElement,
        rootMargin: '20px 0px 0px 0px',
        threshold: [heightThreshold / rootElement.scrollHeight, 1],
      }
    );

    observer.observe(targetElement);

    return () => observer.disconnect();
  }, deps);
};
