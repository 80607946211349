import NotFoundImg from '@static/images/errors/404-night.svg';
import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Main = styled.main`
  flex-grow: 1;
`;

export const MainHeading = styled.h1`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

export const NotFoundSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Image = styled(NotFoundImg)`
  max-height: 160px;
  margin-bottom: 36px;
`;

export const Title = styled.h2`
  ${typographyV2.title2XL};
  color: var(--text-primary);
  margin-bottom: 24px;
`;

export const Description = styled.p`
  ${typographyV2.bodyBase};
  color: var(--text-soft);
`;

export const LinkButton = styled(Button)`
  margin-top: 56px;
  margin-bottom: 144px;
`;
