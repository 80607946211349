import { CheckBox, SmartTableColumn } from '@toggle/design-system';
import { utcFormat } from 'd3';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatInputProps } from '~/components/chat';
import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { useDocumentsEntity } from '~/hooks/use-documents-entity/useDocumentsEntity';

import { EarningsChatInput } from '../earnings/components/earnings-chat-input/EarningsChatInput';
import * as Shared from '../earnings/Earnings.styles';
import * as S from './DocumentWidget.styles';
import { useDocumentWidgetStore } from './useDocumentWidgetStore';
import { DocumentTableItem, groupDocumentByYear, isFirstItem } from './utils';

export interface DocumentProps {
  entityTag: string;
  renderChatInput?: (props?: ChatInputProps) => ReactElement;
}

export const Document = ({ entityTag, renderChatInput }: DocumentProps) => {
  const { t } = useTranslation(['widget', 'asset-overview', 'chat', 'common']);

  const {
    data: documents,
    isError,
    refetch,
    isLoading,
    isRefetching,
  } = useDocumentsEntity(entityTag);

  const {
    initStore,
    rowSelection,
    getIsRowSelected,
    updateRowSelection,
    toggleAllRowsSelection,
    getIsAllRowsSelected,
    getIsSomeRowsSelected,
  } = useDocumentWidgetStore(state => ({
    initStore: state.initStore,
    rowSelection: state.rowSelection,
    getIsRowSelected: state.getIsRowSelected,
    updateRowSelection: state.updateRowSelection,
    toggleAllRowsSelection: state.toggleAllRowsSelection,
    getIsAllRowsSelected: state.getIsAllRowsSelected,
    getIsSomeRowsSelected: state.getIsSomeRowsSelected,
  }));

  const selectedDocuments = Object.entries(rowSelection)
    .filter(([, selected]) => !!selected)
    .map(([id]) => id);

  useEffect(() => {
    if (!isLoading && !isRefetching && !!documents?.length) {
      initStore(documents);
    }
  }, [isLoading, documents]);

  const docsGroupedByYear = groupDocumentByYear(documents);

  if (!documents?.length && (isLoading || isRefetching)) {
    return <S.StyledSkeletonLoader data-testid="document-widget-loader" />;
  }

  if (!documents?.length || isError) {
    return (
      <ErrorMessageWrapper
        displayIcon
        variant={isError ? 'error' : 'empty'}
        onClick={refetch}
      />
    );
  }

  const columns: SmartTableColumn<DocumentTableItem>[] = [
    {
      label: '',
      key: 'id',
      renderHeader: () => (
        <Shared.TableHeaderContent>
          <CheckBox
            name="all-documents"
            label=""
            onChange={(_, e) => toggleAllRowsSelection(e.target.checked)}
            checked={getIsAllRowsSelected() || getIsSomeRowsSelected()}
            partial={getIsSomeRowsSelected() && !getIsAllRowsSelected()}
          />
        </Shared.TableHeaderContent>
      ),
      render: props => {
        const id = props;
        return (
          <Shared.WrapperCheckbox>
            <S.StyledCheckBox
              name={`document-${id}`}
              label=""
              checked={getIsRowSelected(id)}
              onChange={() => updateRowSelection(id, !getIsRowSelected(id))}
            />
          </Shared.WrapperCheckbox>
        );
      },
    },
    {
      label: t('widget:assetOverview.tableDocuments.year'),
      key: 'year',
      renderCell: props => (
        <S.Cell key={props.column.key} $withBorder {...props} />
      ),
      render: (value, item) =>
        value !== 0 && isFirstItem(docsGroupedByYear, item) ? (
          <S.NumericalValue>{value}</S.NumericalValue>
        ) : (
          <span />
        ),
    },
    {
      label: t('widget:assetOverview.tableDocuments.calendar'),
      key: 'period',
      render: value => <S.NumericalValue>{value}</S.NumericalValue>,
    },
    {
      label: t('widget:assetOverview.tableDocuments.filingDate'),
      key: 'release_date',
      render: (_, document) => {
        const date = new Date(document.release_date);
        const formatTime = utcFormat('%Y-%m-%d')(date);
        return <S.NumericalValue>{formatTime}</S.NumericalValue>;
      },
    },
    {
      label: t('widget:assetOverview.tableDocuments.DocumentType'),
      key: 'kind',
    },
    {
      label: t('widget:assetOverview.tableDocuments.companyFiscal'),
      key: 'reference',
    },
    {
      label: t('widget:assetOverview.tableDocuments.file'),
      key: 'name',
      render: (_, document) => (
        <S.PdfLink href={document.location} target="_blank">
          <S.PdfWrapper>
            <S.PdfIcon src="/app/static/images/icons/pdf.svg" />
            {document.name}
          </S.PdfWrapper>
        </S.PdfLink>
      ),
    },
  ];

  return (
    <S.Root>
      <S.StyledSmartTable
        isHeaderSticky
        columns={columns}
        data={documents}
        renderActionColumn={() => <></>}
      />
      <EarningsChatInput
        renderChatInput={renderChatInput}
        documentIds={selectedDocuments}
      />
    </S.Root>
  );
};
