import { Button } from '@toggle/design-system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './ChartError.styles';

export interface ChartErrorProps {
  onRetry: () => void;
  supportEmail: string;
}

export const ChartError = ({ onRetry, supportEmail }: ChartErrorProps) => {
  const { t } = useTranslation(['chart']);

  return (
    <S.ChartErrorRoot data-testid="chart-error">
      <S.ErrorImage alt={t('chart:error.imgAlt')} />
      <S.Title>{t('chart:error.title')}</S.Title>
      <S.Description>
        <Trans
          i18nKey={'chart:error.description'}
          components={[
            <S.Link
              key="key-0"
              title={t('chart:error.linkAlt')}
              href={`mailto:${supportEmail}`}
            />,
          ]}
          values={{ email: supportEmail }}
        />
      </S.Description>
      <Button
        data-testid="chart-error-button"
        label={t('chart:tryAgain')}
        onClick={onRetry}
      />
    </S.ChartErrorRoot>
  );
};
