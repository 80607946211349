import { ChartColor } from '@toggle/chart';
import React from 'react';

import * as S from './MiniLegend.styles';

export interface MiniLegendProps {
  labels: string[];
  colors: ChartColor[];
}

export const MiniLegend = ({ labels, colors }: MiniLegendProps) => {
  return (
    <S.MiniLegendRoot data-testid="mini-legend">
      {labels.map((label, index) => {
        return (
          <S.Item key={label}>
            <S.Dot $color={colors[index].value} />
            <S.Label>{label}</S.Label>
          </S.Item>
        );
      })}
    </S.MiniLegendRoot>
  );
};
