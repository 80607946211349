import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Shared from '../Shared.styles';
import * as S from './ScenarioNoConditions.styles';

export interface ScenarioNoConditionsProps {
  conditionsCount: number;
  runConditionsOnClick: () => void;
  assetsCount: number;
  addConditionsOnClick?: () => void;
}

export const ScenarioNoConditions = ({
  conditionsCount,
  runConditionsOnClick,
  assetsCount,
  addConditionsOnClick,
}: ScenarioNoConditionsProps) => {
  const { t } = useTranslation('scenario');

  const getTitle = () => {
    return `${t('scenario:emptyState.assetsAnd', {
      count: assetsCount,
    })} ${t('scenario:emptyState.conditionsSelected', {
      count: conditionsCount,
    })}`;
  };

  return (
    <Shared.Container>
      <Shared.Img src="/app/static/images/icons/no-assets-found.svg" alt="" />
      {conditionsCount ? (
        <Shared.Description>
          <Shared.Title>{getTitle()}</Shared.Title>
          <Shared.StyledButton
            variant={'primary'}
            label={t('scenario:buttonLabels.runScenario')}
            data-testid="run-scenario"
            onClick={runConditionsOnClick}
          />
        </Shared.Description>
      ) : (
        <S.LabelAndButtonWrapper>
          <Shared.Description data-testid="scenario-no-conditions">
            <Shared.Title>
              {t('scenario:emptyState:noConditionsTitle')}
            </Shared.Title>
            <Shared.Message>
              {t('scenario:emptyState:noConditionsMessage')}
            </Shared.Message>
          </Shared.Description>
          {addConditionsOnClick && (
            <Shared.StyledButton
              data-testid="add-condition-button"
              variant={'secondary'}
              size="medium"
              label={t('scenario:emptyState.addCondition')}
              onClick={addConditionsOnClick}
            />
          )}
        </S.LabelAndButtonWrapper>
      )}
    </Shared.Container>
  );
};
