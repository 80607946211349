import { Drawer } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  ${Drawer.Styled.Body} {
    height: 100%;
  }
  ${Drawer.Styled.Container} {
    width: 512px;
  }
  ${Drawer.Styled.Header} {
    flex-direction: row-reverse;
  }
  ${Drawer.Styled.ButtonContainer} {
    gap: 0;
  }
`;
