import { borderRadius, SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

import { ChartAreaWrapper } from '~/components/chart-area-wrapper/ChartAreaWrapper';

export const Body = styled.section`
  position: relative;
  width: 100%;
`;

export const FullscreenButtonContainer = styled.div`
  display: flex;
  ${borderRadius.r2}
  padding: 4px;
  background-color: var(--surface-default);
  height: 40px;
`;

export const SkeletonHorizonLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '58px',
        borderRadius: '8px',
      },
    },
  ],
})``;

export const StyledChartAreaWrapper = styled(ChartAreaWrapper)`
  height: 240px;
`;

export const Container = styled.div<{ $isChatMessage: boolean }>`
  position: relative;
  width: 100%;

  ${props =>
    props.$isChatMessage &&
    `${StyledChartAreaWrapper} {
        height: 476px;
      }
    `}
`;
