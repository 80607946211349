import { StoreApi } from 'zustand';

import { create } from '~/stores/create-store/createStore';
import { livePriceWebSocket } from '~/stores/live-price-websocket/livePriceWebSocket';

import {
  Identifier,
  LivePriceData,
  LivePriceState,
  mapNewLivePriceDataToWatchedData,
  mapPriceEntityToLivePriceData,
  PriceEntity,
  priceUpdater,
  SubscribeConfig,
  updateWatching,
} from './useLivePriceStore.utils';

const unsubscribe =
  ({
    tickers,
    priceEntities,
    get,
    set,
    identifier,
  }: {
    tickers: string[];
    priceEntities: PriceEntity[];
    get: StoreApi<LivePriceState>['getState'];
    set: StoreApi<LivePriceState>['setState'];
    identifier: Identifier;
  }) =>
  () => {
    const { unsubscribe: unsubscribeWS } = livePriceWebSocket.getState();

    if (!tickers.length) {
      return;
    }

    priceEntities.forEach(({ entity }, index) => {
      const isDeleted = updateWatching(set, get, entity.ticker, identifier);

      if (isDeleted) {
        unsubscribeWS([tickers[index]]);
      }
    });

    const withoutTickers = Object.keys(
      get().componentsWatchingData
    ).reduce<LivePriceData>((livePriceData, key) => {
      return { ...livePriceData, [key]: get().livePriceData[key] };
    }, {});

    set({ livePriceData: withoutTickers });
  };

export const useLivePriceStore = create<LivePriceState>((set, get) => ({
  livePriceData: {},
  componentsWatchingData: {},
  subscribe: ({
    priceEntities,
    identifier = Identifier.Global,
    isPro,
  }: SubscribeConfig) => {
    const { livePriceData, componentsWatchingData } = get();

    const { data: newLivePriceData, tickers } = mapPriceEntityToLivePriceData(
      priceEntities,
      livePriceData
    );

    const watchingLivePriceData = mapNewLivePriceDataToWatchedData(
      priceEntities,
      newLivePriceData,
      identifier,
      componentsWatchingData
    );

    const { subscribe: subscribeWS } = livePriceWebSocket.getState();

    subscribeWS({ tickers, updateHandler: priceUpdater(set), isPro });

    set({
      livePriceData: { ...livePriceData, ...newLivePriceData },
      componentsWatchingData: watchingLivePriceData,
    });

    return unsubscribe({ tickers, priceEntities, get, set, identifier });
  },
}));
