import { ButtonGroup } from '@toggle/design-system';
import styled from 'styled-components';

import { PriceMovementChartRoot } from '~/components/price-movement/components/price-movement-chart/PriceMovementChart.styles';
import { Legend } from '~/components/price-movement/components/price-movement-legend/PriceMovementLegend.styles';

export const ScenarioPriceMovementRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${PriceMovementChartRoot} {
    gap: 0;
  }

  ${Legend} {
    border: none;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  background-color: var(--surface-default);
`;
