import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextBlock } from '~/components/text-block/TextBlock';
import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';

type InsightsReactionProps = {
  entityName: string;
  episodeCount: number;
  isFixedIncome: boolean;
};

export const InsightsReaction = ({
  entityName,
  episodeCount,
  isFixedIncome,
}: InsightsReactionProps) => {
  const { t } = useTranslation(['scenarioCommander', 'analyze']);

  /* i18next-extract-mark-context-next-line ["price", "yield"]*/
  const chartTitlePrefix = t('analyze:experiment.chart.chartTitle', {
    entityName,
    context: isFixedIncome ? 'yield' : 'price',
  });

  return (
    <TextBlock
      data-testid="insights-reaction"
      title={t('scenarioCommander:domino.reaction')}
      text={
        <span>
          {chartTitlePrefix}
          <TooltipContainer
            label={t('analyze:experiment.chart.tooltip')}
            showUnderline
          >
            <span>
              {t('analyze:experiment:chart.episodeCount', {
                count: episodeCount,
              })}
            </span>
          </TooltipContainer>
        </span>
      }
    />
  );
};
