import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@toggle/helpers';
import { screener, ScreenerFilterOption } from '@toggle/toggle';
import { ChangeEvent, useMemo, useRef, useState } from 'react';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const SCREENER_FILTER_SEARCH = 'SCREENER_FILTER_SEARCH';

type UseFilterOptionSearchProps = {
  filter: string;
  defaultOptions: ScreenerFilterOption[];
  enabled?: boolean;
};

export const useFilterOptionSearch = ({
  filter,
  defaultOptions,
  enabled = true,
}: UseFilterOptionSearchProps) => {
  const [value, setValue] = useState('');
  const debouncedSearchTerm = useDebounce(value, 300);
  const prevData = useRef(defaultOptions);
  const defaultKeys = useMemo(
    () => defaultOptions.map(t => t.name),
    [defaultOptions]
  );
  const { data } = useQuery({
    queryKey: [
      SCREENER_FILTER_SEARCH,
      filter,
      debouncedSearchTerm,
      defaultKeys,
    ],
    queryFn: () =>
      wretchRequest<ScreenerFilterOption[]>(
        screener.getFilterOptions.path(filter, debouncedSearchTerm)
      ),
    initialData: prevData.current,
    enabled: !!debouncedSearchTerm && enabled,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleTextChange = (value: string) => {
    setValue(value);
  };

  const onClear = () => {
    setValue('');
  };

  prevData.current = data;

  return { value, data, handleChange, onClear, handleTextChange };
};
