import { DropdownGroup, DropdownMenu } from '@toggle/design-system';
import { Condition, ConditionType } from '@toggle/toggle';
import { divide, times } from 'number-precision';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnakeMeta } from '~/hooks/use-snake/useSnake';
import {
  checkIsDecimalValue,
  DECIMAL_TO_PERCENTAGE_FACTOR,
} from '~/utils/condition-utils/condition-utils';
import { mapDisplayFormatToUnit } from '~/utils/currency/currency';

import { ConditionAssetSearch } from '../condition-asset-search/ConditionAssetSearch';
import { ConditionDataList } from '../condition-data-list/ConditionDataList';
import * as S from './CustomScenarioModal.styles';

export type ConditionBuilderProps = {
  snake: string;
  onConditionChange: (condition: Partial<Condition>) => void;
  defaultCondition?: Condition;
};

export const ConditionBuilder = ({
  snake,
  onConditionChange,
  defaultCondition,
}: ConditionBuilderProps) => {
  const { t } = useTranslation('screener');
  const [condition, setCondition] = useState<Partial<Condition>>(
    defaultCondition ?? {
      condition: ConditionType.Above,
    }
  );

  const { data: snakeMeta } = useSnakeMeta(snake);

  const inputUnit = mapDisplayFormatToUnit(
    snakeMeta?.display_format,
    snakeMeta?.currency
  );

  useEffect(() => {
    onConditionChange(condition);
  }, [condition]);

  const renderNumberInput = (
    propertyName: 'value' | 'min_value' | 'max_value' | 'duration',
    suffix?: string
  ) => {
    let typedValue = condition[propertyName] ?? '';

    const isPropertyNameValue = propertyName === 'value';

    const isTypedValueDecimal = checkIsDecimalValue({
      conditionType: condition.condition,
      value: typedValue,
    });

    if (isPropertyNameValue && isTypedValueDecimal) {
      typedValue = times(typedValue, DECIMAL_TO_PERCENTAGE_FACTOR);
    }

    return (
      <S.StyledInput
        data-testid="input-value"
        type="number"
        value={typedValue}
        onChange={e => {
          let nextValue = e.target.valueAsNumber;

          const isNextValueDecimal = checkIsDecimalValue({
            conditionType: condition.condition,
            value: nextValue,
          });

          if (isPropertyNameValue && isNextValueDecimal) {
            nextValue = divide(nextValue, DECIMAL_TO_PERCENTAGE_FACTOR);
          }

          setCondition(prev => ({
            ...prev,
            [propertyName]: isNaN(nextValue) ? undefined : nextValue,
          }));
        }}
        rightIcon={
          <S.InputSuffix data-testid="input-unit">{suffix}</S.InputSuffix>
        }
      />
    );
  };

  const renderDurationFields = (suffix: string) => {
    return (
      <>
        {renderNumberInput('value', suffix)}
        <S.OptionLabel>{t('scenario:condition.in')}</S.OptionLabel>

        {renderNumberInput('duration')}
        <DropdownMenu
          data-testid="duration-unit-input"
          triggerNode={
            <S.Option
              /* i18next-extract-mark-context-next-line  ["d", "w" , "m", "y"]  */
              label={t('scenario:condition.duration', {
                context: condition.duration_unit,
              })}
              iconName="ChevronLightDown"
              variant="empty"
              iconPosition="right"
            />
          }
        >
          <DropdownGroup
            items={['d', 'w', 'm', 'y'].map(d => ({
              /* i18next-extract-mark-context-next-line  ["d", "w" , "m", "y"]  */
              label: t('scenario:condition.duration', {
                context: d,
              }),
              key: d,
              onClick: () =>
                setCondition(prev => ({
                  ...prev,
                  duration_unit: d,
                })),
            }))}
          />
        </DropdownMenu>
      </>
    );
  };

  const renderConditionFields = (suffix: string) => {
    switch (condition.condition) {
      case ConditionType.Above:
      case ConditionType.Below:
        return renderNumberInput('value', suffix);
      case ConditionType.CrossesAbove:
      case ConditionType.CrossesBelow:
        return renderNumberInput('value', suffix);
      case ConditionType.RisesBy:
      case ConditionType.FallsBy:
        return renderDurationFields('%');
      case ConditionType.RisesByLevel:
      case ConditionType.FallsByLevel:
        return renderDurationFields(suffix);
      default:
        return (
          <>
            {renderNumberInput('max_value', suffix)}
            <S.OptionLabel>{t('scenario:condition.and')}</S.OptionLabel>
            {renderNumberInput('min_value', suffix)}
          </>
        );
    }
  };

  return (
    <>
      {defaultCondition && (
        <>
          <ConditionAssetSearch
            condition={defaultCondition}
            response={onConditionChange}
          />
          <ConditionDataList
            condition={defaultCondition}
            response={onConditionChange}
          />
        </>
      )}
      <DropdownMenu
        triggerNode={
          <S.Option
            /* i18next-extract-mark-context-next-line  ["crosses_above", "crosses_below", "crosses_below", "below", "between",  "rises_by", "falls_by", "rises_by_level",  "falls_by_level"] */
            label={t('scenario:condition.type', {
              context: condition.condition,
            })}
            iconName="ChevronLightDown"
            variant="empty"
            iconPosition="right"
          />
        }
      >
        <DropdownGroup
          items={Object.values(ConditionType).map(c => ({
            /* i18next-extract-mark-context-next-line  ["crosses_above", "crosses_below", "crosses_below", "below", "between",  "rises_by", "falls_by", "rises_by_level",  "falls_by_level"] */
            label: t('scenario:condition.type', {
              context: c,
            }),
            key: c,
            onClick: () => {
              setCondition({ condition: c, duration_unit: 'd' });
            },
          }))}
        />
      </DropdownMenu>
      {renderConditionFields(inputUnit)}
    </>
  );
};
