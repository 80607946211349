import React, { FC, ReactNode, useState } from 'react';

import * as S from './FiltersPanel.styles';

interface FiltersPanelProps {
  title: ReactNode;
  children: ReactNode;
}

export const FiltersPanel: FC<FiltersPanelProps> & {
  Styled: typeof S;
} = ({ title, children, ...rest }) => {
  const [expanded, setExpanded] = useState(true);

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <S.Container {...rest}>
      <S.TitleContainer>
        <S.Title>{title}</S.Title>
        <S.StyledButton
          iconName={expanded ? 'ChevronLightUp' : 'ChevronLightDown'}
          variant="empty"
          size="xsmall"
          data-testid="expand-button"
          onClick={toggle}
        />
      </S.TitleContainer>
      <S.FiltersContainer $expanded={expanded}>{children}</S.FiltersContainer>
    </S.Container>
  );
};

FiltersPanel.Styled = S;
