import {
  DropdownEntry,
  DropdownGroup,
  DropdownMenu,
} from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ConditionDropdown.styles';

type ConditionDropdownProps = {
  conditionLabel: string;
  onEdit: () => void;
  onDelete: () => void;
};

export const ConditionDropdown = ({
  conditionLabel,
  onEdit,
  onDelete,
}: ConditionDropdownProps) => {
  const { t } = useTranslation(['scenario']);

  const items: DropdownEntry[] = [
    {
      label: t('scenario:conditionDropdown.edit'),
      key: 'new',
      iconName: 'Pencil',
      onClick: onEdit,
    },
    {
      label: (
        <S.RemoveText>
          <S.TrashIcon iconName="Trash" />
          {t('scenario:conditionDropdown.remove')}
        </S.RemoveText>
      ),
      key: 'remove',
      onClick: onDelete,
    },
  ];

  return (
    <DropdownMenu
      triggerNode={
        <S.Condition
          label={conditionLabel}
          iconName="ChevronLightDown"
          variant="empty"
          iconPosition="right"
        />
      }
    >
      <DropdownGroup items={items} />
    </DropdownMenu>
  );
};
