import { CheckBoxProps } from '@toggle/design-system';
import React, { FC } from 'react';

import * as S from './RowSelectionCheckbox.styles';

interface RowSelectionCheckboxProps extends Omit<CheckBoxProps, 'label'> {}

export const RowSelectionCheckbox: FC<RowSelectionCheckboxProps> = props => {
  return <S.StyledCheckBox {...props} label="" />;
};
