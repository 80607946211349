import { Button } from '@toggle/design-system';
import { useNetworkStatus } from '@toggle/helpers';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { PasswordInput } from '~/components/forms/password-input/PasswordInput';
import { LoadingView } from '~/components/loading-view/LoadingView';
import { appPaths, authPaths } from '~/routes/app-paths';
import {
  validateConfirmPassword,
  validatePassword,
} from '~/utils/password-validation-utils/password-validation-utils';

import * as AuthSharedStyles from '../auth-shared-styles/AuthSharedStyles.styles';
import { useResetPasswordState } from './hooks/use-reset-password-state/useResetPasswordState';
import * as S from './ResetPasswordView.styles';

enum ChangePasswordFields {
  NewPassword = 'newPassword',
  ConfirmedPassword = 'confirmedPassword',
}

const defaultErrorState = {
  newPassword: '',
  confirmedPassword: '',
};

export const ResetPasswordView = () => {
  const { t } = useTranslation(['auth', 'common', 'settings']);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [errors, setErrors] = useState(defaultErrorState);
  const { checkNetworkStatus, isOnline } = useNetworkStatus();
  const { resetState, resetPassword, checkToken, checkTokenState } =
    useResetPasswordState();

  useEffect(() => {
    checkToken();
  }, []);

  const error = useMemo(() => {
    return resetState.error || checkTokenState.error;
  }, [checkTokenState, resetState]);

  const updateError = (field: ChangePasswordFields, errorText: string) => {
    setErrors(prevState => ({
      ...prevState,
      [field]: errorText,
    }));
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === ChangePasswordFields.NewPassword) {
      setPassword(value);
    }

    if (name === ChangePasswordFields.ConfirmedPassword) {
      setConfirmedPassword(value);
    }
  };

  const clearError = (event: React.FocusEvent<HTMLInputElement>) => {
    updateError(event.target.name as ChangePasswordFields, '');
  };

  const validatePasswords = (
    newPassword: string,
    confirmedPassword: string
  ) => {
    const newPasswordError = validatePassword(newPassword);
    const confirmedPasswordError = validateConfirmPassword(
      confirmedPassword,
      newPassword
    );

    return { newPasswordError, confirmedPasswordError };
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const { newPasswordError, confirmedPasswordError } = validatePasswords(
      password,
      confirmedPassword
    );

    setErrors({
      newPassword: newPasswordError,
      confirmedPassword: confirmedPasswordError,
    });

    const isFormValid = !newPasswordError && !confirmedPasswordError;

    if (isFormValid) {
      resetPasswordHandler();
    }
  };

  const resetPasswordHandler = useCallback(async () => {
    const online = await checkNetworkStatus();

    if (!online) {
      return null;
    }

    if (resetState.isPending) {
      return null;
    }

    return resetPassword({
      password,
      confirmedPassword,
    });
  }, [password, confirmedPassword]);

  return (
    <AuthSharedStyles.Layout>
      <S.Wrapper>
        <S.Container>
          <LoadingView loading={!(checkTokenState.isSuccess || error)}>
            <AuthSharedStyles.Logo alt="logo" title="logo" />
            <S.ErrorContainer>
              {isOnline && error && (
                <S.NoLongerValidWrapper>
                  <S.ErrorView data-testid="errorView">
                    <S.ErrorTitle>
                      {
                        /* i18next-extract-disable-next-line */
                        t(`auth:resetPassword.${error}`)
                      }
                    </S.ErrorTitle>
                    <Link to={authPaths.forgotPassword}>
                      <Button
                        fullWidth
                        label={t('auth:resetPassword.linkToForgotPassword')}
                        size="large"
                      />
                    </Link>
                    <S.BackToLoginLink to={{ pathname: authPaths.login }}>
                      <AuthSharedStyles.CancelButton to={authPaths.login}>
                        {t('auth:resetPassword.backToLogin')}
                      </AuthSharedStyles.CancelButton>
                    </S.BackToLoginLink>
                  </S.ErrorView>
                </S.NoLongerValidWrapper>
              )}

              {checkTokenState.isSuccess && !error && (
                <S.Form data-testid="resetPasswordForm" onSubmit={onSubmit}>
                  <AuthSharedStyles.Heading>
                    {t('auth:resetPassword.title')}
                  </AuthSharedStyles.Heading>

                  <S.StyledPasswordInput
                    data-testid="password-input"
                    label={t('settings:account.newPassword')}
                    variant="outline"
                    name={ChangePasswordFields.NewPassword}
                    onChange={onInputChange}
                    onFocus={clearError}
                    hasError={!!errors.newPassword}
                    /* i18next-extract-disable-next-line */
                    errorText={t(errors.newPassword)}
                  />
                  <PasswordInput
                    data-testid="confirm-password-input"
                    label={t('settings:account.confirmPassword')}
                    name={ChangePasswordFields.ConfirmedPassword}
                    onChange={onInputChange}
                    onFocus={clearError}
                    hasError={!!errors.confirmedPassword}
                    /* i18next-extract-disable-next-line */
                    errorText={t(errors.confirmedPassword)}
                  />
                  {!isOnline && (
                    <S.ErrorTitle>{t('auth:noInternet')}</S.ErrorTitle>
                  )}
                  <AuthSharedStyles.CenterContent>
                    <AuthSharedStyles.SendButtonWrapper>
                      <Button
                        fullWidth
                        data-testid="submit-button"
                        disabled={resetState.isPending}
                        isLoading={resetState.isPending}
                        label={t('auth:resetPassword.update')}
                        size="large"
                      />
                    </AuthSharedStyles.SendButtonWrapper>
                    {/* https://github.com/styled-components/styled-components/issues/4314 */}
                    {/* @ts-ignore-next-line */}
                    <AuthSharedStyles.CancelButton as="a" href={appPaths.base}>
                      {t('common:cancel')}
                    </AuthSharedStyles.CancelButton>
                  </AuthSharedStyles.CenterContent>
                </S.Form>
              )}
            </S.ErrorContainer>
          </LoadingView>
        </S.Container>
      </S.Wrapper>
    </AuthSharedStyles.Layout>
  );
};
