import React from 'react';

import { MultiLineChartLine } from '../../MultiLineChart.types';
import * as S from './MultiLineChartLegend.styles';

type MultiLineChartLegendProps = {
  lines: MultiLineChartLine[];
};

export const MultiLineChartLegend = ({ lines }: MultiLineChartLegendProps) => {
  return (
    <S.LegendContainer data-testid="multi-line-chart-legend">
      {lines.map(line => (
        <S.LegendItem key={line.label}>
          <S.LegendColor $color={line.color} />
          <div>{line.label}</div>
        </S.LegendItem>
      ))}
    </S.LegendContainer>
  );
};
