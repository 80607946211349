import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useUser } from '~/stores/use-user/useUser';

import { queryKeys } from '../../routes/app-paths';

interface AppHistoryProps {
  children: ReactNode;
}

export const AppHistory: FC<AppHistoryProps> = props => {
  const { user } = useUser(({ user }) => ({ user }));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const queryParams = new URLSearchParams(location.search);
      const redirectTo = queryParams.get(queryKeys.redirect);

      if (redirectTo) {
        navigate(redirectTo, { replace: true });
        return;
      }
    }
  }, [location, user, navigate]);

  return <>{props.children}</>;
};
