import { Alert, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const ButtonContainer = styled.footer`
  display: flex;
  padding-top: 36px;
  justify-content: flex-end;
  gap: 8px;
`;

export const ScenarioFilterError = styled(Alert)`
  width: 100%;
  padding: 16px;
  margin-top: 10px;
`;

export const StyledConditionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 32px;
`;

export const ConditionalDescription = styled.p`
  ${typographyV2.bodySm};
  padding-bottom: 32px;
`;

export const Title = styled.h2`
  color: var(--text-soft);
  ${typographyV2.bodySm};
`;
