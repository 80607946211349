import React, { useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Tracking } from '~/services/tracking';

import {
  CustomLink,
  CustomTable,
  CustomTableCell,
  CustomTableHeader,
} from './components/CustomComponents';
import * as S from './Markdown.styles';

export interface MarkdownProps {
  message: string;
  onLoad?: (element: HTMLDivElement) => void;
  components?: Partial<Components>;
}

export const Markdown = ({ message, onLoad, components }: MarkdownProps) => {
  // This is fix for table markdown. Need to check if BE/DS can fix the GPT response to not include underscore with tables
  // Discussion: https://toggleglobal.slack.com/archives/C053NT28Q8M/p1686422095030189
  const formattedMarkdown = message.replace(/\|_/g, '| ');

  const markdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onLoad && markdownRef.current) {
      onLoad(markdownRef.current);
    }
  }, [message]);

  const logError = (error: Error, errorInfo: React.ErrorInfo) => {
    Tracking.captureException(error, { extra: { errorInfo } });
  };

  return (
    <ErrorBoundary fallback={null} onError={logError}>
      <S.MarkdownStyles ref={markdownRef} data-testid="markdown">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            a: CustomLink,
            td: CustomTableCell,
            th: CustomTableHeader,
            table: CustomTable,
            ...components,
          }}
        >
          {formattedMarkdown}
        </ReactMarkdown>
      </S.MarkdownStyles>
    </ErrorBoundary>
  );
};

Markdown.Styled = S;
