import styled, { css } from 'styled-components';

import { ToastPosition } from '~/stores/use-toasts/useToasts';

interface StyledToastProps {
  $position: ToastPosition;
  $offsetY: number;
}

const wrapperPaddingX = 20;
export const offsetBetweenToasts = 20;
export const toastTransitionDuration = 300;
const exitTransitionDelay = toastTransitionDuration * 0.35;
export const transitionClass = 'toast';

const absolutePositions = {
  [ToastPosition.TopLeft]: { x: 'left', y: 'top' },
  [ToastPosition.Top]: { x: 'middle', y: 'top' },
  [ToastPosition.TopRight]: { x: 'right', y: 'top' },
  [ToastPosition.BottomLeft]: { x: 'left', y: 'bottom' },
  [ToastPosition.Bottom]: { x: 'middle', y: 'bottom' },
  [ToastPosition.BottomRight]: { x: 'right', y: 'bottom' },
};

const horizontalAnimation = ({ $position }: StyledToastProps) => css`
  &&.${transitionClass}-enter,
    // specificity increase
  &&.${transitionClass}-exit-active.${transitionClass}-exit-active {
    transform: translateX(
      calc(
        ${absolutePositions[$position].x === 'left' ? '-100% - ' : '100% + '}
          ${wrapperPaddingX}px
      )
    );
  }

  &&.${transitionClass}-enter-active, &&.${transitionClass}-exit {
    transform: translateX(0px);
  }

  &&.${transitionClass}-enter-active {
    transition: top ${toastTransitionDuration}ms
        cubic-bezier(0.73, 1.45, 0.75, 0.98),
      bottom ${toastTransitionDuration}ms cubic-bezier(0.73, 1.45, 0.75, 0.98),
      transform ${toastTransitionDuration}ms
        cubic-bezier(0.73, 1.45, 0.75, 0.98);
  }
`;

const verticalAnimation = ({ $offsetY, $position }: StyledToastProps) => {
  const calcSign = absolutePositions[$position].y === 'top' ? '-' : '+';

  return css`
    left: 50%;
    transform: translateX(-50%);

    &&.${transitionClass}-enter,
      // specificity increase
    &&.${transitionClass}-exit-active.${transitionClass}-exit-active {
      transform: translate(
        -50%,
        calc(
          ${calcSign}100% ${calcSign} ${$offsetY}px ${calcSign}
            ${offsetBetweenToasts}px
        )
      );
    }

    &&.${transitionClass}-enter-active, &&.${transitionClass}-exit {
      transform: translate(-50%, 0px);
    }
  `;
};

export const ToastsWrapper = styled.div`
  position: fixed;
  top: 0;
  pointer-events: ${props => (props.onClick ? 'all' : 'none')};
  width: 100vw;
  height: 100vh;
  z-index: var(--z-index-toast);
  overflow: hidden;
`;

export const Toast = styled.div<StyledToastProps>`
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  margin: ${offsetBetweenToasts}px 0;
  display: inline-block;
  transition: top ${toastTransitionDuration}ms ${exitTransitionDelay}ms
      cubic-bezier(0.73, 1.45, 0.75, 0.98),
    bottom ${toastTransitionDuration}ms ${exitTransitionDelay}ms
      cubic-bezier(0.73, 1.45, 0.75, 0.98),
    transform ${toastTransitionDuration}ms cubic-bezier(0.73, 1.45, 0.75, 0.98);
  ${props => absolutePositions[props.$position].x}: ${wrapperPaddingX}px;
  ${props => absolutePositions[props.$position].y}: ${props =>
    props.$offsetY}px;

  ${props =>
    absolutePositions[props.$position].x === 'middle'
      ? verticalAnimation(props)
      : horizontalAnimation(props)}
`;
