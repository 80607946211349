import { formatEventDate } from '~/views/calendar/utils';

export const groupEntriesByDate = <T>(
  entries: T[],
  fieldName: keyof T,
  entriesPerDateLimit?: number
): Record<string, T[]> => {
  return entries.reduce((groupedEntries, entry) => {
    const dateValue = entry[fieldName];

    if (typeof dateValue !== 'string') {
      return groupedEntries;
    }

    const dateOnly = formatEventDate(new Date(dateValue));

    if (!groupedEntries[dateOnly]) {
      groupedEntries[dateOnly] = [];
    }

    if (
      entriesPerDateLimit &&
      groupedEntries[dateOnly].length >= entriesPerDateLimit
    ) {
      return groupedEntries;
    }

    groupedEntries[dateOnly].push(entry);

    return groupedEntries;
  }, {} as { [date: string]: T[] });
};
