import { PriceStatus } from '@toggle/helpers';
import styled from 'styled-components';

import { priceChangeColors } from '~/utils/common/utils';

export const ColoredValue = styled.span<{ $status: PriceStatus }>`
  font-family: var(--font-roboto-mono);
  color: ${({ $status }) => priceChangeColors[$status]};
  display: inline-block;
  min-width: 60px;
`;

export const NonNumericText = styled.span`
  font-family: var(--font-nort);
`;
