import {
  ChartAssetData,
  PaneData,
  RangeHorizon,
  rangeToResampleMapping,
} from '@toggle/chart';
import { DetailedInsight } from '@toggle/toggle';
import React, { useRef } from 'react';

import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';
import { useTurboChart } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';
import { ValidChartSettings } from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import { ChartArea } from './ChartArea';
import * as S from './ChartBody.styles';

const SINGLE_PANE_HEIGHT = 180;
const PANE_PADDING_TOP = 40;

export interface ChartBodyProps {
  entityTag: string;
  snakes: string[];
  episodes: DetailedInsight['episodes'];
  horizon: RangeHorizon;
}

export const ChartBody = ({
  entityTag,
  snakes,
  episodes,
  horizon,
}: ChartBodyProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const panesRefLimitExceeded = useRef(false);

  const { colors: themeColors } = useChartTheme();

  const assets = snakes.map((snake, index) => {
    return {
      tag: entityTag,
      snake,
      paneIndex: index,
    };
  });

  const initialChartSettings: ValidChartSettings = {
    assets,
    resample: rangeToResampleMapping[horizon],
    horizon,
    domain: null,
    hidden: undefined,
    episodes,
    paneSettings: {
      0: {
        yAxisType: 'merged',
        priceDisplay: 'price',
      },
    },
  };

  const {
    chartData,
    assetWithError,
    hasError,
    hasEmpty,
    chartApiRef,
    chartState,
    primaryAsset,
    primaryPane,
    chartPaneActions,
    retryInitialChartLoad,
    changeYAxisSettings,
    removeActiveAsset,
    changeChartLineColor,
    hideActiveAsset,
    changeSeriesType,
  } = useTurboChart({
    initialChartSettings,
    shouldUseSearchParams: false,
    variants: ['customMulti'],
  });

  return (
    <S.StyledChartAreaWrapper
      assetWithError={assetWithError}
      hasError={hasError}
      hasEmpty={hasEmpty}
      colors={themeColors}
      chartData={chartData}
      chartConfig={{
        enableZoom: false,
        hoverVariant: 'simple',
        panePaddingTop: PANE_PADDING_TOP,
        lineWidth: 1,
      }}
      onRetry={retryInitialChartLoad}
      renderChartArea={(options, containerRef) => {
        return (
          <ChartArea
            canvasRef={canvasRef}
            containerRef={containerRef}
            chartApiRef={chartApiRef}
            panesRefLimitExceeded={panesRefLimitExceeded}
            options={options}
            chartState={chartState}
            primaryAsset={primaryAsset as ChartAssetData}
            primaryPane={primaryPane as PaneData}
            chartPaneActions={chartPaneActions}
            eventsConfig={{
              TMI: true,
            }}
            onChangeTicker={() => {}}
            onChangeIndicator={() => {}}
            changeYAxisSettings={changeYAxisSettings}
            removeActiveAsset={removeActiveAsset}
            changeChartLineColor={changeChartLineColor}
            hideActiveAsset={hideActiveAsset}
            changeSeriesType={changeSeriesType}
          />
        );
      }}
      $height={snakes.length * SINGLE_PANE_HEIGHT}
    />
  );
};
