import { useEffect } from 'react';

import { isMac } from '~/utils/device';

export const useSearchHotkey = (onPress: () => void) => {
  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      const macKeyPress = isMac() ? event.metaKey : event.ctrlKey;
      const isPressed = event.code === 'KeyK' ? macKeyPress : false;

      if (isPressed) {
        event.preventDefault();
        onPress();
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, []);
};
