import { ButtonGroup } from '@toggle/design-system';
import styled from 'styled-components';

import { SectionTitle } from '~/components/section-title/SectionTitle';

export const Flex = styled.div<{
  $chartsGap: number;
}>`
  display: flex;
  gap: ${({ $chartsGap }) => `${$chartsGap}px`};
  width: 100%;
  flex-wrap: wrap;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SectionTitleWrapper = styled.div`
  ${SectionTitle.Styled.TitleWrapper} {
    margin-bottom: 0;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1;
  min-width: 134px;
  max-width: fit-content;
`;
