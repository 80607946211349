import {
  ChartAssetData,
  chartResamples,
  CustomMixDomain,
  PaneData,
  PaneSettingsKeys,
  RANGE_HORIZON_DEFAULT,
  RangeHorizon,
  RESAMPLE_DEFAULT,
  ResampleIntervals,
} from '@toggle/chart';
import { getEntityName } from '@toggle/helpers';
import { DetailedInsight, Entity, SnakeMeta } from '@toggle/toggle';
import { v4 } from 'uuid';

import {
  appendChartAssetParam,
  ChartSearchParams,
  ChartSearchParamsAssetValue,
  setPrimarySnakeParam,
} from '~/utils/chart-search-utils/chart-search-utils';

import { ChartState } from '../../hooks/use-chart-state/useChartState';
import { shouldDisable1DHorizon } from '../resample-utils/resample-utils';

export const COMMANDER_OPEN_TURBO_CUSTOM_EVENT = 'commanderOpenTurboChart';

type PaneSettingsValues<
  IsArray = true,
  T = Pick<PaneData, PaneSettingsKeys>
> = {
  [K in keyof T]: IsArray extends true ? T[K][] : T[K];
};

export type PaneSettings = Pick<PaneData, PaneSettingsKeys>;
type PartialPaneSettings = Partial<PaneSettings>;

const PANE_SETTINGS_VALUES: PaneSettingsValues = {
  seriesType: ['line', 'bars', 'candlestick'],
  yAxisType: ['split', 'merged'],
  priceDisplay: ['price', 'percentage'],
};

const DEFAULT_PANE_SETTINGS: PaneSettingsValues<false> = {
  seriesType: 'line',
  yAxisType: 'split',
  priceDisplay: 'price',
};

export interface CommanderOpenTurboDetails {
  searchParams: URLSearchParams;
}
export interface CommanderOpenTurboEvent
  extends CustomEvent<CommanderOpenTurboDetails> {
  type: typeof COMMANDER_OPEN_TURBO_CUSTOM_EVENT;
}

interface ChartSearchParamsProps extends Partial<ChartState> {
  searchParams: URLSearchParams;
  primaryAsset?: ChartAssetData;
}

export interface ChartSearchParamsAsset {
  tag: string;
  snake: string;
  paneIndex: number;
}

export interface ChartFullScreenSettings {
  multiplePanes: boolean;
}

interface ChartSearchParamsSettings
  extends ReturnType<typeof getChartSettingsFromSearchParams> {}

export interface ChartSettings
  extends Omit<ChartSearchParamsSettings, 'primarySnake'> {}

export interface ValidChartSettings extends ChartSettings {
  assets: ChartSearchParamsAsset[];
  resample: ResampleIntervals;
  primarySnake?: string;
  episodes?: DetailedInsight['episodes'];
}

const isChartParamsAssetValid = (asset: Partial<ChartSearchParamsAsset>) =>
  (['tag', 'snake'] as (keyof ChartSearchParamsAsset)[]).every(
    key => asset[key]
  );

export const checkSearchParams = (
  chartSettings: ChartSettings
): chartSettings is ValidChartSettings =>
  !!chartSettings.assets.length &&
  chartSettings.assets.every(isChartParamsAssetValid);

const getPaneSettingsFromSearchParams = (searchParams: URLSearchParams) => {
  const string = searchParams.toString();
  const settingKeys = Object.keys(PANE_SETTINGS_VALUES) as PaneSettingsKeys[];
  const result: Record<string, PartialPaneSettings> = {};

  settingKeys.forEach(key => {
    const possibleValues = PANE_SETTINGS_VALUES[key].join('|');
    const regex = new RegExp(`${key}\.?([1-9])?=(${possibleValues})`, 'g');
    const matches = [...string.matchAll(regex)];

    matches.forEach(match => {
      const paneIndex = match[1] ?? 0;
      const value = match[2];

      if (value) {
        result[paneIndex] = {
          ...result[paneIndex],
          [key]: value,
        };
      }
    });
  });

  return result;
};

const getFullPaneSettings = (
  partialSettings?: PartialPaneSettings
): PaneSettings => ({
  ...DEFAULT_PANE_SETTINGS,
  ...partialSettings,
});

export const getChartSettingsFromSearchParams = (
  searchParams: URLSearchParams
) => {
  const assets = searchParams.getAll(ChartSearchParams.Asset);
  const assetsValue = assets.map(string => {
    const [tag, snake, paneIndex = 0] = string.split(';');
    return {
      tag,
      snake,
      paneIndex: +paneIndex,
    };
  });

  const paneSettings = getPaneSettingsFromSearchParams(searchParams);
  const customDomain = searchParams.get(ChartSearchParams.Domain)?.split(',');
  const customDomainValue = getCustomDomainValue(customDomain);
  const resample = searchParams.get(ChartSearchParams.Resample);
  const resampleValue =
    chartResamples.find(item => item === resample) ?? RESAMPLE_DEFAULT;

  const horizonInParams = searchParams.get(ChartSearchParams.Horizon);
  let horizonValue: RangeHorizon | undefined;

  if (horizonInParams) {
    horizonValue = Object.values(RangeHorizon).find(
      item => item === horizonInParams
    );
  } else if (!customDomainValue) {
    horizonValue = RANGE_HORIZON_DEFAULT;
  }

  const hidden = searchParams.get(ChartSearchParams.Hidden)?.split(',');
  const primarySnake = searchParams.get(ChartSearchParams.Primary) ?? undefined;

  return {
    assets: assetsValue,
    resample: resampleValue,
    horizon: horizonValue,
    domain: customDomainValue,
    hidden,
    paneSettings,
    primarySnake,
  };
};

const setChartPaneSettingsParams = (
  urlSearchParams: URLSearchParams,
  panes: PaneData[]
) => {
  const settingKeys = Object.keys(PANE_SETTINGS_VALUES) as PaneSettingsKeys[];

  panes.forEach((pane, index) => {
    const secondaryPaneIndexString = index === 0 ? '' : `.${index}`;

    settingKeys.forEach(key => {
      const defaultValue = DEFAULT_PANE_SETTINGS[key];
      const searchParam = `${key}${secondaryPaneIndexString}`;

      if (pane[key] === defaultValue) {
        urlSearchParams.delete(searchParam);
      } else {
        urlSearchParams.set(searchParam, pane[key]);
      }
    });
  });
};

export const getInitialSearchParams = (
  searchParams: ChartSearchParamsProps['searchParams'],
  chartPanes: ChartSearchParamsProps['chartPanes']
) => {
  if (!chartPanes) {
    return searchParams;
  }

  const initSearchParams = new URLSearchParams();
  const keys = [
    ChartSearchParams.Domain,
    ChartSearchParams.Hidden,
    ChartSearchParams.Horizon,
    ChartSearchParams.Resample,
  ];

  keys.forEach(key => {
    const value = searchParams.get(key);

    if (value !== null) {
      initSearchParams.set(key, value);
    }
  });

  return initSearchParams;
};

export const buildChartSearchParams = ({
  searchParams,
  chartPanes,
  primaryAsset,
  ...rest
}: ChartSearchParamsProps) => {
  const urlSearchParams = getInitialSearchParams(searchParams, chartPanes);
  const keys = Object.keys(rest) as Exclude<
    ChartSearchParams,
    ChartSearchParams.Asset | ChartSearchParams.Primary
  >[];

  if (chartPanes) {
    chartPanes.forEach((pane, index) => {
      pane.chartAssetData.forEach(item => {
        const value: ChartSearchParamsAssetValue = [
          item.entity.tag,
          item.entity.default_snake,
          index,
        ];
        appendChartAssetParam(urlSearchParams, value);
        primaryAsset && setPrimarySnakeParam(urlSearchParams, primaryAsset);
      });
    });
    setChartPaneSettingsParams(urlSearchParams, chartPanes);
  }

  keys.forEach(key => {
    const value = rest[key];

    if (value) {
      urlSearchParams.set(key, value.toString());
    } else if (value === null) {
      urlSearchParams.delete(key);
    }
  });

  urlSearchParams.sort();
  return urlSearchParams;
};

export const getValidHorizon = ({
  horizon,
  chartData,
  emptyResamples,
  hasSinglePriceEntity,
  resample,
}: {
  horizon?: RangeHorizon;
  chartData: ChartAssetData[];
  emptyResamples: ResampleIntervals[];
  hasSinglePriceEntity: boolean;
  resample: ResampleIntervals;
}) => {
  if (horizon !== RangeHorizon.OneDay) {
    return horizon;
  }

  return shouldDisable1DHorizon({
    chartData,
    emptyResamples,
    hasSinglePriceEntity,
    resample,
  })
    ? RANGE_HORIZON_DEFAULT
    : horizon;
};

export interface CreateChartPaneDataProps {
  chartAssetData: ChartAssetData[];
  paneStartIndexes: number[];
  primaryPaneIndex: number;
  primaryAssetIndex: number;
  paneSettings: Record<string, PartialPaneSettings>;
}

export const createChartPanesData = ({
  paneStartIndexes,
  chartAssetData,
  paneSettings,
  primaryPaneIndex,
  primaryAssetIndex,
}: CreateChartPaneDataProps): PaneData[] =>
  paneStartIndexes.map((index, idx) => {
    const isPrimaryPane = index === primaryPaneIndex;
    const id = v4();
    const fullSettings = getFullPaneSettings(paneSettings?.[idx]);

    if (isPrimaryPane) {
      const data = chartAssetData.slice(
        primaryAssetIndex,
        paneStartIndexes[idx + 1]
      );
      return {
        id,
        chartAssetData: data,
        ...fullSettings,
      };
    }

    return {
      id,
      chartAssetData: chartAssetData.slice(index, paneStartIndexes[idx + 1]),
      ...fullSettings,
    };
  });

const getCustomDomainValue = (domain?: string[]): CustomMixDomain | null => {
  if (!domain) {
    return null;
  }

  let start: string | number = domain[0];
  let end: string | number = domain[1];

  if (!Number.isNaN(+domain[0])) {
    start = Number(domain[0]);
  } else if (isNaN(new Date(domain[0]).getTime())) {
    return null;
  }

  if (!Number.isNaN(+domain[1])) {
    end = Number(domain[1]);
  } else if (isNaN(new Date(domain[1]).getTime())) {
    return null;
  }

  return [start, end];
};

interface CreateAssetEntitiesWithSnakeProps {
  assets: ChartSearchParamsAsset[];
  entities: Entity[];
  snakeMetas: (SnakeMeta | undefined)[];
}

export const createAssetEntitiesWithSnake = ({
  assets,
  entities,
  snakeMetas,
}: CreateAssetEntitiesWithSnakeProps) => {
  const sortedAssets = assets.sort((a, b) => a.paneIndex - b.paneIndex);
  const assetEntities: Entity[] = [];
  const paneStartIndexes: number[] = [];

  sortedAssets.forEach((asset, idx) => {
    const entity = entities.find(entity => entity.tag === asset.tag);
    const snakeMeta = snakeMetas[idx];

    if (entity && snakeMeta) {
      //only change name for primary pane, secondary ones will use snakeMeta.name.english
      assetEntities.push({
        ...entity,
        default_snake: snakeMeta.snake,
        name:
          asset.paneIndex === 0
            ? getEntityName(entity, snakeMeta)
            : entity.name,
      });

      if (paneStartIndexes[asset.paneIndex] === undefined) {
        paneStartIndexes.push(assetEntities.length - 1);
      }
    }
  });

  return { assetEntities, paneStartIndexes };
};

export const getInitialPrimaryAssetEntity = ({
  assetEntities,
  assets,
  primarySnake = assetEntities[0].default_snake,
}: {
  assets: ChartSearchParamsAsset[];
  assetEntities: Entity[];
  primarySnake?: string;
}) => {
  const primaryAssetIndex = assetEntities.findIndex(
    e => e.default_snake === primarySnake
  );
  const assetIndex = assets.findIndex(
    a => a.snake === assetEntities[primaryAssetIndex]?.default_snake
  );
  const primaryPaneIndex = assetIndex === -1 ? 0 : assets[assetIndex].paneIndex;

  return {
    primaryAssetIndex: primaryAssetIndex === -1 ? 0 : primaryAssetIndex,
    primaryPaneIndex,
  };
};
