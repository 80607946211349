import {
  AssetClassCode,
  Entity,
  PrimaryMethod,
  SnakeMeta,
} from '@toggle/toggle';

export const isEntity = (input: any): input is Entity => {
  return input?.primary_method !== undefined;
};

export const isSinglePriceEntity = (entity: any): boolean => {
  return (
    isEntity(entity) &&
    (entity.primary_method !== PrimaryMethod.Price ||
      entity.asset_class === AssetClassCode.Fx ||
      !isPriceSnake(entity.default_snake))
  );
};

export const isPriceSnake = (snake: string) => /\.price(\.ds)?$/.test(snake);
export const isYieldSnake = (snake: string) => snake.endsWith('.yield.ds');

export const isSnakeWithThreshold = (snakeMeta?: SnakeMeta) =>
  !!snakeMeta?.threshold;

export const getEntityName = (entity: Entity, snakeMeta: SnakeMeta) => {
  return isPriceSnake(entity.default_snake)
    ? entity.name
    : `${entity.name} | ${snakeMeta.name.english}`;
};

export const hasKeyValues = (entityTag: string) =>
  !['btcusd', 'ethusd', 'ltcusd'].includes(entityTag);
