import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EntityWrapper = styled.div`
  display: flex;
  gap: 8px;
  ${typographyV2.bodyBase}
  margin-bottom: 16px;
`;
export const TypeTitle = styled.p`
  ${typographyV2.titleSm};
  margin-bottom: 4px;

  & mark {
    ${typographyV2.titleSm}
    line-height: 1rem;
    color: inherit;
    background-color: inherit;
  }
`;

export const MedianReturn = styled.span`
  ${typographyV2.titleBase};
`;

export const Container = styled.div<{ $isBullish: boolean }>`
  display: flex;
  flex-direction: column;
  color: var(--text-default);
  background: var(--surface-default, #202425);
  border: 1px solid;
  border-radius: inherit;
  padding: 12px;
  width: 300px;
  flex-shrink: 0;
  box-shadow: var(--elevation-strong);
  ${borderRadius.r2};

  border-color: ${props =>
    props.$isBullish ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};

  ${TypeTitle}, ${MedianReturn} {
    color: ${props =>
      props.$isBullish ? 'var(--viz-bullish)' : 'var(--viz-bearish)'};
  }
`;

export const Title = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  ${typographyV2.titleBase}
`;

export const RowsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0;
  margin: 0px 0px 0px 12px;
  list-style-type: none;
`;

export const Row = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RowTitle = styled.span`
  color: var(--text-soft);
  ${typographyV2.bodySm};
`;

export const RowLabel = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  ${typographyV2.titleBase};
`;

export const StarsLabel = styled.p`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  & svg {
    fill: var(--icon-primary);
  }
`;

export const HelperText = styled.p`
  margin-top: 12px;
  margin-bottom: 16px;
  color: var(--text-soft);
  ${typographyV2.bodyXs};
`;
