import styled from 'styled-components';

import iconEmpty from '~/../static/error-empty.svg';
import { Icon } from '~/components/icon/Icon';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  height: 100%;
`;

export const Title = styled.div`
  ${typographyV2.bodyBase};
  margin-bottom: 4px;
`;

export const ErrorIcon = styled(Icon).attrs({
  size: 60,
  viewBox: '0 0 60 60',
})`
  margin-bottom: 8px;
`;

export const EmptyIcon = styled(iconEmpty)`
  width: 66px;
  height: 82px;
  margin-bottom: 8px;
  max-width: 600px;
`;

export const SubTitle = styled.div`
  color: var(--text-soft);
  ${typographyV2.bodySm}
  margin-bottom: 16px;
`;

export const Link = styled.a`
  color: var(--text-link);
  &:hover {
    color: var(--text-link);
  }
`;
