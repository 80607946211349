import styled from 'styled-components';
import { customScrollMinimal } from '../custom-scroll/CustomScroll.styles';
import { PriceStatus } from '@toggle/helpers';
import { TanStackTableRoot } from '../tanstack-table/TanStackTable.styles';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

export const priceChangeColors = {
  default: 'var(--text-default)',
  negative: 'var(--viz-bearish)',
  positive: 'var(--viz-bullish)',
};

export const Container = styled.div`
  flex: 1;
  height: 100%;
  max-height: 500px;
  position: relative;
  display: flex;
  overflow: auto;
  ${customScrollMinimal};
`;

export const PriceWrapper = styled.div<{
  $status: PriceStatus;
}>`
  color: ${({ $status }) => priceChangeColors[$status]};
`;

export const CellInner = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  img {
    ${borderRadius.rFull};
    width: 20px;
    height: 20px;
    overflow: hidden;
  }
`;

export const StyledTanStackTableRoot = styled(TanStackTableRoot)`
  ${borderRadius.r2}
  border-left: 1px solid var(--border-soft);
  border-right: 1px solid var(--border-soft);
  border-bottom: none;

  th {
    border-bottom: 1px solid var(--border-default);
  }

  thead:first-child th {
    border-top: 1px solid var(--border-soft);
  }

  th:first-child {
    border-top-left-radius: 8px;
  }

  th:last-child {
    border-top-right-radius: 8px;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 8px;
    }

    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
`;
