import LoadingIndicator from '@static/images/icons/loading-indicator.svg';
import styled from 'styled-components';

export const Indicator = styled(LoadingIndicator)``;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${Indicator} {
    width: 24px;
    height: 30px;
    position: relative;
    top: 50%;
    margin-top: -15px;
    left: 50%;
    margin-left: -12px;
  }

  [class*='loadingIndicatorRect'] {
    fill: var(--primary-color);
  }
`;
