import {
  ChangeYAxisSettingsProps,
  PaneData,
  ResampleIntervals,
  TimeSeriesItem,
} from '@toggle/chart';
import { isSinglePriceEntity, isSnakeWithThreshold } from '@toggle/helpers';
import { AssetClassCode, SnakeMeta } from '@toggle/toggle';

import { postMultipleEntities } from '~/services/entities/entity-service';

import {
  createAssetEntitiesWithSnake,
  createChartPanesData,
  getInitialPrimaryAssetEntity,
  getValidHorizon,
  ValidChartSettings,
} from '../../utils/chart-settings-utils/chart-settings-utils';
import {
  createAllPanesChartAssetData,
  createChartDataItem,
  getSnakeMeta,
} from '../../utils/chart-utils/chart-utils';
import {
  getAssetEmptyResamples,
  getAssetResample,
} from '../../utils/resample-utils/resample-utils';
import { ChartState } from '../use-chart-state/useChartState';
import { ThemeVariant, useChartTheme } from '../use-chart-theme/useChartTheme';
import {
  GetTimeSeriesProps,
  OnChartDataReadyProps,
} from '../use-turbo-chart/useTurboChart';

export interface UseChartSettingsProps {
  onChartDataReady: (props: OnChartDataReadyProps) => void;
  getTimeSeries: (
    props: GetTimeSeriesProps
  ) => Promise<TimeSeriesItem[] | undefined>;
  chartPanes: PaneData[];
  updateChartState: (partial: Partial<ChartState>) => void;
  variants?: ThemeVariant[];
}

export const useChartSettings = ({
  onChartDataReady,
  getTimeSeries,
  chartPanes,
  updateChartState,
  variants = [],
}: UseChartSettingsProps) => {
  const { colors } = useChartTheme(variants);

  const loadChartFromSettings = async ({
    assets,
    horizon,
    resample,
    hidden,
    domain,
    paneSettings,
    primarySnake,
    episodes,
  }: ValidChartSettings) => {
    const tags = assets.map(asset => asset.tag);
    const entities = await postMultipleEntities(tags);

    const snakeMetas = await Promise.all(
      assets.map(asset => getSnakeMeta(asset.snake))
    );
    const { assetEntities, paneStartIndexes } = createAssetEntitiesWithSnake({
      assets,
      entities,
      snakeMetas,
    });
    const { primaryAssetIndex, primaryPaneIndex } =
      getInitialPrimaryAssetEntity({
        assets,
        primarySnake,
        assetEntities,
      });
    const primaryEntity = assetEntities[primaryAssetIndex];
    const hasSinglePriceEntity = assetEntities.some(
      entity =>
        isSinglePriceEntity(entity) || entity.asset_class === AssetClassCode.Fx
    );
    const validResample = getAssetResample({
      asset: primaryEntity,
      selectedResample: resample,
      hasSinglePriceEntity,
    });
    const shouldFetchDaily = validResample !== ResampleIntervals.OneDay;

    const [dailyTs, currentResamplePrices] = await Promise.all([
      shouldFetchDaily
        ? getTimeSeries({
            latestAsset: primaryEntity,
            resolution: ResampleIntervals.OneDay,
          })
        : Promise.resolve([]),
      Promise.all(
        assetEntities.map(asset =>
          getTimeSeries({
            latestAsset: asset,
            resolution: validResample,
          })
        )
      ),
    ]);

    const dailyTimeseries = shouldFetchDaily
      ? dailyTs
      : currentResamplePrices[primaryAssetIndex];

    const primaryResamplePrice = currentResamplePrices[primaryAssetIndex];
    const hasNoPrices = (
      prices: Array<TimeSeriesItem[] | undefined>
    ): prices is Array<TimeSeriesItem[]> => !prices.some(item => item?.length);

    if (
      !dailyTimeseries ||
      hasNoPrices(currentResamplePrices) ||
      !primaryResamplePrice
    ) {
      throw new Error('Some data is missing');
    }

    // If the checks has passed
    // then we create panes from assetEntities + currentResamplePrices + existingSnakeMetas
    // NOTE: THE ORDER MATTERS!!! assetEntities may not contain same entities
    // as in the assets as there can be case when we coudn't fetch snakeMeta or ts is empty
    // SO currentResamplePrices and existingSnakeMetas are based on the assetEntities which are filtered
    const existingSnakeMetas = snakeMetas.filter(Boolean) as SnakeMeta[];
    const primaryChartData = createChartDataItem({
      entity: primaryEntity,
      ts: primaryResamplePrice,
      lineColorToken: isSnakeWithThreshold(
        existingSnakeMetas[primaryAssetIndex]
      )
        ? colors.default.token
        : colors.getMulti(assets.length)[0].token,
      isHidden: hidden?.includes(primaryEntity.default_snake),
      snakeMeta: existingSnakeMetas[primaryAssetIndex],
      episodes,
    });
    const allPanesChartAssetData = createAllPanesChartAssetData({
      assetEntities,
      primaryEntity,
      primaryChartData,
      currentResamplePrices,
      existingSnakeMetas,
      colors,
      hidden,
      episodes,
    });
    const chartPanes: PaneData[] = createChartPanesData({
      chartAssetData: allPanesChartAssetData,
      paneStartIndexes,
      paneSettings,
      primaryPaneIndex,
      primaryAssetIndex,
    });

    const emptyResamples = getAssetEmptyResamples(
      primaryEntity,
      hasSinglePriceEntity
    );
    const validHorizon = getValidHorizon({
      horizon,
      chartData: allPanesChartAssetData,
      emptyResamples,
      hasSinglePriceEntity,
      resample,
    });

    onChartDataReady({
      chartPanes,
      emptyResamples,
      validResample,
      validHorizon,
      domain,
      hidden,
      dailyTimeseries,
    });
  };

  const changeYAxisSettings = ({
    type,
    priceDisplay,
    paneId,
  }: ChangeYAxisSettingsProps) => {
    const newChartPanes = chartPanes.map(pane => {
      if (pane.id === paneId) {
        return {
          ...pane,
          yAxisType: type ?? pane.yAxisType,
          priceDisplay: priceDisplay ?? pane.priceDisplay,
        };
      }

      return pane;
    });

    updateChartState({
      chartPanes: newChartPanes,
    });
  };

  return {
    loadChartFromSettings,
    changeYAxisSettings,
  };
};
