import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const SubmitButton = styled(Button).attrs({
  variant: 'primary',
})`
  position: absolute;
  bottom: 10px;
  right: 16px;
  align-self: flex-end;
`;
