import { ColumnDef, FilterFn, Row } from '@tanstack/react-table';
import {
  AssetClassName,
  InsightsPrediction,
  MappedEntity,
} from '@toggle/toggle';
import { TFunction } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';

import * as Shared from '~/views/dashboard/my-assets-widget/assets-table/AssetsTable.styles';
import { EntityCell } from '~/widgets/scenario/components/entity-cell/EntityCell';
import {
  PercentageCell,
  PercentageCellProps,
} from '~/widgets/scenario/components/percentage-cell/PercentageCell';
import { isNumberInRange } from '~/widgets/scenario/utils/table-filters/table-filters';

export interface ScenarioTableData {
  entity: MappedEntity;
  prediction?: InsightsPrediction;
  quality: number;
  bestHorizon: string;
  summary: Record<string, number>;
  episodesCount: number;
}

const filterNumber: FilterFn<ScenarioTableData> = (
  row,
  columnId,
  filterValue
) => {
  const rowValue = row.getValue<number>(columnId);
  return isNumberInRange(rowValue, filterValue);
};

const filterPercentage: FilterFn<ScenarioTableData> = (
  row,
  columnId,
  filterValue
) => {
  const rowValue = row.getValue<number>(columnId) * 100;
  return isNumberInRange(rowValue, filterValue);
};

const renderValueCell = (
  row: Row<ScenarioTableData>,
  props: PercentageCellProps
) => {
  const isFixedIncome = row.original.entity.asset_class === AssetClassName.Fi;
  return <PercentageCell suffix={isFixedIncome ? 'bps' : '%'} {...props} />;
};

export const SCENARIO_TICKER_COLUMN_ID = 'entity';
export const SCENARIO_HISTORY_COLUMN_ID = 'history';

export const getHorizonColumns = (
  t: TFunction
): ColumnDef<ScenarioTableData>[] => [
  {
    id: SCENARIO_TICKER_COLUMN_ID,
    header: t('scenario:table.headers.column', {
      context: 'ticker',
    }),
    meta: {
      alignment: 'start',
    },
    accessorKey: 'entity.tag',
    cell: props => <EntityCell entityTag={props.row.original.entity.tag} />,
  },
  {
    id: SCENARIO_HISTORY_COLUMN_ID,
    header: t('scenario:table.headers.column', {
      context: 'history',
    }),
    accessorKey: 'episodesCount',
    filterFn: filterNumber,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.history'),
    },
    cell: props => {
      const item = props.row.original;

      return (
        <Shared.HighlightText>
          <Trans
            t={t}
            i18nKey="scenario:table.cells.history"
            count={item.episodesCount}
            components={[<b key="highlight" />]}
          />
        </Shared.HighlightText>
      );
    },
  },
  {
    id: 'quality',
    accessorKey: 'quality',
    header: t('scenario:table.headers.column', {
      context: 'quality',
    }),
    filterFn: filterNumber,
  },
  {
    id: 'min',
    accessorKey: 'prediction.min',
    header: t('scenario:table.headers.column', {
      context: 'min',
    }),
    filterFn: filterPercentage,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.min'),
    },
    cell: ({ row }) =>
      renderValueCell(row, { value: row.original.prediction?.min }),
  },

  {
    id: 'p20',
    accessorKey: 'prediction.percentiles.20',
    header: t('scenario:table.headers.column', {
      context: 'p20',
    }),
    filterFn: filterPercentage,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.p20'),
    },
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.prediction?.percentiles['20'],
      }),
  },
  {
    id: 'median',
    accessorKey: 'prediction.median',
    header: t('scenario:table.headers.column', {
      context: 'median',
    }),
    filterFn: filterPercentage,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.median'),
    },
    cell: ({ row }) =>
      renderValueCell(row, { value: row.original.prediction?.median }),
  },
  {
    id: 'mean',
    accessorKey: 'prediction.mean',
    header: t('scenario:table.headers.column', {
      context: 'mean',
    }),
    filterFn: filterPercentage,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.mean'),
    },
    cell: ({ row }) =>
      renderValueCell(row, { value: row.original.prediction?.mean }),
  },
  {
    id: 'p80',
    accessorKey: 'prediction.percentiles.80',
    header: t('scenario:table.headers.column', {
      context: 'p80',
    }),
    filterFn: filterPercentage,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.p80'),
    },
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.prediction?.percentiles['80'],
      }),
  },
  {
    id: 'max',
    accessorKey: 'prediction.max',
    header: t('scenario:table.headers.column', {
      context: 'max',
    }),
    filterFn: filterPercentage,
    meta: {
      tooltipLabel: t('scenario:table.tooltips.max'),
    },
    cell: ({ row }) =>
      renderValueCell(row, { value: row.original.prediction?.max }),
  },
];

export const getSummaryColumns = (
  t: TFunction
): ColumnDef<ScenarioTableData>[] => [
  {
    id: SCENARIO_TICKER_COLUMN_ID,
    header: t('scenario:table.headers.column', {
      context: 'ticker',
    }),
    meta: {
      alignment: 'start',
    },
    accessorKey: 'entity.tag',
    cell: props => <EntityCell entityTag={props.row.original.entity.tag} />,
  },
  {
    id: SCENARIO_HISTORY_COLUMN_ID,
    header: t('scenario:table.headers.column', {
      context: 'history',
    }),
    accessorKey: 'episodesCount',
    filterFn: filterNumber,
    cell: props => {
      const item = props.row.original;

      return (
        <Shared.HighlightText>
          <Trans
            t={t}
            i18nKey="scenario:table.cells.history"
            count={item.episodesCount}
            components={[<b key="highlight" />]}
          />
        </Shared.HighlightText>
      );
    },
  },
  {
    id: 'median_1w',
    accessorKey: 'summary.1w',
    header: t('scenario:table.headers.column', {
      context: 'median_1w',
    }),
    filterFn: filterPercentage,
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.summary['1w'],
        showStarIcon: row.original.bestHorizon === '1w',
      }),
  },
  {
    id: 'median_2w',
    accessorKey: 'summary.2w',
    header: t('scenario:table.headers.column', {
      context: 'median_2w',
    }),
    filterFn: filterPercentage,
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.summary['2w'],
        showStarIcon: row.original.bestHorizon === '2w',
      }),
  },
  {
    id: 'median_1m',
    accessorKey: 'summary.1m',
    header: t('scenario:table.headers.column', {
      context: 'median_1m',
    }),
    filterFn: filterPercentage,
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.summary['1m'],
        showStarIcon: row.original.bestHorizon === '1m',
      }),
  },
  {
    id: 'median_3m',
    accessorKey: 'summary.3m',
    header: t('scenario:table.headers.column', {
      context: 'median_3m',
    }),
    filterFn: filterPercentage,
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.summary['3m'],
        showStarIcon: row.original.bestHorizon === '3m',
      }),
  },
  {
    id: 'median_6m',
    accessorKey: 'summary.6m',
    header: t('scenario:table.headers.column', {
      context: 'median_6m',
    }),
    filterFn: filterPercentage,
    cell: ({ row }) =>
      renderValueCell(row, {
        value: row.original.summary['6m'],
        showStarIcon: row.original.bestHorizon === '6m',
      }),
  },
];

export interface EmptyTableData {
  entityTag: string;
}

export const getEmptyColumns = (t: TFunction) =>
  getSummaryColumns(t).map(column => ({
    ...column,
    accessorKey: 'entityTag',
  })) as ColumnDef<EmptyTableData>[];
