import { Loading } from '@toggle/design-system';
import React from 'react';

import { useThemeStore } from '../theme-controller/stores/useThemeStore';

export const Loader = () => {
  const colorScheme = useThemeStore(state => state.colorScheme);

  return <Loading colorScheme={colorScheme} />;
};
