import { useSubscription } from '~/stores/use-subscription/useSubscription';

export const NON_US_INDICES = [
  'Nikkei 225',
  'FTSE 100',
  'Euro Stoxx 50',
  'Nifty 50',
  'JPX Prime',
  'JPX Growth',
  'JPX Standard',
  'Macro DM',
  'Macro EM',
  'G7 FX',
];

type GetFilteredAssetIndicesProps<T extends { name: string }> = {
  data?: T[];
};

export const getFilteredAssetIndices = <T extends { name: string }>({
  data,
}: GetFilteredAssetIndicesProps<T>) => {
  const isBasicUser = useSubscription.getState().isBasicUser();

  if (!data?.length) {
    return [];
  }

  return isBasicUser
    ? data.filter(item => !NON_US_INDICES.includes(item.name))
    : data;
};
