import styled from 'styled-components';

export const Conditions = styled.section`
  border-radius: 4px;
  background: var(--surface-default);
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const ConditionItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
`;
