import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ProgressBar } from '~/components/progress-bar/ProgressBar';

export const SingleDocLoaderRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StyledProgressBar = styled(ProgressBar)`
  width: 220px;
  --line-width: 98%;
  --timing-function: cubic-bezier(0.02, 0.25, 0.7, 0.74);
`;

export const Label = styled.span`
  ${typographyV2.titleBase};
`;
