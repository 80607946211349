import { watchlist } from '@toggle/toggle';

import {
  FAVORITE_WATCHLIST,
  useRemoteStorage,
} from '~/hooks/use-remote-storage';
import { QUERY_KEY_WATCHLIST_SUMMARY } from '~/hooks/use-watchlist-summary/useWatchlistSummary';
import { queryClient } from '~/services/query-client/queryClient';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

import { create } from '../create-store/createStore';
import { resolveEntitiesFromCsv } from './entityResolver';
import { getListEntities } from './getListEntities';
import {
  addListIdToMap,
  deleteListIdFromMap,
  getUniqueListEntities,
  hasFavoriteWatchlist,
  mapAndSortWatchlists,
  mapListsWithNewListEntities,
  mapListsWithNewListName,
  mapListsWithUpdatedWatchlist,
} from './useWatchlist.utils';
import {
  List,
  Watchlist,
  WatchlistEntity,
  Watchlists,
  WatchlistStore,
} from './watchlist-types';

const handleFavorite = (id: string) => {
  const items = useRemoteStorage.getState().items;
  return id === items?.[FAVORITE_WATCHLIST];
};

// eslint-disable-next-line max-lines-per-function
export const useWatchlist = create<WatchlistStore>((set, get) => ({
  activeListId: undefined,
  activeModal: undefined,
  setActiveModal: ({ activeModal }) => set({ activeModal }),
  lists: [],
  error: undefined,
  listIdsByTag: new Map(),
  getLists: async () => {
    const res = await apiFetch(watchlist.getLists.path, {
      schema: watchlist.getLists.schema,
    });

    if (res.error) {
      set({ error: res.error });
    } else {
      const mappedWatchlists = mapAndSortWatchlists(res.data)
        .filter((watchList) => watchList.name);

      set({
        error: undefined,
        lists: mappedWatchlists,
        activeListId: mappedWatchlists[0]?.id,
      });
    }

    if (!res.error) {
      // important! need to wait until we get all the list to prevent race conditions
      const promises = res.data.map(list => get().getList(list.id));
      await Promise.all(promises);
    }
  },
  getList: async (id: string) => {
    const entities = await getListEntities(id);

    set(state => {
      const listIdsByTag = new Map(state.listIdsByTag);
      const lists = state.lists.map(list => ({
        ...list,
        entities: list.id === id ? entities : list.entities,
      }));

      lists.forEach(list => {
        list.entities.forEach(entity => {
          addListIdToMap({ listIdsByTag, listId: list.id, tag: entity.tag });
        });
      });

      return { lists, listIdsByTag };
    });
  },

  toggleFavoriteWatchlist: async id => {
    const storeItems = useRemoteStorage.getState().storeItems;
    const isFavoriteAlready = handleFavorite(id);

    if (isFavoriteAlready) {
      await storeItems({ [FAVORITE_WATCHLIST]: '' });
      set(state => ({
        lists: state.lists.map(list => ({
          ...list,
          isFavorite: false,
        })),
      }));
      return;
    }

    await storeItems({ [FAVORITE_WATCHLIST]: id });
    set(state => ({
      lists: state.lists.map(list => ({
        ...list,
        isFavorite: list.id === id,
      })),
      activeListId: id,
    }));
  },
  createListLoading: false,
  createList: async (name, tags) => {
    set({ createListLoading: true });
    const entities = tags.map(tag => ({ tag }));
    const res = await apiFetch<Watchlist>(watchlist.postList.path, {
      method: 'post',
      body: {
        name,
        entities,
      },
    });

    if (res.error) {
      set({ error: res.error });
      set({ createListLoading: false });
      return null;
    }

    set(state => {
      const newLists = [{ ...res.data, entities }, ...state.lists];

      const listIdsByTag = new Map(state.listIdsByTag);
      entities.forEach(entity => {
        addListIdToMap({
          listIdsByTag,
          listId: res.data.id,
          tag: entity.tag,
        });
      });

      const hasFavorite = hasFavoriteWatchlist();
      return {
        lists: newLists,
        listIdsByTag,
        activeListId:
          hasFavorite && state.activeListId ? state.activeListId : res.data.id,
      };
    });
    set({ createListLoading: false });
    return res.data.id;
  },
  updateListName: async (id, name) => {
    const res = await apiFetch<List>(watchlist.putList.path(id), {
      method: 'put',
      body: {
        name,
      },
    });
    set(({ lists }) =>
      res.error
        ? { error: res.error }
        : {
            lists: mapListsWithNewListName({ lists, id, name }),
          }
    );
    return res;
  },
  updateList: async ({ id, name, tags }) => {
    const entities = tags.map(tag => ({ tag }));
    const res = await apiFetch<List>(watchlist.putList.path(id), {
      method: 'put',
      body: {
        name,
        entities,
      },
    });

    if (res.error) {
      set({ error: res.error });
    } else {
      queryClient.removeQueries({
        queryKey: [QUERY_KEY_WATCHLIST_SUMMARY, id],
      });
      const listIdsByTag = new Map(get().listIdsByTag);
      tags.forEach(tag => addListIdToMap({ listIdsByTag, listId: id, tag }));

      set(({ lists }) => ({
        lists: mapListsWithUpdatedWatchlist({
          lists,
          id,
          name,
          entities,
        }),
      }));
    }

    return res;
  },
  deleteList: async id => {
    const res = await apiFetch(watchlist.deleteList.path(id), {
      method: 'delete',
    });

    if (res.error) {
      set({ error: res.error });
      return;
    }

    const listIdsByTag = new Map(get().listIdsByTag);
    const tagsToRemove = get()
      .lists.find(list => list.id === id)
      ?.entities.map(e => e.tag);

    tagsToRemove?.forEach(tag => {
      deleteListIdFromMap({ listIdsByTag, tag, listId: id });
    });

    const deleteItems = useRemoteStorage.getState().deleteItem;
    const isFavoriteAlready = handleFavorite(id);

    if (isFavoriteAlready) {
      await deleteItems(FAVORITE_WATCHLIST);
    }

    set(({ activeListId, lists }) => {
      const newLists = lists.filter(list => list.id !== id);
      return {
        error: undefined,
        lists: newLists,
        listIdsByTag,
        activeListId: id === activeListId ? newLists?.[0]?.id : activeListId,
      };
    });
  },
  addItemToList: async (id, tag) => {
    const res = await apiFetch<WatchlistEntity>(
      watchlist.postListEntities.path(id),
      {
        method: 'post',
        body: {
          tag,
          quantity: 0,
        },
      }
    );

    queryClient.removeQueries({
      queryKey: [QUERY_KEY_WATCHLIST_SUMMARY, id],
    });

    const listIdsByTag = new Map(get().listIdsByTag);
    addListIdToMap({ listIdsByTag, listId: id, tag });

    set(({ lists }) =>
      res.error
        ? { error: res.error }
        : {
            lists: mapListsWithNewListEntities({
              lists,
              id,
              entities: res.data,
            }),
            listIdsByTag,
          }
    );
  },
  removeItemFromList: async (id, tag) => {
    const res = await apiFetch(watchlist.deleteListEntities.path(id, tag), {
      method: 'delete',
    });

    if (res.error) {
      set({ error: res.error });
      return;
    }

    queryClient.removeQueries({
      queryKey: [QUERY_KEY_WATCHLIST_SUMMARY, id],
    });

    set(state => {
      const newLists = state.lists.reduce<Watchlist[]>((accumulator, list) => {
        if (list.id !== id) {
          return [...accumulator, list];
        }

        if (list.entities.length === 1) {
          return accumulator;
        }

        accumulator.push({
          ...list,
          entities: list.entities.filter(entity => entity.tag !== tag),
        });
        return accumulator;
      }, []);

      const listIdsByTag = new Map(state.listIdsByTag);
      deleteListIdFromMap({ listIdsByTag, tag, listId: id });

      let activeListId = state.activeListId;

      if (id === state.activeListId && newLists.length < state.lists.length) {
        activeListId = newLists?.[0]?.id;
      }

      return { lists: newLists, listIdsByTag, activeListId };
    });
  },
  isInAnyWatchlists: async (tag: string) => {
    const res = await apiFetch<Watchlists>(
      watchlist.getListsByEntities.path(tag)
    );

    if (res.error) {
      return [];
    }

    return res.data;
  },
  /** Use backend as source of truth  */
  isInWatchlist: async (tag: string, watchlistId: string) => {
    const res = await apiFetch<Watchlists>(
      watchlist.getListsByEntities.path(tag)
    );

    if (res.error) {
      return false;
    }

    return res.data.some(res => res.id === watchlistId);
  },
  createListFromCvs: async (tagsCsv: string, newListName: string) => {
    const { createList } = get();
    const tags = await resolveEntitiesFromCsv(tagsCsv);

    if (tags.length === 0) {
      return [null, []];
    }

    const listId = await createList(newListName, tags);
    return [listId, tags];
  },
  editListFromCvs: async (id: string, tagsCsv: string) => {
    const { addItemToList, lists } = get();
    const tags = await resolveEntitiesFromCsv(tagsCsv);

    if (tags.length === 0) {
      return [[], []];
    }

    const [uniqueTags, duplicateTags] = getUniqueListEntities({
      lists,
      id,
      tags,
    });
    await Promise.all(uniqueTags.map(tag => addItemToList(id, tag)));
    return [uniqueTags, duplicateTags];
  },
  createListByAsset: async (assetTag: string, newListName: string) => {
    const { createList } = get();
    const listId = await createList(newListName, [assetTag]);

    if (listId) {
      set({ activeListId: listId });
    }

    return listId;
  },
  editListByAsset: async (id: string, assetTag: string) => {
    const { addItemToList, lists } = get();
    const [uniqueAssets, duplicateTags] = getUniqueListEntities({
      lists,
      id,
      tags: [assetTag],
    });
    const resolved = await Promise.all(
      uniqueAssets.map(tag => addItemToList(id, tag))
    );
    return [resolved.length ? uniqueAssets : [], duplicateTags];
  },

  hasListWithName: (name: string) =>
    get().lists.some(list => list.name === name),
  setActiveListId: (id?: string) => set({ activeListId: id }),
}));
