import { EconomicEventOutput } from '@toggle/toggle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useRunScenario } from '~/hooks/use-run-scenario/useRunScenario';
import { appPaths } from '~/routes/app-paths';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';

import { ConditionOptions } from '../utils/getConditionOptions';
import { AssetsDropdown } from './AssetsDropdown';
import { ConditionsDropdown } from './ConditionsDropdown';
import * as S from './RunScenario.styles';

export enum AssetsConstituents {
  SPX = 'spx',
  FTSE100 = 'ftse100',
  DJI = 'dji',
  NI225 = 'ni225',
}

export interface WatchlistsOptions {
  key: string;
  name: string;
  isChecked: boolean;
}

export interface RunScenarioProps {
  data: EconomicEventOutput;
  conditionOptions: ConditionOptions[];
}

export const RunScenario = ({ data, conditionOptions }: RunScenarioProps) => {
  const { t } = useTranslation(['calendar']);
  const navigate = useNavigate();

  const [selectedAssets, setSelectedAssets] = useState<string>();
  const [showError, setShowError] = useState(false);
  const { lists } = useWatchlist();
  const [customConditionOptions, setCustomConditionOptions] =
    useState<ConditionOptions>();
  const { initializeAssetFilterOptions, initializeConditionFilterOptions } =
    useRunScenario({
      data,
      selectedAssets,
      customConditionOptions,
    });

  const handleSelectAssets = (id: string) => {
    if (showError && !!customConditionOptions) {
      setShowError(false);
    }

    setSelectedAssets(id);
  };

  const handleSetCustomCondition = (config: ConditionOptions) => {
    if (showError && !!selectedAssets) {
      setShowError(false);
    }

    setCustomConditionOptions(config);
  };

  return (
    <S.ScenarioRoot>
      <S.ScenarioTitle>{t('calendar:scenario.title')}</S.ScenarioTitle>
      <S.FilterGroupWrapper>
        {showError && (
          <S.StyledAlert
            data-testid="alert-error"
            variant="inline"
            message={t('calendar:addAssetsAndConditions')}
          />
        )}
        <AssetsDropdown
          selectedAssets={selectedAssets}
          watchlistLists={lists}
          handleSelectAssets={handleSelectAssets}
        />
        <ConditionsDropdown
          name={data.name}
          customCondition={customConditionOptions}
          handleSetCustomCondition={handleSetCustomCondition}
          conditionOptions={conditionOptions}
        />
        <S.StyledButton
          label={t('calendar:scenario.runScenario')}
          size="small"
          fullWidth={false}
          onClick={() => {
            if (!selectedAssets || !customConditionOptions) {
              setShowError(true);
              return;
            }

            initializeAssetFilterOptions();
            initializeConditionFilterOptions();
            navigate(appPaths.scenario);
          }}
          data-testid="btn-navigate-to-scenario"
        />
      </S.FilterGroupWrapper>
    </S.ScenarioRoot>
  );
};
