import React, { ReactNode } from 'react';

import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import { NavItem, NavItemVariants } from '../nav-item/NavItem';
import * as S from './CategoryGroup.styles';

export interface CategoryItem {
  label: ReactNode;
  key: string;
  icon?: ReactNode;
  badge?: ReactNode;
  onClick: (item: CategoryItem) => void;
  subitemGroups?: CategorySubitem[][];
  borderBottom?: boolean;
  variant?: NavItemVariants;
  notification?: ReactNode;
}

export interface CategorySubitem {
  label: ReactNode;
  key: string;
  onClick?: (subItem: CategorySubitem, categoryItem: CategoryItem) => void;
  icon?: SvgIconNames;
  badge?: ReactNode;
  disabled?: boolean;
}

export interface Category {
  id?: string;
  title?: string;
  items: CategoryItem[];
}

interface CategoryGroupProps {
  activeKey?: string;
  activeSubKey?: string;
  category: Category;
  disabledKeys?: string[];
  sideBarExpanded?: boolean;
}

export const CategoryGroup = ({
  activeKey,
  activeSubKey,
  category,
  disabledKeys,
  sideBarExpanded = true,
}: CategoryGroupProps) => {
  return (
    <S.CategoryGroupRoot>
      {category.title && <S.CategoryTitle>{category.title}</S.CategoryTitle>}
      <S.Items>
        {category.items.map(item => (
          <NavItem
            key={item.key}
            showBottomBorder={item?.borderBottom}
            item={item}
            activeKey={activeKey}
            activeSubKey={activeSubKey}
            disabled={disabledKeys?.includes(item.key)}
            sideBarExpanded={sideBarExpanded}
            variant={item.variant}
          />
        ))}
      </S.Items>
    </S.CategoryGroupRoot>
  );
};

CategoryGroup.Styled = S;
