import { Button } from '@toggle/design-system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appPaths, queryKeys } from '~/routes/app-paths';
import { ThemeOverviewWidget } from '~/widgets/theme-overview-widget/ThemeOverviewWidget';

import * as S from './ThemeOverviewDrawer.styles';

interface ThemeOverviewDrawerProps {
  handleClose: () => void;
  selectedThemeId: string;
}

export const ThemeOverviewDrawer = ({
  handleClose,
  selectedThemeId,
}: ThemeOverviewDrawerProps) => {
  const navigate = useNavigate();

  const navigateToTheme = () => {
    const newSearchParams = new URLSearchParams();

    if (!location.pathname.includes(appPaths.themeOverview)) {
      newSearchParams.set(queryKeys.history, location.pathname);
    }

    navigate({
      pathname: `${appPaths.themeOverview}/${selectedThemeId}`,
      search: newSearchParams.toString(),
    });
  };

  return (
    <S.StyledDrawer
      isOpen
      handleCloseClick={handleClose}
      headerButton={
        <Button variant="empty" iconName="Expand" onClick={navigateToTheme} />
      }
    >
      <ThemeOverviewWidget selectedThemeId={selectedThemeId} />
    </S.StyledDrawer>
  );
};
