import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const TableWrapper = styled.div<{ $rowCount: number }>`
  width: 359px;
  margin-top: 34px;

  table {
    thead {
      display: table;
      width: 100%;
      table-layout: fixed;
      border: 1px solid var(--border-soft);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid var(--border-default);
    }

    thead th {
      padding: 8px 12px;
    }

    thead th:first-child {
      border-top-left-radius: 8px;
    }

    thead th:last-child {
      border-top-right-radius: 8px;
    }

    tbody {
      display: block;
      height: ${({ $rowCount }) => ($rowCount > 10 ? '369px' : '190px')};
      overflow: auto;
      ${customScrollMinimal};
      border: 1px solid var(--border-soft);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    tbody td {
      padding: 6px 12px;
    }

    tbody tr:last-child td {
      border-bottom: none;
    }
  }
`;

export const TickerCell = styled.div`
  display: flex;
  gap: 8px;
`;

export const Ticker = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;
