import styled, { keyframes } from 'styled-components';

const linearProgressBarStripes = keyframes`
  from {
    background-position: 0 0;
  } 
  to {
    background-position: 30px 0;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: var(--background-default);
`;

export const InnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoBox = styled.div`
  margin: 0 auto;
  width: 200px;
  margin-bottom: 10%;
`;

export const Logo = styled.img``;

export const ProgressBar = styled.div`
  background: rgba(92, 112, 128, 0.2);
  border-radius: 40px;
  display: block;
  height: 8px;
  overflow: hidden;
  position: relative;
  max-width: 320px;
  width: 320px;
`;

export const ProgressBarMeter = styled.div`
  animation: ${linearProgressBarStripes} 300ms linear infinite reverse;

  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%
  );
  background-color: rgba(92, 112, 128, 0.8);
  background-size: 30px 30px;
  border-radius: 40px;
  height: 100%;
  position: absolute;
  transition: width 300ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width: 100%;
`;
