import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useCountryExposure } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { httpCode } from '~/utils/api-fetch/httpCode';

import { CountrySection } from '../../components/country-section/CountrySection';

export interface CountryExposureProps {
  entityTag: string;
}

export const CountryExposure = forwardRef(
  ({ entityTag }: CountryExposureProps, ref?: ForwardedRef<HTMLDivElement>) => {
    const {
      data: countries = [],
      isLoading,
      isError,
      error,
    } = useCountryExposure(entityTag);
    const { t } = useTranslation('widget');

    const filteredCountries = countries?.filter(c => c.code !== 'N/A ') ?? [];

    return (
      <div ref={ref}>
        <CountrySection
          countries={filteredCountries}
          title={t('widget:assetOverview.countryExposure')}
          tooltip={t('widget:assetOverview:knowledgeGraphTooltip', {
            context: 'countryExposure',
          })}
          collapsedHeight={220}
          isEmpty={!filteredCountries.length}
          isError={isError && error?.status !== httpCode.notFound}
          isLoading={isLoading}
        />
      </div>
    );
  }
);
