import { uniqueBy } from '@toggle/helpers';
import { Entity, formatTicker } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ChatMessagePrompt } from '~/components/chat';
import { appPaths } from '~/routes/app-paths';
import { RelatedCompaniesGrouped } from '~/services/related-companies/related-companies-service';
import {
  appendChartAssetParam,
  ChartSearchParamsAssetValue,
} from '~/utils/chart-search-utils/chart-search-utils';

import { CompareAssetDropdown } from '../compare-asset-dropdown/CompareAssetDropdown';

export interface CompareEntitiesOnChartProps {
  entity: Entity;
  relatedCompanies: RelatedCompaniesGrouped;
}

export const CompareEntitiesOnChart = ({
  entity,
  relatedCompanies,
  ...rest
}: CompareEntitiesOnChartProps) => {
  const { t } = useTranslation('widget');
  const navigate = useNavigate();
  const assetName = `${entity.name_short} (${formatTicker(entity)})`;

  const getAllEntitiesFromCompanies = (
    companies: RelatedCompaniesGrouped
  ): Entity[] => {
    const suppliers = companies.suppliers?.map(s => s.entity) ?? [];
    const competitors = companies.competitors?.map(s => s.entity) ?? [];
    return uniqueBy([...suppliers, ...competitors], 'default_snake').sort(
      (a, b) => b.search.rank - a.search.rank
    );
  };

  const navigateToChart = (values: ChartSearchParamsAssetValue[]) => {
    const searchParams = new URLSearchParams();
    values.forEach(value => {
      appendChartAssetParam(searchParams, value);
    });

    navigate({
      pathname: appPaths.chart,
      search: searchParams.toString(),
    });
  };

  const onSelectAsset = (newEntity: Entity) => {
    const entities = [entity, newEntity];
    const values: ChartSearchParamsAssetValue[] = entities.map(entity => [
      entity.tag,
      entity.default_snake,
      0,
    ]);

    navigateToChart(values);
  };

  return (
    <ChatMessagePrompt
      data-testid="compare-asset-on-chart"
      title={t('widget:assetOverview.chartComparison')}
      message={
        <>
          <span>
            {t('widget:assetOverview.compareAsset', {
              asset: assetName,
            })}
          </span>
          <CompareAssetDropdown
            onSelectAsset={onSelectAsset}
            entities={getAllEntitiesFromCompanies(relatedCompanies)}
          />
        </>
      }
      {...rest}
    />
  );
};
