import { MappedEntity } from '@toggle/toggle';
import React from 'react';

import { CalendarEvents, isCompanyEvent, isEconomicEvent } from '../utils';
import * as S from './CalendarEventDrawerHeader.styles';

export const getFallbackEntityTitle = (tag: string) =>
  tag.toUpperCase().replace('_', ':');

export const getEntityLogo = (tag: string, entity?: MappedEntity) => {
  return entity ? (
    <S.StyledAssetLogoContainer entity={entity} size={48} />
  ) : (
    <S.LogoFallback data-testid="logo-fallback">{tag.charAt(0)}</S.LogoFallback>
  );
};

export const getHeaderProps = (data: CalendarEvents) => {
  if (isEconomicEvent(data)) {
    return {
      title: data.name,
      subtitle: data.category,
      titleLogo: (
        <S.StyledFlagIcon
          data-testid="flag-icon"
          circular
          countryCode={data.country}
        />
      ),
      dateString: data.date,
      reported: data.reported,
    };
  } else if (isCompanyEvent(data)) {
    return {
      title: data.entity?.name ?? getFallbackEntityTitle(data.entity_tag),
      subtitle: data.entity?.formattedTicker ?? '',
      titleLogo: getEntityLogo(data.entity_tag, data.entity),
      dateString: data.effective_date,
      reported: data.reported,
    };
  } else {
    return {
      title: data.entity?.name ?? getFallbackEntityTitle(data.entity_tag),
      subtitle: data.entity?.formattedTicker ?? '',
      titleLogo: getEntityLogo(data.entity_tag, data.entity),
      dateString: data.reporting_date,
      reported: data.reported,
    };
  }
};
