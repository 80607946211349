import { RowSelectionState, SortingState } from '@tanstack/react-table';
import {
  EarningsPeriod,
  EarningsPeriods,
  ScreenerFilter,
  ScreenerFilters,
} from '@toggle/toggle';

import {
  EarningFilterGroupKeys,
  FilterGroups,
  FilterState,
  SharedFilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { create } from '~/stores/create-store/createStore';

import { filterEarnings, getSelectedRowKeys } from '../utils/utils';

const DEFAULT_STATE = {
  activeModalFilter: null,
  activeSortingState: [],
  defaultFilterOption: null,
  earningsData: [],
  filters: [],
  isViewSelectedOnly: true,
  rowSelection: {},
  rowSelectionByFilters: {},
  rowSelectionByManualEdit: {},
};

type InitStoreProps = {
  data: EarningsPeriods;
  filters: ScreenerFilters;
};

type EarningsStore = {
  activeModalFilter: ScreenerFilter | null;
  activeSortingState: SortingState;
  earningsData: EarningsPeriods;
  isViewSelectedOnly: boolean;
  rowSelection: RowSelectionState;
  rowSelectionByFilters: RowSelectionState;
  rowSelectionByManualEdit: RowSelectionState;
  setActiveModalFilter: (filter: ScreenerFilter | null) => void;
  setActiveSortingState: (sortingState: SortingState) => void;
  initStore: ({ data, filters }: InitStoreProps) => void;
  resetStore: () => void;
  getIsAllRowsSelected: () => boolean;
  getIsSomeRowsSelected: () => boolean;
  toggleAllRowsSelection: (selected: boolean) => void;
  updateRowSelection: (key: string, selected: boolean) => void;
  setIsViewSelectedOnly: (isSelected: boolean) => void;
  resetSelectionByManualEdit: () => void;
  getIsRowManualChanged: (key: string) => boolean;
  getIsRowMatchingFilters: (key: string) => boolean;
  onFilterGroupsChange: (
    filterGroups: FilterGroups<EarningFilterGroupKeys>,
    group?: EarningFilterGroupKeys
  ) => void;
  resetAllFilterOptions: () => void;
} & SharedFilterState<EarningFilterGroupKeys>;

const getSelectedRowFromEarningsData = (data: EarningsPeriods) =>
  data.reduce(
    (selectedRows: Record<string, boolean>, earning: EarningsPeriod) => {
      selectedRows[earning.reporting_date] = true;
      return selectedRows;
    },
    {}
  );

export const useEarningsStore = create<EarningsStore>((set, get) => ({
  filterGroups: {} as FilterGroups<EarningFilterGroupKeys>,
  initStore: ({ data, filters }: InitStoreProps) => {
    const selectedRows = getSelectedRowFromEarningsData(data);

    const filterState: FilterState = {
      allFilters: filters,
      activeFilterOptions: [],
      activeFilters: filters.filter(f => f.visible),
    };

    set({
      earningsData: data,
      rowSelection: selectedRows,
      rowSelectionByFilters: selectedRows,
      filterGroups: {
        EARNINGS_FILTERS: filterState,
      },
    });
  },
  resetStore: () => {
    set(DEFAULT_STATE);
  },
  ...DEFAULT_STATE,
  setActiveModalFilter: (filter: ScreenerFilter | null) =>
    set({ activeModalFilter: filter }),
  resetSelectionByManualEdit: () => {
    const { rowSelectionByFilters } = get();
    set({
      rowSelectionByManualEdit: {},
      rowSelection: { ...rowSelectionByFilters },
    });
  },
  setIsViewSelectedOnly: (isSelected: boolean) =>
    set({ isViewSelectedOnly: isSelected }),
  setActiveSortingState: sortingState =>
    set({ activeSortingState: sortingState }),
  toggleAllRowsSelection: (selected: boolean) => {
    const { updateRowSelection, earningsData } = get();

    for (const earnings of earningsData) {
      updateRowSelection(earnings.reporting_date, selected);
    }
  },
  updateRowSelection: (rowId: string, selected: boolean) => {
    const { rowSelection, rowSelectionByFilters, rowSelectionByManualEdit } =
      get();

    const updatedRowSelection = { ...rowSelection };
    updatedRowSelection[rowId] = selected;
    const updatedRowSelectionByManualEdit = { ...rowSelectionByManualEdit };

    if (rowSelectionByFilters[rowId] === selected) {
      delete updatedRowSelectionByManualEdit[rowId];
    } else {
      updatedRowSelectionByManualEdit[rowId] = selected;
    }

    set({
      rowSelection: updatedRowSelection,
      rowSelectionByManualEdit: updatedRowSelectionByManualEdit,
    });
  },
  getIsAllRowsSelected: () => {
    const { rowSelection, earningsData } = get();
    const selectedRowKeys = getSelectedRowKeys(rowSelection);
    return earningsData.length === selectedRowKeys.length;
  },
  getIsSomeRowsSelected: () => {
    const { rowSelection } = get();
    const selectedRowKeys = getSelectedRowKeys(rowSelection);
    return selectedRowKeys.length > 0;
  },
  getIsRowManualChanged: (rowId: string) => {
    const { rowSelectionByManualEdit } = get();
    return !!rowSelectionByManualEdit[rowId];
  },
  getIsRowMatchingFilters: (rowId: string) => {
    const { rowSelectionByFilters } = get();
    return rowSelectionByFilters[rowId];
  },
  onFilterGroupsChange: newFilterGroups => {
    const { earningsData } = get();
    const activeFilterOptions =
      newFilterGroups.EARNINGS_FILTERS.activeFilterOptions;
    const filteredEarnings = filterEarnings(activeFilterOptions, earningsData);
    const selection = getSelectedRowFromEarningsData(filteredEarnings);

    set({
      rowSelectionByManualEdit: {},
      rowSelection: selection,
      rowSelectionByFilters: selection,
      filterGroups: newFilterGroups,
    });
  },
  resetAllFilterOptions: () => {
    const { onFilterGroupsChange, filterGroups } = get();
    onFilterGroupsChange({
      EARNINGS_FILTERS: {
        ...filterGroups.EARNINGS_FILTERS,
        activeFilterOptions: [],
      },
    });
  },
}));
