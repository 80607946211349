import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useBrandAndProducts } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { httpCode } from '~/utils/api-fetch/httpCode';

import { KnowledgeGraphModule } from '../knowledge-graph-module/KnowledgeGraphModule';
import * as S from './BrandAndProduct.styles';

export interface BrandAndProductModuleProps {
  entityTag: string;
}

export const BrandAndProductModule = forwardRef(
  (
    { entityTag }: BrandAndProductModuleProps,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation('widget');

    const {
      data = [],
      isLoading,
      isRefetching,
      isError,
      error,
    } = useBrandAndProducts(entityTag);

    const productContent = data.map(product => ({
      title: product.name,
      id: product.code,
      description: product.evidence,
    }));

    // TEMP -- hide entirely instead of displaying empty error
    if (!productContent.length || error?.status === httpCode.notFound) {
      return null;
    }

    return (
      <div ref={ref}>
        <KnowledgeGraphModule
          isLoading={isLoading || isRefetching}
          isError={isError}
          isEmpty={!data?.length}
          title={t('widget:assetOverview.brandAndProducts')}
          tooltip={t('widget:assetOverview:knowledgeGraphTooltip', {
            context: 'brandAndProducts',
          })}
          collapsedHeight={220}
        >
          {productContent.map(product => (
            <S.ThemeListItem key={product.id} data-testid="product-item">
              <S.StyledPillDropdown label={product.title} />
              <S.StyledListDescription>
                {product.description}
              </S.StyledListDescription>
            </S.ThemeListItem>
          ))}
        </KnowledgeGraphModule>
      </div>
    );
  }
);
