import { WithNAFallback } from '@toggle/design-system';
import { EarningsPeriod } from '@toggle/toggle';
import { differenceInDays, format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Earnings.styles';
import { formatNumberWithUnit } from './utils/utils';

type EarningsProps = { latest?: EarningsPeriod };

export const UnreportedEarnings = ({ latest }: EarningsProps) => {
  const { t } = useTranslation('widget');
  const latestReportingDate = latest?.reporting_date
    ? new Date(latest.reporting_date)
    : new Date();
  const daysLeftToReport = differenceInDays(latestReportingDate, new Date());

  if (!latest || latest.reported || daysLeftToReport > 14) {
    return null;
  }

  return (
    <S.UpcomingRoot>
      <S.UpcomingItem>
        <S.UpcomingTitle>{t('widget:earnings.nextReport')}</S.UpcomingTitle>
        <S.UpcomingValue>{format(latestReportingDate, 'PP')}</S.UpcomingValue>
      </S.UpcomingItem>
      <S.UpcomingItem>
        <S.UpcomingTitle>{t('widget:earnings.periodEnding')}</S.UpcomingTitle>
        <S.UpcomingValue>{`${latest.fiscal_period} ${latest.fiscal_year}`}</S.UpcomingValue>
      </S.UpcomingItem>
      <S.UpcomingItem>
        <S.UpcomingTitle>
          {t('widget:earnings.epsExpectations')}
        </S.UpcomingTitle>
        <S.UpcomingValue>
          <WithNAFallback
            check={latest.eps.estimate !== undefined}
            value={latest.eps?.estimate?.toFixed(2)}
          />
        </S.UpcomingValue>
      </S.UpcomingItem>
      <S.UpcomingItem>
        <S.UpcomingTitle>
          {t('widget:earnings.revenueExpectations')}
        </S.UpcomingTitle>
        <S.UpcomingValue>
          <WithNAFallback
            check={latest.revenue.estimate !== undefined}
            value={formatNumberWithUnit(latest.revenue.estimate)}
          />
        </S.UpcomingValue>
      </S.UpcomingItem>
    </S.UpcomingRoot>
  );
};
