import { ScaleLinear } from 'd3';
import React from 'react';
import * as S from './CumulativeReturn.styles';

export interface CumulativeReturnProps {
  ts: {
    index: number;
    value: number;
  }[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  id: string;
}

export const CumulativeReturn = ({
  ts,
  xScale,
  yScale,
  id,
}: CumulativeReturnProps) => {
  return (
    <>
      <g clipPath={`url(#hl-clip-upper-${id})`}>
        <g key={`plot-positive`} aria-labelledby={`plot-positive`}>
          <S.StyledLinePlot
            ts={ts}
            scaleX={xScale}
            scaleY={yScale}
            $type="positive"
          />
        </g>
      </g>
      <g clipPath={`url(#hl-clip-bottom-${id})`}>
        <g key={`plot-negative`} aria-labelledby={`plot-negative`}>
          <S.StyledLinePlot
            ts={ts}
            scaleX={xScale}
            scaleY={yScale}
            $type="negative"
          />
        </g>
      </g>
    </>
  );
};
