import { SkeletonLoader, SkeletonLoaderProps } from '@toggle/design-system';
import { FC } from 'react';
import styled from 'styled-components';

const IS_CHART_MESSAGE_HEIGHT = '476px';
const IS_LEGACY_VARIANT_HEIGHT = '240px';
const DEFAULT_HEIGHT = '302px';

interface SkeletonChartLoaderProps extends SkeletonLoaderProps {
  $isChatMessage: boolean;
  $isLegacyVariant: boolean;
}

const getHeight = (props: SkeletonChartLoaderProps) => {
  return props.$isChatMessage
    ? IS_CHART_MESSAGE_HEIGHT
    : props.$isLegacyVariant
    ? IS_LEGACY_VARIANT_HEIGHT
    : DEFAULT_HEIGHT;
};

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  min-height: 100%;
`;

export const SkeletonChartLoader = styled<FC<SkeletonChartLoaderProps>>(
  SkeletonLoader
).attrs(props => ({
  areas: [
    {
      styles: {
        width: '100%',
        height: getHeight(props),
      },
    },
  ],
}))``;
