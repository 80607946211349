/* eslint-disable max-lines-per-function */
import { useQueryClient } from '@tanstack/react-query';
import {
  ChartAPIReturn,
  CustomMixDomain,
  getPrimaryDetails,
  isIntradayResample,
  PaneData,
  RangeHorizon,
  ResampleIntervals,
  SeriesType,
  TimeSeriesItem,
} from '@toggle/chart';
import {
  getTimeTillEndOfDayMs,
  isPriceSnake,
  isSinglePriceEntity,
} from '@toggle/helpers';
import { Entity, Resolution, SnakeMeta } from '@toggle/toggle';
import { useEffect, useRef, useState } from 'react';

import { fetchSnakeByName } from '~/services/overview-widget/overview-widget-service';
import { getPrices } from '~/services/price/price-service';
import { Tracking } from '~/services/tracking';
import { useChartLoad } from '~/views/turbo-chart/hooks/use-chart-load/useChartLoad';
import { ChartSearchType } from '~/views/turbo-chart/types/search.types';

import { ChartSettings } from '../../utils/chart-settings-utils/chart-settings-utils';
import {
  createChartDataWithChangedAsset,
  createTsDataFromSnake,
  getHorizons,
  getNewLineColorToken,
  getTargetPaneIndex,
  getUpdatedPaneData,
  updateAllPanes,
  updateChartPane,
  updatePane,
} from '../../utils/chart-utils/chart-utils';
import {
  getAssetResample,
  getChartDataEmptyResamples,
} from '../../utils/resample-utils/resample-utils';
import { useChartErrors } from '../use-chart-errors/useChartErrors';
import { useChartPane } from '../use-chart-pane/useChartPane';
import { useChartSettings } from '../use-chart-settings/useChartSettings';
import { ChartState, useChartState } from '../use-chart-state/useChartState';
import { ThemeVariant, useChartTheme } from '../use-chart-theme/useChartTheme';
import { useResample } from '../use-resample/useResample';

export interface GetTimeSeriesProps {
  latestAsset: Entity;
  resolution: Resolution;
}

export interface OnChartDataReadyProps {
  chartPanes: PaneData[];
  emptyResamples: ResampleIntervals[];
  validResample: ResampleIntervals;
  validHorizon: RangeHorizon | undefined;
  domain: CustomMixDomain | null;
  hidden: string[] | undefined;
  dailyTimeseries: TimeSeriesItem[];
}

export interface UseTurboChartProps {
  openSearch?: (
    type: ChartSearchType,
    {
      changingAssets,
      paneIndex,
    }: { changingAssets?: Entity; paneIndex?: number }
  ) => void;
  initialChartSettings?: ChartSettings;
  initialChartState?: ChartState;
  shouldUseSearchParams?: boolean;
  variants?: ThemeVariant[];
}

export type UseTurboChartReturn = ReturnType<typeof useTurboChart>;

export const useTurboChart = ({
  openSearch,
  initialChartSettings,
  initialChartState,
  shouldUseSearchParams = true,
  variants = [],
}: UseTurboChartProps) => {
  const { colors } = useChartTheme(variants);
  const {
    chartState,
    updateChartState,
    resetChartState,
    primaryAsset,
    primaryPane,
    setChartState,
  } = useChartState(shouldUseSearchParams);
  const [dailyTimeseries, setDailyTimeseries] = useState<TimeSeriesItem[]>();
  const [isLoading, setIsLoading] = useState(false);
  const chartPanes = chartState.chartPanes;

  const chartData = chartPanes.flatMap(panes => panes.chartAssetData);
  const activeAssets = chartData.map(c => c.entity);
  const activeAssetsPerPanels = chartPanes.map(pane =>
    pane.chartAssetData.map(c => ({ entity: c.entity, snakeMeta: c.snakeMeta }))
  );
  const selectedIndicators = activeAssetsPerPanels
    .flat()
    .map(item => item.snakeMeta?.snake);

  const hasSinglePriceEntity = activeAssets.some(isSinglePriceEntity);

  const [horizonRanges] = useState<RangeHorizon[]>(() =>
    Object.values(RangeHorizon)
  );
  const chartApiRef = useRef<null | ChartAPIReturn>(null);
  const [staleTime] = useState(getTimeTillEndOfDayMs);

  const { chartError } = useChartErrors({ chartData });

  const onChartDataReady = ({
    chartPanes,
    emptyResamples,
    validResample,
    validHorizon,
    dailyTimeseries,
    domain,
    hidden,
  }: OnChartDataReadyProps) => {
    resample.setEmptyResamples(emptyResamples);
    updateChartState({
      resample: validResample,
      horizon: validHorizon,
      chartPanes,
      domain,
      hidden,
    });
    updateChartData(chartPanes, validResample);
    setDailyTimeseries(dailyTimeseries);
  };

  const getTimeSeries = async ({
    latestAsset,
    resolution,
  }: GetTimeSeriesProps) => {
    return queryClient.fetchQuery({
      queryKey: ['turbo-chart-ts', latestAsset.default_snake, resolution],
      queryFn: async () => {
        try {
          if (isSinglePriceEntity(latestAsset)) {
            const snakeData = await fetchSnakeByName(latestAsset.default_snake);

            if (snakeData.error) {
              throw snakeData.error;
            }

            return createTsDataFromSnake(
              snakeData.data.result.data,
              isPriceSnake(latestAsset.default_snake)
            );
          } else {
            const prices = await getPrices({
              ticker: latestAsset.subscribable_ticker,
              entityTag: latestAsset.tag,
              resolution: resolution,
            });

            if (prices.data) {
              return prices.data;
            }

            const snakeData = await fetchSnakeByName(latestAsset.default_snake);
            return (
              snakeData.data &&
              createTsDataFromSnake(snakeData.data.result.data, false)
            );
          }
        } catch (error) {
          Tracking.captureException(error as Error);
          return undefined;
        }
      },
      staleTime,
    });
  };

  const { changeYAxisSettings, loadChartFromSettings } = useChartSettings({
    ...chartError,
    getTimeSeries,
    onChartDataReady,
    chartPanes,
    updateChartState,
    variants,
  });

  const { handleInitialChartLoad, retryInitialChartLoad } = useChartLoad({
    initialChartSettings,
    initialChartState,
    openSearch,
    loadChartFromSettings,
    setHasError: chartError.setHasError,
    shouldUseSearchParams,
    setIsLoading,
    updateChartState,
  });

  const {
    changeRange,
    updateChartData,
    onDomainChange,
    disable1DHorizon,
    resample,
  } = useResample({
    ...chartError,
    chartData,
    chartState,
    hasSinglePriceEntity,
    updateChartState,
    getTimeSeries,
    primaryAsset,
    setIsLoading,
  });

  const onAssetOrIndicatorChange = async ({
    entity,
    indicator,
    assetToChange,
  }: {
    indicator: SnakeMeta;
    entity: Entity;
    assetToChange?: Entity;
  }) => {
    chartError.setHasEmpty(false);
    chartError.setHasError(false);

    const asset = {
      ...entity,
      default_snake: indicator.snake,
      currency: indicator.currency,
    };

    if (selectedIndicators.includes(indicator.snake)) {
      return;
    }

    try {
      const isSinglePrice = isSinglePriceEntity(asset);
      const nextResample = getAssetResample({
        asset,
        hasSinglePriceEntity: isSinglePrice,
        selectedResample: resample.selectedResample,
        chartData: chartData,
      });

      const [prices, newChartAssetData] = await Promise.all([
        getTimeSeries({
          latestAsset: asset,
          resolution: nextResample,
        }),
        resample.fetchResampleIfNeeded(chartData, nextResample),
      ]);

      if (!prices?.length) {
        chartError.setAssetWithError(asset);
        chartError.handleEmptyChart(asset.name);
        return;
      }

      const updatedPanes = newChartAssetData.length
        ? updateAllPanes(newChartAssetData, chartPanes)
        : chartPanes;
      const primaryDetails = getPrimaryDetails(updatedPanes);

      const newPaneIndex = getTargetPaneIndex({
        chartData,
        chartPanes,
        isSinglePrice,
        assetToChange,
        indicator,
        primaryPaneId: primaryDetails.primaryPane?.id,
      });
      const targetPane = chartPanes[newPaneIndex];

      const newChartData = createChartDataWithChangedAsset({
        primaryAsset: primaryDetails.primaryAsset,
        chartData: targetPane?.chartAssetData || [],
        asset: asset,
        snakeMeta: indicator,
        prices,
        assetToChange,
        colors,
        newLineColorToken: getNewLineColorToken({
          indicator,
          chartData,
          colors,
        }),
      });

      const newChartPanes = updatePane(
        updatedPanes,
        newPaneIndex,
        getUpdatedPaneData({
          targetPane,
          chartAssetData: newChartData,
          isSinglePrice,
        })
      );

      resample.setEmptyResamples(
        getChartDataEmptyResamples(newChartPanes.flatMap(p => p.chartAssetData))
      );

      let nextHorizon;

      // if first asset is added
      // or if single price is added and currently on intraday --> fallback to default horizon
      if (
        !chartData.length ||
        (isSinglePrice && isIntradayResample(resample.selectedResample))
      ) {
        nextHorizon = getHorizons().activeHorizon;
      }

      updateChartData(newChartPanes, nextResample);
      updateChartState({
        chartPanes: newChartPanes,
        resample: nextResample,
        ...(nextHorizon && { horizon: nextHorizon }),
      });
    } catch (error) {
      chartError.setHasError(true);
    }
  };

  const chartPaneActions = useChartPane({
    chartPanes,
    updateChartState,
    resample,
    onAssetOrIndicatorChange,
  });

  useEffect(() => {
    return () => {
      chartApiRef.current?.reset();
    };
  }, []);

  const queryClient = useQueryClient();

  const removeActiveAsset = (defaultSnake: string, paneIndex = 0) => {
    const chartAssetPane = chartPanes[paneIndex];
    const filteredData = chartAssetPane.chartAssetData.filter(
      a => a.entity.default_snake !== defaultSnake
    );

    const newChartPanes = updatePane(chartPanes, paneIndex, {
      chartAssetData: filteredData,
    });

    updateChartData(newChartPanes);
    updateChartState({
      chartPanes: newChartPanes,
    });
    resample.setEmptyResamples(getChartDataEmptyResamples(filteredData));
  };

  const hideActiveAsset = async (
    snake: Entity['default_snake'],
    { isHidden = false, paneIndex = 0 }
  ) => {
    const updatedData = chartPanes[paneIndex].chartAssetData.map(a => {
      if (a.entity.default_snake === snake) {
        return { ...a, isHidden };
      }

      return a;
    });

    const hidden = updatedData
      .filter(item => item.isHidden)
      .map(item => item.entity.default_snake);
    const newChartPanes = updateChartPane(chartPanes, updatedData, paneIndex);
    updateChartData(newChartPanes);
    updateChartState({
      chartPanes: newChartPanes,
      hidden: hidden.length ? hidden : undefined,
    });
  };

  const changeChartLineColor = (
    colorId: number,
    { snake, paneIndex }: { snake?: string; paneIndex: number }
  ) => {
    const updatedChartPanes = chartPanes[paneIndex].chartAssetData.map(a => {
      if (a.entity.default_snake === snake) {
        return { ...a, lineColorToken: colors.getMulti()[colorId].token };
      }

      return a;
    });

    const newChartPanes = updateChartPane(
      chartPanes,
      updatedChartPanes,
      paneIndex
    );
    updateChartData(newChartPanes);
    updateChartState({ chartPanes: newChartPanes });
  };

  const changeSeriesType = (
    seriesType: SeriesType,
    { snake, paneIndex = 0 }: { snake?: string; paneIndex?: number }
  ) => {
    const updatedChartPane: PaneData = snake
      ? {
          ...chartPanes[paneIndex],
          chartAssetData: chartPanes[paneIndex].chartAssetData.map(a => {
            if (a.entity.default_snake === snake) {
              return { ...a, seriesType };
            }

            return a;
          }),
        }
      : { ...chartPanes[0], seriesType };

    updateChartState({
      chartPanes: [
        ...chartPanes.slice(0, paneIndex),
        updatedChartPane,
        ...chartPanes.slice(paneIndex + 1),
      ],
    });
  };

  return {
    ...chartError,
    ...resample,
    chartPaneActions,
    chartData,
    activeAssetsPerPanels,
    removeActiveAsset,
    hideActiveAsset,
    changeRange,
    changeChartLineColor,
    horizonRanges,
    changeSeriesType,
    chartApiRef,
    onAssetOrIndicatorChange,
    hasSinglePriceEntity,
    disable1DHorizon,
    onDomainChange,
    changeYAxisSettings,
    dailyTimeseries,
    chartState,
    handleInitialChartLoad,
    retryInitialChartLoad,
    chartIsLoading: isLoading,
    resetChartState,
    primaryPane,
    primaryAsset,
    setChartState,
    updateChartState,
  };
};
