import { Chip, customScrollMinimal } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { EntityHeader } from '~/modules/entity-header/EntityHeader';
import { OverviewKeyValues } from '~/modules/overview-key-values/OverviewKeyValues';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
`;

export const ContentContainer = styled.div<{
  $isDrawer: boolean;
}>`
  ${customScrollMinimal};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
  > div {
    padding: 28px 0px;
  }

  ${({ $isDrawer }) =>
    $isDrawer &&
    css`
      > div {
        &:not(:last-child) {
          border-bottom: 2px solid var(--border-soft);
        }
      }
    `}
`;

export const ChartChipSelectorWrapper = styled.div`
  ${Chip.Styled.Container} {
    padding: 6px 9px;
  }
`;

export const StyledOverviewKeyValuesWrapper = styled.div`
  ${OverviewKeyValues.Styled.KeyValuesList} {
    column-gap: 20px;
  }
`;

export const SplitContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const SplitLeftContainer = styled.div`
  width: 387px;
`;

export const SplitRightContainer = styled.div`
  width: 367px;
`;

export const StyledEntityHeader = styled(EntityHeader)<{ $isDrawer: boolean }>`
  ${({ $isDrawer }) => {
    return (
      $isDrawer &&
      css`
        box-shadow: var(--elevation-subtle);
      `
    );
  }}
  margin: 0px;
  padding: 0 20px 20px 20px;
`;

export const ButtonContainer = styled.div`
  padding: 20px 20px 0 20px;
`;
