import { useQueryClient } from '@tanstack/react-query';
import { ChartAssetData, EventsConfig, RangeHorizon } from '@toggle/chart';
import { Insight } from '@toggle/toggle';
import { subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';

import { ChartSearchParams } from '~/utils/chart-search-utils/chart-search-utils';
import { fetchFeed } from '~/views/turbo-chart/feed-services/feed';

const getStartDate = (endDate: Date, chartHorizon?: RangeHorizon) => {
  switch (chartHorizon) {
    case RangeHorizon.OneDay:
      return subDays(endDate, 1);
    case RangeHorizon.OneWeek:
      return subWeeks(endDate, 1);
    case RangeHorizon.TwoWeeks:
      return subWeeks(endDate, 2);
    case RangeHorizon.OneMonth:
      return subMonths(endDate, 1);
    case RangeHorizon.ThreeMonths:
      return subMonths(endDate, 3);
    case RangeHorizon.OneYear:
      return subYears(endDate, 1);
    case RangeHorizon.ThreeYears:
      return subYears(endDate, 3);
    case RangeHorizon.FiveYears:
      return subYears(endDate, 5);
    case RangeHorizon.TenYears:
      return subYears(endDate, 10);
    case RangeHorizon.SixMonths:
    default:
      return subMonths(endDate, 6);
  }
};

export interface UseChartInsightsProps {
  chartData: ChartAssetData[];
  eventsConfig: EventsConfig;
  chartHorizon?: RangeHorizon;
  hasStaleTime?: boolean;
}

export const useChartInsights = ({
  chartData,
  eventsConfig,
  chartHorizon,
  hasStaleTime = true,
}: UseChartInsightsProps) => {
  const [articleInsights, setArticleInsights] = useState<Insight[]>([]);
  const [insightsLoading, setInsightsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const assets = searchParams.getAll(ChartSearchParams.Asset).toString();
  const queryClient = useQueryClient();

  const fetchArticleInsights = async (primaryAsset: ChartAssetData) => {
    const ts = primaryAsset.ts;
    const endDate = new Date(ts[ts.length - 1].time);

    const articles = await queryClient.fetchQuery({
      queryKey: ['article-insights', primaryAsset.entity.tag],
      queryFn: () =>
        fetchFeed({
          entityTag: primaryAsset.entity.tag,
          pageLimit: 60,
          startDate: getStartDate(endDate, chartHorizon),
          endDate: new Date(ts[ts.length - 1].time),
        }),

      staleTime: hasStaleTime ? 3_600_000 : undefined,
    });

    if (articles.error) {
      setIsError(true);
      return [];
    }

    return articles.result;
  };

  const getInsights = async () => {
    setInsightsLoading(true);
    setIsError(false);
    const result = await Promise.all(chartData.map(fetchArticleInsights));
    setArticleInsights(result.flat());
    setInsightsLoading(false);
  };

  useEffect(() => {
    if (!eventsConfig.TMI) {
      return;
    }

    if (chartData.length) {
      getInsights();
    }
  }, [assets, eventsConfig, chartHorizon, chartData]);

  return {
    articleInsights,
    insightsLoading,
    isError,
    getInsights,
  };
};
