import React from 'react';

import { lazyComponentLoader } from '~/utils/component-loader/componentLoader';
import { AssetOverview } from '~/views/asset-overview/AssetOverview';
import { ForgotPasswordView } from '~/views/auth/forgotPassword';
import OnHold from '~/views/auth/on-hold';
import { ResetPasswordView } from '~/views/auth/reset-password-view/ResetPasswordView';
import { Verify } from '~/views/auth/verify/Verify';
import { CalendarView } from '~/views/calendar/CalendarView';
import { ServerError } from '~/views/error/server-error/ServerError';
import { Scenario } from '~/views/scenario/Scenario';
import { InteractiveBrokersCallback } from '~/views/settings/portfolio-view/components/interactive-brokers-callback/InteractiveBrokersCallback';

import { News } from '../views/news/News';
import { appPaths, authPaths, queryKeys } from './app-paths';
import { AppRoute } from './Routes';

const createRoutesFromPathArray = (
  paths: string[],
  route: AppRoute
): AppRoute[] => paths.map(path => ({ path, ...route }));

const Dashboard = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "dashboard" */ '../views/dashboard'
    ),
  'dashboard'
);

const Alfred = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "alfred" */ '../views/alfred'
    ),
  'alfred'
);

const Screener = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "screener" */ '../views/screener'
    ),
  'screener'
);

const Explore = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "explore" */ '../views/insights'
    ),
  'insights'
);

const TurboChart = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "turboChart" */ '../views/turbo-chart'
    ),
  'TurboChart'
);

const WatchlistManager = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "dashboard" */ '../views/watchlist-manager'
    ),
  'watchlist-manager'
);

const WatchlistPage = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "dashboard" */ '../views/watchlist-page'
    ),
  'watchlist-page'
);

const Settings = lazyComponentLoader(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '~/views/settings'
    ),
  'settings'
);
const SignUp = lazyComponentLoader(
  () => import(/* webpackChunkName: "signUp" */ '~/views/auth/sign-up'),
  'signUp'
);
const LoginView = lazyComponentLoader(
  () => import(/* webpackChunkName: "loginView" */ '~/views/auth/login-view'),
  'login-view'
);

const ThemeOverview = lazyComponentLoader(
  () =>
    import(/* webpackChunkName: "themeOverview" */ '~/views/theme-overview'),
  'ThemeOverview'
);

export const routes: AppRoute[] = [
  {
    path: appPaths.base,
    element: <Dashboard />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.dashboard',
    },
  },
  {
    path: `${appPaths.themeOverview}/:themeId`,
    element: <ThemeOverview />,
  },
  {
    path: appPaths.assetOverview,
    element: <AssetOverview />,
  },
  {
    path: appPaths.watchlistManager,
    element: <WatchlistManager />,
    crumb: {
      labelKey: 'watchlistManager:title',
    },
  },
  ...createRoutesFromPathArray(
    [appPaths.watchlist, `${appPaths.watchlist}/:watchlistId`],
    { element: <WatchlistPage /> }
  ),
  {
    path: appPaths.chart,
    element: <TurboChart />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.chart',
    },
  },
  {
    path: appPaths.screener,
    element: <Screener />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.screener',
    },
  },
  {
    path: `${appPaths.screener}/:screenerId`,
    element: <Screener />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.screener',
    },
  },
  {
    path: appPaths.news,
    element: <News />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.news',
    },
  },
  {
    path: appPaths.insights,
    element: <Explore />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.insights',
    },
  },
  ...createRoutesFromPathArray(
    [
      appPaths.settings.account,
      appPaths.settings.appearance,
      appPaths.settings.portfolio,
    ],
    { element: <Settings /> }
  ),
  {
    path: `${appPaths.settings.subscription}/*`,
    element: <Settings />,
  },
  {
    path: appPaths.accountOnHold,
    element: <OnHold />,
  },
];

export const authRoutes: AppRoute[] = [
  {
    path: authPaths.login,
    element: <LoginView />,
  },
  {
    path: authPaths.signUp,
    element: <SignUp />,
  },
];

export const commonRoutes: AppRoute[] = [
  {
    path: appPaths.verifyEmail,
    element: <Verify />,
  },
  {
    path: appPaths.error,
    element: <ServerError />,
  },
  ...createRoutesFromPathArray(
    [
      authPaths.forgotPassword,
      `${authPaths.forgotPassword}/:${queryKeys.emailReset}`,
    ],
    {
      element: <ForgotPasswordView />,
    }
  ),
  {
    path: authPaths.resetPassword,
    element: <ResetPasswordView />,
  },
  {
    path: appPaths.ib,
    element: <InteractiveBrokersCallback />,
  },
];

export const nonBasicRoutes: AppRoute[] = [
  {
    path: appPaths.events,
    element: <CalendarView />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.events',
    },
  },
  {
    path: appPaths.scenario,
    element: <Scenario />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.scenario',
    },
  },
  {
    path: appPaths.alfred,
    element: <Alfred />,
    crumb: {
      isSidebar: true,
      labelKey: 'common:sideNavigation.alfred',
    },
  },
];

export const featureRoutes: AppRoute[] = [];
