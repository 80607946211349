import { PillDropdown } from '@toggle/design-system';
import { Country, Entity, formatTicker } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import {
  useBrandAndProducts,
  useCountryExposure,
  useThemeExposure,
} from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { useKnowledgeGraphDropdownItems } from '~/hooks/use-knowledge-graph-dropdown-items/useKnowledgeGraphDropdownItems';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { useScreenerDropdownItems } from '~/hooks/use-screener-dropdown-items/useScreenerDropdownItems';
import { appPaths } from '~/routes/app-paths';
import { KnowledgeGraphSectionId } from '~/widgets/knowledge-graph-widget/KnowledgeGraphWidget';
import {
  COUNTRIES_DISPLAYED,
  PEER_GROUP_DISPLAYED,
  PRODUCTS_DISPLAYED,
  THEMES_DISPLAYED,
} from '~/widgets/overview/utils';

import { KnowledgeElement } from './components/KnowledgeElement';
import * as S from './OverviewKnowledgeGraph.styles';
import { getKnowledgeGraphNavigateParams } from './utils/knowledge-graph-utils';

export interface CompaniesData {
  competitors: {
    entity: Entity;
  }[];
  suppliers: {
    entity: Entity;
  }[];
  isLoading: boolean;
  isError: boolean;
}

export interface OverviewKnowledgeGraphProps {
  entityTag: string;
  isEtf: boolean;
  companiesData: CompaniesData;
}

export const OverviewKnowledgeGraph = ({
  entityTag,
  isEtf,
  companiesData,
}: OverviewKnowledgeGraphProps) => {
  const { t } = useTranslation(['widget', 'screener']);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { navigateToPage } = useNavigateToAssetOverview();
  const isAssetOverviewPage = location.pathname === appPaths.assetOverview;
  const { viewEvidenceItem, goToAssetOverviewItem } =
    useKnowledgeGraphDropdownItems();
  const { createNewScreenItem, addToCurrentScreenItem } =
    useScreenerDropdownItems();
  const {
    data: themes = [],
    isLoading: themesLoading,
    isError: themesError,
  } = useThemeExposure(entityTag, isEtf);

  const {
    data: countries = [],
    isLoading: countriesLoading,
    isError: countriesError,
  } = useCountryExposure(entityTag);

  const {
    data: products = [],
    isLoading: productsLoading,
    isError: productsError,
  } = useBrandAndProducts(entityTag);

  const filteredCountries = countries.filter(c => c.code !== 'N/A ') || [];
  const slicedCompetitors = companiesData.competitors.slice(
    0,
    PEER_GROUP_DISPLAYED
  );

  const renderCountryItem = (country: Country) => (
    <S.FlexContainer>
      <S.StyledFlagIcon countryCode={country.code} circular />
      {country.name}
    </S.FlexContainer>
  );

  const renderPeerGroupItem = (entity: Entity, label: string) => (
    <S.FlexContainer>
      <S.StyledAssetLogoContainer entity={entity} size={16} />
      {label}
    </S.FlexContainer>
  );

  const peerGroupsItems = [
    viewEvidenceItem({
      sectionId: KnowledgeGraphSectionId.peerGroup,
      entityTag,
      separated: true,
    }),
    goToAssetOverviewItem(),
  ];

  const countryGroupItems = ({ name, id }: { name: string; id: string }) => {
    return [
      viewEvidenceItem({
        sectionId: KnowledgeGraphSectionId.countryExposure,
        entityTag,
        separated: true,
      }),
      addToCurrentScreenItem({ name, id, key: 'country_exposure' }),
      createNewScreenItem({ name, id, key: 'country_exposure' }),
    ];
  };

  const getTooltip = (context: string) => {
    if (isAssetOverviewPage) {
      return undefined;
    }

    return t('widget:assetOverview:knowledgeGraphTooltip', {
      context: context,
    });
  };

  const renderPeerGroupsSection = ({
    slicedData,
    length,
    id,
  }: {
    slicedData: Record<'entity', Entity>[];
    length: number;
    id: string;
  }) => (
    <KnowledgeElement
      /* i18next-extract-mark-context-next-line ["competitors"] */
      title={t('widget:assetOverview.relatedCompany', { context: id })}
      tooltip={getTooltip(id)}
      isLoading={companiesData.isLoading}
      hasError={companiesData.isError}
      data-testid={`overview-${id}`}
      sectionId={KnowledgeGraphSectionId.peerGroup}
      entityTag={entityTag}
    >
      <>
        {slicedData.map(item => (
          <PillDropdown
            key={item.entity.tag}
            label={renderPeerGroupItem(item.entity, formatTicker(item.entity))}
            value={item.entity.tag}
            dropdownItems={peerGroupsItems}
            variant="outline"
          />
        ))}
        {length > PEER_GROUP_DISPLAYED && (
          <S.StyledShowMorePill
            onClick={() =>
              navigateToPage(
                getKnowledgeGraphNavigateParams({
                  sectionId: KnowledgeGraphSectionId.peerGroup,
                  searchParams,
                  entityTag,
                })
              )
            }
            label={t('widget:assetOverview.nMore', {
              length: length - PEER_GROUP_DISPLAYED,
            })}
          />
        )}
      </>
    </KnowledgeElement>
  );

  if (
    !themes.length &&
    !filteredCountries.length &&
    !companiesData.competitors.length &&
    !companiesData.suppliers.length
  ) {
    return null;
  }

  const navigateToKnowledgeGraph = () =>
    navigateToPage(
      getKnowledgeGraphNavigateParams({
        sectionId: KnowledgeGraphSectionId.themeExposure,
        searchParams,
        entityTag,
      })
    );

  return (
    <>
      <SectionTitle title={t('widget:assetOverview.knowledgeGraph')} />
      <S.Container>
        <KnowledgeElement
          title={t('widget:assetOverview.themeExposure')}
          isLoading={themesLoading}
          hasError={!!themesError || (!themesLoading && !themes.length)}
          data-testid="overview-theme-exposure"
          entityTag={entityTag}
          tooltip={getTooltip('themeExposure')}
          sectionId={KnowledgeGraphSectionId.themeExposure}
        >
          <>
            {themes.slice(0, THEMES_DISPLAYED).map(theme => (
              <PillDropdown
                data-testid={`theme-pill-${theme.id}`}
                key={theme.id}
                label={theme.name}
                value={theme.id}
                variant="outline"
                onClickPill={navigateToKnowledgeGraph}
              />
            ))}
            {themes.length > THEMES_DISPLAYED && (
              <S.StyledShowMorePill
                label={t('widget:assetOverview.nMore', {
                  length: themes.length - THEMES_DISPLAYED,
                })}
                onClick={navigateToKnowledgeGraph}
              />
            )}
          </>
        </KnowledgeElement>
        <KnowledgeElement
          title={t('widget:assetOverview.brandAndProducts')}
          isLoading={productsLoading}
          hasError={!!productsError || (!productsError && !products.length)}
          data-testid="overview-brand-and-product"
          entityTag={entityTag}
          tooltip={getTooltip('brandAndProducts')}
          sectionId={KnowledgeGraphSectionId.brandAndProducts}
        >
          <>
            {products.slice(0, PRODUCTS_DISPLAYED).map(product => (
              <PillDropdown
                key={product.code}
                label={product.name}
                value={product.code}
                variant="outline"
              />
            ))}
            {products.length > PRODUCTS_DISPLAYED && (
              <S.StyledShowMorePill
                label={t('widget:assetOverview.nMore', {
                  length: products.length - PRODUCTS_DISPLAYED,
                })}
                onClick={() =>
                  navigateToPage(
                    getKnowledgeGraphNavigateParams({
                      sectionId: KnowledgeGraphSectionId.brandAndProducts,
                      searchParams,
                      entityTag,
                    })
                  )
                }
              />
            )}
          </>
        </KnowledgeElement>
        <KnowledgeElement
          title={t('widget:assetOverview.countryExposure')}
          isLoading={countriesLoading}
          hasError={countriesError || !filteredCountries.length}
          data-testid="overview-country-exposure"
          tooltip={getTooltip('countryExposure')}
          sectionId={KnowledgeGraphSectionId.countryExposure}
          entityTag={entityTag}
        >
          <>
            {filteredCountries.slice(0, COUNTRIES_DISPLAYED).map(country => (
              <PillDropdown
                key={country.code}
                label={renderCountryItem(country)}
                variant="outline"
                dropdownItems={countryGroupItems({
                  name: country.name,
                  id: country.code.toUpperCase(),
                })}
              />
            ))}
            {filteredCountries.length > COUNTRIES_DISPLAYED && (
              <S.StyledShowMorePill
                label={t('widget:assetOverview.nMore', {
                  length: filteredCountries.length - COUNTRIES_DISPLAYED,
                })}
                onClick={() =>
                  navigateToPage(
                    getKnowledgeGraphNavigateParams({
                      sectionId: KnowledgeGraphSectionId.countryExposure,
                      searchParams,
                      entityTag,
                    })
                  )
                }
              />
            )}
          </>
        </KnowledgeElement>
        {slicedCompetitors.length > 0 &&
          renderPeerGroupsSection({
            slicedData: slicedCompetitors,
            length: companiesData.competitors.length,
            id: 'competitors',
          })}
      </S.Container>
    </>
  );
};
