import { z } from 'zod';

export type Price = z.infer<typeof priceSchema>;
export type SubscriptionResponse = z.infer<typeof subscriptionSchema>;
export type UserSubscription = z.infer<typeof subscriptionUserSchema>;
export type Subscription = z.infer<typeof subscriptionAvailableSchema>;
export type PriceWithTax = z.infer<typeof priceWithTaxResponseSchema>;
export type BankCard = z.infer<typeof bankCardResponseSchema>;
export type UpcomingSubscription = z.infer<typeof upcomingSubscriptionSchema>;

export enum UserRole {
  Basic = 'basic',
  Pro = 'pro',
  Copilot = 'copilot',
}

export enum BillingCycle {
  month = 'Monthly',
  year = 'Yearly',
  none = '',
}

export enum SubscriptionName {
  Basic = 'Basic',
  Pro = 'Pro',
  OrganizationPremium = 'OrganizationPremium',
  OrganizationTerminal = 'OrganizationTerminal',
  Premium = 'Premium',
  Terminal = 'Terminal',
  ReflexivityLight = 'ReflexivityLight',
  ReflexivityBasic = 'ReflexivityBasic',
}

export const SubscriptionNameToDisplayNameMap: Partial<
  Record<SubscriptionName, string>
> = {
  ReflexivityBasic: 'Reflexivity Basic',
  ReflexivityLight: 'Reflexivity Light',
};

export enum UserPaymentMethod {
  None = 'none',
  Checkout = 'checkout',
  Stripe = 'stripe',
  ThirdParty = 'third_party',
}

export enum CreditCardScheme {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Discover = 'discover',
  Amex = 'amex',
  Jcb = 'jcb',
  Diners = 'diners',
  UnionPay = 'unionpay',
  CartesBancaires = 'cartes_bancaires',
}

export enum DowngradeReason {
  Unspecified = 'unspecified',
  UserChoice = 'user_choice',
  PaymentIssue = 'payment_issue',
  TrialEnds = 'trial_ended',
}

export interface UserSubscriptionWithUpcoming extends UserSubscription {
  upcoming_subscription: UpcomingSubscription;
}

const subscriptionPromoSchema = z.object({
  price_id: z.string().optional(),
  metadata: z.object({
    paymentsLabel: z.string().optional(),
    settingsPriceLabel: z.string().optional(),
  }),
});

export const upcomingSubscriptionSchema = z.object({
  name: z.nativeEnum(SubscriptionName),
  starting_on: z.string(),
  promo: subscriptionPromoSchema.optional(),
  payment_method: z.nativeEnum(UserPaymentMethod),
  price: z.number(),
  billing_cycle: z.nativeEnum(BillingCycle),
  next_payment: z.string().optional(),
  role: z.nativeEnum(UserRole),
});

export const subscriptionUserSchema = z.object({
  role: z.nativeEnum(UserRole),
  name: z.nativeEnum(SubscriptionName),
  trial: z.boolean(),
  trial_to: z.string().optional(),
  price: z.number(),
  billing_cycle: z.nativeEnum(BillingCycle),
  next_payment: z.string().optional(),
  payment_method: z.nativeEnum(UserPaymentMethod),
  promo: subscriptionPromoSchema.optional(),
  upcoming_subscription: upcomingSubscriptionSchema.optional(),
  grace_hours_remaining: z.number().optional(),
  downgrade_reason: z.nativeEnum(DowngradeReason),
});

export const priceSchema = z.object({
  id: z.string(),
  billing_cycle: z.nativeEnum(BillingCycle),
  price: z.number(),
  currency: z.string(),
});

export const subscriptionAvailableSchema = z.object({
  id: z.string(),
  name: z.string(),
  prices: z.array(priceSchema),
  role: z.nativeEnum(UserRole),
});

export const subscriptionSchema = z.object({
  user: subscriptionUserSchema,
  available: z.array(subscriptionAvailableSchema),
});

export const priceWithTaxResponseSchema = z.object({
  id: z.string(),
  billing_cycle: z.nativeEnum(BillingCycle),
  price: z.number(),
  currency: z.string(),
  next_payment: z.string().optional(),
  tax_amount: z.number(),
  tax_percentage: z.number().optional(),
  total: z.number(),
  promo_amount: z.number().optional(),
  subtotal: z.number().optional(),
  promo: subscriptionPromoSchema.optional(),
  due_date: z.string().optional(),
  residual_amount: z.number().optional(),
});

export const bankCardResponseSchema = z.object({
  expiry_month: z.number(),
  expiry_year: z.number(),
  scheme: z.nativeEnum(CreditCardScheme),
  last_4: z.string(),
});
