import React, { ReactNode, RefObject } from 'react';

import { PinConfig } from '../table-cell/TableCell';
import { TableHeaderProps } from '../table-header/TableHeader';
import * as S from './StickyTableHeader.styles';

export interface StickyTableHeaderProps extends TableHeaderProps {
  size?: number;
  pinConfig?: PinConfig;
  children: ReactNode;
}

export const StickyTableHeader = React.forwardRef(
  (
    {
      pinConfig,
      size,
      children,
      isSorted,
      isSortable,
      ...rest
    }: StickyTableHeaderProps,
    forwardRef
  ) => {
    return (
      <S.StickyStyledTableHeader
        $pinConfig={pinConfig}
        isSortable={isSortable}
        isSorted={isSorted}
        {...rest}
        ref={forwardRef as RefObject<HTMLTableCellElement>}
      >
        <S.StickyContentContainer $size={size}>
          {children}
        </S.StickyContentContainer>
      </S.StickyStyledTableHeader>
    );
  }
);
