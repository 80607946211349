import { useMemo } from 'react';

import { useFilterOptionSearch } from '~/views/screener/use-filter-option-search/useFilterOptionSearch';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';

export const themeExposureFilterKey = 'theme_exposure';

export const useThemeSearch = (useDefaultOptions = true, enabled?: boolean) => {
  const { filterGroups } = useScreenerStore();
  const knowledgeGraphFiltersConfigOptions = useDefaultOptions
    ? filterGroups['Knowledge Graph Filters']?.allFilters.find(
        filter => filter.key === themeExposureFilterKey
      )?.config.options ?? []
    : [];

  const { data, handleTextChange } = useFilterOptionSearch({
    filter: themeExposureFilterKey,
    defaultOptions: knowledgeGraphFiltersConfigOptions,
    enabled,
  });

  const themeSearchResults = useMemo(() => {
    return data.map(filterOption => ({
      name: filterOption.name,
      id: filterOption.value.id as string,
      isTheme: true,
    }));
  }, [data]);

  return {
    themeSearchResults,
    handleTextChange,
  };
};
