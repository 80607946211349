import { config } from '~/config';

import { BaseWs, CloseReason } from '../base-ws/BaseWs';

type FindByType<Union, T> = Union extends { event: { type: T } }
  ? Union
  : never;

export type PortfolioNotificationPayload = {
  title: string;
  body: string;
  event: {
    action: 'connection.success' | 'connection.error';
    type: 'portfolio';
  };
  metadata?: {
    portfolio_id: string;
    provider_id: string;
    provider_name: string;
  };
};

interface AlfredResponsePayload {
  title: string;
  body: string;
  metadata: {
    message_id: string;
    conversation_id: string;
  };
  event: {
    type: 'alfred';
    action: 'message.update' | 'message.success';
  };
}

export type NotificationSocketResponses =
  | AlfredResponsePayload
  | PortfolioNotificationPayload;
export type NotificationResponseTypes =
  NotificationSocketResponses['event']['type'];

type NotificationResponseByType<
  T extends NotificationSocketResponses['event']['type']
> = FindByType<NotificationSocketResponses, T>;

export class NotificationWs extends BaseWs<NotificationSocketResponses> {
  isTabActive: boolean;
  listeners: Map<
    NotificationResponseTypes,
    ((v: any, isTabActive: boolean) => void)[]
  >;
  onVisibilityChange?: (e: Event) => void;

  constructor() {
    const url = config.api.wsNotifierRoot;
    super(url);
    this.onopen = this.onOpen;
    this.onclose = this.onClose;
    this.onmessage = this.onMessage;
    this.isTabActive = true;
    this.listeners = new Map();
  }

  private onOpen() {
    document.addEventListener('visibilitychange', this.visibilityChange);
  }

  private onClose(e: CloseEvent) {
    if (e.reason === CloseReason.NoConnectionRequired) {
      document.removeEventListener('visibilitychange', this.visibilityChange);
    }
  }

  private visibilityChange = (e: Event) => {
    const isTabActive = document.hidden && !this.closed;
    this.isTabActive = isTabActive;
    this.onVisibilityChange?.(e);
  };

  private onMessage = (message: NotificationSocketResponses) => {
    const current = this.listeners.get(message.event.type) ?? [];
    current.forEach(listener => listener(message, this.isTabActive));
  };

  subscribe<T extends NotificationResponseTypes>(
    type: T,
    callback: (
      response: NotificationResponseByType<T>,
      isActiveTab: boolean
    ) => void
  ) {
    const current = this.listeners.get(type) ?? [];
    this.listeners.set(type, [...current, callback]);

    return () => {
      const current = this.listeners.get(type) ?? [];
      this.listeners.set(
        type,
        current.filter(c => c !== callback)
      );
    };
  }
}
