import { Alert, Icon, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { DraggableWatchlistRow } from '../draggable-watchlist-row/DraggableWatchlistRow';
import { TableWatchlistManager } from '../table-watchlist-manager-widget/TableWatchlistManagerWidget';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  ${DraggableWatchlistRow.Styled.CellContentWrapper} {
    width: 20px;
  }

  ${TableWatchlistManager.Styled.StyledTanStackTableRoot} {
    border-left: none;
  }

  ${DraggableWatchlistRow.Styled.StyledTableCell} {
    background-color: transparent;
  }

  ${TableWatchlistManager.Styled.StyledTableHeader},
  ${TableWatchlistManager.Styled.StyledTableCell} {
    background-color: transparent;
    &:hover {
      cursor: default;
    }
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 36px;
  margin-top: auto;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
  gap: 10px;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: var(--text-critical);
  ${typographyV2.titleBase}
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const Label = styled.p`
  color: var(--text-soft);
  ${typographyV2.bodyBase}
`;

export const StyledAlert = styled(Alert)`
  margin-top: 8px;
  width: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;
