import { isPriceSnake } from '@toggle/helpers';

import {
  dispatchHoverEvent,
  dispatchPriceHoverEvent,
} from '~/core/events/events';
import { BaseChartAPIProps } from '~/types/create.types';
import { PriceHover, PriceHoverData } from '~/types/events.types';
import { formatPriceLabel } from '~/utils/format';
import {
  getActivePanes,
  getPaneDividerIndex,
  shouldResizePane,
} from '~/utils/pane/pane-utils';
import { getTimefromSeries } from '~/utils/timeseries/time-series';
import { getCurrentYIndex, getPercentage } from '~/utils/y-scale/y-scale';

import { isMergedAxis } from '../axis/axis';
import { ChartStoreReturn } from '../create/chart-store/chartStore';
import { reDraw } from '../create/redraw/redraw';
import { drawCrosshair, DrawCrosshairProps } from './../crosshair/crosshair';

let dragStart: number | undefined;
let dividerIndex: number | undefined;

export const onMouseDown = (event: MouseEvent) => {
  dragStart = event.offsetY;
};

export const onMouseUp = () => {
  dragStart = undefined;
};

export const onMouseMove = (
  event: MouseEvent,
  chartStore: ChartStoreReturn
) => {
  const base = chartStore.getState().base as BaseChartAPIProps;
  const { colors } = base.options;
  const index = Math.round(base.x.xScale.invert(event.offsetX));
  const isWithinBounds = index <= base.domain[1] && index >= base.domain[0];
  const paneDividerIndex = getPaneDividerIndex(event, base);

  if (!isWithinBounds) {
    return;
  }

  dividerIndex = dragStart !== undefined ? dividerIndex : paneDividerIndex;
  const isResizingPane = dragStart !== undefined && event.buttons === 1;

  if (dividerIndex) {
    base.canvasElement.style.cursor = 'row-resize';

    if (isResizingPane) {
      const diff = event.offsetY - (dragStart as number);
      dragStart = event.offsetY;

      if (shouldResizePane({ dividerIndex, base, diff })) {
        chartStore.getState().resizePane(dividerIndex, diff);
      }
    }
  }

  reDraw({ chartStore, hoveredPaneDivider: dividerIndex });

  if (isResizingPane || paneDividerIndex !== undefined) {
    return;
  }

  dispatchHoverEvent(event, base);

  const primaryAsset = base.primaryAsset;
  const hoveredPoints: DrawCrosshairProps['hoveredPoints'] = [];
  const panesPriceHover: PriceHover['panes'] = [];
  let minYCoord = Infinity;
  const time = primaryAsset.ts[index].time;

  getActivePanes(base.panes).forEach(pane => {
    const isMerged = isMergedAxis(pane.yAxisType);
    const { domainTimeSeries, seriesType, priceDisplay } = pane;
    const priceHoverData: PriceHoverData[] = [];

    for (let i = 0; i < pane.chartAssetData.length; i++) {
      const element = pane.chartAssetData[i];
      const axisIndex = getCurrentYIndex({
        isAxisMerged: isMerged,
        pane,
        asset: element,
      });
      const elementYScale = pane.y[axisIndex];

      if (element.isHidden || !elementYScale) {
        continue;
      }

      const time = getTimefromSeries(
        isPriceSnake(element.entity.default_snake),
        primaryAsset.ts[index].time
      );
      const timeseriesIdx = element.tsByTime.get(time);
      const baseValue = domainTimeSeries[i]?.start.close;

      if (timeseriesIdx !== undefined && baseValue !== undefined) {
        const value = element.ts[timeseriesIdx];
        const previousValue = element.ts[timeseriesIdx - 1]
          ? element.ts[timeseriesIdx - 1]
          : value;

        const shouldDrawCircle =
          seriesType === 'line' || element !== primaryAsset;
        const pointValue =
          priceDisplay === 'price'
            ? value.close
            : getPercentage(value.close, baseValue);
        const yCoord = elementYScale.yScale(pointValue);
        minYCoord = Math.min(minYCoord, yCoord);

        hoveredPoints.push({
          value: pointValue,
          y: elementYScale,
          yCoord,
          circleColor: colors.getThemeColor(element.lineColorToken),
          priceDisplay: pane.priceDisplay,
          shouldDrawCircle,
          yAxisSize: base.yAxisSizes[axisIndex],
        });
        priceHoverData.push({
          chartAssetData: element,
          currentPrice: value,
          previousPrice: previousValue,
          priceFormatted: formatPriceLabel(pointValue, pane.priceDisplay),
        });
      }
    }

    panesPriceHover.push({
      data: priceHoverData,
      id: pane.id,
    });
  });

  const date = new Date(time);
  drawCrosshair({
    ...base,
    x1: base.x.xScale(index),
    date,
    hoveredPoints,
  });
  dispatchPriceHoverEvent({
    base,
    priceHover: {
      panes: panesPriceHover,
      date,
      x: event.clientX,
      y: event.clientY - event.offsetY + minYCoord,
    },
  });

  dragStart = undefined;
  base.canvasElement.style.cursor = 'default';
};
