import { UndefinedInitialDataOptions } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { Entity, mapEntity } from '@toggle/toggle';

import { postEntity } from '~/services/entities/entity-service';
import { ApiFetchResponse } from '~/utils/api-fetch/apiFetch';

export const QUERY_KEY_ENTITY = 'QUERY_KEY_ENTITY';

export enum FILTER {
  id = 1,
  tag = 2,
}

export const mapEntities = (entities: Entity[]) => entities.map(mapEntity);

export const createEntityQueryOptions = (
  entityTag: string
): UndefinedInitialDataOptions<
  ApiFetchResponse<Entity>,
  Error,
  ApiFetchResponse<Entity>,
  string[]
> => ({
  queryKey: [QUERY_KEY_ENTITY, entityTag],
  queryFn: ({ signal }) => postEntity(entityTag, signal),
  staleTime: getTimeTillEndOfDayMs(),
  enabled: !!entityTag,
});
