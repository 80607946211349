import { Toggle } from '@toggle/design-system';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import {
  KEY_DEV_TYPES_EXCEPT_EARNINGS,
  useCompanyEvents,
} from '~/hooks/use-company-events/useCompanyEvents';
import { useEarningsFilter } from '~/hooks/use-earnings-filter/useEarningsFilter';
import { useEconomicsEvents } from '~/hooks/use-economics-events/useEconomicsEvents';
import { queryKeys } from '~/routes/app-paths';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';

import { CountrySelector } from '../country-selector/CountrySelector';
import { EventsTable } from '../events-table/EventsTable';
import {
  CountryFilter,
  isDateBeyondThreeMonthsAndSunday,
  TabsType,
} from '../utils';
import * as S from './EventTabs.styles';

export interface EventTabsProps {
  activeDate: Date;
  activeCountries: CountryFilter[];
  companyOnlyWatchlists: boolean;
  earningsOnlyWatchlists: boolean;
  forceIsTableLoading: boolean;
  setActiveCountries: (value: React.SetStateAction<CountryFilter[]>) => void;
  handleToggle: (checked: boolean, type: TabsType) => void;
  onDateChange: (date: Date) => void;
  setForceIsTableLoading: (isLoading: boolean) => void;
}

export const EventTabs = ({
  activeDate,
  companyOnlyWatchlists,
  earningsOnlyWatchlists,
  activeCountries,
  forceIsTableLoading,
  setForceIsTableLoading,
  setActiveCountries,
  handleToggle,
  onDateChange,
}: EventTabsProps) => {
  const { t } = useTranslation(['calendars']);
  const [searchParams, setSearchParams] = useSearchParams();
  const hasWatchlist = useWatchlist(state => !!state.lists.length);
  const [activeTab, setActiveTab] = useState(TabsType.Earnings);

  const TABS = useMemo(
    () => [
      {
        tabKey: TabsType.Earnings,
        tabLabel: t('calendar:earnings'),
      },
      {
        tabKey: TabsType.Economics,
        tabLabel: t('calendar:economic'),
      },
      {
        tabKey: TabsType.Company,
        tabLabel: t('calendar:company'),
      },
    ],
    []
  );

  useEffect(() => {
    const activeTabSearchParam = searchParams.get(
      queryKeys.activeCalendarTab
    ) as TabsType;

    if (Object.values(TabsType).includes(activeTabSearchParam)) {
      setActiveTab(activeTabSearchParam);
    } else {
      setActiveTab(TabsType.Earnings);
    }
  }, [searchParams]);

  const onTabChange = (_: number, tabKey: string) => {
    if (tabKey !== activeTab) {
      setSearchParams(params => {
        params.set(queryKeys.activeCalendarTab, tabKey);
        return params;
      });
    }
  };

  const {
    data: earningsData = {},
    isLoading: isEarningsLoading,
    isError: isEarningsError,
    refetch: earningRefetch,
  } = useEarningsFilter({
    start_date: startOfDay(activeDate).toISOString(),
    end_date: endOfDay(activeDate).toISOString(),
    watchlists: {
      all: earningsOnlyWatchlists,
    },
    sort_by: {
      field: 'reporting_date',
      desc: false,
    },
  });

  const {
    data: economicData = {},
    isLoading: isEconomicsLoading,
    isError: isEconomicsError,
    refetch: economicRefetch,
  } = useEconomicsEvents({
    from: startOfDay(activeDate).toISOString(),
    to: endOfDay(activeDate).toISOString(),
    countries: activeCountries.map(country => country.value.value),
  });

  const {
    data: companiesData = {},
    isLoading: isCompanyLoading,
    isError: isCompanyError,
    refetch: companyRefetch,
  } = useCompanyEvents({
    filters: [
      {
        start_date: startOfDay(activeDate).toISOString(),
        end_date: endOfDay(activeDate).toISOString(),
        types: KEY_DEV_TYPES_EXCEPT_EARNINGS,
      },
    ],
    sorting: [
      {
        desc: false,
        id: 'effective_date',
      },
    ],
    watchlists: {
      all: companyOnlyWatchlists,
    },
  });

  const getEventsData = () => {
    switch (activeTab) {
      case TabsType.Earnings:
      default:
        return {
          data: earningsData,
          isLoading: isEarningsLoading,
          isError: isEarningsError,
          handleRefetch: () => earningRefetch(),
        };
      case TabsType.Economics:
        return {
          data: economicData,
          isLoading: isEconomicsLoading,
          isError: isEconomicsError,
          handleRefetch: () => economicRefetch(),
        };
      case TabsType.Company:
        return {
          data: companiesData,
          isLoading: isCompanyLoading,
          isError: isCompanyError,
          handleRefetch: () => companyRefetch(),
        };
    }
  };

  const isEconomicTab = activeTab === TabsType.Economics;
  const isEarningsTab = activeTab === TabsType.Earnings;

  return (
    <>
      <S.StyledTabLines
        tabs={TABS}
        activeTab={activeTab}
        onChange={onTabChange}
      />
      <S.TabsContentWrapper>
        {isEconomicTab ? (
          <CountrySelector
            activeCountries={activeCountries}
            setActiveCountries={setActiveCountries}
          />
        ) : (
          hasWatchlist && (
            <S.ToggleWrapper data-testid="only-watchlist-toggle">
              <Toggle
                id="toggle-watchlist-only"
                leftLabel={t('calendar:table.toggleLabel')}
                isChecked={
                  isEarningsTab ? earningsOnlyWatchlists : companyOnlyWatchlists
                }
                onToggle={checked => handleToggle(checked, activeTab)}
              />
            </S.ToggleWrapper>
          )
        )}
        <EventsTable
          activeTab={activeTab}
          activeDate={activeDate}
          tableDataSet={getEventsData()}
          forceIsTableLoading={forceIsTableLoading}
          setForceIsTableLoading={setForceIsTableLoading}
        />
        <S.BottomBar>
          <S.StyledButton
            variant="empty"
            size="small"
            iconName="ArrowLeft"
            label={t('calendar:previousDay')}
            onClick={() => onDateChange(addDays(activeDate, -1))}
          />
          <S.StyledButton
            variant="empty"
            size="small"
            iconName="ArrowRight"
            iconPosition="right"
            label={t('calendar:nextDay')}
            disabled={isDateBeyondThreeMonthsAndSunday(activeDate)}
            onClick={() => onDateChange(addDays(activeDate, 1))}
          />
        </S.BottomBar>
      </S.TabsContentWrapper>
    </>
  );
};
