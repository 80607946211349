import styled from 'styled-components';

import { Tooltip } from '~/components/tooltip/Tooltip';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const StyledTooltip = styled(Tooltip)`
  pointer-events: none;
`;

export const TooltipRoot = styled.div`
  position: absolute;
  top: 40px;
  width: 1px;
  height: 1px;
`;

export const TooltipLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 313px;
  padding: 8px 6px;
  box-sizing: border-box;
`;

export const DateLabel = styled.span`
  ${typographyV2.titleSm}
`;

export const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${typographyV2.bodyXs}
`;

export const TooltipLegend = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipLegendColor = styled.div<{ $color: string }>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-color: ${({ $color }) => $color};
  ${borderRadius.rFull}
`;
