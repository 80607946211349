import { useActions } from '~/stores/use-actions/useActions';
import { useChat } from '~/stores/use-chat/useChat';
import { createNewMessage } from '~/stores/use-chat/utils/updateMessage';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

type CreateThemeOverviewMessageProps = {
  themeId: string;
  themeName: string;
};

export const createThemeOverviewMessage = async ({
  themeId,
  themeName,
}: CreateThemeOverviewMessageProps) => {
  const { append, update } = useChat.getState();
  const { removeAllActions } = useActions.getState();
  const getTheme = useThemeOverviewStore.getState().getTheme;
  const message = createNewMessage({ status: 'pending', question: themeName });
  append(message);
  removeAllActions();

  try {
    await getTheme(themeId);

    update({
      ...message,
      status: 'complete',
      question: themeName,
      reply: [
        {
          source: 'theme-overview-widget',
          data: {
            confidence: 1,
            scores: {},
            selectedThemeId: themeId,
          },
        },
      ],
    });
  } catch (error) {
    update({
      ...message,
      status: 'error',
    });
  }
};
