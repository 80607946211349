import {
  Indicator,
  SnakeMeta,
  snakeMetaArrSchema,
  SnakeMetaResponseV2,
  snakeMetaResponseV2Schema,
  TimeSeriesData,
  timeSeriesDataSchema,
} from '@toggle/toggle';

import { apiFetch, wretchRequest } from '~/utils/api-fetch/apiFetch';

const indicatorEndpointUrls = {
  leadingIndicator: `calculator/v1/toggle-leading-indicator`,
  rangeFinderIndicator: `calculator/v1/range-finder-index`,
  peakIndicator: `calculator/v1/peak-valley-indicator`,
  throughIndicator: `calculator/v1/trough-probability-indicator`,
};

export const timeseriesEndpoints = {
  timeSeries: `snake/v1/filtered`,
  toggleIndicators: `snake/indicators`,
};

export const indicatorQueryEndpoints = {
  [Indicator.TLI]: indicatorEndpointUrls.leadingIndicator,
  [Indicator.RFI]: indicatorEndpointUrls.rangeFinderIndicator,
  [Indicator.PI]: indicatorEndpointUrls.peakIndicator,
  [Indicator.TI]: indicatorEndpointUrls.throughIndicator,
};

export const getIndicatorData = (id: Indicator) => {
  return apiFetch<TimeSeriesData>(indicatorQueryEndpoints[id], {
    schema: timeSeriesDataSchema,
  });
};

export const getIndicatorSnakes = () => {
  return apiFetch<SnakeMetaResponseV2>(timeseriesEndpoints.toggleIndicators, {
    schema: snakeMetaResponseV2Schema,
  });
};

export const postTimeSeriesFiltered = (entities: string[]) => {
  return apiFetch<SnakeMeta[]>(timeseriesEndpoints.timeSeries, {
    schema: snakeMetaArrSchema,
    method: 'post',
    body: JSON.stringify({ entities }),
  });
};

export interface PostFilteredTimeSeriesProps {
  entities: string[];
  signal?: AbortSignal;
}

export const postFilteredTimeSeries = (
  { entities }: PostFilteredTimeSeriesProps,
  signal: AbortSignal
) => {
  return wretchRequest<SnakeMeta[]>(timeseriesEndpoints.timeSeries, {
    schema: snakeMetaArrSchema,
    method: 'post',
    options: {
      signal,
    },
    body: JSON.stringify({ entities }),
  });
};
