import {
  hslToHex,
  isHslColor,
} from '~/utils/color-conversion/color-conversion';

import { DesignToken, ThemeName, themes } from './themes';

export const getThemeColorInHex = (
  themeName: ThemeName,
  designToken: DesignToken
) => {
  const themeColor = themes[themeName][designToken];

  if (isHslColor(themeColor)) {
    return hslToHex(themeColor);
  }

  return themeColor;
};

export const getThemeColor = (
  colorToken: DesignToken,
  themeName: ThemeName
) => {
  const themeColor = themes[themeName][colorToken];
  return themeColor ? getThemeColorInHex(themeName, colorToken) : colorToken;
};
