import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableCell, TanStackTableRoot } from '@toggle/design-system';
import React from 'react';

import { TableAction } from '~/views/screener/results/screener-table-utils';
import {
  SCENARIO_HISTORY_COLUMN_ID,
  SCENARIO_TICKER_COLUMN_ID,
  ScenarioTableData,
} from '~/widgets/scenario/utils/scenario-table-utils/scenario-table-utils';

import { useScenarioStore } from '../../hooks/use-scenario-store/useScenarioStore';
import { SortableTableHeaderCell } from '../sortable-table-header-cell/SortableTableHeaderCell';
import * as S from './ScenarioTable.styles';

export interface ScenarioTableProps {
  columns: ColumnDef<ScenarioTableData>[];
  data: ScenarioTableData[];
  hasError: boolean;
  onRowClick: (item: ScenarioTableData) => void;
  refetch: () => void;
}

export const ScenarioTable = ({
  columns,
  data,
  hasError,
  onRowClick,
  refetch,
}: ScenarioTableProps) => {
  const {
    activeFilterOptions,
    columnFilters,
    showColumnFilterModal,
    clearColumnFilter,
  } = useScenarioStore(state => ({
    ...state.filterGroups['COLUMN_FILTERS'],
    columnFilters: state.columnFilters,
    showColumnFilterModal: state.showColumnFilterModal,
    clearColumnFilter: state.clearColumnFilter,
  }));

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    defaultColumn: {
      sortUndefined: 'last',
    },
  });
  const { rows } = table.getRowModel();

  const isCellNumber = (columnId: string) =>
    ![SCENARIO_TICKER_COLUMN_ID, SCENARIO_HISTORY_COLUMN_ID].includes(columnId);

  const handleDropdownAction =
    (column: Column<ScenarioTableData>) => (action: TableAction) => {
      if (action === 'ascending') {
        return column.toggleSorting(false);
      }

      if (action === 'descending') {
        return column.toggleSorting(true);
      }

      if (action === 'clearSort') {
        return column.clearSorting();
      }

      if (action === 'clearFilter') {
        clearColumnFilter(column.id);
      }

      if (action === 'filter' || action === 'editFilter') {
        showColumnFilterModal(column.id);
      }
    };

  const getEnabledKeys = (column: Column<ScenarioTableData>) => {
    const enabledKeys: TableAction[] = ['ascending', 'descending'];

    if (column.getIsFiltered()) {
      enabledKeys.push('clearFilter', 'editFilter');
    } else if (column.id !== SCENARIO_TICKER_COLUMN_ID) {
      enabledKeys.push('filter');
    }

    if (column.getIsSorted()) {
      enabledKeys.push('clearSort');
    }

    return enabledKeys;
  };

  if (hasError) {
    return (
      <S.StyledErrorMessageWrapper
        displayIcon
        onClick={refetch}
        data-testid="scenario-error-state"
      />
    );
  }

  return (
    <S.ScenarioTableRoot>
      <TanStackTableRoot data-testid="scenario-table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <SortableTableHeaderCell
                  key={header.id}
                  header={header}
                  handleAction={handleDropdownAction(header.column)}
                  enabledKeys={getEnabledKeys(header.column)}
                  activeFilterOptions={activeFilterOptions}
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.id} onClick={() => onRowClick(row.original)}>
              {row.getVisibleCells().map(cell => (
                <TableCell
                  key={cell.id}
                  isNumber={isCellNumber(cell.column.id)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </TanStackTableRoot>
    </S.ScenarioTableRoot>
  );
};
