import React from 'react';

import { useThemePerformanceReturn } from '../hooks/use-theme-performance-return';
import { Horizon } from '../ThemePerformanceSection';
import * as S from './HorizonItem.styles';

type HorizonItemProps = {
  entityTags: string[];
  horizonToIndexMap: Map<string, number>;
  horizon: Horizon;
  isSelected: boolean;
  onClick: () => void;
};

export const HorizonItem = ({
  entityTags,
  horizon,
  horizonToIndexMap,
  isSelected,
  onClick,
}: HorizonItemProps) => {
  const { value, isNegative } = useThemePerformanceReturn({
    entityTags,
    numDays: horizonToIndexMap.get(horizon.toLowerCase()),
  });

  return (
    <S.HorizonWrapper $isSelected={isSelected} onClick={onClick}>
      <S.HorizonTitle>{horizon}</S.HorizonTitle>
      <S.HorizonValue $isNegative={isNegative}>{value}</S.HorizonValue>
    </S.HorizonWrapper>
  );
};
