import { Tooltip } from '@toggle/design-system';
import { formatTicker } from '@toggle/toggle';
import React from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { useEntity } from '~/hooks/use-entities';

import * as S from './EntityCell.styles';

export interface EntityCellProps {
  entityTag: string;
}

export const EntityCell = ({ entityTag }: EntityCellProps) => {
  const { data } = useEntity(entityTag);
  const entity = data?.data;

  if (!entity) {
    return null;
  }

  return (
    <S.EntityCellRoot>
      <AssetLogoContainer entity={entity} size={20} />
      <Tooltip label={entity.name_full} inPortal>
        <S.Ticker>{formatTicker(entity)}</S.Ticker>
      </Tooltip>
    </S.EntityCellRoot>
  );
};
