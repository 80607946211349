import throttle from 'lodash/throttle';

import { CloseReason } from '~/services/base-ws/BaseWs';
import { PriceWS, UpdateHandler } from '~/services/price-ws/PriceWS';
import { create } from '~/stores/create-store/createStore';

type WsInitConfig = {
  updateHandler: UpdateHandler;
  isPro: boolean;
};

interface SubscribeData {
  tickers: string[];
  updateHandler: UpdateHandler;
  isPro: boolean;
}

interface LivePriceWebSocketStore {
  initialize: (config: WsInitConfig) => PriceWS;
  getPriceWS: (config: WsInitConfig) => PriceWS;
  subscribe: (data: SubscribeData) => void;
  unsubscribe: (tickers: string[]) => void;
  closeConnection: () => void;
  priceWS: PriceWS | null;
}

export const livePriceWebSocket = create<LivePriceWebSocketStore>(
  (set, get) => ({
    priceWS: null,
    initialize: ({ updateHandler, isPro }) => {
      const throttledHandler = throttle(updateHandler, 500);
      const priceWS = new PriceWS({ updateHandler: throttledHandler, isPro });
      set({ priceWS });

      return priceWS;
    },

    getPriceWS: ({ updateHandler, isPro }) => {
      const { priceWS, initialize } = get();
      const isWebSocketClosed = !priceWS || priceWS.closed;

      return isWebSocketClosed ? initialize({ updateHandler, isPro }) : priceWS;
    },

    subscribe: ({ tickers, updateHandler, isPro }) => {
      const { getPriceWS } = get();
      const priceWS = getPriceWS({ updateHandler, isPro });

      priceWS.subscribe(tickers);
    },

    unsubscribe: (tickers: string[]) => {
      const { priceWS } = get();

      priceWS?.unsubscribe(tickers);
    },

    closeConnection: () => {
      const { priceWS } = get();

      priceWS?.close(CloseReason.NoConnectionRequired);
    },
  })
);
