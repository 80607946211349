import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';

export const AssetChipRoot = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--border-default);
  overflow: hidden;
  box-sizing: border-box;
`;

export const Entity = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)`
  border-radius: 50%;
`;

export const Ticker = styled.span`
  ${typographyV2.bodySm};
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--surface-hover);
  }
`;

export const EntityButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 2px 6px 8px;
`;

export const WatchlistButton = styled(StyledButton)<{
  $isActive?: boolean;
}>`
  padding: 6px 8px 6px 2px;

  svg {
    fill: ${props =>
      props.$isActive ? 'var(--icon-success)' : 'var(--icon-default)'};
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`;
