import { Button, Icon } from '@toggle/design-system';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as S from './ScenarioLoader.styles';

export const ASSETS_NUMBERS_WARNINGS = 500;

export interface ScenarioLoaderProps {
  countEntities: number;
  onAbort: () => void;
  onEditAssets?: () => void;
  conditionsCount?: number;
}

export const ScenarioLoader = ({
  countEntities,
  onAbort,
  conditionsCount,
}: ScenarioLoaderProps) => {
  const { t } = useTranslation('scenario');

  const [isOpen, setIsOpen] = useState(true);

  const keys = ['pastEpisodes', 'analyzing', 'evaluating'];
  const labels = keys.map(key =>
    /* i18next-extract-mark-context-next-line  ["pastEpisodes", "analyzing", "evaluating"] */
    t('scenario:scenarioLoader', { context: key })
  );

  // duration based on number of conditionsCount with minimum of 2s, distribute across 3 bars
  const ProgressBarDuration = Math.max((conditionsCount ?? 0) * 3000, 2000);

  const loader = (
    <S.StyledSplashLoader
      data-testid="scenario-loading"
      labels={labels}
      isScenario
      duration={ProgressBarDuration}
    />
  );

  if (countEntities < ASSETS_NUMBERS_WARNINGS) {
    return <S.Container>{loader}</S.Container>;
  }

  return (
    <S.Container>
      {isOpen && (
        <S.WarningContainer data-testid="scenario-loading-warning">
          <div>
            <Icon
              iconName="InformationLightCircle"
              size={20}
              fillColor="var(--icon-primary)"
            />
          </div>
          <S.DescriptionWrapper>
            <S.HeaderWrapper>
              <S.WarningTitle>{`${t(
                'scenario:warning.title'
              )} 🚀`}</S.WarningTitle>
              <S.Close
                data-testid="warning-close-button"
                onClick={() => setIsOpen(false)}
              >
                <Icon iconName="Close" size={13} />
              </S.Close>
            </S.HeaderWrapper>
            <S.WarningTextContent>
              <Trans
                i18nKey={'scenario:warning.content'}
                components={[
                  <>
                    <br />
                    <br />
                  </>,
                ]}
              />
            </S.WarningTextContent>
            <Button
              label={t('scenario:warning.btnLabel')}
              variant="tertiary"
              size="small"
              onClick={onAbort}
            />
          </S.DescriptionWrapper>
        </S.WarningContainer>
      )}
      {loader}
    </S.Container>
  );
};
