import { CheckBox } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledCheckBox = styled(CheckBox)`
  cursor: pointer;
  width: 20px;
  height: 20px;

  ${CheckBox.Styled.Checkmark} {
    margin-top: -1px;
  }
`;
