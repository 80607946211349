import { typographyV2 } from '@toggle/design-system';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';

export const Title = styled.p<{
  $variant: 'default' | 'small';
  $hasTooltip: boolean;
}>`
  ${typographyV2.titleLg};
  text-underline-offset: 8px;

  ${props =>
    props.$variant === 'small' &&
    css`
      ${typographyV2.titleBase};
      text-underline-offset: 4px;
    `}

  ${props =>
    props.$hasTooltip &&
    css`
      text-decoration: underline dotted var(--border-default);
    `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  width: fit-content;
  margin-bottom: 12px;
`;

export const StyledLink = styled(Link)`
  display: inline-block;

  &:hover,
  &:focus-within {
    outline: none;
    color: var(--text-primary);

    svg {
      transition: transform 300ms ease;
      transform: translateX(5px);
      fill: var(--icon-primary);
    }
  }
`;
