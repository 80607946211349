import { TableHeader } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { underlineTextStyles } from '~/components/tooltip-container/TooltipContainer.styles';

export const StyledTableHeader = styled(TableHeader)<{
  $hasTooltip?: boolean;
  $isHoverEnabled?: boolean;
  $isOver?: boolean;
}>`
  position: sticky;
  top: 0;
  z-index: var(--z-index-header-cell);
  border-bottom: 1px solid var(--border-default);
  vertical-align: middle;

  ${props =>
    props.$isOver &&
    css`
      background-color: var(--surface-hover);
    `}

  ${props =>
    props.$isHoverEnabled &&
    css`
      &:hover {
        cursor: pointer;
        background-color: var(--surface-hover);
      }
    `}


  ${props =>
    props.$hasTooltip &&
    css`
      ${underlineTextStyles};
    `}
`;
