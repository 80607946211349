const DEFAULT_USER_LOCALE = 'en-US';
const DEFAULT_CURRENCY = 'USD';

export const formatPriceWithCurrency = (
  value: number,
  currency = DEFAULT_CURRENCY,
  decimals = 2,
  locale = DEFAULT_USER_LOCALE
) => {
  try {
    return value
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimals,
      })
      .replace('\u00A0', '');
  } catch {
    return value.toFixed(decimals);
  }
};

export const getCurrencySymbol = (
  currency: string,
  locale = DEFAULT_USER_LOCALE
) => {
  try {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/[\d\u00A0]/g, '')
      .trim();
  } catch {
    return '';
  }
};
