import {
  borderRadius,
  ThreeDots,
  typographyV2,
  values,
} from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Body = styled.div<{ $withBorder: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background: var(--background-default);
  ${borderRadius.r2};
  ${({ $withBorder }) =>
    $withBorder &&
    css`
      border: 1px solid var(--border-soft);
    `}
`;

export const Title = styled.div<{
  $useRoundedBottomBorders: boolean;
}>`
  width: calc(var(--chat-message-width) - 1px);
  background-color: var(--surface-default);
  padding: 16px;
  border-top-left-radius: ${values.r2};
  border-top-right-radius: ${values.r2};
  ${typographyV2.titleBase}
  white-space: pre-wrap;
  border: 1px solid var(--border-soft);

  ${({ $useRoundedBottomBorders }) =>
    $useRoundedBottomBorders &&
    css`
      border-bottom-left-radius: ${values.r2};
      border-bottom-right-radius: ${values.r2};
      border: none;
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: var(--chat-message-width);
`;

export const Content = styled.section<{ $usePadding: boolean }>`
  padding: ${props => (props.$usePadding ? '16px' : '0')};
  ${typographyV2.bodyBase};
`;

export const ChatPromptsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LoaderWrapper = styled.div`
  padding: 12px 16px;
`;

export const Loader = styled(ThreeDots)`
  background: var(--background-default);
  margin: 0;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
`;
