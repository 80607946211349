import styled from 'styled-components';

export const HighLowTripleLineChartRoot = styled.div<{ $maxHeight?: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  ${({ $maxHeight }) =>
    !!$maxHeight ? `min-height: ${$maxHeight + 50}px;` : 'min-height: 300px;'}
  margin-bottom : 20px;

  & [role='tooltip'] {
    z-index: var(--z-tooltip);
  }
`;

export const Chart = styled.svg`
  width: 100%;
  min-height: 100%;
`;

export const BaseLine = styled.line`
  stroke: var(--border-default);
  stroke-width: 2px;
`;

export const HoverAreaReact = styled.rect`
  fill: transparent;
`;

export const DashedLine = styled.line`
  stroke: var(--border-default);
  stroke-dasharray: 6 6;
`;
