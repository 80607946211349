import React from 'react';

import * as S from './ChartLoading.styles';

interface ChartLoadingProps {
  isLegacyVariant: boolean;
  isChatMessage: boolean;
  className?: string;
}

export const ChartLoading = ({
  isLegacyVariant,
  isChatMessage,
  className,
}: ChartLoadingProps) => {
  return (
    <S.SkeletonWrapper data-testid="chart-loading" className={className}>
      <S.SkeletonChartLoader
        $isLegacyVariant={isLegacyVariant}
        $isChatMessage={isChatMessage}
      />
    </S.SkeletonWrapper>
  );
};
