import { SkeletonLoader } from '@toggle/design-system';
import { format } from 'date-fns';
import React from 'react';

import * as S from './CardDay.styles';

export interface CardDayProps {
  date: Date;
  isActive?: boolean;
  rows: {
    label: string;
    count: number;
    isLoading: boolean;
    bgColor?: string;
  }[];
  handleCardClick: () => void;
}

export const CardDay = ({
  isActive = false,
  date,
  rows,
  handleCardClick,
}: CardDayProps) => {
  const timestamp = date.valueOf();

  return (
    <S.CardContainer
      $isActive={isActive}
      data-testid={`card-day-${format(date, 'EEEE')}`}
      onClick={handleCardClick}
    >
      <S.CardDateWrapper>
        <S.DayOfWeek>{format(date, 'EEEE')}</S.DayOfWeek>
        <span>{format(date, 'd')}</span>
      </S.CardDateWrapper>
      <S.RowWrapper>
        {rows.map((r, index) => {
          if (r.isLoading) {
            return (
              <SkeletonLoader
                key={`${timestamp}-${index}`}
                areas={[
                  {
                    styles: {
                      width: '100%',
                      height: '20px',
                      borderRadius: '4px',
                    },
                  },
                ]}
              />
            );
          }

          const isEmptyRow = !r.count;
          return (
            <S.Row
              key={`${timestamp}-${index}`}
              $bgColor={isEmptyRow ? 'inherit' : r.bgColor}
              data-testid={`${timestamp}-${r.label}`}
            >
              {!isEmptyRow && (
                <>
                  <span>{r.label}</span>
                  <span>{r.count}</span>
                </>
              )}
            </S.Row>
          );
        })}
      </S.RowWrapper>
    </S.CardContainer>
  );
};
