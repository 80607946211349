import { watchlist } from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const getListEntities = async (id: string) => {
  const res = await apiFetch(watchlist.getList.path(id), {
    schema: watchlist.getList.schema,
  });

  if (res.error) {
    return [];
  }

  return res.data.entities;
};
