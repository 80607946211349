import DarkLogo from '@static/images/brand/logo-dark.svg';
import LightLogo from '@static/images/brand/logo-light.svg';
import WebMaintenance from '@static/images/reflexivity/web-maintenance.svg';
import { mailTo } from '@toggle/helpers';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useThemeStore } from '~/components/theme-controller/stores/useThemeStore';
import { isDarkTheme } from '~/components/theme-controller/utils/theme-utils/theme-utils';
import { config } from '~/config';

import * as S from './Maintenance.styles';

export const Maintenance = () => {
  const { t } = useTranslation('common');
  const themeName = useThemeStore(state => state.themeName);
  const isDark = isDarkTheme(themeName);
  const supportEmail = mailTo(config.supportEmail);

  const ThemedLogo = isDark ? DarkLogo : LightLogo;

  return (
    <S.MaintenanceRoot data-testid="maintenance-mode">
      <S.Logo>
        <ThemedLogo />
      </S.Logo>
      <S.Tagline>
        <Trans
          t={t}
          i18nKey="common:maintenance.tagline"
          components={[<S.TaglineAlt key="" />]}
        />
      </S.Tagline>
      <S.Description>
        <S.TextContent>
          <Trans
            t={t}
            i18nKey="common:maintenance.textContent"
            components={[<S.StyledAnchor key="key-1" href={supportEmail} />]}
            values={{ email: config.supportEmail }}
          />
        </S.TextContent>
        <S.Image>
          <WebMaintenance />
        </S.Image>
      </S.Description>
    </S.MaintenanceRoot>
  );
};
