import { borderRadius, Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ViewControllerRoot = styled.div<{
  $isSimpleChart: boolean;
}>`
  display: flex;
  justify-content: left;
  padding: 10px 20px 10px 10px;
  gap: 8px;
  min-height: 56px;

  ${({ $isSimpleChart }) =>
    $isSimpleChart &&
    `
      justify-content: space-between;
      padding: 10px 0;
    `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledButton = styled(Button)`
  height: auto;
  ${typographyV2.titleSm}
  ${borderRadius.r2}
  color: var(--text-primary);
  margin-left: auto;
`;
