import { DOMAIN_CHANGE_CUSTOM_EVENT } from '@toggle/chart';
import { RefObject, useLayoutEffect } from 'react';

export interface UseDomainChangeEventListenerProps {
  canvasRef: RefObject<HTMLCanvasElement | null>;
  onDomainChange: ((event: Event) => void) | undefined;
}

export const useDomainChangeEventListener = ({
  canvasRef,
  onDomainChange,
}: UseDomainChangeEventListenerProps) => {
  useLayoutEffect(() => {
    const canvasElement = canvasRef.current;

    if (!canvasElement || !onDomainChange) {
      return;
    }

    canvasElement?.addEventListener(DOMAIN_CHANGE_CUSTOM_EVENT, onDomainChange);

    return () => {
      canvasElement?.removeEventListener(
        DOMAIN_CHANGE_CUSTOM_EVENT,
        onDomainChange
      );
    };
  }, [canvasRef.current, onDomainChange]);
};
