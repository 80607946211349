import { ErrorMessageProps } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';

import * as S from './BaseErrorView.styles';

interface BaseErrorViewProps extends ErrorMessageProps {
  className?: string;
  showBorder?: boolean;
}

export const BaseErrorView = ({
  className,
  title,
  subtitle,
  showBorder = false,
  ...rest
}: BaseErrorViewProps) => {
  const { t } = useTranslation(['common']);

  return (
    <S.ErrorStateRoot
      data-testid="base-error-view"
      className={className}
      $showBorder={showBorder}
    >
      <S.StyledErrorMessage
        displayIcon="error"
        title={title ?? t('common:error.title')}
        subtitle={
          subtitle ?? (
            <p>
              {t('common:error.subtitle')}
              <S.EmailLink href={`mailto:${config.supportEmail}`}>
                {config.supportEmail}
              </S.EmailLink>
            </p>
          )
        }
        {...rest}
      />
    </S.ErrorStateRoot>
  );
};

BaseErrorView.Styled = S;
