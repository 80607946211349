import {
  documentEntity,
  DocumentEntityItem,
  DocumentEntityItemCountSchema,
} from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const getDocumentsEntity = (entityTag: string, signal?: AbortSignal) => {
  return apiFetch<DocumentEntityItem[]>(
    documentEntity.getDocuments.path(entityTag),
    {
      schema: documentEntity.getDocuments.schema,
      method: 'get',
      options: { signal },
    }
  );
};

export const getDocumentsEntityCount = (
  entityTag: string,
  signal?: AbortSignal
) => {
  return apiFetch<DocumentEntityItemCountSchema>(
    documentEntity.getDocumentsCount.path(entityTag),
    {
      schema: documentEntity.getDocumentsCount.schema,
      method: 'get',
      options: { signal },
    }
  );
};
