import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterAssets } from '~/components/filters/components/filter-assets/FilterAssets';
import { SendAllAssetsTo } from '~/components/send-all-assets-to/SendAllAssetsTo';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { fetchPublicLists } from '~/services/watchlist/watchlist';

import { useScenarioStore } from '../../hooks/use-scenario-store/useScenarioStore';
import * as S from './ScenarioToolbar.styles';

export interface ScenarioToolbarProps {
  activeHorizon: string;
  entitiesTags: string[];
  hasData: boolean;
  horizonLabels: string[];
  onEditAssets: () => void;
  setActiveHorizon: (horizon: string) => void;
}
const QUERY_PUBLIC_LISTS = 'QUERY_PUBLIC_LISTS';

export const ScenarioToolbar = ({
  activeHorizon,
  entitiesTags,
  hasData,
  horizonLabels,
  onEditAssets,
  setActiveHorizon,
}: ScenarioToolbarProps) => {
  const { t } = useTranslation(['scenario']);
  const { data: publicLists } = useQuery({
    queryKey: [QUERY_PUBLIC_LISTS],
    queryFn: fetchPublicLists,
    staleTime: getTimeTillEndOfDayMs(),
  });

  const assetFilterGroup = useScenarioStore(
    state => state.filterGroups.ASSET_FILTERS
  );
  const { addFilterOption } = useFilterActions({
    store: useScenarioStore,
    group: 'ASSET_FILTERS',
  });

  const setActiveHorizonIndex = (index: number) =>
    setActiveHorizon(horizonLabels[index]);

  if (!assetFilterGroup.activeFilters.length) {
    return null;
  }

  const activeFilter = assetFilterGroup.activeFilters?.[0];
  const activeFilterOption = assetFilterGroup.activeFilterOptions?.[0];

  const filterProps = {
    filter: activeFilter,
    filterOptions: activeFilterOption?.options ?? [],
    publicLists: publicLists ?? [],
    addFilterOption,
  };

  const hasEntities = !!entitiesTags.length;
  const activeHorizonIndex = horizonLabels.indexOf(activeHorizon);
  return (
    <S.ScenarioToolbarRoot data-testid="scenario-toolbar">
      <S.LeftWrapper>
        <FilterAssets {...filterProps} />
        {hasEntities && (
          <S.AssetCounter>
            <S.AssetCounterLabel>
              {t('scenario:assetsSelected', {
                count: entitiesTags.length,
              })}
            </S.AssetCounterLabel>
            <S.StyledDivider />
            <S.StyledButton
              variant="empty"
              iconName="Search"
              size="xsmall"
              onClick={onEditAssets}
              label={t('scenario:searchAssets')}
            />
          </S.AssetCounter>
        )}
      </S.LeftWrapper>
      <S.RightWrapper>
        {hasData && hasEntities && (
          <S.StyledHorizonSelector
            horizonLabels={horizonLabels}
            onClick={setActiveHorizonIndex}
            activeHorizonIndex={activeHorizonIndex}
          />
        )}
        <SendAllAssetsTo entityTags={entitiesTags} />
      </S.RightWrapper>
    </S.ScenarioToolbarRoot>
  );
};
