import { OutsideHours } from '@toggle/design-system';
import { getTimeMinusOffset } from '@toggle/helpers';
import { PreliminaryPrice, SnakeMeta } from '@toggle/toggle';
import { parse } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { TFunction } from 'i18next';

import {
  EST_TIME_ZONE,
  getPriceChange,
} from '~/widgets/asset-overview-widget/utils/asset-box/asset-box-helpers';

import { PriceBase } from '../price-display.types';

interface FormatPreliminaryProps {
  preliminaryPrice: PreliminaryPrice;
  decimals: number;
  lastPrice: number;
  price: number;
}

/**
 * Formats preliminary price details used in the primary price display.
 *
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-15448?block=block-2dab2213-30a7-4bf9-b4e9-77a1a377115c
 */
const formatPreliminary = ({
  preliminaryPrice,
  decimals,
  lastPrice,
  price,
}: FormatPreliminaryProps) => ({
  price,
  priceChange: getPriceChange({
    lastPrice,
    newPrice: price,
    decimals,
  }).priceChange,
  asOfDate: formatInTimeZone(
    preliminaryPrice.time,
    EST_TIME_ZONE,
    'MMMM do, k:mm zzz'
  ),
});

const formatOfficialClose = (decimals: number, meta?: SnakeMeta) => ({
  price: meta?.last_value,
  priceChange: getPriceChange({
    lastPrice: meta?.before_last_value ?? 0,
    newPrice: meta?.last_value ?? 0,
    decimals,
  }).priceChange,
});

/**
 * Details: https://app.clickup.com/10607188/v/dc/a3pjm-10088/a3pjm-16588
 */
const checkIsPreliminary = (
  alifiedLastTimestamp: string,
  priceHistoryTime: string
) => {
  const snakeTimeZoned = toZonedTime(new Date(alifiedLastTimestamp), 'UTC');
  const priceHistoryZoned = toZonedTime(
    new Date(priceHistoryTime),
    EST_TIME_ZONE
  );
  const snakeTimeStart = parse('00:00:00', 'HH:mm:ss', snakeTimeZoned);
  const priceHistoryStart = parse('00:00:00', 'HH:mm:ss', priceHistoryZoned);
  return (
    getTimeMinusOffset(snakeTimeStart) < getTimeMinusOffset(priceHistoryStart)
  );
};

type FormatPriceProps = {
  base: PriceBase;
  decimals: number;
  t: TFunction;
  meta?: SnakeMeta;
  preliminaryPrice?: PreliminaryPrice;
};

export const formatPrice = ({
  base,
  meta,
  preliminaryPrice,
  decimals,
  t,
}: FormatPriceProps) => {
  const isLive = base.marketHours.isMarketHours && base.isLive;
  const isPreliminary =
    !!meta?.alified_last_timestamp &&
    !!preliminaryPrice &&
    checkIsPreliminary(meta?.alified_last_timestamp, preliminaryPrice.time);

  const officialCloseDetails = !isLive
    ? formatOfficialClose(decimals, meta)
    : base;
  const customPrimaryDetails = isPreliminary
    ? formatPreliminary({
        preliminaryPrice,
        decimals,
        lastPrice: meta.last_value ?? 0,
        price: preliminaryPrice.close,
      })
    : officialCloseDetails;

  const primaryPrice = {
    ...base,
    isLive,
    ...customPrimaryDetails,
  };

  const premarketText: OutsideHours = {
    label: t('analyze:analysis.preMarket'),
    tag: 'pre',
  };
  const afterMarketText: OutsideHours = {
    label: t('analyze:analysis.afterHours'),
    tag: 'after',
  };

  const aftermarket = base.marketHours.isAfterMarket
    ? afterMarketText
    : undefined;
  const outsideHours = base.marketHours.isPreMarket
    ? premarketText
    : aftermarket;
  const customSecondaryDetails = isPreliminary
    ? formatPreliminary({
        preliminaryPrice,
        decimals,
        lastPrice: preliminaryPrice.close,
        price: base.price ?? 0,
      })
    : base;

  const secondaryPrice = {
    ...base,
    outsideHours,
    ...customSecondaryDetails,
    isLive: !base.marketHours.isMarketHours && base?.isLive,
  };

  return { primaryPrice, secondaryPrice };
};
