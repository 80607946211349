import { EarningsScenarioResponse } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EarningState, Expectation } from '../../../../types';
import { formatEarning, getEarningState } from '../../../../utils';
import * as S from './ChartTitle.styles';

export interface ChartTitleProps {
  count?: number;
  expectation: Expectation;
  earningsData: EarningsScenarioResponse;
}

export const ChartTitle = ({
  count,
  expectation,
  earningsData,
}: ChartTitleProps) => {
  const { t } = useTranslation();
  const { eps, currency, reported, ticker } = earningsData;

  const valueFormatted = formatEarning(eps, currency);

  if (reported) {
    const earningsState = getEarningState(eps);

    return (
      <S.ChartTitleRoot data-testid="chart-title-reported">
        {earningsState === EarningState.Beat &&
          t('earnings:chart.titleReportedBeats', {
            ticker,
            count,
            value: valueFormatted,
          })}

        {earningsState === EarningState.Miss &&
          t('earnings:chart.titleReportedMisses', {
            ticker,
            count,
            value: valueFormatted,
          })}

        {earningsState === EarningState.Meet &&
          t('earnings:chart.titleReportedMeet', {
            ticker,
          })}
      </S.ChartTitleRoot>
    );
  }

  return (
    <S.ChartTitleRoot data-testid="chart-title">
      {t('earnings:chart.title', {
        count,
        ticker,
        expectation:
          expectation === Expectation.Beat
            ? t('common:beats', { count })
            : t('common:misses', { count }),
      })}
    </S.ChartTitleRoot>
  );
};
