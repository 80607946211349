export const parseStreamChunk = <T>(inputString: string) => {
  const lines = inputString.trim().split('\n');

  return lines.reduce((result, line) => {
    try {
      if (line) {
        const singleChunk = JSON.parse(line);
        result.push(singleChunk);
      }

      return result;
    } catch (error) {
      return [];
    }
  }, [] as T[]);
};
