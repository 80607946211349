import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RangeHorizon } from '@toggle/chart';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import {
  CalculatorResponse,
  ColumnMeta,
  CountryExposureResponse,
  FilterResults,
  screener,
  themeOverview,
} from '@toggle/toggle';

import { fetchThemeCompanies } from '~/services/related-companies/related-companies-service';
import { wretchRequest } from '~/utils/api-fetch/apiFetch';
import { getNumDaysForHorizon } from '~/views/screener/results/screener-table-utils';
import { fetchTickerCellData } from '~/views/screener/results/useScreenerTable';
import { ThemeCompaniesData } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

import {
  getFieldUnits,
  getFieldValue,
  parseRelevantFieldNames,
  parseRelevantSections,
} from './useThemeOveriview.utils';

const QUERY_KEY_COMPANY_EXPOSURE = 'QUERY_KEY_COMPANY_EXPOSURE';
const QUERY_KEY_COUNTRY_EXPOSURE = 'QUERY_KEY_COUNTRY_EXPOSURE';
const QUERY_KEY_THEME_PERFORMANCE = 'QUERY_KEY_THEME_PERFORMANCE';
const QUERY_KEY_COMPANY_ENTITIES = 'QUERY_KEY_COMPANY_ENTITIES';
const PAGE_SIZE = 75;

export const useThemeCompanyExposure = (themeId: string, limit?: number) =>
  useQuery({
    queryKey: [QUERY_KEY_COMPANY_EXPOSURE, themeId],
    queryFn: () => fetchThemeCompanies(themeId, limit),
    enabled: !!themeId,
    staleTime: getTimeTillEndOfDayMs(),
  });

export const useThemeCountryExposure = (themeId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_COUNTRY_EXPOSURE, themeId],
    queryFn: () =>
      wretchRequest<CountryExposureResponse>(
        themeOverview.getCountryExposure.path(themeId),
        {
          schema: themeOverview.getCountryExposure.schema,
        }
      ),
    enabled: !!themeId,
    staleTime: getTimeTillEndOfDayMs(),
  });

export const useThemePerformance = (entities?: string[], numDays?: number) => {
  const { path, schema } = themeOverview.themePerformance;

  return useQuery({
    queryKey: [QUERY_KEY_THEME_PERFORMANCE, entities, numDays],
    queryFn: () =>
      wretchRequest<CalculatorResponse>(path, {
        schema,
        method: 'post',
        body: {
          entities: entities,
          n_days: numDays,
        },
      }),
    enabled: !!entities && !!entities.length,
    staleTime: getTimeTillEndOfDayMs(),
  });
};

export interface ThemeQueryData {
  companiesData: ThemeCompaniesData[];
  meta: ColumnMeta[];
}

export const useThemeCompanyEntities = ({ themeId }: { themeId: string }) => {
  const queryClient = useQueryClient();

  const themeExposureFilter = {
    values: [
      {
        id: themeId,
      },
    ],
    children: [],
  };

  return useQuery<ThemeQueryData>({
    queryKey: [QUERY_KEY_COMPANY_ENTITIES, themeId],
    queryFn: async ({ signal, pageParam }) => {
      // Need to make a request to get the filter id to be able to sort to get top results
      const initialResults = await wretchRequest<FilterResults>(
        screener.postFilter.path,
        {
          method: 'post',
          body: {
            filters: {
              theme_exposure: themeExposureFilter,
            },
            page: {
              number: 1,
              size: 1,
            },
          },
          signal,
        }
      );

      const sortId = parseRelevantFieldNames({
        ...parseRelevantSections(initialResults),
        themeId,
      }).themeRankFieldName;

      const filterResults = await wretchRequest<FilterResults>(
        screener.postFilter.path,
        {
          method: 'post',
          body: {
            filters: {
              theme_exposure: themeExposureFilter,
            },
            page: {
              number: pageParam,
              size: PAGE_SIZE,
            },
            ...(sortId && {
              sort_by: {
                field: sortId,
                desc: false,
              },
            }),
          },
          signal,
        }
      );

      const { mappedEntities, ts } = await fetchTickerCellData({
        tags: filterResults.entities,
        staleTime: getTimeTillEndOfDayMs(),
        nDays: getNumDaysForHorizon(RangeHorizon.OneMonth),
        queryClient,
      });

      const { knowledgeGraphData, overviewData, performanceData } =
        parseRelevantSections(filterResults);
      const {
        companyRankFieldName,
        themeRankFieldName,
        currentPriceFieldName,
        marketCapFieldName,
        return1DFieldName,
      } = parseRelevantFieldNames({
        knowledgeGraphData,
        overviewData,
        performanceData,
        themeId,
      });

      const companiesData = filterResults.entities
        .map((_, idx) => {
          const entity = mappedEntities[idx];
          const relatedKnowledgeGraphData = knowledgeGraphData?.data[idx];
          const relatedOverviewData = overviewData?.data[idx];
          const relatedPerformanceData = performanceData?.data[idx];

          const companyRank = getFieldValue(
            relatedKnowledgeGraphData,
            companyRankFieldName
          );
          const themeRank = getFieldValue(
            relatedKnowledgeGraphData,
            themeRankFieldName
          );
          const price = getFieldValue(
            relatedOverviewData,
            currentPriceFieldName
          );
          const currency = getFieldUnits(
            relatedKnowledgeGraphData,
            currentPriceFieldName
          );
          const marketCap = getFieldValue(
            relatedOverviewData,
            marketCapFieldName
          );
          const return1D = getFieldValue(
            relatedPerformanceData,
            return1DFieldName
          );

          return {
            tag: entity.tag,
            ticker: entity.formattedTicker,
            name: entity.name,
            company_rank: companyRank,
            theme_rank: themeRank,
            current_price: price,
            currency,
            market_cap: marketCap,
            return_1d: return1D,
            mappedEntity: entity,
            ts: ts[idx],
          };
        })
        .sort((a, b) => {
          const aRank = a.theme_rank ?? Infinity;
          const bRank = b.theme_rank ?? Infinity;

          if (aRank === bRank) {
            return 0;
          }

          return aRank - bRank;
        });
      return { companiesData, meta: knowledgeGraphData?.meta || [] };
    },
    staleTime: getTimeTillEndOfDayMs(),
  });
};
