import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EntityCellRoot = styled.span`
  display: flex;
  gap: 8px;
`;

export const Ticker = styled.span`
  ${typographyV2.bodySm};
  display: flex;
  align-items: center;
  font-family: var(--font-nort);
`;
