import { highlight } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeLogoIcon } from '~/views/theme-overview/components/theme-logo-icon/ThemeLogoIcon';

import { useTheme } from '../../useTheme';
import { Theme } from '../../utils';
import * as S from './ListThemeItem.styles';

export interface ListThemeItemProps {
  theme: Theme;
  onFocus?: (name: string) => void;
  highlighted?: string;
  isFocused: boolean;
}

export const ListThemeItem = ({
  theme,
  onFocus,
  highlighted,
  isFocused,
}: ListThemeItemProps) => {
  const { t } = useTranslation('widget');
  const { data } = useTheme({
    themeId: theme.id,
  });

  const logoUrl = data?.data?.logo_url;

  const themeName = highlighted
    ? highlight(theme.name, highlighted)
    : theme.name;

  return (
    <S.ListThemeItemRoot
      data-testid={`list-theme-item-${theme.id}`}
      onFocus={() => onFocus?.(theme.name)}
      $isFocused={isFocused}
    >
      <ThemeLogoIcon
        name={theme.name}
        logo_url={logoUrl}
        isCondensed={false}
        fallbackIcon={<S.LogoWrapper>{theme.name.charAt(0)}</S.LogoWrapper>}
      />
      <S.Details>
        <S.Title>{themeName}</S.Title>
        <S.Subtitle>{t('widget:themeOverview.investmentTheme')}</S.Subtitle>
      </S.Details>
    </S.ListThemeItemRoot>
  );
};
