import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { DocumentEntityItem } from '@toggle/toggle';

import {
  getDocumentsEntity,
  getDocumentsEntityCount,
} from './documents-entity-service';

const mapDocuments = (document: DocumentEntityItem) => ({
  ...document,
  key: document.name as string,
});

export const QUERY_KEY_DOCUMENTS_ENTITY = 'QUERY_KEY_DOCUMENTS_ENTITY';
export const useDocumentsEntity = (entityTag: string) =>
  useQuery({
    queryKey: [QUERY_KEY_DOCUMENTS_ENTITY, entityTag],
    queryFn: ({ signal }) => getDocumentsEntity(entityTag, signal),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: !!entityTag,
    select: data => data?.data?.map(mapDocuments) ?? [],
  });

export const QUERY_KEY_DOCUMENTS_ENTITY_COUNT =
  'QUERY_KEY_DOCUMENTS_ENTITY_COUNT';
export const useDocumentsEntityCount = (entityTag: string) =>
  useQuery({
    queryKey: [QUERY_KEY_DOCUMENTS_ENTITY_COUNT, entityTag],
    queryFn: ({ signal }) => getDocumentsEntityCount(entityTag, signal),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: !!entityTag,
    select: data => data?.data?.public ?? 0,
  });
