import { HighLowTripleLineChart, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const PriceMovementChartRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${HighLowTripleLineChart.Styled.HighLowTripleLineChartRoot} {
    text {
      ${typographyV2.bodyXs}
    }
  }
`;
