import { STORAGE_ITEMS } from '~/hooks/use-remote-storage/storage-keys';
import { FeatureFlag } from '~/stores/use-flags/useFlags';

export const mapItemsToFeatureFlags = (items: STORAGE_ITEMS) => {
  if (typeof items.feature_flags !== 'object') {
    return [];
  }

  const enabledFeatureFlags = Object.entries(items.feature_flags).reduce(
    (acc, [key, value]) => {
      if (value === 'true' || value === true) {
        acc.push(key as FeatureFlag);
      }

      return acc;
    },
    [] as FeatureFlag[]
  );

  return enabledFeatureFlags;
};
