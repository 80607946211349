import * as Radix from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';
import { Watchlist } from '~/stores/use-watchlist/watchlist-types';

import { AssetsConstituents } from './RunScenario';
import * as S from './RunScenario.styles';

const MAX_NAME_LENGTH = 15;

interface AssetsDropdownProps {
  selectedAssets?: string;
  watchlistLists: Watchlist[];
  handleSelectAssets: (id: string) => void;
}

export const AssetsDropdown = ({
  selectedAssets,
  watchlistLists,
  handleSelectAssets,
}: AssetsDropdownProps) => {
  const { t } = useTranslation(['calendar']);

  const [isOpen, setIsOpen] = useState(false);

  const watchlistOptions = watchlistLists.map(list => ({
    id: list.id,
    label: <TruncateTooltip text={list.name} atLength={MAX_NAME_LENGTH} />,
  }));
  const indexOptions = [
    {
      id: AssetsConstituents.SPX,
      /* i18next-extract-mark-context-next-line ["spx", "ftse100", "dji", "ni225"] */
      label: t('calendar:scenario.assetsOption', {
        context: AssetsConstituents.SPX,
      }),
    },
    {
      id: AssetsConstituents.FTSE100 /* i18next-extract-mark-context-next-line ["spx", "ftse100", "dji", "ni225"] */,
      label: t('calendar:scenario.assetsOption', {
        context: AssetsConstituents.FTSE100,
      }),
    },
    {
      id: AssetsConstituents.DJI /* i18next-extract-mark-context-next-line ["spx", "ftse100", "dji", "ni225"] */,
      label: t('calendar:scenario.assetsOption', {
        context: AssetsConstituents.DJI,
      }),
    },
    {
      id: AssetsConstituents.NI225 /* i18next-extract-mark-context-next-line ["spx", "ftse100", "dji", "ni225"] */,
      label: t('calendar:scenario.assetsOption', {
        context: AssetsConstituents.NI225,
      }),
    },
  ];
  const options = [...watchlistOptions, ...indexOptions];

  return (
    <S.FilterWrapper>
      <S.FilterLabel>{t('calendar:scenario.whatLabel')}</S.FilterLabel>
      <Radix.Root open={isOpen} onOpenChange={setIsOpen}>
        <S.Trigger $active={!!selectedAssets} onClick={() => setIsOpen(true)}>
          <S.TriggerLabel data-testid="asset-dropdown-label">
            {options.find(option => option.id === selectedAssets)?.label ??
              t('calendar:scenario.addAssets')}
          </S.TriggerLabel>
          <S.TriggerArrow iconName="ChevronLightDown" />
        </S.Trigger>
        <Radix.Portal>
          <S.DropdownMenuContent
            align="start"
            sideOffset={5}
            collisionPadding={5}
            onInteractOutside={() => setIsOpen(false)}
          >
            {watchlistOptions && (
              <>
                <S.WatchlistGroup>
                  {t('calendar:scenario.watchlist')}
                </S.WatchlistGroup>
                <S.StyledRadioGroup
                  items={watchlistOptions}
                  activeId={selectedAssets}
                  onClick={(id: string) => handleSelectAssets(id)}
                />
                <S.Separator />
              </>
            )}
            <S.StyledRadioGroup
              items={indexOptions}
              activeId={selectedAssets}
              onClick={(id: string) => handleSelectAssets(id)}
            />
          </S.DropdownMenuContent>
        </Radix.Portal>
      </Radix.Root>
    </S.FilterWrapper>
  );
};
