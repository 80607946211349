import { ScenariosCondition } from '@toggle/toggle';
import isEqual from 'lodash/isEqual';

export interface UseScenarioStateProps {
  conditions: ScenariosCondition[];
  lastConditions?: ScenariosCondition[];
  entitiesTags: string[];
  lastEntitiesTags?: string[];
}

export const useScenarioState = ({
  conditions,
  lastConditions,
  entitiesTags,
  lastEntitiesTags,
}: UseScenarioStateProps) => {
  const hasConditions = !!conditions.length;
  const hasLastConditions = !!lastConditions?.length;
  const showResetAllButton = hasConditions && hasLastConditions;

  const entitiesAreDifferent = !isEqual(entitiesTags, lastEntitiesTags);
  const conditionsAreDifferent = !isEqual(conditions, lastConditions);
  const entitiesOrConditionsChanged =
    entitiesAreDifferent || conditionsAreDifferent;

  const showRunScenarioButton = hasLastConditions
    ? conditionsAreDifferent || entitiesAreDifferent
    : hasConditions && conditionsAreDifferent;

  return {
    showRunScenarioButton,
    showResetAllButton,
    entitiesOrConditionsChanged,
  };
};
