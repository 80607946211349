import {
  NEWS_RECENT_SEARCHES,
  useRemoteStorage,
} from '~/hooks/use-remote-storage';

export interface Suggestion {
  type: 'recent-searches' | 'topics' | 'industry' | 'asset_class';
  label: string;
  timestamp?: number;
}

const MAX_SEARCHES = 4;
const EXPIRATION_DAYS = 5;
const ONE_DAY_MS = 86400000;
const EXPIRATION_DAYS_MS = EXPIRATION_DAYS * ONE_DAY_MS;

const isValidSuggestion = ({ timestamp }: Suggestion) =>
  !!timestamp && Date.now() - timestamp < EXPIRATION_DAYS_MS;

export const useRecentSearches = () => {
  const { storedSearches, storeItems } = useRemoteStorage(state => ({
    storedSearches: (state.items?.[NEWS_RECENT_SEARCHES] as Suggestion[]) ?? [],
    storeItems: state.storeItems,
  }));

  const recentSearches = storedSearches.filter(isValidSuggestion);

  const addRecentSearch = async (query: string) => {
    const trimmedQuery = query.trim();

    if (!trimmedQuery) {
      return;
    }

    const newSearch = {
      label: trimmedQuery,
      type: 'recent-searches',
      timestamp: Date.now(),
    };

    const filteredSearches = recentSearches.filter(
      search => search.label !== trimmedQuery
    );

    const updatedSearches = [newSearch, ...filteredSearches].slice(
      0,
      MAX_SEARCHES
    );

    try {
      await storeItems({
        [NEWS_RECENT_SEARCHES]: updatedSearches,
      });
    } catch (error) {
      console.error('Error storing search items:', error);
    }
  };

  return {
    recentSearches,
    addRecentSearch,
  };
};
