import { z } from 'zod';

export type DocumentEntityItem = z.infer<typeof documentEntityItemSchema>;
export type DocumentEntityItemCountSchema = z.infer<
  typeof documentEntityItemCountSchema
>;

export const documentEntityItemSchema = z.object({
  id: z.string(),
  last_update: z.string(),
  entity: z.string(),
  name: z.string(),
  year: z.number(),
  period: z.string(),
  release_date: z.string(),
  kind: z.string(),
  type: z.enum(['pdf', 'html', 'markdown']),
  source: z.string(),
  location: z.string(),
  indexed: z.boolean(),
  //TO DO: make required when api is updated
  reference: z.string().optional(),
});

export type EntityDocument = z.infer<typeof documentEntityItemSchema>;
export const documentEntityItemCountSchema = z.object({
  public: z.number(),
});

export const documentEntity = {
  getDocuments: {
    path: (entityTag: string) => `document/v1/entity/${entityTag}`,
    schema: z.array(documentEntityItemSchema),
  },
  getDocumentsCount: {
    path: (entityTag: string) => `document/v1/entity/${entityTag}/count`,
    schema: documentEntityItemCountSchema,
  },
  getDocumentContent: {
    path: (documentId: string) => `document/v1/${documentId}/content`,
  },
};
