import { SkeletonLoader, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { underlineTextStyles } from '~/components/tooltip-container/TooltipContainer.styles';

export const TableLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
      },
    },
  ],
})`
  height: 100%;
`;

export const StyledErrorMessageWrapper = styled(ErrorMessageWrapper)`
  border: none;
`;

export const CellWithTooltip = styled.p<{ $hasTooltip?: boolean }>`
  ${props =>
    props.$hasTooltip &&
    css`
      ${underlineTextStyles};
    `}
`;

export const OverflowEllipsisTextCell = styled.div<{
  maxWidth?: string | number;
  $hasTooltip?: boolean;
}>`
  max-width: ${props => props?.maxWidth ?? '160px'};
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-bottom: 3px;
  margin-bottom: -3px;
  font-family: var(--font-nort);

  ${props =>
    props.$hasTooltip &&
    css`
      ${underlineTextStyles};
    `}
`;

export const LogoAndTickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

export const UnderlinedTextWrapper = styled.div`
  ${underlineTextStyles};
`;

export const StyledListWrapper = styled.div`
  padding-left: 20px;
`;

export const TextualTextWrapper = styled.div`
  ${typographyV2.bodySm};
  font-family: var(--font-nort);
`;
