import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EmptyPortfolioWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 64px 35px;
  height: 100%;
`;

export const StyledImg = styled.img`
  align-self: center;
  margin-bottom: 8px;
`;

export const Title = styled.span`
  ${typographyV2.titleBase};
  margin-bottom: 4px;
`;

export const Description = styled.span`
  ${typographyV2.bodySm}
  max-width: 366px;
  text-align: center;
  color: var(--text-soft);
`;

export const AddAssetsButton = styled(Button)`
  margin-top: 16px;
`;
