import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ProgressBar } from '../progress-bar/ProgressBar';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LoaderItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const StyledProgressBar = styled(ProgressBar)<{
  $isLast: boolean;
}>`
  --line-width: ${props => (props.$isLast ? '98%' : '100%')};

  --timing-function: ${props =>
    props.$isLast ? 'cubic-bezier(0.02, 0.25, 0.7, 0.74)' : 'linear'};
`;
