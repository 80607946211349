import { CheckBox } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';

import * as S from './Filter.styles';

export interface OptionProps {
  option: FilterOption;
  disabled?: boolean;
  multiselect?: boolean;
  isChecked?: boolean;
  disabledTooltipLabel?: string;
  handleClick: () => void;
  direction?: 'column' | 'row';
  renderCustomFilterOptionLabel?: (filterOption: FilterOption) => ReactNode;
}

export const Option = ({
  option,
  disabled,
  multiselect,
  isChecked,
  disabledTooltipLabel,
  handleClick,
  direction = 'column',
  renderCustomFilterOptionLabel,
  ...rest
}: OptionProps) => {
  if (multiselect) {
    return (
      <TooltipContainer label={disabledTooltipLabel}>
        <S.CheckboxItem
          onClick={e => {
            e.preventDefault();
            handleClick();
          }}
          disabled={!!disabledTooltipLabel}
        >
          <S.ItemText data-testid="multiselect-item">
            <CheckBox
              label={
                <S.ItemLabel>
                  {renderCustomFilterOptionLabel?.(option) ?? option.name}
                </S.ItemLabel>
              }
              name={option.key}
              checked={isChecked}
            />
          </S.ItemText>
        </S.CheckboxItem>
      </TooltipContainer>
    );
  }

  return (
    <S.Item
      onSelect={e => {
        if (option.childName) {
          e.preventDefault();
        }

        handleClick();
      }}
      $isChecked={isChecked}
      disabled={disabled}
    >
      <S.MenuItemLeftIndicator />
      <S.ItemText $direction={direction} data-testid="single-item" {...rest}>
        <S.ItemLabel $locked={disabled}>
          {renderCustomFilterOptionLabel?.(option) ?? option.name}
        </S.ItemLabel>
        <S.ItemDescription>{option.description}</S.ItemDescription>
      </S.ItemText>
    </S.Item>
  );
};
