import { ScreenerFilter } from '@toggle/toggle';

import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';

export const getCustomScenarioChildOptions = ({
  activeFilters,
  filterKey,
  customNames,
}: {
  activeFilters: ScreenerFilter[];
  filterKey: string;
  customNames: string[];
}) =>
  (
    activeFilters.find(f => f.key === filterKey) as ScreenerFilter
  ).config.children?.reduce((res, child) => {
    const option = child.options.find(o => customNames.includes(o.name));

    if (option) {
      res.push({ ...option, childName: child.name });
    }

    return res;
  }, [] as FilterOption[]);
