import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { FilterResults } from '@toggle/toggle';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { mapFilters } from '~/views/screener/use-screener-store/filter-utils';

import { fetchFilteredEntitiesTagsOnly } from './entity-screener-service/entity-screener-service';

export const QUERY_KEY_SCREENER_ENTITIES_TAGS =
  'QUERY_KEY_SCREENER_ENTITIES_TAGS';

export const getScreenerEntitiesTagsQueryConfig = ({
  filterOptions,
}: {
  filterOptions: ActiveFilterOptions[];
}) => {
  const staleTime = getTimeTillEndOfDayMs();

  const filterOptionsMapped = mapFilters(filterOptions);

  return {
    queryKey: [QUERY_KEY_SCREENER_ENTITIES_TAGS, filterOptionsMapped],
    queryFn: ({ signal }: { signal: AbortSignal }) =>
      fetchFilteredEntitiesTagsOnly(filterOptionsMapped, signal),
    staleTime,
    enabled: !!filterOptions.length,
  };
};

export const useScreenerEntitiesTags = ({
  filterOptions,
}: {
  filterOptions: ActiveFilterOptions[];
}) => {
  const queryConfig = getScreenerEntitiesTagsQueryConfig({
    filterOptions,
  });

  return useQuery<FilterResults>(queryConfig);
};
