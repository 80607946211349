import React, {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
} from 'react';

import * as S from './ChartTooltip.styles';

export interface ChartTooltipProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  children: ReactNode;
}

export const ChartTooltip = forwardRef<HTMLDivElement, ChartTooltipProps>(
  ({ title, children, ...rest }, ref) => (
    <S.Container ref={ref} {...rest}>
      <S.Title data-testid="chart-tooltip">{title}</S.Title>
      <S.ItemsContainer>{children}</S.ItemsContainer>
    </S.Container>
  )
) as ForwardRefExoticComponent<
  ChartTooltipProps & RefAttributes<HTMLDivElement>
> & {
  Styled: typeof S;
};

ChartTooltip.Styled = S;
