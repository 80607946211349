import { Chip } from '@toggle/design-system';
import React, { HTMLAttributes } from 'react';

import * as S from './ChoiceChips.styles';

export interface ChoiceItem<T> {
  id: T;
  label: string;
}

export interface ChoiceChipsProps<T = ChoiceItem<string>>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  disabledItems?: T[];
  items: T[];
  onChange: (item: T) => void;
  selectedItem: T | null;
}

export const ChoiceChips = <T extends ChoiceItem<string>>({
  disabledItems = [],
  items,
  onChange,
  selectedItem,
  ...rest
}: ChoiceChipsProps<T>) => {
  return (
    <S.ChoiceChipsRoot data-testid="choice-chips" {...rest}>
      {items.map(item => (
        <Chip
          data-testid={`${item.id}-chip`}
          key={item.id}
          label={item.label}
          type="choice"
          disabled={disabledItems.includes(item)}
          isActive={item === selectedItem}
          onClick={() => onChange(item)}
        />
      ))}
    </S.ChoiceChipsRoot>
  );
};
