import React, { ReactNode, useState } from 'react';

import * as S from '~/components/markdown/components/CustomComponents.styles';
import { ArticleDrawer } from '~/widgets/news-widget/ArticleDrawer';

export interface CustomLinkProps {
  href?: string;
  children?: ReactNode;
}

export const Article = ({ href, children }: CustomLinkProps) => {
  return (
    <S.ArticlesButton data-testid="article">
      <S.GlobeIcon iconName="Globe" size={14} />
      <S.MarkdownA href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </S.MarkdownA>
      <S.ExternalLinkIcon iconName="ExternalLink" size={12} />
    </S.ArticlesButton>
  );
};

export const InternalNews = ({
  href: articleId,
  children: label,
}: CustomLinkProps) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(open => !open);
  };

  if (!articleId || !label) {
    return null;
  }

  return (
    <>
      <S.InternalNewsButton
        label={label}
        onClick={toggle}
        iconName="Newspaper"
        data-testid="internal-news"
        iconSize={14}
      />
      {open && <ArticleDrawer newsId={articleId} onClose={toggle} />}
    </>
  );
};
