import { compareObjectKeys } from '@toggle/helpers';
import { ZoomBehavior } from 'd3';
import { scaleLinear } from 'd3-scale';
import { create } from 'zustand';

import { xAxis } from '~/core/axis/axis';
import {
  dispatchGutterSizeEvent,
  dispatchInsightsInDomainEvent,
} from '~/core/events/events';
import { Domain } from '~/types/axis.types';
import {
  BaseChartAPIProps,
  ChartPane,
  CreateChartOptionsWithColors,
} from '~/types/create.types';
import { ChartInsight } from '~/types/timeseries.types';
import {
  createChartPane,
  createChartPanes,
  getPaneHeights,
  getPaneTop,
  getYAxisSizes,
} from '~/utils/pane/pane-utils';
import { getThresholdIntervals } from '~/utils/threshold/threshold-utils';
import {
  createFakeTS,
  getChartDomain,
  MIN_IDX_RANGE,
} from '~/utils/timeseries/time-series';
import { getYAxisDomain } from '~/utils/y-scale/y-scale';

import { InitProps } from '../setup/setup';

interface InitialiseProps extends InitProps {
  prevBase?: BaseChartAPIProps;
  options: CreateChartOptionsWithColors;
  context: CanvasRenderingContext2D;
  canvasElement: HTMLCanvasElement;
}

export interface ChartStore {
  base?: BaseChartAPIProps;
  zoom?: {
    zoomBehavior: ZoomBehavior<HTMLCanvasElement, unknown>;
    hasDomainChanged?: boolean;
  };
  initialise: (props: InitialiseProps) => BaseChartAPIProps;
  updateDomain: (domain: Domain) => BaseChartAPIProps;
  setInsights: (insights: ChartInsight[]) => BaseChartAPIProps;
  resizePane: (paneIndex: number, diff: number) => void;
}

const calculateBase = ({
  prevBase,
  resampleInterval,
  horizon,
  context,
  options,
  canvasElement,
  domain: customDomain,
  panes,
  primaryAsset,
  primaryPaneId,
}: InitialiseProps): BaseChartAPIProps => {
  const ts = primaryAsset.ts;
  const lastDataIdx = ts.length - 1;

  const domain = getChartDomain({
    prevBase,
    lastDataIdx,
    horizon,
    resampleInterval,
    timeseries: ts,
    customDomain,
  });

  const fake = createFakeTS({
    ts: primaryAsset.ts,
    index1: ts.length - 1,
    index2: ts.length - 2,
    dataPointsToAdd: Math.round(ts.length / 2),
    lastKnownDate: new Date(ts[ts.length - 1].time),
  });
  const yAxisDomain = getYAxisDomain(domain);

  const maximizedPane = panes.find(p => p.maximized);
  const activePanes = maximizedPane ? [maximizedPane] : panes;
  const paneHeights = getPaneHeights({ options, panes: activePanes, prevBase });
  const chartPanes = createChartPanes({
    prevBase,
    panes: activePanes,
    paneHeights,
    options,
    yAxisDomain,
    primaryAsset,
  });

  const prevPanes = chartPanes[0].maximized
    ? prevBase?.prevPanes?.map(p =>
        p.id === chartPanes[0].id ? chartPanes[0] : p
      )
    : chartPanes;
  const { yAxisSizes, totalWidth } = getYAxisSizes(chartPanes);

  const optionsUpdated: CreateChartOptionsWithColors = {
    ...options,
    gutters: {
      x: options.gutters.x,
      y: totalWidth,
    },
  };

  const tsWithFake = [...primaryAsset.ts, ...fake];
  const x = xAxis({
    options: optionsUpdated,
    timeseries: tsWithFake,
    domain,
    resampleInterval,
  });

  const thresholdIntervals = getThresholdIntervals({
    domain: domain,
    chartPanes,
    primaryAsset,
    x,
  });

  const lastIdx = lastDataIdx + fake.length - 1;
  const fullXScale = scaleLinear()
    .domain([0, lastIdx])
    .range([0, optionsUpdated.width - optionsUpdated.gutters.y]);
  const maxChartZoom = lastDataIdx / MIN_IDX_RANGE;

  const baseProps: BaseChartAPIProps = {
    ...prevBase,
    primaryAsset,
    options: optionsUpdated,
    resampleInterval,
    horizon,
    context,
    fullXScale,
    lastDataIdx,
    maxChartZoom,
    canvasElement,
    domain,
    x,
    panes: chartPanes,
    thresholdIntervals,
    prevPanes,
    yAxisSizes,
    primaryPaneId,
    timeseries: tsWithFake,
  };
  return baseProps;
};

const chartStoresMap = new Map<HTMLCanvasElement, ChartStoreReturn>();
export type ChartStoreReturn = ReturnType<typeof createChartStore>;

export const createChartStore = () =>
  create<ChartStore>((set, get) => ({
    base: undefined,
    initialise: props => {
      const prevBase = get().base;
      const newBase = calculateBase({
        prevBase,
        ...props,
      });

      dispatchGutterSizeEvent(newBase, prevBase);
      set({ base: newBase });
      return newBase;
    },
    updateDomain: domain => {
      const base = get().base as BaseChartAPIProps;
      const options = base.options;
      const lastRealIdx = base.primaryAsset.ts.length - 1;

      // this domain will be used to display line,
      // so it should always use indexes from real timeseries
      const newDomain: Domain = [
        domain[0],
        domain[1] > lastRealIdx ? lastRealIdx : domain[1],
      ];
      const yAxisDomain = getYAxisDomain(newDomain);
      const prevYAxisDomain = getYAxisDomain(base.domain);

      const paneHeights = base.panes.map(p => p.options.height);
      const newPanes = compareObjectKeys(prevYAxisDomain, yAxisDomain, [
        '0',
        '1',
      ])
        ? base.panes
        : createChartPanes({
            prevBase: base,
            panes: base.panes,
            paneHeights,
            options: options,
            yAxisDomain,
            primaryAsset: base.primaryAsset,
          });

      const { yAxisSizes, totalWidth } = getYAxisSizes(newPanes);

      const updatedOptions: CreateChartOptionsWithColors = {
        ...options,
        gutters: {
          x: options.gutters.x,
          y: totalWidth,
        },
      };
      const x = xAxis({
        ...base,
        options: updatedOptions,
        timeseries: base.timeseries,
        domain,
      });

      const thresholdIntervals = getThresholdIntervals({
        domain: newDomain,
        chartPanes: newPanes,
        primaryAsset: base.primaryAsset,
        x,
      });

      const newBase = {
        ...base,
        options: updatedOptions,
        domain: newDomain,
        x,
        panes: newPanes,
        thresholdIntervals,
        yAxisSizes,
      };

      dispatchGutterSizeEvent(newBase, base);

      set({ base: newBase });
      return newBase;
    },
    setInsights: insights => {
      const base = get().base as BaseChartAPIProps;
      const newPanes = base.panes.map(p => ({
        ...p,
        insights: insights.filter(i =>
          p.chartAssetData.some(c => c.entity.tag === i.entity)
        ),
      }));
      const newBase: BaseChartAPIProps = {
        ...base,
        panes: newPanes,
        prevPanes: newPanes,
      };
      set({ base: newBase });
      return newBase;
    },
    resizePane: (paneDividerIndex, diff) => {
      const base = get().base as BaseChartAPIProps;
      const collapsedPaneHeight = base.options.config.collapsedPaneHeight;
      const chartPanes: ChartPane[] = [];
      const indexesToUpdate = [paneDividerIndex - 1, paneDividerIndex];

      base.panes.forEach((pane, index) => {
        if (indexesToUpdate.includes(index)) {
          const sign = index === paneDividerIndex ? -1 : 1;
          const change = diff * sign;
          const top =
            index === paneDividerIndex
              ? getPaneTop({ chartPanes, index })
              : pane.options.gutters.top;
          const height = Math.max(
            collapsedPaneHeight,
            pane.options.height + change
          );
          const createdPane = createChartPane({
            top,
            height,
            paneData: pane,
            options: base.options,
            insights: pane.insights,
            domainTimeSeries: pane.domainTimeSeries,
            actualHeight: height,
          });
          chartPanes.push(createdPane);
        } else {
          chartPanes.push(pane);
        }
      });

      const newBase = { ...base, panes: chartPanes, prevPanes: chartPanes };
      dispatchGutterSizeEvent(newBase, base);
      dispatchInsightsInDomainEvent(newBase);
      set({ base: newBase });
    },
  }));

export const initChartStore = (canvasElement: HTMLCanvasElement) => {
  const chartStore = chartStoresMap.get(canvasElement) || createChartStore();

  if (!chartStoresMap.has(canvasElement)) {
    chartStoresMap.set(canvasElement, chartStore);
  }

  return chartStore;
};
