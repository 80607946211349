/* eslint-disable max-lines-per-function */
import {
  ChartAssetData,
  PaneData,
  PriceDisplay,
  RangeHorizon,
  ResampleIntervals,
} from '@toggle/chart';
import { ButtonLink, Icon } from '@toggle/design-system';
import React, { FC, RefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChartNavigateConfigParams,
  useChartNavigate,
} from '~/hooks/use-chart-navigate/useChartNavigate';
import { ChartArea } from '~/modules/simple-chart/components/chart-area/ChartArea';
import { ChartSearchParamsAssetValue } from '~/utils/chart-search-utils/chart-search-utils';
import { UseChartThemeReturn } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';
import { useTurboChart } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';
import { ValidChartSettings } from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import { ChartPerSnakeRef } from '../ChartPeerGroup';
import * as S from './ChartPerSnake.styles';

export interface ChartPerSnakeProps {
  chartAssets: ValidChartSettings['assets'];
  horizon: RangeHorizon;
  colors: UseChartThemeReturn['colors'];
  chartRef: RefObject<ChartPerSnakeRef>;
  isSingle: boolean;
  initialPriceDisplay: PriceDisplay;
}

export const ChartPerSnake: FC<ChartPerSnakeProps> = ({
  chartAssets,
  horizon,
  colors,
  chartRef,
  isSingle,
  initialPriceDisplay,
}) => {
  const { t } = useTranslation(['chart']);
  const canvasRef: RefObject<HTMLCanvasElement | null> = useRef(null);
  const paneSettings: ChartNavigateConfigParams = {
    priceDisplay: initialPriceDisplay,
    yAxisType: 'merged',
  };

  const snakes = chartAssets.map(item => item.snake);
  const initialChartSettings = useMemo(
    () => ({
      assets: chartAssets,
      resample: ResampleIntervals.OneDay,
      horizon: horizon,
      domain: null,
      hidden: undefined,
      paneSettings: {
        0: paneSettings,
      },
    }),
    [snakes.toString()]
  );

  const {
    assetWithError,
    chartApiRef,
    chartData,
    chartState,
    hasEmpty,
    hasError,
    primaryAsset,
    primaryPane,
    retryInitialChartLoad,
    changeRange,
    hideActiveAsset,
    chartIsLoading,
  } = useTurboChart({
    initialChartSettings,
    shouldUseSearchParams: false,
  });

  const chartNavigate = useChartNavigate();

  const onClick = () => {
    const assets: ChartSearchParamsAssetValue[] = chartData.map(asset => [
      asset.entity.tag,
      asset.snakeMeta?.snake ?? asset.entity.default_snake,
      0,
    ]);

    chartNavigate(assets, {
      horizon: chartState.horizon,
      resample: chartState.resample,
      ...paneSettings,
    });
  };

  chartRef.current = {
    changeRange,
    hideActiveAsset,
  };

  return (
    <S.Container $isSingle={isSingle}>
      {chartIsLoading ? (
        <S.SkeletonChartLoader data-testid="chart-per-snake-loading" />
      ) : (
        <>
          <S.Header>
            <S.Snake>{primaryAsset?.snakeMeta?.name.english}</S.Snake>
            <ButtonLink
              data-testid="open-in-chart"
              label={
                <>
                  {t('chart:openIn')}
                  <Icon iconName={'ChartMixed'} size={16} />
                  {t('chart:title')}
                </>
              }
              onClick={onClick}
            />
          </S.Header>
          <S.StyledChartAreaWrapper
            assetWithError={assetWithError}
            hasError={hasError}
            hasEmpty={hasEmpty}
            colors={colors}
            chartData={chartData}
            onRetry={retryInitialChartLoad}
            chartConfig={{ enableZoom: false }}
            renderChartArea={options => (
              <ChartArea
                canvasRef={canvasRef}
                chartApiRef={chartApiRef}
                options={options}
                chartState={chartState}
                primaryAsset={primaryAsset as ChartAssetData}
                primaryPane={primaryPane as PaneData}
                tooltipSortConfig={{ field: 'priceFormatted', desc: true }}
                axisUnit={
                  initialPriceDisplay === 'percentage'
                    ? '%'
                    : chartData[0]?.entity.currency
                }
              />
            )}
          />
        </>
      )}
    </S.Container>
  );
};
