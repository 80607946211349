import { v4 } from 'uuid';

import { Conversation, MessageV2 } from '../use-chat.types';

export const createNewConversationMessage = (
  question: string,
  id = v4()
): MessageV2 => {
  return {
    id,
    status: 'pending',
    question,
    steps: [],
    reply: [],
  };
};

export const isAnyMessagePending = (conversation?: Conversation) =>
  conversation
    ? conversation.messages.some(
        m => m.status === 'pending' || m.status === 'processing'
      )
    : false;
