import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const MenuBarRoot = styled.div`
  display: flex;
`;

export const MenuItem = styled.div`
  height: 40px;
  padding: 0 4px;
  border-right: 1px solid var(--border-soft);
  display: flex;
  align-items: center;
`;

export const MenuItemSearch = styled(MenuItem)`
  min-width: 132px;

  button {
    justify-content: left;
  }
`;

export const Label = styled.span`
  text-transform: uppercase;
`;

export const StyledButton = styled(Button)`
  &:disabled {
    border: none;
  }
`;
