import { Button, SvgIconNames } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './MenuBarV2.styles';

interface MenuBarV2Item {
  id: ChartMenuBarV2Ids;
  iconName: SvgIconNames;
}

interface MenuBarV2Props {
  onPrimarySearch: () => void;
  onMenuItemClick: (id: ChartMenuBarV2Ids) => void;
  disabledItems: boolean;
  children?: React.ReactNode;
}

export type ChartMenuBarV2Ids = 'marketLeadingIndicators' | 'events';

export const MenuBarV2 = ({
  onPrimarySearch,
  onMenuItemClick,
  disabledItems,
  children,
}: MenuBarV2Props) => {
  const { t } = useTranslation('chart');

  const items: MenuBarV2Item[] = [
    {
      id: 'marketLeadingIndicators',
      iconName: 'Indicators',
    },
    {
      id: 'events',
      iconName: 'Rating',
    },
  ];

  return (
    <S.MenuBarRoot data-testid="menu-bar">
      {children ?? <S.MenuItem>{children}</S.MenuItem>}
      <S.MenuItemSearch>
        <Button
          data-testid="search-button"
          iconName="Add"
          label={t('chart:menuBar', { context: 'addData' })}
          variant="empty"
          fullWidth
          aria-label={t('chart:menuBar', { context: 'addData' })}
          onClick={onPrimarySearch}
        />
      </S.MenuItemSearch>
      {items.map(item => (
        <S.MenuItem key={item.iconName}>
          <S.StyledButton
            iconName={item.iconName}
            /* i18next-extract-mark-context-next-line ["indicators", "events"] */
            label={t('chart:menuBar', { context: item.id })}
            variant="empty"
            onClick={() => onMenuItemClick(item.id)}
            disabled={disabledItems}
            data-testid={`${item.id}-button`}
          />
        </S.MenuItem>
      ))}
    </S.MenuBarRoot>
  );
};
