import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';

export const LogoWrapper = styled.div<{ $size: number }>`
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  background-color: var(--surface-inverse);
  overflow: hidden;
  ${borderRadius.rFull};
`;
