import { Entity } from '@toggle/toggle';
import React from 'react';

import {
  ListEntityItem,
  ListEntityItemProps,
} from '~/components/search-modal/components/list-entity-item/ListEntityItem';

import * as S from './SearchAssetItem.styles';

export interface SearchAssetItem extends Omit<ListEntityItemProps, 'onClick'> {
  entity: Entity;
  isAdded: boolean;
  isFocused: boolean;
  onClick?: (item: Entity, isInWatchlist: boolean) => void;
}

export const SearchAssetItem = ({
  entity,
  isAdded,
  isFocused,
  isDisabled,
  onClick,
  ...rest
}: SearchAssetItem) => {
  const handleClick = onClick
    ? (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        onClick(entity, isAdded);
      }
    : undefined;

  return (
    <ListEntityItem
      entity={entity}
      isFocused={isFocused}
      isDisabled={isDisabled}
      onClick={handleClick}
      actionNode={
        <S.StyledButton
          data-testid="search-asset-item-button"
          size="xsmall"
          variant="tertiary"
          iconName={isAdded ? 'Checkmark' : 'Add'}
          $isActive={isAdded}
          tabIndex={-1}
          disabled={isDisabled}
        />
      }
      data-testid="search-asset-item"
      {...rest}
    />
  );
};
