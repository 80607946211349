import React, { ReactNode } from 'react';

import * as S from './ListDivider.styles';

interface ListDividerProps {
  label: ReactNode;
}

export const ListDivider = ({ label }: ListDividerProps) => (
  <S.Divider data-testid="list-divider">{label}</S.Divider>
);
