import styled, { css, keyframes } from 'styled-components';

const defaultWidth = '240px';
const expandedWidth = '688px';

const expandAnimation = keyframes`
  from {
    width: ${defaultWidth};
    right: 20px;
    transform: translateX(0);
  }
  to {
    width: ${expandedWidth};
    right: 50%;
    transform: translateX(50%);
  }
`;

const collapseAnimation = keyframes`
  from {
    width: ${expandedWidth};
    right: 50%;
    transform: translateX(50%);
  }
  to {
    width: ${defaultWidth};
    right: 20px;
    transform: translateX(0);
  }
`;

export const SearchBarRoot = styled.div<{
  $isExpanded: boolean;
  $isFocused: boolean;
}>`
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 1;
  width: ${defaultWidth};
  right: 20px;
  transform-origin: right;

  ${({ $isExpanded, $isFocused }) =>
    $isExpanded
      ? css`
          animation: ${expandAnimation} 500ms ease forwards;
        `
      : $isFocused &&
        css`
          animation: ${collapseAnimation} 500ms ease forwards;
        `};
`;

export const Form = styled.form`
  width: 100%;
`;
