import { typographyV2 } from '@toggle/design-system';
import { NumericalCellValueStyle } from '@toggle/helpers';
import styled from 'styled-components';

export const Details = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-evenly;
  width: 100%;
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  text-align: center;
`;

export const Heading = styled.p`
  ${typographyV2.bodyXs}
  color: var(--text-soft);
  text-align: center;
`;

export const Separator = styled.hr`
  height: 48px;
  width: 1px;
  background-color: var(--border-soft);
  border: none;
`;

export const DetailItem = styled.div`
  display: flex;
`;

export const DetailReturn = styled.p<{
  $numericalStyle: NumericalCellValueStyle;
}>`
  ${typographyV2.bodyBase}
  font-family: var(--font-roboto-mono);
  color: ${({ $numericalStyle }) =>
    ({
      [NumericalCellValueStyle.Positive]: 'var(--viz-bullish)',
      [NumericalCellValueStyle.Negative]: 'var(--viz-bearish)',
      [NumericalCellValueStyle.Neutral]: 'var(--text-default)',
    }[$numericalStyle])};
`;

export const Text = styled.p`
  ${typographyV2.titleBase}
`;

export const TextUpper = styled(Text)`
  text-transform: uppercase;
`;

export const GroupedText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
