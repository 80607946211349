import React, { ReactNode } from 'react';

import * as S from './DrawerSectionHeader.styles';

interface DrawerSectionHeaderProps {
  title: ReactNode;
  description: ReactNode;
  rightNode?: ReactNode;
}

export const DrawerSectionHeader = ({
  title,
  description,
  rightNode,
}: DrawerSectionHeaderProps) => {
  return (
    <S.Root>
      <S.TitleDescriptionWrapper>
        <S.TitleText>{title}</S.TitleText>
        <S.DescriptionText>{description}</S.DescriptionText>
      </S.TitleDescriptionWrapper>
      {rightNode}
    </S.Root>
  );
};

DrawerSectionHeader.Styled = S;
