import React from 'react';

import * as S from './DocumentLoader.styles';

export const DocumentLoader = () => (
  <S.StyledSkeletonLoader
    areas={[
      {
        styles: {
          width: '100%',
          height: '100%',
          borderRadius: '8px',
        },
      },
    ]}
  />
);
