import styled, { css } from 'styled-components';

import { ErrorMessage } from '~/components/error-message/ErrorMessage';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const BarChartRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Header = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.span<{ $showUnderline: boolean }>`
  ${typographyV2.titleBase};

  ${props =>
    props.$showUnderline &&
    css`
      display: inline-block;
      text-decoration: underline dashed;
      text-underline-offset: 6px;
      text-decoration-thickness: 1px;
      text-decoration-color: var(--border-default);
    `}
`;

export const AxisLine = styled.line<{ $highlight: boolean }>`
  stroke-width: 1px;
  stroke: var(--border-soft);

  ${props =>
    props.$highlight &&
    css`
      stroke: var(--border-default);
    `}
`;

export const AxisText = styled.text<{ $highlight?: boolean }>`
  color: var(--text-soft);
  font-size: 12px;
  fill: var(--text-soft);

  ${props =>
    props.$highlight &&
    css`
      fill: var(--text-default);
    `}
`;

export const LabelAxisY = styled(AxisText)`
  font-family: var(--font-roboto-mono);
`;

export const TitleAxisY = styled.p`
  color: var(--text-soft);
  font-family: var(--font-roboto-mono);
  ${typographyV2.bodySm}
  text-align: right;
  padding-right: 35px;
  padding-bottom: 8px;
`;

export const LabelAxisX = styled(AxisText)`
  transform: translateY(-8px);
`;

export const Svg = styled.svg<{ $isBlurred: boolean }>`
  ${props =>
    props.$isBlurred &&
    css`
      filter: blur(1px);
    `}
`;

export const ErrorMessageWrapper = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(var(--background-default-rgb), 0.5);
  z-index: 1;

  ${ErrorMessage?.Styled.Wrapper} {
    width: calc(100% - 50px);
    margin: 0;
  }
`;

export const LegendWrapper = styled.div`
  position: relative;
  z-index: 2;
`;
