import { rangeToResampleMapping } from '@toggle/chart';
import { SmartTable, Tooltip } from '@toggle/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';
import { appPaths } from '~/routes/app-paths';
import { PAST_EPISODES_HORIZONS } from '~/widgets/past-episodes-widget/components/insight-past-episodes/InsightPastEpisodes';
import {
  getColumns,
  getHorizonsLabels,
  mapEpisodesToTableRow,
} from '~/widgets/past-episodes-widget/components/insight-past-episodes/utils/utils';
import { PastEpisodesChart } from '~/widgets/past-episodes-widget/components/past-episodes-chart/PastEpisodesChart';
import * as S from '~/widgets/past-episodes-widget/PastEpisodesWidget.styles';

export interface ScenarioPastEpisodeSectionProps {
  snake: string[];
  scenario: ScenariosData;
}

export const ScenarioPastEpisodeSection = ({
  scenario,
  snake,
}: ScenarioPastEpisodeSectionProps) => {
  const { t } = useTranslation(['scenario', 'widget', 'chart']);
  const [activeHorizonIdx, setActiveHorizonIdx] = useState(5);

  const navigate = useNavigate();

  const handleChangeHorizon = (index: number) => setActiveHorizonIdx(index);

  const totalTradingDays =
    scenario.episodes.reduce((acc, ep) => acc + ep.events.length, 0) || 0;

  const formattedEpisode = scenario.episodes.map(episode => {
    let endDate = episode.events.reduce((latest, event) => {
      return event.date > latest.date ? event : latest;
    }).date;

    return {
      start_date: episode.date,
      end_date: endDate,
      events: episode.events,
    };
  });

  const columns = getColumns(totalTradingDays, t);

  return (
    <>
      <S.Title data-testid="scenario-past-episode">
        <span>
          <span>{t('widget:pastEpisode:title.occurrences')}</span>
          <S.PastEpisodeKey />
          <TooltipContainer
            label={t('analyze:experiment.chart.tooltip')}
            showUnderline
          >
            <span>
              {t('widget:pastEpisode:title.episodes', {
                episodes: scenario.episodes.length,
              })}
            </span>
          </TooltipContainer>
          <span>
            {t('widget:pastEpisode:title.days', {
              totalTradingDays,
            })}
          </span>
        </span>
      </S.Title>
      <S.PastEpisodesButtons>
        <S.StyledButtonGroup
          labels={getHorizonsLabels(t, PAST_EPISODES_HORIZONS)}
          onClick={handleChangeHorizon}
          activeIndex={activeHorizonIdx}
          $activeIndex={activeHorizonIdx}
        />
        <Tooltip label={t('chart:fullscreen')}>
          <S.StyledButton
            aria-label={t('chart:fullscreen')}
            iconName="Expand"
            variant="empty"
            size="small"
            onClick={() => {
              navigate({
                pathname: appPaths.chart,
                search: createSearchParams({
                  asset: `${scenario.entity.tag};${snake};${0}`,
                  horizon: PAST_EPISODES_HORIZONS[activeHorizonIdx],
                  primary: snake,
                  resample:
                    rangeToResampleMapping[
                      PAST_EPISODES_HORIZONS[activeHorizonIdx]
                    ],
                  'yAxisType.1': 'merged',
                }).toString(),
              });
            }}
          />
        </Tooltip>
      </S.PastEpisodesButtons>

      <S.ChartWrapper>
        <PastEpisodesChart
          entityTag={scenario.entity.tag}
          snakes={snake}
          episodes={formattedEpisode}
          horizon={PAST_EPISODES_HORIZONS[activeHorizonIdx]}
        />
      </S.ChartWrapper>
      <S.Title>{t('widget:pastEpisode.pastEpisode')}</S.Title>
      <S.TableRoot>
        <SmartTable
          columns={columns}
          data={mapEpisodesToTableRow(formattedEpisode)}
          withBodyRowHover={false}
        />
      </S.TableRoot>
    </>
  );
};
