import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const LegendContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  ${typographyV2.bodyXs}
`;

export const LegendColor = styled.div<{ $color: string }>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-color: ${({ $color }) => $color};
  ${borderRadius.rFull}
`;
