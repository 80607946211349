import { Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const SelectorWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  text-transform: uppercase;
  min-width: 42px;
`;

export const StyledIcon = styled(Icon)`
  fill: var(--icon-primary);
`;
