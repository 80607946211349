import { Condition, EntitySearchType, formatTicker } from '@toggle/toggle';
import React from 'react';

import { useTagsSearch } from '~/hooks/use-tags-search/useTagsSearch';

import { ConditionSearch } from '../condition-search/ConditionSearch';
import * as S from './ConditionAssetSearch.styles';

export interface ConditionAssetSearchProps {
  condition: Condition;
  response: (editedPayload: Partial<Condition>) => void;
}

export const ConditionAssetSearch = ({
  condition,
  response,
}: ConditionAssetSearchProps) => {
  const isAssetNameMessage = (value: string) =>
    value.length < 16 && value.split(/\s+/).length < 3;

  const { tags: tagList, searchTags } = useTagsSearch({
    searchType: EntitySearchType.Entity,
    additionalConditionChecker: isAssetNameMessage,
  });

  return (
    <ConditionSearch
      label={condition.formattedTicker}
      dataTestId="scenario-asset-search"
      onChange={e => searchTags(e.target.value)}
    >
      {tagList.map(entity => (
        <S.Item
          key={entity.tag}
          onSelect={() => {
            const ticker = formatTicker(entity);
            const editedCondition = {
              entity: entity.tag,
              entityName: entity.name,
              formattedTicker: ticker,
              snake: '',
              snakeName: '',
            };
            response(editedCondition);
          }}
        >
          {entity.name_short}
        </S.Item>
      ))}
    </ConditionSearch>
  );
};
