import { Drawer } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)<{ $zIndex?: string }>`
  ${Drawer.Styled.Body} {
    height: 100%;
    overflow-y: hidden;
  }

  ${Drawer.Styled.Container} {
    width: 600px;
    z-index: ${({ $zIndex }) =>
      $zIndex ?? `calc(var(--z-index-chat-input) + 1)`};
  }

  ${Drawer.Styled.Header} {
    padding: 6px 16px 6px 6px;
    justify-content: flex-end;
  }
`;
