import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon/Icon';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DropdownGroup = styled(RadixDropdown.Group)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DropdownItemButton = styled.div<{ $disabled?: boolean }>`
  margin: 0 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  ${borderRadius.r1};

  ${({ $disabled }) =>
    !$disabled &&
    css`
      cursor: pointer;
    `}
`;

export const DropdownIcon = styled(Icon)``;

export const DropdownMenuItem = styled(RadixDropdown.Item)`
  all: unset;

  &[data-highlighted] {
    ${DropdownItemButton} {
      background-color: var(--surface-hover);
    }
  }

  &[data-disabled] {
    ${DropdownItemButton} {
      color: var(--text-disabled);
    }

    ${DropdownIcon} {
      fill: var(--icon-disabled);
    }
  }
`;

export const DropdownItemLabel = styled.span`
  ${typographyV2.bodySm}
`;
