import { z } from 'zod';

/** Government trades schemas */
export const govTradesRequestSchema = z.object({
  entities: z.array(z.string()),
  start_date: z.string(),
  transaction_types: z.array(z.enum(['purchase', 'sale', 'partial_sale'])),
  security_types: z.array(z.enum(['stock', 'stock_option'])),
});

export type GovernmentTradesRequest = z.infer<typeof govTradesRequestSchema>;
export type TransactionTypes =
  typeof govTradesRequestSchema['_output']['transaction_types'][number];
export type SecurityTypes =
  typeof govTradesRequestSchema['_output']['security_types'][number];

export const govTradeSchema = z.object({
  tag: z.string(),
  report_date: z.string().optional(),
  transaction_date: z.string(),
  transaction: z.string().optional(),
  amount: z.string().optional(),
  display_name: z.string().optional(),
  chamber: z.string().optional(),
  status: z.string().optional(),
  party: z.string().optional(),
  state: z.string().optional(),
  committees: z.array(z.string()),
  disclosure_url: z.string().optional(),
});

export const govTradeResponseSchema = z.object({
  gov_trades: z.array(govTradeSchema),
});

export type GovernmentTradeInputResponse = z.input<typeof govTradeSchema>;
export type GovernmentTradesInputResponse = z.input<
  typeof govTradeResponseSchema
>;
export type GovernmentTradeOutputResponse = z.output<typeof govTradeSchema>;
export type GovernmentTradesOutputResponse = z.output<
  typeof govTradeResponseSchema
>;

/** Insider trades schemas */
export const insiderTradesRequestSchema = z.object({
  entities: z.array(z.string()),
  start_date: z.string(),
  transaction_types: z.array(z.string()),
  rule: z.string(),
});

export type InsiderTradesRequest = z.infer<typeof insiderTradesRequestSchema>;

const insiderTradeSchema = z.object({
  tag: z.string(),
  transaction: z.string().optional(),
  transaction_date: z.string(),
  insider_title: z.string().optional(),
  insider_name: z.string().optional(),
  shares: z.number().default(-Infinity),
  post_transaction_quantity: z.number().default(-Infinity),
  security_type: z.string().optional(),
  filing_url: z.string().optional(),
});

export const insiderTradesResponseSchema = z.object({
  insider_trades: z.array(insiderTradeSchema),
});

export type InsiderTradeInputResponse = z.input<typeof insiderTradeSchema>;
export type InsiderTradesInputResponse = z.input<
  typeof insiderTradesResponseSchema
>;
export type InsiderTradeOutputResponse = z.output<typeof insiderTradeSchema>;
export type InsiderTradesOutputResponse = z.output<
  typeof insiderTradesResponseSchema
>;

/** IPOs schemas */
export const iposRequestSchema = z.object({
  entities: z.array(z.string()),
  start_date: z.string(),
  types: z.array(z.string()),
});

export type IposRequest = z.infer<typeof iposRequestSchema>;

const ipoSchema = z.object({
  tag: z.string(),
  currency: z.string().optional(),
  date: z.string().optional(),
  ipo_type: z.string(),
  price_public_offering: z.string().optional(),
  price_open: z.string().optional(),
  price_min: z.string().optional(),
  price_max: z.string().optional(),
  insider_lockup_date: z.string().optional(),
  insider_lockup_days: z.number().default(-Infinity),
  lead_underwriters: z.array(z.string()).default([]),
  other_underwriters: z.array(z.string()).default([]),
  filing_url: z.string().optional(),
});

export const iposResponseSchema = z.object({
  ipos: z.array(ipoSchema),
});

export type IpoInputResponse = z.input<typeof ipoSchema>;
export type IposInputResponse = z.input<typeof iposResponseSchema>;

export type IpoOutputResponse = z.output<typeof ipoSchema>;
export type IposOutputResponse = z.output<typeof iposResponseSchema>;

export type ScreenerEventsSchema =
  | typeof govTradeResponseSchema
  | typeof insiderTradesResponseSchema
  | typeof iposResponseSchema;
export type ScreenerEventsRequest =
  | GovernmentTradesRequest
  | InsiderTradesRequest
  | IposRequest;

export type ScreenerEventInputResponse =
  | GovernmentTradeInputResponse
  | InsiderTradeInputResponse
  | IpoInputResponse;
export type ScreenerEventsInputResponse =
  | GovernmentTradesInputResponse
  | InsiderTradesInputResponse
  | IposInputResponse;

export type ScreenerEventOutputResponse =
  | GovernmentTradeOutputResponse
  | InsiderTradeOutputResponse
  | IpoOutputResponse;
export type ScreenerEventsOutputResponse =
  | GovernmentTradesOutputResponse
  | InsiderTradesOutputResponse
  | IposOutputResponse;
