import { Entity, SnakeMeta } from '@toggle/toggle';
import React, { ReactNode } from 'react';

import * as S from './IndicatorListItem.styles';

export interface IndicatorListItemProps {
  isFocused: boolean;
  matchedEntity?: Entity;
  item: SnakeMeta;
  onFocus?: (name: string) => void;
  logoNode: ReactNode;
}

export const IndicatorListItem = ({
  isFocused,
  matchedEntity,
  item,
  onFocus,
  logoNode,
}: IndicatorListItemProps) => {
  return (
    <S.IndicatorItem
      data-testid={`list-item-${item.name.english}`}
      $isFocused={isFocused}
      onFocus={() => onFocus?.(item.name.english)}
    >
      <S.IndicatorText>{item.name.english}</S.IndicatorText>
      <S.Secondary>
        {logoNode}
        {matchedEntity && (
          <S.IndicatorSmallText>
            {matchedEntity.name_short}
          </S.IndicatorSmallText>
        )}
      </S.Secondary>
    </S.IndicatorItem>
  );
};
