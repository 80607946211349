import { TooltipTrigger } from '@toggle/design-system';
import React from 'react';

import {
  TooltipContainer,
  TooltipContainerProps,
} from '~/components/tooltip-container/TooltipContainer';

export interface TruncateTooltipProps
  extends Omit<TooltipContainerProps, 'label'> {
  text: string;
  atLength: number;
  id?: string;
  tooltipText?: string;
  fallbackLabel?: React.ReactElement;
  truncatedLabelWrapper?: React.ReactElement;
}

const SUFFIX = '...';

export const TruncateTooltip = ({
  text,
  atLength,
  id,
  tooltipText,
  fallbackLabel,
  truncatedLabelWrapper = <span id={id} data-testid="truncated-text" />,
  inPortal,
  ...rest
}: TruncateTooltipProps) => {
  const truncatedText = `${text.substring(
    0,
    atLength - SUFFIX.length
  )}${SUFFIX}`;

  if (text.length > atLength) {
    return (
      <TooltipContainer
        label={tooltipText ?? text}
        inPortal={inPortal}
        trigger={[TooltipTrigger.Hover]}
        {...rest}
      >
        {React.cloneElement(truncatedLabelWrapper, {
          children: truncatedText,
          ...rest,
        })}
      </TooltipContainer>
    );
  }

  return (
    fallbackLabel ?? (
      <span id={id} data-testid="text">
        {text}
      </span>
    )
  );
};
