import { getOffset } from '@toggle/helpers';
import {
  AuthDataPayload,
  authSchema,
  ChangePasswordResponse,
  changePasswordSchema,
  CheckPasswordData,
  checkPasswordDataSchema,
  CheckResetPasswordTokenResponse,
  checkResetPasswordTokenSchema,
  EmailValidationResponse,
  emailValidationSchema,
  ResetPasswordResponse,
  resetPasswordSchema,
} from '@toggle/toggle';

import { config } from '~/config';
import { SignUpProps } from '~/stores/use-auth/useAuth';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

const {
  api: { authRoot },
} = config;

export const authEndpoint = {
  login: 'v3/u/login',
  getToken: 'v3/uj',
  refreshToken: 'v2/u/refresh',
  logout: 'v2/u/logout',
  register: 'u/v1/user',
  verifyEmail: 'u/v1/verify-email',
  sendVerifyEmail: 'u/v1/send-verify-email',
  validateEmail: 'u/v1/validate-email',
  requestResetPassword: 'u/v1/request-password-reset',
  resetPassword: 'u/v1/reset-password',
  checkPasswordResetToken: 'u/v1/check-password-reset-token',
  updatePassword: 'v1/u',
  checkPassword: 'v1/u/check-password',
};

interface RequestResetPasswordData {
  email: string;
}

export interface ResetPasswordData {
  password: string;
  confirmedPassword: string;
  token: string;
}

export interface AuthDataTwoFactor {
  user_id: string;
  two_factor_methods: TwoFactorMethod[];
}

export interface ChangePasswordRequest {
  email: string;
  old_password: string;
  new_password: string;
}

export enum TwoFactorMethod {
  Totp = 'totp',
  Email = 'email',
  Backup = 'backup',
}

export type LoginProps = {
  username: string;
  password: string;
};

export const postLogin = (body: LoginProps) => {
  return apiFetch<AuthDataPayload>(authEndpoint.login, {
    basePath: authRoot,
    options: { credentials: 'include' },
    method: 'post',
    body,
    noToken: true,
  });
};

export const getAuthToken = () => {
  return apiFetch<AuthDataPayload>(authEndpoint.getToken, {
    basePath: authRoot,
    options: { credentials: 'include' },
    schema: authSchema,
  });
};

export const getRefreshToken = (token?: string) => {
  return apiFetch<AuthDataPayload>(authEndpoint.refreshToken, {
    basePath: authRoot,
    options: { credentials: 'include' },
    schema: authSchema,
    token,
  });
};

export const postLogout = () => {
  return apiFetch<AuthDataPayload>(authEndpoint.logout, {
    basePath: authRoot,
    options: { credentials: 'include' },
    method: 'post',
  });
};

export const postRegister = (userDetails: SignUpProps) => {
  const {
    termsAccepted,
    email,
    password,
    promoCode,
    referralCode,
    isProfessional,
  } = userDetails;

  const body = {
    email,
    password,
    ...(promoCode && { promo_code: promoCode }),
    ...(referralCode && { referral_code: referralCode }),
    language: 'en',
    document_ids: termsAccepted,
    is_professional: isProfessional,
    tz_offset: getOffset(),
  };

  return apiFetch<AuthDataPayload>(authEndpoint.register, {
    method: 'post',
    body,
  });
};

export const postReqResetPassword = ({ email }: RequestResetPasswordData) => {
  return apiFetch<ResetPasswordResponse>(authEndpoint.requestResetPassword, {
    method: 'post',
    schema: resetPasswordSchema,
    body: { email },
  });
};

export const postResetPassword = (passwordDetails: ResetPasswordData) => {
  const { token, password, confirmedPassword } = passwordDetails;
  return apiFetch<ResetPasswordResponse>(authEndpoint.resetPassword, {
    method: 'post',
    schema: resetPasswordSchema,
    body: {
      token,
      password,
      verify_password: confirmedPassword,
    },
  });
};

export const postCheckResetPasswordToken = (token: string) => {
  return apiFetch<CheckResetPasswordTokenResponse>(
    authEndpoint.checkPasswordResetToken,
    {
      method: 'post',
      schema: checkResetPasswordTokenSchema,
      body: {
        token,
      },
    }
  );
};

export const postEmailValidation = (email: string) => {
  return apiFetch<EmailValidationResponse>(authEndpoint.validateEmail, {
    method: 'post',
    schema: emailValidationSchema,
    body: { email },
  });
};

export const postUpdatePassword = (body: ChangePasswordRequest) => {
  return apiFetch<ChangePasswordResponse>(authEndpoint.updatePassword, {
    basePath: authRoot,
    method: 'post',
    schema: changePasswordSchema,
    body,
  });
};

export const postSendVerifyEmail = (email: string) => {
  return apiFetch(authEndpoint.sendVerifyEmail, {
    method: 'post',
    body: { email },
  });
};

export const postVerifyEmail = (token: string) => {
  return apiFetch(authEndpoint.verifyEmail, {
    method: 'post',
    body: { token },
  });
};

export const postCheckPassword = (username: string, password: string) => {
  return apiFetch<CheckPasswordData>(authEndpoint.checkPassword, {
    basePath: authRoot,
    method: 'post',
    schema: checkPasswordDataSchema,
    body: { username, password },
    options: { credentials: 'include' },
  });
};
