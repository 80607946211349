import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { WatchlistSummary } from '@toggle/toggle';

import { getWatchlistSummary } from './service/watchlist-summary-service';

export const QUERY_KEY_WATCHLIST_SUMMARY = 'QUERY_KEY_WATCHLIST_SUMMARY';

interface ApiError extends Error {
  status: number;
}

export const useWatchlistSummary = (watchlistId: string) =>
  useQuery<WatchlistSummary, ApiError>({
    queryKey: [QUERY_KEY_WATCHLIST_SUMMARY, watchlistId],
    queryFn: ({ signal }) => getWatchlistSummary({ watchlistId, signal }),
    staleTime: getTimeTillEndOfDayMs(),
  });
