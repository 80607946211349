import { SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '140px',
        borderRadius: '8px',
      },
    },
  ],
})``;
