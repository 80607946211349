import { WatchlistSummary, watchlistSummary } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

export interface GetWatchlistSummaryProps {
  watchlistId: string;
  signal?: AbortSignal;
}

export const getWatchlistSummary = ({
  watchlistId,
  signal,
}: GetWatchlistSummaryProps) =>
  wretchRequest<WatchlistSummary>(
    watchlistSummary.getWatchlistSummary.path(watchlistId),
    {
      schema: watchlistSummary.getWatchlistSummary.schema,
      options: {
        signal,
      },
    }
  );
