import { DropdownListItem } from '@toggle/design-system';
import { formatTicker } from '@toggle/toggle';
import React from 'react';
import { useNavigate } from 'react-router';

import { useEntities } from '~/hooks/use-entities';
import {
  BreadcrumbItem,
  useBreadcrumbs,
} from '~/stores/use-breadcrumbs/useBreadcrumbs';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

import * as S from './TerminalBreadcrumbs.styles';

export interface TerminalBreadcrumbsProps {
  showOnlyLastItem?: boolean;
}

export const TerminalBreadcrumbs = ({
  showOnlyLastItem,
}: TerminalBreadcrumbsProps) => {
  const { breadcrumbs = [], removeFromBreadcrumbs } = useBreadcrumbs(state => ({
    breadcrumbs: state.breadcrumbs,
    removeFromBreadcrumbs: state.removeFromBreadcrumbs,
  }));
  const { viewedThemes } = useThemeOverviewStore();
  const navigate = useNavigate();

  const assetTags = breadcrumbs
    .filter(b => b.assetTag)
    .map(b => b.assetTag) as string[];
  const { data: entities = [] } = useEntities(assetTags);

  const onNodeClick = ({
    e,
    item,
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    item: BreadcrumbItem;
  }) => {
    e.preventDefault();
    const { id, to, isAccessibleFromSideBar, navigateFn } = item;

    removeFromBreadcrumbs(id, isAccessibleFromSideBar);
    to ? navigate(to) : navigateFn?.();
  };

  const onSelectDropdownItem = (item: BreadcrumbItem) => {
    removeFromBreadcrumbs(item.id, item.isAccessibleFromSideBar);
    item.navigateFn?.();

    if (item.to) {
      navigate(item.to);
    }
  };

  const getLabel = (item: BreadcrumbItem) => {
    if (item.assetTag) {
      const entity = entities.find(e => e?.tag === item.assetTag);

      if (entity) {
        return formatTicker(entity);
      }
    } else if (item.themeId) {
      return viewedThemes[item.themeId]?.name ?? item.themeId;
    }

    return item.label;
  };

  const renderNode = (item: BreadcrumbItem, disabled?: boolean) => {
    const { id, label } = item;
    const displayLabel = label || getLabel(item);

    if (disabled) {
      return <span data-testid={`breadcrumb-${id}`}>{displayLabel}</span>;
    }

    return (
      <a
        href=""
        data-testid={`breadcrumb-${id}`}
        onClick={e => onNodeClick({ e, item })}
      >
        {displayLabel}
      </a>
    );
  };

  const renderDropdownListItem = (
    item: BreadcrumbItem,
    isActive: boolean,
    isFocused: boolean
  ) => (
    <DropdownListItem
      label={item.label || getLabel(item)}
      isActive={isActive}
      isFocused={isFocused}
    />
  );

  return showOnlyLastItem ? (
    <S.StyledChartBreadcrumbs
      data={breadcrumbs}
      renderNode={renderNode}
      onSelectDropdownItem={onSelectDropdownItem}
      renderDropdownListItem={renderDropdownListItem}
    />
  ) : (
    <S.StyledBreadcrumbs
      data={breadcrumbs}
      renderNode={renderNode}
      maxVisibleItems={6}
      onSelectDropdownItem={onSelectDropdownItem}
      renderDropdownListItem={renderDropdownListItem}
    />
  );
};
