import styled from 'styled-components';

import { ChartAreaWrapper } from '~/components/chart-area-wrapper/ChartAreaWrapper';
import { LegendItem } from '~/views/turbo-chart/components/legend-item/LegendItem';
import { LegendLivePrice } from '~/views/turbo-chart/components/legend-live-price/LegendLivePrice';
import { LegendPriceIcons } from '~/views/turbo-chart/components/legend-price-icons/LegendPriceIcons';

export const StyledChartAreaWrapper = styled(ChartAreaWrapper)<{
  $height: number;
}>`
  height: ${({ $height }) => `${$height}px`};

  ${LegendItem.Styled.Button} {
    font-size: 10px;
  }

  ${LegendItem.Styled.LegendItemRoot} {
    margin-bottom: 0;
    padding: 0 6px;
    opacity: 0.7;

    ${LegendItem.Styled.LegendButtonWrapper} {
      padding-left: 0;
      padding-right: 2px;
      pointer-events: none;
    }

    ${LegendItem.Styled.LegendButtonWrapper}:first-child {
      :first-child {
        display: none;
      }
    }

    ${LegendItem.Styled.EntityNameWrapper} {
      display: none;
    }

    ${LegendItem.Styled.Asset} {
      margin-right: 8px;

      svg {
        margin: 0 2px;
      }
    }
  }

  ${LegendLivePrice.styled.PriceContainer} {
    font-size: 10px;
  }

  ${LegendPriceIcons.Styled.LegendPriceIconsRoot} {
    display: none;
  }
`;
