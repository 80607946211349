import { borderRadius } from '@toggle/design-system';
import styled, { css, keyframes } from 'styled-components';

import { ChatDocumentContextInput } from '~/components/chat';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: var(--chat-message-width);
  max-width: var(--chat-message-width);
`;

const spin = keyframes`
  to {
    --bg-angle: 1turn;
  }
`;

export const LoaderWrapper = styled.div<{
  $isMessagePending: boolean;
  $showModesSwitcher: boolean;
}>`
  ${({ $isMessagePending }) =>
    $isMessagePending &&
    css`
      --bg-angle: 0turn;
      @property --bg-angle {
        inherits: false;
        initial-value: 0deg;
        syntax: '<angle>';
      }

      animation: ${spin} 3s linear infinite;
      background: linear-gradient(
            var(--surface-default),
            var(--surface-default)
          )
          padding-box,
        conic-gradient(from var(--bg-angle), transparent, var(--border-primary))
          border-box;

      border: 2px solid transparent;
      border-radius: 12px;
    `}
  ${props =>
    props.$showModesSwitcher &&
    css`
      border: 1px solid var(--border-soft);
      ${borderRadius.r2};

      & *:not(button) {
        border: none;
      }

      ${ChatDocumentContextInput.Styled.ChatInputFieldWrapper} {
        border-radius: 8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid transparent;
      }
    `}
`;

export const ModesSwitcher = styled.div`
  padding: 1px;
  background: var(--background-default);
  border-radius: inherit;
`;
