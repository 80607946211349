import React from 'react';

import { Tracking } from '~/services/tracking';
import { reloadAppOnce } from '~/utils/reload-app-once/reloadAppOnce';

interface ComponentLoader {
  default: React.ComponentType<any>;
}
type LazyComponent = () => Promise<ComponentLoader>;

export function componentLoader(
  lazyComponent: LazyComponent,
  reloadKey: string
): Promise<ComponentLoader> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        try {
          if (error.name === 'ChunkLoadError') {
            reloadAppOnce(reloadKey);
          }

          reject(error);
        } catch (err) {
          Tracking.captureMessage('Lazy loading failed');
          reject(error);
        }
      });
  });
}

export function lazyComponentLoader(
  lazyComponent: LazyComponent,
  reloadKey: string
): React.LazyExoticComponent<React.ComponentType<any>> {
  return React.lazy(() => componentLoader(lazyComponent, reloadKey));
}
