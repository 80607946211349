import {
  amber,
  amberDark,
  blueDark,
  crimsonDark,
  cyanDark,
  goldDark,
  grassDark,
  grayDark,
  indigoDark,
  limeDark,
  oliveDark,
  orangeDark,
  plumDark,
  purpleDark,
  redDark,
  tealDark,
  tomatoDark,
  violetDark,
  yellowDark,
} from '@radix-ui/colors';

import { hslToHex } from '../../utils/color-conversion/color-conversion';

const background = {
  '--background-default': 'rgba(9, 9, 9, 1)',
  '--background-soft': 'rgba(16, 16, 16, 1)',
  '--background-backdrop': 'rgba(0, 0, 0, 0.7)',
};

const surface = {
  '--surface-default': grayDark.gray3,
  '--surface-hover': grayDark.gray4,
  '--surface-pressed': grayDark.gray5,
  '--surface-active': grayDark.gray5,
  '--surface-inverse': '#ffffff',
  '--surface-primary': amberDark.amber9,
  '--surface-success': grassDark.grass3,
  '--surface-warning': yellowDark.yellow3,
  '--surface-critical': tomatoDark.tomato3,
  '--surface-neutral': grayDark.gray9,
  '--surface-white': '#ffffff',

  '--surface-decorative-indigo-default': indigoDark.indigo3,
  '--surface-decorative-indigo-hover': indigoDark.indigo4,
  '--surface-decorative-indigo-active': indigoDark.indigo5,

  '--surface-decorative-teal-default': tealDark.teal3,
  '--surface-decorative-teal-hover': tealDark.teal4,
  '--surface-decorative-teal-active': tealDark.teal5,

  '--surface-decorative-cyan-default': cyanDark.cyan3,
  '--surface-decorative-cyan-hover': cyanDark.cyan4,
  '--surface-decorative-cyan-active': cyanDark.cyan5,

  '--surface-decorative-purple-default': purpleDark.purple3,
  '--surface-decorative-purple-hover': purpleDark.purple4,
  '--surface-decorative-purple-active': purpleDark.purple5,

  '--surface-decorative-lime-default': limeDark.lime3,
  '--surface-decorative-lime-hover': limeDark.lime4,
  '--surface-decorative-lime-active': limeDark.lime5,
};

const text = {
  '--text-default': '#ffffff',
  '--text-soft': grayDark.gray11,
  '--text-disabled': grayDark.gray9,
  '--text-inverse': grayDark.gray1,
  '--text-primary': amberDark.amber9,
  '--text-success': grassDark.grass11,
  '--text-warning': yellowDark.yellow11,
  '--text-critical': tomatoDark.tomato11,
  '--text-critical-dark-red': redDark.red11,
  '--text-on-primary': grayDark.gray1,
  '--text-on-critical': '#ffffff',
  '--text-link': blueDark.blue11,
  '--text-decorative-indigo': indigoDark.indigo11,
  '--text-decorative-teal': tealDark.teal11,
  '--text-decorative-cyan': cyanDark.cyan11,
  '--text-decorative-purple': purpleDark.purple11,
  '--text-decorative-lime': limeDark.lime10,
};

const icon = {
  '--icon-default': '#ffffff',
  '--icon-soft': grayDark.gray11,
  '--icon-disabled': grayDark.gray9,
  '--icon-inverse': grayDark.gray1,
  '--icon-primary': amberDark.amber9,
  '--icon-success': grassDark.grass11,
  '--icon-success-grass': grassDark.grass9,
  '--icon-warning': yellowDark.yellow11,
  '--icon-critical': tomatoDark.tomato11,
  '--icon-critical-red': redDark.red9,
  '--icon-on-primary': grayDark.gray1,
  '--icon-on-critical': '#ffffff',
  '--icon-decorative-indigo': indigoDark.indigo11,
  '--icon-decorative-teal': tealDark.teal11,
  '--icon-decorative-cyan': cyanDark.cyan11,
  '--icon-decorative-purple': purpleDark.purple11,
  '--icon-decorative-lime': limeDark.lime10,
  '--icon-success-lime': limeDark.lime9,
};

const border = {
  '--border-default': grayDark.gray9,
  '--border-soft': grayDark.gray6,
  '--border-hover': grayDark.gray11,
  '--border-disabled': grayDark.gray6,
  '--border-primary': amberDark.amber9,
  '--border-success': grassDark.grass11,
  '--border-warning': yellowDark.yellow11,
  '--border-critical': tomatoDark.tomato11,
  '--border-focus': amber.amber4,
  '--border-decorative-indigo': indigoDark.indigo11,
  '--border-decorative-teal': tealDark.teal11,
  '--border-decorative-cyan': cyanDark.cyan11,
  '--border-decorative-purple': purpleDark.purple11,
  '--border-decorative-lime': limeDark.lime10,
};

const action = {
  '--action-primary-default': amberDark.amber9,
  '--action-primary-hover': amberDark.amber10,
  '--action-primary-disabled': grayDark.gray4,
  '--action-primary-soft-hover': 'rgba(255, 178, 36, 0.15)',
  '--action-critical-default': tomatoDark.tomato9,
  '--action-critical-hover': tomatoDark.tomato10,
  '--action-critical-disabled': grayDark.gray4,
};

const grassDark11Rgb = '99, 193, 116';
const tomatoDark11Rgb = '241, 106, 80';
const blueDark11Rgb = '82, 169, 255';

const visual = {
  '--viz-bullish': grassDark.grass11,
  '--viz-bullish-rgb': grassDark11Rgb,
  '--viz-bullish-soft': grassDark.grass4,
  '--viz-bearish': tomatoDark.tomato11,
  '--viz-bearish-rgb': tomatoDark11Rgb,
  '--viz-bearish-soft': tomatoDark.tomato4,
  '--viz-neutral-soft': 'rgba(255, 178, 36, 0.15)',
};

const elevation = {
  '--elevation-subtle':
    '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
  '--elevation-moderate':
    '0px 2px 3px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
  '--elevation-strong':
    '0px 4px 4px rgba(0, 0, 0, 0.3), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
};

const custom = {
  '--background-default-rgb': '9, 9, 9',
  '--background-soft-rgb': '16, 16, 16',
  '--background-table-zebra': grayDark.gray1,
  '--chart-pane-gradient': 'hsl(0, 0%, 6%)',
  '--chart-multi-lines-hex-1': hslToHex(amberDark.amber9),
  '--chart-multi-lines-hex-2': hslToHex(violetDark.violet10),
  '--chart-multi-lines-hex-3': hslToHex(tealDark.teal10),
  '--chart-multi-lines-hex-4': hslToHex(goldDark.gold10),
  '--chart-multi-lines-hex-5': hslToHex(crimsonDark.crimson10),
  '--chart-multi-lines-hex-6': hslToHex(orangeDark.orange10),
  '--chart-multi-lines-hex-7': hslToHex(tomatoDark.tomato10),
  '--chart-multi-lines-hex-8': hslToHex(oliveDark.olive10),
  '--chart-multi-lines-hex-9': hslToHex(grassDark.grass10),
  '--chart-multi-lines-hex-10': hslToHex(yellowDark.yellow10),
  '--chart-multi-lines-hex-11': hslToHex(blueDark.blue10),
  '--chart-multi-lines-hex-12': hslToHex(indigoDark.indigo10),
  '--chart-multi-lines-hex-13': hslToHex(plumDark.plum10),
};

const dark_default = {
  ...background,
  ...surface,
  ...border,
  ...text,
  ...icon,
  ...action,
  ...visual,
  ...elevation,
  ...custom,
};

const dark_inverted = {
  ...dark_default,
  '--viz-bullish': tomatoDark.tomato11,
  '--viz-bullish-rgb': tomatoDark11Rgb,
  '--viz-bullish-soft': tomatoDark.tomato4,
  '--viz-bearish': grassDark.grass11,
  '--viz-bearish-rgb': grassDark11Rgb,
  '--viz-bearish-soft': grassDark.grass4,
};
const dark_asian = {
  ...dark_default,
  '--viz-bullish': tomatoDark.tomato11,
  '--viz-bullish-rgb': tomatoDark11Rgb,
  '--viz-bullish-soft': tomatoDark.tomato4,
  '--viz-bearish': blueDark.blue11,
  '--viz-bearish-rgb': blueDark11Rgb,
  '--viz-bearish-soft': blueDark.blue4,
};

const dark_protanopia = {
  ...dark_default,
  '--viz-bearish': blueDark.blue11,
  '--viz-bearish-rgb': blueDark11Rgb,
  '--viz-bearish-soft': blueDark.blue4,
};

const dark_deuteranopia = {
  ...dark_default,
  '--viz-bullish': blueDark.blue11,
  '--viz-bullish-rgb': blueDark11Rgb,
  '--viz-bullish-soft': blueDark.blue4,
};

export const darkTheme = {
  dark_default,
  dark_inverted,
  dark_asian,
  dark_protanopia,
  dark_deuteranopia,
};
