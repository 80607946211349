import { css } from 'styled-components';

import { breakpoints } from '../breakpoints/breakpoints';

export const typography = {
  elephant: css`
    font-size: 40px;
    line-height: 48px;
  `,
  rhino: css`
    font-size: 36px;
    line-height: 44px;
  `,
  tiger: css`
    font-size: 28px;
    line-height: 36px;
  `,
  chimp: css`
    font-size: 22px;
    line-height: 32px;
  `,
  coyote: css`
    font-size: 20px;
    line-height: 32px;
  `,
  dingo: css`
    font-size: 18px;
    line-height: 24px;
  `,
  emu: css`
    font-size: 16px;
    line-height: 24px;
  `,
  koala: css`
    font-size: 14px;
    line-height: 20px;
  `,
  quokka: css`
    font-size: 12px;
    line-height: 16px;
  `,
  quoll: css`
    font-size: 10px;
    line-height: 16px;
  `,
};

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
};

export const fontSize = {
  f50: css`
    font-size: 0.813rem;
    @media ${breakpoints.lg} {
      font-size: 0.75rem;
    }
  `,
  f75: css`
    font-size: 0.938rem;
    @media ${breakpoints.lg} {
      font-size: 0.875rem;
    }
  `,
  f100: css`
    font-size: 1.063rem;
    @media ${breakpoints.lg} {
      font-size: 1rem;
    }
  `,
  f200: css`
    font-size: 1.188rem;
    @media ${breakpoints.lg} {
      font-size: 1.125rem;
    }
  `,
  f300: css`
    font-size: 1.313rem;
    @media ${breakpoints.lg} {
      font-size: 1.25rem;
    }
  `,
  f400: css`
    font-size: 1.625rem;
    @media ${breakpoints.lg} {
      font-size: 1.5rem;
    }
  `,
  f500: css`
    font-size: 2rem;
  `,
  f600: css`
    font-size: 2.5rem;
  `,
  f700: css`
    font-size: 3rem;
  `,
  f800: css`
    font-size: 3rem;
  `,
  f900: css`
    font-size: 4.875rem;
  `,
};

export const lineHeight = {
  l100: css`
    line-height: 1rem;
  `,
  l200: css`
    line-height: 1.25rem;
  `,
  l300: css`
    line-height: 1.5rem;
  `,
  l400: css`
    line-height: 1.75rem;
  `,
  l500: css`
    line-height: 2rem;
  `,
  l600: css`
    line-height: 2.5rem;
  `,
  l700: css`
    line-height: 3.25rem;
  `,
  l800: css`
    line-height: 4rem;
  `,
  l900: css`
    line-height: 5rem;
  `,
  l1000: css`
    line-height: 6.5rem;
  `,
};

export const typographyV2 = {
  displayLg: css`
    ${fontSize.f900};
    ${lineHeight.l1000};
    font-weight: ${fontWeight.light};
  `,
  displayMd: css`
    ${fontSize.f800};
    ${lineHeight.l900};
    font-weight: ${fontWeight.light};
  `,
  displaySm: css`
    ${fontSize.f700};
    ${lineHeight.l800};
    font-weight: ${fontWeight.light};
  `,
  title3XL: css`
    ${fontSize.f600};
    ${lineHeight.l700};
    font-weight: ${fontWeight.medium};
  `,
  title2XL: css`
    ${fontSize.f500};
    ${lineHeight.l600};
    font-weight: ${fontWeight.medium};
  `,
  titleXL: css`
    ${fontSize.f400};
    ${lineHeight.l500};
    font-weight: ${fontWeight.medium};
  `,
  titleLg: css`
    ${fontSize.f300};
    ${lineHeight.l400};
    font-weight: ${fontWeight.medium};
  `,
  titleMd: css`
    ${fontSize.f200};
    ${lineHeight.l300};
    font-weight: ${fontWeight.medium};
  `,
  titleBase: css`
    ${fontSize.f100};
    ${lineHeight.l300};
    font-weight: ${fontWeight.medium};
  `,
  titleSm: css`
    ${fontSize.f75};
    ${lineHeight.l200};
    font-weight: ${fontWeight.medium};
  `,
  titleXs: css`
    ${fontSize.f50};
    ${lineHeight.l100};
    font-weight: ${fontWeight.medium};
  `,
  bodyMd: css`
    ${fontSize.f200};
    ${lineHeight.l300};
    font-weight: ${fontWeight.regular};
  `,
  bodyBase: css`
    ${fontSize.f100};
    ${lineHeight.l300};
    font-weight: ${fontWeight.regular};
  `,
  bodySm: css`
    ${fontSize.f75};
    ${lineHeight.l200};
    font-weight: ${fontWeight.regular};
  `,
  bodyXs: css`
    ${fontSize.f50};
    ${lineHeight.l100};
    font-weight: ${fontWeight.regular};
  `,
  uiBase: css`
    ${fontSize.f100};
    ${lineHeight.l300};
    font-weight: ${fontWeight.medium};
  `,
  uiSm: css`
    ${fontSize.f75};
    ${lineHeight.l200};
    font-weight: ${fontWeight.medium};
  `,
  uiXs: css`
    ${fontSize.f50};
    ${lineHeight.l100};
    font-weight: ${fontWeight.medium};
  `,
};
