import { CheckBox, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const NodeWrapper = styled.div<{ $level: number }>`
  display: flex;
  padding-left: ${({ $level }) => $level * 20}px;

  ${CheckBox.Styled.Checkbox} {
    align-items: center;
  }

  ${CheckBox.Styled.CheckIcon} {
    width: 18px;
    height: 18px;
  }

  ${CheckBox.Styled.Checkmark} {
    margin-top: 0;
    margin-right: 6px;
  }

  ${CheckBox.Styled.CheckboxLabel} {
    ${typographyV2.bodySm}
  }
`;
