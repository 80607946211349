import { Alert } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import { ModalDetails } from '../../views/screener/screener-modal/ScreenerModal';
import * as S from './ActionsModal.styles';

export enum ActionModal {
  SaveAs = 'save',
  Rename = 'rename',
  Delete = 'delete',
  UnsavedChanges = 'unsaved',
  UnsavedDraftChanges = 'unsavedDraft',
  DocSearch = 'doc-search',
}

interface ActionsModalProps {
  inputNode?: ReactNode;
  activeModal: ActionModal | undefined;
  modalDetailsOverride?: Partial<Record<ActionModal, ModalDetails>>;
  cancelBtnProps: ModalDetails['cancelBtnProps'];
  labels?: {
    saveTitle?: string;
    savePrimaryBtn?: string;
    renameTitle?: string;
    deleteTitle?: string;
    deleteContent?: string;
    deleteBtn?: string;
  };
  dataTestId: string;
  errorMessage?: string;
  customWidth?: string;
  onClose: () => void;
  clearError: () => void;
  onRename?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
}

export const ActionsModal = ({
  onClose,
  dataTestId,
  clearError,
  errorMessage,
  activeModal,
  inputNode,
  customWidth,
  onRename,
  onSave,
  onDelete,
  cancelBtnProps,
  modalDetailsOverride,
  labels = {},
}: ActionsModalProps) => {
  if (!activeModal) {
    return null;
  }

  const actionToModalDetails: { [key: string]: ModalDetails } = {
    [ActionModal.SaveAs]: {
      title: labels.saveTitle,
      content: inputNode,
      primaryBtnProps: {
        label: labels.savePrimaryBtn,
        onClick: onSave,
      },
      cancelBtnProps,
    },
    [ActionModal.Rename]: {
      title: labels.renameTitle,
      content: inputNode,
      primaryBtnProps: {
        label: labels.savePrimaryBtn,
        onClick: onRename,
        'data-testid': 'rename-button',
      },
      cancelBtnProps,
    },
    [ActionModal.Delete]: {
      title: labels.deleteTitle,
      content: labels.deleteContent,
      primaryBtnProps: {
        label: labels.deleteBtn,
        onClick: onDelete,
        variant: 'critical',
        'data-testid': 'delete-btn',
      },
      cancelBtnProps,
    },
    [ActionModal.DocSearch]: {
      title: labels.saveTitle,
      content: inputNode,
    },
    ...modalDetailsOverride,
  };

  const { content, ...rest } = actionToModalDetails[activeModal];

  return (
    <S.StyledModal
      isOpen
      onClose={onClose}
      data-testid={dataTestId}
      {...rest}
      $customWidth={customWidth}
    >
      <S.ContentContainer>
        {errorMessage && <Alert message={errorMessage} onClose={clearError} />}
        {content}
      </S.ContentContainer>
    </S.StyledModal>
  );
};
