import styled, { css } from 'styled-components';

export const FilterTreeTrigger = styled.div<{
  $active?: boolean;
  $showAsHovered?: boolean;
}>`
  display: inline-flex;
  background-color: ${props =>
    props.$active ? 'var(--surface-active)' : 'var(--surface-soft)'};
  height: 32px;
  padding: 6px 8px 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.$active ? 'var(--surface-active)' : 'var(--border-default)'};
  cursor: pointer;
  color: ${props =>
    props.$active ? 'var(--text-default)' : 'var(--text-soft)'};

  &:hover {
    background-color: var(--surface-hover);
    color: var(--text-default);
  }

  ${props =>
    props.$showAsHovered &&
    css`
      background-color: var(--surface-hover);
      color: var(--text-default);
    `}
`;
