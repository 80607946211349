import { ButtonGroup, SmartTable } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const ColumnTitleLabel = styled.div`
  font-size: 12px;
`;

export const ColumnSubtitleLabel = styled.div`
  font-size: 10px;
`;

export const MultilineColumnTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const ButtonGroupsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FinancialStatementStyledButtonGroup = styled(ButtonGroup)`
  white-space: nowrap;
  border: none;
  background-color: transparent;
  padding: 0px;

  & > button {
    color: var(--text-default);
    border-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 4px 8px;

    &:hover {
      background-color: var(--surface-hover);
    }
  }
`;

export const AssetFinancialsRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 20px;
  margin: 0 auto;
  cursor: default;
`;

export const FinancialsTableWrapper = styled.div<{
  $isSidebarExpanded: boolean;
  $estimatedColumnIndices?: number[];
}>`
  ${SmartTable.Styled.Table} {
    th {
      border-bottom: 1px solid var(--border-default);
    }

    tbody tr:hover {
      background-color: var(--surface-hover);
      td {
        background-color: var(--surface-hover);
      }
    }

    tbody > tr > td:nth-child(1),
    thead > tr > th:nth-child(1) {
      justify-content: flex-start;
    }

    ${({ $isSidebarExpanded }) =>
      $isSidebarExpanded &&
      css`
        tr td:first-child,
        th:first-child {
          border-right: 1px solid var(--border-default);
        }
      `}

    ${({ $estimatedColumnIndices }) =>
      $estimatedColumnIndices?.map(
        index => css`
          th:nth-child(${index}),
          td:nth-child(${index}) {
            background-color: var(--background-table-zebra);
          }
        `
      )}
  }
`;

export const MetricColumnTitleLabelWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

export const NumericalValue = styled.span`
  font-family: var(--font-roboto-mono);
`;

export const RowLabelWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const RowLabel = styled.p<{ $indentation?: number }>`
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: ${({ $indentation }) =>
    $indentation && `${$indentation * 20}px`};
`;

export const BarChartWrapper = styled.div`
  width: auto;
  cursor: pointer;
`;
