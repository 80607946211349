import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isPathMatches } from '~/components/sidebar/utils/navigation-utils';
import { useChatInputHandlers } from '~/hooks/use-chat-input-handlers/useChatInputHandlers';
import { useChatInputNavigate } from '~/hooks/use-chat-input-navigate/useChatInputNavigate';
import { useGetDocumentContextCounts } from '~/hooks/use-get-document-context-counts/useGetDocumentContextCounts';
import { Source } from '~/stores/use-chat/use-chat.types';
import { useChat } from '~/stores/use-chat/useChat';
import { useDocumentSourcesTableStore } from '~/views/intel/document-sources-view/document-sources-table/use-document-sources-table-store/useDocumentSourcesTableStore';

import {
  ChatInputComposer,
  ChatInputComposerProps,
} from '../chat-input-composer/ChatInputComposer';
import { ChatInputConfig } from '../types';
import { showContextHeader } from '../utils/utils';

export interface ChatInputProps
  extends Pick<ChatInputComposerProps, 'mode' | 'setMode'> {
  submitCallback?: (message: string) => void;
  chatContext?: {
    target: Source;
    documents?: string[];
  };
  config?: ChatInputConfig;
}

export const ChatQuickAnalysis = ({
  submitCallback,
  chatContext,
  config,
  ...rest
}: ChatInputProps) => {
  const [params] = useSearchParams();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const chatInputNavigation = useChatInputNavigate();
  const setCustomContextCounts = useDocumentSourcesTableStore(
    state => state.setCustomContextCounts
  );
  const { isMessagePending, messages, setShowContextHeader } = useChat(
    state => ({
      isMessagePending: state.isMessagePending,
      messages: state.messages,
      setShowContextHeader: state.setShowContextHeader,
    })
  );

  const isAlfredPage = isPathMatches(['alfred']);
  const isLoading = isAlfredPage && isMessagePending;
  const handlers = useChatInputHandlers({
    config,
    chatContext,
    submitCallback,
    isLoading,
  });

  const { documentsCount, companiesCount } = useGetDocumentContextCounts({
    params,
    messages,
  });
  const shouldShowContextHeader = showContextHeader({
    messages,
    searchParams: params,
    disableContextHeader: config?.disableContextHeader,
    documentsCount,
    companiesCount,
  });

  useEffect(() => {
    setShowContextHeader(shouldShowContextHeader);
  }, [shouldShowContextHeader]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    chatInputNavigation(lastMessage);
  }, [messages, chatInputNavigation]);

  useEffect(() => {
    if (!isMessagePending) {
      setCustomContextCounts();
    }
  }, [isMessagePending]);

  return (
    <ChatInputComposer
      ref={inputRef}
      handlers={handlers}
      config={{
        ...config,
        isLoading,
      }}
      contextCounts={{ documentsCount, companiesCount }}
      {...rest}
    />
  );
};
