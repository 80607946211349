import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ErrorState.styles';

export interface ErrorStateProps {
  handleReload: () => void;
}

export const ErrorState = ({ handleReload }: ErrorStateProps) => {
  const { t } = useTranslation(['calendar']);

  return (
    <S.StyledBaseErrorView
      buttonProps={{
        label: t('calendar:errorState.reload'),
        onClick: handleReload,
      }}
    />
  );
};
