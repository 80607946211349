import { useSnakesByNames } from '~/hooks/use-snake/useSnake';

export interface UseValuationSnakeDataProps {
  snakes: { ttm: string[]; ntm: string[] };
  companies: {
    tag: string;
    formattedTicker: string;
    color: string;
  }[];
  enabled: boolean;
}

export const useValuationSnakeData = ({
  snakes,
  companies,
  enabled,
}: UseValuationSnakeDataProps) => {
  const snakesTTM = snakes.ttm.flatMap(snakeName =>
    companies.map(company => ({
      snakeName: `${company.tag}.${snakeName}`,
      nDays: 1,
    }))
  );

  const snakesNTM = snakes.ntm.flatMap(snakeName =>
    companies.map(company => ({
      snakeName: `${company.tag}.${snakeName}`,
      nDays: 1,
    }))
  );

  const { data: snakesDataTTM, isLoading: isLoadingTTM } = useSnakesByNames(
    snakesTTM,
    enabled
  );
  const { data: snakesDataNTM, isLoading: isLoadingNTM } = useSnakesByNames(
    snakesNTM,
    enabled
  );

  return {
    isLoading: isLoadingTTM || isLoadingNTM,
    data: {
      ttm: snakesDataTTM,
      ntm: snakesDataNTM,
    },
  };
};
