import styled from 'styled-components';

import { underlineTextStyles } from '~/components/tooltip-container/TooltipContainer.styles';

export const Root = styled.div`
  width: auto;
  word-wrap: break-word;
  overflow: visible;
  white-space: normal;
  font-family: var(--font-nort);
`;

export const StyledSpan = styled.span`
  ${underlineTextStyles};
  position: relative;
  left: 8px;
`;
