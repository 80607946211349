import { SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

export const NewsThumbnailSkeletonRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  gap: 8px;

  ${SkeletonLoader.Styled.Wrapper} {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
