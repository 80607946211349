import { v4 } from 'uuid';
import wretch from 'wretch';
import AbortAddon from 'wretch/addons/abort';
import { WretchError } from 'wretch/resolver';

import { useAuthStore } from '~/stores/use-auth/store/authStore';

import { parseStreamChunk } from './parse-stream-chunk/parseStreamChunk';

export enum ApiStreamStatus {
  Success = 'success',
  Error = 'error',
  Abort = 'abort',
}

export type ApiStreamProps<T> = {
  body: object;
  onReadChunk: (chunks: T[]) => void | Promise<void>;
  onComplete?: () => void | Promise<void>;
  onError?: (error?: WretchError) => void;
  onAbort: () => void;
  headers?: (headers: Headers) => void;
  controller: AbortController;
  endpoint: string;
};

export const apiStream = async <T>({
  body,
  onReadChunk,
  onComplete,
  onError,
  headers,
  controller,
  onAbort,
  endpoint,
}: ApiStreamProps<T>) => {
  const { getState } = useAuthStore;
  const { token } = getState();

  try {
    await wretch(endpoint)
      .options({ mode: 'cors' })
      .auth(`Bearer ${token}`)
      .headers({ 'X-Request-ID': v4() })
      .addon(AbortAddon())
      .signal(controller)
      .post(body)
      .onAbort(onAbort)
      .res(async response => {
        try {
          if (!response.ok || !response.body) {
            throw new Error();
          }

          headers?.(response.headers);

          const reader = response.body.getReader();
          let chunkToParse = '';

          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              await onComplete?.();
              break;
            }

            const decodedValue = new TextDecoder('utf-8').decode(value);
            chunkToParse += decodedValue;

            const chunks = parseStreamChunk<T>(chunkToParse);

            if (chunks.length) {
              await onReadChunk(chunks);
              chunkToParse = '';
            }
          }
        } catch (error) {
          if (error instanceof Error && error.name === 'AbortError') {
            onAbort();
          }

          if (error instanceof Error && error.name !== 'AbortError') {
            onError?.();
          }
        }
      });
  } catch (error) {
    onError?.(error as WretchError);
  }
};
