import React, { ReactNode, useEffect, useState } from 'react';

import * as S from './ButtonGroup.styles';

export interface ButtonGroupProps {
  labels: ReactNode[];
  activeIndex?: number;
  onClick?: (index: number) => void;
  customActiveBackgroundColor?: string;
}

export const ButtonGroup = ({
  labels,
  activeIndex = 0,
  onClick,
  customActiveBackgroundColor,
  ...rest
}: ButtonGroupProps) => {
  const [index, setIndex] = useState(activeIndex);

  useEffect(() => {
    setIndex(activeIndex);
  }, [activeIndex]);

  const clickHandler = (clickIndex: number) => () => {
    setIndex(clickIndex);
    onClick?.(clickIndex);
  };

  return (
    <S.ButtonGroupRoot role="group" {...rest}>
      {labels.map((label, _index) => (
        <S.ButtonGroupItem
          key={_index}
          type="button"
          data-testid={`${label}-button-group-item`}
          $isActive={index === _index}
          onClick={clickHandler(_index)}
          $customActiveBackgroundColor={customActiveBackgroundColor}
        >
          {label}
        </S.ButtonGroupItem>
      ))}
    </S.ButtonGroupRoot>
  );
};

ButtonGroup.Styles = S;
