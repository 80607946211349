import { Drawer } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  ${Drawer.Styled.Body} {
    height: 100%;
  }
  ${Drawer.Styled.Container} {
    width: 512px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  gap: 32px;
`;
