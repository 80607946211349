import {
  Icon,
  Tooltip,
  TooltipTrigger,
  WithNAFallback,
} from '@toggle/design-system';
import { formatPercentage, getPriceStatus } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './PercentageCell.styles';

export interface PercentageCellProps {
  value?: number;
  suffix?: '%' | 'bps';
  showStarIcon?: boolean;
}

export const PercentageCell = ({
  value,
  suffix = '%',
  showStarIcon,
}: PercentageCellProps) => {
  const { t } = useTranslation('scenario');

  return (
    <>
      {showStarIcon && (
        <Tooltip
          label={
            <S.NonNumericText>
              {t('scenario:table.tooltips.starIcon')}
            </S.NonNumericText>
          }
          trigger={TooltipTrigger.Hover}
        >
          <span>
            <Icon
              iconName="StarFill"
              fillColor="var(--icon-primary)"
              size={12}
            />
          </span>
        </Tooltip>
      )}

      <S.ColoredValue $status={getPriceStatus(value)}>
        <WithNAFallback
          value={
            typeof value === 'number' && formatPercentage(value, { suffix })
          }
        />
      </S.ColoredValue>
    </>
  );
};
