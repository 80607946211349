import { z } from 'zod';

export const economicEventInputSchema = z.object({
  id: z.string(),
  date: z.string(), // ISO 8601 date string
  country: z.string(),
  name: z.string(),
  period: z.string(),
  category: z.string(),
  period_year: z.number(),
  actual: z
    .number()
    .nullish()
    .transform(x => x ?? undefined),
  actual_unit: z
    .string()
    .nullish()
    .transform(x => x ?? undefined),
  consensus: z
    .number()
    .nullish()
    .transform(x => x ?? undefined),
  consensus_unit: z
    .string()
    .nullish()
    .transform(x => x ?? undefined),
  description: z.string().optional(),
  prior: z
    .number()
    .nullish()
    .transform(x => x ?? undefined),
  prior_unit: z
    .string()
    .nullish()
    .transform(x => x ?? undefined),
  surprise: z
    .number()
    .nullish()
    .transform(x => x ?? undefined),
  surprise_unit: z
    .string()
    .nullish()
    .transform(x => x ?? undefined),
  reported: z.boolean(),
  snakes: z
    .object({
      event: z.string().optional(),
      actual: z.string().optional(),
      consensus: z.string().optional(),
      surprise: z.string().optional(),
    })
    .optional(),
});

export const economicEventOutputSchema = z.object({
  id: z.string(),
  date: z.string(), // ISO 8601 date string
  country: z.string(),
  name: z.string(),
  period: z.string(),
  category: z.string(),
  period_year: z.number(),
  actual: z.number().optional(),
  actual_unit: z.string().optional(),
  consensus: z.number().optional(),
  consensus_unit: z.string().optional(),
  description: z.string().optional(),
  prior: z.number().optional(),
  prior_unit: z.string().optional(),
  surprise: z.number().optional(),
  surprise_unit: z.string().optional(),
  reported: z.boolean(),
  snakes: z
    .object({
      event: z.string().optional(),
      actual: z.string().optional(),
      consensus: z.string().optional(),
      surprise: z.string().optional(),
    })
    .optional(),
});

export type EconomicEventInput = z.input<typeof economicEventInputSchema>;
export type EconomicEventOutput = z.output<typeof economicEventOutputSchema>;

export const economicsResponseInputSchema = z.object({
  economics: z.array(economicEventInputSchema),
});
export const economicsResponseOutputSchema = z.object({
  economics: z.array(economicEventOutputSchema),
});

export type EconomicsResponseInput = z.input<
  typeof economicsResponseInputSchema
>;
export type EconomicsResponseOutput = z.output<
  typeof economicsResponseOutputSchema
>;
