import { DocumentItem, documentSchema } from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const documentsEndpoints = {
  getDocument: (name: string) => `legal-documents/v1/doc/${name}`,
  consentToDoc: (docId: string) => `legal-documents/v1/consent/${docId}`,
  pendingConsentDoc: `legal-documents/v1/consent?pending=true`,
  latestDocs: `legal-documents/v1/latest`,
};

export const getDocumentsPendingConsent = () => {
  return apiFetch<DocumentItem[]>(documentsEndpoints.pendingConsentDoc, {
    schema: documentSchema,
  });
};

export const putDocumentConsent = (id: string) => {
  return apiFetch(documentsEndpoints.consentToDoc(id), {
    method: 'put',
    body: {
      accepted: true,
    },
  });
};

export const getSignUpDocuments = (signal?: AbortSignal) => {
  return apiFetch(documentsEndpoints.latestDocs, {
    schema: documentSchema,
    options: { signal },
  });
};
