import {
  ChartAssetData,
  chartResamples,
  Domain,
  DomainChangeEvent,
  isIntradayResample,
  PaneData,
  RangeHorizon,
  RESAMPLE_DEFAULT,
  ResampleIntervals,
  TimeSeriesItem,
} from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import { differenceInCalendarMonths } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

import {
  updateAllPanes,
  updateChartPane,
} from '../../utils/chart-utils/chart-utils';
import {
  allowedResamplesForHorizon,
  createNewResampleData,
  getResampleCacheKey,
  getResampleForHorizon,
  shouldDisable1DHorizon,
} from '../../utils/resample-utils/resample-utils';
import { ChartState } from '../use-chart-state/useChartState';
import { GetTimeSeriesProps } from '../use-turbo-chart/useTurboChart';

export interface UseResampleProps {
  chartData: ChartAssetData[];
  primaryAsset?: ChartAssetData;
  hasSinglePriceEntity: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  chartState: ChartState;
  handleEmptyChart: (text: string) => void;
  updateChartState: (partial: Partial<ChartState>) => void;
  getTimeSeries: (
    params: GetTimeSeriesProps
  ) => Promise<TimeSeriesItem[] | undefined>;
}

export type UseResampleReturn = ReturnType<typeof useResample>;

// eslint-disable-next-line max-lines-per-function
export const useResample = ({
  chartState,
  chartData,
  hasSinglePriceEntity,
  setHasError,
  handleEmptyChart,
  updateChartState,
  getTimeSeries,
  primaryAsset,
  setIsLoading,
}: UseResampleProps) => {
  const [isIntradayEnabled, setIsIntradayEnabled] = useState(false);
  const [emptyResamples, setEmptyResamples] = useState<ResampleIntervals[]>([]);
  const resamplesData = useRef<
    Record<ReturnType<typeof getResampleCacheKey>, ChartAssetData>
  >({});
  const emptyAssetRef = useRef<Entity>(undefined);

  const {
    chartPanes,
    horizon: selectedRange,
    resample: selectedResample,
  } = chartState;
  const disable1DHorizon = shouldDisable1DHorizon({
    chartData,
    emptyResamples,
    resample: selectedResample,
    hasSinglePriceEntity,
  });
  const enabledResamples = chartResamples.filter(
    item =>
      !emptyResamples.includes(item) &&
      (isIntradayResample(item) ? isIntradayEnabled : true)
  );

  const getCachedResampleData = (
    chartData: ChartAssetData[],
    resample: ResampleIntervals
  ) =>
    chartData.map(item => ({
      ...resamplesData.current[getResampleCacheKey(item, resample)],
      //handle case when cache value has a different color/isHidden/seriesType from the current one in chartData
      lineColorToken: item.lineColorToken,
      isHidden: item.isHidden,
      seriesType: item.seriesType,
    }));

  const updateChartData = (
    chartPanes: PaneData[],
    resample = selectedResample
  ) => {
    chartPanes.forEach(pane => {
      updateResampleCache(pane.chartAssetData, resample);
    });
  };

  const updateResampleCache = (
    data: ChartAssetData[],
    resample = selectedResample
  ) => {
    data.forEach(item => {
      resamplesData.current = {
        ...resamplesData.current,
        [getResampleCacheKey(item, resample)]: item,
      };
    });
  };

  const enableIntradayResamples = (
    domain: Domain,
    chartData: ChartAssetData
  ) => {
    const ts = chartData.ts;
    const lastItem = ts[ts.length - 1];
    const datesDomain: [Date, Date] = [
      new Date(ts[domain[0]].time),
      new Date(ts[domain[1]].time),
    ];
    const showIntraday =
      differenceInCalendarMonths(new Date(lastItem.time), datesDomain[0]) <= 1;
    setIsIntradayEnabled(showIntraday);
  };

  //useCallback is used as this function will be used as a deps in useEffect
  const onDomainChange = useCallback(
    (event: Event) => {
      const { domain, roundedDomain, isSameDomain } = (
        event as DomainChangeEvent
      ).detail;
      updateChartState({
        ...(!isSameDomain && { horizon: null }),
        domain,
      });

      if (!disable1DHorizon && primaryAsset) {
        enableIntradayResamples(roundedDomain, primaryAsset);
      }
    },
    [emptyResamples, chartState, primaryAsset]
  );

  const changeRange = async (range: RangeHorizon) => {
    const isSameSelected = selectedRange && selectedRange === range;

    if (isSameSelected) {
      return;
    }

    const resample = getResampleForHorizon({
      selectedResample,
      hasSinglePriceEntity,
      range,
      emptyResamples,
    });

    if (resample === null) {
      return;
    }

    changeResample(resample, range);
  };

  const changeResample = async (
    resample: ResampleIntervals,
    range = selectedRange
  ) => {
    const data = await fetchResampleIfNeeded(chartData, resample);
    const isSameData = data === chartData;

    if (emptyAssetRef.current) {
      const isResampleAllowed =
        !range || allowedResamplesForHorizon[range].includes(selectedResample);
      const nextResample = isResampleAllowed
        ? selectedResample
        : RESAMPLE_DEFAULT;
      handleEmptyChart(`${resample} ${emptyAssetRef.current.name}`);
      setEmptyResamples([...emptyResamples, resample]);

      if (!isResampleAllowed && range === RangeHorizon.OneDay) {
        return;
      }

      const newChartPanes = updateChartPane(
        chartPanes,
        getCachedResampleData(chartData, nextResample)
      );
      updateChartState({
        resample: selectedResample,
        horizon: range,
        chartPanes: newChartPanes,
      });
    } else if (!isSameData) {
      const newChartPanes = updateAllPanes(data, chartPanes);
      updateChartData(newChartPanes, resample);
      updateChartState({
        chartPanes: newChartPanes,
        resample,
        horizon: range === selectedRange ? null : range,
      });
    }

    setIsLoading(false);
  };

  const fetchResampleIfNeeded = async (
    chartData: ChartAssetData[],
    resample: ResampleIntervals
  ) => {
    const unCachedChartData = chartData.filter(
      item => !resamplesData.current[getResampleCacheKey(item, resample)]
    );

    if (unCachedChartData.length) {
      try {
        setHasError(false);
        const prices = await Promise.all(
          unCachedChartData.map(c =>
            getTimeSeries({
              latestAsset: c.entity,
              resolution: resample,
            })
          )
        );

        const emptyAssetIndex = prices.findIndex(item => !item?.length);
        const emptyAsset = unCachedChartData[emptyAssetIndex];
        const data = emptyAsset
          ? chartData
          : createNewResampleData({
              unCachedChartData,
              chartData,
              resample,
              prices: prices as TimeSeriesItem[][],
              resamplesData: resamplesData.current,
            });
        emptyAssetRef.current = emptyAsset?.entity;
        return data;
      } catch {
        emptyAssetRef.current = undefined;
        setHasError(true);
        return chartData;
      }
    }

    emptyAssetRef.current = undefined;
    return getCachedResampleData(chartData, resample);
  };

  return {
    selectedRange,
    changeRange,
    updateChartData,
    onDomainChange,
    disable1DHorizon,
    resample: {
      resamplesData,
      selectedResample,
      enabledResamples,
      emptyResamples,
      changeResample,
      fetchResampleIfNeeded,
      setEmptyResamples,
    },
  };
};
