import { TableCell } from '@toggle/design-system';
import { checkCellValueStyle } from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderRow } from '~/components/price-movement/components/price-movement-table/components/header-row/HeaderRow';
import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';

import { PercentageCell } from '../percentage-cell/PercentageCell';
import * as S from './PredictionSummaryTable.styles';

export interface PredictionSummaryTableProps {
  data: Record<string, PredictionRow>;
  isMedian?: boolean;
  horizons: string[];
  isFixedIncome: boolean;
}

export type PredictionRow = Record<string, number>;

export const PredictionSummaryTable = ({
  data,
  isMedian,
  horizons,
  isFixedIncome,
}: PredictionSummaryTableProps) => {
  const { t } = useTranslation('scenario');

  const headerLabels = horizons.map(horizon => {
    /* i18next-extract-mark-context-next-line  ["1w", "2w", "1m", "3m", "6m"] */
    return t('scenario:drawer.summaryTable.horizon', {
      context: horizon,
    });
  });

  const rowsIds = ['max', 'p80', isMedian ? 'median' : 'mean', 'p20', 'min'];

  const renderTableBody = () =>
    rowsIds.map(rowId => {
      const horizonCells = horizons.map(horizon => {
        const mappedPrediction = data[horizon];
        const value = mappedPrediction?.[rowId];

        return (
          <S.NumericalTableCell
            key={`body-${rowId}-${horizon}`}
            alignment="center"
            $numericalStyle={checkCellValueStyle(value)}
          >
            <PercentageCell
              suffix={isFixedIncome ? 'bps' : '%'}
              value={value}
            />
          </S.NumericalTableCell>
        );
      });

      horizonCells.unshift(
        <TableCell key={`body-${rowId}`} alignment="start" isNumber={false}>
          {
            <TooltipContainer
              showUnderline
              label={t(`scenario:drawer.summaryTable.tooltip`, {
                context: rowId,
              })}
            >
              {
                <span>
                  {
                    /* i18next-extract-mark-context-next-line  ["max", "p80", "median", "mean", "p20", "min"] */

                    t(`scenario:drawer.summaryTable.row`, {
                      context: rowId,
                    })
                  }
                </span>
              }
            </TooltipContainer>
          }
        </TableCell>
      );
      return (
        <tr key={rowId} data-testid={rowId}>
          {horizonCells}
        </tr>
      );
    });

  return (
    <S.PredictionSummaryTableRoot data-testid="prediction-summary-table">
      <S.TableStyled
        withBodyRowHover={false}
        TableHeaderContent={<HeaderRow labels={['', ...headerLabels]} />}
        TableBodyContent={renderTableBody()}
      />
    </S.PredictionSummaryTableRoot>
  );
};
