import {
  citation,
  CitationRequest,
  CitationResponse,
  DocumentGroupRequest,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

export const getCitations = (
  groupId: string,
  body: CitationRequest,
  signal?: AbortSignal
) => {
  const { path, schema } = citation.citationsFiltered;
  return wretchRequest<CitationResponse>(path(groupId), {
    method: 'post',
    schema,
    body,
    signal,
  });
};

export const getDocumentGroup = (
  groupId: string,
  body: DocumentGroupRequest,
  signal?: AbortSignal
) => {
  const { path, schema } = citation.citationDocumentGroup;
  return wretchRequest(path(groupId), {
    schema,
    method: 'post',
    body,
    signal,
  });
};
