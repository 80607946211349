import { KeyDevsObject } from '@toggle/toggle';
import React from 'react';

import * as S from './CompanyEventDrawerContent.styles';

export interface CompanyEventDrawerContentProps {
  data: KeyDevsObject;
}

export const CompanyEventDrawerContent = ({
  data,
}: CompanyEventDrawerContentProps) => {
  const { event_type, headline, situation } = data;
  return (
    <S.Container>
      <S.EventTypeWrapper>{event_type}</S.EventTypeWrapper>
      <S.HeadlineWrapper>{headline}</S.HeadlineWrapper>
      {situation && (
        <S.SituationWrapper data-testid="situation-label">
          {situation}
        </S.SituationWrapper>
      )}
    </S.Container>
  );
};
