import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SplashLoader } from '~/components/splash-loader/SplashLoader';

const Loader = styled(SplashLoader)`
  width: 220px;
`;

export const DocumentMessageLoader = () => {
  const { t } = useTranslation('chat');

  const keys = ['sources', 'searching', 'summarizing'];
  const labels = keys.map(key =>
    /* i18next-extract-mark-context-next-line  ["sources", "searching", "summarizing"] */
    t('chat:documentLoader', { context: key })
  );

  return <Loader labels={labels} duration={4_000} />;
};
