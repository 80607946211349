import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const AssetOverviewRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ScrollContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${customScrollMinimal}
`;
