import {
  customScrollMinimal,
  Input,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 12px 20px;
`;

export const StyledModal = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: 95%;
    max-width: 800px;
    height: 100%;
    max-height: 572px;
  }

  ${Modal.Styled.TextContainer} {
    margin: 18px 0 0 0;
  }

  ${Modal.Styled.Header} {
    padding: 6px 6px 24px 20px;
  }
`;

export const NodesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${customScrollMinimal};
  overflow-y: auto;
  height: 300px;

  mark {
    color: var(--text-primary);
    background-color: transparent;
  }
`;

export const Search = styled(Input)`
  ${Input.Styles.InputWrapper} {
    border: 1px solid var(--border-default);
    padding: 8px 12px;
    width: 100%;
    border-radius: 8px;
    background-color: transparent;
  }
`;

export const FooterButtons = styled.div`
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const ClearButton = styled.div`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  cursor: pointer;

  &:hover {
    color: var(--text-default);
  }
`;

export const NoResultsLabel = styled.span`
  color: var(--text-soft);
`;
