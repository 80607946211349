import { Condition } from '@toggle/toggle';
import React, { FC, useMemo, useState } from 'react';

import { useFilteredTimeSeries } from '~/hooks/use-timeseries/useTimeSeries';

import { ConditionSearch } from '../condition-search/ConditionSearch';
import * as S from './ConditionDataList.styles';

export interface ConditionDataListProps {
  condition: Condition;
  response: (editedPayload: Partial<Condition>) => void;
}

interface Item {
  value: string;
  label: string;
}

export const ConditionDataList: FC<ConditionDataListProps> = ({
  condition,
  response,
}) => {
  const { data: snakeItems } = useFilteredTimeSeries([condition.entity]);
  const [items, setItems] = useState<Item[]>([]);

  const data = useMemo(
    () =>
      snakeItems
        ?.filter(({ search: { rank } }) => rank !== -1)
        .sort((a, b) => b.search.rank - a.search.rank)
        ?.map(i => ({
          label: i.name.english,
          value: i.snake,
        })) ?? [],
    [snakeItems]
  );

  const searchItems = (query: string) => {
    if (query.length > 1) {
      setItems(
        data.filter(d => d.label.toLowerCase().includes(query.toLowerCase()))
      );
    }
  };

  const handleSelect = (selectedItem: Item) => {
    const editedPayload = {
      snake: selectedItem.value,
      snakeName: selectedItem.label,
    };
    response(editedPayload);
  };

  return (
    <ConditionSearch
      label={condition.snakeName}
      dataTestId="scenario-data-list"
      onChange={e => searchItems(e.target.value)}
    >
      {items.map(item => (
        <S.Item
          key={item.value}
          data-testid={`condition-data-${item.value}`}
          onSelect={() => handleSelect(item)}
        >
          {item.label}
        </S.Item>
      ))}
    </ConditionSearch>
  );
};
