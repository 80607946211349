import { Button } from '@toggle/design-system';
import { formatPriceWithCurrency } from '@toggle/helpers';
import { AssetClassCode, periods } from '@toggle/toggle';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionSection } from '~/components/condition-section/ConditionSection';
import { PriceMovementChart } from '~/components/price-movement/components/price-movement-chart/PriceMovementChart';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { EntityHeader } from '~/modules/entity-header/EntityHeader';
import {
  filterPredictions,
  formatUtcDate,
  getInsightCondition,
  getInsightTotalTradingDays,
} from '~/services/insights/utils/insight-utils';
import { InsightWidgetData } from '~/stores/use-chat/assistant-response.type';
import { getPredictionsTableData } from '~/views/turbo-chart/components/insights/utils/inisights-utils/insights-utils';
import { AssessmentWidget } from '~/widgets/assessment-widget/AssessmentWidget';
import { InsightPastEpisodes } from '~/widgets/past-episodes-widget/components/insight-past-episodes/InsightPastEpisodes';
import { PredictionSummaryTable } from '~/widgets/scenario/components/prediction-summary-table/PredictionSummaryTable';

import { InsightSummary } from '../../../insight-summary/InsightSummary';
import { InsightsReaction } from '../insights-reaction/InsightsReaction';
import * as S from './InsightsDrawerContent.styles';

const IDX_MAX = 126;
const ACCORDION_ANIM_DURATION = 200;

export type InsightsDrawerContentProps = {
  insightsData: InsightWidgetData;
  header?: () => React.JSX.Element;
};

export const InsightsDrawerContent = ({
  insightsData,
  header,
}: InsightsDrawerContentProps) => {
  const { t } = useTranslation(['scenarioCommander', 'widget']);
  const { insight, predictions, entity, snakeTs } = insightsData;

  const date = formatUtcDate(
    insight.metadata.last_value_date ?? insight.created_at,
    'PPP'
  );

  // Temp: update to navigate from watchlist when available
  const { navigateToPage } = useNavigateToAssetOverview();

  const navigateToAssetOverview = () => {
    navigateToPage({ entityTag: entity.tag });
  };

  const entityName = entity.name;
  const isFixedIncome =
    entity.asset_class === AssetClassCode.Fi && entity.class === 6;

  const episodeCount = insight.episodes.length;
  const totalTradingDays = getInsightTotalTradingDays(insight);
  const condition = getInsightCondition(insight, t);

  const horizons = periods;
  const predictionByHorizon = useMemo(() => {
    return getPredictionsTableData(horizons, predictions);
  }, [predictions, horizons]);

  return (
    <S.Container>
      {header ? (
        header()
      ) : (
        <EntityHeader
          entity={entity}
          truncateLongTitle
          headerButton={
            <Button
              iconName="Expand"
              label={t('widget:assetOverview.assetOverview')}
              onClick={navigateToAssetOverview}
              variant="tertiary"
              size="small"
            />
          }
        />
      )}
      <InsightSummary insight={insight} isFixedIncome={isFixedIncome} />
      <S.ValueInfo>
        {t('widget:insights:analysisBased', {
          price: formatPriceWithCurrency(insight.metadata.last_value),
          date: date,
        })}
      </S.ValueInfo>
      <ConditionSection
        condition={condition}
        episodesCount={episodeCount}
        totalTradingDays={totalTradingDays}
      />
      <InsightsReaction
        entityName={entityName}
        episodeCount={episodeCount}
        isFixedIncome={isFixedIncome}
      />
      <PriceMovementChart
        prediction={filterPredictions(insightsData, IDX_MAX).predictions}
        snakeTs={snakeTs}
        horizonToIndexOffset={1}
        bestHorizon={insight.metadata.horizon}
        isFixedIncome={isFixedIncome}
        useDynamicDaysLabel
      />
      <PredictionSummaryTable
        data={predictionByHorizon}
        isMedian
        horizons={horizons}
        isFixedIncome={isFixedIncome}
      />
      <S.StyledAccordion
        withBackground={false}
        itemClassName="insights-accordion"
        items={[
          {
            id: 'past-episodes',
            title: t('widget:pastEpisode.pastEpisode'),
            content: (
              <InsightPastEpisodes
                insight={insight}
                entity={entity}
                variant={'insight-past-episodes'}
              />
            ),
            expandedByDefault: true,
          },
        ]}
        duration={ACCORDION_ANIM_DURATION}
      />
      <S.StyledAccordion
        withBackground={false}
        itemClassName="insights-accordion"
        items={[
          {
            id: 'scenario-quality',
            title: t('widget:assessment.title'),
            content: (
              <>
                <AssessmentWidget
                  isFixedIncome={isFixedIncome}
                  gaugeValue={insight.metadata.stars}
                  horizon={insight.metadata.horizon}
                  median={insight.metadata.median_return}
                  checklist={insight.metadata.high_conviction_report}
                  hasHeader={false}
                />
                <S.AssessmentPadding />
              </>
            ),
            expandedByDefault: true,
          },
        ]}
        duration={ACCORDION_ANIM_DURATION}
      />
    </S.Container>
  );
};

InsightsDrawerContent.Styled = S;
