import { useEffect, useState } from 'react';

import {
  fetchRelatedCompanies,
  RelatedCompaniesGrouped,
} from '~/services/related-companies/related-companies-service';

export interface UseOntologyProps {
  entityTag: string;
  shouldFetch?: boolean;
}

export const useOntology = ({
  entityTag,
  shouldFetch = true,
}: UseOntologyProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [relatedCompanies, setRelatedCompanies] =
    useState<RelatedCompaniesGrouped>();

  const getRelatedCompanies = async (entityTag: string) => {
    setRelatedCompanies(undefined);
    setIsLoading(true);

    const { competitors, suppliers } = await fetchRelatedCompanies(entityTag);

    setRelatedCompanies(current => ({
      ...current,
      suppliers,
      competitors,
    }));

    setIsLoading(false);
  };

  useEffect(() => {
    if (shouldFetch) {
      getRelatedCompanies(entityTag);
    } else {
      setIsLoading(false);
    }
  }, [entityTag, shouldFetch]);

  return {
    isLoading,
    relatedCompanies,
  };
};
