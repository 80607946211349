import { SearchFilters, SingleNews } from '@toggle/toggle';
import {
  endOfDay,
  endOfToday,
  endOfYesterday,
  format,
  startOfToday,
  startOfYesterday,
} from 'date-fns';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { WatchlistToCheck } from '~/stores/use-news-watchlists/useNewsWatchlistsStore';
import { getUniqueArray } from '~/utils/common/utils';
import { ASSET_FILTERS_KEY } from '~/views/screener/use-screener-store/filter-utils';

export const TEMP_MIN_FETCHING_NEWS_DATE = endOfDay(new Date('2022-06-30'));
export const QUERY_KEY_MOST_READ_NEWS = 'QUERY_KEY_MOST_READ_NEWS';
export const QUERY_KEY_WATCHLIST_NEWS = 'QUERY_KEY_WATCHLIST_NEWS';
export const REFETCH_INTERVAL_DASHBOARD = 10_000;
export const REFETCH_INTERVAL_NEWS_PAGE = 10_000;

export enum TabsType {
  MostRead = 'mostRead',
  Watchlist = 'watchlist',
}

export const formatCurrentTimeHoursMinutes = (timestamp: Date) =>
  format(timestamp, 'HH:mm');

export const formatPillDate = (timestamp: Date) =>
  format(timestamp, 'yyy-LL-dd');

export const formatDateRangeLabel = (date: Date) =>
  format(date, 'MMM dd, yyyy');

export const formatDateRange = (dateRange: [Date, Date]) => {
  const [formattedFrom, formattedTo] = dateRange.map(formatDateRangeLabel);
  return `${formattedFrom} - ${formattedTo}`;
};

export const getUniqueNews = (paginatedNews?: Array<SingleNews[]>) => {
  const newsMap = new Map<string, SingleNews>();

  paginatedNews?.flat().forEach(singleNews => {
    if (!newsMap.has(singleNews.id)) {
      newsMap.set(singleNews.id, singleNews);
    }
  });

  return Array.from(newsMap.values());
};

export const getFiltersFromActiveOptions = (
  activeOptions: ActiveFilterOptions[]
): SearchFilters => {
  const filters: SearchFilters = {};

  activeOptions.forEach(activeOption => {
    if (activeOption.filter === 'market') {
      filters.markets = activeOption.options.map(
        option => option.value.id
      ) as string[];
    }

    if (activeOption.filter === 'providers') {
      filters.providers = activeOption.options.map(
        option => option.value.providers
      ) as string[];
    }

    if (activeOption.filter === ASSET_FILTERS_KEY) {
      const tags = activeOption.options.flatMap(
        option => option.value.tags
      ) as string[];
      filters.entities = getUniqueArray(tags);
    }

    if (activeOption.filter === 'date-picker') {
      const [selectedDateRange] = activeOption.options;

      switch (selectedDateRange.key) {
        case 'today':
          filters.date_range = {
            from: startOfToday().toISOString(),
            to: endOfToday().toISOString(),
          };
          break;
        case 'yesterday':
          filters.date_range = {
            from: startOfYesterday().toISOString(),
            to: endOfYesterday().toISOString(),
          };
          break;

        default:
          filters.date_range = {
            ...selectedDateRange.value,
          };
          break;
      }
    }
  });

  return filters;
};

export const getUniqueEntities = (watchlist: WatchlistToCheck[]) => {
  const entitiesArr = flatMap(
    watchlist.filter(item => item.isChecked).map(item => item.entities)
  );
  return uniq(entitiesArr.map(entity => entity.tag));
};
