import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChatMessagePrompt } from '~/components/chat';
import { RelatedCompaniesGrouped } from '~/services/related-companies/related-companies-service';
import { relatedOptionsData } from '~/utils/related-companies/related-companies-helpers';

import { RelatedAssetsDropdown } from '../related-assets-dropdown/RelatedAssetsDropdown';

export interface CreateWatchlistFromRelatedCompaniesProps {
  relatedCompanies: RelatedCompaniesGrouped;
}

export const CreateWatchlistFromRelatedCompanies = ({
  relatedCompanies,
  ...rest
}: CreateWatchlistFromRelatedCompaniesProps) => {
  const { t } = useTranslation('widget');

  const relatedOptions = relatedOptionsData(relatedCompanies);

  return (
    <ChatMessagePrompt
      title={t('widget:assetOverview.watchlist')}
      message={
        <>
          <span> {t('widget:assetOverview.createWatchlist')}</span>
          <RelatedAssetsDropdown
            relatedAssets={relatedCompanies}
            relatedOptions={relatedOptions}
          />
        </>
      }
      {...rest}
    />
  );
};
