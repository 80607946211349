import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { customScrollMinimal, Icon, typographyV2 } from '@toggle/design-system';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { Search } from '../search/Search';

export const DropdownContent = css`
  display: flex;
  flex-direction: column;
  width: 250px;
  max-height: min(55vh, 550px);
  background-color: var(--surface-default);
  border: 1px solid var(--border-soft);
  border-radius: 4px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  z-index: var(--z-index-modal);
`;

export const DropdownMenuRoot = styled(RadixDropdownMenu.Root)`
  background-color: var(--surface-default);
  width: 161px;
`;

export const TriggerLabel = styled.span`
  ${typographyV2.bodyBase}
  color: var(--text-default);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`;

export const Trigger = styled<
  FC<RadixDropdownMenu.DropdownMenuTriggerProps & { $active?: boolean }>
>(RadixDropdownMenu.Trigger)`
  display: inline-flex;
  gap: 8px;
  flex-shrink: 0;
  background-color: ${props =>
    props.$active ? 'var(--surface-active)' : 'var(--surface-default)'};
  height: 32px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  width: 161px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid var(--border-default);
  cursor: pointer;

  &:hover {
    background: var(--surface-hover);
    ${TriggerLabel} {
      color: var(--text-default);
    }
  }
`;

export const TriggerArrow = styled(Icon)`
  fill: var(--icon-default);
`;

export const DropdownMenuContent = styled(RadixDropdownMenu.Content)`
  ${DropdownContent}
`;

export const StyledFilter = styled(Search)`
  border-bottom: 1px solid var(--border-soft);
`;

export const ScrollableList = styled.div`
  ${customScrollMinimal};
  overflow-y: auto;
  padding: 8px 12px;
  max-height: 250px;
`;
