import { PriceStatus } from '@toggle/helpers';
import styled from 'styled-components';

import { priceChangeColors } from '~/utils/common/utils';

export const PlotPath = styled.path<{ $status: PriceStatus }>`
  stroke: ${({ $status }) => priceChangeColors[$status]};
  stroke-width: 2px;
  fill-opacity: 0;
`;
