import { useNavigate } from 'react-router-dom';

import { appPaths } from '~/routes/app-paths';
import { ChatMode, useChatMode } from '~/stores/use-chat-mode/useChatMode';

export const useNavigateAlfred = () => {
  const navigate = useNavigate();
  const setMode = useChatMode(state => state.setMode);

  const navigateToAlfred = (mode: ChatMode, searchParams?: URLSearchParams) => {
    setMode(mode);
    navigate({
      pathname: appPaths.alfred,
      ...(searchParams && { search: searchParams?.toString() }),
    });
  };

  return navigateToAlfred;
};
