import { useScrollListener } from '@toggle/helpers';
import { DOW_JONES, SingleNews } from '@toggle/toggle';
import { eachDayOfInterval, endOfDay, isToday } from 'date-fns';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useArticleDrawer } from '~/hooks/use-article-drawer/useArticleDrawer';
import { NewsFilter } from '~/services/news/news.types';
import {
  PROVIDERS_FILTER_KEY,
  useNewsFiltersStore,
} from '~/views/news/stores/use-news-filters-store/useNewsFiltersStore';
import { createNewsApiPayload } from '~/views/news/utils/create-news-api-payload/createNewsApiPayload';
import { ArticleDrawer } from '~/widgets/news-widget/ArticleDrawer';

import {
  NewsHighlights,
  useFetchInfiniteNewsList,
} from '../../hooks/use-fetch-infinite-news/useFetchInfiniteNewsList';
import { useListScroll } from '../../hooks/use-list-scroll/useListScroll';
import { formatPillDate, getUniqueNews } from '../../utils/utils';
import { MoreUnreadNewsPill } from '../more-unread-news-pill/MoreUnreadNewsPill';
import { NewsGroup } from '../news-group/NewsGroup';
import { SCROLLING_PADDING } from '../news-group/NewsGroup.styles';
import { NewsLoadingState } from '../news-loading-state/NewsLoadingState';
import { SearchEmptyStates } from '../search-empty-states/SearchEmptyStates';
import * as S from './NewsList.styles';

const groupNewsByDate = (newsList: SingleNews[]) => {
  return newsList.reduce<Record<string, SingleNews[]>>(
    (accumulator, currentNews) => {
      const publishedDate = formatPillDate(new Date(currentNews.published));

      if (!accumulator[publishedDate]) {
        accumulator[publishedDate] = [];
      }

      accumulator[publishedDate].push(currentNews);

      return accumulator;
    },
    {}
  );
};

export interface NewsListProps {
  filters: NewsFilter[];
}

export const NewsList: FC<NewsListProps> = ({ filters }) => {
  const { t } = useTranslation('news');
  const [isShowToTopPill, setIsShowToTopPill] = useState(false);

  const [refetchTimestamp] = useState(new Date());
  const [prevRefetchTimestamp] = useState(refetchTimestamp);

  const { singleNews, setSingleNews, closeArticleDrawer } = useArticleDrawer();

  const {
    isMostReadSelected,
    dateRangeEnd,
    handleSetDateRange,
    resetDateRange,
    resetAll,
  } = useNewsFiltersStore();

  const genHighlights = () => {
    const result = [];

    if (isMostReadSelected) {
      result.push(NewsHighlights.MostRead);
    }

    return result;
  };

  const { newsData, isFetching, hasNextPage, fetchNextPage, refetch } =
    useFetchInfiniteNewsList({
      dateRangeEnd,
      highlights: genHighlights(),
      newsFilters: createNewsApiPayload(filters),
    });

  const activeNewsList = useMemo(() => {
    if (newsData?.pages) {
      return getUniqueNews(newsData?.pages);
    }

    return [];
  }, [newsData]);

  const activeNewsListObj = useMemo(() => {
    if (activeNewsList) {
      return groupNewsByDate(activeNewsList);
    }

    return {};
  }, [activeNewsList]);
  const newsTimeArr = useMemo(() => {
    if (activeNewsList.length) {
      const firstDate = new Date(activeNewsList[0].published);
      const lastDate = new Date(
        activeNewsList[activeNewsList.length - 1].published
      );

      const [start, end] =
        firstDate < lastDate ? [firstDate, lastDate] : [lastDate, firstDate];

      return eachDayOfInterval({
        start,
        end,
      })
        .map(date => date.toISOString())
        .reverse();
    }

    return [];
  }, [activeNewsList]);

  const handleSelectSpecificDate = (fromTime: string) => {
    const fromTimeDate = new Date(fromTime);

    if (isToday(fromTimeDate)) {
      resetDateRange();
    } else {
      handleSetDateRange(endOfDay(fromTimeDate).toISOString());
    }

    refetch();
  };

  useEffect(() => {
    const newsListDom = newsListRef.current;

    const handleScroll = (e: Event) => {
      if (!activeNewsList.length) {
        return;
      }

      const scrollTop = (e.currentTarget as HTMLDivElement).scrollTop;
      const prevRefetchTime = prevRefetchTimestamp.getTime();
      const latestNewsTime = new Date(activeNewsList[0].published).getTime();

      setIsShowToTopPill(scrollTop > 0 && latestNewsTime > prevRefetchTime);
    };

    if (newsListDom) {
      newsListDom.addEventListener('scroll', handleScroll);
    }

    return () => {
      newsListDom?.removeEventListener('scroll', handleScroll);
    };
  }, [activeNewsList, prevRefetchTimestamp]);

  const handleUnreadPillClick = () => {
    scrollToPosition();
    resetDateRange();
  };

  const newsListRef = useRef<HTMLDivElement>(null);
  const { scrollToPosition, handleDatePillChange } = useListScroll({
    newsListRef,
    activeNewsList,
    handleSelectSpecificDate,
  });

  const isFilteredDjOnly = useMemo(() => {
    const checkedProviderOptions = filters
      .find(filter => filter.key === PROVIDERS_FILTER_KEY)
      ?.options.filter(option => option.isChecked);
    return (
      checkedProviderOptions?.length === 1 &&
      checkedProviderOptions[0].key === DOW_JONES
    );
  }, [filters]);

  useScrollListener(
    newsListRef,
    () => {
      if (hasNextPage) {
        fetchNextPage();
      }

      return hasNextPage;
    },
    [hasNextPage],
    { isGlobalScrollListened: false, offset: SCROLLING_PADDING }
  );

  return (
    <>
      {isShowToTopPill && (
        <MoreUnreadNewsPill onClick={handleUnreadPillClick} />
      )}
      <S.ContentBodyWrapper ref={newsListRef} data-testid="news-list">
        {!isFetching && !activeNewsList.length ? (
          <S.NoNewsFoundWrapper>
            <SearchEmptyStates
              buttonProps={{
                onClick: resetAll,
                label: t('news:resetAllFilters'),
              }}
            />
          </S.NoNewsFoundWrapper>
        ) : (
          <S.ContentBody>
            {newsTimeArr.map(time => {
              const list = activeNewsListObj[formatPillDate(new Date(time))];

              return (
                list && (
                  <NewsGroup
                    key={time}
                    list={list}
                    onChangeDate={handleDatePillChange}
                    onNewsClick={setSingleNews}
                    pillTime={time}
                    isShowDJBadge={isFilteredDjOnly}
                  />
                )
              );
            })}
            {isFetching && <NewsLoadingState />}
          </S.ContentBody>
        )}
      </S.ContentBodyWrapper>
      {singleNews && (
        <ArticleDrawer
          newsId={singleNews.id}
          list={activeNewsList}
          onClick={setSingleNews}
          onClose={closeArticleDrawer}
        />
      )}
    </>
  );
};
