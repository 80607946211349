import { Breadcrumbs, Dropdown, Icon } from '@toggle/design-system';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbItem } from '~/stores/use-breadcrumbs/useBreadcrumbs';

import * as S from './ChartBreadcrumbs.styles';

export interface ChartBreadcrumbsProps {
  className?: string;
  data: BreadcrumbItem[];
  renderNode: (item: BreadcrumbItem, disabled?: boolean) => ReactNode;
  renderDropdownListItem: (
    item: BreadcrumbItem,
    isActive: boolean,
    isFocused: boolean
  ) => ReactNode;
  onSelectDropdownItem: (item: BreadcrumbItem) => void;
}

export const ChartBreadcrumbs = ({
  data,
  className,
  renderNode,
  renderDropdownListItem,
  onSelectDropdownItem,
}: ChartBreadcrumbsProps) => {
  const { t } = useTranslation(['chart']);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showTutorial, setShowTutorial] = useState(true);

  useEffect(() => {
    const handler = setTimeout(() => {
      setShowTutorial(false);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  const onClick = () => {
    setShowTutorial(false);
    setIsDropdownOpen(true);
  };

  if (data.length < 2) {
    return null;
  }

  const firstBreadcrumbs = data.slice(0, data.length - 1).reverse();
  const lastBreadcrumb = data[data.length - 1];

  return (
    <S.ChartBreadcrumbsRoot className={className}>
      <Breadcrumbs.ItemStyles.Breadcrumb>
        {showTutorial ? (
          <S.StyledTooltip
            label={
              <S.Tutorial>
                <Icon size={20} viewBox="0 0 20 20" iconName="ShoePrints" />
                {t('chart:tutorial')}
              </S.Tutorial>
            }
            placement="bottom-start"
            isOpen
          >
            <S.Button onClick={onClick} data-testid="tutorial-button">
              <S.StyledIcon
                size={12}
                iconName="Ellipsis"
                data-testid="tutorial-ellipsis-icon"
              />
            </S.Button>
          </S.StyledTooltip>
        ) : (
          <Dropdown
            dropdownItems={firstBreadcrumbs}
            selectItem={onSelectDropdownItem}
            shouldShowItems={isDropdownOpen}
            renderDropdownItem={(item, isActive, isFocused) =>
              renderDropdownListItem(item, isActive, isFocused)
            }
            onItemsShowChange={setIsDropdownOpen}
            placement="bottom-start"
            inPortal
          >
            <S.Button>
              <S.StyledIcon
                size={12}
                iconName="Ellipsis"
                data-testid="ellipsis-icon"
              />
            </S.Button>
          </Dropdown>
        )}
        <S.Separator>/</S.Separator>
      </Breadcrumbs.ItemStyles.Breadcrumb>
      <Breadcrumbs.ItemStyles.Breadcrumb $disabled>
        {renderNode(lastBreadcrumb, true)}
      </Breadcrumbs.ItemStyles.Breadcrumb>
    </S.ChartBreadcrumbsRoot>
  );
};
