import {
  PRICE_HOVER_CUSTOM_EVENT,
  PriceHover,
  PriceHoverEvent,
} from '@toggle/chart';
import { RefObject, useEffect, useState } from 'react';

export interface UseLegendPriceHoverProps {
  elementRef: RefObject<HTMLCanvasElement | Document | null>;
  enabled?: boolean;
}

export const useLegendPriceHover = ({
  elementRef,
  enabled = true,
}: UseLegendPriceHoverProps) => {
  const [hoveredPrice, setHoveredPrice] = useState<PriceHover>();

  useEffect(() => {
    if (!enabled || !elementRef.current) {
      return undefined;
    }

    const element = elementRef.current;

    const onPriceHover = (event: Event) => {
      const priceHover = (event as PriceHoverEvent).detail;
      setHoveredPrice(priceHover);
    };

    element.addEventListener(PRICE_HOVER_CUSTOM_EVENT, onPriceHover);

    return () => {
      element.removeEventListener(PRICE_HOVER_CUSTOM_EVENT, onPriceHover);
    };
  }, [enabled]);

  return hoveredPrice;
};
