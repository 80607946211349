import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

export function escapeBackslashQuery(query: string): string {
  return query.replace(/\\/g, '\\\\');
}

export const queryString = (values: { [key: string]: unknown }) => {
  const cleanValues = omitBy(values, isNil);
  const params = new URLSearchParams(
    cleanValues as Record<string, string>
  ).toString();

  return params ? `?${params}` : '';
};

const lodashGet = <T>(object: Record<string, any>, path: string[] | string) => {
  let next = object;
  const keys = Array.isArray(path) ? path : path.split('.');
  keys.forEach(key => {
    next = next[key];
  });
  return next as T;
};

export const compareObjectKeys = (
  object1: Record<string, any>,
  object2: Record<string, any>,
  keys: string[]
) => keys.every(key => lodashGet(object1, key) === lodashGet(object2, key));

export const removeMarkdown = (text: string) => {
  return text
    .replace(/```[\s\S]*?```/g, '') // Remove code blocks
    .replace(/`([^`]+)`/g, '$1') // Remove inline code
    .replace(/!\[.*?\]\(.*?\)/g, '') // Remove images
    .replace(/\[([^\]]+)\]\(.*?\)/g, '$1') // Remove links but keep text
    .replace(/(^|\s)([*+-]|\d+\.)\s+/g, '$1') // Remove list markers
    .replace(/(^|\n)>+/g, '$1') // Remove blockquotes
    .replace(/(^|\n)(#+)\s*/g, '$1') // Remove headers
    .replace(/(\*\*|__)(.*?)\1/g, '$2') // Remove bold
    .replace(/(\*(?!\s)|_(?!\s))(.*?)(\1)/g, '$2') // Remove italic but preserve non-formatting asterisks/underscores
    .replace(/~~(.*?)~~/g, '$1') // Remove strikethrough
    .replace(/^\s*[-*_]{3,}\s*$/gm, '') // Remove horizontal rules
    .replace(/\n+/g, ' ') // Replace newlines with a single space
    .replace(/\s+/g, ' ') // Normalize multiple spaces
    .trim(); // Trim spaces
};
