import { PillDropdown, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const StyledCompetitorVendorRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CompetitorVendorListItem = styled.li`
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 30% 70%;
  padding: 12px 12px 12px 4px;
  border-bottom: var(--border-soft) solid 1px;

  ${PillDropdown.Styled.PillDropdownRoot} {
    text-transform: capitalize;
    margin: 0 5px;
    justify-content: left;
  }

  ${PillDropdown.Styled.StyledPillContainer} {
    justify-content: start;
    min-width: 142px;
    width: 100%;
  }

  ${PillDropdown.Styled.Label} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledListDescription = styled.p`
  flex: 2;
  ${typographyV2.bodySm}
  display: flex;
  color: var(--text-soft);
  align-items: center;
  margin-left: 12px;
`;

export const ExpandButtonContainer = styled.div`
  margin-top: 12px;
`;
