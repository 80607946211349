import { PriceDisplay } from '~/types/axis.types';

export const priceDecimals = (price: number, display: PriceDisplay) => {
  if (display === 'percentage' || price > 5) {
    return 2;
  }

  return 4;
};

// TODO this is a copy of webapp and should be replaced once we split up into packages
const DEFAULT_USER_LOCALE = 'en-US';
const SIGNIFICANT_DIGITS_COUNT = 4;

export const formatPriceLabel = (
  price: number,
  priceDisplay: PriceDisplay,
  locale = DEFAULT_USER_LOCALE
) => {
  const absPrice = Math.abs(price);
  const decimals = priceDecimals(absPrice, priceDisplay);

  try {
    const significantNumberRule =
      priceDisplay === 'price' && price !== 0 && absPrice < 1;
    return price
      .toLocaleString(locale, {
        style: 'decimal',
        minimumFractionDigits: significantNumberRule ? undefined : decimals,
        maximumFractionDigits: significantNumberRule ? undefined : decimals,
        maximumSignificantDigits: significantNumberRule
          ? SIGNIFICANT_DIGITS_COUNT
          : undefined,
      })
      .replace('\u00A0', '');
  } catch {
    return price.toFixed(decimals);
  }
};
