import { mailTo } from '@toggle/helpers';
import { TFunction } from 'i18next';

import { config } from '~/config';

export const getTrialEndsMailLink = (
  t: TFunction,
  isPartnerships?: boolean
) => {
  const emailLink = isPartnerships
    ? config.partnershipsEmail
    : config.supportEmail;
  const body = isPartnerships
    ? t('subscription:alerts.trialEnds.mailBodyPartnerships')
    : t('subscription:alerts.trialEnds.mailBodySupport');
  return mailTo(emailLink, {
    subject: t('subscription:alerts.trialEnds.mailSubject'),
    body,
  });
};
