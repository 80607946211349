import React, { ReactNode } from 'react';

import { StyledComponent } from '~/common/styled-component';
import { Icon } from '~/components/icon/Icon';

import { Button, ButtonProps } from '../button/Button';
import * as S from './Alert.styles';

export interface AlertProps {
  type?: AlertType;
  variant?: AlertVariant;
  title?: ReactNode;
  message?: ReactNode;
  withIcon?: boolean;
  onClose?: () => void;
  className?: string;
  primaryBtn?: ButtonConfig;
  secondaryBtn?: ButtonConfig;
  customIcon?: ReactNode;
}

interface ButtonConfig extends ButtonProps {
  onClick: () => void;
}

export type AlertVariant = 'inline' | 'toast';

export enum AlertType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

const icons = {
  success: (
    <Icon
      fillColor="var(--icon-success)"
      iconName="CheckmarkLightCircle"
      size={20}
    />
  ),
  warning: (
    <Icon
      fillColor="var(--icon-warning)"
      iconName="ExclamationLightCircle"
      size={20}
    />
  ),
  error: (
    <Icon
      fillColor="var(--icon-critical)"
      iconName="CloseLightCircle"
      size={20}
    />
  ),
  info: (
    <Icon
      fillColor="var(--icon-primary)"
      iconName="InformationLightCircle"
      size={20}
    />
  ),
};

export const Alert: StyledComponent<AlertProps, typeof S> = ({
  type = AlertType.Error,
  variant = 'toast',
  withIcon = true,
  title,
  message,
  onClose,
  className,
  primaryBtn,
  secondaryBtn,
  customIcon,
  ...rest
}) => {
  return (
    <S.Alert
      type={type}
      variant={variant}
      data-testid="ds-alert"
      className={className}
      {...rest}
    >
      {withIcon && (
        <S.Icon data-testid="icon">{customIcon || icons[type]}</S.Icon>
      )}
      <S.Content>
        <S.ContentSection>
          <S.TextWrapper>
            {title && <S.Title>{title}</S.Title>}
            {message && <S.Message data-testid="message">{message}</S.Message>}
          </S.TextWrapper>
          {onClose && (
            <div>
              <S.Close data-testid="alert-close-button" onClick={onClose}>
                <Icon iconName="Close" size={13} />
              </S.Close>
            </div>
          )}
        </S.ContentSection>

        {(primaryBtn || secondaryBtn) && (
          <S.ButtonsContainer>
            {primaryBtn && <Button {...primaryBtn} size="small" />}
            {secondaryBtn && (
              <Button {...secondaryBtn} size="small" variant="secondary" />
            )}
          </S.ButtonsContainer>
        )}
      </S.Content>
    </S.Alert>
  );
};

Alert.Styled = S;
