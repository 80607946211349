import { ToggleFilterProps } from '~/components/update-filter-options/updateFilterOptions';
import { TabsType } from '~/modules/tab-content/types';
import { queryKeys } from '~/routes/app-paths';
import { createFilterId } from '~/views/screener/use-screener-store/filter-utils';
import { KnowledgeGraphSectionId } from '~/widgets/knowledge-graph-widget/KnowledgeGraphWidget';

interface GetKnowledgeGraphSearchParams {
  entityTag: string;
  searchParams: URLSearchParams;
  sectionId?: KnowledgeGraphSectionId;
}

export const getKnowledgeGraphSearchParams = ({
  entityTag,
  searchParams,
  sectionId,
}: GetKnowledgeGraphSearchParams) => {
  const prevAsset = searchParams.get(queryKeys.assetOverviewAsset) ?? entityTag;

  const newSearchParams = new URLSearchParams();
  newSearchParams.set(queryKeys.assetOverviewAsset, prevAsset);
  newSearchParams.set(queryKeys.assetOverviewTab, TabsType.KnowledgeGraph);

  if (sectionId) {
    newSearchParams.set(queryKeys.assetOverviewSectionId, sectionId);
  }

  return newSearchParams;
};

interface CreateFilterOptionsParams {
  id: string;
  name: string;
  key: string;
}

export const createKGFilterOptions = ({
  id,
  name,
  key,
}: CreateFilterOptionsParams): ToggleFilterProps => {
  const option = {
    key: id,
    name,
    value: { id },
  };

  return {
    filterKey: key,
    filterId: createFilterId(key),
    dynamic: true,
    multiselect: false,
    option,
  };
};

export const KNOWLEDGE_GRAPH_SUCCESS_TOAST = 'KNOWLEDGE_GRAPH_SUCCESS_TOAST';

export const getKnowledgeGraphNavigateParams = ({
  sectionId,
  searchParams,
  entityTag,
}: {
  sectionId: KnowledgeGraphSectionId;
  searchParams: URLSearchParams;
  entityTag: string;
}) => {
  const knowledgeGraphSearchParams = getKnowledgeGraphSearchParams({
    searchParams,
    sectionId,
    entityTag,
  });

  return {
    entityTag,
    searchParams: knowledgeGraphSearchParams,
    tab: TabsType.KnowledgeGraph,
  };
};
