import { SkeletonLoader } from '@toggle/design-system';
import { Entity, FinancialStatementType, TimeFrame } from '@toggle/toggle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';

import { FinancialTable } from './financial-table/FinancialTable';
import { useFinancialStatement } from './use-financial-statement/useFinancialStatement';

export interface AssetFinancialsProps {
  entity: Entity;
}

const timeFrames: TimeFrame[] = ['annual', 'quarterly'];
// order matters here
const financialStatementButtonGroupLabels: FinancialStatementType[] = [
  'incomeStatement',
  'balanceSheet',
  'cashFlow',
];

export const AssetFinancialsWidget = ({ entity }: AssetFinancialsProps) => {
  const { t } = useTranslation('widget');

  const [selectedStatementType, setSelectedStatementType] =
    useState<FinancialStatementType>('incomeStatement');
  const [selectedTimeframe, setSelectedTimeframe] =
    useState<TimeFrame>('annual');

  const { data, isLoading, isRefetching } = useFinancialStatement({
    entityTag: entity.tag,
    type: selectedStatementType,
    timeframe: selectedTimeframe,
  });

  const activeStatementButtonGroupIndex =
    financialStatementButtonGroupLabels.indexOf(selectedStatementType);

  const handleStatementButtonGroupSelection = (i: number) => {
    setSelectedStatementType(financialStatementButtonGroupLabels[i]);
  };

  const activeTimeframeButtonGroupIndex = timeFrames.indexOf(selectedTimeframe);

  const handleTimeframeButtonGroupSelection = (i: number) => {
    setSelectedTimeframe(timeFrames[i]);
  };

  if (isLoading || isRefetching) {
    return (
      <SkeletonLoader
        areas={[
          {
            styles: {
              maxWidth: '1280px',
              borderRadius: '8px',
              height: '360px',
              margin: '20px auto',
            },
          },
        ]}
        data-testid="asset-financials-loader"
      />
    );
  }

  if (!data) {
    return (
      <ErrorMessageWrapper
        variant="empty"
        displayIcon
        title={t('widget:financials.empty.title')}
        subTitle={t('widget:financials.empty.subTitle', { asset: entity.name })}
        data-testid="asset-financials-error-message"
      />
    );
  }

  return (
    <FinancialTable
      data={data}
      activeStatementButtonGroupIndex={activeStatementButtonGroupIndex}
      handleStatementButtonGroupSelection={handleStatementButtonGroupSelection}
      activeTimeframeButtonGroupIndex={activeTimeframeButtonGroupIndex}
      handleTimeframeButtonGroupSelection={handleTimeframeButtonGroupSelection}
    />
  );
};
