import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  & span,
  div {
    ${typographyV2.bodyBase}
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & img {
    width: 18px;
  }

  & div {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  & svg {
    fill: var(--icon-soft);
    cursor: pointer;
  }
`;
