import { WithNAFallback } from '@toggle/design-system';
import React from 'react';

import * as S from './LinkCell.styles';

export const LinkCell = ({ url }: { url?: string }) => {
  return (
    <WithNAFallback
      check={!!url}
      value={
        <S.StyledIcon
          data-testid="link-cell"
          iconName="HtmlLinkIcon"
          size={20}
          onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
        />
      }
    />
  );
};
