import { JSX } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '~/components/button/Button';
import { Dropdown, DropdownProps } from '~/components/dropdown/Dropdown';
import { DropdownList } from '~/components/dropdown/dropdown-list/DropdownList';
import { DropdownListItem } from '~/components/dropdown/dropdown-list-item/DropdownListItem';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const ShowMoreButton = styled(Button)`
  display: none;
  position: absolute;
  background: linear-gradient(
    to left,
    rgba(var(--background-default-rgb), 1) 0%,
    rgba(var(--background-default-rgb), 0) 286.65%
  );
  right: 8px;
  border: 1px solid var(--border-soft);
  padding: 4px;

  svg {
    transform: rotate(90deg);
  }
`;

export const StyledPillContainer = styled.div`
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
`;

export const PillDropdownRoot = styled.div<{
  $hasOptions: boolean;
  $isInteractive: boolean;
  $variant: 'default' | 'outline' | 'buttonPrimary';
}>`
  ${borderRadius.r2};
  ${typographyV2.bodySm};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: var(--text-default);
  padding: 4px 4px;
  gap: 8px;
  outline: none;
  position: relative;

  ${({ $variant }) =>
    $variant === 'default' &&
    css`
      background-color: transparent;
      border: 1px solid transparent;
    `}

  ${({ $variant }) =>
    $variant === 'outline' &&
    css`
      background-color: var(--surface-default);
      border: 1px solid transparent;
    `}

  ${({ $variant }) =>
    $variant === 'buttonPrimary' &&
    css`
      background-color: transparent;
      border: 1px solid var(--border-primary);
      color: var(--text-primary);
      padding: 8px 12px;
      ${borderRadius.r1}
      ${Label} {
        text-transform: none;
      }
    `}

  ${({ $isInteractive, $variant }) =>
    $isInteractive &&
    $variant === 'default' &&
    css`
      cursor: pointer;
      &:hover,
      &:focus-visible {
        background-color: var(--surface-hover);
      }
    `}

  ${({ $isInteractive, $variant }) =>
    $isInteractive &&
    $variant === 'outline' &&
    css`
      cursor: pointer;
      &:hover,
      &:focus-visible {
        border: 1px solid var(--border-hover);
      }
    `}

  ${({ $isInteractive, $variant }) =>
    $isInteractive &&
    $variant === 'buttonPrimary' &&
    css`
      cursor: pointer;
    `}
  
  ${({ $hasOptions }) =>
    $hasOptions &&
    css`
      &:focus-visible {
        outline: 2px solid var(--border-focus);
      }

      &:hover,
      &:focus-within {
        ${ShowMoreButton} {
          display: flex;
        }
      }
    `}
`;

export const Label = styled.span`
  ${typographyV2.bodySm};
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 4px;
  text-wrap: wrap;
`;

export const StyledPillDropdown = styled(Dropdown)`
  min-width: 50px;
  width: fit-content;
  ${DropdownList.Styled.DropdownListRoot} {
    width: 288px;
    white-space: nowrap;
    max-height: 264px;
    overflow: auto;
  }
  ${DropdownListItem.Styled.Item} {
    margin: 8px;
    &:focus-visible {
      outline: 2px solid var(--border-focus);
    }
    &::before {
      background-color: none;
    }
  }
` as <T>(props: DropdownProps<T>) => JSX.Element;
