import { CustomFilter } from '@toggle/toggle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Filters } from '~/components/filters/Filters';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';

import { useScenarioStore } from '../../hooks/use-scenario-store/useScenarioStore';
import { getCustomScenarioChildOptions } from '../../utils/scenario-filter-utils/scenario-filter-utils';
import {
  CustomScenarioModal,
  CustomScenarioModalProps,
} from '../custom-scenario-modal/CustomScenarioModal';

export const ConditionsFilters = () => {
  useTranslation('screener');
  const [activeCustomCondition, setActiveCustomCondition] =
    useState<CustomFilter>();
  const { addFilterOption } = useFilterActions({
    store: useScenarioStore,
    group: 'SCENARIO_CONDITIONS',
  });

  const { filterGroups } = useScenarioStore(state => ({
    filterGroups: state.filterGroups,
  }));

  const handleCustomCondition: CustomScenarioModalProps['handleCustomCondition'] =
    ({ scenarioCondition, filterKey, snakeOptions }) => {
      setActiveCustomCondition(undefined);

      const customNames = Object.values(snakeOptions).map(c => c.name);
      const childrenOptions = getCustomScenarioChildOptions({
        activeFilters: filterGroups.SCENARIO_CONDITIONS.activeFilters,
        filterKey,
        customNames,
      });

      addFilterOption({
        option: {
          name: 'custom',
          key: 'custom',
          value: scenarioCondition,
        },
        multiselect: false,
        dynamic: true,
        filterKey,
        childrenOptions,
      });
    };

  return (
    <>
      {activeCustomCondition && (
        <CustomScenarioModal
          conditionFilter={activeCustomCondition}
          onClose={() => setActiveCustomCondition(undefined)}
          handleCustomCondition={handleCustomCondition}
        />
      )}
      {filterGroups.SCENARIO_CONDITIONS && (
        <Filters
          filterState={filterGroups.SCENARIO_CONDITIONS}
          store={useScenarioStore}
          group={'SCENARIO_CONDITIONS'}
          showCustomFilterModal={false}
          showTriggerLabel={false}
          showAddMoreFilters={false}
          onCustomRangeClick={filter =>
            setActiveCustomCondition(filter.config.custom)
          }
        />
      )}
    </>
  );
};
