import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ConfirmationModal.styles';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onClose: () => void;
  onCancel: () => void;
  hasActiveListInAddMode: boolean;
  selectedEntitiesCount: number;
}

export const ConfirmationModal = ({
  onClose,
  onCancel,
  onConfirm,
  hasActiveListInAddMode,
  selectedEntitiesCount,
}: ConfirmationModalProps) => {
  const { t } = useTranslation(['watchlistManager']);
  return (
    <S.StyledModal
      isOpen
      title={t('watchlistManager:confirmationModal.title')}
      cancelBtnProps={{
        label: t('watchlistManager:confirmationModal.dontSave'),
        onClick: onCancel,
      }}
      primaryBtnProps={{
        label: hasActiveListInAddMode
          ? t('watchlistManager:confirmationModal.addAssetsToWatchlist', {
              count: selectedEntitiesCount,
            })
          : t('watchlistManager:confirmationModal.save'),
        onClick: onConfirm,
      }}
      onClose={onClose}
    >
      <S.Label>
        {hasActiveListInAddMode
          ? t('watchlistManager:confirmationModal.addContent')
          : t('watchlistManager:confirmationModal.content')}
      </S.Label>
    </S.StyledModal>
  );
};
