import React from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownCheckbox } from '~/components/smart-table/table-columns-editor/TableColumnsEditor.styles';

import { DropdownEntry } from '../dropdown-group/DropdownGroup';
import { Separator } from '../DropdownMenu.styles';
import * as S from './DropdownCheckGroup.styles';

export interface DropdownCheckEntry extends DropdownEntry {
  checked: boolean;
}

export type DropdownCheckGroupProps = {
  items: DropdownCheckEntry[];
  onChange: (key: string, checked: boolean) => void;
  selectAllLabel?: string;
  toggleAll?: (checked: boolean) => void;
  label?: string;
};

export const DropdownCheckGroup = ({
  items,
  onChange,
  selectAllLabel,
  label,
  toggleAll,
}: DropdownCheckGroupProps) => {
  const { t } = useTranslation('common');

  const isAllChecked = items.every(item => item.checked);
  const isSomeChecked = isAllChecked || items.some(item => item.checked);

  return (
    <S.DropdownWrapper data-testid="dropdown-wrapper">
      {label && <S.Label>{label}</S.Label>}
      {toggleAll && (
        <S.DropdownMenuCheckboxItem
          key={'select'}
          checked={isAllChecked}
          onSelect={event => event.preventDefault()}
          data-testid="dropdown-select-all"
        >
          <DropdownCheckbox
            name="select-all"
            label={selectAllLabel ?? t('common:selectAll')}
            checked={isSomeChecked}
            partial={isSomeChecked && !isAllChecked}
            size="small"
            onChange={(_, e) => toggleAll(e.target.checked)}
          />
        </S.DropdownMenuCheckboxItem>
      )}
      <S.DropdownGroup $hasSelectAll={!!toggleAll}>
        {items.map(item => (
          <S.DropdownMenuCheckboxItem
            key={item.key}
            onSelect={event => event.preventDefault()}
            disabled={item.disabled}
            data-testid={`dropdown-group-item-${item.key}`}
            checked={item.checked}
          >
            <DropdownCheckbox
              name={item.key}
              label={item.label}
              checked={item.checked}
              size="small"
              onChange={(key, e) => onChange(key, e.target.checked)}
            />
          </S.DropdownMenuCheckboxItem>
        ))}
      </S.DropdownGroup>
      <Separator />
    </S.DropdownWrapper>
  );
};
