import { Row } from '@tanstack/react-table';
import React, { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import * as S from './DraggableWatchlistRow.styles';

export interface DraggableWatchlistRowProps<T>
  extends Omit<HTMLAttributes<HTMLTableRowElement>, 'onDrop'> {
  children: ReactNode;
  row: Row<T>;
  afterNode?: ReactNode;
  onDrop?: ({ toIdx, fromIdx }: { toIdx: number; fromIdx: number }) => void;
}

interface DragItem {
  idx: number;
}

export const DraggableWatchlistRow = <T,>({
  children,
  row,
  afterNode,
  onDrop,
  ...rest
}: DraggableWatchlistRowProps<T>) => {
  const dropRef = useRef(null);
  const dragRef = useRef(null);
  const idx = row.index;

  const [, drag, preview] = useDrag<DragItem>(
    () => ({
      type: 'tableRow',
      item: { idx: row.index },
      canDrag: !!onDrop,
    }),
    [row.id, row.index]
  );

  const [{ canDrop, item }, drop] = useDrop(
    () => ({
      accept: 'tableRow',
      canDrop: (item, monitor) => {
        if (!monitor.isOver() || item.idx === idx) {
          return false;
        }

        return true;
      },
      drop: (item: DragItem) => {
        onDrop?.({ toIdx: idx, fromIdx: item.idx });
      },
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        item: monitor.getItem(),
      }),
    }),
    [idx, row.id]
  );

  preview(drop(dropRef));
  drag(dragRef);

  useEffect(() => {}, [canDrop]);

  const dropPosition = idx > item?.idx ? 'bottom' : 'top';
  return (
    <S.StyledDraggableRow
      ref={dropRef}
      $isBottomOver={canDrop && dropPosition === 'bottom'}
      $isTopOver={canDrop && dropPosition === 'top'}
      {...rest}
    >
      <S.StyledTableCell ref={dragRef}>
        <S.CellContentWrapper>
          {onDrop && (
            <div>
              <S.DragIcon size={12} iconName="GripsDots" />
            </div>
          )}
          {afterNode}
        </S.CellContentWrapper>
      </S.StyledTableCell>
      {children}
    </S.StyledDraggableRow>
  );
};

DraggableWatchlistRow.Styled = S;
