import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

export const SearchLandingRoot = styled.div`
  ${customScrollMinimal};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  padding-bottom: 32px;
  max-width: 776px;
  margin: 0 auto;
`;
