import { Button, Icon } from '@toggle/design-system';
import { ScreenerFilter, ScreenerFilterOption } from '@toggle/toggle';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActiveFilterOptions } from '../filters/Filters';
import { TreeNode } from './components/TreeNode/TreeNode';
import * as S from './SearchTreeModal.styles';
import {
  filterOptionsByHighlightedValues,
  getHighlightedOptionValues,
} from './utils';

export interface SearchTreeModalProps {
  title: string;
  activeFilter: ScreenerFilter;
  activeFilterOptions: ActiveFilterOptions[];
  onClose: () => void;
  setFilterOptions: (filterProps: {
    filterKey: string;
    options: ScreenerFilterOption[];
  }) => void;
  resetFilterOptions: (filterKey: string) => void;
}

export const SearchTreeModal = ({
  title,
  activeFilter,
  activeFilterOptions,
  onClose,
  setFilterOptions,
  resetFilterOptions,
}: SearchTreeModalProps) => {
  const { t } = useTranslation('screener');
  const activeOptions =
    activeFilterOptions?.find(af => af.id === activeFilter.key)?.options ?? [];
  const [selectedOptions, setSelectedOptions] =
    useState<ScreenerFilterOption[]>(activeOptions);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { options } = activeFilter.config;
  const [displayedOptions, setDisplayedOptions] =
    useState<ScreenerFilterOption[]>(options);

  const expandHighlightedOptions = (value: string) => {
    const values = getHighlightedOptionValues({
      options,
      searchQuery: value,
    });
    const filteredOptions = filterOptionsByHighlightedValues(options, values);
    setDisplayedOptions(value ? filteredOptions : options);
    const updatedExpandedItems = [...new Set([...expandedItems, ...values])];
    setExpandedItems(updatedExpandedItems);
  };

  const debouncedHighlight = useCallback(
    debounce((value: string) => {
      expandHighlightedOptions(value);
    }, 250),
    []
  );

  useEffect(() => {
    return () => {
      debouncedHighlight.cancel();
    };
  }, [debouncedHighlight]);

  const onClickCaret = (values: string[], isExpanded: boolean) => {
    const updatedItems = isExpanded
      ? expandedItems.filter(i => !values.includes(i))
      : [...new Set([...expandedItems, ...values])];

    setExpandedItems(updatedItems);
  };

  const onChangeCheckBox = (
    options: ScreenerFilterOption[],
    checked: boolean
  ) => {
    const optionsValues = options.map(o => o.value.code);
    const updatedOptions = checked
      ? [...selectedOptions, ...options]
      : selectedOptions.filter(i => !optionsValues.includes(i.value.code));

    setSelectedOptions(updatedOptions);
  };

  const resetDisplayedOptions = () => {
    setSearchQuery('');
    setExpandedItems([]);
    setDisplayedOptions(options);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    debouncedHighlight(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedOptions.length) {
      setFilterOptions({
        filterKey: activeFilter.key,
        options: selectedOptions,
      });
    } else {
      resetFilterOptions(activeFilter.key);
    }

    onClose();
  };

  const handleClear = () => {
    resetDisplayedOptions();
    setSelectedOptions([]);
    resetFilterOptions(activeFilter.key);
  };

  return (
    <S.StyledModal isOpen title={title} onClose={onClose}>
      <S.Container>
        <S.Search
          data-testid="search-tree-input"
          size="large"
          label={t('screener:gics.searchIndustry')}
          leftIcon={<Icon iconName="Search" size={20} />}
          value={searchQuery}
          onChange={onInputChange}
          rightIcon={
            searchQuery ? (
              <S.ClearButton
                data-testid="clear-search-button"
                onClick={resetDisplayedOptions}
              >
                {t('screener:gics.clear')}
              </S.ClearButton>
            ) : null
          }
        />
        <S.NodesWrapper>
          {displayedOptions.length ? (
            displayedOptions.map(o => {
              return (
                <TreeNode
                  key={o.key}
                  option={o}
                  selectedOptions={selectedOptions}
                  searchQuery={searchQuery}
                  expandedItems={expandedItems}
                  onClickCaret={onClickCaret}
                  onChangeCheckBox={onChangeCheckBox}
                />
              );
            })
          ) : (
            <S.NoResultsLabel>{t('screener:noResults')}</S.NoResultsLabel>
          )}
        </S.NodesWrapper>
      </S.Container>
      <S.FooterButtons>
        <Button
          variant="secondary"
          size="large"
          label={t('screener:gics.clear')}
          onClick={handleClear}
        />
        <S.ConfirmationButtons>
          <Button
            variant="tertiary"
            size="large"
            label={t('screener:gics.cancel')}
            onClick={onClose}
          />
          <Button
            variant="primary"
            size="large"
            label={t('screener:gics.select')}
            onClick={handleSubmit}
          />
        </S.ConfirmationButtons>
      </S.FooterButtons>
    </S.StyledModal>
  );
};
