import { borderRadius, Icon, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const MenuButtonContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0px 8px;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const highlightedState = css`
  border: 1px solid var(--border-hover);
  ${MenuButtonContainer} {
    display: flex;
  }
  ${PriceContainer} {
    display: none;
  }
`;

export const LegendItemDetails = styled.div`
  display: flex;
  align-items: center;
  ${borderRadius.r1}
`;

export const LegendItemRoot = styled.div<{
  $isHighlighted: boolean;
  $hasHover: boolean;
}>`
  padding: 2px;
  display: flex;
  align-items: center;
  background-color: var(--background-soft);
  margin-bottom: 8px;
  ${borderRadius.r1}
  border: 1px solid var(--border-soft);
  ${({ $isHighlighted }) => $isHighlighted && highlightedState}

  ${({ $hasHover }) =>
    $hasHover &&
    css`
      &:has(${LegendItemDetails}:hover) {
        ${highlightedState}
      }
    `}
`;

export const AssetContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  text-transform: uppercase;
`;

export const Button = styled.button<{
  $isUpperCase?: boolean;
}>`
  padding: 0;
  text-transform: ${props => props.$isUpperCase && 'uppercase'};
  background: none;
  border: 0;
  color: inherit;
  ${typographyV2.bodyBase}
  cursor: pointer;
`;

export const LegendButtonWrapper = styled.span<{ $disabled?: boolean }>`
  border-radius: 2px;
  padding: 0 4px;
  display: flex;
  gap: 4px;
  align-items: center;
  &:hover {
    background-color: var(--surface-hover);
  }
  cursor: default;
`;

export const Asset = styled.div`
  display: flex;
  align-items: center;
  ${typographyV2.bodyBase}
`;

export const SeperatorDot = styled(Icon)`
  margin: 0px 4px;
  fill: var(--icon-default);
`;

export const MenuIconSpan = styled.span`
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: var(--surface-hover);
  }
`;

export const EntityNameWrapper = styled.div`
  color: var(--text-soft);
  ${typographyV2.bodySm}
`;
