import { BandChart } from '@toggle/design-system';
import styled from 'styled-components';

export const BandChartWrapper = styled.div<{ $color: string }>`
  padding-top: 8px;
  & svg {
    height: 240px;
  }

  ${BandChart.Styled.LinePlot} {
    color: ${({ $color }) => $color};
    stroke-width: 1px;
  }
`;

export const ChartAndHorizonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
