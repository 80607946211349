import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ChatInputComposer } from '~/components/chat';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Description = styled.p`
  ${typographyV2.bodyBase};
`;

export const ChipsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const WarningText = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-critical);
`;

export const InputWrapper = styled.div`
  margin-top: 32px;
`;

export const ChatInputComposerWrapper = styled.div`
  width: 100%;
  max-width: 634px;

  ${ChatInputComposer.Styled.Form} {
    width: 100%;
  }
`;
