import { Tooltip, TooltipTrigger } from '@toggle/design-system';
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';

import { getFormattedDates } from '../../utils/utils';
import * as S from './DateCell.styles';

interface DateCellProps {
  date: Date;
  displayIcon?: boolean;
}

export const DateCell = ({ date, displayIcon = true }: DateCellProps) => {
  const { isBeforeNineThirtyAm, isAfterFourPm, formattedDate } =
    getFormattedDates(date);

  return (
    <S.DateContainer>
      {formattedDate}
      {displayIcon && (
        <Tooltip
          isTouchDevice={false}
          trigger={TooltipTrigger.Hover}
          label={
            <S.TimeLabel>
              {formatInTimeZone(date, 'America/New_York', 'h:mmaaa zz', {
                locale: enUS,
              })}
            </S.TimeLabel>
          }
        >
          <S.IconWrapper>
            {isBeforeNineThirtyAm && <S.SunRise iconName="SunRise" />}
            {isAfterFourPm && <S.Moon iconName="Moon" />}
            {!isBeforeNineThirtyAm && !isAfterFourPm && (
              <S.Sun iconName="Sun" />
            )}
          </S.IconWrapper>
        </Tooltip>
      )}
    </S.DateContainer>
  );
};
