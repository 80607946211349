import { ScreenerFilter } from '@toggle/toggle';

import { ToggleFilterProps } from '~/components/update-filter-options/updateFilterOptions';
import { AssetsConstituents } from '~/views/dashboard/calendar-widget/calendar-event-drawer/drawer-content/run-scenario/RunScenario';

export const MARKET_INDEX_KEY = 'market_index';
export const ENTITY_TAG_KEY = 'entity_tag';

interface AddAssetFilterOptionsArgs {
  activeAssetFilters: ScreenerFilter[];
  selectedAssets?: string;
  addAssetFilterOption: (props: ToggleFilterProps) => void;
}

export const addAssetFilterOptions = ({
  activeAssetFilters,
  selectedAssets,
  addAssetFilterOption,
}: AddAssetFilterOptionsArgs) => {
  if (!selectedAssets) {
    return;
  }

  const indexAbbreviationToFullNameMap = {
    [AssetsConstituents.SPX]: 'S&P 500',
    [AssetsConstituents.FTSE100]: 'FTSE 100',
    [AssetsConstituents.DJI]: 'Dow Jones Industrial',
    [AssetsConstituents.NI225]: 'Nikkei 225',
  } as const;

  const marketIdxFilter = activeAssetFilters.find(
    filter => filter.key === MARKET_INDEX_KEY
  );
  const selected = marketIdxFilter?.config.options.find(
    option =>
      option.name ===
      indexAbbreviationToFullNameMap[selectedAssets as AssetsConstituents]
  );

  if (selected && !!marketIdxFilter) {
    addAssetFilterOption({
      filterKey: MARKET_INDEX_KEY,
      filterId: MARKET_INDEX_KEY,
      option: selected,
    });
  } else if (selectedAssets) {
    addAssetFilterOption({
      filterKey: ENTITY_TAG_KEY,
      multiselect: true,
      option: {
        key: selectedAssets,
        name: selectedAssets,
        value: {
          watchlistId: selectedAssets,
        },
      },
    });
  }
};
