import * as echarts from 'echarts';
import * as Types from 'echarts/types/dist/echarts';
import React, { FC, useEffect, useRef } from 'react';

import { ThemeName } from '~/design-tokens/themes/themes';

import { createApacheConfig } from './apache-config';
import { usaData } from './data/usaMap';
import { useWindowResize } from '@toggle/helpers';

export interface ApacheChartProps extends Types.EChartsOption {
  type: string;
  theme?: ThemeName;
  initOptions?: echarts.EChartsInitOpts;
}

export const ApacheChart: FC<ApacheChartProps> = ({
  theme = 'dark_default',
  initOptions,
  type,
  ...restOptions
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.EChartsType | null>(null);
  const { width, height } = useWindowResize({ targetRef: ref });

  useEffect(() => {
    chartInstance.current?.resize({ width, height });
  }, [width, height]);

  useEffect(() => {
    if (type === 'apache-us-map') {
      echarts.registerMap('USA', usaData as any, {
        Alaska: {
          left: -131,
          top: 25,
          width: 15,
        },
        Hawaii: {
          left: -110,
          top: 28,
          width: 5,
        },
        'Puerto Rico': {
          left: -76,
          top: 26,
          width: 2,
        },
      });
    }

    if (Object.keys(restOptions).length) {
      // eslint-disable-next-line import/namespace
      chartInstance.current = echarts.init(ref.current, theme, initOptions);
      const option = createApacheConfig(theme, restOptions);
      chartInstance.current.setOption(option);
    }

    return () => {
      chartInstance.current?.dispose();
    };
  }, []);

  useEffect(() => {
    if (chartInstance.current) {
      const option = createApacheConfig(theme, restOptions);
      chartInstance.current.setOption(option);
    }
  }, [theme, initOptions, type, restOptions]);

  return <div ref={ref} />;
};
