import { AssetCard } from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import React, { HtmlHTMLAttributes, ReactNode } from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';

import { ExchangeCard } from '../exchange-card/ExchangeCard';
import * as S from './ListEntityItem.styles';

export interface ListEntityItemProps
  extends Omit<HtmlHTMLAttributes<HTMLButtonElement>, 'onFocus'> {
  entity: Entity;
  isFocused: boolean;
  highlighted?: string;
  snakeName?: string;
  onFocus?: (name: string) => void;
  actionNode?: ReactNode;
  isDisabled?: boolean;
}

export const ListEntityItem = ({
  entity,
  isFocused,
  isDisabled,
  highlighted,
  snakeName,
  actionNode,
  onFocus,
  ...rest
}: ListEntityItemProps) => {
  return (
    <S.ListEntityItemRoot
      data-testid={`list-item-${entity.id}`}
      $isFocused={isFocused}
      onFocus={() => onFocus?.(entity.name)}
      $isDisabled={isDisabled}
      {...rest}
    >
      <S.LeftPart>
        {actionNode}
        <AssetCard
          logoNode={<AssetLogoContainer entity={entity} />}
          entity={entity}
          highlighted={highlighted}
          snakeName={snakeName}
        />
      </S.LeftPart>
      <S.Details>
        <ExchangeCard entity={entity} />
      </S.Details>
    </S.ListEntityItemRoot>
  );
};
