import React, { cloneElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router';

import { ChatInput, ChatInputProps } from '~/components/chat';
import { Sidebar } from '~/components/sidebar/Sidebar';
import { AppRoute } from '~/routes';
import { appPaths, queryKeys } from '~/routes/app-paths';
import { useBreadcrumbs } from '~/stores/use-breadcrumbs/useBreadcrumbs';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { Widgets } from '~/views/widgets/Widgets';

import * as S from './View.styles';

export interface AccountViewProps {
  config: AppRoute;
  showSidebar: boolean;
}

export const View = ({ config, showSidebar }: AccountViewProps) => {
  const { handleLocationChange } = useBreadcrumbs(state => ({
    handleLocationChange: state.handleLocationChange,
  }));
  const { themeId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['common', 'watchlistManager']);
  const location = useLocation();
  const isBasicUser = useSubscription(state => state.isBasicUser());

  const { element, crumb } = config;

  useEffect(() => {
    const labelKey = crumb?.labelKey;
    handleLocationChange({
      to: location,
      crumb: {
        ...crumb,
        label: labelKey ? t(labelKey) : '',
        themeId: themeId ?? undefined,
        assetTag: searchParams.get(queryKeys.assetOverviewAsset) ?? undefined,
      },
    });
  }, [location]);

  const has100Height = [
    appPaths.chart,
    appPaths.screener,
    appPaths.watchlist,
    appPaths.news,
    appPaths.themeOverview,
    appPaths.assetOverview,
    appPaths.alfred,
  ].some(path =>
    matchPath({ path, end: false, caseSensitive: false }, location.pathname)
  );

  const content = cloneElement(element, {
    renderChatInput: (props?: ChatInputProps) =>
      isBasicUser ? null : <ChatInput {...props} />,
  });

  return (
    <S.ViewRoot $has100Height={has100Height}>
      {showSidebar && <Sidebar />}
      <Widgets />
      {content}
    </S.ViewRoot>
  );
};
