import { useInfiniteQuery } from '@tanstack/react-query';
import { SearchRequest, SingleNews } from '@toggle/toggle';

import { fetchNewsArticles } from '~/services/news/news-service';

const QUERY_KEY_SEARCH_NEWS = 'QUERY_KEY_SEARCH_NEWS';

const PAGE_SIZE = 20;

export const useSearchNews = ({ query, sort_by, filters }: SearchRequest) => {
  return useInfiniteQuery<SingleNews[]>({
    queryKey: [QUERY_KEY_SEARCH_NEWS, query, sort_by, filters],
    queryFn: async ({ pageParam, signal }) => {
      const { highlights, date_range, ...newsFilters } = filters ?? {};
      const response = await fetchNewsArticles(
        {
          query,
          highlights,
          date_range,
          newsFilters,
          page: {
            number: pageParam as number,
            size: PAGE_SIZE,
          },
          sort_by,
        },
        signal
      );
      return response.news;
    },
    enabled: !!query,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      const isLastPageFull = lastPage.length === PAGE_SIZE;

      return isLastPageFull ? pages.length + 1 : undefined;
    },
  });
};
