import { Condition } from '@toggle/toggle';
import { TFunction } from 'i18next';
import { times } from 'number-precision';

import {
  formatNumberWithUnit,
  formatPercentageNumber,
} from '~/widgets/earnings/utils/utils';

export const DECIMAL_TO_PERCENTAGE_FACTOR = 100;

export const formatConditionValue = (condition: Condition): string => {
  const { value, actualUnit } = condition;

  if (value === undefined) {
    return '';
  }

  // For 'falls_by' and 'rises_by' conditions value is always below 0.
  if (['falls_by', 'rises_by'].includes(condition.condition)) {
    return times(value, DECIMAL_TO_PERCENTAGE_FACTOR).toString();
  }

  // For percentage units, adds % unit.
  if (actualUnit === '%') {
    return formatPercentageNumber(value);
  }

  // For other units, the value is formatted with compact notation.
  return formatNumberWithUnit(value);
};

const translateConditionLabel = (
  t: TFunction,
  condition: Condition
): string => {
  const {
    condition: conditionType,
    duration,
    duration_unit,
    max_value,
    min_value,
    entityName,
    formattedTicker,
    snakeName,
    actualUnit,
  } = condition;

  const period = t('scenarioCommander:domino.period', {
    context: duration_unit,
    count: duration,
  });

  const formattedValue = formatConditionValue(condition);

  return t('scenarioCommander:domino.conditions', {
    context: conditionType,
    entityName,
    formattedTicker: `(${formattedTicker})`,
    snakeName,
    duration,
    period,
    value: formattedValue,
    max_value,
    min_value,
    actualUnit,
  });
};

export const generateLastConditionLabel = <T extends Condition>({
  t,
  conditions,
}: {
  t: TFunction;
  conditions: T[];
}): string => {
  const andLabel = t('scenarioCommander:domino.and');
  return conditions
    .map(condition => translateConditionLabel(t, condition))
    .join(`${andLabel}\n`);
};
