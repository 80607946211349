import { Entity } from '@toggle/toggle';
import { ScaleLinear } from 'd3';

const eps = 1e-6;

export const getDirectionColor = (isBullish?: boolean) =>
  isBullish ? 'var(--viz-bullish)' : 'var(--viz-bearish)';

export const fixEmptyDomain = (scale: ScaleLinear<number, number>) => {
  if (scale.domain()[0] === scale.domain()[1]) {
    return scale
      .copy()
      .domain([scale.domain()[0] - eps, scale.domain()[0] + eps]);
  }

  return scale;
};

export const priceChangeColors = {
  default: 'var(--text-default)',
  negative: 'var(--viz-bearish)',
  positive: 'var(--viz-bullish)',
  disabled: 'var(--text-disabled)',
};

export const sortElementByEdgeWeight = (elements: Record<'entity', Entity>[]) =>
  [...elements].sort((a, b) => b.entity.search.rank - a.entity.search.rank);

export const generateRandomArray = (data: string[], length = 3) => {
  const randomData = [...data].sort(() => Math.random() - 0.5);
  return randomData.slice(0, length);
};

export const getUniqueArray = <T>(array: T[]): T[] => [...new Set(array)];
