import { useInfiniteQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { CitationFilters } from '@toggle/toggle';

import {
  getCitations,
  getDocumentGroup,
} from '~/views/intel/citation-service/citation-service';

const QUERY_KEY_GROUPED_DOCUMENTS = 'QUERY_KEY_GROUPED_DOCUMENTS';
const PAGE_SIZE = 25;

type UseCitationGroupDocumentsProps = {
  groupId: string;
  filters?: CitationFilters;
  sorting?: {
    desc: boolean;
    id: string;
  }[];
  pageSize?: number;
  docIdsOnly?: boolean;
};

export const useCitationGroupDocuments = ({
  groupId,
  filters,
  sorting,
  pageSize = PAGE_SIZE,
  docIdsOnly,
}: UseCitationGroupDocumentsProps) => {
  return useInfiniteQuery({
    queryKey: [
      QUERY_KEY_GROUPED_DOCUMENTS,
      groupId,
      JSON.stringify(filters),
      JSON.stringify(sorting),
      docIdsOnly,
    ],
    queryFn: async ({ pageParam, signal }) => {
      const documentGroup = await getDocumentGroup(
        groupId,
        {
          filters,
          doc_ids_only: docIdsOnly,
          page: { number: pageParam, size: pageSize },
          ...(sorting?.length && {
            sort_by: {
              field: sorting[0].id,
              desc: sorting[0].desc,
            },
          }),
        },
        signal
      );

      const documentsWithSubRows = await Promise.all(
        (documentGroup.documents ?? []).map(async document => {
          const citationsResult = await getCitations(
            groupId,
            {
              filters: {
                ...filters,
                document_id: {
                  values: [{ id: document.id }],
                },
              },
            },
            signal
          );
          const citations = citationsResult.citations;
          return {
            ...document,
            subRows: citations,
          };
        })
      );

      return {
        ...documentGroup,
        documents: documentsWithSubRows,
      };
    },
    initialPageParam: 1,
    staleTime: getTimeTillEndOfDayMs(),
    getNextPageParam: lastData => lastData.page.next,
  });
};
