import { createGlobalStyle } from 'styled-components';

export const Colors = {
  '--copilot--default': '#ffb224',
  '--copilot-default-rgb': '255, 168, 82',
  '--copilot--hover': '#ffbe7d',
  '--copilot--dark': '#302d2b',
  '--pro--default': '#44d9e9',
  '--pro-default-rgb': '68, 217, 233',
  '--pro--hover': '#66d1ea',
  '--pro--dark': '#1e3338',
  '--success--default': '#51d385',
  '--success--dark': '#223a32',
  '--warning--default': '#ffc852',
  '--warning--dark': '#3e382a',
  '--failure--default': '#fe7f7f',
  '--failure--dark': '#3e2c31',
  '--base--black': '#0d0e10',
  '--base--gray': '#1f2328',
  '--base--0': '#ffffff',
  '--base--0-rgb': '255, 255, 255',
  '--base--5': '#f4f4f4',
  '--base--10': '#e8e8e9',
  '--base--15': '#ddddde',
  '--base--20': '#d1d2d3',
  '--base--25': '#c6c7c8',
  '--base--30': '#babbbd',
  '--base--35': '#afb0b2',
  '--base--40': '#a3a5a7',
  '--base--45': '#98999c',
  '--base--50': '#8c8e91',
  '--base--55': '#818385',
  '--base--60': '#75777a',
  '--base--65': '#6a6c6f',
  '--base--70': '#5e6164',
  '--base--75': '#535659',
  '--base--80': '#474a4e',
  '--base--85': '#3c3f43',
  '--base--90': '#303438',
  '--base--95': '#25282d',
  '--base--100': '#191d22',
  '--base--100-rgb': '25, 29, 34',
  '--red--1': '#ffcdd2',
  '--red--2': '#ef9a9a',
  '--blue--5': '#2196f3',
  '--pink--1': '#f8bbd0',
  '--pink--3': '#f06292',
  '--pink--4': '#ec407a',
  '--purple--1': '#e1bee7',
  '--orange--1': '#ffe0b2',
  '--orange--2': '#ffcc80',
  '--indigo--1': '#c5cae9',
  '--indigo--2': '#9fa8da',
  '--indigo--8': '#8da4ef',
  '--cyan--1': '#b2ebf2',
  '--teal--1': '#b2dfdb',
  '--lime--2': '#e6ee9c',
  '--lime--3': '#dce775',
  '--lime--4': '#d4e157',
  '--amber--9': '#813800',
  '--light-green--4': '#9ccc65',
  '--deep-purple--1': '#d1c4e9',
  '--deep-purple--2': '#b39ddb',
  '--deep-orange--3': '#ff8a65',
};

export const GlobalColors = createGlobalStyle`
  :root {
    ${Colors}
  }
`;
