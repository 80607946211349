import React, { useEffect } from 'react';

import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';

import { useNewsSearchStore } from '../../stores/use-news-search-store/useNewsSearchStore';
import { updateWatchlistFilter } from '../../utils/news-filters-utils/news-filters-utils';
import { SearchLanding } from '../search-landing/SearchLanding';
import { SearchResults } from '../search-results/SearchResults';

export interface SearchViewProps {
  searchQuery: string;
}

export const SearchView = ({ searchQuery }: SearchViewProps) => {
  const { inputValue, accordionFilters, setAccordionFilters } =
    useNewsSearchStore(state => ({
      inputValue: state.inputValue,
      accordionFilters: state.accordionFilters,
      setAccordionFilters: state.setAccordionFilters,
    }));

  const lists = useWatchlist(state => state.lists);

  useEffect(() => {
    const updatedFilters = updateWatchlistFilter(accordionFilters, lists);
    setAccordionFilters(updatedFilters);
  }, [lists]);

  if (inputValue) {
    return <SearchResults searchQuery={searchQuery} />;
  }

  return <SearchLanding />;
};
