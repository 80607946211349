import React, { Fragment, useState } from 'react';

import { DropdownEntry } from '~/components/dropdown-menu/dropdown-group/DropdownGroup';
import { DropdownRadioGroup } from '~/components/dropdown-menu/dropdown-radio-group/DropdownRadioGroup';
import { DropdownMenu } from '~/components/dropdown-menu/DropdownMenu';
import { Icon } from '~/components/icon/Icon';
import { CategoryItem, CategorySubitem } from '~/components/side-navigation';
import { Tooltip, TooltipTrigger } from '~/components/tooltip/Tooltip';

import * as S from './NavItem.styles';

export type NavItemVariants = 'default' | 'secondary' | 'tertiary';

export type NavItemProps = {
  item: CategoryItem;
  activeKey?: string;
  activeSubKey?: string;
  disabled?: boolean;
  sideBarExpanded: boolean;
  showBottomBorder?: boolean;
  variant?: NavItemVariants;
};

export const NavItem = ({
  item,
  activeKey,
  activeSubKey,
  disabled,
  sideBarExpanded,
  showBottomBorder = false,
  variant = 'default',
}: NavItemProps) => {
  const isActive = activeKey === item.key;
  const [expanded, setExpanded] = useState(isActive);
  const activeSubItem = expanded && isActive ? activeSubKey : null;
  const getMenuItems = (subitems: CategorySubitem[]): DropdownEntry[] =>
    subitems
      ? subitems.map(p => ({
          label: p.badge ? (
            <S.DropdownSubitemContainer>
              {p.label}
              {<S.BadgeWrapper>{p.badge}</S.BadgeWrapper>}
            </S.DropdownSubitemContainer>
          ) : (
            p.label
          ),
          key: p.key,
          iconName: p.icon,
          onClick: () => p.onClick?.(p, item),
          disabled: p.disabled,
        }))
      : [];

  const shouldShowSeparator = (i: number, list: any[]) => {
    return i < list.length - 1;
  };

  return (
    <>
      <Tooltip
        label={item.label}
        disabled={sideBarExpanded}
        trigger={TooltipTrigger.Hover}
        placement="bottom"
      >
        <S.ItemWrapper key={item.key} $isActive={isActive} $variant={variant}>
          <S.Item
            data-testid={`side-navigation-item-${item.key}`}
            $isActive={isActive && (!sideBarExpanded || !activeSubItem)}
            disabled={disabled}
            onClick={() => {
              if (item.subitemGroups) {
                setExpanded(!expanded);
              }

              item.onClick(item);
            }}
            $variant={variant}
          >
            <S.ItemLeft>
              {item.icon &&
                (item.subitemGroups && !sideBarExpanded ? (
                  <DropdownMenu
                    triggerNode={
                      <S.IconWrapper data-testid="nav-dropdown-trigger">
                        {item.icon}
                      </S.IconWrapper>
                    }
                    contentProps={{ side: 'right' }}
                  >
                    <S.StyledDropdownWrapper>
                      {item.subitemGroups.map((group, i) => (
                        <Fragment key={i}>
                          <DropdownRadioGroup
                            items={getMenuItems(group)}
                            label={i === 0 ? item.label : ''}
                            selectedKey={activeSubKey}
                          />
                          {shouldShowSeparator(i, item.subitemGroups ?? []) && (
                            <S.Separator />
                          )}
                        </Fragment>
                      ))}
                    </S.StyledDropdownWrapper>
                  </DropdownMenu>
                ) : (
                  <S.IconWrapper>{item.icon}</S.IconWrapper>
                ))}
              <span>
                <S.MainLabel>{item.label}</S.MainLabel>
                {item.notification && (
                  <S.NotificationWrapper $top={0}>
                    {item.notification}
                  </S.NotificationWrapper>
                )}
              </span>
              {item.badge && <S.BadgeWrapper>{item.badge}</S.BadgeWrapper>}
            </S.ItemLeft>
            {item.subitemGroups && (
              <Icon
                iconName={expanded ? 'ChevronLightUp' : 'ChevronLightDown'}
                size={20}
                data-testid="expand-button"
              />
            )}
          </S.Item>
          {!sideBarExpanded && item.notification && (
            <S.NotificationWrapper $right={0} $top={-5}>
              {item.notification}
            </S.NotificationWrapper>
          )}
          {expanded && !!item?.subitemGroups?.length && (
            <S.SubItemsWrapper>
              {item.subitemGroups.map((subitemGroup, i) => (
                <Fragment key={i}>
                  {subitemGroup.map(subitem => (
                    <S.SubItem
                      key={subitem.key}
                      $isActive={activeSubItem === subitem.key}
                      data-testid={`subitem-${subitem.key}`}
                      onClick={() => {
                        subitem.onClick?.(subitem, item);
                      }}
                      disabled={subitem.disabled}
                      $variant={variant}
                    >
                      <S.ItemLeft>
                        {subitem.icon && (
                          <Icon iconName={subitem.icon} size={12} />
                        )}
                        <S.MainLabel>{subitem.label}</S.MainLabel>
                      </S.ItemLeft>
                      {subitem.badge}
                    </S.SubItem>
                  ))}
                  {shouldShowSeparator(i, subitemGroup) && (
                    <S.SubItemSeparator data-testid="subitem-separator" />
                  )}
                </Fragment>
              ))}
            </S.SubItemsWrapper>
          )}
          <S.ItemBackground />
        </S.ItemWrapper>
      </Tooltip>
      {showBottomBorder && <S.Separator />}
    </>
  );
};

NavItem.Styled = S;
