import { breakpoints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ProgressBar } from '~/components/progress-bar/ProgressBar';
import { SplashLoader } from '~/components/splash-loader/SplashLoader';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5%;
  @media ${breakpoints.xl} {
    gap: 100px;
  }
`;

export const StyledSplashLoader = styled(SplashLoader)`
  width: max(20%, 220px);
  align-self: center;
  margin-top: 20px;
`;

export const Label = styled.p`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const StyledProgressBar = styled(ProgressBar)<{ $isLast: boolean }>`
  --line-width: ${props => (props.$isLast ? '98%' : '100%')};

  --timing-function: ${props =>
    props.$isLast ? 'cubic-bezier(0.02, 0.25, 0.7, 0.74)' : 'linear'};
`;

export const WarningContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  border-left: 4px solid var(--border-default);
  background: var(--surface-default);
  display: flex;
  gap: 12px;
`;

export const DescriptionWrapper = styled.div`
  flex: 1;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WarningTitle = styled.div`
  ${typographyV2.titleSm}
`;

export const Close = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 5px;

  svg {
    fill: var(--icon-soft);
  }

  &:hover svg {
    fill: var(--icon-default);
  }
`;

export const WarningTextContent = styled.div`
  ${typographyV2.bodySm}
  margin-top: 4px;
  margin-bottom: 12px;
`;
