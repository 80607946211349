import { typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const ListTitle = styled.p<{ $variant: 'default' | 'secondary' }>`
  ${typographyV2.titleXs};
  color: var(--text-soft);
  padding: 24px 8px 8px 8px;

  ${props =>
    props.$variant === 'secondary' &&
    css`
      padding-top: 8px;
      padding-bottom: 0px;
    `}
`;

export const TitleAndDividerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalDivider = styled.div`
  position: relative;
  left: -8px;
  margin-right: -16px;
  height: 1px;
  background-color: var(--border-soft);
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const HideOrShowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
