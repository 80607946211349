import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import {
  news,
  NewsFilters,
  NewsListResponse,
  SingleNews,
} from '@toggle/toggle';

import { queryClient } from '~/services/query-client/queryClient';
import { apiFetch, wretchRequest } from '~/utils/api-fetch/apiFetch';

export const fetchSingleNews = (id: string, signal?: AbortSignal) =>
  wretchRequest<SingleNews>(news.singleNews.path(id), {
    method: 'get',
    signal,
  });

export type FetchNewsFiltersProps = {
  query?: string;
  highlights?: string[];
  newsFilters?: Record<string, string[]>;
  date_range?: {
    from?: string;
    to?: string;
  };
  page?: {
    number: number;
    size?: number;
  };
  sort_by?: {
    field?: 'score' | 'published';
    desc?: boolean;
  };
};

export const fetchNewsArticles = (
  {
    query,
    newsFilters,
    highlights,
    date_range,
    page,
    sort_by,
  }: FetchNewsFiltersProps,
  signal?: AbortSignal
) => {
  return wretchRequest<NewsListResponse>(news.newsSearch.path, {
    method: 'post',
    body: {
      query,
      filters: {
        ...(newsFilters && Object.keys(newsFilters).length && newsFilters),
        ...(!!highlights?.length && { highlights }),
        date_range,
      },
      page,
      sort_by,
    },
    signal,
  });
};

export const QUERY_NEWS_FILTERS = 'QUERY_NEWS_FILTERS';

export const fetchNewsFilters = async () =>
  queryClient.fetchQuery({
    queryKey: [QUERY_NEWS_FILTERS],
    queryFn: () =>
      apiFetch<NewsFilters>(news.filtersV4.path, {
        method: 'get',
      }),
    staleTime: getTimeTillEndOfDayMs(),
  });

export const fetchProvidersNewsFilter = async () => {
  const { data: newsFilters = [] } = await fetchNewsFilters();

  const providersNewsFilter = newsFilters.find(
    filter => filter.key === 'providers'
  );
  const otherFilters = newsFilters.filter(filter => filter.key !== 'providers');

  return { providersNewsFilter, otherFilters };
};
