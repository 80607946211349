import styled, { css } from 'styled-components';

import { customScrollMinimal } from '~/components/custom-scroll/CustomScroll.styles';
import { DropdownRadioGroup } from '~/components/dropdown-menu/dropdown-radio-group/DropdownRadioGroup';
import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';
import { timingFunction } from '~/utils/transitions';

import { NavItemVariants } from './NavItem';

export const IconWrapper = styled.span`
  display: flex;
  svg {
    fill: var(--icon-default);
  }
`;

export const MainLabel = styled.span`
  color: var(--text-default);
`;

export const BadgeWrapper = styled.span`
  margin-left: auto;
`;

export const ItemWrapper = styled.div<{
  $isActive: boolean;
  $variant: NavItemVariants;
}>`
  position: relative;
  width: 100%;
  margin-right: 8px;
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 0 2px 4px 0;
    height: 100%;
    width: 4px;
    background-color: var(--surface-primary);
    transform: translate(-100%);
    opacity: 0;
    transition: transform 300ms ${timingFunction.base};
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      ${ItemBackground} {
        background-color: var(--surface-default);
      }
    `}
  ${({ $variant }) =>
    ($variant === 'secondary' || $variant === 'tertiary') &&
    css`
      ${ItemBackground} {
        border-radius: 100px;
      }
    `}
  ${({ $variant, $isActive }) =>
    $variant === 'default' &&
    $isActive &&
    css`
      &::before {
        opacity: 1;
        transform: translate(0);
      }
    `}
`;

export const ItemBackground = styled.div`
  ${borderRadius.r1};
  position: absolute;
  inset: 0;
  pointer-events: none;
  margin-left: 8px;
  width: calc(100% - 16px);
  transition: transform 300ms ${timingFunction.base};
`;

export const Item = styled.button<{
  $isActive: boolean;
  $variant: NavItemVariants;
}>`
  ${typographyV2.bodySm};
  ${borderRadius.r1};
  position: relative;
  z-index: 1;
  background: none;
  border: none;
  text-wrap: nowrap;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 16px);
  height: 40px;
  margin-left: 8px;
  padding: 8px 16px;
  &:disabled {
    pointer-events: none;
    svg {
      fill: var(--icon-disabled);
    }
    ${MainLabel} {
      color: var(--text-disabled);
    }
  }
  &:hover,
  &:focus-visible {
    background-color: var(--surface-hover);
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      ${MainLabel} {
        color: var(--text-primary);
      }
      svg {
        fill: var(--icon-primary);
        path {
          fill: var(--icon-primary);
        }
      }
    `}
  ${({ $variant }) =>
    $variant === 'secondary' &&
    css`
      border-radius: 100px;
    `}
  ${({ $variant }) =>
    $variant === 'tertiary' &&
    css`
      ${MainLabel} {
        color: var(--text-primary);
      }
      svg {
        fill: var(--icon-primary);
      }

      &:hover,
      &:focus-visible {
        border-radius: 100px;
        background-color: var(--surface-default);
      }
    `}
`;

export const ItemLeft = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Separator = styled.hr`
  height: 1px;
  width: 100%;
  background-color: var(--border-soft);
  border: none;
`;

export const SubItemSeparator = styled(Separator)`
  position: relative;
  z-index: 2;
  width: 182px;
  margin: 4px 0;
`;
export const SubItemsWrapper = styled.div`
  margin: 8px 0 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SubItem = styled(Item)`
  margin-left: 4px;
  width: calc(100% - 20px);
  padding: 6px 6px 6px 28px;
  height: auto;
  z-index: 1;
  ${borderRadius.r1}
  color: var(--text-default);
  &:hover,
  &:focus-visible {
    background-color: var(--surface-hover);
  }
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--surface-active)' : 'inherit'};
  color: ${({ $isActive }) =>
    $isActive ? 'var(--text-primary)' : 'var(--text-default)'};
`;

export const DropdownSubitemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDropdownWrapper = styled.div`
  ${DropdownRadioGroup.Styled.RadioGroup} {
    ${customScrollMinimal};
    z-index: 5;
  }
  padding-top: 8px;
`;

export const NotificationWrapper = styled.span<{
  $right?: number;
  $top: number;
}>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  right: ${({ $right }) => $right}px;
  z-index: var(--z-tooltip);
`;
