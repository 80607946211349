/**
 * Simple typed version of uniqBy from lodash. This accepts a comparison key from the array you pass in that is used to check for uniqueness.
 *
 * Essentially it removes duplicates from arrays based on a key.
 * @param arr Array that you want to check.
 * @param key Key from the array that you want to check.
 * @returns
 */
export const uniqueBy = <T>(arr: T[], key: keyof T) => {
  const seen = new Set<T[keyof T]>();
  return arr.filter(item => {
    const k = item[key];
    return !seen.has(k) && seen.add(k);
  });
};

export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
