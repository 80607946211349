import { useScrollListener } from '@toggle/helpers';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ListDivider } from '~/components/list-divider/ListDivider';
import { NewsThumbnail } from '~/components/news-thumbnail/NewsThumbnail';
import { NewsThumbnailSkeleton } from '~/components/news-thumbnail-skeleton/NewsThumbnailSkeleton';
import { useArticleDrawer } from '~/hooks/use-article-drawer/useArticleDrawer';
import { useSearchNews } from '~/views/news/hooks/use-search-news/useSearchNews';
import { createNewsApiPayload } from '~/views/news/utils/create-news-api-payload/createNewsApiPayload';
import { ArticleDrawer } from '~/widgets/news-widget/ArticleDrawer';

import { useNewsSearchStore } from '../../stores/use-news-search-store/useNewsSearchStore';
import { getFiltersFromActiveOptions, getUniqueNews } from '../../utils/utils';
import { NewsLoadingState } from '../news-loading-state/NewsLoadingState';
import { SearchEmptyStates } from '../search-empty-states/SearchEmptyStates';
import { SearchFilters } from '../search-filters/SearchFilters';
import { SortDropdown } from '../sort-dropdown/SortDropdown';
import * as S from './SearchResults.styles';

export interface SearchResultsProps {
  searchQuery: string;
}

export const SearchResults = ({ searchQuery }: SearchResultsProps) => {
  const { t } = useTranslation('news');
  const { singleNews, setSingleNews, closeArticleDrawer } = useArticleDrawer();

  const containerRef = useRef<HTMLDivElement>(null);
  const { accordionFilters, activeFilterOptions, sortBy, clearActiveFilters } =
    useNewsSearchStore(state => ({
      activeFilterOptions:
        state.filterGroups.NEWS_SEARCH_FILTERS.activeFilterOptions,
      accordionFilters: state.accordionFilters,
      sortBy: state.sortBy,
      clearActiveFilters: state.clearActiveFilters,
    }));

  const { data, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage } =
    useSearchNews({
      query: searchQuery,
      sort_by: sortBy,
      filters: {
        ...getFiltersFromActiveOptions(activeFilterOptions),
        ...createNewsApiPayload(accordionFilters),
      },
    });

  const uniqueNews = getUniqueNews(data?.pages);

  useEffect(() => {
    return () => {
      clearActiveFilters();
    };
  }, [clearActiveFilters]);

  useScrollListener(
    containerRef,
    () => {
      if (hasNextPage) {
        fetchNextPage();
      }

      return hasNextPage;
    },

    [hasNextPage],
    { isGlobalScrollListened: true, offset: 40 }
  );

  return (
    <S.SearchResultsRoot data-testid="search-results" ref={containerRef}>
      <S.FiltersWrapper>
        <S.Filters>
          <SearchFilters />
          <SortDropdown />
        </S.Filters>
      </S.FiltersWrapper>

      <S.List>
        {isLoading && <NewsLoadingState />}

        {!isLoading && !uniqueNews.length && <SearchEmptyStates />}

        {uniqueNews.map(singleNews => (
          <NewsThumbnail
            key={singleNews.id}
            newsData={singleNews}
            onClick={setSingleNews}
            highlighted={searchQuery}
          />
        ))}

        {hasNextPage && isFetchingNextPage && <NewsThumbnailSkeleton />}
        {!hasNextPage && !isLoading && !!uniqueNews.length && (
          <ListDivider label={t('news:endOfResults')} />
        )}
      </S.List>

      {singleNews && (
        <ArticleDrawer
          newsId={singleNews.id}
          list={uniqueNews}
          onClick={setSingleNews}
          onClose={closeArticleDrawer}
        />
      )}
    </S.SearchResultsRoot>
  );
};
