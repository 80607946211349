import { ChartAPIReturn, EventsConfig } from '@toggle/chart';
import React, { FC, RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryInsights } from '~/hooks/use-insight/useInsight';

import { useInsightsInDomain } from '../../hooks/use-insights-in-domain/useInsightsInDomain';
import { ChartInsightIcon } from './chart-insight-icon/ChartInsightIcon';
import * as S from './Insights.styles';
import { mapInsightsToDetails } from './utils/inisights-utils/insights-utils';
import {
  getNextIndexes,
  getPrevIndexes,
} from './utils/slides-utils/slides-utils';

export interface InsightsProps {
  chartApiRef: RefObject<ChartAPIReturn | null>;
  canvasRef: RefObject<HTMLCanvasElement | null>;
  articleInsightsIds: string[];
  eventsConfig: EventsConfig;
  isSimpleChart?: boolean;
}

export const Insights: FC<InsightsProps> = ({
  chartApiRef,
  canvasRef,
  articleInsightsIds,
  eventsConfig,
  isSimpleChart = false,
}) => {
  const { t } = useTranslation('chart');
  const [isPrevBtnEnabled, setIsPrevBtnEnabled] = useState(false);
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(false);

  const insights = useQueryInsights(articleInsightsIds); // with the metadata.last_value_date
  const {
    insightsInDomain,
    activeInsightsGroup,
    zoomIntoViewText,
    handleSetActiveInsightsGroup,
  } = useInsightsInDomain({
    canvasRef,
    eventsConfig,
    chartApiRef,
    articleInsights: insights.data,
  });

  const updateActiveInsightsGroup = (
    insightsInDomainIndex: number,
    groupedInsightsIndex: number
  ) => {
    const groupedByDate = insightsInDomain[insightsInDomainIndex];
    const groupedByType = groupedByDate.groupedInsights[groupedInsightsIndex];

    const insightsDetails = mapInsightsToDetails(
      groupedByType.insights,
      insights.data
    );

    handleSetActiveInsightsGroup({
      insightsDetails,
      groupedInsightsIndex,
      insightsInDomainIndex,
    });

    setIsNextBtnEnabled(
      insightsInDomainIndex !== insightsInDomain.length - 1 ||
        groupedInsightsIndex !== groupedByDate.groupedInsights.length - 1
    );
    setIsPrevBtnEnabled(
      insightsInDomainIndex !== 0 || groupedInsightsIndex !== 0
    );
  };

  const onArrowClick = (
    direction: 'next' | 'prev',
    insightsByDateIndex: number,
    insightsByTypeIndex: number
  ) => {
    let indexes;

    if (direction === 'next') {
      const insightsByDateLength = insightsInDomain.length;
      const insightsByTypeLength =
        insightsInDomain[insightsByDateIndex].groupedInsights.length;

      indexes = getNextIndexes(
        insightsByDateIndex,
        insightsByTypeIndex,
        insightsByDateLength,
        insightsByTypeLength
      );
    } else {
      const prevInsightsByDateIndex = insightsByDateIndex - 1;
      const prevNestedArrayLength =
        insightsInDomain[prevInsightsByDateIndex].groupedInsights.length;

      indexes = getPrevIndexes(
        insightsByDateIndex,
        insightsByTypeIndex,
        prevNestedArrayLength
      );
    }

    updateActiveInsightsGroup(indexes.mainIndex, indexes.nestedIndex);
  };

  const onClose = () => {
    handleSetActiveInsightsGroup(null);
  };

  return (
    <>
      {insightsInDomain.map((insightsInDomain, insightsInDomainIndex) =>
        insightsInDomain.groupedInsights.map(
          (insightsGroupByType, groupedInsightsIndex) => (
            <ChartInsightIcon
              key={insightsGroupByType.type}
              activeInsightsGroup={activeInsightsGroup}
              groupedInsights={insightsGroupByType}
              groupedInsightsIndex={groupedInsightsIndex}
              insightsInDomainIndex={insightsInDomainIndex}
              hasStackedInsights={insightsInDomain.groupedInsights.length > 1}
              insightIconRight={insightsInDomain.right}
              insightIconTop={insightsInDomain.top}
              isNextBtnEnabled={isNextBtnEnabled}
              isPrevBtnEnabled={isPrevBtnEnabled}
              isSimpleChart={isSimpleChart}
              onArrowClick={onArrowClick}
              onClose={onClose}
              onClick={updateActiveInsightsGroup}
            />
          )
        )
      )}
      {zoomIntoViewText && (
        <S.ZoomIntoViewText
          data-testid="zoom-in-required"
          style={{
            top: zoomIntoViewText.y,
            right: zoomIntoViewText.right,
          }}
        >
          {t('chart:zoomInToView')}
        </S.ZoomIntoViewText>
      )}
    </>
  );
};
