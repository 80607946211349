import { BillingCycle, DowngradeReason } from '@toggle/toggle';
import React from 'react';
import {
  createSearchParams,
  matchPath,
  Navigate,
  useLocation,
} from 'react-router';

import { AppRoute } from '~/routes';
import {
  accountRoutes,
  appPaths,
  authPaths,
  onHoldReason,
} from '~/routes/app-paths';
import { useAuth } from '~/stores/use-auth/useAuth';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import {
  ChangingPlanTier,
  PlanCardType,
} from '~/views/settings/subscription/plan-matrix/utils/plan-matrix.types';

import { View } from '../view/View';

const checkoutRoute = `${appPaths.settings.subscription}/${accountRoutes.checkout}`;
const checkoutPage = `${checkoutRoute}?${createSearchParams({
  billingCycle: BillingCycle.year,
  tier: ChangingPlanTier.Initial,
  type: PlanCardType.Pro,
})}`;

export interface AccountCheckViewProps {
  config: AppRoute;
}

export const AccountCheckView = ({ config }: AccountCheckViewProps) => {
  const location = useLocation();
  const {
    isSubscriptionOnRoll,
    hasCard,
    isFromBO,
    downgradeReason,
    isTrialUser,
  } = useSubscription(state => ({
    isSubscriptionOnRoll: state.isSubscriptionOnRoll(),
    hasCard: !!state.card,
    isFromBO: state.isFromBO(),
    isTerminal: state.isTerminal(),
    isTrialUser: state.isTrialUser(),
    downgradeReason: state.getDowngradeReason(),
  }));
  const { isLocked, isInitError, isInitSignUp } = useAuth();

  const isCheckoutPage = !!matchPath(
    { path: checkoutRoute, end: false, caseSensitive: false },
    location.pathname
  );
  const isOnHoldPage = !!matchPath(
    { path: appPaths.accountOnHold, end: false, caseSensitive: false },
    location.pathname
  );

  const isSubscriptionCheckoutPage = checkoutRoute === location.pathname;
  const isErrorPage = appPaths.error === location.pathname;
  const shouldShowOnHoldSubscription =
    !isLocked &&
    !isSubscriptionCheckoutPage &&
    appPaths.accountOnHold !== location.pathname &&
    !isErrorPage;

  const shouldRedirectToOnHoldSubscription = () => {
    return !isSubscriptionOnRoll && shouldShowOnHoldSubscription;
  };

  const shouldRedirectToOnHoldTrialSubscription = () => {
    const isDowngradedByTrialEnd =
      downgradeReason === DowngradeReason.TrialEnds;

    return isDowngradedByTrialEnd && shouldShowOnHoldSubscription;
  };

  // Check if user verified their email
  if (
    isLocked &&
    appPaths.accountOnHold !== location.pathname &&
    appPaths.verifyEmail !== location.pathname
  ) {
    return (
      <Navigate
        to={`${appPaths.accountOnHold}?reason=${onHoldReason.email}`}
        replace
      />
    );
  }

  // Check if trial has ended
  if (shouldRedirectToOnHoldTrialSubscription()) {
    return (
      <Navigate
        to={`${appPaths.accountOnHold}?reason=${onHoldReason.trial}`}
        replace
      />
    );
  }

  // Check if user payed their subscription
  if (shouldRedirectToOnHoldSubscription()) {
    return (
      <Navigate
        to={`${appPaths.accountOnHold}?reason=${onHoldReason.subscription}`}
        replace
      />
    );
  }

  // Post signup redirection to checkout page to force user to fill payment method
  if (isInitSignUp && !hasCard && !isCheckoutPage) {
    return <Navigate to={checkoutPage} replace />;
  }

  // Check that user filled payment method
  const isPaymentLockedState =
    !isInitSignUp && isTrialUser && !hasCard && !isFromBO;

  if (isPaymentLockedState && !isOnHoldPage && !isCheckoutPage) {
    return (
      <Navigate
        to={`${appPaths.accountOnHold}?reason=${onHoldReason.payment}`}
        replace
      />
    );
  }

  if (appPaths.error === location.pathname && !isInitError) {
    return <Navigate to={appPaths.base} replace />;
  }

  const accountIsLocked = isLocked || !isSubscriptionOnRoll;

  if (
    !accountIsLocked &&
    !isPaymentLockedState &&
    [appPaths.accountOnHold].includes(location.pathname)
  ) {
    return <Navigate to={appPaths.base} replace />;
  }

  const showSidebar =
    !accountIsLocked &&
    !isPaymentLockedState &&
    ![
      checkoutRoute,
      authPaths.resetPassword,
      authPaths.forgotPassword,
      appPaths.verifyEmail,
    ].includes(location.pathname);

  return <View config={config} showSidebar={showSidebar} />;
};
