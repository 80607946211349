export enum ScreenerEvents {
  GovTrades = 'gov_trades',
  InsiderTrades = 'insider_trades',
  Ipos = 'ipos',
}

export const eventToDisplayName: Record<ScreenerEvents, string> = {
  [ScreenerEvents.GovTrades]: 'Government Trades',
  [ScreenerEvents.InsiderTrades]: 'Insider Trades',
  [ScreenerEvents.Ipos]: 'Public Offerings',
};
