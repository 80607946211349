import React, { FC, useEffect, useRef } from 'react';

import { highlightElement } from '../document-highlight-utils/document-highlight-utils';
import { DocumentViewerContainer } from '../document-viewer-container/DocumentViewerContainer';
import { HtmlViewer } from '../DocumentViewer';
import * as S from './DocumentHtmlViewer.styles';

const shouldAddSpace = (content: string, currentNode: Node, prevNode?: Node) =>
  !!prevNode &&
  !content.endsWith(' ') &&
  !prevNode.textContent?.replace(/\s\s*/g, ' ').endsWith(' ') &&
  (currentNode.parentNode?.parentNode !== prevNode.parentNode?.parentNode ||
    (currentNode.parentNode !== prevNode.parentNode &&
      (currentNode.parentNode as HTMLElement | undefined)?.tagName === 'P'));

const nodeFilter: NodeFilter = node =>
  node.parentNode?.textContent === 'Table of Contents' ||
  (node.parentNode?.parentNode as HTMLElement | undefined)?.tagName === 'TD' ||
  (node.parentNode?.parentNode?.parentNode as HTMLElement | undefined)
    ?.tagName === 'TD' ||
  (node.parentNode?.parentNode as HTMLElement | undefined)?.style?.[
    'textAlign'
  ] === 'center'
    ? NodeFilter.FILTER_SKIP
    : NodeFilter.FILTER_ACCEPT;

export const DocumentHtmlViewer: FC<HtmlViewer> = ({
  highlightTexts,
  data,
  name,
  scrollToPrimaryText,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const docNameRef = useRef(name);
  docNameRef.current = name;

  useEffect(() => {
    if (!ref.current || !highlightTexts[0]?.pages) {
      return undefined;
    }

    const container = ref.current;
    const targetPages = highlightTexts[0].pages;

    const firstPage = targetPages[0];
    const lastPage = targetPages[targetPages.length - 1];

    const firstPageHr =
      container.querySelectorAll('hr')[Math.max(0, firstPage - 3)];
    const lastPageHr =
      container.querySelectorAll('hr')[Math.max(0, lastPage + 2)];

    const childrenArr = [...container.children];
    const firstPageHrIndex = childrenArr.findIndex(n => n === firstPageHr);
    const lastPageHrIndex = childrenArr.findIndex(n => n === lastPageHr);

    const nodesSlice = childrenArr.slice(
      firstPageHrIndex + 1,
      lastPageHrIndex === -1 ? childrenArr.length - 1 : lastPageHrIndex
    );

    const fragment = document.createDocumentFragment();
    const copyFragment = document.createDocumentFragment();
    nodesSlice.forEach(node => {
      fragment.append(node);
      copyFragment.append(node.cloneNode(true));
    });

    requestAnimationFrame(() => {
      highlightElement({
        part: highlightTexts[0].text,
        container: fragment,
        shouldAddSpace,
        replaceNodeValue: v => v.replace(/\s\s*/g, ' '),
        filter: nodeFilter,
      });

      container?.insertBefore(
        fragment,
        lastPageHrIndex === -1 ? null : lastPageHr
      );

      const fallbackHr =
        container.querySelectorAll('hr')[Math.max(0, firstPage - 2)];
      scrollToPrimaryText(fallbackHr);
    });

    return () => {
      if (!ref.current || docNameRef.current !== name) {
        return;
      }

      const container = ref.current;

      if (nodesSlice.length && container.contains(nodesSlice[0])) {
        container.insertBefore(copyFragment, nodesSlice[0]);
      } else {
        container.appendChild(copyFragment);
      }

      nodesSlice.forEach(node => {
        if (container.contains(node)) {
          container.removeChild(node);
        }
      });
    };
  }, [highlightTexts]);

  const openNewWindowWithHtml = () => {
    const metaTag = '<meta charset="UTF-8">';
    const htmlWithMetaTag = data.replace('<head>', `<head>${metaTag}`);
    const htmlBlob = new Blob([htmlWithMetaTag], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(htmlBlob);
    const newWindow = window.open(blobUrl, '_blank', 'noopener,noreferrer');

    if (newWindow) {
      newWindow.opener = null;
    }

    setTimeout(() => URL.revokeObjectURL(blobUrl), 0);
  };

  return (
    <DocumentViewerContainer name={name} onClick={openNewWindowWithHtml}>
      <S.StyledContainer ref={ref} dangerouslySetInnerHTML={{ __html: data }} />
    </DocumentViewerContainer>
  );
};
