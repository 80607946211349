import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import { queryKeys } from '~/routes/app-paths';
import { useWatchlistManagerWidget } from '~/stores/use-watchlist-manager-widget/useWatchlistManagerWidget';
import { WatchlistManagerWidget } from '~/widgets/watchlist-manager-widget/WatchlistManagerWidget';

import * as S from './WatchlistManagerModal.styles';

export const WatchlistManagerModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsAddMode, setOnConfirmCallback, setShouldResetList } =
    useWatchlistManagerWidget(state => ({
      setIsAddMode: state.setIsAddMode,
      setOnConfirmCallback: state.setOnConfirmCallback,
      setShouldResetList: state.setShouldResetList,
    }));

  const onClose = () => {
    searchParams.delete(queryKeys.widget);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    return () => {
      setIsAddMode();
      setOnConfirmCallback();
      setShouldResetList();
    };
  }, []);

  return (
    <S.StyledModal
      data-testid="watchlist-manager-modal"
      isOpen
      onClose={onClose}
    >
      <WatchlistManagerWidget onListCreated={onClose} onCancel={onClose} />
    </S.StyledModal>
  );
};
