import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { Country, Product, Theme } from '@toggle/toggle';
import { WretchError } from 'wretch/types';

import {
  fetchBrandAndProducts,
  fetchCountryExposure,
  fetchThemeExposure,
} from '~/services/overview-widget/overview-widget-service';
import { fetchRelatedCompanies } from '~/services/related-companies/related-companies-service';

export const QUERY_KEY_THEME_EXPOSURE = 'QUERY_KEY_THEME_EXPOSURE';
export const useThemeExposure = (entityTag: string, isEtf: boolean) =>
  useQuery<Theme[], WretchError>({
    queryKey: [QUERY_KEY_THEME_EXPOSURE, entityTag],
    queryFn: ({ signal }) => fetchThemeExposure({ entityTag, isEtf }, signal),
    staleTime: getTimeTillEndOfDayMs(),
  });

export const QUERY_KEY_COUNTRY_EXPOSURE = 'QUERY_KEY_COUNTRY_EXPOSURE';
export const useCountryExposure = (entityTag: string) =>
  useQuery<Country[], WretchError>({
    queryKey: [QUERY_KEY_COUNTRY_EXPOSURE, entityTag],
    queryFn: ({ signal }) => fetchCountryExposure(entityTag, signal),
    staleTime: getTimeTillEndOfDayMs(),
  });

export const QUERY_KEY_RELATED_COMPANIES = 'QUERY_KEY_RELATED_COMPANIES';
export const useRelatedCompanies = (
  entityTag: string,
  enabled: boolean = true
) =>
  useQuery({
    queryKey: [QUERY_KEY_RELATED_COMPANIES, entityTag],
    queryFn: () => fetchRelatedCompanies(entityTag),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: enabled,
  });

export const QUERY_KEY_BRAND_AND_PRODUCTS = 'QUERY_KEY_BRAND_AND_PRODUCTS';
export const useBrandAndProducts = (entityTag: string) =>
  useQuery<Product[], WretchError>({
    queryKey: [QUERY_KEY_BRAND_AND_PRODUCTS, entityTag],
    queryFn: () => fetchBrandAndProducts(entityTag),
  });

export const useKnowledgeGraph = (entityTag: string, isEtf: boolean) => {
  const brandAndProducts = useBrandAndProducts(entityTag);
  const relatedCompanies = useRelatedCompanies(entityTag);
  const countryExposure = useCountryExposure(entityTag);
  const themeExposure = useThemeExposure(entityTag, isEtf);

  const competitorsError =
    relatedCompanies.isError || !relatedCompanies.data?.competitors.length;
  const suppliersError =
    relatedCompanies.isError || !relatedCompanies.data?.suppliers.length;
  return {
    isError:
      brandAndProducts.isError &&
      countryExposure.isError &&
      themeExposure.isError &&
      competitorsError &&
      suppliersError,
  };
};
