import { z } from 'zod';

import { mappedEntitySchema } from './entity-schema';
/**
 * For earnings/v1/entity endpoint
 */
const values = z.object({
  value: z.number(),
  estimate: z.number(),
  surprise: z.number(),
  surprise_percentage: z.number(),
});

const movePeriod = z.enum(['d1', 'd2', 'w1', 'w2', 'm1']);

const price = z
  .array(
    z.object({
      period: movePeriod,
      change: z.number(),
    })
  )
  .optional();

export const period = z.object({
  reported: z.boolean(),
  reporting_date: z.string(),
  fiscal_period: z.string(),
  fiscal_year: z.number(),
  currency: z.string(),
  eps: values.partial(),
  revenue: values,
  price,
});

const earningsForEntityResults = z.object({ earnings: z.array(period) });
const earningsForEntityPeriod = z.array(period);

export type EarningsForEntityResults = z.infer<typeof earningsForEntityResults>;
export type EarningsPeriods = z.infer<typeof earningsForEntityPeriod>;
export type EarningsPeriod = z.infer<typeof period>;
export type EarningsValue = z.infer<typeof values>;
export type PriceMove = z.infer<typeof price>;
export type MovePeriod = z.infer<typeof movePeriod>;

// For earnings/v1/{{exchange}}/{{entity}} endpoint

const scenarioDataSchema = z.object({
  period: movePeriod,
  low: z.number(),
  median: z.number(),
  high: z.number(),
});

const scenarioHistoryMetaSchema = z.object({
  count: z.number(),
  medians: z.object({
    w1: z.number(),
    w2: z.number(),
    m1: z.number(),
  }),
});

const scenarioHistorySchema = z.object({
  meta: scenarioHistoryMetaSchema,
});

export const scenarioSchema = z.object({
  data: z.array(scenarioDataSchema),
  history: scenarioHistorySchema.nullable(),
});

export const earningSchema = z.object({
  value: z.number().optional(),
  estimate: z.number().optional(),
  surprise: z.number().optional(),
  surprise_percentage: z.number().optional(),
});

export const earningsScenarioSchema = z.object({
  company_name: z.string(),
  exchange: z.string(),
  ticker: z.string(),
  reported: z.boolean(),
  reporting_date: z.string(),
  fiscal_period: z.string(),
  fiscal_year: z.number(),
  currency: z.string(),
  eps: earningSchema,
  revenue: earningSchema,
  scenarios: z.object({
    beat: scenarioSchema.optional().nullable(),
    miss: scenarioSchema.optional().nullable(),
    actual: scenarioSchema.optional().nullable(),
  }),
});

export type Earning = z.infer<typeof earningSchema>;
export type EarningsScenarioResponse = z.infer<typeof earningsScenarioSchema>;
export type EarningsScenario = z.infer<typeof scenarioSchema>;

/**
 * For earnings/v1/filter/stats endpoint
 */
export const earningsFilterStatsRequestSchema = z.object({
  start_date: z.string(),
  end_date: z.string(),
  watchlists: z
    .object({
      all: z.boolean(),
      identities: z.array(z.string()).optional(),
    })
    .optional(),
});
export type EarningsFilterStatsRequest = z.infer<
  typeof earningsFilterStatsRequestSchema
>;

const earningsFilterStatsResponseSchema = z.object({
  upcoming: z.array(
    z.object({
      date: z.string(),
      count: z.number(),
    })
  ),
  reported: z.array(
    z.object({
      date: z.string(),
      count: z.number(),
    })
  ),
});

export type EarningsFilterStatsResponse = z.infer<
  typeof earningsFilterStatsResponseSchema
>;

/**
 * For earnings/v1/filter endpoint
 */
export const earningsFilterRequestSchema =
  earningsFilterStatsRequestSchema.extend({
    sort_by: z
      .object({
        field: z.string(),
        desc: z.boolean(),
      })
      .optional(),
  });
export type EarningsFilterRequest = z.infer<typeof earningsFilterRequestSchema>;

const financialDataSchema = z.object({
  value: z.number().optional(),
  estimate: z.number().optional(),
  surprise: z.number().optional(),
  surprise_percentage: z.number().optional(),
});

export const earningsItemSchema = z.object({
  entity_tag: z.string(),
  exchange: z.string(),
  reported: z.boolean(),
  reporting_date: z.string(), // ISO 8601 date string
  fiscal_period: z.string(),
  fiscal_year: z.number(),
  currency: z.string(),
  eps: financialDataSchema,
  revenue: financialDataSchema,
});

export const earningsItemWithEntitySchema = earningsItemSchema.extend({
  entity: mappedEntitySchema.optional(),
});

export type EarningsItem = z.infer<typeof earningsItemSchema>;
export type EarningsItemWithEntity = z.infer<
  typeof earningsItemWithEntitySchema
>;

export const earningsFilterResponseSchema = z.object({
  earnings: z.array(earningsItemSchema),
});

export type EarningsFilterResponse = z.infer<
  typeof earningsFilterResponseSchema
>;

type GetEarningsScenarioProps = {
  exchange: string;
  ticker: string;
  year?: number;
  period?: string;
};

const earningsBasePath = 'earnings/v1/';
export const earnings = {
  getEarningsForEntity: {
    path: (entityTag: string) => `${earningsBasePath}entity/${entityTag}`,
    schema: earningsForEntityResults,
  },
  postEarningsFilterStats: {
    path: `${earningsBasePath}filter/stats`,
    schema: earningsFilterStatsResponseSchema,
  },
  postEarningsFilter: {
    path: `${earningsBasePath}filter`,
    schema: earningsFilterResponseSchema,
  },
  getEarningsScenario: {
    path: ({ exchange, ticker, year, period }: GetEarningsScenarioProps) => {
      if (year && period) {
        return `${earningsBasePath}${exchange}/${ticker}?year=${year}&period=${period}`;
      }

      return `${earningsBasePath}${exchange}/${ticker}`;
    },

    schema: earningsScenarioSchema,
  },
};
