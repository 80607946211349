import { watchlist } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

export const fetchPublicLists = () => {
  return wretchRequest(watchlist.getPublicLists.path(), {
    schema: watchlist.getPublicLists.schema,
  });
};

interface FetchPublicListEntitiesParams {
  listId: string;
  signal?: AbortSignal;
}

export const fetchPublicListEntities = async ({
  listId,
  signal,
}: FetchPublicListEntitiesParams) => {
  return await wretchRequest(watchlist.getPublicListEntities.path(listId), {
    schema: watchlist.getPublicListEntities.schema,
    options: { signal },
  });
};
