/* eslint-disable max-lines-per-function */
import {
  DocumentEntityItem,
  EarningsPeriod,
  EarningsPeriods,
  ScreenerFilters,
} from '@toggle/toggle';
import { format, isAfter, isBefore } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { TFunction } from 'i18next';
import { z } from 'zod';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { ToggleFilterProps } from '~/components/update-filter-options/updateFilterOptions';

export const SELECTED_COLUMN_ID = 'rowSelected';
export const EARNINGS_COLUMN_ID = 'earnings';
export const RELEASED_ON_COLUMN_ID = 'releasedOn';
export const QNA_COLUMN_ID = 'Q&A';
export const EXPECTATION_KEY = 'earnings';
export const AFTER_MARKET_HOURS = 'after_market_hours';
export const BEFORE_MARKET_HOURS = 'before_market_hours';

export const EARNINGS_COL_WIDTH = 90;

const MIN_DATE = '1900-01-01';
const MAX_DATE = '2099-12-31';

export const formatNumberWithUnit = (
  num: number | undefined,
  locale?: string | undefined
) => {
  if (num === undefined) {
    return '-';
  }

  const abs = Math.abs(num);

  if (abs === 0) {
    return '0';
  }

  if (abs / 1e12 >= 1) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }

  if (abs / 1e9 >= 100) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);
  }

  if (abs / 1e9 >= 10) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num);
  }

  if (abs / 1e9 >= 1) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }

  if (abs / 1e6 >= 100) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      maximumFractionDigits: 0,
    }).format(num);
  }

  if (abs / 1e6 >= 10) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num);
  }

  if (abs / 1e6 >= 1) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }

  if (abs / 1e3 >= 100) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      maximumFractionDigits: 0,
    }).format(num);
  }

  if (abs / 1e3 >= 10) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num);
  }

  if (abs / 1e3 >= 1) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  }

  if (abs >= 100) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);
  }

  if (abs >= 10) {
    return Intl.NumberFormat(locale, {
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num);
  }

  return Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export const formatPercentageNumber = (num?: number) => {
  if (num === undefined) {
    return '-';
  }

  const abs = Math.abs(num);

  if (abs === 0) {
    return '0';
  }

  return num?.toFixed(2) + '%';
};

export const getFormattedDates = (date: Date) => {
  const dateEST = toZonedTime(date, 'America/New_York');
  const formattedDate = format(date, 'yyyy-MM-dd');

  const nineThirtyAmEST = toZonedTime(date, 'America/New_York');
  nineThirtyAmEST.setHours(9, 30, 0);

  const fourPmEST = toZonedTime(date, 'America/New_York');
  fourPmEST.setHours(15, 59, 59);

  const isBeforeNineThirtyAm = isBefore(dateEST, nineThirtyAmEST);
  const isAfterFourPm = isAfter(dateEST, fourPmEST);

  return { isBeforeNineThirtyAm, isAfterFourPm, formattedDate };
};

export const getSelectedRowKeys = (rowSelection: Record<string, boolean>) =>
  Object.entries(rowSelection)
    .map(([k, v]) => {
      if (v) {
        return k;
      }

      return null;
    })
    .filter(Boolean);

export const getPeriodDocs = (
  data?: EarningsPeriods,
  documents?: DocumentEntityItem[]
): { [period: string]: string[] } => {
  const periodDocs: { [period: string]: string[] } = {};
  const earningsPeriods = data?.map(d => `${d.fiscal_period} ${d.fiscal_year}`);

  if (documents) {
    for (const doc of documents) {
      const fiscalPeriod = doc.period === 'FY' ? 'Q4' : doc.period;
      const period = `${fiscalPeriod} ${doc.year}`;

      if (doc.indexed && earningsPeriods?.includes(period)) {
        if (!periodDocs[period]) {
          periodDocs[period] = [];
        }

        periodDocs[period].push(doc.name);
      }
    }
  }

  return periodDocs;
};

export const getEarningsFilters = (t: TFunction): ScreenerFilters => {
  return [
    ...getEpsFilters(t),
    ...getRevenueFilters(t),
    ...getReturnFilters(t),
    {
      key: 'period',
      name: t('widget:earnings.filters.input.period'),
      searchable: false,
      visible: true,
      locked: false,
      category: 'Period',
      config: {
        name: t('widget:earnings.filters.input.period'),
        multiselect: false,
        options: [
          {
            name: t('widget:earnings.filters.preCovid'),
            key: 'preCovid',
            value: {
              id: 'preCovid',
              ranges: [[MIN_DATE, '2020-03-14']],
            },
          },
          {
            name: t('widget:earnings.filters.duringCovid'),
            key: 'duringCovid',
            value: {
              id: 'duringCovid',
              ranges: [['2020-03-15', '2021-04-19']],
            },
          },
          {
            name: t('widget:earnings.filters.postCovid'),
            key: 'postCovid',
            value: {
              id: 'postCovid',
              ranges: [['2021-04-20', MAX_DATE]],
            },
          },
          {
            name: t('widget:earnings.filters.duringRecessions'),
            key: 'duringRecessions',
            value: {
              id: 'duringRecessions',
              ranges: [
                ['1969-04-01', '1970-12-31'],
                ['1973-10-01', '1975-03-31'],
                ['1979-04-01', '1980-06-30'],
                ['1981-04-01', '1982-06-30'],
                ['1989-10-01', '1991-03-29'],
                ['2001-01-01', '2001-09-28'],
                ['2007-10-01', '2009-06-30'],
                ['2020-01-01', '2020-06-30'],
              ],
            },
          },
          {
            name: t('widget:earnings.filters.hikeCycle'),
            key: 'hikeCycle',
            value: {
              id: 'hikeCycle',
              ranges: [
                ['1994-02-04', '1995-02-01'],
                ['1999-06-30', '2000-05-16'],
                ['2004-06-30', '2006-06-29'],
                ['2015-12-16', '2018-12-19'],
                ['2022-03-16', '2023-06-14'],
              ],
            },
          },
          {
            name: t('widget:earnings.filters.cutCycle'),
            key: 'cutCycle',
            value: {
              id: 'cutCycle',
              ranges: [
                ['1989-12-19', '1992-09-04'],
                ['1995-07-06', '1996-01-31'],
                ['1998-09-29', '1998-11-17'],
                ['2001-01-03', '2001-12-11'],
                ['2007-09-18', '2008-01-30'],
                ['2008-03-18', '2008-12-16'],
                ['2019-07-31', '2020-03-15'],
              ],
            },
          },
        ],
      },
    },
  ];
};

const getRevenueFilters = (t: TFunction) => [
  {
    key: 'revenue-surprise-dollar',
    name: t('widget:earnings.filters.input.revenueSurpriseDollar'),
    searchable: false,
    visible: true,
    locked: false,
    category: 'Revenue',
    config: {
      name: t('widget:earnings.filters.input.revenueSurpriseDollar'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.beats'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.misses'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.revenueSurpriseDollar'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.beats'),
                key: 'beats',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: 0,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    leftIcon: '$',
                    rightIcon: t('widget:earnings.filters.orMore'),
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.misses'),
                key: 'misses',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: 0,
                    units: '$',
                    leftIcon: '$',
                    rightIcon: t('widget:earnings.filters.orLess'),
                  },
                ],
              },
            ],
          },
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'revenue-reported',
    name: t('widget:earnings.filters.input.revenueReported'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Revenue',
    config: {
      name: t('widget:earnings.filters.input.revenueReported'),
      multiselect: false,
      options: [],
      custom: {
        name: t('widget:earnings.filters.input.revenueReported'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'revenue-surprise-percentage',
    name: t('widget:earnings.filters.input.revenueSurprisePercentage'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Revenue',
    config: {
      name: t('widget:earnings.filters.input.revenueSurprisePercentage'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.beats'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.misses'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.revenueSurprisePercentage'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.beats'),
                key: 'beats',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: 0,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    leftIcon: ' ',
                    rightIcon: t('widget:earnings.filters.percentOrMore'),
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.misses'),
                key: 'misses',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: 0,
                    units: '%',
                    leftIcon: ' ',
                    rightIcon: t('widget:earnings.filters.percentOrLess'),
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
];

const getEpsFilters = (t: TFunction) => [
  {
    key: 'eps-surprise-dollar',
    name: t('widget:earnings.filters.input.epsSurpriseDollar'),
    searchable: false,
    visible: true,
    locked: false,
    category: 'EPS',
    config: {
      name: t('widget:earnings.filters.input.epsSurpriseDollar'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.beatsBy15Dollar'),
          key: 'beats-by-0.15',
          value: {
            id: 'beats-by-0.15',
            min: 0.15,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.beatsBy10Dollar'),
          key: 'beats-by-0.10',
          value: {
            id: 'beats-by-0.10',
            min: 0.1,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.beatsBy05Dollar'),
          key: 'beats-by-0.05',
          value: {
            id: 'beats-by-0.05',
            min: 0.05,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.beats'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.misses'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
        {
          name: t('widget:earnings.filters.missesBy05Dollar'),
          key: 'misses-by-0.05',
          value: {
            id: 'misses-by-0.05',
            min: Number.NEGATIVE_INFINITY,
            max: -0.05,
          },
        },
        {
          name: t('widget:earnings.filters.missesBy10Dollar'),
          key: 'misses-by-0.10',
          value: {
            id: 'misses-by-0.10',
            min: Number.NEGATIVE_INFINITY,
            max: -0.1,
          },
        },
        {
          name: t('widget:earnings.filters.missesBy15Dollar'),
          key: 'misses-by-0.15',
          value: {
            id: 'misses-by-0.15',
            min: Number.NEGATIVE_INFINITY,
            max: -0.15,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.epsSurpriseDollar'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.beats'),
                key: 'beats',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: 0,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    leftIcon: '$',
                    rightIcon: t('widget:earnings.filters.orMore'),
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.misses'),
                key: 'misses',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: 0,
                    units: '$',
                    leftIcon: '$',
                    rightIcon: t('widget:earnings.filters.orLess'),
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'eps-reported',
    name: t('widget:earnings.filters.input.epsReported'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'EPS',
    config: {
      name: t('widget:earnings.filters.input.epsReported'),
      multiselect: false,
      options: [],
      custom: {
        name: t('widget:earnings.filters.input.epsReported'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '$',
                    rightIcon: '$',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'eps-surprise-percentage',
    name: t('widget:earnings.filters.input.epsSurprisePercentage'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'EPS',
    config: {
      name: t('widget:earnings.filters.input.epsSurprisePercentage'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.beatsBy15'),
          key: 'beats-by-0.15',
          value: {
            id: 'beats-by-0.15',
            min: 0.15,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.beatsBy10'),
          key: 'beats-by-0.10',
          value: {
            id: 'beats-by-0.10',
            min: 0.1,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.beatsBy05'),
          key: 'beats-by-0.05',
          value: {
            id: 'beats-by-0.05',
            min: 0.05,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.beats'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.misses'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
        {
          name: t('widget:earnings.filters.missesBy05'),
          key: 'misses-by-0.05',
          value: {
            id: 'misses-by-0.05',
            min: Number.NEGATIVE_INFINITY,
            max: -0.05,
          },
        },
        {
          name: t('widget:earnings.filters.missesBy10'),
          key: 'misses-by-0.10',
          value: {
            id: 'misses-by-0.10',
            min: Number.NEGATIVE_INFINITY,
            max: -0.1,
          },
        },
        {
          name: t('widget:earnings.filters.missesBy15'),
          key: 'misses-by-0.15',
          value: {
            id: 'misses-by-0.15',
            min: Number.NEGATIVE_INFINITY,
            max: -0.15,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.epsSurprisePercentage'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.beats'),
                key: 'beats',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: 0,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    leftIcon: ' ',
                    rightIcon: t('widget:earnings.filters.percentOrMore'),
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.misses'),
                key: 'misses',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: 0,
                    units: '%',
                    leftIcon: ' ',
                    rightIcon: t('widget:earnings.filters.percentOrLess'),
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
];

const getReturnFilters = (t: TFunction) => [
  {
    key: 'return-one-day',
    name: t('widget:earnings.filters.input.returnOneDay'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Return',
    config: {
      name: t('widget:earnings.filters.input.returnOneDay'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.positive'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.negative'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.returnOneDay'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    value_type: 'float',
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'return-two-days',
    name: t('widget:earnings.filters.input.returnTwoDays'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Return',
    config: {
      name: t('widget:earnings.filters.input.returnTwoDays'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.positive'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.negative'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.returnTwoDays'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    value_type: 'float',
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'return-one-week',
    name: t('widget:earnings.filters.input.returnOneWeek'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Return',
    config: {
      name: t('widget:earnings.filters.input.returnOneWeek'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.positive'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.negative'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.returnOneWeek'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    value_type: 'float',
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'return-two-weeks',
    name: t('widget:earnings.filters.input.returnTwoWeeks'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Return',
    config: {
      name: t('widget:earnings.filters.input.returnTwoWeeks'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.positive'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.negative'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.returnTwoWeeks'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    value_type: 'float',
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  {
    key: 'return-one-month',
    name: t('widget:earnings.filters.input.returnOneMonth'),
    searchable: false,
    visible: false,
    locked: false,
    category: 'Return',
    config: {
      name: t('widget:earnings.filters.input.returnOneMonth'),
      multiselect: false,
      options: [
        {
          name: t('widget:earnings.filters.positive'),
          key: 'beats',
          value: {
            id: 'beats',
            min: 0.01,
            max: Number.POSITIVE_INFINITY,
          },
        },
        {
          name: t('widget:earnings.filters.negative'),
          key: 'misses',
          value: {
            id: 'misses',
            min: Number.NEGATIVE_INFINITY,
            max: -0.01,
          },
        },
      ],
      custom: {
        name: t('widget:earnings.filters.input.returnOneMonth'),
        key: 'custom',
        custom: [
          {
            type: 'select',
            custom: [
              {
                name: t('widget:earnings.filters.input.aboveOrEqualTo'),
                key: 'above-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    value_type: 'float',
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.input.belowOrEqualTo'),
                key: 'below-or-equal',
                custom: [
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
              {
                name: t('widget:earnings.filters.between'),
                key: 'between',
                custom: [
                  {
                    type: 'text',
                    value_field: 'min',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                  {
                    type: 'label',
                    text: 'and',
                  },
                  {
                    type: 'text',
                    value_field: 'max',
                    value_type: 'float',
                    minimum: Number.NEGATIVE_INFINITY,
                    maximum: Number.POSITIVE_INFINITY,
                    units: '%',
                    rightIcon: '%',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
];

export const filterEarnings = (
  filters: ActiveFilterOptions[],
  earningsData: EarningsPeriods
): EarningsPeriods => {
  type SurpriseFilterValue = {
    id: string;
    min: number;
    max: number;
  };

  type PeriodFilterValue = {
    id: string;
    ranges: string[][];
  };

  const filterFns: {
    [key: string]: (earnings: EarningsPeriod, value: any) => boolean;
  } = {
    'eps-surprise-dollar': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      return z.number().min(min).max(max).safeParse(earnings.eps.surprise)
        .success;
    },
    'eps-reported': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      return z.number().min(min).max(max).safeParse(earnings.eps.value).success;
    },
    'eps-surprise-percentage': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      return z
        .number()
        .min(min)
        .max(max)
        .safeParse(earnings.eps.surprise_percentage).success;
    },
    period: (earnings, value: PeriodFilterValue) => {
      const date = new Date(earnings.reporting_date);

      for (const range of value.ranges) {
        const [start, end] = range;

        if (date >= new Date(start) && date <= new Date(end)) {
          return true;
        }
      }

      return false;
    },
    'revenue-surprise-dollar': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      return z.number().min(min).max(max).safeParse(earnings.revenue.surprise)
        .success;
    },
    'revenue-reported': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      return z.number().min(min).max(max).safeParse(earnings.revenue.value)
        .success;
    },
    'revenue-surprise-percentage': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      return z
        .number()
        .min(min)
        .max(max)
        .safeParse(earnings.revenue.surprise_percentage).success;
    },
    'return-one-day': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      const data = earnings.price?.find(p => p.period === 'd1')?.change ?? 0;
      return z.number().min(min).max(max).safeParse(data).success;
    },
    'return-two-days': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      const data = earnings.price?.find(p => p.period === 'd2')?.change ?? 0;
      return z.number().min(min).max(max).safeParse(data).success;
    },
    'return-one-week': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      const data = earnings.price?.find(p => p.period === 'w1')?.change ?? 0;
      return z.number().min(min).max(max).safeParse(data).success;
    },
    'return-two-weeks': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      const data = earnings.price?.find(p => p.period === 'w2')?.change ?? 0;
      return z.number().min(min).max(max).safeParse(data).success;
    },
    'return-one-month': (earnings, value: SurpriseFilterValue) => {
      const { min = -Infinity, max = Infinity } = value;
      const data = earnings.price?.find(p => p.period === 'm1')?.change ?? 0;
      return z.number().min(min).max(max).safeParse(data).success;
    },
  };

  let result = earningsData;

  for (const filter of filters) {
    const filterFn = filterFns[filter.filter];

    for (const option of filter.options) {
      result = result.filter(earnings => filterFn(earnings, option.value));
    }
  }

  return result;
};

export const getMarketHoursLabel = (date: Date) => {
  const { isAfterFourPm } = getFormattedDates(date);
  return isAfterFourPm ? AFTER_MARKET_HOURS : BEFORE_MARKET_HOURS;
};

export const getCellHighlighted = (columnId: string) => {
  return columnId.toLocaleLowerCase().includes('revenue');
};

export const getEarningsFilterPayload = (
  t: TFunction,
  epsFilter: string,
  epsValue: number
): ToggleFilterProps | undefined => {
  const epsSurpriseDollarFilter = getEarningsFilters(t)[0];

  const option = epsSurpriseDollarFilter.config.options.find(
    opt => opt.key === epsFilter
  );

  if (option) {
    const unit =
      epsSurpriseDollarFilter.config.custom?.custom[0].custom[0].custom[0]
        .units ?? '';

    if (epsFilter === 'beats') {
      option.value.min = epsValue;
      option.name = `> ${epsValue}${unit}`;
    } else {
      option.value.max = epsValue;
      option.name = `< ${epsValue}${unit}`;
    }

    return {
      filterKey: epsSurpriseDollarFilter.key,
      option: option,
      multiselect: false,
    };
  }

  return undefined;
};
