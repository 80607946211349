import { NewsFilter } from '~/services/news/news.types';
import { fetchNewsFilters } from '~/services/news/news-service';
import { create } from '~/stores/create-store/createStore';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { createAccordionFilters } from '~/views/news/utils/news-filters-utils/news-filters-utils';

import { toggleFilterOption } from '../../utils/toggle-filter-option/toggleFilterOption';

export const PROVIDERS_FILTER_KEY = 'providers';

type State = {
  filters: NewsFilter[];
  isMostReadSelected: boolean;
  dateRangeEnd?: string;
};

type Actions = {
  initStore: () => Promise<void>;
  resetAll: () => void;
  toggleFilterOption: (filterKey: string, optionKey?: string) => void;
  setIsMostReadSelected: (nextValue: boolean) => void;
  setFilters: (filters: NewsFilter[]) => void;
  handleSetDateRange: (dateTo?: string) => void;
  resetDateRange: () => void;
};

export type NewsFiltersStore = State & Actions;

export const useNewsFiltersStore = create<NewsFiltersStore>((set, get) => ({
  filters: [],
  isMostReadSelected: false,
  initStore: async () => {
    const { data: newsFilters = [] } = await fetchNewsFilters();

    const accordionFilters = createAccordionFilters(
      newsFilters,
      useWatchlist.getState().lists
    );

    set({
      filters: accordionFilters,
    });
  },

  setFilters: filters =>
    set({
      filters,
    }),

  setIsMostReadSelected: (isMostReadSelected: boolean) => {
    set({
      isMostReadSelected,
    });
  },
  resetAll: () => {
    get().initStore();
    set({
      isMostReadSelected: false,
    });
  },
  toggleFilterOption: (filterKey, optionKey) => {
    set(({ filters }) => ({
      filters: toggleFilterOption({ filters, filterKey, optionKey }),
    }));
  },
  handleSetDateRange: (dateTo?: string) => {
    set({
      dateRangeEnd: dateTo ?? new Date().toISOString(),
    });
  },
  resetDateRange: () => {
    set({
      dateRangeEnd: undefined,
    });
  },
}));
