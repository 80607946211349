import { Highlights } from '@toggle/toggle';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useWatchlist } from '../../stores/use-watchlist/useWatchlist';
import { getWatchlistNames } from '../../utils/watchlist-helpers/watchlist-helpers';
import * as S from './NewsBadges.styles';

export interface NewsBadgesProps {
  highlights?: Highlights[];
  entityTags: string[];
}

export const NewsBadges = ({ highlights, entityTags }: NewsBadgesProps) => {
  const { t } = useTranslation('news');
  const isMostRead = highlights?.includes('most_read');
  const isBreaking = highlights?.includes('breaking');

  const lists = useWatchlist(state => state.lists);

  const watchlistLabel = useMemo(() => {
    const watchlistNames = getWatchlistNames(entityTags, lists);

    if (!watchlistNames.length) {
      return undefined;
    }

    if (watchlistNames.length === 1) {
      return watchlistNames[0];
    }

    return `${watchlistNames[0]} + ${watchlistNames.length - 1}`;
  }, [entityTags, lists]);

  return (
    <S.NewsBadgesRoot data-testid="news-badges">
      {isMostRead && (
        <S.MostReadBadge
          data-testid="badge-most-read"
          label={
            /* i18next-extract-mark-context-next-line ["most_read", "breaking"] */
            t('news:badges.label', { context: 'most_read' })
          }
        />
      )}

      {isBreaking && (
        <S.BreakingBadge
          data-testid="badge-breaking"
          label={
            /* i18next-extract-mark-context-next-line ["most_read", "breaking"] */
            t('news:badges.label', { context: 'breaking' })
          }
        />
      )}

      {watchlistLabel && (
        <S.WatchlistBadge
          data-testid="badge-watchlist"
          label={watchlistLabel}
        />
      )}
    </S.NewsBadgesRoot>
  );
};
