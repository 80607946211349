import { Button, DatePicker, DropdownMenu, i18n } from '@toggle/design-system';
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  format,
  isSameDay,
  startOfToday,
} from 'date-fns';
import React, { useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { CardDay } from '../card-day/CardDay';
import { formatEventDate } from '../utils';
import * as S from './EventOverview.styles';

interface CountData {
  counts: Record<string, number>;
  isLoading: boolean;
}
export interface EventOverviewProps {
  activeDate: Date;
  endWeekDay: Date;
  startWeekDay: Date;
  isNextWeekDisable: boolean;
  onDateChange: (date: Date) => void;
  data: {
    economics: CountData;
    earnings: CountData;
    companies: CountData;
  };
}

export const EventOverview = ({
  activeDate,
  endWeekDay,
  startWeekDay,
  isNextWeekDisable,
  onDateChange,
  data,
}: EventOverviewProps) => {
  const { t } = useTranslation(['calendar']);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const { economics, earnings, companies } = data;
  const weekDates = eachDayOfInterval({
    start: startWeekDay,
    end: endWeekDay,
  });
  const dateLabel = `${format(startWeekDay, 'MMMM d')} - ${format(
    endWeekDay,
    'MMMM d, y'
  )}`;

  return (
    <div>
      <S.DateWrapper>
        <DropdownMenu
          open={isDatePickerOpen}
          onOpenChange={() => setIsDatePickerOpen(prev => !prev)}
          triggerNode={
            <S.CalendarButton
              variant="tertiary"
              size="small"
              iconName="Calendar"
              data-testid="datepicker-trigger-button"
            />
          }
          contentProps={{
            asChild: true,
          }}
        >
          <S.MenuWrapper>
            <I18nextProvider i18n={i18n}>
              <DatePicker
                value={activeDate}
                maxDate={addMonths(startOfToday(), 3)}
                onDateChange={newDate => {
                  onDateChange(newDate as Date);
                  setIsDatePickerOpen(false);
                }}
                data-testid="events-calendar"
              />
            </I18nextProvider>
          </S.MenuWrapper>
        </DropdownMenu>

        <Button
          variant="empty"
          size="small"
          iconName="ArrowLeft"
          data-testid="arrow-previous-week"
          onClick={() => onDateChange(addDays(activeDate, -7))}
        />

        <S.DateLabel data-testid="overview-date-label">{dateLabel}</S.DateLabel>
        <Button
          variant="empty"
          size="small"
          iconName="ArrowRight"
          data-testid="arrow-next-week"
          disabled={isNextWeekDisable}
          onClick={() => onDateChange(addDays(activeDate, 7))}
        />
      </S.DateWrapper>
      <S.EventCardsWrapper>
        {weekDates.map(d => {
          const dateOnly = formatEventDate(d);

          const rows = [
            {
              label: t('calendar:earnings'),
              count: earnings.counts[dateOnly],
              bgColor: 'var(--surface-decorative-indigo-default)',
              isLoading: earnings.isLoading,
            },
            {
              label: t('calendar:economic'),
              count: economics.counts[dateOnly],
              bgColor: 'var(--surface-decorative-purple-default)',
              isLoading: economics.isLoading,
            },
            {
              label: t('calendar:company'),
              count: companies.counts[dateOnly],
              bgColor: 'var(--surface-decorative-cyan-default)',
              isLoading: companies.isLoading,
            },
          ];

          return (
            <CardDay
              key={d.valueOf()}
              date={d}
              isActive={isSameDay(d, activeDate)}
              handleCardClick={() => onDateChange(d)}
              rows={rows}
            />
          );
        })}
      </S.EventCardsWrapper>
    </div>
  );
};
