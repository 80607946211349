import { z } from 'zod';

const baseFinancialStatement = z.object({
  id: z.string(),
  display: z.string(),
  values_fmt: z.array(z.string().nullable()),
  values: z.array(z.number().nullable()),
  value_type: z.literal('percent').or(z.literal('currency')),
});

export type Statement = z.infer<typeof baseFinancialStatement> & {
  subsections?: Statement[];
};

const financialStatement: z.ZodType<Statement> = baseFinancialStatement.extend({
  subsections: z.lazy(() => financialStatement.array().optional()),
});

const schema = z.object({
  columns: z.array(
    z.object({
      year: z.number(),
      period: z.string(),
      type: z
        .literal('actual')
        .or(z.literal('expected'))
        .or(z.literal('ltm'))
        .or(z.literal('ntm')),
    })
  ),
  rows: z.array(financialStatement),
  metadata: z.object({
    currency: z.string(),
  }),
});

export type TimeFrame = 'annual' | 'quarterly';

export const financialStatements = {
  balanceSheet: {
    path: (entityTag: string, timeFrame: TimeFrame) =>
      `financial-statements/v2/${entityTag}/balancesheet/${timeFrame}`,
    schema,
  },
  cashFlow: {
    path: (entityTag: string, timeFrame: TimeFrame) =>
      `financial-statements/v2/${entityTag}/cashflow/${timeFrame}`,
    schema,
  },
  incomeStatement: {
    path: (entityTag: string, timeFrame: TimeFrame) =>
      `financial-statements/v2/${entityTag}/income/${timeFrame}`,
    schema,
  },
} as const;

export type FinancialStatementSchema = z.infer<typeof schema>;
export type FinancialStatementType = keyof typeof financialStatements;
