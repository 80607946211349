import { create } from '~/stores/create-store/createStore';

import { Action } from './actions.types';

const SUPPORTED_ACTIONS = [
  'chart-comparison:compare-entities',
  'chat-quality-score:create-message',
  'past-episodes-widget:create-message',
  'watchlist:create-related-companies',
  'watchlist:create',
];

interface ActionsStore {
  actions: Action[];
  setActions(actions: Action[]): void;
  removeAction(action: Action): void;
  removeAllActions(): void;
}

export const useActions = create<ActionsStore>(set => ({
  actions: [],
  setActions: actions => {
    const filteredActions = actions.filter(action =>
      SUPPORTED_ACTIONS.includes(action.action)
    );

    set({ actions: filteredActions });
  },
  removeAction: action => {
    set(state => ({
      actions: state.actions.filter(p => p.display !== action.display),
    }));
  },
  removeAllActions: () => {
    set({
      actions: [],
    });
  },
}));
