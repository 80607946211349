// Cannot use createStore function as the order of resetting the store matters.
// See useAuth logout function for more info
import { AuthDataPayload, UserRole } from '@toggle/toggle';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { useUser } from '~/stores/use-user/useUser';
import { getRefreshToken } from '~/views/auth/services/auth-services';

interface AuthStore {
  token?: string;
  email?: string;
  expiry?: string;
  isLocked: boolean;
  source?: number;
  subscription?: UserRole;
  user_id?: string;
  user_role?: string;
  authProcessing: boolean;
  isInitError: boolean;
  isInitSignUp: boolean;
  destroy: () => void;
  update: (data: AuthDataPayload, isInitSignUp: boolean) => void;
  unlock: () => void;
  refreshToken: () => Promise<void>;
  setAuthProcessing: (authProcessing: boolean) => void;
  setIsInitError: (isInitError: boolean) => void;
}

export const useAuthStore = createWithEqualityFn<AuthStore>(
  set => ({
    token: undefined,
    email: undefined,
    expiry: undefined,
    isLocked: false,
    source: undefined,
    subscription: undefined,
    user_id: undefined,
    user_role: undefined,
    authProcessing: true,
    isInitSignUp: false,
    isInitError: false,
    destroy: () =>
      set({
        token: undefined,
        email: undefined,
        expiry: undefined,
        source: undefined,
        subscription: undefined,
        user_id: undefined,
        user_role: undefined,
      }),
    update: (data, isInitSignUp) => set({ ...data, isInitSignUp }),
    unlock: () => set({ isLocked: false }),
    refreshToken: async () => {
      const session = await getRefreshToken();

      if (!session.error) {
        set(session.data);
        await useUser.getState().initialize();
      }
    },
    setAuthProcessing: (authProcessing: boolean) => set({ authProcessing }),
    setIsInitError: (isInitError: boolean) => set({ isInitError }),
  }),
  shallow
);
