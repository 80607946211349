import { Icon, Tooltip, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const IconWrapper = styled.span`
  display: flex;
`;

export const Sun = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-success);
`;

export const SunRise = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-primary);
`;

export const Moon = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-soft);
`;

export const DateContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--font-nort);

  ${Tooltip.Styled.TooltipBubble} {
    padding: 4px 8px;
  }
`;

export const TimeLabel = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-default);
`;
