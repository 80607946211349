import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import React, { FC, memo } from 'react';

import { PlotLine, PlotTypes } from '../../HighLowTripleLineChart.types';
import { GradientArea } from '../gradient-area/GradientArea';
import * as S from './Plot.styles';

export interface PlotProps {
  plots: PlotLine[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  linePlotType?: PlotTypes;
}

const Plot: FC<PlotProps> = ({ plots, xScale, yScale, linePlotType }) => {
  return (
    <>
      {plots.map(({ type, ts, gradients }) => (
        <g key={`plot-${type}`} aria-labelledby={`plot-${type}`}>
          <S.LinePlotStyled
            ts={ts}
            scaleX={xScale}
            scaleY={yScale}
            curve={d3.curveLinear}
            $type={linePlotType}
            $dashed={type === PlotTypes.Median}
          />
          {gradients?.map(({ y0, y1, gradientStops }) => (
            <GradientArea
              key={`plot-${type}-gradient-${y0}`}
              ts={ts}
              scaleX={xScale}
              scaleY={yScale}
              stops={gradientStops}
              y1={y1}
              y0={y0}
              curve={d3.curveLinear}
            />
          ))}
        </g>
      ))}
    </>
  );
};

export const MemoPlot = memo(Plot);
