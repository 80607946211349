import { useQueries } from '@tanstack/react-query';
import { CreateScreenResponse } from '@toggle/toggle';
import { useMemo } from 'react';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { getScreenerEntitiesTagsQueryConfig } from '~/widgets/scenario/hooks/use-screener-entities-tags/useScreenerEntitiesTags';

export const QUERY_KEY_SCREENER_FILTERS_ENTITIES_TAGS =
  'QUERY_KEY_SCREENER_FILTERS_ENTITIES_TAGS';

const parseScreenerFilterOptionsQueries = (
  savedScreens: CreateScreenResponse[],
  screenerFilter?: ActiveFilterOptions
) => {
  if (!screenerFilter?.options?.length) {
    return [];
  }

  return screenerFilter.options.map(option => {
    const screen = savedScreens.find(
      screen => screen.id === option.value.screenerId
    );

    const filterOptionsMerged = Object.values(
      screen?.config.filterOptionGroups ?? {}
    ).flat();

    return getScreenerEntitiesTagsQueryConfig({
      filterOptions: filterOptionsMerged,
    });
  });
};

export const useParsedScreenerOptionsQueries = (
  savedScreens: CreateScreenResponse[],
  screenerFilter?: ActiveFilterOptions
) => {
  const queries = useMemo(
    () => parseScreenerFilterOptionsQueries(savedScreens, screenerFilter),
    [savedScreens, screenerFilter]
  );

  return useQueries({
    queries,
    combine: results => ({
      data: results.map(res => res.data?.entities ?? []),
      isLoading: results.some(result => result.isLoading),
      isFetched: results.every(result => result.isFetched),
    }),
  });
};
