import { Entity } from '@toggle/toggle';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router';

import {
  ChartSettings,
  checkSearchParams,
  getChartSettingsFromSearchParams,
  ValidChartSettings,
} from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import { ChartSearchType } from '../../types/search.types';
import { ChartState } from '../use-chart-state/useChartState';

export interface UseChartLoadProps {
  initialChartSettings?: ChartSettings;
  initialChartState?: ChartState;
  setHasError: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  shouldUseSearchParams?: boolean;
  openSearch?: (
    type: ChartSearchType,
    {
      changingAssets,
      paneIndex,
    }: { changingAssets?: Entity; paneIndex?: number }
  ) => void;
  loadChartFromSettings: (settings: ValidChartSettings) => Promise<void>;
  updateChartState: (partial: Partial<ChartState>) => void;
}

export const useChartLoad = ({
  initialChartSettings,
  initialChartState,
  setHasError,
  setIsLoading,
  shouldUseSearchParams,
  openSearch,
  loadChartFromSettings,
  updateChartState,
}: UseChartLoadProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleInitialChartLoad = async (settings: ValidChartSettings) => {
    try {
      setIsLoading(true);
      await loadChartFromSettings(settings);
    } catch (error) {
      setHasError(true);

      if (shouldUseSearchParams) {
        setSearchParams('', {
          replace: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const retryInitialChartLoad = () => {
    const settings =
      initialChartSettings ?? getChartSettingsFromSearchParams(searchParams);

    if (settings) {
      setHasError(false);
      handleInitialChartLoad(settings);
    }
  };

  useEffect(() => {
    const settings =
      initialChartSettings ?? getChartSettingsFromSearchParams(searchParams);

    if (checkSearchParams(settings)) {
      handleInitialChartLoad(settings);
    } else if (
      initialChartState &&
      !!initialChartState.chartPanes?.[0].chartAssetData.length
    ) {
      updateChartState(initialChartState);
    } else {
      openSearch?.(ChartSearchType.Primary, {});

      if (shouldUseSearchParams) {
        setSearchParams('', {
          replace: true,
        });
      }
    }
  }, [JSON.stringify(initialChartSettings)]);

  return { handleInitialChartLoad, retryInitialChartLoad };
};
