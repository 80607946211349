export enum ResampleIntervals {
  OneMinute = '1m',
  FiveMinutes = '5m',
  FifteenMinutes = '15m',
  ThirtyMinutes = '30m',
  OneHour = '1h',
  OneDay = '1D',
  OneWeek = '1W',
  OneMonth = '1M',
}

export enum RangeHorizon {
  OneDay = 'OneDay',
  OneWeek = 'OneWeek',
  TwoWeeks = 'TwoWeeks',
  OneMonth = 'OneMonth',
  TwoMonths = 'TwoMonths',
  ThreeMonths = 'ThreeMonths',
  SixMonths = 'SixMonths',
  OneYear = 'OneYear',
  ThreeYears = 'ThreeYears',
  FiveYears = 'FiveYears',
  TenYears = 'TenYears',
  Max = 'Max',
}

export const RANGE_HORIZON_DEFAULT = RangeHorizon.OneMonth;
export const RESAMPLE_DEFAULT = ResampleIntervals.OneDay;

export const rangeToResampleMapping = {
  [RangeHorizon.OneDay]: ResampleIntervals.OneMinute,
  [RangeHorizon.OneWeek]: ResampleIntervals.FiveMinutes,
  [RangeHorizon.TwoWeeks]: ResampleIntervals.FiveMinutes,
  [RangeHorizon.OneMonth]: ResampleIntervals.OneHour,
  [RangeHorizon.TwoMonths]: ResampleIntervals.OneHour,
  [RangeHorizon.ThreeMonths]: ResampleIntervals.OneDay,
  [RangeHorizon.SixMonths]: ResampleIntervals.OneDay,
  [RangeHorizon.OneYear]: ResampleIntervals.OneDay,
  [RangeHorizon.ThreeYears]: ResampleIntervals.OneWeek,
  [RangeHorizon.FiveYears]: ResampleIntervals.OneWeek,
  [RangeHorizon.TenYears]: ResampleIntervals.OneMonth,
  [RangeHorizon.Max]: ResampleIntervals.OneMonth,
};

export const RESAMPLE_BUTTON_ID = 'legend-resample';
export const INDICATOR_BUTTON_ID = 'legend-indicator';
export const chartResamples = Object.values(ResampleIntervals);
export const intradayResamples = [
  ResampleIntervals.OneMinute,
  ResampleIntervals.FiveMinutes,
  ResampleIntervals.FifteenMinutes,
  ResampleIntervals.ThirtyMinutes,
  ResampleIntervals.OneHour,
];
export const nonIntradayResamples = [
  ResampleIntervals.OneMonth,
  ResampleIntervals.OneWeek,
  ResampleIntervals.OneDay,
];
