import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const DashedButton = styled(Button)`
  border: 1px dashed var(--border-primary);
`;
