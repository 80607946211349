import {
  Accordion,
  customScrollMinimal,
  SmartTable,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  gap: 20px;
  overflow-y: auto;
  ${customScrollMinimal}

  ${SmartTable.Styled.Table} {
    tr {
      background-color: var(--background-soft);
      th,
      td {
        background-color: var(--background-soft);
      }
    }
  }
`;

export const StyledAccordion = styled(Accordion)`
  .insights-accordion
    > ${Accordion.Styled.TitleContainer},
    ${Accordion.Styled.Content} {
    padding: 12px 0;
  }

  ${Accordion.Styled.AccordionItem} {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const AssessmentPadding = styled.div`
  height: 120px;
`;

export const ValueInfo = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
`;
