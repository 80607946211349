import React from 'react';
import { useTranslation } from 'react-i18next';

import { useChat } from '~/stores/use-chat/useChat';

import * as S from './ChatInputContextHeader.styles';

interface ChatInputContextHeaderProps {
  contextCounts?: {
    documentsCount: number;
    companiesCount: number;
  };
}

export const ChatInputContextHeader = ({
  contextCounts,
}: ChatInputContextHeaderProps) => {
  const { t } = useTranslation('chat');
  const { setIsSearchWithinContext, isSearchWithinContext, isMessagePending } =
    useChat(state => ({
      setIsSearchWithinContext: state.setIsSearchWithinContext,
      isSearchWithinContext: state.isSearchWithinContext,
      isMessagePending: state.isMessagePending,
    }));

  return (
    <S.ContextHeaderContainer data-testid="context-header">
      <S.HeaderTextWrapper>
        {isSearchWithinContext ? (
          <>
            <S.HeaderTextDefault data-testid="enabled-context-label">
              {t('contextHeader.searchWithinContext')}
            </S.HeaderTextDefault>
            <S.HeaderTextPrimary>
              {`${t('contextHeader.documents', {
                count: contextCounts?.documentsCount,
              })} ${t('contextHeader.companies', {
                count: contextCounts?.companiesCount,
              })}`}
            </S.HeaderTextPrimary>
          </>
        ) : (
          <S.HeaderTextDisabled data-testid="disabled-context-label">
            {t('contextHeader.searchWithinContext')}
          </S.HeaderTextDisabled>
        )}
      </S.HeaderTextWrapper>
      <S.StyledToggle
        isChecked={isSearchWithinContext}
        onToggle={() => setIsSearchWithinContext(!isSearchWithinContext)}
        disabled={isMessagePending}
        aria-label={t('contextHeader.toggleSearchWithinContext')}
      />
    </S.ContextHeaderContainer>
  );
};
