import { DocumentEntityItem } from '@toggle/toggle';
import { TFunction } from 'i18next';

export interface DocumentTableItem extends DocumentEntityItem {
  key: string;
  alfred?: SVGElement;
}

export const groupDocumentByYear = (
  documents: DocumentTableItem[] | undefined
) =>
  documents?.reduce((acc: Record<string, DocumentTableItem[]>, d) => {
    const { year } = d;
    const item = acc[year];

    if (item) {
      item.push(d);
    } else {
      acc[year] = [d];
    }

    return acc;
  }, {}) ?? {};

export const isFirstItem = (
  docsGroupedByYear: Record<string, DocumentTableItem[]>,
  document: DocumentTableItem
) => {
  const items = docsGroupedByYear[document.year];
  const idx = items.findIndex(i => i.key === document.key);
  return idx === 0;
};

export const getQuestions = (t: TFunction) =>
  [...Array(18)].map((_, index) =>
    t(`asset-overview:documentAssistant.question_${index}`)
  );
