import { AssetSubClassCode, Class } from '../api/entity-constants';

const ETF: AssetSubClassCode[] = [
  AssetSubClassCode.FiEtf,
  AssetSubClassCode.EquityEtf,
  AssetSubClassCode.CommodityEtf,
  AssetSubClassCode.CurrencyEtf,
  AssetSubClassCode.OtherEtf,
];

export const isEtf = (entity: {
  sub_class: AssetSubClassCode;
  class: Class;
}) => {
  return ETF.includes(entity.sub_class) || entity.class === Class.ClassEtf;
};
