import { Badge } from '@toggle/design-system';
import styled from 'styled-components';

export const NewsBadgesRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledBadge = styled(Badge).attrs({
  size: 'small',
  badgeStyle: 'solid',
})`
  ${Badge.Styled.BadgeTextual} span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
  }
`;

export const MostReadBadge = styled(StyledBadge).attrs({
  color: 'error',
  leftIcon: 'Trending',
})``;

export const BreakingBadge = styled(StyledBadge).attrs({
  leftIcon: 'Breaking',
})`
  ${Badge.Styled.BadgeTextual} {
    background: var(--surface-decorative-lime-default);
    color: var(--text-decorative-lime);

    svg {
      fill: var(--icon-decorative-lime);
    }
  }
`;

export const WatchlistBadge = styled(StyledBadge).attrs({
  leftIcon: 'Watchlist',
})`
  ${Badge.Styled.BadgeTextual} {
    background: var(--surface-decorative-cyan-default);
    color: var(--text-decorative-cyan);

    svg {
      fill: var(--icon-decorative-cyan);
    }
  }
`;
