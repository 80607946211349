import {
  borderRadius,
  ButtonGroup,
  Card,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const ExpectationsSwitcherRoot = styled(Card)`
  ${typographyV2.bodyBase};
  ${borderRadius.r2};
  display: flex;
  align-items: center;
  color: var(--text-primary);
  margin-bottom: 12px;
  flex-wrap: wrap;
  gap: 12px;
`;

export const CustomButtonGroup = styled(ButtonGroup)`
  width: 200px;
`;
