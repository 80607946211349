import * as Radix from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionOptions } from '../utils/getConditionOptions';
import * as S from './RunScenario.styles';

interface ConditionsDropdownProps {
  name: string;
  customCondition?: ConditionOptions;
  handleSetCustomCondition: (condition: ConditionOptions) => void;
  conditionOptions: ConditionOptions[];
}

export const ConditionsDropdown = ({
  name,
  customCondition,
  handleSetCustomCondition,
  conditionOptions,
}: ConditionsDropdownProps) => {
  const { t } = useTranslation(['calendar']);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.FilterWrapper>
      <S.FilterLabel>
        {t('calendar:scenario.whenLabel')} {name}
      </S.FilterLabel>
      <Radix.Root open={isOpen} onOpenChange={setIsOpen}>
        <S.ConditionTrigger
          $active={!!customCondition}
          onClick={() => setIsOpen(true)}
        >
          <S.TriggerLabel data-testid="condition-dropdown-label">
            {customCondition?.value ?? t('calendar:scenario.condition')}
          </S.TriggerLabel>
          <S.TriggerArrow iconName="ChevronLightDown" />
        </S.ConditionTrigger>
        <Radix.Portal>
          <S.DropdownMenuContent
            align="start"
            sideOffset={5}
            collisionPadding={5}
            onInteractOutside={() => setIsOpen(false)}
          >
            <S.ConditionTitle>{name}</S.ConditionTitle>
            {conditionOptions.map(({ value, config }) => {
              return (
                <S.Item
                  key={value}
                  onSelect={e => {
                    e.preventDefault();
                    handleSetCustomCondition({ value, config });
                  }}
                  $isChecked={customCondition?.value === value}
                >
                  <S.MenuItemLeftIndicator />
                  <S.ItemText>{value}</S.ItemText>
                </S.Item>
              );
            })}
          </S.DropdownMenuContent>
        </Radix.Portal>
      </Radix.Root>
    </S.FilterWrapper>
  );
};
