import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled, { css } from 'styled-components';

export const Container = styled.button<{ as: 'button' | 'div' }>`
  ${borderRadius.r2};
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid var(--border-soft);
  background: var(--surface-default);

  ${({ as }) =>
    as === 'button' &&
    css`
      scroll-margin: var(--chat-footer-height);
      cursor: pointer;
      outline: none;

      &:hover {
        border-color: var(--border-hover);
      }

      &:focus-visible {
        border-color: var(--border-focus);
      }
    `}
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
`;

export const Title = styled.p`
  color: var(--text-soft);
  ${typographyV2.bodyXs};
  text-align: left;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typographyV2.bodySm};
  color: var(--text-default);
  text-align: left;

  & button {
    padding: 4px 8px;
    color: var(--text-soft);
    border: 1px dashed var(--border-default);
  }
`;

export const IconContainer = styled.div`
  padding: 4px;
  background: var(--background-soft);
  ${borderRadius.r1};
  display: flex;

  & svg {
    fill: var(--icon-disabled);
  }
`;
