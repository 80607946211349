import { checkCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import React from 'react';

import { Tooltip } from '~/components/tooltip/Tooltip';

import { BAR_HEIGHT, CHART_WIDTH, margin } from '../constants';
import * as S from './HorizontalLabels.styles';

interface HorizontalLabelsProps {
  value: number;
  leftLabel: string;
  rightLabel: string;
  tooltipLabel: string;
  paddingLeft?: number;
  yPosition: number;
  prefix: string;
}

export const HorizontalLabels = ({
  value,
  leftLabel,
  rightLabel,
  tooltipLabel,
  paddingLeft = 0,
  yPosition,
  prefix,
}: HorizontalLabelsProps) => {
  const rightLabelText =
    value > 0 && prefix ? `${prefix}${rightLabel}` : rightLabel;
  return (
    <g
      transform={`translate(0, ${BAR_HEIGHT / 2})`}
      data-testid="left-right-labels"
    >
      <S.MedianText
        x={CHART_WIDTH - margin.right}
        y={yPosition}
        dy="0.35em"
        $numericalStyle={checkCellValueStyle(value)}
      >
        {rightLabelText}
      </S.MedianText>
      <Tooltip disabled={!tooltipLabel} label={tooltipLabel} inPortal>
        <S.LabelText x={margin.left + paddingLeft} y={yPosition} dy="0.35em">
          {/* TODO: https://app.clickup.com/t/8693518xt include portfolio image + ticker  */}
          {leftLabel}
        </S.LabelText>
      </Tooltip>
    </g>
  );
};
