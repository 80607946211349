import {
  formatNumberWithUnit,
  formatPercentageNumber,
} from '~/widgets/earnings/utils/utils';

export const formatNumber = (isPercentage: boolean, value?: number) => {
  if (isPercentage) {
    return formatPercentageNumber(value);
  }

  return formatNumberWithUnit(value);
};
