import * as Radix from '@radix-ui/react-dropdown-menu';
import { RangeHorizon } from '@toggle/chart';
import { Entity, MappedEntity, SnakeMeta } from '@toggle/toggle';
import React, { ReactNode, useState } from 'react';

import { ChartPreview } from '~/modules/chart-preview/ChartPreview';

export interface ChartPreviewDropdownProps
  extends Radix.DropdownMenuContentProps {
  children: ReactNode;
  enableHover?: boolean;
  entity: Entity | MappedEntity;
  horizon: RangeHorizon;
  snakeMeta?: SnakeMeta;
}

export const ChartPreviewDropdown = ({
  children,
  entity,
  horizon,
  ...rest
}: ChartPreviewDropdownProps) => {
  const [open, setOpen] = useState(false);

  const onMouseEnter = () => {
    setOpen(true);
  };

  const onMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Radix.Root open={open} onOpenChange={setOpen} modal={false}>
      <Radix.Trigger
        asChild
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </Radix.Trigger>
      <Radix.Portal>
        {open && (
          <ChartPreview
            horizon={horizon}
            entity={entity}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            {...rest}
          />
        )}
      </Radix.Portal>
    </Radix.Root>
  );
};
