import { InfiniteData } from '@tanstack/react-query';
import { RangeHorizon } from '@toggle/chart';
import {
  Entity,
  ExploreResults,
  ExploreSingleInsight,
  PostExploreRequestFilters,
} from '@toggle/toggle';

import { useExploreStore } from '~/views/insights/hooks/use-explore-store/useExploreStore';

export type ExploreMappedData = {
  entity: Entity;
  insights: ExploreSingleInsight[];
  isLoading: boolean;
};

export const HORIZON_KEY_MAPPING: Record<string, RangeHorizon> = {
  '1w': RangeHorizon.OneWeek,
  '2w': RangeHorizon.TwoWeeks,
  '1m': RangeHorizon.OneMonth,
  '2m': RangeHorizon.TwoMonths,
  '3m': RangeHorizon.ThreeMonths,
  '6m': RangeHorizon.SixMonths,
};

export const PERIOD = 'period';
export const DIRECTION = 'direction';
export const TRADING_HORIZON = 'trading_horizon';
export const RETURN_PROFILE = 'return_profile';
export const INSIGHTS_QUALITY = 'insights_quality';

export const useGetActiveHorizon = () => {
  const { filterGroups } = useExploreStore(state => {
    return {
      filterGroups: state.filterGroups,
    };
  });

  const currentPeriod = filterGroups.INSIGHT_FILTERS.activeFilterOptions.find(
    option => option.id === PERIOD
  )?.options[0].key;

  if (!currentPeriod) {
    return RangeHorizon.OneMonth;
  }

  return HORIZON_KEY_MAPPING[currentPeriod] ?? RangeHorizon.OneMonth;
};

export const useGetExploreFiltersValues = () => {
  const { assetFilterGroup } = useExploreStore(state => {
    return {
      assetFilterGroup: state.filterGroups,
    };
  });

  const getFilterValue = (filterId: string) => {
    const filter = assetFilterGroup.INSIGHT_FILTERS.activeFilterOptions.find(
      option => option.id === filterId
    );
    return filter?.options[0].value;
  };

  const result = [
    PERIOD,
    DIRECTION,
    TRADING_HORIZON,
    RETURN_PROFILE,
    INSIGHTS_QUALITY,
  ].reduce((accu, curr) => {
    const value = getFilterValue(curr);

    if (value) {
      return {
        ...accu,
        [curr]: {
          values: [value],
        },
      };
    }

    return accu;
  }, {} as Omit<PostExploreRequestFilters, 'entity_tag' | 'combined'>);

  return result;
};

export const mapExploreData = ({
  exploreData,
  entities,
  isEntitiesLoading,
}: {
  exploreData?: InfiniteData<ExploreResults, unknown>;
  entities: Entity[];
  isEntitiesLoading: boolean;
}): ExploreMappedData[] =>
  exploreData?.pages?.flatMap(page =>
    page.entities
      .map(exploreInsight => {
        const entity = entities.find(e => e.tag === exploreInsight.tag);

        if (!entity) {
          return null;
        }

        return {
          entity,
          insights: exploreInsight.insights.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          ),
          isLoading: isEntitiesLoading && !entity,
        };
      })
      .filter(e => !!e)
      .filter(e => !e.entity.gated)
  ) ?? [];
