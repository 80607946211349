import React, {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
} from 'react';

import { Icon } from '~/components/icon/Icon';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Button.styles';

export type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';
export type IconPosition = 'left' | 'right';
export type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'empty'
  | 'critical';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: ReactNode;
  iconName?: SvgIconNames;
  iconPosition?: IconPosition;
  variant?: Variant;
  size?: Size;
  isLoading?: boolean;
  fullWidth?: boolean;
  'data-testid'?: string;
  children?: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      iconName,
      iconPosition = 'left',
      variant = 'primary',
      size = 'medium',
      isLoading = false,
      fullWidth = false,
      children,
      ...rest
    },
    ref
  ) => {
    let iconSize: number;

    switch (size) {
      case 'large':
      case 'xl':
        iconSize = 20;
        break;
      case 'xxl':
        iconSize = 40;
        break;
      case 'xsmall':
        iconSize = 12;
        break;
      default:
        iconSize = 16;
    }

    return (
      <S.ButtonRoot
        data-testid="button"
        $size={size}
        $variant={variant}
        $iconOnly={!label}
        $fullWidth={fullWidth}
        {...rest}
        ref={ref}
      >
        {isLoading ? (
          <S.Loading data-testid="button-loading">
            <S.Spinner />
          </S.Loading>
        ) : (
          <S.Label $isReversed={iconPosition === 'right'}>
            {iconName && <Icon iconName={iconName} size={iconSize} />}
            {label && <S.Text>{label}</S.Text>}
          </S.Label>
        )}
        {children}
      </S.ButtonRoot>
    );
  }
) as ForwardRefExoticComponent<
  ButtonProps & RefAttributes<HTMLButtonElement>
> & {
  Styled: typeof S;
};

Button.Styled = S;
