export const keyboard = {
  esc: {
    key: 'Escape',
  },
  enter: {
    key: 'Enter',
  },
  space: {
    code: 'Space',
    key: 'Space',
  },
  tab: {
    key: 'Tab',
  },
  up: {
    key: 'ArrowUp',
  },
  down: {
    key: 'ArrowDown',
  },
  left: {
    key: 'ArrowLeft',
  },
  right: {
    key: 'ArrowRight',
  },
} as const;

export type KeyboardKeys = typeof keyboard extends Record<
  string,
  { key: infer U }
>
  ? U
  : never;
