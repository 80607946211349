import { ColumnDef, Row } from '@tanstack/react-table';
import { Tooltip, TooltipTrigger, WithNAFallback } from '@toggle/design-system';
import {
  GovernmentTradeOutputResponse,
  InsiderTradeOutputResponse,
  IpoOutputResponse,
} from '@toggle/toggle';
import { TFunction } from 'i18next';
import React from 'react';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { ScreenerEventsTableData } from '~/hooks/use-screener-events/useScreenerEvents';
import { MonetaryValueCell } from '~/views/theme-overview/tab-content/companies/knowledge-graph/companies-table/monetary-value-cell/MonetaryValueCell';
import { DateCell } from '~/widgets/earnings/components/date-cell/DateCell';

import { LinkCell } from '../link-cell/LinkCell';
import { OfferingPriceTooltipLabel } from '../offering-price-tooltip-label/OfferingPriceTooltipLabel';
import * as S from '../ScreenerEventsTable.styles';
import { UnderwritersCell } from '../underwriters-cell/UnderwritersCell';
import { ScreenerEvents } from './types';

export const SCREENER_TICKER_COLUMN_ID = 'tag';
export const SCREENER_EVENTS_TRADES_COLUMN_ID = 'trades';
export const SCREENER_EVENTS_SHARES_COLUMN_ID = 'shares';
export const SCREENER_EVENTS_AMOUNT_COLUMN_ID = 'amount';
export const SCREENER_EVENTS_PRICE_COLUMN_ID = 'price';
export const SCREENER_EVENTS_OPEN_COLUMN_ID = 'open';
export const SCREENER_EVENTS_TAG_COLUMN_ID = 'tag';
export const SCREENER_EVENTS_DATE_COLUMN_ID = 'transaction_date';
const SCREENER_EVENTS_TRANSACTION_COLUMN_ID = 'transaction';
const SCREENER_EVENTS_INSIDER_TITLE_COLUMN_ID = 'insider_title';
const SCREENER_EVENTS_NAME_COLUMN_ID = 'insider_name';
const SCREENER_EVENTS_SECURITY_TYPE_COLUMN_ID = 'security_type';
export const SCREENER_EVENTS_LINK_COLUMN_ID = 'filing_url';
const SCREENER_EVENTS_CHAMBER_COLUMN_ID = 'chamber';
const SCREENER_EVENTS_OFFERING_COLUMN_ID = 'offering';
const SCREENER_EVENTS_LOCKUP_COLUMN_ID = 'lockup';
const SCREENER_EVENTS_UNDERWRITERS_COLUMN_ID = 'underwriters';

export const getGovernmentMemberStatus = (status?: string) => {
  if (!status) {
    return undefined;
  }

  return status === 'Member' ? 'Current' : 'Former';
};

export const parseRange = (range?: string): [number, number] => {
  if (!range) {
    return [Infinity, Infinity];
  }

  const cleanedRange = range.replace(/[\$,]/g, '');
  const [min, max] = cleanedRange.split(' - ').map(Number);
  return [min, max];
};

export const sortRangeStrings = (a?: string, b?: string): number => {
  const [minA] = parseRange(a);
  const [minB] = parseRange(b);

  if (minA === Infinity && minB === Infinity) {
    return 0;
  }

  if (minA === Infinity) {
    return 1;
  }

  if (minB === Infinity) {
    return -1;
  }

  return minA - minB;
};

export const isInvalidData = (value?: number | string) => {
  return value === -Infinity || value === undefined;
};

export const createEntityColumnDef = <T extends ScreenerEventsTableData>(
  t: TFunction
): ColumnDef<T> => ({
  id: SCREENER_EVENTS_TAG_COLUMN_ID,
  header: t('screener:screenerEvents.ticker'),
  meta: {
    alignment: 'start',
  },
  cell: ({ row }) => {
    const entity = row.original.entity;
    return (
      entity && (
        <S.LogoAndTickerWrapper>
          <AssetLogoContainer entity={entity} size={24} />
          <S.TextualTextWrapper>{entity.formattedTicker}</S.TextualTextWrapper>
        </S.LogoAndTickerWrapper>
      )
    );
  },
  accessorFn: row => row.entity?.formattedTicker ?? row.tag,
});

export const createLinkColumnDef = <T extends ScreenerEventsTableData>(
  t: TFunction,
  field: keyof T
): ColumnDef<T> => ({
  id: SCREENER_EVENTS_LINK_COLUMN_ID,
  header: t('screener:screenerEvents.link'),
  cell: props => <LinkCell url={props.row.original[field] as string} />,
  accessorKey: field,
});

export const createTradesColumnDef = <T extends ScreenerEventsTableData>(
  t: TFunction
): ColumnDef<T> => ({
  id: SCREENER_EVENTS_TRADES_COLUMN_ID,
  header: t('screener:screenerEvents.numberOfTrades'),
  meta: {
    alignment: 'end',
  },
  cell: ({ getValue }) => getValue<number>(),
  accessorKey: 'trades',
  aggregationFn: 'count',
});

export const getInsiderTradeColumns = (t: TFunction) => {
  const insiderTradeColumns: ColumnDef<InsiderTradeOutputResponse>[] = [
    createEntityColumnDef(t),
    createTradesColumnDef(t),
    {
      id: SCREENER_EVENTS_DATE_COLUMN_ID,
      header: t('screener:screenerEvents.date'),
      cell: props => (
        <DateCell
          date={new Date(props.row.original.transaction_date)}
          displayIcon={false}
        />
      ),
      accessorKey: 'transaction_date',
    },
    {
      id: SCREENER_EVENTS_TRANSACTION_COLUMN_ID,
      header: t('screener:screenerEvents.transaction'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.transaction}
          value={
            <Tooltip
              label={props.row.original.transaction}
              disabled={isInvalidData(props.row.original.transaction)}
            >
              <S.OverflowEllipsisTextCell maxWidth={'160px'}>
                {props.row.original.transaction}
              </S.OverflowEllipsisTextCell>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'transaction',
    },
    {
      id: SCREENER_EVENTS_INSIDER_TITLE_COLUMN_ID,
      header: t('screener:screenerEvents.title'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.insider_title}
          value={
            <Tooltip
              label={props.row.original.insider_title}
              disabled={isInvalidData(props.row.original.insider_title)}
            >
              <S.OverflowEllipsisTextCell maxWidth={'120px'}>
                {props.row.original.insider_title}
              </S.OverflowEllipsisTextCell>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'insider_title',
    },
    {
      id: SCREENER_EVENTS_NAME_COLUMN_ID,
      header: t('screener:screenerEvents.name'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.insider_name}
          value={
            <Tooltip
              label={props.row.original.insider_name}
              disabled={isInvalidData(props.row.original.insider_name)}
            >
              <S.OverflowEllipsisTextCell maxWidth={'190px'}>
                {props.row.original.insider_name}
              </S.OverflowEllipsisTextCell>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'insider_name',
    },
    {
      id: SCREENER_EVENTS_SHARES_COLUMN_ID,
      header: t('screener:screenerEvents.shares'),
      meta: {
        alignment: 'end',
      },
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.post_transaction_quantity}
          value={
            <Tooltip
              label={
                <S.CellWithTooltip>
                  {t('screener:screenerEvents.postTransactionQuantity')}
                  <br />
                  {props.row.original.post_transaction_quantity}
                </S.CellWithTooltip>
              }
              trigger={TooltipTrigger.Hover}
              placement="top-end"
              disabled={isInvalidData(
                props.row.original.post_transaction_quantity
              )}
            >
              <S.CellWithTooltip $hasTooltip>
                {props.row.original.shares}
              </S.CellWithTooltip>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'shares',
    },
    {
      id: SCREENER_EVENTS_SECURITY_TYPE_COLUMN_ID,
      header: t('screener:screenerEvents.securityType'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.security_type}
          value={
            <Tooltip
              label={props.row.original.security_type}
              disabled={isInvalidData(props.row.original.security_type)}
            >
              <S.OverflowEllipsisTextCell maxWidth={'180px'}>
                {props.row.original.security_type}
              </S.OverflowEllipsisTextCell>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'security_type',
    },
    createLinkColumnDef(t, 'filing_url'),
  ];
  return insiderTradeColumns as ColumnDef<ScreenerEventsTableData>[];
};

export const getGovernmentTradeColumns = (t: TFunction) => {
  const governmentTradeColumns: ColumnDef<GovernmentTradeOutputResponse>[] = [
    createEntityColumnDef(t),
    createTradesColumnDef(t),
    {
      id: SCREENER_EVENTS_DATE_COLUMN_ID,
      header: t('screener:screenerEvents.date'),
      cell: props => (
        <DateCell
          date={new Date(props.row.original.transaction_date)}
          displayIcon={false}
        />
      ),
      accessorKey: 'transaction_date',
    },
    {
      id: SCREENER_EVENTS_TRANSACTION_COLUMN_ID,
      header: t('screener:screenerEvents.transaction'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.transaction}
          value={
            <S.OverflowEllipsisTextCell maxWidth={'135px'}>
              {props.row.original.transaction}
            </S.OverflowEllipsisTextCell>
          }
        />
      ),
      accessorKey: 'transaction',
    },
    {
      id: SCREENER_EVENTS_AMOUNT_COLUMN_ID,
      header: t('screener:screenerEvents.amount'),
      meta: {
        alignment: 'end',
      },
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.amount}
          value={
            <S.CellWithTooltip>{props.row.original.amount}</S.CellWithTooltip>
          }
        />
      ),
      accessorKey: 'amount',
      sortingFn: (
        rowA: Row<GovernmentTradeOutputResponse>,
        rowB: Row<GovernmentTradeOutputResponse>
      ) => {
        const valueA = rowA.original.amount;
        const valueB = rowB.original.amount;
        return sortRangeStrings(valueA, valueB);
      },
    },
    {
      id: SCREENER_EVENTS_NAME_COLUMN_ID,
      header: t('screener:screenerEvents.name'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.display_name}
          value={
            <Tooltip
              label={`${getGovernmentMemberStatus(
                props.row.original.status
              )} | ${props.row.original.party} - ${props.row.original.state}`}
              trigger={TooltipTrigger.Hover}
              placement="top-start"
              disabled={
                isInvalidData(props.row.original.status) ||
                isInvalidData(props.row.original.party) ||
                isInvalidData(props.row.original.state)
              }
            >
              <S.OverflowEllipsisTextCell maxWidth={'295px'} $hasTooltip>
                {props.row.original.display_name}
              </S.OverflowEllipsisTextCell>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'display_name',
    },
    {
      id: SCREENER_EVENTS_CHAMBER_COLUMN_ID,
      header: t('screener:screenerEvents.chamber'),
      cell: props => (
        <WithNAFallback
          check={!!props.row.original.chamber}
          value={
            <Tooltip
              label={props.row.original.committees.map((item, index) => (
                <S.StyledListWrapper key={index}>
                  <ul>
                    <li key={index}>{item}</li>
                  </ul>
                </S.StyledListWrapper>
              ))}
              trigger={TooltipTrigger.Hover}
              disabled={!props.row.original.committees?.length}
            >
              <S.OverflowEllipsisTextCell maxWidth={'140px'} $hasTooltip>
                {props.row.original.chamber}
              </S.OverflowEllipsisTextCell>
            </Tooltip>
          }
        />
      ),
      accessorKey: 'chamber',
    },
    createLinkColumnDef(t, 'disclosure_url'),
  ];
  return governmentTradeColumns as ColumnDef<ScreenerEventsTableData>[];
};

export const getIpoColumns = (t: TFunction) => {
  const ipoTradeColumns: ColumnDef<IpoOutputResponse>[] = [
    createEntityColumnDef(t),
    {
      id: SCREENER_EVENTS_DATE_COLUMN_ID,
      header: t('screener:screenerEvents.date'),
      cell: props => (
        <WithNAFallback
          value={
            props.row.original.date !== undefined && (
              <DateCell
                date={new Date(props.row.original.date)}
                displayIcon={false}
              />
            )
          }
        />
      ),
      accessorKey: 'date',
      aggregationFn: 'count',
    },
    {
      id: SCREENER_EVENTS_OFFERING_COLUMN_ID,
      header: t('screener:screenerEvents.offering'),
      cell: props => (
        <Tooltip label={props.row.original.ipo_type}>
          <S.OverflowEllipsisTextCell maxWidth={'150px'}>
            {props.row.original.ipo_type}
          </S.OverflowEllipsisTextCell>
        </Tooltip>
      ),
      accessorKey: 'ipo_type',
    },
    {
      id: SCREENER_EVENTS_PRICE_COLUMN_ID,
      header: t('screener:screenerEvents.offeringPrice'),
      meta: {
        alignment: 'end',
      },
      cell: props => (
        <WithNAFallback
          value={
            props.row.original.price_public_offering && (
              <Tooltip
                label={
                  props.row.original.price_min !== undefined &&
                  props.row.original.price_max !== undefined && (
                    <OfferingPriceTooltipLabel
                      minPrice={props.row.original.price_min}
                      maxPrice={props.row.original.price_max}
                    />
                  )
                }
                trigger={TooltipTrigger.Hover}
                placement="top-end"
                disabled={
                  isInvalidData(props.row.original.price_min) ||
                  isInvalidData(props.row.original.price_max)
                }
              >
                <S.UnderlinedTextWrapper>
                  <MonetaryValueCell
                    value={parseFloat(props.row.original.price_public_offering)}
                  />
                </S.UnderlinedTextWrapper>
              </Tooltip>
            )
          }
        />
      ),
      accessorKey: 'price_public_offering',
    },
    {
      id: SCREENER_EVENTS_OPEN_COLUMN_ID,
      header: t('screener:screenerEvents.open'),
      meta: {
        alignment: 'end',
      },
      cell: props => (
        <WithNAFallback
          value={
            props.row.original.price_open !== undefined && (
              <MonetaryValueCell
                value={parseFloat(props.row.original.price_open)}
              />
            )
          }
        />
      ),
      accessorKey: 'price_open',
    },
    {
      id: SCREENER_EVENTS_LOCKUP_COLUMN_ID,
      header: t('screener:screenerEvents.lockup'),
      cell: props => (
        <Tooltip
          label={t('screener:screenerEvents.days', {
            count: props.row.original.insider_lockup_days,
          })}
          trigger={TooltipTrigger.Hover}
          placement="top-start"
          disabled={isInvalidData(props.row.original.insider_lockup_days)}
        >
          <S.UnderlinedTextWrapper>
            <WithNAFallback
              value={
                props.row.original.insider_lockup_date !== undefined && (
                  <DateCell
                    date={new Date(props.row.original.insider_lockup_date)}
                    displayIcon={false}
                  />
                )
              }
            />
          </S.UnderlinedTextWrapper>
        </Tooltip>
      ),
      accessorKey: 'insider_lockup_date',
    },
    {
      id: SCREENER_EVENTS_UNDERWRITERS_COLUMN_ID,
      header: t('screener:screenerEvents.underwriters'),
      cell: props => (
        <UnderwritersCell
          leadUnderwriters={props.row.original.lead_underwriters}
          otherUnderwriters={props.row.original.other_underwriters}
        />
      ),
      accessorKey: 'lead_underwriters',
    },
    createLinkColumnDef(t, 'filing_url'),
  ];
  return ipoTradeColumns as ColumnDef<ScreenerEventsTableData>[];
};

export const getColumns = ({
  eventType,
  t,
}: {
  eventType: ScreenerEvents;
  t: TFunction<'screener', undefined>;
}): ColumnDef<ScreenerEventsTableData>[] => {
  if (eventType === ScreenerEvents.InsiderTrades) {
    return getInsiderTradeColumns(t);
  }

  if (eventType === ScreenerEvents.GovTrades) {
    return getGovernmentTradeColumns(t);
  }

  return getIpoColumns(t);
};
