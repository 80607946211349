import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { RenameInput } from '../rename-input/RenameInput';
import { WatchlistManagerHeaderProps } from '../watchlist-manager-header/WatchlistManagerHeader';
import * as S from './WatchlistManagerName.styles';

export interface WatchlistManagerNameProps extends WatchlistManagerHeaderProps {
  openNameInput: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const WatchlistManagerName: FC<WatchlistManagerNameProps> = ({
  isRenameActive,
  listName,
  onUpdateName,
  openNameInput,
  setIsRenameActive,
  hasEntities,
  hasInputError,
  activeListName,
}) => {
  const { t } = useTranslation('watchlistManager');

  if (activeListName) {
    return (
      <S.Title data-testid="watchlist-title">
        {t('watchlistManager:addAssetsToWatchlist', {
          name: activeListName,
        })}
      </S.Title>
    );
  }

  if (!hasEntities) {
    return (
      <S.Title data-testid="watchlist-title">
        {t('watchlistManager:createNewWatchlist')}
      </S.Title>
    );
  }

  return (
    <S.WatchlistManagerTitleContainer>
      {isRenameActive ? (
        <RenameInput
          value={listName}
          setValue={onUpdateName}
          setIsRenameActive={setIsRenameActive}
          hasError={hasInputError}
        />
      ) : (
        <>
          <S.Title data-testid="watchlist-title">
            {listName || t('watchlistManager:nameYourWatchlist')}
          </S.Title>
          <S.StyledButton
            iconName="Pencil"
            variant="empty"
            size="small"
            onClick={openNameInput}
            data-testid="pencil-button"
          />
        </>
      )}
    </S.WatchlistManagerTitleContainer>
  );
};
