import { ScreenerFilter } from '@toggle/toggle';

import { ActiveFilterOptions } from '~/components/filters/Filters';

export const extractByCondition = <
  T extends ScreenerFilter | ActiveFilterOptions
>(
  items: T[],
  condition: (item: T) => boolean
) => {
  return items.reduce(
    (acc, item) => {
      if (condition(item)) {
        acc.matching.push(item);
      } else {
        acc.nonMatching.push(item);
      }

      return acc;
    },
    { matching: [] as T[], nonMatching: [] as T[] }
  );
};
