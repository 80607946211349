import { Button, SkeletonLoader, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const SingleArticleRoot = styled.article`
  display: flex;
  flex-direction: column;

  & > :last-child {
    margin-bottom: 0px;
  }
`;

export const Title = styled.h1`
  ${typographyV2.titleXL};
  margin-bottom: 8px;
`;

export const Meta = styled.section`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  display: flex;
  align-items: center;
  margin: 0;
`;

export const MetaItem = styled.span`
  &:not(:last-child)::after {
    content: '•';
    margin: 0 0.5em;
  }
`;

export const ViewMoreButton = styled(Button)`
  padding: 0px 8px;
  border: none;
`;

export const AssetChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
`;

export const ContentSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '16px',
        borderRadius: '8px',
        marginBottom: '8px',
      },
    },
    {
      styles: {
        width: '100%',
        height: '16px',
        borderRadius: '8px',
        marginBottom: '8px',
      },
    },
    {
      styles: {
        width: '100%',
        height: '16px',
        borderRadius: '8px',
        marginBottom: '8px',
      },
    },
    {
      styles: {
        width: '80%',
        height: '16px',
        borderRadius: '8px',
        marginBottom: '8px',
      },
    },
  ],
})<{ $width?: string }>`
  padding: 16px 0;
`;
