import { ColumnMeta } from '@toggle/toggle';

const MultiColumnFilterNameToKeyArray = [
  [/Return \(%\)/, 'returns'],
  [/Seasonality Returns \(%\)/, 'seasonality_returns'],
];

export const getColumnFilterKeyDetails = (meta?: ColumnMeta) => {
  if (!meta) {
    return {
      isMultiColumnFilter: false,
      filterKey: '',
    };
  }

  const { filter, name } = meta;

  const singleColumnFilterKey = filter;
  const multiColumnFilterKey =
    MultiColumnFilterNameToKeyArray.find(([regex]) =>
      (regex as RegExp).test(name)
    )?.[1] || '';
  const filterKey = singleColumnFilterKey || multiColumnFilterKey;

  const isMultiColumnFilter = !filter && !!filterKey;

  return {
    isMultiColumnFilter,
    filterKey,
  };
};
