import { formatPercentage } from '@toggle/helpers/src/utils/numbers/numbers';
import React from 'react';

import { LABEL_WIDTH, margin } from '../constants';
import * as S from '../HorizontalBarChart.styles';

export interface AxisProps extends React.SVGProps<SVGGElement> {
  headerX: number;
  headerLabel: string;
  xScale: d3.ScaleLinear<number, number, never>;
  tickValues: number[];
  height: number;
  useOriginalValue: boolean;
  prefix: string;
}

export const Axis = ({
  headerX,
  headerLabel,
  xScale,
  tickValues,
  height,
  useOriginalValue,
  prefix,
  ...props
}: AxisProps) => {
  const isEven = (i: number) => i % 2 === 0;
  const zeroPoint = tickValues.findIndex(t => t === 0);
  const strokeWidth = (tick: number) => (tick === 0 ? 2 : 1);

  const getLineStroke = (i: number) => {
    if (zeroPoint > -1 && isEven(zeroPoint)) {
      return isEven(i) ? undefined : '2,2';
    } else {
      return isEven(i) ? '2,2' : undefined;
    }
  };

  return (
    <g data-testid="axis-root" {...props}>
      {headerLabel && (
        <S.AxisText data-testid="y-axis-right-header" x={headerX} isHeader>
          {headerLabel}
        </S.AxisText>
      )}

      {tickValues.map((tick, i) => {
        const baseAxisText = formatPercentage(tick, { useOriginalValue });
        const axisText =
          tick > 0 && !!prefix ? `${prefix}${baseAxisText}` : baseAxisText;
        return (
          <g
            key={i}
            transform={`translate(${xScale(tick) + LABEL_WIDTH}, 0)`}
            data-testid="x-axis-item"
          >
            <S.AxisLine
              data-testid="x-axis-vertical-line"
              y1={margin.top}
              y2={height}
              strokeWidth={strokeWidth(tick)}
              strokeDasharray={getLineStroke(i)}
            />
            <S.AxisText y={0} data-testid="x-axis-text-item">
              {axisText}
            </S.AxisText>
          </g>
        );
      })}
    </g>
  );
};
