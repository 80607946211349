import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './WithNAFallback.styles';

export interface WithNAFallbackProps {
  value: React.ReactNode;
  check?: boolean;
}

export const WithNAFallback = ({
  value,
  check = true,
}: WithNAFallbackProps) => {
  const { t } = useTranslation('common');

  if (check && value) {
    return value;
  }

  return (
    <S.Text aria-haspopup data-testid="table-cell-empty-value">
      {t('common:table.na')}
    </S.Text>
  );
};
