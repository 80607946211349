import {
  FAVORITE_WATCHLIST,
  useRemoteStorage,
} from '~/hooks/use-remote-storage';

import {
  Watchlist,
  WatchlistEntities,
  Watchlists,
  WatchlistStore,
} from './watchlist-types';

export const isWatchlistFavorite = (id: string) => {
  const items = useRemoteStorage.getState().items;
  return id === items?.[FAVORITE_WATCHLIST];
};

export const hasFavoriteWatchlist = () => {
  const items = useRemoteStorage.getState().items;
  return !!items?.[FAVORITE_WATCHLIST];
};

export const mapAndSortWatchlists = (watchlists: Watchlists) => {
  const sortedWatchlists = watchlists.sort(
    (listA, listB) =>
      new Date(listB.last_update).valueOf() -
      new Date(listA.last_update).valueOf()
  );

  return sortedWatchlists.reduce((acc, list) => {
    const isFavorite = isWatchlistFavorite(list.id);
    const mappedItem = {
      ...list,
      isFavorite,
      entities: [],
    };

    if (isFavorite) {
      return [mappedItem, ...acc];
    } else {
      return [...acc, mappedItem];
    }
  }, [] as Watchlist[]);
};

export const generateNewListName = (lists: Watchlist[]) => {
  const allListNames = lists.map(list => list.name);
  let newListName = 'Watchlist - 1';
  let i = 1;

  while (allListNames.includes(newListName)) {
    i += 1;
    newListName = `Watchlist - ${i}`;
  }

  return newListName;
};

interface MapListsWithNewListNameParams {
  lists: Watchlist[];
  id: string;
  name: string;
}

export const mapListsWithNewListName = ({
  lists,
  id,
  name,
}: MapListsWithNewListNameParams) => {
  return lists.map(list => {
    if (list.id === id) {
      return {
        ...list,
        name,
      };
    }

    return list;
  });
};

interface MapListsWithNewListEntitiesParams {
  lists: Watchlist[];
  id: string;
  entities: WatchlistEntities;
}

export const mapListsWithNewListEntities = ({
  lists,
  id,
  entities,
}: MapListsWithNewListEntitiesParams) => {
  return lists.map(list => {
    if (list.id === id) {
      return {
        ...list,
        entities: [entities, ...list.entities],
      };
    }

    return list;
  });
};

export const mapListsWithUpdatedWatchlist = ({
  lists,
  id,
  entities,
  name,
}: {
  lists: Watchlist[];
  id: string;
  entities: WatchlistEntities[];
  name: string;
}) => {
  return lists.map(list => {
    if (list.id === id) {
      const mapTagToEntity = list.entities.reduce((res, item) => {
        res[item.tag] = item;
        return res;
      }, {} as Record<string, WatchlistEntities>);

      return {
        ...list,
        entities: entities.map(e => mapTagToEntity[e.tag] ?? e),
        name,
      };
    }

    return list;
  });
};

export const getWatchlistEntitiesTags = (
  lists: Watchlist[],
  id: unknown
): string[] => {
  const list = lists.find(list => list.id === id);
  return list?.entities.map(entity => entity.tag) ?? [];
};

interface GetUniqueListEntitiesParams {
  lists: Watchlist[];
  id: string;
  tags: string[];
}

export const getUniqueListEntities = ({
  lists,
  id,
  tags,
}: GetUniqueListEntitiesParams) => {
  let duplicateTags: string[] = [];
  const currentEntityTags = getWatchlistEntitiesTags(lists, id);

  const uniqueTags = tags.filter(tag => {
    if (currentEntityTags.includes(tag)) {
      duplicateTags.push(tag);
      return false;
    }

    return true;
  });
  return [uniqueTags, duplicateTags];
};

export const addListIdToMap = ({
  listIdsByTag,
  tag,
  listId,
}: {
  listIdsByTag: WatchlistStore['listIdsByTag'];
  tag: string;
  listId: string;
}) => {
  const listIds = listIdsByTag.get(tag);

  if (listIds?.includes(listId)) {
    return;
  }

  if (listIds) {
    listIdsByTag.set(tag, [...listIds, listId]);
  } else {
    listIdsByTag.set(tag, [listId]);
  }
};

export const deleteListIdFromMap = ({
  listIdsByTag,
  tag,
  listId,
}: {
  listIdsByTag: WatchlistStore['listIdsByTag'];
  tag: string;
  listId: string;
}) => {
  const listIds = listIdsByTag.get(tag);

  if (listIds) {
    if (listIds.length > 1) {
      listIdsByTag.set(
        tag,
        listIds.filter(i => i !== listId)
      );
    } else if (listIds.length === 1) {
      listIdsByTag.delete(tag);
    }
  }
};
