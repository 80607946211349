import { apiFetch } from '~/utils/api-fetch/apiFetch';

export interface PromoMetadata {
  headerBannerMd?: string;
  headerPill?: string;
  paymentsLabel?: string;
  plansBanner?: string;
  settingsPriceLabel?: string;
  signUpBannerMd?: string;
}

export interface Promo {
  promoCode?: string;
  metadata: PromoMetadata;
}

interface ResponseSuccess {
  promo: {
    metadata: PromoMetadata;
  };
}

export const SUBSCRIPTIONS_PROMO_CODE = `subscriptions/v1/promo-code`;

export const verifyPromoCode = async (promoCode: string) => {
  const url = `${SUBSCRIPTIONS_PROMO_CODE}/${encodeURIComponent(promoCode)}`;
  return await apiFetch<ResponseSuccess>(url);
};
