import { SkeletonLoader, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const AssetInsightsListRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-soft);
`;

export const Heading = styled.p`
  ${typographyV2.titleSm}
  color: var(--text-soft);
`;

export const LoadingSkeleton = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '260px',
        borderRadius: '8px',
      },
    },
  ],
})``;
