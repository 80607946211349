import { TableHeader, TableHeaderProps } from '@toggle/design-system';
import React from 'react';

interface HeaderRowProps extends TableHeaderProps {
  labels: string[];
}

export const HeaderRow = ({ labels, ...rest }: HeaderRowProps) => (
  <tr data-testid="header-row">
    {labels.map(label => (
      <TableHeader key={label} isHoverEnabled={false} {...rest}>
        {label}
      </TableHeader>
    ))}
  </tr>
);
