import { Icon, PillDropdown, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const LogoAndTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPillDropdown = styled(PillDropdown)`
  margin-left: auto;
`;

export const TitleText = styled.div<{ $isCondensed: boolean }>`
  ${({ $isCondensed }) =>
    $isCondensed ? typographyV2.bodySm : typographyV2.bodyMd};
  text-transform: capitalize;
`;

export const SubtitleText = styled.div`
  ${typographyV2.bodyXs}
  color: var(--text-soft);
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
  fill: var(--icon-primary);
`;
