export const SNAKES_TTM = [
  'pe_ibes_trailing_12m',
  'trailing_evsales',
  'pb_ibes_trailing_12m',
  'trailing_price_cfps',
];

export const SNAKES_NTM = [
  'pe_ibes_forward_12m',
  'forward_evsales',
  'pb_ibes_forward_12m',
  'forward_price_cfps',
];

export const DISPLAYED_ENTITIES = 5;

export const CHARTS_GAP = 16;

interface GetBarChartDimensionProps {
  width: number;
  isDrawer: boolean;
  chartsLength: number;
}

export const getBarChartDimension = ({
  width,
  isDrawer,
  chartsLength,
}: GetBarChartDimensionProps) => {
  return {
    chartWidth: isDrawer ? 220 : width / chartsLength - CHARTS_GAP,
    chartHeight: isDrawer ? 160 : (width / chartsLength - CHARTS_GAP) * 0.8,
  };
};
