import React from 'react';

import * as S from './ThreeDots.styles';

interface ThreeDotsProps {
  className?: string;
}

export const ThreeDots = ({ className }: ThreeDotsProps) => {
  return (
    <S.ThreeDotsRoot data-testid="three-dots" className={className}>
      <S.Dot />
      <S.Dot />
      <S.Dot />
    </S.ThreeDotsRoot>
  );
};
