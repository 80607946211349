import { Dropdown, DropdownListItem, Select } from '@toggle/design-system';
import {
  CustomComponentOption,
  CustomFilter,
  ScreenerFilter,
  ScreenerFilterOption,
} from '@toggle/toggle';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CustomFilterModalInput,
  InputState,
} from '~/components/custom-filter-modal-input/CustomFilterModalInput';
import {
  FilterGroupKeys,
  UseFilterActionsReturn,
} from '~/hooks/use-filter-actions/useFilterActions';

import { ToggleFilterProps } from '../update-filter-options/updateFilterOptions';
import { ChildFilterOptionDropdown } from './child-filter-option-dropdown/ChildFilterOptionDropdown';
import * as S from './CustomFilterModal.styles';

const numFormatter = new Intl.NumberFormat(window.navigator.language, {
  notation: 'compact',
  maximumFractionDigits: 2,
});

export interface CustomFilterModalProps<
  T extends FilterGroupKeys = FilterGroupKeys
> {
  activeFilter: ScreenerFilter;
  name?: string;
  onClose: () => void;
  addFilterOption: (filterProps: ToggleFilterProps) => void;
  addActiveFilters?: UseFilterActionsReturn<T>['addActiveFilters'];
}

export const CustomFilterModal = <T extends FilterGroupKeys>({
  activeFilter,
  name = activeFilter.config.custom?.name,
  onClose,
  addFilterOption,
  addActiveFilters,
}: CustomFilterModalProps<T>) => {
  const { t } = useTranslation(['screener', 'common']);
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState<InputState>();

  const { custom } = activeFilter.config.custom as CustomFilter;
  const [customFilterComponent] = custom;
  const [customOption, setCustomOption] = useState<CustomComponentOption>(
    customFilterComponent.custom[0]
  );

  const childFilter = activeFilter.config.children?.[0];
  const defaultChildOption = childFilter?.options.find(
    child => child.key === childFilter.default
  );
  const [selectedChildOption, setSelectedChildOption] = useState<
    ScreenerFilterOption | undefined
  >(defaultChildOption);

  const onSelect = (item: CustomComponentOption) => {
    setCustomOption(item);
    setInputState(undefined);
  };

  const handleChange = ({ units, scale, values }: InputState) => {
    setInputState({
      units,
      scale,
      values,
    });
  };

  const formatName = (inputState: InputState) => {
    const { units, values } = inputState;
    const [min, max] = [values.min?.number, values.max?.number].map(v =>
      typeof v === 'number' ? numFormatter.format(v) : null
    );

    if (min && max) {
      return t('screener:between', {
        value1: `${min}${units}`,
        value2: `${max}${units}`,
      });
    }

    return min ? `> ${min}${units}` : `< ${max}${units}`;
  };

  const handleApply = () => {
    if (!inputState) {
      return;
    }

    if (inputState.values.min && inputState.values.max) {
      const max = inputState.values.max.number;
      const min = inputState.values.min.number;
      inputState.values.min.number = Math.min(min, max);
      inputState.values.max.number = Math.max(min, max);
    }

    const value = Object.fromEntries(
      Object.entries(inputState.values).map(([key, values]) => [
        key,
        values.number / inputState.scale,
      ])
    );

    const filterPayload: ToggleFilterProps = {
      filterKey: activeFilter.key,
      option: {
        key: customOption.key,
        name: formatName(inputState),
        value,
      },
      multiselect: false,
      childrenOptions:
        childFilter && selectedChildOption
          ? [
              {
                childName: childFilter.name,
                ...selectedChildOption,
              },
            ]
          : undefined,
    };

    addActiveFilters?.(activeFilter);
    addFilterOption(filterPayload);
    onClose();
  };

  const requiredKeys = customOption.custom
    .map(item => item.value_field)
    .filter(Boolean) as string[];
  const isPrimaryDisabled =
    !inputState ||
    !requiredKeys.every(
      key =>
        inputState.values[key]?.string &&
        !Number.isNaN(inputState.values[key].number)
    );

  return (
    <S.StyledModalRoot
      isOpen
      title={
        <S.ModalTitleWrapper>
          {name}
          {childFilter && selectedChildOption && (
            <ChildFilterOptionDropdown
              childFilter={childFilter}
              selectedChildOption={selectedChildOption}
              setSelectedChildOption={setSelectedChildOption}
            />
          )}
        </S.ModalTitleWrapper>
      }
      onClose={onClose}
      primaryBtnProps={{
        label: t('screener:apply'),
        onClick: handleApply,
        disabled: isPrimaryDisabled,
      }}
      cancelBtnProps={{ label: t('common:cancel'), onClick: onClose }}
      data-testid="custom-filter-modal"
    >
      <S.CustomSelectorWrapper>
        {customFilterComponent.type === 'select' && (
          <>
            <Dropdown
              dropdownItems={customFilterComponent.custom}
              selectItem={onSelect}
              shouldShowItems={isOpen}
              renderDropdownItem={(item, isActive, isFocused) => (
                <DropdownListItem
                  label={item.name}
                  isActive={isActive}
                  isFocused={isFocused}
                />
              )}
              onItemsShowChange={setIsOpen}
            >
              <Select
                isActive={isOpen}
                inputProps={{
                  value: customOption?.name,
                }}
              />
            </Dropdown>
            <S.CustomStyledInputWrapper>
              {customOption.custom.map(meta => (
                <Fragment key={meta.value_field ?? meta.text}>
                  <CustomFilterModalInput
                    input={inputState}
                    handleChange={handleChange}
                    meta={meta}
                  />
                </Fragment>
              ))}
            </S.CustomStyledInputWrapper>
          </>
        )}
      </S.CustomSelectorWrapper>
    </S.StyledModalRoot>
  );
};
