export const assetSubClassCategories = [
  {
    name: 'chart:widget.indicators.chipsSelector_recent',
    value: '',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_stocks',
    value: 'stocks',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_themes',
    value: 'themes',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_indexes',
    value: 'indexes',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_commodities',
    value: 'commodities',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_govBonds',
    value: 'govBonds',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_fx',
    value: 'fx',
    data: [],
  },
  {
    name: 'chart:widget.indicators.chipsSelector_economics',
    value: 'economics',
    data: [],
  },
];
