import { Input, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  width: 400px;
  ${typographyV2.bodyBase};
  ${Input.Styles.InputWrapper} {
    background-color: inherit;
  }
`;
