import { SingleNews } from '@toggle/toggle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Markdown } from '~/components/markdown/Markdown';

import { ArticleDate } from '../article-date/ArticleDate';
import { AssetChip } from '../asset-chip/AssetChip';
import * as S from './SingleArticle.styles';

export interface SingleArticleProps {
  article: SingleNews;
  isLoading: boolean;
  onEntityClick(entityTag: string): void;
}

const ENTITY_DISPLAY_LIMIT = 5;

export const SingleArticle = ({
  article,
  isLoading,
  onEntityClick,
}: SingleArticleProps) => {
  const { t } = useTranslation('news');
  const [showMore, setShowMore] = useState(false);

  const handleMore = () => {
    setShowMore(true);
  };

  const entities = showMore
    ? article.entities
    : article.entities.slice(0, ENTITY_DISPLAY_LIMIT);

  return (
    <S.SingleArticleRoot data-testid="single-article">
      <S.Title>{article.title}</S.Title>

      <S.Meta>
        <S.MetaItem>
          <ArticleDate date={article.published} />
        </S.MetaItem>
        <S.MetaItem>
          {
            /* i18next-extract-mark-context-next-line ["benzinga", "mtn", "dowjones"] */
            t('news:source', {
              context: article.source,
            })
          }
        </S.MetaItem>
      </S.Meta>

      <S.AssetChips>
        {entities.map(entityTag => (
          <AssetChip
            key={entityTag}
            entityTag={entityTag}
            onEntityClick={onEntityClick}
          />
        ))}
        {article.entities.length > ENTITY_DISPLAY_LIMIT && !showMore && (
          <S.ViewMoreButton
            variant="secondary"
            onClick={handleMore}
            size="small"
          >
            {t('news:seeMore')}
          </S.ViewMoreButton>
        )}
      </S.AssetChips>

      {isLoading && <S.ContentSkeleton data-testid="loading-state" />}

      {!isLoading && article?.body && <Markdown message={article.body} />}
    </S.SingleArticleRoot>
  );
};
