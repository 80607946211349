import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ChartFooter.styles';

export const ChartFooter = () => {
  const { t } = useTranslation(['common', 'earnings']);

  return (
    <S.ChartFooterRoot>
      <S.FooterItem>{t('earnings:chart.helpText')}</S.FooterItem>
    </S.ChartFooterRoot>
  );
};
