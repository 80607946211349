import { Tooltip, TooltipProps } from '@toggle/design-system';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useTouchDevice } from '~/hooks/MediaQueryHook';

import * as S from './TooltipContainer.styles';

export type TooltipContainerProps = Omit<
  TooltipProps,
  'closeLabel' | 'isTouchDevice' | 'children'
> & { children?: TooltipProps['children']; showUnderline?: boolean };

export const TooltipContainer = forwardRef<HTMLElement, TooltipContainerProps>(
  (props, ref) => {
    const isTouchDevice = useTouchDevice();
    const { t } = useTranslation('common');

    if (!props.children) {
      return null;
    }

    if (!props.label) {
      return props.children;
    }

    return (
      <Tooltip
        ref={ref}
        {...props}
        closeLabel={t('common:tapToClose')}
        isTouchDevice={isTouchDevice}
      >
        {props.showUnderline ? (
          <S.UnderlineText>{props.children}</S.UnderlineText>
        ) : (
          props.children
        )}
      </Tooltip>
    );
  }
);
