import { ScenariosCondition } from '@toggle/toggle';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';
import {
  CUSTOM,
  generateConditionLabel,
} from '~/utils/condition-utils/condition-utils';

import { MappedConditions } from '../../components/scenario-assistant-modal/use-scenario-assistant/enrichedConditions';

type FilterConfigOption = {
  name: string;
  key: string;
  value: {
    snake?: string;
    condition: string;
    value: number;
    duration?: number;
    duration_unit?: string;
  };
};

export const mapFilterToCondition = (
  filterOption: ActiveFilterOptions
): ScenariosCondition => {
  const configOption = filterOption.options[0] as FilterConfigOption;
  const isCustom = configOption.key === CUSTOM;
  const snakeTail = configOption.value.snake ?? '';
  const snakeHead = filterOption.options
    .filter(option => option.childName)
    .map(option => option.value.value)
    .join('');

  const display = joinFilterNames(filterOption);
  const snake = isCustom ? snakeTail : snakeHead + snakeTail;

  return {
    ...configOption.value,
    display,
    snake,
  };
};

export const mapFiltersToConditions = (filterOptions: ActiveFilterOptions[]) =>
  filterOptions.map(mapFilterToCondition);

export const joinFilterNames = (filterOption: ActiveFilterOptions): string => {
  const configOption = filterOption.options[0] as FilterConfigOption;

  const lastChunk = generateConditionLabel(configOption);
  const chunks = filterOption.options
    .filter(option => option.childName)
    .map(option => option.name)
    .join(' ');

  return `${chunks} ${lastChunk}`.trim();
};

export const joinFiltersNames = (filterOptions: ActiveFilterOptions[]) =>
  filterOptions.map(joinFilterNames);

export const checkIfFirstOptionIsNotChild = (
  filterOptions?: FilterOption[]
): boolean => !!filterOptions?.length && !filterOptions[0].childName;

export const mapAssistantConditionsToConditions = (
  assistantConditions: MappedConditions[]
): ScenariosCondition[] =>
  assistantConditions
    .filter(c => c.confirmed && c.isValid)
    // eslint-disable-next-line no-unused-vars
    .map(({ pendingRun, confirmed, isValid, ...rest }) => rest);
