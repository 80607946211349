import { Portfolio } from '../usePortfolio';

export enum SearchTabsOptions {
  Insights = 'insights',
  Ib = 'ib',
}

export enum TradingProvider {
  Ib = 'interactive-brokers',
}

export enum PriceProvider {
  Nasdaq = 'Nasdaq',
  Ib = 'Ib',
}

export const ENABLED_TRADING_PROVIDERS: string[] = [TradingProvider.Ib];

export const isTradingEnabledForPortfolio = (p: Portfolio) =>
  ENABLED_TRADING_PROVIDERS.includes(p.provider.external_id);

export const mapSearchTabToTradingProvider: Partial<
  Record<SearchTabsOptions, TradingProvider>
> = {
  [SearchTabsOptions.Ib]: TradingProvider.Ib,
};

export const mapTradingProviderToSearchTab: Record<string, SearchTabsOptions> =
  {
    [TradingProvider.Ib]: SearchTabsOptions.Ib,
  };

export const mapTradingProviderToPriceProvider: Record<string, PriceProvider> =
  {
    [TradingProvider.Ib]: PriceProvider.Ib,
  };
