import { AssetLogo, Toggle } from '@toggle/design-system';
import { Entity, formatTicker } from '@toggle/toggle';
import React from 'react';

import type { SeriesType } from '~/types/series.types';

import * as S from './ChartTypeSelectorItem.style';

export interface ChartTypeSelectorItemProps {
  entity: Entity;
  series: SeriesType;
  isChecked: boolean;
  onClick: () => void;
}

export const ChartTypeSelectorItem = ({
  entity,
  series,
  isChecked,
  onClick,
}: ChartTypeSelectorItemProps) => {
  return (
    <S.Wrapper>
      <S.AssetWrapper>
        <AssetLogo entity={entity} isDarkTheme size={20} />
        <S.AssetTicker>{formatTicker(entity)}</S.AssetTicker>
      </S.AssetWrapper>
      <Toggle
        data-testid={`${series}-${entity.default_snake}-toggle`}
        isChecked={isChecked}
        onClick={onClick}
      />
    </S.Wrapper>
  );
};
