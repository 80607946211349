import { useLocation } from 'react-router';

import { appPaths } from '~/routes/app-paths';
import { ChartResponse } from '~/stores/use-chat/assistant-response.type';
import { Message, MessageReply } from '~/stores/use-chat/use-chat.types';
import { useChat } from '~/stores/use-chat/useChat';
import { ChartState } from '~/views/turbo-chart/hooks/use-chart-state/useChartState';

import { useChartNavigate } from '../use-chart-navigate/useChartNavigate';
import { useNavigateAlfred } from '../use-navigate-alfred/useNavigateAlfred';

export const useChatInputNavigate = () => {
  const chartNavigate = useChartNavigate();
  const navigateToAlfred = useNavigateAlfred();
  const location = useLocation();
  const { update } = useChat(state => ({
    update: state.update,
  }));

  const isChartAssistant = (reply: MessageReply): reply is ChartResponse =>
    reply.source === 'chart-assistant';

  const decideNavigation = (message?: Message) => {
    if (!message || message.hasSeen || !message.reply[0]) {
      return;
    }

    const isAssetOverview = location.pathname === appPaths.assetOverview;
    const isChartPage = location.pathname === appPaths.chart;
    const isAlfredPage = appPaths.alfred === location.pathname;

    if (isChartPage) {
      if (message.status !== 'complete') {
        return;
      }

      const chartAssistantReply = message.reply.find(isChartAssistant);

      if (chartAssistantReply) {
        const data = chartAssistantReply.data;
        const domain: ChartState['domain'] =
          !!data.start || !!data.end
            ? [data.start ?? 0, data.end ?? new Date().toISOString()]
            : null;
        chartNavigate(
          data.assets.map(a => [a.tag, a.snake]),
          {
            resample: data.resample,
            seriesType: data.series_type,
            yAxisType: data.y_axis_type,
            ...(domain ? { domain } : { horizon: data.horizon }),
          },
          isChartPage
        );

        update({
          ...message,
          hasSeen: true,
        });
        return;
      }
    }

    if (!isAlfredPage && !isAssetOverview) {
      navigateToAlfred('intel');
    }

    update({
      ...message,
      hasSeen: true,
    });
  };

  return decideNavigation;
};
