import {
  add,
  endOfWeek,
  startOfDay,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { KEY_DEV_TYPES_EXCEPT_EARNINGS } from '~/hooks/use-company-events/useCompanyEvents';
import { useEarningsFilterStats } from '~/hooks/use-earnings-filter-stats/useEarningsFilterStats';
import { US_COUNTRY_CODE } from '~/hooks/use-economics-events/useEconomicsEvents';
import { useFinancialEventsCalendar } from '~/hooks/use-financial-events-calendar/useFinancialEventsCalendar';
import { queryKeys } from '~/routes/app-paths';

import * as S from './CalendarView.styles';
import { EventOverview } from './event-overview/EventOverview';
import { EventTabs } from './event-tabs/EventTabs';
import {
  formatEarningsCount,
  formatEconomicsCompanyCount,
  isDateBeyondThreeMonths,
  TabsType,
} from './utils';

export const CalendarView = () => {
  const { t } = useTranslation(['calendar']);
  const defaultCountryFilter = [
    {
      key: US_COUNTRY_CODE,
      name: t('calendar:defaultSelectedCountry'),
      value: { value: US_COUNTRY_CODE },
    },
  ];

  const today = startOfToday();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeDate, setActiveDate] = useState(today);
  const [earningsOnlyWatchlists, setEarningsOnlyWatchlists] = useState(false);
  const [companyOnlyWatchlists, setCompanyOnlyWatchlists] = useState(false);
  const [activeCountries, setActiveCountries] = useState(defaultCountryFilter);
  const [forceIsTableLoading, setForceIsTableLoading] = useState(true);

  const dateThreeMonthsLater = add(today, { months: 3 });
  const maxDate = startOfDay(dateThreeMonthsLater);

  useEffect(() => {
    const activeDateSearchParam = searchParams.get(
      queryKeys.activeCalendarDate
    );

    if (activeDateSearchParam) {
      const parsedDate = new Date(parseFloat(activeDateSearchParam));

      setActiveDate(parsedDate);
    }
  }, [searchParams]);

  const monday = startOfWeek(activeDate, { weekStartsOn: 1 });
  const sunday = endOfWeek(activeDate, { weekStartsOn: 1 });
  const isNextWeekDisabled = isDateBeyondThreeMonths(sunday);
  const endDate = isNextWeekDisabled ? maxDate : sunday;

  const activeCountriesValues = activeCountries.map(
    country => country.value.value
  );

  const { data: earningsCounts, isLoading: earningsCountsLoading } =
    useEarningsFilterStats({
      start_date: monday.toISOString(),
      end_date: endDate.toISOString(),
      watchlists: {
        all: earningsOnlyWatchlists,
      },
    });
  const {
    data: economicsAndCompanyCounts,
    isLoading: economicsCompanyCountsLoading,
  } = useFinancialEventsCalendar({
    from: monday.toISOString(),
    to: endDate.toISOString(),
    countries: activeCountriesValues.length ? activeCountriesValues : undefined,
    types: KEY_DEV_TYPES_EXCEPT_EARNINGS,
    watchlists: {
      all: companyOnlyWatchlists,
    },
  });
  const { economics: economicsCounts, companies: companiesCounts } =
    formatEconomicsCompanyCount(economicsAndCompanyCounts);

  const countData = {
    economics: {
      counts: economicsCounts,
      isLoading: economicsCompanyCountsLoading,
    },
    earnings: {
      counts: formatEarningsCount(earningsCounts),
      isLoading: earningsCountsLoading,
    },
    companies: {
      counts: companiesCounts,
      isLoading: economicsCompanyCountsLoading,
    },
  };

  const handleToggle = (checked: boolean, type: TabsType) => {
    if (type === TabsType.Earnings) {
      setEarningsOnlyWatchlists(checked);
    } else {
      setCompanyOnlyWatchlists(checked);
    }
  };

  const onDateChange = (date: Date) => {
    const activeDateSearchParam = searchParams.get(
      queryKeys.activeCalendarDate
    );

    if (activeDateSearchParam) {
      setSearchParams(params => {
        params.delete(queryKeys.activeCalendarDate);
        return params;
      });
    }

    setForceIsTableLoading(true);
    setActiveDate(date);
  };

  return (
    <S.Container data-testid="calendar-view">
      <S.EventsWrapper>
        <S.Title>{t('calendar:pageTitle')}</S.Title>
        <EventOverview
          activeDate={activeDate}
          startWeekDay={monday}
          endWeekDay={endDate}
          isNextWeekDisable={isNextWeekDisabled}
          data={countData}
          onDateChange={onDateChange}
        />
      </S.EventsWrapper>
      <S.TableContentWrapper>
        <EventTabs
          activeDate={activeDate}
          earningsOnlyWatchlists={earningsOnlyWatchlists}
          companyOnlyWatchlists={companyOnlyWatchlists}
          activeCountries={activeCountries}
          forceIsTableLoading={forceIsTableLoading}
          setForceIsTableLoading={setForceIsTableLoading}
          setActiveCountries={setActiveCountries}
          handleToggle={handleToggle}
          onDateChange={onDateChange}
        />
      </S.TableContentWrapper>
    </S.Container>
  );
};
