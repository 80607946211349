import { Property } from 'csstype';
import React from 'react';

import * as S from './TableHeaderIcons.styles';

export interface TableHeaderIconsProps {
  alignment?: Property.TextAlign;
  sortDirection?: 'asc' | 'desc' | false;
  isPinned?: boolean;
  isFiltered?: boolean;
  children: React.ReactNode;
}

export const TableHeaderIcons = ({
  alignment = 'end',
  sortDirection = false,
  isPinned = false,
  isFiltered = false,
  children,
}: TableHeaderIconsProps) => {
  const reversedAlignments = ['end', 'right'];
  const isReversed = reversedAlignments.includes(alignment);

  return (
    <S.TableHeaderIconsRoot $isReversed={isReversed}>
      {children}
      {!!sortDirection && (
        <S.SortIcon iconName="Filter" $isSortAsc={sortDirection === 'asc'} />
      )}
      {isPinned && <S.PinIcon iconName="PinSolid" />}
      {isFiltered && <S.FilterIcon iconName="Filter" />}
    </S.TableHeaderIconsRoot>
  );
};

TableHeaderIcons.Styled = S;
