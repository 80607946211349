import { DropdownGroup, DropdownMenu } from '@toggle/design-system';
import {
  Condition,
  CustomComponentOption,
  CustomFilter,
  ScenariosCondition,
} from '@toggle/toggle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  coreCPI,
  countryDeu,
  countryUs,
  getCustomInflation,
  headlineCPI,
  inflationSnake,
} from '../../utils/scenario-conditions-filters/custom-scenario-conditions-filters';
import { validateCondition } from '../scenario-assistant-modal/validate-condition/validateCondition';
import { ConditionBuilder } from './ConditionBuilder';
import * as S from './CustomScenarioModal.styles';

type SnakeOptions = {
  type: string;
  key: string;
  name: string;
};

export type CustomScenarioModalProps = {
  conditionFilter: CustomFilter;
  onClose: () => void;
  handleCustomCondition: (props: {
    snakeOptions: Record<string, Omit<SnakeOptions, 'type'>>;
    scenarioCondition: ScenariosCondition;
    filterKey: string;
  }) => void;
};

export const CustomScenarioModal = ({
  conditionFilter,
  handleCustomCondition,
  onClose,
}: CustomScenarioModalProps) => {
  const { t } = useTranslation(['screener', 'scenario', 'common']);
  const [condition, setCondition] = useState<Partial<Condition>>();
  const [error, setError] = useState(false);
  const [snakeOptions, setSnakeOptions] = useState(() =>
    conditionFilter.custom.reduce((a, c) => {
      return {
        ...a,
        [c.type]: { key: c.custom[0].key, name: c.custom[0].name },
      };
    }, {} as Record<string, Omit<SnakeOptions, 'type'>>)
  );

  const snake = Object.values(snakeOptions)
    .map(m => m.key)
    .join(conditionFilter.key === 'inflation' ? '.' : '_');

  const onApply = () => {
    if (!condition || (condition && !validateCondition(condition))) {
      setError(true);
      return;
    }

    const scenarioCondition = {
      ...condition,
      snake,
    };

    handleCustomCondition({
      scenarioCondition,
      snakeOptions,
      filterKey: conditionFilter.key,
    });
  };

  const handleSelection = ({ type, key, name }: SnakeOptions) => {
    setSnakeOptions(prev => ({
      ...prev,
      [type]: { key, name },
    }));
  };

  const handleDropdownOptions = (
    customOptions: CustomComponentOption[],
    type: string
  ) => {
    const filterFn = (o: CustomComponentOption) => {
      // When inflation filter;
      // Headline CPI (for all countries)
      // Core CPI (only available for US, UK and Japan)
      // Core PCE (only available for US)
      // Headline PCE (only available for US)
      if (
        conditionFilter.key === getCustomInflation(t).key &&
        type === inflationSnake.type &&
        o.key !== headlineCPI.key
      ) {
        if (o.key === coreCPI.key) {
          // exclude deu
          return snakeOptions.country.key !== countryDeu.key;
        }

        // exclude  uk and jpn and deu
        return snakeOptions.country.key === countryUs.key;
      }

      // return all
      return true;
    };

    return customOptions.filter(filterFn).map(c => ({
      label: c.name,
      key: c.key,
      onClick: () => {
        handleSelection({
          type,
          key: c.key,
          name: c.name,
        });
      },
    }));
  };

  const handleCondition = (c: Partial<Condition>) => {
    setError(false);
    setCondition(c);
  };

  return (
    <S.StyledModalRoot
      isOpen
      title={conditionFilter.name}
      onClose={onClose}
      primaryBtnProps={{
        label: t('screener:apply'),
        onClick: onApply,
      }}
      cancelBtnProps={{ label: t('common:cancel'), onClick: onClose }}
    >
      <S.ConditionBuilderOptions>
        {error && (
          <S.ErrorAlert
            data-testid="error-message"
            message={t('scenario:condition.error')}
          />
        )}
        <S.ConditionOptions>
          {conditionFilter.custom.map(item => (
            <DropdownMenu
              key={item.type}
              triggerNode={
                <S.Option
                  label={snakeOptions[item.type].name}
                  iconName="ChevronLightDown"
                  variant="empty"
                  iconPosition="right"
                />
              }
            >
              <DropdownGroup
                items={handleDropdownOptions(item.custom, item.type)}
              />
            </DropdownMenu>
          ))}
          <ConditionBuilder snake={snake} onConditionChange={handleCondition} />
        </S.ConditionOptions>
      </S.ConditionBuilderOptions>
    </S.StyledModalRoot>
  );
};
