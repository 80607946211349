import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseErrorView } from '../base-error-view/BaseErrorView';
import * as S from './ErrorMessageWrapper.styles';

type Variant = 'error' | 'empty';

interface ErrorMessageWrapperProps {
  variant?: Variant;
  displayIcon?: boolean;
  onClick?: () => void;
  title?: string;
  subTitle?: string;
  showBorder?: boolean;
}

export const ErrorMessageWrapper = ({
  onClick,
  displayIcon,
  variant = 'error',
  title,
  subTitle,
  showBorder = true,
  ...rest
}: ErrorMessageWrapperProps) => {
  const { t } = useTranslation(['widget', 'common']);
  const errorButton = {
    label: t('error.reloadButtonLabel'),
    onClick,
  };

  const messageComponent = {
    error: (
      <BaseErrorView
        title={title}
        subtitle={subTitle}
        buttonProps={errorButton}
        displayIcon={displayIcon ? 'error' : undefined}
      />
    ),
    empty: (
      <BaseErrorView
        displayIcon={displayIcon ? 'empty' : undefined}
        title={title ?? t('widget:empty.titleEmpty')}
        subtitle={subTitle ?? t('widget:empty.subtitleEmpty')}
      />
    ),
  };

  return (
    <S.StyledErrorMessageRoot {...rest} $showBorder={showBorder}>
      {messageComponent[variant]}
    </S.StyledErrorMessageRoot>
  );
};
