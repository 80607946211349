import React, { ReactNode } from 'react';

import * as S from './CalendarEventDrawerHeader.styles';
import { EventDate } from './event-date/EventDate';

export type CalendarEventDrawerProps = {
  title: string;
  subtitle: string;
  dateString: string;
  logoNode?: ReactNode;
  reported?: boolean;
};

export const CalendarEventDrawerHeader = ({
  title,
  subtitle,
  logoNode,
  dateString,
  reported,
}: CalendarEventDrawerProps) => {
  return (
    <S.Container>
      <S.LogoAndTitleWrapper>
        <S.LogoWrapper>{logoNode}</S.LogoWrapper>
        <S.ColumnWrapper>
          <S.TitleWrapper data-testid="event-name">{title}</S.TitleWrapper>
          <S.SubtitleWrapper data-testid="asset-ticker">
            {subtitle}
          </S.SubtitleWrapper>
        </S.ColumnWrapper>
      </S.LogoAndTitleWrapper>
      <EventDate dateString={dateString} reported={reported} />
    </S.Container>
  );
};
