import { PriceHover, PriceHoverData } from '@toggle/chart';
import { ChartTooltip } from '@toggle/design-system';
import { formatTicker } from '@toggle/toggle';
import React, { CSSProperties, forwardRef } from 'react';

import { TooltipSortConfig } from '~/modules/multi-line-chart/MultiLineChart';
import { formatUtcDate } from '~/services/insights/utils/insight-utils';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';

import * as S from './SimpleChartTooltip.styles';

export interface SimpleChartTooltipProps {
  priceHover?: PriceHover;
  styles?: CSSProperties;
  sortConfig?: TooltipSortConfig;
}

export const SimpleChartTooltip = forwardRef<
  HTMLDivElement,
  SimpleChartTooltipProps
>(({ priceHover, styles, sortConfig }, ref) => {
  const { colors } = useChartTheme();

  if (!priceHover) {
    return null;
  }

  const parseFieldValue = (
    item: PriceHoverData,
    sortConfig: TooltipSortConfig
  ) => {
    const value = item[sortConfig.field];

    if (typeof value === 'string') {
      return parseFloat(value.replace(',', ''));
    }

    return 0;
  };

  const pane = priceHover.panes[0];

  if (sortConfig) {
    pane.data.sort((a, b) => {
      const valueA = parseFieldValue(a, sortConfig);
      const valueB = parseFieldValue(b, sortConfig);
      return sortConfig.desc ? valueB - valueA : valueA - valueB;
    });
  }

  const isoDate = formatUtcDate(priceHover.date.toISOString(), 'MMM d yyyy');

  return (
    <ChartTooltip
      ref={ref}
      title={isoDate}
      style={{
        zIndex: 'var(--z-tooltip)',
        ...styles,
      }}
      data-testid="simple-chart-tooltip"
    >
      {pane.data.map(data => (
        <ChartTooltip.Styled.Item key={data.chartAssetData.entity.tag}>
          <ChartTooltip.Styled.Label>
            <S.Dot
              $color={colors.getThemeColor(data.chartAssetData.lineColorToken)}
            />
            {formatTicker(data.chartAssetData.entity)}
          </ChartTooltip.Styled.Label>
          <ChartTooltip.Styled.Label data-testid="tooltip-price-label">
            {data.priceFormatted}
          </ChartTooltip.Styled.Label>
        </ChartTooltip.Styled.Item>
      ))}
    </ChartTooltip>
  );
});
