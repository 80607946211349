import {
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { DatePicker, DatePickerValue, i18n } from '@toggle/design-system';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { Filters } from '~/components/filters/Filters';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';

import { useNewsSearchStore } from '../../stores/use-news-search-store/useNewsSearchStore';
import {
  formatDateRange,
  TEMP_MIN_FETCHING_NEWS_DATE,
} from '../../utils/utils';
import { ClearFiltersButton } from '../clear-filters-button/ClearFiltersButton';
import { MoreFilters } from './components/more-filters/MoreFilters';
import * as S from './SearchFilters.styles';

const DATE_PICKER_Y_AXIS_OFFSET_PX = 5;

export const SearchFilters = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datePickerValues, setDatePickerValues] = useState<DatePickerValue>([
    null,
    null,
  ]);

  const {
    accordionFilters,
    activeFiltersCount,
    activeAccordionFiltersCount,
    clearActiveFilters,
    filterGroups,
    toggleFilterOption,
  } = useNewsSearchStore(state => ({
    accordionFilters: state.accordionFilters,
    activeFiltersCount: state.getActiveFiltersCount(),
    activeAccordionFiltersCount: state.getActiveAccordionFiltersCount(),
    clearActiveFilters: state.clearActiveFilters,
    filterGroups: state.filterGroups,
    toggleFilterOption: state.toggleFilterOption,
  }));

  const { addFilterOption } = useFilterActions({
    store: useNewsSearchStore,
    group: 'NEWS_SEARCH_FILTERS',
  });

  const { refs, floatingStyles, context } = useFloating({
    open: showDatePicker,
    onOpenChange: setShowDatePicker,
    placement: 'bottom-start',
    middleware: [
      offset({
        mainAxis: DATE_PICKER_Y_AXIS_OFFSET_PX,
      }),
    ],
  });

  const openDatePicker = () => {
    const triggerElement = document.querySelector(
      'button[data-filter="date-picker"]'
    );

    refs.setPositionReference(triggerElement);
    setShowDatePicker(true);
  };

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context),
  ]);

  const setFilterOption = (dateRange: DatePickerValue) => {
    if (Array.isArray(dateRange)) {
      const [from, to] = dateRange;

      if (from && to) {
        const name = formatDateRange([from, to]);

        addFilterOption({
          filterKey: 'date-picker',
          option: {
            name,
            key: 'date-range',
            value: {
              from,
              to,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    const isDateRangeFilterActive =
      filterGroups.NEWS_SEARCH_FILTERS.activeFilterOptions.find(option => {
        return option.id === 'date-picker';
      });

    if (!isDateRangeFilterActive) {
      setDatePickerValues([null, null]);
    }
  }, [filterGroups]);

  return (
    <S.SearchFiltersRoot
      data-testid="search-filters"
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <Filters
        filterState={filterGroups.NEWS_SEARCH_FILTERS}
        store={useNewsSearchStore}
        group={'NEWS_SEARCH_FILTERS'}
        showCustomFilterModal={false}
        showAddMoreFilters={false}
        onCustomRangeClick={filter => {
          if (filter.key === 'date-picker') {
            openDatePicker();
          }
        }}
      />

      <MoreFilters
        filters={accordionFilters}
        moreFiltersSelectedCount={activeAccordionFiltersCount}
        toggleFilterOption={toggleFilterOption}
      />

      {(!!activeFiltersCount || !!activeAccordionFiltersCount) && (
        <ClearFiltersButton onClick={clearActiveFilters} />
      )}

      {context.open && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <I18nextProvider i18n={i18n}>
            <DatePicker
              value={datePickerValues}
              maxDate={new Date()}
              minDate={TEMP_MIN_FETCHING_NEWS_DATE}
              selectRange
              onDateChange={dateRange => {
                setDatePickerValues(dateRange);
                setShowDatePicker(false);
                setFilterOption(dateRange);
              }}
              data-testid="calendar"
            />
          </I18nextProvider>
        </div>
      )}
    </S.SearchFiltersRoot>
  );
};
