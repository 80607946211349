import { DropdownProps, Icon, ModalProps } from '@toggle/design-system';
import React, { ReactNode } from 'react';

import * as S from '~/components/search-modal/SearchModal.styles';

import { useSearchModalKeyboard } from './hooks/useSearchModalKeyboard';

export interface SearchModalBaseProps<T> extends ModalProps {
  searchValue: string;
  handleSearch: (value: string) => void;
  selectItem: DropdownProps<T>['selectItem'];
  dropdownItems: DropdownProps<T>['dropdownItems'];
  isLoading: boolean;
  noSearchResultsLabel: ReactNode;
  renderDropdownItem: DropdownProps<T>['renderDropdownItem'];
  value?: string;
  placeholder?: string;
  focusedItemIdx?: number;
  beforeResultsNode?: DropdownProps<T>['beforeResultsNode'];
  afterInListNode?: DropdownProps<T>['afterInListNode'];
  shouldCloseOnSelect?: DropdownProps<T>['shouldCloseOnSelect'];
  loadingNode?: ReactNode;
  shouldResetScrollOnItemsChange?: boolean;
}

export const SearchModalBase = <T,>({
  dropdownItems,
  searchValue,
  value = searchValue,
  isLoading,
  placeholder,
  beforeResultsNode,
  noSearchResultsLabel,
  shouldCloseOnSelect,
  afterInListNode,
  focusedItemIdx = -1,
  loadingNode,
  shouldResetScrollOnItemsChange = true,
  selectItem,
  handleSearch,
  renderDropdownItem,
  ...rest
}: SearchModalBaseProps<T>) => {
  const showNoSearchResults =
    !!value.length && !dropdownItems.length && !isLoading;

  const clearButton = !!searchValue.length && (
    <S.StyledButton
      data-testid="clear-button"
      tabIndex={-1}
      onClick={() => handleSearch('')}
      iconName="CloseCircle"
      size="small"
      variant="empty"
    />
  );

  const dropdownApiRef = useSearchModalKeyboard();

  if (isLoading && loadingNode) {
    return (
      <S.StyledModal $inChartPage={false} {...rest}>
        {loadingNode}
      </S.StyledModal>
    );
  }

  return (
    <S.StyledModal $inChartPage={false} {...rest}>
      <S.StyledDropdown<T>
        enableFloating={false}
        shouldCloseOnOutsideClick={false}
        shouldCloseOnSelect={shouldCloseOnSelect}
        shouldResetScrollOnItemsChange={shouldResetScrollOnItemsChange}
        shouldDisableItemMouseEvents
        selectItem={selectItem}
        dropdownItems={dropdownItems}
        autocompleteInputValue={value}
        focusedItemIdx={focusedItemIdx}
        renderDropdownItem={renderDropdownItem}
        beforeResultsNode={beforeResultsNode}
        afterResultsNode={
          showNoSearchResults && (
            <S.HelpText data-testid="no-search-results">
              {noSearchResultsLabel}
            </S.HelpText>
          )
        }
        afterInListNode={afterInListNode}
        dropdownApiRef={dropdownApiRef}
      >
        <S.StyledAutocompleteInput
          data-testid="search-input"
          value={searchValue}
          onChange={e => handleSearch(e.target.value)}
          placeholder={placeholder}
          leftIcon={<Icon iconName="Search" size={20} />}
          rightIcon={clearButton}
          autoFocus
          $autocompleteInputVariant={'secondary'}
        />
      </S.StyledDropdown>
    </S.StyledModal>
  );
};
