import { AccordionItem } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NewsFilter } from '~/services/news/news.types';
import { countFilterKeyChecked } from '~/views/news/utils/news-filters-utils/news-filters-utils';

import * as S from './SideAccordionFilters.styles';

export type SideAccordionFiltersProps = {
  filters: NewsFilter[];
  toggleFilterOption: (filterKey: string, optionKey?: string) => void;
};

export const SideAccordionFilters = ({
  filters,
  toggleFilterOption,
}: SideAccordionFiltersProps) => {
  const { t } = useTranslation(['news']);

  const accordionItemFactory = (filter: NewsFilter) => {
    if (!filter.options.length) {
      return null;
    }

    const { some, all, count } = countFilterKeyChecked({
      filters,
      filterKey: filter.key,
    });

    const genRightAlignedChild = () => {
      if (!count) {
        return null;
      }

      if (count === 1) {
        return (
          <S.SingleSelectedItem data-testid="right-label">
            {filter.options.filter(item => item.isChecked)[0].name}
          </S.SingleSelectedItem>
        );
      }

      return (
        <S.StyledBadge
          data-testid="right-label"
          variant="numerical"
          label={count}
          size="small"
        />
      );
    };

    return {
      title: filter.name,
      className: count ? 'option-selected' : undefined,
      content: (
        <S.AccordionContentWrapper>
          <S.StyledCheckbox
            label={t('news:allFilter', { filter: filter.name })}
            name={t('news:allFilter', { filter: filter.name })}
            checked={some}
            partial={some && !all}
            onChange={() => toggleFilterOption(filter.key)}
            data-testid={`toggle-all-${filter.name}`}
          />

          {filter.options.map(item => (
            <S.StyledCheckbox
              data-testid="filter-option-checkbox"
              key={item.key}
              label={item.name}
              name={item.name}
              checked={item.isChecked}
              onChange={() => toggleFilterOption(filter.key, item.key)}
            />
          ))}
        </S.AccordionContentWrapper>
      ),
      rightAlignedChild: genRightAlignedChild(),
    };
  };

  const items = filters
    .map(accordionItemFactory)
    .filter(Boolean) as AccordionItem[];

  return (
    <S.SideAccordionFiltersRoot>
      <S.StyledAccordion data-testid="accordion-filters" items={items} />
    </S.SideAccordionFiltersRoot>
  );
};
