import {
  HighLowTripleLineChart,
  HighLowTripleLineChartProps,
} from '@toggle/design-system';
import { periods, Prediction } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PriceMovementLegend } from '../price-movement-legend/PriceMovementLegend';
import * as S from './PriceMovementChart.styles';
import { getHorizonToIndexMap } from './utils/price-movement-chart-utils';

export interface PriceMovementChartProps
  extends Pick<
    HighLowTripleLineChartProps,
    | 'bestHorizon'
    | 'yAxisLabel'
    | 'isBordered'
    | 'maxHeight'
    | 'isFixedIncome'
    | 'useDynamicDaysLabel'
  > {
  prediction: Prediction[];
  horizonToIndexOffset?: number;
  defaultHorizonToIndex?: Map<string, number>;
  defaultHorizons?: string[];
  legendLabel?: string;
  isMedian?: boolean;
  snakeTs?: { index: number; value: number }[];
  isShowCumulativeReturn?: boolean;
}

export const PriceMovementChart = ({
  prediction,
  bestHorizon,
  horizonToIndexOffset = 0,
  defaultHorizonToIndex,
  defaultHorizons,
  legendLabel,
  isMedian = true,
  snakeTs = [],
  isShowCumulativeReturn = true,
  ...rest
}: PriceMovementChartProps) => {
  const { t } = useTranslation(['scenario', 'screener']);
  const horizonToIndex =
    defaultHorizonToIndex ?? getHorizonToIndexMap(horizonToIndexOffset);

  const tooltipLabels = {
    /* i18next-extract-mark-context-next-line  ["p80"] */
    high: t('scenario:drawer.summaryTable.row', {
      context: 'p80',
    }),
    /* i18next-extract-mark-context-next-line  ["median", "mean"] */
    median: t('scenario:drawer.summaryTable.row', {
      context: isMedian ? 'median' : 'mean',
    }),
    /* i18next-extract-mark-context-next-line  ["p20"] */
    low: t('scenario:drawer.summaryTable.row', {
      context: 'p20',
    }),
  };
  const daysLabel = t('screener:screenerEvents.days', {
    count: prediction.length,
  });

  return (
    <S.PriceMovementChartRoot data-testid="price-movement-chart">
      <PriceMovementLegend
        bestHorizon={bestHorizon}
        legendLabel={legendLabel}
        isShowCumulativeReturn={isShowCumulativeReturn}
      />
      <HighLowTripleLineChart
        ts={prediction}
        snakeTs={snakeTs}
        bestHorizon={bestHorizon}
        horizonToIndex={horizonToIndex}
        highlightedHorizons={defaultHorizons ?? periods}
        tooltipLabels={tooltipLabels}
        daysLabel={daysLabel}
        {...rest}
      />
    </S.PriceMovementChartRoot>
  );
};
