import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ResetAllButton.styles';

export interface ResetAllButtonProps {
  resetAllFilterOptions: () => void;
}

export const ResetAllButton = ({
  resetAllFilterOptions,
}: ResetAllButtonProps) => {
  const { t } = useTranslation(['widget']);

  return (
    <S.ResetButton
      label={t('widget:earnings.resetAll')}
      iconName="Reset"
      iconPosition="left"
      onClick={resetAllFilterOptions}
      variant="empty"
      data-testid="reset-all-button"
    />
  );
};
