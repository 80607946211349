import styled, { css } from 'styled-components';

import { timingFunction } from '~/utils/transitions';

import { NavItem } from './components/nav-item/NavItem';
import { SidebarLogotype } from './components/sidebar-logotype/SidebarLogotype';
import { COLLAPSED_WIDTH_PX, EXPANDED_WIDTH_PX } from './SideNavigation';

export const LogoButton = styled.button`
  display: flex;
  align-items: end;
  justify-content: start;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  height: 60px;
  padding-left: 20px;
  padding-bottom: 13px;
`;

export const AsideExpandable = styled.aside<{ $isExpanded: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 1px solid var(--border-soft);
  height: 100vh;
  width: ${({ $isExpanded }) =>
    $isExpanded ? EXPANDED_WIDTH_PX : COLLAPSED_WIDTH_PX};
  transition: width 0.3s ${timingFunction.base};
  background-color: var(--background-soft);
  z-index: 1;
`;

export const Header = styled.header`
  position: relative;
  border-bottom: 1px solid var(--border-soft);
  height: 60px;
`;

export const StyledLogotype = styled(SidebarLogotype)<{ isExpanded: boolean }>`
  transition: transform 300ms ${timingFunction.base};

  img {
    transition: opacity 300ms ${timingFunction.base};
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      transform: translateX(-43px);
    `};
`;

export const ToggleButtonWrapper = styled.div`
  box-shadow: var(--elevation-strong);
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  border-radius: 50%;
`;

export const ToggleButton = styled.button<{ $isExpanded: boolean }>`
  border: 1px solid var(--border-soft);
  background-color: var(--background-default);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    border-color: var(--surface-primary);
    background-color: var(--surface-primary);
    svg {
      fill: var(--text-on-primary);
    }
  }

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      transform: rotate(180deg);
    `};
`;

export const Categories = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 16px 0px 16px 0;
  gap: 16px;
  overflow-x: clip;

  ${NavItem.Styled.MainLabel},
  ${NavItem.Styled.BadgeWrapper} {
    transition: opacity 0.3s ${timingFunction.base};
  }

  ${NavItem.Styled.SubItem} {
    transition-property: height, margin, padding, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ${timingFunction.base};
  }

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          ${NavItem.Styled.MainLabel} {
            opacity: 1;
          }
        `
      : css`
          ${NavItem.Styled.ItemWrapper} {
            &::before {
              transform: translateX(-100%);
            }
          }

          ${NavItem.Styled.ItemBackground} {
            transform: translateX(6px);
            width: calc(100% - 24px);
          }

          ${NavItem.Styled.SubItemsWrapper} {
            height: 0;
            margin: 0;
          }

          ${NavItem.Styled.Item} {
            width: calc(100% - 24px);
            margin-left: 14px;
            padding-left: 10px;
          }
          ${NavItem.Styled.SubItem} {
            height: 0;
            opacity: 0;
            margin: 0;
            padding: 0;
            ${NavItem.Styled.ItemLeft} {
              height: 0;
              opacity: 0;
              margin: 0;
              padding: 0;
            }
          }
          ${NavItem.Styled.SubItemSeparator} {
            margin: 0;
            height: 0;
          }
          ${NavItem.Styled.MainLabel},
          ${NavItem.Styled.BadgeWrapper} {
            opacity: 0;
          }
        `}
`;
