import {
  PreliminaryPrice,
  preliminaryPriceSchema,
  PriceHistory,
  Resolution,
} from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export const priceEndpoints = {
  prices: `price-history/v1/prices`,
  preliminaryPrice: (subscribableTicker: string) =>
    `price-history/v1/market-close?ticker=${subscribableTicker}`,
};

export const getPreliminaryPrice = (
  subscribableTicker: string,
  signal?: AbortSignal
) => {
  return apiFetch<PreliminaryPrice>(
    priceEndpoints.preliminaryPrice(subscribableTicker),
    {
      schema: preliminaryPriceSchema,
      options: { signal },
    }
  );
};

export interface GetPricesProps {
  ticker?: string;
  entityTag: string;
  resolution: Resolution;
  dateFrom?: string;
  dateTo?: string;
}

export const getPrices = async ({
  ticker,
  entityTag,
  resolution,
  dateFrom,
  dateTo,
}: GetPricesProps) => {
  const params = [
    ['ticker', ticker],
    ['from', dateFrom],
    ['to', dateTo],
    ['entity_tag', entityTag],
    ['resolution', resolution],
  ].filter(i => i[1]) as [string, string][];
  const priceUrl = `${priceEndpoints.prices}?${new URLSearchParams(
    params
  ).toString()}`;

  return apiFetch<PriceHistory>(priceUrl);
};
