import { ChartTypeSelector } from '@toggle/chart';
import styled from 'styled-components';

import { ChartPrice } from '../chart-price/ChartPrice';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  min-height: 40px;
  gap: 4px;
  align-items: flex-end;
`;

export const ChartTypeSelectorStyled = styled(ChartTypeSelector)`
  height: 40px;
`;

export const ChartPriceStyled = styled(ChartPrice)`
  flex: 1;
  padding: 0px;
`;
