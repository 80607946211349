import React, { ReactNode } from 'react';

import * as S from './NoDataFound.styles';

export interface NoDataFoundProps {
  bannerPath: string;
  title: string;
  description: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  children?: ReactNode;
}

export const NoDataFound = ({
  bannerPath,
  title,
  description,
  buttonLabel,
  buttonAction,
  children,
  ...rest
}: NoDataFoundProps) => {
  return (
    <S.EmptyPortfolioWrapper {...rest}>
      <S.StyledImg src={bannerPath} />
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      {buttonAction && (
        <S.AddAssetsButton onClick={buttonAction} label={buttonLabel} />
      )}
      {children}
    </S.EmptyPortfolioWrapper>
  );
};
