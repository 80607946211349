import { StoreApi } from 'zustand';

import {
  DASHBOARD_VIEW_MODE,
  STORAGE_ITEMS,
  useRemoteStorage,
} from '~/hooks/use-remote-storage';
import { create } from '~/stores/create-store/createStore';

export const SIDEBAR_CLOSED_WIDTH = '34px';
export const SIDEBAR_EXPANDED_WIDTH = '100px';

export enum ViewMode {
  List = 'list',
  Grid = 'grid',
}

export type DashboardViewMode = ViewMode;

export interface AppStore {
  isSidebarExpanded: boolean;
  dashboardViewMode: DashboardViewMode;
  initialize: () => Promise<void>;
  setDashboardViewMode: (
    storeItems: (items: STORAGE_ITEMS) => Promise<void>,
    value: DashboardViewMode
  ) => void;
  setSidebarExpanded: (value: boolean) => void;
}

const initialize = async (set: StoreApi<AppStore>['setState']) => {
  const dashboardViewMode = useRemoteStorage.getState().items?.[
    DASHBOARD_VIEW_MODE
  ] as DashboardViewMode | undefined;
  set({
    dashboardViewMode: dashboardViewMode ?? ViewMode.Grid,
  });
};

export const useApp = create<AppStore>(set => ({
  isSidebarExpanded: true,
  /**
   * @deprecated TO REMOVE WHEN CALENDAR WIDGET HAS BEEN RELEASED
   */
  dashboardViewMode: ViewMode.Grid,
  initialize: async () => initialize(set),
  /**
   * @deprecated
   */
  setDashboardViewMode: (
    storeItems: (items: STORAGE_ITEMS) => Promise<void>,
    value: DashboardViewMode
  ) => {
    set({ dashboardViewMode: value });
    storeItems({ [DASHBOARD_VIEW_MODE]: value });
  },
  setSidebarExpanded: (value: boolean) => set({ isSidebarExpanded: value }),
}));
