import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  align-items: center;
  ${typographyV2.bodyBase}
  color: var(--text-disabled);

  &::before {
    flex: 1;
    content: '';
    padding: 0.5px;
    margin-right: 8px;
    background-color: var(--border-soft);
  }

  &::after {
    flex: 1;
    content: '';
    padding: 0.5px;
    margin-left: 8px;
    background-color: var(--border-soft);
  }
`;
