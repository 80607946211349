import React from 'react';

import { Icon } from '../icon/Icon';
import * as S from './ScrollingIndicator.styles';

interface ScrollingIndicatorProps {
  scrollToBottom: () => void;
}

export const ScrollingIndicator = ({
  scrollToBottom,
}: ScrollingIndicatorProps) => {
  return (
    <S.ShadedArea>
      <S.ScrollingIndicator
        data-testid="scrolling-indicator"
        role="status"
        onClick={scrollToBottom}
      >
        <Icon iconName="ArrowDown" size={20} />
      </S.ScrollingIndicator>
    </S.ShadedArea>
  );
};
