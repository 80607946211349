import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { FlagIcon } from '~/components/flag-icon/FlagIcon';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const LogoAndTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  ${typographyV2.bodyMd};
  color: var(--text-default);
  overflow-wrap: break-word;
`;

export const SubtitleWrapper = styled.div`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoFallback = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: var(--dark-purple-12);
  color: var(--dark-purple-9);
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  ${typographyV2.titleXL};
`;

export const StyledFlagIcon = styled(FlagIcon)`
  width: 48px;
  height: 48px;
`;

export const LogoWrapper = styled.div`
  flex-shrink: 0;
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)`
  border-radius: 50%;
`;
