import { dateToUTC } from '@toggle/helpers';
import { utcFormat } from 'd3-time-format';
import {
  add,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  format,
  startOfDay,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import {
  nonIntradayResamples,
  RangeHorizon,
  ResampleIntervals,
} from '~/types/resample.types';

import { Domain, i18n, Timeseries, TimeSeriesItem } from '../';

export const isIntradayResample = (resample: ResampleIntervals) =>
  !nonIntradayResamples.includes(resample);

export const formatXAxisPoint = (date: Date, resample: ResampleIntervals) => {
  const isIntraday = isIntradayResample(resample);

  if (isIntraday) {
    return startOfDay(date).getTime() === date.getTime()
      ? localeAxisMark(date)
      : `${localeAxisMark(date)} ${intradayAxisMark(date)}`;
  } else {
    return localeAxisMark(toZonedTime(date, 'UTC'));
  }
};

const intradayAxisMark = (date: Date) => format(date, 'HH:mm');

const localeAxisMark = (date: Date) =>
  `${date.toLocaleString(i18n.resolvedLanguage, {
    weekday: 'short',
  })} ${date.getDate()} ${date.toLocaleString(i18n.resolvedLanguage, {
    month: 'short',
  })}'${date.getFullYear().toString().slice(-2)}`;

const getTimeMinusOffset = (date: Date): number =>
  date.getTime() - date.getTimezoneOffset() * 60 * 1000;

// returns date in local time when time will match startOfDay(00:00) in UTC, EXCEPT 1D horizon
// for 1D it will return date in local time that matches UTC representation for (from - 24 hours)
// example1, not 1D horizon: for America/New_York will return 20:00 while UTC is 00:00, from is any
// example2, 1D horizon: for America/New_York will return 16:00 while UTC is 20:00, from is 20:00
export function getStartOfRangeHorizon(horizon: RangeHorizon, from: Date) {
  const mapping: Partial<Record<RangeHorizon, () => Date>> = {
    [RangeHorizon.OneDay]: () => add(from, { hours: -24 }),
    [RangeHorizon.OneWeek]: () => add(from, { days: -7 }),
    [RangeHorizon.TwoWeeks]: () => add(from, { days: -14 }),
    [RangeHorizon.OneMonth]: () => add(from, { months: -1, days: 1 }),
    [RangeHorizon.TwoMonths]: () => add(from, { months: -2, days: 1 }),
    [RangeHorizon.ThreeMonths]: () => add(from, { months: -3, days: 1 }),
    [RangeHorizon.SixMonths]: () => add(from, { months: -6, days: 1 }),
    [RangeHorizon.OneYear]: () => add(from, { years: -1, days: 1 }),
    [RangeHorizon.ThreeYears]: () => add(from, { years: -3, days: 1 }),
    [RangeHorizon.FiveYears]: () => add(from, { years: -5, days: 1 }),
    [RangeHorizon.TenYears]: () => add(from, { years: -10, days: 1 }),
  };

  const startDate = mapping[horizon]?.();
  const shouldTransformToStart = horizon !== RangeHorizon.OneDay;
  const startOfLocalDay =
    startDate && (shouldTransformToStart ? startOfDay(startDate) : startDate);
  return startOfLocalDay && getTimeMinusOffset(startOfLocalDay);
}

export const showZoomInToViewText = (data: Timeseries, domain: Domain) => {
  const roundedDomain = [Math.ceil(domain[0]), Math.floor(domain[1])];
  // Need to fix when domain is changed
  const latestDomain =
    roundedDomain[1] in data ? roundedDomain[1] : roundedDomain[1] - 1;
  return (
    differenceInCalendarMonths(
      new Date(data[latestDomain].time),
      new Date(data[roundedDomain[0]].time)
    ) > 6
  );
};

export const axisDateToTimezone = (date: Date, isIntraday: boolean) =>
  isIntraday ? date : dateToUTC(date);

export const formatHoursAndMins = (date: Date, isIntraday?: boolean) =>
  isIntraday ? format(date, 'HH:mm') : utcFormat('%I:%M')(date);

export const formatMonth = (date: Date) =>
  date.toLocaleString(i18n.resolvedLanguage, { month: 'short' });

export const formatYear = (date: Date) => date.getFullYear().toString();

export type DateTransitionTier = 'year' | 'month' | 'day' | 'hour';

interface DateTransitionConfig {
  diff: (date1: Date, date2: Date) => number;
  format: (date: Date) => string;
  dateFnName: 'getDate' | 'getMonth' | 'getFullYear' | 'getHours';
}

export const dateTransitionMapping: Record<
  DateTransitionTier,
  DateTransitionConfig
> = {
  year: {
    diff: differenceInCalendarYears,
    dateFnName: 'getFullYear',
    format: date => date.getFullYear().toString(),
  },
  month: {
    diff: differenceInCalendarMonths,
    dateFnName: 'getMonth',
    format: formatMonth,
  },
  day: {
    diff: differenceInCalendarDays,
    dateFnName: 'getDate',
    format: date => date.getDate().toString(),
  },
  hour: {
    diff: (date1: Date) => (date1.getMinutes() === 0 ? 1 : 0),
    dateFnName: 'getHours',
    format: date => format(date, 'HH:mm'),
  },
};

interface DateTransitionProps {
  currentDate: Date;
  prevDate: Date;
  timeseries: TimeSeriesItem[];
  index: number;
  tier: DateTransitionTier;
}

export const isDateTransitioning = ({
  currentDate,
  prevDate,
  timeseries,
  index,
  tier,
}: DateTransitionProps) => {
  const diffFn = dateTransitionMapping[tier].diff;

  if (diffFn(currentDate, prevDate) !== 1) {
    return false;
  }

  const dateMethod = dateTransitionMapping[tier].dateFnName;
  const prev = new Date((timeseries[index - 1] || timeseries[0]).time);
  const current = new Date(timeseries[index].time);
  return prev[dateMethod]() !== current[dateMethod]();
};
