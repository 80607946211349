import classNames from 'classnames';
import React, {
  HTMLAttributes,
  JSX,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Icon } from '~/components/icon/Icon';
import { SvgIconNames } from '~/design-tokens/iconography/SvgIcons';

import * as S from './Accordion.styles';

export interface AccordionItem {
  id?: string;
  titleIcon?: SvgIconNames;
  title: ReactNode;
  content?: ReactNode;
  rightAlignedChild?: ReactNode;
  renderIcon?: (expanded: boolean) => JSX.Element;
  hideIcon?: boolean;
  className?: string;
  props?: HTMLAttributes<HTMLDivElement>;
  value?: ReactNode;
  showHasValue?: boolean;
  expandedByDefault?: boolean;
}

export interface AccordionProps<T = AccordionItem>
  extends HTMLAttributes<HTMLDivElement> {
  withBackground?: boolean;
  variant?: 'primary' | 'secondary';
  items: T[];
  onItemClick?: (props: {
    item: T;
    event: MouseEvent<HTMLDivElement>;
    isExpanded: boolean;
  }) => void;
  collapseAccordionsOnClick?: boolean;
  className?: string;
  itemClassName?: string;
  defaultExpandedIds?: string[];
  duration?: number;
  disabledCollapse?: boolean;
}

export const Accordion = <T extends AccordionItem>({
  withBackground = true,
  items,
  onItemClick,
  collapseAccordionsOnClick = true,
  className,
  itemClassName,
  defaultExpandedIds,
  variant = 'primary',
  duration = 400,
  disabledCollapse = false,
  ...rest
}: AccordionProps<T>) => {
  const [expandedItemsIdx, setExpandedItemsIdx] = useState<Set<number>>(
    () => new Set()
  );
  const itemsContentHeights = useRef(new Map());

  const handleClick = ({
    event,
    accordion,
    idx,
  }: {
    event: MouseEvent<HTMLDivElement>;
    accordion: T;
    idx: number;
  }) => {
    if (!accordion.content) {
      return;
    }

    const newSet: Set<number> = collapseAccordionsOnClick
      ? new Set()
      : new Set(expandedItemsIdx);
    if (disabledCollapse) {
      newSet.add(idx);
      setExpandedItemsIdx(newSet);
    } else {
      expandedItemsIdx.has(idx) ? newSet.delete(idx) : newSet.add(idx);
      setExpandedItemsIdx(newSet);
    }

    onItemClick?.({
      event,
      item: accordion,
      isExpanded: !expandedItemsIdx.has(idx),
    });
  };

  useEffect(() => {
    const exp = items.reduce<number[]>((res, item, idx) => {
      if (item.expandedByDefault) {
        res.push(idx);
      }

      return res;
    }, []);

    setExpandedItemsIdx(new Set(exp));
  }, [items.length]);

  const itemsWithIds = items.map((item, idx) => ({
    ...item,
    id: item.id ?? (performance.now() + '').replace('.', '') + idx,
  }));

  return (
    <S.Accordion
      $withBackground={withBackground}
      $variant={variant}
      className={className}
      data-testid="accordion"
      {...rest}
    >
      {itemsWithIds.map((accordionItem, idx) => (
        <S.AccordionItem
          key={idx}
          data-testid="accordion-item"
          className={classNames(
            expandedItemsIdx.has(idx) && 'accordion-expanded',
            itemClassName,
            accordionItem.className
          )}
          $hideIcon={!!accordionItem.hideIcon}
          {...accordionItem.props}
        >
          <S.TitleContainer
            onClick={event =>
              handleClick({ event, accordion: accordionItem, idx })
            }
            aria-controls={accordionItem.id}
            role="button"
            data-testid="accordion-item-button"
            $isExpandable={!!accordionItem.content}
          >
            <S.TitleContent>
              {(accordionItem.titleIcon || variant === 'secondary') && (
                <Icon
                  iconName={accordionItem.titleIcon ?? 'StarEmpty'}
                  fillColor="var(--icon-primary)"
                  size={18}
                />
              )}
              {accordionItem.title}
            </S.TitleContent>
            <S.RightAlignedItems>
              {accordionItem.rightAlignedChild}
              {accordionItem?.showHasValue && !!accordionItem.value && (
                <>{accordionItem.value}</>
              )}
              {accordionItem?.renderIcon?.(expandedItemsIdx.has(idx)) ?? (
                <S.IconStyled
                  iconName="ChevronLightRight"
                  fillColor="var(--icon-primary)"
                  size={16}
                  $withDefaultIcon={!accordionItem?.renderIcon}
                  $expanded={expandedItemsIdx.has(idx)}
                />
              )}
            </S.RightAlignedItems>
          </S.TitleContainer>
          {!!accordionItem.content && (
            <S.TransitionWrapper
              data-testid="transition-wrapper"
              $duration={`${duration}ms`}
              ref={node => {
                if (!node) {
                  return;
                }

                // this is to handle case when content height might change in next rerender
                // for example content was 300px, then content of the accordion has changed
                // and we need to update it
                const height = itemsContentHeights.current.get(idx);
                node.style.height = expandedItemsIdx.has(idx)
                  ? `${height}px`
                  : '0px';
              }}
            >
              <S.Content
                id={accordionItem.id}
                ref={node => {
                  itemsContentHeights.current.set(idx, node?.clientHeight ?? 0);
                }}
              >
                {accordionItem.content}
              </S.Content>
            </S.TransitionWrapper>
          )}
        </S.AccordionItem>
      ))}
    </S.Accordion>
  );
};

Accordion.Styled = S;
