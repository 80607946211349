import { SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

import { ChartAreaWrapper } from '~/components/chart-area-wrapper/ChartAreaWrapper';

export const Body = styled.section`
  position: relative;
  width: 100%;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  min-height: 100%;
`;

export const SkeletonChartLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '350px',
        borderRadius: '8px',
      },
    },
  ],
})``;

export const StyledChartAreaWrapper = styled(ChartAreaWrapper)`
  height: 240px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
`;
