import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { getKnowledgeGraphNavigateParams } from '~/modules/overview-knowledge-graph/utils/knowledge-graph-utils';
import { KnowledgeGraphSectionId } from '~/widgets/knowledge-graph-widget/KnowledgeGraphWidget';

export const useKnowledgeGraphDropdownItems = () => {
  const { t } = useTranslation();
  const { navigateToPage } = useNavigateToAssetOverview();

  const [searchParams] = useSearchParams();

  const viewEvidenceItem = ({
    sectionId,
    entityTag,
    separated,
  }: {
    sectionId: KnowledgeGraphSectionId;
    entityTag: string;
    separated?: boolean;
  }) => {
    return {
      label: t('widget:assetOverview.viewEvidence'),
      onClick: () =>
        navigateToPage(
          getKnowledgeGraphNavigateParams({
            sectionId,
            searchParams,
            entityTag,
          })
        ),
      separated,
    };
  };

  const goToAssetOverviewItem = () => {
    return {
      label: t('widget:assetOverview.assetOverview'),
      onClick: (selectedEntityTag: string) => {
        navigateToPage({ entityTag: selectedEntityTag });
      },
    };
  };

  return {
    viewEvidenceItem,
    goToAssetOverviewItem,
  };
};
