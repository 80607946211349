import { ScreenerFilter } from '@toggle/toggle';
import { TFunction } from 'i18next';

import {
  getCustomIndexMoves,
  getCustomInflation,
  getCustomInterest,
  getCustomOil,
} from './custom-scenario-conditions-filters';

// eslint-disable-next-line max-lines-per-function
export const getConditionFilters = (t: TFunction): ScreenerFilter[] => [
  {
    key: 'central_bank_actions',
    name: t('scenario:filters.conditions', {
      context: 'central_bank_actions',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      multiselect: false,
      name: 'Central Bank',
      options: [
        {
          name: 'hikes',
          key: 'hikes',
          value: {
            snake: '_hikes',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'cuts',
          key: 'cuts',
          value: {
            snake: '_cuts',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
      ],
      children: [
        {
          name: 'Central Bank',
          multiselect: false,
          options: [
            {
              name: 'FED',
              key: 'fed',
              value: {
                value: 'us.event_fed',
              },
            },
            {
              name: 'BoE',
              key: 'boe',
              value: {
                value: 'uk.event_boe',
              },
            },
            {
              name: 'BOJ',
              key: 'boj',
              value: {
                value: 'jpn.event_boj',
              },
            },
            {
              name: 'ECB',
              key: 'ecb',
              value: {
                value: 'eu.event_ecb',
              },
            },
          ],
          default: 'fed',
        },
      ],
    },
  },
  {
    key: 'oil-prices',
    name: t('scenario:filters.conditions', {
      context: 'oil-prices',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      multiselect: false,
      name: 'Oil Price',
      options: [
        {
          name: 'price rises 10% in a week',
          key: 'rises_10_w',
          value: {
            condition: 'rises_by',
            value: 0.1,
            duration: 1,
            duration_unit: 'w',
          },
        },
        {
          name: 'price rises 10% in a month',
          key: 'rises_10_m',
          value: {
            condition: 'rises_by',
            value: 0.1,
            duration: 1,
            duration_unit: 'm',
          },
        },
        {
          name: 'price falls 10% in a week',
          key: 'falls_10_w',
          value: {
            condition: 'falls_by',
            value: 0.1,
            duration: 1,
            duration_unit: 'w',
          },
        },
        {
          name: 'price falls 10% in a month',
          key: 'falls_10_m',
          value: {
            condition: 'falls_by',
            value: 0.1,
            duration: 1,
            duration_unit: 'm',
          },
        },
      ],
      children: [
        {
          name: 'Instrument Selector',
          multiselect: false,
          options: [
            {
              name: 'CO:ICE',
              key: 'co_ice',
              description: 'Brent Crude',
              value: {
                value: 'co_ice_fut_energy.price',
              },
            },
            {
              name: 'CL:NYMEX',
              key: 'cl_nymex',
              description: 'Light Sweet Crude Oil',
              value: {
                value: 'cl_nymex_fut_energy.price',
              },
            },
            {
              name: 'USO:ARCA',
              key: 'use_arca',
              description: 'US Oil Fund',
              value: {
                value: 'uso_arca.price',
              },
            },
          ],
          default: 'co_ice',
        },
      ],
      custom: getCustomOil(t),
    },
  },
  {
    key: 'interest-rate',
    name: t('scenario:filters.conditions', {
      context: 'interest-rate',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      name: 'Interest Rate',
      multiselect: false,
      options: [
        {
          name: 'yield rises 10bps in a week',
          key: 'rises_10bps_w',
          value: {
            snake: '.yield',
            condition: 'rises_by_level',
            value: 0.1,
            duration: 1,
            duration_unit: 'w',
          },
        },
        {
          name: 'yield falls 10bps in a week',
          key: 'falls_10_m',
          value: {
            snake: '.yield',
            condition: 'falls_by_level',
            value: 0.1,
            duration: 1,
            duration_unit: 'w',
          },
        },
        {
          name: 'yield crosses above 4%',
          key: 'crosses_above_4',
          value: {
            snake: '.yield',
            condition: 'crosses_above',
            value: 4,
          },
        },
        {
          name: 'yield crosses below 4%',
          key: 'crosses_below_4',
          value: {
            snake: '.yield',
            condition: 'crosses_below',
            value: 4,
          },
        },
      ],
      children: [
        {
          name: 'Country',
          multiselect: false,
          options: [
            {
              name: 'US',
              key: 'us',
              value: {
                value: 'sov_us_usd_',
              },
            },
            {
              name: 'UK',
              key: 'uk',
              value: {
                value: 'sov_uk_gbp_',
              },
            },
            {
              name: 'Japan',
              key: 'jpn',
              value: {
                value: 'sov_jpn_jpy_',
              },
            },
            {
              name: 'Germany',
              key: 'deu',
              value: {
                value: 'sov_deu_eur_',
              },
            },
          ],
          default: 'us',
        },
        {
          name: 'Tenor',
          multiselect: false,
          options: [
            {
              name: '2Y',
              key: '2y',
              value: {
                value: '2y',
              },
            },
            {
              name: '3Y',
              key: '3y',
              value: {
                value: '3y',
              },
            },
            {
              name: '5Y',
              key: '5y',
              value: {
                value: '5y',
              },
            },
            {
              name: '7Y',
              key: '7y',
              value: {
                value: '7y',
              },
            },
            {
              name: '10Y',
              key: '10y',
              value: {
                value: '10y',
              },
            },
            {
              name: '30Y',
              key: '30y',
              value: {
                value: '30y',
              },
            },
          ],
          default: '2y',
        },
      ],
      custom: getCustomInterest(t),
    },
  },
  {
    key: 'rate-curve',
    name: t('scenario:filters.conditions', {
      context: 'rate-curve',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      name: 'Rate Curve',
      multiselect: false,
      options: [
        {
          name: 'rate curve steepens',
          key: 'steepens',
          value: {
            snake: '.yield',
            condition: 'rises_by_level',
            value: 0.25,
            duration: 1,
            duration_unit: 'm',
          },
        },
        {
          name: 'rate curve flattens',
          key: 'flattens',
          value: {
            snake: '.yield',
            condition: 'falls_by_level',
            value: 0.25,
            duration: 1,
            duration_unit: 'm',
          },
        },
        {
          name: 'rate curve inverts',
          key: 'inverts',
          value: {
            snake: '.yield',
            condition: 'crosses_below',
            value: 0,
          },
        },
        {
          name: 'rate curve uninverts',
          key: 'un_inverts',
          value: {
            snake: '.yield',
            condition: 'crosses_above',
            value: 0,
          },
        },
        {
          name: 'rate curve is Inverted',
          key: 'is_inverted',
          value: {
            snake: '.yield',
            condition: 'below',
            value: 0,
          },
        },
        {
          name: 'rate curve is Upward Sloping',
          key: 'is_upward_sloping',
          value: {
            snake: '.yield',
            condition: 'above',
            value: 0,
          },
        },
      ],
      children: [
        {
          name: 'Country',
          multiselect: false,
          options: [
            {
              name: 'US',
              key: 'us',
              value: {
                value: 'sov_us_usd_',
              },
            },
            {
              name: 'UK',
              key: 'uk',
              value: {
                value: 'sov_uk_gbp_',
              },
            },
            {
              name: 'Japan',
              key: 'jpn',
              value: {
                value: 'sov_jpn_jpy_',
              },
            },
            {
              name: 'Germany',
              key: 'deu',
              value: {
                value: 'sov_deu_eur_',
              },
            },
          ],
          default: 'us',
        },
        {
          name: 'Tenor',
          multiselect: false,
          options: [
            {
              name: '2s5s',
              key: '2s5s',
              value: {
                value: '2y_5y_curve',
              },
            },
            {
              name: '2s10s',
              key: '2s10s',
              value: {
                value: '2y_10y_curve',
              },
            },
            {
              name: '5s10s',
              key: '5s10s',
              value: {
                value: '5y_10y_curve',
              },
            },
            {
              name: '10s30s',
              key: '10s30s',
              value: {
                value: '10y_30y_curve',
              },
            },
          ],
          default: '2s5s',
        },
      ],
    },
  },
  {
    key: 'inflation',
    name: t('scenario:filters.conditions', {
      context: 'inflation',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      name: 'Inflation',
      multiselect: false,
      options: [
        {
          name: 'is above 2%',
          key: 'is_above_2',
          value: {
            condition: 'above',
            value: 2,
          },
        },
        {
          name: 'is above 5%',
          key: 'is_above_5',
          value: {
            condition: 'above',
            value: 5,
          },
        },
        {
          name: 'is below 2%',
          key: 'is_below_2',
          value: {
            condition: 'below',
            value: 2,
          },
        },
        {
          name: 'is below 5%',
          key: 'is_below_5',
          value: {
            condition: 'below',
            value: 5,
          },
        },
      ],
      children: [
        {
          name: 'Country',
          multiselect: false,
          options: [
            {
              name: 'US',
              key: 'us',
              value: {
                value: 'us',
              },
            },
            {
              name: 'UK',
              key: 'uk',
              value: {
                value: 'uk',
              },
            },
            {
              name: 'Japan',
              key: 'jpn',
              value: {
                value: 'jpn',
              },
            },
            {
              name: 'Germany',
              key: 'deu',
              value: {
                value: 'deu',
              },
            },
          ],
          default: 'us',
        },
        {
          name: 'Time Series',
          multiselect: false,
          options: [
            {
              name: 'headline CPI',
              key: 'headline_cpi',
              value: {
                value: '.cpi_level.change_pct(horiz=1y)',
              },
            },
            {
              name: 'core CPI',
              key: 'core_cpi',
              include: ['us', 'jpn', 'uk'],
              value: {
                value: '.cpi_core_level.change_pct(horiz=1y)',
              },
            },
            {
              name: 'headline PCE',
              key: 'headline_pce',
              include: ['us'],
              value: {
                value: '.pce_level.change_pct(horiz=1y)',
              },
            },
            {
              name: 'core PCE',
              key: 'core_pce',
              include: ['us'],
              value: {
                value: '.pce_core_level.change_pct(horiz=1y)',
              },
            },
          ],
          default: 'headline_cpi',
        },
      ],
      custom: getCustomInflation(t),
    },
  },
  {
    key: 'political-events',
    name: t('scenario:filters.conditions', {
      context: 'political-events',
    }),
    visible: true,
    searchable: false,
    dynamic: true,
    config: {
      name: 'Political Events',
      multiselect: false,
      options: [
        {
          name: 'presidential election',
          key: 'presidential_election',
          value: {
            snake: '.presidential_election.event',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'government shutdown starts',
          key: 'government_shutdown_starts',
          value: {
            snake: '.government_shutdown_start',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'government shutdown ends',
          key: 'government_shutdown_ends',
          value: {
            snake: '.government_shutdown_end',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'presidential election · Republicans win',
          key: 'presidential_election_republicans_win',
          value: {
            snake: '.event_presidential_republican_win',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'presidential election · Democrats win',
          key: 'presidential_election_democrats_win',
          value: {
            snake: '.event_presidential_democratic_win',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'senate election · Republicans win',
          key: 'senate_election_republicans_win',
          value: {
            snake: '.event_senate_republican_win',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'senate election · Democrats win',
          key: 'senate_election_democrats_win',
          value: {
            snake: '.event_senate_democratic_win',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'house election · Republicans win',
          key: 'house_election_republicans_win',
          value: {
            snake: '.event_house_republican_win',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
        {
          name: 'house election · Democrats win',
          key: 'house_election_democrats_win',
          value: {
            snake: '.event_house_democratic_win',
            condition: 'crosses_above',
            value: 0.5,
          },
        },
      ],
      children: [
        {
          name: 'Country',
          multiselect: false,
          options: [
            {
              name: 'USA',
              key: 'usa',
              value: {
                value: 'us',
              },
            },
          ],
          default: 'usa',
        },
      ],
    },
    category: 'Scenario',
    locked: true,
  },
  {
    key: 'risk-off',
    name: t('scenario:filters.conditions', {
      context: 'risk-off',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      name: 'Risk-Off',
      multiselect: false,
      options: [
        {
          name: 'S&P 500 drops 2% in a day',
          key: 'spx_500_drops_2_d',
          value: {
            snake: 'spx.price.ds',
            condition: 'falls_by',
            value: 0.02,
            duration: 1,
            duration_unit: 'd',
          },
        },
        {
          name: 'Dollar index rises 2% in a day',
          key: 'dx_rises_2_d',
          value: {
            snake: 'dx_ice_fut_currency.price',
            condition: 'rises_by',
            value: 0.02,
            duration: 1,
            duration_unit: 'd',
          },
        },
        {
          name: 'US 10Y yields drop 20bps in a day',
          key: 'us_10y_drop_20_d',
          value: {
            snake: 'sov_us_usd_10y.yield.ds',
            condition: 'falls_by_level',
            value: 0.2,
            duration: 1,
            duration_unit: 'd',
          },
        },
      ],
    },
  },
  {
    key: 'index',
    name: t('scenario:filters.conditions', {
      context: 'index',
    }),
    visible: true,
    searchable: false,
    category: 'Scenario',
    locked: true,
    dynamic: true,
    config: {
      name: 'Index',
      multiselect: false,
      options: [
        {
          name: 'rises 2% in a day',
          key: 'rises_2_d',
          value: {
            condition: 'rises_by',
            value: 0.02,
            duration: 1,
            duration_unit: 'd',
          },
        },
        {
          name: 'rises 5% in a week',
          key: 'rises_5_w',
          value: {
            condition: 'rises_by',
            value: 0.05,
            duration: 1,
            duration_unit: 'w',
          },
        },
        {
          name: 'falls 2% in a day',
          key: 'falls_2_d',
          value: {
            condition: 'falls_by',
            value: 0.02,
            duration: 1,
            duration_unit: 'd',
          },
        },
        {
          name: 'falls 5% in a day',
          key: 'falls_5_d',
          value: {
            condition: 'falls_by',
            value: 0.05,
            duration: 1,
            duration_unit: 'w',
          },
        },
      ],
      children: [
        {
          name: 'Instrument selector',
          multiselect: false,
          options: [
            {
              name: 'S&P 500',
              key: 'spx',
              value: {
                value: 'spx.price',
              },
            },
            {
              name: 'FTSE 100',
              key: 'ftse_100',
              value: {
                value: 'ukx.price',
              },
            },
            {
              name: 'Nikkei 225',
              key: 'nikkei_225',
              value: {
                value: 'nky.price',
              },
            },
            {
              name: 'DAX',
              key: 'dax',
              value: {
                value: 'dax.price',
              },
            },
          ],
          default: 'spx',
        },
      ],
      custom: getCustomIndexMoves(t),
    },
  },
];
