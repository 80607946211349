import { SingleNews } from '@toggle/toggle';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NewsThumbnail } from '~/components/news-thumbnail/NewsThumbnail';

import { DatePillDropdown } from '../../modules/date-pill-dropdown/DatePillDropdown';
import { TopNewsReducerAction } from '../../News';
import * as S from './NewsGroup.styles';

export interface NewsGroupProps {
  list?: SingleNews[];
  onChangeDate: (action: TopNewsReducerAction) => void;
  onNewsClick: (singleNews: SingleNews) => void;
  pillTime: string;
  isShowDJBadge: boolean;
}

export const NewsGroup = ({
  list = [],
  onChangeDate,
  onNewsClick,
  pillTime,
  isShowDJBadge,
}: NewsGroupProps) => {
  const { t } = useTranslation(['news']);

  const [isSticky, setIsSticky] = useState(false);
  const datePillRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const refDom = datePillRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    if (refDom) {
      observer.observe(refDom);
    }

    return () => {
      refDom && observer.unobserve(refDom);
    };
  }, [isSticky, datePillRef]);

  return (
    <S.NewsGroupRoot id={`news-group-${pillTime}`} data-testid="news-group">
      <S.PillWrapper ref={datePillRef} data-testid="top-pill-wrapper">
        <DatePillDropdown
          originalPillTime={pillTime}
          onChangeDate={onChangeDate}
        />
        {isShowDJBadge && (
          <S.BadgeWrapper data-testid="dow-jones-badge">
            <img
              src="/app/static/images/logotypes/dow-jones-logo.svg"
              alt={t('news:dowJonesDisclaimer.dowJonesLogo')}
            />
            <S.PoweredBy>{t('news:poweredBy')}</S.PoweredBy>
            <S.StyledImg
              src="/app/static/images/logotypes/dow-jones-name.svg"
              alt={t('news:dowJonesDisclaimer.downJonesName')}
            />
          </S.BadgeWrapper>
        )}
        <S.Divider $isPillSticky={isSticky} data-testid="group-news-divider" />
      </S.PillWrapper>

      {list.map(news => {
        return (
          <NewsThumbnail
            key={news.id}
            isGrid={false}
            newsData={news}
            onClick={onNewsClick}
          />
        );
      })}
    </S.NewsGroupRoot>
  );
};
