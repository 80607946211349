import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const DocumentEmptyRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 8px;
`;

export const Description = styled.h3<{ $hasData: boolean }>`
  ${typographyV2.titleBase}
  color: ${({ $hasData }) =>
    $hasData ? 'var(--text-default)' : 'var(--text-disabled)'};
`;

export const StyledImage = styled.img<{ $hasData: boolean }>`
  filter: ${({ $hasData }) => ($hasData ? 'none' : 'grayscale(100%)')};
`;
