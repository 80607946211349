import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './MoreUnreadNewsPill.styles';

export interface MoreUnreadNewsPillProps {
  onClick: () => void;
}

export const MoreUnreadNewsPill = ({ onClick }: MoreUnreadNewsPillProps) => {
  const { t } = useTranslation(['news']);

  return (
    <S.MoreUnreadNewsPillRoot
      onClick={onClick}
      variant="tertiary"
      iconName="ArrowUp"
      label={t('news:moreUnreadNews')}
      data-testid="more-unread-news-pill"
    />
  );
};
