import { NumericalCellValueStyle } from '@toggle/helpers/src/utils/table/table';
import { Property } from 'csstype';
import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { FixedTableCell } from '../Table.styles';
import { PinConfig } from './TableCell';

interface TableCellStyleProps {
  $alignment?: Property.TextAlign;
  $condensed?: boolean;
  $numericalStyle?: NumericalCellValueStyle;
  $pinConfig?: PinConfig;
  $isNumber?: boolean;
  $isUpperCase: boolean;
  $fixed?: boolean;
}

export const TableCell = styled.td<TableCellStyleProps>`
  font-family: ${({ $isNumber }) =>
    $isNumber ? 'var(--font-roboto-mono)' : 'var(--font-nort)'};
  ${typographyV2.bodySm}
  border-bottom: 1px solid var(--border-soft);
  background-color: var(--background-default);
  text-transform: ${({ $isUpperCase }) => $isUpperCase && 'uppercase'};
  white-space: nowrap;
  text-align: ${({ $alignment }) => $alignment};
  color: ${({ $numericalStyle }) => {
    const numericalColorStyle =
      $numericalStyle === NumericalCellValueStyle.Positive
        ? 'var(--text-success)'
        : 'var(--text-critical)';
    return $numericalStyle === NumericalCellValueStyle.Neutral
      ? 'var(--text-default)'
      : numericalColorStyle;
  }};
  ${({ $fixed }) => $fixed && FixedTableCell};
  ${({ $condensed }) =>
    $condensed
      ? css`
          ${typographyV2.bodyXs}
          padding: 4px 12px;
        `
      : css`
          padding: 12px;
        `};

  ${props =>
    props.$pinConfig &&
    css`
      position: sticky;
      z-index: var(--z-index-table-cell);
      left: ${props.$pinConfig.left}px;
      right: ${props.$pinConfig.right}px;
    `}

  ${props =>
    props.$pinConfig?.isLastPinned &&
    css`
      border-right: 1px solid var(--border-default);
    `}

  ${props =>
    props.$pinConfig?.showShadow &&
    css`
      box-shadow: 15px 0 30px 15px rgba(var(--background-default-rgb), 0.6);
      clip-path: inset(0px -60px 0px 0px);
    `}
`;
