import { create } from '../create-store/createStore';

export interface WatchlistManagerStore {
  shouldResetList?: boolean;
  isAddMode?: boolean;
  onConfirmCallback?: () => void;
  setIsAddMode: (isAddMode?: boolean) => void;
  setOnConfirmCallback: (onConfirmCallback?: () => void) => void;
  setShouldResetList: (shouldResetList?: boolean) => void;
}

export const useWatchlistManagerWidget = create<WatchlistManagerStore>(set => ({
  setIsAddMode: isAddMode => set({ isAddMode }),
  setOnConfirmCallback: onConfirmCallback => set({ onConfirmCallback }),
  setShouldResetList: shouldResetList => set({ shouldResetList }),
}));
