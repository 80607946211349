import * as d3 from 'd3';
import { SpxTsData } from '../BandChart.types';

export const getDirectionalArrays = (ts: SpxTsData[]) => {
  let lastSeen = '';
  const thresholds = ts.reduce((acc, tsPoint) => {
    const context = tsPoint['direction'] || '';
    const switchContext = lastSeen !== context;
    !acc.length || switchContext
      ? acc.push([tsPoint])
      : acc[acc.length - 1].push(tsPoint);
    lastSeen = context;
    return acc;
  }, [] as SpxTsData[][]);
  return thresholds;
};

export const drawThresholdFill = (
  ctx: CanvasRenderingContext2D,
  tsData: SpxTsData[],
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  width: number,
  height: number,
  threshold: number,
  fillColors: {
    positive: string;
    negative: string;
  },
  heightOffset: number
) => {
  const yThreshold = yScale(threshold) + heightOffset;
  ctx.clearRect(0, 0, width, height);

  const areaGenerator = d3
    .area<SpxTsData>()
    .x(d => xScale(d.index))
    .y0(yThreshold)
    .y1(d => yScale(d.value) + heightOffset)
    .curve(d3.curveLinear);
  const areaPath = areaGenerator(tsData);
  if (!areaPath) return;

  ctx.save();
  ctx.globalCompositeOperation = 'destination-over';

  const path = new Path2D(areaPath);
  ctx.clip(path);

  const bullishColor = fillColors.positive;
  ctx.fillStyle = bullishColor;
  ctx.fillRect(0, 0, width, yThreshold);

  const bearishColor = fillColors.negative;
  ctx.fillStyle = bearishColor;
  ctx.fillRect(0, yThreshold, width, height - yThreshold);

  ctx.restore();
};
