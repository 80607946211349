import React from 'react';

import { FilterOption } from '~/components/update-filter-options/updateFilterOptions';
import { generateConditionLabel } from '~/utils/condition-utils/condition-utils';

import * as S from './Filter.styles';

export type FilterLabelProps = {
  options: FilterOption[];
  multiselect: boolean;
};

export const FilterLabel = ({ options, multiselect }: FilterLabelProps) => {
  const childNames = options.filter(o => o.childName).map(o => o.name);
  const notChild = (option: FilterOption) => !option.childName;
  const optionCount = options.filter(notChild).length;
  const selectedOption = options.find(notChild);
  const childLabel =
    selectedOption && childNames.length ? `(${childNames.join(' · ')})` : '';

  const displayName = generateConditionLabel(selectedOption) ?? childNames;

  return (
    <S.SelectedValue data-testid="active-filter-label">
      {childLabel && <span>{childLabel}</span>}
      {optionCount > 1 && multiselect ? (
        <S.CountBadge data-testid="multiselect-count">
          {optionCount}
        </S.CountBadge>
      ) : (
        <span>{displayName}</span>
      )}
    </S.SelectedValue>
  );
};
