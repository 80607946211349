import { PillDropdown, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const PillContainer = styled.div`
  flex: 1;
`;

export const StyledPillDropdown = styled(PillDropdown)`
  ${typographyV2.bodySm}
  align-items: center;
  text-transform: capitalize;
`;

export const StyledHeader = styled.span`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const AssetHeader = styled.div`
  display: flex;
  width: calc(100% - 40px);
  flex-direction: column;
  align-items: start;
  justify-content: center;
  #company-label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #company-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    ${typographyV2.bodySm}
    color : var(--text-soft)
  }
`;
