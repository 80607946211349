import { Badge, SideNavigation } from '@toggle/design-system';
import styled from 'styled-components';

export const SideNavigationStyled = styled(SideNavigation)`
  z-index: var(--z-index-sidebar);
`;

export const AlfredBadge = styled(Badge)`
  margin-left: 32px;
`;

export const ReasonerBadge = styled(Badge)`
  margin-left: 8px;
`;
