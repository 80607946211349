import {
  customScrollMinimal,
  TableCell,
  TableHeader,
  typographyV2,
} from '@toggle/design-system';
import { PriceStatus } from '@toggle/helpers';
import styled from 'styled-components';

import { FlagIcon } from '~/components/flag-icon/FlagIcon';
import { priceChangeColors } from '~/utils/common/utils';

export const TableWrapper = styled.div`
  ${customScrollMinimal};
  overflow: auto;
  width: 100%;

  table {
    th:first-child {
      width: 7%;
    }
`;

export const StyledTableHeader = styled(TableHeader)`
  position: sticky;
  top: 0;
  z-index: var(--z-index-header-cell);

  &:hover {
    background-color: var(--surface-hover);
    cursor: pointer;
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: normal;
`;

export const TimeWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
`;

export const TimeIconWrapper = styled.div`
  display: flex;
`;

export const TickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Ticker = styled.span`
  ${typographyV2.bodySm};
  font-family: var(--font-nort);
  text-transform: uppercase;
`;

export const EntityName = styled.span`
  ${typographyV2.bodySm};
  font-family: var(--font-nort);
`;

export const SurpriseWrapper = styled.div<{ $status: PriceStatus }>`
  color: ${({ $status }) => priceChangeColors[$status]};
`;

export const Headline = styled.span`
  ${typographyV2.bodySm};
  font-family: var(--font-nort);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const EventNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NortFontText = styled.span`
  ${typographyV2.bodySm};
  font-family: var(--font-nort);
`;

export const StyledFlagIcon = styled(FlagIcon)`
  display: block;
  width: 20px;
  height: 20px;
`;
