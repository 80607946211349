import { deviceConstraints, typography } from '@toggle/design-system';
import styled from 'styled-components';

export const MaintenanceRoot = styled.section`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  padding: 60px;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  grid-template-areas:
    'logo'
    'tagline'
    'description';
`;

export const Logo = styled.aside`
  grid-area: logo;
  width: 100%;
  max-width: 287px;
  margin-bottom: 48px;
`;

export const Tagline = styled.h1`
  grid-area: tagline;
  ${typography.rhino}
  margin-bottom: 27px;

  @media ${deviceConstraints.smartphoneOrTablet} {
    font-size: 46px;
    margin-bottom: 27px;
  }
  @media ${deviceConstraints.desktop} {
    font-size: 56px;
    margin-bottom: 60px;
  }
`;

export const TaglineAlt = styled.span`
  color: var(--text-primary);
`;

export const StyledAnchor = styled.a`
  color: var(--text-link);
  text-decoration: none;

  &:hover {
    color: var(--action-primary-hover);
    cursor: pointer;
  }
`;

export const Description = styled.section`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: 'description image';

  @media ${deviceConstraints.desktop} {
    grid-template-areas: 'description image';
  }
`;

export const TextContent = styled.p`
  grid-area: description;
  max-width: 420px;
  ${typography.dingo}
  color: var(--text-default);
`;

export const Image = styled.aside`
  grid-area: image;
  align-self: center;
  margin: 0 0 27px 0;
  justify-self: center;

  @media ${deviceConstraints.desktop} {
    margin: 0 0 0 27px;
    justify-self: start;
  }
`;
