import React, { FC } from 'react';

import { ColorScheme } from '~/design-tokens/themes/themes';

import * as S from './Loading.styles';

interface LoadingProps {
  className?: string;
  colorScheme?: ColorScheme;
}

export const Loading: FC<LoadingProps> = ({
  className,
  colorScheme = ColorScheme.Dark,
}) => (
  <S.Loader data-testid="loading-view" className={className}>
    <S.InnerBox>
      <S.LogoBox>
        <img src={`/app/static/images/brand/logo-${colorScheme}.svg`} alt="" />
      </S.LogoBox>
      <S.ProgressBar>
        <S.ProgressBarMeter />
      </S.ProgressBar>
    </S.InnerBox>
  </S.Loader>
);
