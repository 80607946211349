import { useQueries, useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';

import {
  fetchSnakeByName,
  fetchSnakeMeta,
} from '~/services/overview-widget/overview-widget-service';

export const QUERY_KEY_SNAKE_META = 'QUERY_KEY_SNAKE_META';

export const useSnakeMeta = (snake = '') => {
  return useQuery({
    queryKey: [QUERY_KEY_SNAKE_META, snake],
    queryFn: ({ signal }) => fetchSnakeMeta(snake, signal),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: !!snake,
  });
};

export interface SnakeByName {
  snakeName: string;
  nDays?: number;
}
export const QUERY_KEY_SNAKES_BY_NAMES = 'QUERY_KEY_SNAKES_BY_NAMES';

export const useSnakesByNames = (data: SnakeByName[], enabled = true) => {
  const queries = data.map(({ snakeName, nDays }) => ({
    queryKey: [QUERY_KEY_SNAKES_BY_NAMES, { snakeName, nDays }],
    queryFn: () => fetchSnakeByName(snakeName, nDays),
    enabled,
  }));

  return useQueries({
    queries,
    combine: results => {
      return {
        data: results
          .map(result => {
            const res = result.data?.data;

            return res
              ? {
                  data: res.result.data,
                  snake: res.meta.snake,
                }
              : undefined;
          })
          .filter(r => r),
        isLoading: results.some(
          result => result.isLoading || result.isRefetching
        ),
      };
    },
  });
};
