import { Button, Icon } from '@toggle/design-system';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatInputProps } from '~/components/chat';
import { useNavigateAlfred } from '~/hooks/use-navigate-alfred/useNavigateAlfred';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

import * as S from './EarningsChatInput.styles';

interface EarningsChatInputProps {
  renderChatInput?: (props?: ChatInputProps) => ReactElement;
  documentIds?: string[];
}

export const EarningsChatInput = ({
  renderChatInput,
  documentIds,
}: EarningsChatInputProps) => {
  const { t } = useTranslation(['chat', 'asset-overview']);
  const navigateToAlfred = useNavigateAlfred();
  const isBasicUser = useSubscription(state => state.isBasicUser());

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const renderChatInputProps: ChatInputProps = {
    chatContext: { target: 'document-assistant', documents: documentIds },
    config: {
      placeholder: t('chat:placeholder', { context: 'intel' }),
      persistInputTextOnSubmit: true,
      clearDocumentContextOnSubmit: true,
      disableContextHeader: true,
      allowedModes: [],
    },
    submitCallback: () => {
      navigateToAlfred('intel');
    },
  };

  if (isBasicUser) {
    return null;
  }

  return (
    <div>
      <S.ChatInputWrapper onClick={() => setIsSearchOpen(true)}>
        <S.StyledInput
          leftIcon={
            <S.AlfredIconWrapper>
              <Icon iconName="AlfredNav" size={24} />
            </S.AlfredIconWrapper>
          }
          rightIcon={
            <Button label={t('asset-overview:documentSearch.askQuestion')} />
          }
          placeholder={t('asset-overview:documentSearch.buttonText')}
        />
      </S.ChatInputWrapper>
      <S.StyledModal
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        description={!!renderChatInput && renderChatInput(renderChatInputProps)}
      />
    </div>
  );
};
