import { RangeHorizon } from '@toggle/chart';
import { DetailedInsight } from '@toggle/toggle';
import React from 'react';

import { ChartBody } from './ChartBody';

interface PastEpisodesChartProps {
  entityTag: string;
  snakes: string[];
  episodes: DetailedInsight['episodes'];
  horizon: RangeHorizon;
}

export const PastEpisodesChart = ({
  entityTag,
  snakes,
  episodes,
  horizon,
}: PastEpisodesChartProps) => {
  return (
    <ChartBody
      entityTag={entityTag}
      episodes={episodes}
      snakes={snakes}
      horizon={horizon}
    />
  );
};
