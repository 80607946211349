import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import datePicker from './locales/en/datePicker.json';

export const i18n = createInstance({
  fallbackLng: 'en',
  lng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      datePicker,
    },
  },
});

i18n.use(initReactI18next).init();
