// TODO - refactor - https://app.clickup.com/t/86784zrk9
import {
  add as dateAdd,
  addMinutes,
  differenceInMinutes,
  format,
  startOfDay,
  startOfYear,
} from 'date-fns';

type OptionalU<T> = T | undefined;

export type DateValue = Date | number | string;

export interface LocalisedDateFormatting {
  default: string;
  US: string;
}

export enum RangeHorizon {
  ZeroDay = '0d',
  OneDay = '1d',
  TwoDays = '2d',
  OneWeek = '1w',
  TwoWeeks = '2w',
  TwelveDays = '12d',
  OneMonth = '1m',
  TwoMonths = '2m',
  ThreeMonths = '3m',
  SixMonths = '6m',
  YearToDate = 'ytd',
  OneYear = '1y',
  ThreeYears = '3y',
  FiveYears = '5y',
  TenYears = '10y',
  Max = 'max',
}

const HOUR = 60;
const MINUTES_IN_DAY = 1440;
const MINUTES_IN_THREE_DAYS = 4300;
const MS_IN_DAY = 24 * 60 * 60 * 1000;
export const THREE_YEARS_IN_DAYS = 1095;
export const ONE_YEAR_IN_DAYS = 365;

const NOW_LABEL = 'Now';
const MIN_LABEL = 'min';
const HOUR_LABEL = 'h';
const DAY_LABEL = 'd';

export const getOffset = () => new Date().getTimezoneOffset() / HOUR;

export enum RangeTypes {
  D = 'd',
  W = 'w',
  M = 'm',
  Y = 'y',
}

export const WORKING_DAYS_IN_RANGE = {
  [RangeTypes.D]: 1,
  [RangeTypes.W]: 5,
  [RangeTypes.M]: 21,
  [RangeTypes.Y]: 12 * 21,
};

export const Formats: {
  readonly 'dd MMM yyyy': LocalisedDateFormatting;
  readonly 'dd MMM, yyyy': LocalisedDateFormatting;
  readonly 'dd-MMM-yyyy': LocalisedDateFormatting;
  readonly 'yyyy-MM-dd': LocalisedDateFormatting;
} = {
  'dd MMM yyyy': {
    default: 'dd MMM yyyy',
    US: 'MMM dd yyyy',
  },
  'dd MMM, yyyy': {
    default: 'dd MMM, yyyy',
    US: 'MMM dd, yyyy',
  },
  'dd-MMM-yyyy': {
    default: 'dd-MMM-yyyy',
    US: 'MMM-dd-yyyy',
  },
  'yyyy-MM-dd': {
    default: 'yyyy-MM-dd',
    US: 'yyyy-MM-dd',
  },
};

type DifferenceInTimeProps = {
  country: string;
  start: DateValue;
  end: DateValue;
  postfix?: string;
};

/**
 * @name differenceInTime
 * @category Date Helpers
 * @summary Calculate time difference between startDate and endDate.
 * @param {DateValue} start - date from
 * @param {DateValue} end - date to
 * @param {string} [postfix] - text after value
 * @returns {string} text with difference value
 */
function differenceInTime({
  country,
  start,
  end,
  postfix = 'ago',
}: DifferenceInTimeProps) {
  const startDate = formatToDate(start);
  const endDate = formatToDate(end);

  const minutes = differenceInMinutes(startDate, endDate);

  if (minutes < 1) {
    return NOW_LABEL;
  }

  if (minutes < HOUR) {
    return `${minutes} ${MIN_LABEL} ${postfix}`;
  } else if (minutes < MINUTES_IN_DAY) {
    const hours = Math.round(minutes / HOUR);
    return `${hours} ${HOUR_LABEL} ${postfix}`;
  } else if (minutes < MINUTES_IN_THREE_DAYS) {
    const days = Math.round(minutes / MINUTES_IN_DAY);
    return `${days} ${DAY_LABEL} ${postfix}`;
  } else {
    return formatDateToLocale(country, endDate, Formats['dd-MMM-yyyy']);
  }
}

function formatDateToLocale(
  country: string,
  date: DateValue,
  formatOptions: LocalisedDateFormatting
): string {
  const dateInstance: Date = date instanceof Date ? date : formatToDate(date);
  const formatByLocalization =
    formatOptions[country as keyof LocalisedDateFormatting] ||
    formatOptions.default;

  return format(dateInstance, formatByLocalization);
}

function formatUTCDateToLocale(
  country: string,
  date: DateValue,

  formatOptions: LocalisedDateFormatting
): string {
  const dateInstance = date instanceof Date ? date : formatToDate(date);
  const utcDate = addMinutes(dateInstance, dateInstance.getTimezoneOffset());
  return formatDateToLocale(country, utcDate, formatOptions);
}

/**
 * @name formatToDate
 * @category Date Helpers
 * @summary Convert the given argument to an instance of Date.
 * @param {DateValue} argument - the value to convert
 * @returns {Date} the parsed date in the local time zone
 */
function formatToDate(argument: DateValue): Date {
  if (argument instanceof Date) {
    return argument;
  } else if (typeof argument === 'number') {
    //TODO: This is a code smell, it's not necessarily obvious that numeric values should be multiplied x1000! Looks
    // like a conversion of seconds to milliseconds; investigate where used and fix...
    //Using timestamp
    return new Date(argument * 1000);
  } else if (typeof argument === 'string') {
    return new Date(argument);
  } else {
    throw new TypeError('invalid argument');
  }
}

function getStartOfRangeUTC(
  timeRange: RangeHorizon,
  from: Date | number = Date.now()
): OptionalU<number> {
  let startDate: OptionalU<Date>;

  switch (timeRange) {
    case RangeHorizon.OneWeek:
      startDate = dateAdd(from, { days: -7 });
      break;
    case RangeHorizon.TwoWeeks:
      startDate = dateAdd(from, { days: -14 });
      break;
    case RangeHorizon.TwelveDays:
      startDate = dateAdd(from, { days: -12 });
      break;
    case RangeHorizon.OneMonth:
      startDate = dateAdd(from, { months: -1, days: 1 });
      break;
    case RangeHorizon.TwoMonths:
      startDate = dateAdd(from, { months: -2, days: 1 });
      break;
    case RangeHorizon.ThreeMonths:
      startDate = dateAdd(from, { months: -3, days: 1 });
      break;
    case RangeHorizon.SixMonths:
      startDate = dateAdd(from, { months: -6, days: 1 });
      break;
    case RangeHorizon.YearToDate:
      startDate = startOfYear(from);
      break;
    case RangeHorizon.OneYear:
      startDate = dateAdd(from, { years: -1, days: 1 });
      break;
    case RangeHorizon.ThreeYears:
      startDate = dateAdd(from, { years: -3, days: 1 });
      break;
    case RangeHorizon.FiveYears:
      startDate = dateAdd(from, { years: -5, days: 1 });
      break;
    case RangeHorizon.TenYears:
      startDate = dateAdd(from, { years: -10, days: 1 });
      break;
    default:
      startDate = undefined;
      break;
  }

  const startOfLocalDay = startDate && startOfDay(startDate);
  return startOfLocalDay && getTimeMinusOffset(startOfLocalDay);
}

const getTimeMinusOffset = (date: Date): number => {
  return date.getTime() - date.getTimezoneOffset() * 60000;
};

const dateToUTC = (date: Date): Date => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

const startOfDayUTC = (date: Date) => {
  const ret = new Date(date);
  ret.setUTCHours(0, 0, 0, 0);
  return ret;
};

const isUTCMonday = (date: Date) => {
  return date.getUTCDay() === 1;
};

const isUTCSunday = (date: Date) => {
  return date.getUTCDay() === 0;
};

const prevWorkingDayUTC = (date: Date) => {
  const d = new Date(date);

  if (isUTCSunday(d)) {
    d.setUTCDate(d.getUTCDate() - 2);
  } else if (isUTCMonday(d)) {
    d.setUTCDate(d.getUTCDate() - 3);
  } else {
    d.setUTCDate(d.getUTCDate() - 1);
  }

  return d;
};

const weekNumber = (date: Date) => {
  const startOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const daysBetween = (date.getTime() - startOfYear.getTime()) / MS_IN_DAY;
  const yearStartDay = (6 + startOfYear.getUTCDay()) % 7;
  return Math.floor((daysBetween + yearStartDay) / 7);
};

const endOfWeek = (date: Date) => {
  const ret = new Date(date);
  ret.setUTCDate(ret.getUTCDate() - ((ret.getUTCDay() + 6) % 7) + 6);
  return ret;
};

const getQuarterNumber = (date: Date) => {
  const currentMonth = date.getUTCMonth();
  const month = currentMonth - (currentMonth % 3);
  return month / 3 + 1;
};

const getHalfYearNumber = (date: Date) =>
  Math.floor(date.getUTCMonth() / 6) + 1;

export {
  dateToUTC,
  differenceInTime,
  endOfWeek,
  formatDateToLocale,
  formatUTCDateToLocale,
  getHalfYearNumber,
  getQuarterNumber,
  getStartOfRangeUTC,
  getTimeMinusOffset,
  prevWorkingDayUTC,
  startOfDayUTC,
  weekNumber,
};
