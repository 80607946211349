import { HighConvictionReportKeys } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InsightCardDetails } from '~/components/insight-card-details/InsightCardDetails';
import { ChatQualityScoreData } from '~/stores/use-chat/assistant-response.type';

import { AssessmentAccordion } from './assessment-accordion/AssessmentAccordion';
import { AssessmentGauge } from './assessment-gauge/AssessmentGauge';
import * as S from './AssessmentWidget.styles';

export const AssessmentWidget = ({
  isFixedIncome = false,
  gaugeValue,
  horizon,
  median,
  checklist,
  titleElement,
  hasHeader = true,
}: ChatQualityScoreData) => {
  const { t } = useTranslation('widget');

  const {
    CrossBusinessCycles,
    SeveralEpisodes,
    Sparse,
    Frequent,
    HighHitRatioInSample,
    HighHitRatioOutSample,
    HighPathRiskReturn,
    HighPnlDrawDownRatio,
  } = HighConvictionReportKeys;

  const conditionValues = {
    [CrossBusinessCycles]: checklist[CrossBusinessCycles],
    [SeveralEpisodes]: checklist[SeveralEpisodes],
    [Sparse]: checklist[Sparse],
    [Frequent]: checklist[Frequent],
  };

  const backTestValues = {
    [HighHitRatioInSample]: checklist[HighHitRatioInSample],
    [HighHitRatioOutSample]: checklist[HighHitRatioOutSample],
    [HighPathRiskReturn]: checklist[HighPathRiskReturn],
    [HighPnlDrawDownRatio]: checklist[HighPnlDrawDownRatio],
  };

  return (
    <>
      <S.AssessmentTitleSelector>
        {hasHeader && (
          <S.AssessmentTitle>{t('widget:assessment.title')}</S.AssessmentTitle>
        )}
        {titleElement}
      </S.AssessmentTitleSelector>
      <S.AssessmentContentWrapper>
        <S.AssessmentGaugeWrapper>
          <AssessmentGauge gaugeValue={gaugeValue} />
        </S.AssessmentGaugeWrapper>
        {median !== undefined && (
          <S.HeaderContentWrapper>
            <InsightCardDetails
              horizon={horizon}
              median={median}
              isFixedIncome={isFixedIncome}
            />
          </S.HeaderContentWrapper>
        )}
      </S.AssessmentContentWrapper>
      <S.AssessmentBody>
        <AssessmentAccordion
          title={t('widget:assessment.conditionRobustness')}
          items={conditionValues}
          showHasValue
        />
        <AssessmentAccordion
          title={t('widget:assessment.backTestResult')}
          items={backTestValues}
          showHasValue
        />
      </S.AssessmentBody>
    </>
  );
};
