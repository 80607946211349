import { z } from 'zod';

import { periodSchema } from './compass-schema';
import { AssetClassCode, AssetSubClassCode, Class } from './entity-constants';
import { ExploreSingleInsight } from './explore-filter-schema';

export enum InsightDirection {
  Unspecified,
  Bullish,
  Bearish,
}

enum ConditionType {
  Unspecified,
  CrossesAbove,
  CrossesBelow,
  Above,
  Below,
  StrictlyAbove,
  StrictlyBelow,
  Between,
  RisesBy,
  FallsBy,
  RisesByLevel,
  FallsByLevel,
}

const conditionSchema = z.object({
  entity: z.string(),
  snake: z.string(),
  condition: z.nativeEnum(ConditionType),
  value: z.number(),
  method_sub_cls: z.string(),
  condition_text: z.string(),
});

const eventSchema = z.object({
  date: z.string().datetime(),
});

export const insightEpisodeSchema = z.object({
  start_date: z.string().datetime(),
  end_date: z.string().datetime(),
  events: z.array(eventSchema),
});

const hitRatioSchema = z.object({
  median: z.number(),
  hit_ratio: z.number(),
});

export enum HighConvictionReportKeys {
  CrossBusinessCycles = 'cross_business_cycles',
  SeveralEpisodes = 'several_episodes',
  Sparse = 'sparse',
  Frequent = 'frequent',
  HighHitRatioInSample = 'high_hit_ratio_in_sample',
  HighHitRatioOutSample = 'high_hit_ratio_out_sample',
  HighPathRiskReturn = 'high_path_risk_return',
  HighPnlDrawDownRatio = 'high_pnl_draw_down_ratio',
}

const highConvictionReportSchema = z.object({
  [HighConvictionReportKeys.CrossBusinessCycles]: z.boolean(),
  [HighConvictionReportKeys.Frequent]: z.boolean(),
  [HighConvictionReportKeys.HighHitRatioInSample]: z.boolean(),
  [HighConvictionReportKeys.HighHitRatioOutSample]: z.boolean(),
  [HighConvictionReportKeys.HighPathRiskReturn]: z.boolean(),
  [HighConvictionReportKeys.HighPnlDrawDownRatio]: z.boolean(),
  [HighConvictionReportKeys.SeveralEpisodes]: z.boolean(),
  [HighConvictionReportKeys.Sparse]: z.boolean(),
});

export const metadataSchema = z.object({
  topic: z.string(),
  asset_cls: z.nativeEnum(AssetClassCode),
  class: z.nativeEnum(Class),
  direction: z.nativeEnum(InsightDirection),
  eco: z.string(),
  entity: z.string(),
  event: z.string(),
  snake: z.string(),
  horizon: periodSchema,
  last_value: z.number(),
  last_value_date: z.string().datetime().optional(),
  market_cap: z.number(),
  median_return: z.number(),
  sector: z.string(),
  stars: z.number(),
  sub_cls: z.nativeEnum(AssetSubClassCode),
  signal_high: z.number(),
  signal_low: z.number(),
  high_conviction_report: highConvictionReportSchema,
  hit_rate: z.number(),
  type: z.string(),
});

export enum InsightsChartType {
  Scenario = 'scenario',
  ScenarioConditions = 'scenario-conditions',
  ScenarioThumbnail = 'scenario-thumbnail',
  Backtest = 'backtest',
}

const chartSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(InsightsChartType),
  url: z.string(),
});

const cardSchema = z.object({
  name: z.string(),
  intuition: z.string(),
});

const contentSchema = z.object({
  section_title: z.string(),
  section_content: z.string(),
});

export const insightSchema = z.object({
  metadata: metadataSchema,
  id: z.string(),
  content: z.array(contentSchema),
  disclaimer: z.string(),
  created_at: z.string().datetime(),
  title: z.string(),
  card: cardSchema,
  charts: z.array(chartSchema),
});

export const detailedInsightSchema = insightSchema.extend({
  conditions: z.array(conditionSchema),
  episodes: z.array(insightEpisodeSchema),
  hit_ratio: z.record(hitRatioSchema),
});

export const insightFilterResponseSchema = z.object({
  result: z.array(insightSchema),
});

export const insightFilterReqSchema = z.object({
  topic: z.array(z.string()).optional(),
  asset_cls: z.array(z.number()).optional(),
  class: z.array(z.number()).optional(),
  direction: z.nativeEnum(InsightDirection).optional(),
  eco: z.array(z.string()).optional(),
  entity: z.array(z.string()).optional(),
  event: z.array(z.string()).optional(),
  horizon: z.array(z.string()).optional(),
  last_value__lte: z.number().optional(),
  market_cap_usd_bn__gte: z.number().optional(),
  market_cap_usd_bn__lt: z.number().optional(),
  median_return__gte: z.number().optional(),
  median_return__lt: z.number().optional(),
  sector: z.array(z.string()).optional(),
  stars: z.array(z.number()).optional(),
  sub_class: z.array(z.number()).optional(),
  from_date: z.string().datetime().optional(),
  to_date: z.string().datetime().optional(),
  page: z.number().optional(),
  limit: z.number().optional(),
});

export const insightPredictionSchema = z.object({
  relative_idx: z.number(),
  horizon: z.string(),
  mean: z.number(),
  median: z.number(),
  count: z.number(),
  percentiles: z.object({
    '10': z.number(),
    '20': z.number(),
    '30': z.number(),
    '40': z.number(),
    '60': z.number(),
    '70': z.number(),
    '80': z.number(),
    '90': z.number(),
  }),
  high: z.number(),
  low: z.number(),
  std_dev: z.number(),
  min: z.number(),
  max: z.number(),
});

export const predictionsSchema = z.array(insightPredictionSchema);

export const insights = {
  insight: {
    path: (id: string) => `insights/v1/${id}`,
    schema: detailedInsightSchema,
  },
  insightFilter: {
    path: 'insights/v1/filter',
    schema: insightFilterResponseSchema,
  },
  predictions: {
    path: (id: string) => `insights/v1/${id}/predictions`,
    schema: predictionsSchema,
  },
};

export type DetailedInsight = z.infer<typeof detailedInsightSchema>;
export type InsightCondition = z.infer<typeof conditionSchema>;
export type InsightFilterResponse = z.infer<typeof insightFilterResponseSchema>;
export type Insight = z.infer<typeof insightSchema>;
export type InsightMeta = z.infer<typeof metadataSchema>;
export type InsightFilterRequestSchema = z.infer<typeof insightFilterReqSchema>;
export type InsightsPrediction = z.infer<typeof insightPredictionSchema>;
export type InsightsEpisode = z.infer<typeof insightEpisodeSchema>;
export type HighConvictionReport = z.infer<typeof highConvictionReportSchema>;

export interface ExploreSingleInsightWithMeta extends ExploreSingleInsight {
  metadata?: InsightMeta;
}
