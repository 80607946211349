import { Button, Chip } from '@toggle/design-system';
import styled, { css } from 'styled-components';

type ButtonDirection = 'prev' | 'next';

export const ChipsContainer = styled.div`
  padding: 12px 0;
  display: flex;
  gap: 8px;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
`;

export const StyledChip = styled(Chip)`
  flex-shrink: 0;
`;

export const StyledButton = styled(Button)<{ $direction: ButtonDirection }>`
  position: absolute;

  ${({ $direction }) => {
    const gradientDirection = $direction === 'prev' ? 'left' : 'right';

    return css`
      background: linear-gradient(
        to ${gradientDirection},
        rgba(var(--background-default-rgb), 0.5) 0%,
        rgba(var(--background-default-rgb), 0.8) 12.5%,
        rgba(var(--background-default-rgb), 1) 50%
      );
      ${gradientDirection}: -3px;
    `;
  }};
`;
