import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Filters } from '~/components/filters/Filters';

import { useDocumentSourcesTableStore } from '../document-sources-table/use-document-sources-table-store/useDocumentSourcesTableStore';
import * as S from './DocumentFilters.styles';

export const DocumentFilters = () => {
  const { t } = useTranslation('chat');
  const documentFilters = useDocumentSourcesTableStore(
    state => state.filterGroups.DOCUMENT_FILTERS
  );
  const filterOptions = useMemo(
    () => documentFilters.activeFilterOptions,
    [documentFilters]
  );
  const hasActiveFilterOptions = filterOptions.length > 0;
  const clearAllActiveFilterOptions = useDocumentSourcesTableStore(
    state => state.clearAllActiveFilterOptions
  );

  return (
    <S.Container>
      {documentFilters.allFilters.length > 0 ? (
        <S.FiltersWrapper>
          <Filters
            filterState={documentFilters}
            store={useDocumentSourcesTableStore}
            group={'DOCUMENT_FILTERS'}
            showCustomFilterModal={false}
            showAddMoreFilters={false}
          />
        </S.FiltersWrapper>
      ) : (
        <S.SkeletonChartLoader />
      )}
      {hasActiveFilterOptions && (
        <S.StyledButton
          data-testid="clear-all-btn"
          label={t('chat:documentSourcesTable.clearAll')}
          iconName="Clear"
          variant="empty"
          size="small"
          onClick={clearAllActiveFilterOptions}
        />
      )}
    </S.Container>
  );
};
