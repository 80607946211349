import { z } from 'zod';

import { filtersSchemas } from './screener';

export const documentGroupSchema = z.object({
  id: z.string(),
  kind: z.string(),
  year: z.number(),
  period: z.string(),
  entity: z.string(),
  citations_count: z.number(),
  relevance: z.number(),
});

const pageSchema = z.object({
  current: z.number(),
  next: z.number().optional(),
  size: z.number(),
});

export const citationSchema = z.object({
  text: z.string(),
  entity: z.string(),
  document_id: z.string(),
  document_kind: z.string(),
  document_type: z.enum(['pdf', 'html', 'markdown']),
  document_name: z.string(),
  score: z.number(),
  pages: z.array(z.number()),
  published_date: z.string(),
  period: z.string(),
  year: z.number(),
});

const citationResponseSchema = z.object({
  page: pageSchema,
  total_count: z
    .object({
      citations: z.number(),
      docs: z.number(),
      companies: z.number(),
    })
    .optional(),
  citations: z.array(citationSchema),
});

const filterRequestSchema = z.record(
  z.string(),
  z.object({
    values: z.array(z.record(z.string(), z.unknown())),
  })
);

const pageRequestSchema = z
  .object({
    number: z.number(),
    size: z.number(),
  })
  .optional();

const sortByRequestSchema = z
  .object({
    field: z.string(),
    desc: z.boolean(),
  })
  .optional();

const citationRequestSchema = z.object({
  filters: filterRequestSchema.optional(),
  page: pageRequestSchema,
  sort_by: sortByRequestSchema,
});

const documentGroupRequestSchema = z.object({
  filters: filterRequestSchema.optional(),
  doc_ids_only: z.boolean().optional(),
  page: pageRequestSchema,
  sort_by: sortByRequestSchema,
});

const documentGroupResponseSchema = z.object({
  documents: z.array(documentGroupSchema).optional(),
  doc_ids: z.array(z.string()).optional(),
  page: pageSchema,
});

export const citation = {
  citationsFiltered: {
    path: (groupId: string) => `citations/v1/group/${groupId}/filter`,
    schema: citationResponseSchema,
  },
  citationDocumentGroup: {
    path: (groupId: string) => `citations/v1/group/${groupId}/documents`,
    schema: documentGroupResponseSchema,
  },
  citationFilterSchemas: {
    path: (groupId: string) => `citations/v1/group/${groupId}/schemas`,
    schema: filtersSchemas,
  },
};

export type Citation = z.infer<typeof citationSchema>;
export type CitationResponse = z.infer<typeof citationResponseSchema>;
export type CitationRequest = z.infer<typeof citationRequestSchema>;
export type CitationFilters = z.infer<typeof filterRequestSchema>;
export type DocumentGroup = z.infer<typeof documentGroupSchema> & {
  subRows: Citation[];
};
export type DocumentSourcesTableData = Citation | DocumentGroup;
export type DocumentGroupResponse = z.infer<typeof documentGroupResponseSchema>;
export type DocumentGroupRequest = z.infer<typeof documentGroupRequestSchema>;
