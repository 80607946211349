import { queryKeys } from '~/routes/app-paths';

export const widgetSearchParamKeys = {
  indicator: 'indicator',
};

export const widgetPaths = {
  assetOverview: 'ao',
  searchAsset: 'searchAsset',
  watchlistManagerModal: 'watchlistManagerModal',
} as const;

export type WidgetPathNames = typeof widgetPaths[keyof typeof widgetPaths];

// Can be used to build to ao widget url, using tag from Entity
// Can be used alongside react-router-dom const [searchParams, setSearchParams] = useSearchParams();
export const buildAoWidgetParams = (entityTag: string) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append(queryKeys.assetOverviewAsset, entityTag);

  return `widget=${widgetPaths.assetOverview}&${urlSearchParams}`;
};
