import {
  Alert,
  borderRadius,
  Button,
  Input,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModalRoot = styled(Modal)`
  ${Modal.Styled.ModalContainer} {
    width: 100%;
    max-width: 740px;
    background: var(--background-soft);
  }
`;

export const ConditionBuilderOptions = styled.section`
  padding: 20px;
`;
export const ConditionOptions = styled.section`
  display: flex;
  gap: 12px;
  background: var(--surface-default);
  padding: 12px;
  ${borderRadius.r2};
`;

export const Option = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  ${borderRadius.r2};
  background: var(--surface-soft);
  border: 1px solid var(--border-default);
  color: var(--text-default);
  padding: 20px 12px;
`;

export const OptionLabel = styled.span`
  ${typographyV2.bodySm}
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
`;

export const StyledInput = styled(Input).attrs({ size: 'medium' })`
  max-width: 90px;
  ${borderRadius.r2};
  border: 1px solid var(--border-default);
  background: var(--surface-soft);
  ${Input.Styles.InputElement} {
    width: 40px;
  }
  ${Input.Styles.InputWrapper} {
    background-color: unset;
    border: none;
  }
`;

export const ErrorAlert = styled(Alert)`
  margin-bottom: 12px;
  width: 100%;
`;

export const InputSuffix = styled.span`
  color: var(--text-soft);
  ${typographyV2.bodyBase};
  padding-right: 12px;
`;
