import {
  AutocompleteInput,
  borderRadius,
  Dropdown,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  position: absolute;
  bottom: calc(100% + 4px);
  width: 288px;
  border: 1px solid var(--border-soft);
  background-color: var(--surface-default);
  box-shadow: var(--elevation-moderate);
  ${borderRadius.r1};

  ${Dropdown.Styled.DropdownListContainer} ul {
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-top: 1px solid var(--border-soft);
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const SearchInput = styled(AutocompleteInput)`
  padding: 8px;
`;

export const EntityName = styled.p`
  ${typographyV2.bodySm};
`;
