/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ChipHorizonSelector, i18n, RangeHorizon } from '@toggle/chart';
import { Toggle } from '@toggle/design-system';
import { AssetSubClassCode, Entity, formatTicker } from '@toggle/toggle';
import React, { ChangeEvent, createRef, RefObject, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { useRelatedCompanies } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import {
  SNAKES_NTM,
  SNAKES_TTM,
} from '~/modules/overview-valuation-metrics/utils';
import { sortElementByEdgeWeight } from '~/utils/common/utils';
import { i18n as i18nTerminal } from '~/utils/i18n/i18n';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';
import { UseTurboChartReturn } from '~/views/turbo-chart/hooks/use-turbo-chart/useTurboChart';
import { PEER_GROUP_DISPLAYED } from '~/widgets/overview/utils';

import * as S from './ChartPeerGroup.styles';
import { ChartPerSnake } from './ChartPerSnake/ChartPerSnake';
import { ChipsSelector } from './components/chips-selector/ChipsSelector';
import { SnakeTypes } from './utils/chart-subpage-navigation/chart-subpage-navigation';

export interface ChartPeerGroupProps {
  entity: Pick<
    Entity,
    'tag' | 'default_snake' | 'ticker' | 'exchange' | 'sub_class'
  >;
}

enum ValuationTypes {
  TTM = 'TTM',
  NTM = 'NTM',
}

const variants = {
  [ValuationTypes.TTM]: SNAKES_TTM,
  [ValuationTypes.NTM]: SNAKES_NTM,
};

const defaultSnakes = ['price.ds'];

export interface ChartPerSnakeRef {
  changeRange: UseTurboChartReturn['changeRange'];
  hideActiveAsset: UseTurboChartReturn['hideActiveAsset'];
}

export const ChartPeerGroup = ({ entity }: ChartPeerGroupProps) => {
  const { t } = useTranslation(['widget', 'chart', 'analyze', 'countries']);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultChartHorizon = RangeHorizon.ThreeMonths;
  const horizonParam = (searchParams.get('horizon') ??
    defaultChartHorizon) as RangeHorizon;
  const snakeTypeParam = searchParams.get('snakeType') as SnakeTypes | null;

  const { data: relatedCompaniesData, isLoading: areRelatedCompaniesLoading } =
    useRelatedCompanies(entity.tag);

  const [showPeerGroup, setShowPeerGroup] = useState(true);
  const [selectedSnakeTypes, setSelectedSnakeTypes] = useState(
    snakeTypeParam ?? SnakeTypes.Price
  );
  const [activeValuation, setActiveValuation] = useState(ValuationTypes.TTM);
  const [entitiesVisibility, setEntitiesVisibility] = useState<
    Record<string, boolean>
  >({});
  const [horizon, setHorizon] = useState(horizonParam);
  const { colors } = useChartTheme(['multiline']);

  const sortedCompanies = sortElementByEdgeWeight(
    relatedCompaniesData?.competitors ?? []
  );
  const peerEntities = sortedCompanies
    .slice(0, PEER_GROUP_DISPLAYED)
    .map(competitor => competitor.entity);

  const showValuationMetrics =
    selectedSnakeTypes === SnakeTypes.ValuationMetrics;
  const snakeNames = variants[activeValuation];
  const isPriceSnakeSelected = selectedSnakeTypes === SnakeTypes.Price;
  const allSnakes = isPriceSnakeSelected ? defaultSnakes : snakeNames;
  const allEntities = showPeerGroup ? [entity, ...peerEntities] : [entity];
  const chartsPerSnake = allSnakes.map(snake => ({
    snake,
    assets: allEntities.map(entity => ({
      tag: entity.tag,
      snake: `${entity.tag}.${snake}`,
      paneIndex: 0,
    })),
  }));

  const chartRefs = chartsPerSnake.map(
    () => createRef() as RefObject<ChartPerSnakeRef>
  );

  const onCheckboxChange = (
    entityTag: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    chartRefs.forEach((ref, index) => {
      const snake = `${entityTag}.${allSnakes[index]}`;
      ref.current?.hideActiveAsset(snake, { isHidden: !event.target.checked });
    });

    setEntitiesVisibility(prev => ({
      ...prev,
      [entityTag]: !event.target.checked,
    }));
  };

  const onHorizonChange = (horizon: RangeHorizon) => {
    chartRefs.forEach(ref => {
      ref.current?.changeRange(horizon);
    });

    setHorizon(horizon);
    setSearchParams(
      params => {
        params.delete('horizon');
        return params;
      },
      { replace: true }
    );
  };

  const onToggle = (checked: boolean) => {
    setShowPeerGroup(checked);
    setEntitiesVisibility({});
  };

  if (areRelatedCompaniesLoading) {
    return (
      <S.SkeletonWrapper data-testid="chart-loading">
        <S.SkeletonChartLoader />
      </S.SkeletonWrapper>
    );
  }

  const isStockAsset = entity.sub_class === AssetSubClassCode.EquityStock;
  const snakeTypeItems = [
    {
      id: SnakeTypes.Price,
      label: t('widget:assetOverview.chartTab.snakeTypesSelector.price'),
    },
    {
      id: SnakeTypes.ValuationMetrics,
      label: t(
        'widget:assetOverview.chartTab.snakeTypesSelector.valuationMetrics'
      ),
    },
  ];
  const valuationItems = [
    {
      id: ValuationTypes.TTM,
      label: t('widget:assetOverview.ttm'),
    },
    {
      id: ValuationTypes.NTM,
      label: t('widget:assetOverview.ntm'),
    },
  ];

  const multiColors = colors.getMulti(allEntities.length);
  return (
    <I18nextProvider i18n={i18n}>
      <S.ChartPeerGroupRoot data-testid="chart-peer-group">
        <S.Body>
          <I18nextProvider i18n={i18nTerminal}>
            <S.Toolbar>
              <S.GroupSection>
                {isStockAsset && (
                  <ChipsSelector
                    data-testid="snake-type-selector"
                    items={snakeTypeItems}
                    selectedId={selectedSnakeTypes}
                    onChange={setSelectedSnakeTypes}
                  />
                )}
                <ChipHorizonSelector
                  horizonRanges={Object.values(RangeHorizon)}
                  selectedRange={horizon}
                  onChange={onHorizonChange}
                />
              </S.GroupSection>
              <S.GroupSection>
                {showValuationMetrics && (
                  <ChipsSelector
                    data-testid="valuation-type-selector"
                    items={valuationItems}
                    selectedId={activeValuation}
                    onChange={setActiveValuation}
                  />
                )}
                {!!peerEntities.length && (
                  <Toggle
                    leftLabel={t(
                      'widget:assetOverview.chartTab.peerGroupToggle'
                    )}
                    isChecked={showPeerGroup}
                    size="small"
                    onToggle={onToggle}
                    data-testid="peer-toggle"
                  />
                )}
              </S.GroupSection>
            </S.Toolbar>

            <S.CheckboxContainer>
              {allEntities.map((entity, index) => (
                <S.StyledCheckBox
                  key={entity.tag}
                  name={entity.tag}
                  $color={multiColors[index].value}
                  label={formatTicker(entity)}
                  checked={!entitiesVisibility[entity.tag]}
                  onChange={onCheckboxChange}
                  variant={allEntities.length === 1 ? 'disabled' : 'primary'}
                />
              ))}
            </S.CheckboxContainer>
            <S.ChartContent>
              {chartsPerSnake.map((item, index) => (
                <ChartPerSnake
                  key={item.snake}
                  chartAssets={item.assets}
                  colors={colors}
                  horizon={horizon}
                  chartRef={chartRefs[index]}
                  isSingle={chartsPerSnake.length === 1}
                  initialPriceDisplay={
                    isPriceSnakeSelected &&
                    showPeerGroup &&
                    !!peerEntities.length
                      ? 'percentage'
                      : 'price'
                  }
                />
              ))}
            </S.ChartContent>
          </I18nextProvider>
        </S.Body>
      </S.ChartPeerGroupRoot>
    </I18nextProvider>
  );
};
