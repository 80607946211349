import { SingleNews } from '@toggle/toggle';
import { useState } from 'react';

export const useArticleDrawer = () => {
  const [singleNews, setSingleNews] = useState<SingleNews | undefined>();

  const closeArticleDrawer = () => {
    setSingleNews(undefined);
  };

  return {
    singleNews,
    setSingleNews,
    closeArticleDrawer,
  };
};
