import {
  ChartAssetData,
  ChartTypeSelectorProps,
  hasOHLC,
  HorizonWithPriceRange,
  SeriesType,
} from '@toggle/chart';
import { Entity } from '@toggle/toggle';
import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';

import { ChartNavigateConfigParams } from '~/hooks/use-chart-navigate/useChartNavigate';

import { ChartMessageHeader } from '../chart-message-header/ChartMessageHeader';
import * as S from './ChartHeader.styles';

interface ChartHeaderProps {
  chartData: ChartAssetData[];
  horizonWithPriceRanges: HorizonWithPriceRange[];
  chartConfig: ChartNavigateConfigParams;
  entity: Entity | undefined;
  changeSeriesType: ChartTypeSelectorProps['onChange'];
  seriesType: SeriesType;
  renderFullScreenButton: () => ReactNode;
  t: TFunction;
  isLegacyVariant?: boolean;
  isChatMessage?: boolean;
}

export const ChartHeader = ({
  chartData,
  chartConfig,
  horizonWithPriceRanges,
  entity,
  changeSeriesType,
  seriesType,
  renderFullScreenButton,
  isLegacyVariant = true,
  isChatMessage,
  t,
}: ChartHeaderProps) => {
  //this is to prevent translations lazy loading when any modal is opened from menu bar
  //resulting in unmounting and remounting full DOM

  if (!isLegacyVariant) {
    return null;
  }

  if (isChatMessage) {
    return (
      <ChartMessageHeader
        chartData={chartData}
        t={t}
        showChartArea={!!horizonWithPriceRanges.length}
        chartConfig={chartConfig}
      />
    );
  }

  return (
    <S.HeaderContainer>
      {chartData[0] && entity && (
        <S.ChartPriceStyled
          entity={entity}
          snakeMeta={chartData[0].snakeMeta}
          ts={chartData[0].ts}
          t={t}
        />
      )}
      <S.ChartTypeSelectorStyled
        selectedSeriesType={seriesType}
        onChange={changeSeriesType}
        disableMenuBar={!chartData.length}
        hasOHLC={hasOHLC(chartData[0])}
      />
      {renderFullScreenButton()}
    </S.HeaderContainer>
  );
};
