import { BankCard, UserSubscription } from '@toggle/toggle';
import React, { FC, useState } from 'react';

import { EditPaymentMethodModal } from '~/views/settings/subscription/edit-payment-method-modal/EditPaymentMethodModal';
import { checkCardExpiry } from '~/views/settings/subscription/utils/subscription-view-utils';

import { AlertCardError } from '../alert-card-error/AlertCardError';
import { AlertCardIsExpiring } from '../alert-card-is-expiring/AlertCardIsExpiring';
import { AlertGracePeriod } from '../alert-grace-period/AlertGracePeriod';
import * as S from '../alert-trial-ends/AlertTrialEnds.styles';

export interface AlertPaymentMethodProps {
  card: BankCard;
  userSubscription: UserSubscription;
  cardError: boolean;
}

export const AlertPaymentMethod: FC<AlertPaymentMethodProps> = ({
  card,
  userSubscription,
  cardError,
}) => {
  const [isEditCardModalOpen, setIsEditCardModalOpen] = useState(false);
  const { expiryDate, isCardExpired } = checkCardExpiry(card);
  const shouldShowError = cardError || isCardExpired;

  const isInGracePeriod = !!userSubscription?.grace_hours_remaining;

  const openEditCardModal = () => {
    setIsEditCardModalOpen(true);
  };

  if (isEditCardModalOpen) {
    return (
      <EditPaymentMethodModal
        isInGracePeriod={isInGracePeriod}
        onClose={() => setIsEditCardModalOpen(false)}
      />
    );
  }

  return (
    <S.AlertTrialEndsRoot>
      {userSubscription.grace_hours_remaining && (
        <AlertGracePeriod
          hoursRemaining={userSubscription.grace_hours_remaining}
          openEditCardModal={openEditCardModal}
        />
      )}
      <AlertCardError
        hasError={shouldShowError}
        openEditCardModal={openEditCardModal}
      />
      <AlertCardIsExpiring
        expiryDate={expiryDate}
        openEditCardModal={openEditCardModal}
      />
    </S.AlertTrialEndsRoot>
  );
};
