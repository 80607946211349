import { customScrollMinimal, Icon, TableCell } from '@toggle/design-system';
import { PriceStatus } from '@toggle/helpers';
import styled, { css } from 'styled-components';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';
import { priceChangeColors } from '~/utils/common/utils';

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  overflow: auto;
  ${customScrollMinimal}
`;

export const TableWrapper = styled.div`
  ${customScrollMinimal};
  width: 100%;
  height: 100%;
`;

export const TickerCell = styled.div`
  display: flex;
  gap: 8px;
`;

export const Ticker = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const HighlightText = styled.span`
  color: var(--text-soft);

  b {
    font-family: var(--font-roboto-mono);
    color: var(--text-default);
  }
`;

export const StyledAssetLogoContainer = styled(AssetLogoContainer)`
  border-radius: 50%;
`;

export const PriceWrapper = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  gap: 4px;

  color: ${({ $isDisabled }) =>
    $isDisabled ? 'var(--text-disabled)' : 'var(--text-default)'};
`;

export const Currency = styled.span`
  font-size: 8px;
  text-transform: uppercase;
  margin-top: 2px;
`;

export const StampWrapper = styled.div<{
  $color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 20px;
  > svg {
    fill: ${({ $color }) => $color};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
`;

export const ValueWrapper = styled.div<{
  $status: PriceStatus;
  $alignment?: string;
}>`
  color: ${({ $status }) => priceChangeColors[$status]};
`;

export const TableHeaderEditMode = styled.span`
  display: flex;
`;

export const StyledGripsDots = styled(Icon)`
  margin: 4px;
`;

export const GripsWrapper = styled.span`
  padding: 0 4px;
  cursor: grab;
`;

export const CheckboxWrapper = styled.span`
  padding-left: 20px;
`;

export const CellFlexWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledTableCell = styled(TableCell)<{
  $noPadding?: boolean;
}>`
  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0px;
    `}
`;
