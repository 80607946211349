import React from 'react';

import * as S from './Spinner.styles';

interface SpinnerProps {
  size?: 'small' | 'large';
  useAccentColor?: boolean;
}

export const Spinner = ({
  size = 'large',
  useAccentColor = false,
}: SpinnerProps) => (
  <S.SpinnerIcon
    data-testid="spinner"
    $size={size}
    $useAccentColor={useAccentColor}
  />
);
