import { RangeHorizon, ResampleIntervals } from '@toggle/chart';
import { hasKeyValues } from '@toggle/helpers';
import { AssetSubClassCode, Entity, isEtf } from '@toggle/toggle';
import React, { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import { useChartNavigate } from '~/hooks/use-chart-navigate/useChartNavigate';
import { useRelatedCompanies } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { About } from '~/modules/about/About';
import { AssetInsightsList } from '~/modules/asset-insights-list/AssetInsightsList';
import { OverviewFinancials } from '~/modules/overview-financials/OverviewFinancials';
import { OverviewKeyValues } from '~/modules/overview-key-values/OverviewKeyValues';
import { OverviewKnowledgeGraph } from '~/modules/overview-knowledge-graph/OverviewKnowledgeGraph';
import { OverviewValuation } from '~/modules/overview-valuation-metrics/OverviewValuationMetrics';
import { SimpleChart } from '~/modules/simple-chart/SimpleChart';
import { ChartSearchParamsAssetValue } from '~/utils/chart-search-utils/chart-search-utils';
import { sortElementByEdgeWeight } from '~/utils/common/utils';
import { ChartState } from '~/views/turbo-chart/hooks/use-chart-state/useChartState';
import { ChartSettings } from '~/views/turbo-chart/utils/chart-settings-utils/chart-settings-utils';

import { InsightsDrawer } from '../insight-widget/components/insights-drawer/InsightsDrawer';
import * as S from './Overview.styles';

export interface OverviewProps {
  entity: Entity;
  overviewTabChartContainerRef?: RefObject<HTMLDivElement | null>;
}

export const Overview = ({
  entity,
  overviewTabChartContainerRef,
}: OverviewProps) => {
  const { t } = useTranslation([
    'widget',
    'common',
    'chart',
    'analyze',
    'countries',
    'scenarioCommander',
    'screener',
    'scenario',
  ]);
  const [hasInsightData, setHasInsightData] = useState(true);
  const [selectedInsightId, setSelectedInsightId] = useState('');
  const chartNavigate = useChartNavigate();

  const {
    data: relatedCompaniesData,
    isLoading,
    isError,
  } = useRelatedCompanies(entity.tag);

  const competitors = sortElementByEdgeWeight(
    relatedCompaniesData?.competitors ?? []
  );
  const suppliers = sortElementByEdgeWeight(
    relatedCompaniesData?.suppliers ?? []
  );

  const chartSettings: ChartSettings = {
    assets: [
      {
        tag: entity.tag,
        snake: entity.default_snake,
        paneIndex: 0,
      },
    ],
    resample: ResampleIntervals.OneDay,
    horizon: RangeHorizon.OneMonth,
    domain: null,
    hidden: undefined,
    paneSettings: {},
  };

  const isStock = entity.sub_class === AssetSubClassCode.EquityStock;
  const entityIsEtf = isEtf(entity);
  const displayAbout = isStock || entityIsEtf;

  const navigateToChart = (chartState: ChartState) => {
    const entityValue: ChartSearchParamsAssetValue = [
      entity.tag,
      entity.default_snake,
      0,
    ];

    chartNavigate([entityValue], {
      resample: chartState.resample,
      horizon: chartState.horizon,
      seriesType: chartState.chartPanes[0].seriesType,
    });
  };

  return (
    <S.OverviewRoot>
      <InsightsDrawer
        insightId={selectedInsightId}
        isOpen={!!selectedInsightId}
        closeDrawer={() => {
          setSelectedInsightId('');
        }}
        zIndex={'calc(var(--z-index-modal) + 1)'}
      />
      <div ref={overviewTabChartContainerRef}>
        <SimpleChart
          entity={entity}
          key={entity.tag}
          initialChartSettings={chartSettings}
          isLegacyVariant={false}
          onClickFullscreen={navigateToChart}
          maxHorizon={RangeHorizon.TenYears}
          eventsConfig={{
            TMI: true,
          }}
        />
      </div>
      {hasKeyValues(entity.tag) && <OverviewKeyValues entityTag={entity.tag} />}

      <S.Flex>
        <S.SplitSection>
          {displayAbout && <About entity={entity} />}
        </S.SplitSection>
        {isStock && (
          <S.SplitSection>
            <OverviewKnowledgeGraph
              entityTag={entity.tag}
              isEtf={entityIsEtf}
              companiesData={{
                competitors: relatedCompaniesData?.competitors ?? [],
                suppliers,
                isLoading,
                isError,
              }}
            />
          </S.SplitSection>
        )}
      </S.Flex>
      {isStock && (
        <>
          <OverviewFinancials
            entity={entity}
            competitorsData={{
              competitors,
              isLoading,
            }}
          />
          <OverviewValuation
            entity={entity}
            competitorsData={{
              competitors,
              isLoading,
            }}
          />
        </>
      )}

      {hasInsightData && (
        <S.Flex>
          <S.SplitSection>
            <AssetInsightsList
              title={
                <SectionTitle
                  title={t('widget:assetOverview.insightSectionTitle')}
                />
              }
              entityTag={entity.tag}
              setHasInsights={setHasInsightData}
              onClickInsight={setSelectedInsightId}
            />
          </S.SplitSection>
        </S.Flex>
      )}
    </S.OverviewRoot>
  );
};
