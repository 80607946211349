import { useQuery } from '@tanstack/react-query';
import { themeOverview, ThemeOverviewResponse } from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';

export interface UseThemeProps {
  themeId: string;
}

export const QUERY_KEY_USE_THEME = 'QUERY_KEY_USE_THEME';

export const useTheme = ({ themeId }: UseThemeProps) => {
  const { path, schema } = themeOverview.getTheme;

  return useQuery({
    queryKey: [QUERY_KEY_USE_THEME, themeId],
    queryFn: () => {
      return apiFetch<ThemeOverviewResponse>(path(themeId), {
        method: 'get',
        schema,
      });
    },
  });
};
