import {
  ContinueConversationResponse,
  conversations,
  PostConversationResponse,
  SearchConversationResponse,
} from '@toggle/toggle';

import { apiFetch, wretchRequest } from '~/utils/api-fetch/apiFetch';

export const postConversation = ({
  message,
  conversationId,
  controller,
}: {
  message: string;
  conversationId?: string;
  controller?: AbortController;
}):
  | Promise<ContinueConversationResponse>
  | Promise<PostConversationResponse> => {
  const body = {
    message,
  };

  if (conversationId) {
    return wretchRequest(
      conversations.continueConversation.path(conversationId),
      {
        schema: conversations.continueConversation.schema,
        method: 'post',
        body,
        signal: controller?.signal,
      }
    );
  }

  return wretchRequest(conversations.postConversation.path, {
    schema: conversations.postConversation.schema,
    method: 'post',
    body,
    signal: controller?.signal,
  });
};

export const getConversation = (id: string) =>
  wretchRequest(conversations.getConversation.path(id), {
    schema: conversations.getConversation.schema,
  });

export const searchConversation = (query: string, page: number) => {
  return wretchRequest<SearchConversationResponse>(
    `${conversations.searchConversation.path}?q=${query}&page=${page}`,
    { schema: conversations.searchConversation.schema }
  );
};

export const getConversationMessageWithSteps = ({
  messageId,
  conversationId,
  controller,
}: {
  messageId: string;
  conversationId: string;
  controller?: AbortController;
}) =>
  wretchRequest(
    conversations.getConversationMessage.path(conversationId, messageId),
    {
      schema: conversations.getConversationMessage.schema,
      signal: controller?.signal,
    }
  );

export const getConversationMessageStep = ({
  messageId,
  conversationId,
  stepId,
  controller,
}: {
  messageId: string;
  conversationId: string;
  stepId: string;
  controller?: AbortController;
}) =>
  wretchRequest(
    conversations.getConversationMessageStep.path(
      conversationId,
      messageId,
      stepId
    ),
    {
      signal: controller?.signal,
      schema: conversations.getConversationMessageStep.schema,
    }
  );

export const updateConversation = ({
  id,
  name,
  favourite,
}: {
  id: string;
  name?: string;
  favourite?: boolean;
}) => {
  const body = favourite !== undefined ? { name, favourite } : { name };
  return apiFetch(conversations.conversationId.path(id), {
    method: 'put',
    body,
  });
};

export const deleteConversation = (id: string) =>
  apiFetch(conversations.conversationId.path(id), {
    method: 'delete',
  });

export const getAllConversations = () =>
  wretchRequest(conversations.getAllConversations.path, {
    schema: conversations.getAllConversations.schema,
  });

export const postFeedback = (body: {
  conversation_id: string;
  message_id: string;
  feedback: string;
}) => {
  wretchRequest(conversations.feedback.path, {
    method: 'post',
    body,
  });
};
