import { keyboard } from '@toggle/helpers';
import React, { useRef } from 'react';

import * as S from './RenameInput.styles';

export interface RenameInputProps {
  value: string;
  setValue: (name: string) => void;
  setIsRenameActive: React.Dispatch<React.SetStateAction<boolean>>;
  hasError: boolean;
}

export const RenameInput = ({
  value,
  setValue,
  setIsRenameActive,
  hasError,
}: RenameInputProps) => {
  const inputRef = useRef(null);

  const submit = (value: string) => {
    setValue(value);
    setIsRenameActive(false);
  };

  const handleDropdownKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === keyboard.enter.key) {
      submit(value);
    }
  };

  return (
    <S.StyledInput
      ref={inputRef}
      autoFocus
      value={value}
      rightIcon={`${value.length}/30`}
      maxLength={30}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      onKeyDown={handleDropdownKeyDown}
      data-testid="rename-input"
      onBlur={() => {
        submit(value);
      }}
      hasError={hasError}
    />
  );
};
