import { Button, Tooltip } from '@toggle/design-system';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarEventDrawerHeader } from './calendar-event-drawer-header/CalendarEventDrawerHeader';
import * as S from './CalendarEventDrawer.styles';

type CalendarEventDrawerHeaderContent = {
  title: string;
  subtitle: string;
  dateString: string;
  titleLogo?: ReactNode;
  reported?: boolean;
};
export interface CalendarEventDrawerProps {
  closeDrawer: () => void;
  isOpen: boolean;
  drawerContent: ReactNode;
  headerContent: CalendarEventDrawerHeaderContent;
  onFullScreenClick?: () => void;
}

export const CalendarEventDrawer = ({
  isOpen,
  drawerContent,
  headerContent,
  closeDrawer,
  onFullScreenClick,
}: CalendarEventDrawerProps) => {
  const { t } = useTranslation(['calendar']);
  return (
    <S.StyledDrawer
      data-testid="calendar-events-drawer"
      isOpen={isOpen}
      handleCloseClick={closeDrawer}
      headerTitle={<></>} // This is to right-align the close button
      headerButton={
        onFullScreenClick && (
          <Tooltip
            isTouchDevice={false}
            label={t('calendar:viewMore')}
            strategy="fixed"
            placement="left"
          >
            <Button
              variant="empty"
              iconName="Expand"
              onClick={onFullScreenClick}
            />
          </Tooltip>
        )
      }
    >
      <S.ContentWrapper>
        <CalendarEventDrawerHeader
          title={headerContent.title}
          subtitle={headerContent.subtitle}
          logoNode={headerContent.titleLogo}
          dateString={headerContent.dateString}
          reported={headerContent.reported}
        />
        {drawerContent}
      </S.ContentWrapper>
    </S.StyledDrawer>
  );
};
