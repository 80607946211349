import { PillDropdown } from '@toggle/design-system';
import styled from 'styled-components';

import { CompanyListItem } from '~/components/peer-section/company-list-item/CompanyListItem';

import { DrawerSectionHeader } from '../drawer-section-header/DrawerSectionHeader';

export const GridContainer = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${PillDropdown.Styled.StyledPillContainer} {
    justify-content: start;
    min-width: 116px;
    width: 100%;
  }

  ${PillDropdown.Styled.Label} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${CompanyListItem.Styled.AssetHeader} {
    width: calc(100% - 28px);
  }
`;

export const Root = styled.div`
  ${DrawerSectionHeader.Styled.Root} {
    padding-bottom: 4px;
  }
`;

export const CompanyListItemWrapper = styled.div`
  width: 25%;
  border-bottom: 1px solid var(--border-soft);
  padding-top: 12px;
  padding-bottom: 4px;
`;
