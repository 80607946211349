import {
  customScrollMinimal,
  TableCell,
  TableHeaderIcons,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

import { SortableTableHeaderCell } from '~/widgets/scenario/components/sortable-table-header-cell/SortableTableHeaderCell';

import { EntityDetailsCell } from '../document-sources-table-cells/entity-details-cell/EntityDetailsCell';

export const StyledTableHeaderRow = styled.tr`
  ${SortableTableHeaderCell.Styled.StyledTableHeader} {
    background-color: var(--background-soft);
  }
  ${TableHeaderIcons.Styled.SortIcon} {
    fill: var(--icon-soft);
  }
`;

export const StyledTableCell = styled(TableCell)<{
  $isActive?: boolean;
  $isSubRow?: boolean;
}>`
  white-space: normal;
  background-color: ${({ $isActive, $isSubRow }) =>
    $isActive
      ? 'var(--surface-active)'
      : $isSubRow
      ? 'var(--surface-default)'
      : 'var(--background-soft)'};
`;

export const NumberContainer = styled.div`
  ${typographyV2.bodySm}
  font-family: var(--font-roboto-mono);
  color: var(--text-default);
`;

export const RelevanceHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RelevanceCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  justify-content: flex-end;
`;

export const SubRowLabelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SubRowLabel = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${typographyV2.bodyXs};
  color: var(--text-soft);
  position: absolute;
  width: 450px;
`;

export const TableWrapper = styled.div`
  ${customScrollMinimal};
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--background-soft);
  height: 100%;

  container-type: inline-size;
  @container (max-width: 525px) {
    ${SubRowLabel} {
      width: 350px;
    }
  }
`;

export const EntityDetailsWrapper = styled.div`
  ${EntityDetailsCell.Styled.EntityDetails} {
    gap: 8px;
    color: var(--text-default);
  }
`;

export const SpacerTableCell = styled(TableCell)`
  pointer-events: none;
  background-color: var(--background-soft);
  border-bottom: none;
`;

export const SpacerCellContent = styled.div`
  height: var(--chat-footer-height);
`;
