/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import * as Radix from '@radix-ui/react-dropdown-menu';
import { TooltipTrigger } from '@toggle/design-system';
import type { ScreenerFilter } from '@toggle/toggle';
import React, { ReactNode, useState } from 'react';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import {
  FilterOption,
  FilterUseType,
  ToggleFilterProps,
} from '~/components/update-filter-options/updateFilterOptions';
import {
  FilterGroupKeys,
  UseFilterActionsReturn,
} from '~/hooks/use-filter-actions/useFilterActions';
import { FilterSearch } from '~/views/screener/filter-search/FilterSearch';
import { useFilterOptionSearch } from '~/views/screener/use-filter-option-search/useFilterOptionSearch';
import { isFilterWithChildOptionActive } from '~/views/screener/use-screener-store/filter-utils';

import { ActiveFilterOptions } from '../filters/Filters';
import { ChildFilter } from './ChildFilter';
import * as S from './Filter.styles';
import { FilterFooterActions } from './FilterFooterActions';
import { FilterLabel } from './FilterLabel';
import { MultiSelectedOptions } from './MultiSelectedOptions';
import { Option } from './Option';

interface FilterWithIsdisabledProp extends ScreenerFilter {
  isDisabled?: boolean;
}

export type FilterProps<T extends FilterGroupKeys = FilterGroupKeys> = {
  filter: FilterWithIsdisabledProp;
  labels: {
    searchPlaceholder: string;
    resetLabel: string;
    noResultsLabel: string;
    selectedLabel: string;
    disabledTooltipLabel: string;
    multiselectFeatured: string;
    remove: string;
    setCustomRange: string;
    renderCustomFilterOptionLabel?: (filterOption: FilterOption) => ReactNode;
  };
  useType?: FilterUseType;
  multiselect?: boolean;
  showTriggerLabel?: boolean;
  filterOptions: ActiveFilterOptions['options'];
  disabledOptions?: ActiveFilterOptions['options'];
  addFilterOption: UseFilterActionsReturn<T>['addFilterOption'];
  removeFilterOption: UseFilterActionsReturn<T>['removeFilterOption'];
  resetFilterOptions?: UseFilterActionsReturn<T>['resetFilterOptions'];
  onRemove?: UseFilterActionsReturn<T>['removeFilter'];
  onCustomRangeClick: () => void;
  id?: string;
  showListTitle?: boolean;
};

export const Filter = <T extends FilterGroupKeys>({
  filter,
  id = filter.key,
  labels,
  filterOptions,
  disabledOptions,
  removeFilterOption,
  addFilterOption,
  resetFilterOptions,
  showTriggerLabel = true,
  useType = 'single-use',
  multiselect = useType === 'single-use' && filter.config.multiselect,
  onRemove,
  onCustomRangeClick,
  showListTitle = false,
}: FilterProps<T>) => {
  const {
    name,
    tooltip,
    searchable,
    config,
    key: filterKey,
    dynamic,
    isDisabled = false,
  } = filter;
  const [open, setOpen] = useState(false);

  const handleReset = resetFilterOptions
    ? (e: Event) => {
        e.preventDefault();
        resetFilterOptions(filterKey);
      }
    : undefined;

  const {
    data: searchData,
    handleChange,
    value,
    onClear,
  } = useFilterOptionSearch({
    filter: filterKey,
    defaultOptions: config.options,
  });

  const data = searchable ? searchData : config.options;

  const onFilterSelect = (props: ToggleFilterProps, active: boolean) => {
    if (active) {
      removeFilterOption(props);

      if (
        multiselect &&
        config.min_select &&
        config.default &&
        filterOptions.length === 1
      ) {
        const defaultOption = data.find(o => o.key === config.default);

        if (defaultOption) {
          addFilterOption({
            filterKey,
            multiselect,
            option: defaultOption,
          });
        }
      }
    } else {
      addFilterOption(props);
    }

    if (useType === 'multi-use') {
      onClear();
    }
  };

  const isChecked = (optKey: string) =>
    filterOptions.some(f => f.key === optKey);

  const handleOutsideClick = () => {
    const onlyChildrenSelected =
      !!filterOptions.length &&
      filterOptions.filter(o => o.childName).length === filterOptions.length;

    if (onlyChildrenSelected) {
      removeFilterOption({
        filterKey,
        option: filterOptions[0],
      });
    }

    onClear();
  };

  const handleRemove = () => {
    onRemove?.({
      filterKey: filter.key,
      filterId: id,
      option: filterOptions[0],
    });
  };

  const handleOpen = () => setOpen(true);

  const filteredOptions = data.map(option => {
    const checked = isChecked(option.key);
    const customHeaderTitle = option?.customHeaderTitle;
    const hasBottomBorder = option?.hasBottomBorder;

    if (multiselect && checked && !value) {
      return null;
    }

    const disabled = !!disabledOptions?.some(o => o.key === option.key);

    return (
      <div key={option.key}>
        {customHeaderTitle && (
          <S.CustomHeaderLabel>{customHeaderTitle}</S.CustomHeaderLabel>
        )}
        <Option
          key={option.key}
          option={option}
          disabled={disabled}
          isChecked={checked && !disabled}
          handleClick={() =>
            onFilterSelect(
              {
                option,
                filterKey,
                filterId: id,
                multiselect,
                dynamic,
              },
              checked
            )
          }
          multiselect={multiselect}
          renderCustomFilterOptionLabel={labels.renderCustomFilterOptionLabel}
        />
        {hasBottomBorder && <S.CustomBottomBorder />}
      </div>
    );
  });

  const showEmpty = !filteredOptions.length && value;
  const isParentOptionActive = isFilterWithChildOptionActive(filterOptions);
  const showResetButton = handleReset && !value && isParentOptionActive;
  const isFilterActive = useType === 'single-use' && isParentOptionActive;
  const isMultiUseSingleFilter = useType === 'single-use' && id !== filter.key;
  const listTitle = searchable ? `${labels.multiselectFeatured}${name}` : name;
  //hide list if there is a search value OR multiselect + ALL items are selected
  const displayList =
    !value && (!multiselect || filterOptions.length !== data.length);

  return (
    <S.DropdownMenuRoot open={open} onOpenChange={setOpen}>
      <TooltipContainer label={tooltip} trigger={[TooltipTrigger.Hover]}>
        <S.Trigger
          $active={isFilterActive}
          data-filter={id}
          data-testid={`${useType}-filter`}
          onClick={handleOpen}
          disabled={isDisabled}
        >
          {(showTriggerLabel || !isFilterActive) && (
            <S.TriggerLabel data-testid="filter-name">{name}</S.TriggerLabel>
          )}
          {isFilterActive && (
            <FilterLabel options={filterOptions} multiselect={multiselect} />
          )}
          <S.TriggerArrow iconName="ChevronLightDown" />
        </S.Trigger>
      </TooltipContainer>
      <Radix.Portal>
        <S.DropdownMenuContent
          align="start"
          sideOffset={5}
          collisionPadding={5}
          onInteractOutside={handleOutsideClick}
        >
          {searchable && (
            <>
              <FilterSearch
                value={value}
                searchPlaceholder={
                  filter.search?.placeholder ?? labels.searchPlaceholder
                }
                onChange={handleChange}
                onClear={onClear}
              />
              {showResetButton && <S.Separator />}
            </>
          )}
          {showResetButton && (
            <S.ResetItem onSelect={handleReset}>
              <S.ResetButton data-testid="reset-label">
                <S.ResetIconWrapper>
                  <S.ResetIcon iconName="Reset" />
                </S.ResetIconWrapper>
                <S.ResetButtonLabel>{labels.resetLabel}</S.ResetButtonLabel>
              </S.ResetButton>
            </S.ResetItem>
          )}

          <S.ScrollableList>
            {multiselect && !!filterOptions.length && !value && (
              <MultiSelectedOptions
                title={labels.selectedLabel}
                options={filterOptions.filter(o => !o.childName)}
                filterKey={filterKey}
                onFilterSelect={onFilterSelect}
                disabledTooltipLabel={labels.disabledTooltipLabel}
                defaultOptionKey={config.default}
                renderCustomFilterOptionLabel={
                  labels.renderCustomFilterOptionLabel
                }
              />
            )}

            {(displayList || value) && <S.Separator />}
            {displayList && (
              <S.DropdownMenuTitle>
                {showListTitle && (
                  <S.DropdownMenuTitleText data-testid="filter-title-text">
                    {listTitle}
                  </S.DropdownMenuTitleText>
                )}
                {config.children?.map(f => (
                  <ChildFilter
                    activeOptions={filterOptions}
                    filter={f}
                    key={f.name}
                    onClick={option =>
                      addFilterOption({
                        option,
                        filterKey,
                        filterId: id,
                      })
                    }
                  />
                ))}
              </S.DropdownMenuTitle>
            )}
            <S.ScrollableList>
              {showEmpty ? (
                <S.NoResults>{labels.noResultsLabel}</S.NoResults>
              ) : (
                filteredOptions
              )}
            </S.ScrollableList>
          </S.ScrollableList>

          {!value && (
            <FilterFooterActions
              filter={filter}
              onRemove={handleRemove}
              onCustomRangeClick={onCustomRangeClick}
              isMultiUseSingleFilter={isMultiUseSingleFilter}
              labels={labels}
            />
          )}
        </S.DropdownMenuContent>
      </Radix.Portal>
    </S.DropdownMenuRoot>
  );
};
