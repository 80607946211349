import {
  Accordion,
  Badge,
  borderRadius,
  CheckBox,
  customScrollMinimal,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export enum FilterGroups {
  Watchlist = 'watchlist',
  Provider = 'provider',
  Industries = 'industries',
  Countries = 'countries',
  AssetClasses = 'assetClasses',
}

export const SideAccordionFiltersRoot = styled.div`
  ${borderRadius.r2};
  border: 1px solid var(--border-soft);
  overflow: hidden;
`;

export const StyledAccordion = styled(Accordion)`
  ${Accordion.Styled.AccordionItem} {
    margin-bottom: 0;
    border-radius: 0;
    background-color: var(--background-default);

    &:not(:first-child) > ${Accordion.Styled.TitleContainer} {
      border-top: 1px solid var(--border-soft);
    }

    &:hover {
      background-color: unset;
    }

    &.option-selected > ${Accordion.Styled.TitleContainer} {
      color: var(--text-default);
    }
  }

  ${Accordion.Styled.TitleContainer} {
    padding: 0;
    color: var(--text-soft);
    ${typographyV2.bodySm};

    &:hover {
      background-color: var(--surface-hover);
    }
  }

  ${Accordion.Styled.Content} {
    padding-top: 6px;
  }

  ${Accordion.Styled.IconStyled} {
    width: 12px;
    height: 12px;
    fill: var(--icon-soft);
  }

  ${Accordion.Styled.TitleContainer} {
    padding: 6px 8px;
  }

  .accordion-expanded {
    ${Accordion.Styled.TitleContainer} {
      background-color: var(--surface-active);
    }
  }
`;

export const AccordionContentWrapper = styled.div`
  max-height: 160px;
  overflow-y: scroll;
  ${customScrollMinimal};

  > :not(:first-child) {
    margin-left: 8px;
  }
`;

export const StyledCheckbox = styled(CheckBox)`
  ${typographyV2.bodySm};

  ${CheckBox.Styled.Checkbox} {
    align-items: center;
  }

  ${CheckBox.Styled.CheckboxLabel} {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${CheckBox.Styled.Checkmark} {
    width: 14px;
    height: 14px;
  }
`;

export const StyledBadge = styled(Badge)`
  ${Badge.Styled.BadgeNumerical} {
    ${typographyV2.bodyXs};
    background-color: var(--surface-primary);
    color: var(--text-on-primary);
  }
`;

export const SingleSelectedItem = styled.span`
  ${typographyV2.bodySm};
  color: var(--text-primary);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
`;
