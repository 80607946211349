import { SkeletonLoader } from '@toggle/design-system';
import styled from 'styled-components';

export const OverviewKeyValuesRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const KeyValuesList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 50px;
  row-gap: 20px;
`;

export const KeyValueSkeletonLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        height: '164px',
        borderRadius: '8px',
      },
    },
  ],
})``;
