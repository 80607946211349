import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClearAllButton } from '~/views/scenario/header/clear-all-button/ClearAllButton';

import * as Shared from '../Shared.styles';
import * as S from './ScenarioNoFilteredAssets.styles';

export const ScenarioNoFilteredAssets = () => {
  const { t } = useTranslation('screener');

  return (
    <Shared.Container data-testid="scenario-no-assets">
      <Shared.Img src="/app/static/images/icons/no-assets-found.svg" alt="" />
      <S.Description>
        <Shared.Title>{t('screener:emptyTitle')}</Shared.Title>
        <Shared.Message>{t('screener:emptyMessage')}</Shared.Message>
      </S.Description>
      <ClearAllButton />
    </Shared.Container>
  );
};
