import React, { FC, HTMLAttributes } from 'react';

import * as S from './SplashLoader.styles';

const SCENARIO_LAST_LABEL_MULTIPLIER = 3;

interface StyledComponent<P, S> extends FC<P> {
  Styled: S;
}

interface SplashLoaderProps extends HTMLAttributes<HTMLDivElement> {
  labels: string[];
  duration?: number;
  isScenario?: boolean;
}

export const SplashLoader: StyledComponent<SplashLoaderProps, typeof S> = ({
  labels,
  duration = 2_000,
  isScenario,
  ...rest
}) => {
  const items = labels.map((label, idx) => ({
    label,
    animationDuration: `${
      isScenario && idx === labels.length - 1
        ? duration * SCENARIO_LAST_LABEL_MULTIPLIER
        : duration
    }ms`,
    animationDelay: `${idx * duration}ms`,
  }));

  return (
    <S.Container {...rest} data-testid="splash-loader">
      {items.map((item, idx) => (
        <S.LoaderItem key={item.label}>
          <S.Label>{item.label}</S.Label>
          <S.StyledProgressBar
            animationDelay={item.animationDelay}
            animationDuration={item.animationDuration}
            $isLast={idx === items.length - 1}
          />
        </S.LoaderItem>
      ))}
    </S.Container>
  );
};

SplashLoader.Styled = S;
