import { SortOption } from '@toggle/toggle';

import {
  FilterState,
  NewsSearchFilterGroupKeys,
  SharedFilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { NewsFilter } from '~/services/news/news.types';
import { fetchProvidersNewsFilter } from '~/services/news/news-service';
import { create } from '~/stores/create-store/createStore';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { toggleFilterOption } from '~/views/news/utils/toggle-filter-option/toggleFilterOption';

import {
  createAccordionFilters,
  createScreenerFilters,
} from '../../utils/news-filters-utils/news-filters-utils';

export type SortType = 'most-relevant' | 'newest' | 'oldest';

const sortOption: Record<SortType, SortOption> = {
  'most-relevant': {
    field: 'score',
    desc: true,
  },
  newest: {
    field: 'published',
    desc: true,
  },
  oldest: {
    field: 'published',
    desc: false,
  },
};

interface NewsSearchStore extends SharedFilterState<NewsSearchFilterGroupKeys> {
  accordionFilters: NewsFilter[];
  clearActiveFilters: () => void;
  clearSearch: () => void;
  initStore: () => Promise<void>;
  inputValue: string;
  isInputExpanded: boolean;
  getActiveFiltersCount: () => number;
  getActiveAccordionFiltersCount: () => number;
  mostReadOnly?: boolean;
  setFilterState: (filterState: Partial<FilterState>) => void;
  setInputValue: (value: string) => void;
  setInputExpanded: (isInputExpanded: boolean) => void;
  sortType: SortType;
  sortBy?: SortOption;
  setAccordionFilters: (accordionFilters: NewsFilter[]) => void;
  setSortBy: (sortType: SortType) => void;
  toggleFilterOption: (filterKey: string, optionKey?: string) => void;
}

export const useNewsSearchStore = create<NewsSearchStore>((set, get) => ({
  filterGroups: {
    NEWS_SEARCH_FILTERS: {
      activeFilterOptions: [],
      activeFilters: [],
      allFilters: [],
    },
  },
  accordionFilters: [],

  initStore: async () => {
    const { providersNewsFilter, otherFilters } =
      await fetchProvidersNewsFilter();

    const accordionFilters = createAccordionFilters(
      otherFilters,
      useWatchlist.getState().lists
    );

    set({
      accordionFilters,
    });

    if (providersNewsFilter) {
      const screenerFilters = createScreenerFilters([providersNewsFilter]);

      get().setFilterState({
        activeFilters: screenerFilters,
      });
    }
  },

  setFilterState: filterState => {
    set(prevState => ({
      filterGroups: {
        NEWS_SEARCH_FILTERS: {
          ...prevState.filterGroups.NEWS_SEARCH_FILTERS,
          ...filterState,
        },
      },
    }));
  },

  clearActiveFilters: () => {
    get().setFilterState({
      activeFilterOptions: [],
    });
    get().initStore();
  },
  getActiveAccordionFiltersCount: () =>
    get().accordionFilters.reduce((counter, filter) => {
      if (filter.options.some(option => option.isChecked)) {
        counter++;
      }

      return counter;
    }, 0),

  getActiveFiltersCount: () =>
    get().filterGroups.NEWS_SEARCH_FILTERS.activeFilterOptions.length,
  inputValue: '',
  setInputValue: inputValue => set({ inputValue }),
  clearSearch: () => set({ inputValue: '' }),
  isInputExpanded: false,
  setInputExpanded: isInputExpanded => set({ isInputExpanded }),
  sortType: 'most-relevant',
  setAccordionFilters: accordionFilters => set({ accordionFilters }),
  setSortBy: sortType => {
    const sortBy = sortOption[sortType];

    set({ sortBy, sortType });
  },
  toggleFilterOption: (filterKey, optionKey) => {
    set(({ accordionFilters }) => ({
      accordionFilters: toggleFilterOption({
        filters: accordionFilters,
        filterKey,
        optionKey,
      }),
    }));
  },
}));
