import { Property } from 'csstype';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon/Icon';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { FixedTableCell } from '../Table.styles';

interface TableHeaderStyleProps {
  $alignment?: Property.TextAlign;
  $isSorted?: boolean;
  $isSortable?: boolean;
  $fixed?: boolean;
  $isHoverEnabled: boolean;
  $isLeftOver: boolean;
  $isRightOver: boolean;
}

export const FilterIcon = styled(Icon)<{ $isSorted?: boolean }>`
  fill: ${({ $isSorted }) =>
    $isSorted ? 'var(--text-primary)' : 'var(--text-soft)'};
`;

export const TableHeader = styled.th<TableHeaderStyleProps>`
  background-color: var(--background-default);
  border-bottom: 1px solid var(--border-soft);
  padding: 12px;
  text-align: ${({ $alignment }) => $alignment};
  white-space: nowrap;
  color: var(--text-soft);
  ${typographyV2.bodyXs};
  vertical-align: middle;
  ${({ $isSortable }) =>
    $isSortable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};

  ${({ $isLeftOver }) =>
    $isLeftOver &&
    css`
      border-left: 2px solid var(--primary-color);
    `};
  ${({ $isRightOver }) =>
    $isRightOver &&
    css`
      border-right: 2px solid var(--primary-color);
    `};

  ${({ $fixed }) => $fixed && FixedTableCell};

  ${({ $isHoverEnabled }) =>
    $isHoverEnabled &&
    css`
      &:hover {
        background-color: var(--surface-hover);
      }
    `};
`;

export const Label = styled.span<{ $alignment?: Property.TextAlign }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: ${({ $alignment = 'center' }) => {
    switch ($alignment) {
      case 'left':
        return 'start';
      case 'right':
        return 'end';
      default:
        return $alignment;
    }
  }};
`;
