import { Alert, Modal, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledModal = styled(Modal)<{ $customWidth?: string }>`
  ${Modal.Styled.ModalContainer} {
    max-width: 80%;
    width: ${({ $customWidth }) => {
      return $customWidth || '540px';
    }};
  }

  ${Alert.Styled.Alert} {
    width: 100%;
    padding: 8px 16px;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${typographyV2.bodyBase};
`;
