import { deviceConstraints, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: var(--surface-default);
  padding: 80px 12px 40px 12px;
  margin: 0 16px;
  text-align: center;

  @media ${deviceConstraints.desktop} {
    margin: 0;
    padding: 80px 40px 40px 40px;
  }
`;

export const Title = styled.p`
  ${typographyV2.titleXL};
`;

export const Description = styled.p`
  ${typographyV2.bodyBase};
`;

export const Mobile = styled.img`
  position: absolute;
  top: -120px;
`;
