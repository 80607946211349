import {
  Button,
  DropdownListItem,
  DropdownProps,
  Icon,
} from '@toggle/design-system';
import { Entity } from '@toggle/toggle';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetLogoContainer } from '~/components/asset-logo-container/AssetLogoContainer';

import * as S from './CompareAssetDropdown.styles';

export interface CompareAssetDropdownProps {
  entities: Entity[];
  onSelectAsset: (entity: Entity) => void;
}

export const CompareAssetDropdown: FC<CompareAssetDropdownProps> = ({
  entities,
  onSelectAsset,
}) => {
  const { t } = useTranslation('widget');
  const [value, setValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const hideDropdown = () => {
    setValue('');
    setShowDropdown(false);
  };

  const selectItem: DropdownProps<Entity>['selectItem'] = entity => {
    hideDropdown();
    onSelectAsset(entity);
  };

  const filteredEntities = value
    ? entities.filter(entity =>
        entity.name_short.toLowerCase().includes(value.toLowerCase())
      )
    : entities;

  return (
    <S.Container>
      <Button
        label={t('widget:assetOverview.relatedCompany')}
        onClick={() => setShowDropdown(true)}
        variant="tertiary"
        size="small"
        data-testid="compare-asset-button"
      />
      {showDropdown && (
        <S.StyledDropdown<FC<DropdownProps<Entity>>>
          dropdownItems={filteredEntities}
          enableFloating={false}
          renderDropdownItem={(entity, isActive, isFocused, isDisabled) => (
            <DropdownListItem
              label={entity.name_short}
              leadingIcon={<AssetLogoContainer entity={entity} size={20} />}
              isActive={isActive}
              isFocused={isFocused}
              isDisabled={isDisabled}
            />
          )}
          onOutsideClick={hideDropdown}
          selectItem={selectItem}
        >
          <S.SearchInput
            size="medium"
            leftIcon={<Icon iconName="Search" size={20} />}
            onChange={onChange}
            value={value}
          />
        </S.StyledDropdown>
      )}
    </S.Container>
  );
};
