import { flexRender, Header } from '@tanstack/react-table';
import {
  TableHeaderIcons,
  Tooltip,
  TooltipTrigger,
} from '@toggle/design-system';
import React, { useState } from 'react';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { TableAction } from '~/views/screener/results/screener-table-utils';
import { TableDropdownMenu } from '~/views/screener/results/table-dropdown-menu/TableDropdownMenu';

import * as S from './SortableTableHeaderCell.styles';

export interface SortableTableHeaderCellProps {
  enabledKeys?: TableAction[];
  activeFilterOptions?: ActiveFilterOptions[];
  enableDropdown?: boolean;
  handleAction: (action: TableAction) => void;
  header: Header<any, unknown>;
  alignment?: 'start' | 'center' | 'end';
}

export const SortableTableHeaderCell = ({
  enabledKeys = [],
  activeFilterOptions = [],
  enableDropdown = true,
  handleAction,
  header,
  alignment: initialAlignment,
}: SortableTableHeaderCellProps) => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const isFiltered = header.column.getIsFiltered();
  const headerFilter = activeFilterOptions.find(f => f.filter === header.id);

  const columnMeta = header.column.columnDef.meta ?? {};
  const alignment = initialAlignment ?? columnMeta?.alignment;

  if (enableDropdown) {
    const sortOrder = header.column.getIsSorted();

    return (
      <TableDropdownMenu
        onOpenChange={setIsDropdownMenuOpen}
        key={header.id}
        handleAction={handleAction}
        alignment={alignment}
        enabledKeys={enabledKeys}
        open={isDropdownMenuOpen}
        activeFilterDescription={
          headerFilter?.options[0].description ?? headerFilter?.options[0].name
        }
        renderTriggerNode={() => (
          <Tooltip
            inPortal
            label={columnMeta.tooltipLabel}
            trigger={TooltipTrigger.Hover}
            disabled={!columnMeta.tooltipLabel}
          >
            <S.StyledTableHeader
              data-testid="table-header-sortable"
              key={header.id}
              colSpan={header.colSpan}
              alignment={alignment}
              $isOver={isDropdownMenuOpen}
              $isHoverEnabled
              $hasTooltip={!!columnMeta?.tooltipLabel}
              onClick={() => {
                setIsDropdownMenuOpen(true);
              }}
            >
              <TableHeaderIcons
                alignment={alignment}
                isFiltered={isFiltered}
                sortDirection={sortOrder}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableHeaderIcons>
            </S.StyledTableHeader>
          </Tooltip>
        )}
      />
    );
  }

  return (
    <S.StyledTableHeader
      key={header.id}
      colSpan={header.colSpan}
      alignment={alignment}
      isHoverEnabled={false}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </S.StyledTableHeader>
  );
};

SortableTableHeaderCell.Styled = S;
