import { useLocation } from 'react-router';

import { appPaths, queryKeys } from '~/routes/app-paths';
import {
  createInsightMessage,
  CreateInsightMessageProps,
} from '~/services/create-insight-message/createInsightMessage';

import { useNavigateAlfred } from '../use-navigate-alfred/useNavigateAlfred';

export const useNavigateToInsight = () => {
  const location = useLocation();
  const navigateToAlfred = useNavigateAlfred();

  const goToInsight = ({
    insightId,
    title,
    name,
  }: CreateInsightMessageProps) => {
    if (location.pathname === appPaths.alfred) {
      createInsightMessage({
        insightId,
        title,
        name,
      });
    } else {
      const searchParams = new URLSearchParams();
      searchParams.append(queryKeys.insightId, insightId);

      if (title) {
        searchParams.append(queryKeys.insightTitle, title);
      }

      if (name) {
        searchParams.append(queryKeys.insightName, name);
      }

      navigateToAlfred('intel', searchParams);
    }
  };

  return goToInsight;
};
