import { getCurrencySymbol } from '@toggle/helpers';
import {
  AssetSubClassName,
  AssetSubClassNames,
  DisplayFormat,
} from '@toggle/toggle';

const DEFAULT_USER_LOCALE = 'en-US';
export const SIGNIFICANT_DIGITS_COUNT = 4;

export const priceDecimals = (entitySubClass: AssetSubClassNames) => {
  return (price: number) => {
    if (
      (entitySubClass === AssetSubClassName.GenericCross ||
        entitySubClass === AssetSubClassName.MajorCross) &&
      price <= 100
    ) {
      return 4;
    } else {
      if (price >= 5) {
        return 2;
      }

      if (price === 0) {
        return 2;
      }

      if (price < 5) {
        return 4;
      }
    }

    return 2;
  };
};

export const priceSuffix = (
  displayFormat?: DisplayFormat | '',
  currency = ''
): string => {
  switch (displayFormat) {
    case DisplayFormat.CURRENCY:
      return currency;
    case DisplayFormat.NUMBER:
      return '';
    case DisplayFormat.PERCENT:
      return '%';
    default:
      return '';
  }
};

export const mapDisplayFormatToUnit = (
  displayFormat?: DisplayFormat | '',
  currency = ''
) => {
  const suffix = priceSuffix(displayFormat, currency);

  if (displayFormat === DisplayFormat.CURRENCY) {
    return getCurrencySymbol(suffix);
  }

  return suffix;
};

export const formatAssetPrice = (
  price: number,
  currency?: string,
  decimals = 2,
  locale = DEFAULT_USER_LOCALE,
  shouldShowCurrency = true
) => {
  try {
    const significantNumberRule = price !== 0 && Math.abs(price) < 1;
    return price
      .toLocaleString(locale, {
        style: currency && shouldShowCurrency ? 'currency' : 'decimal',
        currency,
        minimumFractionDigits: significantNumberRule ? undefined : decimals,
        maximumFractionDigits: significantNumberRule ? undefined : decimals,
        maximumSignificantDigits: significantNumberRule
          ? SIGNIFICANT_DIGITS_COUNT
          : undefined,
      })
      .replace('\u00A0', '');
  } catch {
    return price.toFixed(decimals);
  }
};

export const formatPriceWithCurrency = (
  value: number,
  currency: string,
  decimals = 2,
  locale = DEFAULT_USER_LOCALE
) => {
  try {
    return value
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimals,
      })
      .replace('\u00A0', '');
  } catch {
    return value.toFixed(decimals);
  }
};

export const formatPriceWithCurrencyCode = (
  value: number,
  currency: string,
  isCrypto = false,
  decimals = 2,
  locale = DEFAULT_USER_LOCALE
) => {
  try {
    return value.toLocaleString(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
      minimumFractionDigits: isCrypto ? undefined : decimals,
      maximumFractionDigits: isCrypto ? SIGNIFICANT_DIGITS_COUNT : decimals,
    });
  } catch {
    return value.toFixed(decimals);
  }
};

// Docs: https://www.notion.so/toggle/Formatting-for-asset-prices-PnL-and-Positions-302d617750834560ba0a781513a86a3a
export const formatPortfolioPrice = (price: number, currency: string) => {
  let digits: Partial<Intl.NumberFormatOptions> = {
    minimumFractionDigits: 2,
  };

  if (price < 1 && price > -1) {
    digits = {
      maximumSignificantDigits: 4,
      minimumSignificantDigits: 4,
    };
  }

  if ((price < 5 && price >= 1) || (price > -5 && price <= -1)) {
    digits = {
      minimumFractionDigits: 4,
    };
  }

  try {
    return price
      .toLocaleString(DEFAULT_USER_LOCALE, {
        style: 'currency',
        currency,
        ...digits,
      })
      .replace('\u00A0', '');
  } catch {
    return price.toFixed(2);
  }
};
