import {
  DropdownEntry,
  DropdownMenu,
  DropdownMenuProps,
  DropdownRadioGroup,
} from '@toggle/design-system';
import { groupBy } from '@toggle/helpers';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  defaultDropdownKeys,
  menuItems,
  navigableDropdownOptions,
  TableAction,
} from '../screener-table-utils';
import * as S from './TableDropdownMenu.styles';

export type TableDropdownMenuProps = {
  alignment?: 'start' | 'center' | 'end';
  handleAction: (action: TableAction) => void;
  disabledKeys?: TableAction[];
  enabledKeys?: TableAction[];
  selectedKey?: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  filterType?: 'screening' | 'data' | 'default';
  activeFilterDescription?: string;
  renderTriggerNode: (hasNavigableOption: boolean) => ReactNode;
  triggerProps?: DropdownMenuProps['triggerProps'];
};

export const TableDropdownMenu = ({
  alignment = 'end',
  handleAction,
  disabledKeys = [],
  enabledKeys = defaultDropdownKeys,
  onOpenChange,
  open,
  renderTriggerNode,
  filterType = 'default',
  activeFilterDescription,
  selectedKey,
  triggerProps,
}: TableDropdownMenuProps) => {
  const { t } = useTranslation(['common']);

  const mappedItems = menuItems
    .filter(item => enabledKeys.includes(item.key))
    .filter(item => !disabledKeys.includes(item.key))
    .map(item => ({
      ...item,
      /* i18next-extract-mark-context-next-line ["ascending", "descending", "filter", "pin", "unpin", "left", "right", "start", "end", "reset", "clearFilter", "editFilter", "themeOverview"] */
      label: (
        <S.DropdownLabelContainer>
          {t('common:table.dropdown', {
            context: item.key,
            /* i18next-extract-mark-context-next-line ["data", "screening", "default"] */
            filterType: t('common:table.filterType', {
              context: filterType,
            }),
          })}
          <S.DropdownLabelSubtitle>
            {item.key === 'editFilter' && activeFilterDescription}
          </S.DropdownLabelSubtitle>
        </S.DropdownLabelContainer>
      ),
      onClick: () => handleAction(item.key),
    }));

  const hasNavigableOption = mappedItems.some(item =>
    navigableDropdownOptions.includes(item.key)
  );

  const groups: DropdownEntry[][] = Object.values(
    groupBy(mappedItems, item => item.group)
  );

  return (
    <DropdownMenu
      align={alignment}
      onOpenChange={onOpenChange}
      open={open}
      triggerNode={renderTriggerNode(hasNavigableOption)}
      triggerProps={triggerProps}
    >
      {groups.map((group, index) => (
        <DropdownRadioGroup
          key={index}
          items={group}
          selectedKey={selectedKey}
        />
      ))}
    </DropdownMenu>
  );
};
