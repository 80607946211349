import { useQuery } from '@tanstack/react-query';
import {
  financialStatements,
  FinancialStatementSchema,
  FinancialStatementType,
  TimeFrame,
} from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

export const QUERY_KEY_ASSET_FINANCIAL = 'QUERY_KEY_ASSET_FINANCIAL';

type UseFinancialStatementProps = {
  entityTag: string;
  type: FinancialStatementType;
  timeframe: TimeFrame;
};

type GetAssetFinancialStatementProps = UseFinancialStatementProps & {
  signal?: AbortSignal;
};

export const getAssetFinancialStatement = ({
  entityTag,
  type,
  timeframe,
  signal,
}: GetAssetFinancialStatementProps): Promise<FinancialStatementSchema> => {
  const { path, schema } = financialStatements[type];

  return wretchRequest<FinancialStatementSchema>(path(entityTag, timeframe), {
    schema: schema,
    options: { signal },
  });
};

export const useFinancialStatement = ({
  entityTag,
  type,
  timeframe,
}: UseFinancialStatementProps) =>
  useQuery({
    queryKey: [QUERY_KEY_ASSET_FINANCIAL, entityTag, type, timeframe],
    queryFn: ({ signal }) =>
      getAssetFinancialStatement({
        entityTag,
        type,
        timeframe,
        signal,
      }),
    enabled: !!entityTag,
  });
