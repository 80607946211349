import { Input } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledInput = styled(Input)<{ $textAlign: string }>`
  width: 0;
  flex-grow: 1;

  ${Input.Styles.InputElement} {
    width: 0;
    text-align: ${({ $textAlign }) => $textAlign};
  }

  ${Input.Styles.LeftIcon} {
    color: var(--text-soft);
  }
  ${Input.Styles.RightIcon} {
    color: var(--text-soft);
  }
`;
