import { ChartAssetData } from '@toggle/chart';

export enum ChartSearchParams {
  Asset = 'asset',
  Resample = 'resample',
  Horizon = 'horizon',
  Domain = 'domain',
  Hidden = 'hidden',
  Primary = 'primary',
}

export type ChartSearchParamsAssetValue = [
  tag: string,
  snake: string,
  paneIndex?: number
];

export const appendChartAssetParam = (
  urlSearchParams: URLSearchParams,
  value: ChartSearchParamsAssetValue
) => {
  urlSearchParams.append(ChartSearchParams.Asset, value.join(';'));
};

export const setPrimarySnakeParam = (
  urlSearchParams: URLSearchParams,
  primaryAsset: ChartAssetData
) => {
  urlSearchParams.set(
    ChartSearchParams.Primary,
    primaryAsset.entity.default_snake
  );
};
