import { StrictUnion } from '@toggle/helpers';
import { Entity, MethodSubClass, SnakeMeta } from '@toggle/toggle';

export interface ChipFilteredData {
  name: string;
  value: string;
  data: SnakeMeta[];
}

export interface EntityWithListTypes extends Entity {
  searchListType: SearchListTypes;
}

export interface ThemeWithListTypes extends Theme {
  searchListType: SearchListTypes;
}

export type EntityOrThemeWithListTypes =
  | EntityWithListTypes
  | ThemeWithListTypes;

export interface EntityWithIndicator {
  entity: EntityWithListTypes;
  snakeMeta: SnakeMeta;
  searchListType: SearchListTypes;
}

export interface Theme {
  name: string;
  id: string;
  logo_url?: string;
  isTheme: boolean;
}

export interface TitleItem {
  title?: string;
}
export interface ListItemData extends TitleItem {
  data: (Entity | EntityWithIndicator | Theme)[];
  searchListType: SearchListTypes;
  isVisible: boolean;
}

export type SearchListTypes = 'open' | 'recent' | 'suggestions' | 'result';
export type ChipType = 'fundamentals' | 'technicals' | 'macro' | 'tli';
export const ENTITY_CHIP_ID = 'entity-chip';

export type Item = StrictUnion<
  EntityOrThemeWithListTypes | TitleItem | EntityWithIndicator | SnakeMeta
>;

export const isThemeItem = (item: Item): item is ThemeWithListTypes => {
  return !!item.isTheme;
};

export const isEntityItem = (item: Item) => !!item.default_snake;

export const containsEntity = (list: Entity[], entity: Entity) =>
  list.some(item => item.default_snake === entity.default_snake);

export const filterItemsByActive = (
  itemsToFilter: Entity[],
  activeItems: Entity[]
) => itemsToFilter.filter(item => !containsEntity(activeItems, item));

export const containsIndicator = (list: Entity[], indicator: SnakeMeta) =>
  list.some(item => item.default_snake === indicator.snake);

export const filterByMethod = (data: SnakeMeta[], methods: MethodSubClass[]) =>
  data.filter(item => methods.includes(item.method_sub_class));

export const addTitleAndSearchType = (item: ListItemData) => {
  return item.title
    ? [
        {
          title: item.title,
          disabled: true,
        },
        ...item.data.map(f => ({
          ...f,
          searchListType: item.searchListType,
        })),
      ]
    : item.data.map(f => ({ ...f, searchListType: item.searchListType }));
};

export const getChipsVisibility = ({
  isInAllChip,
  isSnakesLoading,
  searchQuery,
}: {
  isInAllChip: boolean;
  isSnakesLoading: boolean;
  searchQuery: string;
}) => !isSnakesLoading && !searchQuery && !isInAllChip;

interface FilterSnakesProps {
  snakeItems: SnakeMeta[];
  searchQuery: string;
}

export const getFilteredSnakes = ({
  snakeItems,
  searchQuery,
}: FilterSnakesProps) => {
  return snakeItems.filter(
    item =>
      item.name.english.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.search.keywords?.join(' ').includes(searchQuery.toLowerCase())
  );
};
