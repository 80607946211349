import { ChartAssetData } from '@toggle/chart';
import { Button, Icon } from '@toggle/design-system';
import { isSinglePriceEntity } from '@toggle/helpers';
import { formatTicker } from '@toggle/toggle';
import { TFunction } from 'i18next';
import React from 'react';

import { TooltipContainer } from '~/components/tooltip-container/TooltipContainer';
import {
  ChartNavigateConfigParams,
  useChartNavigate,
} from '~/hooks/use-chart-navigate/useChartNavigate';
import { ChartSearchParamsAssetValue } from '~/utils/chart-search-utils/chart-search-utils';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';

import * as S from './ChartMessageHeader.styles';

export interface ChartMessageHeaderProps {
  chartData: ChartAssetData[];
  t: TFunction;
  showChartArea: boolean;
  chartConfig?: ChartNavigateConfigParams;
}

export const ChartMessageHeader = ({
  chartData,
  t,
  showChartArea,
  chartConfig,
}: ChartMessageHeaderProps) => {
  const { colors } = useChartTheme();
  const chartNavigate = useChartNavigate();

  if (!showChartArea) {
    return null;
  }

  const navigateToChart = () => {
    const assets = chartData.map(
      asset =>
        [
          asset.entity.tag,
          asset.entity.default_snake,
        ] as ChartSearchParamsAssetValue
    );

    chartNavigate(assets, chartConfig);
  };

  return (
    <S.Container data-testid="chart-message-header">
      <S.LabelRoot>
        {chartData.map((c, i) => {
          const { entity, snakeMeta } = c;
          return (
            <TooltipContainer key={i} label={entity.name} inPortal>
              <S.LabelContainer>
                <Icon
                  iconName="Dot"
                  size={20}
                  fillColor={colors.getThemeColor(c.lineColorToken)}
                />
                <S.Label>
                  {!isSinglePriceEntity(entity)
                    ? formatTicker(entity)
                    : `${formatTicker(entity)} ${
                        snakeMeta?.name.english ?? ''
                      }`}
                </S.Label>
              </S.LabelContainer>
            </TooltipContainer>
          );
        })}
      </S.LabelRoot>
      <div>
        <Button
          variant="secondary"
          iconName="Expand"
          label={t('chart:fullscreen')}
          data-testid="open-full-chart"
          onClick={navigateToChart}
        />
      </div>
    </S.Container>
  );
};
