import React, { ReactNode } from 'react';

import { SkeletonLoader } from '~/components/skeleton-loader/SkeletonLoader';
import { TableCell } from '~/components/table';

import { SmartTableCellProps } from '../SmartTable.types';
import * as S from './SmartTableCell.styles';

const isNumber = (data: unknown) => typeof data === 'number';

export const SmartTableCell = <T extends {}>({
  item,
  column,
  alignment,
  hasScrollbar,
  ...rest
}: SmartTableCellProps<T>) => {
  let content;

  if (item.isLoading) {
    content = (
      <SkeletonLoader
        areas={[
          {
            styles: {
              width: '100%',
              height: '20px',
              borderRadius: '8px',
            },
          },
        ]}
      />
    );
  } else {
    content = column.render
      ? column.render(item[column.key], item)
      : item[column.key];
  }

  return column.sticky ? (
    <S.StickyTableCell
      alignment={alignment}
      key={column.key}
      $displayBorder={hasScrollbar}
      isNumber={isNumber(content)}
    >
      {content as ReactNode}
    </S.StickyTableCell>
  ) : (
    <TableCell
      key={column.key}
      alignment={alignment}
      isNumber={isNumber(content)}
      {...rest}
    >
      {content as ReactNode}
    </TableCell>
  );
};
