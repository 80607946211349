import { ErrorButton } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { NoDataFound } from '~/components/no-data-found/NoDataFound';
import { useNewsSearchStore } from '~/views/news/stores/use-news-search-store/useNewsSearchStore';

import { ClearFiltersButton } from '../clear-filters-button/ClearFiltersButton';
import * as S from './SearchEmptyStates.styles';

interface SearchEmptyStatesProps {
  buttonProps?: ErrorButton;
}

export const SearchEmptyStates = ({ buttonProps }: SearchEmptyStatesProps) => {
  const { t } = useTranslation('news');

  const [, setSearchParams] = useSearchParams();

  const { clearActiveFilters, clearSearch } = useNewsSearchStore(state => ({
    clearSearch: state.clearSearch,
    clearActiveFilters: state.clearActiveFilters,
  }));

  const handleClick = () => {
    clearActiveFilters();
    clearSearch();
    setSearchParams({});
  };

  return (
    <NoDataFound
      data-testid="search-empty-states"
      bannerPath="/app/static/images/empty-state/no-news.svg"
      title={t('news:emptySearch.title')}
      description={t('news:emptySearch.description')}
    >
      <S.ButtonWrapper>
        <ClearFiltersButton
          label={t('news:emptySearch.buttonLabel')}
          onClick={handleClick}
          {...buttonProps}
        />
      </S.ButtonWrapper>
    </NoDataFound>
  );
};
