import * as d3 from 'd3';
import React, { useState } from 'react';

import { getHumanFriendlyTicks } from '~/charts/helpers/chart-ticks/chart-ticks';
import { Button } from '~/components/button/Button';

import { Axis } from './axis/Axis';
import {
  BAR_CHART_WIDTH,
  BAR_HEIGHT,
  CHART_WIDTH,
  LABEL_HEIGHT,
  margin,
  MAX_DISPLAY_ITEMS,
  PORTFOLIO_ITEM_LEFT_PADDING,
  ROW_HEIGHT,
} from './constants';
import { ChartHeader } from './header/ChartHeader';
import * as S from './HorizontalBarChart.styles';
import { ChartItem } from './item/ChartItem';
import { assureZeroInDomain, formatPortfolioData } from './utils';

export interface MappedDominoV2 {
  label: string;
  tooltipLabel: string;
  tag?: string;
  median: number;
}
export interface HorizontalBarChartPropsV2 {
  data: MappedDominoV2[];
  headerData?: MappedDominoV2[];
  headerTitle?: string;
  headerLabel?: string;
  medianLabel: string;
  viewAllText: string;
  viewLessText: string;
  onChartItemClick?: (mappedDomino: MappedDominoV2) => void;
  useOriginalValue?: boolean;
  prefix?: string;
}

export const HorizontalBarChartV2 = ({
  data,
  headerData = [],
  headerTitle,
  headerLabel,
  medianLabel,
  viewAllText,
  viewLessText,
  onChartItemClick,
  useOriginalValue = false,
  prefix = '+',
}: HorizontalBarChartPropsV2) => {
  const [expanded, setExpanded] = useState(false);
  const onViewAllHandle = () => setExpanded(true);
  const hasMaximum = data.length > MAX_DISPLAY_ITEMS;
  const values = data.map(i => i.median);
  const headerValues = headerData.map(i => i.median);
  const TOTAL_HEADER_HEIGHT = headerData.length * ROW_HEIGHT;

  const HEADER_TITLE_HEIGHT = headerTitle ? ROW_HEIGHT : 0;
  const portfolioData = formatPortfolioData({ data, hasMaximum, expanded });
  const viewAllButtonHeight = hasMaximum ? ROW_HEIGHT : 0;
  const height =
    (portfolioData.length + headerData.length) * ROW_HEIGHT +
    LABEL_HEIGHT * 2 +
    margin.top +
    margin.bottom +
    viewAllButtonHeight +
    HEADER_TITLE_HEIGHT;
  const minValue = Math.min(...values, ...headerValues);
  const maxValue = Math.max(...values, ...headerValues);

  const [adjustedMin, adjustMax] = assureZeroInDomain([minValue, maxValue]);

  const { maxRounded, minRounded, chartTicks } = getHumanFriendlyTicks({
    min: adjustedMin,
    max: adjustMax,
  });

  const verticalCoordinateOfContent =
    margin.top + ROW_HEIGHT + TOTAL_HEADER_HEIGHT;

  const assetRowListHeight = portfolioData.length * ROW_HEIGHT;

  const axisHeight =
    verticalCoordinateOfContent +
    assetRowListHeight +
    margin.bottom -
    (expanded ? ROW_HEIGHT : 0);

  const xScale = d3
    .scaleLinear()
    .domain([minRounded, maxRounded])
    .range([margin.left, BAR_CHART_WIDTH]);

  const yScale = d3
    .scaleBand()
    .domain(portfolioData.map(d => d.label))
    .range([ROW_HEIGHT, axisHeight]);

  return (
    <S.RootSVG width={CHART_WIDTH} height={height} data-testid="high-low-chart">
      <ChartHeader
        headerData={headerData}
        headerTitle={headerTitle}
        xScale={xScale}
        yScale={yScale}
        prefix={prefix}
      />
      <Axis
        transform={`translate(0, ${margin.top + HEADER_TITLE_HEIGHT})`}
        headerX={CHART_WIDTH - margin.right}
        headerLabel={medianLabel}
        xScale={xScale}
        tickValues={chartTicks}
        height={axisHeight}
        useOriginalValue={useOriginalValue}
        prefix={prefix}
      />

      <g
        data-testid="chart-bars-root"
        width={CHART_WIDTH}
        transform={`translate(0, ${HEADER_TITLE_HEIGHT})`}
      >
        <S.LabelHeader
          x={margin.left + PORTFOLIO_ITEM_LEFT_PADDING}
          y={BAR_HEIGHT}
        >
          {headerLabel}
        </S.LabelHeader>

        {portfolioData.map((d, i) => (
          <ChartItem
            key={d.tag || i}
            data={d}
            index={i}
            hasMaximum={hasMaximum && !expanded}
            xScale={xScale}
            yScale={yScale}
            viewAllText={viewAllText}
            onViewAllHandle={onViewAllHandle}
            onItemClick={onChartItemClick}
            useOriginalValue={useOriginalValue}
            prefix={prefix}
          />
        ))}
      </g>

      {expanded && (
        <foreignObject
          y={verticalCoordinateOfContent + assetRowListHeight}
          width="200"
          height="36"
        >
          <Button
            data-testid="view-less-btn"
            label={viewLessText}
            variant="empty"
            size="xsmall"
            onClick={() => setExpanded(false)}
          />
        </foreignObject>
      )}
    </S.RootSVG>
  );
};
