import React, { ReactElement, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  createRoutesFromElements,
  NonIndexRouteObject,
  Route,
  RouterProvider,
} from 'react-router';

import { ChatInputProps } from '~/components/chat';
import { ProtectedView } from '~/components/protected-view/ProtectedView';
import { Root } from '~/Root';
import { Tracking } from '~/services/tracking';
import { FeatureFlag } from '~/stores/use-flags/useFlags';
import { ServerError } from '~/views/error';
import { NotFound } from '~/views/error/not-found/NotFound';

import { appPaths } from './app-paths';
import {
  authRoutes,
  commonRoutes,
  featureRoutes,
  nonBasicRoutes,
  routes,
} from './AppRoutes';

export type AppRoute = Omit<NonIndexRouteObject, 'element'> & {
  element: ReactElement<{
    renderChatInput: (props?: ChatInputProps) => ReactNode;
  }>;
  featureFlag?: FeatureFlag;
  crumb?: { isSidebar?: boolean; labelKey?: string; getLabel?: any };
  authRequired?: boolean;
};

export const Routes = () => {
  const appRoutes = [
    ...commonRoutes,
    ...featureRoutes,
    ...routes,
    ...authRoutes,
    ...nonBasicRoutes,
  ];

  const logError = (error: Error, errorInfo: React.ErrorInfo) => {
    Tracking.captureException(error, { extra: { errorInfo } });
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <ErrorBoundary fallback={<ServerError />} onError={logError}>
            <Root />
          </ErrorBoundary>
        }
      >
        {appRoutes.map(routeConfig => (
          <Route
            path={routeConfig.path}
            element={<ProtectedView config={routeConfig} />}
            key={routeConfig.path}
          />
        ))}
        <Route path={appPaths.notFound} element={<NotFound />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};
