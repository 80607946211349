import { useLocation, useNavigate, useSearchParams } from 'react-router';

import { TabsType } from '~/modules/tab-content/types';
import { appPaths, queryKeys } from '~/routes/app-paths';
import { createAssetOverviewMessage } from '~/services/create-asset-overview-message/createAssetOverviewMessage';
import { widgetPaths } from '~/views/widgets/widget-utils';

import { useNavigateAlfred } from '../use-navigate-alfred/useNavigateAlfred';

interface NavigateToPageProps {
  entityTag: string;
  searchParams?: URLSearchParams;
  tab?: TabsType;
}

export const useNavigateToAssetOverview = () => {
  const [queryParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();
  const navigateToAlfred = useNavigateAlfred();

  const navigateToChatMessage = (entityTag: string) => {
    createAssetOverviewMessage({ entityTag });

    if (location.pathname !== appPaths.alfred) {
      navigateToAlfred('intel');
    }
  };

  const navigateToPage = ({
    entityTag,
    searchParams,
    tab = TabsType.Overview,
  }: NavigateToPageProps) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(queryKeys.assetOverviewAsset, entityTag);
    newSearchParams.set(queryKeys.assetOverviewTab, tab);

    if (location.pathname !== appPaths.assetOverview) {
      const isSearchAssetPage =
        queryParams.get('widget') === widgetPaths.searchAsset;

      if (isSearchAssetPage) {
        newSearchParams.set(queryKeys.history, widgetPaths.searchAsset);
      } else {
        newSearchParams.set(queryKeys.history, location.pathname);
      }
    }

    newSearchParams.delete(queryKeys.widget);

    navigate({
      pathname: appPaths.assetOverview,
      search: newSearchParams.toString(),
    });
  };

  return {
    navigateToChatMessage,
    navigateToPage,
  };
};
