import {
  Button,
  DropdownListItem,
  DropdownProps,
  Icon,
} from '@toggle/design-system';
import { Entity } from '@toggle/toggle';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateWatchlistModal } from '~/components/watchlist/create-watchlist-modal/CreateWatchlistModal';
import { useWatchlistActions } from '~/hooks/use-watchlist-actions/useWatchlistActions';
import { RelatedCompaniesGrouped } from '~/services/related-companies/related-companies-service';
import { RelatedOptionItem } from '~/utils/related-companies/related-companies-helpers';

import * as S from './RelatedAssestsDropdown.styles';

export interface RelatedAssetsDropdownProps {
  relatedAssets: RelatedCompaniesGrouped;
  relatedOptions: RelatedOptionItem[];
}

export const RelatedAssetsDropdown: FC<RelatedAssetsDropdownProps> = ({
  relatedAssets,
  relatedOptions,
}) => {
  const { t } = useTranslation('widget');
  const [showModal, setShowModal] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<Entity[]>([]);

  const { createNewList, watchlistErrorText, createListLoading, clearError } =
    useWatchlistActions();

  const selectItem = (item: RelatedOptionItem) => {
    const entities = relatedAssets[item.id]?.map(a => a.entity);
    setShowModal(true);
    setSelectedAssets(entities);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onCreateList = async (name: string) => {
    await createNewList(
      name,
      selectedAssets.map(entity => entity.tag)
    );
    closeModal();
  };

  return (
    <>
      <S.StyledDropdown<FC<DropdownProps<RelatedOptionItem>>>
        placement="top-start"
        shouldShowItems={false}
        shouldSetActiveOnSelect={false}
        dropdownItems={relatedOptions}
        renderDropdownItem={(item, isActive, isFocused, isDisabled) => (
          <DropdownListItem
            /* i18next-extract-mark-context-next-line ["competitors", "suppliers"] */
            data-testid={`option-${item.id}`}
            label={t('widget:assetOverview.relatedCompany', {
              context: item.id,
            })}
            leadingIcon={<Icon size={16} iconName={item.iconName} />}
            isActive={isActive}
            isFocused={isFocused}
            isDisabled={isDisabled}
          />
        )}
        selectItem={selectItem}
      >
        <Button
          label={t('widget:assetOverview.relatedCompanyType')}
          variant="tertiary"
          size="small"
          data-testid="related-companies-button"
        />
      </S.StyledDropdown>
      <CreateWatchlistModal
        isOpen={showModal}
        onClose={closeModal}
        onSubmit={onCreateList}
        isLoading={createListLoading}
        watchlistErrorText={watchlistErrorText}
        clearError={clearError}
      />
    </>
  );
};
