import { Alert } from '@toggle/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const ButtonContainer = styled.footer`
  display: flex;
  padding-top: 36px;
  justify-content: flex-end;
  gap: 8px;
`;

export const ScenarioFilterError = styled(Alert)`
  width: 100%;
  padding: 16px;
  margin-top: 10px;
`;
