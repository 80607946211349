import React from 'react';

import { HorizonItem } from '../horizon-item/HorizonItem';
import { Horizon } from '../ThemePerformanceSection';
import * as S from './HorizonSelector.styles';

export const HorizonSelector = ({
  entityTags,
  horizons,
  selectedHorizon,
  handleHorizonChange,
  horizonToIndexMap,
}: {
  entityTags: string[];
  horizons: Horizon[];
  selectedHorizon: Horizon;
  handleHorizonChange: (horizon: Horizon) => void;
  horizonToIndexMap: Map<string, number>;
}) => {
  return (
    <S.Root>
      {horizons.map(horizon => (
        <HorizonItem
          key={horizon}
          entityTags={entityTags}
          horizonToIndexMap={horizonToIndexMap}
          horizon={horizon}
          isSelected={horizon === selectedHorizon}
          onClick={() => handleHorizonChange(horizon)}
        />
      ))}
    </S.Root>
  );
};
