import { DetailedInsight, InsightsEpisode, TSDatum } from '@toggle/toggle';
import { format } from 'date-fns';
import { format as tzFormat, toZonedTime } from 'date-fns-tz';
import { TFunction } from 'i18next';

import { InsightWidgetData } from '~/stores/use-chat/assistant-response.type';

export const getInsightTotalTradingDays = (insight: DetailedInsight) => {
  return insight.episodes.reduce((acc, ep) => acc + ep.events.length, 0);
};

export const getInsightEpisodeDates = (episodes: InsightsEpisode[]) =>
  episodes.map(episode => format(new Date(episode.start_date), 'yyyy-MM-dd'));

export const filterPredictions = (
  data: InsightWidgetData,
  indexMax: number
) => {
  return {
    ...data,
    predictions: data.predictions.filter((_, i) => i <= indexMax),
    scores: {},
    confidence: 1,
  };
};

export const getInsightCondition = (insight: DetailedInsight, t: TFunction) => {
  const conditions = insight.conditions.map(
    condition => condition.condition_text
  );
  const andLabel = t('scenarioCommander:domino.and');
  return conditions.join(`${andLabel}\n`);
};

export const formatCumulativeReturnSnake = (
  data: TSDatum[],
  startDate: string
) => {
  const foundIndex = data.findIndex(
    d => d.index.slice(0, 10) === startDate.slice(0, 10)
  );
  const startIndex = foundIndex === -1 ? 0 : foundIndex;
  return data.slice(startIndex).map((d, idx) => ({
    index: idx,
    value: d.value,
  }));
};

export const formatUtcDate = (dateString: string, pattern: string) => {
  return tzFormat(toZonedTime(new Date(dateString), 'UTC'), pattern);
};
