import styled from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const CategoryGroupRoot = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CategoryTitle = styled.h4`
  color: var(--text-soft);
  text-transform: uppercase;
  ${typographyV2.bodyXs};
  padding: 0 8px 0 24px;
`;

export const Items = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
