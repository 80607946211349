import { calendarResponseSchema } from './calendar';
import { countriesResponseSchema } from './countries';
import {
  economicEventInputSchema,
  economicsResponseInputSchema,
} from './economics-events';
import { keyDevsResponseSchema } from './key-developments-events';
import {
  govTradeResponseSchema,
  insiderTradesResponseSchema,
  iposResponseSchema,
} from './screener-events';

export * from './calendar';
export * from './economics-events';
export * from './key-developments-events';
export * from './screener-events';

const financialEventsBasePath = 'financial-events/v1/';
export const financialEvents = {
  postGovTrades: {
    path: `${financialEventsBasePath}gov-trades`,
    schema: govTradeResponseSchema,
  },
  postInsiderTrades: {
    path: `${financialEventsBasePath}insider-trades`,
    schema: insiderTradesResponseSchema,
  },
  postIpos: {
    path: `${financialEventsBasePath}ipos`,
    schema: iposResponseSchema,
  },
  postKeyDevs: {
    path: `${financialEventsBasePath}key-devs`,
    schema: keyDevsResponseSchema,
  },
  getEconomics: {
    path: ({
      from,
      to,
      countries,
    }: {
      from: string;
      to: string;
      countries?: string[];
    }) => {
      const basePath = `${financialEventsBasePath}economics?from=${from}&to=${to}`;
      const countriesParams =
        countries?.map(country => `&country=${country}`) ?? [];
      return `${basePath}${countriesParams.join('')}`;
    },
    schema: economicsResponseInputSchema,
  },
  getEconomicsById: {
    path: (id: string) => `${financialEventsBasePath}economics/${id}`,
    schema: economicEventInputSchema,
  },
  postCalendars: {
    path: `${financialEventsBasePath}calendars`,
    schema: calendarResponseSchema,
  },
  getCountries: {
    path: `${financialEventsBasePath}countries`,
    schema: countriesResponseSchema,
  },
};
