import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ChildFilterDropdownTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typographyV2.titleLg};
  color: var(--text-primary);
  cursor: pointer;
`;
