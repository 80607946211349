import { borderRadius, Button } from '@toggle/design-system';
import styled from 'styled-components';

export const MoreUnreadNewsPillRoot = styled(Button)`
  position: absolute;
  z-index: var(--z-index-toast);
  top: 70px;
  left: 50%;
  transform: translateX(-50%);

  border: 1px solid var(--border-soft);
  ${borderRadius.r4};
  color: var(--text-primary);
  background-color: var(--surface-default);

  & svg {
    fill: var(--text-primary);
  }
`;
