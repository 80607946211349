import styled from 'styled-components';

export const BreadcrumbsRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

export const Separator = styled.span`
  padding: 0 8px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 20px;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
`;
