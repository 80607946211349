import { SchemaValidationError } from '@toggle/helpers';
import { ZodError, ZodIssue, ZodSchema } from 'zod';

import { Tracking } from '~/services/tracking';

export type ValidateResponseSchemaProps<T> = {
  data: T;
  endpoint: string;
  schema: ZodSchema<T>;
};

export const validateResponseSchema = <T>({
  data,
  endpoint,
  schema,
}: ValidateResponseSchemaProps<T>): SchemaValidationError | false => {
  try {
    schema.parse(data);
    return false;
  } catch (error) {
    if (error instanceof ZodError) {
      const flattenedError = error.flatten((issue: ZodIssue) => ({
        message: issue.message,
        errorCode: issue.code,
        path: issue.path,
      }));

      const validationError = new SchemaValidationError(
        endpoint,
        flattenedError
      );
      Tracking.captureException(error, {
        extra: {
          endpoint,
          flattenedError,
        },
      });
      return validationError;
    }

    throw error as Error;
  }
};
