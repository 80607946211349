import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';
import { earnings, EarningsScenarioResponse } from '@toggle/toggle';

import { wretchRequest } from '~/utils/api-fetch/apiFetch';

const QUERY_KEY_EARNINGS_SCENARIO = 'QUERY_KEY_EARNINGS_SCENARIO';

export type EarningsScenarioProps = {
  exchange: string;
  ticker: string;
  year?: number;
  period?: string;
};

export const useEarningsScenario = ({
  ticker,
  exchange,
  year,
  period,
}: EarningsScenarioProps) => {
  const { path, schema } = earnings.getEarningsScenario;

  return useQuery({
    queryKey: [QUERY_KEY_EARNINGS_SCENARIO, ticker, exchange],
    queryFn: ({ signal }) =>
      wretchRequest<EarningsScenarioResponse>(
        path({ exchange, ticker, year, period }),
        {
          schema,
          signal,
        }
      ),
    staleTime: getTimeTillEndOfDayMs(),
    enabled: !!ticker,
  });
};
