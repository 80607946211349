import { WithNAFallback } from '@toggle/design-system';
import React from 'react';

import { formatNumberWithUnit } from '../../utils/utils';
import * as S from './PriceCell.styles';

interface NumberCellProps {
  value: number;
  percentage?: boolean;
}

export const PriceCell = ({ value }: NumberCellProps) => {
  const formattedValue = formatNumberWithUnit(value);

  return (
    <S.PriceTableValue>
      <WithNAFallback
        check={formattedValue !== '-'}
        value={formatNumberWithUnit(value)}
      />
    </S.PriceTableValue>
  );
};
