import { useQuery } from '@tanstack/react-query';
import { getTimeTillEndOfDayMs } from '@toggle/helpers';

import { SCREENER_ID, WATCHLIST_ID, WORKING_LIST_ID } from '~/config/constants';
import { fetchPublicListEntities } from '~/services/watchlist/watchlist';
import { useParsedScreenerOptionsQueries } from '~/views/scenario/hooks/use-scenario-entity-tags';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';
import { useScreenerEntitiesTags } from '~/widgets/scenario/hooks/use-screener-entities-tags/useScreenerEntitiesTags';

import { FilterGroups } from '../use-filter-actions/useFilterActions';
import { useWorkingList } from '../use-working-list/useWorkingList';

const QUERY_PUBLIC_LIST_ENTITIES = 'QUERY_PUBLIC_LIST_ENTITIES';

export const useAssetsFilterEntityTags = (
  filterGroups: FilterGroups<'ASSET_FILTERS'>
) => {
  const filters = filterGroups.ASSET_FILTERS.activeFilterOptions;
  const activeFilterOption = filters?.[0];

  const isWatchlistFilter = !!activeFilterOption?.options?.some(
    opt => WATCHLIST_ID in opt.value
  );
  const isScreenerFilter = !!activeFilterOption?.options?.some(
    opt => SCREENER_ID in opt.value
  );

  const isWorkingList = !!activeFilterOption?.options?.some(
    opt => WORKING_LIST_ID in opt.value
  );

  const { savedScreens } = useScreenerStore(state => ({
    savedScreens: state.savedScreens,
  }));

  const { data: watchlistData, isLoading: isWatchlistLoading } =
    useScreenerEntitiesTags({
      filterOptions: isWatchlistFilter
        ? [{ ...activeFilterOption, filter: 'entity_tag' }]
        : [],
    });

  const activePublicListId = activeFilterOption?.options[0]?.value?.indexId as
    | string
    | undefined;
  const { data: indexEntitiesData, isLoading: isIndexDataLoading } = useQuery({
    queryKey: [QUERY_PUBLIC_LIST_ENTITIES, activePublicListId],
    queryFn: ({ signal }) =>
      fetchPublicListEntities({
        signal,
        listId: activePublicListId ?? '',
      }),
    staleTime: getTimeTillEndOfDayMs(),
    select: data => data.entities.map(e => e.tag),
    enabled: !isWatchlistFilter && !isScreenerFilter && !!activePublicListId,
  });

  const { data: screenerData, isLoading: isScreenerLoading } =
    useParsedScreenerOptionsQueries(savedScreens, activeFilterOption);

  const workingList = useWorkingList(store => store.workingList);

  if (isWorkingList) {
    return {
      data: workingList,
      isLoading: false,
    };
  }

  if (isScreenerFilter) {
    return {
      data: screenerData.flat() ?? [],
      isLoading: isScreenerLoading,
    };
  }

  if (isWatchlistFilter) {
    return {
      data: watchlistData?.entities ?? [],
      isLoading: isWatchlistLoading,
    };
  }

  return {
    data: indexEntitiesData ?? [],
    isLoading: isIndexDataLoading,
  };
};
