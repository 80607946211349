import { ConditionType, EconomicEventOutput } from '@toggle/toggle';
import { TFunction } from 'i18next';

import { formatNumber } from './number-formatter';

export const DEFAULT_BEATS_MISSES_VALUE = 0.005;

export enum Condition {
  BEATS = 'Beats',
  MISSES = 'Misses',
  IsBelow = 'IsBelow',
  IsAbove = 'IsAbove',
}

export interface GetConditionOptionsProps {
  data: EconomicEventOutput;
  t: TFunction;
}
interface ScenarioConditionConfig {
  snake: string;
  condition: ConditionType;
  value: number;
}

export interface ConditionOptions {
  value: string;
  config: ScenarioConditionConfig;
}

export const getConditionOptions = ({
  data,
  t,
}: GetConditionOptionsProps): ConditionOptions[] => {
  const {
    snakes,
    consensus,
    consensus_unit: consensusUnit,
    surprise,
    surprise_unit: surpriseUnit,
    actual,
    actual_unit: actualUnit,
  } = data;

  if (!snakes) {
    return [];
  }

  const { surprise: surpriseSnake, actual: actualSnake } = snakes;

  const formatLabel = (unit?: string, value?: number) =>
    formatNumber(unit === '%', value);

  const reportedLabel = formatLabel(actualUnit, actual);
  const surpriseLabel = formatLabel(surpriseUnit ?? actualUnit, surprise);
  const consensusLabel = formatLabel(consensusUnit, consensus);

  if (!actual && surpriseSnake) {
    const unreportedOptions = [
      {
        /* i18next-extract-mark-context-next-line ["Beats", "Misses", "IsBelow", "IsAbove"] */
        value: t('calendar:scenario.conditionOption', {
          context: Condition.BEATS,
        }),
        config: {
          snake: surpriseSnake,
          condition: ConditionType.Above,
          value: DEFAULT_BEATS_MISSES_VALUE,
        },
      },
      {
        /* i18next-extract-mark-context-next-line ["Beats", "Misses", "IsBelow", "IsAbove"] */
        value: t('calendar:scenario.conditionOption', {
          context: Condition.MISSES,
        }),
        config: {
          snake: surpriseSnake,
          condition: ConditionType.Below,
          value: -DEFAULT_BEATS_MISSES_VALUE,
        },
      },
    ];

    if (consensus && actualSnake) {
      return [
        ...unreportedOptions,
        {
          /* i18next-extract-mark-context-next-line ["Beats", "Misses", "IsBelow", "IsAbove"] */
          value: t('calendar:scenario.conditionOption', {
            context: Condition.IsBelow,
            label: consensusLabel,
          }),
          config: {
            snake: actualSnake,
            condition: ConditionType.Below,
            value: consensus,
          },
        },
        {
          /* i18next-extract-mark-context-next-line ["Beats", "Misses", "IsBelow", "IsAbove"] */
          value: t('calendar:scenario.conditionOption', {
            context: Condition.IsAbove,
            label: consensusLabel,
          }),
          config: {
            snake: actualSnake,
            condition: ConditionType.Above,
            value: consensus,
          },
        },
      ];
    }

    return unreportedOptions;
  }

  const reportedOptions: ConditionOptions[] = [];

  if (actualSnake && actual) {
    reportedOptions.push(
      {
        /* i18next-extract-mark-context-next-line ["Beats", "Misses", "IsBelow", "IsAbove"] */
        value: t('calendar:scenario.conditionOption', {
          context: Condition.IsBelow,
          label: reportedLabel,
        }),
        config: {
          snake: actualSnake,
          condition: ConditionType.Below,
          value: actual,
        },
      },
      {
        /* i18next-extract-mark-context-next-line ["Beats", "Misses", "IsBelow", "IsAbove"] */
        value: t('calendar:scenario.conditionOption', {
          context: Condition.IsAbove,
          label: reportedLabel,
        }),
        config: {
          snake: actualSnake,
          condition: ConditionType.Above,
          value: actual,
        },
      }
    );
  }

  if (surpriseSnake && !!surprise) {
    const surpriseOption = {
      value: t(
        surprise > 0
          ? 'calendar:scenario.beatsBy'
          : 'calendar:scenario.missesBy',
        { label: surpriseLabel }
      ),
      config: {
        snake: surpriseSnake,
        condition: surprise > 0 ? ConditionType.Above : ConditionType.Below,
        value: surprise,
      },
    };

    return [surpriseOption, ...reportedOptions];
  }

  return reportedOptions;
};
