import React, { ReactNode } from 'react';

import { StyledComponent } from '~/common/styled-component';

import * as S from './PriceDisplay.styles';

export type OutsideTag = 'pre' | 'after';

export interface PriceChange {
  status: 'default' | 'positive' | 'negative';
  change: string;
  proportionChange: string;
}

export interface OutsideHours {
  label: string;
  tag: OutsideTag;
}

export interface PriceDisplayProps {
  price: string | number;
  currency: string;
  priceChange?: PriceChange;
  liveLabel?: string;
  timeNode?: ReactNode;
  isPrimary?: boolean;
  outsideHoursText?: OutsideHours;
  showMarketIndicator?: boolean;
}

export const PriceDisplay: StyledComponent<PriceDisplayProps, typeof S> = ({
  price,
  currency,
  priceChange,
  liveLabel,
  timeNode,
  outsideHoursText,
  isPrimary = true,
  showMarketIndicator = true,
  ...rest
}) => (
  <S.Container data-testid="module-price-display" {...rest}>
    <S.PriceContainer $isPrimary={isPrimary}>
      <S.Price>{price}</S.Price>
      <S.Currency>{currency}</S.Currency>
      {priceChange && (
        <S.PriceChangeContainer
          $status={priceChange.status}
          data-testid="price-change"
        >
          <span>{priceChange.change}</span>
          <span>{priceChange.proportionChange}</span>
        </S.PriceChangeContainer>
      )}
    </S.PriceContainer>
    {showMarketIndicator && (
      <S.LabelsContainer data-testid="market-status">
        {timeNode}
        {outsideHoursText && (
          <S.OutsideHoursTag $tagType={outsideHoursText.tag}>
            {outsideHoursText.label}
          </S.OutsideHoursTag>
        )}
        {liveLabel && <S.LiveLabel>{liveLabel}</S.LiveLabel>}
      </S.LabelsContainer>
    )}
  </S.Container>
);

PriceDisplay.Styled = S;
