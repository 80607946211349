import { ChartAssetData, PriceHoverData } from '@toggle/chart';
import { Variant } from '@toggle/helpers';
import { DisplayFormat, mapEntity, SnakeMeta } from '@toggle/toggle';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { globalPriceFormatter } from '~/utils/asset-price/assetPrice';

import { useChartTheme } from '../../hooks/use-chart-theme/useChartTheme';
import * as S from './IndicatorLegendDetails.styles';

export interface IndicatorLegendDetailsProps {
  chartAssetData: ChartAssetData;
  hoverData?: PriceHoverData;
}

export const IndicatorLegendDetails: FC<IndicatorLegendDetailsProps> = ({
  chartAssetData,
  hoverData,
}) => {
  const { t } = useTranslation('chart');
  const { colors } = useChartTheme();
  const entity = mapEntity(chartAssetData.entity);
  const snakeMeta = chartAssetData.snakeMeta as SnakeMeta;
  const threshold = snakeMeta.threshold;
  const color = colors.getThemeColor(chartAssetData.lineColorToken) as Variant;

  const priceFormatter = (price: number) =>
    globalPriceFormatter({
      entity,
      price,
      priceSuffix: entity.currency,
      shouldShowCurrency: snakeMeta.display_format === DisplayFormat.CURRENCY,
    });

  const thresholdLinesData = threshold
    ? [
        {
          key: 'bullish' as Variant,
          value: threshold.good,
          indicatorLine: <S.BullishLine />,
        },
        {
          key: 'bearish' as Variant,
          value: threshold.bad,
          indicatorLine: (
            <S.BearishIndicator>
              <S.BearishLine /> <S.BearishLine />
              <S.BearishLine />
            </S.BearishIndicator>
          ),
        },
      ]
    : [];

  const lastClosePrice = chartAssetData.ts[chartAssetData.ts.length - 1]?.close;
  const hoveredClosePrice = hoverData?.currentPrice.close;
  const closePrice = hoveredClosePrice ?? lastClosePrice;

  return (
    <S.Container data-testid="indicator-legend-details">
      <S.Value data-testid="indicator-legend-price" $color={color}>
        {priceFormatter(closePrice)}
      </S.Value>
      {thresholdLinesData
        .filter(item => typeof item.value === 'number')
        .map(item => (
          <S.Item data-testid={`threshold-${item.key}`} key={item.key}>
            {item.indicatorLine}
            <S.Label>{t('chart:threshold', { context: item.key })}</S.Label>
            <S.Value $color={item.key}>{item.value}</S.Value>
          </S.Item>
        ))}
    </S.Container>
  );
};
