import { SkeletonLoader, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { ChartAreaWrapper } from '~/components/chart-area-wrapper/ChartAreaWrapper';

export const Container = styled.div<{ $isSingle: boolean }>`
  height: ${props => (props.$isSingle ? '100%' : 'max(45vh,340px)')};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const StyledChartAreaWrapper = styled(ChartAreaWrapper)`
  height: calc(100% - 32px);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Snake = styled.p`
  ${typographyV2.titleSm}
`;

export const SkeletonChartLoader = styled(SkeletonLoader).attrs({
  areas: [
    {
      styles: {
        width: '100%',
        borderRadius: '8px',
      },
    },
  ],
})`
  display: flex;
  flex-grow: 1;
`;
