import { AssetClassName, ScenariosCondition } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';
import { FilterList } from '~/widgets/scenario/components/filter-list/FilterList';
import { ScenarioPriceMovement } from '~/widgets/scenario/components/scenario-price-movement/ScenarioPriceMovement';
import { ScenarioQuality } from '~/widgets/scenario/components/scenario-quality/ScenarioQuality';

import { MappedConditions } from '../scenario-assistant-modal/use-scenario-assistant/enrichedConditions';
import { ScenarioPastEpisodeSection } from '../scenario-past-episode-section/ScenarioPastEpisodeSection';
import * as S from './ScenarioDrawer.styles';
import { generateLastConditionLabel } from './utils/utils';

export interface ScenarioDrawerProps {
  activeHorizon: string;
  closeDrawer: () => void;
  horizons: string[];
  isSummary: boolean;
  lastConditions?: ScenariosCondition[] | MappedConditions[];
  scenario: ScenariosData;
}

export const ScenarioDrawer = ({
  activeHorizon,
  closeDrawer,
  horizons,
  isSummary,
  lastConditions = [],
  scenario,
}: ScenarioDrawerProps) => {
  const { t } = useTranslation(['scenario', 'widget', 'chart']);

  const isFixedIncome = scenario.entity.asset_class === AssetClassName.Fi;
  const bestHorizon = scenario.report.best_horizon;
  const initHorizon = isSummary ? bestHorizon : activeHorizon;
  const headerTitle = t('scenario:drawer.drawerTitle', {
    entityName: scenario.entity.name,
    count: scenario.episodes.length,
  });

  const filterNames = lastConditions.map(
    condition =>
      condition.display ||
      (condition &&
        generateLastConditionLabel({
          t,
          conditions: [condition as MappedConditions],
        }))
  );

  return (
    <S.StyledDrawer
      data-testid="scenario-drawer"
      isOpen
      headerTitle={headerTitle}
      headerSubtitle={<FilterList list={filterNames} />}
      handleCloseClick={closeDrawer}
    >
      <S.ScenarioDrawerWrapper>
        <ScenarioPriceMovement
          bestHorizon={bestHorizon}
          horizons={horizons}
          isFixedIncome={isFixedIncome}
          scenario={scenario}
        />
        <S.Separator />
        {scenario.episodes.length > 0 && (
          <ScenarioPastEpisodeSection
            scenario={scenario}
            snake={lastConditions.map(condition => condition.snake)}
          />
        )}
        <S.LineBreak />
        <ScenarioQuality
          bestHorizon={bestHorizon}
          horizons={horizons}
          initHorizon={initHorizon}
          isFixedIncome={isFixedIncome}
          scenario={scenario}
        />
      </S.ScenarioDrawerWrapper>
    </S.StyledDrawer>
  );
};
