/* eslint-disable complexity */
import { To } from 'react-router';
import { v4 } from 'uuid';

import { create } from '../create-store/createStore';

export type BreadcrumbItem = {
  id: string;
  to?: To;
  isAccessibleFromSideBar?: boolean;
  navigateFn?: () => void;
  label: string;
  themeId?: string;
  assetTag?: string;
};

export type HandleLocationChangeProps = {
  to?: To;
  crumb?: {
    isSidebar?: boolean;
    isAccessibleFromSideBar?: boolean;
    label: string;
    themeId?: string;
    assetTag?: string;
  };
  navigateFn?: () => void;
};

type BreadcrumbsStore = {
  breadcrumbs?: BreadcrumbItem[];
  addBreadCrumbItem: ({
    to,
    label,
  }: {
    to?: To;
    label: string;
    navigateFn?: () => void;
  }) => void;
  removeFromBreadcrumbs: (id: string, shouldReInitialize?: boolean) => void;
  removeLastBreadcrumb: () => void;
  reInitializeWith: (item: BreadcrumbItem) => void;
  handleLocationChange: ({
    to,
    crumb,
    navigateFn,
  }: HandleLocationChangeProps) => void;
};

export const useBreadcrumbs = create<BreadcrumbsStore>((set, get) => ({
  breadcrumbs: undefined,
  addBreadCrumbItem(item) {
    const { breadcrumbs = [] } = get();
    const breadcrumb = {
      ...item,
      id: v4(),
    };
    set({
      breadcrumbs: [...breadcrumbs, breadcrumb],
    });
  },
  removeFromBreadcrumbs(breadcrumbId, shouldReInitialize) {
    const { breadcrumbs = [], reInitializeWith } = get();
    const itemIndex = breadcrumbs.findIndex(b => b.id === breadcrumbId);

    if (itemIndex !== -1) {
      if (shouldReInitialize) {
        reInitializeWith(breadcrumbs[itemIndex]);
      } else {
        const newBreadcrumbs = [...breadcrumbs.slice(0, itemIndex + 1)];

        set({
          breadcrumbs: newBreadcrumbs,
        });
      }
    }
  },
  reInitializeWith(item) {
    set({
      breadcrumbs: [item],
    });
  },
  removeLastBreadcrumb() {
    const { breadcrumbs = [] } = get();
    set({
      breadcrumbs: breadcrumbs.slice(0, breadcrumbs.length - 1),
    });
  },
  handleLocationChange({ to, crumb, navigateFn }) {
    const isAccessibleFromSideBar = crumb?.isAccessibleFromSideBar;

    const {
      breadcrumbs = [],
      addBreadCrumbItem,
      reInitializeWith,
      removeLastBreadcrumb,
    } = get();

    if (!crumb) {
      return;
    }

    const item = { ...crumb, id: v4(), to, navigateFn };

    if (crumb.isSidebar || isAccessibleFromSideBar) {
      return reInitializeWith(item);
    }

    const assetTag = crumb.assetTag;
    const themeId = crumb.themeId;
    const label = crumb.label;

    const lastCrumb = breadcrumbs[breadcrumbs.length - 1];
    const secondLastCrumb = breadcrumbs[breadcrumbs.length - 2];

    const shouldRemoveLast =
      (assetTag && secondLastCrumb?.assetTag === assetTag) ||
      (themeId && secondLastCrumb?.themeId === themeId) ||
      (label && secondLastCrumb?.label === label);

    // Handle backward navigating, eg: A>B>C to A>B
    if (secondLastCrumb && shouldRemoveLast) {
      removeLastBreadcrumb();
      return;
    }

    // Handle forward navigating, should not add new duplicate crumb, eg: A>B>C to A>B>C
    const shouldReturn =
      (assetTag && lastCrumb?.assetTag === assetTag) ||
      (themeId && lastCrumb?.themeId === themeId) ||
      (label && lastCrumb?.label === label);

    if (shouldReturn) {
      return;
    }

    addBreadCrumbItem(item);
  },
}));
