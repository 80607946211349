import React from 'react';

import { Filter, FilterProps } from '~/components/filter/Filter';
import { SCREENER_ID } from '~/config/constants';
import { FilterGroupKeys } from '~/hooks/use-filter-actions/useFilterActions';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';

import { mapActiveFilterOptions, mapToFilterOptions } from '../utils';

export const FilterScreeners = <T extends FilterGroupKeys>({
  filter,
  filterOptions,
  ...rest
}: FilterProps<T>) => {
  const savedScreens = useScreenerStore(state => state.savedScreens);

  if (savedScreens.length === 0) {
    return null;
  }

  filter.config.options = mapToFilterOptions({
    items: savedScreens,
    valueKey: SCREENER_ID,
  });

  const activeFilterOptions = mapActiveFilterOptions(
    savedScreens,
    SCREENER_ID,
    filterOptions
  );

  return (
    <Filter filter={filter} filterOptions={activeFilterOptions} {...rest} />
  );
};
