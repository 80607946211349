import React from 'react';

import { useChartApi } from '~/hooks/use-chart-api/useChartApi';
import { ChartAreaProps } from '~/views/turbo-chart/components/chart-area/ChartArea';

import { ChartLegend } from './ChartLegend';

export const ChartArea = (props: ChartAreaProps) => {
  const {
    canvasRef,
    chartApiRef,
    options,
    chartState,
    primaryAsset,
    primaryPane,
  } = props;
  useChartApi({
    canvasRef,
    chartApiRef,
    options,
    chartState,
    primaryAsset,
    primaryPane,
  });

  return (
    <>
      <canvas data-testid="chart-area-canvas" ref={canvasRef} />
      <ChartLegend {...props} />
    </>
  );
};
