export enum PriceStatus {
  Default = 'default',
  Positive = 'positive',
  Negative = 'negative',
}

export enum Status {
  Disabled = 'disabled',
}

export const formatPercentage = (
  n: number,
  options?: Partial<{
    decimals: number;
    suffix: string;
    useOriginalValue: boolean;
  }>
) => {
  let { decimals = 2, suffix = '', useOriginalValue } = options ?? {};
  const calculatedValue = (n * (useOriginalValue ? 1 : 100)).toFixed(decimals);

  if (suffix === 'bps') {
    suffix = Math.abs(+calculatedValue) === 1 ? 'bp' : 'bps';
  }

  return `${calculatedValue}${suffix}`;
};

const getDecimalFormatter = (language: string): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export function formatDecimal(language: string, value: number): string {
  return getDecimalFormatter(language).format(value);
}

const getIntegerFormatter = (language: string): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    style: 'decimal',
    maximumFractionDigits: 0,
  });
};

export function formatInteger(language: string, value: number): string {
  return getIntegerFormatter(language).format(value);
}

export function numberWithCommas(x: number | string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function logicalXOR(a: boolean, b: boolean): boolean {
  return (a && !b) || (!a && b);
}

export function numberRoundedToPrecision(num: number, precision = 2) {
  return Number(num.toFixed(precision));
}

export const decimalCount = (n: number | string): number => {
  try {
    if (Number.isInteger(parseFloat(n as string)) || n === '') {
      return 0;
    }

    return n.toString().split('.')[1].length;
  } catch {
    return 0;
  }
};

export const cryptoRandom = () => {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
};

export const getPriceStatus = (value?: number) => {
  if (!value) {
    return PriceStatus.Default;
  }

  return value > 0 ? PriceStatus.Positive : PriceStatus.Negative;
};
