import React from 'react';

import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useNavigateToCheckout } from '~/views/settings/subscription/hooks/use-navigate-to-checkout/useNavigateToCheckout';

import { AlertPaymentMethod } from './components/alert-payment-method/AlertPaymentMethod';
import { AlertTrialEnds } from './components/alert-trial-ends/AlertTrialEnds';
import * as S from './NotificationAlerts.styles';

export const NotificationAlerts = () => {
  const { card, userSubscription, cardError } = useSubscription(state => ({
    userSubscription: state.userSubscription,
    card: state.card,
    cardError: state.cardError,
  }));

  const upgradeToPro = useNavigateToCheckout();

  return (
    <S.NotificationAlertsRoot data-testid="notification-alerts">
      <AlertTrialEnds upgradeToPro={upgradeToPro} />
      {card && (
        <AlertPaymentMethod
          card={card}
          userSubscription={userSubscription}
          cardError={cardError}
        />
      )}
    </S.NotificationAlertsRoot>
  );
};
