import React, {
  cloneElement,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  TooltipContainer,
  TooltipContainerProps,
} from '~/components/tooltip-container/TooltipContainer';

interface EllipsisDetectorProps extends TooltipContainerProps {
  children: ReactElement<any>;
  vertical?: boolean;
}

export const EllipsisDetector: FC<EllipsisDetectorProps> = ({
  children,
  vertical = false,
  ...tooltipProps
}) => {
  const ref = useRef<HTMLElement>(null);
  const [ellipsisActive, setEllipsisActive] = useState(false);

  const isEllipsisActive = (el: HTMLElement) => {
    return vertical
      ? el.offsetHeight < el.scrollHeight
      : el.offsetWidth < el.scrollWidth;
  };

  useEffect(() => {
    if (ref.current) {
      setEllipsisActive(isEllipsisActive(ref.current));
    }
  });

  return (
    <>
      {!ellipsisActive ? (
        cloneElement(children, { ref })
      ) : (
        <TooltipContainer {...tooltipProps}>
          {cloneElement(children)}
        </TooltipContainer>
      )}
    </>
  );
};
