import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './AssessmentGauge.style';

export interface AssessmentGaugeProps {
  gaugeValue: number;
}

export const AssessmentGauge = ({ gaugeValue }: AssessmentGaugeProps) => {
  const { t } = useTranslation('widget');

  return (
    <S.QualityGaugeWrapper>
      <S.QualityGaugeBackground />
      <S.GaugeArrow $value={gaugeValue} data-testid="verdict-arrow" />
      <S.GaugeVerdictWrapper data-testid="verdict-wrapper" $value={gaugeValue}>
        {gaugeValue}
        <S.GaugeVerdict data-testid="verdict-value">
          {
            /* i18next-extract-mark-context-next-line ["1", "2", "3", "4", "5", "6", "7", "8"] */
            t('widget:assessment.evaluations.stars', {
              context: gaugeValue,
            })
          }
        </S.GaugeVerdict>
      </S.GaugeVerdictWrapper>
    </S.QualityGaugeWrapper>
  );
};
