import { borderRadius, fontFamily, typographyV2 } from '@toggle/design-system';
import { Variant } from '@toggle/helpers';
import styled from 'styled-components';

const colorRecord: Record<Variant, string> = {
  bullish: 'var(--viz-bullish)',
  bearish: 'var(--viz-bearish)',
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--background-soft);
  ${borderRadius.r1};
  margin-left: 8px;
`;

export const Item = styled.div`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 8px;
`;

export const Dot = styled.div<{ $color: Variant }>`
  width: 8px;
  height: 8px;
  background-color: ${props => colorRecord[props.$color]};
  ${borderRadius.rFull};
`;

export const Label = styled.p`
  ${typographyV2.bodySm};
`;

export const Value = styled.span<{ $color: Variant }>`
  font-family: ${fontFamily.robotoMono};
  ${typographyV2.bodySm};
  color: ${props => colorRecord[props.$color]};
`;

export const BullishLine = styled.span`
  width: 12px;
  height: 2px;
  ${borderRadius.r05};
  background-color: var(--viz-bullish);
`;

export const BearishIndicator = styled.div`
  display: flex;
  gap: 1px;
`;

export const BearishLine = styled.span`
  width: 4px;
  border: none;
  ${borderRadius.r05};
  border-top: 2px solid var(--viz-bearish);
`;
