import { Entity, EntitySearchType, UseTagSearchParams } from '@toggle/toggle';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

import { QUERY_DEBOUNCE_OPTS, QUERY_DELAY } from '~/config/constants';
import { postEntitiesByQuery } from '~/services/entities/entity-service';
import { useSubscription } from '~/stores/use-subscription/useSubscription';

interface SnakeDescriptionBasicWeighted {
  description: string;
  snake: string;
  pos_weight: number;
  weight: number;
}

export interface TimeSeriesSnakesQueryResponse {
  query_text: string;
  result: SnakeDescriptionBasicWeighted[];
}

interface TagSearchProps {
  searchType: EntitySearchType;
  params?: UseTagSearchParams;
  additionalConditionChecker?: (value: string) => boolean;
}

interface UseTagsData<T> {
  tags: T[];
}

interface UseTagSearchData<T> extends UseTagsData<T> {
  query: string;
}

interface UseTagSearchResult<T = Entity | TimeSeriesSnakesQueryResponse>
  extends UseTagSearchData<T> {
  loading: boolean;
  searchTags(query: string): void;
  clearTags(): void;
  setQuery: (value: string) => void;
}

const FETCH_BY_QUERY = {
  [EntitySearchType.Entity]: (query: string, params?: UseTagSearchParams) =>
    postEntitiesByQuery(query, EntitySearchType.Entity, params),
  [EntitySearchType.Currency]: (query: string, params?: UseTagSearchParams) =>
    postEntitiesByQuery(query, EntitySearchType.Currency, params),
  [EntitySearchType.Economies]: (query: string, params?: UseTagSearchParams) =>
    postEntitiesByQuery(query, EntitySearchType.Economies, params),
  [EntitySearchType.EntityAndEconomies]: (
    query: string,
    params?: UseTagSearchParams
  ) => postEntitiesByQuery(query, EntitySearchType.EntityAndEconomies, params),
  [EntitySearchType.EntityLookup]: (
    query: string,
    params?: UseTagSearchParams,
    forceApiCall?: boolean
  ) =>
    postEntitiesByQuery(
      query,
      EntitySearchType.EntityLookup,
      params,
      forceApiCall
    ),
};

const isSnakeResponse = (
  result: Entity[] | TimeSeriesSnakesQueryResponse
): result is TimeSeriesSnakesQueryResponse => {
  return 'result' in result;
};

export const extractTags = (
  fetchResult: Entity[] | TimeSeriesSnakesQueryResponse,
  shouldFilterGatedEntities?: boolean
): Entity[] | SnakeDescriptionBasicWeighted[] =>
  isSnakeResponse(fetchResult)
    ? fetchResult.result
    : shouldFilterGatedEntities
    ? fetchResult.filter(e => !e.gated)
    : fetchResult;

export function useTagsSearch<
  T extends Entity | SnakeDescriptionBasicWeighted = Entity
>({
  searchType,
  params,
  additionalConditionChecker,
}: TagSearchProps): UseTagSearchResult<T> {
  const [search, setSearch] = useState<UseTagSearchData<T>>({
    query: '',
    tags: [],
  });
  const [loading, setLoading] = useState(false);
  const [inputQuery, setInputQuery] = useState<string>('');
  const prevQuery = useRef<string>(null);
  const isBasicUser = useSubscription(state => state.isBasicUser());

  const debouncedTagsSearchQuery = useCallback(
    debounce(
      async (
        query: string,
        searchType: EntitySearchType,
        forceSearch: boolean
      ) => {
        prevQuery.current = query;

        if (
          (!query ||
            (additionalConditionChecker &&
              !additionalConditionChecker?.(query))) &&
          !forceSearch
        ) {
          setSearch({ query: '', tags: [] });
          setLoading(false);
        } else {
          try {
            setLoading(true);

            const fetchResult = await FETCH_BY_QUERY[searchType](
              query,
              params,
              forceSearch
            );

            if (fetchResult.error) {
              throw fetchResult.error;
            }

            if (prevQuery.current === query) {
              setSearch({
                query,
                tags: extractTags(fetchResult.data, isBasicUser) as T[],
              });
            }
          } catch (error) {
            setSearch({ query, tags: [] });
          } finally {
            setLoading(false);
          }
        }
      },
      QUERY_DELAY,
      QUERY_DEBOUNCE_OPTS
    ),
    [JSON.stringify(params?.criteria)]
  );

  const searchTags = (query: string) => {
    setLoading(!!query);
    setInputQuery(query);

    if (inputQuery !== search.query) {
      setSearch({ ...search, query });
    }
  };

  const clearTags = () => {
    setSearch({ query: '', tags: [] });
    setInputQuery('');
    setLoading(false);
  };

  const setQuery = (value: string) => {
    setInputQuery(value);
  };

  useEffect(() => {
    debouncedTagsSearchQuery(inputQuery, searchType, !!params?.criteria);

    return debouncedTagsSearchQuery.cancel;
  }, [inputQuery, searchType, JSON.stringify(params?.criteria)]);

  return {
    query: inputQuery,
    tags: search.tags,
    loading,
    searchTags,
    clearTags,
    setQuery,
  };
}
