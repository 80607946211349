import { Entity } from '@toggle/toggle';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { NewsFilter } from '~/services/news/news.types';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { useNewsFiltersStore } from '~/views/news/stores/use-news-filters-store/useNewsFiltersStore';
import {
  countFilterKeyChecked,
  updateWatchlistFilter,
} from '~/views/news/utils/news-filters-utils/news-filters-utils';

import { ClearFiltersButton } from '../clear-filters-button/ClearFiltersButton';
import { HighlightsFilters } from '../highlights-filters/HighlightsFilters';
import { NewsList } from '../news-list/NewsList';
import { SideAccordionFilters } from '../side-accordion-filters/SideAccordionFilters';
import * as S from './NewsView.styles';

export interface NewsViewProps {
  entity?: Entity;
  includedFilterKeys?: NewsFilter['key'][];
}

export const NewsView: FC<NewsViewProps> = ({ entity, includedFilterKeys }) => {
  const { t } = useTranslation('news');
  const {
    filters,
    setIsMostReadSelected,
    setFilters,
    resetAll,
    toggleFilterOption,
    isMostReadSelected,
  } = useNewsFiltersStore();

  const lists = useWatchlist(state => state.lists);

  useEffect(() => {
    const updatedFilters = updateWatchlistFilter(filters, lists);
    setFilters(updatedFilters);
  }, [lists]);

  const newsFilters = includedFilterKeys
    ? filters.filter(f => includedFilterKeys.includes(f.key))
    : filters;

  const initialDataFilters = entity
    ? [
        {
          key: 'entities',
          name: 'Entity',
          options: [
            {
              isChecked: true,
              visible: true,
              key: entity.tag,
              name: entity.name,
            },
          ],
        },
      ]
    : [];
  const newsListFilters = [...initialDataFilters, ...newsFilters];

  const showResetButton =
    isMostReadSelected ||
    newsFilters.some(
      f => countFilterKeyChecked({ filters, filterKey: f.key }).some
    );

  return (
    <>
      {entity && (
        <S.ListTitle>
          {entity.name} {t('news:title')}
        </S.ListTitle>
      )}
      <S.NewsViewRoot data-testid="news-lists-container">
        <S.LeftContent>
          <NewsList filters={newsListFilters} />
        </S.LeftContent>
        <S.RightContent>
          <HighlightsFilters
            isFetchMostRead={isMostReadSelected}
            toggleIsFetchMostRead={setIsMostReadSelected}
          />
          <SideAccordionFilters
            filters={newsFilters}
            toggleFilterOption={toggleFilterOption}
          />
          {showResetButton && (
            <ClearFiltersButton
              data-testid="reset-all-filters"
              onClick={resetAll}
            />
          )}
        </S.RightContent>
      </S.NewsViewRoot>
    </>
  );
};
