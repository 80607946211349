import { SharedInputStyles, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const SearchInputRoot = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  padding-left: 12px;
  gap: 8px;
  align-items: center;
  flex: 1 0 0;
  border: 1px solid var(--border-soft);
  background-color: var(--surface-default);
  border-radius: 40px;
  height: 40px;
  overflow: hidden;
`;

export const Input = styled.input`
  ${SharedInputStyles};
  ${typographyV2.bodyBase};
  padding: 0;
  width: 100%;
  color: inherit;
`;

export const SearchIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: var(--icon-soft);
  }
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  height: 100%;
  outline: none;
  margin-right: 3px;

  svg {
    fill: var(--icon-soft);
  }

  &:hover,
  &:focus-visible {
    svg {
      fill: var(--icon-default);
    }
  }
`;
