import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Expectation } from '../../types';
import * as S from './ExpectationsSwitcher.styles';

export interface ExpectationsSwitcherProps {
  onChange: (nextExpectation: Expectation) => void;
  expectation: Expectation;
}

const switcherStates = [Expectation.Beat, Expectation.Miss];

export const ExpectationsSwitcher = ({
  onChange,
  expectation,
}: ExpectationsSwitcherProps) => {
  const { t } = useTranslation('calendar');

  const activeIndex = switcherStates.indexOf(expectation);

  const onClick = (nextIndex: number) => {
    onChange(switcherStates[nextIndex]);
  };

  return (
    <S.ExpectationsSwitcherRoot
      data-testid="expectations-switcher"
      size="small"
    >
      <Trans
        t={t}
        i18nKey="earnings:expectationSwitcher"
        components={[
          <S.CustomButtonGroup
            key="key-0"
            labels={[t('common:beats'), t('common:misses')]}
            activeIndex={activeIndex}
            onClick={onClick}
          />,
        ]}
      />
    </S.ExpectationsSwitcherRoot>
  );
};
