import { Button, HighLowChart } from '@toggle/design-system';
import { useElemSize } from '@toggle/helpers/src/hooks/use-elem-size/useElemSize';
import { EarningsScenarioResponse, MappedEntity } from '@toggle/toggle';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { TabsType } from '~/modules/tab-content/types';

import { EarningState, Expectation } from '../../types';
import { getEarningState, getScenario } from '../../utils';
import { ChartEmptyStates } from './components/chart-empty-states/ChartEmptyStates';
import { ChartFooter } from './components/chart-footer/ChartFooter';
import { ChartLegend } from './components/chart-legend/ChartLegend';
import { ChartTitle } from './components/chart-title/ChartTitle';
import * as S from './EarningsChart.styles';

const padding = {
  left: 85,
  right: 150,
};

export interface EarningsChartProps {
  expectation: Expectation;
  earningsData: EarningsScenarioResponse;
  entity: MappedEntity;
}

export const EarningsChart = ({
  expectation,
  earningsData,
  entity,
}: EarningsChartProps) => {
  const { t } = useTranslation('earnings');
  const chartWrapperRef = useRef(null);
  const { width } = useElemSize(chartWrapperRef);
  const { navigateToPage } = useNavigateToAssetOverview();

  const scenario = getScenario(earningsData, expectation);
  const mappedData = scenario?.data.map(item => ({
    high: item.high / 100,
    median: item.median / 100,
    low: item.low / 100,
    label: t(item.period),
  }));

  if (!scenario || !mappedData) {
    return (
      <ChartEmptyStates
        earningsData={earningsData}
        expectation={expectation}
        scenario={scenario}
      />
    );
  }

  const historyMetaCount = scenario.history?.meta.count;
  const searchParams = new URLSearchParams();

  const { eps } = earningsData;

  if (eps.surprise) {
    searchParams.append(
      'epsFilter',
      getEarningState(eps) === EarningState.Beat ? 'beats' : 'misses'
    );
    searchParams.append('epsValue', eps.surprise.toString());
  }

  return (
    <S.EarningsChartRoot data-testid="earnings-chart">
      <S.Title>
        <ChartTitle
          count={historyMetaCount}
          expectation={expectation}
          earningsData={earningsData}
        />
        {scenario && (
          <Button
            data-testid="dig-into-button"
            type="button"
            size="small"
            label={t('earnings:digIntoTheData')}
            onClick={() => {
              navigateToPage({
                entityTag: entity.tag,
                tab: TabsType.Earnings,
                searchParams,
              });
            }}
          />
        )}
      </S.Title>
      <S.Content>
        <ChartLegend />
        <S.ChartWrapper ref={chartWrapperRef} data-testid="chart-container">
          <HighLowChart
            data={mappedData}
            width={width}
            height={216}
            padding={padding}
          />
        </S.ChartWrapper>
        <ChartFooter />
      </S.Content>
    </S.EarningsChartRoot>
  );
};
