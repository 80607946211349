import { AlertType, Button } from '@toggle/design-system';
import { Entity } from '@toggle/toggle';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlockerFunction,
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router';

import { useRowsSelection } from '~/hooks/use-rows-selection/useRowsSelection';
import { useWatchlistActions } from '~/hooks/use-watchlist-actions/useWatchlistActions';
import { appPaths } from '~/routes/app-paths';
import { postMultipleEntities } from '~/services/entities/entity-service';
import { resolveEntitiesFromCsv } from '~/stores/use-watchlist/entityResolver';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { WatchlistError } from '~/stores/use-watchlist/watchlist-types';
import { useWatchlistManagerWidget } from '~/stores/use-watchlist-manager-widget/useWatchlistManagerWidget';

import { ChatWatchlistManager } from '../chat-watchlist-manager-widget/ChatWatchlistManagerWidget';
import { TableWatchlistManager } from '../table-watchlist-manager-widget/TableWatchlistManagerWidget';
import { ConfirmationModal } from './components/confirmation-modal/ConfirmationModal';
import { WatchlistManagerHeader } from './components/watchlist-manager-header/WatchlistManagerHeader';
import * as S from './WatchlistManagerWidget.styles';

export interface WatchlistManagerWidgetProps {
  onListCreated: (id: string) => void;
  onCancel?: () => void;
  className?: string;
}

export const WatchlistManagerWidget = ({
  className,
  onListCreated,
  onCancel,
}: WatchlistManagerWidgetProps) => {
  const { t } = useTranslation(['watchlistManager', 'common']);
  const navigate = useNavigate();
  const [entitiesList, setEntitiesList] = useState<Entity[]>([]);
  const [countEntitiesAdded, setCountEntitiesAdded] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [isRenameActive, setIsRenameActive] = useState(false);
  const [listName, setListName] = useState('');
  const [hasExceededUsageLimit, setHasExceededUsageLimit] = useState(false);
  const [chatInputState, setChatInputState] = useState({
    value: '',
    isAssetMessage: true,
  });
  const shadowContainerRef = useRef<HTMLDivElement>(null);
  const { watchlistId } = useParams();
  const { lists, updateList } = useWatchlist(state => ({
    lists: state.lists,
    updateList: state.updateList,
  }));

  const { isAddMode, onConfirmCallback, shouldResetList } =
    useWatchlistManagerWidget(state => ({
      isAddMode: state.isAddMode,
      onConfirmCallback: state.onConfirmCallback,
      shouldResetList: state.shouldResetList,
    }));

  const activeListInAddMode =
    isAddMode && watchlistId
      ? lists.find(list => list.id === watchlistId)
      : undefined;

  const {
    createNewList,
    watchlistErrorText,
    setWatchlistError,
    createListLoading,
  } = useWatchlistActions();

  const {
    rowSelection,
    setRowSelection,
    updateRowSelection,
    toggleAllRowsSelection,
  } = useRowsSelection(entitiesList);

  const onUpdateName = (name: string) => {
    setListName(name);

    if (name !== listName) {
      setWatchlistError(undefined);
    }
  };

  const updateEntitiesList = (entities: Entity[]) => {
    setEntitiesList(entities);
    const updatedRowSelection = entities.reduce<Record<string, boolean>>(
      (acc, data) => {
        acc[data.tag] = true;
        return acc;
      },
      {}
    );
    setRowSelection(updatedRowSelection);
    setRowSelection(updatedRowSelection);
  };

  const onSelectItem = (entity: Entity, isInWatchlist: boolean) => {
    if (isInWatchlist) {
      removeEntity(entity);
      setWatchlistError(undefined);
    } else {
      setCountEntitiesAdded(1);
      updateEntitiesList([entity, ...entitiesList]);
      setTimeout(() => setCountEntitiesAdded(0), 300);
    }
  };

  const onChatSubmit = async (value: string) => {
    setIsPending(true);
    const tags = await resolveEntitiesFromCsv(value);
    const newTags = tags.filter(tag => !entitiesList.some(e => e.tag === tag));
    const newEntities = await postMultipleEntities(newTags);

    setCountEntitiesAdded(newEntities.length);
    updateEntitiesList([...newEntities, ...entitiesList]);
    setWatchlistError(undefined);
    setIsPending(false);
    setTimeout(() => setCountEntitiesAdded(0), 1000);
  };

  const onConfirmSuccess = (id: string) => {
    setListName('');
    updateEntitiesList([]);
    setTimeout(() => onListCreated(id), 4);
  };

  const activeTags = shouldResetList
    ? []
    : activeListInAddMode?.entities.map(e => e.tag) || [];

  const onConfirm = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    if (!isAddMode && !listName) {
      setWatchlistError(WatchlistError.EmptyName);
      setIsRenameActive(true);
      return false;
    }

    const tags = Object.keys(rowSelection).reduce<string[]>((res, tag) => {
      if (rowSelection[tag]) {
        res.push(tag);
      }

      return res;
    }, []);

    if (!isAddMode && !tags.length) {
      setWatchlistError(WatchlistError.EmptyList);
      return false;
    }

    if (activeListInAddMode && watchlistId) {
      await updateList({
        id: watchlistId,
        name: activeListInAddMode.name,
        tags: [...tags, ...activeTags],
      });
      onConfirmSuccess(watchlistId);
      onConfirmCallback?.();

      return !!watchlistId;
    }

    let listId: string | null = null;

    try {
      listId = await createNewList(listName, tags);

      if (listId) {
        onConfirmSuccess(listId);
      }
    } catch (error) {
      if (error === WatchlistError.UsageLimitExceeded) {
        setHasExceededUsageLimit(true);
      } else {
        setIsRenameActive(true);
      }
    }

    return !!listId;
  };

  const removeEntity = (entity: Entity) => {
    updateEntitiesList(entitiesList.filter(d => d.tag !== entity.tag));
  };

  const getIsInWatchlist = (item: Entity) =>
    entitiesList.some(d => d.tag === item.tag);

  const saveAndExit = async () => {
    const succeed = await onConfirm();

    if (succeed) {
      blocker.proceed?.();
    } else {
      blocker.reset?.();
    }
  };

  const onChatInputValueChange = (value: string, isAssetMessage = false) => {
    setChatInputState({ value, isAssetMessage });
  };

  const onItemsShowChange = (show: boolean) => {
    shadowContainerRef.current?.toggleAttribute('data-overlay', show);
  };

  const hasUnsavedChanges = !!listName || !!entitiesList.length;
  const shouldBlock = useCallback<BlockerFunction>(
    () => hasUnsavedChanges && !hasExceededUsageLimit,
    [hasUnsavedChanges, hasExceededUsageLimit]
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked' && !hasUnsavedChanges) {
      blocker.proceed();
    }
  }, [blocker, hasUnsavedChanges]);

  const hasEntities = !!entitiesList.length;
  const selectedEntitiesCount = Object.values(rowSelection).filter(
    x => !!x
  ).length;
  const showActionButtons =
    hasEntities && (chatInputState.isAssetMessage || !chatInputState.value);

  return (
    <S.Container className={className} data-testid="watchlist-manager-widget">
      <WatchlistManagerHeader
        listName={listName}
        onUpdateName={onUpdateName}
        isRenameActive={isRenameActive}
        setIsRenameActive={setIsRenameActive}
        hasEntities={hasEntities}
        hasInputError={!!watchlistErrorText}
        activeListName={activeListInAddMode?.name}
      />
      <S.ContentContainer>
        {hasEntities && (
          <TableWatchlistManager
            data={entitiesList}
            countEntitiesAdded={countEntitiesAdded}
            toggleAllRowsSelection={toggleAllRowsSelection}
            rowSelection={rowSelection}
            updateRowSelection={updateRowSelection}
            shadowContainerRef={shadowContainerRef}
          />
        )}
        {!hasExceededUsageLimit && (
          <>
            {hasEntities && (
              <S.Label>{t('watchlistManager:wantAddMore')}</S.Label>
            )}
            <ChatWatchlistManager
              value={chatInputState.value}
              isPending={isPending}
              getIsInWatchlist={getIsInWatchlist}
              onSelectItem={onSelectItem}
              onChatSubmit={onChatSubmit}
              onChange={onChatInputValueChange}
              onItemsShowChange={onItemsShowChange}
              activeListEntityTags={activeTags}
            />
          </>
        )}
      </S.ContentContainer>
      {hasExceededUsageLimit && (
        <S.StyledAlert
          variant="inline"
          type={AlertType.Warning}
          title={t('watchlistManager:watchlistLimitReached')}
          message={t('watchlistManager:upgradeToCreateMore')}
          primaryBtn={{
            variant: 'primary',
            onClick: () => {
              navigate(appPaths.settings.subscription);
            },
            label: t('watchlistManager:upgrade'),
            size: 'large',
          }}
        />
      )}
      {showActionButtons && (
        <S.FooterContainer>
          {watchlistErrorText && (
            <>
              <S.StyledIcon
                size={20}
                iconName="ExclamationCircle"
                fillColor="var(--icon-critical)"
              />
              <S.ErrorMessage data-testid="error-message">
                {watchlistErrorText}
              </S.ErrorMessage>
            </>
          )}
          <S.FlexContainer>
            {onCancel && (
              <Button
                label={t('common:cancel')}
                variant="tertiary"
                size="large"
                onClick={onCancel}
              />
            )}
            <Button
              label={
                activeListInAddMode
                  ? t(
                      'watchlistManager:confirmationModal.addAssetsToWatchlist',
                      {
                        count: selectedEntitiesCount,
                      }
                    )
                  : t('watchlistManager:confirmationModal.save')
              }
              variant="primary"
              size="large"
              onClick={onConfirm}
              isLoading={createListLoading}
              disabled={createListLoading || hasExceededUsageLimit}
              data-testid="watchlist-manager-confirm-button"
            />
          </S.FlexContainer>
        </S.FooterContainer>
      )}
      {blocker.state === 'blocked' && (
        <ConfirmationModal
          onCancel={blocker.proceed}
          onClose={blocker.reset}
          onConfirm={saveAndExit}
          hasActiveListInAddMode={!!activeListInAddMode}
          selectedEntitiesCount={selectedEntitiesCount}
        />
      )}
    </S.Container>
  );
};
