import { StyledComponent } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessageWrapper } from '~/components/error-message-wrapper/ErrorMessageWrapper';
import { SectionTitle } from '~/components/section-title/SectionTitle';

import { KeyValueElement } from './components/KeyValueElement';
import { useKeyValues } from './hooks/use-key-values/useKeyValues';
import * as S from './OverviewKeyValues.styles';

export interface OverviewKeyValuesProps {
  entityTag: string;
}

export const OverviewKeyValues: StyledComponent<
  OverviewKeyValuesProps,
  typeof S
> = ({ entityTag }: OverviewKeyValuesProps) => {
  const { t } = useTranslation('widget');

  const { data, isError, refetch, isRefetching, isLoading } =
    useKeyValues(entityTag);

  if (isLoading || isRefetching) {
    return <S.KeyValueSkeletonLoader data-testid="loading-state" />;
  }

  if (isError) {
    return (
      <ErrorMessageWrapper
        data-testid="error-state"
        onClick={refetch}
        displayIcon
      />
    );
  }

  if (!data?.length) {
    return null;
  }

  const filteredData = data.filter(d => d.value_fmt);
  return (
    <S.OverviewKeyValuesRoot data-testid="overview-key-values">
      <SectionTitle title={t('widget:assetOverview.keyValuesTitle')} />
      <S.KeyValuesList>
        {filteredData.map(keyValue => (
          <KeyValueElement key={keyValue.key} keyValue={keyValue} />
        ))}
      </S.KeyValuesList>
    </S.OverviewKeyValuesRoot>
  );
};

OverviewKeyValues.Styled = S;
