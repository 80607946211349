import React, { FC } from 'react';

import * as S from './PlainIcon.styles';
import { Tooltip, TooltipTrigger } from '~/components/tooltip/Tooltip';

const TOOLTIP_TRIGGER_SIZE = 12;

interface DirectionalIconProps {
  mostRecent?: boolean;
  translateX?: number;
  translateY?: number;
  direction: string;
  valueString: string;
  dateString: string;
}

export const PlainIcon: FC<DirectionalIconProps> = ({
  mostRecent = false,
  translateX = 0,
  translateY = 0,
  direction,
  valueString,
  dateString,
}) => {
  return (
    <Tooltip
      isTouchDevice={false}
      trigger={TooltipTrigger.Hover}
      label={
        <S.LabelWrapper>
          <S.Date>{dateString}</S.Date>
          <S.Value>{valueString}</S.Value>
        </S.LabelWrapper>
      }
      inPortal
      aria-label={`${dateString}: ${valueString}`}
    >
      <g
        transform={`translate(${translateX}, ${translateY})`}
        className="plain-icon-group"
      >
        <rect
          width={TOOLTIP_TRIGGER_SIZE}
          height={TOOLTIP_TRIGGER_SIZE}
          fill="none"
          transform={`translate(${-TOOLTIP_TRIGGER_SIZE / 2}, ${
            -TOOLTIP_TRIGGER_SIZE / 2
          })`}
        />
        <g data-testid={`plain-icon-wrapper${mostRecent ? '-last' : ''}`}>
          {!mostRecent ? (
            <S.CircleIcon direction={direction} />
          ) : (
            <>
              <S.CircleIcon direction={direction} />
              <S.CircleRingIcon direction={direction} />
            </>
          )}
        </g>
      </g>
    </Tooltip>
  );
};
