import {
  Portal,
  ScrollingIndicator,
  StyledComponent,
} from '@toggle/design-system';
import { keyboard, useDisableScroll } from '@toggle/helpers';
import React, { ReactNode, useEffect, useRef } from 'react';

import { useScrollingIndicator } from '~/hooks/use-scrolling-indicator/useScrollingIndicator';
import { useApp } from '~/stores/use-app/useApp';

import { ChatQuestionResponse, ChatQuestionResponseProps } from '../chat';
import * as S from './OverlayModal.styles';

export interface OverlayModalProps {
  onClose: VoidFunction;
  title: ReactNode;
  children: ReactNode;
  className?: string;
  chatPrompts?: ChatQuestionResponseProps['chatPrompts'];
}

const ADDITIONAL_BOTTOM_OFFSET = 20;

export const OverlayModal: StyledComponent<OverlayModalProps, typeof S> = ({
  className,
  title,
  children,
  onClose,
  chatPrompts,
}) => {
  const { isSidebarExpanded } = useApp(state => ({
    isSidebarExpanded: state.isSidebarExpanded,
  }));
  const backdrop = useRef<HTMLDivElement>(null);

  const { containerRef, contentRef, showScrollingIndicator, scrollToBottom } =
    useScrollingIndicator<HTMLDivElement, HTMLDivElement>(
      ADDITIONAL_BOTTOM_OFFSET
    );
  useDisableScroll(true);

  useEffect(() => {
    const current = backdrop.current;

    if (!current) {
      return undefined;
    }

    const keyHandler = (e: KeyboardEvent) => {
      if (e.key === keyboard.esc.key) {
        onClose();
      }
    };

    const clickHandler = (e: MouseEvent) => {
      e.target === current && onClose();
    };

    current.addEventListener('click', clickHandler);
    window.addEventListener('keyup', keyHandler);

    return () => {
      current.removeEventListener('click', clickHandler);
      window.removeEventListener('keyup', keyHandler);
    };
  }, [onClose]);

  return (
    <Portal>
      <S.Container className={className} data-testid="overlay-modal">
        <S.Backdrop data-testid="modal-backdrop" ref={backdrop} />
        <S.BodyContainer
          role="dialog"
          ref={containerRef}
          $isSidebarExpanded={isSidebarExpanded}
        >
          <ChatQuestionResponse
            contentRef={contentRef}
            title={title}
            chatPrompts={chatPrompts}
          >
            {children}
          </ChatQuestionResponse>
          {showScrollingIndicator && (
            <ScrollingIndicator scrollToBottom={scrollToBottom} />
          )}
        </S.BodyContainer>
      </S.Container>
    </Portal>
  );
};

OverlayModal.Styled = S;
