import { Button, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const WatchlistManagerTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h4`
  ${typographyV2.titleLg};
`;

export const StyledButton = styled(Button)`
  path {
    fill: var(--icon-primary);
  }
`;
