import { RangeHorizon } from '@toggle/chart';
import { useElemSize } from '@toggle/helpers';
import { Entity, formatTicker } from '@toggle/toggle';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import { SectionTitle } from '~/components/section-title/SectionTitle';
import { appPaths } from '~/routes/app-paths';
import { useChartTheme } from '~/views/turbo-chart/hooks/use-chart-theme/useChartTheme';
import {
  getChartSubPageSearchParams,
  SnakeTypes,
} from '~/widgets/chart-peer-group/utils/chart-subpage-navigation/chart-subpage-navigation';
import { CompetitorsData } from '~/widgets/overview/utils';

import { ValuationMetrics } from '../valuation-metrics/ValuationMetrics';
import * as S from './OverviewValuationMetric.styles';
import { useValuationSnakeData } from './useValuationSnakeData';
import {
  CHARTS_GAP,
  DISPLAYED_ENTITIES,
  getBarChartDimension,
  SNAKES_NTM,
  SNAKES_TTM,
} from './utils';

export interface OverviewValuationProps {
  entity: Entity;
  competitorsData: CompetitorsData;
}

export const OverviewValuation = ({
  entity,
  competitorsData,
}: OverviewValuationProps) => {
  const { t } = useTranslation('widget');
  const { colors } = useChartTheme(['soft', 'multiline']);
  const [activeValuation, setActiveValuation] = useState(0);
  const [searchParams] = useSearchParams();

  const chartSubPageSearchParams = getChartSubPageSearchParams({
    entityTag: entity.tag,
    prevSearchParams: searchParams,
    horizon: RangeHorizon.OneYear,
    snakeType: SnakeTypes.ValuationMetrics,
  }).toString();

  const containerRef = useRef(null);
  const { width } = useElemSize(containerRef);
  const isDrawer = width < 600;

  const multiColors = colors.getMulti(competitorsData.competitors.length);
  const formattedCompetitorsData =
    competitorsData.competitors
      .slice(0, DISPLAYED_ENTITIES - 1)
      .map((s, index) => ({
        tag: s.entity.tag,
        formattedTicker: formatTicker(s.entity),
        color: multiColors[index + 1].value,
      })) ?? [];

  const companies = [
    {
      tag: entity.tag,
      formattedTicker: formatTicker(entity),
      color: multiColors[0].value,
    },
    ...formattedCompetitorsData,
  ];

  const linkToChartSubPage = `${appPaths.assetOverview}?${chartSubPageSearchParams}`;

  const variants = {
    ttm: SNAKES_TTM,
    ntm: SNAKES_NTM,
  };
  const { data, isLoading } = useValuationSnakeData({
    snakes: variants,
    companies,
    enabled: !competitorsData.isLoading,
  });

  const hasDataTTM = data.ttm.length;
  const hasDataNTM = data.ntm.length;

  if ((!hasDataTTM && !hasDataNTM) || isLoading) {
    return null;
  }

  const labels = [
    { label: t('widget:assetOverview.ttm'), enabled: hasDataTTM },
    { label: t('widget:assetOverview.ntm'), enabled: hasDataNTM },
  ]
    .filter(l => l.enabled)
    .map(l => l.label);

  const variantData = Object.values(data)[activeValuation] as {
    data: {
      value: number;
      index: string;
    }[];
    snake: string;
  }[];

  const groupedData = Object.groupBy(variantData, s => s.snake.split('.')[1]);
  return (
    <div>
      <S.Header>
        <S.SectionTitleWrapper>
          <SectionTitle
            title={t('widget:assetOverview.valuationMetrics')}
            linkTo={linkToChartSubPage}
          />
        </S.SectionTitleWrapper>
        <S.StyledButtonGroup
          data-testid={'valuation-metrics-ttm-htm-button-group'}
          labels={labels}
          onClick={setActiveValuation}
        />
      </S.Header>
      <S.Flex ref={containerRef} $chartsGap={CHARTS_GAP}>
        {Object.entries(groupedData).map(
          ([snakeName, snakesData], index, arr) => (
            <ValuationMetrics
              key={index}
              snakesData={snakesData ?? []}
              snakeName={snakeName}
              companies={companies}
              {...getBarChartDimension({
                width,
                isDrawer,
                chartsLength: arr.length,
              })}
            />
          )
        )}
      </S.Flex>
    </div>
  );
};
