import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { borderRadius } from '~/design-tokens/border-radius/borderRadius';
import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

import { Separator } from '../DropdownMenu.styles';

export const RadioGroup = styled(RadixDropdown.RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RadioGroupWrapper = styled.div`
  ${Separator} {
    margin-top: 4px;
  }
`;

export const Label = styled(RadixDropdown.Label)`
  ${typographyV2.bodyXs}
  color: var(--text-soft);
  padding: 2px 16px;
  text-transform: uppercase;
`;

export const RadioItemWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding-right: 8px;
`;
export const RadioItemSelected = styled.div`
  border-top-right-radius: 2px;
  border-bottom-right-radius: 4px;
  width: 4px;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  ${borderRadius.r1}
  width: 100%;
  gap: 8px;

  :hover {
    background-color: var(--surface-hover);
    cursor: pointer;
  }
`;

export const RadioItemLabel = styled.span`
  ${typographyV2.bodySm}
  width: 100%;
`;

export const RadioItem = styled(RadixDropdown.RadioItem)`
  all: unset;

  &[data-state='checked'] {
    ${RadioItemSelected} {
      background-color: var(--surface-primary);
    }

    ${RadioButton} {
      background-color: var(--surface-active);
    }
  }
`;
