import { Chip } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RangeHorizon } from '~/types/resample.types';

import * as S from './ChipHorizonSelector.styles';

export interface ChipHorizonSelectorProps {
  horizonRanges: RangeHorizon[];
  selectedRange: RangeHorizon | null;
  disabledHorizons?: RangeHorizon[];
  onChange: (rangeHorizon: RangeHorizon) => void;
}

export const ChipHorizonSelector = ({
  horizonRanges,
  selectedRange,
  onChange,
  disabledHorizons = [],
}: ChipHorizonSelectorProps) => {
  const { t } = useTranslation('chart');

  return (
    <S.ChipHorizonSelectorRoot data-testid="horizon-selector">
      {horizonRanges.map(range => (
        <Chip
          data-testid={`${range}-horizon`}
          key={range}
          label={t('chart:intervals.horizon', {
            context: range,
          })}
          type="choice"
          disabled={disabledHorizons.includes(range)}
          isActive={range === selectedRange}
          onClick={() => onChange(range)}
        />
      ))}
    </S.ChipHorizonSelectorRoot>
  );
};
