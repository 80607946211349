import styled, { css } from 'styled-components';

import { typographyV2 } from '../../design-tokens/typography/Typography.styles';
import { Icon } from '../icon/Icon';

type ToggleSize = 'medium' | 'small';

interface SliderIconProps {
  $isVisible: boolean;
  $size: ToggleSize;
}

interface CircleProps {
  $isChecked: boolean;
  $size: ToggleSize;
}
interface ToggleContainerProps {
  $isChecked: boolean;
  $isBackgroundActive?: boolean;
  $size: ToggleSize;
}

export const ToggleRoot = styled.label<{ $disabled: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.$disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const LabelText = styled.span<{ $isSolid: boolean }>`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  white-space: nowrap;
  ${({ $isSolid }) =>
    $isSolid &&
    css`
      color: var(--text-default);
    `}
`;

export const StyledIcon = styled(Icon).attrs<SliderIconProps>(({ $size }) => ({
  size: $size === 'medium' ? 14 : 10,
}))<SliderIconProps>`
  fill: var(--icon-on-primary);
  transition: opacity 0.3s ease;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
`;

export const ToggleContainer = styled.span<ToggleContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--surface-neutral);
  transition: background-color 0.3s ease;
  border-radius: 32px;
  height: ${({ $size }) => ($size === 'medium' ? '24px' : '18px')};
  width: ${({ $size }) => ($size === 'medium' ? '44px' : '32px')};

  ${({ $isBackgroundActive, $isChecked }) =>
    ($isBackgroundActive || $isChecked) &&
    css`
      background-color: var(--surface-primary);
    `}
`;

export const Circle = styled.span<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 20px;
  left: 2px;
  background-color: var(--surface-white);
  transition: transform 0.3s ease;
  height: ${props => (props.$size === 'medium' ? '20px' : '14px')};
  width: ${props => (props.$size === 'medium' ? '20px' : '14px')};
  transform: ${props =>
    props.$isChecked ? 'translateX(100%)' : 'translateX(0)'};
`;

export const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:focus-visible + ${ToggleContainer} {
    outline: 1px solid var(--border-focus);
  }
`;
