import { Toggle, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const ContextHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  align-items: center;
  border-bottom: 1px solid var(--border-soft);
`;

export const StyledToggle = styled(Toggle)`
  ${Toggle.Styled.ToggleContainer} {
    height: 12px;
    width: 22px;
  }

  ${Toggle.Styled.Circle} {
    width: 9px;
    height: 9px;
  }

  ${Toggle.Styled.StyledIcon} {
    width: 7px;
    height: 7px;
  }
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  gap: 0.35em;
  padding-right: 8px;
`;

export const HeaderTextDefault = styled.span`
  ${typographyV2.bodyXs};
`;

export const HeaderTextPrimary = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-primary);
`;

export const HeaderTextDisabled = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-disabled);
`;
