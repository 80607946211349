export enum ONBOARDING_KEYS {
  ONBOARDING_TRADE_PREFERENCE = 'ONBOARDING_TRADE_PREFERENCE',
  ONBOARDING_INVESTMENT_STYLE = 'ONBOARDING_INVESTMENT_STYLE',
  ONBOARDING_NAVIGATION_OPTION = 'ONBOARDING_NAVIGATION_OPTION',
  ONBOARDING_TOS_ACCEPTANCE = 'ONBOARDING_TOS_ACCEPTANCE',
  ONBOARDING_PP_ACCEPTANCE = 'ONBOARDING_PP_ACCEPTANCE',
  ONBOARDING_INITIAL_EXPLORE_FILTERS = 'ONBOARDING_INITIAL_EXPLORE_FILTERS',
  ONBOARDING_TRADE_OPTIONS = 'ONBOARDING_TRADE_OPTIONS',
  ONBOARDING_INVESTOR_STYLE = 'ONBOARDING_INVESTOR_STYLE',
  ONBOARDING_INVESTOR_ACTION = 'ONBOARDING_INVESTOR_ACTION',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  ONBOARDING_SKIPPED = 'ONBOARDING_SKIPPED',
}

export enum POP_UP_KEYS {
  EXPLORE_BANNER_NO_REPEAT = 'EXPLORE_BANNER_NO_REPEAT',
  MY_TOGGLE_BANNER_NO_REPEAT = 'MY_TOGGLE_BANNER_NO_REPEAT',
  PORTFOLIO_CONNECTION_BANNER_NO_REPEAT = 'PORTFOLIO_CONNECTION_BANNER_NO_REPEAT',
}

export enum SubscriptionAlertKeys {
  WasTrial = 'TRIAL_STARTED',
  ShowDowngradeAlert = 'SHOW_DOWNGRADE_ALERT',
  SubscriptionEndedInlineAlertDismissed = 'SUBSCRIPTION_ENDED_INLINE_ALERT_DISMISSED',
}

export type ContinueContextButtonKeys =
  | 'CHAT_DOCUMENT_SEARCH_CONTEXT_SEEN'
  | 'CHAT_DOCUMENT_SEARCH_CONTEXT_SEEN_DOC_VIEW';

export const AO_SELECTED_HORIZON = 'AO_SELECTED_HORIZON';
export const THEME_NAME_STORAGE_KEY = 'THEME_NAME';
export const FAVORITE_WATCHLIST = 'FAVORITE_WATCHLIST';
export const CHART_WIDGET_RECENTLY_OPENED_ENTITIES =
  'CHART_WIDGET_RECENTLY_OPENED_ENTITIES';
export const AO_WIDGET_RECENTLY_OPENED_ENTITIES =
  'AO_WIDGET_RECENTLY_OPENED_ENTITIES';
export const RECENTLY_OPENED_THEMES_OR_ENTITIES =
  'RECENTLY_OPENED_THEMES_OR_ENTITIES';
export const DASHBOARD_VIEW_MODE = 'DASHBOARD_VIEW_MODE';
export const NEWS_RECENT_SEARCHES = 'NEWS_RECENT_SEARCHES';

export type STORAGE_KEYS =
  | ONBOARDING_KEYS
  | POP_UP_KEYS
  | SubscriptionAlertKeys
  | typeof AO_SELECTED_HORIZON
  | typeof THEME_NAME_STORAGE_KEY
  | typeof FAVORITE_WATCHLIST
  | typeof CHART_WIDGET_RECENTLY_OPENED_ENTITIES
  | typeof AO_WIDGET_RECENTLY_OPENED_ENTITIES
  | typeof DASHBOARD_VIEW_MODE
  | typeof RECENTLY_OPENED_THEMES_OR_ENTITIES
  | typeof NEWS_RECENT_SEARCHES
  | ContinueContextButtonKeys
  | 'feature_flags';

export type STORAGE_ITEMS = Partial<
  Record<STORAGE_KEYS, string | string[] | object | boolean>
>;
