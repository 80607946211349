import d3 from 'd3';
import React, { useMemo } from 'react';

import { SpxTsData } from '../BandChart.types';
import { getDirectionalArrays } from '../section-utils/section-utils';
import * as S from './SectionArea.style';

export interface SectionAreaProps {
  ts: SpxTsData[];
  scaleX: d3.ScaleLinear<number, number>;
  y?: number;
  height: number;
}

export const SectionArea = ({
  ts,
  scaleX,
  height,
  y = 0,
}: SectionAreaProps) => {
  const sectionRanged = useMemo(() => {
    return getDirectionalArrays(ts);
  }, [ts]);

  return (
    <S.SectionAreaWrapper data-testid="section-areas">
      {sectionRanged.map(sectionArray => {
        const firstElement = sectionArray[0];
        const lastElement = sectionArray[sectionArray.length - 1];

        if (firstElement.direction) {
          return (
            <g transform={`translate( 0, ${-y})`} key={firstElement.index}>
              <S.Rectangle
                data-testid={`section-rect-${firstElement.direction}`}
                x={scaleX(firstElement.index)}
                y={0}
                width={Math.abs(
                  scaleX(lastElement.index - firstElement.index || 1)
                )}
                height={Math.abs(height + y)}
                $direction={firstElement.direction}
              />
            </g>
          );
        }

        return null;
      })}
    </S.SectionAreaWrapper>
  );
};
