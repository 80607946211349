import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import countryCodesAlpha3 from '~/utils/country-iso-codes/country-iso-alpha-three.json';
import countryCodes from '~/utils/country-iso-codes/country-iso-codes.json';
import regionalCodes from '~/utils/country-iso-codes/regional-codes.json';

import * as S from './FlagIcon.styles';

export interface FlagIconProps extends HTMLAttributes<HTMLSpanElement> {
  countryCode?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  rounded?: boolean;
  circular?: boolean;
}

export const FlagIcon = ({
  countryCode = '',
  size = 'md',
  className,
  rounded,
  circular,
}: FlagIconProps) => {
  const countryCodeUpperCase = countryCode.toUpperCase();

  if (!countryCode) {
    return null;
  }

  if (
    !countryCodesAlpha3.includes(countryCodeUpperCase) &&
    !countryCodes.includes(countryCodeUpperCase) &&
    !regionalCodes.includes(countryCodeUpperCase)
  ) {
    return (
      <S.Fallback $size={size} data-testid="fallback">
        {countryCodeUpperCase}
      </S.Fallback>
    );
  }

  const flagClassNames = classNames(
    `fflag-${countryCodeUpperCase}`,
    className,
    { 'ff-round': circular }
  );

  return <S.Flag $size={size} $rounded={rounded} className={flagClassNames} />;
};
