import { ChartColor } from '@toggle/chart';
import {
  DesignToken,
  getThemeColor as dsGetThemeColor,
} from '@toggle/design-system';

import { useThemeStore } from '~/components/theme-controller/stores/useThemeStore';

export type UseChartThemeReturn = ReturnType<typeof useChartTheme>;

export type ThemeVariant = 'default' | 'soft' | 'multiline' | 'customMulti';

export const useChartTheme = (variants: ThemeVariant[] = ['default']) => {
  const themeName = useThemeStore(state => state.themeName);

  const getThemeColor = (colorToken: string) =>
    dsGetThemeColor(colorToken as DesignToken, themeName);

  const defaultColor = {
    value: getThemeColor('--surface-inverse'),
    token: '--surface-inverse',
  };

  const getMulti = (length?: number) => {
    const multi = [
      {
        value: getThemeColor('--chart-multi-lines-hex-1'),
        token: '--chart-multi-lines-hex-1',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-2'),
        token: '--chart-multi-lines-hex-2',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-3'),
        token: '--chart-multi-lines-hex-3',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-4'),
        token: '--chart-multi-lines-hex-4',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-5'),
        token: '--chart-multi-lines-hex-5',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-6'),
        token: '--chart-multi-lines-hex-6',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-7'),
        token: '--chart-multi-lines-hex-7',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-8'),
        token: '--chart-multi-lines-hex-8',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-9'),
        token: '--chart-multi-lines-hex-9',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-10'),
        token: '--chart-multi-lines-hex-10',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-11'),
        token: '--chart-multi-lines-hex-11',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-12'),
        token: '--chart-multi-lines-hex-12',
      },
      {
        value: getThemeColor('--chart-multi-lines-hex-13'),
        token: '--chart-multi-lines-hex-13',
      },
    ];

    if (variants.includes('multiline')) {
      return Array(4).fill(multi).flat() as ChartColor[];
    }

    if (variants.includes('customMulti') && length) {
      return Array.from({ length }, () => defaultColor) as ChartColor[];
    }

    return multi;
  };

  const border = {
    default: {
      value: getThemeColor('--border-default'),
      token: '--border-default',
    },
    soft: { value: getThemeColor('--border-soft'), token: '--border-soft' },
    hover: { value: getThemeColor('--border-hover'), token: '--border-hover' },
  };

  return {
    colors: {
      primary: {
        value: getThemeColor('--border-primary'),
        token: '--border-primary',
      },
      default: defaultColor,
      typography: {
        soft: { value: getThemeColor('--text-soft'), token: '--text-soft' },
        primary: {
          value: getThemeColor('--text-primary'),
          token: '--text-primary',
        },
        highlight: {
          value: getThemeColor('--text-default'),
          token: '--text-default',
        },
      },
      border,

      surface: {
        default: {
          value: getThemeColor('--surface-default'),
          token: '--surface-default',
        },
      },
      line: {
        default: {
          value: getThemeColor('--border-primary'),
          token: '--border-primary',
        },
      },
      threshold: {
        bullish: {
          value: getThemeColor('--viz-bullish-soft'),
          token: '--viz-bullish-soft',
        },
        bearish: {
          value: getThemeColor('--viz-bearish-soft'),
          token: '--viz-bearish-soft',
        },
      },
      paneGradient: {
        value: getThemeColor('--chart-pane-gradient'),
        token: '--chart-pane-gradient',
      },
      bars: {
        up: { value: getThemeColor('--viz-bullish'), token: '--viz-bullish' },
        down: { value: getThemeColor('--viz-bearish'), token: '--viz-bearish' },
      },
      ...(variants.includes('soft') && {
        border: {
          ...border,
          default: border.soft,
        },
      }),
      getMulti,
      getThemeColor,
    },
  };
};
