import { Icon } from '@toggle/design-system';
import styled from 'styled-components';

export const Sun = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-success);
`;

export const Moon = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-soft);
`;

export const SunRise = styled(Icon).attrs({
  size: 16,
})`
  fill: var(--icon-primary);
`;
