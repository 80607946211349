import { ChartAssetData } from '@toggle/chart';
import { Alert, AlertType } from '@toggle/design-system';
import { cryptoRandom } from '@toggle/helpers';
import { Entity } from '@toggle/toggle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';

export interface UseChartErrorsProps {
  chartData: ChartAssetData[];
}

export const useChartErrors = ({ chartData }: UseChartErrorsProps) => {
  const { t } = useTranslation('chart');
  const { showToast, removeToast } = useToasts(
    ({ showToast, removeToast }) => ({
      showToast,
      removeToast,
    })
  );
  const [hasError, setHasError] = useState(false);
  const [hasEmpty, setHasEmpty] = useState(false);
  const [assetWithError, setAssetWithError] = useState<Entity>();

  const handleEmptyChart = (bannerLabel: string) => {
    setHasEmpty(true);

    if (chartData.length > 0) {
      const toastId = cryptoRandom();
      showToast({
        id: toastId,
        scope: 'chart',
        position: ToastPosition.TopRight,
        content: (
          <Alert
            type={AlertType.Warning}
            title={t('chart:widget.emptyBannerTitle', {
              label: bannerLabel,
            })}
            onClose={() => {
              removeToast(toastId);
            }}
          />
        ),
      });
    }
  };

  return {
    chartError: {
      assetWithError,
      setAssetWithError,
      hasError,
      setHasError,
      hasEmpty,
      setHasEmpty,
      handleEmptyChart,
    },
  };
};
