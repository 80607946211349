import * as d3 from 'd3';
import { ScaleLinear, ScaleTime } from 'd3';
import React, { useMemo, useRef } from 'react';

import { TsPoint } from '../../MultiLineChart.types';

export interface MultiLineChartLinePlotProps {
  data: TsPoint[];
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  color: string;
  tooltipDate?: Date;
}

const linePath =
  (xScale: ScaleTime<number, number>, yScale: ScaleLinear<number, number>) =>
  (ts: TsPoint[]) =>
    d3
      .line<TsPoint>()
      .x(d => xScale(d.date))
      .y(d => yScale(d.value))
      .curve(d3.curveLinear)(ts);

export const MultiLineChartLinePlot = ({
  data,
  xScale,
  yScale,
  color,
  tooltipDate,
}: MultiLineChartLinePlotProps) => {
  const pathRef = useRef<SVGPathElement>(null);

  const path = useMemo(
    () => linePath(xScale, yScale)(data),
    [data, xScale, yScale]
  );

  const selectedData = data.find(
    d => d.date.valueOf() === tooltipDate?.valueOf()
  );

  return (
    <g>
      <path
        ref={pathRef}
        data-testid="line-plot"
        vectorEffect="non-scaling-stroke"
        d={path ?? ''}
        stroke={color}
        fill="none"
      />
      {!!selectedData && (
        <circle
          cx={xScale(selectedData.date)}
          cy={yScale(selectedData.value)}
          r="4"
          fill={color}
          stroke="white"
          data-testid="selected-point"
        />
      )}
    </g>
  );
};
