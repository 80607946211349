import { ButtonLink, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.div`
  margin-bottom: 24px;
`;

export const Description = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const StyledButtonLink = styled(ButtonLink)`
  & > span {
    gap: 4px;
  }
`;
