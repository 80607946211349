import { Dropdown, DropdownList, DropdownProps } from '@toggle/design-system';
import { JSX } from 'react';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  ${DropdownList.Styled.DropdownListRoot} {
    box-shadow: none;
    border: none;
    background-color: var(--background-soft);
    max-width: 660px;
    overflow-x: hidden;
    padding-left: 8px;
    border-radius: 12px;
  }
` as <T>(props: DropdownProps<T>) => JSX.Element;
