import { createGlobalStyle } from 'styled-components';

import {
  DEFAULT_THEME,
  ThemeName,
  themes,
} from '~/design-tokens/themes/themes';

export const ThemeOrangeGlobalStyles = createGlobalStyle`
  :root {
    --primary-color: var(--copilot--default);
    --primary-color-dark: var(--copilot--dark);
    --btn-default-bg: var(--copilot--default);
    --btn-hover-bg: var(--copilot--hover);
    --btn-disabled-bg: var(--navy--core);
    --btn-text-color: var(--base--100);
    --input-hover: var(--copilot--dark);
    --primary-outline-btn-background-color: var(--copilot--dark);
    --primary-outline-btn-border-color: var(--copilot--hover);
    --primary-outline-btn-text-color: var(--copilot--hover);
    --btn-text-disabled-color: var(--base--70);
    --logo-color: var(--copilot--default);
    --logo-color-secondary: var(--copilot--default);
    --search-item-bg: var(--copilot--default);
    --list-item-hover-bg: rgba(var(--copilot-default-rgb), 0.16);
    --menu-active-item-border: #5d4e3f;
    --menu-active-item-border-rgb: 93, 78, 63;
    --primary-hover-8pc: rgba(var(--copilot-default-rgb), 0.08);
    --article-sidebar-item-active-bg: rgba(var(--copilot-default-rgb), 0.08);
    --header-notification-tooltip: #3d3329;
  }
`;

export const ThemeGlobalStyles = createGlobalStyle<{ $themeName?: ThemeName }>`
  :root {
    ${props => themes[props.$themeName ?? DEFAULT_THEME]};

    /* Z-indexes */
    --z-index-smarttable-sticky-header-cell: 1;
    --z-tooltip: 22;
    --z-index-modal: 15;
  }
`;
