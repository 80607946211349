import { AssetLogo, SmartTable, SmartTableColumn } from '@toggle/design-system';
import { Entity, formatTicker } from '@toggle/toggle';
import React from 'react';

import * as S from './AssetTable.styles';

export type TableRow = Pick<Entity, 'ticker' | 'name'>;

export interface AssetTableProps {
  entities: Entity[];
  tickerTitle: string;
  nameTitle: string;
  isDarkTheme: boolean;
}

export const AssetTable = ({
  entities,
  tickerTitle,
  nameTitle,
  isDarkTheme,
}: AssetTableProps) => {
  const columns: SmartTableColumn<TableRow>[] = [
    {
      label: tickerTitle,
      key: 'ticker',
      render: (_, item) => (
        <S.TickerCell>
          <AssetLogo
            entity={item as Entity}
            size={24}
            isDarkTheme={isDarkTheme}
            withFallback={true}
          />
          <S.Ticker>{formatTicker(item as Entity)}</S.Ticker>
        </S.TickerCell>
      ),
    },
    {
      label: nameTitle,
      key: 'name',
    },
  ];

  return (
    <S.TableWrapper $rowCount={entities.length}>
      <SmartTable
        columns={columns}
        data={entities.map(entity => ({ ...entity, key: entity.id }))}
        withBodyRowHover={false}
      />
    </S.TableWrapper>
  );
};

AssetTable.Styled = S;
