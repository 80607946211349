/* eslint-disable max-lines-per-function */
import { postEntity } from '~/services/entities/entity-service';
import { create } from '~/stores/create-store/createStore';

import {
  deleteConversation,
  getAllConversations,
  getConversation,
  getConversationMessageStep,
  getConversationMessageWithSteps,
  postConversation,
  postFeedback,
  updateConversation,
} from '../services/conversations-service';
import { ChatStore, Conversation } from './use-chat.types';
import { createNewConversationMessage } from './utils/use-chat-utils';

export const useChat = create<ChatStore>((set, get) => ({
  conversationId: undefined,
  errorInfo: undefined,
  conversations: [],
  controller: new AbortController(),
  abortRequest: () => {
    const { controller, conversations, conversationId } = get();

    const conversation = conversationId
      ? conversations.find(c => c.id === conversationId)
      : undefined;
    const updateConversations: Conversation[] = conversation
      ? conversations.map(c =>
          c === conversation
            ? {
                ...c,
                messages: [
                  ...c.messages.slice(0, -1),
                  { ...c.messages[c.messages.length - 1], status: 'abort' },
                ],
              }
            : c
        )
      : conversations;

    set({ conversations: updateConversations });
    controller.abort();
  },
  addFeedback: ({ messageId, feedback }) => {
    const { conversations, conversationId } = get();
    postFeedback({
      conversation_id: conversationId as string,
      message_id: messageId,
      feedback,
    });

    const newConversations = conversations.map(c => {
      if (c.id === conversationId) {
        return {
          ...c,
          messages: c.messages.map(m =>
            m.id === messageId
              ? {
                  ...m,
                  feedback,
                }
              : m
          ),
        };
      }

      return c;
    });

    set({ conversations: newConversations });
  },
  resendMessage: message => {
    const { conversationId, postQuestion, conversations } = get();

    const newConversations: Conversation[] = conversations.map(c => {
      if (c.id === conversationId) {
        return {
          ...c,
          messages: c.messages.filter(m => m.id !== message.id),
        };
      }

      return c;
    });

    set({ conversations: newConversations });
    postQuestion(message.question, conversationId);
  },
  postQuestion: async (question, conversationId) => {
    const controller = new AbortController();

    set({
      controller,
      errorInfo: undefined,
    });

    try {
      const response = await postConversation({
        message: question,
        conversationId,
        controller,
      });

      const newMessage = createNewConversationMessage(
        question,
        response.message_id
      );

      if ('id' in response) {
        const newConversations: Conversation[] = [
          {
            id: response.id,
            starred: false,
            summary: '',
            name: response.name,
            messages: [newMessage],
            date: new Date().toISOString(),
          },
          ...get().conversations,
        ];
        set({
          conversations: newConversations,
          conversationId: response.id,
        });
      } else {
        const newConversations = get().conversations.map(c =>
          c.id === conversationId
            ? {
                ...c,
                messages: [...c.messages, newMessage],
              }
            : c
        );
        set({
          conversations: newConversations,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  getMessage: async (conversationId, messageId) => {
    const { conversations, getConversationMessage } = get();

    const message = conversations
      .find(c => c.id === conversationId)
      ?.messages.find(m => m.id === messageId);

    if (
      !message ||
      message.status === 'abort' ||
      message.status === 'success'
    ) {
      return undefined;
    }

    return getConversationMessage(conversationId, messageId);
  },
  markConversationReadStatus: (conversationId, isRead) => {
    set({
      conversations: get().conversations.map(c =>
        c.id === conversationId ? { ...c, isUnread: !isRead } : c
      ),
    });
  },
  getUnreadConversations: () => get().conversations.filter(c => c.isUnread),
  updateConversation: async ({ name, id, favourite }) => {
    const res = await updateConversation({ id, name, favourite });

    if (!res.error) {
      set(state => ({
        conversations: state.conversations.map(c =>
          c.id === id
            ? { ...c, name: name ?? c.name, starred: favourite ?? c.starred }
            : c
        ),
      }));
    }

    return res;
  },
  deleteConversation: async id => {
    const res = await deleteConversation(id);

    if (!res.error) {
      set(state => ({
        conversations: state.conversations.filter(c => c.id !== id),
        conversationId:
          state.conversationId === id ? undefined : state.conversationId,
      }));
    }

    return res;
  },
  isFetchingConversations: true,
  getConversations: async () => {
    try {
      const res = await getAllConversations();

      const promises = res.conversations.map(c => getConversation(c.id));
      const conversationsMessages = await Promise.all(promises);

      const mapped: Conversation[] = res.conversations.map((r, i) => ({
        id: r.id,
        name: r.name,
        date: r.date,
        summary: r.summary,
        starred: r.favourite,
        isUnread: false,
        messages: conversationsMessages[i].messages.map(m => ({
          question: m.question,
          id: m.message_id,
          status: m.status,
          reply: [{ content: m.response, status: 'success' }],
          steps: [],
          stepsCount: m.step_count,
        })),
      }));

      set({ conversations: mapped, isFetchingConversations: false });
    } catch (error) {
      console.error(error);
    }
  },
  resetConversationId: () => {
    set({ conversationId: undefined });
  },
  resetStore: () => {
    set({
      conversationId: undefined,
      conversations: [],
      isFetchingConversations: true,
    });
  },
  getConversationMessage: async (conversationId, messageId) => {
    const response = await getConversationMessageWithSteps({
      conversationId,
      messageId,
    });

    const newConversations = get().conversations.map(c => {
      if (c.id === conversationId) {
        return {
          ...c,
          messages: c.messages.map(m =>
            m.id === messageId
              ? {
                  ...m,
                  status: response.status,
                  steps: response.steps,
                  reply: response.response ? [response.response] : m.reply,
                }
              : m
          ),
        };
      }

      return c;
    });

    set({
      conversations: newConversations,
      processingMessage: undefined,
      errorInfo:
        response.status === 'error'
          ? {
              steps: response.steps,
              questionsId: messageId,
            }
          : undefined,
    });

    return response;
  },
  getConversationMessageStep: async (conversationId, messageId, stepId) => {
    try {
      const data = await getConversationMessageStep({
        conversationId,
        messageId,
        stepId,
      });

      const resolvedEntities = data.resolved;

      if (!resolvedEntities?.length) {
        return data;
      } else {
        const resolvedWithMappedEntities = await Promise.all(
          resolvedEntities.map(async resolved => {
            const mappedEntity = await postEntity(
              resolved.entity,
              get().controller.signal
            );
            return {
              ...resolved,
              mappedEntity: mappedEntity.data,
            };
          })
        );
        return {
          ...data,
          resolved: resolvedWithMappedEntities,
        };
      }
    } catch (error) {
      console.error(error);
      return {
        error: true,
        is_success: false,
      };
    }
  },
}));
