import { CalendarResponse, EarningsFilterStatsResponse } from '@toggle/toggle';
import {
  addMonths,
  format,
  isAfter,
  isSunday as getIsSunday,
  startOfToday,
} from 'date-fns';
import { toDate } from 'date-fns-tz';

export interface CountryFilter {
  key: string;
  name: string;
  value: { value: string };
}

export enum TabsType {
  Company = 'company',
  Earnings = 'earnings',
  Economics = 'economics',
}

export const isDateBeyondThreeMonths = (date: Date) => {
  const dateAhead = addMonths(startOfToday(), 3);
  return isAfter(date, dateAhead);
};

export const isDateBeyondThreeMonthsAndSunday = (date: Date) => {
  const dateAhead = addMonths(startOfToday(), 3);
  const isSunday = getIsSunday(date);
  return isSunday && isAfter(date, dateAhead);
};

export const formatEventDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatEarningsCount = (data?: EarningsFilterStatsResponse) => {
  if (!data) {
    return {};
  }

  let formattedData: Record<string, number> = {};

  data.reported.forEach(event => {
    formattedData[
      formatEventDate(
        toDate(event.date, {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      )
    ] = event.count;
  });
  data.upcoming.forEach(event => {
    const date = formatEventDate(
      toDate(event.date, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );

    if (formattedData[date] || formattedData[date] === 0) {
      formattedData[date] += event.count;
    } else {
      formattedData[date] = event.count;
    }
  });

  return formattedData;
};

export const formatEconomicsCompanyCount = (data?: CalendarResponse) => {
  if (!data) {
    return {
      economics: {},
      companies: {},
    };
  }

  const economicsCount = Object.keys(data).reduce((acc, key) => {
    const keyDate = formatEventDate(
      toDate(key, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
    acc[keyDate] = data[key].economic;
    return acc;
  }, {} as Record<string, number>);

  const companiesCount = Object.keys(data).reduce((acc, key) => {
    const keyDate = formatEventDate(
      toDate(key, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
    acc[keyDate] = data[key].company;
    return acc;
  }, {} as Record<string, number>);

  return {
    economics: economicsCount,
    companies: companiesCount,
  };
};
