import { ButtonLink } from '@toggle/design-system';
import styled from 'styled-components';

export const StyledButtonLink = styled(ButtonLink)`
  padding-top: 8px;
  ${ButtonLink.Styled.Label} {
    gap: 4px;
  }

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
`;
