import {
  Chip,
  DatePicker,
  DropdownMenu,
  i18n,
  Icon,
} from '@toggle/design-system';
import {
  format,
  isDate,
  isToday,
  isYesterday,
  startOfYesterday,
} from 'date-fns';
import React, { useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { Option } from '~/components/filter/Option';

import { TopNewsReducerAction } from '../../News';
import { TEMP_MIN_FETCHING_NEWS_DATE } from '../../utils/utils';
import * as S from './DatePillDropdown.styles';

export interface DatePillDropdownProp {
  originalPillTime: string;
  onChangeDate: ({ date }: TopNewsReducerAction) => void;
}

export const DatePillDropdown = ({
  originalPillTime,
  onChangeDate,
}: DatePillDropdownProp) => {
  const { t } = useTranslation(['news']);

  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);

  const pillLabel = () => {
    const labelTime = new Date(originalPillTime);

    if (isToday(labelTime)) {
      return t('news:pillDropdown.today');
    } else if (isYesterday(labelTime)) {
      return t('news:pillDropdown.yesterday');
    } else {
      return format(labelTime, 'EEEE, dd MMMM');
    }
  };

  const pillOptions = [
    {
      key: 'today',
      name: t('news:pillDropdown.today'),
      value: { value: new Date() },
      isChecked: originalPillTime ? isToday(new Date(originalPillTime)) : false,
    },
    {
      key: 'yesterday',
      name: t('news:pillDropdown.yesterday'),
      value: { value: startOfYesterday() },
      isChecked: originalPillTime
        ? isYesterday(new Date(originalPillTime))
        : false,
    },
  ];

  return (
    <div>
      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={() => {
          setIsShowDatePicker(false);
          setIsDropdownOpen(prev => !prev);
        }}
        triggerNode={
          <S.ChipWrapper>
            <Chip
              ref={ref}
              label={pillLabel()}
              rightIcon="ChevronLightDown"
              rightIconSize={12}
              data-testid="date-pill-chip"
            />
          </S.ChipWrapper>
        }
        contentProps={{
          asChild: true,
        }}
      >
        <S.MenuWrapper $isCalendarShow={isShowDatePicker}>
          {isShowDatePicker ? (
            <I18nextProvider i18n={i18n}>
              <DatePicker
                value={new Date(originalPillTime)}
                maxDate={new Date()}
                minDate={TEMP_MIN_FETCHING_NEWS_DATE}
                onDateChange={newDate => {
                  if (newDate && isDate(newDate)) {
                    onChangeDate({
                      date: (newDate as Date).toISOString(),
                    });
                    setIsDropdownOpen(false);
                  }
                }}
                data-testid="calendar"
              />
            </I18nextProvider>
          ) : (
            <>
              {pillOptions.map(option => {
                return (
                  <Option
                    key={option.key}
                    option={option}
                    handleClick={() => {
                      onChangeDate({
                        date: option.value.value.toISOString(),
                      });
                    }}
                    isChecked={option.isChecked}
                    data-testid={`time-${option.key}`}
                  />
                );
              })}
              <S.Divider />
              <S.CustomDate
                onClick={() => setIsShowDatePicker(true)}
                data-testid="date-picker-opener"
              >
                <Icon iconName="Settings" size={16} />
                <S.SpecificDate>
                  {t('news:pillDropdown.specificDate')}
                </S.SpecificDate>
              </S.CustomDate>
            </>
          )}
        </S.MenuWrapper>
      </DropdownMenu>
    </div>
  );
};
