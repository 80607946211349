import styled, { css } from 'styled-components';

import { typographyV2 } from '~/design-tokens/typography/Typography.styles';

export const SharedInputStyles = css`
  flex: 1;
  border: none;
  background-color: transparent;
  border: none;
  caret-color: var(--text-primary);
  -moz-appearance: textfield;
  border-color: transparent;

  &:active {
    border-color: transparent;
    outline: none;
  }
  &:hover {
    border-color: transparent;
    outline: none;
  }
  &:focus-within {
    border-color: transparent;
    outline: none;
  }

  &::placeholder {
    color: var(--text-soft);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Container = styled.div`
  width: auto;
`;

export const InputElement = styled.input<{
  $size?: 'medium' | 'large';
  $rightAlignText?: boolean;
  disabled: boolean;
}>`
  ${SharedInputStyles}
  ${({ $rightAlignText }) =>
    $rightAlignText &&
    css`
      text-align: right;
    `}

  ${({ $size }) =>
    $size === 'large' ? typographyV2.bodyBase : typographyV2.bodySm};

  color: ${({ disabled }) =>
    disabled ? 'var(--text-disabled)' : 'var(--text-default)'};
`;
interface InputWrapperProps {
  $focused?: boolean;
  $disabled?: boolean;
  $error?: boolean;
  $variant: 'fill' | 'outline';
}

export const PrefixContainer = styled.div`
  color: var(--text-soft);
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  border: 1px solid var(--border-default);
  gap: 8px;
  align-items: center;

  ${({ $variant }) => {
    if ($variant === 'fill') {
      return css`
        background-color: var(--surface-default);
      `;
    } else {
      return css`
        background-color: transparent;
      `;
    }
  }}

  ${({ $focused, $error, $disabled }) => {
    switch (true) {
      case $disabled:
        return css`
          border-color: var(--border-disabled);
          cursor: default;
          ${PrefixContainer} {
            color: var(--surface-neutral);
          }
          & svg {
            fill: var(--surface-neutral);
          }
        `;
      case $error:
        return css`
          border-color: var(--border-critical);
        `;
      case $focused:
        return css`
          border-color: var(--border-primary);
        `;
      default:
        return css`
          &:hover {
            border-color: var(--border-hover);
          }
        `;
    }
  }}
`;

export const LeftIcon = styled.span`
  width: auto;
  display: inline-block;
  display: flex;
  align-items: center;
`;
export const RightIcon = styled.span`
  width: auto;
  display: flex;
  align-items: center;
`;

export const ErrorLabel = styled.label`
  display: block;
  text-align: left;
  margin-top: 6px;
  color: var(--text-critical);
  ${typographyV2.bodySm}
`;

export const HelperLabel = styled(ErrorLabel)`
  color: var(--text-soft);
`;

export const StyledLabel = styled.label`
  display: block;
  ${typographyV2.bodySm};
  color: var(--text-default);
  margin-bottom: 4px;
`;
