import { Icon } from '@toggle/design-system';
import {
  checkCellValueStyle,
  formatPercentage,
  NumericalCellValueStyle,
} from '@toggle/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './InsightCardDetails.style';

export interface InsightCardDetailsProps {
  qualityStars?: number;
  horizon?: string;
  median: number;
  isFixedIncome?: boolean;
}

export const InsightCardDetails = ({
  qualityStars,
  horizon,
  median,
  isFixedIncome = false,
}: InsightCardDetailsProps) => {
  const { t } = useTranslation('widget');
  const suffix = isFixedIncome ? 'bps' : '%';
  const decimals = isFixedIncome ? 0 : 2;

  const valueStyle = checkCellValueStyle(median);

  const starsTitleMapping = (numberOfStars: number): string => {
    switch (numberOfStars) {
      case 0:
      case 1:
      case 2:
        return t('widget:insight.starsTitle.0_2_stars');
      case 3:
      case 4:
        return t('widget:insight.starsTitle.3_4_stars');
      case 5:
        return t('widget:insight.starsTitle.5_stars');
      case 6:
        return t('widget:insight.starsTitle.6_stars');
      case 7:
        return t('widget:insight.starsTitle.7_stars');
      case 8:
        return t('widget:insight.starsTitle.8_stars');
      default:
        return '';
    }
  };

  return (
    <S.Details>
      {qualityStars && (
        <>
          <S.Detail>
            <S.Heading>{starsTitleMapping(qualityStars)}</S.Heading>
            <S.GroupedText>
              <Icon
                iconName="StarFill"
                fillColor="var(--icon-primary)"
                size={20}
              />
              <S.Text>{qualityStars}&nbsp;/&nbsp;8</S.Text>
            </S.GroupedText>
          </S.Detail>
          <S.Separator />
        </>
      )}
      {horizon && (
        <>
          <S.Detail>
            <S.Heading>{t('widget:insight.attractiveHorizon')}</S.Heading>
            <S.TextUpper>
              {
                /* i18next-extract-mark-context-next-line ["1m", "1w", "2w", "3m", "6m"] */
                t('widget:assetOverview.horizon', {
                  context: horizon,
                })
              }
            </S.TextUpper>
          </S.Detail>
          <S.Separator />
        </>
      )}
      <S.Detail>
        <S.Heading>{t('widget:insight.medianReturn')}</S.Heading>
        <S.DetailReturn $numericalStyle={valueStyle}>
          {valueStyle === NumericalCellValueStyle.Positive && '+'}
          {formatPercentage(median, {
            suffix,
            decimals,
          })}
        </S.DetailReturn>
      </S.Detail>
    </S.Details>
  );
};
