import { PriceStatus } from '@toggle/helpers';
import styled from 'styled-components';

import { priceChangeColors } from '~/utils/common/utils';

import { SummaryTable } from '../summary-table/SummaryTable';

export const EarningsDrawerContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledSummaryTableWrapper = styled.div`
  tbody tr:last-child {
    ${SummaryTable.Styled.StyledTableCell},
    ${SummaryTable.Styled.StyledTableHeader} {
      border-bottom: none;
    }
  }
`;

export const SurpriseWrapper = styled.div<{ $status: PriceStatus }>`
  color: ${({ $status }) => priceChangeColors[$status]};
`;
