import { borderRadius, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.button`
  display: flex;
  padding: 16px 12px;
  margin: 4px 0px;
  gap: 28px;
  cursor: pointer;
  ${borderRadius.r1}
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: left;

  &:hover,
  &:focus-visible {
    background-color: var(--surface-hover);
  }
`;

export const Container = styled.div`
  width: fit-content;
  justify-content: center;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const Title = styled.p`
  ${typographyV2.titleBase}
`;

export const Image = styled.img`
  height: 100%;
  width: 120px;
`;
