import React, { ReactNode } from 'react';

import { Icon } from '~/components/icon/Icon';

import { DropdownEntry } from '../dropdown-group/DropdownGroup';
import { Separator } from '../DropdownMenu.styles';
import * as S from './DropdownRadioGroup.styles';

export type DropdownRadioGroupProps = {
  label?: ReactNode;
  items: DropdownEntry[];
  selectedKey?: string;
};

export const DropdownRadioGroup = ({
  label,
  items,
  selectedKey,
}: DropdownRadioGroupProps) => {
  return (
    <S.RadioGroupWrapper data-testid="dropdown-radio-group">
      {label && <S.Label>{label}</S.Label>}
      <S.RadioGroup value={selectedKey}>
        {items.map(item => (
          <S.RadioItem
            key={item.key}
            value={item.key}
            onSelect={item?.onClick}
            data-testid={`dropdown-radiogroup-item-${item.key}`}
          >
            <S.RadioItemWrapper>
              <S.RadioItemSelected />
              <S.RadioButton>
                {item.iconName && <Icon iconName={item.iconName} size={16} />}
                <S.RadioItemLabel>{item.label}</S.RadioItemLabel>
              </S.RadioButton>
            </S.RadioItemWrapper>
          </S.RadioItem>
        ))}
      </S.RadioGroup>
      <Separator />
    </S.RadioGroupWrapper>
  );
};

DropdownRadioGroup.Styled = S;
