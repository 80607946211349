import { entities } from '@toggle/toggle';

import { apiFetch } from '~/utils/api-fetch/apiFetch';
import { getUniqueArray } from '~/utils/common/utils';

import { EntityTags } from './watchlist-types';

export const CHAT_INPUT_ENTITIES_REGEX = /[\n\r,]/;

export const resolveEntitiesFromCsv = async (
  maybeCsv: string
): Promise<string[]> => {
  const csv = maybeCsv.trim().split(CHAT_INPUT_ENTITIES_REGEX).join(',');
  const res = await apiFetch<EntityTags>(entities.resolveFromCsv.path, {
    method: 'post',
    body: {
      csv,
    },
  });

  if (res.error) {
    return [];
  }

  const uniqueTags = getUniqueArray(res.data.tags);

  if (!uniqueTags.length) {
    return [];
  }

  return uniqueTags;
};
