/* eslint-disable max-lines-per-function */
import { ChartAssetData, PaneData } from '@toggle/chart';
import { Entity, SnakeMeta } from '@toggle/toggle';
import { v4 } from 'uuid';

import { postEntity } from '~/services/entities/entity-service';

import { getChartDataEmptyResamples } from '../../utils/resample-utils/resample-utils';
import { UseChartStateReturn } from '../use-chart-state/useChartState';
import { UseResampleReturn } from '../use-resample/useResample';

export interface UseChartPaneProps {
  chartPanes: PaneData[];
  resample: UseResampleReturn['resample'];
  updateChartState: UseChartStateReturn['updateChartState'];
  onAssetOrIndicatorChange: (props: {
    indicator: SnakeMeta;
    entity: Entity;
    assetToChange?: Entity;
  }) => Promise<void>;
}

export type UseChartPaneReturn = ReturnType<typeof useChartPane>;

export const useChartPane = ({
  chartPanes,
  updateChartState,
  resample,
  onAssetOrIndicatorChange,
}: UseChartPaneProps) => {
  const addToggleIndicator = async (indicator: SnakeMeta, checked: boolean) => {
    if (!checked) {
      const pane = chartPanes.find(p =>
        p.chartAssetData.some(a => a.snakeMeta?.snake === indicator.snake)
      ) as PaneData;
      removePane(pane.id);
      return;
    }

    const entityData = await postEntity(indicator.entity);

    if (!entityData.data) {
      return;
    }

    onAssetOrIndicatorChange({
      indicator,
      entity: entityData.data,
    });
  };

  const removePane = (id: string) => {
    let nextChartPanes = chartPanes.filter(pane => pane.id !== id);
    resample.setEmptyResamples(
      getChartDataEmptyResamples(nextChartPanes.flatMap(p => p.chartAssetData))
    );
    const collapsedPanes = chartPanes.filter(p => p.collapsed);

    if (nextChartPanes.length === collapsedPanes.length) {
      nextChartPanes = [
        { ...nextChartPanes[0], collapsed: false },
        ...nextChartPanes.slice(1),
      ];
    }

    updateChartState({ chartPanes: nextChartPanes });
  };

  const movePane = (direction: 'up' | 'down', paneIndex: number) => {
    const nextChartPanes = [...chartPanes];
    const moveDownIndex =
      paneIndex !== chartPanes.length - 1 ? paneIndex + 1 : paneIndex;
    let newIndex =
      direction === 'up' && paneIndex !== 0 ? paneIndex - 1 : moveDownIndex;

    [nextChartPanes[newIndex], nextChartPanes[paneIndex]] = [
      nextChartPanes[paneIndex],
      nextChartPanes[newIndex],
    ];

    updateChartState({ chartPanes: nextChartPanes });
  };

  const moveBetweenPanes = ({
    newPaneIndex,
    paneIndex,
    snake,
    inExistingPane = true,
  }: {
    paneIndex: number;
    newPaneIndex: number;
    snake: string;
    inExistingPane?: boolean;
    panelLength?: number;
  }) => {
    let nextChartPanes = [...chartPanes];
    const updatedPane = nextChartPanes[newPaneIndex];
    const currentChartAssetData = nextChartPanes[paneIndex].chartAssetData;

    const movingChartAsset = currentChartAssetData.find(
      chartData => chartData.entity.default_snake === snake
    ) as ChartAssetData;

    nextChartPanes[paneIndex].chartAssetData = currentChartAssetData.filter(
      chartData => chartData.entity.default_snake !== snake
    );

    if (!nextChartPanes[paneIndex].chartAssetData.length) {
      nextChartPanes = nextChartPanes.filter(
        p => p.id !== nextChartPanes[paneIndex].id
      );
    }

    if (updatedPane && inExistingPane) {
      updatedPane.chartAssetData = [
        ...updatedPane.chartAssetData,
        movingChartAsset,
      ];
    } else {
      const newChartPane: PaneData = {
        id: v4(),
        chartAssetData: [movingChartAsset],
        seriesType: 'line',
        priceDisplay: 'price',
        yAxisType: 'merged',
      };

      nextChartPanes = [
        ...nextChartPanes.slice(0, Math.max(0, newPaneIndex)),
        newChartPane,
        ...nextChartPanes.slice(newPaneIndex + Number(inExistingPane)),
      ];
    }

    updateChartState({ chartPanes: nextChartPanes });
  };

  const updatePaneField =
    (
      field: Extract<keyof PaneData, 'collapsed' | 'maximized'>,
      paneId: string
    ) =>
    (pane: PaneData) =>
      pane.id === paneId ? { ...pane, [field]: !pane[field] } : pane;

  const collapsePane = (id: string) => {
    const nextChartPanes = chartPanes.map(updatePaneField('collapsed', id));
    updateChartState({ chartPanes: nextChartPanes });
  };

  const maximizePane = (id: string) => {
    const nextChartPanes = chartPanes.map(updatePaneField('maximized', id));
    updateChartState({ chartPanes: nextChartPanes });
  };

  return {
    removePane,
    movePane,
    addToggleIndicator,
    collapsePane,
    maximizePane,
    moveBetweenPanes,
  };
};
