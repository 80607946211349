import { z } from 'zod';

export const calendarRequestSchema = z.object({
  from: z.string(),
  to: z.string(),
  watchlists: z
    .object({
      all: z.boolean().optional(),
      identities: z.array(z.string()).optional(),
    })
    .optional(),
  countries: z.array(z.string()).optional(),
  types: z.array(z.string()).optional(),
});

export type CalendarRequest = z.infer<typeof calendarRequestSchema>;

export const calendarResponseSchema = z.record(
  z.string(),
  z.object({
    economic: z.number(),
    company: z.number(),
  })
);

export type CalendarResponse = z.infer<typeof calendarResponseSchema>;
