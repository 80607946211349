export const mailTo = (
  email: string,
  options?: {
    subject?: string;
    body?: string;
  }
) => {
  const emailParams = Object.entries(options || {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const paramsString = emailParams ? `?${emailParams}` : '';

  return new URL(`mailto:${email}${paramsString}`).toString();
};
