import { Entity, mapEntity } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './ExchangeCard.styles';

export interface ExchangeCardProps {
  entity: Entity;
}

export const ExchangeCard = ({ entity }: ExchangeCardProps) => {
  const { t } = useTranslation(['countries', 'analyze']);

  const countryCode = entity.country?.long;
  const mappedEntity = mapEntity(entity);

  return (
    <S.ExchangeCardRoot data-testid="exchange-card">
      <S.Details>
        <S.Title>{entity.exchange.code}</S.Title>
        {mappedEntity.sub_class && (
          <S.Subtitle>
            {
              /* i18next-extract-disable-next-line */
              t(`analyze:assetSubClass.${mappedEntity.sub_class}`)
            }
          </S.Subtitle>
        )}
      </S.Details>
      {countryCode && (
        <S.Country>
          <S.StyledFlagIcon
            title={
              /* i18next-extract-disable-next-line */
              t(`countries:${countryCode}`)
            }
            countryCode={countryCode}
            circular
          />
        </S.Country>
      )}
    </S.ExchangeCardRoot>
  );
};
