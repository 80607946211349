import { config } from '~/config';

import { BaseWs, CloseReason } from '../base-ws/BaseWs';

const {
  api: { wsRoot, wsDelayedRoot },
} = config;

export interface PriceUpdate {
  ticker: string;
  exchange: string;
  price: number;
  time: string;
}
export type UpdateHandler = (update: PriceUpdate) => void;

enum ACTION {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

export enum EVENT {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
  PRICE = 'price',
  ERROR = 'error',
}

interface PricePayload extends PriceUpdate {
  event: EVENT.PRICE;
}

interface ErrorPayload {
  event: EVENT.ERROR;
  message: string;
}

interface SubscribedPayload {
  event: EVENT.SUBSCRIBED;
  ticker: string;
}

interface UnsubscribedPayload {
  event: EVENT.UNSUBSCRIBED;
  ticker: string;
}

type SocketResponses =
  | PricePayload
  | SubscribedPayload
  | UnsubscribedPayload
  | ErrorPayload;

type ErrorHandler = (message: string) => void;

export type PriceWsProps = {
  updateHandler: UpdateHandler;
  isPro?: boolean;
  onError?: ErrorHandler;
};

export class PriceWS extends BaseWs<SocketResponses> {
  readonly maxIdleTime = 60000;
  readonly url = wsRoot;
  private closeTimer?: number = undefined;
  readonly visibilityListener: (_: Event) => void;
  allTickers: string[] = [];

  constructor({ updateHandler, isPro, onError }: PriceWsProps) {
    const url = isPro ? wsDelayedRoot : wsRoot;
    super(url);

    this.onopen = this.onOpen;
    this.url = url;
    this.onclose = this.onClose;
    this.onmessage = this.handleMessageEvent(updateHandler, onError);
    this.visibilityListener = this.onVisibilityChange;
    document.addEventListener('visibilitychange', this.visibilityListener);
  }

  readonly onVisibilityChange = (_: Event) => {
    window.clearTimeout(this.closeTimer);

    if (document.hidden && !this.closed) {
      this.scheduleClose(CloseReason.InactiveTab);
    } else if (this.closed) {
      this.initializeWs(this.url);
    }
  };

  private scheduleClose(reason: CloseReason) {
    this.closeTimer = window.setTimeout(
      this.close.bind(this, reason),
      this.maxIdleTime
    );
  }

  private handleMessageEvent(
    updateHandler: UpdateHandler,
    onError?: ErrorHandler
  ) {
    return (payload: SocketResponses) => {
      const { event, ...pricePayload } = payload;

      switch (event) {
        case EVENT.PRICE:
          updateHandler(pricePayload as PriceUpdate);
          break;
        case EVENT.ERROR:
          onError?.(payload.message);
          break;
        case EVENT.SUBSCRIBED:
        case EVENT.UNSUBSCRIBED:
          break;
        default:
          onError?.(`Unknown event: ${event}`);
      }
    };
  }

  private subscribeTickers(tickers: string[]) {
    tickers.forEach(ticker =>
      this.sendMessage(JSON.stringify({ action: ACTION.SUBSCRIBE, ticker }))
    );
  }

  private onOpen() {
    this.subscribeTickers(this.allTickers);
  }

  private onClose(e: CloseEvent) {
    if (e.reason === CloseReason.NoConnectionRequired) {
      document.removeEventListener('visibilitychange', this.visibilityListener);
    }
  }

  subscribe(tickers: string[]) {
    window.clearTimeout(this.closeTimer);
    this.allTickers.push(...tickers);

    if (this.readyState === WebSocket.OPEN) {
      this.subscribeTickers(tickers);
    }
  }

  //https://www.typescriptlang.org/docs/handbook/2/classes.html#overriding-methods
  //I guess we will never need to call super.unsubscribe here
  //@ts-ignore
  unsubscribe(tickers: string[]) {
    if (this.readyState === WebSocket.OPEN) {
      this.allTickers = this.allTickers.filter(
        ticker => !tickers.includes(ticker)
      );

      if (!this.allTickers.length) {
        this.scheduleClose(CloseReason.NoConnectionRequired);
      }

      tickers.forEach(ticker =>
        this.sendMessage(JSON.stringify({ action: ACTION.UNSUBSCRIBE, ticker }))
      );
    }
  }
}
