import * as d3 from 'd3';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  MultiLineChartLine,
  TooltipData,
  TooltipSort,
} from '../../MultiLineChart.types';
import * as S from './MultiLineChartTooltip.styles';

export type MultiLineChartTooltipProps = {
  lines: MultiLineChartLine[];
  tooltipData: TooltipData;
  formatter: (n: number) => string;
  sortBy?: TooltipSort;
};

export const MultiLineChartTooltip = ({
  lines,
  tooltipData,
  formatter,
  sortBy,
}: MultiLineChartTooltipProps) => {
  const { t } = useTranslation('tooltip');
  const tooltipRows = lines.map(line => ({
    color: line.color,
    label: line.label,
    value: line.data.find(d => d.date.valueOf() === tooltipData.date.valueOf())
      ?.value,
  }));

  if (sortBy) {
    tooltipRows.sort((a, b) =>
      sortBy === 'desc'
        ? (b.value ?? 0) - (a.value ?? 0)
        : (a.value ?? 0) - (b.value ?? 0)
    );
  }

  const TooltipLabel = (
    <S.TooltipLabelContainer data-testid="multi-line-chart-tooltip">
      <S.DateLabel>{d3.utcFormat('%b %d %Y')(tooltipData.date)}</S.DateLabel>
      {tooltipRows.map(row => (
        <S.TooltipRow key={row.label} data-testid="tooltip-row">
          <S.TooltipLegend>
            <S.TooltipLegendColor $color={row.color} />
            <div>{row.label}</div>
          </S.TooltipLegend>
          <div data-testid="row-value-label">
            {row.value !== undefined
              ? formatter(row.value)
              : t('tooltip:notAvailable')}
          </div>
        </S.TooltipRow>
      ))}
    </S.TooltipLabelContainer>
  );

  return (
    <S.StyledTooltip
      inPortal
      label={TooltipLabel}
      isTouchDevice={false}
      isOpen
      hasArrow={false}
    >
      <S.TooltipRoot style={{ left: `${tooltipData.xPos}px` }} />
    </S.StyledTooltip>
  );
};
