import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentSourcesTableStore } from '../use-document-sources-table-store/useDocumentSourcesTableStore';
import * as S from './DocumentSourcesTableNoResultsCell.styles';

export const DocumentSourcesTableNoResultsCell = ({
  colSpan,
}: {
  colSpan: number;
}) => {
  const { t } = useTranslation('chat');
  const clearAllActiveFilterOptions = useDocumentSourcesTableStore(
    state => state.clearAllActiveFilterOptions
  );

  return (
    <tbody>
      <S.StyledTableRow>
        <S.StyledTableTd colSpan={colSpan}>
          <S.Root>
            <img
              src="/app/static/images/empty-state/no-search-result.svg"
              alt=""
            />
            <S.Title>{t('chat:documentSourcesTable.noResultsFound')}</S.Title>
            <S.StyledButton
              label={t('chat:documentSourcesTable.clearAllFilters')}
              variant="empty"
              iconName="Clear"
              onClick={clearAllActiveFilterOptions}
            />
          </S.Root>
        </S.StyledTableTd>
      </S.StyledTableRow>
    </tbody>
  );
};
