import { customScrollMinimal } from '@toggle/design-system';
import styled from 'styled-components';

import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '~/stores/use-app/useApp';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 3;
`;

export const BodyContainer = styled.div<{
  $isSidebarExpanded: boolean;
}>`
  position: absolute;
  left: ${({ $isSidebarExpanded }) =>
    $isSidebarExpanded
      ? `calc(50% + ${SIDEBAR_EXPANDED_WIDTH})`
      : `calc(50% + ${SIDEBAR_CLOSED_WIDTH})`};
  transform: translateX(-50%);
  width: var(--chat-message-width);
  height: 100%;
  max-height: 100%;
  padding-top: 36px;
  overflow-y: auto;
  color: var(--text-default);

  ${customScrollMinimal};

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:first-child {
    margin-bottom: 87px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: var(--background-backdrop);
`;
