import React from 'react';

import * as S from './Card.styles';

export type SizeType = 'small' | 'medium' | 'large';

export interface CardProps {
  children?: React.ReactNode;
  size?: SizeType;
}

export const Card = ({ children, size = 'medium', ...rest }: CardProps) => {
  return (
    <S.CardRoot data-testid="card" size={size} {...rest}>
      {children}
    </S.CardRoot>
  );
};
