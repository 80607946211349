import { queryString } from '@toggle/helpers';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { matchRoutes, Navigate, RouteObject, useLocation } from 'react-router';

import { authRoutes, commonRoutes, nonBasicRoutes } from '~/routes';
import { appPaths, authPaths, queryKeys } from '~/routes/app-paths';
import { AppRoute } from '~/routes/Routes';
import { Tracking } from '~/services/tracking';
import { useAuthStore } from '~/stores/use-auth/store/authStore';
import { useFlags } from '~/stores/use-flags/useFlags';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { ServerError } from '~/views/error';

import { AccountCheckView } from './account-check-view/AccountCheckView';

export interface ProtectedRouteProps {
  config: AppRoute | RouteObject;
}

export const ProtectedView = ({ config }: ProtectedRouteProps) => {
  const location = useLocation();
  const isAuthenticated = useAuthStore(state => !!state.token);
  const isBasicUser = useSubscription(state => state.isBasicUser());

  const getFeatureFlagState = useFlags(state => state.getFeatureFlagState);

  const isAuthRoute = matchRoutes(authRoutes, location);
  const isNonBasicRoute = matchRoutes(nonBasicRoutes, location);

  if (!isAuthenticated) {
    const isCommonRoute = matchRoutes(commonRoutes, location);
    return isAuthRoute || isCommonRoute ? (
      config.element
    ) : (
      <Navigate
        to={
          authPaths.login +
          queryString({
            [queryKeys.redirect]: `${location.pathname}${location.search}`,
          })
        }
        replace
      />
    );
  }

  if (isAuthRoute) {
    return <Navigate to={appPaths.base} replace />;
  }

  if (isBasicUser && isNonBasicRoute) {
    return <Navigate to={appPaths.base} replace />;
  }

  const routesConfig = config as AppRoute;
  const featureFlagDisabled =
    routesConfig.featureFlag && !getFeatureFlagState(routesConfig.featureFlag);

  if (featureFlagDisabled) {
    return <Navigate to={appPaths.base} replace />;
  }

  const logError = (error: Error, errorInfo: React.ErrorInfo) => {
    Tracking.captureException(error, { extra: { errorInfo } });
  };

  return (
    <ErrorBoundary fallback={<ServerError />} onError={logError}>
      <AccountCheckView config={routesConfig} />
    </ErrorBoundary>
  );
};
