import { useQuery } from '@tanstack/react-query';

import { postFilteredTimeSeries } from '~/services/timeseries/timeseries-service';

export const QUERY_KEY_TIME_SERIES_FILTERED = 'QUERY_KEY_TIME_SERIES_FILTERED';

export const useFilteredTimeSeries = (tag: string[]) => {
  return useQuery({
    queryKey: [QUERY_KEY_TIME_SERIES_FILTERED, tag.join('')],
    queryFn: ({ signal }) => postFilteredTimeSeries({ entities: tag }, signal),
    enabled: !!tag.length,
  });
};
