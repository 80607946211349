import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionModal,
  ActionsModal,
} from '~/components/actions-modal/ActionsModal';
import { BaseRenameInput } from '~/components/base-rename-input/BaseRenameInput';
import { useWatchlistActions } from '~/hooks/use-watchlist-actions/useWatchlistActions';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { WatchlistError } from '~/stores/use-watchlist/watchlist-types';

import { DocsSearchInput } from './doc-search-input/DocSearchInput';

export enum Actions {
  CreateWatchlist = 'create-watchlist',
  DocSearch = 'doc-search',
}

export interface ModalsProps {
  entityTags: string[];
  activeModal: Actions;
  setActiveModal: (activeModal: Actions | null) => void;
}

export const Modals = ({
  entityTags,
  activeModal,
  setActiveModal,
}: ModalsProps) => {
  const { t } = useTranslation(['common', 'screener']);
  const [watchlistName, setWatchlistName] = useState('');

  const {
    watchlistErrorText,
    showWatchlistSavedToast,
    clearError,
    setWatchlistError,
  } = useWatchlistActions();
  const { createList, hasListWithName } = useWatchlist(state => ({
    hasListWithName: state.hasListWithName,
    createList: state.createList,
  }));

  const defaultCancelBtnProps = {
    label: t('common:cancel'),
    onClick: () => {
      setWatchlistName('');
      clearError();
      setActiveModal(null);
    },
  };

  const onSaveWatchlist = async () => {
    if (!watchlistName) {
      setWatchlistError(WatchlistError.EmptyName);
      return;
    }

    if (hasListWithName(watchlistName)) {
      setWatchlistError(WatchlistError.Duplicate);
      return;
    }

    try {
      const id = await createList(watchlistName, entityTags);

      if (id === null) {
        setWatchlistError(WatchlistError.ServerError);
        return;
      }

      showWatchlistSavedToast({
        id,
        message: t('common:watchlistSuccessfullySaved'),
        btnLabel: t('common:viewWatchlist'),
      });
      setActiveModal(null);
    } catch {
      setWatchlistError(WatchlistError.ServerError);
    }
  };

  const nameInput = (
    <BaseRenameInput
      data-testid="watchlist-name-input"
      value={watchlistName}
      placeholder={t('screener:screenerPlaceholder')}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setWatchlistName(e.target.value);
        clearError();
      }}
    />
  );

  switch (activeModal) {
    case Actions.CreateWatchlist:
      return (
        <ActionsModal
          activeModal={ActionModal.SaveAs}
          dataTestId="save-as-watchlist"
          onClose={() => {
            clearError();
            setActiveModal(null);
          }}
          clearError={clearError}
          errorMessage={watchlistErrorText}
          cancelBtnProps={defaultCancelBtnProps}
          labels={{
            savePrimaryBtn: t('common:save'),
            saveTitle: t('common:saveAsWatchlist'),
          }}
          inputNode={nameInput}
          onSave={onSaveWatchlist}
        />
      );
    case Actions.DocSearch:
      return (
        <ActionsModal
          activeModal={ActionModal.DocSearch}
          dataTestId="document-search"
          onClose={() => {
            setActiveModal(null);
          }}
          clearError={() => {}}
          cancelBtnProps={undefined}
          labels={{
            saveTitle: t('common:linkingDocSearch.modalTitle'),
          }}
          inputNode={<DocsSearchInput entityTags={entityTags} />}
          customWidth="674px"
        />
      );
    default:
      return null;
  }
};
