import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { Icon } from '@toggle/design-system';
import React, { FC, ReactNode } from 'react';

import type { FilterProps } from './Filter';
import * as S from './Filter.styles';

export interface FilterFooterActionsProps
  extends Pick<FilterProps, 'filter' | 'onCustomRangeClick' | 'labels'> {
  onRemove: DropdownMenuItemProps['onClick'];
  isMultiUseSingleFilter: boolean;
}

export const FilterFooterActions: FC<FilterFooterActionsProps> = ({
  filter,
  labels,
  isMultiUseSingleFilter,
  onCustomRangeClick,
  onRemove,
}) => {
  const showRemove = !filter.locked || isMultiUseSingleFilter;
  const showCustomRange = !!filter.config.custom;

  const renderItem = ({
    onClick,
    label,
    id,
  }: {
    onClick: DropdownMenuItemProps['onClick'];
    label: ReactNode;
    id: string;
  }) => {
    return (
      <>
        <S.Separator />
        <S.Item data-testid={id} onClick={onClick}>
          <S.MenuItemLeftIndicator />
          <S.ItemText>{label}</S.ItemText>
        </S.Item>
      </>
    );
  };

  const removeNode =
    showRemove &&
    renderItem({
      id: 'remove',
      onClick: onRemove,
      label: (
        <S.RemoveLabel>
          <Icon iconName="Trash" size={16} />
          <span>{labels.remove}</span>
        </S.RemoveLabel>
      ),
    });

  const customRangeNode =
    showCustomRange &&
    renderItem({
      id: 'custom-range',
      onClick: onCustomRangeClick,
      label: (
        <S.CustomLabel>
          <Icon iconName="Settings" size={16} />
          <span>{labels.setCustomRange}</span>
        </S.CustomLabel>
      ),
    });

  return (
    <>
      {customRangeNode}
      {removeNode}
    </>
  );
};
