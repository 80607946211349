import {
  AutocompleteInput,
  borderRadius,
  Button,
  customScrollMinimal,
  Dropdown,
  DropdownList,
  DropdownProps,
  Input,
  Modal,
  typographyV2,
} from '@toggle/design-system';
import { JSX } from 'react';
import styled, { css } from 'styled-components';

export const StyledModal = styled(Modal)<{ $inChartPage: boolean }>`
  ${Modal.Styled.Header} {
    padding-bottom: 16px;
  }

  ${Modal.Styled.TextContainer} {
    margin-top: 12px;
    margin-bottom: 0;
  }

  ${({ $inChartPage }) => {
    return css`
      ${Modal.Styled.ModalContainer} {
        background-color: var(--background-soft);
        display: flex;
        flex-direction: column;
        width: ${$inChartPage ? '1000px' : '792px'};
        box-shadow: var(--elevation-moderate);
        padding: 0;
      }

      ${Modal.Styled.Backdrop} {
        background-color: ${$inChartPage
          ? 'transparent'
          : 'var(--background-backdrop)'};
        backdrop-filter: ${$inChartPage ? 'blur(0px)' : 'blur(1px)'};
      } ;
    `;
  }}
`;

export const SearchResultWrapper = styled.div`
  flex-shrink: 0;
  width: 600px;
`;

export const StyledDropdown = styled(Dropdown)`
  padding: 0px;

  ${Dropdown.Styled.DropdownListContainer} {
    position: relative;
    overflow-x: hidden;
  }
  ${DropdownList.Styled.DropdownListRoot} {
    box-shadow: none;
    border: none;
    background-color: unset;

    padding: 0px 8px 8px 8px;
  }
` as <T>(props: DropdownProps<T>) => JSX.Element;

export const ModalContent = styled.div`
  display: flex;
  height: 460px;
  width: 1000px;
`;

export const VerticalDivider = styled.div`
  margin-left: 16px;
  margin-right: 8px;
  border-left: 1px solid var(--border-soft);
  height: 100%;
`;

export const HelpText = styled.p`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  padding: 12px 16px;
`;

export const ClearButton = styled.button`
  ${typographyV2.bodySm};
  color: var(--text-soft);
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    color: var(--text-default);
  }
`;

export const StyledAutocompleteInput = styled(AutocompleteInput)<{
  $autocompleteInputVariant?: 'default' | 'secondary';
}>`
  ${Input.Styles.LeftIcon} svg {
    fill: var(--text-soft);
  }

  ${Input.Styles.Container} {
    width: 100%;
  }

  ${Input.Styles.InputWrapper} {
    border: 1px solid var(--border-soft);
    ${({ $autocompleteInputVariant }) =>
      $autocompleteInputVariant === 'secondary' &&
      css`
        border-radius: 0;
        border-left: none;
        border-right: none;
      `}

    background-color: transparent;
    padding-left: 20px;
  }

  ${Input.Styles.InputElement} {
    text-align: left;
  }
`;

export const PanesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-height: 450px;
  overflow-y: scroll;
  width: 361px;
  ${customScrollMinimal};
`;

export const TextWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

export const HelperText = styled.p`
  border: 1px dashed var(--border-soft);
  ${borderRadius.r2};
  display: flex;
  justify-content: center;
  padding: 12px;
  width: 100%;
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const StyledButton = styled(Button)`
  svg {
    fill: var(--icon-soft);
  }
`;
