import { PriceDisplay, PriceRange } from '~/types/axis.types';
import { ChartPane, DomainTimeSeries } from '~/types/create.types';
import { SeriesType } from '~/types/series.types';
import { ChartAssetData, TimeSeriesItem } from '~/types/timeseries.types';

import { calculateDataRange, getPercentage } from '../y-scale/y-scale';

interface GetTSRangeProps {
  timeseries: TimeSeriesItem[];
  seriesType: SeriesType;
  priceDisplay: PriceDisplay;
  isPrimaryAsset: boolean;
  domainTimeSeries: DomainTimeSeries;
}

const getPercentageRange = (
  range: PriceRange,
  baseValue: number
): PriceRange => {
  const value1 = getPercentage(range.min, baseValue);
  const value2 = getPercentage(range.max, baseValue);
  const min = Math.min(value1, value2);
  const max = Math.max(value1, value2);

  return {
    min,
    max,
    length: max - min,
  };
};

const getTSRange = ({
  timeseries,
  seriesType,
  priceDisplay,
  domainTimeSeries,
  isPrimaryAsset,
}: GetTSRangeProps) => {
  const slicedData = timeseries.slice(
    domainTimeSeries.startIndex,
    domainTimeSeries.endIndex + 1
  );
  const range = calculateDataRange(
    slicedData,
    seriesType === 'line' || !isPrimaryAsset
      ? { min: 'close', max: 'close' }
      : { min: 'low', max: 'high' }
  );
  return priceDisplay === 'price'
    ? range
    : getPercentageRange(range, domainTimeSeries.start.close);
};

export interface RangeIndexToYCoordinateProps {
  chartAssetData: ChartAssetData[];
  seriesType: SeriesType;
  priceDisplay: PriceDisplay;
  isAxisMerged: boolean;
  domainTimeSeries: ChartPane['domainTimeSeries'];
}

export interface RangeIndexesToYCoordinateReturn {
  allRanges: {
    entityDefaultSnake: string;
    range: PriceRange;
  }[];
  mergedRange: PriceRange;
}

export const rangeIndexesToYCoordinate = ({
  chartAssetData,
  seriesType,
  priceDisplay,
  isAxisMerged,
  domainTimeSeries,
}: RangeIndexToYCoordinateProps): RangeIndexesToYCoordinateReturn => {
  const currentPlottedData = chartAssetData.filter(e => !e.isHidden);
  let min = Infinity;
  let max = -Infinity;
  const allRanges = [];

  for (let i = 0; i < chartAssetData.length; i++) {
    const asset = chartAssetData[i];
    const domainTs = domainTimeSeries[i];

    if ((asset.isHidden && currentPlottedData.length > 0) || !domainTs) {
      continue;
    }

    const range = getTSRange({
      timeseries: asset.ts,
      domainTimeSeries: domainTs,
      seriesType,
      priceDisplay,
      isPrimaryAsset: i === 0,
    });
    allRanges.push({
      entityDefaultSnake: asset.entity.default_snake,
      range: { min: range.min, max: range.max, length: range.max - range.min },
    });

    if (currentPlottedData.length > 1 && isAxisMerged) {
      if (range.min < min) {
        min = range.min;
      }

      if (range.max > max) {
        max = range.max;
      }
    } else {
      min = range.min;
      max = range.max;
    }
  }

  return { allRanges, mergedRange: { min, max, length: max - min } };
};
