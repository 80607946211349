import { ScreenerFilter } from '@toggle/toggle';

export const insightsAssetFilter: ScreenerFilter = {
  key: 'explore_assets',
  name: 'Assets',
  visible: true,
  searchable: true,
  category: 'Universe',
  locked: true,
  config: {
    multiselect: false,
    name: 'Assets',
    options: [],
  },
};
