import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const EventDateRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

export const Title = styled.div<{ $isReported?: boolean }>`
  ${typographyV2.bodySm};
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${props =>
    props.$isReported ? 'var(--text-success)' : 'var(--text-primary)'};

  svg {
    fill: currentColor;
  }
`;
export const Description = styled.div`
  ${typographyV2.bodyBase};
  color: var(--text-default);
`;
