import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100vh;
  overflow: hidden;
`;

export const StyledTitle = styled.span`
  ${typographyV2.titleLg};
  color: var(--text-default);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
