import {
  OHLCPrice,
  PriceHoverData,
  SeriesType,
  TimeSeriesItem,
} from '@toggle/chart';
import { isPriceSnake, isYieldSnake } from '@toggle/helpers';
import { DisplayFormat, MappedEntity, SnakeMeta } from '@toggle/toggle';
import React from 'react';

import { PriceDisplayReturn } from '~/components/price-display-view/helpers/price-display.types';
import { priceDecimals } from '~/utils/currency/currency';
import { getPriceChange } from '~/widgets/asset-overview-widget/utils/asset-box/asset-box-helpers';

import * as S from '../legend-live-price/LegendLivePrice.styles';
import {
  LegendPriceIcons,
  PriceIcon,
} from '../legend-price-icons/LegendPriceIcons';

export interface LastClosePriceProps {
  iconMapping: PriceIcon[];
  price: PriceDisplayReturn;
  entity: MappedEntity;
  snakeMeta: SnakeMeta;
  legendColor: string;
  isPrimary: boolean;
  seriesType?: SeriesType;
  priceFormatter: (price: number) => string;
  ts: TimeSeriesItem[];
  hoverData?: PriceHoverData;
}

export const LastClosePrice = ({
  entity,
  snakeMeta,
  legendColor,
  isPrimary,
  seriesType,
  priceFormatter,
  ts,
  iconMapping,
  price,
  hoverData,
}: LastClosePriceProps) => {
  const isOHLCSeries =
    seriesType && ['bars', 'candlestick'].includes(seriesType);
  const isOHLCPrice = isOHLCSeries && isPrimary;

  const lastClosePrice =
    hoverData?.currentPrice?.close ?? ts[ts.length - 1].close;
  const beforeLastPrice =
    hoverData?.previousPrice?.close ?? ts[ts.length - 2].close;

  const decimals = entity.sub_class
    ? priceDecimals(entity.sub_class)(lastClosePrice)
    : undefined;

  const { priceChange } = getPriceChange({
    lastPrice: beforeLastPrice,
    newPrice: lastClosePrice ?? 0,
    decimals,
  });

  if (isOHLCPrice) {
    return (
      <OHLCPrice
        price={hoverData?.currentPrice ?? ts[ts.length - 1]}
        priceFormatter={priceFormatter}
      />
    );
  }

  const isPriceOrYield =
    isPriceSnake(snakeMeta.snake) || isYieldSnake(snakeMeta.snake);

  return (
    <S.LegendPriceRoot data-testid="last-close-price">
      {isPrimary &&
        iconMapping.map((icon, i) =>
          icon.shouldShow ? (
            <LegendPriceIcons key={i} iconInfo={icon} price={price} />
          ) : null
        )}
      <S.PriceContainer $color={legendColor}>
        <p>
          {lastClosePrice ? priceFormatter(lastClosePrice) : lastClosePrice}
        </p>
        {snakeMeta.display_format === DisplayFormat.CURRENCY && (
          <p>{snakeMeta.currency.toUpperCase()}</p>
        )}
        {isPriceOrYield && (
          <>
            <p>{priceChange.change}</p>
            <p>({priceChange.proportionChange})</p>
          </>
        )}
      </S.PriceContainer>
    </S.LegendPriceRoot>
  );
};
