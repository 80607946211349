import { ScreenerFilterOption } from '@toggle/toggle';
import React from 'react';

import { TreeNode } from '../TreeNode/TreeNode';

export interface ChildNodeProps {
  children?: ScreenerFilterOption[];
  level: number;
  selectedOptions: ScreenerFilterOption[];
  searchQuery: string;
  expandedItems: string[];
  onClickCaret: (values: string[], isExpanded: boolean) => void;
  onChangeCheckBox: (options: ScreenerFilterOption[], checked: boolean) => void;
}

export const ChildNode = ({
  children,
  level,
  selectedOptions,
  searchQuery,
  expandedItems,
  onClickCaret,
  onChangeCheckBox,
}: ChildNodeProps) => {
  return (
    <>
      {children?.map(c => (
        <TreeNode
          key={c.key}
          option={c}
          level={level}
          selectedOptions={selectedOptions}
          searchQuery={searchQuery}
          expandedItems={expandedItems}
          onClickCaret={onClickCaret}
          onChangeCheckBox={onChangeCheckBox}
        />
      ))}
    </>
  );
};
