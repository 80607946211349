import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextBlock } from '../text-block/TextBlock';

export interface ConditionSectionProps {
  condition: string;
  episodesCount: number;
  totalTradingDays: number;
}

export const ConditionSection = ({
  condition,
  episodesCount,
  totalTradingDays,
}: ConditionSectionProps) => {
  const { t } = useTranslation('scenarioCommander');
  const tradingDays = t('scenarioCommander:domino.occurrencesTradingDays', {
    count: totalTradingDays,
    tradingDays: totalTradingDays,
  });
  const episodes = t('scenarioCommander:domino.occurrencesEpisodes', {
    count: episodesCount,
    episodes: episodesCount,
  });
  const helpText = t('scenarioCommander:domino.occurrences', {
    occurrencesEpisodes: episodes,
    occurrencesTradingDays: tradingDays,
  });

  return (
    <TextBlock
      data-testid="condition-section"
      title={t('scenarioCommander:domino.condition')}
      text={condition}
      helpText={helpText}
    />
  );
};
