import { SortingState } from '@tanstack/react-table';
import { citation, FilterSchemas } from '@toggle/toggle';
import { create } from 'zustand';

import {
  DocumentSearchFilterGroupKeys,
  FilterGroups,
  SharedFilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { postEntity } from '~/services/entities/entity-service';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

const COMPANY_FILTER_KEY = 'company';

export type DocumentSourcesTableStore = {
  sorting: {
    id: string;
    desc: boolean;
  }[];
  handleColumnSorting: (newSorting: SortingState) => void;
  resetColumnSorting: () => void;
  updateFilters: (groupId?: string) => void;
  clearAllActiveFilterOptions: () => void;
  resetFilters: () => void;
  activeDocumentIds: string[];
  setActiveDocumentIds: (ids: string[]) => void;
  customContextCounts?: {
    documentsCount: number;
    companiesCount: number;
  };
  setCustomContextCounts: (counts?: {
    documentsCount: number;
    companiesCount: number;
  }) => void;
} & SharedFilterState<DocumentSearchFilterGroupKeys>;

const defaultColumnSorting = [{ id: 'rank', desc: true }];

export const useDocumentSourcesTableStore = create<DocumentSourcesTableStore>(
  (set, get) => ({
    resetFilters: () => {
      set({
        filterGroups: {
          DOCUMENT_FILTERS: {
            activeFilterOptions: [],
            activeFilters: [],
            allFilters: [],
          },
        },
      });
    },
    updateFilters: async groupId => {
      const { resetFilters } = get();

      if (!groupId) {
        resetFilters();
        return;
      }

      const filterSchemas = await apiFetch<FilterSchemas>(
        citation.citationFilterSchemas.path(groupId),
        { method: 'post', body: {} }
      );

      if (!filterSchemas.error) {
        const { schemas } = filterSchemas.data.groups[0];
        const updatedSchemas = await Promise.all(
          schemas.map(async schema => {
            if (schema.key !== COMPANY_FILTER_KEY) {
              return schema;
            }

            const options = schema.config.options ?? [];
            const updatedOptions = await Promise.all(
              options.map(async option => {
                const { data } = await postEntity(option.key);
                return {
                  ...option,
                  name: data?.name ?? option.name,
                  entity: data,
                };
              })
            );
            return {
              ...schema,
              config: {
                ...schema.config,
                options: updatedOptions,
              },
            };
          })
        );

        const filterGroup = {
          DOCUMENT_FILTERS: {
            allFilters: updatedSchemas,
            activeFilterOptions: [],
            activeFilters: updatedSchemas.filter(f => f.visible),
          },
        };
        set({ filterGroups: filterGroup });
      }
    },
    filterGroups: {
      DOCUMENT_FILTERS: {
        activeFilterOptions: [],
        activeFilters: [],
        allFilters: [],
      },
    },
    sorting: defaultColumnSorting,
    handleColumnSorting: (newSorting: SortingState) => {
      set({ sorting: newSorting });
    },
    resetColumnSorting: () => {
      set({ sorting: defaultColumnSorting });
    },
    clearAllActiveFilterOptions: () =>
      set(state => {
        const clearedFilterGroups: FilterGroups<DocumentSearchFilterGroupKeys> =
          {
            DOCUMENT_FILTERS: {
              ...state.filterGroups.DOCUMENT_FILTERS,
              activeFilterOptions: [],
            },
          };

        return { filterGroups: clearedFilterGroups };
      }),
    activeDocumentIds: [],
    setActiveDocumentIds: ids => {
      set({ activeDocumentIds: ids });
    },
    setCustomContextCounts: counts => {
      set({ customContextCounts: counts });
    },
  })
);
