import { RefObject, useEffect, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

export interface UseWindowResizeProps<T> {
  targetRef: RefObject<T | null>;
}

export const useWindowResize = <T extends HTMLElement>({
  targetRef,
}: UseWindowResizeProps<T>) => {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!targetRef.current) {
      return undefined;
    }

    const target = targetRef.current;

    const handleResize = () => {
      const ret = target.getBoundingClientRect();
      setSize({ width: ret.width, height: ret.height });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(target);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { width: size.width, height: size.height };
};
