import {
  getDirection,
  Indicator,
  numberRoundedToPrecision,
} from '@toggle/helpers';
import React, { FC } from 'react';
import { format } from 'date-fns';

import { IndexedTsPoint } from '../../TadpoleChart';
import { PlainIcon } from '../icons/PlainIcon';

export interface Scale<T> {
  (value: T | number): number;
  invert(point: number): T;
  domain(): T[];
  domain(domain: Iterable<T>): this;
  copy(): this;
  range(): number[];
}

interface IconsOverlayProps {
  xScale: Scale<number>;
  yScale: Scale<number>;
  ts: IndexedTsPoint[];
  lowerBound: number;
  upperBound: number;
  indicator: Indicator;
}

export const ThresholdIconsOverlay: FC<IconsOverlayProps> = ({
  xScale,
  yScale,
  ts,
  lowerBound,
  upperBound,
  indicator,
}) => {
  return (
    <g aria-labelledby="spark-plot-icons">
      {ts.map(({ index, value, date }) => {
        const translateY = yScale(index);
        const translateX = xScale(value);
        const lastValue = index === ts[ts.length - 1].index;
        const dateString = format(date, 'MMM dd, yyyy');
        const valueString = numberRoundedToPrecision(value).toFixed(2);

        const direction = getDirection({
          indicator,
          up: upperBound,
          down: lowerBound,
          value: value,
        });

        return (
          <PlainIcon
            key={`icon-Dot-${index}`}
            valueString={valueString}
            dateString={dateString}
            mostRecent={lastValue}
            translateX={translateX}
            translateY={translateY}
            direction={direction}
          />
        );
      })}
    </g>
  );
};
