import React from 'react';
import { useSearchParams } from 'react-router';

import { queryKeys } from '~/routes/app-paths';
import {
  Suggestion,
  useRecentSearches,
} from '~/views/news/hooks/use-recent-searches/useRecentSearches';
import { useNewsSearchStore } from '~/views/news/stores/use-news-search-store/useNewsSearchStore';

import { SearchSources } from '../search-sources/SearchSources';
import { SearchSuggestions } from '../search-suggestions/SearchSuggestions';
import mockSuggestions from './mock-suggestions.json';
import * as S from './SearchLanding.styles';

export const SearchLanding = () => {
  const [, setSearchParams] = useSearchParams();

  const setSearchParam = (value: string) => {
    setSearchParams({
      [queryKeys.searchNews]: value,
    });
  };

  const setInputValue = useNewsSearchStore(state => state.setInputValue);
  const { recentSearches, addRecentSearch } = useRecentSearches();
  const suggestions = [...recentSearches, ...mockSuggestions] as Suggestion[];

  const handleSelect = (suggestion: Suggestion) => {
    setSearchParam(suggestion.label);
    addRecentSearch(suggestion.label);
    setInputValue(suggestion.label);
  };

  return (
    <S.SearchLandingRoot data-testid="search-landing">
      <S.Content>
        <SearchSources />
        <SearchSuggestions list={suggestions} handleSelect={handleSelect} />
      </S.Content>
    </S.SearchLandingRoot>
  );
};
