import { Icon } from '@toggle/design-system';
import React, { FC, ReactNode } from 'react';

import * as S from './ChatMessagePrompt.styles';

export interface ChatMessagePromptProps {
  title: ReactNode;
  message: ReactNode;
  onClick?: () => void;
}

export const ChatMessagePrompt: FC<ChatMessagePromptProps> = ({
  title,
  message,
  onClick,
  ...rest
}) => {
  return (
    <S.Container
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      data-testid="chat-message-prompt"
      {...rest}
    >
      <S.MessageContainer>
        <S.Title>{title}</S.Title>
        <S.Message>{message}</S.Message>
      </S.MessageContainer>
      <S.IconContainer>
        <Icon size={12} iconName="ArrowUp" />
      </S.IconContainer>
    </S.Container>
  );
};
