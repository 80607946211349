type getArticleDisplayDateProps = {
  created_at: string;
  last_value_reference_date?: string;
};

export const getArticleDisplayDate = ({
  created_at,
  last_value_reference_date,
}: getArticleDisplayDateProps) => {
  if (last_value_reference_date) {
    const strippedReferenceDate = new Date(last_value_reference_date);
    strippedReferenceDate.setUTCHours(0, 0, 0, 0);
    return strippedReferenceDate;
  }

  const strippedCreationDate = new Date(created_at);
  strippedCreationDate.setUTCHours(0, 0, 0, 0);

  const poiDate = new Date(strippedCreationDate);
  poiDate.setDate(
    strippedCreationDate.getUTCDate() -
      (strippedCreationDate.getUTCDate() === 1 ? 3 : 1)
  );

  return poiDate;
};
