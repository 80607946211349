import {
  Badge,
  Category,
  CategoryItem,
  CategorySubitem,
  Icon,
} from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';

import { useThemeStore } from '~/components/theme-controller/stores/useThemeStore';
import { useWatchlistActions } from '~/hooks/use-watchlist-actions/useWatchlistActions';
import { appPaths } from '~/routes/app-paths';
import { useApp } from '~/stores/use-app/useApp';
import { useAuth } from '~/stores/use-auth/useAuth';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { MAX_NAME_LENGTH } from '~/stores/use-watchlist/constants';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { useChat } from '~/views/reasoner/use-chat/useChat';
import { useScreenerActions } from '~/views/screener/use-screener-actions/useScreenerActions';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';
import { widgetPaths } from '~/views/widgets/widget-utils';

import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import * as S from './Sidebar.styles';
import {
  getActiveNavigationKey,
  getActiveNavigationSubKey,
  mapSidebarKeyToPathname,
  mapSidebarKeyToRegex,
  sidebarKeys,
} from './utils/navigation-utils';

export const FEEDBACK_SUPPORT_EMAIL = 'support@reflexivity.com';

// eslint-disable-next-line max-lines-per-function
export const Sidebar = () => {
  const { t } = useTranslation(['screener', 'common']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { logout } = useAuth();
  const colorScheme = useThemeStore(state => state.colorScheme);

  const {
    savedScreens,
    activeSavedScreen,
    draftScreenFilterGroups,
    canCreateNewScreen,
  } = useScreenerStore(state => ({
    savedScreens: state.savedScreens,
    activeSavedScreen: state.activeSavedScreen,
    draftScreenFilterGroups: state.draftScreenFilterGroups,
    canCreateNewScreen: state.canCreateNewScreen(),
  }));
  const { isSidebarExpanded, setSidebarExpanded } = useApp(state => ({
    isSidebarExpanded: state.isSidebarExpanded,
    setSidebarExpanded: state.setSidebarExpanded,
  }));
  const { lists } = useWatchlist(state => ({
    lists: state.lists,
  }));
  const { navigateToScreener, createNewDraft } = useScreenerActions();

  const { unreadConversationsCount } = useChat(state => ({
    unreadConversationsCount: state.getUnreadConversations().length,
  }));

  const { navigateToWatchlist } = useWatchlistActions();

  const isOnDraftScreen =
    !activeSavedScreen &&
    mapSidebarKeyToRegex[sidebarKeys.screener].test(location.pathname);
  const showDraftScreen = isOnDraftScreen || draftScreenFilterGroups;

  const defaultNavigationHandler = (categoryItem: CategoryItem) => {
    navigate(mapSidebarKeyToPathname[categoryItem.key]);
  };

  const dashboardLabel = t('common:sideNavigation.dashboard');
  const createNewLabel = t('common:createNew');

  const isBasicUser = useSubscription(state => state.isBasicUser());

  const categories: Category[] = [
    {
      id: 'menu',
      items: [
        {
          label: dashboardLabel,
          key: sidebarKeys.dashboard,
          icon: <Icon iconName="DashboardNew" size={20} />,
          onClick: defaultNavigationHandler,
        },
        {
          label: t('common:sideNavigation.watchlist'),
          key: sidebarKeys.watchlist,
          icon: <Icon iconName="Watchlist" size={20} />,
          subitemGroups: lists.length
            ? [
                [
                  ...lists.map<CategorySubitem>(list => ({
                    label: (
                      <TruncateTooltip
                        text={list.name}
                        atLength={MAX_NAME_LENGTH}
                        placement="right"
                        inPortal
                      />
                    ),
                    key: `${appPaths.watchlist}/${list.id}`,
                    onClick: () => navigateToWatchlist(list.id),
                  })),
                ],
                [
                  {
                    label: createNewLabel,
                    key: 'new_watchlist',
                    onClick: () => {
                      searchParams.set(
                        'widget',
                        widgetPaths.watchlistManagerModal
                      );
                      setSearchParams(searchParams);
                    },
                    icon: 'Add',
                  },
                ],
              ]
            : undefined,
          onClick: () => {
            if (!lists.length) {
              navigateToWatchlist();
            }
          },
        },
        {
          label: t('common:sideNavigation.screener'),
          key: sidebarKeys.screener,
          icon: <Icon iconName="Screener" size={20} />,
          subitemGroups: [
            [
              ...savedScreens.map<CategorySubitem>(filter => ({
                label: (
                  <TruncateTooltip
                    text={filter.name}
                    atLength={MAX_NAME_LENGTH}
                    placement="right"
                    inPortal
                  />
                ),
                key: `${appPaths.screener}/${filter.id}`,
                onClick: () => {
                  navigateToScreener(filter.id, filter.name);
                },
              })),
              ...(showDraftScreen
                ? [
                    {
                      label: t('screener:newScreen'),
                      key: appPaths.screener,
                      badge: (
                        <Badge
                          label={t('screener:draft')}
                          color="neutral"
                          size="small"
                          badgeStyle="outline"
                        />
                      ),
                      onClick: () => {
                        navigateToScreener(null, null);
                      },
                    } as CategorySubitem,
                  ]
                : []),
            ],
            [
              {
                label: createNewLabel,
                key: 'new_screen',
                onClick: () => {
                  createNewDraft();
                },
                icon: 'Add',
                disabled: !canCreateNewScreen,
              },
            ],
          ],
          onClick: () => {
            if (!savedScreens.length) {
              createNewDraft({ skipUnsavedCheck: true });
            }
          },
        },
        {
          label: t('common:sideNavigation.insights'),
          key: sidebarKeys.insights,
          icon: <Icon iconName="ExploreNav" size={20} />,
          onClick: defaultNavigationHandler,
        },
        {
          label: t('common:sideNavigation.news'),
          key: sidebarKeys.news,
          icon: <Icon iconName="Newspaper" size={20} />,
          onClick: defaultNavigationHandler,
        },
        {
          label: t('common:sideNavigation.chart'),
          key: sidebarKeys.chart,
          icon: <Icon iconName="ChartMixed" size={20} />,
          onClick: defaultNavigationHandler,
          borderBottom: isBasicUser,
        },
        ...(isBasicUser
          ? []
          : [
              {
                label: t('common:sideNavigation.events'),
                key: sidebarKeys.events,
                icon: <Icon iconName="Calendar" size={20} />,
                onClick: defaultNavigationHandler,
              },
              {
                label: t('common:sideNavigation.scenario'),
                key: sidebarKeys.scenario,
                icon: <Icon iconName="Scenario" size={20} />,
                onClick: defaultNavigationHandler,
              },
              {
                label: t('common:sideNavigation.alfred'),
                key: sidebarKeys.alfred,
                icon: <Icon iconName="AlfredNav" size={20} />,
                onClick: defaultNavigationHandler,
                badge: (
                  <S.AlfredBadge
                    label={t('common:sideNavigation.beta')}
                    color="neutral"
                    size="small"
                  />
                ),
                borderBottom: true,
                notification: !!unreadConversationsCount && (
                  <Badge
                    variant="numerical"
                    size="small"
                    label={unreadConversationsCount}
                    aria-label={t('common:notifications.count', {
                      count: unreadConversationsCount,
                    })}
                  />
                ),
              },
            ]),
        {
          label: t('common:sideNavigation.search'),
          key: sidebarKeys.search,
          icon: <Icon iconName="Search" size={20} />,
          onClick: () => {
            searchParams.set('widget', 'searchAsset');
            setSearchParams(searchParams);
          },
        },
      ],
    },
    {
      id: 'options',
      items: [
        {
          label: t('common:sideNavigation.feedback'),
          key: sidebarKeys.feedback,
          icon: <Icon iconName="Feedback" size={20} />,
          onClick: () => {
            const subject = t('common:sideNavigation.terminalFeedback');
            const mailtoLink = `mailto:${FEEDBACK_SUPPORT_EMAIL}?subject=${encodeURIComponent(
              subject
            )}`;
            window.location.href = mailtoLink;
          },
          variant: 'tertiary',
        },
        {
          label: t('common:sideNavigation.settings'),
          key: sidebarKeys.settings,
          icon: <Icon iconName="Settings" size={20} />,
          onClick: defaultNavigationHandler,
          variant: 'secondary',
        },
        {
          label: t('common:sideNavigation.help'),
          key: sidebarKeys.help,
          icon: <Icon iconName="Support" size={20} />,
          onClick: () => {
            window.zE('webWidget', 'open');
            window.zE('webWidget', 'show');
          },
          variant: 'secondary',
        },
        {
          label: t('common:sideNavigation.signOut'),
          key: sidebarKeys.signOut,
          icon: <Icon iconName="SignOut" size={20} />,
          onClick: () => {
            logout();
          },
          variant: 'secondary',
        },
      ],
    },
  ];

  const onSidebarToggle = () => {
    setSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <S.SideNavigationStyled
      activeKey={getActiveNavigationKey(categories)}
      activeSubKey={getActiveNavigationSubKey(categories)}
      colorScheme={colorScheme}
      isExpanded={isSidebarExpanded}
      onSidebarToggle={onSidebarToggle}
      onLogoClick={() => {
        navigate(appPaths.base);
      }}
      categories={categories}
    />
  );
};
