import { add, differenceInSeconds } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

import { deleteCookie, getCookie, setCookie } from '~/utils/index';

interface CountdownValues {
  timeLeft?: number;
  newCountdown: () => void;
  stopCountdown: () => void;
}

interface CountdownProps {
  key: string;
  time: number;
  onEnd?: () => void;
}

export const useCountdown = ({
  key,
  time,
  onEnd,
}: CountdownProps): CountdownValues => {
  const interval = useRef<ReturnType<typeof setInterval>>(undefined);
  const [timeLeft, setTimeLeft] = useState<number>();

  const newCountdown = () => {
    setCountdownCookie();
    startCountdown();
  };

  const setCountdownCookie = () => {
    const countdownTime = add(new Date(), { seconds: time });
    setCookie(key, JSON.stringify(countdownTime.getTime()), {
      expires: countdownTime,
    });
  };

  const startCountdown = () => {
    const localStorageTime = getCookie(key);

    if (localStorageTime) {
      countdownInterval(+localStorageTime);
    }
  };

  const setTimeDifference = (countdownTime: number) => {
    const seconds = differenceInSeconds(countdownTime, new Date().getTime(), {
      roundingMethod: 'ceil',
    });

    if (seconds <= 0) {
      interval.current && clearInterval(interval.current);
      setTimeLeft(undefined);
      onEnd?.();
      return;
    }

    setTimeLeft(seconds);
  };

  const countdownInterval = (countdownTime: number) => {
    setTimeDifference(countdownTime);
    interval.current = setInterval(() => {
      setTimeDifference(countdownTime);
    }, 1000);
  };

  const stopCountdown = () => {
    interval.current && clearInterval(interval.current);
    deleteCookie(key);
    setTimeLeft(undefined);
  };

  useEffect(() => {
    startCountdown();

    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, []);

  return {
    timeLeft,
    newCountdown,
    stopCountdown,
  };
};
