import { z } from 'zod';

import { entitySchema } from './entity-schema';

const statusEnum = z.enum(['error', 'success', 'pending', 'processing']);
const stepSchema = z.object({
  title: z.string(),
  content: z.string().optional(),
  status: statusEnum,
  id: z.string().optional(),
});

const conversationMessageResponseSchema = z.object({
  content: z.string().optional(),
  status: statusEnum,
});

export const conversationMessageStepsSchema = z.object({
  message_id: z.string(),
  conversation_id: z.string(),
  question: z.string(),
  status: statusEnum,
  steps: z.array(stepSchema),
  response: conversationMessageResponseSchema.optional(),
});

const postConversationSchema = z.object({
  id: z.string(),
  message_id: z.string(),
  name: z.string(),
});

const continueConversationSchema = z.object({ message_id: z.string() });
const conversationMessage = z.object({
  response: z.string(),
  question: z.string(),
  message_id: z.string(),
  status: statusEnum,
  step_count: z.number(),
  date: z.string(),
});

const getConversationSchema = z.object({
  messages: z.array(conversationMessage),
});

const conversationSearchResult = z.object({
  conversation: z.string(),
  conversation_id: z.string(),
  message_id: z.string(),
  updated_at: z.string(),
  summary: z.string(),
});

const searchConversationSchema = z.object({
  results: z.array(conversationSearchResult),
});

const conversationDetailsSchema = z.object({
  name: z.string(),
  date: z.string(),
  id: z.string(),
  summary: z.string(),
  favourite: z.boolean(),
});

const getAllConversationsSchema = z.object({
  conversations: z.array(conversationDetailsSchema),
});

const feedbackEnum = z.enum(['Good', 'Bad']);
const postConversationFeedback = z.object({
  conversation_id: z.string(),
  message_id: z.string(),
  feedback: feedbackEnum,
});

const getMessageStepSchema = z.object({
  error: z.boolean().nullable(),
  is_success: z.boolean(),
  resolved: z
    .array(
      z.object({
        entity: z.string(),
        mappedEntity: entitySchema.optional(),
      })
    )
    .optional(),
  data_descriptions: z
    .array(
      z.object({
        display: z.array(z.string()),
      })
    )
    .optional(),
});

export type ConversationMessageWithSteps = z.infer<
  typeof conversationMessageStepsSchema
>;
export type ConversationMessageWithoutSteps = z.infer<
  typeof conversationMessage
>;
export type ConversationMessageResponseSchema = z.infer<
  typeof conversationMessageResponseSchema
>;
export type PostConversationResponse = z.infer<typeof postConversationSchema>;
export type ContinueConversationResponse = z.infer<
  typeof continueConversationSchema
>;
export type ConversationDetails = z.infer<typeof conversationDetailsSchema>;
export type ConversationSearchResult = z.infer<typeof conversationSearchResult>;
export type SearchConversationResponse = z.infer<
  typeof searchConversationSchema
>;
export type PostConversationFeedback = z.infer<typeof postConversationFeedback>;
export type ConversationMessageFeedback = z.infer<typeof feedbackEnum>;
export type ProcessingStatus = z.infer<typeof statusEnum>;
export type Step = z.infer<typeof stepSchema>;
export type GetMessageStep = z.infer<typeof getMessageStepSchema>;

export const conversations = {
  getAllConversations: {
    path: 'conversation/v1',
    schema: getAllConversationsSchema,
  },
  conversationId: {
    path: (id: string) => `conversation/v1/${id}`,
  },
  postConversation: { path: 'conversation/v1', schema: postConversationSchema },
  continueConversation: {
    path: (id: string) => `conversation/v1/${id}`,
    schema: continueConversationSchema,
  },
  getConversation: {
    path: (id: string) => `conversation/v1/${id}`,
    schema: getConversationSchema,
  },
  searchConversation: {
    path: 'conversation/v1/search',
    schema: searchConversationSchema,
  },
  getConversationMessage: {
    path: (conversationId: string, messageId: string) =>
      `conversation/v1/${conversationId}/messages/${messageId}`,
    schema: conversationMessageStepsSchema,
  },
  feedback: {
    path: 'conversation/v1/feedback',
    schema: postConversationFeedback,
  },
  getConversationMessageStep: {
    path: (conversationId: string, messageId: string, stepId: string) =>
      `conversation/v1/${conversationId}/messages/${messageId}/steps/${stepId}`,
    schema: getMessageStepSchema,
  },
};
