import { PriceDisplay, TabLines, typographyV2 } from '@toggle/design-system';
import styled, { css, keyframes } from 'styled-components';

const shrink = keyframes`
  0% {
    height: 210px;
  }
  100% {
    height: 157px;
  }
`;

export const HeaderContent = styled.div<{
  $hasEntity: boolean;
  $isCondensed: boolean;
}>`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${({ $hasEntity }) => !$hasEntity && 'height: 60px'};
  ${({ $isCondensed }) =>
    $isCondensed &&
    css`
      animation: ${shrink} 0.2s ease-out;
    `}
`;

export const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;

  ${TabLines.Styled.Header} {
    border-bottom: none;
  }
`;

export const ContentWrapper = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  ${PriceDisplay.Styled.PriceContainer} {
    margin-top: 7px;
  }

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      ${PriceDisplay.Styled.PriceContainer} {
        margin-top: 0;
      }
      ${PriceDisplay.Styled.Price} {
        ${typographyV2.bodySm}
      }
      ${PriceDisplay.Styled.Currency} {
        ${typographyV2.bodyXs}
      }
      ${PriceDisplay.Styled.PriceChangeContainer} {
        ${typographyV2.bodyXs}
      }
    `}
`;

export const Header = styled.div<{ $showShadow: boolean }>`
  ${({ $showShadow }) => $showShadow && 'box-shadow:var(--elevation-strong)'};
  border-bottom: 1px solid var(--border-default);
  background-color: var(--background-default);
  max-height: 218px;
  width: 100%;
`;
