import { Dropdown, DropdownListItem } from '@toggle/design-system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SortType,
  useNewsSearchStore,
} from '../../stores/use-news-search-store/useNewsSearchStore';
import * as S from './SortDropdown.styles';

const sortOptions: SortType[] = ['most-relevant', 'newest', 'oldest'];

export const SortDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('news');

  const { setSortBy, sortType } = useNewsSearchStore(state => ({
    setSortBy: state.setSortBy,
    sortType: state.sortType,
  }));

  const selectHandler = (type: SortType) => {
    setSortBy(type);
  };

  useEffect(() => {
    return () => {
      setSortBy('most-relevant');
    };
  }, []);

  return (
    <S.SortDropdownRoot data-testid="sort-dropdown">
      <Dropdown
        dropdownItems={sortOptions}
        selectItem={selectHandler}
        activeItemIdx={sortOptions.findIndex(option => option === sortType)}
        shouldShowItems={isOpen}
        renderDropdownItem={(item, isActive, isFocused) => (
          <DropdownListItem
            /* i18next-extract-mark-context-next-line ["most-relevant", "newest", "oldest"] */
            label={t('news:sortingDropdown.label', {
              context: item,
            })}
            isActive={isActive}
            isFocused={isFocused}
          />
        )}
        onItemsShowChange={setIsOpen}
        offsetValue={{
          x: 0,
          y: 1,
        }}
      >
        <S.StyledSelect
          inputProps={{
            /* i18next-extract-mark-context-next-line ["most-relevant", "newest", "oldest"] */
            value: t('news:sortingDropdown.label', {
              context: sortType,
            }),
            variant: 'outline',
            size: 'medium',
          }}
          isReadonlyInput
          isActive={false}
        />
      </Dropdown>
    </S.SortDropdownRoot>
  );
};
