import { AlertType, Button } from '@toggle/design-system';
import { mailTo } from '@toggle/helpers';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ActionLink } from '~/components/link/link.styles';
import { config } from '~/config';

import { ScenarioConditionBuilder } from '../scenario-condition-builder/ScenarioConditionBuilder';
import { MappedConditions } from '../use-scenario-assistant/enrichedConditions';
import { useScenarioAssistant } from '../use-scenario-assistant/useScenarioAssistant';
import { validateConditions } from '../validate-condition/validateCondition';
import * as S from './ConditionsView.styles';

export interface ConditionsViewProps {
  editFilterKey?: string;
  conditions: MappedConditions[];
  handleClose: () => void;
  handleEdit: () => void;
}

export const ConditionsView = ({
  editFilterKey,
  conditions,
  handleClose,
  handleEdit,
}: ConditionsViewProps) => {
  const { t } = useTranslation(['screener', 'common']);
  const supportEmail = mailTo(config.supportEmail);
  const askedQuestion = useScenarioAssistant(state => state.askedQuestion);
  const confirmConditions = useScenarioAssistant(
    state => state.confirmConditions
  );
  const [error, setError] = useState('');

  const handleConfirm = () => {
    const selectedConditions = conditions.filter(f => f.active);

    if (selectedConditions.length) {
      if (!validateConditions(selectedConditions)) {
        setError('screener:error.validationError');
        return;
      }

      confirmConditions();
      handleClose();
    } else {
      setError('screener:error.emptyConditionOptions');
    }
  };

  return (
    <S.Container data-testid="scenario-assistant-condition-view">
      <S.StyledConditionalWrapper>
        <S.Title data-testid="new-custom-header">
          {t('screener:modal.conditionEntry')}
        </S.Title>
        <S.ConditionalDescription>{askedQuestion}</S.ConditionalDescription>
        {error && (
          <S.ScenarioFilterError
            type={AlertType.Error}
            variant="inline"
            onClose={() => setError('')}
            message={
              <Trans
                t={t}
                i18nKey={error}
                components={[<ActionLink key="key-0" href={supportEmail} />]}
                values={{
                  email: config.supportEmail,
                }}
              />
            }
          />
        )}
        <S.Title data-testid="new-custom-header">
          {t('screener:conditions')}
        </S.Title>
        <ScenarioConditionBuilder
          editFilterKey={editFilterKey}
          conditions={conditions}
        />
      </S.StyledConditionalWrapper>
      <S.ButtonContainer>
        <Button
          variant="secondary"
          data-testid="edit-button"
          size="large"
          label={t('screener:editTextEntry')}
          onClick={handleEdit}
        />
        <Button
          data-testid="confirm-button"
          variant="primary"
          size="large"
          label={t('common:confirm')}
          onClick={handleConfirm}
        />
      </S.ButtonContainer>
    </S.Container>
  );
};
