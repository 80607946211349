import { z } from 'zod';

export type FilterValues = {
  key: 'above-or-equal' | 'below-or-equal' | 'between';
  value: {
    min?: number;
    max?: number;
  };
};

export const isNumberInRange = (
  value: number,
  filterValue: FilterValues
): boolean => {
  switch (filterValue.key) {
    case 'between':
    case 'below-or-equal':

    case 'above-or-equal': {
      const { min = -Infinity, max = Infinity } = filterValue.value;
      return z.number().min(min).max(max).safeParse(value).success;
    }

    default: {
      return false;
    }
  }
};
