import { Card, Icon } from '@toggle/design-system';
import { intlFormat } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './EventDate.styles';

export interface EventDateProps {
  dateString: string;
  reported?: boolean;
}

export const EventDate = ({ dateString, reported }: EventDateProps) => {
  const reportingDate = new Date(dateString);

  const hasTime = dateString.includes('T');
  const { t } = useTranslation('calendar');

  const hourFormatted = intlFormat(reportingDate, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    hour12: false,
  });

  const dateFormatted = intlFormat(reportingDate, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });

  const getLabel = () => {
    if (reported) {
      return <span data-testid="reported">{t('calendar:reported')}</span>;
    }

    if (hasTime) {
      return (
        <span data-testid="scheduled-at" suppressHydrationWarning>
          {t('calendar:scheduledAt', {
            hourFormatted,
          })}
        </span>
      );
    }

    return (
      <span data-testid="scheduled-for" suppressHydrationWarning>
        {t('calendar:scheduledFor')}
      </span>
    );
  };

  return (
    <S.EventDateRoot data-testid="earnings-date">
      <Card size="small">
        <S.Title $isReported={reported}>
          <Icon
            iconName={reported ? 'CheckmarkLightCircle' : 'Calendar'}
            size={16}
          />
          {getLabel()}
        </S.Title>
        <S.Description suppressHydrationWarning>{dateFormatted}</S.Description>
      </Card>
    </S.EventDateRoot>
  );
};
