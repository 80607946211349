import { Input, Select, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const SortDropdownRoot = styled.div`
  display: flex;
`;

export const StyledSelect = styled(Select)`
  ${Input.Styles.InputWrapper} {
    padding: 5px 11px;
    border-radius: 8px;
  }

  ${Input.Styles.InputElement} {
    ${typographyV2.bodySm};
    width: 95px;
    padding: 0;
  }
`;
