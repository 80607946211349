import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useThemeExposure } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { httpCode } from '~/utils/api-fetch/httpCode';
import { ThemeOverviewDrawer } from '~/views/theme-overview/theme-overview-drawer/ThemeOverviewDrawer';
import { useThemeOverviewStore } from '~/views/theme-overview/use-theme-overview-store/useThemeOverviewStore';

import { KnowledgeGraphModule } from '../knowledge-graph-module/KnowledgeGraphModule';
import * as S from './ThemeExposureModule.styles';

export interface ThemeExposureModuleProps {
  entityTag: string;
  isEtf: boolean;
}

export const ThemeExposureModule = forwardRef(
  (
    { entityTag, isEtf }: ThemeExposureModuleProps,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation('widget');
    const [selectedThemeId, setSelectedThemeId] = useState<string>();
    const { getTheme } = useThemeOverviewStore();

    useEffect(() => {
      if (selectedThemeId) {
        getTheme(selectedThemeId);
      }
    }, [selectedThemeId]);

    const {
      data: themes = [],
      isLoading: themesLoading,
      isRefetching: themesRefetching,
      error: themesError,
    } = useThemeExposure(entityTag, isEtf);

    return (
      <div ref={ref}>
        {selectedThemeId && (
          <ThemeOverviewDrawer
            handleClose={() => setSelectedThemeId(undefined)}
            selectedThemeId={selectedThemeId}
          />
        )}
        <KnowledgeGraphModule
          data-testid="theme-exposure-knowledge"
          title={t('widget:assetOverview.themeExposure')}
          tooltip={t('widget:assetOverview:knowledgeGraphTooltip', {
            context: 'themeExposure',
          })}
          collapsedHeight={220}
          isLoading={themesLoading || themesRefetching}
          isError={!!themesError && themesError.status !== httpCode.notFound}
          isEmpty={!themes.length}
        >
          {themes?.map(theme => (
            <S.ThemeListItem key={theme.id} data-testid="theme-exposure-item">
              <S.StyledPillDropdown
                label={theme.name}
                onClickPill={() => setSelectedThemeId(theme.id)}
              />
              <S.StyledListDescription>
                {theme.evidence}
              </S.StyledListDescription>
            </S.ThemeListItem>
          ))}
        </KnowledgeGraphModule>
      </div>
    );
  }
);
