import { useQueries, useQuery } from '@tanstack/react-query';
import { DetailedInsight } from '@toggle/toggle';

import { getInsight } from '~/services/insights/insights-service';

export const QUERY_KEY_INSIGHT = 'QUERY_KEY_INSIGHT';

export const useInsight = (insightId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_INSIGHT, insightId],
    queryFn: () => getInsight(insightId),
    enabled: !!insightId,
  });

export const useQueryInsights = (insightsId: string[], enabled = true) => {
  const queries = insightsId.map(insightId => ({
    queryKey: [QUERY_KEY_INSIGHT, insightId],
    queryFn: () => getInsight(insightId),
    enabled,
  }));
  return useQueries({
    queries,
    combine: results => {
      const hasResult = !!results.length;
      return {
        data: results
          .map(result => result.data?.data)
          .filter(Boolean) as DetailedInsight[],
        isLoading:
          hasResult &&
          results.some(result => result.isLoading || result.isRefetching),
        isError: hasResult && results.every(result => result.data?.error),
        refetch: () => {
          results.forEach(result => result.refetch());
        },
      };
    },
  });
};
