import {
  borderRadius,
  ButtonLink,
  customScrollMinimal,
  Icon,
  typographyV2,
} from '@toggle/design-system';
import styled from 'styled-components';

export const InternalNewsButton = styled(ButtonLink)`
  ${borderRadius.r05};
  background-color: var(--surface-default);
  color: var(--text-soft);
  padding: 2px 4px;
  height: 20px;
  margin: auto 2px;
  vertical-align: text-bottom;

  span {
    gap: 4px;
    ${typographyV2.uiXs};
  }

  svg {
    fill: var(--icon-soft);
  }

  &:hover {
    text-decoration: none;
    background-color: var(--surface-hover);
    color: var(--text-primary);
    cursor: pointer;

    svg {
      fill: var(--icon-primary);
    }
  }

  &:focus {
    text-decoration: none;
  }
`;
export const ArticlesButton = styled.span`
  ${borderRadius.r05};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  background-color: var(--surface-default);
  padding: 2px 4px;
  height: 20px;
  margin: auto 2px;
  vertical-align: text-bottom;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: var(--surface-hover);

    a {
      color: var(--text-primary);
    }

    svg {
      fill: var(--icon-primary);
    }
  }
`;

export const GlobeIcon = styled(Icon)`
  fill: var(--icon-soft);
`;

export const ExternalLinkIcon = styled(Icon)`
  fill: var(--icon-soft);
`;

export const MarkdownA = styled.a`
  ${typographyV2.uiXs}
  color: var(--text-soft);
`;

export const MarkdownTableContainer = styled.div`
  overflow: scroll;
  margin: 1em 0px;
  ${customScrollMinimal}
`;

export const MarkdownTable = styled.table`
  border-spacing: 1px;
  width: 100%;

  tr {
    background-color: var(--background-default);
  }

  td {
    border-bottom: 1px solid var(--border-soft);
  }

  tbody tr:hover {
    background-color: var(--surface-hover);
  }
`;
