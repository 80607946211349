import { typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

export const NewsRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NewsTitle = styled.h1`
  ${typographyV2.titleLg};
`;
